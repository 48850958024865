import ShapeTool from './shapeTool'
import Wall from './wall'
import Util from './util'
import store from 'store'
import { addObject } from 'store/objects'
import { isOrthoModeEnabled } from 'store/tools/selectors'
import { setStatus } from 'store/status'

import * as THREE from 'three'

class EllipseTool extends ShapeTool {
  /*
      -= properties =-

      wall: Wall object used to create the rectangle visual
      obj3d: root of the visual used to indicate the bounds of the drawn object
    */

  constructor() {
    super()

    this.name = 'ELLIPSE_TOOL'
    this.createVisual()
  }

  createVisual() {
    this.obj3d = new THREE.Object3D()
  }

  updateVisual(startPos, endPos) {
    if (this.wall !== undefined) {
      this.wall.segments.forEach(segment => segment.hideLengthLabel())
      this.obj3d.remove(this.wall.obj3d)
    }

    // We are no longer drawing so return
    if (this.shapeCancelled) return

    let xDiff = endPos.x - startPos.x
    let yDiff = (endPos.y - startPos.y) * -1

    if (this.shiftModifier || isOrthoModeEnabled()) {
      if (yDiff > xDiff) {
        xDiff = xDiff > 0 ? yDiff : Math.abs(yDiff) * -1
      } else {
        yDiff = yDiff > 0 ? xDiff : Math.abs(xDiff) * -1
      }
    }

    const appState = store.getState()
    const units = appState.objects.present.units
    const layer = appState.layers.currentLayer
    const model = Wall.createModel(
      this.getEllipseCenterLine(startPos, xDiff, yDiff, 9),
      units,
      layer,
      { convertFromNativeUnits: true }
    )

    if (Wall.isCenterLineValid(model)) {
      this.wall = new Wall(model, units)

      this.wall.segments.forEach(segment => (segment.wall = this.wall))
      this.wall.segments.forEach(segment => segment.showLengthLabel())

      this.obj3d.add(this.wall.obj3d)
    }
  }

  finishShapeDescription(multiSelect) {
    super.finishShapeDescription()

    if (!this.wall || !this.wall.obj3d) return

    this.obj3d.remove(this.wall.obj3d)

    if (this.shapeCancelled) {
      this.wall = undefined
      return
    }

    const pos = this.wall.obj3d.position.clone()
    const positions = []
    positions.push(pos)
    this.wall.segments.forEach(seg => {
      positions.push(pos.clone().add(seg.startPoint))
      positions.push(pos.clone().add(seg.endPoint))
    })
    const isExteriorWall = this.wall.layerKey === 'EXTERIOR_WALLS'

    if (isExteriorWall) {
      // Don't allow exterior walls to be placed inside facility
      const isOutsideFacility = Util.isPositionsOutsideFacility(positions)
      // check to see if it has exterior walls within its bounds
      const surroundsFacility = Util.isSurroundingFacility(positions)

      if (isOutsideFacility && !surroundsFacility) {
        store.dispatch(addObject({ object: this.wall.toModel(), multiSelect }))
      } else if (surroundsFacility) {
        this.wall.segments.forEach(segment => segment.hideLengthLabel())
        const error = `Exterior walls can't enclose other exterior walls!`
        store.dispatch(setStatus({ text: error, type: 'error' }))
      } else {
        this.wall.segments.forEach(segment => segment.hideLengthLabel())
        const error = `Exterior walls can't be placed inside the facility!`
        store.dispatch(setStatus({ text: error, type: 'error' }))
      }
    } else {
      // Don't allow interior walls to be placed outside facility
      const isInsideFacility = Util.isPositionsOverFacility(positions)
      if (isInsideFacility) {
        store.dispatch(addObject({ object: this.wall.toModel(), multiSelect }))
      } else {
        this.wall.segments.forEach(segment => segment.hideLengthLabel())
        const error = 'Interior walls must be placed inside the facility!'
        store.dispatch(setStatus({ text: error, type: 'error' }))
      }
    }
  }

  deactivate() {
    if (this.wall) this.wall.segments.forEach(seg => seg.hideLengthLabel())

    this.removeEventListeners()
  }

  getEllipseCenterLine(origin, width, height, sampleCount) {
    const points = []

    for (let i = 0; i < sampleCount - 1; i += 1) {
      const t = ((Math.PI * 2) / (sampleCount - 1)) * i
      const x = (width / 2) * Math.cos(t)
      const y = (height / 2) * Math.sin(t)
      points.push([x, y, 0])
    }
    points.push(points[0].slice())

    points.forEach(point => {
      point[0] += origin.x + width / 2
      point[1] += origin.y - height / 2
    })

    return points
  }
}

export default EllipseTool
