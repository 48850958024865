import { useQuery } from '@apollo/client'
import { GetAllProductsQuery } from '~/gql/graphql'
import { SubmenuTrigger } from 'react-aria-components'
import { MenuDropDownButton } from '../components/MenuDropDownButton'
import { Menu, MenuItem } from '~/ui/Menu'
import { ProductOption } from '~/screens/FacilityScreen/MiddleCenter/ProductsMenu/components/ProductOption'
import { GET_ALL_PRODUCTS } from '~/client/queries'

type Products = NonNullable<GetAllProductsQuery['Products']>

type GroupedProducts = Record<
  'Industrial Overhead' | 'Commercial Overhead' | 'Directional' | 'Evaporative' | 'Heat',
  Products
>

const groupAndSortProducts = (products: Products) => {
  const groupedProducts = products.reduce<GroupedProducts>(
    (groups, product) => {
      if (product) {
        const { category, application, type } = product
        if (category === 'HEAT') groups.Heat.push(product)
        else if (category === 'EVAP') groups.Evaporative.push(product)
        else if (type === 'OVERHEAD') groups[application === 'COMMERCIAL' ? 'Commercial Overhead' : 'Industrial Overhead'].push(product)
        else if (type === 'DIRECTIONAL') groups.Directional.push(product)
      }
      return groups
    },
    {
      'Industrial Overhead': [],
      'Commercial Overhead': [],
      Directional: [],
      Evaporative: [],
      Heat: [],
    }
  )
  return Object.entries(groupedProducts).map(([type, products]) => {
    const sortedProducts = [...products].sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0))
    return [type, sortedProducts] as const
  })
}

const ProductsMenu = () => {
  const { data, loading } = useQuery(GET_ALL_PRODUCTS)
  const groupedProducts = groupAndSortProducts(data?.Products ?? [])

  return (
    <MenuDropDownButton isDisabled={loading} label={loading ? 'Loading...' : 'Products'} icon="fan">
      {groupedProducts.map(([type, products]) => {
        return (
          <SubmenuTrigger key={type}>
            <MenuItem className="p-4 w-[250px] text-xs flex items-center justify-between rounded-none">
              {type}
            </MenuItem>
            <Menu
              variant="square"
              popoverProps={{
                offset: 0,
                transition: { duration: 0 },
                placement: 'end top',
                className: 'dark',
              }}
              className="min-w-[250px]"
            >
              {products.map(product => (
                <ProductOption key={product.id} {...product} />
              ))}
            </Menu>
          </SubmenuTrigger>
        )
      })}
    </MenuDropDownButton>
  )
}

export default ProductsMenu
