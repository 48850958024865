import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Description = styled.p`
  color: ${theme('colors.light.subdued')};
  margin: 0;
`

export default Description
