import isEmpty from 'lodash-es/isEmpty'

import { getMessage } from 'config/messages'

export const CLEAR_STATUS = 'CLEAR_STATUS'
export const SET_STATUS = 'SET_STATUS'
export const DISABLE_CANVAS = 'DISABLE_CANVAS'
export const ENABLE_CANVAS = 'ENABLE_CANVAS'

// clearStatus() || clearStatus({ type: 'info' })
export const clearStatus = ({ type = '' } = {}) => {
  if (isEmpty(type)) {
    return { type: CLEAR_STATUS }
  }

  return {
    type: CLEAR_STATUS,
    payload: {
      type,
    },
  }
}

// setStatus({ type: 'saving', text: 'Saving...' })
export const setStatus = ({ type, text }) => ({
  type: SET_STATUS,
  payload: {
    type,
    text,
  },
})

// disableCanvas()
export const disableCanvas = () => ({
  type: DISABLE_CANVAS,
})

// enableCanvas()
export const enableCanvas = () => ({
  type: ENABLE_CANVAS,
})

// setStatusOn({ action: 'hover', show: 'airflow' })
export const setStatusOn = ({ action, show }) => {
  const message = getMessage({ key: show, action })

  return {
    type: SET_STATUS,
    payload: {
      type: message.type,
      text: message.text,
    },
  }
}

// setLoadingStatus() || setLoadingStatus('Loading facility...')
export const setLoadingStatus = (text = 'Loading...') => ({
  type: SET_STATUS,
  payload: {
    type: 'loading',
    text,
  },
})
