import ShapeTool from './shapeTool'
import Obstruction from './obstruction'
import Util from './util'
import Units from './units'
import store from 'store'
import { addObstruction } from 'store/objects'
import { isOrthoModeEnabled } from 'store/tools/selectors'
import { setStatus } from 'store/status'

import * as THREE from 'three'

class CubeTool extends ShapeTool {
  constructor() {
    super()

    this.name = 'CUBE_TOOL'
    this.createVisual()
  }

  createVisual() {
    this.obj3d = new THREE.Object3D()
  }

  updateVisual(startPos, endPos) {
    if (this.obstruction) {
      this.obstruction.hideLengthLabel()
      this.obj3d.remove(this.obstruction.obj3d)
    }

    // We are no longer drawing so return
    if (this.shapeCancelled) return

    const diff = endPos.clone().sub(startPos)

    if (isOrthoModeEnabled()) {
      if (Math.abs(diff.x) > Math.abs(diff.y)) {
        diff.y = Math.abs(diff.x) * Math.sign(diff.y)
      } else {
        diff.x = Math.abs(diff.y) * Math.sign(diff.x)
      }
    }

    const positions = Util.getBoxPositions(
      new THREE.Vector3().copy(startPos),
      new THREE.Vector3().copy(startPos).add(diff)
    )
    const model = Obstruction.createModel(positions)
    this.obstruction = new Obstruction(model)
    this.obj3d.add(this.obstruction.obj3d)

    this.obstruction.showLengthLabel()
  }

  deactivate() {
    if (this.obstruction) this.obstruction.hideLengthLabel()

    this.removeEventListeners()
  }

  finishShapeDescription(multiSelect) {
    super.finishShapeDescription()

    if (!this.obstruction) return

    this.obstruction.hideLengthLabel()
    this.obj3d.remove(this.obstruction.obj3d)

    const positions = this.obstruction.toModel().positions.map(position => ({
      x: Units.inchesToNative(position.x),
      y: Units.inchesToNative(position.y),
      z: Units.inchesToNative(position.z),
    }))
    positions.push(this.obstruction.obj3d.position)

    const isInsideFacility = Util.isPositionsOverFacility(positions)
    if (isInsideFacility) {
      if (this.shapeCancelled) {
        this.obstruction = undefined
        return
      }

      store.dispatch(
        addObstruction({ obstruction: this.obstruction.toModel(), multiSelect })
      )
    } else {
      const error = 'Obstructions must be placed inside the facility!'
      store.dispatch(setStatus({ text: error, type: 'error' }))
    }
  }
}

export default CubeTool
