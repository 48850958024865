import React, { ComponentProps } from 'react'
import PropTypes from 'prop-types'

import Container from './styled/Container'
import Icon from './styled/Icon'
import Text from './styled/Text'
import Subtext from './styled/Subtext'

const Loader = ({
  centered = false,
  label = 'Loading...',
  sublabel,
  iconSize = '16',
  ...props
}: {
  centered?: boolean
  inline?: boolean
  label?: string
  sublabel?: string
  iconSize?: string | number
} & ComponentProps<typeof Container>) => (
  <Container centered={centered} {...props}>
    <Icon
      name="refresh"
      size={iconSize}
      hasLabel={label !== ''}
      marginBottom={sublabel ? 's' : undefined}
    />
    {label !== '' && <Text centered={centered}>{label}</Text>}
    {sublabel && <Subtext centered={centered}>{sublabel}</Subtext>}
    {props.children}
  </Container>
)

export default Loader
