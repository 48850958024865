import styled from 'styled-components'

import { breakpoints } from 'lib/styleUtils'

const Container = styled.div<{ vCenter?: boolean; reverse?: boolean; dropdown?: boolean }>`
  ${props => props.vCenter && `align-items: center;`};
  ${props => props.reverse && `flex-direction: row-reverse;`};
  justify-content: space-between;
  ${props => props.dropdown && `cursor: pointer;`};

  ${breakpoints.s`
    display: block;
  `};

  ${breakpoints.m`
    display: flex;
  `};

  ${breakpoints.l`
    display: flex;
  `};
`

export default Container
