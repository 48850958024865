import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'

import Alert from 'components/UIKit/Alert'
import Button from 'components/UIKit/Button'
import Loader from 'components/UIKit/Loader'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'

import { STORAGE_KEY } from 'components/Modals/ExportPDFLayoutModal/Wizard/constants'

class FloorForm extends Component {
  state = {
    error: '',
    name: '',
    data: null,
    saving: false,
  }

  UNSAFE_componentWillMount() {
    if (!this.isEditScreen && !this.isDuplicateScreen) {
      const currentFloor = get(this.props, 'facility.floors').length
      const nextFloor = this.getNextFloorName(currentFloor)

      this.setState({
        name: nextFloor,
      })
    } else {
      const currentFloor = get(this.props, 'facility.floor')
      const defaultFloorName = this.getDefaultFloorName(currentFloor)

      this.setState({
        name: defaultFloorName,
      })
    }
  }

  getDefaultFloorName = floor =>
    this.isEditScreen ? floor.name : `${floor.name} (Copy)`

  getNextFloorName = currentFloor => {
    const nextFloor = parseInt(currentFloor, 10) + 1

    return `Area ${nextFloor}`
  }

  get isEditScreen() {
    return this.props.title === 'Edit Area'
  }

  get isDuplicateScreen() {
    return this.props.title === 'Duplicate Area'
  }

  handleNameChanged = event => {
    this.setState({
      name: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      saving: true,
    })

    if (this.state.name.trim() === '') {
      this.setState({
        error: 'Please enter a name!',
        saving: false,
      })
      return
    }

    const { facility, match } = this.props
    let data = {
      name: this.state.name,
      facilityId: facility.id,
    }

    if (this.isEditScreen) {
      data = {
        id: match.params.id,
        name: this.state.name,
      }
    }

    if (this.isDuplicateScreen) {
      data = {
        name: this.state.name,
        floorId: facility.floor.id,
      }
    }

    // TODO: Submit logic should exist outside of the form
    // We should not need to modify this code to add variables to submit functions
    this.props
      .onSubmit(data)
      .then(res => {
        const floorId = this.isEditScreen
          ? facility.floor.id
          : res.data.floor.id
        const versionId = this.isEditScreen
          ? facility.floor.version.id
          : res.data.floor.version.id

        if (!this.isEditScreen) {
          // Copy export settings for current area
          const facilityId = get(facility, 'id')
          const floorId = get(facility, 'floor.id')
          const floorVersionId = get(facility, 'floor.version.id')
          const itemId = `${STORAGE_KEY}:${facilityId}:${floorId}:${floorVersionId}`
          const item = window.localStorage.getItem(itemId)
          const currentExportSettings = JSON.parse(item) || {}

          // Remove additional options that don't copy well
          currentExportSettings.savedAdditionalImages = []
          currentExportSettings.selectedAdditionalImages = []
          currentExportSettings.savedComfortZones = []
          currentExportSettings.tempComfortZones = []
          currentExportSettings.selectedMainImageSnapshot = null
          currentExportSettings.savedMainImageSnapshot = null
          currentExportSettings.mainImage = 'PLAN_VIEW'
          if (!this.isDuplicateScreen) {
            currentExportSettings.selectedObjects = {}
            currentExportSettings.savedObjects = {}
          }
          const additionalOptions = get(
            currentExportSettings,
            'additionalOptions',
            []
          )
          currentExportSettings.additionalOptions = additionalOptions.filter(
            item => {
              const optsToRemove = ['ADDITIONAL_IMAGES', 'COMFORT_ZONE_METRICS']
              !this.isDuplicateScreen && optsToRemove.concat('OBJECT_INVENTORY')

              return !optsToRemove.includes(item)
            }
          )
          window.localStorage.setItem(
            `${STORAGE_KEY}:${get(facility, 'id')}:${floorId}:${versionId}`,
            JSON.stringify(currentExportSettings)
          )
        }

        this.props.history.push(
          `/facility/${facility.id}/area/${floorId}/version/${versionId}`
        )
      })
      .catch(error => {
        this.setState({
          error:
            get(error, 'graphQLErrors.0.message') || 'An error has occurred',
          saving: false,
        })
      })
  }

  render() {
    const { error, name, saving } = this.state

    return (
      <Modal
        {...this.props}
        title={this.props.title}
        onSubmit={this.handleSubmit}
        primaryAction={
          <Button
            primary
            onClick={this.handleSubmit}
            disabled={saving}
            label={saving ? 'Saving...' : this.props.buttonText}
          />
        }
        secondaryAction={<Button to={this.props.parentRoute} label="Cancel" />}
        size="500px"
        {...this.props}
      >
        {this.props.facilityData && this.props.facilityData.loading ? (
          <Loader />
        ) : (
          <>
            {!isEmpty(error) && (
              <Space bottom="base">
                <Alert type="error" text={error} />
              </Space>
            )}
            <Space bottom="base">
              <TextField
                label="Name"
                value={name}
                onChange={this.handleNameChanged}
              />
            </Space>
          </>
        )}
      </Modal>
    )
  }
}

FloorForm.propTypes = {
  parentRoute: PropTypes.string,
  facility: PropTypes.object,
  facilityData: PropTypes.object,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  data: PropTypes.object,
}

export default FloorForm
