import { Cloudinary } from 'cloudinary-core'

export default new class extends Cloudinary {
  constructor() {
    super({ cloud_name: 'dn4aewdax' })
  }
  async upload(file: string, { url, api_key, timestamp, signature }: { url: string; api_key: string; timestamp: string; signature: string }) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('api_key', api_key)
    formData.append('timestamp', timestamp)
    formData.append('signature', signature)

    return fetch(url, {
      method: 'POST',
      body: formData,
    })
  }
}
