import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { withRouter, matchPath } from 'react-router-dom'
import withProps from 'recompact/withProps'
import get from 'lodash-es/get'

import ApolloClient from 'client'
import { GET_FACILITY_QUERY } from '../queries'

function getParams(props) {
  const { id, floorId = null, versionId = null } = get(props, 'match.params')
  const _id = id || get(props, 'facility.id') || get(props, 'id')
  const _floorId =
    floorId || get(props, 'facility.floor.id') || get(props, 'floorId') || null
  const _versionId =
    versionId ||
    get(props, 'facility.floor.version.id') ||
    get(props, 'versionId') ||
    null
  return { id: _id, floorId: _floorId, versionId: _versionId }
}

export default compose(
  withRouter,
  withProps(props => {
    const match = matchPath(props.location.pathname, {
      path: '/facility/:id/area/:floorId/version/:versionId',
    })
    const fallbackMatch = matchPath(props.location.pathname, {
      path: '/facility/:id',
    })
    return get(match, 'params') || get(fallbackMatch, 'params')
  }),
  graphql(GET_FACILITY_QUERY, {
    options: props => {
      const { id, floorId, versionId } = getParams(props)
      return {
        variables: {
          id,
          floorId,
          versionId,
        },
      }
    },
    props: ({ data, ownProps }) => {
      let facility = data.facility
      if (!facility) {
        const { id, floorId, versionId } = getParams(ownProps)
        facility = ApolloClient.readQuery({
          query: GET_FACILITY_QUERY,
          variables: {
            id,
            floorId,
            versionId,
          },
        })
      }

      return {
        loading: get(data, 'loading') || get(ownProps, 'loading'),
        facility,
      }
    },
  })
)
