import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from './styled/Button'

const PRESS_DOWN_DELAY = 300
class TemperatureButton extends Component {
  static propTypes = {
    onPress: PropTypes.func,
    direction: PropTypes.string,
    disabled: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.pressDownDelay = PRESS_DOWN_DELAY
    this.pressDownTimeout = 0
  }

  handlePress = () => {
    if (this.props.disabled) {
      return
    }

    this.props.onPress()
    this.pressDownTimeout = setTimeout(this.handlePress, this.pressDownDelay)
    this.pressDownDelay = this.pressDownDelay / 2
  }

  handleMouseDown = () => {
    if (this.props.disabled) {
      return
    }

    this.handlePress()
  }

  handleMouseUp = () => {
    if (this.props.disabled) {
      return
    }

    clearTimeout(this.pressDownTimeout)
    this.pressDownDelay = PRESS_DOWN_DELAY
  }

  handleClick = event => {
    event.preventDefault()
  }

  render() {
    const { direction, disabled } = this.props
    return (
      <Button
        size="s"
        paddingLeft="0"
        paddingRight="0"
        noBorder
        icon={`arrow${direction}`}
        onMouseOut={this.handleMouseUp}
        onPointerDown={this.handleMouseDown}
        onPointerUp={this.handleMouseUp}
        onClick={this.handleClick}
        disabled={disabled}
      />
    )
  }
}

export default /** @type any */(TemperatureButton)
