import { graphql } from '~/gql'

const FLOOR_FRAGMENT = graphql(`
  fragment FloorFragment on Floor {
    id
    name
    createdAt
    updatedAt
  }
`)

export default FLOOR_FRAGMENT
