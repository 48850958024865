import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Label = styled.h3`
  border-bottom: ${props => (props.color ? '2px solid ' + props.color : null)};
  font-size: ${theme('fonts.sizes.l')};
  font-weight: ${theme('fonts.weights.semiBold')};
  text-align: center;

  small {
    margin-left: ${theme('spacing.xs')};
  }
`

export default Label
