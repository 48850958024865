import React from 'react'
import { func, string } from 'prop-types'

import Container from './styled/Container'
import Text from './styled/Text'
import ViewFilterDropdown from './ViewFilterDropdown'

const Header = ({ handleFilterChange, filterType }) => (
  <Container>
    <Text>Tap to add to your layout</Text>
    <ViewFilterDropdown
      handleFilterChange={handleFilterChange}
      filterType={filterType}
    />
  </Container>
)

Header.propTypes = {
  handleFilterChange: func,
  filterType: string,
}

export default Header
