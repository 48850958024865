import styled from 'styled-components'

import { breakpoints, theme } from 'lib/styleUtils'

import Icon from 'components/UIKit/Icon'

const StyledIcon = styled(Icon)`
  display: none;
  margin-right: ${theme('spacing.xs')};

  ${breakpoints.m`
    display: inline-flex;
  `};
`

export default StyledIcon
