import styled from 'styled-components'

import theme, { Spacing } from 'config/theme'

const flexProps = (props: FlexProps) => {
  let styles = ''

  if (props.alignItems) {
    styles += `align-items: ${props.alignItems};`
  }
  if (props.flexDirection) {
    styles += `flex-direction: ${props.flexDirection};`
  }
  if (props.flexGrow) {
    styles += `flex-grow: ${props.flexGrow};`
  }
  if (props.flexShrink) {
    styles += `flex-shrink: ${props.flexShrink};`
  }
  if (props.flexWrap) {
    styles += `flex-wrap: ${props.flexWrap};`
  }
  if (props.justifyContent) {
    styles += `justify-content: ${props.justifyContent};`
  }

  if (props.gap) {
    styles += `gap: ${props.gap}em;`
  }

  return styles
}

const spacing = (props: FlexProps) => {
  if (props.spacingBottom) {
    return `margin-bottom: ${theme.spacing[props.spacingBottom]};`
  }

  if (props.spacingRight && !props.lastChildFormatOff) {
    return `margin-right: ${theme.spacing[props.spacingRight]};`
  }
}

const marginLeft = ({ marginLeft }: FlexProps) => {
  if (marginLeft) {
    return `margin-left: ${marginLeft};`
  }
}
const marginBottom = ({ marginBottom }: FlexProps) => {
  if (marginBottom) {
    return `margin-bottom: ${theme.spacing[marginBottom]};`
  }
}

const positionRelative = ({ positionRelative }: FlexProps) => {
  if (positionRelative) {
    return 'position: relative;'
  }
}

const Container = styled.div<FlexProps>`
  display: flex;

  ${flexProps};
  ${marginLeft};
  ${marginBottom};
  ${positionRelative};

  > *:not(:last-child) {
    ${spacing};
  }
`

type FlexProps = {
  alignItems?: string
  flexDirection?: string
  flexGrow?: string
  flexShrink?: string
  flexWrap?: string
  justifyContent?: string
  lastChildFormatOff?: boolean
  marginBottom?: Spacing
  marginLeft?: string
  positionRelative?: boolean
  spacingBottom?: Spacing
  spacingRight?: Spacing
  gap?: string
}

export default Container
