import styled from 'styled-components'

const Container = styled.tr`
  vertical-align: middle;

  &:not(:last-child) {
    ${props =>
      props.bordered &&
      `border-bottom: 1px solid ${props.theme.colors.light.subdued};`};
  }

  ${props =>
    props.disabled &&
    `background-color: ${props.theme.colors.light.extraLight};`};
`

export default Container
