import { graphql } from '~/gql'

const ALL_FACILITY_TEMPLATES_QUERY = graphql(`
  query AllFacilityTemplates(
    $orderBy: String
    $filter: TemplateFilter
    $limit: Int
    $page: Int
  ) {
    allFacilityTemplates(
      orderBy: $orderBy
      filter: $filter
      limit: $limit
      page: $page
    ) {
      templates {
        ...FacilityTemplateFragment
      }
      count
    }
  }
`)

export default ALL_FACILITY_TEMPLATES_QUERY
