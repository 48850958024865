import React from 'react'
import PropTypes from 'prop-types'

import theme from 'config/theme'

import A from '../A'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

const getLinkProperties = url =>
  url !== ''
    ? { href: url, target: '_blank' }
    : { href: '', onClick: event => event.preventDefault() }

const InfoTooltip = ({
  title,
  iconSize,
  color = theme.colors.light.subdued,
  url = '',
}) => (
  <Tooltip content={title}>
    <A noBorder {...getLinkProperties(url)}>
      <Icon name="help" color={color} size={iconSize} />
    </A>
  </Tooltip>
)

InfoTooltip.propTypes = {
  color: PropTypes.string,
  iconSize: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}

export default InfoTooltip
