import React from 'react'
import PropTypes from 'prop-types'
import Nav from './styled/Nav'
import NavItem from './styled/NavItem'
import NavLink from './styled/NavLink'
import SubNav from './styled/SubNav'
import SubLink from './styled/SubLink'
import { URIFragmentGenerator } from './utils/URIFragment'

const Navigation = props => (
  <Nav>
    {props.data.map((item, index) => (
      <NavItem key={index}>
        <NavLink
          onClick={event => props.updateActiveIndex(index)}
          to={`#${URIFragmentGenerator(item.title)}`}
          active={index === props.activeIndex}
          // $active={index === props.activeIndex}
          /* 
              FIXME: update styled-components to 5.1.0>= 
              in order to use $ transient props
              */
        >
          {item.title}
        </NavLink>
        {index === props.activeIndex && (
          <SubNav>
            {item.articles.map((i, j) => (
              <SubLink key={j} content={i.header} />
            ))}
          </SubNav>
        )}
      </NavItem>
    ))}
  </Nav>
)

Navigation.propTypes = {
  data: PropTypes.array.isRequired,
  activeIndex: PropTypes.number,
  updateActiveIndex: PropTypes.func,
}

export default Navigation
