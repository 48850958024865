import { graphql } from '~/gql'

const GET_CONVERTED_DATA_QUERY = graphql(`
  query GetConvertedData($facilityId: ID!, $format: String, $floorId: ID) {
    convertedData: convert(
      facilityId: $facilityId
      format: $format
      floorId: $floorId
    )
  }
`)

export default GET_CONVERTED_DATA_QUERY
