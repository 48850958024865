import React from 'react'
import { useAppSelector } from "~/store/hooks";
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from 'config/theme'

import { getSuggestedImageData } from 'lib/cfd'
import VariantText from 'components/UIKit/VariantText'
import Select from 'components/UIKit/Select'
import { useCFDImagesFlat } from 'components/Modals/CFDImagesModal/hooks/useCFDImages'
import Loader from 'components/UIKit/Loader'
import { Alert } from 'components/PerformanceMetrics/subcomponents/Alert'
import { SIMULATION_TYPES } from 'config/cfd'
import { Stack } from 'components/UIKit/Stack'

// TODO: Add heights for metric
const isSuggested = (image, type) => {
  switch (type) {
    case 'cooling':
      return image.includes('Fine') || image.includes('_z43') || image.includes('_z67')
    case 'destrat':
      return image.includes('destrat')
    case 'heating':
      return image.includes('T_z43') || image.includes('T_z67') || image.includes('intensity')
    default:
      return true
  }
}

const isRelevantImageGroup = type => ({ goal }) =>
  type === 'heating'
    ? goal === SIMULATION_TYPES.radiantHeat || goal === SIMULATION_TYPES.unitHeating
    : goal === type

const IMAGE_PER_PAGE_OPTIONS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 4, label: '4' },
]

const ToggleContainer = styled.div`
  outline: 2px solid transparent;
  position: relative;
  transition: all 0.2s ease-in-out;
  padding: 0.25em;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ isActive }) =>
    isActive
      ? `outline: 2px solid ${theme.colors.light.primary};
        &::before {
          content: attr(data-selected-index);
          position: absolute;
          top: 0.25em;
          left: 0.25em;
          width: 25px;
          aspect-ratio: 1/1;
          text-align: center;
          font-weight: bold;
          background-color: ${theme.colors.dark.primary};
        }`
      : ''}

  &:hover {
    cursor: pointer;
    outline: 2px solid ${theme.colors.dark.primary};
    background-color: ${theme.colors.light.extraLight};
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: self-start;
  gap: 1em;
`

const Thumbnail = styled.img`
  object-fit: contain;
  background-color: ${theme.colors.light.subdued};
  aspect-ratio: 4/3;
`

const mapImages = (selectedImages, distanceUnits) => ({ id, url }) => {
  const displayIndex = selectedImages.indexOf(id) + 1
  const isActive = displayIndex !== 0
  const { description, title } = getSuggestedImageData(distanceUnits, url)
  return { id, url, isActive, displayIndex, description, title }
}

const TypedImages = props => {
  const { setImages, selectedImages, type, imagesPerPage, setImagesPerPage } = props
  const { data, loading, error } = useCFDImagesFlat()
  const distanceUnits = useAppSelector(state => state.units.DISTANCE)

  if (loading) {
    return <Loader centered label="Fetching CFD Images..." />
  } else if (error || !data) {
    console.error(error ?? new Error(`No images found for CFD type ${type}`))
    return <Alert label="Failed to get CFD Images." />
  }

  const relevantImages = data?.filter(isRelevantImageGroup(type))
  const imagesWithActiveDetails = relevantImages?.map(({ images }) =>
    images.map(mapImages(selectedImages, distanceUnits))
  )
  const images = imagesWithActiveDetails?.flat()
  const suggestedImages = images?.filter(img => isSuggested(img.id, type))

  const handleClick = id => {
    const allImages = new Set(images.map(({ id }) => id))
    const validSelectedImages = selectedImages.filter(imgId => allImages.has(imgId))
    const selectedImagesSet = new Set(validSelectedImages)
    const isRemoved = selectedImagesSet.delete(id)
    if (!isRemoved) selectedImagesSet.add(id)
    setImages(Array.from(selectedImagesSet.values()))
  }

  return (
    <Stack alignItems="start" spacing={1}>
      <Select
        inline
        label="Images Per Page"
        options={IMAGE_PER_PAGE_OPTIONS}
        value={imagesPerPage}
        onChange={({ target }) => setImagesPerPage(parseInt(target.value))}
      />
      <VariantText style={{ marginTop: 10 }} bold size="l">
        Suggested Images
      </VariantText>
      <Grid>
        {!suggestedImages || !suggestedImages.length ? (
          <Alert severity="warn" label="No suggested images" />
        ) : (
          suggestedImages.map(({ id, url, description, displayIndex, isActive, title }) => {
            return (
              <ToggleContainer
                data-selected-index={displayIndex}
                key={id}
                isActive={isActive}
                onClick={() => handleClick(id)}
              >
                <Thumbnail alt="cfd" src={url} />
                <VariantText align="center" bold size="s">
                  {title}
                </VariantText>
                <VariantText align="center" color="light" size="s">
                  {description}
                </VariantText>
              </ToggleContainer>
            )
          })
        )}
      </Grid>
      <hr style={{ width: '100%' }} />
      <VariantText style={{ marginTop: 10 }} bold size="l">
        All Images
      </VariantText>
      <Grid>
        {!images || !images.length ? (
          <Alert severity="warn" label="No CFD Images" />
        ) : (
          images.map(({ id, url, description, displayIndex, isActive, title }) => {
            return (
              <ToggleContainer
                data-selected-index={displayIndex}
                key={id}
                isActive={isActive}
                onClick={() => handleClick(id)}
              >
                <Thumbnail alt="cfd" src={url} />
                <VariantText bold size="s">
                  {title}
                </VariantText>
                <VariantText color="light" size="s">
                  {description}
                </VariantText>
              </ToggleContainer>
            )
          })
        )}
      </Grid>
    </Stack>
  )
}

TypedImages.propTypes = {
  setImages: PropTypes.func,
  selectedImages: PropTypes.array,
  type: PropTypes.string,
  imagesPerPage: PropTypes.number,
  setImagesPerPage: PropTypes.func,
}

export default TypedImages
