import Color from 'color'
import isEmpty from 'lodash-es/isEmpty'

import Util from './util'
import Units from './units'
import Primitives from './primitives'
import ArrowRenderer from './arrowRenderer'
import FloatingElementManager from './floatingElementManager'

import { updateComfortZone } from 'store/objects'
import { objectIsSelected } from 'store/selectedObjects/selectors'
import { getCeilings, getRoofs } from 'store/objects/selectors'

import getRenderOrder from 'config/canvasRenderOrder'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import OBJECT_TYPES from 'config/objectTypes'
import theme from 'config/theme'

import store from 'store'
import ComfortZoneUtil from './comfortZoneUtil'
import { get } from 'lodash-es'
import CLICK_PRIORITY from 'config/clickPriority'

import * as THREE from 'three'

const COLOR_DARKENER = 0.2
const COMFORT_ZONE_OPACITY = 0.2
const EDGE_OPACITY = 0.6
const EDGE_WIDTH = 2
const GAP_SIZE = 1
const DASH_SIZE = 1

class ComfortZone {
  constructor(model, units) {
    this.layerKey = LAYER_KEYS.COMFORT_ZONES
    this.className = CLASS_NAMES.COMFORT_ZONE
    this.clickPriority = CLICK_PRIORITY[this.layerKey]
    this.units = units || 'INCHES'
    this.id = model.id ? model.id : Util.guid()
    this.centerLines = model.centerLines || []
    this.lastDescriptionPos = new THREE.Vector3()
    const p = model.position || { x: 0, y: 0, z: 0 }
    this.position = new THREE.Vector3().copy({
      x: Units.inchesToNative(p.x),
      y: Units.inchesToNative(p.y),
      z: Units.inchesToNative(p.z),
    })

    this.name = model.name
    this.displayName = model.name
    this.metrics = model.metrics
    this.selectedHeight = model.selectedHeight
    this.selectedPerformanceHeight = model.selectedPerformanceHeight
    this.indoorSummerTemp = model.indoorSummerTemp
    this.indoorWinterTemp = model.indorWinterTemp
    this.indoorHumidity = model.indoorHumidity
    this.indoorWinterHumidity = model.indoorWinterHumidity
    this.primaryUse = model.primaryUse
    this.labelPos = model.labelPos

    // Get the height of the ceiling we're drawing inside of
    this.height = 48
    const ceilings = getCeilings()
    const ceiling = Object.values(ceilings).find(ceiling => {
      return Util.isPointInPolygon(this.position, ceiling.perimeterPoints)
    })

    if (ceiling && ceiling.enabled) {
      this.height = ceiling.height
    } else {
      const roofs = getRoofs()
      const roof = Object.values(roofs).find(ceiling => {
        return Util.isPointInPolygon(this.position, ceiling.perimeterPoints)
      })

      if (roof) {
        this.height = roof.height - 12 // set zone height 1 ft below roof
      }
    }

    this.positions = model.positions.map(position => ({
      x: Units.inchesToNative(position.x),
      y: Units.inchesToNative(position.y),
      z: Units.inchesToNative(position.z),
    }))
    this.color = model.color
    const nativeHeight = Units.inchesToNative(this.height)

    // Create the zone object
    this.createComfortZone(this.positions, nativeHeight, this.color)

    // Set the position on the zone
    this.obj3d.position.copy(
      new THREE.Vector3(this.position.x, this.position.y, 0)
    )

    // Used by Interactifier
    this.obj3d.wrapperId = this.id
    this.selectable = true
    this.draggable = false
    this.multiDraggable = true

    this.updateSnapBox()

    if (objectIsSelected(this.id)) {
      this.select()
    }

    this.reinitializeKeypressHandler = true

    ArrowRenderer.subscribe(this)
  }

  createComfortZone(positions, height, color) {
    if (this.borderObj) this.obj3d.remove(this.borderObj)
    if (this.dashObj) this.obj3d.remove(this.dashObj)
    const threeColor = new Color(color)
    const rgbColor = threeColor.darken(COLOR_DARKENER).rgbNumber()

    if (!this.obj3d)
      this.obj3d = Primitives.getCustomMesh(this.positions, height)

    this.obj3d.material.transparent = true
    this.obj3d.material.opacity = COMFORT_ZONE_OPACITY
    this.obj3d.material.color.setHex(rgbColor)
    const position = Util.polygonCentroid(this.positions)
    this.obj3d.geometry.translate(-position.x, -position.y, 0)
    this.obj3d.userData.objectType = OBJECT_TYPES.COMFORT_ZONE
    this.obj3d.renderOrder = getRenderOrder('comfortZone')
    this.obj3d.position.copy(position)
    this.createCustomSelectionBox(this.positions, height)

    const isSelected = objectIsSelected(this.id)
    const is3dViewMode = store.getState().camera.is3D

    const isValidResizeState = isSelected && !is3dViewMode

    const dashGeometry = new THREE.EdgesGeometry(this.obj3d.geometry)
    const dashMaterial = new THREE.LineDashedMaterial({
      color: rgbColor,
      dashSize: DASH_SIZE,
      gapSize: GAP_SIZE,
    })
    const dashObj = new THREE.LineSegments(dashGeometry, dashMaterial)
    dashObj.computeLineDistances()
    this.dashObj = dashObj

    this.obj3d.add(this.dashObj)

    const edgeMaterial = Primitives.getOutlineMaterial(
      positions,
      rgbColor,
      EDGE_WIDTH,
      0
    )

    this.borderObj = Primitives.getRegionMesh(positions, rgbColor, edgeMaterial)
    this.borderObj.position.copy(
      new THREE.Vector3(-this.position.x, -this.position.y, height + 0.001)
    )
    this.borderObj.renderOrder = getRenderOrder('comfortZone') + 1
    this.obj3d.add(this.borderObj)

    this.updateSnapBox()

    if (this.resizeHandles) this.obj3d.remove(this.resizeHandles)
    if (isValidResizeState) {
      const isRectangle = ComfortZoneUtil.isRectangle(this.positions)
      this.resizeHandles = isRectangle
        ? ComfortZoneUtil.buildResizeHandles(this.snapBox, this.id)
        : ComfortZoneUtil.buildCustomHandles(this.positions, this.id)
      this.obj3d.add(this.resizeHandles)
    }

    this.showNameTag()
  }

  updateLabelPosition() {
    this.obj3d.updateMatrixWorld()
    const bbox = new THREE.Box3().setFromObject(this.obj3d)
    const topLeft = new THREE.Vector3(bbox.min.x, bbox.max.y, 0)

    let shortestDistance
    const closestPosition = new THREE.Vector3()
    this.positions.forEach(pos => {
      pos.z = 0
      const distance = topLeft.distanceTo(pos)
      if (!shortestDistance || shortestDistance > distance) {
        shortestDistance = distance
        closestPosition.copy(pos)
      }
    })

    this.labelPos = closestPosition
  }

  showNameTag() {
    const yOffset = this.positions.length === 5 ? 1 : 0
    if (!isEmpty(this.displayName)) {
      const nameTag = FloatingElementManager.showFloatingElement(
        this.id,
        this.obj3d,
        {},
        'label',
        null,
        {
          offset: { x: 0, y: yOffset },
        }
      )

      nameTag.innerHTML = `${this.displayName}`
      nameTag.style.backgroundColor = this.name
        ? theme.colors.dark.bg
        : theme.colors.dark.light
      nameTag.style.color = theme.colors.dark.fg
      nameTag.style.borderRadius = '0px'
      nameTag.style.padding = '1px 5px'
    } else {
      this.hideNameTag()
    }
  }

  hideNameTag() {
    FloatingElementManager.hideFloatingElement(this.id)
  }

  updateSnapBox() {
    const box = this.selectionBox.clone()
    box.position.copy(this.obj3d.position)
    this.snapBox = new THREE.Box3().setFromObject(box)
  }

  visibilityChanged(visible) {
    if (this.obj3d) this.obj3d.visible = visible

    if (!visible) {
      this.hideNameTag()
    } else {
      this.showNameTag()
    }
  }

  select(draggable) {
    const isLocked = store.getState().layers.layers[LAYER_KEYS.COMFORT_ZONES]
      .locked
    this.draggable = !isLocked
    if (draggable === false) {
      this.draggable = false
    }
    this.borderObj.material.uniforms.outlineOpacity.value = EDGE_OPACITY
  }

  updatePositions({ x: deltaX = 0, y: deltaY = 0, z: deltaZ = 0 }) {
    this.positions = this.positions.map(pos => ({
      x: pos.x + deltaX,
      y: pos.y + deltaY,
      z: pos.z + deltaZ,
    }))
    this.position = Util.polygonCentroid(this.positions)
    this.metrics = {}
  }

  move(translationVec) {
    this.obj3d.translateX(translationVec.x)
    this.obj3d.translateY(translationVec.y)
    this.updatePositions(translationVec)
    this.updateLabelPosition()
  }

  drop(_, __, saveModel = true) {
    this.isResizing = false
    this.isDragging = false
    if (saveModel) {
      store.dispatch(updateComfortZone({ comfortZone: this.toModel() }))
    }
  }

  static createModel(positions, color) {
    const position = Util.polygonCentroid(positions)

    return {
      positions: positions.map(p => ({
        x: Units.nativeToInches(p.x),
        y: Units.nativeToInches(p.y),
        z: Units.nativeToInches(p.z),
      })),
      position: {
        x: Units.nativeToInches(position.x),
        y: Units.nativeToInches(position.y),
        z: Units.nativeToInches(position.z),
      },
      color,
    }
  }

  toModel() {
    this.updateLabelPosition()

    return {
      id: this.id,
      position: {
        x: Units.nativeToInches(this.position.x),
        y: Units.nativeToInches(this.position.y),
        z: Units.nativeToInches(this.position.z),
      },
      positions: this.positions.map(position => ({
        x: Units.nativeToInches(position.x),
        y: Units.nativeToInches(position.y),
        z: Units.nativeToInches(position.z),
      })),
      indoorSummerTemp: this.indoorSummerTemp,
      indoorWinterTemp: this.indoorWinterTemp,
      indoorHumidity: this.indoorHumidity,
      indoorWinterHumidity: this.indoorWinterHumidity,
      primaryUse: this.primaryUse,
      selectedHeight: this.selectedHeight,
      selectedPerformanceHeight: this.selectedPerformanceHeight,
      color: this.color,
      name: this.name,
      metrics: this.metrics,
      labelPos: this.labelPos,
      layerKey: this.layerKey,
    }
  }

  getSnappableEdgePoints() {
    return []
  }

  drag({
    dragDelta,
    newPosition,
    projectedMousePos,
    lastProjectedMousePos,
    isShiftDown,
  }) {
    const isLocked = store.getState().layers.layers[LAYER_KEYS.COMFORT_ZONES]
      .locked
    if (isLocked) {
      return
    }

    const pos = projectedMousePos || newPosition
    if (!this.isResizing) {
      this.resizeHandleUnderMouse = ComfortZoneUtil.getResizeHandleOverPosition(
        pos,
        this.id
      )
    }

    const isValidResizeState =
      !this.isDragging && this.resizeHandleUnderMouse && dragDelta
    const isValidDragState = !this.isResizing

    if (isValidResizeState) {
      this.handleResizeDrag(
        projectedMousePos,
        lastProjectedMousePos,
        newPosition,
        isShiftDown,
        dragDelta
      )
    } else if (isValidDragState) {
      this.isDragging = true
      this.move(dragDelta)
      this.updateSnapBox()
    }
  }

  snap(snapDelta) {
    return
  }

  createCustomSelectionBox(positions, height) {
    if (this.selectionBox) this.obj3d.remove(this.selectionBox)
    if (this.resizeHandles) this.obj3d.remove(this.resizeHandles)

    this.selectionBox = Primitives.getCustomMesh(positions, height)
    if (get(this.selectionBox, 'material.color')) {
      this.selectionBox.material.color.set(this.color)
    }
    this.selectionBox.material.transparent = true
    this.selectionBox.userData.objectType = OBJECT_TYPES.COMFORT_ZONE
    this.obj3d.userData.objectType = OBJECT_TYPES.COMFORT_ZONE
    this.selectionBox.wrapperId = this.id

    const position = Util.polygonCentroid(positions)
    this.selectionBox.geometry.translate(-position.x, -position.y, -position.z)
  }

  handleResizeDrag(
    mousePos,
    lastMousePos,
    newPosition,
    isShiftDown,
    dragDelta
  ) {
    this.isResizing = true
    const nativeHeight = Units.inchesToNative(this.height)
    this.obj3d.remove(this.dashObj)
    this.obj3d.material.opacity = 0

    // Get the unrotated mouse positions before and after the drag so
    // we can use them to determine the unrotated drag delta
    // NOTE: Comfort zone does not currently support rotation
    const unrotatedPoints = ComfortZoneUtil.getRotatedPositions(
      [lastMousePos, mousePos],
      this.obj3d.position.clone(),
      0
    )
    const mouseXChange = unrotatedPoints[1].x - unrotatedPoints[0].x
    const mouseYChange = unrotatedPoints[1].y - unrotatedPoints[0].y
    const unrotatedDragDelta = new THREE.Vector3(mouseXChange, mouseYChange, 0)
    const isRectangle = ComfortZoneUtil.isRectangle(this.positions)

    // Get new positions based on the unrotated drag delta
    this.positions = isRectangle
      ? ComfortZoneUtil.getResizedPositionsFromBox(
          this.snapBox,
          unrotatedDragDelta,
          this.positions,
          this.resizeHandleUnderMouse
        )
      : ComfortZoneUtil.getCustomResizedPositions(
          this.positions,
          unrotatedDragDelta,
          this.resizeHandleUnderMouse
        )

    this.createCustomSelectionBox(this.positions, nativeHeight, this.color)

    // Get the new center position for the obstruction
    const newCenterPos = Util.polygonCentroid(this.positions)
    this.obj3d.position.copy(newCenterPos)
    this.position = newCenterPos

    // Update resize handles
    if (this.resizeHandles) this.obj3d.remove(this.resizeHandles)
    this.resizeHandles = isRectangle
      ? ComfortZoneUtil.buildResizeHandles(this.snapBox, this.id)
      : ComfortZoneUtil.buildCustomHandles(this.positions, this.id)
    this.obj3d.add(this.resizeHandles)

    // update outline
    if (this.borderObj) this.obj3d.remove(this.borderObj)
    this.borderObj = Primitives.getRegionMesh(
      this.positions,
      this.color,
      Primitives.getOutlineMaterial(this.positions, this.color, EDGE_WIDTH, 0)
    )
    this.borderObj.position.copy(
      new THREE.Vector3(-newCenterPos.x, -newCenterPos.y, newCenterPos.z)
    )
    this.obj3d.add(this.borderObj)

    // Reselect zone while resizing so it looks selected still
    this.select()
  }

  destroy() {
    this.hideNameTag()
    return
  }
}

export default ComfortZone
