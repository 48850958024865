import styled from 'styled-components'

import { getZIndexOf } from 'lib/utils'

const Mask = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${getZIndexOf('panelMask')};
`

export default Mask
