import find from 'lodash-es/find'

type UserData = { id: string; roles?: ({ name?: string | undefined | null } | undefined | null)[] | undefined | null }

const currentUserIs = (roleName: string, user: UserData | null | undefined) => {
  if (!user) return false
  const match = find(user.roles, role => role?.name === roleName)
  return Boolean(match)
}

const currentUserIsCustomer = (user: UserData | null | undefined) => currentUserIs('Customer', user)

const currentUserIsAdmin = (user: UserData | null | undefined) => currentUserIs('Admin', user)

const currentUserIsAuthor = (facility: { author: { id: string } }, user: UserData) => {
  const authorId = facility.author.id
  const userId = user?.id
  return userId === authorId
}

const currentUserCanEdit = (_facility: { author: { id: string | undefined } | undefined }, _user: UserData | undefined, _shared: boolean) => {
  // return (
  //   (currentUserIsAuthor(facility, user) && !currentUserIsCustomer(user)) ||
  //   currentUserIsAdmin(user) ||
  //   shared
  // );
  return true
}

export {
  currentUserIs as default,
  currentUserIsCustomer,
  currentUserIsAdmin,
  currentUserIsAuthor,
  currentUserCanEdit,
}
