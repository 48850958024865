import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import cloudinary from 'config/cloudinary'

import Label from './styled/Label'

import Thumbnail from './Thumbnail'
import ImagesFieldContainer from '../styled/ImagesFieldContainer'
import ThumbnailGrid from '../styled/ThumbnailGrid'

import { LABEL_WIDTH, THUMBNAIL_SIZE } from '../MetadataSection'

const Images = ({ images, onDelete, url, history }) => {
  const getImageUrl = ({ cloudinaryId }) => {
    const cloudinaryImage = cloudinary.image(cloudinaryId, {
      format: 'png',
      secure: true,
      transformation: [
        {
          width: THUMBNAIL_SIZE,
          height: THUMBNAIL_SIZE,
          crop: 'fill',
        },
      ],
    })
    return cloudinaryImage.src
  }

  return (
    <ImagesFieldContainer>
      <Label inline labelWidth={`${LABEL_WIDTH}px`}>
        Images
      </Label>
      <ThumbnailGrid>
        {images &&
          images.map((image, index) => {
            const cloudinaryImage = getImageUrl({
              cloudinaryId: image.cloudinaryId,
            })
            const imageUrl = url + index

            return (
              cloudinaryImage && (
                <Thumbnail
                  key={image.cloudinaryId}
                  src={cloudinaryImage}
                  width={THUMBNAIL_SIZE}
                  height={THUMBNAIL_SIZE}
                  onClickHandler={() => history.push(imageUrl)}
                  onDelete={() => {
                    if (
                      confirm('Are you sure you want to remove this image?') // eslint-disable-line
                    ) {
                      onDelete(image.cloudinaryId)
                    }
                  }}
                />
              )
            )
          })}
      </ThumbnailGrid>
    </ImagesFieldContainer>
  )
}

Images.propTypes = {
  history: PropTypes.object,
  images: PropTypes.arrayOf(PropTypes.object),
  onDelete: PropTypes.func,
  url: PropTypes.string,
}

export default withRouter(Images)
