import { useCallback } from 'react'
import { MeasurementSystem } from '~/gql/graphql'
import { useAppSelector } from '~/store/hooks'
import { SYSTEMS, TYPES } from '~/store/units/constants'

type FormattingOptions = {
  round: number
}

const decimal = /^\d+(\.\d*)?$|^\.\d+$/
const feetAndInches = /^(\d*)'?\s*(\d*)"?$/
const meters = /^\d*\.?\d*m?$/
const INCHES_TO_METERS_CONVERSION = 0.0254
const ONE_FOOT_IN_INCHES = 12
const ONE_METER_IN_INCHES = 1 / INCHES_TO_METERS_CONVERSION

export type DistanceDimensions = ReturnType<typeof useDistanceDimensions>
export const useDistanceDimensions = (unitsOverride?: MeasurementSystem) => {
  const distanceUnits = useAppSelector(state => unitsOverride ?? state.units[TYPES.DISTANCE])
  const isImperial = distanceUnits === SYSTEMS.IMPERIAL

  const displayFormattedValue = useCallback(
    (inputInches: number, options: FormattingOptions = { round: 2 }): string => {
      if (isNaN(inputInches)) return ''
      if (isImperial) {
        const feet = Math.floor(inputInches / 12)
        const inches = Math.floor(inputInches % 12)
        return `${feet}' ${Math.floor(inches)}"`
      } else {
        const meters = inputInches * INCHES_TO_METERS_CONVERSION
        return new Intl.NumberFormat('en-US', {
          style: 'unit',
          unit: 'meter',
          unitDisplay: 'short',
          maximumFractionDigits: options.round,
        }).format(meters)
      }
    },
    [distanceUnits]
  )

  const parseUserInputToInches = useCallback(
    (input: string): number | null => {
      if (isImperial) {
        if (decimal.test(input.trim())) {
          const feet = parseFloat(input)
          return feet * 12
        }
        const match = feetAndInches.exec(input.trim())
        if (!match) return null
        const [, feet, inches] = match
        return parseInt(feet || "0") * 12 + parseInt(inches)
      } else {
        const noWhitespaceInput = input.replace(/\s/g, '')
        if (!meters.test(noWhitespaceInput)) return null
        return parseFloat(noWhitespaceInput) / INCHES_TO_METERS_CONVERSION
      }
    },
    [distanceUnits]
  )

  const oneDistanceUnit = isImperial ? ONE_FOOT_IN_INCHES : ONE_METER_IN_INCHES

  return { displayFormattedValue, parseUserInputToInches, oneDistanceUnit }
}
