import React from 'react'
import { func, object } from 'prop-types'
import get from 'lodash-es/get'

import Menu from '../Menu'

const items = [
  {
    key: 'Industrial',
    imageSrc:
      'https://awseb-e-bedvmwmhfm-stack-bigasss3-1u6fy939oaojf.s3.us-east-2.amazonaws.com/products/Thumbnails/Industrial-Fans/Basic-6/Basic6_10ft.png',
  },
  {
    key: 'Commercial',
    imageSrc:
      'https://awseb-e-bedvmwmhfm-stack-bigasss3-1u6fy939oaojf.s3.us-east-2.amazonaws.com/products/Thumbnails/Commercial-Fans/Isis/Isis_Silver.png',
  },
  {
    key: 'Residential',
    imageSrc:
      'https://awseb-e-bedvmwmhfm-stack-bigasss3-1u6fy939oaojf.s3.us-east-2.amazonaws.com/products/Thumbnails/Residential-Fans/Haiku/Haiku-Cocoa-Bamboo.png',
  },
]

function CatalogMenu({ product, handleSelect }) {
  const selected = items.find(item => item.key === product.catalog)
  return (
    <Menu
      items={items}
      handleSelect={handleSelect}
      value={product.catalog}
      selectedImg={get(selected, 'imageSrc')}
    />
  )
}

CatalogMenu.propTypes = {
  product: object,
  handleSelect: func,
}

export default CatalogMenu
