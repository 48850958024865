import React from 'react'
import PropTypes from 'prop-types'

import Line from './styled/Line'

const sizes = {
  height: '150',
  width: '300',
  stroke: '10',
}

const setLineWidth = (peak, side) => {
  let width = sizes.width

  if (peak === 'vertical' || side === 'left' || side === 'right') {
    width = sizes.height
  }

  return parseInt(width, 10) - parseInt(sizes.stroke, 10)
}

const RectangleIcon = ({ facilitySize, peak, side, offset }) => (
  <svg height={sizes.height} width={sizes.width}>
    <rect
      height={sizes.height}
      width={sizes.width}
      style={{
        fill: 'white',
        stroke: 'black',
        strokeWidth: sizes.stroke,
      }}
    />
    <Line
      height={parseInt(sizes.stroke, 10) / 2}
      width={setLineWidth(peak, side)}
      peak={peak}
      side={side}
      offset={offset}
      facilitySize={facilitySize}
    />
  </svg>
)

RectangleIcon.propTypes = {
  facilitySize: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
  peak: PropTypes.oneOf(['horizontal', 'vertical']),
  side: PropTypes.oneOf(['left', 'right', 'top', 'left']),
  offset: PropTypes.number,
}

export default RectangleIcon
