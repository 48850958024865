import styled from 'styled-components'

import { darken, theme } from 'lib/styleUtils'

import Icon from 'components/UIKit/Icon'

const StyledIcon = styled(Icon)`
  fill: ${props => darken(props.theme.colors.light.subdued, 0.4)};
  margin-right: ${theme('spacing.xs')};
`

export default StyledIcon
