import styled from 'styled-components'

const Container = styled.ol`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  > *:last-child::after {
    display: none;
  }
`

export default Container
