import type { Vector3Like } from "three"
import type { GridLine } from "./types"

// Updates the models for beams/columns by shifting left/right/up/down
export default function shiftGridLines({
  rotation,
  direction,
  models,
  spacing,
  startIndex,
  positionDiff,
}: Readonly<{
  rotation: number
  direction: Vector3Like
  models: readonly GridLine[]
  spacing: number
  startIndex: number
  positionDiff: Vector3Like
}>) {
  let shiftFunction = defaultShiftFunction
  let reverse = false
  if (startIndex !== 0 && startIndex + 1 < models.length) {
    // If horizontal and moving left OR vertical and moving up
    const isHorizontal = !!(+rotation.toFixed(6) === 0)
    if (
      (isHorizontal && positionDiff.x < 0) ||
      (!isHorizontal && positionDiff.y > 0)
    ) {
      // PUSHING
      if (direction.x > 0 || direction.y < 0) {
        shiftFunction = shiftPush
      } else if (direction.x < 0 || direction.y > 0) {
        shiftFunction = shiftPull
      }
    } else if (
      // If horizontal and moving right OR vertical and moving down
      (+rotation.toFixed(6) === 0 && positionDiff.x > 0) ||
      (+rotation.toFixed(6) === 1 && positionDiff.y < 0)
    ) {
      reverse = true
      if (direction.x > 0 || direction.y < 0) {
        // shiftFunction = shiftDownRightPull;
        shiftFunction = shiftPull
      } else if (direction.x < 0 || direction.y > 0) {
        // shiftFunction = shiftDownRightPush;
        shiftFunction = shiftPush
      }
    }
  }

  return shiftFunction({
    models,
    spacing,
    startIndex,
    positionDiff,
    reverse,
  })
}

type ShiftFunctionProps = Readonly<{
  models: readonly GridLine[]
  spacing: number
  startIndex: number
  positionDiff: Vector3Like
  reverse: boolean
}>

function defaultShiftFunction({
  models: initModels,
  positionDiff,
  startIndex,
}: ShiftFunctionProps) {
  const models = [...initModels]
  const model = models[startIndex]

  models[startIndex] = {
    ...model,
    position: {
      x: model.position.x + positionDiff.x,
      y: model.position.y + positionDiff.y,
      z: model.position.z,
    },
  }

  return models
}

function shiftPush({
  models: initModels,
  spacing,
  startIndex,
  positionDiff,
  reverse,
}: ShiftFunctionProps) {
  const models = [...initModels]
  if (reverse) {
    models.reverse()
    startIndex = models.length - 1 - startIndex
  }
  const firstIndex = startIndex
  const lastIndex = 0

  // Grab all the models to the left/above
  const modelsToUpdate = models.slice(lastIndex, firstIndex + 1)

  for (let idx = modelsToUpdate.length - 1; idx >= 0; --idx) {
    const model = modelsToUpdate[idx]
    let newPosition = {
      x: model.position.x + positionDiff.x,
      y: model.position.y + positionDiff.y,
      z: model.position.z,
    }

    // Last possible model to update
    if (idx === lastIndex) {
      // Index of the model to the left/above
      const prevIndex = idx + 1
      const prevModel = models[prevIndex]

      // Get x/y spacing between the current and previous models
      const xDiff = model.position.x - prevModel.position.x
      const yDiff = model.position.y - prevModel.position.y

      // Get actual spacing between the models
      const distance = Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2))
      // Don't update if moving will keep space between the models less than defined spacing
      if (+distance.toPrecision(6) < +spacing.toPrecision(6)) {
        break
        // Otherwise, space the two models by defined spacing relative to x/y amounts
        // We have to calculate the x/y spacing because the lines could be moving diagonally
      } else {
        const xRatio = xDiff / distance
        const yRatio = yDiff / distance
        const xSpacing =
          Math.pow(xRatio * Math.sqrt(spacing), 2) * Math.sign(xDiff)
        const ySpacing =
          Math.pow(yRatio * Math.sqrt(spacing), 2) * Math.sign(yDiff)
        newPosition = {
          x: prevModel.position.x + xSpacing,
          y: prevModel.position.y + ySpacing,
          z: model.position.z,
        }
      }
    }

    models[idx] = {
      ...model,
      position: newPosition,
    }
  }
  if (reverse) {
    models.reverse()
  }

  return models
}

function shiftPull({
  models: initModels,
  spacing,
  startIndex,
  positionDiff,
  reverse,
}: ShiftFunctionProps) {
  const models = [...initModels]
  if (reverse) {
    models.reverse()
    startIndex = models.length - 1 - startIndex
  }
  const firstIndex = startIndex
  const lastIndex = models.length - 1

  // Grab all the models to the right/below
  const modelsToUpdate = models.slice(firstIndex, lastIndex + 1)

  for (let idx = 0; idx < modelsToUpdate.length; ++idx) {
    const model = modelsToUpdate[idx]
    let newPosition = {
      x: model.position.x + positionDiff.x,
      y: model.position.y + positionDiff.y,
      z: model.position.z,
    }

    // Last possible model to update
    if (idx + firstIndex === lastIndex) {
      // Index of the model to the left/above
      const prevIndex = idx - 1 + firstIndex
      const prevModel = models[prevIndex]

      // Get x/y spacing between the current and previous models
      const xDiff = model.position.x - prevModel.position.x
      const yDiff = model.position.y - prevModel.position.y

      // Get actual spacing between the models
      const distance = Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2))
      // Don't update if moving will keep space between the models less than defined spacing
      if (+distance.toPrecision(6) < +spacing.toPrecision(6)) {
        break
        // Otherwise, space the two models by defined spacing relative to x/y amounts
        // We have to calculate the x/y spacing because the lines could be moving diagonally
      } else {
        const xRatio = xDiff / distance
        const yRatio = yDiff / distance
        const xSpacing =
          Math.pow(xRatio * Math.sqrt(spacing), 2) * Math.sign(xDiff)
        const ySpacing =
          Math.pow(yRatio * Math.sqrt(spacing), 2) * Math.sign(yDiff)
        newPosition = {
          x: prevModel.position.x + xSpacing,
          y: prevModel.position.y + ySpacing,
          z: model.position.z,
        }
      }
    }

    models[idx + firstIndex] = {
      ...model,
      position: newPosition,
    }
  }
  if (reverse) {
    models.reverse()
  }

  return models
}
