import React from 'react'
import PropTypes from 'prop-types'

import Space from 'components/UIKit/Space'

import Desc from './styled/Desc'
import Icon from './styled/Icon'
import Label from './styled/Label'
import Value from './styled/Value'

const Results = ({ results, state } = {}) => (
  <div>
    <Space bottom="base">
      <Label>
        <Icon name="obstruction" size="18" color="error" />
        Money Lost
      </Label>
      <Value>{results.loss}</Value>
    </Space>
    <Space bottom="base">
      <Label>
        <Icon name="checkCircle" size="18" color="success" />
        Savings
      </Label>
      <Value>{results.savings}</Value>
      <Desc>Per Year</Desc>
    </Space>
    <Space bottom="base">
      <Label>
        <Icon name="arrowDown" size="18" color="subdued" />
        Annual Loss Due to Heat
      </Label>
      <Value>{results.percentage}</Value>
    </Space>
    <Space bottom="base">
      <Label>
        <Icon name="arrowDown" size="18" color="subdued" />
        Productivity Loss Reduction
      </Label>
      <Value>{results.reduction}</Value>
    </Space>
  </div>
)

Results.propTypes = {
  results: PropTypes.object,
  state: PropTypes.string,
}

export default Results
