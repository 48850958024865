import { graphql } from '~/gql'

const UPDATE_FACILITY_TEMPERATURES_MUTATION = graphql(`
  mutation UpdateFacilityTemperature(
    $id: ID!
    $indoorSummerTemp: Int
    $indoorWinterTemp: Int
    $indoorHumidity: Float
    $indoorWinterHumidity: Float
  ) {
    updateFacilityTemperature(
      id: $id
      indoorSummerTemp: $indoorSummerTemp
      indoorWinterTemp: $indoorWinterTemp
      indoorHumidity: $indoorHumidity
      indoorWinterHumidity: $indoorWinterHumidity
    ) {
      ...FacilityFragment
    }
  }
`)

export default UPDATE_FACILITY_TEMPERATURES_MUTATION
