import React from 'react'
import { bool, func } from 'prop-types'

import Icon from 'components/UIKit/Icon'

import Container from './styled/Container'

const Trigger = ({ showDock, onClick }) => (
  <Container showDock={showDock} onClick={onClick}>
    <Icon name={showDock ? 'cross' : 'addToArtifact'} size="30px" />
  </Container>
)

Trigger.propTypes = {
  onClick: func.isRequired,
  showDock: bool,
}

export default Trigger
