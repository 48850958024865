import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash-es/omit'
import { useNetwork } from 'networkProvider'

import Container from './styled/Container'
import Label from './styled/Label'
import Input from './styled/Input'
import InputContainer from './styled/InputContainer'
import Loader from 'components/UIKit/Loader'

// NOTE: It would be better to not spread props on more than
// one component.
const Select = ({
  name,
  label,
  placeholder = 'Select...',
  options,
  inline,
  labelWidth,
  selected,
  disablePlaceholder = true,
  noBottom,
  loading,
  ...props
}) => {
  const network = useNetwork()

  return (
    <Container inline={inline}>
      {label && (
        <Label
          htmlFor={name}
          inline={inline}
          width={labelWidth}
          maxWidth={labelWidth}
        >
          {label}
        </Label>
      )}
      {loading ? (
        <Loader label="" />
      ) : (
        <InputContainer
          {...omit(props, ['onChange', 'data-testid', 'required'])}
          online={network.online}
        >
          <Input
            name={name}
            id={name}
            {...props}
            disabled={props.disabled || !network.online}
            online={network.online}
            required={props.required}
          >
            {placeholder && (
              <option value="" disabled={disablePlaceholder}>
                {placeholder}
              </option>
            )}
            {options.map((option, index) => (
              <option
                key={index}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            ))}
          </Input>
        </InputContainer>
      )}
    </Container>
  )
}

Select.propTypes = {
  disablePlaceholder: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string,
  labelWidth: PropTypes.string,
  name: PropTypes.string,
  noBottom: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  selected: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
}

export default /** @type any */(Select)
