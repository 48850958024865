export const STATES = [
  { name: 'Alaska', code: 'AK', noFan: 0.0, withFan: 0.01 },
  { name: 'Alabama', code: 'AL', noFan: 2.38, withFan: 0.91 },
  { name: 'Arkansas', code: 'AR', noFan: 2.82, withFan: 1.13 },
  { name: 'Arizona', code: 'AZ', noFan: 7.58, withFan: 4.49 },
  {
    name: 'North California',
    code: 'CA - Northern',
    noFan: 2.19,
    withFan: 1.01,
  },
  {
    name: 'South California',
    code: 'CA - Southern',
    noFan: 3.48,
    withFan: 1.68,
  },
  { name: 'Colorado', code: 'CO', noFan: 1.43, withFan: 0.6 },
  { name: 'Connecticut', code: 'CT', noFan: 0.98, withFan: 0.41 },
  { name: 'District of Columbia', code: 'DC', noFan: 1.18, withFan: 0.49 },
  { name: 'Delaware', code: 'DE', noFan: 1.18, withFan: 0.49 },
  { name: 'Florida', code: 'FL', noFan: 3.36, withFan: 1.23 },
  { name: 'Georgia', code: 'GA', noFan: 2.85, withFan: 1.17 },
  { name: 'Hawaii', code: 'HI', noFan: 3.54, withFan: 1.07 },
  { name: 'Iowa', code: 'IA', noFan: 1.49, withFan: 0.56 },
  { name: 'Idaho', code: 'ID', noFan: 1.38, withFan: 0.63 },
  { name: 'Illinois', code: 'IL', noFan: 1.59, withFan: 0.61 },
  { name: 'Indiana', code: 'IN', noFan: 1.35, withFan: 0.54 },
  { name: 'Kansas', code: 'KS', noFan: 2.71, withFan: 1.18 },
  { name: 'Kentucky', code: 'KY', noFan: 1.91, withFan: 0.76 },
  { name: 'Louisiana', code: 'LA', noFan: 3.13, withFan: 1.3 },
  { name: 'Massachusetts', code: 'MA', noFan: 0.39, withFan: 0.21 },
  { name: 'Maryland', code: 'MD', noFan: 1.33, withFan: 0.52 },
  { name: 'Maine', code: 'ME', noFan: 0.39, withFan: 0.2 },
  { name: 'Michigan', code: 'MI', noFan: 0.88, withFan: 0.33 },
  { name: 'Minnesota', code: 'MN', noFan: 0.93, withFan: 0.38 },
  { name: 'Missouri', code: 'MO', noFan: 1.97, withFan: 0.73 },
  { name: 'Mississippi', code: 'MS', noFan: 2.97, withFan: 1.16 },
  { name: 'Montana', code: 'MT', noFan: 0.63, withFan: 0.26 },
  { name: 'North Carolina', code: 'NC', noFan: 1.4, withFan: 0.56 },
  { name: 'North Dakota', code: 'ND', noFan: 0.89, withFan: 0.37 },
  { name: 'Nebraska', code: 'NE', noFan: 1.59, withFan: 0.68 },
  { name: 'New Hampshire', code: 'NH', noFan: 0.74, withFan: 0.3 },
  { name: 'New Jersey', code: 'NJ', noFan: 1.54, withFan: 0.62 },
  { name: 'New Mexico', code: 'NM', noFan: 1.82, withFan: 0.79 },
  { name: 'Nevada', code: 'NV', noFan: 1.72, withFan: 0.76 },
  { name: 'New York', code: 'NY', noFan: 0.69, withFan: 0.29 },
  { name: 'Ohio', code: 'OH', noFan: 0.84, withFan: 0.35 },
  { name: 'Oklahoma', code: 'OK', noFan: 3.0, withFan: 1.25 },
  { name: 'Oregon', code: 'OR', noFan: 0.65, withFan: 0.29 },
  { name: 'Pennsylvania', code: 'PA', noFan: 0.76, withFan: 0.34 },
  { name: 'Rhode Island', code: 'RI', noFan: 0.62, withFan: 0.28 },
  { name: 'South Carolina', code: 'SC', noFan: 2.66, withFan: 1.14 },
  { name: 'South Dakota', code: 'SD', noFan: 1.1, withFan: 0.48 },
  { name: 'Tennessee', code: 'TN', noFan: 2.33, withFan: 0.94 },
  { name: 'Texas', code: 'TX', noFan: 5.17, withFan: 2.36 },
  { name: 'Utah', code: 'UT', noFan: 1.46, withFan: 0.6 },
  { name: 'Virginia', code: 'VA', noFan: 2.0, withFan: 0.82 },
  { name: 'Vermont', code: 'VT', noFan: 0.65, withFan: 0.28 },
  { name: 'Washington', code: 'WA', noFan: 0.98, withFan: 0.38 },
  { name: 'Wisconsin', code: 'WI', noFan: 0.84, withFan: 0.34 },
  { name: 'West Virginia', code: 'WV', noFan: 1.26, withFan: 0.53 },
  { name: 'Wyoming', code: 'WY', noFan: 0.98, withFan: 0.38 },
]
