export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'

export const showAlert = payload => ({
  type: SHOW_ALERT,
  payload,
})

export const hideAlert = () => ({
  type: HIDE_ALERT,
})
