import { SYSTEMS } from '../constants'
import { round } from '../utils'

const NATIVE_TO_INCHES = 6 // 6" per native unit
const INCHES_TO_CENTIMETERS = 2.54

export function metricToImperial(data, options = {}) {
  return data / INCHES_TO_CENTIMETERS
}

export function metricToNative(data, options = {}) {
  return imperialToNative(metricToImperial(data))
}

export function imperialToMetric(data, options = {}) {
  return data * INCHES_TO_CENTIMETERS
}

export function imperialToNative(data, options = {}) {
  return data / NATIVE_TO_INCHES
}

export function nativeToMetric(data, options = {}) {
  return imperialToMetric(nativeToImperial(data))
}

export function nativeToImperial(data, options = {}) {
  return data * NATIVE_TO_INCHES
}

export function convertDistance({ value, system }, toSystem, options = {}) {
  let newValue = value
  if (system === SYSTEMS.METRIC && toSystem === SYSTEMS.IMPERIAL) {
    newValue = metricToImperial(value, options)
  } else if (system === SYSTEMS.METRIC && toSystem === SYSTEMS.NATIVE) {
    newValue = metricToNative(value, options)
  } else if (system === SYSTEMS.IMPERIAL && toSystem === SYSTEMS.METRIC) {
    newValue = imperialToMetric(value, options)
  } else if (system === SYSTEMS.IMPERIAL && toSystem === SYSTEMS.NATIVE) {
    newValue = imperialToNative(value, options)
  } else if (system === SYSTEMS.NATIVE && toSystem === SYSTEMS.METRIC) {
    newValue = nativeToMetric(value, options)
  } else if (system === SYSTEMS.NATIVE && toSystem === SYSTEMS.IMPERIAL) {
    newValue = nativeToImperial(value, options)
  }

  return round(newValue, options.round)
}
