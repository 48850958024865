import styled from 'styled-components'

const Bar = styled.div`
  margin-top: ${props => props.marginTop}px;

  transition: left 0.3s ease-out;

  @media (min-width: 960px) {
    left: -340px;
    width: 340px;
    padding: 40px 40px;
  }

  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #fff;
  -webkit-overflow-scrolling: touch;

  ${props => props.open && 'left: 0 !important;'}
`

export default Bar
