import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.div`
  color: ${theme('colors.light.fg')};
  margin-top: ${theme('spacing.base')};
  min-width: 180px;
  padding: 44px ${theme('spacing.l')} 0 ${theme('spacing.l')};
  position: relative;
  &:not(:last-child) {
    border-right: 1px solid ${theme('colors.light.subdued')};
  }
`

export default Container
