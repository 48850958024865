import { GroupProps } from '@react-three/fiber'

// TODO: after interactivity is completely migrated to R3F, remove "name" 
const TEN_THOUSAND_FEET = 20000

export const Grid = (props: GroupProps) => {
  const gridSize = TEN_THOUSAND_FEET

  const subgridDivisions = gridSize / 10
  const gridLineColor = 0xd8d8d8

  const gridDivisions = subgridDivisions / 5
  const subgridLineColor = 0xe6e6e6

  const horizontalRotation = Math.PI / 2
  return (
    <group {...props}>
      <gridHelper
        name="r3f-grid"
        rotation-x={horizontalRotation}
        args={[gridSize, subgridDivisions, subgridLineColor, subgridLineColor]}
      />
      <gridHelper
        name="r3f-grid"
        rotation-x={horizontalRotation}
        args={[gridSize, gridDivisions, gridLineColor, gridLineColor]}
      />
      <mesh name="r3f-grid" position-z={-1}>
        <planeGeometry args={[gridSize, gridSize]} />
        <meshBasicMaterial toneMapped={false} />
      </mesh>
    </group>
  )
}
