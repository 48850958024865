import { graphql } from '~/gql'

const DELETE_FLOOR_MUTATION = graphql(`
  mutation DeleteFloor($id: ID!) {
    deleteFloor(id: $id) {
      id
      facility {
        floor {
          id
          version {
            id
          }
        }
      }
    }
  }
`)

export default DELETE_FLOOR_MUTATION
