import React from 'react'
import { string, func } from 'prop-types'

import Flex, { FlexItem } from 'components/UIKit/Flex'
import H1 from 'components/UIKit/H1'
import DocTypeItem from './styled/DocTypeItem'
import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'

const SelectType = props => (
  <Flex flexDirection="column">
    <FlexItem>
      <Space bottom="base">
        <p>
          <strong>Select document type:</strong>
        </p>
      </Space>
    </FlexItem>
    <FlexItem>
      <Flex justifyContent="space-between">
        <FlexItem width="320px">
          <DocTypeItem
            href=""
            onClick={e => props.setDocType(e, 'SOLUTION')}
            active={props.docType === 'SOLUTION'}
          >
            <H1>Solution Summary</H1>
            <VariantText size="s">
              A customer-facing document with options to add fan schedule,
              comments, document explanation,
              <br />
              comfort zone metrics, and additional images.
            </VariantText>
          </DocTypeItem>
        </FlexItem>
        <FlexItem width="320px">
          <DocTypeItem
            href=""
            onClick={e => props.setDocType(e, 'INTERNAL')}
            active={props.docType === 'INTERNAL'}
          >
            <H1>Internal Summary</H1>
            <VariantText size="s">
              An internal document with option to add fan schedule, object
              inventory, and additional images.
            </VariantText>
          </DocTypeItem>
        </FlexItem>
      </Flex>
    </FlexItem>
  </Flex>
)

SelectType.propTypes = {
  setDocType: func,
  docType: string,
}
export default SelectType
