import React from 'react'
import { string, instanceOf } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'

import { SYSTEMS } from 'store/units/constants'
import { withUnits } from 'store/units/decorators'
import { Distance } from 'store/units/types'
import * as selectedObjectsSelectors from 'store/selectedObjects/selectors'

import InlineField from './styled/InlineField'
import StyledLabel from './styled/Label'

function HeightFromFloorIndicator({
  distance = new Distance({
    value: 0,
    system: SYSTEMS.NATIVE,
  }),
  distanceUnits,
}) {
  return (
    <InlineField>
      <StyledLabel>
        Height
        <br />
        From Floor:
      </StyledLabel>
      {distance.formattedValue(distanceUnits, { round: distanceUnits === 'METRIC' ? 2 : 0, })}
    </InlineField>
  )
}

HeightFromFloorIndicator.displayName = 'HeightFromFloorIndicator'

HeightFromFloorIndicator.propTypes = {
  distance: instanceOf(Distance),
  distanceUnits: string,
}

const mapStateToProps = state => ({
  distance: selectedObjectsSelectors.mostRecentSelectedObjectHeightFromFloor(
    state
  ),
})

export default compose(
  withUnits,
  appConnect(mapStateToProps)
)(HeightFromFloorIndicator)
