import React from 'react'
import styled from 'styled-components'

import Loader from 'components/UIKit/Loader'

const Container = styled.div`
  height: 75px;
`

export default function PopoverMenuLoader() {
  return (
    <Container>
      <Loader label="" iconSize={10} centered />
    </Container>
  )
}
