import { createGlobalStyle } from 'styled-components'

import { lighten, theme } from 'lib/styleUtils'
import { getZIndexOf } from 'lib/utils'

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background-color: #eee;
    color: ${theme('colors.light.fg')};
    font-family: ${theme('fonts.family.base')};
    font-size: ${theme('fonts.sizes.base')};
    line-height: ${theme('fonts.lineHeight.base')};
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    cursor: default;
  }

  ::-moz-selection {
    background: ${props => lighten(theme('colors.light.primary')(props), 0.6)};
  }

  ::selection {
    background: ${props => lighten(theme('colors.light.primary')(props), 0.6)};
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: ${theme('colors.light.subdued')};
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: ${theme('colors.light.subdued')};
  }

  :-ms-input-placeholder { /* IE 10+ */
    color: ${theme('colors.light.subdued')};
  }

  :-moz-placeholder { /* Firefox 18- */
    color: ${theme('colors.light.subdued')};
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: ${theme('colors.light.bg')};
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: ${theme('radius.base')};
  }

  canvas {
    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  #drawing-canvas {
    position: relative;
  }

  .css-canvas {
    pointer-events: none;
    position: absolute;
    top: 0;
  }

  .tippy-tooltip-content {
    font-size: ${theme('fonts.sizes.s')};
  }

  .floating-text-input {
    position: absolute;
    z-index: ${getZIndexOf('floatingElement')};
    min-width: 45px;
    max-width: 60px;
    border: 1px solid cornflowerblue;
    outline: none;
    text-align: center;
  }

  .floating-text-element {
    font-size: 12px;
    position: absolute;
    z-index: ${getZIndexOf('floatingElement')};
    outline: none;
    pointer-events: none;
    background-color: #FFFFFF;
    padding: 1px 2px;
    border-radius: 4px;
    opacity: 0.9;
  }

  .floating-text-element.metadata-trigger {
    align-items: center;
    background-color: ${theme('colors.light.bg')};
    border: 1px solid #B2B2B2;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 5px -2px;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    pointer-events: initial;
    width: 24px;
    z-index: ${getZIndexOf('metadata')};
  }
  .floating-text-element.metadata-trigger.open {
    background-color: ${theme('colors.light.primary')};
    box-shadow: ${theme('colors.light.primary')} 0px 0px 8px 3px;
    border-color: ${theme('colors.light.bg')};
  }

  .floating-text-element.metadata-popup {
    background-color: ${theme('colors.light.bg')};
    border: 1px solid ${theme('colors.light.subdued')};
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 16px -6px;
    font-size: ${theme('fonts.sizes.s')};
    padding: ${theme('spacing.xs')} ${theme('spacing.base')};
    pointer-events: initial;
    width: 220px;
    z-index: ${getZIndexOf('metadata')};
  }

  .metadata-title {
    font-weight: bold;
    font-size: ${theme('fonts.sizes.s')};
  }
  .metadata-notes {
    white-space: pre-wrap;
  }
  .metadata-images {
    margin-top: ${theme('spacing.base')};
  }
  .metadata-thumbnail-link {
    cursor: pointer;
    text-decoration: none;
  }
  .metadata-thumbnail {
    display: inline;
    margin-right: ${theme('spacing.xs')};
  }
  .single-resizer {
    border: 3px solid #eb5648 !important;
    filter: hue-rotate(64deg) saturate(9);
    z-index: 9999;
    -webkit-filter: hue-rotate(64deg) saturate(9);
  }
  .baf-tooltip.rc-tooltip {
    opacity: 1;
  }
  .baf-tooltip .rc-tooltip-inner {
    background-color: ${theme('colors.dark.subdued')};
    border: ${theme('colors.dark.subdued')};
  }
  .baf-tooltip.rc-tooltip-placement-top .rc-tooltip-arrow,
  .baf-tooltip.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .baf-tooltip.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: ${theme('colors.dark.subdued')};
  }
  .baf-tooltip.rc-tooltip-placement-right .rc-tooltip-arrow,
  .baf-tooltip.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  .baf-tooltip.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    border-right-color: ${theme('colors.dark.subdued')};
  }
  .baf-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .baf-tooltip.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .baf-tooltip.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: ${theme('colors.dark.subdued')};
  }
  .baf-tooltip.rc-tooltip-placement-left .rc-tooltip-arrow,
  .baf-tooltip.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  .baf-tooltip.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    border-left-color: ${theme('colors.dark.subdued')};
  }
`

export default GlobalStyles
