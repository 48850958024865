import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const EditTitleContainer = styled.div`
  align-items: center;
  display: flex;

  > *:not(:last-child) {
    margin-right: ${theme('spacing.s')};
  }
`

export default EditTitleContainer
