import React from 'react'
import PropTypes from 'prop-types'

import Container from './styled/Container'
import Input from './styled/Input'
import Label from './styled/Label'

const Range = ({
  name,
  onChange,
  options,
  label = '',
  value = '',
  max,
  min,
  step = 0.1,
  disabled,
  ...props
}) => (
  <Container {...props}>
    <Input
      disabled={disabled}
      type="range"
      name={name}
      id={name}
      options={options}
      value={value}
      step={step}
      min={min || 0}
      max={max || options.length - 1}
      onChange={onChange}
    />
    {label && (
      <Label htmlFor={name} disabled={disabled}>
        {label}
      </Label>
    )}
  </Container>
)

Range.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.string,
  step: PropTypes.number,
  disabled: PropTypes.bool,
}

export default /** @type any */(Range)
