import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Label = styled.strong`
  font-size: ${theme('fonts.sizes.s')};
  font-weight: ${theme('fonts.weights.bold')};
`

export default Label
