import Tool from './tool'
import store from 'store'
import Units from './units'
import { addRoofSection, deleteRoofSection } from 'store/objects'
import Util from './util'
import Primitives from './primitives'
import Region from './region'
import RoofToolUtil from './roofToolUtil'
import SnapQueries from './snapQueries'

import * as THREE from 'three'

class RoofSectionTool extends Tool {
  constructor() {
    super()

    this.name = 'ROOF_SECTION_TOOL'
    this.obj3d = new THREE.Object3D()
    this.cursor = this.createCursor()
    this.startedLine = false
    this.linePoints = []

    this.obj3d.add(this.cursor)
  }

  reset() {
    this.linePoints = []
    this.startedLine = false
    this.obj3d.remove(this.nextLineVisual)
  }

  createCursor() {
    return Primitives.getCrosshairCursorVisual(
      Units.inchesToNative(6),
      Units.inchesToNative(6),
      0.3,
      0x000000
    )
  }

  toolMoved(
    mousePos,
    snappedMousePos,
    sceneIntersectionPoint,
    objectUnderTool,
    objectUnderSnappedTool
  ) {
    this.cursor.position.set(snappedMousePos.x, snappedMousePos.y, 0)
    this.lastSnappedMousePos = snappedMousePos
    this.lastSceneIntersectionPoint = sceneIntersectionPoint

    if (this.startedLine) {
      this.updateNextLineVisual(this.linePoints[this.linePoints.length - 1])
    }
  }

  toolFinish(mousePos, snappedMousePos) {
    this.reset()
  }

  toolUp({
    mousePos,
    snappedMousePos,
    sceneIntersectionPoint,
    objectUnderTool,
    objectUnderSnappedTool,
  }) {
    const roofSection = RoofToolUtil.getRoofSectionUnderCursor(
      sceneIntersectionPoint
    )

    if (roofSection) {
      const lineCrossesRoof =
        this.startedLine &&
        Util.isPointOnPolygonPerimeter(
          snappedMousePos,
          roofSection.perimeterPoints
        )

      const lastPointAdded = new THREE.Vector3(
        snappedMousePos.x,
        snappedMousePos.y,
        roofSection.height
      )
      this.linePoints.push(lastPointAdded)
      this.updateNextLineVisual(lastPointAdded)

      if (!this.startedLine) {
        this.startSectionLine()
      }

      if (lineCrossesRoof) {
        this.finishSectionLine(this.linePoints)
      }
    }
  }

  startSectionLine() {
    this.startedLine = true
  }

  finishSectionLine(linePoints) {
    const roofSection = RoofToolUtil.getRoofSectionUnderCursor(
      this.lastSceneIntersectionPoint
    )
    const roofSectionModel = roofSection.toModel()
    const roofModel = store.getState().objects.present.roofs[roofSection.roofId]

    linePoints = linePoints.map(point =>
      Units.nativeToUnitsV(roofSection.units, point)
    )

    const position = roofModel.position
    const region = new Region(roofSectionModel.perimeterPoints)
    const subRegions = region.split(linePoints)

    if (!subRegions) {
      console.log(
        "Couldn't split roof on given section line. Perimeter points: ",
        roofSectionModel.perimeterPoints,
        '; section line:',
        linePoints
      )

      this.reset()
      return
    }

    const newSectionModel1 = {
      ...roofSectionModel,
      id: Util.guid(),
      position: position,
      perimeterPoints: subRegions[0].points.map(point => [point.x, point.y]),
    }
    const newSectionModel2 = {
      ...roofSectionModel,
      id: Util.guid(),
      position: position,
      perimeterPoints: subRegions[1].points.map(point => [point.x, point.y]),
    }

    store.dispatch(addRoofSection({ roofSection: newSectionModel1 }))
    store.dispatch(addRoofSection({ roofSection: newSectionModel2 }))
    store.dispatch(deleteRoofSection({ roofSection: roofSectionModel }))

    this.reset()
  }

  updateNextLineVisual(lastPointAdded) {
    this.obj3d.remove(this.nextLineVisual)

    this.nextLineVisual = new THREE.Object3D()

    const points = this.linePoints.slice()
    points.push(this.lastSnappedMousePos)

    for (let i = 0; i < points.length - 1; i += 1) {
      const p1 = points[i]
      const p2 = points[i + 1]
      const lineLength = p2
        .clone()
        .sub(p1)
        .length()
      if (lineLength > Units.inchesToNative(1)) {
        const tube = Primitives.getTubeMesh(p1, p2)
        this.nextLineVisual.add(tube)
      }
    }

    this.obj3d.add(this.nextLineVisual)
  }

  getSnapRegions(facility, draggedObject) {
    let snapRegions = []

    const cursorPoint = this.lastSceneIntersectionPoint
    const roofSection = cursorPoint
      ? RoofToolUtil.getRoofSectionUnderCursor(cursorPoint)
      : null

    if (roofSection) {
      snapRegions = snapRegions.concat(
        SnapQueries.getEdgeMidpointLinesForRoof(roofSection)
      )
      snapRegions = snapRegions.concat(
        SnapQueries.getPerimeterPointsForRoof(roofSection)
      )
    }

    return snapRegions
  }

  getArrowDescriptions() {}
}

export default RoofSectionTool
