import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ApolloProvider as GQLProvider,
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import apiEndpoint from 'config/apiEndpoint'
import { node } from 'prop-types'

import * as fragments from './fragments'
import * as queries from './queries'

import auth0Client from 'lib/Auth'

const customFetch = (_, options) => {
  const event = new CustomEvent('online')
  dispatchEvent(event)

  if (navigator.onLine) {
    return fetch(apiEndpoint, options)
      .then(res => {
        const apiEvent = new CustomEvent('apiStatus', {
          detail: { status: true },
        })
        dispatchEvent(apiEvent)

        return res
      })
      .catch(err => {
        const apiEvent = new CustomEvent('apiStatus', {
          detail: { status: false },
        })
        const isAbortedRequest = err.name === 'AbortError'
        !isAbortedRequest && dispatchEvent(apiEvent)

        throw err
      })
  }
}
const httpLink = new BatchHttpLink({ fetch: customFetch })
const authLink = setContext(context => {
  if (auth0Client.isTokenExpired(auth0Client.accessToken)) {
    return auth0Client
      .renewToken()
      .then(result => ({
        headers: {
          ...context.headers,
          authorization: `Bearer ${result.accessToken}` || null,
        },
      }))
      .catch(err => ({
        headers: {
          ...context.headers,
          authorization: null,
        },
      }))
  }
  return {
    headers: {
      ...context.headers,
      authorization: `Bearer ${auth0Client.accessToken}`,
    },
  }
})

function checkNetworkStatus() {
  const URL = '/status'
  let xhr = new XMLHttpRequest()
  return new Promise((resolve, reject) => {
    xhr.onload = () => {
      resolve(true)
    }
    xhr.onerror = () => {
      reject(false)
    }
    xhr.open('GET', URL, true)
    xhr.send()
  })
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  if (networkError) {
    if (networkError.statusCode === 401) {
      auth0Client.logout()
    } else if (networkError.message === 'Failed to fetch') {
      checkNetworkStatus()
        .then(() => {
          const event = new CustomEvent('online')
          const apiEvent = new CustomEvent('apiStatus', {
            detail: { status: false },
          })

          dispatchEvent(event)
          dispatchEvent(apiEvent)
        })
        .catch(() => {
          const event = new CustomEvent('forceOffline')
          dispatchEvent(event)
        })
    }
  }
})
const link = ApolloLink.from([errorLink, authLink, httpLink])

const cache = new InMemoryCache()

/** @type {ApolloClient<NormalizedCacheObject>} client */
const client = 'process' in globalThis && 'VITEST_WORKER_ID' in process.env ? new Proxy({}, { get() { throw new Error("using apollo client directly in test" )}}) : new ApolloClient({
  link,
  cache,
})

const ApolloProvider = ({ children }) => {
  return <GQLProvider client={client}>{children}</GQLProvider>
}

ApolloProvider.propTypes = {
  children: node,
}

export { client, queries, fragments, ApolloProvider, cache }
export default client
