import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.td`
  ${props => props.hover && `color: ${props.theme.colors.light.subdued};`}
  font-weight: ${theme('fonts.weights.bold')};
  cursor: pointer;
  padding: ${theme('spacing.base')};
  ${props => `border-bottom: 1px solid ${props.theme.colors.light.subdued}`};
  transition: all 100ms ease-in-out;
`

export default Container
