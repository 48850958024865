import {
  combineReducers,
  compose as _compose,
  UnknownAction,
} from 'redux'
import { ThunkAction, Tuple, configureStore } from '@reduxjs/toolkit'
import { logger } from 'redux-logger'
import undoable, { excludeAction, groupByActionTypes } from 'redux-undo'
import throttle from 'lodash-es/throttle'

import client from '~/client'

import auth from './auth'
import alert from './alert'
import camera from './camera'
import layers, { TOGGLE_LAYER_VISIBILITY } from './layers'
import objects, {
  ADD_CEILING,
  UPDATE_CEILING,
  DELETE_CEILING,
  ADD_ROOF,
  UPDATE_ROOF,
  UPDATE_ROOF_SECTION,
  FORCE_UPDATE_OBJECT,
  REQUEST_AIRFLOW,
  RECEIVE_AIRFLOW,
  UPDATE_AIRFLOW_STATUS,
  SET_AIRFLOW_LAYER,
  LOAD_FACILITY,
  RESET_FACILITY,
  UPDATE_PRODUCT_HEIGHT,
  UPDATE_DIMENSION,
  ADD_DIMENSION,
  UPDATE_OBJECTS_PASSIVELY,
  REQUEST_HEAT_MAP,
  RECEIVE_HEAT_MAP,
  UPDATE_HEAT_MAP_STATUS,
  SET_HEAT_MAP_LAYER,
} from './objects'
import objectsPersistence from './objectsPersistence'
import panel from './panel'
import selectedObjects from './selectedObjects'
import tools from './tools'
import cfd from './cfd'
import loadingDock from './loadingDock'
import userInterface from './userInterface'
import quote from './quote'
import backgroundImageForm from './backgroundImageForm'
import status from './status'
import units from './units'
import router from './router'
import install from './install'

const reducers = combineReducers({
  auth,
  alert,
  camera,
  layers,
  objects: undoable(objects, {
    debug: import.meta.env.DEV,
    syncFilter: true,
    groupBy: groupByActionTypes(ADD_DIMENSION),
    filter: excludeAction([
      LOAD_FACILITY,
      RESET_FACILITY,
      ADD_ROOF,
      UPDATE_ROOF,
      UPDATE_ROOF_SECTION,
      FORCE_UPDATE_OBJECT,
      REQUEST_AIRFLOW,
      RECEIVE_AIRFLOW,
      UPDATE_AIRFLOW_STATUS,
      SET_AIRFLOW_LAYER,
      TOGGLE_LAYER_VISIBILITY,
      ADD_CEILING,
      UPDATE_CEILING,
      DELETE_CEILING,
      UPDATE_PRODUCT_HEIGHT,
      UPDATE_DIMENSION,
      UPDATE_OBJECTS_PASSIVELY,
      REQUEST_HEAT_MAP,
      RECEIVE_HEAT_MAP,
      UPDATE_HEAT_MAP_STATUS,
      SET_HEAT_MAP_LAYER,
    ]),
  }),
  objectsPersistence,
  panel,
  selectedObjects,
  tools,
  cfd,
  loadingDock,
  userInterface,
  quote,
  backgroundImageForm,
  status,
  units,
  router,
  install,
})

const AUTH_STORE_LS_KEY = 'BAF:UI:store'

const loadState = () => {
  try {
    const serializedState = localStorage.getItem(AUTH_STORE_LS_KEY)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(AUTH_STORE_LS_KEY, serializedState)
  } catch (err) {
    // Ignore write errors.
  }
}

const persistedState = loadState()

export function setupStore(preloadedState?: Partial<ReturnType<typeof reducers>>) {
  return configureStore({
    reducer: reducers,
    preloadedState,
    devTools: import.meta.env.DEV,
    middleware: getDefaultMiddleware => {
      const middlewares = getDefaultMiddleware({
        thunk: {
          extraArgument: client
        },
        serializableCheck: false,
        immutableCheck: false,
      })
      if ('__REDUX_DEVTOOLS_EXTENSION__' in window) {
        return middlewares
      }
      if (import.meta.env.MODE !== "development") {
        return middlewares
      }
      return middlewares.concat(logger)
    }
  })
}

const store = setupStore(persistedState)

export type RootState = ReturnType<typeof store.getState>
export type AppStore = typeof store
export type AppDispatch = AppStore['dispatch']
export type AppGetState = AppStore['getState']
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, UnknownAction>

store.subscribe(
  throttle(() => {
    saveState({
      auth: store.getState().auth,
    })
  }, 1000)
)

export default store
