import styled from 'styled-components'

const ErrorMessage = styled.div`
  left: 50%;
  max-width: 500px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`

export default ErrorMessage
