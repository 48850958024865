import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Icon from 'components/UIKit/Icon'
import { Variant } from '~/config/theme'

const SubMenuIcon = styled(Icon)<{ variant: Variant }>`
  fill: ${props => props.theme.colors[props.variant].subdued};
  position: absolute;

  right: ${theme('spacing.s')};
  top: 50%;
  transform: translateY(-50%);
`

export default SubMenuIcon
