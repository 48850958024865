import type { Object3D } from "three"

export class CompatibilityObject3DHandles {
  #objects = new Map<string, Object3D>()

  ref(id: string) {
    return (object: Object3D | null) => {
      if (object === null) {
        this.#objects.delete(id)
      } else {
        this.#objects.set(id, object)
      }
    }
  }
  get(id: string) {
    return this.#objects.get(id)
  }
}

export const handles = new CompatibilityObject3DHandles()
