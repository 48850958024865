import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Label = styled.div`
  flex-grow: 1;
  text-align: left;
  margin-left: ${theme('spacing.s')};
`

export default Label
