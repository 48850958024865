import { useRouteMatch } from "react-router"

const routes = {
  auth: '/',
  authCallback: '/callback',
  chooseDrawingMethod: '/new-facility/:id/choose-drawing-method',
  uploadDWG: '/new-facility/:id/upload-dwg',
  chooseLayers: '/new-facility/:id/choose-layers',
  facilities: '/facilities',
  facilityFavorites: '/facilities/favorites',
  facilityArchived: '/facilities/archived',
  faq: '/faq',
  templates: '/templates',
  reports: '/reports',
  canvasRenderError: '/canvas-error',
  facility: {
    short: '/facility/:id',
    full: '/facility/:id/area/:floorId/version/:versionId',
    fullLegacy: '/facility/:id/floor/:floorId/version/:versionId',
  },
  onboarding: {
    1: {
      short: '/exterior-walls',
      full: '/new-facility/:id/exterior-walls',
    },
    2: {
      short: '/roofs',
      full: '/new-facility/:id/roofs',
    },
    3: {
      short: '/interior-walls',
      full: '/new-facility/:id/interior-walls',
    },
    4: {
      short: '/ceilings',
      full: '/new-facility/:id/ceilings',
    },
    5: {
      short: '/obstructions',
      full: '/new-facility/:id/obstructions',
    },
    6: {
      short: '/zones',
      full: '/new-facility/:id/zones',
    },
  },
  salesforceAuthentication: '/salesforce/login',
  salesforceCallback: '/api/callbacks/salesforce/auth',
  settings: '/user-settings',
  modals: {
    addControlPanelInstallInfo: '/add-control-panel-install-info',
    addElectricPanelInstallInfo: '/add-electric-panel-install-info',
    addProductInstallInfo: '/add-product-install-info',
    addProductOverlayImage: '/add-product-overlay-image',
    annotateImage: '/annotate-image',
    cfdImages: '/cfd-images/:goal',
    configureCFD: '/configure-cfd',
    convertImage: '/convert-image',
    distributeObstruction: '/distribute-obstruction',
    distributeProduct: '/distribute-product',
    distributeDoor: '/distribute-door',
    duplicateFacility: '/duplicate-facility/:id',
    duplicateFloor: '/duplicate-area/:id',
    editFacility: '/edit-facility/:id',
    editFacilityTemplate: '/edit-template/:id',
    editFloor: '/edit-area/:id',
    editImage: '/edit-image',
    editVersion: '/edit-version/:id',
    exportPDFLayout: '/export-pdf-layout',
    importExportData: '/import-export-data',
    installReview: '/install-review',
    newFacility: '/new-facility',
    newFacilityTemplate: '/new-template/:id',
    newFloor: '/new-area',
    newVersion: '/new-version',
    performanceMetrics: '/performance-metrics',
    releaseNotes: '/release-notes',
    requestQuote: '/request-quote',
    roofTemplates: '/roof-templates',
    savings: '/savings',
    scaleImage: '/scale-image',
    selectOpportunity: '/select-opportunity',
    shapeTemplates: '/shape-templates',
    snapshot: '/snapshot/:snapshotId',
    uploadImage: '/upload-image/:type',
    userProfileInfo: '/user-profile-info',
    userSearch: '/:id/user-search',
    viewImage: '/view-image',
    webcamCapture: '/webcam-capture/:type',
    sendToSalesHub: '/send-to-saleshub',
  },
  help: {
    airflow: '/airflow-help',
    cfd: '/cfd-help',
    heatmap: '/heatmap-help',
  },
  reassign: '/reassign',
}

export function useFacilityMatch(): { id?: string; floorId?: string; versionId?: string } {
  return useRouteMatch<{ id: string; floorId: string; versionId: string }>(routes.facility.full)?.params ?? {}
}

export default routes
