import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import { Helmet } from 'react-helmet'
import find from 'lodash-es/find'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import pick from 'lodash-es/pick'
import qs from 'qs'

import { showProductSize } from 'lib/utils'
import { getTitle } from 'config/titles'
import { updateUtilityBox } from 'store/objects'
import { withUnits } from 'store/units/decorators'

import Button from 'components/UIKit/Button'
import Checkbox from 'components/UIKit/Checkbox'
import FormLabel from 'components/UIKit/FormLabel'
import Modal from 'components/UIKit/Modal'
import Select from 'components/UIKit/Select'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'
import Tooltip from 'components/UIKit/Tooltip'

const controllerTypes = [
  'BAFCon',
  'WSC',
  'Standard Wall Mounted Keypad',
  'Essence Wired',
  'Essence Wireless',
  'Other',
]

const typeOptions = controllerTypes.map(type => ({
  label: type,
  value: type,
}))

const PRODUCT_LABEL_FIELDS = ['model', 'size']

const AddControlPanelInstallInfoModal = ({ parentRoute, online, ...props }) => {
  const [type, setType] = useState(props.selectedUtilityBox.type || null)
  const [typeOther, setTypeOther] = useState(
    props.selectedUtilityBox.typeOther || null
  )
  const [fans, setFans] = useState(props.selectedUtilityBox.fans || [])

  function handleSubmit(event) {
    event.preventDefault()
    const data = {
      type,
      fans,
    }
    // We only want to include the `typeOther` option in the data when
    // `Other` is selected as an option.
    const _typeOther = type.toLowerCase() === 'other' ? typeOther : null
    const canSubmit = type !== null && type !== '' && fans.length !== 0
    if (!canSubmit) return false
    props.onUpdateUtilityBox({
      ...props.selectedUtilityBox,
      ...data,
      typeOther: _typeOther,
    })
    props.history.push(parentRoute)
  }
  const productsArray = Array.from(Object.values(props.products))
  const disableSubmit =
    !type ||
    (type.toLowerCase() === 'other' && isEmpty(typeOther)) ||
    (productsArray.length > 0 && fans.length === 0) ||
    !online

  return (
    <div>
      <Helmet>
        <title>{getTitle('installInfo')}</title>
      </Helmet>
      <Modal
        title="Install Information"
        onSubmit={handleSubmit}
        parentRoute={parentRoute}
        primaryAction={
          disableSubmit ? (
            <Tooltip
              content={
                online
                  ? 'You need to fill out all the fields before submitting'
                  : 'Internet connection required to update install information'
              }
            >
              {/*
                Tooltips around a disabled Button require a wrapping element
                because of how browsers handle events on disabled buttons.
              */}
              <div>
                <Button primary label="Submit" disabled={true} />
              </div>
            </Tooltip>
          ) : (
            <Button primary label="Submit" />
          )
        }
        secondaryAction={<Button to={parentRoute} label="Cancel" />}
        {...props}
      >
        <>
          <Space bottom="base">
            <Select
              name="type"
              label="Controller Type"
              onChange={event => setType(event.target.value)}
              value={type}
              options={typeOptions}
            />
            {type === 'Other' && (
              <Space top="s">
                <TextField
                  name="typeOther"
                  label="Please specify:"
                  value={typeOther}
                  width="50%"
                  onChange={event => setTypeOther(event.target.value)}
                />
              </Space>
            )}
          </Space>
          {productsArray.length > 0 ? (
            <Space bottom="base">
              <FormLabel>Fans</FormLabel>
              {productsArray.map(product => (
                <Checkbox
                  key={product.id}
                  name={`product-${product.id}`}
                  label={
                    <ProductLabel
                      {...pick(product, PRODUCT_LABEL_FIELDS)}
                      {...pick(props, ['distanceUnits'])}
                    />
                  }
                  onChange={event => {
                    const foundProduct = find(fans, { id: product.id })
                    if (foundProduct) {
                      setFans(fans.filter(f => f.id !== foundProduct.id))
                    } else {
                      setFans([...fans, product])
                    }
                  }}
                  value={product}
                  checked={find(fans, { id: product.id })}
                />
              ))}
            </Space>
          ) : null}
        </>
      </Modal>
    </div>
  )
}

function ProductLabel(props = {}) {
  return (
    <>
      <span>
        {props.model} - {showProductSize(props.size, props.distanceUnits)}
      </span>
    </>
  )
}

ProductLabel.propTypes = {
  distanceUnits: PropTypes.string,
  model: PropTypes.string,
  size: PropTypes.string,
}

AddControlPanelInstallInfoModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  onUpdateUtilityBox: PropTypes.func,
  parentRoute: PropTypes.string,
  products: PropTypes.object,
  selectedUtilityBox: PropTypes.object,
  online: PropTypes.bool,
}

const mapStateToProps = ({ objects }, ownProps) => {
  const queryParams = qs.parse(get(ownProps, 'location.search'), {
    ignoreQueryPrefix: true,
  })

  const objectId = get(queryParams, 'objectId')
  const selectedUtilityBox = get(objects.present, `utilityBoxes[${objectId}]`)
  const products = get(objects.present, 'products')

  return {
    selectedUtilityBox,
    products,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateUtilityBox(utilityBox) {
    dispatch(updateUtilityBox({ utilityBox }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withUnits
)(AddControlPanelInstallInfoModal)
