import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'

import Icon from 'components/UIKit/Icon'
import Tooltip from 'components/UIKit/Tooltip'

import Container from './styled/Container'

const tooltip = {
  facility: {
    locked: `
      You are viewing a protected facility. Your changes will not be saved.
    `,
  },
  template: {
    locked: `
      You are viewing a template. Your changes will not be saved unless you
      make a copy of this template.
    `,
    unlocked: `
      You are viewing a template. Any change you make will be saved.
    `,
  },
}

const Lock = (props = {}) => (
  <Container>
    <Tooltip
      content={get(tooltip, `${props.type}.${props.status}`)}
      placement="left"
    >
      <Icon name={props.status} color="bg" />
    </Tooltip>
  </Container>
)

Lock.propTypes = {
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default Lock
