import styled from 'styled-components'

const P = styled.p`
  margin-left: ${props => {
    switch (props.tabOver) {
      case 1:
        return '40px'
      case 2:
        return '60px'
      default:
        return '20px'
    }
  }};
`

export default P
