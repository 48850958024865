import React from 'react'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import { getStepNumber } from 'config/onboarding'
import { getTitle } from 'config/titles'

import { GET_USER_QUERY } from 'client/queries'
import { UPDATE_USER_MUTATION } from 'client/mutations'
import Onboarding from 'components/Onboarding'
import AppContainer from 'components/App/styled/AppContainer'

const OnboardingComfortZonesScreen = ({
  onModalClose,
  userId,
  data,
  ...props
}) => (
  <AppContainer>
    <Helmet>
      <title>{getTitle('comfortZones')}</title>
    </Helmet>
    <Onboarding
      {...props}
      step={getStepNumber('Zones')}
      message="Draw and configure your comfort zones."
    />
  </AppContainer>
)

OnboardingComfortZonesScreen.propTypes = {
  onModalClose: PropTypes.func,
  userId: PropTypes.string,
  data: PropTypes.object,
}

const mapStateToProps = ({ auth }) => ({
  userId: auth.userId,
})

export default compose(
  graphql(GET_USER_QUERY, {
    options: ({ userId }) => ({ variables: { id: userId } }),
  }),
  graphql(UPDATE_USER_MUTATION, {
    props: ({ mutate }) => ({
      onModalClose: variables => mutate({ variables }),
    }),
  }),
  appConnect(mapStateToProps)
)(OnboardingComfortZonesScreen)
