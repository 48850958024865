import styled, { css } from 'styled-components'

import { theme } from 'lib/styleUtils'
import { getZIndexOf } from 'lib/utils'

const setFloating = css`
  bottom: ${theme('spacing.xl')};
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
`

const Container = styled.div<{ floating?: string }>`
  align-items: center;
  background-color: ${theme('colors.dark.bg')};
  border-radius: ${theme('radius.base')};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  color: ${theme('colors.dark.fg')};
  display: flex;
  font-size: ${theme('fonts.sizes.s')};
  position: relative;
  z-index: ${getZIndexOf('alert')};

  ${props => props.floating ? setFloating : ''};
`

export default Container
