import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'

import ProductsScreen from './screens/ProductsScreen'
import DetailsScreen from './screens/DetailsScreen'

class GetQuotePanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      screenIndex: get(
        this.props,
        'facility.floor.version.quote.salesforceQuoteId'
      )
        ? 1
        : 0,
    }
  }

  handlePreviousScreen = () => {
    this.setState({
      screenIndex: 0,
    })
  }

  handleNextScreen = () => {
    this.setState({
      screenIndex: 1,
    })
  }

  render() {
    if (this.state.screenIndex === 1) {
      return (
        <DetailsScreen
          facility={this.props.facility}
          onPreviousScreen={this.handlePreviousScreen}
          alignment={this.props.alignment}
        />
      )
    }
    return (
      <ProductsScreen
        facility={this.props.facility}
        onNextScreen={this.handleNextScreen}
        alignment={this.props.alignment}
      />
    )
  }
}

GetQuotePanel.propTypes = {
  alignment: PropTypes.string,
  facility: PropTypes.object,
}

GetQuotePanel.defaultProps = {
  alignment: 'right',
}

export default GetQuotePanel
