import styled from 'styled-components'

const Nav = styled.ul`
  font-size: 14px;
  margin: 0;
  padding: 0;
  list-style: none;
`

export default Nav
