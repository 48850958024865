import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Grid from 'components/UIKit/Grid'

const Container = styled(Grid)`
  align-content: ${props => (props.loading ? 'center' : 'flex-start')};
  height: 100%;
  justify-content: ${props => (props.loading ? 'center' : 'flex-start')};
  margin-left: 0;
  overflow-y: scroll;
  padding-bottom: ${theme('spacing.l')};
  padding-left: ${theme('spacing.base')};
  padding-right: ${theme('spacing.l')};
  padding-top: ${theme('spacing.base')};
  width: 600px;
`

export default Container
