import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Stack } from 'components/UIKit/Stack'

const PerformanceMetricsContainer = ({ children }) => {
  const [maxHeight, setMaxHeight] = useState('100dvh')

  useEffect(() => {
    const canvasObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const canvasHeight = entry?.contentRect?.height
        const newHeight = typeof canvasHeight === 'number' && `calc(${canvasHeight}px - 10dvh)`
        setMaxHeight(height => newHeight || height)
      })
    })
    const canvasElement = document.querySelector('#drawing-canvas')
    canvasElement && canvasObserver.observe(canvasElement)
    return () => canvasObserver.disconnect()
  }, [])

  return (
    <Stack
      alignItems="unset"
      justifyContent="unset"
      padding={1}
      spacing={0.5}
      style={{ overflow: 'scroll', maxHeight, position: 'relative' }}
    >
      {children}
    </Stack>
  )
}

PerformanceMetricsContainer.propTypes = {
  children: PropTypes.node,
}

export { PerformanceMetricsContainer }
