import React from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";

import { isTouchUI } from 'store/userInterface/selectors'

import Container from './styled/Container'

export const Toolbar = (props = {}) => <Container {...props} />

Toolbar.propTypes = {
  borderBottom: PropTypes.bool,
  borderTop: PropTypes.bool,
  isTouchUI: PropTypes.bool,
  size: PropTypes.string,
  sticky: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
}

const mapStateToProps = store => ({
  isTouchUI: isTouchUI(store),
})

export default /** @type any */(appConnect(mapStateToProps)(Toolbar));
