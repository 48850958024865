import React from 'react'
import { Helmet } from 'react-helmet'
import pick from 'lodash-es/pick'

import { getTitle } from 'config/titles'

import Modal from 'components/UIKit/Modal'
import OpportunitiesList from '../../OpportunitiesList'

const SelectOpportunityModal = props => (
  <div>
    <Helmet>
      <title>{getTitle('selectOpportunity')}</title>
    </Helmet>
    <Modal title="Select Opportunity" {...props}>
      <OpportunitiesList {...pick(props, ['history'])} />
    </Modal>
  </div>
)

export default SelectOpportunityModal
