import get from 'lodash-es/get'
import * as THREE from 'three'

import { getSelectedObjects } from '../selectedObjects/selectors'

import * as actions from './actions'

import LAYER_KEYS from 'config/layerKeys'
import Util from 'components/DrawingCanvas/lib/util'
import Units from 'components/DrawingCanvas/lib/units'
import Facility from 'components/DrawingCanvas/lib/facility'

import {
  LOAD_FACILITY,
  ADD_OBJECT,
  ADD_ROOF,
  ADD_ROOF_SECTION,
  ADD_UTILITY_BOX,
  UPDATE_ELEVATION_POINT,
  UPDATE_ELEVATION_LINE,
  UPDATE_ROOF,
  UPDATE_ROOF_SECTION,
  UPDATE_OBJECT,
  UPDATE_OBJECTS,
  UPDATE_WALL,
  UPDATE_SEGMENT,
  ADD_PRODUCT,
  DISTRIBUTE_PRODUCTS,
  UPDATE_PRODUCT,
  ADD_OBSTRUCTION,
  DISTRIBUTE_OBSTRUCTIONS,
  UPDATE_OBSTRUCTION,
  ADD_CEILING,
  UPDATE_CEILING,
  DELETE_CEILING,
  DELETE_OBJECTS,
  DUPLICATE_OBJECTS,
  DELETE_ROOF_SECTION,
  UPDATE_SEGMENTS,
} from '../objects'
import { TOGGLE_LAYER_VISIBILITY } from '../layers'

export const INITIAL_STATE = {
  isUploading: false,
  isValid: true,
  validTypes: ['cooling', 'destrat'],
  didFail: false,
  errorMessage: '',
  didAbort: false,
  isAborting: false,
  percentComplete: 0,
  selectedLayer: {
    level: '',
    type: 'overhead',
    goal: undefined,
    url: '',
  },
  grid: {},
  models: {},
}

export const shouldIgnoreUpdateToInvalidateCFD = payload => {
  const ignoreForCFD =
    get(payload, 'ignoreForCFD') || get(payload, '[0].ignoreForCFD')
  if (ignoreForCFD) return true

  const LAYERS_TO_IGNORE = [
    LAYER_KEYS.COMFORT_POINTS,
    LAYER_KEYS.COMFORT_ZONES,
    LAYER_KEYS.DIMENSIONS,
    LAYER_KEYS.UTILITY_BOXES,
  ]
  const facilityObjects = Facility.current.getAllObjects()

  if (Array.isArray(payload) && payload.length) {
    // If all objects have layer keys and are on ignored layers return true
    const ignoredObjects = payload.filter(object =>
      LAYERS_TO_IGNORE.includes(object.layerKey)
    )
    if (ignoredObjects.length === payload.length) return true

    const objectIds = payload.map(obj => obj.id)
    const objects = facilityObjects.filter(obj => objectIds.includes(obj.id))
    let length = 0
    LAYERS_TO_IGNORE.forEach(layerKey => {
      const object = objects.filter(obj => layerKey === obj.layerKey)
      if (object && object.length) {
        length += object.length
      }
    })

    // Moving multiple comfort points
    if (length === payload.length) {
      return true
    }
  } else {
    const object = facilityObjects.find(obj => payload === obj.id)
    // Moving a single comfort point
    if (object && LAYERS_TO_IGNORE.includes(object.layerKey)) {
      return true
    }
  }
}

export const shouldIgnoreDeleteToInvalidateCFD = globalState => {
  const selectedObjects = getSelectedObjects(globalState)
  const validItems = selectedObjects.filter(
    obj =>
      obj.className !== 'ComfortZone' &&
      obj.className !== 'ComfortPoint' &&
      obj.className !== 'Door' &&
      obj.className !== 'UtilityBox' &&
      obj.className !== 'Dimension'
  )

  return !validItems.length
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.UPLOAD_OBJ: {
      return {
        ...INITIAL_STATE,
        isUploading: true,
        isValid: true,
        didAbort: false,
        isAborting: false,
        validTypes: get(action, 'payload.goals', []),
      }
    }
    case actions.UPLOAD_COMPLETE: {
      return {
        ...state,
        isUploading: false,
        didAbort: false,
        isAborting: false,
        isValid: true,
      }
    }
    case actions.UPLOAD_OBJ_FAILED: {
      const errorMessage = get(action, 'payload.errorMessage')
      return {
        ...state,
        isUploading: false,
        didFail: true,
        didAbort: false,
        isAborting: false,
        errorMessage,
        validTypes: [], // TODO how do we know which type failed?
      }
    }
    case actions.ABORT_UPLOAD_OBJ: {
      return {
        ...state,
        isUploading: false,
        didAbort: false,
        isAborting: true,
        isValid: false,
        validTypes: [
          ...state.validTypes.filter(type =>
            get(action, 'payload.type').includes(type)
          ),
        ],
      }
    }
    case actions.UPLOAD_OBJ_ABORTED: {
      return {
        ...state,
        isUploading: false,
        didAbort: true,
        isAborting: false,
      }
    }
    case actions.CANCEL_JOB: {
      const { validTypes } = state
      const { type } = action.payload
      const newValidTypes = []

      if (validTypes.includes('cooling') && !type.includes('cooling')) {
        newValidTypes.push('cooling')
      }
      if (validTypes.includes('destrat') && !type.includes('destrat')) {
        newValidTypes.push('destrat')
      }
      if (validTypes.includes('heating') && !type.includes('heating')) {
        newValidTypes.push('heating')
      }

      return {
        ...state,
        isUploading: false,
        didAbort: false,
        isAborting: true,
        isValid: !!newValidTypes.length,
        validTypes: [...newValidTypes],
      }
    }
    case actions.JOB_CANCELLED: {
      return {
        ...state,
        isUploading: false,
        didAbort: true,
        isAborting: false,
      }
    }
    case actions.NOTIFY_UPLOAD_OBJ_PROGRESS: {
      const percentComplete = get(action, 'payload.percentComplete')
      return {
        ...state,
        percentComplete,
      }
    }
    case actions.SET_CFD_LAYER: {
      return {
        ...state,
        selectedLayer: {
          type: action.payload.type,
          url: action.payload.url,
          level: action.payload.level,
          goal: action.payload.goal,
        },
      }
    }
    case actions.GET_VTK_DATA_STARTED: {
      const goal = action.payload.goal
      const type = action.payload.type
      const types = get(state.models, `${goal}.${type}`, {})

      return {
        ...state,
        models: {
          ...state.models,
          [action.payload.goal]: {
            ...state.models[action.payload.goal],
            [action.payload.type]: {
              ...types,
              [action.payload.level]: {
                isLoading: true,
              },
            },
          },
        },
      }
    }

    case actions.SET_VTK_DATA: {
      // CFD data comes back unscaled to our canvas,
      // So we need to scale it and update the positions
      const geometry = action.payload.geometry

      if (!geometry.positionsUpdated) {
        const obj3d = new THREE.Mesh(geometry, new THREE.MeshNormalMaterial())
        // CFD is taking the data we send them and converting it
        // from feet to meters, so we need to undo that conversion
        const scale = Units.metersToNative(1)
        const positions = obj3d.geometry.attributes.position.array
        const zFightOffset = 0.4
        for (let i = 0; i < positions.length; i += 3) {
          positions[i] = positions[i] * scale
          positions[i + 1] = positions[i + 1] * scale
          positions[i + 2] = positions[i + 2] * scale + zFightOffset
        }
        geometry.positionsUpdated = true
        obj3d.geometry.attributes.position.needsUpdate = true
      }

      const goal = action.payload.goal
      const type = action.payload.type
      const types = get(state.models, `${goal}.${type}`, {})

      return {
        ...state,
        models: {
          ...state.models,
          [action.payload.goal]: {
            ...state.models[action.payload.goal],
            [action.payload.type]: {
              ...types,
              [action.payload.level]: {
                geometry: action.payload.geometry,
                url: action.payload.url,
                isValid: state.isValid,
              },
            },
          },
        },
      }
    }
    case TOGGLE_LAYER_VISIBILITY: {
      if (action.payload.layerKey === LAYER_KEYS.CFD) {
        return {
          ...state,
          grid: {
            ...state.grid,
            updateId: Util.guid(),
          },
        }
      }
      return {
        ...state,
      }
    }
    case actions.UPDATE_CFD: {
      return {
        ...INITIAL_STATE,
        isValid: action.payload.isValid,
        validTypes: action.payload.validTypes,
      }
    }
    case LOAD_FACILITY: {
      return {
        ...INITIAL_STATE,
        isValid: get(action.payload, 'data.cfd.isValid', true),
        validTypes: get(action.payload, 'data.cfd.validTypes', []),
      }
    }
    case UPDATE_OBJECT:
    case UPDATE_OBJECTS:
    case ADD_OBJECT: {
      const payload = action.payload

      if (shouldIgnoreUpdateToInvalidateCFD(payload)) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        isValid: false,
        validTypes: [],
      }
    }
    case ADD_ROOF:
    case ADD_ROOF_SECTION:
    case ADD_UTILITY_BOX:
    case UPDATE_ROOF:
    case UPDATE_ROOF_SECTION:
    case UPDATE_ELEVATION_POINT:
    case UPDATE_ELEVATION_LINE:
    case UPDATE_WALL:
    case UPDATE_SEGMENT:
    case UPDATE_SEGMENTS:
    case ADD_PRODUCT:
    case DISTRIBUTE_PRODUCTS:
    case UPDATE_PRODUCT:
    case ADD_OBSTRUCTION:
    case DISTRIBUTE_OBSTRUCTIONS:
    case UPDATE_OBSTRUCTION:
    case ADD_CEILING:
    case UPDATE_CEILING:
    case DELETE_CEILING:
    case DELETE_OBJECTS:
    case DUPLICATE_OBJECTS:
    case DELETE_ROOF_SECTION: {
      const payload = action.payload
      const deletionTypes = ['DELETE_OBJECTS', 'DELETE_SELECTED_OBJECTS']

      if (shouldIgnoreUpdateToInvalidateCFD(payload)) {
        return {
          ...state,
        }
      }

      if (deletionTypes.includes(action.type)) {
        if (shouldIgnoreDeleteToInvalidateCFD(action.globalState)) {
          return {
            ...state,
          }
        }
      }
      return {
        ...state,
        isValid: false,
        validTypes: [],
      }
    }
    default:
      return state
  }
}
