import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const setSize = props => {
  const [one, two] = props.size.split('of')
  return (parseInt(one, 10) / parseInt(two, 10)) * 100
}

const Container = styled.div`
  ${props => props.hCenter && `align-items: center;`};
  box-sizing: border-box;
  display: flex;
  flex-basis: ${props => setSize(props)}%;
  flex-direction: ${props => (props.direction ? props.direction : 'column')};
  ${props => props.vCenter && `justify-content: center;`};
  padding-left: ${theme('spacing.base')};
`

export default Container
