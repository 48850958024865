import { LOAD_FACILITY, RESET_FACILITY } from '../objects/action_types'
import * as actions from './actions'
import { extractBundles } from './selectors'

export const initialState = {
  bundles: /** @type {Record<string, any> */({}),
  opportunity: /** @type {Record<string, any> */({}),
  name: /** @type {string|null} */ (null),
  updatedBundles: /** @type {Record<string, any>|null} */ (null),
  discountReasonId: /** @type {Record<string, any>|null} */ (null),
  discountNotes: /** @type {Record<string, any>|null} */ (null),
  shippingPrice: 0,
  screenIndex: /** @type {number|undefined} */(undefined),
}

export default function quoteReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_FACILITY: {
      return {
        ...state,
        screenIndex: 0,
        bundles: extractBundles(action.payload.data.products),
      }
    }
    case RESET_FACILITY: {
      return initialState
    }
    case actions.INIT_BUNDLES: {
      return {
        ...state,
        bundles: action.payload.bundles,
      }
    }
    case actions.ADD_BUNDLE: {
      return {
        ...state,
        bundles: {
          ...state.bundles,
          [action.payload.groupId]: [
            ...(state.bundles[action.payload.groupId] || []),
            action.payload.bundle,
          ],
        },
      }
    }
    case actions.ADD_OPPORTUNITY: {
      return {
        ...state,
        opportunity: action.payload.opportunity,
      }
    }
    case actions.CLEAR_OPPORTUNITY: {
      return {
        ...state,
        opportunity: {},
      }
    }
    case actions.UPDATE_QUOTE_NAME: {
      return {
        ...state,
        name: action.payload.name,
      }
    }
    case actions.UPDATE_BUNDLES: {
      return {
        ...state,
        // TODO: This is dirty
        updatedBundles: action.payload.bundles,
      }
    }
    case actions.UPDATE_DISCOUNT_REASON_ID: {
      return {
        ...state,
        discountReasonId: action.payload.id,
      }
    }
    case actions.UPDATE_DISCOUNT_NOTES: {
      return {
        ...state,
        discountNotes: action.payload.notes,
      }
    }
    case actions.UPDATE_SHIPPING_PRICE: {
      return {
        ...state,
        shippingPrice: parseFloat(action.payload.price),
      }
    }
    default: {
      return state
    }
  }
}
