import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Button from './Button'

const ProductButton = styled(Button)`
  right: calc(8 * ${theme('spacing.base')} + ${theme('touchUI.buttonSize')});
`

export default ProductButton
