import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.div`
  align-items: center;
  background-color: ${theme('colors.dark.bg')};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export default Container
