import Tool from './tool'
import store from 'store'
import ElevationPoint from './elevationPoint'
import Units from './units'
import { addElevationPoint, updateRoof } from 'store/objects'
import { isSnapEnabled } from 'store/tools/selectors'
import Primitives from './primitives'
import RoofToolUtil from './roofToolUtil'
import SnapQueries from './snapQueries'
import { getThreeHexFromTheme } from 'lib/utils'

class ElevationPointTool extends Tool {
  constructor() {
    super()

    this.name = 'ELEVATION_POINT_TOOL'

    this.obj3d = this.createCursor()
  }

  createCursor() {
    return Primitives.getCrosshairCursorVisual(
      Units.inchesToNative(6),
      Units.inchesToNative(6),
      0.3,
      getThreeHexFromTheme('three.dark')
    )
  }

  toolMoved(
    mousePos,
    snappedMousePos,
    sceneIntersectionPoint,
    objectUnderTool,
    objectUnderSnappedTool
  ) {
    this.obj3d.position.set(snappedMousePos.x, snappedMousePos.y, 100)
    this.lastSceneIntersectionPoint = sceneIntersectionPoint
  }

  toolUp({
    mousePos,
    snappedMousePos,
    sceneIntersectionPoint,
    objectUnderTool,
    objectUnderSnappedTool,
    multiSelect,
  }) {
    const roof = RoofToolUtil.getRoofUnderCursor(snappedMousePos)

    if (roof) {
      const roofPos = roof.obj3d.position
      const localPosition = {
        x: snappedMousePos.x - roofPos.x,
        y: snappedMousePos.y - roofPos.y,
        z: roof.height,
      }
      const oldModel = roof.toModel()
      const model = ElevationPoint.createModel(localPosition, roof.id)
      store.dispatch(addElevationPoint({ elevationPoint: model, multiSelect }))
      const newRoofModel = {
        ...oldModel,
        elevationPointIds: [...oldModel.elevationPointIds, model.id],
      }
      store.dispatch(updateRoof({ roof: newRoofModel }))
    }
  }

  getSnapRegions(facility, draggedObject) {
    if (!isSnapEnabled()) return []

    const cursorPoint = this.lastSceneIntersectionPoint
    const roof = cursorPoint
      ? RoofToolUtil.getRoofUnderCursor(cursorPoint)
      : null

    let snapRegions = []

    if (roof) {
      snapRegions = snapRegions.concat(
        SnapQueries.getEdgeMidpointLinesForRoof(roof)
      )

      snapRegions = snapRegions.concat(
        SnapQueries.getPerimeterPointsForRoof(roof)
      )

      snapRegions = snapRegions.concat(
        SnapQueries.getElevationPointLinesForRoof(roof)
      )
    }

    const filteredSnapRegions = SnapQueries.getFilteredSnapRegions(snapRegions)

    return filteredSnapRegions
  }

  getArrowDescriptions() {}
}

export default ElevationPointTool
