import React from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";
import isEmpty from 'lodash-es/isEmpty'

import { clearOpportunity } from 'store/quote'

import Button from 'components/UIKit/Button'
import FormLabel from 'components/UIKit/FormLabel'
import Space from 'components/UIKit/Space'
import Tooltip from 'components/UIKit/Tooltip'

import ButtonContainer from './styled/ButtonContainer'
import OpportunityButton from './styled/Button'

function OpportunitySelect({
  canClear,
  onClear,
  onClearOpportunity,
  onClick,
  selectedOpportunity,
  truncate,
  url,
}) {
  function getClickProp() {
    if (url) {
      return {
        to: url,
      }
    }

    if (onClick) {
      return {
        onClick: event => onClick(event),
      }
    }

    return null
  }

  return (
    <>
      <Space bottom="s">
        <FormLabel>Opportunity</FormLabel>
      </Space>
      <ButtonContainer>
        <OpportunityButton
          selectedOpportunity={!isEmpty(selectedOpportunity) && canClear}
          fullWidth
          type="button"
          truncate={truncate}
          label={
            isEmpty(selectedOpportunity)
              ? 'Select Opportunity'
              : selectedOpportunity
          }
          icon="search"
          {...getClickProp()}
        />
        {!isEmpty(selectedOpportunity) && canClear && (
          <Tooltip content="Clear this opportunity">
            <Button
              noBorder
              icon="cross"
              type="button"
              onClick={event => {
                event.preventDefault()
                onClear && onClear(event)
                onClearOpportunity && onClearOpportunity()
              }}
            />
          </Tooltip>
        )}
      </ButtonContainer>
    </>
  )
}

OpportunitySelect.propTypes = {
  canClear: PropTypes.bool,
  onClear: PropTypes.func,
  onClearOpportunity: PropTypes.func,
  onClick: PropTypes.func,
  selectedOpportunity: PropTypes.string,
  truncate: PropTypes.string,
  url: PropTypes.string,
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClearOpportunity() {
    dispatch(clearOpportunity())
  },
})

export default appConnect(null, mapDispatchToProps)(OpportunitySelect)
