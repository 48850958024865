import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useNetwork } from 'networkProvider'

import { getTitle } from 'config/titles'
import { currentUserIsCustomer } from 'lib/currentUserIs'
import withUser from 'client/decorators/withUser'

import VariantText from 'components/UIKit/VariantText'
import Dashboard from 'components/Dashboard'

import MyFacilities from './MyFacilities'

/**
 * main page for facilities, with facilities list and various modals
 * @param {Object} props
 * @returns Facilities Screen
 */
const FacilitiesScreen = props => {
  const network = useNetwork()
  return (
    <>
      <Helmet>
        <title>{getTitle('facilities')}</title>
      </Helmet>
      <Dashboard noAction={!network.online}>
        {!currentUserIsCustomer(props.user) ? (
          <MyFacilities {...props} online={network.online} />
        ) : (
          <VariantText align="center" size="l">
            Please go to the URL you were provided.
          </VariantText>
        )}
      </Dashboard>
    </>
  )
}

FacilitiesScreen.propTypes = {
  user: PropTypes.object,
}

// TODO: comment required in both places to trick intellisense
/**
 * main page for facilities, with facilities list and various modals
 * @param {Object} props
 * @returns Facilities Screen
 */
export default withUser(FacilitiesScreen)
