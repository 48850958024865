import styled, { ThemedStyledProps } from 'styled-components'

import { breakpoints } from 'lib/styleUtils'

import Icon from '../../Icon'
import { Theme } from '~/config/theme';

const setMargin = (props: ThemedStyledProps<{ responsive?: boolean; hasRightSibling?: boolean }, Theme>) => {
  if (props.responsive) {
    return breakpoints.l`
      margin-right: ${props.hasRightSibling ? props.theme.spacing.xs : 0};
    `
  }

  return `
    margin-right: ${props.hasRightSibling ? props.theme.spacing.xs : 0};
  `
}

const StyledIcon: any = styled(Icon)`
  ${(props: any) => setMargin(props)};
`

export default StyledIcon
