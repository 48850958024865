import { ActionTypes } from 'redux-undo'
import get from 'lodash-es/get'
import * as PersistenceEngine from './actions'
import * as Objects from '../objects'
import * as CFD from '../cfd'
import * as Layers from '../layers'
import * as UserInterface from '../userInterface'
import * as LoadingDock from '../loadingDock'
import * as Install from '../install'

const initialState = {
  needsSaved: false,
  isSaving: false,
}

export default function objectsPersistenceReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case Objects.LOAD_FACILITY: {
      return {
        ...state,
        needsSaved: false,
        isSaving: false,
      }
    }
    case Objects.RESET_FACILITY: {
      return initialState
    }
    case Objects.UPDATE_DIMENSION: {
      return {
        ...state,
        needsSaved: false,
      }
    }
    case PersistenceEngine.SAVE_OBJECTS_STARTED: {
      return {
        ...state,
        needsSaved: false,
        isSaving: true,
      }
    }
    case PersistenceEngine.SAVE_OBJECTS_SUCCEEDED: {
      return {
        ...state,
        needsSaved: false,
        isSaving: false,
      }
    }
    case PersistenceEngine.SAVE_OBJECTS_FAILED: {
      return {
        ...state,
        needsSaved: false,
        isSaving: false,
        error: action.payload.error,
      }
    }
    case ActionTypes.UNDO:
    case ActionTypes.REDO:
    case CFD.UPLOAD_COMPLETE:
    case CFD.UPLOAD_OBJ_FAILED:
    case CFD.ABORT_UPLOAD_OBJ:
    case CFD.CANCEL_JOB:
    case Objects.ADD_OBJECT:
    case Objects.DELETE_OBJECTS:
    case Objects.DUPLICATE_OBJECTS:
    case Objects.DISTRIBUTE_PRODUCTS:
    case Objects.DISTRIBUTE_OBSTRUCTIONS:
    case Objects.ADD_ROOF:
    case Objects.UPDATE_ROOF:
    case Objects.ADD_ROOF_SECTION:
    case Objects.UPDATE_ROOF_SECTION:
    case Objects.UPDATE_ROOF_STRUCTURE:
    case Objects.ADD_MOUNTING_BEAM:
    case Objects.REMOVE_MOUNTING_BEAM:
    case Objects.UPDATE_MOUNTING_BEAM:
    case Objects.ADD_COLUMN_LINE:
    case Objects.REMOVE_COLUMN_LINE:
    case Objects.UPDATE_COLUMN_LINE:
    case Objects.ADD_ELEVATION_POINT:
    case Objects.UPDATE_ELEVATION_POINT:
    case Objects.ADD_ELEVATION_LINE:
    case Objects.UPDATE_ELEVATION_LINE:
    case Objects.UPDATE_SEGMENT:
    case Objects.UPDATE_SEGMENTS:
    case Objects.UPDATE_WALL:
    case Objects.UPDATE_OBJECT:
    case Objects.UPDATE_OBJECTS:
    case Objects.FORCE_UPDATE_OBJECT:
    case Objects.ADD_PRODUCT:
    case Objects.UPDATE_PRODUCT:
    case Objects.ADD_COMFORT_POINT:
    case Objects.UPDATE_COMFORT_POINT:
    case Objects.ADD_COMFORT_ZONE:
    case Objects.UPDATE_COMFORT_ZONE:
    case Objects.ADD_DOOR:
    case Objects.UPDATE_DOOR:
    case Objects.ADD_UTILITY_BOX:
    case Objects.UPDATE_UTILITY_BOX:
    case Objects.ADD_OBSTRUCTION:
    case Objects.UPDATE_OBSTRUCTION:
    case Objects.ADD_DIMENSION:
    case Objects.ADD_BACKGROUND_IMAGE:
    case Objects.UPDATE_BACKGROUND_IMAGE:
    case Objects.ADD_CEILING:
    case Objects.UPDATE_CEILING:
    case Objects.DELETE_BACKGROUND_IMAGE:
    case Objects.DELETE_CEILING:
    case Objects.ADD_METADATA_IMAGE:
    case Objects.AUTO_GENERATE_DIMENSIONS:
    case Layers.SET_CURRENT_LAYER:
    case Layers.SET_FOCUSED_LAYER:
    case Layers.TOGGLE_LAYER_VISIBILITY:
    case Layers.TOGGLE_LAYER_LOCKED:
    case UserInterface.TOGGLE_TOUCH_UI:
    case UserInterface.TOGGLE_DEFAULT_UI:
    case LoadingDock.ADD_LOADING_DOCK_PRODUCT:
    case LoadingDock.ADD_LOADING_DOCK_OBSTRUCTION:
    case LoadingDock.REMOVE_LOADING_DOCK_ITEM:
    case Install.UPDATE_INSTALL:
    case Layers.SET_VISIBLE_LAYERS: {
      return {
        ...state,
        needsSaved: get(action, 'payload.needsSaved', true),
      }
    }
    default: {
      return state
    }
  }
}
