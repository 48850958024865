import styled from 'styled-components'

const StyledIcon = styled.div<{ fullWidth?: boolean; swapDirection?: boolean }>`
  display: inline-flex;
  justify-content: space-between;
  ${props => props.fullWidth && 'width: 100%;'}
  ${props => props.swapDirection && 'flex-direction: row-reverse;'}
`

export default StyledIcon
