import { graphql } from '~/gql'

const QUOTE_FRAGMENT = graphql(`
  fragment QuoteFragment on Quote {
    id
    shippingPrice
    bundles {
      id
      name
      lineItems {
        id
        priceId
        listPrice
        salesPrice
        quantity
        meta
      }
    }
    opportunity {
      id
      name
      isWon
      isClosed
    }
    name
    salesforceQuoteId
    currencyISOCode
    discountReason {
      id
      name
    }
    discountNotes
  }
`)

export default QUOTE_FRAGMENT
