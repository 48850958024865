import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { graphql } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Helmet } from 'react-helmet'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'

import { getTitle } from 'config/titles'
import { CREATE_ENTRY_MUTATION } from 'client/mutations'
import { GET_QUOTE_QUERY } from 'client/queries'
import Button from 'components/UIKit/Button'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'
import { ButtonGroup } from './styled/ButtonGroup'
import TypeButton from './TypeButton'
import { LOG_USER_ACTION_MUTATION } from 'client/mutations'
import { handleLogUserAction } from 'client/handlers'

const SendToSalesHubModal = props => {
  const [entryName, setEntryName] = React.useState('')
  const [entryType, setEntryType] = React.useState('SITE_VISIT')
  const [entryCreated, setEntryCreated] = React.useState(false)
  return (
    <div>
      <Helmet>
        <title>{getTitle('sendToSalesHub')}</title>
      </Helmet>
      <Modal
        title="Send To SalesHub"
        primaryAction={
          <Mutation
            mutation={CREATE_ENTRY_MUTATION}
            variables={{
              name: entryName,
              type: entryType,
              facilityId: get(props, 'facility.id'),
              opportunityId: get(props, 'selectedOpportunity.id'),
            }}
            onCompleted={data => {
              setEntryCreated(true)
              const entryId = get(data, 'createEntry.id')
              if (entryId) {
                props.logUserAction({ action: 'send to saleshub' })
                window.open(
                  `https://hub.bigassfans.com/entries/${entryId}`,
                  '_blank'
                )
              }
            }}
          >
            {(createEntry, { loading, error }) => (
              <Button
                primary
                disabled={loading || entryCreated}
                label={entryCreated ? 'Entry Created' : 'Create Entry'}
                onClick={createEntry}
              />
            )}
          </Mutation>
        }
        secondaryAction={<Button to={props.parentRoute} label="Cancel" />}
      >
        <Space bottom="base">
          <TextField
            name="name"
            label="Entry Name"
            value={entryName}
            overrideValue={entryName}
            onChange={e => setEntryName(e.target.value)}
          />
        </Space>
        <Space>
          <ButtonGroup>
            <TypeButton
              label="Site Visit"
              onClick={e => setEntryType('SITE_VISIT')}
              type="SITE_VISIT"
              selectedType={entryType}
            />
            <TypeButton
              label="Cold Call"
              onClick={e => setEntryType('COLD_CALL')}
              type="COLD_CALL"
              selectedType={entryType}
            />
          </ButtonGroup>
        </Space>
      </Modal>
    </div>
  )
}

SendToSalesHubModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  loading: PropTypes.bool,
  parentRoute: PropTypes.string,
  logUserAction: PropTypes.func,
}

export default compose(
  graphql(GET_QUOTE_QUERY, {
    options: ({ facility }) => ({
      variables: {
        id: get(facility, 'floor.version.id'),
      },
      fetchPolicy: 'network-only',
    }),
    props: ({ data }) => ({
      selectedOpportunity: get(data, 'Version.quote.opportunity'),
      opportunityLoading: data.loading,
    }),
    skip: ({ facility }) => isEmpty(get(facility, 'floor.version.id')),
  }),
  graphql(LOG_USER_ACTION_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      logUserAction: ({ action }) =>
        handleLogUserAction({ action, mutate, ownProps }),
    }),
  })
)(SendToSalesHubModal)
