import get from 'lodash-es/get'
import Util from './util'
import ElevationPoint from './elevationPoint'
import Primitives from './primitives'
import { objectIsSelected } from 'store/selectedObjects/selectors'
import { isLayerVisible } from 'store/layers'
import store from 'store'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import { getThreeHexFromTheme } from 'lib/utils'
import * as THREE from 'three'
import CLICK_PRIORITY from 'config/clickPriority'

const COLOR = getThreeHexFromTheme('three.valid')
const SELECTED_COLOR = getThreeHexFromTheme('three.objects.elevation.selected')
const ERROR_COLOR = getThreeHexFromTheme('three.invalid')
const HOVER_RADIUS_MULTIPLIER = 5

class ElevationLine {
  constructor(model) {
    this.id = model.id
    this.className = CLASS_NAMES.ELEVATION_LINE
    this.roofId = model.roofId
    this.selectable = true
    this.isSelected = false
    this.height = model.height

    this.elevationPoints = []
    this.lineMeshes = []
    this.indicators = []

    this.layerKey = LAYER_KEYS.ROOFS
    this.clickPriority = CLICK_PRIORITY[this.layerKey]

    model.elevationPoints.forEach(elevationPointModel =>
      this.addElevationPoint(elevationPointModel)
    )

    this.createMesh()

    if (objectIsSelected(this.id)) {
      this.select()
    }
  }

  select() {
    const error = !this.allPointsInBounds()
    this.isSelected = true
    this.indicators.forEach(indicator => {
      indicator.material.color.setHex(error ? ERROR_COLOR : SELECTED_COLOR)
      indicator.scale.set(
        (indicator.scale.x = HOVER_RADIUS_MULTIPLIER),
        indicator.scale.y,
        (indicator.scale.z = HOVER_RADIUS_MULTIPLIER)
      )
    })
  }

  deselect() {
    const error = !this.allPointsInBounds()
    this.isSelected = false
    this.indicators.forEach(indicator =>
      indicator.material.color.setHex(error ? ERROR_COLOR : COLOR)
    )
  }

  allPointsInBounds() {
    return (
      this.elevationPoints.filter(ep => ep.isInRoofBounds()).length ===
      this.elevationPoints.length
    )
  }

  mouseEntered() {
    if (!this.isSelected) {
      this.indicators.forEach(indicator => {
        indicator.material.opacity = 1
        indicator.scale.set(
          (indicator.scale.x = HOVER_RADIUS_MULTIPLIER),
          indicator.scale.y,
          (indicator.scale.z = HOVER_RADIUS_MULTIPLIER)
        )
      })
    }
  }

  mouseExited() {
    if (!this.isSelected) {
      this.indicators.forEach(indicator => {
        indicator.material.opacity = 0.25
        indicator.scale.set(
          (indicator.scale.x = 1),
          indicator.scale.y,
          (indicator.scale.z = 1)
        )
      })
    }
  }

  addElevationPoint(epModel) {
    const ep = new ElevationPoint(epModel)
    ep.parentLine = this
    this.elevationPoints.push(ep)
  }

  elevationPointsHaveSameHeight() {
    for (let i = 0; i < this.elevationPoints.length; i += 1) {
      if (
        i > 0 &&
        this.elevationPoints[i].position.z !==
          this.elevationPoints[i - 1].position.z
      )
        return false
    }
    return true
  }

  recreateMesh() {
    const parent = this.obj3d.parent
    if (parent) parent.remove(this.obj3d)
    this.createMesh()
    if (parent) parent.add(this.obj3d)
  }

  createMesh() {
    const error = !this.allPointsInBounds()

    this.obj3d = new THREE.Object3D()
    this.elevationPoints.forEach(ep => this.obj3d.add(ep.obj3d))

    for (let i = 0; i < this.elevationPoints.length - 1; i += 1) {
      const ep1 = this.elevationPoints[i]
      const ep2 = this.elevationPoints[i + 1]

      const lineMesh = Primitives.getTubeMesh(
        ep1.position,
        ep2.position,
        error ? ERROR_COLOR : COLOR,
        2 * HOVER_RADIUS_MULTIPLIER // NOTE: 2 was the default thickness
      )
      lineMesh.material.opacity = 0.25
      lineMesh.wrapperId = this.id
      lineMesh.material.depthTest = true
      this.lineMeshes.push(lineMesh)

      const indicator = lineMesh.clone()
      indicator.material = indicator.material.clone()
      indicator.material.opacity = 0.25
      indicator.geometry = new THREE.CylinderGeometry(
        (get(lineMesh, 'geometry.parameters.radiusTop') || 0) /
          HOVER_RADIUS_MULTIPLIER,
        (get(lineMesh, 'geometry.parameters.radiusBottom') || 0) /
          HOVER_RADIUS_MULTIPLIER,
        get(lineMesh, 'geometry.parameters.height') || 0
      )

      this.indicators.push(indicator)

      this.obj3d.add(lineMesh)
      this.obj3d.add(indicator)
    }
  }

  visibilityChanged(visible) {
    if (this.obj3d)
      this.obj3d.visible =
        isLayerVisible(store.getState(), LAYER_KEYS.ELEVATION_LINE) && visible
  }

  toModel() {
    return {
      id: this.id,
      elevationPointIds: this.elevationPoints.map(ep => ep.id),
      roofId: this.roofId,
      height: this.height,
      layerKey: this.layerKey,
    }
  }

  static createModel(roofId, elevationPointIds, height) {
    return {
      id: Util.guid(),
      roofId,
      elevationPointIds,
      height,
    }
  }

  static getDenormalizedModel(model) {
    const currentState = store.getState().objects.present
    return {
      ...model,
      elevationPoints: model.elevationPointIds.map(
        epId => currentState.elevationPoints[epId]
      ),
    }
  }
}

export default ElevationLine
