import isEqual from 'lodash-es/isEqual'
import get from 'lodash-es/get'
import store from 'store'
import Util from './util'
import Units from './units'
import Camera from './camera'
import { updateComfortPoint } from 'store/objects'
import { Velocity } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'
import { objectIsSelected } from 'store/selectedObjects/selectors'
import zoomConfig from 'config/zoom'
import Facility from './facility'

import getRenderOrder from 'config/canvasRenderOrder'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import CLICK_PRIORITY from 'config/clickPriority'

import * as THREE from 'three'

class ComfortPoint {
  constructor(model, units) {
    this.layerKey = LAYER_KEYS.COMFORT_POINTS
    this.constructComfortPoint(model)
  }

  constructComfortPoint(model) {
    this.id = model.id || Util.guid()
    this.className = CLASS_NAMES.COMFORT_POINT
    this.clickPriority = CLICK_PRIORITY[this.layerKey]
    this.savedIndoorSummerTemp = model.savedIndoorSummerTemp
    this.savedIndoorWinterTemp = model.savedIndoorWinterTemp
    this.savedIndoorHumidity = model.savedIndoorHumidity
    this.savedPrimaryUse = model.savedPrimaryUse
    this.savedLevel = model.savedLevel

    const isSelectedObject = objectIsSelected(this.id)

    let texture = new THREE.TextureLoader().load('/comfort-point-sprite.png')
    if (isSelectedObject) {
      texture = new THREE.TextureLoader().load(
        '/comfort-point-selected-sprite.png'
      )
    }

    const spriteMaterial = new THREE.SpriteMaterial({
      map: texture,
      depthWrite: false,
      depthTest: false,
    })
    this.obj3d = new THREE.Sprite(spriteMaterial)

    this.obj3d.renderOrder = getRenderOrder('comfortPoint')

    // Used by Interactifier
    this.obj3d.wrapperId = this.id

    // Set position
    this.obj3d.position.copy(
      new THREE.Vector3(
        Units.inchesToNative(model.position.x),
        Units.inchesToNative(model.position.y),
        Units.inchesToNative(8)
      )
    )
    this.position = this.obj3d.position

    // rotate cylinder upright
    this.obj3d.rotation.x = Math.PI / 2

    // Used by Interactifier
    this.selectable = true
    this.draggable = false
    this.multiDraggable = true

    // Set the scale of the sprite based on the camera zoom
    let scale
    const camera = Camera.current

    if (camera.type === 'PerspectiveCamera') {
      const vector = new THREE.Vector3()
      const scaleFactor = zoomConfig.comfortPointScaleFactor
      const defaultDepth = zoomConfig.comfortPointDefaultDepth
      scale =
        (scaleFactor *
          vector
            .setFromMatrixPosition(this.obj3d.matrixWorld)
            .sub(camera.position)
            .length()) /
        defaultDepth
    } else {
      scale =
        (zoomConfig.comfortPointBase - camera.zoom * 100) *
        zoomConfig.comfortPointMultiplier
    }

    // Make sure the scale does not drop below 5
    if (scale < 10) scale = 10
    this.obj3d.scale.set(scale, scale, scale)

    if (isSelectedObject) {
      this.select()
    }
  }

  visibilityChanged(visible) {
    if (this.obj3d) this.obj3d.visible = visible
  }

  async select(draggable) {
    const isLocked = store.getState().layers.layers[LAYER_KEYS.COMFORT_POINTS]
      .locked
    this.draggable = !isLocked
    if (draggable === false) {
      this.draggable = false
    }
    const velocity = this.getAirflowVelocityAverage()

    // Update only if we have new air velocity
    const oldValue = get(this.velocity, 'value')
    const newValue = get(velocity, 'value')
    if (newValue && newValue !== oldValue) {
      this.velocity = velocity

      store.dispatch(
        updateComfortPoint({
          comfortPoint: {
            id: this.id,
            position: {
              x: Units.nativeToInches(this.position.x),
              y: Units.nativeToInches(this.position.y),
              z: Units.nativeToInches(this.position.z),
            },
            velocity,
          },
        })
      )
    }
  }

  static comfortsPointsAreEqual = (cp1, cp2) => isEqual(cp1, cp2)

  getAirflowVelocities() {
    const { airflow } = Facility.current
    const position = this.obj3d.position
    const velocities = []

    // If no airflow data, return null so we don't display old velocities
    if (!airflow || !airflow.length) return null

    airflow.forEach(ad => {
      const wallPoints = ad.wall.insetPoints.map(point => [
        point[0] + ad.wall.obj3d.position.x,
        point[1] + ad.wall.obj3d.position.y,
      ])
      if (Util.isPointInPolygon(position, wallPoints)) {
        const boundingBox = new THREE.Box3().setFromObject(ad.obj3d)
        const gridUnitSize = Units.unitsToNative(ad.units, ad.gridSize)
        const { velocities: airflowVelocities } = ad

        const minX = boundingBox.min.x
        const minY = boundingBox.min.y

        const xDiff = position.x - minX
        const yDiff = position.y - minY
        const cols = xDiff / gridUnitSize
        const rows = yDiff / gridUnitSize
        const lowerX = Math.floor(cols)
        const upperX = Math.ceil(cols)
        const lowerY = Math.floor(rows)
        const upperY = Math.ceil(rows)

        if (lowerX !== upperX && lowerY !== upperY) {
          velocities.push(
            airflowVelocities[lowerY][lowerX],
            airflowVelocities[lowerY][upperX],
            airflowVelocities[upperY][lowerX],
            airflowVelocities[upperY][upperX]
          )
        } else if (lowerX !== upperX && lowerY === upperY) {
          velocities.push(
            airflowVelocities[lowerY][lowerX],
            airflowVelocities[lowerY][upperX]
          )
        } else if (lowerX === upperX && lowerY !== upperY) {
          velocities.push(
            airflowVelocities[lowerY][lowerX],
            airflowVelocities[upperY][lowerX]
          )
        } else {
          velocities.push(airflowVelocities[lowerY][lowerX])
        }
      }
    })

    return velocities.length
      ? velocities.map(
          velocity =>
            new Velocity({
              value: velocity,
              system: SYSTEMS.IMPERIAL,
            })
        )
      : []
  }

  getAirflowVelocityAverage(velocities = this.getAirflowVelocities()) {
    if (!velocities || !velocities.length) return

    const avgVelocity = new Velocity({ value: 0, system: SYSTEMS.IMPERIAL })

    velocities.forEach(velocity => (avgVelocity.value += velocity.value))
    avgVelocity.value /= velocities.length

    return avgVelocity
  }

  getStandingVelocity() {
    const velocities = this.getAirflowVelocities()
    if (!velocities || !velocities.length) return

    const standingPositions = [velocities[0], velocities[2], velocities[3]]

    return this.getAirflowVelocityAverage(standingPositions)
  }

  getSeatedVelocity() {
    const velocities = this.getAirflowVelocities()
    if (!velocities || !velocities.length) return

    const seatedPositions = [velocities[0], velocities[1], velocities[2]]

    return this.getAirflowVelocityAverage(seatedPositions)
  }

  drop(_, __, saveModel = true) {
    if (saveModel) {
      store.dispatch(
        updateComfortPoint({
          comfortPoint: this.toModel(),
        })
      )
    }
  }

  toModel() {
    const velocity = this.getAirflowVelocityAverage()

    return {
      id: this.id,
      position: {
        x: Units.nativeToInches(this.position.x),
        y: Units.nativeToInches(this.position.y),
        z: Units.nativeToInches(this.position.z),
      },
      velocity,
      savedIndoorSummerTemp: this.savedIndoorSummerTemp,
      savedIndoorWinterTemp: this.savedIndoorWinterTemp,
      savedIndoorHumidity: this.savedIndoorHumidity,
      savedPrimaryUse: this.savedPrimaryUse,
      savedLevel: this.savedLevel,
    }
  }

  getSnappableEdgePoints() {
    return []
  }

  drag({ dragDelta }) {
    const isLocked = store.getState().layers.layers[LAYER_KEYS.COMFORT_POINTS]
      .locked
    if (isLocked) {
      return
    }
    this.obj3d.position.add(dragDelta)
  }

  snap(snapDelta) {
    return
  }

  destroy() {
    return
  }
}

export default ComfortPoint
