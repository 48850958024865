import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import TemplatesModal from '../TemplatesModal'

import LShapeScreen from './screens/LShapeScreen'
import RectangleScreen from './screens/RectangleScreen'

const screens = [
  {
    key: 'rectangle',
    title: 'Rectangle',
    component: RectangleScreen,
    icon: 'rectangle',
  },
  { key: 'l-shape', title: 'L-Shape', component: LShapeScreen, icon: 'lshape' },
]

const ShapeTemplatesModal = props => (
  <TemplatesModal title="Shape Templates" screens={screens} {...props} />
)

ShapeTemplatesModal.propTypes = {
  props: PropTypes.object,
  history: PropTypes.object,
  parentRoute: PropTypes.string,
}

export default withRouter(ShapeTemplatesModal)
