import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.div`
  margin-top: ${theme('spacing.base')};
  text-align: center;
`

export default Container
