import React, { useState, useEffect, useMemo, createContext, useCallback } from 'react'
import { compose } from 'redux'
import withProps from 'recompact/withProps'

export const NetworkContext = createContext()

function NetworkProvider(props) {
  const [apiStatus, setAPIStatus] = useState(true) // API status
  const [online, setOnline] = useState(true) // API and network status, used to determine app functionality
  const [status, setStatus] = useState(true) // Network status
  const [showReloadNotification, setShowReloadNotification] = useState(false)

  function updateStatus(event, forceOffline) {
    setStatus(forceOffline ? false : navigator.onLine)
  }

  function updateAPIStatus(event) {
    setAPIStatus(event.detail.status)
  }

  const value = useMemo(
    () => ({
      apiStatus,
      online,
      status,
      showReloadNotification,
    }),
    [online, apiStatus, status, showReloadNotification]
  )

  const pollForETag = useCallback(function () {
    const obj = this
    fetch(window.location.href)
      .then(resp => {
        const newEtag = resp.headers.get('etag')
        if (!obj.etag) {
          obj.etag = newEtag
        }
        const oldEtag = obj.etag
        if (newEtag !== oldEtag) {
          setShowReloadNotification(true)
        }
        obj.etag = newEtag
      }, () => {})
      .catch(err => {})
  }, [])

  useEffect(() => {
    const interval = setInterval(pollForETag, 5 * 60_000)
    return () => clearInterval(interval)
  }, [pollForETag])

  useEffect(() => {
    setOnline(status && apiStatus)
  }, [status, apiStatus])

  useEffect(() => {
    window.addEventListener('online', event => updateStatus(event, false))
    window.addEventListener('offline', event => updateStatus(event, false))
    window.addEventListener('forceOffline', event => updateStatus(event, true))
    window.addEventListener('apiStatus', event => updateAPIStatus(event))
  }, [])

  return <NetworkContext.Provider value={value} {...props} />
}

function useNetwork() {
  const context = React.useContext(NetworkContext)

  if (!context) {
    throw new Error('useNetwork must be used within a NetworkProvider')
  }

  return context
}

const withNetwork = compose(
  withProps(props => {
    const network = useNetwork()

    return {
      apiStatus: network.apiStatus,
      online: network.online,
      status: network.status,
      showReloadNotification: network.showReloadNotification,
    }
  })
)

export { NetworkProvider, useNetwork, withNetwork }
