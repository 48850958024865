import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import PropTypes from 'prop-types'
import * as filestack from 'filestack-js'

import FILESTACK_API_KEY from 'config/filestack'
import routes from 'config/routes'
import { getTitle } from 'config/titles'
import { appendURL } from 'lib/utils'
import { updateBackgroundImage } from 'store/objects'

import Modal from 'components/UIKit/Modal'

class EditImageModal extends Component {
  static propTypes = {
    props: PropTypes.object,
    parentRoute: PropTypes.string,
    history: PropTypes.object,
    match: PropTypes.object,
    backgroundImage: PropTypes.object,
    onUpdateBackgroundImage: PropTypes.func,
  }

  componentDidMount() {
    this.client = filestack.init(FILESTACK_API_KEY)
    this.handleLoadImage(this.props.backgroundImage)
  }

  componentWillUnmount() {
    this.closePicker()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.props.backgroundImage.BACKGROUND_IMAGE &&
      nextProps.backgroundImage.BACKGROUND_IMAGE
    ) {
      this.handleLoadImage(nextProps.backgroundImage)
    }
  }

  createPicker = () => {
    this.picker = this.client.picker({
      onUploadDone: this.handleFileUploadFinished,
      customText: {
        Save: 'Apply',
        Upload: 'Save',
      },
      displayMode: 'inline',
      container: '#filestack-ref',
    })
  }

  openPicker = backgroundImage => {
    this.picker.crop([backgroundImage.BACKGROUND_IMAGE.src])
  }

  closePicker = () => {
    if (this.picker) this.picker.close()
  }

  handleLoadImage = backgroundImage => {
    if (
      backgroundImage &&
      backgroundImage.BACKGROUND_IMAGE &&
      backgroundImage.BACKGROUND_IMAGE.src
    ) {
      this.createPicker()
      this.openPicker(backgroundImage)
    }
  }

  handleClose = () => {
    this.props.history.push(
      this.props.match.url.replace(routes.modals.editImage, '')
    )
  }

  handleFileUploadFinished = files => {
    files.filesUploaded.forEach(file => {
      this.client
        .metadata(file.handle, { width: true, height: true })
        .then(res => {
          this.handleSave({
            file,
            res,
          })
        })
        .catch(err => {})
    })
  }

  handleSave = async ({ file, res }) => {
    const { history, parentRoute } = this.props
    const { url } = file
    const { width, height } = res

    this.props.onUpdateBackgroundImage({
      ...this.props.backgroundImage.BACKGROUND_IMAGE,
      src: url,
      originalWidth: width,
      originalHeight: height,
      width,
      height,
      // Reset scaling so the user can start from scratch
      referenceLength: null,
      point1X: null,
      point1Y: null,
      point2X: null,
      point2Y: null,
    })
    history.push(appendURL(parentRoute, routes.modals.scaleImage))
  }

  render() {
    const { parentRoute, history, props } = this.props
    return (
      <div>
        <Helmet>
          <title>{getTitle('editImage')}</title>
        </Helmet>
        <Modal
          title="Edit Image"
          parentRoute={parentRoute}
          history={history}
          onClose={() => this.handleClose()}
          {...props}
        >
          <div id="filestack-ref" />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ objects }) => ({
  backgroundImage: objects.present.backgroundImage,
})

const mapDispatchToProps = dispatch => ({
  onUpdateBackgroundImage(backgroundImage) {
    dispatch(updateBackgroundImage({ backgroundImage }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withRouter
)(EditImageModal)
