export interface UtilityBox {
  utilityBoxType: string
  width: number
  height: number
  centerPointHeight: number
  color: number
}

export const firePanel: UtilityBox =
  {
    utilityBoxType: 'firePanel',
    width: 36,
    height: 48,
    centerPointHeight: 72,
    color: 0xff0000,
  }

export const electricPanel: UtilityBox =
  {
    utilityBoxType: 'electricPanel',
    width: 36,
    height: 48,
    centerPointHeight: 72,
    color: 0x0000ff,
  }

export const controlPanel: UtilityBox =
  {
    utilityBoxType: 'controlPanel',
    width: 18,
    height: 18,
    centerPointHeight: 72,
    color: 0x000000,
  }

export const dewtectSensor: UtilityBox =
  {
    utilityBoxType: 'dewtectSensor',
    width: 12,
    height: 16,
    centerPointHeight: 72,
    color: 0xbab86c,
  }
