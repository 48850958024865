import styled from 'styled-components'

import { theme } from 'lib/styleUtils'
import { getZIndexOf } from 'lib/utils'
import { motion } from 'framer-motion'

const Overlay = styled(motion.div)`
  background-color: ${theme('colors.dark.bg')};
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.85;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${getZIndexOf('modalOverlay')};
`

export default Overlay
