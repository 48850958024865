import { ReactNode } from 'react'
import Container from './styled/Container'
import Media from './styled/Media'
import Content from './styled/Content'

const Bucket = ({ media, content, centered }: { media: ReactNode; content: ReactNode; centered?: boolean }) => (
  <Container centered={centered}>
    <Media>{media}</Media>
    <Content>{content}</Content>
  </Container>
)

export default Bucket
