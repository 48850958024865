import Util from './util'
import Units from './units'

class Edge {
  constructor(p1, p2) {
    this.p1 = p1
    this.p2 = p2
    this.intersectionPoints = []
  }

  /*
    An edge may have up to two intersection points (one for each
    end of the polyline intersecting the region). This insures the
    points are stored in order of nearest distance to this.p1.
  */
  addIntersectionPoint(point) {
    if (this.intersectionPoints.length === 0) {
      this.intersectionPoints[0] = point
    } else {
      const curPointDist = this.intersectionPoints[0]
        .clone()
        .sub(this.p1)
        .length()
      const newPointDist = point.clone().sub(this.p1).length()

      if (newPointDist < curPointDist) {
        const temp = this.intersectionPoints[0]
        this.intersectionPoints[0] = point
        this.intersectionPoints[1] = temp
      } else {
        this.intersectionPoints[1] = point
      }
    }
  }

  isIntersected() {
    return this.intersectionPoints.length > 0
  }

  pointOverlapsEndPoint(point, distanceThreshold = Units.inchesToNative(1)) {
    const dist1 = point.clone().sub(this.p1).length()
    const dist2 = point.clone().sub(this.p2).length()

    return dist1 < distanceThreshold || dist2 < distanceThreshold
  }
}

class Node {
  constructor(point) {
    this.point = point
  }

  connect(node) {
    this.next = node
    node.prev = this
  }
}

class Region {
  constructor(points = []) {
    this.children = []
    this.edges = []

    if (points.length > 0) {
      this.points = Util.getCounterClockwisePoints(points)
      this.points = Util.toVec2Array(this.points)
      this.buildEdgesFromPoints()
    }
  }

  /*
    Finds the two sub-Regions formed by splitting this region along the given
    polyline. If the polyline doesn't intersect the Region at both ends, then
    'undefined' is returned; otherwise it returns an array of two Regions.
  */
  split(polyline) {
    polyline = Util.toVec2Array(polyline)
    const firstPolylinePoint = polyline[0]
    const lastPolylinePoint = polyline[polyline.length - 1]

    const regionCanBeSplit = this.markIntersectedEdges(
      firstPolylinePoint,
      lastPolylinePoint
    )

    if (regionCanBeSplit) {
      this.buildVertexGraph()

      const subRegions = [
        this.getSubRegionFromGraph(polyline, true),
        this.getSubRegionFromGraph(polyline, false),
      ]

      this.clearMarkedEdges()

      if (
        !Util.isPolygonValid(subRegions[0].points) ||
        !Util.isPolygonValid(subRegions[1].points)
      ) {
        console.error(
          'Region split operation resulted in at least one invalid region:',
          subRegions[0].points,
          subRegions[1].points
        )
      }

      return subRegions
    }

    this.clearMarkedEdges()

    return undefined
  }

  /*
    If a region is splittable by the given polyline, its two sub-regions may be
    collected by walking the vertex graph for the region (with nodes inserted at
    the points where the polyline intersects its edges) starting from the second
    intersection node and following edges to the first, clockwise for one region
    and counter for the other. This gives us a full sub-region except for the
    polyline itself, which is added next. This returns a Region built in that
    way from an existing vertex graph.
  */
  getSubRegionFromGraph(polyline, clockwise) {
    const firstIntersectionPoint = polyline[0]
    const lastIntersectionPoint = polyline[polyline.length - 1]
    const firstIntersectionNode = this.nodeForPoint(firstIntersectionPoint)
    const lastIntersectionNode = this.nodeForPoint(lastIntersectionPoint)

    console.assert(
      firstIntersectionNode && lastIntersectionNode,
      'Unable to find either first or last intersection nodes: ',
      firstIntersectionNode,
      lastIntersectionNode
    )

    let currentNode = lastIntersectionNode

    const regionPoints = []
    const visited = []
    while (!visited.includes(currentNode)) {
      if (!currentNode) break

      if (currentNode.point) {
        regionPoints.push(currentNode.point)
      }

      if (currentNode === firstIntersectionNode) {
        for (let j = 1; j < polyline.length - 1; j += 1) {
          regionPoints.push(polyline[j])
        }

        break
      }

      visited.push(currentNode)

      if (clockwise) {
        if (currentNode.next) currentNode = currentNode.next
      } else {
        if (currentNode.prev) currentNode = currentNode.prev
      }
    }

    if (regionPoints.length) regionPoints.push(regionPoints[0].clone())

    return new Region(regionPoints)
  }

  /*
    Walks the edges of this region, creating and connecting together graph nodes
    for each end of an edge (i.e. for each vertex describing the region's shape).
    In total, two intersection points should be stored on the walked edge objects
    (one for each end of the polyline used to create a split); they may be on a
    single Edge or on two different Edges. Those intersection points are also
    connected in sequence in the graph.
  */
  buildVertexGraph() {
    this.nodes = []

    this.edges.forEach(edge => {
      const edgeNode1 = this.nodeForPoint(edge.p1, true)
      const edgeNode2 = this.nodeForPoint(edge.p2, true)

      if (edge.isIntersected()) {
        const intersectionNode1 = this.nodeForPoint(
          edge.intersectionPoints[0],
          true
        )

        edgeNode1.connect(intersectionNode1)

        let lastIntersectionNode = intersectionNode1
        if (edge.intersectionPoints[1]) {
          const intersectionNode2 = this.nodeForPoint(
            edge.intersectionPoints[1],
            true
          )
          intersectionNode1.connect(intersectionNode2)
          lastIntersectionNode = intersectionNode2
        }

        lastIntersectionNode.connect(edgeNode2)
      } else {
        edgeNode1.connect(edgeNode2)
      }
    })
  }

  /*
    Given the points at either end of the splitting polyline, we look for
    edges of this region which are beneath these points (with a threshold distance).
    In order for a split of this region to be valid, both of the given points must
    lie on some edge. This method stores the intersection points on the appropriate
    edges and returns whether the split will be possible or not.
  */
  markIntersectedEdges(firstPolylinePoint, lastPolylinePoint) {
    let intersectionCount = 0
    let endPointOverlaps = 0

    const edge1 = Region.findEdgeUnderPoint(firstPolylinePoint, this.edges)
    const edge2 = Region.findEdgeUnderPoint(lastPolylinePoint, this.edges)

    if (edge1) {
      if (edge1.pointOverlapsEndPoint(firstPolylinePoint)) {
        endPointOverlaps += 1
      } else {
        edge1.addIntersectionPoint(firstPolylinePoint)
        intersectionCount += 1
      }
    }

    if (edge2) {
      if (edge2.pointOverlapsEndPoint(lastPolylinePoint)) {
        endPointOverlaps += 1
      } else {
        edge2.addIntersectionPoint(lastPolylinePoint)
        intersectionCount += 1
      }
    }

    const regionCanBeSplit = intersectionCount + endPointOverlaps === 2

    return regionCanBeSplit
  }

  clearMarkedEdges() {
    this.edges.forEach(edge => {
      edge.intersectionPoints.length = 0
    })
  }

  static findEdgeUnderPoint(
    point,
    edges,
    distanceThreshold = Units.inchesToNative(6)
  ) {
    const edgeUnder = edges.find(edge => {
      const dist = Util.distanceToLineSegment(
        edge.p1.x,
        edge.p1.y,
        edge.p2.x,
        edge.p2.y,
        point.x,
        point.y
      )
      return dist <= distanceThreshold
    })

    return edgeUnder
  }

  /*
    When building the graph we want to re-use the same Nodes, indexing them
    by the position in space they're associated with. We use a threshold for
    considering a point in space to be equivalent, so that small discrepencies
    in position are ignored (e.g. you have to edges meeting at a point, but one
    point differs by .00001 on X).
  */
  nodeForPoint(point, allowConstruction) {
    let node = this.nodes.find(
      curNode =>
        curNode.point.clone().sub(point).length() < Units.inchesToNative(0.1)
    )

    if (!node && allowConstruction) {
      node = new Node(point)
      this.nodes.push(node)
    }

    return node
  }

  findDeepestRegionContainingPoint(point) {
    return this.findDeepestRegionContainingPointAux(point, this)
  }

  findDeepestRegionContainingPointAux(point, region) {
    const regions = region.getChildren()
    let childWithPoint

    for (let i = 0; i < regions.length; i += 1) {
      const curRegion = regions[i]

      if (curRegion.containsPoint(point)) {
        childWithPoint = curRegion
        break
      }
    }

    if (childWithPoint) {
      return this.findDeepestRegionContainingPointAux(point, childWithPoint)
    }

    return region
  }

  containsPoint(point) {
    return Util.isPointInPolygon(point, this.points)
  }

  buildEdgesFromPoints() {
    this.edges = []

    for (let i = 0; i < this.points.length - 1; i += 1) {
      const edge = new Edge(this.points[i], this.points[i + 1])
      this.edges.push(edge)
    }
  }

  containsRegion(region) {
    const bigger =
      Util.polygonArea(this.points) - Util.polygonArea(region.points) > 0
    const containsCenter = Util.isPointInPolygon(
      Util.polygonCentroid(region.points),
      this.points
    )

    return bigger && containsCenter
  }

  addChild(region, checkForNesting) {
    // If the Region to be added spatially contains one of the
    // present children remove it as a child of this Region and
    // make a child of the Region to be added.
    if (checkForNesting) {
      for (let i = this.children.length - 1; i > -1; i -= 1) {
        const child = this.children[i]
        if (region.containsRegion(child)) {
          region.addChild(child)
          Util.remove(this.children, child)
        }
      }
    }

    this.children.push(region)
  }

  getChildren() {
    return this.children
  }
}

export default Region
