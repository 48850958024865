import { graphql } from '~/gql'

export const GET_VERSION_FACILITY = graphql(`
  query GetVersionFacility($versionId: ID!) {
    Version(id: $versionId) {
      id
      floor {
        id
        facility {
          id
          primaryObjective
          primaryUse
          indoorSummerTemp
          indoorWinterTemp
          indoorHumidity
          indoorWinterHumidity
          primaryType
        }
      }
    }
  }
`)
