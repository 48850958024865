import { useAppDispatch } from "~/store/hooks"
import { AugmentedModel, average, become3, inchesToNative2 } from "../util"
import { ColorRepresentation, Vector3 } from "three"
import { addColumnLine, addMountingBeam, removeColumnLine, removeMountingBeam } from "~/store/objects/actions"
import Util from "../../lib/util"
import { Circle, Text } from "@react-three/drei"

export function MountingStructureLineButtons(props: { thisModel: AugmentedModel; nextModel: AugmentedModel | undefined; roofSectionId: string; type: 'beam' | 'column'; color: ColorRepresentation; textZ: number; circleZ: number; orthogonal: Vector3 }) {
  const { thisModel, nextModel, orthogonal, circleZ, textZ } = props
  const dispatch = useAppDispatch()
  const averageThis = inchesToNative2(average(thisModel.point1, thisModel.point2))

  return (
    <>
      {nextModel && (
        <group onClick={e => {
          e.stopPropagation()
          const wallPositionA = new Vector3().copy(thisModel.position)
          const wallPositionB = new Vector3().copy(nextModel.position)
          const newPosition = new Vector3().addVectors(wallPositionA, wallPositionB).multiplyScalar(0.5)
          const modelPosition = { x: newPosition.x, y: newPosition.y, z: newPosition.z }

          if (props.type === "beam") {
            dispatch(
              addMountingBeam({
                roofSectionId: props.roofSectionId,
                beamId: Util.guid(),
                previousBeamId: thisModel.id,
                beamPosition: modelPosition,
              })
            )
          } else if (props.type === 'column') {
            dispatch(
              addColumnLine({
                roofSectionId: props.roofSectionId,
                columnLineId: Util.guid(),
                previousColumnLineId: thisModel.id,
                columnLinePosition: modelPosition,
              })
            )
          }
        }}>
          <Circle args={[5, 20]} position={become3(averageThis.clone().addScaledVector(orthogonal, props.type === 'column' ? -10 : 10), circleZ)} material-color={props.color}/>
          <Text fontSize={8} position={become3(averageThis.clone().addScaledVector(orthogonal, props.type === 'column' ? -10 : 10), textZ)} color="black">+</Text>
        </group>
      )}
      <group onClick={e => {
        e.stopPropagation()
        if (props.type === 'beam') {
          dispatch(
            removeMountingBeam({
              roofSectionId: props.roofSectionId,
              beamId: thisModel.id,
            })
          )
        } else if (props.type === 'column') {
          dispatch(
            removeColumnLine({
              roofSectionId: props.roofSectionId,
              columnLineId: thisModel.id,
            })
          )
        }
      }}>
        <Circle args={[5, 20]} position={become3(averageThis.clone().addScaledVector(orthogonal, props.type === 'column' ? 10 : -10), circleZ)} material-color={props.color}/>
        <Text fontSize={8} position={become3(averageThis.clone().addScaledVector(orthogonal, props.type === 'column' ? 10 : -10), textZ)} color="black">-</Text>
      </group>
    </>
  )
}
