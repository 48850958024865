import withProps from 'recompact/withProps'
import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'
import Select from 'components/UIKit/Select'
import ApolloClient from 'client'
import { graphql } from '~/gql'

// NOTE: This query would be better served if it was specific
// to the mounting options
const withVariationsMountingOptionAdders = withProps(ownProps => {
  const variation = ApolloClient.readFragment({
    id: `ProductVariation:${ownProps.productVariationId}`,
    fragment:
      graphql(`fragment MountingStructuresSelect on ProductVariation {
        mountingOptionAdders {
          id
          mountingType
        }
      }`)
  })
  const mountingOptionAdders = get(variation, 'mountingOptionAdders') || []
  const options =
    mountingOptionAdders.length > 1
      ? [{ value: 'Unknown', label: 'Unknown', disabled: false }]
      : []

  return {
    disabled: ownProps.disabled,
    options: [
      { value: '', label: 'Select...', disabled: true },
      ...options,
      ...sortBy(mountingOptionAdders, 'mountingType').map(o => ({
        value: o.id,
        label: o.mountingType,
      })),
    ],
  }
})

const MountingStructuresSelect = withVariationsMountingOptionAdders(Select)
MountingStructuresSelect.displayName = 'MountingStructuresSelect'

export default /** @type {any} */(MountingStructuresSelect)
