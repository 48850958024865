import React from 'react'

import { PERFORMANCE_METRICS_PANEL } from 'store/panel/types'

import Panel from 'components/UIKit/Panel'
import PerformanceMetrics from '../../PerformanceMetrics'

function PerformanceMetricsPanel(props = {}) {
  return (
    <Panel
      title="Performance Metrics"
      landscape
      panelKey={PERFORMANCE_METRICS_PANEL}
      width="500px"
      flushRight
    >
      <PerformanceMetrics />
    </Panel>
  )
}

export default PerformanceMetricsPanel
