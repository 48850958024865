import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import A from '../../A'

const HelpLink = styled(A)`
  border-bottom: none;
  color: ${theme('colors.light.fg')};
  font-size: ${theme('fonts.sizes.xs')};
  margin-left: ${theme('spacing.xs')};
  opacity: 0.4;

  &:active,
  &:focus,
  &:hover {
    opacity: 1;
  }
`

export default HelpLink
