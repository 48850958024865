import { DragControls, DragControlsProps } from '@react-three/drei/web/DragControls'
import { ThreeEvent, useThree } from '@react-three/fiber'
import { useRef } from 'react'
import { Material, Matrix4, Vector3 } from 'three'
import { BackgroundImageMaterial } from '~/components/DrawingCanvas/BackgroundImage/components/BackgroundImageMaterial'
import { useBackgroundImage } from '~/components/DrawingCanvas/BackgroundImage/hooks/useBackgroundImage'
import Units from '~/components/DrawingCanvas/lib/units'
import LayerKeys from '~/config/layerKeys'
import ObjectTypes from '~/config/objectTypes'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { setCurrentLayer } from '~/store/layers'
import { updateBackgroundImage } from '~/store/objects'
import { showPanel } from '~/store/panel'
import { SELECTED_BACKGROUND_IMAGE_PANEL } from '~/store/panel/types'

export const BackgroundImageMesh = () => {
  const backgroundImage = useBackgroundImage()
  const { width, height, rotation } = backgroundImage
  const { x, y, z } = backgroundImage.position

  const { raycaster, scene } = useThree()
  const { visible, locked } = useAppSelector(({ layers }) => layers.layers.BACKGROUND_IMAGE)
  const isMoveTool = useAppSelector(({ tools }) => tools.activeTool === 'MOVE_TOOL')
  const dragMatrix = useRef(new Matrix4().setPosition(new Vector3(x, y, z)))

  const dispatch = useAppDispatch()

  const handleDrag: DragControlsProps['onDrag'] = local => {
    if (locked || !isMoveTool) return
    dragMatrix.current.copy(local)
  }

  const handleDragEnd = () => {
    const position = new Vector3().setFromMatrixPosition(dragMatrix.current)
    const [x, y, z] = position.toArray()
    dispatch(
      updateBackgroundImage({
        backgroundImage: {
          position: { x, y, z },
          width: Units.nativeToInches(width),
          height: Units.nativeToInches(height),
        },
      })
    )
  }

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    // TODO: remove this after refactoring other components to R3F. Stop propagation on other components before it reaches the bg image.
    const { distance } = e
    const occludingObjects = raycaster.intersectObjects(scene.children).filter(intersection => {
      const isOccluding = intersection.distance < distance
      const isVisible = intersection.object.visible
      const isOpaque =
        'material' in intersection.object &&
        intersection.object.material instanceof Material &&
        (intersection.object.material?.opacity ?? 0) > 0
      const isFloor = intersection.object.userData.objectType === ObjectTypes.FLOOR
      return isOccluding && isVisible && isOpaque && !isFloor
    })
    if (!visible || locked || occludingObjects.length) return
    dispatch(setCurrentLayer({ layerKey: LayerKeys.BACKGROUND_IMAGE }))
    dispatch(showPanel({ type: SELECTED_BACKGROUND_IMAGE_PANEL }))
  }

  return (
    <DragControls
      dragConfig={{ enabled: isMoveTool }}
      axisLock="z"
      autoTransform={false}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      matrix={dragMatrix.current}
    >
      <mesh
        name="background-image"
        visible={visible}
        onClick={handleClick}
        rotation={[rotation.x, rotation.y, (rotation.z * Math.PI) / 180]}
      >
        <BackgroundImageMaterial />
        <planeGeometry args={[width, height]} />
      </mesh>
    </DragControls>
  )
}
