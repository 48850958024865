import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Camera = styled.div`
  background-color: ${theme('colors.dark.bg')};
  position: relative;
`

export default Camera
