import styled from 'styled-components'
import { getZIndexOf } from 'lib/utils'
import theme from 'config/theme'

const ContextMenuContainer = styled.div`
  background-color: ${theme.colors.light.bg};
  box-shadow: ${theme.shadows.menu};
  height: auto;
  left: ${props => `${props.screenPos.x}px`};
  position: absolute;
  top: ${props => `${props.screenPos.y}px`};
  width: ${theme.contextMenu.width};
  z-index: ${getZIndexOf('contextMenu')};
`

export default ContextMenuContainer
