import styled, { ThemedStyledProps, css } from 'styled-components'

import { breakpoints, truncate, theme } from 'lib/styleUtils'
import { Spacing, Theme } from '~/config/theme'

const setSize = (props: ThemedStyledProps<{ size?: Spacing }, Theme>) => {
  if (props.size === 's') {
    return css`
      font-size: ${theme('fonts.sizes.xs')};
    `
  }

  return css`
    font-size: ${theme('fonts.sizes.s')};
  `
}

const Label = styled.span<{ responsive?: boolean; truncate?: string; size?: Spacing }>`
  -webkit-appearance: none;
  flex-shrink: 0;

  ${props => props.responsive && `display: none;`};
  ${props => setSize(props)};
  ${props => props.truncate && truncate(props.truncate)};

  ${breakpoints.l`
    ${(props: any) => props.responsive && `display: block;`}
  `};
`

export default Label
