export const ADD_LOADING_DOCK_PRODUCT = 'ADD_LOADING_DOCK_PRODUCT'
export const ADD_LOADING_DOCK_DOOR = 'ADD_LOADING_DOCK_DOOR'
export const ADD_LOADING_DOCK_UTILITY_BOX = 'ADD_LOADING_DOCK_UTILITY_BOX'
export const ADD_LOADING_DOCK_OBSTRUCTION = 'ADD_LOADING_DOCK_OBSTRUCTION'
export const REMOVE_LOADING_DOCK_ITEM = 'REMOVE_LOADING_DOCK_ITEM'
export const SHOW_LOADING_DOCK = 'SHOW_LOADING_DOCK'
export const HIDE_LOADING_DOCK = 'HIDE_LOADING_DOCK'

export const addLoadingDockProduct = payload => ({
  type: ADD_LOADING_DOCK_PRODUCT,
  payload,
})

export const addLoadingDockObstruction = payload => ({
  type: ADD_LOADING_DOCK_OBSTRUCTION,
  payload,
})

export const addLoadingDockDoor = payload => ({
  type: ADD_LOADING_DOCK_DOOR,
  payload,
})

export const addLoadingDockUtilityBox = payload => ({
  type: ADD_LOADING_DOCK_UTILITY_BOX,
  payload,
})

export const removeLoadingDockItem = payload => ({
  type: REMOVE_LOADING_DOCK_ITEM,
  payload,
})

export const toggleLoadingDock = show => ({
  type: show ? SHOW_LOADING_DOCK : HIDE_LOADING_DOCK,
})
