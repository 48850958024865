import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Icon from 'components/UIKit/Icon'

const EditIcon = styled(Icon)`
  cursor: pointer;
  margin-left: ${theme('spacing.xs')};
`

export default EditIcon
