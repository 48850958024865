import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { theme } from 'lib/styleUtils'
import { Variant } from '~/config/theme'

const isDarkVariant = (props: { variant?: Variant; 'data-variant'?: Variant }) =>
  props.variant === 'dark' || props['data-variant'] === 'dark'
const styles = css<{ variant?: Variant; 'data-variant'?: Variant }>`
  color: ${props =>
    isDarkVariant(props)
      ? props.theme.colors.dark.fg
      : props.theme.colors.light.fg};
  display: block;
  padding: ${theme('spacing.xs')};
  text-decoration: none;
  cursor: pointer;
`

const ActionButtonContainer = styled.div<{ variant?: Variant; 'data-variant'?: Variant }>`
  ${styles};
`

const ActionAnchorContainer = styled.a<{ variant?: Variant; 'data-variant'?: Variant }>`
  ${styles};
`

const ActionLinkContainer = styled(Link)<{ variant?: Variant; 'data-variant'?: Variant }>`
  ${styles};
`

export { ActionButtonContainer, ActionAnchorContainer, ActionLinkContainer }
