import styled from 'styled-components'
import { Link } from 'react-router-dom'

const ImageLink = styled(Link)`
  transition: filter 200ms ease;
  width: 120px;
  ${({ disabled }) =>
    disabled
      ? 'pointer-events: none; filter: saturate(0) contrast(0.25);'
      : 'cursor: pointer; filter: saturate(1) contrast(1);'}
`

export default ImageLink
