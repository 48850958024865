import { graphql } from '~/gql'

export default graphql(`
  mutation DuplicateFloor($name: String!, $floorId: ID!) {
    floor: duplicateFloor(name: $name, floorId: $floorId) {
      ...FloorFragment
      version {
        ...VersionFragment
      }
    }
  }
`)
