import React, { useState } from 'react'
import { useAppSelector } from "~/store/hooks";
import { useHistory, useRouteMatch } from 'react-router-dom'
import { CFD_STATUS } from 'config/cfd'
import routes from 'config/routes'
import { useCFDImages } from '../hooks/useCFDImages'
import Tabs, { Tab } from 'components/UIKit/Tabs'
import { camelToTitleCase } from 'utils/string'
import { ImageDisplay } from '../subcomponents/ImageDisplay'
import { InProgressLoader } from '../subcomponents/InProgressLoader'
import { Container } from '../styled/Container'
import { MainImageContainer } from '../styled/MainImage'
import { SideBar } from '../styled/SideBar'
import { Thumbnail } from '../styled/Thumbnail'
import { UnitHeatImageDisplay } from '../subcomponents/UnitHeatImageDisplay'
import { Loader } from './Loader'

const routeMatcher = `${routes.facility.full}${routes.modals.cfdImages}`

export const ModalContent = () => {
  const { data, error, loading } = useCFDImages()
  const history = useHistory()
  const { goal } = useRouteMatch(routeMatcher).params
  const defaultView = data?.find(({ type }) => type === goal)?.imageUrls[0]?.view ?? ''
  const isUploading = useAppSelector(({ cfd }) => cfd.isUploading)
  const [selectedView, setSelectedView] = useState(defaultView)
  const safeSelectedView = selectedView || defaultView

  const handleTabClick = selectedType => {
    const newView = data?.find(({ type }) => type === selectedType)?.imageUrls[0]?.view
    if (newView) setSelectedView(newView)
    history.push(selectedType)
  }

  if (loading || isUploading) return <Loader />
  else if (error) return <p>Failed to load CFD data</p>

  const activeImages = data
    ?.find(({ type }) => goal === type)
    ?.imageUrls?.find(({ view }) => view === safeSelectedView)

  const activeTabIndex = Math.max(
    data.findIndex(({ type }) => goal === type),
    0
  )

  return (
    <Tabs size="s" activeIndex={activeTabIndex}>
      {data.map(({ type, status, imageUrls }) => (
        <Tab flush key={type} title={camelToTitleCase(type)} onClick={() => handleTabClick(type)}>
          {status === CFD_STATUS.IN_PROGRESS ? (
            <InProgressLoader />
          ) : (
            <Container>
              <SideBar>
                {imageUrls.map(({ view, thumbnail }) => (
                  <Thumbnail
                    key={view}
                    selected={view === safeSelectedView}
                    src={thumbnail}
                    onClick={() => setSelectedView(view)}
                  />
                ))}
              </SideBar>
              <MainImageContainer>
                {!activeImages ? (
                  <p>Image not found</p>
                ) : activeImages.unitHeatImages ? (
                  <UnitHeatImageDisplay imageGroup={activeImages} />
                ) : (
                  <ImageDisplay simulationType={goal} imageGroup={activeImages} />
                )}
              </MainImageContainer>
            </Container>
          )}
        </Tab>
      ))}
    </Tabs>
  )
}
