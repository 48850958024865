import styled from 'styled-components'

const Container = styled.div<{ inline?: boolean }>`
  ${props =>
    props.inline &&
    `
    align-items: center;
    display: flex;
  `};
`

export default Container
