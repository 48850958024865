import { useApolloClient } from '@apollo/client'
import { captureException } from '@sentry/react'
import { GET_DEFAULT_VARIATION } from '~/client/queries'
import { GetAllProductsQuery } from '~/gql/graphql'
import { guid } from '~/lib/utils'
import { useAppDispatch } from '~/store/hooks'
import { setActiveTool } from '~/store/tools'
import { MenuItem } from '~/ui/Menu'

type Product = NonNullable<GetAllProductsQuery['Products']>[number]

export const ProductOption = (product: Product) => {
  const client = useApolloClient()
  const dispatch = useAppDispatch()

  const handleClick = async () => {
    try {
      const { data } = await client.query({
        query: GET_DEFAULT_VARIATION,
        variables: { productId: product.id, isHeater: product.category === 'HEAT' },
      })
      const { defaultVariation } = data.Product ?? {}
      if (!defaultVariation) {
        throw new Error(`Product ${product.model} is missing a default variation`)
      }
      dispatch(
        setActiveTool({
          tool: 'PRODUCT_TOOL',
          props: {
            ...defaultVariation,
            id: guid(),
            variationId: defaultVariation.id,
            voltageId: defaultVariation.voltages?.[0].id,
            voltage: defaultVariation.voltages?.[0].inputPower,
            mountingOptionAdderId: defaultVariation.mountingOptionAdders?.[0]?.id ?? 'Unknown',
          },
        })
      )
    } catch (error) {
      captureException(error)
    }
  }

  return (
    <MenuItem
      className="cursor-pointer flex items-center gap-2 p-2.5 text-xs"
      onAction={handleClick}
    >
      <div className="relative w-[30px] h-[30px] bg-white">
        <img
          className={`absolute w-[30px] h-[30px] inset-0 object-contain`}
          src={new URL(`/src/assets/thumbnails/${product.model}.jpg`, import.meta.url).href}
          alt={product.model}
        />
      </div>
      {product.model}
    </MenuItem>
  )
}
