import React, { Component } from 'react'
import isEmpty from 'lodash-es/isEmpty'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { STATES } from 'config/productivity'
import PropTypes from 'prop-types'

import PC from 'lib/ProductivityCalculator'

import Button from 'components/UIKit/Button'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Select from 'components/UIKit/Select'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'

import Results from './Results'

import { LOG_USER_ACTION_MUTATION } from 'client/mutations'
import { handleLogUserAction } from 'client/handlers'

class ProductivityCalculator extends Component {
  state = {
    state: '',
    employees: '',
    avgWages: '',
    avgHours: '',
    results: {},
    canSubmit: false,
  }

  getStates = () =>
    STATES.map(state => {
      return {
        label: `${state.name} (${state.code})`,
        value: state.name,
      }
    })

  handleChange = event => {
    const { name, value } = event.target

    if (this.allFieldsFilled()) {
      this.setState({
        canSubmit: true,
      })
    }

    this.setState({
      [name]: value,
    })
  }

  allFieldsFilled = () => {
    const { state, employees, avgWages, avgHours } = this.state

    if (
      state !== '' &&
      employees !== '' &&
      avgWages !== '' &&
      avgHours !== ''
    ) {
      return true
    }
  }

  handleClick = event => {
    const { state, employees, avgWages, avgHours } = this.state
    const options = { state, employees, avgWages, avgHours }

    event.preventDefault()

    if (!this.allFieldsFilled()) return

    const productivity = new PC(options)

    this.props.logUserAction({ action: 'productivity calculator' })

    this.setState({
      results: {
        ...productivity.results,
      },
    })
  }

  renderResults = () => {
    const { results, state } = this.state

    return <Results results={results} state={state} />
  }

  render() {
    const {
      state,
      employees,
      avgWages,
      avgHours,
      results,
      canSubmit,
    } = this.state

    return (
      <div>
        <Space bottom="base">
          <h2>Productivity</h2>
        </Space>
        <Grid>
          <GridItem size="1of2">
            <Space bottom="base">
              <Select
                name="state"
                label="State"
                options={this.getStates()}
                onChange={this.handleChange}
                value={state}
                labelWidth="50px"
                inline
              />
            </Space>
            <Space bottom="base">
              <TextField
                name="employees"
                label="Number of Employees"
                onChange={this.handleChange}
                type="number"
                value={employees}
                width="100px"
                labelWidth="150px"
                inline
              />
            </Space>
            <Space bottom="base">
              <TextField
                name="avgWages"
                label="Avg. Hourly Wages"
                onChange={this.handleChange}
                value={avgWages}
                width="100px"
                labelWidth="150px"
                inline
              />
            </Space>
            <Space bottom="base">
              <TextField
                name="avgHours"
                label="Avg. Hours Per Week"
                onChange={this.handleChange}
                value={avgHours}
                width="100px"
                labelWidth="150px"
                inline
              />
            </Space>
            <Button
              primary
              icon="calculator"
              label="Calculate Savings"
              onClick={this.handleClick}
              disabled={!canSubmit}
            />
          </GridItem>
          <GridItem size="1of2">
            {!isEmpty(results) ? (
              this.renderResults()
            ) : (
              <div>
                <Space bottom="base">
                  <p>
                    Ever wonder how much you’re losing to every bead of sweat?
                    Or, put another way, how much you could be saving with Big
                    Ass Fans?
                  </p>
                </Space>
                <p>Fill out the information to find out!</p>
              </div>
            )}
          </GridItem>
        </Grid>
      </div>
    )
  }
}

ProductivityCalculator.propTypes = {
  logUserAction: PropTypes.func,
}

export default compose(
  graphql(LOG_USER_ACTION_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      logUserAction: ({ action }) =>
        handleLogUserAction({ action, mutate, ownProps }),
    }),
  })
)(ProductivityCalculator)
