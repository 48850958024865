import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Query } from '@apollo/client/react/components'
import find from 'lodash-es/find'
import get from 'lodash-es/get'
import debounce from 'lodash-es/debounce'
import { withRouter } from 'react-router-dom'

import { primaryUses } from 'config/facility'

import { ALL_PRIMARY_USES_QUERY } from 'client/queries'

import A from 'components/UIKit/A'
import Alert from 'components/UIKit/Alert'
import Flex, { FlexItem } from 'components/UIKit/Flex'
import H1 from 'components/UIKit/H1'
import Icon from 'components/UIKit/Icon'
import Loader from 'components/UIKit/Loader'
import Popover from 'components/UIKit/Popover'
import Select from 'components/UIKit/Select'
import SelectList from 'components/UIKit/SelectList'
import Space from 'components/UIKit/Space'

import InlineSearch from '../InlineSearch'
import Pagination from '../Pagination'
import PrimaryUseFilter, { filterData } from '../PrimaryUseFilter'

const EnhancedSelectList = (props = {}) => {
  const [view, setView] = useState(props.initialView)
  const [sort, setSort] = useState('updatedAt_DESC')
  const [primaryUse, setPrimaryUse] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    setCurrentPage(0)
  }, [primaryUse])

  function getView(title) {
    const foundView = find(props.views, { title })
    return foundView || null
  }

  const viewFilter = getView(view).filter
  const viewLimit = getView(view).limit
  const viewAllUsers = getView(view).allUsers

  const variables = {
    orderBy: sort,
    filter: viewFilter,
    limit: viewLimit || null,
    page: currentPage,
    allUsers: viewAllUsers,
  }

  if (searchQuery !== '') {
    variables.filter = { ...viewFilter, name_contains: searchQuery }
  }

  if (primaryUse !== '' && !getView(view).getLocalPrimaryUses) {
    variables.filter = { ...variables.filter, primaryUse }
  }

  const searchFlexItemProps = isSearching
    ? { width: '100%' }
    : { width: 'auto' }

  return (
    <Query
      query={getView(view).query}
      variables={!props.paginateLocally && variables}
      notifyOnNetworkStatusChange
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        let facilitiesData = get(data, getView(view).queryName, [])
        let count = get(data, getView(view).count, [])
        if (props.paginateLocally) {
          if (variables.filter) {
            facilitiesData = facilitiesData.filter(facility => {
              const passesFilter =
                !viewFilter ||
                Object.keys(viewFilter).every(
                  field => facility[field] === viewFilter[field]
                )
              return (
                passesFilter &&
                facility.primaryUse.includes(primaryUse) &&
                facility.name.includes(searchQuery)
              )
            })
          }

          if (variables.orderBy) {
            const orderByOptions = variables.orderBy.split('_')
            const field = orderByOptions[0]
            const direction = orderByOptions[1]

            const getSortValues = (a, b) => {
              let aVal = a[field]
              let bVal = b[field]
              if (typeof aVal === 'string') {
                aVal = aVal.toUpperCase()
              }
              if (typeof bVal === 'string') {
                bVal = bVal.toUpperCase()
              }
              return [aVal, bVal]
            }

            switch (direction) {
              case 'ASC':
                facilitiesData = facilitiesData.sort((a, b) => {
                  const [aVal, bVal] = getSortValues(a, b)
                  return aVal > bVal ? 1 : -1
                })
                break
              case 'DESC':
                facilitiesData = facilitiesData.sort((a, b) => {
                  const [aVal, bVal] = getSortValues(a, b)
                  return aVal < bVal ? 1 : -1
                })
                break
              default:
                break
            }
          }

          const start = variables.page * variables.limit
          const end = variables.limit && start + variables.limit

          count = facilitiesData.length
          facilitiesData = facilitiesData.slice(start, end)
        }
        const totalPages = loading ? null : Math.ceil(count / viewLimit)

        return (
          <>
            <Space bottom="base">
              <Flex alignItems="center" positionRelative>
                <Flex flexGrow="1">
                  <FlexItem {...searchFlexItemProps}>
                    <InlineSearch
                      query={searchQuery}
                      onChange={debounce(
                        event => setSearchQuery(event.target.value),
                        500
                      )}
                      onOpen={() => setIsSearching(true)}
                      onClose={() => {
                        setIsSearching(false)
                        setSearchQuery('')
                      }}
                    />
                  </FlexItem>
                  {!isSearching && (
                    <FlexItem>
                      <Popover
                        items={props.views.map(({ title, icon, url }) => ({
                          title,
                          onClick: () => {
                            setPrimaryUse('')
                            setView(title)
                            setCurrentPage(0)
                            props.history.push(url)
                          },
                          icon: {
                            name: icon,
                            size: '16',
                            color: 'subdued',
                          },
                        }))}
                        renderTrigger={({ isDropdownVisible }) => (
                          <H1 flush>
                            <A noBorder href="#0" data-testid="views-dropdown">
                              <Icon
                                name={getView(view).icon}
                                color="subdued"
                                marginRight="s"
                              />
                              <b>{getView(view).title}</b>
                              {props.views.length > 1 && (
                                <Icon
                                  name={
                                    isDropdownVisible ? 'arrowUp' : 'arrowDown'
                                  }
                                  color="subdued"
                                  marginLeft="xs"
                                />
                              )}
                            </A>
                          </H1>
                        )}
                      />
                    </FlexItem>
                  )}
                </Flex>
                <Flex spacingRight="base" marginLeft="auto">
                  <FlexItem>
                    {getView(view).getLocalPrimaryUses || searchQuery !== '' ? (
                      <PrimaryUseFilter
                        loading={loading}
                        error={error}
                        data={facilitiesData}
                        primaryUse={primaryUse}
                        onChange={event => setPrimaryUse(event.target.value)}
                      />
                    ) : (
                      <Query query={ALL_PRIMARY_USES_QUERY}>
                        {({ loading, error, data }) => {
                          return error ? (
                            <Alert
                              floating
                              closeable
                              autoClose={false}
                              type="error"
                              text={get(
                                error,
                                'message',
                                'Error fetching data.'
                              )}
                            />
                          ) : (
                            <Select
                              noBottom
                              inline
                              name="filter"
                              label="Filter:"
                              labelWidth="50px"
                              onChange={event =>
                                setPrimaryUse(event.target.value)
                              }
                              value={primaryUse}
                              placeholder={
                                loading ? 'Loading...' : 'All Primary Uses'
                              }
                              disablePlaceholder={false}
                              options={
                                loading
                                  ? [{ label: 'Loading...', value: '' }]
                                  : primaryUses.filter(primaryUse =>
                                      get(data, 'allPrimaryUses', []).includes(
                                        primaryUse.value
                                      )
                                    )
                              }
                            />
                          )
                        }}
                      </Query>
                    )}
                  </FlexItem>
                  <FlexItem>
                    <Select
                      inline
                      name="sort"
                      label="Sort:"
                      labelWidth="50px"
                      onChange={event => setSort(event.target.value)}
                      value={sort}
                      placeholder=""
                      options={[
                        { value: 'updatedAt_DESC', label: 'Last updated' },
                        { value: 'createdAt_DESC', label: 'Created at' },
                        { value: 'name_ASC', label: 'Name' },
                      ]}
                    />
                  </FlexItem>
                </Flex>
              </Flex>
            </Space>

            {loading ? (
              <Loader />
            ) : facilitiesData.length ? (
              <>
                <SelectList background="bg">
                  {props.children({
                    data: getView(view).getLocalPrimaryUses
                      ? filterData(facilitiesData, primaryUse)
                      : facilitiesData,
                    variables,
                    user: getView(view).user,
                    title: getView(view).title,
                    allUsers: getView(view).allUsers,
                  })}
                </SelectList>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPreviousPage={() => {
                    setCurrentPage(currentPage - 1)
                  }}
                  onPage={page => {
                    setCurrentPage(page)
                  }}
                  onNextPage={() => {
                    setCurrentPage(currentPage + 1)
                  }}
                />
              </>
            ) : (
              <p data-testid="facilities-empty">
                {searchQuery === ''
                  ? getView(view).emptyMessage
                  : getView(view).emptySearchMessage}
              </p>
            )}
          </>
        )
      }}
    </Query>
  )
}

EnhancedSelectList.propTypes = {
  children: PropTypes.func,
  data: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  initialView: PropTypes.string,
  views: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      filter: PropTypes.any,
      icon: PropTypes.string,
      query: PropTypes.object.isRequired,
      emptyMessage: PropTypes.string.isRequired,
      emptySearchMessage: PropTypes.string.isRequired,
      queryName: PropTypes.string.isRequired,
    })
  ).isRequired,
  paginateLocally: PropTypes.bool,
}

export default withRouter(EnhancedSelectList)
