import React, { Component } from 'react'
import { appConnect } from "~/store/hooks";
import { bool, func, object, string } from 'prop-types'
import isEmpty from 'lodash-es/isEmpty'

import { showAlert } from 'store/alert'
import { setActiveTool } from 'store/tools'
import {
  removeLoadingDockItem,
  toggleLoadingDock,
} from 'store/loadingDock/actions'
import { showLoadingDock } from 'store/loadingDock/selectors'
import { getDistanceUnits } from 'store/units/selectors'

import cloudinary from 'config/cloudinary'
import { showProductSize } from 'lib/utils'
import Util from 'components/DrawingCanvas/lib/util'

import VariantText from 'components/UIKit/VariantText'

import Container from '././styled/Container'
import Content from '././styled/Content'
import Dock from './styled/Dock'
import Item from './Item'
import Header from './Header'
import Trigger from './Trigger'
import BlankSlateMessage from './BlankSlateMessage'

const variantTextProps = {
  color: 'light',
  size: 'small',
}

class LoadingDock extends Component {
  state = {
    filterType: 'All Items',
  }

  getUtilityBoxIcon = type => {
    switch (type) {
      case 'electricPanel':
        return 'panelElectric'
      case 'firePanel':
        return 'panelFire'
      default:
        return 'panelControl'
    }
  }

  handleFilterChange = type => {
    this.setState({ filterType: type })
  }

  handleAddItem = (type, key) => {
    const { loadingDock, onShowAlert, onToggleLoadingDock } = this.props
    try {
      const itemModel = loadingDock[type][key]
      itemModel.id = Util.guid()
      if (itemModel) {
        switch (type) {
          case 'products':
            this.props.onAddProduct(itemModel)
            break
          case 'obstructions':
            this.props.onAddObstruction(itemModel)
            break
          case 'doors':
            this.props.onAddDoor(itemModel)
            break
          case 'utilityBoxes':
            this.props.onAddUtilityBox(itemModel)
            break
          default:
            break
        }
        onToggleLoadingDock(false)
      }
    } catch (err) {
      console.error(err)
      onShowAlert({
        text: err.message,
        type: 'error',
      })
    }
  }

  render() {
    const {
      loadingDock,
      distanceUnits,
      showDock,
      onRemoveLoadingDockItem,
      onToggleLoadingDock,
    } = this.props
    const type = this.state.filterType
    const products = []
    const obstructions = []
    const doors = []
    const utilityBoxes = []

    if (type === 'Products' || type === 'All Items') {
      Object.keys(loadingDock.products).forEach((key, index) => {
        const item = loadingDock.products[key]
        products.push(
          <Item
            key={key}
            name={item.modelName}
            imageSrc={cloudinary.url(item.product.cloudinaryId)}
            onClick={() => this.handleAddItem('products', key)}
            removeItem={() => onRemoveLoadingDockItem(item)}
          >
            <VariantText {...variantTextProps}>
              Diameter {showProductSize(item.size, distanceUnits)}
            </VariantText>
          </Item>
        )
      })
    }

    if (type === 'Obstructions' || type === 'All Items') {
      Object.keys(loadingDock.obstructions).forEach((key, index) => {
        const item = loadingDock.obstructions[key]
        const hasImage = item.obstructionType !== 'basic'
        products.push(
          <Item
            key={key}
            name="Obstruction"
            icon={hasImage ? null : 'obstruction'}
            imageSrc={
              hasImage ? `/obstructions/${item.obstructionType}.png` : null
            }
            onClick={() => this.handleAddItem('obstructions', key)}
            removeItem={() => onRemoveLoadingDockItem(item)}
          >
            <div>
              <VariantText {...variantTextProps}>
                Height {showProductSize(item.height, distanceUnits)}
              </VariantText>
              <VariantText {...variantTextProps}>
                Width {showProductSize(item.width, distanceUnits)}
              </VariantText>
            </div>
          </Item>
        )
      })
    }

    if (type === 'Doors' || type === 'All Items') {
      Object.keys(loadingDock.doors).forEach((key, index) => {
        const item = loadingDock.doors[key]
        doors.push(
          <Item
            key={key}
            name="Door"
            icon={item.doorType === 'manDoor' ? 'doorMan' : 'doorDock'}
            onClick={() => this.handleAddItem('doors', key)}
            removeItem={() => onRemoveLoadingDockItem(item)}
          >
            <div>
              <VariantText {...variantTextProps}>
                Height {showProductSize(item.height, distanceUnits)}
              </VariantText>
              <VariantText {...variantTextProps}>
                Width {showProductSize(item.width, distanceUnits)}
              </VariantText>
            </div>
          </Item>
        )
      })
    }

    if (type === 'Utility Boxes' || type === 'All Items') {
      Object.keys(loadingDock.utilityBoxes).forEach((key, index) => {
        const item = loadingDock.utilityBoxes[key]
        utilityBoxes.push(
          <Item
            key={key}
            name={item.label}
            icon={this.getUtilityBoxIcon(item.utilityBoxType)}
            onClick={() => this.handleAddItem('utilityBoxes', key)}
            removeItem={() => onRemoveLoadingDockItem(item)}
          >
            <div>
              <VariantText {...variantTextProps}>
                Height {showProductSize(item.height, distanceUnits)}
              </VariantText>
              <VariantText {...variantTextProps}>
                Width {showProductSize(item.width, distanceUnits)}
              </VariantText>
            </div>
          </Item>
        )
      })
    }

    const showBlankSlate =
      isEmpty(products) &&
      isEmpty(obstructions) &&
      isEmpty(doors) &&
      isEmpty(utilityBoxes)

    return (
      <Container showDock={showDock}>
        {showDock && (
          <Dock>
            <Header
              handleFilterChange={this.handleFilterChange}
              filterType={this.state.filterType}
            />
            <Content>
              {products}
              {obstructions}
              {doors}
              {utilityBoxes}
              {showBlankSlate && <BlankSlateMessage type={type} />}
            </Content>
          </Dock>
        )}
        <Trigger
          showDock={showDock}
          onClick={() => onToggleLoadingDock(!showDock)}
        />
      </Container>
    )
  }
}

LoadingDock.propTypes = {
  loadingDock: object,
  onAddObstruction: func,
  onAddProduct: func,
  onAddDoor: func,
  onAddUtilityBox: func,
  distanceUnits: string,
  showDock: bool,
  onRemoveLoadingDockItem: func,
  onShowAlert: func,
  onToggleLoadingDock: func,
}

const mapStateToProps = state => ({
  loadingDock: state.loadingDock,
  showDock: showLoadingDock(),
  distanceUnits: getDistanceUnits(state),
})

const mapDispatchToProps = dispatch => ({
  onAddDoor(doorModel) {
    dispatch(setActiveTool({ tool: 'DOOR_TOOL', props: doorModel }))
  },
  onAddProduct(productModel) {
    dispatch(setActiveTool({ tool: 'PRODUCT_TOOL', props: productModel }))
  },
  onAddUtilityBox(utilityBoxModel) {
    dispatch(
      setActiveTool({ tool: 'UTILITY_BOX_TOOL', props: utilityBoxModel })
    )
  },
  onAddObstruction(obstructionModel) {
    dispatch(
      setActiveTool({ tool: 'OBSTRUCTION_TOOL', props: obstructionModel })
    )
  },
  onRemoveLoadingDockItem(product) {
    dispatch(removeLoadingDockItem(product))
  },
  onToggleLoadingDock(show) {
    dispatch(toggleLoadingDock(show))
  },
  onShowAlert({ text, type }) {
    dispatch(showAlert({ text, type }))
  },
})

export default appConnect(mapStateToProps, mapDispatchToProps)(LoadingDock)
