import styled from 'styled-components'

const getSpacing = property => {
  switch (true) {
    case Array.isArray(property) && property.every(p => typeof p === 'number'): {
      return property.map(prop => `${prop}em`).join(' ')
    }
    case typeof property === 'number': {
      return `${property}em`
    }
    default: {
      return 'initial'
    }
  }
}

/** @type {any} Stack  */
export const Stack = styled.div`
  display: flex;
  width: ${({ width = 'auto' }) => width};
  flex-direction: ${({ direction = 'column' }) => direction};
  gap: ${({ spacing }) => (typeof spacing === 'number' ? `${spacing}em` : 'initial')};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  padding: ${({ padding }) => getSpacing(padding)};
  margin: ${({ margin }) => getSpacing(margin)};
`
