import React from 'react'
import Switch from 'components/UIKit/Switch'
import { compose } from 'redux'
import withUser from 'client/decorators/withUser'
import get from 'lodash-es/get'
import { UPDATE_USER_MUTATION } from 'client/mutations'
import { useApolloClient } from '@apollo/client'

const UserInterfaceSettings = props => {
  const client = useApolloClient()
  const fanSpin = get(props, 'user.preferences.showFanSpin', true)
  const prefs = get(props, 'user.preferences', {})
  const id = get(props, 'user.id')
  if (!id) return <>Loading...</>

  return (
    <div style={{ backgroundColor: 'white', padding: 10, borderRadius: 5 }}>
      <h4>User Preferences</h4>
      <div style={{ display: 'flex' }}>
        <div>
          <Switch
            isChecked={fanSpin}
            label='Fan Spin &emsp;'
            onClick={async () => {
              await client
                .mutate({
                  mutation: UPDATE_USER_MUTATION,
                  variables: {
                    id,
                    requiresCacheClear: true,
                    preferences: {
                      ...prefs,
                      showFanSpin: !fanSpin,
                    },
                  },
                })
                .catch(
                  error =>
                    get(error, 'graphQLErrors[0].message') ||
                    get(error, 'message')
                )
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default compose(withUser)(UserInterfaceSettings)
