import Tool from './tool'
import Util from './util'
import Primitives from './primitives'
import SnapQueries from './snapQueries'
import { getThreeHexFromTheme } from 'lib/utils'

import * as THREE from 'three'

class ShapeTool extends Tool {
  /*
    -= properties =-

    startedShapeCreation:
    startPos: position where toolDown() was first called
    endPos: either the current mouse position or were toolDown() was called the second time
    obj3d: root of the visual used to indicate the bounds of the drawn object
  */

  constructor() {
    super()

    this.name = 'SHAPE_TOOL'
    this.startedShapeCreation = false
    this.shapeCancelled = false
    this.createVisual()
    this.shiftModifier = false
    this.id = Util.guid()

    this.cursor = this.createCursor()
    this.obj3d.add(this.cursor)
  }

  createVisual() {
    this.obj3d = new THREE.Object3D()
    this.getRectEdges(1, 1).forEach(line => this.obj3d.add(line))

    this.obj3d.visible = false
  }

  createCursor() {
    const geometry = new THREE.BoxGeometry(1, 1, 1)
    const material = new THREE.MeshBasicMaterial({
      color: getThreeHexFromTheme('three.activeToolCursor'),
      depthTest: false,
    })
    return new THREE.Mesh(geometry, material)
  }

  addEventListeners = () => {
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
  }

  removeEventListeners() {
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyDown = e => {
    // shift key down
    if (e.which === 16) {
      this.shiftModifier = true
    }
  }

  handleKeyUp = e => {
    // shift key up
    if (e.which === 16) {
      this.shiftModifier = false
    }

    // Esc key up
    if (e.which === 27) {
      // Cancel shape drawing and let the wrapper class
      // remove the visual that it was displaying
      this.shapeCancelled = true
      this.updateVisual(this.startPos, this.endPos)
      this.finishShapeDescription(e.shiftKey)
    }
  }

  activate(mousePos) {
    this.addEventListeners()
    this.cursor.position.set(mousePos.x, mousePos.y, 0)
  }

  deactivate() {
    this.removeEventListeners()
  }

  getRectEdges(origin, width, height) {
    const top = Primitives.getLine(
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(width, 0, 0),
      0x000000,
      0.4
    )
    const right = Primitives.getLine(
      new THREE.Vector3(width, 0, 0),
      new THREE.Vector3(width, -height, 0),
      0x000000,
      0.4
    )
    const bottom = Primitives.getLine(
      new THREE.Vector3(width, -height, 0),
      new THREE.Vector3(0, -height, 0),
      0x000000,
      0.4
    )
    const left = Primitives.getLine(
      new THREE.Vector3(0, -height, 0),
      new THREE.Vector3(0, 0, 0),
      0x000000,
      0.4
    )

    this.top = top
    this.right = right
    this.left = left
    this.bottom = bottom

    const edges = [top, right, bottom, left]

    edges.forEach(edge => edge.position.add(origin))

    return edges
  }

  toolDown(
    mousePos,
    snappedMousePos,
    lastSceneIntersectionPoint,
    objectWithCursor,
    objectWithSnappedCursor,
    isTouch
  ) {
    if (!this.startedShapeCreation) {
      this.startShapeDescription(mousePos)
    }
  }

  toolUp({ multiSelect }) {
    if (this.startedShapeCreation) {
      this.finishShapeDescription(multiSelect)
    }
  }

  toolMoved(mousePos, snappedMousePos) {
    this.endPos = mousePos
    this.cursor.position.set(mousePos.x, mousePos.y, 0)

    if (this.startedShapeCreation) {
      this.updateVisual(this.startPos, this.endPos)
    }
  }

  updateVisual(startPos, endPos) {
    this.obj3d.children.forEach(child => {
      this.obj3d.remove(child)
    })

    // We are no longer drawing so return
    if (this.shapeCancelled) return

    const xDiff = Math.abs(endPos.x - startPos.x)
    const yDiff = Math.abs(endPos.y - startPos.y)

    // Another possible approach is to just scale the
    // lines differently when the size changes:
    // this.top.scale.x = xDiff;
    // this.bottom.scale.x = xDiff;
    // this.left.scale.y = yDiff;
    // this.right.scale.y = yDiff;
    // this.bottom.position.y = -yDiff + 1;
    // this.right.position.x = xDiff - 1;

    this.getRectEdges(startPos, xDiff, yDiff).forEach(line =>
      this.obj3d.add(line)
    )
  }

  startShapeDescription(startPos) {
    this.startPos = startPos
    this.startedShapeCreation = true
    this.hideArrows = true
    this.shapeCancelled = false
    this.obj3d.visible = true
  }

  finishShapeDescription() {
    this.startedShapeCreation = false
    this.hideArrows = false
  }

  getSnapRegions(facility, draggedObject) {
    return SnapQueries.getAllWallCenterLines()
  }

  toolDragged(/* mousePos, snappedMousePos */) {}

  getArrowDescriptions() {
    return super.getArrowDescriptions()
  }
}

export default ShapeTool
