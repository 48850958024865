import React from 'react'
import PropTypes from 'prop-types'

import routes from 'config/routes'
import { isTouchDevice } from 'lib/utils'

import Button from 'components/UIKit/Button'
import Popover from 'components/UIKit/Popover'
import RequiredPermission from '../RequiredPermission'

const isTouch = isTouchDevice()
const setVariant = isTouch ? 'dark' : 'light'

const CreateFacilityButton = (props = {}) => (
  <RequiredPermission name="Create Facility">
    <Popover
      variant={setVariant}
      right
      items={[
        {
          title: 'From scratch',
          to: `${props.match.url}${routes.modals.newFacility}`,
          icon: {
            name: 'draw',
            size: '16',
            color: 'subdued',
          },
        },
        {
          title: 'From a template',
          to: `${routes.templates}`,
          icon: {
            name: 'interiorWall',
            size: '16',
            color: 'subdued',
          },
        },
      ]}
      renderTrigger={({ isDropdownVisible }) => (
        <Button
          primary
          dropdown
          isDropdownVisible={isDropdownVisible}
          label="New Facility"
          data-testid="new-facility-button"
          variant={setVariant}
        />
      )}
    />
  </RequiredPermission>
)

CreateFacilityButton.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default CreateFacilityButton
