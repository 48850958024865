import create from 'zustand'

export const useModifiers = create(() => ({
  shift: false,
  ctrl: false,
  alt: false,
}))

function handleKey(e: KeyboardEvent) {
  useModifiers.setState({
    shift: e.shiftKey,
    ctrl: e.ctrlKey,
    alt: e.altKey,
  })
}
document.addEventListener("keydown", handleKey, true)
document.addEventListener("keyup", handleKey, true)

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    import.meta.hot?.invalidate("modifiers can't be reloaded safely as it uses global state")
  })
}
