async function loadURLAsBase64(url: string, stripQuery?: boolean): Promise<string | ArrayBuffer> {
  const response = await fetch(stripQuery ? url.split('?')[0] : url)
  const blob = await response.blob()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (reader.result) resolve(reader.result)
      else reject(reader.error)
    }
    reader.readAsDataURL(blob)
  })
}

export function addTransformationsToCloudinaryUrl(url: string) {
  const transformations = 'q_auto'
  const [first, second] = url.split('/upload/')

  return `${first}/upload/${transformations}/${second}`
}

export const getBase64FromImage = (url: string) => {
  if (url.includes('.s3.')) {
    return loadURLAsBase64(url, true)
  } else if (url.includes('.cloudinary.')) {
    const transformedUrl = addTransformationsToCloudinaryUrl(url)
    return loadURLAsBase64(transformedUrl)
  } else {
    return loadURLAsBase64(url)
  }
}
