import React, { useEffect } from 'react'
import { Query } from '@apollo/client/react/components'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { RELEASE_NOTES_QUERY } from 'client/queries'

import Modal from 'components/UIKit/Modal'
import { LOG_USER_ACTION_MUTATION } from 'client/mutations'
import { handleLogUserAction } from 'client/handlers'

import styles from './style.module.css'

const ReleaseNoteWrapper = styled.div`
  margin: 20px 0;
  padding: 0 10px;
`

const ReleaseNotesModal = props => {
  return (
    <Modal title="SpecLab Release Notes" {...props}>
      <Query
        query={RELEASE_NOTES_QUERY}
        notifyOnNetworkStatusChange
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data }) => {
          if (loading) return <p>Loading release notes...</p>
          if (error) return <p>There was an error loading release notes.</p>
          const releaseNotes = [...data.releaseNotes]
            .sort((a, b) => {
              const aDate = new Date(a.effectiveDate)
              const bDate = new Date(b.effectiveDate)
              if (aDate < bDate) return 1
              return -1
            })
            .map((releaseNote, index) => {
              const markup = { __html: decodeURI(releaseNote.htmlContents) }
              return (
                <ReleaseNoteWrapper key={index}>
                  <h4>{`Version ${releaseNote.version} - ${releaseNote.effectiveDate}`}</h4>
                  <div className={styles.changelog} dangerouslySetInnerHTML={markup} />
                </ReleaseNoteWrapper>
              )
            })
          return releaseNotes
        }}
      </Query>
    </Modal>
  )
}

ReleaseNotesModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  loading: PropTypes.bool,
  parentRoute: PropTypes.string,
  logUserAction: PropTypes.func,
}

export default compose(
  withRouter,
  graphql(LOG_USER_ACTION_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      logUserAction: ({ action }) =>
        handleLogUserAction({ action, mutate, ownProps }),
    }),
  })
)(ReleaseNotesModal)
