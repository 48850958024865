import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '../Button'
import Icon from '../Icon'
import theme from 'config/theme'

const FixedButton = styled(Button)`
  position: fixed;
  left: 0px;
  margin-left: ${theme.spacing.m};
`

const MenuButton = props => (
  <FixedButton noBorder onClick={props.handleOpen}>
    <Icon name="menu" />
  </FixedButton>
)

MenuButton.propTypes = {
  handleOpen: PropTypes.func.isRequired,
}

export default MenuButton
