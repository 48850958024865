import {
  TOGGLE_TOUCH_UI,
  TOGGLE_DEFAULT_UI,
  TOGGLE_FULLSCREEN,
} from './actions'
import { TOUCH_UI_LOCAL_STORAGE_KEY } from './constants'

const initialState = {
  isTouchUI: false,
  isFullscreen: false,
}

export default function userInterfaceReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_TOUCH_UI: {
      localStorage.setItem(TOUCH_UI_LOCAL_STORAGE_KEY, 'Touch UI')
      return {
        ...state,
        isTouchUI: true,
      }
    }
    case TOGGLE_FULLSCREEN: {
      return {
        ...state,
        isFullscreen: !state.isFullscreen,
      }
    }
    case TOGGLE_DEFAULT_UI: {
      localStorage.setItem(TOUCH_UI_LOCAL_STORAGE_KEY, 'Default UI')
      return {
        ...state,
        isTouchUI: false,
      }
    }
    default: {
      return {
        ...state,
        isTouchUI:
          localStorage.getItem(TOUCH_UI_LOCAL_STORAGE_KEY) === 'Touch UI',
      }
    }
  }
}
