import withProps from 'recompact/withProps'
import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'
import Select from 'components/UIKit/Select'
import ApolloClient from 'client'
import { graphql } from '~/gql'

// NOTE: This query would be better served if it was specific
// to the mounting options
const withVariationsVoltages = withProps(ownProps => {
  const variation = ApolloClient.readFragment({
    id: `ProductVariation:${ownProps.productVariationId}`,
    fragment: graphql(`fragment VoltageSelect on ProductVariation {
      voltages {
        id
        inputPower
      }
    }`),
  })
  const voltages = get(variation, 'voltages') || []

  return {
    disabled: ownProps.disabled,
    options: [
      { value: '', label: 'Choose a voltage...', disabled: true },
      { value: 'unknown', label: 'Unknown', disabled: false },
      ...sortBy(voltages, 'inputPower').map(o => ({
        value: o.id,
        label: o.inputPower,
      })),
    ],
  }
})

const VoltageSelect = withVariationsVoltages(Select)
VoltageSelect.displayName = 'VoltageSelect'

export default VoltageSelect
