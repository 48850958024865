import styled, { css } from 'styled-components'

import { theme } from 'lib/styleUtils'

const setFontSize = props =>
  props.isTouchUI
    ? css`
        font-size: ${theme('fonts.sizes.h1')};
      `
    : css`
        font-size: ${theme('fonts.sizes.s')};
      `

const Header = styled.h3`
  flex-grow: 1;
  margin: 0;
  ${setFontSize}
`

export default Header
