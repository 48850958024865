import styled, { ThemedStyledProps, css } from 'styled-components'

import { darken, lighten, theme } from 'lib/styleUtils'
import { isTouchDevice } from 'lib/utils'
import { Theme, Variant } from '~/config/theme';

const isDarkVariant = (props: { variant?: Variant; 'data-variant'?: Variant }) =>
  props.variant === 'dark' || props['data-variant'] === 'dark'

const setPrimaryActionStyle = (props: ThemedStyledProps<{ primaryAction?: boolean }, Theme>) =>
  props.primaryAction &&
  css`
    background-color: ${props.theme.colors.light.primary};
    color: ${props.theme.colors.light.fg};
    font-weight: ${props.theme.fonts.weights.semiBold};

    &:active,
    &:focus,
    &:hover {
      background-color: ${darken(props.theme.colors.light.primary, 0.1)};
    }
  `

const setBackgroundColor = (props: ThemedStyledProps<{ highlight?: boolean; active?: boolean; variant?: Variant; 'data-variant'?: Variant }, Theme>) => {
  let color

  if (props.highlight) {
    color = lighten(props.theme.colors.light.primary, 0.6)
  }

  if (props.active) {
    color = isDarkVariant(props)
      ? lighten(props.theme.colors.dark.bg, 0.2)
      : lighten(props.theme.colors.light.subdued, 0.2)
  }

  return `background-color: ${color};`
}

const setFontSize = ({ adjustForTouch }: { adjustForTouch?: boolean }) => css`
  font-size: ${theme(`fonts.sizes.${adjustForTouch ? 'l' : 's'}`)};
`

const Container = styled.div<{ highlight?: boolean; active?: boolean; variant?: Variant; 'data-variant'?: Variant; adjustForTouch?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  transition: 0.3s ease-in-out;

  ${setFontSize}
  ${props => setBackgroundColor(props)};
  ${props =>
    props.adjustForTouch &&
    css`
      padding: ${theme('spacing.xs')} 0;
    `};

  ${props =>
    props.adjustForTouch &&
    css`
      margin: 0 ${theme('spacing.s')};
    `};

  ${isTouchDevice() &&
    css<{variant?: Variant; 'data-variant'?: Variant}>`
    &:active,
    &:focus,
    &:hover {
      background-color: ${props =>
        isDarkVariant(props)
          ? lighten(props.theme.colors.dark.bg, 0.2)
          : lighten(props.theme.colors.light.subdued, 0.2)};
    }
  `}

  ${props => setPrimaryActionStyle(props)};
`

export default Container
