import get from 'lodash-es/get'
import { requestAirflow, receiveAirflow } from 'store/objects'
import { clearStatus, setLoadingStatus } from 'store/status'
import { getAllProducts } from 'client/queries/productsQuery'
import airVelocityWorker from './airvelocity.worker?worker'
import {
  evalPointSpacing,
  isExteriorWall,
  isInteriorWall,
  isValidSegment,
  nearestValueFromArray,
  rotate,
} from './util'
import Units from 'components/DrawingCanvas/lib/units'

export const DEFAULT_COOLING_FAN_SPEED = 'COOLING'
export const DEFAULT_DESTRAT_FAN_SPEED = 'DESTRATIFICATION'
export const AIRFLOW_EVALUATION_POINTS = [4, 24, 43, 67]

async function airflowUtil(
  { airVelocities, objects, obstructions, products, segments },
  callback
) {
  try {
    // Valid segments that can be used to make walls
    const validSegments = Object.values(segments).filter(isValidSegment)
    // Each time we need to iterate through segments use this function
    const mapSegments = (parentId, addSegment) => {
      for (let i = 0; i < validSegments.length; i++) {
        const segment = validSegments[i]
        if (segment.parentId === parentId) addSegment(segment)
      }
    }

    // Polygon needed to see if obstructions and walls are inside
    let exteriorPolygon = []
    // Fields needed for the input for the air velocity package
    let exteriorWallId,
      ceilingFans = [],
      directionalFans = [],
      interiorWalls = [],
      perimeterWalls = []
    // Take all the wall values out of objects
    const walls = Object.values(objects)
    // Find the exteriorWall
    const exteriorWall = walls.find(isExteriorWall)

    // Set the values for the perimeter walls and exterior
    exteriorWallId = exteriorWall.id

    // Go through the valid segments and return the vertices of the exterior Walls
    mapSegments(exteriorWallId, segment => {
      // Take the first startpoint and all the endpoints to form the polygon
      // of the exterior walls
      if (!exteriorPolygon.length) {
        exteriorPolygon.push([segment.startPoint.x, segment.startPoint.y])
      }
      exteriorPolygon.push([segment.endPoint.x, segment.endPoint.y])
      // perimeter walls to pass to the air velocity package
      perimeterWalls.push({
        vertex1: { ...segment.startPoint },
        vertex2: { ...segment.endPoint },
      })
    })

    // Iterate over all the interior walls
    for (let i = 0; i < walls.length; i++) {
      // Determine the object we are inspecting is an interior Wall
      if (isInteriorWall(walls[i])) {
        // Pull the interior wall info out of segments
        mapSegments(walls[i].id, segment => {
          const startPoint = get(segment, 'startPoint')
          const endPoint = get(segment, 'endPoint')
          if (startPoint && endPoint) {
            interiorWalls.push([segment.startPoint, segment.endPoint])
          }
        })
      }
    }

    // Taking the value of each of the obstructions
    const obs = Object.values(obstructions)
    let obstructs = []
    // Iterate over the obstructions
    for (let i = 0; i < obs.length; i++) {
      let {
        height,
        obstructionType,
        offset,
        position,
        positions,
        rotation,
        startLocation,
      } = obs[i]
      // Making sure there is a base position
      position = position || { x: 0, y: 0 }
      // rotation of the object pulled out
      const rot = (rotation && rotation.z) || 0
      // Clone the positions so the obstruction keeps them
      let clonedPositions = positions.map(pos => {
        const [x, y] = rotate(position.x, position.y, pos.x, pos.y, -rot)
        return {
          x,
          y,
        }
      })

      // If the obstruction is basic, remove the last element b/c repeated
      if (obstructionType === 'basic') clonedPositions.pop()

      // Start height is in case the obstruction starts off the ground
      let startHeight = offset || 0
      if (startLocation === 'ceiling') {
        startHeight =
          segments[get(exteriorWall, 'segments.0')].height - height || 0
      }
      // Minimal description of the obstructions for the AVP
      // Make sure to use clonedPositions to not delete positions
      // And make sure there are actually positions in the array
      if (clonedPositions.length) {
        obstructs.push({
          height: Units.inchesToNative(height),
          positions: clonedPositions,
          startHeight,
        })
      }
    }

    // The ids and angles of all fans in the scene, used to filter air velocities
    const productInfo = Object.values(products)
    let interpolationPoints = {}
    // Add keys for each evaluation point into the object
    AIRFLOW_EVALUATION_POINTS.forEach(
      value => (interpolationPoints[value] = {})
    )

    // Run through all the products and calculate the airvelocities needed
    // And push the product info into the appropriate array
    for (let i = 0; i < productInfo.length; i++) {
      // Pull out all the product info needed
      const {
        coolingFanSpeedId,
        height,
        position,
        product,
        rotation,
        size,
        variationId,
      } = productInfo[i]

      // Check if the type is Directional
      // isDirectional is sometimes on the model, but not consistent
      const isDirectional = product.type === 'DIRECTIONAL'

      // Pull the variantId out of airVelocities, each entry should be unique
      const fanVariant = airVelocities.find(value => variationId === value.id)
      // If the fanVariant isn't found, ignore it
      if (!fanVariant) continue
      // Power is the modifier of the airVelocity for calculating airflow
      const power = get(fanVariant, 'product.fanSpeed.power') / 100 || 1

      // Conversion table of angles from the SpecLab system to the airflow system
      let angleConversions = {
        22.5: 67.5,
        90: 0,
        67.5: 22.5,
        0: 90,
      }
      // Grab the angle from the rotation and convert it
      const angle = angleConversions[get(rotation, 'x')]

      const heights = []
      // Grab the air velocities for the product
      const velocities = fanVariant.airVelocities
      // Determine the heights in the airVelocities
      for (let i = 0; i < velocities.length; i++) {
        const { airfoilHeight } = velocities[i]
        if (!heights.includes(airfoilHeight)) heights.push(airfoilHeight)
      }
      // Find the airfoilHeight on the Interpolation points closest to the fan
      const airfoilHeight =
        height != null && nearestValueFromArray(height, heights)
      // If height was undefined skip the rest, this should never happen
      if (airfoilHeight === false) continue

      // The interpolationPoints id changes based on the type of fan
      // Because we need to include the angle for directionals.
      const interpolationPointsId = isDirectional
        ? `${variationId}.${angle}.${coolingFanSpeedId ||
            DEFAULT_COOLING_FAN_SPEED}.${airfoilHeight}`
        : `${variationId}.${coolingFanSpeedId ||
            DEFAULT_COOLING_FAN_SPEED}.${airfoilHeight}`

      // Calculate the interpolation points if they don't already exist
      // Each set of points should have every eval height, so we just need to look at one
      if (
        interpolationPoints[AIRFLOW_EVALUATION_POINTS[0]][
          interpolationPointsId
        ] == null
      ) {
        // Decide which array to push into based on the type of fan
        // iterate over them grabbing the appropriate interpolation points
        for (let j = 0; j < velocities.length; j++) {
          const interpolationPoint = velocities[j]
          const {
            airVelocity,
            distanceFromFan: distance,
            evaluationHeight,
            offsetFromFanCenter: offset,
          } = interpolationPoint

          // Check if the airfoilHeight matches the one we want
          const pointHeight = get(interpolationPoint, 'airfoilHeight')
          const airfoilMismatch = pointHeight !== airfoilHeight

          // If there is an angle check that it matches that of the fan
          const angleMismatch =
            isDirectional && interpolationPoint.angle !== angle

          // Make sure that height is in AIRFLOW_EVALUATION_POINTS by
          // checking if the object exists on interpolationPoints
          const heightExists = interpolationPoints[evaluationHeight]

          if (airfoilMismatch || angleMismatch || !heightExists) continue

          // If no points have been added to this eval height, add one
          if (!interpolationPoints[evaluationHeight][interpolationPointsId]) {
            interpolationPoints[evaluationHeight][interpolationPointsId] = {
              id: interpolationPointsId,
              interpolationPoints: [],
            }
          }

          interpolationPoints[evaluationHeight][
            interpolationPointsId
          ].interpolationPoints.push({
            airVelocity: parseFloat(airVelocity) * power,
            distance: parseFloat(distance),
            offset: offset == null ? undefined : parseFloat(offset),
          })
        }
      }

      // If the InterpolationData is there, push the product into the array
      if (
        interpolationPoints[AIRFLOW_EVALUATION_POINTS[0]][interpolationPointsId]
      ) {
        const array = isDirectional ? directionalFans : ceilingFans
        array.push({
          interpolationPointsId,
          position,
          rotation,
          diameter: size,
        })
      }
    }
    // Map each of the inputs to the evaluation heights
    let inputs = AIRFLOW_EVALUATION_POINTS.map(evalHeight => ({
      ceilingFans,
      directionalFans,
      evalHeight,
      evalPointSpacing,
      exteriorWallId,
      interiorWalls,
      perimeterWalls,
      interpolationPoints: Object.values(interpolationPoints[evalHeight]),
      obstructions: obstructs,
    }))
    // Build the service worker to calculate the airflow velocities
    const worker = new airVelocityWorker()
    worker.onmessage = event => {
      const avData = event.data
      const airflow = avData.map((av, idx) => ({
        objectId: inputs[idx].exteriorWallId,
        gridSize: evalPointSpacing,
        evaluationHeight: inputs[idx].evalHeight,
        airflowVelocities: av.airVelocities,
        topLeftAnchorPosition: av.topLeftAnchorPosition,
      }))
      callback(airflow)
      worker.terminate()
    }
    // Send the inputs calculated into the service worker
    worker.postMessage(inputs)
  } catch (err) {
    console.log(err)
    return []
  }
}

export const updateAirflow = async (
  dispatch,
  { products, objects, segments, obstructions }
) => {
  dispatch(setLoadingStatus())
  dispatch(requestAirflow())

  // NOTE: Takes advantage of query batching
  const productsData = await getAllProducts()

  const variationsData = Object.keys(products).map(key => {
    const productObject = products[key]
    const product = productsData.find(
      p => p.id === get(productObject, 'product.id')
    )
    if (!product) return {}

    const variation = products[key]
    const coolingFanSpeedId =
      variation.coolingFanSpeedId || DEFAULT_COOLING_FAN_SPEED
    const heightFromFloor = Math.floor(variation.height)

    let fanSpeed
    let closestHeight = Infinity

    // Find fan speed with closest height from floor
    const fanSpeeds = get(product, 'fanSpeeds', [])
    fanSpeeds.forEach(fs => {
      if (fs.speed === coolingFanSpeedId) {
        const heightDiff = Math.abs(fs.heightFromFloor - heightFromFloor)
        if (heightDiff < closestHeight) {
          closestHeight = heightDiff
          fanSpeed = fs
        }
      }
    })

    const variationData = get(product, 'variations', []).find(
      v => v.id === variation.variationId
    )

    return {
      product: {
        id: get(product, 'id'),
        fanSpeed,
      },
      airVelocities: get(variationData, 'airVelocities'),
      id: variation.variationId,
    }
  })

  const validProductIds = [],
    productInfo = []
  for (let i = 0; i < variationsData.length; i++) {
    const { airVelocities, id, product } = variationsData[i] || {}
    // Make sure the fan has a fanSpeed and that airVelocities have a length
    // Otherwise don't add the product
    if (
      get(product, 'fanSpeed.speed') &&
      airVelocities &&
      airVelocities.length
    ) {
      validProductIds.push(product.id)
      productInfo.push({
        airVelocities,
        id,
        product,
      })
    }
  }

  const validProducts = {}
  Object.keys(products).forEach(id => {
    const product = products[id]
    if (validProductIds.includes(product.product.id)) {
      validProducts[id] = product
    }
  })

  // Only run the airVelocities if we have valid products with airVelocity info
  if (productInfo.length && Object.keys(validProducts).length) {
    airflowUtil(
      {
        products: validProducts,
        objects,
        segments,
        obstructions,
        airVelocities: productInfo,
      },
      airflow => {
        dispatch(clearStatus)
        dispatch(receiveAirflow(airflow))
      }
    )
  } else {
    // If there are no fans, there is no airflow
    dispatch(clearStatus)
    dispatch(receiveAirflow([]))
  }
}
