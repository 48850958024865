import { graphql } from '~/gql'

// NOTE: It's not a good idea to poll a large fragment.
// We're running all the resolvers!
const GET_CFD_STATUS_QUERY = graphql(`
  query GetCFDStatus($versionId: ID!) {
    Version(id: $versionId) {
      ...VersionFragment
    }
  }
`)

export default GET_CFD_STATUS_QUERY
