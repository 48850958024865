import React from 'react'

import { ToolbarGroup } from 'components/UIKit/Toolbar'

import StatusBar from 'components/StatusBar'

const BottomCenter = () => (
  <ToolbarGroup flexGrow="1" alignment="center">
    <StatusBar />
  </ToolbarGroup>
)

export default BottomCenter
