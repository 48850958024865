import React from 'react'
import PropTypes from 'prop-types'

import Space from 'components/UIKit/Space'

import Select from 'components/UIKit/Select'
import Checkbox from 'components/UIKit/Checkbox'
import Flex, { FlexItem } from 'components/UIKit/Flex'

import get from 'lodash-es/get'

import {
  liftTypeOptions,
  levelOptions,
  fireRelayTypeOptions,
  installAdderOptions,
} from './constants'
import TextField from 'components/UIKit/TextField'

const InstallAddersTab = ({ productsArray, onUpdateProducts }) => {
  return (
    <>
      <Space bottom="base">
        <Space bottom="base">
          <Flex flexWrap="wrap">
            {installAdderOptions.map((item, idx) => {
              const value = get(productsArray[0], 'installAdders', []).includes(
                item
              )
              return (
                <FlexItem key={idx} width="300px" marginRight="base">
                  <Checkbox
                    label={item}
                    value={value}
                    checked={value}
                    onChange={e => {
                      const newAdders = [...get(
                        productsArray[0],
                        'installAdders',
                        []
                      )]
                      if (value) {
                        const index = newAdders.indexOf(item)
                        if (index > -1) {
                          newAdders.splice(index, 1)
                        }
                      } else {
                        newAdders.push(item)
                      }
                      const newProducts = productsArray.map(p => {
                        return {
                          ...p,
                          installAdders: newAdders,
                        }
                      })
                      onUpdateProducts(newProducts)
                    }}
                  />
                </FlexItem>
              )
            })}
          </Flex>
        </Space>
        <Space bottom="base">
          <Select
            name="liftType"
            label="Type of Lift"
            inline
            onChange={e => {
              const newProducts = productsArray.map(p => ({
                ...p,
                liftType: e.target.value,
              }))
              onUpdateProducts(newProducts)
            }}
            value={productsArray[0].liftType || ''}
            options={liftTypeOptions}
            placeholder="Please Select An Option..."
          />
        </Space>
        <Space bottom="base">
          <Select
            label="Install Level"
            inline
            value={productsArray[0].level || ''}
            options={levelOptions}
            onChange={e => {
              const newProducts = productsArray.map(p => ({
                ...p,
                level: e.target.value,
              }))
              onUpdateProducts(newProducts)
            }}
          />
        </Space>
        <Space bottom="base">
          <Checkbox
            label="Fire Relay"
            checked={productsArray[0].fireRelay}
            value={productsArray[0].fireRelay}
            onChange={e => {
              const newProducts = productsArray.map(p => ({
                ...p,
                fireRelay: !productsArray[0].fireRelay,
              }))
              onUpdateProducts(newProducts)
            }}
          />
          {productsArray[0].fireRelay && (
            <Select
              label="Type of Fire Relay"
              value={productsArray[0].fireRelayType || ''}
              options={fireRelayTypeOptions}
              onChange={e => {
                const newProducts = productsArray.map(p => ({
                  ...p,
                  fireRelayType: e.target.value,
                }))
                onUpdateProducts(newProducts)
              }}
            />
          )}
        </Space>
      </Space>
      <Space>
        <TextField
          onBlur={e => {
            const newProducts = productsArray.map(p => ({
              ...p,
              installNotes: e.target.value,
            }))
            onUpdateProducts(newProducts)
          }}
          value={productsArray[0].installNotes || ''}
          multiline
          noResize
          label="Installer Notes"
        />
      </Space>
    </>
  )
}

InstallAddersTab.propTypes = {
  productsArray: PropTypes.array,
  onUpdateProducts: PropTypes.func,
}

export default InstallAddersTab
