import React from 'react'
import { Helmet } from 'react-helmet'

import { getTitle } from 'config/titles'

import Modal from 'components/UIKit/Modal'

import ProductivityCalculator from '../../ProductivityCalculator'

const SavingsModal = ({ ...props }) => (
  <div>
    <Helmet>
      <title>{getTitle('savings')}</title>
    </Helmet>
    <Modal title="Savings" {...props}>
      <ProductivityCalculator />
    </Modal>
  </div>
)

export default SavingsModal
