import styled from 'styled-components'

import { lighten, theme } from 'lib/styleUtils'

import Action from 'components/UIKit/Action'

const activeStyles = ({ theme }) =>
  `
    background-color: ${lighten(theme.colors.light.subdued, 0.2)};
  `

const StyledAction = styled(Action).withConfig({shouldForwardProp: prop => prop !== 'onHover' && prop !== 'isActive'})`
  border-bottom: ${props =>
    props.last ? '0' : `1px solid ${theme('colors.light.subdued')(props)}`};
  border-radius: 0;
  cursor: ${props => (props.disabled ? 'not-allowed' : `pointer`)};
  padding: ${theme('spacing.s')};
  text-align: center;
  width: 100%;
  &:active,
  &:focus {
    box-shadow: none;
    ${activeStyles};
  }

  ${props => props.isActive && activeStyles(props)};
`

export default StyledAction
