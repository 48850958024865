import LAYER_KEYS, { LayerKeys } from 'config/layerKeys'
import { MenuDropDownButton } from '../components/MenuDropDownButton'
import { MenuItem } from '~/ui/Menu'
import { Icon } from '~/ui/Icon'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useAppDispatch } from '~/store/hooks'
import { setCurrentLayer } from '~/store/layers'
import { setActiveTool } from '~/store/tools'
import { useRouteMatch } from 'react-router'

export default function AnnotationsMenu() {
  const dispatch = useAppDispatch()
  const action = (layerKey: LayerKeys, tool: string) => () => {
    dispatch(setCurrentLayer({ layerKey }))
    dispatch(setActiveTool({ tool }))
  }
  const match = useRouteMatch()
  const navigate = useNavigate()
  return (
    <MenuDropDownButton label="Annotations" icon="ruler">
      <MenuItem onAction={action(LAYER_KEYS.DIMENSIONS, 'DIMENSION_TOOL')} variant="square">
        <Icon name='ruler' size="14"/>
        Measurement
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.COMFORT_ZONES, 'COMFORT_ZONE_TOOL')} variant="square">
        <Icon name='comfortZone' size="14"/>
        Comfort Zone
      </MenuItem>
      <MenuItem onAction={() => navigate(match.url + '/upload-image/background')} variant="square">
        <Icon name='image' size="14"/>
        Background Image
      </MenuItem>
    </MenuDropDownButton>
  )
}
