import styled, { keyframes, css } from 'styled-components'

import Icon from '../../Icon'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
`

const animation = props => css`
  ${props.animated && rotate} 1s linear infinite;
`

const StyledIcon = styled(Icon)`
  animation: ${animation};
`

export default StyledIcon
