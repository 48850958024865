import styled from 'styled-components'

import Icon from '../../Icon'

const StyledIcon = styled(Icon)`
  position: relative;
  top: -1px;
`

export default StyledIcon
