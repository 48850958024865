import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.div`
  background-color: ${props => theme(`colors.light[${props.backgroundColor}]`)};
  color: ${props => theme(`colors.light[${props.color}]`)};
  display: ${props => (props.show ? 'block' : 'none')};
  font-size: ${theme('fonts.sizes.s')};
  padding: ${theme('spacing.s')};
  text-align: center;
`

export default Container
