import { graphql } from '~/gql'

const ALL_DISCOUNT_REASONS_QUERY = graphql(`
  query AllDiscountReasons {
    Salesforce {
      discountReasons {
        id
        name
      }
    }
  }
`)

export default ALL_DISCOUNT_REASONS_QUERY
