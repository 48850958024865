import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/UIKit/Icon'

import Container from './styled/Container'
import BrushOption from './styled/BrushOption'
import Item from './styled/Item'
import Divider from './styled/Divider'
import CloseIcon from './styled/CloseIcon'
import { ColorPicker } from 'react-aria-components'
import { SwatchWell } from '~/ui/Color'
import { Label } from '~/ui/Field'
import theme from '~/config/theme'

const Tools = ({
  brushRadius,
  color,
  handleBrushSelection,
  handleClear,
  handleClose,
  handleColorSelection,
  handleSave,
  handleUndo,
}) => (
  <Container>
    <BrushOption
      size={4}
      active={brushRadius === 1}
      onClick={() => handleBrushSelection(1)}
      color={color}
    />
    <BrushOption
      size={8}
      active={brushRadius === 4}
      onClick={() => handleBrushSelection(4)}
      color={color}
    />
    <BrushOption
      size={16}
      active={brushRadius === 8}
      onClick={() => handleBrushSelection(8)}
      color={color}
    />

    <Item>
      <ColorPicker value={color} onChange={color => handleColorSelection(color.toString("hex"))}>
        <Label>Color</Label>
        <SwatchWell swatches={theme.colors.swatches}/>
      </ColorPicker>
    </Item>

    <Divider />

    <Item onClick={handleUndo}>
      <Icon name="undo" color="subdued" />
    </Item>
    <Item onClick={handleClear}>
      <Icon name="trash" color="subdued" />
    </Item>
    <Item
      onClick={() => {
        handleSave()
        handleClose()
      }}
    >
      <Icon name="disk" color="subdued" />
    </Item>

    <CloseIcon color="light" name="cross" onClick={handleClose} size="28" />
  </Container>
)

Tools.propTypes = {
  brushRadius: PropTypes.number,
  color: PropTypes.string,
  handleBrushSelection: PropTypes.func,
  handleClear: PropTypes.func,
  handleClose: PropTypes.func,
  handleColorSelection: PropTypes.func,
  handleSave: PropTypes.func,
  handleUndo: PropTypes.func,
}

export default Tools
