import styled from 'styled-components'

import { theme } from 'lib/styleUtils'
import { getZIndexOf } from 'lib/utils'

const PerspectiveSwitchContainer = styled.div`
  background-color: ${theme('colors.light.bg')};
  border-radius: ${theme('radius.base')};
  box-shadow: ${theme('shadows.base')};
  bottom: ${theme('spacing.l')};
  left: ${theme('spacing.l')};
  padding: ${theme('spacing.base')};
  position: absolute;
  z-index: ${getZIndexOf('closeFullscreenButton')};
`

export default PerspectiveSwitchContainer
