import styled from 'styled-components'

import { theme } from 'lib/styleUtils'
import Icon from 'components/UIKit/Icon'

const RemoveIcon = styled(Icon)`
  padding: ${theme('spacing.xs')};
  position: absolute;
  right: ${theme('spacing.xs')};
  top: -${theme('spacing.xs')};
`

RemoveIcon.defaultProps = {
  color: 'subdued',
  name: 'cross',
  size: '44px',
}

export default RemoveIcon
