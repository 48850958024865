import React from 'react'
import routes from 'config/routes'
import { CFD_PANEL } from 'store/panel/types'
import Panel from 'components/UIKit/Panel'
import { CFDPanelContent } from './subcomponents/CFDPanelContent'

export default function CFDPanel() {
  return (
    <Panel title="CFD Simulation" helpUrl={routes.help.cfd} landscape panelKey={CFD_PANEL}>
      <CFDPanelContent />
    </Panel>
  )
}
