import React from 'react'

import Button from 'components/UIKit/Button'
import Card from 'components/UIKit/Card'
import H1 from 'components/UIKit/H1'
import Space from 'components/UIKit/Space'

import Dashboard from '../Dashboard'

const NotFoundRoute = () => (
  <Dashboard noAction>
    <Card>
      <Space bottom="base">
        <H1>Not Found</H1>
        <p>Sorry. We can&apos;t seem to find that page.</p>
      </Space>
      <Button primary to="/" icon="home" label="Go Home" />
    </Card>
  </Dashboard>
)

export default NotFoundRoute
