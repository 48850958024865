import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import { graphql } from '@apollo/client/react/hoc'
import { ActionCreators } from 'redux-undo'
import { Route, Prompt, withRouter } from 'react-router-dom'
import isEqual from 'lodash-es/isEqual'
import get from 'lodash-es/get'

import routes from 'config/routes'
import {
  STEPS,
  getStepLayerId,
  getStepVisibleLayerIds,
  getStepLabel,
} from 'config/onboarding'
import { appendURL } from 'lib/utils'
import {
  onNextOnboardingStep,
  onFinishOnboarding,
  onSkipWizard,
} from 'config/analytics'
import { trackEvent } from 'lib/analytics'

import { GET_FACILITY_QUERY } from 'client/queries'
import { showAlert } from 'store/alert'
import {
  setCurrentLayer,
  setFocusedLayer,
  setVisibleLayers,
  toggleLayerLocked,
  toggleLayerVisibility,
} from 'store/layers'
import { showPanel, hidePanel, resetPanels, togglePanel } from 'store/panel'
import { DEFAULT_SELECTED_PANEL, CONTROLS_PANEL, SELECTED_BACKGROUND_IMAGE_PANEL } from 'store/panel/types'
import { loadFacility, resetFacility } from 'store/objects'
import { saveObjects } from 'store/objectsPersistence'
import { set2D, set3D } from 'store/camera'
import { selectObjects, deselectObjects } from 'store/selectedObjects/selectors'
import LAYER_KEYS from 'config/layerKeys'
import { TYPES } from 'store/units/constants'
import CLASS_NAMES from 'config/objectClassNames'

import Chrome from '../Chrome'

import A from 'components/UIKit/A'
import Button from 'components/UIKit/Button'
import Loader from 'components/UIKit/Loader'
import Logo from 'components/UIKit/Logo'
import Space from 'components/UIKit/Space'
import StepProgress from 'components/UIKit/StepProgress'
import Switch from 'components/UIKit/Switch'
import { ToolbarGroup, ToolbarItem } from 'components/UIKit/Toolbar'

import BackgroundImageToggleContainer from './styled/BackgroundImageToggleContainer'
import ConvertImageModal from 'components/Modals/ConvertImageModal'
import DockedPanelTab from 'components/DockedPanelTab'
import DrawingCanvas from 'components/DrawingCanvas'
import DrawingTools from 'components/DrawingTools'
import DrawingSettings from 'components/DrawingSettings'
import DrawingCanvasContainer from 'components/DrawingCanvasContainer'
import HistoryToolbarGroup from 'components/HistoryToolbarGroup'
import LogoContainer from './styled/LogoContainer'
import PanelManager from 'components/PanelManager'
import ScaleImageModal from 'components/Modals/ScaleImageModal'
import StatusBar from 'components/StatusBar'
import SubmitFeedbackButton from 'components/SubmitFeedbackButton'
import UploadImageModal from 'components/Modals/UploadImageModal'
import EditImageModal from 'components/Modals/EditImageModal'
import ZoomToolbarGroup from 'components/ZoomToolbarGroup'

// TODO: Abstract this into a shared location
import DrawingPanel from 'screens/FacilityScreen/styled/DrawingPanel'

import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

class Onboarding extends Component {
  state = {
    height: 600,
    loadedCanvas: false,
    width: 800,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)

    if (!this.props.facilityData.loading) {
      this.handleLoadData(this.props)
      this.facilityLoaded = true
    }

    setTimeout(this.handleWindowResize, 2500)
    this.autoSaveInterval = setInterval(this.handleAutoSave, 1000)
    window.addEventListener('beforeunload', this.handleWarnUnsaved)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const units = get(this.props, 'facilityData.facility.units')
    const nextUnits = get(nextProps, 'facilityData.facility.units')

    if (
      (this.props.facilityData.loading && !nextProps.facilityData.loading) ||
      !isEqual(units, nextUnits)
    ) {
      this.handleLoadData(nextProps)
      this.facilityLoaded = true
    } else if (
      this.props.facilityData.loading &&
      !nextProps.facilityData.loading
    ) {
      this.facilityLoaded = false
    }

    const { currentLayer, step } = nextProps
    if (currentLayer !== getStepLayerId(step)) {
      nextProps.onLayerChange({ step })
    }
  }

  componentWillUnmount() {
    const { onToggle2d } = this.props

    window.removeEventListener('resize', this.handleWindowResize)
    clearInterval(this.autoSaveInterval)
    window.removeEventListener('beforeunload', this.handleWarnUnsaved)
    onToggle2d()
  }

  getFinalFacilityUrl() {
    const facility = this.props.facilityData?.facility
    const facilityId = facility?.id
    const floorId = facility?.floor?.id
    const versionId = facility?.floor?.version?.id
    if (facilityId && floorId && versionId) {
      return `/facility/${facilityId}/area/${floorId}/version/${versionId}`
    } else {
      return `/facility/${this.props.match.params.id}`
    }
  }

  setNextLabel = () => (this.isLastStep() ? 'Finish' : 'Next')

  setNextUrl = () => {
    if (this.isLastStep()) {
      return this.getFinalFacilityUrl()
    }

    return `/new-facility/${this.props.match.params.id}${
      routes.onboarding[this.props.step + 1].short
    }`
  }

  setPreviousUrl = () =>
    `/new-facility/${this.props.match.params.id}${
      routes.onboarding[this.props.step - 1].short
    }`

  isLastStep = () => this.props.step === STEPS.length

  isFirstStep = () => this.props.step === 1

  handleAutoSave = () => {
    const { needsSaved, online, onSaveObjects, facilityData } = this.props
    const versionId = get(facilityData, 'facility.floor.version.id')

    if (needsSaved && versionId && online) {
      onSaveObjects({
        versionId,
      })
    }
  }

  handleWarnUnsaved = event => {
    if (this.props.needsSaved || this.props.isSaving) {
      const warning = 'Your facility has unsaved changes! Leave anyway?'
      event.returnValue = warning

      return event
    }

    return undefined
  }

  handleLoadData = props => {
    const data = get(props, 'facilityData.facility.floor.version.data')
    const units = get(props, 'facilityData.facility.units')

    if (data) {
      this.props.onLoadFacility({
        data: {
          objects: data.objects,
          segments: data.segments,
          products: data.products,
          obstructions: data.obstructions,
          dimensions: data.dimensions,
          roofs: data.roofs,
          roofSections: data.roofSections,
          elevationPoints: data.elevationPoints,
          elevationLines: data.elevationLines,
          ceilings: data.ceilings,
          units: data.units,
          backgroundImage: data.backgroundImage,
          layerKey: getStepLayerId(this.props.step),
          visibleLayerIds: getStepVisibleLayerIds(this.props.step),
        },
        units: {
          [TYPES.DISTANCE]: units,
          [TYPES.VELOCITY]: units,
          [TYPES.TEMPERATURE]: units,
        },
      })
    } else {
      this.props.onResetFacility({
        layerKey: getStepLayerId(this.props.step),
        visibleLayerIds: getStepVisibleLayerIds(this.props.step),
        units: {
          [TYPES.DISTANCE]: units,
          [TYPES.VELOCITY]: units,
          [TYPES.TEMPERATURE]: units,
        },
      })
    }
  }

  handleWindowResize = () => {
    if (this.containerEl) {
      this.setState({
        width: this.containerEl.offsetWidth,
        height: this.containerEl.offsetHeight,
        loadedCanvas: true,
      })
    }
  }

  handleFacilityMenuClick = (facility, event) => {
    event.preventDefault()

    this.props.history.push(
      `/facility/${facility.id}/floor/${facility.floor.id}/version/${facility.floor.version.id}`
    )
  }

  handleUnimplementedFeatureClick = event => {
    event.preventDefault()

    this.props.onShowAlert({
      text: 'This feature is not implemented yet.',
      type: 'warning',
    })
  }

  handleBackgroundImageEdit = () => {
    this.props.onToggleBackgroundImageActive(this.props.backgroundImageActive)
  }

  filterAllFacilitiesData = () => {
    const { allFacilitiesData, facilityData } = this.props

    return allFacilitiesData.allFacilities.map(facility => ({
      id: facility.id,
      title: facility.name,
      onClick: event => this.handleFacilityMenuClick(facility, event),
      active: facility.id === facilityData.facility.id,
      icon: {
        name: 'building',
      },
    }))
  }

  render() {
    const {
      backgroundImage,
      backgroundImageVisible,
      controlsActive,
      hasDockedPanel,
      isSaving,
      match,
      needsSaved,
      onToggle2d,
      onToggle3d,
      onToggleBackgroundImage,
      onTogglePanel,
      online,
      is3D,
      showPanel,
      step,
    } = this.props

    return this.facilityLoaded ? (
      <Chrome>
        <Prompt
          when={isSaving || (needsSaved && online)}
          message="Are you sure you want to leave? You have unsaved changes!"
        />
        <ToolbarGroup key="topLeft" size="25%" alignment="left">
          <ToolbarItem space="s" paddingLeft="base" paddingRight="base">
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup key="topCenter" size="50%" alignment="center">
          <ToolbarItem space="s" fullWidth>
            <StepProgress steps={STEPS} current={this.props.step} />
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup
          key="topRight"
          size="25%"
          alignment="right"
          paddingRight="base"
        >
          {!this.isFirstStep() && (
            <ToolbarItem space="s">
              <Button
                disabled={isSaving}
                to={this.setPreviousUrl()}
                label="Previous"
                onClick={event => {
                  /* Adding a prevent default to disable the link til the objectsPersistence is resolved. */
                  if (isSaving) event.preventDefault()
                }}
              />
            </ToolbarItem>
          )}
          <ToolbarItem space="s">
            <Button
              primary
              disabled={isSaving || needsSaved}
              to={this.setNextUrl()}
              label={this.setNextLabel()}
              onClick={event => {
                /* Adding a prevent default to disable the link til the objectsPersistence is resolved. */
                if (isSaving || needsSaved) {
                  event.preventDefault()
                } else {
                  if (this.setNextLabel() === 'Next') {
                    trackEvent(onNextOnboardingStep())
                  } else {
                    trackEvent(onFinishOnboarding())
                  }
                }
              }}
            />
          </ToolbarItem>
          <ToolbarItem space="s" paddingLeft="s" paddingRight="s">
            <A
              to={this.getFinalFacilityUrl()}
              onClick={() => trackEvent(onSkipWizard())}
            >
              Skip Wizard
            </A>
          </ToolbarItem>
        </ToolbarGroup>
        <HistoryToolbarGroup key="middleLeft" online={online} />
        <ToolbarGroup key="middleCenter" size="60%" alignment="center">
          <ToolbarItem space="s">
            <p>{this.props.message}</p>
          </ToolbarItem>
        </ToolbarGroup>
        <ZoomToolbarGroup key="middleRight" />
        <div key="canvas">
          <DrawingCanvasContainer
            hasDockedPanel={hasDockedPanel}
            key="canvas"
            ref={el => {
              this.containerEl = el
            }}
          >
            <>
              <DrawingPanel>
                <Space bottom="s">
                  <DrawingTools online={online} />
                </Space>
                <DrawingSettings online={online} />
              </DrawingPanel>
              <SubmitFeedbackButton />
              <DrawingCanvas
                online={online}
              />
            </>
          </DrawingCanvasContainer>
          <PanelManager online={online} />
          {!hasDockedPanel && (
            <DockedPanelTab
              onClick={event => {
                event.preventDefault()
                showPanel({ type: DEFAULT_SELECTED_PANEL })
              }}
            />
          )}
          <SentryRoute
            path={`${match.url}${routes.modals.uploadImage}`}
            render={props => (
              <UploadImageModal parentRoute={match.url} {...props} />
            )}
          />
          <SentryRoute
            path={`${match.url}${routes.modals.editImage}`}
            render={props => (
              <EditImageModal parentRoute={match.url} {...props} />
            )}
          />
          <SentryRoute
            path={`${match.url}${routes.modals.convertImage}`}
            render={props => (
              <ConvertImageModal parentRoute={match.url} {...props} />
            )}
          />
          <SentryRoute
            path={`${match.url}${routes.modals.scaleImage}`}
            render={props => (
              <ScaleImageModal parentRoute={match.url} {...props} />
            )}
          />
        </div>
        <ToolbarGroup key="bottomLeft" flexGrow="0" alignment="left">
          <ToolbarItem separator="right" paddingLeft="base" paddingRight="base">
            {backgroundImage[LAYER_KEYS.BACKGROUND_IMAGE] ? (
              <BackgroundImageToggleContainer>
                <Switch
                  name="backgroundImage"
                  label="Background Image"
                  onClick={onToggleBackgroundImage}
                  isChecked={backgroundImageVisible}
                />
                <small>
                  <A onClick={this.handleBackgroundImageEdit}>
                    {this.props.backgroundImageActive ? 'Done' : 'Edit'}
                  </A>
                </small>
              </BackgroundImageToggleContainer>
            ) : (
              <Button
                label="Trace Image"
                icon="image"
                to={appendURL(match.url, '/upload-image/background')}
              />
            )}
          </ToolbarItem>
        </ToolbarGroup>
        <ToolbarGroup key="bottomCenter" flexGrow="1" alignment="center">
          <StatusBar />
        </ToolbarGroup>
        <ToolbarGroup key="bottomRight" flexGrow="0" alignment="right">
          <ToolbarItem separator="left" paddingLeft="base" paddingRight="base">
            <Switch
              name="3d"
              label="3D View"
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                return !is3D
                  ? onToggle3d(CONTROLS_PANEL)
                  : onToggle2d(CONTROLS_PANEL)
              }}
              isChecked={is3D}
              responsive
            />
            <Button
              dropdown={is3D}
              paddingLeft="0px"
              paddingRight="0px"
              display={is3D ? 'inline-flex' : 'none'}
              noBorder
              dropdownReverse
              onClick={() => onTogglePanel(CONTROLS_PANEL)}
              isDropdownVisible={controlsActive === CONTROLS_PANEL}
            />
          </ToolbarItem>
        </ToolbarGroup>
      </Chrome>
    ) : (
      <Loader
        label={
          step === 1
            ? 'Loading...'
            : `Loading ${getStepLabel(step) || 'facility'}...`
        }
        centered
      />
    )
  }
}

const mapStateToProps = ({
  auth,
  objects,
  objectsPersistence,
  camera,
  panel,
  layers,
  selectedObjects,
}) => ({
  userId: auth.userId,
  needsSaved: objectsPersistence.needsSaved,
  isSaving: objectsPersistence.isSaving,
  is3D: camera.is3D,
  hasDockedPanel: panel.right.visiblePanel !== null,
  backgroundImage: objects.present.backgroundImage,
  backgroundImageVisible: layers.layers[LAYER_KEYS.BACKGROUND_IMAGE].visible,
  controlsActive: panel.other.visiblePanel,
  backgroundImageActive: !layers.layers[LAYER_KEYS.BACKGROUND_IMAGE].locked,
  currentLayer: layers.currentLayer,
})

const mapDispatchToProps = dispatch => ({
  onLayerChange({ step }) {
    const newLayerKey = getStepLayerId(step)
    dispatch(setCurrentLayer({ layerKey: newLayerKey }))
  },
  onShowAlert(options) {
    dispatch(showAlert(options))
  },
  onTogglePanel(panel) {
    dispatch(togglePanel({ type: panel }))
  },
  onLoadFacility({ data, units }) {
    const { layerKey, visibleLayerIds } = data

    deselectObjects({})
    dispatch(loadFacility({ data, units }))
    dispatch(resetPanels())
    dispatch(setVisibleLayers({ visibleLayerIds, needsSaved: false }))
    dispatch(
      setFocusedLayer({
        layerKey,
        needsSaved: false,
      })
    )
    dispatch(ActionCreators.clearHistory())
    dispatch(set2D())
  },

  onResetFacility({ layerKey, visibleLayerIds, units }) {
    dispatch(resetFacility())
    dispatch(loadFacility({ data: {}, units }))
    dispatch(setCurrentLayer({ layerKey }))
    dispatch(setVisibleLayers({ visibleLayerIds }))
    dispatch(setFocusedLayer({ layerKey }))
    dispatch(resetPanels())
    dispatch(ActionCreators.clearHistory())
  },

  onSaveObjects({ versionId }) {
    dispatch(saveObjects({ versionId, saveLayers: false }))
  },

  onToggle2d(panel) {
    dispatch(set2D())
    dispatch(hidePanel({ type: panel }))
  },

  onToggle3d(panel) {
    dispatch(set3D())
    dispatch(showPanel({ type: panel }))
  },

  onToggleBackgroundImageActive(active) {
    if (active) {
      dispatch(toggleLayerLocked({ layerKey: LAYER_KEYS.BACKGROUND_IMAGE }))
      dispatch(showPanel({ type: DEFAULT_SELECTED_PANEL }))
    } else {
      dispatch(toggleLayerLocked({ layerKey: LAYER_KEYS.BACKGROUND_IMAGE }))
      dispatch(showPanel({ type: SELECTED_BACKGROUND_IMAGE_PANEL }))
    }
  },

  showPanel(payload) {
    dispatch(showPanel(payload))
  },

  onToggleBackgroundImage() {
    dispatch(
      toggleLayerVisibility({
        layerKey: LAYER_KEYS.BACKGROUND_IMAGE,
      })
    )
  },
})

Onboarding.propTypes = {
  allFacilitiesData: PropTypes.object,
  backgroundImage: PropTypes.object,
  backgroundImageActive: PropTypes.bool,
  backgroundImageVisible: PropTypes.bool,
  controlsActive: PropTypes.string,
  currentLayer: PropTypes.string,
  facilityData: PropTypes.object,
  hasDockedPanel: PropTypes.bool,
  history: PropTypes.object,
  isSaving: PropTypes.bool,
  match: PropTypes.object,
  message: PropTypes.string,
  needsSaved: PropTypes.bool,
  onLayerChange: PropTypes.func,
  onLoadFacility: PropTypes.func,
  onResetFacility: PropTypes.func,
  onSaveObjects: PropTypes.func,
  onShowAlert: PropTypes.func,
  onToggle2d: PropTypes.func,
  onToggle3d: PropTypes.func,
  onToggleBackgroundImage: PropTypes.func,
  onToggleBackgroundImageActive: PropTypes.func,
  onTogglePanel: PropTypes.func,
  online: PropTypes.bool,
  resetPanels: PropTypes.func,
  showPanel: PropTypes.func,
  step: PropTypes.number,
}

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  graphql(GET_FACILITY_QUERY, {
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
        floorId: match.params.floorId || null,
        versionId: match.params.versionId || null,
      },
      fetchPolicy: 'network-only',
    }),
    name: 'facilityData',
  }),
  withRouter
)(Onboarding)
