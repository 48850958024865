import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

export const TabsContainer = styled.div`
  border-bottom: 1px solid ${theme('colors.light.subdued')};
  display: flex;
  margin-bottom: ${theme('spacing.base')};
  ${({ splitEvenly }) => splitEvenly && '& > button {flex-grow: 1}'};
`
