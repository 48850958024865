import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Text = styled.p`
  color: ${theme('colors.light.fg')};
  line-height: 1.3;
  max-width: 500px;
`

export default Text
