import React from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";

import { isTouchUI } from 'store/userInterface/selectors'
import theme from 'config/theme'

import Tooltip from 'components/UIKit/Tooltip'

import Badge from './styled/Badge'
import Icon from './styled/Icon'

const iconForType = (type, icon) => {
  if (icon) {
    return icon
  }

  if (type === 'error') {
    return 'cross'
  }

  if (type === 'warning') {
    return 'warn'
  }

  return 'check'
}

const getIconSize = (type, icon) => {
  if (icon === 'help') {
    return '16'
  }
  return '10'
}

const StatusBadge = ({
  type,
  icon,
  animated,
  placement,
  tooltipTitle,
  trigger,
  isTouchUI,
}) => {
  if (tooltipTitle) {
    return (
      <Tooltip content={tooltipTitle} placement={placement} trigger={trigger}>
        <Badge type={type}>
          <Icon
            name={iconForType(type, icon)}
            color={theme.colors.light.bg}
            size={getIconSize(type, icon)}
            animated={animated}
          />
        </Badge>
      </Tooltip>
    )
  }

  return (
    <Badge type={type} isTouchUI={isTouchUI}>
      <Icon
        name={iconForType(type, icon)}
        color={theme.colors.light.bg}
        size="10"
        animated={animated}
      />
    </Badge>
  )
}

StatusBadge.defaultProps = {
  placement: 'right',
}

StatusBadge.propTypes = {
  animated: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
  tooltipTitle: PropTypes.string,
  trigger: PropTypes.any,
  placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  isTouchUI: PropTypes.bool,
}

const mapStateToProps = store => ({
  isTouchUI: isTouchUI(store),
})

export default /** @type any */(appConnect(mapStateToProps)(StatusBadge));
