import { graphql } from '~/gql'

const GET_AR_OPTIONS_QUERY = graphql(`
  query GetAROptions($catalog: String!, $model: String) {
    AROptions(catalog: $catalog, model: $model) {
      option
    }
  }
`)

export default GET_AR_OPTIONS_QUERY
