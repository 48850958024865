import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { LOAD_FACILITY } from '../objects/action_types'

const initialState = {
  is3D: false,
  three: {
    rotation: {
      polar: 0.5 * Math.PI,
      azimuth: 0,
      passive: false,
    },
    position: {
      location: [0, 0, 500] as [number, number, number],
      target: [0, 0, 0] as [number, number, number],
      passive: false,
    },
  },
  two: {
    position: {
      location: [0, 0] as [number, number],
      target: [0, 0] as [number, number],
      passive: true,
    },
    zoom: {
      factor: 1,
      passive: true,
    },
    fit: false,
  },
}

const slice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    zoomIn(state) {
      state.two.zoom.factor = parseFloat((state.two.zoom.factor * 1.1).toFixed(2))
      state.two.zoom.passive = false
    },
    zoomOut(state) {
      state.two.zoom.factor = parseFloat((state.two.zoom.factor * 0.9).toFixed(2))
      state.two.zoom.passive = false
    },
    setZoom(state, action: PayloadAction<{ factor: number; passive: boolean }>) {
      state.two.zoom = action.payload
    },
    set3D(state) {
      state.is3D = true
    },
    set2D(state) {
      state.is3D = false
    },
    start2DFit(state) {
      state.two.fit = true
    },
    fitFinished(state) {
      state.two.fit = false
    },
    set3DRotation(state, action: PayloadAction<{ polar: number; azimuth: number; passive: boolean }>) {
      state.three.rotation = action.payload
    },
    set3DPosition(state, action: PayloadAction<{ location: [number, number, number]; target?: [number, number, number]; passive: boolean }>) {
      state.three.position.location = action.payload.location
      if (action.payload.target) state.three.position.target = action.payload.target
      state.three.position.passive = action.payload.passive
    },
    set2DPosition(state, action: PayloadAction<{ location: [number, number]; target?: [number, number]; passive: boolean }>) {
      state.two.position.location = action.payload.location
      if (action.payload.target) state.two.position.target = action.payload.target
      state.two.position.passive = action.payload.passive
    },
    set2DZoom(state, action: PayloadAction<{ factor: number; passive: boolean }>) {
      state.two.zoom = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(LOAD_FACILITY, () => {
        return { ...initialState, two: { ...initialState.two }}
      })
  },
})

export default slice.reducer
export const { zoomIn, zoomOut, setZoom, set3D, set2D, set3DRotation, set3DPosition, set2DPosition, set2DZoom, start2DFit, fitFinished } = slice.actions
