import * as router from './actions'

const initialState = {
  hash: '',
  pathname: '',
  key: '',
  search: '',
  state: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case router.CHANGE_ROUTE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default: {
      return state
    }
  }
}
