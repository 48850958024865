import styled from 'styled-components'
import theme from 'config/theme'

const RowContainer = styled.div`
  cursor: pointer;
  height: ${theme.spacing.square};
  padding: ${theme.spacing.m} ${theme.spacing.base};
  text-align: left;
  width: 100%;

  &:hover {
    background-color: ${theme.contextMenu.hoverColor};
  }
`

export default RowContainer
