import styled from 'styled-components'

const Close = styled.a`
  display: block;
  flex-shrink: 0;
  ${props =>
    props.marginLeft &&
    `margin-left: ${props.theme.spacing[props.marginLeft]};`}
  transition: 0.3s ease-in-out;
  cursor: pointer;
  &:active,
  &:focus,
  &:hover {
    transform: scale(1.2);
  }
`

export default Close
