export const SET_ACTIVE_TOOL = 'SET_ACTIVE_TOOL'
export const SET_ACTIVE_TOOL_CATEGORY = 'SET_ACTIVE_TOOL_CATEGORY'
export const SET_SNAP_TOGGLE = 'SET_SNAP_TOGGLE'
export const SET_ORTHO_MODE_TOGGLE = 'SET_ORTHO_MODE_TOGGLE'
export const SET_CONTINUOUS_MODE_TOGGLE = 'SET_CONTINUOUS_MODE_TOGGLE'
export const UPDATED_OVERLAY_ACTIVE_STATUS = 'UPDATED_OVERLAY_ACTIVE_STATUS'

export const setActiveTool = payload => ({
  type: SET_ACTIVE_TOOL,
  payload,
})

export const setActiveToolCategory = payload => ({
  type: SET_ACTIVE_TOOL_CATEGORY,
  payload,
})

export const setSnapToggle = payload => ({
  type: SET_SNAP_TOGGLE,
  payload,
})

export const setContinuousModeToggle = payload => ({
  type: SET_CONTINUOUS_MODE_TOGGLE,
  payload,
})

export const setOrthoModeToggle = payload => ({
  type: SET_ORTHO_MODE_TOGGLE,
  payload,
})

export const updateOverlayActiveStatus = payload => ({
  type: UPDATED_OVERLAY_ACTIVE_STATUS,
  payload,
})
