import sample from 'lodash-es/sample'
import * as THREE from 'three'

import LAYERS from 'config/layerKeys'
import theme from 'config/theme'

import Facility from './facility'
import Tool from './tool'
import Util from './util'
import Units from './units'
import Offset from './offset'
import Primitives from './primitives'
import Obstruction from './obstruction'
import ComfortZone from './comfortZone'
import Wall from './wall'
import WallSegment from './wallSegment'
import { SnapPoint } from './snapRegion'
import store from 'store'
import { isTouchUI } from 'store/userInterface/selectors'
import { clearStatus, setStatus } from 'store/status'
import {
  addObject,
  deleteObjects,
  addObstruction,
  addComfortZone,
} from 'store/objects'
import { isSnapEnabled } from 'store/tools/selectors'
import { getThreeHexFromTheme } from 'lib/utils'
import SnapQueries from './snapQueries'

const MINIMUM_ZONE_SIZE = 2

class LineTool extends Tool {
  /*
    -= properties =-

    newSegmentEndPoint : the end point of the line segment which would be created given the user's current mouse position
    startedWall: whether at least one point has been added to a wall description
    currentWallPoints: all the points so far added to the current wall description
    obj3d: visual representation of the wall described so far (a THREE.Object3D)
    textInput: jquery object for the floating text input element
    cursor: THREE.Object3D representing the cursor
    cachedWallId: If connecting points to existing wall, store ID of original wall to delete
  */
  static WALL_SNAP_DISTANCE = Units.feetToNative(10)
  static OBSTRUCTION_SNAP_DISTANCE = Units.feetToNative(3)
  static ORTHOGONAL_SNAP_DISTANCE = Units.feetToNative(3)
  static CONNECT_TO_EXISTING_SEGMENT_SNAP_DISTANCE = Units.feetToNative(5)

  constructor() {
    super()

    this.name = 'LINE_TOOL'

    this.startedWall = false
    this.currentWallPoints = []
    this.lastWallPointAdded = undefined
    this.id = Util.guid()

    this.cachedWallId = undefined
    this.connectedToExistingWall = false
    this.scene = Util.getSceneGraphRootFromNode(Facility.current.obj3d)

    this.obj3d = new THREE.Object3D()
    this.cursor = this.createCursor(1)

    const appState = store.getState()
    this.layer = appState.layers.currentLayer
    this.units = appState.objects.present.units
    this.color = sample(theme.colors.swatches)

    this.obj3d.add(this.cursor)
    this.initTextInput()
  }

  toolDown(
    mousePos,
    snappedMousePos,
    _,
    objectUnderTool,
    snappedObjectUnderTool,
    isTouch = false
  ) {
    this.startedWall = true
    this.hideArrows = true

    if (isTouch) return

    if (this.isWall()) {
      const isInteriorWallLayer = this.layer === 'INTERIOR_WALLS'
      const objectIsWallSegment = objectUnderTool instanceof WallSegment

      if (isInteriorWallLayer) {
        // Only set snapped position for interior walls if over a wall segment
        if (objectIsWallSegment) {
          // Don't allow interior walls to be started and ended on another segment
          if (this.pointStartedOnWallSegment) {
            if (this.pointStartedOnWallSegment.id === objectUnderTool.id) {
              store.dispatch(
                setStatus({
                  type: 'warning',
                  text: `Can't place wall segment on an existing segment`,
                })
              )
              return
            }
          }
          this.connectToExistingWall(snappedMousePos)
          this.pointStartedOnWallSegment = objectUnderTool
          this.connectedToExistingWall = true
        } else {
          if (Util.isPositionsOverFacility([snappedMousePos])) {
            this.createSegment(snappedMousePos)
            this.addPointToWallDescription(snappedMousePos)
            this.pointStartedOnWallSegment = undefined
          } else {
            this.startedWall = false
            const error = 'Interior walls must be placed inside a facility!'
            store.dispatch(setStatus({ text: error, type: 'error' }))
          }
          return
        }
      } else {
        this.connectToExistingWall(snappedMousePos)
      }
    }

    this.createSegment(snappedMousePos)
    this.addPointToWallDescription(snappedMousePos)

    return true
  }

  /*
    @param mousePos
    @param snappedMousePos
  */
  toolUp({
    mousePos,
    snappedMousePos,
    sceneIntersectionPoint,
    objectUnderTool,
    objectUnderSnappedTool,
    multiSelect,
    allIntersectedObjects,
    isTouch = false,
  }) {
    if (!isTouch) return

    this.startedWall = true
    this.hideArrows = true

    if (this.isWall()) {
      const isInteriorWallLayer = this.layer === 'INTERIOR_WALLS'
      const objectIsWallSegment = objectUnderTool instanceof WallSegment

      if (isInteriorWallLayer) {
        // Only set snapped position for interior walls if over a wall segment
        if (objectIsWallSegment) {
          this.connectToExistingWall(snappedMousePos)
          this.connectedToExistingWall = true
        } else {
          this.createSegment(mousePos)
          this.addPointToWallDescription(mousePos)
          return
        }
      } else {
        this.connectToExistingWall(snappedMousePos)
      }
    }

    this.createSegment(snappedMousePos)
    this.addPointToWallDescription(snappedMousePos)
  }

  toolMoved(
    mousePos,
    snappedMousePos,
    sceneIntersectionPoint,
    objectUnderTool
  ) {
    if (this.isWall()) {
      const isInteriorWallLayer = this.layer === 'INTERIOR_WALLS'
      const snapping = mousePos !== snappedMousePos
      const objectIsWallSegment = objectUnderTool instanceof WallSegment
      const point = [snappedMousePos.x, snappedMousePos.y]

      if (isInteriorWallLayer) {
        // Only set snapped position for interior walls if over a wall segment
        if (objectIsWallSegment) {
          if (objectUnderTool.layerKey === 'EXTERIOR_WALLS')
            this.cursor.scale.set(4, 4, 1)
          else this.cursor.scale.set(3, 3, 1)
          this.reCreateEdgeCircle(point)
        } else {
          this.cursor.scale.set(1, 1, 1)
          this.cursor.position.set(mousePos.x, mousePos.y, 0)
          this.removeEdgeCircle()
          this.createSegment(mousePos)
          return
        }
      } else {
        // Show visual if we're connecting line segment to a wall
        if (snapping && objectIsWallSegment) {
          if (objectUnderTool.layerKey === 'EXTERIOR_WALLS')
            this.cursor.scale.set(4, 4, 1)
          else this.cursor.scale.set(3, 3, 1)
          this.reCreateEdgeCircle(point)
        } else {
          this.removeEdgeCircle()
          this.cursor.scale.set(1, 1, 1)
        }
      }
    }

    this.cursor.position.set(snappedMousePos.x, snappedMousePos.y, 0)
    this.createSegment(snappedMousePos)
  }

  connectToExistingWall(snappedMousePos) {
    if (this.currentWallPoints.length === 0) {
      let point = {}
      const segments = Facility.current.getWallSegments()

      // Check mouse position against segment points
      for (let i = 0; i < segments.length; i++) {
        const s = segments[i]
        if (!s.nextSegment) {
          point = Util.arrayPointToObjectPoint(s.getCenterLinePoints()[1])
        } else if (!s.previousSegment) {
          point = Util.arrayPointToObjectPoint(s.getCenterLinePoints()[0])
        }

        // If we connected to an existing wall, copy the wall points to
        // this.currentWallPoints to recreate the wall
        if (point.x === snappedMousePos.x && point.y === snappedMousePos.y) {
          const wall = Facility.current.getWallWithId(s.parentId)
          const globalPoints = wall.globalCenterLinePoints()

          if (globalPoints.length > 1) {
            // If the user clicks a segment == first point in array, the polygon will
            // complete itself. Since we're recreating the wall, just reverse the order
            const threshold = isTouchUI() ? 0.8 : undefined
            if (
              Util.pointsAreEqual2D(
                globalPoints[0],
                [point.x, point.y],
                threshold
              )
            ) {
              globalPoints.reverse()
            }
          }

          this.currentWallPoints = globalPoints
          this.cachedWallId = wall.id
          this.connectedToExistingWall = true

          break
        }
      }
    }
  }

  createSegment(snappedMousePos) {
    const lastPoint = this.currentWallPoints[this.currentWallPoints.length - 1]
    const newPoint = [snappedMousePos.x, snappedMousePos.y]

    this.newSegmentEndPoint = new THREE.Vector3(
      snappedMousePos.x,
      snappedMousePos.y,
      0
    )

    if (
      lastPoint !== undefined &&
      !(lastPoint[0] === newPoint[0] && lastPoint[1] === newPoint[1])
    ) {
      this.updateNewSegmentLengthDisplay()

      if (this.isWall()) {
        this.reCreateWallMesh(this.currentWallPoints)
        this.reCreateNewSegmentMesh(newPoint)
      } else if (this.isObstruction()) {
        this.reCreateWallMesh([...this.currentWallPoints, newPoint])
        this.reCreateNewSegmentMesh(newPoint)
      } else if (this.isComfortZone()) {
        this.reCreateWallMesh([...this.currentWallPoints, newPoint])
        this.reCreateNewSegmentMesh(newPoint)
      }
    }
  }

  reCreateWallMesh(wallPoints) {
    if (wallPoints.length > 1) {
      this.obj3d.remove(this.wallMesh)

      if (this.isWall()) {
        const model = Wall.createModel(wallPoints, this.units, this.layer, {
          convertFromNativeUnits: true,
        })

        this.object = new Wall(model, this.units)
        this.object.segments.forEach(segment => (segment.wall = this.object))
      } else if (this.isObstruction()) {
        const model = Obstruction.createModel(
          wallPoints.map(Util.arrayPointToObjectPoint)
        )

        this.object = new Obstruction(model, this.units)
      } else if (this.isComfortZone()) {
        const model = ComfortZone.createModel(
          wallPoints.map(Util.arrayPointToObjectPoint),
          this.color
        )

        this.object = new ComfortZone(model, this.units)
      }

      if (this.object) {
        this.wallMesh = this.object.obj3d

        this.obj3d.add(this.wallMesh)
      }
    }
  }

  reCreateNewSegmentMesh(newPoint) {
    // Create visual for the 'new segment' (going from the last wall point to the current mouse position)
    const newSegmentPoints = [
      this.currentWallPoints[this.currentWallPoints.length - 1],
      newPoint,
    ]
    this.obj3d.remove(this.newSegmentMesh)

    this.newSegmentMesh = Primitives.getWallMesh(
      newSegmentPoints,
      Wall.thicknessForLayerKey(this.layer, true),
      Wall.heightForLayerKey(this.layer, true)
    )
    this.obj3d.add(this.newSegmentMesh)
  }

  // Create a circle if the segment will "attach" to an existing wall
  // If the segment is "attached" to 2 existing walls, it will bisect the existing region
  reCreateEdgeCircle(point) {
    this.removeEdgeCircle()

    const edgeCircle = Primitives.getCircle(LineTool.WALL_SNAP_DISTANCE * 10)
    edgeCircle.name = 'EdgeCircle'
    edgeCircle.position.x = point[0]
    edgeCircle.position.y = point[1]
    this.scene.add(edgeCircle)
  }

  /*
    @param mousePos
    @param snappedMousePos
  */
  toolFinish() {
    this.finishWallDescription()
  }

  activate(mousePos) {
    this.cursor.position.set(mousePos.x, mousePos.y, 0)
  }

  deactivate() {
    this.removeEdgeCircle()
    if (this.startedWall) this.finishWallDescription()
  }

  frameRendered(deltaMillis, canvasState) {
    if (this.shouldDisplayTextInput()) {
      if (!this.currentWallPoints || !this.currentWallPoints.length) return
      this.textInput.style.display = 'block'

      const lastWallPoint =
        this.currentWallPoints[this.currentWallPoints.length - 1]

      const lastPoint = new THREE.Vector3(lastWallPoint[0], lastWallPoint[1], 0)
      const newSegmentLength = this.newSegmentLength()
      const newSegmentDirection = this.newSegmentDirection()
      const textInputPosition = lastPoint
        .clone()
        .addScaledVector(newSegmentDirection, newSegmentLength / 2)

      const screenPos = Util.vec3ToScreenPoint(
        textInputPosition,
        canvasState.camera,
        canvasState.width,
        canvasState.height
      )

      this.textInput.style.top = `${screenPos.y}px`
      this.textInput.style.left = `${screenPos.x}px`
    } else {
      this.textInput.style.display = 'none'
    }
  }

  /*
    Updates the value in the text input to give the current
    length in feet and inches of the wall segment which would
    be created given the user's current mouse position
  */
  updateNewSegmentLengthDisplay() {
    this.textInput.value = Units.toDistanceString(this.newSegmentLength())

    if (!isTouchUI()) {
      this.textInput.select()
    }
  }

  /*
    Length of the wall segment which would be created given the user's current mouse position
  */
  newSegmentLength() {
    const lastWallPoint =
      this.currentWallPoints[this.currentWallPoints.length - 1]

    if (!this.newSegmentEndPoint) return 0

    const newPoint = this.newSegmentEndPoint.clone()
    const lastPoint = new THREE.Vector3(lastWallPoint[0], lastWallPoint[1], 0)
    return newPoint.clone().sub(lastPoint).length()
  }

  /*
    Direction of the wall segment which would be created given the user's current mouse position
  */
  newSegmentDirection() {
    const lastWallPoint =
      this.currentWallPoints[this.currentWallPoints.length - 1]

    if (!this.newSegmentEndPoint) return 0

    const newPoint = this.newSegmentEndPoint.clone()
    const lastPoint = new THREE.Vector3(lastWallPoint[0], lastWallPoint[1], 0)
    return newPoint.clone().sub(lastPoint).normalize()
  }

  shouldDisplayTextInput() {
    return this.startedWall
  }

  addPointToWallDescription(point) {
    // Required to be in array style by offset.js
    const arrayStylePoint = [point.x, point.y]

    const previousPoint =
      this.currentWallPoints[this.currentWallPoints.length - 1]

    const notFirstPoint = previousPoint !== undefined

    // Prevent adding the same point twice from a double click or something
    if (
      notFirstPoint &&
      Util.pointsAreEqual2D(
        previousPoint,
        arrayStylePoint,
        Units.inchesToNative(1)
      )
    ) {
      return
    }

    // don't let a wall stack on itself
    if (this.currentWallPoints.length >= 2) {
      if (
        Util.isPointOnSegment(
          Util.arrayPointToObjectPoint(previousPoint),
          point,
          Util.arrayPointToObjectPoint(
            this.currentWallPoints[this.currentWallPoints.length - 2]
          )
        ) ||
        Util.distanceToLineSegment(
          previousPoint[0],
          previousPoint[1],
          point.x,
          point.y,
          this.currentWallPoints[this.currentWallPoints.length - 2][0],
          this.currentWallPoints[this.currentWallPoints.length - 2][1]
        ) < Units.inchesToNative(1)
      ) {
        store.dispatch(
          setStatus({
            type: 'warning',
            text: `Can't place wall on itself.`,
          })
        )
        return
      }
    }

    store.dispatch(clearStatus())

    this.currentWallPoints.push(arrayStylePoint)

    this.updateNewSegmentLengthDisplay()

    // Store the object style point
    this.lastWallPointAdded = point

    // The point just added overlapped the first point, forming a polygon
    if (this.firstAndLastpointsAreEqual2D()) {
      this.addedPointOverlappingFirstPoint()
    }

    // If we've already added one point, and the point just added overlapped
    // an existing wall segment
    if (
      notFirstPoint &&
      ((this.objectWithCursor instanceof WallSegment && this.isWall()) ||
        (this.objectWithCursor instanceof Obstruction && this.isObstruction()))
    ) {
      this.finishWallDescription()
    }
  }

  isWall() {
    return (
      this.layer === LAYERS.EXTERIOR_WALLS ||
      this.layer === LAYERS.INTERIOR_WALLS
    )
  }

  isObstruction() {
    return this.layer === LAYERS.OBSTRUCTIONS
  }

  isComfortZone() {
    return this.layer === LAYERS.COMFORT_ZONES
  }

  addedPointOverlappingFirstPoint() {
    const firstWallPoint = this.currentWallPoints[0]
    const lastWallPoint =
      this.currentWallPoints[this.currentWallPoints.length - 1]

    // It's important that the first and last points are exactly the
    // the same (since that's sometimes used to check whether a sequence of
    // points forms a polygon). We may have gotten here just because these
    // first and last points were close; this makes them exactly the same.
    lastWallPoint[0] = firstWallPoint[0]
    lastWallPoint[1] = firstWallPoint[1]

    this.finishWallDescription()
  }

  firstAndLastpointsAreEqual2D() {
    const firstWallPoint = this.currentWallPoints[0]
    const lastWallPoint =
      this.currentWallPoints[this.currentWallPoints.length - 1]

    const threshold = isTouchUI() ? 0.8 : undefined

    return (
      this.currentWallPoints.length > 2 &&
      Util.pointsAreEqual2D(firstWallPoint, lastWallPoint, threshold)
    )
  }

  mergeRedundantSegments(segments) {
    const segmentStack = segments.slice().reverse()
    const segmentsFormPolygon = this.isPolygon(
      Wall.getCenterLinePointsFromSegments(segments)
    )

    const finalSegments = segments
    finalSegments.length = 0

    let usedFirstSegment = false

    while (segmentStack.length > 0) {
      const seg1 = segmentStack.pop()
      let seg2 = segmentStack[segmentStack.length - 1]
      if (!seg2 && segmentsFormPolygon) {
        usedFirstSegment = true
        seg2 = finalSegments[0] // Use the polygon's first segment as the last seg2
      }

      if (seg2) {
        // This will return false for collinear points that are very close together...
        const segmentsFormLine =
          Util.distanceToLineSegment(
            seg1.startPoint.x,
            seg1.startPoint.y,
            seg2.endPoint.x,
            seg2.endPoint.y,
            seg2.startPoint.x,
            seg2.startPoint.y
          ) < Units.inchesToNative(1)

        if (segmentsFormLine) {
          seg1.endPoint = seg2.endPoint
          if (segmentStack.length) {
            // Get rid of seg2 from stack
            segmentStack.pop()
            // Push the new segment back onto stack to compare against
            segmentStack.push(seg1)
          } else {
            if (usedFirstSegment) {
              // Replace first segment in finalized list to preserve the order.
              finalSegments.shift()
              finalSegments.unshift(seg1)
            } else finalSegments.push(seg1)
          }
        } else {
          // No need to merge, re-add both originals
          finalSegments.push(seg1)
        }
      } else {
        // We only had one segment left
        finalSegments.push(seg1)
      }
    }
  }

  isPolygon(centerLinePoints) {
    if (!centerLinePoints) return false
    return Offset.isPolygon(centerLinePoints)
  }

  makeCounterClockwise(centerLinePoints) {
    let bTempSegment = false
    // Adds a temporary segment to close the polygon for algo if we need it
    if (!this.isPolygon(centerLinePoints) && centerLinePoints.length > 2) {
      const newLinePoint = centerLinePoints[0]
      centerLinePoints.push(newLinePoint)
      bTempSegment = true
    }

    const cClockwise = Util.pointsAreCounterClockwise(centerLinePoints)
    if (this.isPolygon(centerLinePoints) && !cClockwise) {
      if (centerLinePoints.length > 2) {
        if (bTempSegment) centerLinePoints.pop()
        // Ensure segments are in counter-clockwise order
        centerLinePoints.reverse()
      }
    } else if (cClockwise && bTempSegment) {
      centerLinePoints.pop()
    }
  }

  // Get outset points for a given set of centerline points
  extrudePoints(centerLinePoints) {
    if (centerLinePoints.length <= 2) return centerLinePoints

    // Ensure c-clockwise so algo knows which direction to extrude
    this.makeCounterClockwise(centerLinePoints)
    const thicknesses = centerLinePoints.map(p =>
      Wall.thicknessForLayerKey(this.layer, true)
    )
    thicknesses.pop()

    const offsetData = Offset.offset(centerLinePoints, thicknesses)
    return offsetData.pureOutsetPoints
  }

  createWallObject(currentWallPoints = this.currentWallPoints) {
    // Get outset points and use as new centerline points.
    // This will mimic user drawing the inset points and preserve on-screen measurements
    const newCenterlinePoints = !this.connectedToExistingWall
      ? this.extrudePoints(currentWallPoints)
      : currentWallPoints

    const model = Wall.createModel(
      newCenterlinePoints,
      this.units,
      this.layer,
      {
        convertFromNativeUnits: true,
      }
    )

    this.mergeRedundantSegments(model.segments)

    const wall = new Wall(model, 'INCHES')

    wall.segments.forEach(segment => (segment.wall = wall))

    return wall
  }

  removeEdgeCircle() {
    const existingEdgeCircle = this.scene.getObjectByName('EdgeCircle')
    if (existingEdgeCircle) {
      this.scene.remove(existingEdgeCircle)
    }
  }

  finishWallDescription(multiSelect) {
    this.startedWall = false
    this.hideArrows = false
    this.lastWallPointAdded = undefined

    // Get object positions for testing if outside\inside facility
    let positions = []
    if (this.isWall() || this.isObstruction()) {
      positions = Util.toVec3Array(this.currentWallPoints)
    }

    if (this.currentWallPoints.length > 1) {
      const isExteriorWall = this.layer === LAYERS.EXTERIOR_WALLS
      if (this.isComfortZone()) {
        const bbox = new THREE.Box3().setFromObject(this.object.obj3d)
        const xOffset = Math.abs(bbox.max.x - bbox.min.x)
        const yOffset = Math.abs(bbox.max.y - bbox.min.y)

        // Prevent comfort zones being created below the mininum size
        if (xOffset < MINIMUM_ZONE_SIZE || yOffset < MINIMUM_ZONE_SIZE) {
          const error = 'Comfort zone too small to create!'
          store.dispatch(setStatus({ text: error, type: 'error' }))
        } else {
          store.dispatch(clearStatus())
          store.dispatch(
            addComfortZone({
              comfortZone: this.object.toModel(),
              multiSelect,
            })
          )
        }
      } else if (this.isObstruction()) {
        // Don't allow obstrucitons to be placed outside facility
        const isInsideFacility = Util.isPositionsOverFacility(positions)
        if (isInsideFacility) {
          store.dispatch(
            addObstruction({
              obstruction: this.object.toModel(),
              multiSelect,
            })
          )
        } else {
          const error = 'Obstructions must be placed inside the facility!'
          store.dispatch(setStatus({ text: error, type: 'error' }))
        }
      } else if (isExteriorWall) {
        // Don't allow exterior walls to be placed inside facility
        const isOutsideFacility = Util.isPositionsOutsideFacility(positions)
        const surroundsFacility = Util.isSurroundingFacility(positions)

        if ((isOutsideFacility && !surroundsFacility) || this.cachedWallId) {
          const wall = this.createWallObject()
          store.dispatch(
            addObject({
              object: wall.toModel(),
              multiSelect,
            })
          )
        } else if (surroundsFacility) {
          const error = `Exterior walls can't enclose other exterior walls!`
          store.dispatch(setStatus({ text: error, type: 'error' }))
        } else {
          const error = `Exterior walls can't be placed inside the facility!`
          store.dispatch(setStatus({ text: error, type: 'error' }))
        }
      } else {
        // Don't allow interior walls to be placed outside facility
        const isInsideFacility = Util.isPositionsOverFacility(positions)
        if (isInsideFacility) {
          const wall = this.createWallObject()
          store.dispatch(
            addObject({
              object: wall.toModel(),
              multiSelect,
            })
          )
        } else {
          const error = 'Interior walls must be placed inside the facility!'
          store.dispatch(setStatus({ text: error, type: 'error' }))
        }
      }
    }

    if (this.cachedWallId) store.dispatch(deleteObjects([this.cachedWallId]))

    this.pointStartedOnWallSegment = undefined
    this.obj3d.remove(this.wallMesh)
    this.removeEdgeCircle()

    this.currentWallPoints.length = 0
    this.obj3d.remove(this.newSegmentMesh)

    // We need to make sure update runs on end
    // as the tool isn't necessarily selected
    // after a wall description is done (it changes via redux)
    this.textInput.style.display = 'none'
    this.connectedToExistingWall = false
  }

  getSnapRegions(facility, draggedObject) {
    const wallPointSnapPoints = []
    let wallPointSnapLines = []

    // if we haven't started a wall, add snap points of all walls to connect to
    if (!this.startedWall && this.isWall()) {
      const snapRange = LineTool.CONNECT_TO_EXISTING_SEGMENT_SNAP_DISTANCE

      const segments = facility.getWallSegments()
      if (segments.length) {
        segments.forEach(s => {
          if (!s.nextSegment) {
            // Don't have a next segment, so connect to end point of this segment
            const point = Util.arrayPointToObjectPoint(
              s.getCenterLinePoints()[1]
            )
            wallPointSnapPoints.push(new SnapPoint(point, snapRange, true))
          } else if (!s.previousSegment) {
            // Don't have a previous segment, so connect to start point of this segment
            const point = Util.arrayPointToObjectPoint(
              s.getCenterLinePoints()[0]
            )
            wallPointSnapPoints.push(new SnapPoint(point, snapRange, true))
          }
        })
      }
    }

    this.currentWallPoints.forEach((wallPoint, i) => {
      const point = Util.arrayPointToObjectPoint(wallPoint)
      wallPointSnapLines = this.isWall()
        ? wallPointSnapLines.concat(
            Util.snapLinesForOrthogonalAxesAtPoint(
              point,
              LineTool.ORTHOGONAL_SNAP_DISTANCE
            )
          )
        : []

      // Add SnapPoint with animated target visual to first point added,
      // since it's where we need to click in order to finish the wall.
      if (i === 0 && this.currentWallPoints.length > 2) {
        const snapRange = this.isObstruction()
          ? LineTool.OBSTRUCTION_SNAP_DISTANCE
          : LineTool.WALL_SNAP_DISTANCE
        wallPointSnapPoints.push(new SnapPoint(point, snapRange, true))
      }
    })

    // Gather snap lines running through each existing wall segment
    const wallCenterSnapLines = this.isWall()
      ? SnapQueries.getAllWallCenterLines()
      : []

    if (isSnapEnabled()) {
      return wallPointSnapPoints
        .concat(wallPointSnapLines)
        .concat(wallCenterSnapLines)
    }

    // When using the Interior Wall Line Tool we still want to snap
    // to exterior wall center lines when snapping is disabled
    const currentLayer = store.getState().layers.currentLayer
    if (currentLayer === 'INTERIOR_WALLS') {
      const extWallCenterLines = SnapQueries.getAllWallCenterLines([], true)
      return extWallCenterLines
    }

    return []
  }

  createCursor(size) {
    const geometry = new THREE.BoxGeometry(size, size, 1)
    const material = new THREE.MeshBasicMaterial({
      color: getThreeHexFromTheme('three.activeToolCursor'),
      depthTest: false,
    })
    return new THREE.Mesh(geometry, material)
  }

  initTextInput() {
    this.textInput = document.createElement('input')
    this.textInput.type = 'text'
    this.textInput.className = 'floating-text-input'
    this.textInput.style.display = 'none'
    this.textInput.style.pointerEvents = 'none'
    document.getElementById('drawing-canvas').appendChild(this.textInput)

    this.textInput.addEventListener('keyup', e => {
      if (e.which === 13) {
        // If Enter was pressed
        this.addSegmentWithTextInputLength()
      }
      if (e.which === 27) {
        // If Esc was pressed
        if (this.isObstruction() && this.currentWallPoints.length) {
          this.object.positions.pop()
        }
        this.toolFinish()
      }
    })

    this.textInput.addEventListener('input', () =>
      Units.validateTextInput(this.textInput)
    )
  }

  /*
    Adds a new segment to the wall with length equal to the current
    value of the floating text input, starting from the last point added.
  */
  addSegmentWithTextInputLength() {
    const distance = Units.fromDistanceString(this.textInput.value)

    // Do nothing if we're given an invalid string
    if (distance === null) {
      return
    }

    const newSegmentDirection = this.newSegmentDirection()

    let lastWallPoint =
      this.currentWallPoints[this.currentWallPoints.length - 1]

    lastWallPoint = new THREE.Vector3(lastWallPoint[0], lastWallPoint[1], 0)

    const textInputSpecifiedEndPoint = lastWallPoint.addScaledVector(
      newSegmentDirection,
      distance.native()
    )

    this.addPointToWallDescription(textInputSpecifiedEndPoint)

    this.reCreateWallMesh(this.currentWallPoints)
  }

  getArrowDescriptions() {
    return super.getArrowDescriptions()
  }

  getOrthoReferencePoint() {
    if (this.startedWall) {
      return this.lastWallPointAdded
    } else {
      return null
    }
  }
}

export default LineTool
