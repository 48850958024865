import { SHOW_ALERT, HIDE_ALERT } from './actions'

const initialState = {
  text: /** @type {string|null} */(''),
  type: 'default',
  action: {},
  autoClose: true,
}

export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ALERT: {
      return {
        ...state,
        text: action.payload.text,
        type: action.payload.type || 'default',
        action: action.payload.action || {},
        autoClose: action.payload.autoClose,
      }
    }
    case HIDE_ALERT: {
      return {
        ...state,
        text: null,
      }
    }
    default: {
      return state
    }
  }
}
