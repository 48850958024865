import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import * as filestack from 'filestack-js'
import { graphql } from '@apollo/client/react/hoc'
import get from 'lodash-es/get'

import { GET_CLOUDINARY_SIGNATURE_QUERY } from 'client/queries'

import FILESTACK_API_KEY from 'config/filestack'
import routes from 'config/routes'
import { getTitle } from 'config/titles'
import cloudinary from 'config/cloudinary'
import { appendURL } from 'lib/utils'
import sendToSentry from 'lib/sendToSentry'
import LAYER_KEYS from 'config/layerKeys'

import { saveBackgroundImageFile } from 'store/backgroundImageForm'
import { mostRecentSelectedObject } from 'store/selectedObjects/selectors'
import { addBackgroundImage, addMetadataImage } from 'store/objects'

import Modal from 'components/UIKit/Modal'

class UploadImageModal extends Component {
  static propTypes = {
    cloudinaryAuth: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    onAddBackgroundImage: PropTypes.func,
    onSaveBackgroundImageFile: PropTypes.func,
    onUpdateObjectMetadata: PropTypes.func,
    parentRoute: PropTypes.string,
    props: PropTypes.object,
  }

  componentDidMount() {
    this.client = filestack.init(FILESTACK_API_KEY)
    this.createPicker()
    this.openPicker()
  }

  componentWillUnmount() {
    this.closePicker()
  }

  createPicker = () => {
    this.picker = this.client.picker({
      accept: ['image/*', '.pdf', '.dwg'],
      uploadInBackground: false,
      disableTransformer: this.props.match.params.type === 'background',
      maxFiles: this.props.match.params.type === 'metadata' ? 10 : 1,
      onUploadDone: this.handleFileUploadFinished,
      fromSources: [
        'local_file_system',
        'googledrive',
        'dropbox',
        'clouddrive',
        'box',
        'onedrive',
        'picasa',
      ],
      customText: {
        'or Drag and Drop, Copy and Paste Files':
          'You can upload .DWG, .PDF or image files.',
      },
      displayMode: 'inline',
      container: '#filestack-ref',
    })
  }

  openPicker = () => {
    this.picker.open()
  }

  closePicker = () => {
    this.picker.close()
  }

  handleFileUploadFinished = async files => {
    await Promise.all(
      files.filesUploaded.map(async file => {
        this.client
          .metadata(file.handle, { width: true, height: true })
          .then(res => {
            this.handleSave({
              file,
              res,
            })
          })
          .catch(err => {})
      })
    )

    this.handleNavigateOnComplete(files)
  }

  handleCloudinaryUpload = async (data, object) => {
    try {
      // Upload image to cloudinary and store the results.
      const { cloudinaryAuth = {} } = this.props
      const { CloudinarySignature = {} } = cloudinaryAuth
      const response = await cloudinary.upload(data, CloudinarySignature)
      const { public_id: cloudinaryId } = await response.json()
      return cloudinaryId
    } catch (error) {
      sendToSentry({
        prefix: 'Cloudinary upload failure',
        error,
        action: 'UploadImageModal.handleCloudinaryUpload',
      })
      throw error
    }
  }

  handleSave = async ({ file, res }) => {
    const { url, mimetype, filename } = file
    const { width, height } = res
    const {
      match,
      onSaveBackgroundImageFile,
      onAddBackgroundImage,
    } = this.props

    let convertedUrl = url

    if (mimetype.match('heic')) {
      const splitUrl = convertedUrl.split('/')
      splitUrl.splice(splitUrl.length - 1, 0, 'output=format:jpg')
      convertedUrl = splitUrl.join('/')
    }

    switch (match.params.type) {
      case 'background':
        if (
          filename.match('dwg') ||
          mimetype.match('pdf') ||
          mimetype.match('application')
        ) {
          // Temporarily save the returned data to be used in subsequent modals
          onSaveBackgroundImageFile({ src: convertedUrl, mimetype, filename })
        } else {
          onAddBackgroundImage({
            backgroundImage: {
              BACKGROUND_IMAGE: {
                src: convertedUrl,
                originalWidth: width,
                originalHeight: height,
                width,
                height,
                opacity: 1,
                position: {
                  x: 0,
                  y: 0,
                  z: 0,
                },
                rotation: {
                  x: 0,
                  y: 0,
                  z: 0,
                },
                layerKey: LAYER_KEYS.BACKGROUND_IMAGE,
                id: LAYER_KEYS.BACKGROUND_IMAGE,
              },
            },
          })
        }
        break
      case 'metadata':
        const selectedObject = mostRecentSelectedObject()
        if (selectedObject) {
          const cloudinaryId = await this.handleCloudinaryUpload(
            convertedUrl,
            selectedObject
          )
          this.props.onUpdateObjectMetadata({
            cloudinaryId,
            objectId: selectedObject.id,
            width,
            height,
          })
        }
        break
      default:
        break
    }
  }

  handleNavigateOnComplete = files => {
    const { match, history, parentRoute } = this.props
    switch (match.params.type) {
      case 'background':
        const mimetype = get(files, 'filesUploaded[0].mimetype', '')
        const filename = get(files, 'filesUploaded[0].filename', '')
        if (
          filename.match('dwg') ||
          mimetype.match('pdf') ||
          mimetype.match('application')
        ) {
          history.push(appendURL(parentRoute, routes.modals.convertImage))
        } else {
          history.push(appendURL(parentRoute, routes.modals.editImage))
        }
        break
      case 'metadata':
        this.handleClose()
        break
      default:
        this.handleClose()
        break
    }
  }

  handleClose = () => {
    const { history, match } = this.props
    const uploadImageUrlPart = routes.modals.uploadImage.replace(
      /:type/,
      match.params.type
    )
    const newUrl = match.url.replace(uploadImageUrlPart, '')
    history.push(newUrl)
  }

  render() {
    const { parentRoute, history, props } = this.props
    return (
      <div>
        <Helmet>
          <title>{getTitle('uploadImage')}</title>
        </Helmet>
        <Modal
          title="Upload Image"
          parentRoute={parentRoute}
          history={history}
          onClose={() => this.handleClose()}
          {...props}
        >
          <div id="filestack-ref" />
        </Modal>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  onSaveBackgroundImageFile(attrs) {
    dispatch(saveBackgroundImageFile(attrs))
  },
  onAddBackgroundImage(attrs) {
    dispatch(addBackgroundImage(attrs))
  },
  onUpdateObjectMetadata: attrs => {
    dispatch(addMetadataImage({ ...attrs, ignoreForCFD: true }))
  },
})

export default compose(
  appConnect(null, mapDispatchToProps),
  graphql(GET_CLOUDINARY_SIGNATURE_QUERY, {
    options: {
      fetchPolicy: 'network-only',
    },
    name: 'cloudinaryAuth',
  }),
  withRouter
)(UploadImageModal)
