import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Action from 'components/UIKit/Action'
import Icon from 'components/UIKit/Icon'

const PRESS_DOWN_DELAY = 300

class ZoomButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onZoom: PropTypes.func,
    type: PropTypes.string,
  }
  constructor(props) {
    super(props)

    this.pressDownDelay = PRESS_DOWN_DELAY
    this.pressDownTimeout = 0
  }

  handleZoom = () => {
    this.props.onZoom()
    this.pressDownTimeout = setTimeout(this.handleZoom, this.pressDownDelay)
    this.pressDownDelay = this.pressDownDelay / 2
  }

  handleMouseDown = () => {
    this.handleZoom()
  }

  handleMouseUp = () => {
    clearTimeout(this.pressDownTimeout)
    this.pressDownDelay = PRESS_DOWN_DELAY
  }

  render() {
    const { type, disabled } = this.props

    return (
      <Action
        text={type}
        variant="dark"
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        disabled={disabled}
      >
        <Icon size="14" name={`zoom${type}`} color="#fff" />
      </Action>
    )
  }
}

export default ZoomButton
