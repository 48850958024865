import get from 'lodash-es/get'

import LAYER_KEYS from './layerKeys'

interface LayerData {
  name: string
  label: string
  icon?: string
}

const ProductsLayer: LayerData = {
  name: 'Products',
  label: 'Products',
  icon: 'fan',
}

const layers: Partial<Record<LAYER_KEYS, LayerData>> = {
  [LAYER_KEYS.ROOFS]: {
    name: 'Roofs',
    label: 'Roofs',
    icon: 'home',
  },
  [LAYER_KEYS.ELEVATION_LINE]: {
    name: 'Elevation Line',
    label: 'Line',
  },
  [LAYER_KEYS.ELEVATION_POINT]: {
    name: 'Elevation Point',
    label: 'Point',
  },
  [LAYER_KEYS.ROOF_SECTIONS]: {
    name: 'Mounting Structures',
    label: 'Structures',
    icon: 'mountingStructures',
  },
  [LAYER_KEYS.CEILINGS]: {
    name: 'Ceilings',
    label: 'Ceilings',
    icon: 'ceilings',
  },
  [LAYER_KEYS.PRODUCTS]: ProductsLayer,
  [LAYER_KEYS.PRODUCTS_OVERHEAD]: ProductsLayer,
  [LAYER_KEYS.PRODUCTS_EVAP]: ProductsLayer,
  [LAYER_KEYS.PRODUCTS_DIRECTIONAL]: ProductsLayer,
  [LAYER_KEYS.PRODUCTS_HEATERS]: ProductsLayer,
  [LAYER_KEYS.OBSTRUCTIONS]: {
    name: 'Obstructions',
    label: 'Obstructions',
    icon: 'obstruction',
  },
  [LAYER_KEYS.COMFORT_POINTS]: {
    name: 'Comfort Points',
    label: 'Comfort Points',
    icon: 'location',
  },
  [LAYER_KEYS.COMFORT_ZONES]: {
    name: 'Comfort Zones',
    label: 'Comfort Zones',
    icon: 'comfortZone',
  },
  [LAYER_KEYS.INTERIOR_WALLS]: {
    name: 'Interior Walls',
    label: 'Int. Walls',
    icon: 'interiorWall',
  },
  [LAYER_KEYS.EXTERIOR_WALLS]: {
    name: 'Exterior Walls',
    label: 'Ext. Walls',
    icon: 'square',
  },
  [LAYER_KEYS.DIMENSIONS]: {
    name: 'Annotations',
    label: 'Annotations',
    icon: 'ruler',
  },
  [LAYER_KEYS.BACKGROUND_IMAGE]: {
    name: 'Background Image',
    label: 'BG Image',
    icon: 'image',
  },
  [LAYER_KEYS.PRIMARY_MOUNTING_STRUCTURE]: {
    name: 'Primary Structure',
    label: 'Primary',
  },
  [LAYER_KEYS.SECONDARY_MOUNTING_STRUCTURE]: {
    name: 'Secondary Structure',
    label: 'Secondary',
  },
  [LAYER_KEYS.COLUMNS]: {
    name: 'Columns',
    label: 'Columns',
  },
  [LAYER_KEYS.DIMENSION_LINES]: {
    name: 'Dimension Lines',
    label: 'Dimension Lines',
  },
  [LAYER_KEYS.NOTES]: {
    name: 'Notes',
    label: 'Notes',
  },
  [LAYER_KEYS.PRODUCT_HEIGHTS]: {
    name: 'Product Heights',
    label: 'Product Heights',
  },
  [LAYER_KEYS.GRID_BOX]: {
    name: 'GridBox',
    label: 'GridBox',
    icon: 'interiorWall',
  },
}

export const DEFAULT_LAYER: LayerData = {
  name: 'Layers',
  label: 'Layers',
  icon: 'layers',
}

export const OVERRIDE_LAYERS: Partial<Record<LAYER_KEYS, LayerData>> = {
  [LAYER_KEYS.DOORS]: {
    name: 'Doors',
    label: 'Doors',
    icon: 'doorMan',
  },
  [LAYER_KEYS.UTILITY_BOXES]: {
    name: 'Utility Boxes',
    label: 'Utility',
    icon: 'panelControl',
  },
}

const LAYERS_WITH_DIFFERENT_KEYS = [
  { from: 'Mounting Structures', to: 'Roof Sections' },
  { from: 'Annotations', to: 'Dimensions' },
]

const LAYERS_WITHOUT_TOOLS: LAYER_KEYS[] = [LAYER_KEYS.CEILINGS]

export const shouldShowLayerInTools = (name: LAYER_KEYS) =>
  !LAYERS_WITHOUT_TOOLS.includes(name)

export const getKey = (key = '') => key.toUpperCase().replace(' ', '_')

export const getLayer = (name: string, { list = layers }: { list?: Partial<Record<string, LayerData>> } = {}) => {
  const key = getKey(name)
  let layer = list[key]

  LAYERS_WITH_DIFFERENT_KEYS.forEach(l => {
    const newKey = getKey(l.to)
    const isRemappedLayerName = getKey(l.from) === key

    if (isRemappedLayerName) {
      layer = get(list, [newKey])
    }
  })

  return layer
}

const getLayerValue = ({ key, value, includeOverrides }: { key: 'name'|'label'|'icon'; value: string; includeOverrides: boolean }) => {
  if (getLayer(value)) {
    return getLayer(value)?.[key]
  }

  if (includeOverrides && getLayer(value, { list: OVERRIDE_LAYERS })) {
    return getLayer(value, { list: OVERRIDE_LAYERS })?.[key]
  }

  return DEFAULT_LAYER[key]
}

export const getLayerName = (name: string, { includeOverrides = false } = {}) =>
  getLayerValue({ key: 'name', value: name, includeOverrides })

export const getLayerLabel = (name: string, { includeOverrides = false } = {}) =>
  getLayerValue({ key: 'label', value: name, includeOverrides })

export const getLayerIcon = (name: string, { includeOverrides = false } = {}) =>
  getLayerValue({ key: 'icon', value: name, includeOverrides })

export default layers
