import styled from 'styled-components'

import Button from 'components/UIKit/Button'

const StyledButton = styled(Button)`
  background-color: transparent;

  &:active,
  &:focus,
  &:hover {
    border-color: transparent;
  }
`

export default StyledButton
