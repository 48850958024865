import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Step = styled.li`
  cursor: pointer;
  font-size: ${theme(`fonts.sizes.s`)};
  list-style: none;
  position: relative;
  text-align: center;
  width: 20px;

  &::before {
    background: ${theme('colors.light.light')};
    content: '';
    height: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -25px;
    width: 1px;
  }
`

export default Step
