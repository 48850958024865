import React, { Component } from 'react'
import { appConnect } from "~/store/hooks";
import { graphql } from '@apollo/client/react/hoc'
import { Helmet } from 'react-helmet'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import ResizableRect from 'react-resizable-rotatable-draggable-touch'
import qs from 'qs'
import fileSaver from 'file-saver'

import { GET_CLOUDINARY_SIGNATURE_QUERY } from 'client/queries'

import get from 'lodash-es/get'

import { showAlert } from 'store/alert'
import { addMetadataImage, updateObject } from 'store/objects'

import cloudinary from 'config/cloudinary'
import routes from 'config/routes'
import { getTitle } from 'config/titles'

import { parseCloudinaryUrlTransforms } from 'lib/utils'
import sendToSentry from 'lib/sendToSentry'

import { getBase64FromImage } from 'components/Modals/ExportPDFLayoutModal/Wizard/utils'
import Flex from 'components/UIKit/Flex'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'

import BackgroundImage from './styled/BackgroundImage'
import CanvasContainer from './styled/CanvasContainer'
import CloseIcon from './styled/CloseIcon'
import Container from './styled/Container'
import MenuColumn from './styled/MenuColumn'
import MenuContainer from './styled/MenuContainer'
import Product from './styled/Product'

import CanvasControls from './CanvasControls'
import CatalogMenu from './CatalogMenu'
import ImageGrid from './ImageGrid'
import ModelMenu from './ModelMenu'
import SizeMenu from './SizeMenu'

class AddProductOverlayImageModal extends Component {
  state = {
    top: 0,
    left: 0,
    height: 95,
    width: 256,
    rotateAngle: 0,
    historyIndex: 0,
    history: [],
    product: {
      catalog: null,
      model: null,
      size: null,
      image: null,
    },
  }

  getCloudinaryImage = ({ cloudinaryId }) => {
    return cloudinary.image(cloudinaryId, {
      format: 'png',
      secure: true,
      transformation: [{ crop: 'fill' }],
    })
  }

  handleCloudinaryUpload = async data => {
    try {
      // Upload image to cloudinary and store the results.
      const { cloudinaryAuth = {} } = this.props
      const { CloudinarySignature = {} } = cloudinaryAuth
      const response = await cloudinary.upload(data, CloudinarySignature)
      const { public_id: cloudinaryId } = await response.json()
      return cloudinaryId
    } catch (error) {
      sendToSentry({
        prefix: 'Cloudinary upload failure',
        error,
        action: 'AddProductOverlayImageModal.handleCloudinaryUpload',
      })
      throw error
    }
  }

  saveMetaData = async () => {
    const { object, image, onUpdateObject, onShowAlert } = this.props

    const images = object.metadata.images.map(img => {
      const imageModel = { ...img }
      return imageModel
    })

    const model = {
      ...object,
      metadata: {
        ...object.metadata,
        images,
      },
    }

    onUpdateObject(model)

    const bgImage = this.getCloudinaryImage({
      cloudinaryId: image.cloudinaryId,
    })
    /**
     * Create annotated PNG by combining background image
     * and drawings into a new canvas and using toDataURL
     */
    const { height, width, top, left, product } = this.state
    const canvas = document.createElement('canvas')
    canvas.width = image.width || bgImage.width
    canvas.height = image.height || bgImage.height
    const ctx = canvas.getContext('2d')
    const bgImg = new Image() // Background image
    const parsedUrl = parseCloudinaryUrlTransforms(bgImage.src)
    const bgBase64 = await getBase64FromImage(parsedUrl)

    bgImg.src = bgBase64
    bgImg.onload = async () => {
      // Scale background image and add it to canvas
      ctx.drawImage(bgImg, 0, 0, bgImg.width, bgImg.height)

      const productImg = product.image
      const angleInRadians = this.state.rotateAngle * (Math.PI / 180)

      // Save canvas state
      ctx.save()
      // Translate to product image position first (top left of image)
      ctx.translate(left, top)
      // Then translate to product image center to rotate around
      ctx.translate(width / 2, height / 2)
      // Apply image roation to canvas
      ctx.rotate(angleInRadians)
      // Draw the image onto the canvas
      ctx.drawImage(productImg, -(width / 2), -(height / 2), width, height)
      // Restore canvas rotation and translation to original state
      ctx.restore()

      // Generate combined image
      const annotatedImage = canvas.toDataURL('image/png')

      // Save the image to the user's machine
      fileSaver.saveAs(
        annotatedImage,
        `metadata-${get(image, 'cloudinaryId')}.jpg`
      )

      // Upload result to Cloudinary and update object metadata
      const cloudinaryId = await this.handleCloudinaryUpload(
        annotatedImage,
        this.props.object
      )

      this.props.onUpdateObjectMetadata({
        cloudinaryId,
        objectId: this.props.object.id,
        width,
        height,
      })

      this.handleClose()
      onShowAlert({
        text: 'Annotation saved successfully',
        type: 'success',
      })
    }
  }

  handleBack = () => {
    this.setState({
      product: {
        catalog: null,
        model: null,
        size: null,
        image: null,
      },
    })
  }

  handleClose = () => {
    const { history, match } = this.props
    history.push(match.url.replace(routes.modals.addProductOverlayImage, ''))
  }

  handleUndoImageChange = () => {
    if (this.state.historyIndex <= 1) {
      this.resetProductImage()
      this.setState({ historyIndex: 0 })
      return
    }

    const index = this.state.historyIndex
    const historyState = this.state.history[index - 2]
    const newState = {
      top: historyState.top,
      left: historyState.left,
      height: historyState.height,
      width: historyState.width,
      rotateAngle: historyState.rotateAngle,
      historyIndex: index - 1,
    }

    this.setState({ ...newState })
  }

  handleRedoImageChange = () => {
    if (this.state.history.length === this.state.historyIndex) return

    const index = this.state.historyIndex
    const historyState = this.state.history[index]
    const newState = {
      top: historyState.top,
      left: historyState.left,
      height: historyState.height,
      width: historyState.width,
      rotateAngle: historyState.rotateAngle,
      historyIndex: index + 1,
    }

    this.setState({ ...newState })
  }

  resetProductImage = () => {
    this.setState({
      top: 0,
      left: 0,
      height: 95,
      width: 256,
      rotateAngle: 0,
    })
  }

  resetProductImageHistory = () => {
    this.setState({
      historyIndex: 0,
      history: [],
    })
  }

  handleHistorySave = () => {
    const { history, historyIndex } = this.state
    const newHistory = history.slice(0, historyIndex)

    newHistory.push({
      top: this.state.top,
      left: this.state.left,
      height: this.state.height,
      width: this.state.width,
      rotateAngle: this.state.rotateAngle,
    })

    this.setState({
      history: newHistory,
      historyIndex: historyIndex + 1,
    })
  }

  handleResize = (style, isShiftKey, type) => {
    // type is a string and it shows which resize-handler you clicked
    // e.g. if you clicked top-right handler, then type is 'tr'
    let { top, left, width, height } = style
    top = Math.round(top)
    left = Math.round(left)
    width = Math.round(width)
    height = Math.round(height)

    this.setState({
      top,
      height,
      left,
      width,
    })
  }

  handleRotate = rotateAngle => {
    this.setState({ rotateAngle })
  }

  handleDrag = (deltaX, deltaY) => {
    this.setState({
      top: this.state.top + deltaY,
      left: this.state.left + deltaX,
    })
  }

  handleCatalogSelect = catalog =>
    this.setState({
      product: { catalog },
    })

  handleImageSelect = async thumbnail => {
    // Set the larger image version
    const imageUrl = thumbnail.split('/Thumbnails').join('')
    const image = new Image()
    image.src = await getBase64FromImage(imageUrl)

    image.onload = async () => {
      this.setState({
        height: image.height / 8,
        width: image.width / 8,
        product: {
          ...this.state.product,
          image,
        },
      })
    }
  }

  handleModelSelect = model =>
    this.setState({
      product: {
        ...this.state.product,
        model,
        size: null,
        image: null,
      },
    })

  handleSizeSelect = size =>
    this.setState({
      product: {
        ...this.state.product,
        size,
        image: null,
      },
    })

  render() {
    const { parentRoute, history, props, image } = this.props
    const { top, left, height, width, rotateAngle, product } = this.state

    const showSizeInput =
      product.catalog === 'Residential' &&
      ['Haiku', 'i6'].includes(product.model)
    const showImageGrid =
      (showSizeInput && product.size) || (!showSizeInput && product.model)
    const cloudinaryImage =
      image &&
      this.getCloudinaryImage({
        cloudinaryId: image.cloudinaryId,
      })
    const aspectRatio = this.state.width / this.state.height
    const imgWidth = image.width || cloudinaryImage.width
    const imgHeight = image.height || cloudinaryImage.height
    return (
      <>
        <Helmet>
          <title>{getTitle('addProductOverlayImage')}</title>
        </Helmet>

        <Modal
          size="100%"
          parentRoute={parentRoute}
          history={history}
          flushContent
          noMargin
          maxHeight="100vh"
          {...props}
        >
          <Container>
            {product.image ? (
              <>
                {cloudinaryImage && (
                  <CanvasContainer width={imgWidth} height={imgHeight}>
                    <ResizableRect
                      aspectRatio={aspectRatio}
                      left={left}
                      top={top}
                      width={width}
                      height={height}
                      rotateAngle={rotateAngle}
                      zoomable="n, w, s, e, nw, ne, se, sw"
                      onRotate={this.handleRotate}
                      onRotateEnd={this.handleHistorySave}
                      onResize={this.handleResize}
                      onResizeEnd={this.handleHistorySave}
                      onDrag={this.handleDrag}
                      onDragEnd={this.handleHistorySave}
                    />
                    <BackgroundImage
                      src={cloudinaryImage.src}
                      alt="Background Image"
                    />
                    <Product
                      left={left}
                      top={top}
                      width={width}
                      height={height}
                      rotateAngle={rotateAngle}
                      src={product.image.src}
                    />
                  </CanvasContainer>
                )}
                <CanvasControls
                  history={this.state.history}
                  historyIndex={this.state.historyIndex}
                  handleBack={this.handleBack}
                  handleRedoImageChange={this.handleRedoImageChange}
                  handleUndoImageChange={this.handleUndoImageChange}
                  resetProductImage={this.resetProductImage}
                  resetProductImageHistory={this.resetProductImageHistory}
                  saveMetaData={this.saveMetaData}
                />
              </>
            ) : (
              <MenuContainer>
                <MenuColumn>
                  <Space bottom="s">
                    <VariantText size="xl">Catalog</VariantText>
                  </Space>
                  <CatalogMenu
                    product={product}
                    handleSelect={this.handleCatalogSelect}
                  />

                  {product.catalog && (
                    <>
                      <Space bottom="s" top="base">
                        <VariantText size="xl">Model</VariantText>
                      </Space>
                      <ModelMenu
                        product={product}
                        handleSelect={this.handleModelSelect}
                      />
                    </>
                  )}

                  {showSizeInput && (
                    <>
                      <Space bottom="s" top="base">
                        <VariantText size="xl">Size</VariantText>
                      </Space>
                      <SizeMenu
                        product={product}
                        handleSelect={this.handleSizeSelect}
                      />
                    </>
                  )}
                </MenuColumn>

                {showImageGrid ? (
                  <ImageGrid
                    product={product}
                    handleSelect={this.handleImageSelect}
                  />
                ) : (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    style={{ flexGrow: 1 }}
                  >
                    <VariantText color="light">
                      Please fill out all fields to load product images
                    </VariantText>
                  </Flex>
                )}
              </MenuContainer>
            )}
            <CloseIcon
              name="cross"
              color="light"
              size="28"
              onClick={this.handleClose}
            />
          </Container>
        </Modal>
      </>
    )
  }
}

AddProductOverlayImageModal.propTypes = {
  cloudinaryAuth: PropTypes.object,
  history: PropTypes.object,
  image: PropTypes.object,
  match: PropTypes.object,
  object: PropTypes.object,
  onShowAlert: PropTypes.func,
  onUpdateObject: PropTypes.func,
  onUpdateObjectMetadata: PropTypes.func,
  parentRoute: PropTypes.string,
  props: PropTypes.object,
}

const mapStateToProps = ({ objects }, ownProps) => {
  const queryParams = qs.parse(get(ownProps, 'location.search'), {
    ignoreQueryPrefix: true,
  })
  const objectId = get(queryParams, 'objectId')
  const objectType = get(queryParams, 'objectType')
  const imageIndex = get(queryParams, 'imageIndex')
  const object = get(objects.present, `[${objectType}][${objectId}]`)
  const metadata = get(object, 'metadata')
  const image = get(metadata, `images[${imageIndex}]`)

  return {
    image,
    object,
    objects,
  }
}

const mapDispatchToProps = dispatch => ({
  onShowAlert({ text, type }) {
    dispatch(showAlert({ text, type }))
  },
  onUpdateObject(model) {
    dispatch(updateObject({ object: model }))
  },
  onUpdateObjectMetadata: attrs => {
    dispatch(addMetadataImage({ ...attrs, ignoreForCFD: true }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  graphql(GET_CLOUDINARY_SIGNATURE_QUERY, {
    options: {
      fetchPolicy: 'network-only',
    },
    name: 'cloudinaryAuth',
  }),
  withRouter
)(AddProductOverlayImageModal)
