import React, { Component } from 'react'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { withApollo } from '@apollo/client/react/hoc'
import get from 'lodash-es/get'
import isEqual from 'lodash-es/isEqual'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { object, func, arrayOf, string, bool } from 'prop-types'

import { facilityData } from 'config/comfortPointFacilityData'
import { updateAirflow } from 'lib/airflow/airflow'
import * as Util from 'lib/thermalComfortTool/util'

import { primaryUses } from 'config/facility'

import { enableCanvas, disableCanvas } from 'store/status'
import { updateComfortPoint, setAirflowLayer } from 'store/objects'
import { setCFDLayer } from 'store/cfd'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { isTouchUI } from 'store/userInterface/selectors'
import { SELECTED_COMFORT_POINT_PANEL } from 'store/panel/types'
import { SYSTEMS } from 'store/units/constants'
import { withUnits } from 'store/units/decorators'
import { Temperature } from 'store/units/types'
import CLASS_NAMES from 'config/objectClassNames'
import {
  getCFDDataAverageAtPosition,
  getFPM,
  CFDUrl,
  getBerkeleyData,
} from 'lib/cfd'

import Icon from 'components/UIKit/Icon'
import InfoTooltip from 'components/UIKit/InfoTooltip'
import Label from 'components/UIKit/Label'
import Loader from 'components/UIKit/Loader'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'
import Switch from 'components/UIKit/Switch'
import Select from 'components/UIKit/Select'
import TextField from 'components/UIKit/TextField'
import TemperatureInput from 'components/UIKit/TemperatureInput'
import Grid, { GridItem } from '../../UIKit/Grid'

import MetadataSection from 'components/MetadataSection'

import PMVScale from 'components/PMVScale'

import Facility from 'components/DrawingCanvas/lib/facility'
import Units from 'components/DrawingCanvas/lib/units'

import store from 'store'
import LAYER_KEYS from 'config/layerKeys'

import Cooling from './styled/Cooling'
import CoolingValue from './styled/CoolingValue'
import Item from './styled/Item'
import StyledLabel from './styled/Label'
import List from './styled/List'
import { HEIGHT_VALUES } from 'config/cfd'

import * as THREE from 'three'

class SelectedComfortPointPanel extends Component {
  state = {
    loading: false,
    useCfdData: true,
    indoorSummerTemp: 0,
    indoorWinterTemp: 0,
    indoorHumidity: 0,
    indoorWinterHumidity: 0,
    isStanding: false,
    primaryUse: '',
    selectedLevel: 0,
  }

  seatedHeights = HEIGHT_VALUES.seated
  standingHeights = HEIGHT_VALUES.standing

  async componentDidMount() {
    if (!this.props.airflow.isValid || !this.props.airflow.data) {
      this.setState({ loading: true })
      // TODO: @refactor Remove
      // this.props.onDisableCanvas()
    }

    if (!this.props.airflow.data) {
      await this.updateAirFlowData()
    }

    this.handleSavedProperties()
  }

  componentDidUpdate(prevProps, prevState) {
    // Use CFD data when turning on the CFD layer
    const cfdVisibility = this.props.layers.layers.CFD.visible
    if (cfdVisibility !== prevProps.layers.layers.CFD.visible) {
      if (cfdVisibility && !this.state.useCfdData) {
        this.setState({ useCfdData: true })
      }
    }

    // if CFD is valid but no CFD models have been created, create them
    // by setting the default level
    if (this.props.cfd.isValid && isEmpty(this.props.cfd.models)) {
      this.handleLevelChange('67', true)
    }

    if (this.props.airflow.data && !this.props.airflow.isValid) {
      setTimeout(() => {
        this.updateAirFlowData()
      }, 1000)
    } else {
      if (!this.props.airflow.isFetching) {
        if (this.props.airflow.isValid && this.props.airflow.data) {
          if (this.state.loading) {
            this.setState({ loading: false })
            this.props.onEnableCanvas()
          }
        }
      }
    }

    this.handleSavedProperties()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.objects, nextProps.objects) ||
      !isEqual(this.props.layers, nextProps.layers) ||
      !isEqual(this.props.airflow, nextProps.airflow) ||
      !isEqual(this.props.cfd, nextProps.cfd) ||
      !isEqual(this.state, nextState)
    )
  }

  updateAirFlowData() {
    const {
      products,
      objects,
      obstructions,
      segments,
    } = this.props.objects.present
    this.props.onUpdateAirflow({
      products,
      objects,
      obstructions,
      segments,
    })
  }

  handleSavedProperties() {
    // Make sure we are using saved properties if available
    const selectedObject = this.props.selectedObject
    if (selectedObject) {
      const indoorSummerTemp = selectedObject.savedIndoorSummerTemp
      const indoorWinterTemp = selectedObject.savedIndoorWinterTemp
      const indoorHumidity = selectedObject.savedSummerHumidity
      const indoorWinterHumidity = selectedObject.savedWinterHumidity
      const primaryUse = selectedObject.savedPrimaryUse
      const selectedLevel = selectedObject.savedLevel
      this.setState({
        indoorSummerTemp,
        indoorWinterTemp,
        indoorHumidity,
        indoorWinterHumidity,
        primaryUse,
        selectedLevel,
      })
    }
  }

  getVtkUrls(levels) {
    return []
    // TODO: @refactor replace with hooks
    // if (!levels) return []
    // const urls = get(this.props, `cooling.cfdUrls`).filter(
    //   url =>
    //     get(url, 'fileProps.type') === 'overhead' &&
    //     url.fileExtension === 'vtk' &&
    //     levels.includes(get(url, 'fileProps.level'))
    // )

    // return urls
  }

  handleLevelChange(value, cfdIsValid) {
    if (cfdIsValid) {
      const type = 'overhead'
      const level = value.toString()
      // TODO: @refactor Use hooks
      const urls = get(this.props, `cooling.cfdUrls`)
      const url = urls?.find(
        u =>
          get(u, 'fileProps.type') === type &&
          u.fileExtension === 'vtk' &&
          get(u, 'fileProps.level') === level
      )
      if (url) {
        this.props.onUpdateCFDLayer({
          type,
          level,
          goal: 'cooling',
          url: get(url, 'url'),
        })
      }
    } else {
      this.props.onUpdateAirflowLayer(value)
    }
    this.setState({ selectedLevel: value })
  }

  handleInputChange = (key, value) => {
    this.setState({ [key]: value })
  }

  handleComfortPointUpdate = (key, value) => {
    const comfortPoint = {
      ...this.props.selectedObject,
      [key]: value,
    }

    this.props.onUpdateComfortPoint(comfortPoint)
  }

  handlePrimaryUseChange = primaryUse => {
    const comfortData = facilityData.find(facility => {
      if (!this.props.facility) return false
      return facility.type === primaryUse
    })

    if (!comfortData) {
      this.setState({
        indoorHumidity: null,
        indoorWinterHumidity: null,
        indoorSummerTemp: null,
        indoorWinterTemp: null,
        primaryUse,
      })
    }

    const airTemp = new Temperature({
      value: comfortData.airTemp,
      system: SYSTEMS.METRIC,
    })

    const winterAirTemp = new Temperature({
      value: comfortData.winterAirTemp,
      system: SYSTEMS.METRIC,
    })

    this.setState({
      indoorHumidity: comfortData.indoorHumidity,
      indoorWinterHumidity: comfortData.indoorWinterHumidity,
      indoorSummerTemp: airTemp.imperial({ round: 2 }),
      indoorWinterTemp: winterAirTemp.imperial({ round: 2 }),
      primaryUse,
    })
  }

  getElevationOptions() {
    return [
      {
        label: 'Seated',
        value: 'seated',
      },
      {
        label: 'Standing',
        value: 'standing',
      },
    ]
  }

  getCFDData = (selectedComfortPoint, cfd) => {
    const position = new THREE.Vector3().set(
      Units.inchesToNative(selectedComfortPoint.position.x),
      Units.inchesToNative(selectedComfortPoint.position.y),
      Units.inchesToNative(selectedComfortPoint.position.z)
    )

    const levels = this.state.isStanding
      ? this.standingHeights
      : this.seatedHeights
    const urls = this.getVtkUrls(levels)
    const models = get(this.props.cfd, 'models')

    return getCFDDataAverageAtPosition({
      models,
      position,
      urls,
      levels,
    })
  }

  getAirflowVelocity(selectedComfortPoint) {
    const comfortPoints = Facility.current.getComfortPoints()
    const comfortPoint = comfortPoints.find(
      cp => cp.id === selectedComfortPoint.id
    )

    if (!comfortPoint) return

    const velocity = this.state.isStanding
      ? comfortPoint.getSeatedVelocity()
      : comfortPoint.getStandingVelocity()

    return velocity
  }

  getComfortPointData(selectedComfortPoint, cfdIsValid) {
    const cfd = Facility.current.getCFD()
    let cfdVelocity

    // If CFD data is available use it for FPM
    if (cfd.length && cfdIsValid) {
      const cfdData = this.getCFDData(selectedComfortPoint, cfd)
      if (cfdData) {
        cfdVelocity = getFPM(cfdData)
      }
    }
    // Convert air velocity in FPM to m/s
    const velocity =
      this.state.useCfdData && cfdVelocity !== undefined
        ? cfdVelocity
        : this.getAirflowVelocity(selectedComfortPoint)

    // There is no air velocity data at the current position
    if (!velocity) {
      return (
        <div key={selectedComfortPoint.id}>
          <PanelSection>
            <Cooling>
              <Label>No Air Velocity Data</Label>
            </Cooling>
          </PanelSection>
          <MetadataSection
            object={selectedComfortPoint}
            onBlur={this.props.onUpdateComfortPoint}
          />
        </div>
      )
    }

    const primaryUse = this.state.primaryUse || this.props.facility.primaryUse

    let airTemp = new Temperature({
      value:
        this.state.indoorSummerTemp || this.props.facility.indoorSummerTemp,
      system: SYSTEMS.IMPERIAL,
    })

    let winterAirTemp = new Temperature({
      value:
        this.state.indoorWinterTemp || this.props.facility.indoorWinterTemp,
      system: SYSTEMS.IMPERIAL,
    })

    let indoorHumidity = isNil(this.state.indoorHumidity)
      ? this.props.facility.indoorHumidity
      : this.state.indoorHumidity

    let indoorWinterHumidity = isNil(this.state.indoorWinterHumidity)
      ? this.props.facility.indoorWinterHumidity
      : this.state.indoorWinterHumidity

    const { temperatureUnits, velocityUnits } = this.props
    const {
      set,
      pmv: exactPMV,
      ppd: exactPPD,
      coolingEffect,
      indoorHumidity: comfortDataHumidity,
      airTemp: comfortDataAirTemp,
    } = getBerkeleyData({
      velocity,
      primaryUse,
      airTemp: airTemp,
      humidity: indoorHumidity,
    })

    if (isNil(airTemp.value)) {
      airTemp = comfortDataAirTemp
    }

    if (isNil(indoorHumidity)) {
      indoorHumidity = comfortDataHumidity
    }

    if (isNil(indoorWinterHumidity)) {
      indoorWinterHumidity = comfortDataHumidity
    }

    const pmv = Util.roundTwoDecimals(exactPMV)
    const ppd = Util.roundTwoDecimals(exactPPD)

    coolingEffect.convertTo(temperatureUnits, { round: 2 })

    if (temperatureUnits === SYSTEMS.IMPERIAL) {
      coolingEffect.value = coolingEffect.value - 32
    }

    const selectedPosture = this.state.isStanding ? 'standing' : 'seated'
    const elevationOptions = this.getElevationOptions()
    const isLocked = store.getState().layers.layers[LAYER_KEYS.COMFORT_POINTS]
      .locked

    return (
      <div key={selectedComfortPoint.id}>
        <PanelSection>
          <Cooling>
            <Space bottom="base">
              <h3>Perceived Cooling Effect</h3>
            </Space>
            <CoolingValue>
              <Icon name="snowflake" color="fg" />
              <Label type="success">{coolingEffect.format({ round: 2 })}</Label>
            </CoolingValue>
          </Cooling>
        </PanelSection>
        <PanelSection title="Advanced Information">
          <Space bottom="base">
            <Switch
              name="useCfdData"
              label="CFD Data"
              onClick={event =>
                this.handleInputChange('useCfdData', !this.state.useCfdData)
              }
              isChecked={this.state.useCfdData && cfdVelocity !== undefined}
              disabled={!this.props.cfd.isValid || isLocked ? true : false}
              responsive
            />
          </Space>
          <Space bottom="base">
            <Select
              name="primaryUse"
              label="Primary Use"
              disabled={isLocked ? true : false}
              onChange={event => {
                const primaryUse = event.target.value
                this.handlePrimaryUseChange(primaryUse)
                this.handleComfortPointUpdate('savedPrimaryUse', primaryUse)
              }}
              value={primaryUse}
              options={primaryUses}
            />
          </Space>
          <Grid>
            <GridItem size={'1of2'}>
              <Space bottom="base">
                <TemperatureInput
                  label="Indoor Summer Temp"
                  name="indoorSummerTemp"
                  degrees={airTemp}
                  disabled={isLocked ? true : false}
                  units={temperatureUnits}
                  showFormattedUnits={true}
                  onChange={({ degrees }) => {
                    this.handleInputChange('indoorSummerTemp', degrees)
                    this.handleComfortPointUpdate(
                      'savedIndoorSummerTemp',
                      degrees.imperial()
                    )
                  }}
                />
              </Space>
            </GridItem>
            <GridItem size={'1of2'}>
              <Space bottom="base">
                <TemperatureInput
                  label="Indoor Winter Temp"
                  name="indoorWinterTemp"
                  degrees={winterAirTemp}
                  disabled={isLocked ? true : false}
                  units={temperatureUnits}
                  showFormattedUnits={true}
                  onChange={({ degrees }) => {
                    this.handleInputChange('indoorWinterTemp', degrees)
                    this.handleComfortPointUpdate(
                      'savedIndoorWinterTemp',
                      degrees.imperial()
                    )
                  }}
                />
              </Space>
            </GridItem>
            <GridItem size={'1of2'}>
              <Space bottom="base">
                <TextField
                  label="Summer Humidity (%)"
                  disabled={isLocked ? true : false}
                  value={indoorHumidity}
                  overrideValue={indoorHumidity}
                  type="number"
                  onChange={event => {
                    const indoorHumidity = parseFloat(event.target.value)
                    this.handleInputChange('indoorHumidity', indoorHumidity)
                    this.handleComfortPointUpdate(
                      'savedSummerHumidity',
                      indoorHumidity
                    )
                  }}
                />
              </Space>
            </GridItem>
            <GridItem size={'1of2'}>
              <Space bottom="base">
                <TextField
                  label="Winter Humidity (%)"
                  disabled={isLocked ? true : false}
                  value={indoorWinterHumidity}
                  overrideValue={indoorWinterHumidity}
                  type="number"
                  onChange={event => {
                    const indoorWinterHumidity = parseFloat(event.target.value)
                    this.handleInputChange(
                      'indoorWinterHumidity',
                      indoorWinterHumidity
                    )
                    this.handleComfortPointUpdate(
                      'savedWinterHumidity',
                      indoorWinterHumidity
                    )
                  }}
                />
              </Space>
            </GridItem>
          </Grid>
          <Space bottom="base">
            <Select
              name="select"
              label="Airflow / CFD Level"
              disabled={isLocked ? true : false}
              value={selectedPosture}
              options={elevationOptions}
              onChange={event => {
                const isStanding = event.target.value === 'standing'
                this.handleInputChange('isStanding', isStanding)
                this.handleComfortPointUpdate('isStanding', isStanding)
              }}
            />
          </Space>
          <List>
            <Item>
              <StyledLabel>Air Velocity:</StyledLabel>
              {velocity.formattedValue(velocityUnits, { round: 2 })}
            </Item>
            <Item>
              <Space bottom="s">
                <StyledLabel>
                  <InfoTooltip title="Predicted Mean Vote" iconSize="16px" />
                  PMV:
                </StyledLabel>
                {pmv}
              </Space>
              <PMVScale value={pmv} />
            </Item>
            <Item>
              <StyledLabel>
                <InfoTooltip
                  title="Percentage of Persons Dissatisfied"
                  iconSize="16px"
                />
                PPD:
              </StyledLabel>
              {ppd}%
            </Item>
            <Item>
              <StyledLabel>
                <InfoTooltip title="Perceived Temperature" iconSize="16px" />
                SET:
              </StyledLabel>
              {set.formattedValue(temperatureUnits, { round: 2 })}
            </Item>
          </List>
        </PanelSection>
        <MetadataSection
          object={selectedComfortPoint}
          onBlur={this.props.onUpdateComfortPoint}
        />
      </div>
    )
  }

  render() {
    const { selectedObject, cfd, alignment, isTouchUI } = this.props
    const { loading } = this.state
    const cfdIsValid = cfd.isValid

    return (
      <Panel
        title="Comfort Point"
        alignment={alignment}
        docked
        panelKey={SELECTED_COMFORT_POINT_PANEL}
        scrollable
        hasToolbar={isTouchUI}
      >
        {selectedObject && (
          <div>
            {loading ? (
              <Space bottom="base">
                <Loader
                  label="Loading airflow data..."
                  iconSize={16}
                  centered
                  inline
                />
              </Space>
            ) : (
              this.getComfortPointData(selectedObject, cfdIsValid)
            )}
          </div>
        )}
      </Panel>
    )
  }
}

SelectedComfortPointPanel.propTypes = {
  selectedObject: object,
  cfd: object,
  cfdUrls: arrayOf(CFDUrl),
  airflow: object,
  facility: object,
  objects: object,
  onUpdateComfortPoint: func,
  onUpdateCFDLayer: func,
  onUpdateAirflowLayer: func,
  onUpdateAirflow: func,
  onDisableCanvas: func,
  onEnableCanvas: func,
  client: object,
  layers: object,
  unitsToConverter: func,
  formatUnits: func,
  units: object,
  alignment: string,
  temperatureUnits: string,
  velocityUnits: string,
  isTouchUI: bool,
}

SelectedComfortPointPanel.defaultProps = {
  alignment: 'right',
}

const mapStateToProps = ({
  selectedObjects,
  objects,
  layers,
  cfd,
  ...store
}) => {
  const airflow = objects.present.airflow
  const selectedObject = mostRecentSelectedObjectOfClassName(
    CLASS_NAMES.COMFORT_POINT,
    {
      selectedObjects,
      objects,
    }
  )
  return {
    isTouchUI: isTouchUI(store),
    selectedObject,
    layers,
    cfd,
    airflow,
    objects,
  }
}

const mapDispatchToProps = dispatch => ({
  onDisableCanvas() {
    dispatch(disableCanvas({}))
  },
  onEnableCanvas() {
    dispatch(enableCanvas({}))
  },
  onUpdateComfortPoint(comfortPoint) {
    dispatch(updateComfortPoint({ comfortPoint }))
  },
  onUpdateCFDLayer(selectedLayer) {
    dispatch(setCFDLayer(selectedLayer))
  },
  onUpdateAirflowLayer(layerKey) {
    dispatch(setAirflowLayer({ layerKey }))
  },
  async onUpdateAirflow({ products, objects, obstructions, segments }) {
    await updateAirflow(dispatch, {
      products,
      objects,
      obstructions,
      segments,
    })
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withApollo,
  withUnits
)(SelectedComfortPointPanel)
