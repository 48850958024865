import React, { Component } from 'react'
import get from 'lodash-es/get'
import { graphql } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'

import { GET_SALESFORCE_AUTHORIZATION_URL_QUERY } from 'client/queries'
import Loader from 'components/UIKit/Loader'

class SalesforceAuthenticationScreen extends Component {
  static defaultProps = {
    isLoading: true,
    authorizationUrl: undefined,
  }

  static propTypes = {
    authorizationUrl: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  componentDidUpdate(prevProps) {
    const didLoadAuthorizationUrl =
      !this.props.isLoading && this.props.authorizationUrl
    if (didLoadAuthorizationUrl) {
      window.location.href = this.props.authorizationUrl
    }
  }

  render() {
    return <Loader centered label="Redirecting to Salesforce" />
  }
}

const enhance = graphql(GET_SALESFORCE_AUTHORIZATION_URL_QUERY, {
  options: {
    variables: {
      redirectUrl: `https://${window.location.host}/api/callbacks/salesforce/auth`,
    },
  },
  props(result) {
    return {
      isLoading: get(result, 'data.loading'),
      authorizationUrl: get(result, 'data.Salesforce.authorizationUrl'),
    }
  },
})

export default enhance(SalesforceAuthenticationScreen)
