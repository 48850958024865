import { createContext, PropsWithChildren } from 'react'
import Units from '~/components/DrawingCanvas/lib/units'
import { useAppSelector } from '~/store/hooks'
import { BackgroundImage } from '~/store/objects/types'

export const BackgroundImageContext = createContext<null | BackgroundImage>(null)

export const BackgroundImageProvider = ({ children }: PropsWithChildren<{}>) => {
  const backgroundImage = useAppSelector(
    ({ objects }) => objects.present.backgroundImage.BACKGROUND_IMAGE
  )

  if (!backgroundImage) return <></>

  const { width, height } = backgroundImage
  const resizedBackgroundImage = {
    ...backgroundImage,
    width: Units.inchesToNative(width),
    height: Units.inchesToNative(height),
  }

  return (
    <BackgroundImageContext.Provider value={resizedBackgroundImage}>
      {children}
    </BackgroundImageContext.Provider>
  )
}
