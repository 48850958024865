import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const transform = ({
  facilitySize = { x: 0, y: 0 },
  peak,
  side,
  offset = 0,
}) => {
  const isMonoslope = peak === 'monoslope'
  const xModifier = 320 / facilitySize.x
  const yModifier = 155 / facilitySize.y
  const xOffset = isMonoslope ? 0 : xModifier * offset
  const yOffset = isMonoslope ? 0 : yModifier * offset

  if (!side || side === 'centered') {
    if (peak === 'vertical') {
      return `transform: translate(150px, 5px) rotate(90deg);`
    } else {
      return `transform: translate(5px, 70px)`
    }
  }

  if (side === 'left') {
    return `transform: translate(${xOffset + 5}px, 5px) rotate(90deg);`
  }

  if (side === 'right') {
    return `transform: translate(${300 - xOffset}px, 5px) rotate(90deg);`
  }

  if (side === 'top') {
    return `transform: translate(5px, ${yOffset}px)`
  }

  if (side === 'bottom') {
    return `transform: translate(5px, ${145 - yOffset}px)`
  }
}

const Line = styled.rect`
  fill: ${theme('colors.light.primary')};

  ${transform}
`

export default Line
