import { graphql } from '~/gql'

const ALL_DEFAULT_PRODUCT_VARIATIONS_QUERY = graphql(`
  query AllDefaultProductVariations {
    products: Products {
      defaultVariation {
        ...ProductVariationFragment
      }
    }
  }
`)

export default ALL_DEFAULT_PRODUCT_VARIATIONS_QUERY
