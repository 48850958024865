import { DialogTrigger as RACDialogTrigger, DialogTriggerProps, ModalOverlay, ModalOverlayProps, Modal as RACModal } from 'react-aria-components';
import { AnimationDefinition, motion } from 'framer-motion'
import { createContext, useContext, useState, Dispatch, SetStateAction } from 'react';

const MotionModalOverlay = motion(ModalOverlay)
const MotionModal = motion(RACModal)

const overlayStyles = 'fixed top-0 left-0 w-full h-[--visual-viewport-height] isolate z-20 bg-black/[20%] flex items-center justify-center p-4 text-center'
const modalStyles = 'w-full max-w-md max-h-full rounded bg-white dark:bg-zinc-800/70 dark:backdrop-blur-2xl dark:backdrop-saturate-200 forced-colors:bg-[Canvas] text-left align-middle text-gray-700 dark:text-zinc-300 shadow-2xl bg-clip-padding border border-black/10 dark:border-white/10'

type AnimationState = 'unmounted' | 'hidden' | 'visible'
export const DialogTriggerAnimationStateContext = createContext<[AnimationState, Dispatch<SetStateAction<AnimationState>>] | null>(null)

export function useDialogTriggerAnimations() {
  const ctx = useContext(DialogTriggerAnimationStateContext)

  if (ctx === null) {
    return {}
  }

  const [animation, setAnimation] = ctx
  return {
    isExiting: animation === 'hidden',
    onAnimationComplete: (completed: AnimationDefinition) => setAnimation(state => completed === 'hidden' && state === 'hidden' ? 'unmounted' : state),
    initial: 'hidden',
    animate: animation,
  }
}

export function DialogTrigger(props: DialogTriggerProps) {
  const [animation, setAnimation] = useState<'unmounted' | 'hidden' | 'visible'>('unmounted')

  return (
    <DialogTriggerAnimationStateContext.Provider value={[animation, setAnimation]}>
      <RACDialogTrigger onOpenChange={open => setAnimation(open ? 'visible' : 'hidden')} {...props}/>
    </DialogTriggerAnimationStateContext.Provider>
  )
}

export function Modal(props: ModalOverlayProps) {
  const animationProps = useDialogTriggerAnimations()

  return (
    <MotionModalOverlay
      variants={{hidden: { opacity: 0 }, visible: { opacity: 1 }}}
      {...animationProps}
      {...props}
      className={overlayStyles}
    >
      {/* @ts-ignore-error the types for the children prop don't quite add up */}
      <MotionModal variants={{hidden: { opacity: 0, y: 32 }, visible: { opacity: 1, y: 0 }}} {...props} className={modalStyles}/>
    </MotionModalOverlay>
  );
}
