import styled, { StyledConfig, ThemedStyledProps, css } from 'styled-components'
import { Link } from 'react-router-dom'

import { isTouchUI } from 'store/userInterface/selectors'
import { actionFocus, noSelect, theme } from 'lib/styleUtils'
import { Spacing, Theme, Variant } from '~/config/theme'

const setDarkTheme = ({ noBorder, primary, theme, variant }: ThemedStyledProps<{ noBorder?: boolean; primary?: boolean; variant?: Variant }, Theme>) => {
  if (variant === 'dark') {
    const isTouch = () => {
      if (isTouchUI()) {
        return `
        color: ${theme.colors.dark.light};
        `
      }
      return `
      color: ${theme.colors.dark.fg};
      `
    }
    const isPrimary = () => {
      if (primary) {
        return `
          background-color: ${theme.colors.light.primary};
          color: ${theme.colors.light.fg};
          font-weight: ${theme.fonts.weights.semiBold};
          border-color: transparent;
      `
      }
    }
    const hasNoBorder = () => {
      if (noBorder) {
        return `
          background-color: transparent;
          border-color: transparent;
      `
      }
    }
    return css`
      background-color: ${theme.colors.dark.bg};
      border-color: ${theme.colors.dark.light};
      ${isTouch}
      ${hasNoBorder}
      ${isPrimary}
    `
  }
}

const setTheme = (props: ThemedStyledProps<{ noBorder?: boolean; disabled?: boolean; primary?: boolean; danger?: boolean }, Theme>) => {
  if (props.disabled) {
    if (props.noBorder) {
      return css`
        color: ${theme('colors.light.fg')};
        opacity: 0.5;
      `
    }

    return css`
      background-color: ${theme('colors.light.subdued')};
      color: ${theme('colors.light.fg')};
      font-weight: ${theme('fonts.weights.semiBold')};
      opacity: 0.5;
    `
  }

  if (props.primary) {
    return css`
      background-color: ${theme('colors.light.primary')};
      color: ${theme('colors.light.fg')};
      font-weight: ${theme('fonts.weights.semiBold')};
    `
  }

  if (props.danger) {
    return css`
      background-color: ${theme('colors.light.error')};
      color: ${theme('colors.light.bg')};
      font-weight: ${theme('fonts.weights.semiBold')};
    `
  }

  return css`
    background-color: ${props.noBorder
      ? `transparent`
      : props.theme.colors.light.bg};
    color: ${theme('colors.light.fg')};
  `
}

const setActiveDarkTheme = ({ theme, noBorder, variant }: ThemedStyledProps<{ noBorder?: boolean; variant?: Variant }, Theme>) => {
  if (variant === 'dark') {
    const hasBorder = () => {
      if (!noBorder) {
        return `
          border-color: ${theme.colors.dark.fg};
      `
      }
    }
    return css`
      color: ${theme.colors.dark.fg};
      ${hasBorder}
    `
  }
}

const setSize = (props: ThemedStyledProps<{ size?: Spacing }, Theme>) => {
  if (props.size === 's') {
    return css`
      height: ${theme('spacing.base')};
      padding: 0 ${theme('spacing.s')};
    `
  }

  if (props.size === 'l') {
    return css`
      height: ${theme('spacing.xl')};
      padding: 0 ${theme('spacing.base')};
    `
  }

  if (props.size === 'xl') {
    return css`
      height: ${theme('spacing.xl')};
      padding: 0 ${theme('spacing.base')};
    `
  }

  if (props.size === 'square') {
    return css`
      height: ${theme('spacing.square')};
      width: ${theme('spacing.square')};
    `
  }

  return css`
    height: ${theme('spacing.l')};
    padding: 0 ${theme('spacing.s')};
  `
}

const setPaddingLeft = (props: ThemedStyledProps<{ paddingLeft?: string }, Theme>) => {
  if (props.paddingLeft) {
    return css`
      padding-left: ${props.paddingLeft};
    `
  }

  return null
}

const setPaddingRight = (props: ThemedStyledProps<{ paddingRight?: string }, Theme>) => {
  if (props.paddingRight) {
    return css`
      padding-right: ${props.paddingRight};
    `
  }

  return null
}

const styles = css<{ noBorder?: boolean; primary?: boolean; danger?: boolean; display?: string; fullWidth?: boolean; disabled?: boolean; paddingLeft?: string; paddingRight?: string; size?: Spacing }>`
  align-items: center;
  border: ${theme('borderWidth')} solid ${theme('colors.light.subdued')};
  ${props =>
    (props.noBorder || props.primary || props.danger) &&
    `border-color: transparent;`};
  border-radius: ${theme('radius.base')};
  display: ${props => props.display || 'inline-flex'};
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  -webkit-appearance: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : `pointer`)};

  ${props => props.fullWidth && `width: 100%;`};
  ${props => props.disabled && `pointer-events: none;`};
  ${noSelect()};
  ${props => setTheme(props)};
  ${props => setSize(props)};
  ${props => setPaddingLeft(props)};
  ${props => setPaddingRight(props)};
  ${setDarkTheme}

  &:hover,
  &:active,
  &:focus {
    ${props =>
      (props.primary || props.danger) &&
      `background-color:${props.theme.colors.dark.bg};`};
    ${props =>
      props.disabled &&
      `
        border: ${props.theme.borderWidth} solid ${props.theme.colors.light.subdued};
        cursor: default;
      `};
    ${props =>
      !props.noBorder
        ? `border-color: ${props.theme.colors.light.fg};`
        : 'border-color: transparent'};
    ${props => props.primary && `color:${props.theme.colors.dark.fg};`};
    ${setActiveDarkTheme}
  }

  ${props => !props.noBorder && actionFocus()};
  ${props => props.noBorder && `outline: none;`};
`

const nonforwardingProps: (string | number | symbol)[] = [
  "dropdown",
  "dropdownReverse",
  "icon",
  "iconColor",
  "iconRight",
  "iconRightColor",
  "isDropdownVisible",
  "label",
  "responsive",
  "variant",
  "truncate",
  "noBorder",
  "primary",
  "danger",
  "display",
  "fullWidth",
  "disabled",
  "paddingLeft",
  "paddingRight",
  "size",
]

const config: StyledConfig<any> = { shouldForwardProp: (prop) => !nonforwardingProps.includes(prop) }

const ButtonContainer = styled('button').withConfig(config)`
  ${styles};
`

const AnchorContainer = styled('a').withConfig(config)`
  ${styles};
`

const LinkContainer = styled(Link).withConfig(config)`
  ${styles};
`

export { ButtonContainer, AnchorContainer, LinkContainer }
