import styled from 'styled-components'

import { lighten, theme } from 'lib/styleUtils'

// Correlates to the panel's header height
const size = '40px'

const position = ({ theme, isTouchUI }) =>
  isTouchUI
    ? `
  left: 0;
  right: auto;
  border-left: none;
  border-right: 1px solid ${theme.colors.light.subdued};
`
    : `
  right: 0;
  left: auto;
  border-left: 1px solid ${theme.colors.light.subdued};
  border-right: none;
`

const Tab = styled.a`
  background-color: ${props => lighten(props.theme.colors.light.subdued, 0.2)};
  border-bottom: 1px solid ${theme('colors.light.subdued')};
  display: block;
  height: ${size};
  line-height: ${size};
  position: absolute;
  text-align: center;
  top: 0;
  width: ${size};
  cursor: pointer;

  > *:first-child {
    transition: transform 0.3s ease-in-out;
    position: relative;
    top: -2px;
  }

  &:active,
  &:focus,
  &:hover {
    > *:first-child {
      transform: translateX(-2px);
    }
  }

  ${position}
`

export default Tab
