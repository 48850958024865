import { graphql } from '~/gql'

const GET_FACILITY_QUERY = graphql(`
  query GetFacility($id: ID!, $floorId: ID, $versionId: ID) {
    facility: Facility(id: $id) {
      ...FacilityFragment
      floor(floorId: $floorId) {
        ...FloorFragment
        version(versionId: $versionId) {
          ...VersionFragment
          snapshots {
            ...SnapshotFragment
          }
        }
        versions {
          id
          name
        }
      }
      floors {
        id
        name
        version {
          id
        }
      }
    }
  }
`)

export default GET_FACILITY_QUERY
