import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import Modal from 'components/UIKit/Modal'
import { getTitle } from 'config/titles'
import { ModalContent } from 'components/Modals/CFDImagesModal/subcomponents/ModalContent'

export const CFDImagesModal = ({ parentRoute }) => {
  const history = useHistory()
  return (
    <div>
      <Helmet>
        <title>{getTitle('cfdImages')}</title>
      </Helmet>
      <Modal
        title="CFD Images"
        parentRoute={parentRoute}
        history={history}
        flushContent={true}
        size="80%"
        onClose={() => history.push(parentRoute)}
      >
        <ModalContent />
      </Modal>
    </div>
  )
}

CFDImagesModal.propTypes = {
  parentRoute: PropTypes.string,
}
