import { graphql } from '~/gql'

const CREATE_QUOTE_MUTATION = graphql(`
  mutation CreateQuote(
    $name: String!
    $opportunityId: String
    $versionId: ID
    $bundles: [createQuoteBundleInput]
    $shippingPrice: Float
  ) {
    createQuote(
      name: $name
      opportunityId: $opportunityId
      versionId: $versionId
      bundles: $bundles
      shippingPrice: $shippingPrice
    ) {
      ...QuoteFragment
    }
  }
`)

export default CREATE_QUOTE_MUTATION
