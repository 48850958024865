import PropTypes from 'prop-types'
import RcTooltip from 'rc-tooltip'

import 'rc-tooltip/assets/bootstrap.css'
import { ComponentProps, PropsWithChildren } from 'react'

type TooltipProps =
  Pick<ComponentProps<typeof RcTooltip>, 'placement' | 'trigger'>
  & { content: ComponentProps<typeof RcTooltip>['overlay'] }

const Tooltip = ({ children, placement, content, trigger }: PropsWithChildren<TooltipProps>) => (
  <RcTooltip
    placement={placement ?? 'top'}
    overlay={content}
    trigger={trigger}
    destroyTooltipOnHide
    overlayClassName="baf-tooltip"
  >
    {children}
  </RcTooltip>
)

export default Tooltip
