import { graphql } from '~/gql'

const GET_SNAPSHOTS_QUERY = graphql(`
  query getSnapshots($versionId: ID) {
    getSnapshots(versionId: $versionId) {
      ...SnapshotFragment
    }
  }
`)

export default GET_SNAPSHOTS_QUERY
