import styled from 'styled-components'

const Container = styled.div`
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100vh;
`

export default Container
