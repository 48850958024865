import { graphql } from '~/gql'

const DELETE_FACILITY_MUTATION = graphql(`
  mutation DeleteFacility($id: ID!) {
    deleteFacility(id: $id) {
      id
    }
  }
`)

export default DELETE_FACILITY_MUTATION
