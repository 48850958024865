import styled, { css } from 'styled-components'

const TOOLBAR_AND_HEADER_HEIGHT = '98px'

const setPadding = props => {
  if (props.scrollable) {
    if (props.hasToolbar) {
      return null
    }
    return css`
      padding-bottom: ${props.hasFooter ? '110px' : '60px'};
    `
  }
}

const setHeight = props => {
  if (props.hasToolbar) {
    return `height: calc(100% - ${TOOLBAR_AND_HEADER_HEIGHT});`
  }

  return 'height: 100%;'
}

const Content = styled.div`
  ${props =>
    props.minimized &&
    `
  height: 0;
  `}
  ${props =>
    props.scrollable &&
    `
    overflow-y: auto;
  `};
  ${props =>
    props.stretchContainer &&
    `
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `};
  ${setPadding}
  ${props => props.scrollable && setHeight}
`

export default Content
