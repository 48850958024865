const messages = [
  {
    key: 'airflow',
    action: 'hover',
    type: 'info',
    text: 'Display airflow for the current facility.',
  },
  {
    key: 'door',
    action: 'activate',
    type: 'info',
    text: 'Doors can only be placed on walls.',
  },
  {
    key: 'door',
    action: 'click',
    type: 'error',
    text: 'Doors can only be placed on walls.',
  },
  {
    key: 'snapshots',
    action: 'hover',
    type: 'info',
    text: 'Take a downloadable screenshot of your facility.',
  },
  {
    key: 'heatmap',
    action: 'hover',
    type: 'info',
    text: 'Display heat map for the current facility.',
  },
] as const
export type MessageKeys = (typeof messages)[number]['key']
export type MessageActions = (typeof messages)[number]['action']

export const getMessage = ({ key, action }: { key: MessageKeys; action: MessageActions }) =>
  messages.filter(
    message => message.key === key && message.action === action
  )[0]

export default messages
