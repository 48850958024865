import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Stack } from 'components/UIKit/Stack'

const getGridArea = (rowIndex, columnIndex) => `r${rowIndex}c${columnIndex}`

const getGridTemplateAreas = ({ columnCount = 1, rowCount = 1 }) => {
  const areas = Array(rowCount)
    .fill('')
    .map((_, rowIndex) => {
      const rowValues = Array(columnCount)
        .fill('')
        .map((_, columnIndex) => getGridArea(rowIndex, columnIndex))
      return `"${rowValues.join(' ')}"`
    })
    .join('\n')
  return areas
}

const Grid = styled.div`
  display: grid;
  gap: ${({ gap }) => `${gap ?? 0}em`};
  grid-template-columns: repeat(${({ columnCount = 1 }) => columnCount}, 1fr);
  grid-template-rows: repeat(${({ rowCount = 1 }) => rowCount}, 1fr);
  place-items: ${({ placeItems }) => placeItems ?? 'center'};
  grid-template-areas: ${getGridTemplateAreas};
  padding: ${({ padding }) => `${padding ?? 0}em`};
`

const GridItem = styled.div`
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  grid-area: ${({ gridArea }) => gridArea};
  font-size: ${({ theme, size }) => theme?.fonts?.sizes?.[size] ?? null};
  font-weight: ${({ theme, weight }) => theme?.fonts?.weights?.[weight] ?? null};
`

const MetricsTable = ({ columns, ...styleProps }) => {
  const columnCount = columns.length
  const rowCount = columns.reduce((maxRows, { cells }) => Math.max(maxRows, cells.length), 1) + 1
  return (
    <Grid columnCount={columnCount} rowCount={rowCount} {...styleProps}>
      {columns.map(({ header, cells }, columnIndex) => (
        <Fragment key={columnIndex}>
          <GridItem size="h1" weight="bold" gridArea={getGridArea(0, columnIndex)}>
            {header}
          </GridItem>
          {cells.map((cell, cellIndex) => (
            <GridItem
              size="l"
              weight="semiBold"
              gridArea={getGridArea(cellIndex + 1, columnIndex)}
              key={cellIndex}
            >
              <Stack>
                {cell.icon && cell.icon}
                {cell.content}
              </Stack>
            </GridItem>
          ))}
        </Fragment>
      ))}
    </Grid>
  )
}

const Cell = PropTypes.shape({
  icon: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
})

const Column = PropTypes.shape({
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  cells: PropTypes.arrayOf(Cell),
})

MetricsTable.propTypes = {
  columns: PropTypes.arrayOf(Column),
  placeItems: PropTypes.string,
  gap: PropTypes.number,
  padding: PropTypes.number,
}

export { MetricsTable }
