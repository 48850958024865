import React from 'react'
import { MetricsDisplay } from 'components/PerformanceMetrics/subcomponents/MetricsDisplay'
import { PerformanceMetricsControls } from './subcomponents/PerformanceMetricsControls'

export function PerformanceMetrics() {
  return (
    <PerformanceMetricsControls>
      <MetricsDisplay />
    </PerformanceMetricsControls>
  )
}

export default PerformanceMetrics
