import React from 'react'
import { Helmet } from 'react-helmet'

import { getTitle } from 'config/titles'

import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import Icon from 'components/UIKit/Icon'
import Blockquote from 'components/UIKit/Blockquote'

const CFDHelpModal = ({ ...props }) => (
  <div>
    <Helmet>
      <title>{getTitle('cfdHelp')}</title>
    </Helmet>
    <Modal title="About CFD Simulation" {...props}>
      <Space bottom="base">
        <p>
          A three-dimensional simulation of the airflow generated by a fan
          layout. Based on computed fan performance and validated with empirical
          data collected per AMCA 230-15.
        </p>
      </Space>
      <Space bottom="base">
        <Blockquote>
          <p>
            <Icon name="info" size="14" color="subdued" /> This feature should
            be used to assess the effectiveness of a fan configuration.
          </p>
        </Blockquote>
      </Space>
    </Modal>
  </div>
)

export default CFDHelpModal
