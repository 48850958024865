import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import theme from 'config/theme'

const styles = props => {
  const s = theme.spacing

  let styles = ''

  // TODO: abstract margin style to new utility func that can be used
  // across codebase instead of so much duplicate WET code in each component

  if (props.marginBottom) styles += `margin-bottom: ${s[props.marginBottom]};`
  if (props.marginLeft) styles += `margin-left: ${s[props.marginLeft]};`
  if (props.marginRight) styles += `margin-right: ${s[props.marginRight]};`
  if (props.marginTop) styles += `margin-top: ${s[props.marginTop]};`

  if (props.width) styles += `width: ${props.width};`

  return styles
}

const Container = styled.hr`
  border-top: 1px solid
    ${props =>
      props.type === 'primary'
        ? theme.colors.light.primary
        : theme.colors.light.subdued};

  ${styles}
`

const HR = props => <Container {...props} />

HR.propTypes = {
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  marginTop: PropTypes.string,
  width: PropTypes.string,
}
export default HR
