import React, { Component } from 'react'
import PropTypes from 'prop-types'

import VariantText from '~/components/UIKit/VariantText'
import Space from '~/components/UIKit/Space'
import Flex, { FlexItem } from '~/components/UIKit/Flex'
import TextField from '~/components/UIKit/TextField'

class ContactInfo extends Component {
  render() {
    return (
      <>
        <Flex justifyContent="space-around" alignItems="center">
          <FlexItem width="350px">
            <Flex flexDirection="column" justifyContent="center">
              <Space bottom="base">
                <TextField
                  name="tempContactInfo"
                  label="Contact Name"
                  value={this.props.tempContactInfo.name}
                  onChange={e =>
                    this.props.handleFieldChanged({ name: e.target.value })
                  }
                />
              </Space>
              <Space bottom="base">
                <TextField
                  name="tempContactInfo"
                  label="Contact Email Address"
                  value={this.props.tempContactInfo.email}
                  onChange={e =>
                    this.props.handleFieldChanged({ email: e.target.value })
                  }
                />
              </Space>
              <Space bottom="base">
                <TextField
                  name="tempContactInfo"
                  label="Contact Phone Number"
                  value={this.props.tempContactInfo.phone}
                  onChange={e =>
                    this.props.handleFieldChanged({ phone: e.target.value })
                  }
                />
              </Space>
            </Flex>
          </FlexItem>
          <FlexItem width="350px">
            <Space bottom="base">
              <VariantText bold italic>
                Will show in the yellow bar on every page
              </VariantText>
            </Space>
            <img
              src="/custom-contact-information-screenshot.png"
              alt="contact info ss"
              width="350px"
            />
          </FlexItem>
        </Flex>
      </>
    )
  }
}

ContactInfo.propTypes = {
  tempContactInfo: PropTypes.object,
  handleFieldChanged: PropTypes.func,
}

export default ContactInfo
