import isEqual from 'lodash-es/isEqual'

import Facility from './facility'
import Primitives from './primitives'
import Region from './region'
import ShapeTool from './shapeTool'
import SnapQueries from './snapQueries'
import Wall from './wall'
import WallSegment from './wallSegment'
import Units from './units'
import Util from './util'

import store from 'store'
import { addObject } from 'store/objects'
import { isOrthoModeEnabled } from 'store/tools/selectors'
import { setStatus } from 'store/status'

import * as THREE from 'three'

class RectangleTool extends ShapeTool {
  /*
      -= properties =-

      wall: Wall object used to create the rectangle visual
      obj3d: root of the visual used to indicate the bounds of the drawn object
    */

  static WALL_SNAP_DISTANCE = Units.feetToNative(10)

  constructor() {
    super()

    this.name = 'RECTANGLE_TOOL'
    this.scene = Util.getSceneGraphRootFromNode(Facility.current.obj3d)
    this.createVisual()

    this.cursor = this.createCursor()
    this.cursor.scale.set(0.0001, 0.0001, 0.0001)
    this.obj3d.add(this.cursor)
  }

  createVisual() {
    this.obj3d = new THREE.Object3D()
  }

  updateVisual(startPos, endPos) {
    if (this.wall !== undefined) {
      this.wall.segments.forEach(segment => segment.hideLengthLabel())
      this.obj3d.remove(this.wall.obj3d)
    }

    // We are no longer drawing so return
    if (this.shapeCancelled) return

    let xDiff = endPos.x - startPos.x
    let yDiff = (endPos.y - startPos.y) * -1

    if (this.shiftModifier || isOrthoModeEnabled()) {
      if (yDiff > xDiff) {
        xDiff = xDiff > 0 ? yDiff : Math.abs(yDiff) * -1
      } else {
        yDiff = yDiff > 0 ? xDiff : Math.abs(xDiff) * -1
      }
    }

    const appState = store.getState()
    const units = appState.objects.present.units
    const layer = appState.layers.currentLayer
    const model = Wall.createModel(
      this.getRectCenterLine(startPos, xDiff, yDiff),
      units,
      layer,
      { convertFromNativeUnits: true }
    )

    if (Wall.isCenterLineValid(model)) {
      this.wall = new Wall(model, units)

      this.wall.segments.forEach(segment => (segment.wall = this.wall))
      this.wall.segments.forEach(segment => segment.showLengthLabel())

      this.obj3d.add(this.wall.obj3d)
    }
  }

  toolDown(mousePos, snappedMousePos) {
    if (!this.startedShapeCreation) {
      this.startShapeDescription(snappedMousePos)
    }
  }

  toolMoved(mousePos, snappedMousePos, _, objectUnderTool) {
    this.endPos = snappedMousePos
    this.removeEdgeCircle()

    const isSnapping = !isEqual(mousePos, snappedMousePos)
    if (isSnapping) {
      const isOverWallSegment = objectUnderTool instanceof WallSegment
      if (isOverWallSegment) this.cursor.scale.set(4, 4, 1)

      const newPoint = [snappedMousePos.x, snappedMousePos.y]
      this.createEdgeCircle(newPoint)
    } else {
      this.cursor.scale.set(0.0001, 0.0001, 0.0001)
    }

    this.cursor.position.set(snappedMousePos.x, snappedMousePos.y, 0)

    if (this.startedShapeCreation) {
      this.updateVisual(this.startPos, this.endPos)
    }
  }

  finishShapeDescription(multiSelect) {
    super.finishShapeDescription()

    if (!this.wall || !this.wall.obj3d) return

    this.obj3d.remove(this.wall.obj3d)

    if (this.shapeCancelled) {
      this.wall = undefined
      return
    }

    const pos = this.wall.obj3d.position.clone()
    const positions = []
    positions.push(pos)
    this.wall.segments.forEach(seg => {
      positions.push(pos.clone().add(seg.startPoint))
      positions.push(pos.clone().add(seg.endPoint))
    })
    const isExteriorWall = this.wall.layerKey === 'EXTERIOR_WALLS'

    if (isExteriorWall) {
      // Don't allow exterior walls to be placed inside facility
      const isOutsideFacility = Util.isPositionsOutsideFacility(positions)
      // check to see if it has exterior walls within its bounds
      const surroundsFacility = Util.isSurroundingFacility(positions)

      if (isOutsideFacility && !surroundsFacility) {
        store.dispatch(addObject({ object: this.wall.toModel(), multiSelect }))
      } else if (surroundsFacility) {
        this.wall.segments.forEach(segment => segment.hideLengthLabel())
        const error = `Exterior walls can't enclose other exterior walls!`
        store.dispatch(setStatus({ text: error, type: 'error' }))
      } else {
        this.wall.segments.forEach(segment => segment.hideLengthLabel())
        const error = `Exterior walls can't be placed inside the facility!`
        store.dispatch(setStatus({ text: error, type: 'error' }))
      }
    } else {
      // Don't allow interior walls to be placed outside facility
      const isInsideFacility = Util.isPositionsOverFacility(positions)
      if (isInsideFacility) {
        store.dispatch(addObject({ object: this.wall.toModel(), multiSelect }))
      } else {
        this.wall.segments.forEach(segment => segment.hideLengthLabel())
        const error = 'Interior walls must be placed inside the facility!'
        store.dispatch(setStatus({ text: error, type: 'error' }))
      }
    }
  }

  getSnapRegions(facility, draggedObject) {
    // When using the Interior Wall Rectangle Tool we
    // want to snap to exterior wall center lines
    const currentLayer = store.getState().layers.currentLayer
    if (currentLayer === 'INTERIOR_WALLS') {
      const extWallCenterLines = SnapQueries.getAllWallCenterLines(
        [],
        true,
        RectangleTool.WALL_SNAP_DISTANCE
      )
      return extWallCenterLines
    }

    return []
  }

  deactivate() {
    if (this.wall) this.wall.segments.forEach(seg => seg.hideLengthLabel())

    this.removeEdgeCircle()
    this.removeEventListeners()
  }

  getRectCenterLine(origin, width, height) {
    const points = []

    points.push([origin.x, origin.y, 0])
    points.push([origin.x + width, origin.y, 0])
    points.push([origin.x + width, origin.y - height, 0])
    points.push([origin.x, origin.y - height, 0])
    points.push([origin.x, origin.y, 0])

    return points
  }

  // Create a circle if the segment will "attach" to an existing wall
  createEdgeCircle(point) {
    this.removeEdgeCircle()

    const regionRoot = Facility.current.getRegions()

    let edgeFound = false

    const findEdge = region => {
      const edges = region.edges

      edgeFound = Boolean(
        Region.findEdgeUnderPoint({ x: point[0], y: point[1] }, edges)
      )

      if (!edgeFound && region.children.length) {
        edgeFound = region.children.some(findEdge)
      }

      return edgeFound
    }

    findEdge(regionRoot)

    if (edgeFound) {
      const edgeCircle = Primitives.getCircle(
        RectangleTool.WALL_SNAP_DISTANCE * 10
      )
      edgeCircle.name = 'EdgeCircle'
      edgeCircle.position.x = point[0]
      edgeCircle.position.y = point[1]
      this.scene.add(edgeCircle)
    }
  }

  removeEdgeCircle() {
    const existingEdgeCircle = this.scene.getObjectByName('EdgeCircle')
    if (existingEdgeCircle) {
      this.scene.remove(existingEdgeCircle)
    }
  }
}

export default RectangleTool
