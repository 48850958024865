import styled from 'styled-components'

export const ASPECT_RATIO = 4 / 3
const TOOL_BAR_HEIGHT = 60
const TOOL_BAR_HEIGHT_PX = `${TOOL_BAR_HEIGHT}px`

const BackgroundImage = styled.img`
  height: calc(100vh - ${TOOL_BAR_HEIGHT_PX});
  height: 100%;
  left: 50%;
  left: 0;
  margin-left: calc(-0.5 * ((100vh - ${TOOL_BAR_HEIGHT_PX}) * ${ASPECT_RATIO}));
  margin-left: 0;
  object-fit: contain;
  position: absolute;
  pointer-events: none;
  top: 0;
  width: calc((100vh - ${TOOL_BAR_HEIGHT_PX}) * ${ASPECT_RATIO});
  width: 100%;
`

export default BackgroundImage
