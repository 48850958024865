import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import theme from 'config/theme'

const SubItem = styled.li`
  list-style: none;
`

const StyledSubLink = styled.a`
  color: ${theme.colors.dark.subdued};

  display: flex;
  align-items: center;
  column-gap: 0.25em;
  text-decoration: none;

  padding: 2px 0;

  cursor: pointer;
`

const SubLink = props => {
  return (
    <SubItem>
      <StyledSubLink active={props.active}>{props.content}</StyledSubLink>
    </SubItem>
  )
}

SubLink.propTypes = {
  active: PropTypes.bool,
  content: PropTypes.string,
}

export default SubLink
