import Tool from './tool'
import store from 'store'
import ElevationPoint from './elevationPoint'
import ElevationLine from './elevationLine'
import Units from './units'
import { addElevationLine } from 'store/objects'
import { isSnapEnabled } from 'store/tools/selectors'
import Util from './util'
import RoofToolUtil from './roofToolUtil'
import Primitives from './primitives'
import SnapQueries from './snapQueries'
import { getThreeHexFromTheme } from 'lib/utils'
import { setStatus } from 'store/status'

import * as THREE from 'three'
const COLOR = getThreeHexFromTheme('three.valid')

class ElevationLineTool extends Tool {
  constructor() {
    super()

    this.name = 'ELEVATION_LINE_TOOL'
    this.obj3d = new THREE.Object3D()
    this.cursor = this.createCursor()
    this.startedLine = false
    this.addedPoints = []
    this.lastEPPosition = undefined
    this.startCorner = undefined
    this.endCorner = undefined

    this.obj3d.add(this.cursor)
  }

  createCursor() {
    return Primitives.getCrosshairCursorVisual(
      Units.inchesToNative(6),
      Units.inchesToNative(6),
      0.3,
      0x000000
    )
  }

  toolMoved(
    mousePos,
    snappedMousePos,
    sceneIntersectionPoint,
    objectUnderTool,
    objectUnderSnappedTool
  ) {
    let position = snappedMousePos.clone()
    let wallIntersections = []

    this.lastSceneIntersectionPoint = sceneIntersectionPoint

    if (this.startedLine) {
      // Loop through walls, find intersections
      for (let i = 0; i < this.roof.perimeterPoints.length - 1; i++) {
        const wallP1 = {
          x: this.roof.perimeterPoints[i][0],
          y: this.roof.perimeterPoints[i][1],
        }
        const wallP2 = {
          x: this.roof.perimeterPoints[i + 1][0],
          y: this.roof.perimeterPoints[i + 1][1],
        }
        const foundWallIntersection = Util.lineSegmentsIntersectionPoint(
          this.lastEPPosition,
          snappedMousePos,
          wallP1,
          wallP2
        )

        if (foundWallIntersection) {
          wallIntersections.push({
            ...foundWallIntersection,
            distance: Util.distanceToLineSegment(
              wallP1.x,
              wallP1.y,
              wallP2.x,
              wallP2.y,
              this.lastEPPosition.x,
              this.lastEPPosition.y
            ),
            distanceToInterSection: this.lastEPPosition.distanceTo(
              foundWallIntersection
            ),
          })
        }
      }

      // Find closest wall. Filter walls that are very close to the starting point,
      // as sometimes the line is placed slighty in front or behind of a wall line
      const usableIntersections = wallIntersections
        .filter(wall => wall.distance > 1)
        .sort((a, b) => (a.distance > b.distance ? 1 : -1))
      if (usableIntersections.length) {
        position.set(usableIntersections[0].x, usableIntersections[0].y)
      }

      this.updateNextLineVisual()
    }
    this.lastSnappedMousePos = position
    this.cursor.position.set(position.x, position.y, 100)
  }

  toolFinish(mousePos, snappedMousePos) {
    this.finishElevationLine()
  }

  toolUp({
    mousePos,
    snappedMousePos,
    sceneIntersectionPoint,
    objectUnderTool,
    objectUnderSnappedTool,
    multiSelect,
    allIntersectedObjects,
  }) {
    const roof = RoofToolUtil.getRoofUnderCursor(this.lastSnappedMousePos)

    if (roof) {
      const lineCrossesRoof =
        this.startedLine &&
        Util.isPointOnPolygonPerimeter(
          this.lastSnappedMousePos,
          roof.perimeterPoints
        )

      const roofPos = roof.obj3d.position
      const localPosition = new THREE.Vector3(
        this.lastSnappedMousePos.x - roofPos.x,
        this.lastSnappedMousePos.y - roofPos.y,
        roof.height
      )

      const wallSegment = allIntersectedObjects.find(
        obj => obj.className === 'WallSegment'
      )

      const wall = allIntersectedObjects.find(obj => obj.className === 'Wall')
      this.addedPoints.push({
        position: localPosition,
        wallSegment,
        wall,
      })

      this.lastEPPosition = new THREE.Vector3(
        this.lastSnappedMousePos.x,
        this.lastSnappedMousePos.y,
        roof.height
      )
      this.updateNextLineVisual()

      if (!this.startedLine) {
        this.startCorner = this.getRoofCorner(localPosition, roof)
        this.startElevationLine(roof)
      } else {
        this.endCorner = this.getRoofCorner(localPosition, roof)
      }

      if (lineCrossesRoof) {
        this.finishElevationLine()
      }
    } else {
      const error = 'Elevation Lines must be placed on a roof!'
      store.dispatch(setStatus({ text: error, type: 'error' }))
    }
  }

  // Gets the corner of the roof a position is over otherwise return nothing
  getRoofCorner(pos, roof) {
    const bbox = new THREE.Box3().setFromObject(roof.obj3d)
    const roundedPos = new THREE.Vector2(Math.round(pos.x), Math.round(pos.y))
    const minX = Math.round(bbox.min.x)
    const maxX = Math.round(bbox.max.x)
    const maxY = Math.round(bbox.max.y)
    const minY = Math.round(bbox.min.y)

    if (roundedPos.x === minX && roundedPos.y === maxY) {
      return 'topLeft'
    } else if (roundedPos.x === minX && roundedPos.y === minY) {
      return 'bottomLeft'
    } else if (roundedPos.x === maxX && roundedPos.y === maxY) {
      return 'topRight'
    } else if (roundedPos.x === maxX && roundedPos.y === minY) {
      return 'bottomRight'
    }

    return
  }

  // Gets offsets toward the inside of the facility if both points
  // are on corners of the roof to prevent invalid positions
  getPointOffset() {
    const offsets = { x: 0, y: 0 }
    const corners = [this.startCorner, this.endCorner]
    const offSetValue = Units.inchesToNative(1)

    if (corners.includes('topLeft') && corners.includes('bottomLeft')) {
      offsets.x += offSetValue
    } else if (corners.includes('topLeft') && corners.includes('topRight')) {
      offsets.y -= offSetValue
    } else if (
      corners.includes('bottomLeft') &&
      corners.includes('bottomRight')
    ) {
      offsets.y += offSetValue
    } else if (
      corners.includes('topRight') &&
      corners.includes('bottomRight')
    ) {
      offsets.x -= offSetValue
    }

    return offsets
  }

  startElevationLine(roof) {
    this.startedLine = true
    this.roof = roof
  }

  finishElevationLine() {
    this.startedLine = false

    if (this.addedPoints.length > 0) {
      this.shiftLinePointsToGenerateGables()

      const epModels = this.addedPoints.map(point => {
        const offsets = this.getPointOffset()
        const newPos = new THREE.Vector3(
          point.position.x + offsets.x,
          point.position.y + offsets.y,
          point.position.z
        )

        return ElevationPoint.createModel(
          newPos,
          this.roof.id,
          this.roof.height,
          point.wallSegment && point.wallSegment.id,
          point.wall && point.wall.id
        )
      })

      const elModel = ElevationLine.createModel(
        this.roof.id,
        epModels.map(ep => ep.id),
        this.roof.height
      )

      store.dispatch(
        addElevationLine({ elevationLine: elModel, elevationPoints: epModels })
      )

      this.addedPoints.length = 0
    }

    this.obj3d.remove(this.nextLineVisual)
  }

  /*
    This is used to approximate gable geometry by stretching the roof geometry over gable areas
    in special cases. This is possible because we use a constrained Delaunay triangulation, where
    the constraints are the roof edges and the points being triangulated are elevation points. If
    we minutely shift the elevation points belonging to certain classes of elevation lines, we can
    create gaps between the edge constraints and the points, over which triangles will extend.

    At the moment it only works for elevation lines comprised of two points. It is intended to create
    gables for either sloped roofs, or roofs with a single central peak.

    WARNING: doing this creates slight innacuracies in the roof geometry, both in the total roof volume
    and in the position of the interior gable surface. Additionally, if a roof has a peak which is not
    perfectly centered, it will be shifted, so it will not be in the exact position specified by the user.
    It's unclear whether our accuracy requirements will be tight enough for this to be problematic.
  */
  shiftLinePointsToGenerateGables() {
    const elevationPointShiftDistance = Units.inchesToNative(1.05)

    if (this.addedPoints.length === 2) {
      const globalAddedPoints = this.addedPoints.map(point =>
        point.position.clone().add(this.roof.obj3d.position)
      )

      const lineVec = globalAddedPoints[1].clone().sub(globalAddedPoints[0])
      const contractionVec = lineVec
        .clone()
        .normalize()
        .multiplyScalar(elevationPointShiftDistance)

      // Contract elevation line at both ends
      this.addedPoints[0].position.add(contractionVec)
      this.addedPoints[1].position.addScaledVector(contractionVec, -1)

      const centroid = Util.polygonCentroid(this.roof.perimeterPoints)
      const midPoint = globalAddedPoints[0]
        .clone()
        .add(lineVec.clone().multiplyScalar(0.5))
      const midpointToCentroid = centroid
        .clone()
        .sub(midPoint)
        .normalize()

      // Used to shift an elevation line from one edge of a sloped roof slightly
      // toward the center of the roof. The direction is orthogonal to the elevation line
      // itself, but it's ambiguous what 'sign' the shift should have, so
      // we use the position of the roof centroid to figure out whether an inversion
      // of direction is necessary.
      const centerwardShiftVec = lineVec
        .clone()
        .normalize()
        .applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2)

      if (centerwardShiftVec.clone().dot(midpointToCentroid) < 0) {
        centerwardShiftVec.multiplyScalar(-1)
      }

      centerwardShiftVec.multiplyScalar(elevationPointShiftDistance)

      this.addedPoints[0].position.add(centerwardShiftVec)
      this.addedPoints[1].position.add(centerwardShiftVec)
    }
  }

  updateNextLineVisual() {
    if (this.roof) {
      this.obj3d.remove(this.nextLineVisual)
      this.nextLineVisual = new THREE.Object3D()
      const roofPos = this.roof.obj3d.position.clone()

      for (let i = 0; i < this.addedPoints.length; i += 1) {
        const lineStartPos = this.addedPoints[i].position.clone().add(roofPos)
        let lineEndPos =
          i < this.addedPoints.length - 1
            ? this.addedPoints[i + 1].position.clone().add(roofPos)
            : this.lastSnappedMousePos

        // Set line end height the same as the start line height
        lineEndPos.set(lineEndPos.x, lineEndPos.y, lineStartPos.z)

        const lineLength = lineEndPos
          .clone()
          .sub(lineStartPos)
          .length()
        if (lineLength > Units.inchesToNative(1)) {
          const tube = Primitives.getTubeMesh(
            lineStartPos,
            lineEndPos,
            COLOR,
            8
          )
          tube.material.opacity = 1
          tube.material.depthTest = true
          this.nextLineVisual.add(tube)
        }
      }

      this.obj3d.add(this.nextLineVisual)
    }
  }

  getSnapRegions(facility, draggedObject) {
    if (!isSnapEnabled()) return []

    let snapRegions = []

    if (!this.lastSceneIntersectionPoint) return []

    const cursorPoint = this.lastSceneIntersectionPoint
    const roof = cursorPoint
      ? RoofToolUtil.getRoofUnderCursor(cursorPoint)
      : null

    if (roof) {
      snapRegions = snapRegions.concat(
        SnapQueries.getEdgeMidpointLinesForRoof(roof)
      )

      snapRegions = snapRegions.concat(
        SnapQueries.getElevationPointLinesForRoof(roof)
      )

      snapRegions = snapRegions.concat(
        SnapQueries.getPerimeterPointsForRoof(roof)
      )
    }

    const filteredSnapRegions = SnapQueries.getFilteredSnapRegions(snapRegions)

    return filteredSnapRegions
  }

  getOrthoReferencePoint() {
    if (this.startedLine) {
      return this.lastEPPosition
    } else {
      return null
    }
  }

  getArrowDescriptions() {}
}

export default ElevationLineTool
