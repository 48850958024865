import get from 'lodash-es/get'

import Facility from './facility'
import Wall from './wall'
import Product from './product'
import Util from './util'
import Roof from './roof'
import Airflow from './airflow'
import CFD, {
  HIGH_VELOCITY_COLORS,
  LOW_VELOCITY_COLORS,
  HEAT_MAP_COLORS,
} from './cfd'
import UtilityBox from './utilityBox'
import RoofSection from './roofSection'
import Obstruction from './obstruction'
import ComfortPoint from './comfortPoint'
import ComfortZone from './comfortZone'
import ElevationPoint from './elevationPoint'
import ElevationLine from './elevationLine'
import WallSegment from './wallSegment'
import Ceiling from './ceiling'
import MetadataIcon from './metadataIcon'
import GridBox from './gridBox'
import store from 'store'
import { getSelectedObjects } from 'store/selectedObjects/selectors'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import { primaryUseVelocityTypes } from 'config/facility'
import HeatMap from './heatMap'

class SceneBuilder {
  constructor(currentState) {
    this.currentState = currentState
    this.objectGroupData = {
      exteriorWalls: {
        layerKey: LAYER_KEYS.EXTERIOR_WALLS,
        className: CLASS_NAMES.WALL,
        _class: Wall,
        updateFunctionName: 'updateWallFunction',
      },
      interiorWalls: {
        layerKey: LAYER_KEYS.INTERIOR_WALLS,
        className: CLASS_NAMES.WALL,
        _class: Wall,
        updateFunctionName: 'updateWallFunction',
      },
      exteriorWallSegments: {
        layerKey: LAYER_KEYS.EXTERIOR_WALLS,
        className: CLASS_NAMES.WALL_SEGMENT,
        _class: WallSegment,
        addFunctionName: 'wallSegmentChanged',
        removeFunctionName: 'wallSegmentChanged',
        updateFunctionName: 'wallSegmentChanged',
      },
      interiorWallSegments: {
        layerKey: LAYER_KEYS.INTERIOR_WALLS,
        className: CLASS_NAMES.WALL_SEGMENT,
        _class: WallSegment,
        addFunctionName: 'wallSegmentChanged',
        removeFunctionName: 'wallSegmentChanged',
        updateFunctionName: 'wallSegmentChanged',
      },
      roofs: {
        layerKey: LAYER_KEYS.ROOFS,
        className: CLASS_NAMES.ROOF,
        _class: Roof,
      },
      roofSections: {
        layerKey: LAYER_KEYS.ROOF_SECTIONS,
        className: CLASS_NAMES.ROOF_SECTION,
        _class: RoofSection,
      },
      elevationPoints: {
        layerKey: LAYER_KEYS.ELEVATION_POINT,
        className: CLASS_NAMES.ELEVATION_POINT,
        _class: ElevationPoint,
        addFunctionName: 'elevationMarkerChanged',
        removeFunctionName: 'elevationMarkerChanged',
        updateFunctionName: 'elevationMarkerChanged',
      },
      elevationLines: {
        layerKey: LAYER_KEYS.ELEVATION_LINE,
        className: CLASS_NAMES.ELEVATION_LINE,
        _class: ElevationLine,
        addFunctionName: 'elevationMarkerChanged',
        removeFunctionName: 'elevationMarkerChanged',
        updateFunctionName: 'elevationMarkerChanged',
      },
      obstructions: {
        layerKey: LAYER_KEYS.OBSTRUCTIONS,
        className: CLASS_NAMES.OBSTRUCTION,
        _class: Obstruction,
      },
      utilityBoxes: {
        layerKey: LAYER_KEYS.UTILITY_BOXES,
        className: CLASS_NAMES.UTILITY_BOX,
        _class: UtilityBox,
      },
      comfortPoints: {
        layerKey: LAYER_KEYS.COMFORT_POINTS,
        className: CLASS_NAMES.COMFORT_POINT,
        _class: ComfortPoint,
      },
      comfortZones: {
        layerKey: LAYER_KEYS.COMFORT_ZONES,
        className: CLASS_NAMES.COMFORT_ZONE,
        _class: ComfortZone,
      },
      products: {
        layerKey: LAYER_KEYS.PRODUCTS,
        className: CLASS_NAMES.PRODUCT,
        _class: Product,
      },
      cfd: {
        layerKey: LAYER_KEYS.CFD,
        className: CLASS_NAMES.CFD,
        _class: CFD,
        addFunctionName: 'updateCFDFunction',
        updateFunctionName: 'updateCFDFunction',
        removeFunctionName: 'removeCFDFunction',
      },
      ceilings: {
        layerKey: LAYER_KEYS.CEILINGS,
        className: CLASS_NAMES.CEILING,
        _class: Ceiling,
      },
      airflow: {
        layerKey: LAYER_KEYS.AIRFLOW,
        className: CLASS_NAMES.AIRFLOW,
        _class: Airflow,
        addFunctionName: 'updateAirflowFunction',
        updateFunctionName: 'updateAirflowFunction',
        removeFunctionName: 'removeAirflowFunction',
      },
      airflowData: {
        layerKey: LAYER_KEYS.AIRFLOW,
        className: CLASS_NAMES.AIRFLOW_DATA,
        _class: Airflow,
        addFunctionName: 'updateAirflowDataFunction',
        updateFunctionName: 'updateAirflowDataFunction',
        removeFunctionName: 'updateAirflowDataFunction',
      },
      gridBox: {
        layerKey: LAYER_KEYS.GRID_BOX,
        className: CLASS_NAMES.GRID_BOX,
        _class: GridBox,
      },
      heatMap: {
        layerKey: LAYER_KEYS.HEAT_MAP,
        className: CLASS_NAMES.HEAT_MAP,
        _class: HeatMap,
        addFunctionName: 'updateHeatMapFunction',
        updateFunctionName: 'updateHeatMapFunction',
        removeFunctionName: 'removeHeatMapFunction',
      },
    }

    this.metadataIcons = []
  }

  //////////////////
  // Core functions
  ////////////////

  /*
        Uses diffs of visible objects in the current state with previous state in order to determine
        which objects need to be added, re-constructed, or removed from the scene.
    */
  rebuild(currentState) {
    this.currentState = currentState

    // For cases where a property changes that references another object
    // (rather than the modified property being on the object itself), add
    // that property to `referenceProps`. If any of the Ids in referenceProps
    // change, the referenced object will be rebuilt.
    const referenceProps = getSelectedObjects(currentState).map(o => o.id)
    this.modifiedObjectReferences = this.findModifiedObjectReferences(
      referenceProps
    )

    const allObjects = this.getAllObjects(currentState)
    const layers = currentState.layers
    const units = currentState.units

    this.updateScene(allObjects, layers, units)
  }

  unload() {
    // Unload Obstructions
    const allObstructions = Facility.current.getObstructions()
    allObstructions.forEach(obstruction => {
      obstruction.destroy()
    })

    // Unload Products
    const allProducts = Facility.current.getProducts()
    allProducts.forEach(product => {
      product.destroy()
    })

    const allComfortZones = Facility.current.getComfortZones()
    allComfortZones.forEach(comfortZone => {
      comfortZone.destroy()
    })

    // Destroy all metadata icons
    this.metadataIcons.forEach(mdi => mdi.destroy())
  }

  getAllObjects(currentState) {
    const walls = currentState.objects
    const segments = currentState.segments

    const exteriorWalls = {}
    const interiorWalls = {}
    const exteriorWallSegments = {}
    const interiorWallSegments = {}
    Object.keys(walls).forEach(key => {
      const wall = walls[key]
      if (wall.layerKey === LAYER_KEYS.EXTERIOR_WALLS) {
        exteriorWalls[wall.id] = wall
        wall.segments.forEach(key => {
          exteriorWallSegments[key] = { ...segments[key], parentId: wall.id }
        })
      } else {
        interiorWalls[wall.id] = wall
        wall.segments.forEach(key => {
          interiorWallSegments[key] = { ...segments[key], parentId: wall.id }
        })
      }
    })
    const selectedLayer = get(currentState, 'cfd.selectedLayer')
    const goal = get(selectedLayer, 'goal')
    const type = get(selectedLayer, 'type')
    const level = get(selectedLayer, 'level')
    const cfdModels = get(currentState, 'cfd.models')
    const cfdModel = get(cfdModels, `${goal}.${type}.${level}`)
    const cfdGrid = get(currentState, 'cfd.grid')

    const objects = {
      exteriorWalls,
      interiorWalls,
      exteriorWallSegments,
      interiorWallSegments,
      products: currentState.products,
      roofs: currentState.roofs,
      utilityBoxes: currentState.utilityBoxes,
      roofSections: currentState.roofSections,
      obstructions: currentState.obstructions,
      comfortPoints: currentState.comfortPoints,
      comfortZones: currentState.comfortZones,
      elevationPoints: currentState.elevationPoints,
      elevationLines: currentState.elevationLines,
      ceilings: currentState.ceilings,
      airflow: { data: currentState.airflow.selectedLayer },
      airflowData: { data: currentState.airflow.data },
      backgroundImage: currentState.backgroundImage,
      gridBox: currentState.gridBox,
      cfd: {
        data: {
          model: cfdModel,
          selectedLayer,
        },
      },
      cfdGrid: {
        data: {
          grid: cfdGrid,
          model: selectedLayer.type === 'overhead-fpm' && cfdModel,
          level,
        },
      },
      heatMap: { data: currentState.heatMap },
    }

    // Ensure that no layerKey of objects is null/undefined
    Object.keys(objects).forEach(key => {
      if (!objects[key]) {
        objects[key] = {}
      }
    })

    return objects
  }

  updateScene(allObjects, layers, units) {
    this.triggerPreBuildEvents(this.previousModifiedObjects)
    const modifiedObjects = {
      added: [],
      updated: [],
      removed: [],
    }

    Object.keys(this.objectGroupData).forEach(key => {
      const groupData = this.objectGroupData[key]
      const { _class, layerKey, className, cachedData } = groupData
      const uniqueTypeName = Util.capitalizeFirstLetter(key)
      const objects = allObjects[key]

      const diff = Util.simpleObjectDiff(
        this[`previous${uniqueTypeName}s`],
        objects
      )

      // Used to update objects in the scene pointed to by references,
      // e.g. the object pointed to by 'selectedObjectId'
      diff.updated = diff.updated.concat(
        this.modifiedObjectReferences
          .map(id => objects[id])
          .filter(obj => obj !== undefined && !diff.added.includes(obj))
      )

      const getDenormalizedModel = this.getDenormalizedModelFunction(_class)

      const addFunction = this.getAddFunctionForType(
        className,
        groupData.addFunctionName
      )
      const updateFunction = this.getUpdateFunctionForType(
        className,
        groupData.updateFunctionName
      )
      const removeFunction = this.getRemoveFunctionForType(
        className,
        groupData.removeFunctionName
      )

      diff.added.forEach(model => {
        const denormModel = getDenormalizedModel(model)
        addFunction(denormModel, units, _class, className, layerKey, cachedData)
        modifiedObjects.added.push(denormModel)
      })

      diff.updated.forEach(model => {
        const denormModel = getDenormalizedModel(model)
        updateFunction(
          denormModel,
          units,
          _class,
          className,
          layerKey,
          cachedData
        )
        if (!this.modifiedObjectReferences.includes(denormModel.id)) {
          modifiedObjects.updated.push(denormModel)
        }
      })

      diff.removed.forEach(model => {
        const denormModel = getDenormalizedModel(model)
        removeFunction(denormModel, units, _class, className, layerKey)
        modifiedObjects.removed.push(denormModel)
      })

      // Store objects so we diff with it on next build
      this[`previous${uniqueTypeName}s`] = objects
    })

    this.triggerVisibilityEvents(layers)
    this.triggerPostBuildEvents(modifiedObjects)

    this.previousModifiedObjects = modifiedObjects
  }

  triggerVisibilityEvents(layers) {
    const changedLayers = []
    Object.keys(layers).forEach(key => {
      const prevIsVisible = get(this, `previousLayers[${key}].visible`)
      const isVisible = get(layers, `[${key}].visible`)
      if (!this.previousLayers || prevIsVisible !== isVisible) {
        changedLayers.push(key)
      }
    })

    Facility.current.getAllObjects().forEach(obj => {
      const objLayer = layers[obj.layerKey]
      const layerChanged =
        changedLayers.includes(obj.layerKey) ||
        (get(objLayer, 'children') || []).some(c => changedLayers.includes(c))

      if (objLayer && layerChanged && obj.visibilityChanged) {
        obj.visibilityChanged(objLayer.visible)
      }
    })

    this.metadataIcons.forEach(icon => {
      icon.updateVisibility(changedLayers)
    })

    this.previousLayers = layers
  }

  /*
    Given a layerKey, if it has an associated layer, return whether
    the layer is visible or not. If the layerKey has no layer, return true.
  */
  isLayerKeyVisible(layerKey) {
    const layerExists = this.currentState.layers[layerKey] !== undefined
    const value = !layerExists || this.currentState.layers[layerKey].visible
    return value
  }

  triggerPreBuildEvents(previousModifiedObjects) {
    const facility = Facility.current

    facility.getAllObjects().forEach(obj => {
      if (obj.sceneWillRebuild) {
        obj.sceneWillRebuild(
          facility,
          this.objectWasModified(previousModifiedObjects, obj)
        )
      }
    })
  }

  triggerPostBuildEvents(modifiedObjects) {
    const facility = Facility.current
    const mergedModifiedObjects = Object.values(modifiedObjects).reduce(
      (merged, arr) => merged.concat(arr),
      []
    )

    if (!mergedModifiedObjects.length) return

    const modifiedCategories = new Set(
      mergedModifiedObjects.map(obj => obj.layerKey)
    )

    facility.getAllObjects().forEach(obj => {
      if (obj.sceneDidRebuild) {
        obj.sceneDidRebuild(
          facility,
          this.objectWasModified(mergedModifiedObjects, obj)
        )
      }
    })

    this.updateMetadataIcons(modifiedObjects)
    this.updatePerspectiveChange()

    facility.sceneDidRebuild(modifiedCategories, modifiedObjects)
  }

  updatePerspectiveChange() {
  }

  updateMetadataIcons(modifiedObjects) {
    const metadataIconIds = this.metadataIcons.map(icon => icon.objectId)

    modifiedObjects.added.forEach(obj => {
      if (
        MetadataIcon.hasMetadata(obj.metadata) &&
        !metadataIconIds.includes(obj.id)
      ) {
        this.metadataIcons.push(
          new MetadataIcon(obj, { history: this.currentState.history })
        )
      }
    })

    modifiedObjects.updated.forEach(obj => {
      const icon = this.metadataIcons.find(i => i.objectId === obj.id)
      if (icon) {
        icon.update(obj)
      } else if (MetadataIcon.hasMetadata(obj.metadata)) {
        this.metadataIcons.push(
          new MetadataIcon(obj, { history: this.currentState.history })
        )
      }
    })

    modifiedObjects.removed.forEach(obj => {
      const icon = this.metadataIcons.find(i => i.objectId === obj.id)
      if (icon) {
        icon.destroy()
      }
    })
  }

  objectWasModified(modifiedObjects, obj) {
    if (!modifiedObjects) return false

    return (
      modifiedObjects.find(changedObj => obj.id === changedObj.id) !== undefined
    )
  }

  findModifiedObjectReferences(referencedObjectIds) {
    let modifiedIds = []

    if (!this.previousReferencedObjectIds) this.previousReferencedObjectIds = []

    referencedObjectIds.forEach(id => {
      if (!this.previousReferencedObjectIds.find(prevId => prevId === id))
        modifiedIds.push(id)
    })

    this.previousReferencedObjectIds.forEach(prevId => {
      if (!referencedObjectIds.find(id => id === prevId))
        modifiedIds.push(prevId)
    })

    modifiedIds = modifiedIds.filter(id => id !== null && id !== undefined)

    this.previousReferencedObjectIds = referencedObjectIds

    return modifiedIds
  }

  //////////////////
  // Default type-agnostic functions
  ////////////////

  getAddFunctionForType(className, customFunctionName) {
    if (customFunctionName) return this[customFunctionName].bind(this)
    else return this.defaultAddFunction.bind(this)
  }

  getUpdateFunctionForType(className, customFunctionName) {
    if (customFunctionName) return this[customFunctionName].bind(this)
    else return this.defaultUpdateFunction.bind(this)
  }

  getRemoveFunctionForType(className, customFunctionName) {
    if (customFunctionName) return this[customFunctionName].bind(this)
    else return this.defaultRemoveFunction.bind(this)
  }

  getDenormalizedModelFunction(_class) {
    if (_class.getDenormalizedModel) return _class.getDenormalizedModel
    else return this.defaultGetDenormalizedModel
  }

  defaultAddFunction(model, units, _class, className, layerKey) {
    if (SceneBuilder.validateModel(model, _class)) {
      // TODO: These should be named props at least
      const obj = new _class(model, units)
      if (obj.visibilityChanged) {
        obj.visibilityChanged(this.isLayerKeyVisible(layerKey))
      }

      Facility.current[`add${className}`](obj)
    }
  }

  defaultRemoveFunction(model, units, _class, className, layerKey) {
    Facility.current[`remove${className}WithId`](model.id)
  }

  defaultUpdateFunction(model, units, _class, className, layerKey) {
    this.defaultRemoveFunction(model, units, _class, className, layerKey)
    this.defaultAddFunction(model, units, _class, className, layerKey)
  }

  // Do nothing by default; we only need to denormalize when the model
  // references child models.
  defaultGetDenormalizedModel(model) {
    return model
  }

  //////////////////
  // Custom functions for specific types
  ////////////////

  updateWallFunction(model, units, _class, className, layerKey) {
    Facility.current.removeWallWithId(model.id)

    const wall = new Wall(model, units)
    wall.visibilityChanged(this.isLayerKeyVisible(layerKey))
    Facility.current.addWall(wall)
  }

  createCFD(geometry, units, layerKey, type, level) {
    if (!geometry) {
      return
    }

    const isVisible = this.isLayerKeyVisible(layerKey)

    const facility = get(this, 'currentState.facility')

    const cfdObj = new CFD(geometry, units, type, level, facility)
    cfdObj.visibilityChanged(isVisible)

    Facility.current.addCFD(cfdObj)
  }

  updateCFDFunction(data, units, _class, className, layerKey) {
    if (data) {
      Facility.current.removeCFD()
      Facility.current.removeCFDGrid()
    }
    const level = get(data, 'selectedLayer.level')
    const type = get(data, 'selectedLayer.type')
    const geometry = get(data, 'model.geometry')

    if (geometry && level && type) {
      this.createCFD(geometry, units, layerKey, type, level)
    }
  }

  removeCFDFunction(model, units, _class, className, layerKey) {
    if (model) {
      Facility.current.removeCFD()
      Facility.current.removeCFDGrid()
    }
  }

  updateAirflowFunction(model, units, _class, className, layerKey) {
    if (model) {
      Facility.current.removeAirflow()
      const colors = this.getVelocityColorScheme()
      const airflow = new Airflow(model, units, colors)
      airflow.visibilityChanged(this.isLayerKeyVisible(layerKey))
      Facility.current.addAirflow(airflow)
    }
  }

  // NOTE: Shouldn't these functions exist at the class
  // it deals with?
  removeAirflowFunction(model, units, _class, className, layerKey) {
    if (model) Facility.current.removeAirflow()
  }

  updateAirflowDataFunction(model, units, _class, className, layerKey) {
    if (model) {
      const selected = store.getState().objects.present.airflow.selectedLayer

      if (!selected) return

      const selectedModel = model.filter(
        m => m.evaluationHeight === selected.evaluationHeight
      )

      Facility.current.removeAirflow()

      selectedModel.forEach(m => {
        const airflow = new Airflow(m, units, this.getVelocityColorScheme())
        airflow.visibilityChanged(this.isLayerKeyVisible(layerKey))
        Facility.current.addAirflow(airflow)
      })
    }
  }

  getVelocityColorScheme() {
    let colors = HIGH_VELOCITY_COLORS
    const primaryUse = get(this, 'currentState.facility.primaryUse')
    if (primaryUse === 'OTHER') {
      const primaryType = get(this, 'currentState.facility.primaryType')
      if (primaryType === 'RESIDENTIAL' || primaryType === 'COMMERCIAL') {
        colors = LOW_VELOCITY_COLORS
      }
    } else if (primaryUseVelocityTypes.low.includes(primaryUse)) {
      colors = LOW_VELOCITY_COLORS
    }
    return colors
  }

  elevationMarkerChanged(emModel, units, _class, className, layerKey) {
    const normalizedRoofModel = this.currentState.roofs[emModel.roofId]

    if (normalizedRoofModel) {
      const roofModel = Roof.getDenormalizedModel(normalizedRoofModel)
      this['defaultUpdateFunction'](roofModel, units, Roof, CLASS_NAMES.ROOF)
    }
  }

  wallSegmentChanged(segmentModel, units, _class, className, layerKey) {
    if (!this.currentState.layers[layerKey].visible) return

    const normalizedWallModel = this.currentState.objects[segmentModel.parentId]

    if (normalizedWallModel) {
      const wallModel = Wall.getDenormalizedModel(normalizedWallModel)
      this['updateWallFunction'](wallModel, units, Wall, CLASS_NAMES.WALL)
    }
  }

  removeBackgroundImageFunction(model, units, _class, className, layerKey) {
    if (model) Facility.current.removeBackgroundImage()
  }

  updateHeatMapFunction(model, units, _class, className, layerKey) {
    if (model) {
      Facility.current.removeHeatMap()
      const heatMap = new HeatMap(model, units, HEAT_MAP_COLORS)
      heatMap.visibilityChanged(this.isLayerKeyVisible(layerKey))
      Facility.current.addHeatMap(heatMap)
    }
  }

  removeHeatMapFunction(model, units, _class, className, layerKey) {
    if (model) Facility.current.removeHeatMap()
  }

  //////////////////
  // Miscellaneous
  ////////////////

  static validateModel(model, _class) {
    return !_class.isModelValid || _class.isModelValid(model)
  }
}

export default SceneBuilder
