import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/UIKit/Icon'
import { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'

import Content from './styled/Content'
import Description from './styled/Description'

class EmptyPanelSection extends Component {
  static propTypes = {
    icon: PropTypes.string,
    children: PropTypes.string,
  }

  render() {
    const { icon, children } = this.props
    return (
      <PanelSection>
        <Content>
          <div>
            {icon && (
              <Space bottom="base">
                <Icon name={icon} size="30px" color="subdued" />
              </Space>
            )}
            <Description>{children}</Description>
          </div>
        </Content>
      </PanelSection>
    )
  }
}

export default EmptyPanelSection
