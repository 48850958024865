import { isLowVelocityFacility } from '~/config/cfd'
import { FacilityDefaults } from '~/hooks/useFacilityDefaults'
import { Upload, Vector } from './types'
import { BUILDING_JSON, MODEL_OBJ } from '../constants/filenames'
import { MIME_TYPES } from '../constants/mimeTypes'
import { UNITS } from '../constants/units'

type GetBuildingJsonArgs = {
  facilityDefaults: FacilityDefaults
  locations: Vector[]
  planeHeights: number[]
  generateAirVelocityProfile: boolean
}

export const getBuildingJson = ({
  facilityDefaults: { primaryUse, primaryType },
  generateAirVelocityProfile,
  locations,
  planeHeights,
}: GetBuildingJsonArgs): Upload => {
  const { maxU, mapU } = isLowVelocityFacility({ primaryUse, primaryType })
    ? {
        maxU: 168,
        mapU: 'bas25',
      }
    : {
        maxU: 308, // Max value of the color scale in CFD generated images
        mapU: 'bas31', // Point on color scale where colors begin to transition from red to blue
      }

  const buildingInput = {
    units: UNITS,
    file: MODEL_OBJ,
    locations,
    mapU,
    maxU,
    planeHeights,
    ...(generateAirVelocityProfile ? { tableData: true } : {}),
  }

  return {
    file: JSON.stringify(buildingInput),
    fileName: BUILDING_JSON,
    MIME: MIME_TYPES.json,
  }
}
