import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from 'components/UIKit/Stack'
import Icon from 'components/UIKit/Icon'

const iconName = {
  success: 'check',
  warn: 'warn',
  error: 'obstruction'
}

const Alert = ({ label, severity = 'error', ...stackProps }) => {
  return (
    <Stack direction="row" spacing={1} padding={1} {...stackProps}>
      <Icon name={iconName[severity] ?? iconName.error} color="error" />
      {label}
    </Stack>
  )
}

Alert.propTypes = {
  label: PropTypes.string,
  severity: PropTypes.oneOf(['success', 'warning', 'error']),
}

export { Alert }
