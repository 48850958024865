import React from 'react'
import { func, object } from 'prop-types'
import get from 'lodash-es/get'

import { DefaultQueryProvider } from 'client/providers'
import { GET_AR_IMAGES_QUERY } from 'client/queries'

import { GridItem } from 'components/UIKit/Grid'
import Loader from 'components/UIKit/Loader'

import Container from './styled/Container'
import Thumbnail from './styled/Thumbnail'

function ImageGrid({ product, handleSelect }) {
  return (
    <DefaultQueryProvider
      query={GET_AR_IMAGES_QUERY}
      passThrough={true}
      variables={{
        catalog: product.catalog,
        model: product.model,
        size: product.size,
      }}
    >
      {({ loading, data }) => {
        // Filter out any product folder paths
        const images = get(data, 'ARImages')
        const validImages =
          images && images.filter(img => img.url.slice(-1) !== '/')

        const filteredImages =
          validImages &&
          validImages.filter(image => {
            if (product.model.indexOf('Plus') === -1) {
              return image.url.indexOf('Plus') === -1
            }

            return image
          })

        return (
          <Container loading={loading}>
            {loading ? (
              <Loader />
            ) : (
              filteredImages.map(({ url }) => (
                <GridItem size="1of3" key={url}>
                  <Thumbnail src={url} onClick={() => handleSelect(url)} />
                </GridItem>
              ))
            )}
          </Container>
        )
      }}
    </DefaultQueryProvider>
  )
}

ImageGrid.propTypes = {
  product: object,
  handleSelect: func,
}

export default ImageGrid
