import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { graphql } from '@apollo/client/react/hoc'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import get from 'lodash-es/get'
import PropTypes from 'prop-types'
import isEmpty from 'lodash-es/isEmpty'
import truncate from 'lodash-es/truncate'
import branch from 'recompact/branch'
import renderNothing from 'recompact/renderNothing'

import cloudinary from 'config/cloudinary'
import routes from 'config/routes'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'

import { GET_QUOTE_PANEL } from 'store/panel/types'
import { selectObjects, deselectObjects } from 'store/selectedObjects/selectors'
import { saveObjects } from 'store/objectsPersistence'
import {
  initBundles,
  addBundle,
  getGroupedProductVariations,
  clearOpportunity,
  updateQuoteName,
} from 'store/quote'
import { updateProduct } from 'store/objects'
import { showAlert } from 'store/alert'

import { GET_QUOTE_QUERY } from 'client/queries'

import Panel, { PanelSection } from 'components/UIKit/Panel'
import Bucket from 'components/UIKit/Bucket'
import Checkbox from 'components/UIKit/Checkbox'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Image from 'components/UIKit/Image'
import Select from 'components/UIKit/Select'
import TextField from 'components/UIKit/TextField'
import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'
import Icon from 'components/UIKit/Icon'
import OpportunitySelect from 'components/OpportunitySelect'

import EmptyMessage from '../styled/EmptyMessage'
import SaveQuoteButton from '../SaveQuoteButton'

const LETTERS = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]

class ProductsScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name:
        get(props, 'quoteData.Version.quote.name') ||
        get(props, 'name') ||
        props.facility.name,
      opportunityId: get(props, 'quoteData.Version.quote.opportunity.id', ''),
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.quoteData &&
      this.props.quoteData.loading &&
      !nextProps.quoteData.loading
    ) {
      this.setState({
        name:
          get(nextProps, 'quoteData.Version.quote.name') ||
          get(nextProps, 'name') ||
          '',
        opportunityId: get(
          nextProps,
          'quoteData.Version.quote.opportunity.id',
          ''
        ),
      })
    }
  }

  componentDidMount() {
    this.props.onLoad()

    if (Object.keys(this.props.bundles).length < 1) {
      const bundles = {}
      const groupedVariations = getGroupedProductVariations(
        this.props.productVariations
      )
      Object.keys(groupedVariations).forEach((groupId, index) => {
        bundles[groupId] = [
          {
            name: `Bundle ${LETTERS[index].toUpperCase()}-1`,
          },
        ]
      })
      this.props.onInitBundles(bundles)
    }
  }

  getOpportunity = () => {
    const { opportunity } = this.props
    const opportunityFromDataSource = get(
      this.props,
      'quoteData.Version.quote.opportunity',
      false
    )

    if (!isEmpty(opportunity)) {
      return opportunity
    }

    if (opportunityFromDataSource) {
      return opportunityFromDataSource
    }

    return null
  }

  handleChangeName = event => {
    this.setState({
      name: event.target.value,
    })
    if (this.props.onNameChanged) this.props.onNameChanged(event.target.value)
  }

  handleSelect = variation => {
    selectObjects({
      objects: [
        {
          id: variation.id,
          className: CLASS_NAMES.PRODUCT,
          layerKey: LAYER_KEYS.PRODUCTS,
        },
      ],
      ignorePanel: true,
    })
  }

  handleDeselect = variation => {
    deselectObjects({
      objects: [
        {
          id: variation.id,
          className: CLASS_NAMES.PRODUCT,
          layerKey: LAYER_KEYS.PRODUCTS,
        },
      ],
      ignorePanel: true,
    })
  }

  handleToggleProduct = (event, variation) => {
    this.props.onUpdateProduct({
      ...variation,
      includedInQuote: !variation.includedInQuote,
    })
  }

  handleSelectBundle = (event, variation, groupId) => {
    if (event.target.value === 'Add Bundle') {
      const name = prompt('Bundle name')
      if (name) {
        this.props.onAddBundle(groupId, { name })
        this.props.onUpdateProduct({
          ...variation,
          quoteBundle: name, // NOTE: The select uses name as value and label
        })
      }
      return
    }
    this.props.onUpdateProduct({
      ...variation,
      quoteBundle: event.target.value,
    })
  }

  renderProductHeading(productVariation, index) {
    return (
      <Bucket
        media={
          <Image
            src={cloudinary.url(
              `products/${productVariation.product.cloudinaryId}.jpg`,
              {
                width: 100,
                height: 100,
                secure: true,
              }
            )}
            alt="placeholder"
            width="50"
            height="50"
          />
        }
        content={
          <div>
            <h3>{productVariation.product.model}</h3>
            <VariantText size="s">
              {`${Math.round(productVariation.size / 12)}'`},{' '}
              {productVariation.voltage},{' '}
              {`${Math.round(productVariation.tubeLength / 12)}'`} Tube
            </VariantText>
          </div>
        }
      />
    )
  }

  renderProductVariations(productVariations, groupIndex, groupId) {
    let options = []
    if (
      Object.keys(this.props.bundles).length > 0 &&
      this.props.bundles[groupId]
    ) {
      options = this.props.bundles[groupId]
        .filter(b => b.name !== undefined) // Remove undefined bundles
        .map(bundle => ({
          label: bundle.name,
          value: bundle.name,
        }))
    }
    options.push({
      label: 'Add Bundle',
      value: 'Add Bundle',
    })
    return productVariations.map((variation, index) => (
      <Space key={variation.id} bottom="s">
        <Grid
          onMouseOver={() => this.handleSelect(variation)}
          onMouseOut={() => this.handleDeselect(variation)}
          vCenter
        >
          <GridItem size="1of3">
            <Checkbox
              name={variation.id}
              value={variation.id}
              checked={variation.includedInQuote}
              onChange={event => this.handleToggleProduct(event, variation)}
              label={`Fan ${index + 1}`}
            />
          </GridItem>
          <GridItem size="2of3">
            <Select
              size="100%"
              onChange={event =>
                this.handleSelectBundle(event, variation, groupId)
              }
              value={variation.quoteBundle}
              options={options}
            />
          </GridItem>
        </Grid>
      </Space>
    ))
  }

  render() {
    const { alignment } = this.props
    const groupedVariations = getGroupedProductVariations(
      this.props.productVariations
    )
    const opportunity = this.getOpportunity()

    return (
      <Panel
        title="Choose Products"
        alignment={alignment}
        docked
        collapsible={false}
        scrollable
        panelKey={GET_QUOTE_PANEL}
        footer={
          <SaveQuoteButton
            versionId={get(this.props.facility, 'floor.version.id')}
            onQuoteSaved={this.props.onNextScreen}
            onError={err => {
              if (this.props.onShowAlert)
                this.props.onShowAlert(
                  `Salesforce Error: ${truncate(err.message, { length: 16 })}`
                )
            }}
          />
        }
      >
        <PanelSection>
          <Space bottom="s">
            <TextField
              label="Quote Name"
              value={this.state.name}
              onChange={this.handleChangeName}
              disabled={get(this.props, 'quoteData.loading')}
            />
          </Space>
          <OpportunitySelect
            truncate="200px"
            url={`${this.props.match.url}${routes.modals.selectOpportunity}`}
            selectedOpportunity={opportunity ? opportunity.name : ''}
            canClear={false}
          />
        </PanelSection>
        {Object.keys(groupedVariations).length === 0 && (
          <EmptyMessage>
            <Space bottom="base">
              <Icon name="plus" size="30px" color="subdued" />
            </Space>
            Add products to generate a quote.
          </EmptyMessage>
        )}
        {Object.keys(groupedVariations).map((groupId, index) => (
          <PanelSection
            title={`Product ${LETTERS[index].toUpperCase()}`}
            key={groupId}
          >
            <Space bottom="s">
              {this.renderProductHeading(groupedVariations[groupId][0], index)}
            </Space>
            <Grid>
              <GridItem size="1of3">
                <Space bottom="xs">
                  <VariantText size="s">Instance</VariantText>
                </Space>
              </GridItem>
              <GridItem size="2of3">
                <Space bottom="xs">
                  <VariantText size="s">Bundle</VariantText>
                </Space>
              </GridItem>
            </Grid>
            {this.renderProductVariations(
              groupedVariations[groupId],
              index,
              groupId
            )}
          </PanelSection>
        ))}
      </Panel>
    )
  }
}

ProductsScreen.propTypes = {
  alignment: PropTypes.string,
  bundles: PropTypes.object,
  facility: PropTypes.object,
  match: PropTypes.object,
  onAddBundle: PropTypes.func,
  onInitBundles: PropTypes.func,
  onLoad: PropTypes.func,
  onNameChanged: PropTypes.func,
  onNextScreen: PropTypes.func,
  onShowAlert: PropTypes.func,
  onUpdateProduct: PropTypes.func,
  opportunity: PropTypes.object,
  productVariations: PropTypes.object,
  quoteData: PropTypes.object,
}

const mapStateToProps = ({ quote, objects }) => {
  return {
    productVariations: objects.present.products,
    bundles: quote.bundles,
    opportunity: quote.opportunity,
    name: quote.name,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad() {
    dispatch(clearOpportunity())
    dispatch(
      updateQuoteName(
        get(ownProps, 'quoteData.Version.quote.name', ownProps.facility.name)
      )
    )
  },

  onUpdateProduct(product) {
    // TODO: We shouldn't be saving quote data
    // to objects. V3 will need to move away from this
    // as much as possible
    // and reflect bundles using the data in salesforce
    dispatch(updateProduct({ product }))
    dispatch(
      saveObjects({
        versionId: get(ownProps, 'facility.floor.version.id'),
        saveLayers: false,
      })
    )
  },

  onInitBundles(bundles) {
    dispatch(initBundles({ bundles }))
  },

  onAddBundle(groupId, bundle) {
    dispatch(addBundle({ groupId, bundle }))
  },

  onNameChanged(name) {
    dispatch(updateQuoteName(name))
  },

  onShowAlert(text) {
    dispatch(
      showAlert({
        text,
        type: 'error',
        autoClose: true,
      })
    )
  },
})

export default compose(
  branch(({ facility } = {}) => !facility, renderNothing),
  appConnect(mapStateToProps, mapDispatchToProps),
  graphql(GET_QUOTE_QUERY, {
    options: ({ facility }) => ({
      variables: {
        id: get(facility, 'floor.version.id'),
      },
    }),
    // skip: ({ facility }) => !get(facility, 'floor.version.quote.id'),
    name: 'quoteData',
  }),
  withRouter
)(ProductsScreen)
