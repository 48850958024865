export const SHOW_PANEL = 'SHOW_PANEL'
export const HIDE_PANEL = 'HIDE_PANEL'
export const TOGGLE_PANEL = 'TOGGLE_PANEL'
export const RESET_PANELS = 'RESET_PANELS'
export const SET_DEFAULT_PANEL = 'SET_DEFAULT_PANEL'

export const showPanel = payload => ({
  type: SHOW_PANEL,
  payload,
})

export const hidePanel = payload => ({
  type: HIDE_PANEL,
  payload,
})

export const togglePanel = payload => ({
  type: TOGGLE_PANEL,
  payload,
})

export const resetPanels = () => ({
  type: RESET_PANELS,
})

export const setDefaultPanel = payload => ({
  type: SET_DEFAULT_PANEL,
  payload,
})
