import Dashboard from 'components/Dashboard'
import PermissionDeniedRoute from 'components/PermissionDeniedRoute'
import ReassignFacilities from 'components/ReassignFacilities'
import RequiredPermission from 'components/RequiredPermission'
import { getTitle } from 'config/titles'
import React from 'react'
import { Helmet } from 'react-helmet'

export const ReassignFacilitiesScreen = () => {
  return (
    <>
      <Helmet>
        <title>{getTitle('Reassign')}</title>
      </Helmet>
      <Dashboard>
        <RequiredPermission
          name={'Reassign Facility'}
          FallbackComponent={PermissionDeniedRoute}
        >
          {/* <Tabs>
            <Tab title={'Facility Search'}>
              <div>test</div>
            </Tab>
            <Tab title={'User Search'}>
              <div>test</div>
            </Tab>
            <Tab title={'tab - 2'}>
              <div>test</div>
            </Tab>
          </Tabs>
          <div>Pending Requests</div> */}
          <ReassignFacilities />
        </RequiredPermission>
      </Dashboard>
    </>
  )
}

export default ReassignFacilitiesScreen
