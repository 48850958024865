import PropTypes from 'prop-types'
import styled from 'styled-components'

import theme, { Spacing } from 'config/theme'

const styles = (props: FlexItemProps) => {
  const s = theme.spacing

  let styles = ''

  if (props.marginBottom) styles += `margin-bottom: ${s[props.marginBottom]};`
  if (props.marginLeft) styles += `margin-left: ${s[props.marginLeft]};`
  if (props.marginRight) styles += `margin-right: ${s[props.marginRight]};`
  if (props.marginTop) styles += `margin-top: ${s[props.marginTop]};`

  if (props.width) styles += `width: ${props.width};`

  return styles
}

const Container = styled.div<FlexItemProps>`
  ${styles}
`

type FlexItemProps = {
  marginBottom?: Spacing
  marginLeft?: Spacing
  marginRight?: Spacing
  marginTop?: Spacing
  width?: string
}

export default Container
