import styled, { keyframes, css, ThemedStyledProps } from 'styled-components'
import get from 'lodash-es/get'
import { Spacing, StandardColor, Theme } from '~/config/theme'

const color = ({ color, theme }: ThemedStyledProps<{ color?: StandardColor | string }, Theme>) => {
  if (color) {
    return `fill: ${theme.colors.light[color as StandardColor] ?? color}`
  }

  return `fill: currentColor;`
}

const spacing = (props: ThemedStyledProps<{ marginRight?: Spacing; marginLeft?: Spacing; marginBottom?: Spacing }, Theme>) => {
  if (props.marginRight) {
    return `margin-right: ${props.theme.spacing[props.marginRight]};`
  }

  if (props.marginLeft) {
    return `margin-left: ${props.theme.spacing[props.marginLeft]};`
  }
  if (props.marginBottom) {
    return `margin-bottom: ${props.theme.spacing[props.marginBottom]};`
  }

  return null
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
`

const animation = (props: ThemedStyledProps<{ spin?: boolean },Theme>) => css`
  ${props.spin && rotate} 1s linear infinite;
`

const Container = styled.svg`
  align-self: center;
  display: inline-flex;
  vertical-align: middle;
  animation: ${animation}
  ${color};
  ${spacing};
`

export default Container
