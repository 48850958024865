import type { ComponentProps } from 'react'
import { ButtonContainer, LinkContainer } from './styled/Container'
import Icon from './styled/Icon'
import Label from './styled/Label'
import type { FontSize, Variant } from '~/config/theme'

type LinkActionProps = ComponentProps<typeof LinkContainer>
type ButtonActionProps = ComponentProps<typeof ButtonContainer>

type ActionProps =
  LinkActionProps | ButtonActionProps

function testLink(props: ActionProps): props is LinkActionProps {
  return 'to' in props && props.to
}

export default function Action(props: ActionProps & { text: string; variant?: Variant; size?: FontSize }) {
  const { text, children, variant = 'light', size = 'xs' } = props

  const contents =
    <>
      <Icon>{children}</Icon>
      <Label variant={variant} size={size}>
        {text}
      </Label>
    </>

  if (testLink(props)) {
    return <LinkContainer {...props}>{contents}</LinkContainer>
  } else {
    return <ButtonContainer {...(props as any)}>{contents}</ButtonContainer>
  }
}
