import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { appConnect } from "~/store/hooks";
import PropTypes from 'prop-types'

import Toolbar from 'components/UIKit/Toolbar'
import Canvas from 'components/UIKit/Canvas'

import Container from './styled/Container'

export class Chrome extends Component {
  getComponent(key) {
    const { children } = this.props

    return children.filter(component => component.key === key) || null
  }

  render() {
    const isTouchUI = this.props.isTouchUI
    const isFullscreen = this.props.isFullscreen

    if (isFullscreen) {
      return (
        <Container>
          <Canvas>{this.getComponent('canvas')}</Canvas>
        </Container>
      )
    }

    if (isTouchUI) {
      return (
        <Container>
          <Toolbar size="xl" variant="dark">
            {this.getComponent('topLeft')}
            {this.getComponent('topRight')}
          </Toolbar>
          <Toolbar borderBottom>{this.getComponent('middle')}</Toolbar>
          <Canvas>{this.getComponent('canvas')}</Canvas>
        </Container>
      )
    }

    return (
      <Container>
        <Toolbar size="xl">
          {this.getComponent('topLeft')}
          {this.getComponent('topCenter')}
          {this.getComponent('topRight')}
        </Toolbar>
        <Toolbar variant="dark">
          {this.getComponent('middleLeft')}
          {this.getComponent('middleCenter')}
          {this.getComponent('middleRight')}
        </Toolbar>
        <Canvas>{this.getComponent('canvas')}</Canvas>
        <Toolbar borderTop>
          {this.getComponent('bottomLeft')}
          {this.getComponent('bottomCenter')}
          {this.getComponent('bottomRight')}
        </Toolbar>
      </Container>
    )
  }
}

Chrome.propTypes = {
  children: PropTypes.node,
  isTouchUI: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  online: PropTypes.bool,
}

const mapStateToProps = ({ userInterface }) => ({
  isTouchUI: userInterface.isTouchUI,
  isFullscreen: userInterface.isFullscreen,
})

export default appConnect(mapStateToProps, null)(withRouter(Chrome))
