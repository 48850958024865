import PropTypes from 'prop-types'

import Container from './styled/Container'

const SelectListItem = Container

SelectListItem.propTypes = {
  actions: PropTypes.any,
  onClick: PropTypes.func,
  padded: PropTypes.bool,
  selected: PropTypes.bool,
}

export default /** @type any */(SelectListItem)
