import styled from 'styled-components'

import { getZIndexOf } from 'lib/utils'
import { stretch } from 'lib/styleUtils'

const offset = ({ offsetSide, offsetValue }) => `${offsetSide}: ${offsetValue}`

const Container = styled.div`
  ${stretch()};

  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  width: auto;
  z-index: ${getZIndexOf('loadingOverlay')};

  ${offset};
`

export default Container
