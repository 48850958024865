import { graphql } from '~/gql'

const UPDATE_QUOTE_MUTATION = graphql(`
  mutation UpdateQuote(
    $id: ID!
    $bundles: [createQuoteBundleInput]
    $discountReasonId: ID
    $discountNotes: String
    $name: String
    $opportunityId: String
    $shippingPrice: Float
  ) {
    updateQuote(
      id: $id
      bundles: $bundles
      discountReasonId: $discountReasonId
      discountNotes: $discountNotes
      name: $name
      opportunityId: $opportunityId
      shippingPrice: $shippingPrice
    ) {
      ...QuoteFragment
    }
  }
`)

export default UPDATE_QUOTE_MUTATION
