import { updateColumnLine, updateMountingBeam } from "~/store/objects/actions"
import { average, become3, getLineSegmentForPoint, inchesToNative2, useMountingStructureData } from "../util"
import { Vector2, Vector3 } from "three"
import { formatDistance, unformatDistance } from "~/store/units/formatters/distance"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { MeasurementSystem, UnitTypes } from "~/store/units/constants"
import { convertDistance } from "~/store/units/converters/distance"
import { Html, Line } from "@react-three/drei"
import { TextField } from "~/ui/TextField"

export function MountingStructureLineMeasures(props: { other: [Vector3, number] }) {
  const data = useMountingStructureData()
  const thisModel = getLineSegmentForPoint(data.model, data.perimeterPoints, data.orientation, false)
  const otherModel = getLineSegmentForPoint(props.other, data.perimeterPoints, data.orientation, false)
  const is3D = useAppSelector(store => store.camera.is3D)

  const dispatch = useAppDispatch()

  const distanceUnits = useAppSelector(it => it.units[UnitTypes.DISTANCE])
  const format = (value: number) => formatDistance({ value: convertDistance({ value, system: MeasurementSystem.IMPERIAL }, distanceUnits), system: distanceUnits }, { round: distanceUnits === MeasurementSystem.IMPERIAL ? 0 : 2 })!
  const unformat = (value: string) => unformatDistance({ value, system: distanceUnits }, { round: 2 })

  if (thisModel === undefined || otherModel === undefined) {
    return <></>
  }

  const lineZ = is3D ? 1 : 516
  const orientation2 = new Vector2(data.orientation.x, data.orientation.y)

  const makeP1 = (it: Vector2) => it.clone().add(orientation2.clone().setLength(data.type === 'column' ? -100 : 100))
  const makeP2 = (it: Vector2) => it.clone().add(orientation2.clone().setLength(data.type === 'column' ? -100 : 100).multiplyScalar(-1))

  const p1 = makeP1(thisModel.point1)
  const p2 = makeP2(thisModel.point2)

  const modelText = format(thisModel.position.distanceTo(otherModel.position))

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      event.currentTarget.value = modelText
    } else if (event.key === "Enter") {
      const distance = unformat(event.currentTarget.value)
      if (distance === null) {
        return
      }

      const vectorToOtherLine = otherModel.position.clone().sub(thisModel.position)
      const directionToOtherLine = vectorToOtherLine.clone().normalize()
      const newVector = directionToOtherLine.clone().multiplyScalar(distance)
      const vectorDiff = vectorToOtherLine.clone().sub(newVector)
      const newPosition = thisModel.position.clone().add(vectorDiff)

      if (data.type === 'beam') {
        dispatch(updateMountingBeam({
          roofSectionId: data.roofSectionId,
          beamId: thisModel.id,
          beamPosition: { x: newPosition.x, y: newPosition.y, z: newPosition.z },
          direction: { x: directionToOtherLine.x, y: directionToOtherLine.y, z: directionToOtherLine.z }
        }))
      } else if (data.type === 'column') {
        dispatch(updateColumnLine({
          roofSectionId: data.roofSectionId,
          columnLineId: thisModel.id,
          columnLinePosition: { x: newPosition.x, y: newPosition.y, z: newPosition.z },
          direction: { x: directionToOtherLine.x, y: directionToOtherLine.y, z: directionToOtherLine.z }
        }))
      } else {
        data.type satisfies never
      }
    }
  }

  return (
    <>
      <Line points={[become3(inchesToNative2(p1), lineZ), become3(inchesToNative2(makeP1(otherModel.point1)), lineZ)]} lineWidth={1} color={"black"}/>
      <Line points={[become3(inchesToNative2(p2), lineZ), become3(inchesToNative2(makeP2(otherModel.point2)), lineZ)]} lineWidth={1} color={"black"}/>
      <Html position={become3(average(inchesToNative2(p2), inchesToNative2(makeP2(otherModel.point2))), lineZ)} center>
        <TextField defaultValue={modelText} onKeyDown={handleKeyDown} size={6} variant="dimension" aria-label="Distance between mounting structures"/>
      </Html>
    </>
  )
}
