import { graphql } from '~/gql'

const RELEASE_NOTES_QUERY = graphql(`
  query speclabReleaseNotes {
    releaseNotes: speclabReleaseNotes {
      id
      htmlContents
      type
      version
      effectiveDate
    }
  }
`)

export default RELEASE_NOTES_QUERY
