import { useAppSelector, useAppDispatch } from "~/store/hooks";
import { compose } from 'redux'

import { set2D, set3D } from 'store/camera'
import Switch from 'components/UIKit/Switch'
import { graphql } from '@apollo/client/react/hoc'
import { LOG_USER_ACTION_MUTATION } from 'client/mutations'
import { handleLogUserAction } from 'client/handlers'
import { useCallback } from 'react'
import { hidePanel, showPanel } from '~/store/panel'
import { CONTROLS_PANEL } from '~/store/panel/types'

function PerspectiveSwitch({
  logUserAction,
  showControls = false,
  ...rest
} = {}) {
  const is3D = useAppSelector(store => store.camera.is3D)
  const dispatch = useAppDispatch()
  const switch2D = useCallback(() => {
    dispatch(set2D())
    if (showControls) {
      dispatch(hidePanel({ type: CONTROLS_PANEL }))
    }
  }, [])
  const switch3D = useCallback(() => {
    dispatch(set3D())
    if (showControls) {
      dispatch(showPanel({ type: CONTROLS_PANEL }))
    }
  }, [])

  return (
    <Switch
      name="3d"
      label="3D View"
      onClick={event => {
        event.preventDefault()
        if (!is3D) logUserAction({ action: '3D toggle' })
        return is3D
          ? switch2D()
          : switch3D()
      }}
      isChecked={is3D}
      {...rest}
    />
  )
}

export default compose(
  graphql(LOG_USER_ACTION_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      logUserAction: ({ action }) =>
        handleLogUserAction({ action, mutate, ownProps }),
    }),
  })
)(PerspectiveSwitch)
