import { isNil } from 'lodash-es'

import Unit from './unit'
import type { FormattedUnitData, UnformattedUnitData, SomeUnitData } from './unit'
import { TYPES, SYSTEMS } from '../constants'
import type { FormatOptions } from '../formatters'

class Temperature<Data extends FormattedUnitData | UnformattedUnitData> extends Unit<Data> {
  constructor({ value, system }: Omit<Data, 'type'>) {
    if (isNil(value)) {
      console.warn('No value provided to Temperature unit!')
    }
    if (isNil(system)) {
      console.warn('No system provided to Temperature unit!')
    }
    super({
      value: value,
      system: system,
      type: TYPES.TEMPERATURE,
    } as Data)
  }

  clone() {
    return new Temperature<Data>({
      value: this.value,
      system: this.system,
    } as Data)
  }

  static unformat({ value, system }: Omit<FormattedUnitData, 'type'>, options?: FormatOptions) {
    return Unit.unformat({ value, system, type: TYPES.TEMPERATURE }, options)
  }

  static isValid(props: Partial<Omit<SomeUnitData, 'type'>>): props is Omit<SomeUnitData, 'type'> {
    return (
      typeof props.value === 'number' &&
      [SYSTEMS.IMPERIAL, SYSTEMS.METRIC].includes(props.system as any)
    )
  }
}

export class ImperialTemperature<Data extends FormattedUnitData | UnformattedUnitData> extends Temperature<Data> {
  constructor(value: Data['value']) {
    super({ value, system: SYSTEMS.IMPERIAL } as Data)
  }
}

export class MetricTemperature<Data extends FormattedUnitData | UnformattedUnitData> extends Temperature<Data> {
  constructor(value: Data['value']) {
    super({ value, system: SYSTEMS.METRIC } as Data)
  }
}

export type AnyTemperature = Temperature<FormattedUnitData | UnformattedUnitData>

export default Temperature
