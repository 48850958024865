import styled from 'styled-components'
import { FontSize, StandardColor } from '~/config/theme';

const Container = styled.p<{ align?: string; bold?: boolean; fontWeight?: string; italic?: boolean; color?: StandardColor; size?: FontSize; uppercase?: boolean }>`
  ${props => props.align && `text-align: ${props.align};`};
  ${props => props.bold && `font-weight: bold;`};
  ${props => props.fontWeight && `font-weight: ${props.fontWeight};`};
  ${props => props.italic && `font-style: italic;`};
  ${props => props.color && `color: ${props.theme.colors.light[props.color]};`};
  ${props =>
    props.size && `font-size: ${props.theme.fonts.sizes[props.size]};`};
  ${props =>
    props.uppercase &&
    `
      letter-spacing: 0.5px;
      text-transform: uppercase;
    `};
`

export default Container
