import styled from 'styled-components'

const Breadcrumb = styled.button`
  background-color: ${props => props.theme.colors.light.bg};
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colors.light.subdued};
  border-top: 1px solid ${props => props.theme.colors.light.subdued};
  display: flex;
  font-weight: ${props => props.theme.fonts.weights.semiBold};
  line-height: 24px;
  padding: ${props => props.theme.spacing.base};
  width: 100%;
`

export default Breadcrumb
