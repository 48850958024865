import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import pick from 'lodash-es/pick'
import startCase from 'lodash-es/startCase'

import routes from 'config/routes'
import Button from 'components/UIKit/Button'
import Flex from 'components/UIKit/Flex'

const TYPES = ['product', 'controlPanel', 'electricPanel']

const FIELDS = {
  controlPanel: ['type', 'fans'],
  electricPanel: ['voltage', 'brand'],
  product: ['deckHeight', 'heightToAttachPoint', 'liftNeeded'],
}

export const isComplete = val => {
  if (Array.isArray(val) && val.length === 0) return false
  return val !== 0 && val !== null && val !== undefined && val !== ''
}

function AddInstallInfoButton(props = {}) {
  const modalKey = `add${startCase(props.type).replace(' ', '')}InstallInfo`
  const currentTypeFields = pick(props.selectedObject, FIELDS[props.type])
  const isRequiredType = TYPES.includes(props.type)
  const isNotRequiredDirectional =
    get(props, 'selectedObject.product.type') === 'DIRECTIONAL' &&
    !get(props, 'selectedObject.isDirectionalOverhead')
  const shouldShowButton = isRequiredType && !isNotRequiredDirectional
  const hasSavedData =
    !isEmpty(currentTypeFields) &&
    Object.values(currentTypeFields).every(isComplete)

  const label = () => {
    if (hasSavedData && props.online) {
      return 'Update'
    }
    if (hasSavedData && !props.online) {
      return 'View'
    } else {
      return 'Add'
    }
  }
  return shouldShowButton ? (
    <Flex justifyContent="center">
      {!hasSavedData && !props.online ? null : (
        <Button
          label={`${label()} install information`}
          to={`${props.match.url}${routes.modals[modalKey]}?objectId=${props.selectedObject.id}`}
          disabled={props.disabled}
        />
      )}
    </Flex>
  ) : null
}

AddInstallInfoButton.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  selectedObject: PropTypes.shape({
    id: PropTypes.string,
  }),
  type: PropTypes.oneOf(TYPES),
  online: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default /** @type {any} */(withRouter(AddInstallInfoButton))
