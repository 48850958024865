import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom'
import { appConnect } from "~/store/hooks";
import { withRouter } from 'react-router-dom'
import get from 'lodash-es/get'
import qs from 'qs'

import cloudinary from 'config/cloudinary'
import routes from 'config/routes'
import { getTitle } from 'config/titles'

import { objectStateKeyFromClassName } from 'store/objects/selectors'

import Button from 'components/UIKit/Button'
import DownloadImageButton from 'components/DownloadImageButton'
import Modal from 'components/UIKit/Modal'

class ViewImageModal extends Component {
  componentDidMount() {
    this.viewer && this.viewer.fitToViewer()
  }
  getCloudinaryAssetUrl = ({ cloudinaryId }) => {
    return cloudinary.url(cloudinaryId, {
      crop: 'fill',
      secure: true,
    })
  }

  getImageUrl = ({ cloudinaryId }) => {
    const cloudinaryImage = cloudinary.image(cloudinaryId, {
      format: 'png',
      secure: true,
      transformation: [{ crop: 'fill' }],
    })
    return cloudinaryImage.src
  }

  render() {
    const {
      parentRoute,
      history,
      props,
      image,
      editUrl,
      addProductUrl,
    } = this.props
    const cloudinaryId = get(image, 'cloudinaryId')
    const cloudinaryImage = this.getImageUrl({ cloudinaryId })
    const cloudinaryAssetUrl = this.getCloudinaryAssetUrl({ cloudinaryId })

    return (
      <>
        <Helmet>
          <title>{getTitle('viewImage')}</title>
        </Helmet>

        <Modal
          size="950px"
          title="View Image"
          parentRoute={parentRoute}
          history={history}
          secondaryAction={
            <>
              <DownloadImageButton cloudinaryImage={cloudinaryAssetUrl} />
              <Button to={addProductUrl} label="Add Product" primary />
            </>
          }
          primaryAction={<Button to={editUrl} label="Edit" primary />}
          {...props}
        >
          {cloudinaryImage && (
            <UncontrolledReactSVGPanZoom
              width={900}
              height={500}
              miniatureProps={{ miniaturePosition: 'none' }}
              SVGBackground="transparent"
              background="transparent"
              ref={viewer => {
                this.viewer = viewer
              }}
            >
              <svg width={900} height={500}>
                <image
                  x={0}
                  y={0}
                  width={900}
                  height={500}
                  xlinkHref={cloudinaryImage}
                />
              </svg>
            </UncontrolledReactSVGPanZoom>
          )}
        </Modal>
      </>
    )
  }
}

ViewImageModal.propTypes = {
  props: PropTypes.object,
  parentRoute: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  image: PropTypes.object,
  object: PropTypes.object,
  editUrl: PropTypes.string,
  addProductUrl: PropTypes.string,
}

const mapStateToProps = ({ objects }, ownProps) => {
  const queryParams = qs.parse(get(ownProps, 'location.search'), {
    ignoreQueryPrefix: true,
  })
  const objectId = get(queryParams, 'objectId')
  const objectType = get(queryParams, 'objectType')
  const imageIndex = get(queryParams, 'imageIndex')
  const object = get(objects.present, `[${objectType}][${objectId}]`)
  const metadata = get(object, 'metadata')
  const image = get(metadata, `images[${imageIndex}]`)

  const facilityUrl = ownProps.match.url.replace(routes.modals.viewImage, '')
  const objectClassName = get(object, 'className')
  const objectStateKey = objectStateKeyFromClassName(objectClassName)
  const editUrl = `${facilityUrl}${routes.modals.annotateImage}?objectId=${objectId}&objectType=${objectStateKey}&imageIndex=${imageIndex}`
  const addProductUrl = `${facilityUrl}${routes.modals.addProductOverlayImage}?objectId=${objectId}&objectType=${objectStateKey}&imageIndex=${imageIndex}`

  return {
    image,
    editUrl,
    addProductUrl,
  }
}

export default appConnect(mapStateToProps)(withRouter(ViewImageModal))
