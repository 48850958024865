
      ;(function() {
        var environment = import.meta.env.MODE
        var userSnapAPIKey = import.meta.env.VITE_APP_USERSNAP_API_KEY
        var allowlist = ['production']
        if (allowlist.includes(environment) && userSnapAPIKey) {
          window.onUsersnapLoad = function(api) {
            api.init()
            window.Usersnap = api
          }
          var script = document.createElement('script')
          script.async = 1
          script.src = `https://api.usersnap.com/load/${userSnapAPIKey}.js?onload=onUsersnapLoad`
          document.getElementsByTagName('head')[0].appendChild(script)
        }
      })()
    