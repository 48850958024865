import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import { withRouter } from 'react-router-dom'
import get from 'lodash-es/get'

import { getSnapshotUrl } from 'lib/utils'

import {
  onFacilityTemplateCreated,
  onFacilityTemplateEdited,
} from 'config/analytics'
import { trackEvent } from 'lib/analytics'

import { showAlert } from 'store/alert'
import { Snapshots } from 'client/providers'

import Alert from 'components/UIKit/Alert'
import Button from 'components/UIKit/Button'
import Grid, { GridItem } from 'components/UIKit/Grid'
import FormLabel from 'components/UIKit/FormLabel'
import Image from 'components/UIKit/Image'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'
import VariantText from 'components/UIKit/VariantText'

import SelectableImage from './styled/SelectableImage'

class TemplateForm extends Component {
  state = {
    name: get(this.props, 'formData.name') || '',
    description: get(this.props, 'formData.description') || null,
    imageUrl: get(this.props, 'formData.imageUrl') || null,
    localError: null,
  }

  handleInputChange = event => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  }

  handleSnapshotClick = (event, imageUrl) => {
    event.preventDefault()

    // We want to be able to toggle the currently selected image
    // if the user is selecting the same image.
    if (this.state.imageUrl === imageUrl) {
      this.setState({
        imageUrl: null,
      })
    } else {
      this.setState({
        imageUrl,
      })
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    if (this.props.buttonText === 'Update Template') {
      trackEvent(onFacilityTemplateEdited())
    } else {
      trackEvent(onFacilityTemplateCreated())
    }

    const { onSubmit, match, history, parentRoute, showAlert } = this.props
    const { name, description, imageUrl } = this.state
    const facilityId = match.params.id

    if (name.trim() === '') {
      this.setState({ localError: 'Must provide a template name!' })
      return
    }

    onSubmit({
      variables: {
        facilityId,
        name,
        description,
        imageUrl,
      },
    })

    history.push(parentRoute)
    showAlert({ type: 'success', text: 'Your template was saved!' })
  }

  render() {
    const { name, description, imageUrl, localError } = this.state
    const { error, loading, versionId } = this.props
    const anyError = localError || error || null

    return (
      <Modal
        title={this.props.title}
        onSubmit={this.handleSubmit}
        primaryAction={
          <Button
            primary
            onClick={this.handleSubmit}
            disabled={loading}
            label={loading ? 'Saving...' : this.props.buttonText}
          />
        }
        secondaryAction={<Button to={this.props.parentRoute} label="Cancel" />}
        size="500px"
        {...this.props}
      >
        {anyError && (
          <Space bottom="base">
            <Alert type="error" text={anyError} />
          </Space>
        )}
        <Space bottom="base">
          <TextField
            label="Name"
            name="name"
            value={name}
            onChange={this.handleInputChange}
          />
        </Space>
        <Space bottom="base">
          <TextField
            label="Description"
            name="description"
            value={description}
            onChange={this.handleInputChange}
            multiline
            maxcharlength="255"
          />
        </Space>
        <Snapshots versionId={versionId}>
          {snapshots => (
            <>
              <Space bottom="s">
                <FormLabel>Image</FormLabel>
                <VariantText size="s">
                  {snapshots.length === 0
                    ? 'You need to take snapshots to use as the template image.'
                    : 'Select a snapshot below to use as the template image:'}
                </VariantText>
              </Space>
              {snapshots.length !== 0 && (
                <Grid>
                  {snapshots
                    // Let's only grab, at most, 6 snapshots.
                    .slice(0, 6)
                    .map(({ id, cropData, cloudinaryId }) => (
                      <GridItem key={id} size="1of3">
                        <Space bottom="base">
                          <SelectableImage
                            // The current image is the selected one.
                            selected={
                              imageUrl ===
                              getSnapshotUrl({ cropData, cloudinaryId })
                            }
                            // The current image is not the selected one.
                            inactive={
                              imageUrl &&
                              imageUrl !==
                                getSnapshotUrl({ cropData, cloudinaryId })
                            }
                            onClick={event =>
                              this.handleSnapshotClick(
                                event,
                                getSnapshotUrl({ cropData, cloudinaryId })
                              )
                            }
                          >
                            <Image
                              src={getSnapshotUrl({ cropData, cloudinaryId })}
                            />
                          </SelectableImage>
                        </Space>
                      </GridItem>
                    ))}
                </Grid>
              )}
            </>
          )}
        </Snapshots>
      </Modal>
    )
  }
}

TemplateForm.propTypes = {
  buttonText: PropTypes.string,
  error: PropTypes.string,
  formDataError: PropTypes.object,
  formDataLoading: PropTypes.bool,
  history: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  onSubmit: PropTypes.func,
  parentRoute: PropTypes.string,
  showAlert: PropTypes.func,
  title: PropTypes.string,
  versionId: PropTypes.string,
}

export default compose(withRouter, appConnect(null, { showAlert }))(TemplateForm)
