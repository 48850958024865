import React, { Component, ComponentProps, ReactNode } from 'react'
import get from 'lodash-es/get'

import PopoverItem from '../PopoverItem'

import Card from './styled/Card'
import List from './styled/List'
import NestedGroup from './styled/NestedGroup'
import SubMenu from './styled/SubMenu'
import PopoverMenuLoader from './styled/PopoverMenuLoader'
import RequiredPermission from 'components/RequiredPermission'
import { Variant } from '~/config/theme'

const ConditionalWrapper = ({ condition, wrapper, children }: { condition: boolean; wrapper: (fn: ReactNode) => ReactNode; children: ReactNode }) =>
  condition ? wrapper(children) : children

type PopoverMenuProps = {
  action?: ComponentProps<typeof PopoverItem>
  isVisible?: boolean
  items: ComponentProps<typeof PopoverItem>[]
  loading?: boolean
  variant: Variant
  adjustForTouch?: boolean
  scrollable?: boolean
  position?: string
}

class PopoverMenu extends Component<PopoverMenuProps> {
  state = {
    activeSubMenuIndex: null,
  }

  static defaultProps = {
    direction: 'right',
  }

  nestedGroupRef: Record<number, HTMLElement | null>

  constructor(props: PopoverMenuProps) {
    super(props)

    this.nestedGroupRef = {}
  }

  handleMouseOver = (_event: React.MouseEvent<HTMLElement>, index: number) => {
    this.setState({
      activeSubMenuIndex: index,
    })
  }

  handleMouseOut = () => {
    this.setState({
      activeSubMenuIndex: null,
    })
  }

  handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    const { activeSubMenuIndex } = this.state
    if (activeSubMenuIndex === index) {
      this.handleMouseOut()
    } else {
      this.handleMouseOver(event, index)
    }
  }

  renderItems() {
    const { items, variant, adjustForTouch, scrollable } = this.props
    const { activeSubMenuIndex } = this.state

    return items.map((item, index) => {
      if (get(item, 'subMenu.items')) {
        const position = get(item, 'subMenu.position')
        return (
          <ConditionalWrapper
            key={index}
            condition={get(item, 'permissionNeeded', false)}
            wrapper={children => (
              <RequiredPermission name={get(item, 'permissionNeeded')}>
                {children}
              </RequiredPermission>
            )}
          >
            <NestedGroup
              ref={node => (this.nestedGroupRef[index] = node)}
              onMouseLeave={event => {
                if (!adjustForTouch) this.handleMouseOut()
              }}
            >
              <PopoverItem
                key={index}
                // @ts-ignore-error item can override this if it wants
                variant={variant}
                onMouseEnter={(event: any) => {
                  if (!adjustForTouch) this.handleMouseOver(event, index)
                }}
                // @ts-ignore-error item can override this if it wants
                onClick={event =>
                  adjustForTouch && this.handleClick(event, index)
                }
                hasSubMenu
                adjustForTouch={adjustForTouch}
                position={position}
                {...item}
              />
              <SubMenu
                isVisible={activeSubMenuIndex === index}
                variant={variant}
                // direction={get(item, 'subMenu.direction')}
                position={position}
              >
                <List
                  variant={variant}
                  position={position}
                  scrollable={scrollable}
                >
                  {get(item, 'subMenu.items').map((i: any, ix: number) => (
                    <PopoverItem
                      key={ix}
                      {...i}
                      onClick={event => {
                        // Invoke the submenu item's onClick handler
                        i.onClick(event)
                        // Close the active sub menu on touch layout
                        if (adjustForTouch) this.handleMouseOut()
                      }}
                      variant={variant}
                      adjustForTouch={adjustForTouch}
                      position={position}
                    />
                  ))}
                </List>
              </SubMenu>
            </NestedGroup>
          </ConditionalWrapper>
        )
      }

      return (
        <ConditionalWrapper
          key={index}
          condition={get(item, 'permissionNeeded', false)}
          wrapper={children => (
            <RequiredPermission name={get(item, 'permissionNeeded')}>
              {children}
            </RequiredPermission>
          )}
        >
          <PopoverItem
            {...item}
            variant={variant}
            adjustForTouch={adjustForTouch}
          />
        </ConditionalWrapper>
      )
    })
  }

  render() {
    const { items, isVisible, action, variant, ...props } = this.props

    return (
      <Card isVisible={isVisible} variant={variant} {...props}>
        {this.props.loading ? (
          <PopoverMenuLoader />
        ) : (
          <>
            <List variant={variant} {...props}>
              {this.renderItems()}
            </List>
            {action && (
              <PopoverItem primaryAction {...action} variant={variant} />
            )}
          </>
        )}
      </Card>
    )
  }
}

export default PopoverMenu
