import React from 'react'
import PropTypes from 'prop-types'

import { manDoor, dockDoor } from 'config/doors'
import LAYER_KEYS from 'config/layerKeys'

import TouchPopover from '../styled/TouchPopover'
import ItemMenu from '../ItemMenu'
import AddItemMenuItem from '../AddItemMenuItem'
import withStandardProps from '../withStandardProps'

const WallsAndDoorsMenu = ({ history, insertItem, match }) => (
  <TouchPopover
    renderMenu={({ isDropdownVisible, handleTriggerClick }) => (
      <ItemMenu
        title="Walls & Doors"
        isVisible={isDropdownVisible}
        closeMenu={handleTriggerClick}
        items={[
          {
            title: 'Exterior Wall',
            icon: {
              name: 'square',
              size: '20',
            },
            onClick: () =>
              insertItem({
                layerKey: LAYER_KEYS.EXTERIOR_WALLS,
                tool: 'LINE_TOOL',
              }),
          },
          {
            title: 'Interior Wall',
            icon: {
              name: 'interiorWall',
              size: '20',
            },
            onClick: () =>
              insertItem({
                layerKey: LAYER_KEYS.INTERIOR_WALLS,
                tool: 'LINE_TOOL',
              }),
          },
          {
            title: 'Man Door',
            icon: {
              name: 'doorMan',
              size: '20',
            },
            onClick: () => {
              insertItem({
                layerKey: LAYER_KEYS.DOORS,
                tool: 'DOOR_TOOL',
                props: manDoor,
              })
            },
          },
          {
            title: 'Dock Door',
            icon: {
              name: 'doorDock',
              size: '20',
            },
            onClick: () => {
              insertItem({
                layerKey: LAYER_KEYS.DOORS,
                tool: 'DOOR_TOOL',
                props: dockDoor,
              })
            },
          },
        ]}
      />
    )}
    renderTrigger={({ isDropdownVisible }) => (
      <AddItemMenuItem
        icon="interiorWall"
        isDropdownVisible={isDropdownVisible}
        label="Walls & Doors"
      />
    )}
  />
)

WallsAndDoorsMenu.propTypes = {
  history: PropTypes.object,
  insertItem: PropTypes.func,
  match: PropTypes.object,
}

export default withStandardProps(WallsAndDoorsMenu)
