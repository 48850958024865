import React from 'react'
import PropTypes from 'prop-types'

import ProgressBarWrapper from './Styled/ProgressBarWrapper'
import ProgressBarLabel from './Styled/ProgressBarLabel'
import ProgressBarFill from './Styled/ProgressBarFill'

const ProgressBar = ({ bgcolor, completed }) => {
  return (
    <ProgressBarWrapper>
      <ProgressBarFill completed={completed} bgColor={bgcolor}>
        <ProgressBarLabel>{`${completed}%`}</ProgressBarLabel>
      </ProgressBarFill>
    </ProgressBarWrapper>
  )
}

ProgressBar.propTypes = {
  bgcolor: PropTypes.string,
  completed: PropTypes.number,
}

export default /** @type any */(ProgressBar)
