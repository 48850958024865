import { graphql } from '~/gql'
import client from '~/client'

const RADIANT_HEATER_PERFORMANCE_QUERY = graphql(`
  query IRHRelativeIntensity {
    IRHRelativeIntensity {
      heightAboveFinishedFloor
      distanceFromTube
      relativeIntensityRatio
    }
  }
`)

/**
 * Fetch and format relative intensity data for radiant heaters from database
 * @returns Object of relative intensity data, keyed by height, distance
 */
export async function getRadiantHeaterPerformanceData() {
  const radiantHeaterPerformance = await client.query({
    query: RADIANT_HEATER_PERFORMANCE_QUERY,
  })
  const relativeIntensityDataArray =
    radiantHeaterPerformance.data.IRHRelativeIntensity

  // Sort the return array of database rows into an object
  // Keyed by height above finished floor, then distance from tube
  // e.g,
  // {
  //   12: { 0: 19.73, 1: 17.54, ... },
  //   13: { 0: 15.98, 1: 14.47, ... },
  //   ...
  // }
  let relativeIntensityData: Record<string, Record<string, number>> = {}
  relativeIntensityDataArray!.filter(it => it != null).forEach(p => {
    const {
      heightAboveFinishedFloor: heightAFF,
      distanceFromTube: dist,
      relativeIntensityRatio: ratio,
    } = p

    relativeIntensityData[heightAFF] = relativeIntensityData[heightAFF] || {}
    relativeIntensityData[heightAFF][dist] = ratio
  })

  return relativeIntensityData
}
