import { primaryUseVelocityTypes } from 'config/facility'
import { SYSTEMS } from 'store/units/constants'
import { Velocity } from 'store/units/types'
import { FacilityDefaults } from '~/hooks/useFacilityDefaults'

export const SIMULATION_TYPES = {
  cooling: 'cooling',
  destrat: 'destrat',
  unitHeating: 'unitHeating',
  radiantHeat: 'radiantHeat',
} as const
export type SIMULATION_TYPES = (typeof SIMULATION_TYPES)[keyof typeof SIMULATION_TYPES]

export const COMPOSITE_SIMULATION_TYPES = {
  heatingComposite: 'heatingComposite',
} as const
export type COMPOSITE_SIMULATION_TYPES = (typeof COMPOSITE_SIMULATION_TYPES)[keyof typeof COMPOSITE_SIMULATION_TYPES]

export const CFD_MAIN_TYPES = {
  [SIMULATION_TYPES.cooling]: 'cooling',
  [SIMULATION_TYPES.destrat]: 'destrat',
  [SIMULATION_TYPES.radiantHeat]: 'heating',
  [SIMULATION_TYPES.unitHeating]: 'cooling',
} as const
export type CFD_MAIN_TYPES = (typeof CFD_MAIN_TYPES)[keyof typeof CFD_MAIN_TYPES]

export const CFD_STATUS = {
  UNAVAILABLE: 'UNAVAILABLE',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
  CANCELED: 'CANCELED',
} as const
export type CFD_STATUS = (typeof CFD_STATUS)[keyof typeof CFD_STATUS]

export const CFD_FILE_PROPS_TYPE = {
  overhead: 'overhead',
  destrat: 'destrat',
} as const
export type CFD_FILE_PROPS_TYPE = (typeof CFD_FILE_PROPS_TYPE)[keyof typeof CFD_FILE_PROPS_TYPE]

export const HEIGHTS = {
  standing: 'standing',
  seated: 'seated',
} as const
export type HEIGHTS = (typeof HEIGHTS)[keyof typeof HEIGHTS]

export const HEIGHT_VALUES = {
  [HEIGHTS.standing]: [4, 43, 67],
  [HEIGHTS.seated]: [4, 24, 43],
} as const

export const COVERAGE_THRESHOLDS = {
  destrat: 10,
  draftRisk: 40,
  coolingLow: 42.5,
  coolingHigh: 85,
  intensity: 5,
}

export const STILL_AIR_VELOCITY = new Velocity({ value: 0.1, system: SYSTEMS.METRIC })

export const CFD_OBJECTIVES = {
  COOLING: 'COOLING',
  DESTRATIFICATION: 'DESTRATIFICATION',
  HEATING: 'HEATING',
} as const
export type CFD_OBJECTIVES = (typeof CFD_OBJECTIVES)[keyof typeof CFD_OBJECTIVES]

export const isLowVelocityFacility = ({
  primaryUse,
  primaryType,
}: Pick<FacilityDefaults, 'primaryType' | 'primaryUse'>) => {
  return (
    primaryUseVelocityTypes.low.includes(primaryUse) ||
    (!!primaryType && primaryType !== 'INDUSTRIAL')
  )
}

export const getCoolingThreshold = ({ primaryUse, primaryType }: Pick<FacilityDefaults, 'primaryType' | 'primaryUse'>) =>
  isLowVelocityFacility({ primaryType, primaryUse })
    ? COVERAGE_THRESHOLDS.coolingLow
    : COVERAGE_THRESHOLDS.coolingHigh

export const getUnitHeatThreshold = (facilityDefaultData: FacilityDefaults) => {
  const defaultTemp = facilityDefaultData.indoorWinterTemp ?? 55
  const minInput = 55
  const maxInput = 68
  const targetMin = 66
  const targetMax = 74
  return ((defaultTemp - minInput) / (maxInput - minInput)) * (targetMax - targetMin) + targetMin
}
