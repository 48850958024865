import styled from 'styled-components'

const Product = styled.div`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: ${props => props.height}px;
  left: ${props => props.left}px;
  pointer-events: none;
  position: absolute;
  top: ${props => props.top}px;
  transform: rotate(${props => props.rotateAngle}deg);
  width: ${props => props.width}px;
`

export default Product
