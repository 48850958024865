class RenderLoop {
  static subscribers = new Set()

  static update(deltaMillis, canvasState) {
    RenderLoop.subscribers.forEach(subscriber =>
      subscriber.frameRendered(deltaMillis, canvasState)
    )
  }

  static subscribe(subscriber) {
    RenderLoop.subscribers.add(subscriber)
  }

  static unsubscribe(subscriber) {
    RenderLoop.subscribers.delete(subscriber)
  }
}

export default RenderLoop
