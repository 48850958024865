import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { getTitle } from 'config/titles'

import { UPDATE_VERSION_MUTATION } from 'client/mutations'
import { GET_FACILITY_QUERY } from 'client/queries'

import VersionForm from '../../VersionForm'

const EditVersionModal = ({ ...props }) => (
  <div>
    <Helmet>
      <title>{getTitle('editVersion')}</title>
    </Helmet>
    <VersionForm title="Edit Version" buttonText="Save Version" {...props} />
  </div>
)

export default compose(
  graphql(GET_FACILITY_QUERY, {
    options: ({ facility, match }) => ({
      variables: {
        id: facility.id,
        floorId: null,
        versionId: match.params.id,
      },
    }),
    name: 'facilityData',
  }),
  graphql(UPDATE_VERSION_MUTATION, {
    props: ({ mutate }) => ({
      onSubmit: variables => mutate({ variables }),
    }),
    options: props => ({
      refetchQueries: [
        {
          query: GET_FACILITY_QUERY,
          variables: {
            id: props.facility.id,
            floorId: null,
            versionId: props.match.params.id,
          },
          name: 'facilityData',
        },
      ],
    }),
  }),
  withRouter
)(EditVersionModal)
