import { graphql } from '@apollo/client/react/hoc'
import get from 'lodash-es/get'

import { isDevelopment } from 'lib/utils'

import { GET_APP_SETTINGS_QUERY } from '../queries'

// 5 minutes
const DEFAULT_POLL_INTERVAL = 300000

export default graphql(GET_APP_SETTINGS_QUERY, {
  options: () => ({
    // This `pollInterval` is set up because the `isUnderMaintenance` flag in
    // `AppSettings` needs to be checked regularly in order to properly
    // redirect users that are currently using the app.
    pollInterval: isDevelopment() ? null : DEFAULT_POLL_INTERVAL,
    fetchPolicy: 'network-only',
  }),
  props: result => ({
    loading: get(result, 'data.loading') || get(result, 'ownProps.loading'),
    appSettings: get(result, 'data.AppSettings') || {},
  }),
})
