import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import TextField from 'components/UIKit/TextField'

const StyledTextField = styled(TextField)`
  font-size: ${theme('fonts.sizes.s')};
  text-align: center;
`

export default StyledTextField
