import { graphql } from '~/gql'

const UPDATE_USER_MUTATION = graphql(`
  mutation updateUser(
    $id: ID!
    $name: String
    $email: String
    $phone: String
    $preferences: Json
    $requiresCacheClear: Boolean
  ) {
    updateUser(
      id: $id
      name: $name
      email: $email
      phone: $phone
      preferences: $preferences
      requiresCacheClear: $requiresCacheClear
    ) {
      ...UserFragment
    }
  }
`)

export default UPDATE_USER_MUTATION
