const separator = '-'
const base = `SpecLab ${separator} Big Ass Fans`

const titles = {
  addProduct: 'Add Product',
  addProductOverlayImage: 'Add Product Image',
  auth: 'Log In / Sign Up',
  ceilings: 'Ceilings',
  configureCFD: 'Configure CFD',
  cfdImages: 'CFD Images',
  chooseDrawingMethod: 'Choose Drawing Method',
  chooseLayers: 'Choose Layers',
  comfortZones: 'Comfort Zones',
  distributeDoors: 'Distribute Doors',
  distributeObstructions: 'Distribute Obstructions',
  distributeProducts: 'Distribute Products',
  duplicateFacility: 'Duplicate Facility',
  duplicateFloor: 'Duplicate Area',
  duplicateVersion: 'Duplicate Version',
  editFacility: 'Edit Facility',
  editFloor: 'Edit Area',
  editVersion: 'Edit Version',
  exteriorWalls: 'Exterior Walls',
  facilities: 'Facilities',
  templates: 'Templates',
  facility: 'Facility',
  faq: 'Frequently Asked Questions',
  installInfo: 'Install Information',
  interiorWalls: 'Interior Walls',
  newFacility: 'New Facility',
  newFacilityTemplate: 'New Facility Template',
  newFloor: 'New Area',
  newVersion: 'New Version',
  notFound: 'Not Found',
  obstructions: 'Obstructions',
  performanceMetrics: 'Performance Metrics',
  roofs: 'Roofs',
  savings: 'Savings',
  selectOpportunity: 'Select Opportunity',
  snapshot: 'Snapshot',
  uploadDWG: 'Upload DWG',
  uploadImage: 'Upload Image',
  scaleImage: 'Scale Image',
  convertImage: 'Convert Image',
  airflowHelp: 'About Airflow',
  cfdHelp: 'About CFD Simulation',
  annotateImage: 'Annotate Image',
  viewImage: 'View Image',
  editImage: 'Edit Image',
  webcamCapture: 'Webcam Capture',
  heatMapHelp: 'About Heat Map',
  sendToSalesHub: 'Send To SalesHub',
}

export const getTitleForPath = (path: string) => (titles as Partial<Record<string, string>>)[path] || path

export const getTitle = (path: string) => `${getTitleForPath(path)} ${separator} ${base}`
