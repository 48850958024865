import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Flex from 'components/UIKit/Flex'

import { TOOL_BAR_HEIGHT_PX } from '../Tools/styled/Container'

const CanvasContainer = styled(Flex)`
  background-color: ${theme('colors.dark.bg')};
  height: calc(100vh - ${TOOL_BAR_HEIGHT_PX});
  justify-content: center;
  position: relative;
  width: 100%;
`

export default CanvasContainer
