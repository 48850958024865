import earcut from 'earcut'
import Offset from './offset'
import Units from './units'
import Util from './util'
import getRenderOrder from 'config/canvasRenderOrder'
import OBJECT_TYPES from 'config/objectTypes'
import { getThreeHexFromTheme } from 'lib/utils'

import * as THREE from 'three'
import cdt2d from 'cdt2d'

/*
    Primitives is a collection of static utility methods for obtaining graphical
    primitives renderable by three.js. Some of these primitives are a bit higher
    level—for instance, getWallMesh(...) will return a 3D mesh representing a
    facility wall based on given 'center line' points and thickness.
*/

class Primitives {
  static clock = new THREE.Clock()
  static dragGeometry = new THREE.CircleGeometry(2, 18)
  static largeDragGeometry = new THREE.CircleGeometry(4, 18)
  static dragMaterial = new THREE.MeshLambertMaterial({
    color: getThreeHexFromTheme('three.light'),
    emissive: getThreeHexFromTheme('three.light'),
    transparent: true,
    side: THREE.DoubleSide,
    opacity: 0.99,
  })
  static ringGeometry = new THREE.RingGeometry(1.7, 1.9, 18)
  static ringMaterial = new THREE.MeshBasicMaterial({
    color: getThreeHexFromTheme('three.dark'),
    side: THREE.DoubleSide,
  })
  static largeRingGeometry = new THREE.RingGeometry(3.4, 3.8, 18)

  static getWarningSign() {
    // Container object for the warning sign
    const object = new THREE.Object3D()

    // Outer circle to act as an outline
    const outlineGeometry = new THREE.CircleGeometry(2.4, 18)
    const outlineMaterial = new THREE.MeshLambertMaterial({
      color: getThreeHexFromTheme('three.light'),
      transparent: true,
      side: THREE.DoubleSide,
      opacity: 0.99,
    })
    const outlineCircle = new THREE.Mesh(outlineGeometry, outlineMaterial)
    outlineCircle.position.set(0, -0.2, 0.1)
    object.add(outlineCircle)

    // Inner circle
    const innerGeometry = new THREE.CircleGeometry(2, 18)
    const innerMaterial = new THREE.MeshLambertMaterial({
      color: '#ff0000',
      transparent: true,
      side: THREE.DoubleSide,
      opacity: 0.99,
    })
    const innerCircle = new THREE.Mesh(innerGeometry, innerMaterial)
    innerCircle.position.set(0, -0.2, 0.11)
    object.add(innerCircle)

    // Line for exclamation mark
    const exclamationGeometry = new THREE.BufferGeometry()
    const vertices = new Float32Array([
      -0.25, 1.8, 0,
      0.25, 1.8, 0,
      0, -0.2, 0,
      0, -0.2, 0,
    ])
    const faces = [
      0, 1, 2,
      3, 0, 2,
    ]
    exclamationGeometry.setIndex(faces)
    exclamationGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3))

    const exclamationMaterial = new THREE.MeshBasicMaterial({
      color: getThreeHexFromTheme('three.light'),
      transparent: true,
      opacity: 0.99,
      side: THREE.DoubleSide,
    })
    const exclamation = new THREE.Mesh(exclamationGeometry, exclamationMaterial)
    exclamation.position.set(0, -0.8, 0.12)
    object.add(exclamation)

    // Cirlce to cap the line
    const circleGeometry = new THREE.CircleGeometry(0.25, 18)
    const circleGeometryMaterial = new THREE.MeshLambertMaterial({
      color: getThreeHexFromTheme('three.light'),
      emissive: getThreeHexFromTheme('three.light'),
      transparent: true,
      side: THREE.DoubleSide,
      opacity: 0.99,
    })
    const circle = new THREE.Mesh(circleGeometry, circleGeometryMaterial)
    circle.position.set(0, 1, 0.12)
    object.add(circle)

    // Circle for exclamation dot
    const dot = new THREE.Mesh(circleGeometry, circleGeometryMaterial)
    dot.position.set(0, -1.55, 0.12)
    object.add(dot)

    return object
  }

  // Creates error sign for object error states
  static getErrorSign(object) {
    const errorSign = new THREE.Object3D()
    errorSign.add(Primitives.getWarningSign())

    // Set scale of the sign based on object size
    const clone = object.obj3d.clone()
    clone.rotation.set(0, 0, 0)
    const box = new THREE.Box3().setFromObject(clone)
    const length = Math.abs(box.max.x - box.min.x)
    const width = Math.abs(box.max.y - box.min.y)
    const offset = width > length ? length : width
    const scale = offset * 0.1
    errorSign.scale.set(scale, scale, 1)

    // Set sign just above the object
    const height = Math.abs(box.max.z - box.min.z) + 0.1
    errorSign.position.set(0, 0, height)

    // Keep sign rotation regardless of object rotation
    const objectRotation = -object.obj3d.rotation.z
    errorSign.rotation.set(0, 0, objectRotation)

    return errorSign
  }

  static getPolyline(
    points,
    useSegments,
    color = 0x0000ff,
    thickness,
    randomColors
  ) {
    const material = new THREE.LineBasicMaterial({
      color,
      depthTest: false,
      linewidth: 3,
    })

    const geometry = new THREE.BufferGeometry()
    const verts = points.map(point => [point[0], point[1], 0]).flat()
    geometry.setAttribute('position', new THREE.BufferAttribute(verts, 3))

    let line

    if (useSegments) {
      line = new THREE.Object3D()
      for (let i = 0; i < verts.length; i += 2) {
        if (randomColors) {
          color = Math.random() * 0xffffff
        }
        line.add(Primitives.getLine(verts[i], verts[i + 1], color, thickness))
      }
    } else {
      line = new THREE.Line(geometry, material)
    }

    return line
  }

  static getLine(start, end, color = 0xff6417, thickness = 0.2) {
    const verts = Primitives._triangleStripForLineEndPoints(
      start,
      end,
      thickness
    )
    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute('position', new THREE.BufferAttribute(verts, 3))

    const meshLine = new THREE.Mesh(
      geometry,
      new THREE.MeshBasicMaterial({
        color,
        side: THREE.DoubleSide,
        transparent: true,
        depthTest: false,
      })
    )
    meshLine.renderOrder = getRenderOrder('snapRegions')

    // We place this second line over the mesh-based line since it's
    // rendered with GL_LINE and will always render at 1px thickness
    // independent of camera distance.
    const glLine = new THREE.Line(
      geometry,
      new THREE.LineBasicMaterial({
        color,
        transparent: true,
        depthTest: false,
      })
    )
    glLine.renderOrder = getRenderOrder('snapRegions')

    meshLine.add(glLine)

    return meshLine
  }

  static getTextSprite(text, fontSize = 36, color = '#444444') {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    ctx.font = `${fontSize}px Arial`
    ctx.textAlign = 'center'
    ctx.textBaseline = 'top'

    // setting canvas width/height before ctx draw, else canvas is empty
    const strWidth = ctx.measureText(text).width
    canvas.width = strWidth
    canvas.height = fontSize // fontSize * 1.5

    // after setting the canvas width/height we have to re-set font to apply!?! looks like ctx reset
    ctx.font = `${fontSize}px Arial`
    ctx.textAlign = 'center'
    ctx.fillStyle = color
    ctx.textBaseline = 'top'
    ctx.fillText(text, canvas.width / 2, 0)

    const texture = new THREE.Texture(canvas)
    texture.wrapS = THREE.ClampToEdgeWrapping
    texture.wrapT = THREE.ClampToEdgeWrapping
    texture.minFilter = THREE.LinearFilter
    texture.needsUpdate = true

    const width = strWidth / 20
    const height = fontSize / 20

    const geometry = new THREE.PlaneGeometry(width, height, 1)

    const material = new THREE.MeshBasicMaterial({
      side: THREE.FrontSide,
      map: texture,
      transparent: true,
      depthTest: false,
    })

    const spriteMesh = new THREE.Mesh(geometry, material)

    spriteMesh.width = width
    spriteMesh.height = height

    return spriteMesh
  }

  static getTubeMesh(startPoint, endPoint, color = 0xff0000, thickness = 2) {
    const direction = endPoint.clone().sub(startPoint)
    const length = direction.length()
    const midpoint = direction
      .clone()
      .multiplyScalar(0.5)
      .add(startPoint)

    direction.normalize()

    const geometry = new THREE.CylinderGeometry(
      Units.inchesToNative(thickness),
      Units.inchesToNative(thickness),
      length
    )
    const material = new THREE.MeshBasicMaterial({
      color,
      side: THREE.DoubleSide,
      depthTest: false,
    })
    const lineMesh = new THREE.Mesh(geometry, material)

    const quat = new THREE.Quaternion()
    quat.setFromUnitVectors(new THREE.Vector3(0, 1, 0), direction)

    lineMesh.setRotationFromQuaternion(quat)
    lineMesh.position.copy(midpoint)

    lineMesh.material.depthTest = false
    lineMesh.material.transparent = true
    lineMesh.material.opacity = 0.25

    return lineMesh
  }

  static getCylinderMesh(ellipsePoints, height) {
    // Ensure points are 2d
    ellipsePoints = ellipsePoints.map(point => [point[0], point[1]])

    // Flatten points
    ellipsePoints = ellipsePoints.reduce(
      (array, vert) => array.concat(vert),
      []
    )
    const sideWallTriPoints = Primitives._sideWallTrianglePointsForPolygon(
      ellipsePoints,
      height,
      true
    )

    const topTriIndices = earcut(ellipsePoints)
    const topTriPoints = Primitives._indicesTo3DPoints(
      topTriIndices,
      ellipsePoints,
      height
    )

    const allTriPoints = sideWallTriPoints.concat(topTriPoints)

    return Primitives._getMeshFromTrianglePoints(allTriPoints)
  }

  static getCustomMesh(points, height) {
    // Ensure points are 2d
    let convertedPoints = points.map(point => [point.x, point.y])

    // Flatten points
    convertedPoints = convertedPoints.reduce(
      (array, vert) => array.concat(vert),
      []
    )
    const sideWallTriPoints = Primitives._sideWallTrianglePointsForPolygon(
      convertedPoints,
      height,
      points.length < 3 ? true : !Util.pointsAreCounterClockwise(points)
    )

    const topTriIndices = earcut(convertedPoints)
    const topTriPoints = Primitives._indicesTo3DPoints(
      topTriIndices,
      convertedPoints,
      height
    )
    const bottomTriPoints = Primitives._indicesTo3DPoints(
      topTriIndices,
      convertedPoints,
      0
    )

    const allTriPoints = sideWallTriPoints.concat(topTriPoints, bottomTriPoints)

    return Primitives._getMeshFromTrianglePoints(allTriPoints)
  }

  /*
    Returns a text sprite showing the length of a polygon/polyline edge in
    feet and inches. If the edge is part of a polygon, the label will be positioned
    in the interior of the polygon of the points are given in counter-clockwise order
    and on the outside if the ordering is clockwise.
  */
  static getEdgeLengthLabel(startPoint, endPoint, margin, color) {
    const edgeVec = endPoint.clone().sub(startPoint)

    const orthoVec = edgeVec
      .clone()
      .normalize()
      .applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2)

    const labelPos = orthoVec.clone().multiplyScalar(margin)

    // These are used to move the zero-margin label off of edge. The reason it's on
    // the edge in the first place is that the label's position is based on its center,
    // so in the case of a vertical edge, for instance, we want to shift the label on
    // its x-axis by half the label's width.
    const xShiftVec = orthoVec.clone()
    const yShiftVec = orthoVec.clone()
    xShiftVec.y = 0
    yShiftVec.x = 0

    const label = Primitives.getTextSprite(
      Units.toDistanceString(edgeVec.length()),
      undefined,
      color
    )
    xShiftVec.multiplyScalar(label.width / 2)
    yShiftVec.multiplyScalar(label.height / 2)
    labelPos.add(xShiftVec)
    labelPos.add(yShiftVec)
    label.position.copy(labelPos)

    return label
  }

  static getWallMesh(centerLinePoints, thicknesses, heights, materials) {
    let thicknessesArray
    let heightsArray

    if (Array.isArray(thicknesses)) {
      thicknessesArray = thicknesses
    } else {
      thicknessesArray = centerLinePoints.map(() => thicknesses)
    }

    if (Array.isArray(heights)) {
      heightsArray = heights
    } else {
      heightsArray = centerLinePoints.map(() => heights)
    }

    const wallSegmentPolygons = Primitives._getWallSegmentPolygons(
      centerLinePoints,
      thicknessesArray
    )

    let allTriPoints = []
    const vertexGroups = []

    // Generate all the triangle points on each surface of each wall segment
    // and add them to allTriPoints.
    wallSegmentPolygons.forEach((segmentPoly, i) => {
      const vertexIndex = allTriPoints.length / 3 // Divide by three since allTriPoints stores vector components directly
      const vertexGroup = {
        start: vertexIndex,
        materialIndex: materials ? materials[i] : 0,
      }
      const wallSegmentHeight = heightsArray[i]

      const flatSegmentPolyVerts = segmentPoly.reduce(
        (array, vert) => array.concat(vert),
        []
      )

      // Triangulate the segment polygon (top-down view of the wall segment)
      const topTriIndices = earcut(flatSegmentPolyVerts)
      const topTriPoints = Primitives._indicesTo3DPoints(
        topTriIndices,
        flatSegmentPolyVerts,
        wallSegmentHeight
      )

      allTriPoints = allTriPoints.concat(topTriPoints)

      const sideSurfaceTriPoints = Primitives._sideWallTrianglePointsForPolygon(
        flatSegmentPolyVerts,
        wallSegmentHeight,
        true
      )

      allTriPoints = allTriPoints.concat(sideSurfaceTriPoints)

      vertexGroup.count = allTriPoints.length / 3 - vertexGroup.start
      vertexGroups.push(vertexGroup)
    })

    const selectedMaterial = new THREE.MeshBasicMaterial({
      color: 0xd3d3eb,
      side: THREE.DoubleSide,
    })

    const mesh = Primitives._getMeshFromTrianglePoints(allTriPoints, {
      additionalMaterials: [selectedMaterial],
    })

    vertexGroups.forEach(group => {
      mesh.geometry.addGroup(group.start, group.count, group.materialIndex)
    })

    return mesh
  }

  static getRoofMesh(allPoints, elevationPoints, roofHeight, edges, color) {
    // Used cdt2d to perform constrained Delaunay triangulation on our set of points.
    // The triangulation will ensure that each 'edge' we pass it is the side of some triangle.
    // cdt2d returns indices into allPoints.
    const triIndices = cdt2d(allPoints.slice(), edges, { exterior: false })

    // Collect points from the indices
    const triPoints = triIndices.reduce(
      (array, triangle) =>
        array
          .concat([allPoints[triangle[0]]])
          .concat([allPoints[triangle[1]]])
          .concat([allPoints[triangle[2]]]),
      []
    )

    // Our triangle points are all at z = 0 at this point. This function is used to find the correct
    // height for points which overlap an elevation point.
    const heightForPoint = point => {
      const matchingElevationPoint = elevationPoints.find(
        elevationPoint =>
          Math.abs(elevationPoint.x - point[0]) < Units.inchesToNative(0.5) &&
          Math.abs(elevationPoint.y - point[1]) < Units.inchesToNative(0.5)
      )

      if (matchingElevationPoint) {
        return matchingElevationPoint.z - roofHeight
      }
      return 0
    }

    // Flatten the triangle points into an array of floats, while settng correct Z values with heightForPoint(...)
    const flatTriPointsWithHeight = triPoints.reduce(
      (array, point) =>
        array
          .concat(point[0])
          .concat(point[1])
          .concat(heightForPoint(point)),
      []
    )

    const material = new THREE.MeshPhongMaterial({
      color,
      emissive: 0x000000,
      specular: 0x222222,
      emissiveIntensity: 0.6,
      shininess: 10,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.6,
    })

    const roofMesh = this._getMeshFromTrianglePoints(flatTriPointsWithHeight, {
      customMaterial: material,
    })

    roofMesh.userData.triPoints = flatTriPointsWithHeight

    if (!triPoints.length) roofMesh.userData.hasInvalidPoints = true

    const geometry = new THREE.BufferGeometry()
    const vertices = new Float32Array(flatTriPointsWithHeight)

    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3))
    geometry.computeVertexNormals()

    const edgesGeometry = new THREE.EdgesGeometry(geometry)
    const lines = new THREE.LineSegments(edgesGeometry, new THREE.LineBasicMaterial({ color }))

    roofMesh.add(lines)

    return roofMesh
  }

  static getCrosshairCursorVisual(gapSize, lineLength, lineThickness, color) {
    const line1 = Primitives.getLine(
      new THREE.Vector3(0, gapSize / 2, 0),
      new THREE.Vector3(0, gapSize / 2 + lineLength, 0),
      color,
      lineThickness
    )
    const line2 = Primitives.getLine(
      new THREE.Vector3(gapSize / 2, 0, 0),
      new THREE.Vector3(gapSize / 2 + lineLength, 0, 0),
      color,
      lineThickness
    )
    const line3 = Primitives.getLine(
      new THREE.Vector3(0, -gapSize / 2, 0),
      new THREE.Vector3(0, -gapSize / 2 - lineLength, 0),
      color,
      lineThickness
    )
    const line4 = Primitives.getLine(
      new THREE.Vector3(-gapSize / 2, 0, 0),
      new THREE.Vector3(-gapSize / 2 - lineLength, 0, 0),
      color,
      lineThickness
    )

    const parent = new THREE.Object3D()
    parent.add(line1)
    parent.add(line2)
    parent.add(line3)
    parent.add(line4)

    parent.children.forEach(child => {
      // set 'transparent' to true to get later render order
      child.material.transparent = true
    })

    return parent
  }

  // Returns a plane shaped mesh of the given size with a texture
  // applied to it producted by a custom shader which renders a
  // relatively zoom-independent, anti-aliased green circle.
  static getCircle(size) {
    const geometry = new THREE.PlaneGeometry(size, size)

    const vertexShader = `
      varying vec2 vUv;

      void main()
      {
        vUv = uv;
        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        gl_Position = projectionMatrix * mvPosition;
      }
    `

    const fragmentShader = `
      uniform vec2 planeSize;
      varying vec2 vUv;

      void main( void ) {
        vec2 localCoord = vec2(vUv.x * planeSize.x, vUv.y * planeSize.y);
        vec2 halfPlaneSize = planeSize / 2.;

        // This way our target visual doesn't have to match the plane size
        const float baseScale = 0.4;

        // Scale to compensate for camera zoom
        float scale = min(1., dFdx(localCoord.x)) * baseScale;

        float distFromCenter = length(localCoord - halfPlaneSize);

        float withinTarget = smoothstep(halfPlaneSize.x, halfPlaneSize.x - 8., distFromCenter / scale);

        if (withinTarget > 0.) {
          float normalizedDistance = distFromCenter / halfPlaneSize.x;
          float distance = normalizedDistance / scale;
          float visibleBand = smoothstep(0.7, 0.8, distance);
          vec3 color = vec3(0.56, 0.85, 0.56);

          gl_FragColor = vec4(color, visibleBand * withinTarget);
        } else { // This way we can skip the other computations if we're outside the target
          gl_FragColor = vec4(0.);
        }
      }
    `

    const uniforms = {
      time: { value: Primitives.clock.getElapsedTime() },
      planeSize: { value: new THREE.Vector2(size, size) },
    }

    const material = new THREE.ShaderMaterial({
      uniforms,
      vertexShader,
      fragmentShader,
      side: THREE.FrontSide,
      transparent: true,
      depthTest: false,
      extensions: { derivatives: true },
    })
    const mesh = new THREE.Mesh(geometry, material)

    return mesh
  }

  static getOutlineMaterial(
    polygonPoints,
    outlineColor = Math.random() * 0xffffff,
    outlineWidth = Units.feetToNative(3),
    outlineOpacity = 1.0
  ) {
    polygonPoints = [...polygonPoints]
    let outlineColorObj = new THREE.Color(outlineColor)

    // Convert to object style if necessary
    if (polygonPoints[0].x === undefined && polygonPoints[0][0] !== undefined) {
      polygonPoints = polygonPoints.map(
        point => new THREE.Vector2(point[0], point[1])
      )
    } else if (!(polygonPoints[0] instanceof THREE.Vector2)) {
      // It's important that these are actually Vector2's and not
      // e.g. Vector3's since the data layout will be different
      polygonPoints = polygonPoints.map(
        point => new THREE.Vector2(point.x, point.y)
      )
    }

    // Add start point at end so we complete our perimeter on uneven count edges
    polygonPoints.push(new THREE.Vector2().copy(polygonPoints[0]))

    const vertexShader = `
      varying vec3 worldPos;

      void main()
      {
        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        worldPos = position;
        gl_Position = projectionMatrix * mvPosition;
      }
    `

    const fragmentShader = `
      varying vec3 worldPos;
      uniform vec2 verts[ ${polygonPoints.length} ];
      uniform vec3 outlineColor;
      uniform float outlineWidth;
      uniform float outlineOpacity;

      // Return minimum distance between line segment vw and point p
      // https://stackoverflow.com/questions/849211/shortest-distance-between-a-point-and-a-line-segment
      float distanceToSeg(vec2 v, vec2 w, vec2 p) {
        float length = distance(v, w);
        if (length == 0.0) return distance(p, v);
        float t = max(0.0, min(1., dot(p - v, w - v) / (length*length)));
        vec2 projection = v + t * (w - v);
        return distance(p, projection);
      }

      void main( void ) {
        float smallestDistance = distanceToSeg(verts[0], verts[1], worldPos.xy);
        for(int i = 1; i < ${polygonPoints.length - 1}; i++) {
         vec2 vert1 = verts[i];
         vec2 vert2 = verts[i+1];
         float dist = distanceToSeg(vert1, vert2, worldPos.xy);

         if (dist < smallestDistance) {
          smallestDistance = dist;
         }
        }

        float normalizedDistance = smallestDistance / outlineWidth;
        float alpha = max(1. - step(0.5, normalizedDistance), 1. - smoothstep(0.5, 1.0, normalizedDistance));
        gl_FragColor = vec4(outlineColor.xyz, alpha * outlineOpacity);
      }
    `

    const uniforms = {
      verts: { type: 'v2v', value: polygonPoints },
      outlineColor: {
        type: 'uVec3',
        value: new THREE.Vector3(
          outlineColorObj.r,
          outlineColorObj.g,
          outlineColorObj.b
        ),
      },
      outlineWidth: { type: 'f', value: outlineWidth },
      outlineOpacity: { type: 'f', value: outlineOpacity },
    }

    return new THREE.ShaderMaterial({
      uniforms,
      vertexShader,
      fragmentShader,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 1,
    })
  }

  static getRegionMesh(
    points,
    color = Math.random() * 0xffffff,
    material,
    transformationFunc
  ) {
    if (points.length > 0 && points[0].x !== undefined) {
      points = points.map(point => [point.x, point.y])
    }

    // Convert points to 2D if necessary
    if (points.length > 0 && points[0].length > 2) {
      points = points.map(point => [point[0], point[1]])
    }

    const flatPoints = points.reduce((array, point) => array.concat(point), [])

    const triIndices = earcut(flatPoints)
    let triPoints = Primitives._indicesTo3DPoints(triIndices, flatPoints, 0.01)

    if (transformationFunc) {
      const groupedPoints = []
      for (let i = 0; i < triPoints.length; i += 3) {
        groupedPoints[i / 3] = [
          triPoints[i + 0],
          triPoints[i + 1],
          triPoints[i + 2],
        ]
      }
      triPoints = groupedPoints
        .map(point => transformationFunc(point))
        .reduce((array, point) => array.concat([point.x, point.y, point.z]), [])
    }

    material =
      material ||
      new THREE.MeshBasicMaterial({
        color,
        transparent: true,
        opacity: 0.5,
        side: THREE.DoubleSide,
      })

    return this._getMeshFromTrianglePoints(triPoints, {
      customMaterial: material,
    })
  }

  static getDragHandle(distanceToEdge, size = 's') {
    const dragHandle = new THREE.Mesh(
      size === 's' ? Primitives.dragGeometry : Primitives.largeDragGeometry,
      Primitives.dragMaterial
    )
    dragHandle.position.set(distanceToEdge, 0, 0.09)
    dragHandle.userData.objectType = OBJECT_TYPES.DRAG_HANDLE

    const upArrow = Primitives.getArrowMesh()
    upArrow.position.set(0, 0.05, 0.1)
    upArrow.material.color.setHex(getThreeHexFromTheme('three.dark'))

    const downArrow = Primitives.getArrowMesh()
    downArrow.position.set(0, -0.05, 0.1)
    downArrow.rotation.set(0, 0, (180 * Math.PI) / 180)
    downArrow.material.color.setHex(getThreeHexFromTheme('three.dark'))

    const ring = new THREE.Mesh(
      size === 's' ? Primitives.ringGeometry : Primitives.largeRingGeometry,
      Primitives.ringMaterial
    )
    ring.position.set(0, 0, 0.1)

    dragHandle.add(upArrow)
    dragHandle.add(downArrow)
    dragHandle.add(ring)

    return dragHandle
  }

  static getArrowMesh() {
    const geometry = new THREE.BufferGeometry()
    const vertices = new Float32Array([
      0, 2.5, 0, // v1
      1, 0, 0, // v2
      0, 0.5, 0, // v3
      -1, 0, 0, // v4
    ])
    const faces = [
      0, 1, 2,
      3, 0, 2,
    ]
    geometry.setIndex(faces)
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3))

    const material = new THREE.MeshBasicMaterial({
      color: getThreeHexFromTheme('three.objects.product.directionArrow'),
      transparent: true,
      opacity: 0.9,
      side: THREE.DoubleSide,
    })

    return new THREE.Mesh(geometry, material)
  }

  static getHeaterArrowMesh() {
    const geometry = new THREE.BufferGeometry()
    const vertices = new Float32Array([
      0, 9, 0,
      5, 0, 0,
      0, 3, 0,
      -5, 0, 0,
    ])
    const faces = [
      0, 1, 2,
      3, 0, 2,
    ]
    geometry.setIndex(faces)
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3))

    const material = new THREE.MeshBasicMaterial({
      color: getThreeHexFromTheme('three.objects.product.directionArrow'),
      transparent: true,
      opacity: 0.9,
      side: THREE.DoubleSide,
    })

    return new THREE.Mesh(geometry, material)
  }

  static getFloorMesh(insetPoints) {
    const flatInsetPoints = insetPoints.reduce(
      (array, point) => array.concat(point),
      []
    )

    const floorTriIndices = earcut(flatInsetPoints)
    const floorTriPoints = Primitives._indicesTo3DPoints(
      floorTriIndices,
      flatInsetPoints,
      0.01
    )

    const vertexShader = `
      varying vec3 pos;

      void main()
      {
        pos = position;
        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        gl_Position = projectionMatrix * mvPosition;
      }
    `

    const fragmentShader = `
      uniform float thickness;
      uniform bool selected;
      uniform float opacity;
      varying vec3 pos;

      void main( void ) {
        highp vec2 position = pos.xy;
        highp float lineWidth = thickness;

        vec3 lineColor = vec3(0.94, 0.94, 0.94);
        vec3 bgColor = vec3(0.975, 0.975, 0.975);

        float yMod = mod(position.y, thickness);
        float maxMod = yMod;

        vec3 color = mix(bgColor, lineColor, min(step(thickness - lineWidth/2., maxMod), step(lineWidth/2., maxMod)));

        if (selected) {
          color.z += 1.0;
          color.y -= 0.05;
          color.x -= 0.05;
        }

        gl_FragColor = vec4( color, opacity );
      }
    `

    const uniforms = {
      resolution: { value: new THREE.Vector2(1, 1) },
      thickness: { value: 1.5 },
      selected: { value: false },
      opacity: { value: 0.5 },
    }

    const material = new THREE.ShaderMaterial({
      uniforms,
      vertexShader,
      fragmentShader,
      side: THREE.FrontSide,
      transparent: true,
      depthWrite: false,
    })

    const mesh = this._getMeshFromTrianglePoints(floorTriPoints, {
      customMaterial: material,
    })

    mesh.renderOrder = getRenderOrder('floor')

    return mesh
  }

  static _getMeshFromTrianglePoints(points, options = {}, debug = false) {
    const customMaterial = options.customMaterial
    const additionalMaterials = options.additionalMaterials

    const geometry = new THREE.BufferGeometry()
    const vertices = new Float32Array(points)

    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3))
    geometry.computeVertexNormals()

    let material = customMaterial

    if (material === undefined) {
      material = new THREE.MeshLambertMaterial({
        color: 0x888888,
        side: THREE.DoubleSide,
      })
    }

    let transparencyLevels = [material]
    for (let i = 9; i >= 0; i--) {
      let opaqueMaterial = new THREE.MeshLambertMaterial({
        color: 0x888888,
        transparent: true,
        opacity: parseFloat((i / 10).toFixed(1)) + (i === 0 ? 0.01 : 0),
        depthWrite: false,
      })
      transparencyLevels.push(opaqueMaterial)
    }

    let mesh

    if (additionalMaterials === undefined) {
      mesh = new THREE.Mesh(geometry, material)
    } else {
      mesh = new THREE.Mesh(
        geometry,
        transparencyLevels.concat(additionalMaterials)
      )
    }

    if (debug) {
      const wireframe = new THREE.WireframeGeometry(geometry)

      const line = new THREE.LineSegments(
        wireframe,
        new THREE.LineBasicMaterial({
          color: 0x0000ff,
          linewidth: 3,
        })
      )

      return line
    }

    return mesh
  }

  /*
      Returns an array of polygons (as arrays of points), one for each
      edge of the given wall center line. Each of the returned polygons
      corresponds to one segment of a wall.
    */
  static _getWallSegmentPolygons(centerLinePoints, thicknesses) {
    const offsetData = Offset.offset(centerLinePoints, thicknesses)

    return offsetData.edgePolygons
  }

  static _indicesTo3DPoints(indices, points, zVal) {
    const verts = []

    indices.forEach(index => {
      verts.push(points[index * 2])
      verts.push(points[index * 2 + 1])
      verts.push(zVal)
    })

    return verts
  }

  static _triangleStripForLineEndPoints(startPoint, endPoint, thickness) {
    let triPoints
    const lineVec = new THREE.Vector3()
    let orthoVec = new THREE.Vector3()

    lineVec.subVectors(endPoint, startPoint)
    let lineLength = lineVec.length()

    // Extend lines by half their thickness, otherwise you
    // get gaps in the corners
    lineVec.multiplyScalar(1 + thickness / 2 / lineLength)
    lineLength = lineVec.length()

    lineVec.normalize()
    orthoVec = lineVec
      .clone()
      .applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2)

    const lastTriPoint = startPoint
      .clone()
      .addScaledVector(orthoVec, -thickness / 2)
    triPoints = lastTriPoint.toArray()
    lastTriPoint.addScaledVector(orthoVec, thickness)
    triPoints = triPoints.concat(lastTriPoint.toArray())
    lastTriPoint.addScaledVector(orthoVec, -thickness)
    lastTriPoint.addScaledVector(lineVec, lineLength / 2)
    triPoints = triPoints.concat(lastTriPoint.toArray())
    lastTriPoint.addScaledVector(lineVec, lineLength / 2)
    lastTriPoint.addScaledVector(orthoVec, thickness)
    triPoints = triPoints.concat(lastTriPoint.toArray())
    lastTriPoint.addScaledVector(orthoVec, -thickness)
    triPoints = triPoints.concat(lastTriPoint.toArray())

    return new Float32Array(triPoints)
  }

  /*
      Given a polygon (as an array of points) representing the top-down view of
      the center line of a wall, this generates the 3D geometry of the surfaces
      on the sides of the wall.
    */
  static _sideWallTrianglePointsForPolygon(
    polygonPoints,
    height,
    clockwise = false
  ) {
    let triPoints = []

    for (let i = 0; i < polygonPoints.length; i += 2) {
      const startPoint = new THREE.Vector3(
        polygonPoints[i],
        polygonPoints[i + 1],
        height
      )
      const endPoint = new THREE.Vector3(
        polygonPoints[(i + 2) % polygonPoints.length],
        polygonPoints[(i + 3) % polygonPoints.length],
        height
      )

      triPoints.push([startPoint.x, startPoint.y, startPoint.z])
      triPoints.push([startPoint.x, startPoint.y, startPoint.z - height])
      triPoints.push([endPoint.x, endPoint.y, endPoint.z - height])

      triPoints.push([endPoint.x, endPoint.y, endPoint.z - height])
      triPoints.push([endPoint.x, endPoint.y, endPoint.z])
      triPoints.push([startPoint.x, startPoint.y, startPoint.z])
    }

    if (clockwise) {
      triPoints.reverse()
    }

    // flatten
    triPoints = triPoints.reduce((array, point) => array.concat(point), [])

    return triPoints
  }
}

export default Primitives
