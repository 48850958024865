import styled from 'styled-components'

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex: 0.25;
  flex-direction: row;
  justify-content: flex-end;
`

export default Actions
