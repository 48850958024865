import { graphql } from '~/gql'

const GET_SNAPSHOT_QUERY = graphql(`
  query getSnapshot($snapshotId: ID, $cloudinaryId: String) {
    getSnapshot(snapshotId: $snapshotId, cloudinaryId: $cloudinaryId) {
      ...SnapshotFragment
    }
  }
`)

export default GET_SNAPSHOT_QUERY
