import styled, { css } from 'styled-components'

import { lighten, breakpoints } from 'lib/styleUtils'

const getOppositeSide = side => {
  switch (side) {
    case 'right':
      return 'left'
    case 'left':
      return 'right'
    default:
      return null
  }
}

const setMargin = ({ space, separator, theme }) => {
  let value

  switch (space) {
    case 's':
      value = theme.spacing.s
      break
    case 'base':
      value = theme.spacing.base
      break
    default:
      value = 0
  }

  if (!separator) {
    return `margin-right: ${value};`
  }

  return `margin-${getOppositeSide(separator)}: ${value};`
}

const setPaddingLeft = props => {
  if (props.paddingLeft) {
    return css`
      padding-left: ${props.theme.spacing[props.paddingLeft]};
    `
  }

  // This padding is necessary. The extra padding is needed for tapping the caret icon
  if (props.responsive) {
    return css`
      ${breakpoints.s`
        padding-left: ${props.theme.spacing.xs};
      `};
    `
  }

  return null
}

const setPaddingRight = props => {
  if (props.paddingRight) {
    return css`
      padding-right: ${props.theme.spacing[props.paddingRight]};
    `
  }

  // This padding is necessary. The extra padding is needed for tapping the caret icon
  if (props.responsive) {
    return css`
      ${breakpoints.s`
        padding-right: ${props.theme.spacing.xs};
    `};
    `
  }

  return null
}

const setSeparator = ({ separator, theme }) =>
  `
    &::after {
      background-color: ${theme.colors.light.subdued};
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      ${separator === 'right' ? `right: 0;` : `left: 0;`}
      top: 0;
      width: 1px;
    }
  `

const activeBackground = props => {
  if (props.variant === 'dark') {
    return `
      background-color: ${lighten(props.theme.colors.dark.bg, 0.2)};
    `
  }

  return `
    background-color: ${lighten(props.theme.colors.light.subdued, 0.2)};
  `
}

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;

  ${setMargin};
  ${setPaddingLeft};
  ${setPaddingRight};

  ${props => props.active && activeBackground};
  ${props =>
    props.borderLeft &&
    `border-left: 1px solid ${props.theme.colors.light.subdued}`};
  ${props =>
    props.borderRight &&
    `border-right: 1px solid ${props.theme.colors.light.subdued}`};
  ${props => props.fullWidth && `width: 100%;`};
  ${props => props.separator && setSeparator(props)};
`

export default Container
