import defaultTo from 'lodash-es/defaultTo'
import get from 'lodash-es/get'
import Color from 'color'
import isEqual from 'lodash-es/isEqual'
import sortBy from 'lodash-es/sortBy'
import Util from './util'
import Units from './units'
import Primitives from './primitives'
import Roof from './roof'
import Wall from './wall'
import Facility from './facility'
import store from 'store'
import { objectIsSelected } from 'store/selectedObjects/selectors'
import { updateRoofSection } from 'store/objects'
import getRenderOrder from 'config/canvasRenderOrder'
import intersect from './polygonIntersection'
import {
  primaryMountingTypes,
  primaryToSecondaryTypes,
  structureStyles,
} from 'config/mountingStructures'
import OBJECT_TYPES from 'config/objectTypes'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import CLICK_PRIORITY from 'config/clickPriority'
import { getThreeHexFromTheme } from 'lib/utils'

import * as THREE from 'three'
const Z_AXIS = new THREE.Vector3(0, 0, 1)
const COLUMN_THICKNESS = 4

class RoofSection {
  constructor(model, units) {
    this.id = model.id
    this.className = CLASS_NAMES.ROOF_SECTION
    this.roofId = model.roofId
    this.wallId = model.wallId
    this.units = units
    this.layerKey = defaultTo(model.category, model.layerKey)
    this.clickPriority = CLICK_PRIORITY[this.layerKey]
    this.perimeterPoints = model.perimeterPoints.map(point => [
      Units.unitsToNative(units, point[0]),
      Units.unitsToNative(units, point[1]),
      0,
    ])
    this.height = Units.unitsToNative(units, model.height)
    this.color = getThreeHexFromTheme('three.objects.roofSection.default')
    this.beamWidth = Units.unitsToNative(units, model.beamWidth)
    this.beamDepth = Units.unitsToNative(units, model.beamDepth)
    this.beamSpacing = Units.unitsToNative(units, model.beamSpacing)
    this.beamShift = Units.unitsToNative(units, model.beamShift)
    this.beamRotation = model.beamRotation
    this.hasColumns = model.hasColumns
    this.primaryStructuresEnabled = model.primaryStructuresEnabled
    const columnSpacing = model.columnSpacing || 144
    this.columnSpacing = Units.unitsToNative(units, columnSpacing)
    const columnShift = model.columnShift || 0
    this.columnShift = Units.unitsToNative(units, columnShift)
    this.primaryStructureType = model.primaryStructureType
    this.secondaryStructureType = model.secondaryStructureType
    this.columnNames =
      model.columnNames ||
      Array.from(Array(26))
        .map((e, i) => i + 65)
        .map(x => String.fromCharCode(x))
    this.beamNames = model.beamNames || Array.from(Array(26)).map((e, i) => i)
    this.secondaryStructureDepth = Units.unitsToNative(
      units,
      model.secondaryStructureDepth
    )
    this.structureStyle = model.structureStyle || structureStyles[0].value
    this.secondaryStructureStyle =
      model.secondaryStructureStyle || structureStyles[0].value
    this.beamOrientation = new THREE.Vector3(0, -1, 0).applyAxisAngle(
      Z_AXIS,
      this.beamRotation
    )
    this.columnWidth =
      Units.unitsToNative(units, model.columnWidth) || COLUMN_THICKNESS
    this.columnLength = this.columnWidth // columns are to be square. Why?  ¯\_(ツ)_/¯
    this.isCylinder = model.isCylinder || false

    if (model.beamModels) {
      this.beamModels = model.beamModels.map(model => ({
        id: model.id,
        position: Units.unitsToNativeV(this.units, model.position),
        edited: model.edited,
      }))
    }
    if (model.columnLineModels) {
      this.columnLineModels = model.columnLineModels.map(model => ({
        id: model.id,
        position: Units.unitsToNativeV(this.units, model.position),
        edited: model.edited,
      }))
    }

    // Don't render secondary structure visual if there isn't
    // supposed to be any secondary structure
    if (!this.secondaryStructureType) {
      this.secondaryVisible = false
    }

    this.obj3d = new THREE.Object3D()

    // Used by Interactifier
    this.selectable = true
    this.draggable = false

    // 3D Model and Column lines
    const beamAndColumnData = this.updateBeamAndColumnLayout()
    if (beamAndColumnData) {
      this.createVisual(
        beamAndColumnData.augmentedBeamModels,
        beamAndColumnData.augmentedColumnLineModels
      )
    }
  }

  generateBeamModels() {
    const perimeterPoints = Util.toVec3Array(this.perimeterPoints).map(
      point => new THREE.Vector3(point.x, point.y, 0)
    )
    const beamSpacing = this.beamSpacing
    const beamShift = -this.beamShift
    const orthoOrientation = this.getOrthoOrientation()

    // The points within our roof section where beams will be placed
    // given the current configuration
    const beamPositions = this.getPositions(
      this.beamOrientation,
      orthoOrientation,
      beamSpacing,
      beamShift,
      perimeterPoints
    )

    // Some positions may be invalid but we won't know until
    // we try making line segments for them. The invalid positions
    // correspond to null line segments; we use that to filter
    // the original position list.
    const beamLineSegments = this.getLineSegmentsForPoints(
      beamPositions,
      this.beamOrientation,
      true
    ).filter(bls => bls !== null)

    const allBeamPositions = []
    for (let i = 0; i < beamLineSegments.length; ++i) {
      const bls = beamLineSegments[i]
      const brokenSegments = this.getBrokenSegments(bls, this.beamOrientation)
      for (let j = 0; j < brokenSegments.length; ++j) {
        const bs = brokenSegments[j]
        allBeamPositions.push(bs.point1)
      }
    }

    return allBeamPositions.map(position =>
      RoofSection.createBeamModel(position)
    )
  }

  generateColumnLineModels() {
    const perimeterPoints = Util.toVec3Array(this.perimeterPoints).map(
      point => new THREE.Vector3(point.x, point.y, 0)
    )
    const columnSpacing = this.columnSpacing
    const columnShift = -this.columnShift
    const orthoOrientation = this.getOrthoOrientation()
    const columnPositions = this.getPositions(
      orthoOrientation,
      this.beamOrientation,
      columnSpacing,
      columnShift,
      perimeterPoints
    )

    const columnLineSegments = this.getLineSegmentsForPoints(
      columnPositions,
      this.getOrthoOrientation(),
      true
    ).filter(cls => cls !== null)

    const allColumnPositions = []
    for (let i = 0; i < columnLineSegments.length; ++i) {
      const cls = columnLineSegments[i]
      const brokenSegments = this.getBrokenSegments(
        cls,
        this.getOrthoOrientation()
      )
      for (let j = 0; j < brokenSegments.length; ++j) {
        const bs = brokenSegments[j]
        allColumnPositions.push(bs.point1)
      }
    }

    return allColumnPositions.map(position =>
      RoofSection.createColumnLineModel(position)
    )
  }

  /*
    SceneBuilder event
  */
  sceneDidRebuild(facility, thisChanged) {
    const roofModel = this.getAssociatedRoofModel()
    const roofChanged = !isEqual(roofModel, this.previousRoofModel)

    if (thisChanged || roofChanged) {
      const beamAndColumnData = this.updateBeamAndColumnLayout()
      if (beamAndColumnData) {
        this.createVisual(
          beamAndColumnData.augmentedBeamModels,
          beamAndColumnData.augmentedColumnLineModels
        )
      }
    }
    this.previousRoofModel = roofModel
  }

  updateBeamAndColumnLayout() {
    let shouldUpdateRoofSection = false
    // Generate beam models if necessary
    if (!this.beamModels) {
      this.beamModels = this.generateBeamModels()
      shouldUpdateRoofSection = true
    }

    // Always re-calculate the line segments from the beam/column line positions
    // since the roof section perimeter might change (the segments extend
    // across the roof section perimeter).
    const beamLineSegments = this.getLineSegmentsForPoints(
      this.beamModels.map(model => model.position),
      this.beamOrientation
    ).filter(seg => seg !== null)

    // Augment models with segment endpoints
    const augmentedBeamModels = beamLineSegments.map((seg, i) => ({
      id: this.beamModels[i].id,
      position: this.beamModels[i].position,
      point1: seg.point1,
      point2: seg.point2,
    }))

    // Generate column line models if necessary
    if (!this.columnLineModels) {
      this.columnLineModels = this.generateColumnLineModels()
      shouldUpdateRoofSection = true
    }

    if (shouldUpdateRoofSection) {
      store.dispatch(updateRoofSection({ roofSection: this.toModel() }))
      return
    }

    const columnLineSegments = this.getLineSegmentsForPoints(
      this.columnLineModels.map(model => model.position),
      this.getOrthoOrientation()
    ).filter(seg => seg !== null)

    // Augment models with segment endpoints
    const augmentedColumnLineModels = columnLineSegments.map((seg, i) => ({
      id: this.columnLineModels[i].id,
      position: this.columnLineModels[i].position,
      point1: seg.point1,
      point2: seg.point2,
    }))

    return {
      augmentedColumnLineModels,
      augmentedBeamModels,
    }
  }

  getSortedModels(models, orientation) {
    const perimeterPoints = Util.toVec3Array(this.perimeterPoints)
    let sortedSegments = []
    const axis = orientation.x === 1 ? 'y' : 'x'
    const perpendicular = orientation.x === 1 ? 'x' : 'y'
    for (let i = 0; i < perimeterPoints.length - 1; i++) {
      const point1 = perimeterPoints[i]
      const point2 = perimeterPoints[i + 1]
      const max = Math.max(point1[axis], point2[axis])
      const min = Math.min(point1[axis], point2[axis])
      sortedSegments.push({
        max,
        min,
        point1,
        point2,
      })
    }

    if (axis === 'x') {
      sortedSegments = sortBy(sortedSegments, 'max')
    } else {
      sortedSegments = sortBy(sortedSegments, 'min').reverse()
    }
    let sortedBeamModels = []
    sortedSegments.forEach(seg => {
      let objCreated = false
      const index = sortedBeamModels.length
      for (let i = 0; i < models.length; ++i) {
        const model = models[i]
        if (
          Util.isPointOnSegment(seg.point1, model.point1, seg.point2) ||
          Util.isPointOnSegment(seg.point1, model.point2, seg.point2)
        ) {
          if (objCreated) {
            sortedBeamModels[index].models.push(model)
          } else {
            sortedBeamModels.push({
              wallSegment: seg,
              models: [model],
            })
            objCreated = true
          }
        }
      }
    })

    sortedBeamModels.forEach(({ models }) => {
      models = sortBy(models, `point1.${perpendicular}`)
    })

    return sortedBeamModels
  }

  /*
    Constructs the three.js visual representation
  */
  createVisual(augmentedBeamModels, augmentedColumnLineModels) {
    this.clearOldVisuals(this.obj3d)

    const primaryRoofPanels = this.getPanels(
      Facility.current.getRoofWithId(this.roofId)
    )
    const secondaryRoofPanels = this.getPanels(
      Facility.current.getRoofWithId(this.roofId),
      'secondary'
    )

    // Create and add primary mounting structure beams
    if (this.primaryStructuresEnabled) {
      const beams = this.createBeamMeshes(
        augmentedBeamModels,
        primaryRoofPanels
      )
      beams.forEach(beam => this.obj3d.add(beam))
    }

    // Create and add columns
    if (this.hasColumns) {
      this.columns = this.createColumnMeshes(
        augmentedBeamModels,
        augmentedColumnLineModels,
        primaryRoofPanels
      )
      this.columns.forEach(column => {
        this.obj3d.add(column)
      })
    }

    const mainMesh = this.createMainMesh(secondaryRoofPanels)
    this.obj3d.add(mainMesh)

    // Shift slightly to avoid z-fighting with roof mesh
    // NOTE: this will have to be taken account of if we use Raycasting
    // to check height from floor to mounting structure.
    const Z_FIGHT_SHIFT = Units.inchesToNative(1)
    this.obj3d.position.copy(
      new THREE.Vector3(0, 0, this.height - Z_FIGHT_SHIFT)
    )

    if (objectIsSelected(this.id)) {
      this.select()
    }

    const isVisible = store.getState().layers.layers[
      LAYER_KEYS.PRIMARY_MOUNTING_STRUCTURE
    ].visible

    this.visibilityChanged(isVisible)
  }

  getPanels(roof, target = 'primary') {
    const styleIsRoofConforming =
      this.structureStyle === structureStyles[0].value

    if (target === 'secondary') {
      // Roof-conforming style
      if (this.secondaryStructureStyle === structureStyles[0].value) {
        return roof.getPanels().filter(panel => panel.normal.z > 0.05)
      } else {
        // Flat style
        const polygon = this.perimeterPoints.map(
          point => new THREE.Vector3(point[0], point[1], 0)
        )
        return [
          {
            polygon,
            normal: new THREE.Vector3(0, 0, 1),
            centroid: Util.polygonCentroid(polygon),
            rotationParams: { axis: new THREE.Vector3(0, 0, 1), angle: 0 },
          },
        ]
      }
    }

    if (styleIsRoofConforming && !this.associatedRoofIsFlat()) {
      // Roof-conforming style
      // Filter to remove vertical roof panels (e.g. gables)
      return roof.getPanels().filter(panel => panel.normal.z > 0.05)
    } else {
      // Flat style
      const polygon = this.perimeterPoints.map(
        point => new THREE.Vector3(point[0], point[1], 0)
      )
      return [
        {
          polygon,
          normal: new THREE.Vector3(0, 0, 1),
          centroid: Util.polygonCentroid(polygon),
          rotationParams: { axis: new THREE.Vector3(0, 0, 1), angle: 0 },
        },
      ]
    }
  }

  createColumnMeshes(beamSegments, columnLineSegments, roofPanels) {
    const columnPositions = []

    // Columns are positioned at beam/column line intersection points
    beamSegments.forEach(beamSeg => {
      columnLineSegments.forEach(colLineSeg => {
        const intersection = Util.lineSegmentsIntersectionPoint(
          beamSeg.point1,
          beamSeg.point2,
          colLineSeg.point1,
          colLineSeg.point2
        )
        if (intersection) {
          columnPositions.push(intersection)
        }
      })
    })

    // Project 2D column positions onto roof panels
    const projectedColumnPositions = columnPositions
      .map(pos => {
        const panel = RoofSection.findRoofPanelForPoint(pos, roofPanels)

        if (panel) {
          const vec3Pos = new THREE.Vector3(pos.x, pos.y, 0)
          return Util.rayPlaneIntersectionPoint(
            vec3Pos,
            Z_AXIS,
            panel.centroid,
            panel.normal.clone().negate()
          )
        } else {
          return null
        }
      })
      .filter(a => !!a) // Remove nulls

    return projectedColumnPositions.map(pos => this.createColumnMesh(pos))
  }

  createColumnMesh(pos) {
    const columnHeight = pos.z + this.height - this.beamDepth
    let columnGeometry
    if (this.isCylinder) {
      columnGeometry = new THREE.CylinderGeometry(
        defaultTo(this.columnWidth, COLUMN_THICKNESS) / 2,
        defaultTo(this.columnLength, COLUMN_THICKNESS) / 2,
        columnHeight
      )
      columnGeometry.rotateX(-Math.PI * 0.5)
    } else {
      columnGeometry = new THREE.BoxGeometry(
        defaultTo(this.columnWidth, COLUMN_THICKNESS),
        defaultTo(this.columnLength, COLUMN_THICKNESS),
        columnHeight
      )
    }

    const columnMaterial = new THREE.MeshPhongMaterial({
      color: getThreeHexFromTheme('three.objects.roofSection.column.default'),
    })
    const column = new THREE.Mesh(columnGeometry, columnMaterial)
    column.position.set(pos.x, pos.y, columnHeight / 2 - this.height)
    column.userData.objectType = OBJECT_TYPES.COLUMN

    return column
  }

  static projectBeamSegmentsOnRoof(beamLineSegments, roofPanels) {
    return beamLineSegments
      .map(segment => {
        const panel = RoofSection.findRoofPanelForBeam(segment, roofPanels)
        if (!panel) {
          return null
        }

        const projectedPoint1 = Util.rayPlaneIntersectionPoint(
          segment.point1,
          Z_AXIS,
          panel.centroid,
          panel.normal.clone().negate()
        )
        const projectedPoint2 = Util.rayPlaneIntersectionPoint(
          segment.point2,
          Z_AXIS,
          panel.centroid,
          panel.normal.clone().negate()
        )

        if (projectedPoint1 && projectedPoint2) {
          return {
            point1: projectedPoint1,
            point2: projectedPoint2,
            rotationParams: panel.rotationParams,
          }
        } else {
          return null
        }
      })
      .filter(a => !!a) // Remove nulls
  }

  static findRoofPanelForBeam(beamLineSegment, roofPanels) {
    return roofPanels.find(panel => {
      const segCenter = beamLineSegment.point1
        .clone()
        .addScaledVector(
          beamLineSegment.point2.clone().sub(beamLineSegment.point1),
          0.5
        )
      return Util.isPointInPolygon(segCenter, panel.polygon)
    })
  }

  static findRoofPanelForPoint(point, roofPanels) {
    return roofPanels.find(panel => {
      return Util.isPointInPolygon(point, panel.polygon)
    })
  }

  static splitBeamsAlongRoofJoints(beamLineSegments, roofJoints) {
    // Project both onto z = 0 plane
    roofJoints = roofJoints.map(segment => [
      new THREE.Vector3(segment.point1.x, segment.point1.y, 0),
      new THREE.Vector3(segment.point2.x, segment.point2.y, 0),
    ])
    beamLineSegments = beamLineSegments.map(segment => [
      new THREE.Vector3(segment.point1.x, segment.point1.y, 0),
      new THREE.Vector3(segment.point2.x, segment.point2.y, 0),
    ])

    // Initially each beam is represented by one line segment, but after
    // splitting, we get an extra segment for each split. We string together
    // the segments which were split apart into single polylines.
    const beamPolylines = beamLineSegments.map(segment => segment.slice())

    for (let i = 0; i < beamLineSegments.length; i += 1) {
      const beam = beamLineSegments[i]

      for (let j = 0; j < roofJoints.length; j += 1) {
        const joint = roofJoints[j]

        const intersection = Util.lineSegmentsIntersectionPoint(
          beam[0],
          beam[1],
          joint[0],
          joint[1]
        )

        if (intersection) {
          // These aren't technically polylines at this point since
          // we don't controll the order in which the beams are split
          // by roof joints; but they'll becomes polylines after they
          // are sorted below.
          beamPolylines[i].push(
            new THREE.Vector3(intersection.x, intersection.y, 0)
          )
        }
      }
    }

    // Order points by distance from first point
    beamPolylines.forEach(polyline => {
      const polyLineStart = get(polyline, '[0]')
      if (polyLineStart) {
        polyline.sort((vectorA, vectorB) => {
          if (!vectorA.isVector3 || !vectorB.isVector3) return 0

          const aLength = vectorA
            .clone()
            .sub(polyLineStart)
            .length()
          const bLength = vectorB
            .clone()
            .sub(polyLineStart)
            .length()
          return aLength - bLength
        })
      }
    })

    const splitSegments = []
    beamPolylines.forEach(polyline => {
      for (let i = 0; i < polyline.length - 1; i += 1) {
        splitSegments.push({ point1: polyline[i], point2: polyline[i + 1] })
      }
    })

    return splitSegments
  }

  /**
    Note: this is only used to collect geometry and orientation information
    for assembling the secondary mounting structure mesh. These meshes are
    never actually added to the scene themselves though.
  **/
  createRoofPanelMesh(panel) {
    const centroid = panel.centroid

    // Center both the panel and perimiter polygons at the
    // origin so that they're overlapping before performing
    // the intersection.
    let panelPolygon = panel.polygon.map(point => ({
      x: point.x - centroid.x,
      y: point.y - centroid.y,
    }))
    let perimeterPolygon = this.perimeterPoints.map(point => ({
      x: point[0] - centroid.x,
      y: point[1] - centroid.y,
    }))

    panelPolygon = Util.getCounterClockwisePoints(panelPolygon)
    perimeterPolygon = Util.getCounterClockwisePoints(perimeterPolygon)

    if (
      !Util.isPolygonValid(panelPolygon) ||
      !Util.isPolygonValid(perimeterPolygon)
    ) {
      console.log(
        'Roof panel and/or perimeter polygons were invalid; (panel, perimeter):',
        panelPolygon,
        perimeterPolygon
      )
      return null
    }

    let intersectedPanelPolygon = panelPolygon

    // This is a temporary fix to avoid doing any polygon intersections
    // when it's not strictly necessary. This way we have no regression
    // for roofs with only one section until the intersection stuff
    // can be improved.
    const wall = this.getAssociatedWallModel()
    if (wall && wall.roofSectionIds.length > 1) {
      panelPolygon.pop()
      perimeterPolygon.pop()

      intersectedPanelPolygon = intersect(panelPolygon, perimeterPolygon)

      if (intersectedPanelPolygon.length > 0) {
        intersectedPanelPolygon = intersectedPanelPolygon[0]
        intersectedPanelPolygon.push(intersectedPanelPolygon[0])
      } else {
        // Empty intersection
        return null
      }
    }

    if (!Util.isPolygonValid(intersectedPanelPolygon)) {
      console.log(
        'Roof panel/perimeter intersection result not valid!',
        intersectedPanelPolygon
      )
      return null
    }

    // Used to project flattened region mesh vertices back onto the
    // associated roof panel plane.
    const projectionFunc = point => {
      point = new THREE.Vector3(point[0], point[1], point[2])
      return Util.rayPlaneIntersectionPoint(
        point,
        Z_AXIS,
        new THREE.Vector3(0, 0, centroid.z),
        panel.normal
      )
    }

    const mesh = Primitives.getRegionMesh(
      intersectedPanelPolygon.map(point => [point.x, point.y]),
      0x000000,
      null,
      projectionFunc
    )

    mesh.position.copy(centroid)
    mesh.position.z = 0

    return mesh
  }

  associatedRoofIsFlat() {
    return Roof.isFlat(this.getAssociatedRoofModel())
  }

  getAssociatedRoofModel() {
    const state = store.getState().objects.present
    return Roof.getDenormalizedModel(state.roofs[this.roofId])
  }

  getAssociatedWallModel() {
    const state = store.getState().objects.present
    const wall = state.objects[this.wallId]
    if (wall) {
      return Wall.getDenormalizedModel(state.objects[this.wallId])
    }
  }

  clearOldVisuals(parentNode) {
    const children = parentNode.children.slice()
    children.forEach(child => parentNode.remove(child))
  }

  /**
    The 'main' mesh fills the shape of the entire section. It's the part that is
    selectable, and its height is set to the secondary mounting structure height.
  **/
  createMainMesh(roofPanels) {
    const panelMeshes = roofPanels
      .map(panel => this.createRoofPanelMesh(panel))
      .filter(mesh => mesh !== null)

    let fullGeometry
    panelMeshes.forEach((mesh, i) => {
      mesh.updateMatrix()
      const geometryCopy = mesh.geometry.clone()
      geometryCopy.applyMatrix4(mesh.matrix)
      fullGeometry =
        i === 0 ? geometryCopy : Util.geometryUnion(fullGeometry, geometryCopy)
    })

    let color = this.color
    let error = false

    // Display a red error mesh in place of the mesh we were trying to construct
    // if an error occurred in geometry calculation.
    if (!fullGeometry) {
      fullGeometry = new THREE.PlaneGeometry(
        Units.feetToNative(15),
        Units.feetToNative(15)
      )
      color = getThreeHexFromTheme('three.invalid')
      error = true
    }

    fullGeometry.computeBoundingBox()
    fullGeometry.computeBoundingSphere()

    const mainMesh = new THREE.Mesh(
      fullGeometry,
      new THREE.MeshBasicMaterial({
        color,
        transparent: true,
        opacity: store.getState().layers.layers[
          LAYER_KEYS.SECONDARY_MOUNTING_STRUCTURE
        ].visible
          ? 0.5
          : 0,
        side: THREE.DoubleSide,
        depthWrite: false,
      })
    )
    mainMesh.wrapperId = this.id

    if (error) {
      const errorText = Primitives.getTextSprite(
        'Error in RoofSection geometry calculation',
        42
      )
      errorText.position.y += Units.feetToNative(13) / 2.0
      mainMesh.add(errorText)
    }

    mainMesh.position.z =
      -this.secondaryStructureDepth + Units.inchesToNative(1)
    mainMesh.userData.objectType = OBJECT_TYPES.SUB_MOUNTING_STRUCTURE
    mainMesh.renderOrder = getRenderOrder('mountingStructures')

    return mainMesh
  }

  /*
    SceneBuilder event
  */
  visibilityChanged(visible) {
    const primaryVisible = store.getState().layers.layers[
      LAYER_KEYS.PRIMARY_MOUNTING_STRUCTURE
    ].visible
    const secondaryVisible = store.getState().layers.layers[
      LAYER_KEYS.SECONDARY_MOUNTING_STRUCTURE
    ].visible
    const columnsVisible = store.getState().layers.layers[LAYER_KEYS.COLUMNS]
      .visible

    const allInvisible = !primaryVisible && !secondaryVisible && !columnsVisible

    if (this.obj3d) {
      if (allInvisible) {
        this.obj3d.visible = false
      } else if (!this.obj3d.visible) this.obj3d.visible = true
    }

    const beams = Util.findChildrenWithType(
      this.obj3d,
      OBJECT_TYPES.PRIMARY_BEAM
    )
    beams.forEach(b => {
      b.visible = primaryVisible
    })

    const columns = Util.findChildrenWithType(this.obj3d, OBJECT_TYPES.COLUMN)
    columns.forEach(c => {
      c.visible = columnsVisible
    })

    const subMountingStructure = this.getMainMesh()
    if (subMountingStructure) {
      subMountingStructure.material.opacity = secondaryVisible ? 0.5 : 0
    }
  }

  getMainMesh() {
    return this.obj3d.children.find(
      child => child.userData.objectType === OBJECT_TYPES.SUB_MOUNTING_STRUCTURE
    )
  }

  createBeamMeshes(beamLineSegments, roofPanels) {
    const orientation = this.beamOrientation
    const beamWidth = this.beamWidth
    const beamDepth = this.beamDepth

    const roofJoints = Util.findSharedPolygonEdges(
      roofPanels.map(panel => panel.polygon)
    )
    const splitBeamLineSegments = RoofSection.splitBeamsAlongRoofJoints(
      beamLineSegments,
      roofJoints
    )
    const projectedBeamSegments = RoofSection.projectBeamSegmentsOnRoof(
      splitBeamLineSegments,
      roofPanels
    )

    // THREE.Mesh objects representing each beam
    const beamMeshes = projectedBeamSegments.map(segment => {
      const length = segment.point2
        .clone()
        .sub(segment.point1)
        .length()

      const mesh = this.createBeamMesh(
        orientation,
        length,
        beamWidth,
        beamDepth,
        segment.rotationParams
      )

      mesh.position.copy(
        segment.point1
          .clone()
          .addScaledVector(segment.point2.clone().sub(segment.point1), 0.5)
      )

      // Offset beam based on beamDepth
      mesh.position.z -= this.beamDepth / 2

      return mesh
    })

    return beamMeshes
  }

  getPositions(orientation, orthoOrientation, spacing, shift, perimeterPoints) {
    // Algorithm overview:
    // 1. Find the bounding circle for the polygon
    // 2. Find the point of intersection of a line extending from the circle center
    //    to its perimeter in the direction orthogonal to the beam orientation.
    // 3. Find the nearest point on the roof section perimeter to that point.
    // 4. Moving from that point along the 'orthoOrientation', collect a point
    //    every 'spacing' distance (and shift everything by 'shift').

    const boundingCircle = Util.boundingCircleForPolygon(perimeterPoints)
    const boundingCircleDiameter = boundingCircle.radius * 2
    if (!spacing) {
      return []
    }
    let count = Math.floor(boundingCircleDiameter / spacing)

    // If there's still space after the last position, add one more to reach the wall
    if (boundingCircleDiameter % spacing !== 0) {
      count += 1
    }
    const positions = []
    const absOrientation = new THREE.Vector3(
      Math.abs(Math.abs(orientation.x) - 1),
      Math.abs(Math.abs(orientation.y) - 1),
      orientation.z
    )
    const absOrthoOrientation = new THREE.Vector3(
      Math.abs(Math.abs(orthoOrientation.x) - 1),
      Math.abs(Math.abs(orthoOrientation.y) - 1),
      orthoOrientation.z
    )

    const startPoint = boundingCircle.center
      .clone()
      .addScaledVector(orthoOrientation, -boundingCircle.radius)
    const endPoint = boundingCircle.center
      .clone()
      .addScaledVector(orthoOrientation, boundingCircle.radius)
    let startPerimPoint
    let endPerimPoint
    perimeterPoints.forEach(point => {
      if (
        !startPerimPoint ||
        point
          .clone()
          .sub(startPoint)
          .length() <
          startPerimPoint
            .clone()
            .sub(startPoint)
            .length()
      ) {
        startPerimPoint = point
      }
      if (
        !endPerimPoint ||
        endPoint
          .clone()
          .sub(point)
          .length() <
          endPoint
            .clone()
            .sub(endPerimPoint)
            .length()
      ) {
        endPerimPoint = point
      }
    })
    const wallThickness = Wall.thicknessForLayerKey(
      LAYER_KEYS.EXTERIOR_WALLS,
      true
    )
    const offset = wallThickness / 2
    const startPos = startPerimPoint
      .clone()
      .add(orthoOrientation.clone().multiplyScalar(offset))
    const endPos = endPerimPoint
      .clone()
      .multiply(absOrientation)
      .add(startPos.clone().multiply(absOrthoOrientation))
      .sub(orthoOrientation.clone().multiplyScalar(offset))

    let prevPos
    for (let i = 0; i < count; i += 1) {
      const nextPos = startPos
        .clone()
        .addScaledVector(orthoOrientation, spacing * i)

      if (
        prevPos &&
        nextPos
          .clone()
          .sub(prevPos)
          .length() >
          endPos
            .clone()
            .sub(prevPos)
            .length()
      ) {
        positions.push(endPos.clone())
        break
      } else {
        positions.push(nextPos)
      }
      prevPos = nextPos.clone()
    }

    return positions
  }

  getOrientationAxis(orientation) {
    let orientationAxis
    Object.keys(orientation).find(key => {
      if (Number(orientation[key].toFixed(6))) {
        orientationAxis = key
      }
      return orientationAxis
    })

    return orientationAxis
  }

  getBrokenSegments(segment, orientation) {
    const orientationKey = this.getOrientationAxis(orientation)
    const perimeterPoints = Util.toVec3Array(this.perimeterPoints)

    let brokenSegments = []

    const points = []

    for (let j = 0; j < perimeterPoints.length - 1; j++) {
      const wallSegPoint1 = perimeterPoints[j]
      const wallSegPoint2 = perimeterPoints[j + 1]
      const intersection = Util.lineSegmentsIntersectionPoint(
        wallSegPoint1,
        wallSegPoint2,
        segment.point1,
        segment.point2
      )
      if (intersection) {
        points.push(new THREE.Vector2(intersection.x, intersection.y))
      }
    }

    let furthestPoints = []

    if (points.length >= 2) {
      furthestPoints = Util.getFurthestTwoPoints(points)
    }

    if (furthestPoints.length === 2) {
      const sortedPoints = sortBy(points, p => p[orientationKey])
      for (let j = 0; j < sortedPoints.length; j += 2) {
        const point1 = sortedPoints[j]
        const point2 = sortedPoints[j + 1]

        brokenSegments.push({ point1, point2 })
      }
    }

    return brokenSegments
  }

  /*
    Given a set of positions where beams or column-lines should be located within the
    roof section perimeter, this will find a set of line segments stretching from one
    side of, the perimeter to the other and having the given orientation.

    @return An array of segment objects: {point1, point2}.
  */
  getLineSegmentsForPoints(points, orientation, useFurthest) {
    const orientationKey = this.getOrientationAxis(orientation)
    const perimeterPoints = Util.toVec3Array(this.perimeterPoints)

    const lineSegments = points.map(pos => {
      const segPoint1 = pos
        .clone()
        .addScaledVector(orientation, -Units.feetToNative(10000))
      const segPoint2 = pos
        .clone()
        .addScaledVector(orientation, Units.feetToNative(10000))
      let intersectionPoints = []

      for (let i = 0; i < perimeterPoints.length - 1; i++) {
        const wallSegPoint1 = perimeterPoints[i]
        const wallSegPoint2 = perimeterPoints[i + 1]
        const intersectionPoint = Util.lineSegmentsIntersectionPoint(
          wallSegPoint1,
          wallSegPoint2,
          segPoint1,
          segPoint2
        )
        if (intersectionPoint) {
          intersectionPoints.push(
            new THREE.Vector2(intersectionPoint.x, intersectionPoint.y)
          )
        }
      }

      // May happen with non-convex polygons or from imprecision in the
      // intersection calculation.
      if (intersectionPoints.length > 2) {
        if (useFurthest) {
          intersectionPoints = Util.getFurthestTwoPoints(intersectionPoints)
        } else {
          const position = new THREE.Vector2(pos.x, pos.y)
          const positionValue = Number(position[orientationKey].toPrecision(6))
          intersectionPoints = intersectionPoints.filter(ip => {
            return Number(ip[orientationKey].toPrecision(6)) > positionValue
          })
          if (intersectionPoints.length) {
            intersectionPoints = [
              position,
              Util.getClosestPoint(position, intersectionPoints),
            ]
          }
        }
      }

      // If the number of intersectionPoints isn't two, the line was either
      // tangent to the polygon or does not overlap it at all.
      if (intersectionPoints.length === 2) {
        intersectionPoints = sortBy(intersectionPoints, orientationKey)
        return {
          point1: intersectionPoints[0],
          point2: intersectionPoints[1],
        }
      } else {
        return null
      }
    })

    return lineSegments
  }

  createBeamMesh(orientation, length, width, depth, rotationParams) {
    const beamBounds = orientation.clone().multiplyScalar(length)
    const widthAxis = orientation.clone().applyAxisAngle(Z_AXIS, Math.PI / 2)
    const depthAxis = orientation.clone().applyAxisAngle(widthAxis, Math.PI / 2)
    const widthVec = widthAxis.multiplyScalar(width)
    const depthVec = depthAxis.multiplyScalar(depth)
    beamBounds.add(widthVec)
    beamBounds.add(depthVec)

    // Note that if we want arbitrarily oriented beams, we need another approach here.
    // I'd scale in some consistent orientation (i.e. beam length always on Y-axis),
    // and then rotate into the desired orientation.
    const geometry = new THREE.BoxGeometry(
      Math.abs(beamBounds.x),
      Math.abs(beamBounds.y),
      Math.abs(beamBounds.z)
    )
    const material = new THREE.MeshStandardMaterial({
      color: getThreeHexFromTheme('three.objects.roofSection.beam.default'),
      metalness: 0.7,
      roughness: 0.6,
      side: THREE.DoubleSide,
    })

    const mesh = new THREE.Mesh(geometry, material)
    mesh.userData.objectType = OBJECT_TYPES.PRIMARY_BEAM

    mesh.rotateOnAxis(rotationParams.axis, -rotationParams.angle)

    return mesh
  }

  toModel() {
    let convertedBeamModels
    if (this.beamModels) {
      convertedBeamModels = this.beamModels.map(model => ({
        id: model.id,
        position: Units.nativeToUnitsV(this.units, model.position),
        edited: model.edited,
      }))
    }
    let convertedColumnLineModels
    if (this.columnLineModels) {
      convertedColumnLineModels = this.columnLineModels.map(model => ({
        id: model.id,
        position: Units.nativeToUnitsV(this.units, model.position),
        edited: model.edited,
      }))
    }

    return {
      id: this.id,
      roofId: this.roofId,
      wallId: this.wallId,
      layerKey: this.layerKey,
      perimeterPoints: this.perimeterPoints.map(point => [
        Units.nativeToUnits(this.units, point[0]),
        Units.nativeToUnits(this.units, point[1]),
        0,
      ]),
      height: Units.nativeToUnits(this.units, this.height),
      position: Units.nativeToUnitsV(this.units, this.obj3d.position),
      beamSpacing: Units.nativeToUnits(this.units, this.beamSpacing),
      beamWidth: Units.nativeToUnits(this.units, this.beamWidth),
      beamDepth: Units.nativeToUnits(this.units, this.beamDepth),
      beamShift: Units.nativeToUnits(this.units, this.beamShift),
      beamRotation: this.beamRotation,
      columnSpacing: Units.nativeToUnits(this.units, this.columnSpacing),
      columnShift: Units.nativeToUnits(this.units, this.columnShift),
      primaryStructuresEnabled: this.primaryStructuresEnabled,
      primaryStructureType: this.primaryStructureType,
      secondaryStructureType: this.secondaryStructureType,
      secondaryStructureDepth: Units.nativeToUnits(
        this.units,
        this.secondaryStructureDepth
      ),
      structureStyle: this.structureStyle,
      secondaryStructureStyle: this.secondaryStructureStyle,
      beamModels: convertedBeamModels,
      hasColumns: this.hasColumns,
      columnLineModels: convertedColumnLineModels,
      columnWidth: Units.nativeToUnits(this.units, this.columnWidth),
      columnLength: Units.nativeToUnits(this.units, this.columnLength),
      isCylinder: this.isCylinder,
      columnNames:
        this.columnNames ||
        Array.from(Array(26))
          .map((e, i) => i + 65)
          .map(x => String.fromCharCode(x)),
      beamNames: this.beamNames || Array.from(Array(26)).map((e, i) => i),
    }
  }

  static createBeamModel(position) {
    return {
      id: Util.guid(),
      position,
    }
  }

  static createColumnLineModel(position) {
    return {
      id: Util.guid(),
      position,
    }
  }

  static createModel(
    wallId,
    roofId,
    perimeterPoints,
    position,
    height = Units.feetToInches(12.1),
    beamSpacing = 300, // 25 ft
    beamWidth = 8,
    beamDepth = 24,
    beamShift = 0,
    beamRotation = 0,
    columnSpacing = 144, // 12 ft
    columnShift = 0,
    hasColumns = false,
    primaryStructuresEnabled = false,
    primaryStructureType = RoofSection.getDefaultPrimaryStructureType(),
    secondaryStructureType = RoofSection.getDefaultSecondaryStructureType(),
    secondaryStructureDepth = 9,
    structureStyle = structureStyles[0].value,
    secondaryStructureStyle = structureStyles[0].value,
    primaryVisible = true,
    secondaryVisible = true,
    columnsVisible = true,
    columnWidth = 24, // 2 ft
    columnLength = 24,
    isCylinder = false,
    columnNames = Array.from(Array(26))
      .map((e, i) => i + 65)
      .map(x => String.fromCharCode(x)),
    beamNames = Array.from(Array(26)).map((e, i) => i)
  ) {
    return {
      id: Util.guid(),
      wallId,
      roofId,
      layerKey: LAYER_KEYS.ROOF_SECTIONS,
      perimeterPoints,
      height,
      position,
      beamSpacing,
      beamWidth,
      beamDepth,
      beamShift,
      beamRotation,
      columnSpacing,
      columnShift,
      hasColumns,
      primaryStructuresEnabled,
      primaryStructureType,
      secondaryStructureType,
      secondaryStructureDepth,
      structureStyle,
      secondaryStructureStyle,
      primaryVisible,
      secondaryVisible,
      columnsVisible,
      columnWidth,
      columnLength,
      isCylinder,
      columnNames,
      beamNames,
    }
  }

  /*
    A vector rotated 90 degrees about the z-axis from our beam orientation
  */
  getOrthoOrientation() {
    const orientation = new THREE.Vector3(
      Math.abs(this.beamOrientation.x),
      Math.abs(this.beamOrientation.y),
      this.beamOrientation.z
    )
    return orientation.clone().applyAxisAngle(Z_AXIS, -Math.PI / 2)
  }

  select() {
    const currentColor = Color(this.color)
    const selectedColor = currentColor.lighten(0.3).rgbNumber()
    this.getMainMesh().material.color.setHex(selectedColor)
  }

  deselect() {
    this.getMainMesh().material.color.setHex(Color(this.color).rgbNumber())
  }

  static getDefaultPrimaryStructureType() {
    return primaryMountingTypes[0].value
  }

  static getDefaultSecondaryStructureType() {
    return primaryToSecondaryTypes[this.getDefaultPrimaryStructureType()][0]
      .value
  }

  // ///
  // Interactable methods
  // ///

  getSnappableEdgePoints() {
    return []
  }

  getSnappableColumnEdgePoints() {
    const columnPoints = []

    if (!this.columns || !this.columns.length) return []

    this.columns.forEach(column => {
      const box = new THREE.Box3().setFromObject(column)

      columnPoints.push([box.min.x, box.min.y, 0])
      columnPoints.push([box.min.x, box.max.y, 0])
      columnPoints.push([box.min.x, box.max.y, 0])
      columnPoints.push([box.max.x, box.max.y, 0])
      columnPoints.push([box.max.x, box.max.y, 0])
      columnPoints.push([box.max.x, box.min.y, 0])
      columnPoints.push([box.max.x, box.min.y, 0])
      columnPoints.push([box.min.x, box.min.y, 0])
    })

    return columnPoints
  }

  destroy() {
  }
}

export default RoofSection
