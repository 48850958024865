import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'

import Alert from 'components/UIKit/Alert'
import Button from 'components/UIKit/Button'
import Loader from 'components/UIKit/Loader'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'
import { STORAGE_KEY } from 'components/Modals/ExportPDFLayoutModal/Wizard/constants'

class VersionForm extends Component {
  state = {
    error: '',
    name: '',
    data: null,
    saving: false,
  }

  UNSAFE_componentWillMount() {
    if (!this.isEditScreen) {
      const currentVersion = get(this.props, 'facility.floor.versions').length
      const nextVersion = this.getNextVersionName(currentVersion)

      this.setState({
        name: nextVersion,
      })
    } else {
      const currentVersion = get(this.props, 'facility.floor.version')
      const defaultVersionName = this.getDefaultVersionName(currentVersion)

      this.setState({
        name: defaultVersionName,
      })
    }
  }

  getDefaultVersionName = version =>
    this.isEditScreen ? version.name : `${version.name} (Copy)`

  getNextVersionName = currentVersion => {
    const nextVersion = parseInt(currentVersion, 10) + 1

    return `Version ${nextVersion}`
  }

  get isEditScreen() {
    return this.props.title === 'Edit Version'
  }

  handleNameChanged = event => {
    this.setState({
      name: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      saving: true,
    })

    if (this.state.name.trim() === '') {
      this.setState({
        error: 'Please enter a name!',
        saving: false,
      })
      return
    }

    const { facility, match } = this.props

    let data
    if (this.isEditScreen) {
      data = { id: match.params.id, name: this.state.name }
    } else {
      data = {
        floorId: facility.floor.id,
        name: this.state.name,
        fromVersionId: facility.floor.version.id,
      }
    }

    this.props
      .onSubmit(data)
      .then(res => {
        const versionId = this.isEditScreen
          ? facility.floor.version.id
          : res.data.createVersion.id

        if (!this.isEditScreen) {
          // Copy export settings for current version
          const facilityId = get(facility, 'id')
          const floorId = get(facility, 'floor.id')
          const floorVersionId = get(facility, 'floor.version.id')
          const itemId = `${STORAGE_KEY}:${facilityId}:${floorId}:${floorVersionId}`
          const item = window.localStorage.getItem(itemId)
          const currentExportSettings = JSON.parse(item) || {}

          // Remove additional options that don't copy well
          currentExportSettings.savedAdditionalImages = []
          currentExportSettings.selectedAdditionalImages = []
          currentExportSettings.savedComfortZones = []
          currentExportSettings.tempComfortZones = []
          currentExportSettings.selectedMainImageSnapshot = null
          currentExportSettings.savedMainImageSnapshot = null
          currentExportSettings.mainImage = 'PLAN_VIEW'
          if (!this.isDuplicateScreen) {
            currentExportSettings.selectedObjects = {}
            currentExportSettings.savedObjects = {}
          }
          const additionalOptions = get(
            currentExportSettings,
            'additionalOptions',
            []
          )
          currentExportSettings.additionalOptions = additionalOptions.filter(
            item => {
              const optsToRemove = ['ADDITIONAL_IMAGES', 'COMFORT_ZONE_METRICS']
              !this.isDuplicateScreen && optsToRemove.concat('OBJECT_INVENTORY')

              return !optsToRemove.includes(item)
            }
          )
          window.localStorage.setItem(
            `${STORAGE_KEY}:${get(facility, 'id')}:${get(
              facility,
              'floor.id'
            )}:${versionId}`,
            JSON.stringify(currentExportSettings)
          )
        }

        this.props.history.push(
          `/facility/${facility.id}/floor/${facility.floor.id}/version/${versionId}`
        )
      })
      .catch(error => {
        this.setState({
          error:
            get(error, 'graphQLErrors.0.message') || 'An error has occurred',
          saving: false,
        })
      })
  }

  render() {
    const { error, name, saving } = this.state

    return (
      <Modal
        {...this.props}
        title={this.props.title}
        onSubmit={this.handleSubmit}
        primaryAction={
          <Button
            primary
            onClick={this.handleSubmit}
            disabled={saving}
            label={saving ? 'Saving...' : this.props.buttonText}
          />
        }
        secondaryAction={<Button to={this.props.parentRoute} label="Cancel" />}
        size="500px"
        {...this.props}
      >
        {this.props.facilityData && this.props.facilityData.loading ? (
          <Loader />
        ) : (
          <>
            {error.length > 0 && (
              <Space bottom="base">
                <Alert type="error" text={error} />
              </Space>
            )}
            <Space bottom="base">
              <TextField
                label="Name"
                value={name}
                onChange={this.handleNameChanged}
              />
            </Space>
          </>
        )}
      </Modal>
    )
  }
}

VersionForm.propTypes = {
  buttonText: PropTypes.string,
  facility: PropTypes.object,
  facilityData: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  onSubmit: PropTypes.func,
  parentRoute: PropTypes.string,
  title: PropTypes.string,
}

export default VersionForm
