import styled from 'styled-components'

import { noSelect, theme } from 'lib/styleUtils'

const Container = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${noSelect};

  ${props => props.padded && `padding: ${props.theme.spacing.base};`};
  ${props =>
    props.selected &&
    `background-color: ${props.theme.colors.light.extraLight};`};

  ${props =>
    props.disabled &&
    `
  cursor: not-allowed;
    background-color: ${props.theme.colors.light.extraLight};
  `}

  ${props =>
    props.actions &&
    `border-left: 1px solid ${props.theme.colors.light.subdued}`}

  &:hover {
    ${props =>
      props.onClick &&
      `background-color: ${props.theme.colors.light.extraLight};`};
  }

  border-bottom: 1px solid ${theme('colors.light.subdued')};
`

export default Container
