import styled from 'styled-components'

import { darken, theme } from 'lib/styleUtils'

const EmptyMessage = styled.div`
  color: ${props => darken(props.theme.colors.light.subdued, 0.4)};
  font-size: ${theme('fonts.sizes.s')};
  margin-top: ${theme('spacing.l')};
  text-align: center;
`

export default EmptyMessage
