import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const setInlineStyles = props => {
  if (props.inline) {
    return `margin-right: ${props.theme.spacing.s};`
  }

  return `
    display: block;
    margin-bottom: ${props.theme.spacing.xs};
  `
}

const Label = styled.label`
  font-family: ${theme('fonts.family.base')};
  font-size: ${theme('fonts.sizes.s')};
  font-weight: ${theme('fonts.weights.bold')};

  ${props => props.labelWidth && `min-width: ${props.labelWidth};`};
  ${setInlineStyles};
`

export default Label
