import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'

import { version } from '../../package.json'

const config = {
  development: 'https://f3173d3eeaf54e04a463902d910080a1@sentry.io/224916',
  staging: 'https://0606a6e1954e4f4d83954d50d5461e42@sentry.io/248077',
  production: 'https://8d687d5f42f742b8bbccab53b6e7d914@sentry.io/260117',
}

const env = import.meta.env.MODE
const url = config[(env || 'development') as keyof typeof config]

export const history = createBrowserHistory()

const ConfiguredSentry = Sentry.init({
  dsn: url,
  release: version,
  environment: env,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
  ],
  tracesSampleRate: 0.2,
})

export default ConfiguredSentry
