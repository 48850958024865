import get from 'lodash-es/get'
import { requestHeatMap, receiveHeatMap } from 'store/objects'
import { clearStatus, setLoadingStatus } from 'store/status'
import { getHeaterProducts } from 'client/queries/productsQuery'
import { getRadiantHeaterPerformanceData } from 'client/queries/radiantHeaterPerformanceQuery'
import heatMapWorker from './heatMap.worker?worker'
import {
  evalPointSpacing,
  isExteriorWall,
  isInteriorWall,
  isValidSegment,
  rotate,
} from '../airflow/util'
import Units from 'components/DrawingCanvas/lib/units'

async function heatMapUtil(
  { objects, obstructions, products, segments, relativeIntensityData },
  callback
) {
  try {
    // Valid segments that can be used to make walls
    const validSegments = Object.values(segments).filter(isValidSegment)
    // Each time we need to iterate through segments use this function
    const mapSegments = (parentId, addSegment) => {
      for (let i = 0; i < validSegments.length; i++) {
        const segment = validSegments[i]
        if (segment.parentId === parentId) addSegment(segment)
      }
    }

    // Polygon needed to see if obstructions and walls are inside
    let exteriorPolygon = []
    // Fields needed for the input for the air velocity package
    let exteriorWallId,
      interiorWalls = [],
      perimeterWalls = []
    // Take all the wall values out of objects
    const walls = Object.values(objects)
    // Find the exteriorWall
    const exteriorWall = walls.find(isExteriorWall)

    // Set the values for the perimeter walls and exterior
    exteriorWallId = exteriorWall.id

    // Go through the valid segments and return the vertices of the exterior Walls
    mapSegments(exteriorWallId, segment => {
      // Take the first start point and all the endpoints to form the polygon
      // of the exterior walls
      if (!exteriorPolygon.length) {
        exteriorPolygon.push([segment.startPoint.x, segment.startPoint.y])
      }
      exteriorPolygon.push([segment.endPoint.x, segment.endPoint.y])
      // perimeter walls to pass to the air velocity package
      perimeterWalls.push({
        vertex1: { ...segment.startPoint },
        vertex2: { ...segment.endPoint },
      })
    })

    // Iterate over all the interior walls
    for (let i = 0; i < walls.length; i++) {
      // Determine the object we are inspecting is an interior Wall
      if (isInteriorWall(walls[i])) {
        // Pull the interior wall info out of segments
        mapSegments(walls[i].id, segment => {
          const startPoint = get(segment, 'startPoint')
          const endPoint = get(segment, 'endPoint')
          if (startPoint && endPoint) {
            interiorWalls.push([segment.startPoint, segment.endPoint])
          }
        })
      }
    }

    // Taking the value of each of the obstructions
    const obs = Object.values(obstructions)
    let obstructs = []
    // Iterate over the obstructions
    for (let i = 0; i < obs.length; i++) {
      let {
        height,
        obstructionType,
        offset,
        position,
        positions,
        rotation,
        startLocation,
      } = obs[i]
      // Making sure there is a base position
      position = position || { x: 0, y: 0 }
      // rotation of the object pulled out
      const rot = (rotation && rotation.z) || 0
      // Clone the positions so the obstruction keeps them
      let clonedPositions = positions.map(pos => {
        const [x, y] = rotate(position.x, position.y, pos.x, pos.y, -rot)
        return {
          x,
          y,
        }
      })

      // If the obstruction is basic, remove the last element b/c repeated
      if (obstructionType === 'basic') clonedPositions.pop()

      // Start height is in case the obstruction starts off the ground
      let startHeight = offset || 0
      if (startLocation === 'ceiling') {
        startHeight =
          segments[get(exteriorWall, 'segments.0')].height - height || 0
      }
      // Minimal description of the obstructions for the AVP
      // Make sure to use clonedPositions to not delete positions
      // And make sure there are actually positions in the array
      if (clonedPositions.length) {
        obstructs.push({
          height: Units.inchesToNative(height),
          positions: clonedPositions,
          startHeight,
        })
      }
    }

    const productInfo = Object.values(products).map(prod => {
      let performance = {}
      prod.heaterPerformance.forEach(o => {
        performance[o.distanceFromBurner] = o.axialRelativeIntensity
      })

      return {
        height: Math.round(prod.height),
        position: prod.position,
        product: prod.product,
        rotation: prod.rotation.x,
        size: prod.size,
        variationId: prod.variationId,
        inputFiringRate: prod.heaterData[0]?.inputFiringRate,
        lengthOfTube: Math.trunc(prod.heaterData[0]?.minTubeLength / 12),
        radEfficiency: prod.heaterData[0]?.radEfficiency,
        performance,
      }
    })

    // Build the service worker to calculate the airflow velocities
    const worker = new heatMapWorker()
    worker.onmessage = event => {
      const heatMapData = event.data
      const heatMap = heatMapData.map(hm => ({
        ...hm,
        objectId: exteriorWallId,
        gridSize: evalPointSpacing,
        evaluationHeight: 4,
      }))
      callback(heatMap)
      worker.terminate()
    }
    // Send the inputs calculated into the service worker
    worker.postMessage({
      evalHeight: 4,
      evalPointSpacing,
      exteriorWallId,
      interiorWalls,
      perimeterWalls,
      products: productInfo,
      obstructions: obstructs,
      relativeIntensityData,
    })
  } catch (err) {
    console.log(err)
    return []
  }
}

export const updateHeatMap = async (
  dispatch,
  { products, objects, segments, obstructions }
) => {
  dispatch(setLoadingStatus())
  dispatch(requestHeatMap())

  // NOTE: Takes advantage of query batching
  const productsData = await getHeaterProducts()
  const relativeIntensityData = await getRadiantHeaterPerformanceData()

  const variationsData = Object.keys(products).map(key => {
    const productObject = products[key]
    const product = productsData.find(p => p.id === productObject.productId)
    if (!product) return {}

    const variation = products[key]

    return {
      heaterData: variation.heaterData,
      heaterPerformance: variation.heaterPerformance,
      variationId: variation.variationId,
      productId: get(product, 'id'),
    }
  })

  const validProductIds = [],
    productInfo = []
  for (let i = 0; i < variationsData.length; i++) {
    const { variationId, heaterData, heaterPerformance, productId } =
      variationsData[i] || {}
    // Make sure the product has real heater data
    // Otherwise don't add the product
    if (
      heaterData &&
      heaterData.length &&
      heaterPerformance &&
      heaterPerformance.length
    ) {
      validProductIds.push(productId)
      productInfo.push({
        variationId,
        heaterData,
        heaterPerformance,
      })
    }
  }

  const validProducts = {}
  Object.keys(products).forEach(id => {
    const product = products[id]
    if (validProductIds.includes(product.product.id)) {
      validProducts[id] = product
    }
  })

  if (productInfo.length && Object.keys(validProducts).length) {
    heatMapUtil(
      {
        products: validProducts,
        objects,
        segments,
        obstructions,
        airVelocities: productInfo,
        relativeIntensityData,
      },
      heatMap => {
        dispatch(clearStatus)
        dispatch(receiveHeatMap(heatMap))
      }
    )
  } else {
    // If there are no heaters, there is nothing to show
    dispatch(clearStatus)
    dispatch(receiveHeatMap([]))
  }
}
