import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MetricsTable } from './MetricsTable'
import { useDestratWorker } from 'components/PerformanceMetrics/hooks/useVtkWorker'
import { usePerformanceMetricsProps } from 'components/PerformanceMetrics/hooks/usePerformanceMetricsProps'
import InfoTooltip from 'components/UIKit/InfoTooltip'
import {
  SeatedVelocityIcon,
  StandingVelocityIcon,
  ThermometerIcon,
  PredictedMeanVoteIcon,
} from '../icons'
import Tabs from 'components/UIKit/Tabs/Tabs'
import { Tab } from 'components/UIKit/Tabs/index'
import { formatHeights, formatPercent } from '../util/format'
import { SpinningIcon } from 'components/PerformanceMetrics/styled/SpinningIcon'
import { PieChart } from 'components/PerformanceMetrics/subcomponents/PieChart'
import Icon from 'components/UIKit/Icon/Icon'
import { ImperialTemperature } from 'store/units/types/temperature'
import { ImperialVelocity } from 'store/units/types/velocity'
import { HEIGHTS, HEIGHT_VALUES, STILL_AIR_VELOCITY } from 'config/cfd'
import { camelToTitleCase } from 'utils/string'
import { getThermalComfort } from 'lib/thermalComfortTool/index'
import { useFormatTemperature } from 'components/PerformanceMetrics/hooks/useFormatTemperature'
import { useFormatVelocity } from 'components/PerformanceMetrics/hooks/useFormatVelocity'

const HEIGHT_ICONS = {
  [HEIGHTS.standing]: <StandingVelocityIcon />,
  [HEIGHTS.seated]: <SeatedVelocityIcon />,
}

const useNoFansData = () => {
  const { formatTemperature } = useFormatTemperature()
  const { formatVelocity } = useFormatVelocity()
  const { facility } = usePerformanceMetricsProps()
  const { indoorWinterHumidity, indoorWinterTemp } = facility
  const defaultAirTemp = new ImperialTemperature(indoorWinterTemp)
  const { pmv } = getThermalComfort({
    airVelocity: STILL_AIR_VELOCITY,
    humidity: indoorWinterHumidity,
    meanAirTemp: defaultAirTemp,
    clothingType: facility.winterClothingType,
    metabolicRate: facility.activityLevel,
  })
  return {
    airVelocity: formatVelocity(STILL_AIR_VELOCITY),
    meanAirTemp: formatTemperature(defaultAirTemp),
    pmv: pmv.toFixed(2),
  }
}

const useWithFansData = ({ destratUrl, draftRiskUrls }) => {
  const { formatTemperature } = useFormatTemperature()
  const { formatVelocity } = useFormatVelocity()
  const { areaOfConcernEdges, facility } = usePerformanceMetricsProps()
  const { indoorWinterHumidity, indoorWinterTemp } = facility
  const defaultAirTemp = new ImperialTemperature(indoorWinterTemp)

  const destratResults = useDestratWorker({
    comfortZoneEdges: areaOfConcernEdges,
    destratUrl,
    draftRiskUrls,
  })

  if (destratResults.loading) {
    return { loading: true, error: undefined, data: undefined }
  } else if (destratResults.error || !destratResults.data) {
    return { loading: false, error: destratResults.error, data: undefined }
  } else {
    const { averageAirVelocity, draftRiskCoverage, destratCoverage } = destratResults.data
    const airVelocity = new ImperialVelocity(averageAirVelocity)
    const { pmv } = getThermalComfort({
      airVelocity,
      humidity: indoorWinterHumidity,
      meanAirTemp: defaultAirTemp,
      clothingType: facility.winterClothingType,
      metabolicRate: facility.activityLevel,
    })
    const data = {
      airVelocity: formatVelocity(airVelocity),
      averageAirTemperature: formatTemperature(defaultAirTemp),
      pmv: pmv.toFixed(2),
      destratCoverage,
      draftRisk: draftRiskCoverage,
    }
    return { loading: false, error: undefined, data }
  }
}

/**
 * @typedef {import('hooks/useFacilityVtkResults').vtkResult} vtkFiles
 * @param {object} props
 * @param {vtkFiles} props.vtkFiles
 */
const DestratResults = ({ vtkFiles: { vtkUrlGroups } }) => {
  const [selectedHeight, setSelectedHeight] = useState(HEIGHTS.standing)
  const noFansMetrics = useNoFansData()
  const withFansMetrics = useWithFansData({
    destratUrl: vtkUrlGroups.destrat,
    draftRiskUrls: vtkUrlGroups.velocity[selectedHeight],
  })

  const formattedHeights = formatHeights(HEIGHT_VALUES[selectedHeight])
  const legend = {
    header: (
      <InfoTooltip
        title={`Calculated using evaluation heights: ${formattedHeights}`}
        iconSize="16px"
      />
    ),
    cells: [
      { icon: HEIGHT_ICONS[selectedHeight], content: 'Avg Air Velocity' },
      { icon: <ThermometerIcon />, content: 'Avg Air Temp' },
      { icon: <PredictedMeanVoteIcon />, content: 'Occupant PMV Value' },
      { content: '% Destratified' },
      { content: 'Draft Risk' },
    ],
  }

  const noFans = {
    header: 'No Fans',
    cells: [
      { content: noFansMetrics.airVelocity },
      { content: noFansMetrics.meanAirTemp },
      { content: noFansMetrics.pmv },
      { icon: <PieChart percentComplete={0} />, content: '0%' },
      { icon: <PieChart percentComplete={0} />, content: '0%' },
    ],
  }

  const withFans = {
    header: 'With Fans',
    cells: withFansMetrics.loading
      ? [{ content: <SpinningIcon name="refresh" size="20" /> }]
      : withFansMetrics.error || !withFansMetrics.data
      ? [
          {
            icon: <Icon name="warn" size="20" />,
            content: withFansMetrics.error?.message || 'Failed to calculate metrics',
          },
        ]
      : [
          { content: withFansMetrics.data.airVelocity },
          { content: withFansMetrics.data.averageAirTemperature },
          { content: withFansMetrics.data.pmv },
          {
            icon: <PieChart percentComplete={withFansMetrics.data.destratCoverage} />,
            content: formatPercent(withFansMetrics.data.destratCoverage),
          },
          {
            icon: <PieChart percentComplete={withFansMetrics.data.draftRisk} />,
            content: formatPercent(withFansMetrics.data.draftRisk),
          },
        ],
  }

  return (
    <Tabs size="s" activeIndex={Object.values(HEIGHTS).indexOf(selectedHeight)}>
      {Object.values(HEIGHTS).map((height, i) => (
        <Tab key={i} title={camelToTitleCase(height)} onClick={() => setSelectedHeight(height)}>
          <MetricsTable columns={[legend, noFans, withFans]} />
        </Tab>
      ))}
    </Tabs>
  )
}

DestratResults.propTypes = {
  vtkFiles: PropTypes.object,
}

export { DestratResults }
