import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { format } from 'date-fns'
import get from 'lodash-es/get'

import theme from 'config/theme'

import { ALL_OPPORTUNITIES_QUERY } from 'client/queries'
import { showAlert } from 'store/alert'
import { addOpportunity } from 'store/quote'

import Icon from 'components/UIKit/Icon'
import Loader from 'components/UIKit/Loader'
import SearchInput from 'components/UIKit/SearchInput'
import SelectList, {
  SelectListIcon,
  SelectListItem,
  SelectListLink,
  SelectListTitle,
} from 'components/UIKit/SelectList'
import Flex from 'components/UIKit/Flex'
import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'

import MetaList from './styled/MetaList'

function OpportunitiesList(props = {}) {
  const [query, setQuery] = useState('')

  // TODO: `handleSearch()` should be throttled!
  useEffect(() => {
    handleSearch()
  }, [query]) /* eslint-disable-line react-hooks/exhaustive-deps */

  function handleClick(event, opportunity) {
    event.preventDefault()
    props.addOpportunity({ opportunity })
    props.onSelect && props.onSelect(event)
    props.history && props.history.goBack()
  }

  async function handleSearch() {
    try {
      // Escape single quotes because Salesforce won't match properly
      // with single quotes in the query string.
      const escapedQuery = query.replace(/'/g, "\\'")
      await props.refetch({
        limit: 10,
        search: escapedQuery,
      })
    } catch (error) {
      props.showAlert({
        text: get(error, 'graphQLErrors[0].message') || '',
        type: 'error',
      })
    }
  }

  function handleChange(event) {
    const { value } = event.target
    setQuery(value)
  }

  function handleClear(event) {
    setQuery('')
  }

  function renderSearch() {
    return (
      <Space bottom="base">
        <SearchInput
          name="search"
          placeholder="Search..."
          value={query}
          onChange={handleChange}
          onClear={handleClear}
        />
      </Space>
    )
  }

  function renderOpportunities() {
    if (props.opportunities.length === 0) {
      return <p>There are no matching opportunities.</p>
    }

    return (
      <SelectList>
        {props.opportunities.map(opportunity => (
          <SelectListItem key={opportunity.id}>
            <SelectListLink
              href="#"
              onClick={event => handleClick(event, opportunity)}
            >
              <Flex flexGrow="1" flexDirection="column">
                <Space bottom="xs">
                  <SelectListTitle>{opportunity.name}</SelectListTitle>
                </Space>
                <MetaList>
                  <VariantText size="s">
                    <Icon
                      marginRight="xs"
                      name="checkCircle"
                      size="13"
                      color={theme.colors.light.subdued}
                    />
                    {opportunity.stageName}
                  </VariantText>
                  <VariantText size="s">
                    <Icon
                      marginRight="xs"
                      name="calendar"
                      size="16"
                      color={theme.colors.light.subdued}
                    />
                    {format(new Date(opportunity.closeDate), 'MM/dd/yyyy')}
                  </VariantText>
                </MetaList>
              </Flex>
              <SelectListIcon
                name="arrowRight"
                color={theme.colors.light.subdued}
              />
            </SelectListLink>
          </SelectListItem>
        ))}
      </SelectList>
    )
  }

  return (
    <>
      {renderSearch()}
      {props.loading ? <Loader /> : renderOpportunities()}
    </>
  )
}

OpportunitiesList.propTypes = {
  addOpportunity: PropTypes.func,
  changeValues: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  opportunities: PropTypes.arrayOf(PropTypes.object),
  props: PropTypes.object,
  refetch: PropTypes.func,
  showAlert: PropTypes.func,
}

const mapDispatchToProps = { showAlert, addOpportunity }

export default compose(
  graphql(ALL_OPPORTUNITIES_QUERY, {
    /**
     * Note: The 'no-cache' fetchPolicy must be used
     * or an edge case bug is present where, after just
     * logging in, filtering results fails until refreshing
     * the browser page.
     */
    options: {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
    props: ({ data } = {}) => ({
      opportunities: get(data, 'Salesforce.opportunities') || [],
      refetch: data.refetch,
      loading: data.loading,
    }),
  }),
  appConnect(null, mapDispatchToProps)
)(withApollo(OpportunitiesList))
