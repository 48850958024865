import * as THREE from 'three'
import {
  string,
  number,
  instanceOf,
  oneOf,
  bool,
  shape,
  array,
} from 'prop-types'
import filter from 'lodash-es/filter'
import isNil from 'lodash-es/isNil'
import groupBy from 'lodash-es/groupBy'
import get from 'lodash-es/get'
import flatten from 'lodash-es/flatten'
import isArray from 'lodash-es/isArray'

import store from 'store'
import { getVTKData } from 'store/cfd'
import { SYSTEMS, TYPES } from 'store/units/constants'
import { Velocity, Temperature, Distance } from 'store/units/types'

import { facilityData } from 'config/comfortPointFacilityData'
import { primaryUseVelocityTypes } from 'config/facility'

import { comfortModel as comfortModels } from './thermalComfortTool/comfortModels'
import * as Util from './thermalComfortTool/util'

export const METRICS_PROPS = [
  {
    prop: 'avgSpeed',
    isValid: value => Velocity.isValid(value),
    load: data => new Velocity(data),
  },
  {
    prop: 'minAvgSpeed',
    isValid: value => Velocity.isValid(value),
    load: data => new Velocity(data),
  },
  {
    prop: 'maxAvgSpeed',
    isValid: value => Velocity.isValid(value),
    load: data => new Velocity(data),
  },
  {
    prop: 'avgAirTemp',
    isValid: value => Temperature.isValid(value),
    load: data => new Temperature(data),
  },
  {
    prop: 'avgCoolingEffect',
    isValid: value => Temperature.isValid(value),
    load: data => new Temperature(data),
  },
  {
    prop: 'minAvgCoolingEffect',
    isValid: value => Temperature.isValid(value),
    load: data => new Temperature(data),
  },
  {
    prop: 'maxAvgCoolingEffect',
    isValid: value => Temperature.isValid(value),
    load: data => new Temperature(data),
  },
  {
    prop: 'coolingCoverageFraction',
    isValid: value => typeof value === 'number',
    load: value => value,
  },
  {
    prop: 'avgPPD',
    isValid: value => typeof value === 'number',
    load: value => value,
  },
  {
    prop: 'minPPD',
    isValid: value => typeof value === 'number',
    load: value => value,
  },
  {
    prop: 'maxPPD',
    isValid: value => typeof value === 'number',
    load: value => value,
  },
  {
    prop: 'avgPMV',
    isValid: value => typeof value === 'number',
    load: value => value,
  },
  {
    prop: 'minPMV',
    isValid: value => typeof value === 'number',
    load: value => value,
  },
  {
    prop: 'maxPMV',
    isValid: value => typeof value === 'number',
    load: value => value,
  },
  {
    prop: 'avgSET',
    isValid: value => Temperature.isValid(value),
    load: data => new Temperature(data),
  },
  {
    prop: 'minSET',
    isValid: value => Temperature.isValid(value),
    load: data => new Temperature(data),
  },
  {
    prop: 'maxSET',
    isValid: value => Temperature.isValid(value),
    load: data => new Temperature(data),
  },
]

export const CFDUrl = shape({
  fileProps: shape({
    type: string,
    level: string,
  }),
  fileExtension: string,
  url: string,
})

const VelocityUnit = shape({
  value: number,
  type: TYPES.VELOCITY,
  system: oneOf(Object.values(SYSTEMS)),
})

const TemperatureUnit = shape({
  value: number,
  type: TYPES.TEMPERATURE,
  system: oneOf(Object.values(SYSTEMS)),
})

export const PerformanceMetrics = shape({
  avgSpeed: VelocityUnit,
  avgAirTemp: TemperatureUnit,
  minAvgSpeed: VelocityUnit,
  maxAvgSpeed: VelocityUnit,
  avgCoolingEffect: TemperatureUnit,
  minAvgCoolingEffect: TemperatureUnit,
  maxAvgCoolingEffect: TemperatureUnit,
  coolingCoverageFraction: number,
})

const Geometry = shape({
  attributes: shape({
    velocity: shape({
      array: instanceOf(Float32Array),
      count: number,
    }),
  }),
})

export const CFDModel = shape({
  geometry: Geometry,
  isValid: bool,
  validTypes: array,
  isLoading: bool,
  url: string,
})

export const cfdGroups = (urls, withFPM = false) => {
  return groupBy(
    filter(
      urls,
      f => withFPM || !get(f, 'fileProps.type', '').includes('overhead-fpm')
    ).sort((a, b) => get(a, 'fileProps.level') - get(b, 'fileProps.level')),
    f => {
      if (f.id.includes('intensity')) {
        const rawGroupId = f.id
          .slice(f.id.indexOf('intensity'))
          .replace('.', '_')
        const splitGroupId = rawGroupId.split('_')
        return `${splitGroupId?.[2]}_${
          f.fileExtension === 'vtk' ? 'vtk' : 'img'
        }`
      }
      return `${get(f, 'fileProps.type', '')}_${
        f.fileExtension === 'vtk' ? 'vtk' : 'img'
      }`
    }
  )
}

const getDistanceValue = (value, distanceUnits, both = true) =>
  new Distance({
    value,
    system: SYSTEMS.IMPERIAL,
  }).formattedValue(distanceUnits, {
    both,
    roundCentimeters: true,
    round: true,
  })

export const getSuggestedImageData = (
  distanceUnits = SYSTEMS.IMPERIAL,
  image
) => {
  let parsedNumber = 0
  const types = [
    'streamlinesFine',
    'streamlinesCoarse',
    'destrat',
    'UftByMin_x',
    'UftByMin_y',
    'T_x',
    'T_y',
  ]
  const match = /_z(.*?)inch/.exec(image)
  const temperatureMatch = /T_z(.*?)inch/.exec(image)
  const intensityMatch = /intensity_zNormal_([A-Za-z]+)/.exec(image)
  const height = match ? match[1] : null
  const key = height || types.find(type => image.includes(type))

  if (temperatureMatch) {
    const temperatureHeight = temperatureMatch[1]
    const turnoverMatch = /T_z[\d]+inch-([\d]+)turnover/.exec(image)?.[1]
    const turnoverValue = parseInt(turnoverMatch) / 100
    const turnoverLabel = isNaN(turnoverValue) ? "": ` (${turnoverValue} Air Turnover${turnoverValue === 1 ? '':'s'})`
    return {
      title: `${getDistanceValue(temperatureHeight, distanceUnits, false)}`,
      description: `Temperature at ${getDistanceValue(
        temperatureHeight,
        distanceUnits,
        false
      )} above floor${turnoverLabel}`,
    }
  }

  if (intensityMatch) {
    const type = intensityMatch[1]
    const defaultLabel = "IRH Intensity"
    const isoLabel = "IRH Intensity - Isometric"
    const planLabel = "IRH Intensity - Plan"
    const description = "Heat Intensity on a scale of 1 to 10"
    if (!type) {
      return {
        title: defaultLabel,
        description
      }
    } else {
      return {
        title: type === 'planX' ? planLabel : isoLabel,
        description
      }

    }
  }

  if (height) {
    return {
      title: `${getDistanceValue(
        height,
        distanceUnits,
        false
      )} Overhead Airflow`,
      description: `Airflow at ${getDistanceValue(
        height,
        distanceUnits,
        false
      )} above floor`,
    }
  }

  switch (key) {
    case 'streamlinesFine':
      return {
        title: 'Streamline (Fine)',
        description:
          '3D view of air movement (20 streamlines) throughout the space',
      }
    case 'streamlinesCoarse':
      return {
        title: 'Streamline (Coarse)',
        description:
          '3D view of air movement (5 streamlines) throughout the space',
      }
    case 'destrat':
      return {
        title: 'Destratification',
        description: 'Destratification airflow at roof level',
      }
    case 'UftByMin_x':
      parsedNumber = image[image.indexOf(key) + 10]
      return {
        title: `Fan ${parsedNumber} - Section`,
        description: `Show a side view of Fan ${parsedNumber}, oriented along the X-axis`,
      }
    case 'UftByMin_y':
      parsedNumber = image[image.indexOf(key) + 10]
      return {
        title: `Fan ${parsedNumber} - Section`,
        description: `Show a side view of Fan ${parsedNumber}, oriented along the Y-axis`,
      }
    case 'T_x':
      parsedNumber = image[image.indexOf(key) + 3]
      return {
        title: `Fan ${parsedNumber} - Section`,
        description: `Show a side view of Fan ${parsedNumber}, oriented along the X-axis`,
      }
    case 'T_y':
      parsedNumber = image[image.indexOf(key) + 3]
      return {
        title: `Fan ${parsedNumber} - Section`,
        description: `Show a side view of Fan ${parsedNumber}, oriented along the Y-axis`,
      }
    default:
      return { title: '', description: '' }
  }
}

function getMatchUrlFn({ type, level, extension }) {
  return url =>
    url.fileProps.type === type &&
    String(url.fileProps.level) === String(level) &&
    url.fileExtension === extension
}

function sortModels(levels, models, urls, goal = 'cooling') {
  const extension = 'vtk'
  const sortedModels = []
  let loaded = true

  for (let i = 0; i < levels.length; ++i) {
    const type = levels[i] === 'roof' ? 'destrat' : 'overhead'
    const level = levels[i]
    const model = get(models, `${goal}.${type}.${level}`)

    if (!model || !model.geometry) {
      if (urls && urls.length) {
        const urlObj = urls.find(
          getMatchUrlFn({
            type,
            level,
            extension,
          })
        )
        if (urlObj) {
          store.dispatch(getVTKData({ level, type, goal, url: urlObj.url }))
          loaded = false
        }
      }
    }
    if (model) {
      sortedModels.push(model)
    }
  }

  return loaded && sortedModels
}

export const getCFDDataAverageAtPosition = ({
  models,
  position,
  levels,
  urls,
  goal,
}) => {
  const sortedModels = sortModels(
    levels.sort((a, b) => Number(a) - Number(b)),
    models,
    urls,
    goal
  )

  if (!sortedModels) return

  // CFD data for every level at the given position
  const allCFDData = sortedModels.map(model => {
    return getCFDDataAtPosition({ model, position })
  })

  // Collect each value for averaging
  const xValues = allCFDData.map(data => data.velocity[0])
  const yValues = allCFDData.map(data => data.velocity[1])
  const zValues = allCFDData.map(data => data.velocity[2])

  const toAverage = array => array.reduce((a, b) => a + b) / array.length

  return [toAverage(xValues), toAverage(yValues), toAverage(zValues)]
}

export const getCFDDataAtPosition = (
  { model, position },
  isTemperature = false
) => {
  if (get(model, 'isLoading')) {
    return {}
  }
  const attributes = get(model, 'geometry.attributes')
  const positionCount = get(attributes, 'position.count', 0)
  const positionSize = get(attributes, 'position.itemSize')
  let closestPosIndex = 0
  let closestDistance = Infinity

  const currentPos = new THREE.Vector3()
  const otherPos = new THREE.Vector3()
  if (positionCount) {
    for (let i = 0; i < positionCount * positionSize; i += positionSize) {
      currentPos.set(
        attributes.position.array[i],
        attributes.position.array[i + 1],
        attributes.position.array[i + 2]
      )
      otherPos.set(position.x, position.y, position.z)
      const distance = currentPos.distanceTo(otherPos)

      if (distance < closestDistance) {
        closestDistance = distance
        if (isTemperature) {
          closestPosIndex = i / positionSize
        }
        closestPosIndex = i / positionSize
      }
    }
  }

  let data = {
    velocity: [],
    color: [],
    position: [],
  }
  Object.keys(attributes).forEach(attribute => {
    const attributeData = attributes[attribute]
    const arr = get(attributeData, 'array')
    const itemSize = get(attributeData, 'itemSize')
    data[attribute] = []
    for (let i = 0; i < itemSize; ++i) {
      data[attribute].push(arr[i + closestPosIndex * itemSize])
    }
  })

  return data
}

export const getFPM = velocity => {
  if (!velocity || !velocity.length || velocity.length < 3) {
    return new Velocity({
      value: 0,
      system: SYSTEMS.IMPERIAL,
    })
  }

  const x = velocity[0]
  const y = velocity[1]
  const z = velocity[2]

  // We get velocities at x, y, z values
  // Use pythagorean to get actual speed
  return new Velocity({
    value: Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2)),
    system: SYSTEMS.IMPERIAL,
  })
}

/**
 * Calculates the comfort model data.
 * @param {string} primaryUse - The type of facility: Storage, Cardio.
 * @param {string} primaryType - Needed if the primary use is 'other'.
 * @param {Temperature} airTemp - The air temperature of the space.
 * @param {number} humidity - The humidity % of the space.
 * @param {Velocity} velocity - The average velocity of the space.
 * @param {number} heatIntensity - The average heat intensity of the space from 0 to 10.
 * @param {number} benchmarkIntensity - The threshold needed to achieve the benchmark temperature increase.
 * @param {number} benchmarkAirTempIncrease - The amount to increase the temperature by.
 * @param {number} benchmarkAirTempIncrease - The amount to increase the radiant temperature by.
 */
export function getBerkeleyData({
  primaryUse,
  primaryType,
  airTemp,
  humidity,
  velocity,
  heatIntensity,
  benchmarkIntensity = 7,
  benchmarkAirTempIncrease = 10,
  benchmarkRadiantTempIncrease = 25,
}) {
  let comfortData =
    facilityData.find(facility => {
      if (primaryUse === 'OTHER') {
        return facility.type === primaryType
      }
      return facility.type === primaryUse
    }) || facilityData.find(facility => facility.type === 'OTHER')

  if (isNil(airTemp.value)) {
    airTemp.value = comfortData ? comfortData.airTemp : 0
    airTemp.system = SYSTEMS.METRIC
  }

  if (humidity == null || humidity === undefined || humidity <= 0) {
    humidity = comfortData && comfortData.humidity
  }

  // Heat Intensity Calculations
  const newAirTempValue = heatIntensity
    ? airTemp.imperial() +
      (heatIntensity / benchmarkIntensity) * benchmarkAirTempIncrease
    : airTemp.imperial()
  const newAirTemp = new Temperature({
    value: newAirTempValue,
    system: SYSTEMS.IMPERIAL,
  })

  const newMRT = new Temperature({
    value: heatIntensity
      ? airTemp.imperial() +
        (heatIntensity / benchmarkIntensity) * benchmarkRadiantTempIncrease
      : newAirTempValue,
    system: SYSTEMS.IMPERIAL,
  })

  const externalWork = 0
  const pmvElevatedAirspeed = comfortModels.pmvElevatedAirspeed(
    newAirTemp.metric(), // Air temperature (°C)
    newMRT.metric(), // Mean radiant temperature (MRT)
    velocity.metric({ round: 2 }), // Relative air velocity (m/s)
    humidity, // Relative humidity (%)
    comfortData && comfortData.activityLevel, // Metabolic rate (MET)
    comfortData && comfortData.clothingType, // Unit of clothing insulation [m²·K/W] (COL)
    externalWork // External work, normally around 0 (MET)
  )

  return {
    ppd: pmvElevatedAirspeed.ppd,
    pmv: pmvElevatedAirspeed.pmv,
    set: new Temperature({
      value: pmvElevatedAirspeed.set,
      system: SYSTEMS.METRIC,
    }),
    coolingEffect: new Temperature({
      value: newAirTemp.metric() - pmvElevatedAirspeed.ta_adj,
      system: SYSTEMS.METRIC,
    }),
    humidity,
    airTemp,
    newAirTemp,
    newMRT,
  }
}

// Ensure backwards compatibility.
// Old way CFD is an object. New way it's an array of cooling and destrat
export function convertCFDDataToArray(cfdData) {
  let cfds = []
  if (isArray(cfdData)) cfds = cfdData
  else cfds.push({ ...cfdData, type: 'COOLING' })
  return cfds
}