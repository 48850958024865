import styled from 'styled-components'

import { getZIndexOf } from 'lib/utils'
import theme from 'config/theme'

const isInactive = props => props.index > props.current

const size = '20px'

const Number = styled.span`
  color: ${props =>
    isInactive(props) ? theme.colors.light.subdued : theme.colors.light.bg};
  display: block;
  font-size: ${theme.fonts.sizes.xs};
  font-weight: bold;
  line-height: ${size};
  position: absolute;
  text-align: center;
  top: -4px;
  z-index: ${getZIndexOf('step3')};
`

export default Number
