import { useCallback } from 'react'
import { useAppSelector } from "~/store/hooks";
import { Velocity } from 'store/units/types'
import { ImperialVelocity } from 'store/units/types/velocity'

/**
 * Returns a function that formats velocities according to the facility's units.
 * @callback formatVelocity
 * @param {(Velocity|number)} imperialVelocity
 * @param {object} options
 * @param {number} options.round - The number of decimal places to round to.
 * @returns {string}
 *
 * @typedef {object} velocityFormatter
 * @prop {formatVelocity} formatVelocity
 * @returns {velocityFormatter}
 */
export const useFormatVelocity = () => {
  const { VELOCITY } = useAppSelector(({ units }) => units)
  const isMetric = VELOCITY === 'METRIC'
  return {
    formatVelocity: useCallback(
      (imperialVelocity, options = { round: isMetric ? 2 : 0 }) => {
        const formatter = isMetric ? 'formatMetric' : 'formatImperial'
        if (imperialVelocity instanceof Velocity) {
          return imperialVelocity[formatter](options)
        } else {
          return new ImperialVelocity(imperialVelocity)[formatter](options)
        }
      },
      [isMetric]
    ),
  }
}
