import { graphql } from '~/gql'

export const GET_ALL_PRODUCTS = graphql(`
  query GetAllProducts {
    Products {
      id
      model
      type
      application
      category
      sortIndex
      defaultVariation {
        id
      }
    }
  }
`)
