import PropTypes from 'prop-types'

import Container from './styled/Container'

const Label = /** @type {any} */(Container)

Label.propTypes = {
  type: PropTypes.string,
}

export default Label
