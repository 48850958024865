import Util from './util'

// Represents a polygon or polyline edge
class Edge {
  /**
   * @param  {Object} current
   * @param  {Object} next
   * @constructor
   */
  constructor(current, next) {
    /**
     * @type {Object}
     */
    this.current = current

    /**
     * @type {Object}
     */
    this.next = next

    /**
     * @type {Object}
     */
    this._inNormal = this.inwardsNormal()

    /**
     * @type {Object}
     */
    this._outNormal = this.outwardsNormal()
  }

  /**
   * Creates outwards normal
   * @return {Object}
   */
  outwardsNormal() {
    const inwards = this.inwardsNormal()
    return [-inwards[0], -inwards[1]]
  }

  /**
   * Creates inwards normal
   * @return {Object}
   */
  inwardsNormal() {
    const dx = this.next[0] - this.current[0]
    const dy = this.next[1] - this.current[1]
    const edgeLength = Math.sqrt(dx * dx + dy * dy)

    // if (edgeLength === 0) throw new Error('Vertices overlap');
    if (edgeLength === 0) {
      // eslint-disable-next-line
      console.warn('Vertices overlap')
    }

    return [-dy / edgeLength, dx / edgeLength]
  }

  /**
   * Offsets the edge by dx, dy
   * @param  {Number} dx
   * @param  {Number} dy
   * @return {Edge}
   */
  offset(dx, dy) {
    return Edge.offsetEdge(this.current, this.next, dx, dy)
  }

  /**
   * @param  {Number} dx
   * @param  {Number} dy
   * @return {Edge}
   */
  inverseOffset(dx, dy) {
    return Edge.offsetEdge(this.next, this.current, dx, dy)
  }

  /**
   * @static
   * @param  {Array.<Number>} current
   * @param  {Array.<Number>} next
   * @param  {Number}         dx
   * @param  {Number}         dy
   * @return {Edge}
   */
  static offsetEdge(current, next, dx, dy) {
    return new Edge(
      [current[0] + dx, current[1] + dy],
      [next[0] + dx, next[1] + dy]
    )
  }

  /**
   *
   * @return {Edge}
   */
  inverse() {
    return new Edge(this.next, this.current)
  }
}

class Offset {
  static offset(vertices, thicknesses) {
    const edges = Offset._buildEdges(vertices)

    const offsetData = Offset.offsetSegmentList(edges, thicknesses)

    let insetPoints
    let outsetPoints

    let insetEdges
    let outsetEdges

    let pureOutsetPoints

    if (Offset.isPolygon(vertices)) {
      const ccPadding = Util.getCounterClockwisePoints(offsetData.padding)
      const ccMargin = Util.getCounterClockwisePoints(offsetData.margin)

      insetPoints = Offset.clipOffsetEdges(ccPadding, true)
      outsetPoints = Offset.clipOffsetEdges(ccMargin, true)

      pureOutsetPoints = outsetPoints

      insetEdges = Offset.pointsToEdges(insetPoints)
      outsetEdges = Offset.pointsToEdges(outsetPoints)
    } else {
      const padding = Offset.clipOffsetEdges(offsetData.padding, false)
      const margin = Offset.clipOffsetEdges(offsetData.margin, false)

      insetEdges = Offset.pointsToEdges(padding)
      outsetEdges = Offset.pointsToEdges(margin)

      pureOutsetPoints = margin

      outsetPoints = Offset.getClosedOffsetPoints(margin, padding)
    }

    const edgePolygons = Offset.getEdgePolygons(insetEdges, outsetEdges)

    return {
      insetPoints,
      outsetPoints,
      insetEdges,
      outsetEdges,
      edgePolygons,
      pureOutsetPoints,
    }
  }

  /*
    Returns an array of polygons (each as an array of points), one for each edge
    in the given insetEdges/outsetEdges arrays. The polygons are formed by connecting
    the inset/outset edges at either side, so that each returned polygon surrounds
    one edge completely.
  */
  static getEdgePolygons(insetEdges, outsetEdges) {
    if (insetEdges.length !== outsetEdges.length)
      throw new Error(
        `getEdgePolygons(...) requires the same number of inset edges as outset edges, received insetEdges: ${insetEdges.length}, outsetEdges: ${outsetEdges.length}`
      )

    const edgePolygons = []

    // Note that we are only using the unique points on the polygon rather than
    // giving an explicit list of edges.
    for (let i = 0; i < insetEdges.length; i += 1) {
      edgePolygons.push(insetEdges[i].concat(outsetEdges[i].slice().reverse()))
    }

    return edgePolygons
  }

  /*
    Given two sets of points, one for the outset points of a polyline and another for
    the inset points of the same polyline, this will return a single array of points
    which includes the original inset/outset points, but with two edges added connecting
    the inset and offset edges at either end of the polyline.

    The ordering of points in the returned polygon is:
    -insetPoints (in order given)
    -the last point of insetPoints, the last point of outsetPoints (connecting the far end of the polyline)
    -outsetPoints points in reverse order
    -the first outsetPoint (original ordering), the first insetPoint (connecting the near end of the polyline)
  */
  static getClosedOffsetPoints(outsetPoints, insetPoints) {
    let polygonPoints = insetPoints.slice()
    polygonPoints.push(insetPoints[insetPoints.length - 1])
    polygonPoints.push(outsetPoints[outsetPoints.length - 1])
    polygonPoints = polygonPoints.concat(outsetPoints.slice().reverse())
    polygonPoints.push(outsetPoints[0])
    polygonPoints.push(insetPoints[0])

    return polygonPoints
  }

  static _buildEdges(vertices) {
    const edges = []

    // This should work for both polygons and polylines since
    // we repeat the last vertex in the case of polygons.
    const edgeCount = vertices.length - 1

    for (let i = 0; i < edgeCount; i += 1) {
      edges.push(new Edge(vertices[i], vertices[i + 1]))
    }

    return edges
  }

  static isPolygon(vertices) {
    return (
      vertices.length > 2 &&
      Offset.equals(vertices[0], vertices[vertices.length - 1])
    )
  }

  static _offsetSegment(e1, dist) {
    const offsets = [
      e1.offset(e1._inNormal[0] * dist, e1._inNormal[1] * dist),
      e1.offset(e1._inNormal[0] * -dist, e1._inNormal[1] * -dist),
    ]

    const margin = [offsets[1].current, offsets[1].next]
    const padding = [offsets[0].current, offsets[0].next]

    return {
      padding,
      margin,
    }
  }

  static pointsToEdges(points) {
    const edges = []
    for (let i = 0; i < points.length; i += 2) {
      edges.push([points[i], points[i + 1]])
    }

    return edges
  }

  static clipOffsetEdges(edgePoints, isPolygon) {
    // No clipping necessary if there is only one edge (i.e. 2 edge points)
    if (edgePoints.length === 2) {
      return edgePoints.slice()
    }

    const clippedEdgePoints = []

    // The last two points are for the edge connecting the last point back
    // to the first point, which is only present in polygons.
    const length = isPolygon ? edgePoints.length : edgePoints.length - 2

    for (let i = 0; i < length; i += 2) {
      // First edge
      const p1 = edgePoints[i]
      const p2 = edgePoints[i + 1]

      // Second edges
      const thirdPointIndex = (i + 2) % edgePoints.length
      const fourthPointIndex = (i + 3) % edgePoints.length
      const p3 = edgePoints[thirdPointIndex]
      const p4 = edgePoints[fourthPointIndex]

      const line1 = Util.lineFromPoints(p1, p2)
      const line2 = Util.lineFromPoints(p3, p4)

      let intersectionPoint = Util.getIntersectionPoint(
        line1.slope,
        line1.yShift,
        line2.slope,
        line2.yShift
      )
      intersectionPoint = Util.objectPointToArrayPoint(intersectionPoint)

      // set end point of first segment to intersection point
      // set start point of second segment to intersection point
      clippedEdgePoints[i + 1] = intersectionPoint.slice()
      clippedEdgePoints[thirdPointIndex] = intersectionPoint.slice()

      if (clippedEdgePoints[i] === undefined) {
        clippedEdgePoints[i] = edgePoints[i]
      }
      if (clippedEdgePoints[fourthPointIndex] === undefined) {
        clippedEdgePoints[fourthPointIndex] = edgePoints[fourthPointIndex]
      }
    }

    return clippedEdgePoints
  }

  static offsetSegmentList(edges, thicknesses) {
    let padding = []
    let margin = []

    for (let i = 0; i < edges.length; i += 1) {
      const segmentData = Offset._offsetSegment(edges[i], thicknesses[i] / 2)
      padding = padding.concat(segmentData.padding)
      margin = margin.concat(segmentData.margin)
    }

    return { padding, margin }
  }

  static equals(p1, p2) {
    return p1[0] === p2[0] && p1[1] === p2[1]
  }
}

export default Offset
