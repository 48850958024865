import React from 'react'
import PropTypes from 'prop-types'

import H1 from 'components/UIKit/H1'
import Button from 'components/UIKit/Button'
import Space from 'components/UIKit/Space'

import Container from './styled/Container'

const DoesNotExistRoute = (props = {}) => (
  <Container flush={props.flush}>
    <Space bottom="base">
      <H1>Facility No Longer Exists</H1>
      <p>Sorry, the facility you are trying to access no longer exists.</p>
    </Space>
    <Button primary to="/" label="Go Home" />
  </Container>
)

DoesNotExistRoute.propTypes = {
  flush: PropTypes.bool,
}

export default DoesNotExistRoute
