import React from 'react'
import PropTypes from 'prop-types'
import { useNetwork } from 'networkProvider'

import routes from 'config/routes'

import { ALL_FACILITIES_QUERY, ALL_USER_FAVORITES_QUERY } from 'client/queries'
import { FACILITY_PAGE_LIMIT } from 'client/decorators/withInitialData'

import FacilitiesListItem from './FacilitiesListItem'
import EnhancedSelectList from '../EnhancedSelectList'
import FACILITY_SEARCH_QUERY from 'client/queries/facilitySearchQuery'

const FacilitiesList = (props = {}) => {
  const network = useNetwork()
  return (
    <EnhancedSelectList
      views={[
        {
          title: 'My Facilities',
          filter: null,
          icon: 'building',
          query: ALL_FACILITIES_QUERY,
          emptyMessage: 'You have no facilities right now. Create one!',
          emptySearchMessage: 'No facilities match that query.',
          url: routes.facilities,
          queryName: 'allFacilities.facilities',
          count: 'allFacilities.count',
          limit: FACILITY_PAGE_LIMIT,
        },
        {
          title: 'My Favorites',
          filter: { isFavorite: true },
          icon: 'star',
          query: ALL_USER_FAVORITES_QUERY,
          emptyMessage: 'You have no favorited facilities right now.',
          emptySearchMessage: 'No favorited facilities match that query.',
          url: routes.facilityFavorites,
          queryName: 'allFacilities',
          getLocalPrimaryUses: true,
          count: 'allFacilities.count',
          limit: FACILITY_PAGE_LIMIT,
          user: props.user,
        },
        {
          title: 'Archived',
          filter: { isArchived: true },
          icon: 'inbox',
          query: ALL_FACILITIES_QUERY,
          emptyMessage: 'You have no archived facilities right now.',
          emptySearchMessage: 'No archived facilities match that query.',
          url: routes.facilityArchived,
          queryName: 'allFacilities.facilities',
          getLocalPrimaryUses: true,
          count: 'allFacilities.count',
          limit: FACILITY_PAGE_LIMIT,
        },
        {
          title: 'All Facilities',
          filter: null,
          icon: 'building',
          query: FACILITY_SEARCH_QUERY,
          emptyMessage:
            'Search all facilities by name, author name/email, or filter by primary use.',
          emptySearchMessage: 'No facilities match that query.',
          url: routes.facilities,
          queryName: 'facilitySearch.facilities',
          count: 'facilitySearch.count',
          limit: FACILITY_PAGE_LIMIT,
          allUsers: true,
          user: props.user,
        },
      ]}
      initialView={props.view}
      paginateLocally={!network.online}
    >
      {({ data, variables, user, title }) =>
        data.map(facility => {
          return (
            <FacilitiesListItem
              user={user}
              title={title}
              key={facility.id}
              facility={facility}
              variables={variables}
              offline={!network.online}
            />
          )
        })
      }
    </EnhancedSelectList>
  )
}

FacilitiesList.propTypes = {
  data: PropTypes.object,
  view: PropTypes.string,
  user: PropTypes.object,
}

export default FacilitiesList
