import React from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter } from 'react-router-dom'
import { graphql } from '@apollo/client/react/hoc'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { withProps } from 'recompact'
import get from 'lodash-es/get'

import { GET_FACILITY_QUERY, ALL_FACILITIES_QUERY } from 'client/queries'
import {
  DELETE_FACILITY_MUTATION,
  DELETE_VERSION_MUTATION,
  UPDATE_FLOOR_MUTATION,
  DELETE_FLOOR_MUTATION,
} from 'client/mutations'
import { handleDeleteFacility } from 'client/handlers'
import { showAlert } from 'store/alert'

import routes from 'config/routes'

import { clearStatus, setStatusOn } from 'store/status'

import Button from 'components/UIKit/Button'
import { ClickablePopoverItem } from 'components/UIKit/Popover'
import { ToolbarGroup, ToolbarItem } from 'components/UIKit/Toolbar'

import DuplicateFacilityModal from 'components/Modals/DuplicateFacilityModal'
import EditFacilityModal from 'components/Modals/EditFacilityModal'
import NewFacilityModal from 'components/Modals/NewFacilityModal'
import NewFacilityTemplateModal from 'components/Modals/NewFacilityTemplateModal'
import NewVersionModal from 'components/Modals/NewVersionModal'
import NewFloorModal from 'components/Modals/NewFloorModal'
import DuplicateFloorModal from 'components/Modals/DuplicateFloorModal'
import EditVersionModal from 'components/Modals/EditVersionModal'
import EditFloorModal from 'components/Modals/EditFloorModal'
import SelectOpportunityModal from 'components/Modals/SelectOpportunityModal'
import { hasPermission } from 'components/RequiredPermission'
import Logo from 'components/UIKit/Logo'
import {
  removeStorageItems,
  removeStorageItem,
} from 'components/Modals/ExportPDFLayoutModal/Wizard/constants'
import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

function facilityOptions({ ...props } = {}) {
  const actions = []
  const id = props.facility.id
  const notMyFacility = props.facility.author.id !== props.userId
  const isTemplate = props.facility.isTemplate
  const item = isTemplate ? 'template' : 'facility'

  if (
    !props.hideEditButtons ||
    (isTemplate && !props.hideEditTemplateButtons)
  ) {
    actions.push({
      title: `Edit facility details`,
      icon: {
        name: 'edit',
      },
      to: `${props.match.url}/edit-facility/${id}`,
    })
  }

  if (!props.hideDuplicateButtons) {
    actions.push({
      title: `Copy this ${item}`,
      icon: {
        name: 'duplicate',
      },
      to: `${props.match.url}/duplicate-facility/${id}`,
    })
  }

  if (!props.hideDeleteButtons && !isTemplate) {
    actions.push({
      title: 'Delete this facility',
      icon: {
        name: 'trash',
      },
      onClick: event => {
        event.preventDefault()
        event.stopPropagation()

        // eslint-disable-next-line
        if (confirm('Are you sure you want to delete this facility?')) {
          props
            .onFacilityDelete({
              id,
            })
            .then(res => {
              removeStorageItems({ facility: res.data.deleteFacility.id })

              props.onShowAlert({
                type: 'success',
                text: 'Facility deleted!',
              })
              if (props.match.url.split('/').includes(id)) {
                props.history.push('/facilities')
              }
            })
        }
      },
    })
  }

  // The current user is not the owner of this facility,
  // so we want to give them an option to duplicate.
  if (notMyFacility) {
    if (!isTemplate || (isTemplate && props.hideEditTemplateButtons)) {
      return [
        {
          id,
          title: `Copy '${props.facility.name}'`,
          description: props.facility.author.name
            ? `Created by ${props.facility.author.name}`
            : null,
          icon: {
            name: 'duplicate',
          },
          to: `${props.match.url}/duplicate-facility/${id}`,
        },
      ]
    }
  }

  if (!props.hideCreateTemplateButtons && !isTemplate) {
    actions.push({
      title: 'Save as a template...',
      icon: {
        name: 'interiorWall',
      },
      to: `${props.match.url}/new-template/${id}`,
    })
  }

  return actions
}

const TopLeftTouch = ({
  facilities,
  facility,
  hideCreateTemplateButtons,
  hideDeleteButtons,
  hideDuplicateButtons,
  hideEditButtons,
  hideEditTemplateButtons,
  history,
  match,
  onClearStatus,
  onFacilityDelete,
  onFloorDelete,
  onSetStatus,
  onShowAlert,
  onVersionChange,
  onVersionDelete,
  popoverItemsLoading,
  showCreateNewFacilityButton,
  type,
  userId,
  userName,
  versionId,
  online,
}) => {
  const floorName = get(facility, 'floor.name', 'Areas')
  const versionName = get(facility, 'floor.version.name', 'Versions')

  return (
    <ToolbarGroup size="70%" alignment="left">
      <ToolbarItem paddingLeft="m" paddingRight="m">
        <Logo secondary height="40" />
      </ToolbarItem>
      {online && (
        <ClickablePopoverItem
          itemsLoading={popoverItemsLoading}
          items={facilityOptions({
            facility,
            hideDeleteButtons,
            hideDuplicateButtons,
            hideEditButtons,
            hideEditTemplateButtons,
            hideCreateTemplateButtons,
            history,
            match,
            onFacilityDelete,
            onShowAlert,
            userId,
          })}
          renderTrigger={({ isDropdownVisible }) => (
            <ToolbarItem>
              <Button
                isDropdownVisible={isDropdownVisible}
                dropdown
                responsive
                truncate="125px"
                noBorder
                icon="building"
                iconColor="primary"
                label={facility.name}
                data-testid="facility-item-options-single"
                variant="dark"
              />
            </ToolbarItem>
          )}
          action={
            showCreateNewFacilityButton && online
              ? {
                  title: 'Create New Facility',
                  to: `${match.url}${routes.modals.newFacility}`,
                }
              : null
          }
          variant="dark"
        />
      )}
      <ClickablePopoverItem
        items={facility.floors.map(f => ({
          id: f.id,
          title: f.name,
          onClick: event => {
            event.preventDefault()

            history.push(
              `/facility/${facility.id}/area/${f.id}/version/${f.version.id}`
            )
          },
          active: f.id === facility.floor.id,
          actions: online
            ? [
                {
                  icon: {
                    name: 'edit',
                  },
                  variant: 'dark',
                  to: `${match.url}/edit-area/${f.id}`,
                },
                {
                  icon: { name: 'duplicate' },
                  variant: 'dark',
                  onClick: event => {
                    event.preventDefault()
                    event.stopPropagation()

                    history.push(`${match.url}/duplicate-area/${f.id}`)
                  },
                },
                {
                  icon: facility.floors.length > 1 ? { name: 'trash' } : null,
                  variant: 'dark',
                  onClick: event => {
                    event.preventDefault()
                    event.stopPropagation()

                    if (
                      // eslint-disable-next-line
                      confirm('Are you sure you want to delete this area?')
                    ) {
                      onFloorDelete({ id: f.id }).then(res => {
                        const floorId = res.data.deleteFloor.facility.floor.id
                        const versionId =
                          res.data.deleteFloor.facility.floor.version.id

                        removeStorageItem({
                          facility: facility.id,
                          area: res.data.deleteFloor.id,
                        })

                        onShowAlert({
                          type: 'success',
                          text: 'Area deleted',
                        })
                        history.push(
                          `/facility/${facility.id}/area/${floorId}/version/${versionId}`
                        )
                      })
                    }
                  },
                },
              ]
            : null,
        }))}
        renderTrigger={({ isDropdownVisible }) => (
          <ToolbarItem paddingLeft="s" paddingRight="s">
            <Button
              responsive
              isDropdownVisible={isDropdownVisible}
              dropdown
              noBorder
              icon="floor"
              label={floorName}
              variant="dark"
              iconColor="primary"
            />
          </ToolbarItem>
        )}
        action={
          online
            ? {
                title: 'Create New Area',
                to: `${match.url}${routes.modals.newFloor}`,
              }
            : null
        }
        scrollable
        variant="dark"
      />
      <ClickablePopoverItem
        items={facility.floor.versions.map(v => ({
          id: v.id,
          title: v.name,
          onClick: event => {
            event.preventDefault()

            onVersionChange({
              id: facility.floor.id,
              currentVersionId: v.id,
            }).then(() => {
              history.push(
                `/facility/${facility.id}/area/${facility.floor.id}/version/${v.id}`
              )
            })
          },
          active: v.id === facility.floor.version.id,
          actions: online
            ? [
                {
                  icon: {
                    name: 'edit',
                  },
                  variant: 'dark',
                  to: `${match.url}/edit-version/${v.id}`,
                },
                {
                  icon:
                    facility.floor.versions.length > 1
                      ? { name: 'trash' }
                      : null,
                  variant: 'dark',
                  onClick: event => {
                    event.preventDefault()
                    event.stopPropagation()

                    if (
                      // eslint-disable-next-line
                      confirm('Are you sure you want to delete this version?')
                    ) {
                      onVersionDelete({ id: v.id }).then(res => {
                        onShowAlert({
                          type: 'success',
                          text: 'Version deleted!',
                        })
                        history.push(`/facility/${facility.id}`)
                      })
                    }
                  },
                },
              ]
            : null,
        }))}
        renderTrigger={({ isDropdownVisible }) => (
          <ToolbarItem paddingLeft="s" paddingRight="s">
            <Button
              isDropdownVisible={isDropdownVisible}
              dropdown
              noBorder
              icon="disk"
              responsive
              label={versionName}
              variant="dark"
              iconColor="primary"
            />
          </ToolbarItem>
        )}
        action={
          online
            ? {
                title: 'Create New Version',
                to: `${match.url}${routes.modals.newVersion}`,
              }
            : null
        }
        scrollable
        variant="dark"
      />
      <SentryRoute
        path={`${match.url}${routes.modals.duplicateFacility}`}
        render={props => (
          <DuplicateFacilityModal
            parentRoute={match.url}
            currentFacilityName={facility.name}
            {...props}
          />
        )}
      />
      <SentryRoute
        exact
        path={`${match.url}${routes.modals.editFacility}`}
        render={props => (
          <EditFacilityModal parentRoute={match.url} {...props} />
        )}
      />
      <SentryRoute
        exact
        path={`${match.url}${routes.modals.newFacility}`}
        render={props => (
          <NewFacilityModal parentRoute={match.url} {...props} />
        )}
      />
      <SentryRoute
        path={`${match.url}${routes.modals.editFacility}${routes.modals.selectOpportunity}`}
        render={props => <SelectOpportunityModal {...props} />}
      />
      <SentryRoute
        path={`${match.url}${routes.modals.newFacility}${routes.modals.selectOpportunity}`}
        render={props => <SelectOpportunityModal {...props} />}
      />
      <SentryRoute
        exact
        path={`${match.url}${routes.modals.newFacilityTemplate}`}
        render={props => (
          <NewFacilityTemplateModal
            parentRoute={match.url}
            versionId={facility.floor.version.id}
            {...props}
          />
        )}
      />
      <SentryRoute
        path={`${match.url}${routes.modals.newFloor}`}
        render={props => (
          <NewFloorModal
            parentRoute={match.url}
            facility={facility}
            {...props}
          />
        )}
      />
      <SentryRoute
        path={`${match.url}${routes.modals.editFloor}`}
        render={props => (
          <EditFloorModal
            parentRoute={match.url}
            facility={facility}
            {...props}
          />
        )}
      />
      <SentryRoute
        path={`${match.url}${routes.modals.duplicateFloor}`}
        render={props => (
          <DuplicateFloorModal
            parentRoute={match.url}
            facility={facility}
            userId={userId}
            {...props}
          />
        )}
      />
      <SentryRoute
        path={`${match.url}${routes.modals.newVersion}`}
        render={props => (
          <NewVersionModal
            parentRoute={match.url}
            facility={facility}
            userId={userId}
            {...props}
          />
        )}
      />
      <SentryRoute
        path={`${match.url}${routes.modals.editVersion}`}
        render={props => (
          <EditVersionModal
            parentRoute={match.url}
            facility={facility}
            {...props}
          />
        )}
      />
    </ToolbarGroup>
  )
}

TopLeftTouch.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.object),
  facility: PropTypes.object,
  hideCreateTemplateButtons: PropTypes.bool,
  hideDeleteButtons: PropTypes.bool,
  hideDuplicateButtons: PropTypes.bool,
  hideEditButtons: PropTypes.bool,
  hideEditTemplateButtons: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  objects: PropTypes.object,
  onClearStatus: PropTypes.func,
  onFacilityDelete: PropTypes.func,
  onFloorDelete: PropTypes.func,
  onSetStatus: PropTypes.func,
  onShowAlert: PropTypes.func,
  onVersionChange: PropTypes.func,
  onVersionDelete: PropTypes.func,
  popoverItemsLoading: PropTypes.bool,
  push: PropTypes.func,
  showCreateNewFacilityButton: PropTypes.bool,
  type: PropTypes.string,
  url: PropTypes.string,
  userId: PropTypes.string,
  userName: PropTypes.string,
  versionId: PropTypes.string,
  online: PropTypes.bool,
}

const mapStateToProps = ({ auth, objects, panel }) => ({
  userId: auth.userId,
  userName: auth.name,
  objects: objects.present.objects,
  type: panel.bottom.visiblePanel,
})

const mapDispatchToProps = dispatch => ({
  onShowAlert({ text, type }) {
    dispatch(showAlert({ text, type }))
  },
  onSetStatus({ action, show }) {
    dispatch(setStatusOn({ action, show }))
  },
  onClearStatus({ type }) {
    dispatch(clearStatus({ type }))
  },
  showAlert,
})

export default compose(
  hasPermission({
    name: 'Create Facility',
    withPermissions: true,
  }),
  withProps(props => ({
    showCreateNewFacilityButton:
      props.showCreateNewFacilityButton || props.permissions.isAllowed,
    hideEditButtons: props.hideEditButtons || !props.permissions.isAllowed,
    popoverItemsLoading:
      props.popoverItemsLoading || props.permissions.isLoading,
  })),
  hasPermission({
    name: 'Delete Facility',
    withPermissions: true,
  }),
  withProps(props => ({
    hideDeleteButtons: props.hideDeleteButtons || !props.permissions.isAllowed,
    popoverItemsLoading:
      props.popoverItemsLoading || props.permissions.isLoading,
  })),
  hasPermission({
    name: 'Create Template',
    withPermissions: true,
  }),
  withProps(props => ({
    hideCreateTemplateButtons:
      props.hideCreateTemplateButtons || !props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  hasPermission({
    name: 'Edit Template',
    withPermissions: true,
  }),
  withProps(props => ({
    hideEditTemplateButtons:
      props.hideEditTemplateButtons || !props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  appConnect(mapStateToProps, mapDispatchToProps),
  graphql(DELETE_FACILITY_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onFacilityDelete: ({ id }) =>
        handleDeleteFacility({ id, mutate, ownProps }),
    }),
  }),
  graphql(DELETE_VERSION_MUTATION, {
    props: ({ mutate }) => ({
      onVersionDelete: ({ id }) => mutate({ variables: { id } }),
    }),
    options: props => ({
      refetchQueries: [
        {
          query: GET_FACILITY_QUERY,
          variables: {
            id: props.facility.id,
            floorId: null,
            versionId: null,
          },
          name: 'facilityData',
        },
        {
          query: ALL_FACILITIES_QUERY,
          name: 'allFacilitiesData',
        },
      ],
    }),
  }),
  graphql(DELETE_FLOOR_MUTATION, {
    props: ({ mutate }) => ({
      onFloorDelete: ({ id }) => mutate({ variables: { id } }),
    }),
    options: props => ({
      refetchQueries: [
        {
          query: GET_FACILITY_QUERY,
          variables: {
            id: props.facility.id,
            floorId: null,
            versionId: null,
          },
          name: 'facilityData',
        },
        {
          query: ALL_FACILITIES_QUERY,
          name: 'allFacilitiesData',
        },
      ],
    }),
  }),
  graphql(UPDATE_FLOOR_MUTATION, {
    props: ({ mutate }) => ({
      onVersionChange: variables => mutate({ variables }),
    }),
    options: props => ({
      refetchQueries: [
        {
          query: GET_FACILITY_QUERY,
          variables: {
            id: props.facility.id,
            floorId: null,
            versionId: null,
          },
          name: 'facilityData',
        },
        {
          query: ALL_FACILITIES_QUERY,
          name: 'allFacilitiesData',
        },
      ],
    }),
  }),
  withRouter
)(TopLeftTouch)
