import React from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";

import { isTouchUI } from 'store/userInterface/selectors'

import Icon from 'components/UIKit/Icon'

import Container from './styled/Container'

const DockedPanelTab = ({ onClick, isTouchUI }) => (
  <Container href="#" onClick={onClick} isTouchUI={isTouchUI}>
    <Icon name="caretLeft" color="fg" />
  </Container>
)

DockedPanelTab.propTypes = {
  onClick: PropTypes.func.isRequired,
  isTouchUI: PropTypes.bool,
}

const mapStateToProps = store => ({
  isTouchUI: isTouchUI(store),
})

export default appConnect(mapStateToProps)(DockedPanelTab)
