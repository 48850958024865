import { graphql } from '@apollo/client/react/hoc'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import get from 'lodash-es/get'

import { GET_USER_QUERY } from '../queries'

// TODO: The server already knows the identity of the user
// This query should support 0 variables or add a currentUser query
export default compose(
  appConnect(({ auth }) => ({
    userId: auth.userId,
  })),
  graphql(GET_USER_QUERY, {
    options: ({ userId }) => ({ variables: { id: userId } }),
    props: result => ({
      loading: get(result, 'data.loading') || get(result, 'ownProps.loading'),
      user: get(result, 'data.User'),
    }),
  })
)
