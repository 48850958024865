import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Media = styled.div`
  flex-shrink: 0;
  margin-right: ${theme('spacing.s')};
`

export default Media
