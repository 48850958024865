import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { getTitle } from 'config/titles'

import { CREATE_FACILITY_TEMPLATE_MUTATION } from 'client/mutations'

import TemplateForm from '../../TemplateForm'

const NewFacilityModal = ({ ...props }) => (
  <>
    <Helmet>
      <title>{getTitle('newFacilityTemplate')}</title>
    </Helmet>
    <Mutation
      mutation={CREATE_FACILITY_TEMPLATE_MUTATION}
      refetchQueries={['AllFacilityTemplates']}
    >
      {(createFacilityTemplate, { loading, error }) => (
        <TemplateForm
          title="New Template"
          buttonText="Create Template"
          onSubmit={createFacilityTemplate}
          error={error}
          loading={loading}
          {...props}
        />
      )}
    </Mutation>
  </>
)

export default withRouter(NewFacilityModal)
