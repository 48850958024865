import { graphql } from '~/gql'

const ALL_USAGE_REPORT_QUERY = graphql(`
  query AllUsageReportQuery($filter: ReportFilter) {
    allUsageReport(filter: $filter) {
      reports {
        email
        company
        yearToDate
        last30Days
      }
    }
  }
`)

export default ALL_USAGE_REPORT_QUERY
