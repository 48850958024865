import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Icon from '../../Icon'

const StyledIcon = styled(Icon)<{ marginTop?: string; isVisible?: boolean }>`
  margin-top: ${props => props.marginTop}px;
  margin-right: ${theme('spacing.xs')};
  transition: 0.2s ease-in-out;

  ${props => props.isVisible && `transform: rotate(90deg);`};
`

export default StyledIcon
