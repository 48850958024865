import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Label = styled.h3`
  font-size: ${theme('fonts.sizes.s')};
  text-transform: uppercase;
`

export default Label
