import React, { Component } from 'react'
import { appConnect } from "~/store/hooks";
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { withProps } from 'recompact'
import get from 'lodash-es/get'
import PropTypes from 'prop-types'
import * as THREE from 'three'

import { facilityData } from 'config/comfortPointFacilityData'
import { primaryUses } from 'config/facility'
import CLASS_NAMES from 'config/objectClassNames'

import { enableCanvas, disableCanvas } from 'store/status'
import { updateComfortZone } from 'store/objects'
import { setCFDLayer } from 'store/cfd'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { isTouchUI } from 'store/userInterface/selectors'
import { SELECTED_COMFORT_ZONE_PANEL } from 'store/panel/types'
import { SYSTEMS } from 'store/units/constants'
import { withUnits } from 'store/units/decorators'
import { Temperature } from 'store/units/types'
import { Distance } from 'store/units/types'

import Panel, { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'
import Select from 'components/UIKit/Select'
import Tabs, { Tab } from 'components/UIKit/Tabs'
import TemperatureInput from 'components/UIKit/TemperatureInput'
import TextField from 'components/UIKit/TextField'
import DimensionInput from 'components/UIKit/DimensionInput'
import Grid, { GridItem } from 'components/UIKit/Grid'

import store from 'store'
import LAYER_KEYS from 'config/layerKeys'
import withFacility from 'client/decorators/withFacility'

import MetadataSection from 'components/MetadataSection'
import { hasPermission } from 'components/RequiredPermission'

import BerkeleyData from './BerkeleyData'
import ComfortZoneUtil from 'components/DrawingCanvas/lib/comfortZoneUtil'
import Units from 'components/DrawingCanvas/lib/units'
import { roundTwoDecimals } from 'lib/thermalComfortTool/util'
import { ColorPicker } from 'react-aria-components'
import { ChooserWell } from '~/ui/Color'
import { Label } from '~/ui/Field'
import theme from '~/config/theme'

class SelectedComfortZonePanel extends Component {
  state = {
    showAdvanced: false,
  }

  toggleShowAdvanced() {
    this.setState({
      showAdvanced: !this.state.showAdvanced,
    })
  }

  handleInputChange = (key, value) => {
    const { selectedObject, onUpdateComfortZone } = this.props
    const updatedObject = { ...selectedObject }

    if (Array.isArray(key)) {
      key.forEach((k, i) => {
        updatedObject[k] = value[i]
      })
    } else {
      updatedObject[key] = value
    }

    onUpdateComfortZone(updatedObject)
  }

  getUpdatedPosition(xOffset, yOffset, pos) {
    const position = new THREE.Vector3()
      .copy({
        x: Units.inchesToNative(pos.x),
        y: Units.inchesToNative(pos.y),
        z: Units.inchesToNative(pos.z),
      })
      .add(new THREE.Vector3(xOffset, yOffset, 0))

    return {
      x: Units.nativeToInches(position.x),
      y: Units.nativeToInches(position.y),
      z: Units.nativeToInches(position.z),
    }
  }

  handleDimensionsChange = (newWidth, newLength) => {
    if (!newWidth && !newLength) return

    const { selectedObject, onUpdateComfortZone } = this.props

    const { position, positions, rotation } = selectedObject
    // Grab the width and length from the original obstruction
    let { xDiff, xMin, yDiff, yMin } = ComfortZoneUtil.getPositionDiffs(
      positions
    )
    // Rounding the difference to keep the math cleaner
    xDiff = Math.round(xDiff * 100) / 100
    yDiff = Math.round(yDiff * 100) / 100

    // Calculate the changes in the length and width input for the obstruction.
    const deltaWidth = Units.inchesToNative((newWidth || xDiff) - xDiff)
    const deltaLength = Units.inchesToNative((newLength || yDiff) - yDiff)

    // Calculate the translation vector to be used for the object.
    // First grab the rotation angle and convert it to radians
    const angle = (((rotation && rotation.z) || 0) * Math.PI) / 180

    // Vector is [deltaX, deltaY]* rotation matrix to orient in the direction of the obstruction
    // Since the object grows in 2 directions, we only want to translate points by 1/2 the unit vector
    // If deltaLength is negative, wen want vX to move to the left at 90 rotation
    // Therefore we add deltaLength versus subtracting
    const vX =
      (deltaWidth * Math.abs(Math.cos(angle)) +
        deltaLength * Math.abs(Math.sin(angle))) /
      2
    const vY =
      -(
        deltaWidth * Math.abs(Math.sin(angle)) +
        deltaLength * Math.abs(Math.cos(angle))
      ) / 2
    // Adjust the center based on the new vector
    // The object moves down if y is > 0, so we want to subtract the y
    const newPosition = this.getUpdatedPosition(vX, vY, position)

    // Get the center values of the shifts;
    const centerX = xMin + xDiff / 2
    const centerY = yMin + yDiff / 2
    // Move each position based on the translation
    const newPositions = positions.map(pos => {
      const { x, y, z } = pos
      // Get the percentage of width and height the point was away from the center
      // This is to keep scale for each point
      const xPercent = (x - centerX) / xDiff
      const yPercent = (y - centerY) / yDiff
      const newPos = {
        x: centerX + xPercent * (newWidth || xDiff),
        y: centerY + yPercent * (newLength || yDiff),
        z,
      }
      // Translate the new calculated points and return
      return this.getUpdatedPosition(vX, vY, newPos)
    })
    // Return the updated Obstruction
    const updatedComfortZone = {
      ...selectedObject,
      position: newPosition,
      positions: newPositions,
    }
    // Update the Obstruction
    onUpdateComfortZone(updatedComfortZone)
  }

  handlePrimaryUseChange = (primaryUse, onlyPrimaryUse = false) => {
    const comfortData = facilityData.find(facility => {
      return facility.type === primaryUse
    })

    const airTemp = new Temperature({
      value: comfortData.airTemp,
      system: SYSTEMS.METRIC,
    })

    const winterAirTemp = new Temperature({
      value: comfortData.winterAirTemp,
      system: SYSTEMS.METRIC,
    })

    if (onlyPrimaryUse) {
      this.handleInputChange(['primaryUse', 'metrics'], [primaryUse, {}])
    } else {
      this.handleInputChange(
        [
          'indoorHumidity',
          'indoorWinterHumidity',
          'indoorSummerTemp',
          'indoorWinterTemp',
          'primaryUse',
          'metrics',
        ],
        [
          comfortData && comfortData.indoorHumidity,
          comfortData && comfortData.indoorWinterHumidity,
          airTemp.imperial(),
          winterAirTemp.imperial(),
          primaryUse,
          {},
        ]
      )
    }
  }

  getDistance(value) {
    return new Distance({
      value,
      system: SYSTEMS.IMPERIAL,
    })
  }

  getValue(distance) {
    let value = get(distance, 'value', 0.1)
    if (value < 0.1) return

    value = distance.imperial() || undefined

    return value
  }

  getSettingsData() {
    const { selectedObject, facility } = this.props

    const primaryUse =
      get(selectedObject, 'primaryUse', this.props.primaryUse) ||
      get(facility, 'primaryUse', 'OTHER')

    const primaryType = get(facility, 'primaryType', 'INDUSTRIAL')

    let comfortData = facilityData.find(facility => {
      if (!this.props.facility) return false
      return facility.type === primaryUse
    })

    // If no primary use is found default to 'OTHER'
    if (!comfortData) {
      comfortData = facilityData.find(facility => facility.type === 'OTHER')
    }

    let airTemp =
      selectedObject.indoorSummerTemp ||
      get(facility, 'indoorSummerTemp', this.props.indoorSummerTemp)
    if (airTemp == null || airTemp === undefined || airTemp <= 0) {
      const comfortDataSummerAirTemp = comfortData ? comfortData.airTemp : 0
      airTemp = new Temperature({
        value: comfortDataSummerAirTemp,
        system: SYSTEMS.METRIC,
      })
    } else {
      airTemp = new Temperature({
        value: airTemp,
        system: SYSTEMS.IMPERIAL,
      })
    }

    let winterAirTemp =
      selectedObject.indoorWinterTemp ||
      get(facility, 'indoorWinterTemp', this.props.indoorWinterTemp)
    if (
      winterAirTemp == null ||
      winterAirTemp === undefined ||
      winterAirTemp <= 0
    ) {
      const comfortDataWinterAirTemp = comfortData
        ? comfortData.winterAirTemp
        : 0
      winterAirTemp = new Temperature({
        value: comfortDataWinterAirTemp,
        system: SYSTEMS.METRIC,
      })
    } else {
      winterAirTemp = new Temperature({
        value: winterAirTemp,
        system: SYSTEMS.IMPERIAL,
      })
    }

    let indoorHumidity =
      selectedObject.indoorHumidity === undefined
        ? get(facility, 'indoorHumidity')
        : selectedObject.indoorHumidity

    // if humidity is blank or NaN control is defered to its on blur function
    if (indoorHumidity < 0) {
      indoorHumidity = 0
    } else if (indoorHumidity === undefined || indoorHumidity === null) {
      indoorHumidity = comfortData && comfortData.indoorHumidity
    }

    let indoorWinterHumidity =
      selectedObject.indoorWinterHumidity === undefined
        ? get(facility, 'indoorWinterHumidity')
        : selectedObject.indoorWinterHumidity

    // if humidity is blank or NaN control is defered to its on blur function
    if (indoorWinterHumidity < 0) {
      indoorWinterHumidity = 0
    } else if (
      indoorWinterHumidity === undefined ||
      indoorWinterHumidity === null
    ) {
      indoorWinterHumidity = comfortData && comfortData.indoorWinterHumidity
    }

    return {
      airTemp,
      winterAirTemp,
      indoorHumidity,
      indoorWinterHumidity,
      primaryUse,
      primaryType,
      comfortData,
    }
  }

  getSettings({
    airTemp,
    winterAirTemp,
    indoorHumidity,
    indoorWinterHumidity,
    primaryUse,
    comfortData,
  }) {
    const { distanceUnits, selectedObject, facility } = this.props
    let length
    let width

    const {
      xDiff: objWidth,
      yDiff: objLength,
    } = ComfortZoneUtil.getPositionDiffs(selectedObject.positions)

    if (width === undefined) {
      width = Math.abs(objWidth)
    } else if (
      roundTwoDecimals(width) !== roundTwoDecimals(Math.abs(objWidth))
    ) {
      width = false
    }

    if (length === undefined) {
      length = Math.abs(objLength)
    } else if (
      roundTwoDecimals(length) !== roundTwoDecimals(Math.abs(objLength))
    ) {
      length = false
    }

    const inputWidth = this.props.isTouchUI ? '200px' : '100px'

    const isLocked = store.getState().layers.layers[LAYER_KEYS.COMFORT_ZONES]
      .locked

    if (!primaryUse || !get(selectedObject, 'primaryUse')) {
      this.handlePrimaryUseChange(get(facility, 'primaryUse'), true)
    }

    return (
      <PanelSection>
        <Space bottom="base">
          <DimensionInput
            label="Width"
            labelWidth="70px"
            width={inputWidth}
            name="width"
            disabled={isLocked}
            distance={this.getDistance(width)}
            tabIndex={2}
            units={distanceUnits}
            onChange={({ distance }) => {
              this.handleDimensionsChange(this.getValue(distance), 0)
            }}
          />
        </Space>
        <Space bottom="base">
          <DimensionInput
            label="Length"
            labelWidth="70px"
            width={inputWidth}
            name="Length"
            disabled={isLocked}
            distance={this.getDistance(length)}
            tabIndex={3}
            units={distanceUnits}
            onChange={({ distance }) => {
              this.handleDimensionsChange(0, this.getValue(distance))
            }}
          />
        </Space>
        <Space bottom="base">
          <Select
            name="primaryUse"
            label="Primary Use"
            disabled={isLocked}
            onChange={event => {
              const primaryUse = event.target.value
              this.handlePrimaryUseChange(primaryUse)
            }}
            value={primaryUse}
            options={primaryUses}
          />
        </Space>
        <Grid>
          <GridItem size="1of2">
            <Space bottom="base">
              <TemperatureInput
                label="Indoor Summer Temp"
                disabled={isLocked}
                name="indoorSummerTemp"
                degrees={airTemp}
                showFormattedUnits={true}
                units={this.props.temperatureUnits}
                onChange={({ degrees }) => {
                  this.handleInputChange(
                    ['indoorSummerTemp', 'metrics'],
                    [degrees.imperial(), {}]
                  )
                }}
              />
            </Space>
          </GridItem>
          <GridItem size="1of2">
            <Space bottom="base">
              <TemperatureInput
                label="Indoor Winter Temp"
                disabled={isLocked}
                name="indoorWinterTemp"
                degrees={winterAirTemp}
                showFormattedUnits={true}
                units={this.props.temperatureUnits}
                onChange={({ degrees }) => {
                  this.handleInputChange(
                    ['indoorWinterTemp', 'metrics'],
                    [degrees.imperial(), {}]
                  )
                }}
              />
            </Space>
          </GridItem>
          <GridItem size="1of2">
            <Space bottom="base">
              <TextField
                label="Summer Humidity (%)"
                disabled={isLocked}
                value={indoorHumidity}
                overrideValue={indoorHumidity}
                type="number"
                min="0"
                max="100"
                onBlur={e => {
                  let indoorHumidity = parseFloat(e.target.value)

                  if (
                    isNaN(indoorHumidity) ||
                    indoorHumidity === null ||
                    indoorHumidity === undefined ||
                    indoorHumidity < 0
                  ) {
                    indoorHumidity = get(
                      facility,
                      'indoorHumidity',
                      comfortData && comfortData.indoorHumidity
                    )
                  }

                  this.handleInputChange(
                    ['indoorHumidity', 'metrics'],
                    [indoorHumidity, {}]
                  )
                }}
                onChange={event => {
                  let indoorHumidity = parseFloat(event.target.value)

                  if (indoorHumidity < 0) {
                    indoorHumidity = 0
                  } else if (indoorHumidity > 100) {
                    indoorHumidity = 100
                  }

                  this.handleInputChange(
                    ['indoorHumidity', 'metrics'],
                    [indoorHumidity, {}]
                  )
                }}
              />
            </Space>
          </GridItem>
          <GridItem size="1of2">
            <Space bottom="base">
              <TextField
                label="Winter Humidity (%)"
                disabled={isLocked}
                value={indoorWinterHumidity}
                overrideValue={indoorWinterHumidity}
                type="number"
                min="0"
                max="100"
                onBlur={e => {
                  let indoorWinterHumidity = parseFloat(e.target.value)

                  if (
                    isNaN(indoorWinterHumidity) ||
                    indoorWinterHumidity === null ||
                    indoorWinterHumidity === undefined ||
                    indoorWinterHumidity < 0
                  ) {
                    indoorWinterHumidity = get(
                      facility,
                      'indoorWinterHumidity',
                      comfortData && comfortData.indoorWinterHumidity
                    )
                  }

                  this.handleInputChange(
                    ['indoorWinterHumidity', 'metrics'],
                    [indoorWinterHumidity, {}]
                  )
                }}
                onChange={event => {
                  let indoorWinterHumidity = parseFloat(event.target.value)

                  if (indoorWinterHumidity < 0) {
                    indoorWinterHumidity = 0
                  } else if (indoorWinterHumidity > 100) {
                    indoorWinterHumidity = 100
                  }

                  this.handleInputChange(
                    ['indoorWinterHumidity', 'metrics'],
                    [indoorWinterHumidity, {}]
                  )
                }}
              />
            </Space>
          </GridItem>
        </Grid>
      </PanelSection>
    )
  }

  getTabs() {
    const { selectedObject, facility, showData } = this.props
    const {
      airTemp,
      winterAirTemp,
      primaryUse,
      primaryType,
      indoorHumidity,
      indoorWinterHumidity,
      comfortData,
    } = this.getSettingsData()
    const content = [
      {
        title: 'Settings',
        component: this.getSettings({
          airTemp,
          winterAirTemp,
          indoorHumidity,
          indoorWinterHumidity,
          primaryUse,
          comfortData,
        }),
      },
    ]
    if (showData) {
      content.unshift({
        component: (
          <BerkeleyData
            comfortZone={selectedObject}
            facility={facility}
            primaryUse={primaryUse}
            primaryType={primaryType}
            airTemp={airTemp}
            humidity={indoorHumidity}
          />
        ),
        title: 'Data',
      })
    }

    // If we have more than 1 piece of content (tab), use tabs
    // Otherwise, just show the content without tabs
    return (
      content.length &&
      (content.length > 1 ? (
        <Tabs size="s" splitEvenly={true}>
          {content.map((tab, i) => (
            <Tab key={i} title={tab.title}>
              {tab.component}
            </Tab>
          ))}
        </Tabs>
      ) : (
        content[0].component
      ))
    )
  }

  renderContent() {
    const { selectedObject } = this.props
    const name = selectedObject.name || ''
    const isLocked = store.getState().layers.layers[LAYER_KEYS.COMFORT_ZONES]
      .locked
    return (
      <>
        <PanelSection>
          <Space bottom="base">
            <ColorPicker value={selectedObject.color} onChange={color => this.handleInputChange('color', color.toString("hex"))}>
              <Label>Color</Label>
              <ChooserWell swatches={theme.colors.swatches} isDisabled={isLocked}/>
            </ColorPicker>
          </Space>
          <TextField
            inline
            label="Name"
            labelWidth="60px"
            value={name}
            overrideValue={name}
            disabled={isLocked}
            onChange={event => {
              this.handleInputChange('name', event.target.value)
            }}
          />
        </PanelSection>
        <PanelSection>{this.getTabs()}</PanelSection>
        <MetadataSection
          object={selectedObject}
          onBlur={this.props.onUpdateComfortZone}
          disabled={isLocked}
        />
      </>
    )
  }

  render() {
    const { selectedObject, alignment, isTouchUI } = this.props

    return (
      <Panel
        title="Comfort Zone"
        alignment={alignment}
        docked
        panelKey={SELECTED_COMFORT_ZONE_PANEL}
        scrollable
        hasToolbar={isTouchUI}
      >
        {selectedObject ? this.renderContent() : null}
      </Panel>
    )
  }
}

SelectedComfortZonePanel.propTypes = {
  selectedObject: PropTypes.object,
  facility: PropTypes.object,
  onUpdateComfortZone: PropTypes.func,
  temperatureUnits: PropTypes.string,
  primaryUse: PropTypes.string,
  indoorHumidity: PropTypes.number,
  indoorWinterHumidity: PropTypes.number,
  indoorSummerTemp: PropTypes.number,
  indoorWinterTemp: PropTypes.number,
  showData: PropTypes.bool,
  permissionLoading: PropTypes.bool,
  alignment: PropTypes.string,
  isTouchUI: PropTypes.bool,
  distanceUnits: PropTypes.string,
  disabled: PropTypes.bool,
}

SelectedComfortZonePanel.defaultProps = {
  alignment: 'right',
}

const mapStateToProps = ({ selectedObjects, objects, ...store }) => {
  const selectedObject = mostRecentSelectedObjectOfClassName(
    CLASS_NAMES.COMFORT_ZONE,
    {
      selectedObjects,
      objects,
    }
  )
  return {
    layers: store.layers,
    isTouchUI: isTouchUI(store),
    selectedObject,
    objects,
  }
}

const mapDispatchToProps = dispatch => ({
  onDisableCanvas() {
    dispatch(disableCanvas({}))
  },
  onEnableCanvas() {
    dispatch(enableCanvas({}))
  },
  onUpdateComfortZone(comfortZone) {
    dispatch(updateComfortZone({ comfortZone }))
  },
  onUpdateCFDLayer(selectedLayer) {
    dispatch(setCFDLayer(selectedLayer))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withApollo,
  withUnits,
  withFacility,
  hasPermission({
    name: 'See Raw Data',
    withPermissions: true,
  }),
  withProps(props => ({
    showData: props.permissions.isAllowed,
    permissionLoading: props.loading || props.permissions.isLoading,
  }))
)(SelectedComfortZonePanel)
