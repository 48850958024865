import React, { Component } from 'react'
import PropTypes from 'prop-types'

import A from '../A'
import Modal from '../Modal'

import Container from './styled/Container'
import FullImageContainer from './styled/FullImageContainer'
import Icon from './styled/Icon'

class ImageZoom extends Component {
  state = {
    isZoomed: false,
  }

  handleClick = event => {
    event.preventDefault()

    this.toggleZoom(true)
  }

  toggleZoom(flag) {
    this.setState({
      isZoomed: flag,
    })
  }

  render() {
    const { thumbnail, fullImage, modalSize } = this.props
    const { isZoomed } = this.state

    return (
      <Container>
        <A href="#0" onClick={this.handleClick}>
          {thumbnail}
          <Icon name="zoomIn" size="12" />
        </A>
        {isZoomed && (
          <Modal onClose={() => this.toggleZoom(false)} size={modalSize}>
            <FullImageContainer>{fullImage}</FullImageContainer>
          </Modal>
        )}
      </Container>
    )
  }
}

ImageZoom.propTypes = {
  fullImage: PropTypes.node.isRequired,
  modalSize: PropTypes.string,
  thumbnail: PropTypes.node.isRequired,
}

ImageZoom.defaultProps = {
  modalSize: '800px',
}

export default ImageZoom
