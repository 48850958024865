import Units from './units'
import Facility from './facility'
import ArrowRenderer from './arrowRenderer'

import OBJECT_TYPES from 'config/objectTypes'
import CLASS_NAMES from 'config/objectClassNames'
import LAYER_KEYS from 'config/layerKeys'
import getRenderOrder from 'config/canvasRenderOrder'

import { updateObjects } from 'store/objects'
import store from 'store'
import CLICK_PRIORITY from 'config/clickPriority'

import * as THREE from 'three'

class GridBox {
  constructor({ id, xMin, yMin, xMax, yMax, height, type, models }) {
    this.id = id
    this.className = CLASS_NAMES.GRID_BOX
    this.layerKey = LAYER_KEYS.GRID_BOX
    this.clickPriority = CLICK_PRIORITY[this.layerKey]
    this.units = 'INCHES'

    this.models = models
    this.objectsInGrid = models.map(model => model.id)

    this.type = type

    this.native = {
      xMin: Units.unitsToNative(this.units, xMin),
      yMin: Units.unitsToNative(this.units, yMin),
      xMax: Units.unitsToNative(this.units, xMax),
      yMax: Units.unitsToNative(this.units, yMax),
      height: Units.unitsToNative(this.units, height),
    }

    const geometry = new THREE.BoxGeometry(
      this.native.xMax - this.native.xMin,
      this.native.yMax - this.native.yMin,
      this.native.height + 0.01 // add a little to let the lines show against the object
    )
    const material = new THREE.MeshBasicMaterial({
      color: 0x000fff,
      opacity: 0.2,
      transparent: true,
    })
    this.obj3d = new THREE.Mesh(geometry, material)

    this.obj3d.position.set(
      this.native.xMin + (this.native.xMax - this.native.xMin) / 2,
      this.native.yMin + (this.native.yMax - this.native.yMin) / 2,
      this.native.height / 2 + 0.01
    )
    this.obj3d.userData.objectType = OBJECT_TYPES.GRID_BOX

    const gridBoxGeometry = new THREE.EdgesGeometry(geometry)
    const gridBoxMaterial = new THREE.LineDashedMaterial({
      color: 0xff0000,
    })
    this.obj3d.add(new THREE.LineSegments(gridBoxGeometry, gridBoxMaterial))
    this.obj3d.renderOrder = getRenderOrder('gridBox')
    this.obj3d.wrapperId = this.id

    ArrowRenderer.subscribe(this)

    this.selectable = true
    this.draggable = true
    this.multiDraggable = false
  }

  drag({ dragDelta = { x: 0, y: 0 } }) {
    const xOffset = (this.native.xMax - this.native.xMin) / 2
    const yOffset = (this.native.yMax - this.native.yMin) / 2
    const pos = {
      x: this.obj3d.position.x + dragDelta.x,
      y: this.obj3d.position.y + dragDelta.y,
    }

    this.obj3d.position.set(pos.x, pos.y, this.obj3d.position.z)
    this.native = {
      ...this.native,
      xMin: pos.x - xOffset,
      xMax: pos.x + xOffset,
      yMin: pos.y - yOffset,
      yMax: pos.y + yOffset,
    }

    this.objectsInGrid.forEach(id => {
      const object = Facility.current.findObjectWithId(id)
      if (object) {
        const objectPosition = object.obj3d.position.clone()
        objectPosition.setX(objectPosition.x + dragDelta.x)
        objectPosition.setY(objectPosition.y + dragDelta.y)
        object.move(objectPosition)
      }
    })

    this.arrowsHaveBeenAdded = false
    this.isDragging = true
  }

  drop() {
    const models = this.objectsInGrid.map(id => {
      const object = Facility.current.findObjectWithId(id)
      return object && object.toModel()
    })
    this.models = [...models]
    models.push(this.toModel())
    store.dispatch(updateObjects(models))
  }

  snap(snapDelta) {
    const newPosition = this.obj3d.position.clone()
    newPosition.setX(newPosition.x + snapDelta.x)
    newPosition.setY(newPosition.y + snapDelta.y)
    this.drag({ newPosition })
  }

  toModel() {
    return {
      id: this.id,
      models: this.models,
      xMin: Units.nativeToUnits(this.units, this.native.xMin),
      xMax: Units.nativeToUnits(this.units, this.native.xMax),
      yMin: Units.nativeToUnits(this.units, this.native.yMin),
      yMax: Units.nativeToUnits(this.units, this.native.yMax),
      height: Units.nativeToUnits(this.units, this.native.height),
      type: this.type,
      className: CLASS_NAMES.GRID_BOX,
    }
  }

  getSnappableEdgePoints() {
    return [
      [this.native.xMin, this.native.yMin, 0],
      [this.native.xMin, this.native.yMax, 0],
      [this.native.xMax, this.native.yMax, 0],
      [this.native.xMax, this.native.yMin, 0],
    ]
  }

  getArrowDescriptions() {
    if (this.arrowsHaveBeenAdded) {
      return this.arrowDescriptions
    }

    const descriptions = []

    const xMid = this.native.xMin + (this.native.xMax - this.native.xMin) / 2
    const yMid = this.native.yMin + (this.native.yMax - this.native.yMin) / 2

    const directions = [
      {
        direction: 'up',
        vector: new THREE.Vector3(0, 1, 0),
        position: new THREE.Vector3(
          xMid,
          this.native.yMax,
          this.native.height / 2
        ),
      },
      {
        direction: 'down',
        vector: new THREE.Vector3(0, -1, 0),
        position: new THREE.Vector3(
          xMid,
          this.native.yMin,
          this.native.height / 2
        ),
      },
      {
        direction: 'right',
        vector: new THREE.Vector3(1, 0, 0),
        position: new THREE.Vector3(
          this.native.xMax,
          yMid,
          this.native.height / 2
        ),
      },
      {
        direction: 'left',
        vector: new THREE.Vector3(-1, 0, 0),
        position: new THREE.Vector3(
          this.native.xMin,
          yMid,
          this.native.height / 2
        ),
      },
    ]

    const options = []

    switch (this.type) {
      case CLASS_NAMES.OBSTRUCTION:
        options.push({
          measureFrom: Facility.SURFACE,
          measureTo: Facility.SURFACE,
          includedTypes: [OBJECT_TYPES.WALL, OBJECT_TYPES.OBSTRUCTION],
          xyOnly: true,
          obstructionMode: true,
        })
        break
      case CLASS_NAMES.PRODUCT:
        options.push({
          measureFrom: Facility.CENTER,
          measureTo: Facility.CENTER,
          includedTypes: [OBJECT_TYPES.FAN_COLLISION_CUBE],
          xyOnly: true,
        })
        options.push({
          measureFrom: Facility.CENTER,
          measureTo: Facility.SURFACE,
          includedTypes: [OBJECT_TYPES.WALL],
          xyOnly: true,
        })
        break
      default:
        break
    }

    directions.forEach(({ direction, vector, position }) => {
      let measurements = []
      options.forEach(opts => {
        measurements = measurements.concat(
          Facility.current.measureObjectsInDirectionFromPoint(
            vector,
            position,
            opts,
            this.obj3d
          )
        )
      })
      measurements.sort((a, b) => a.distance - b.distance)

      if (measurements.length > 0) {
        const measurement = measurements[0]

        descriptions.push({
          key: direction,
          vector: measurement.vector,
          position,
          showLength: true,
          editable: false,
        })
      }
    })

    this.arrowDescriptions = descriptions
    this.arrowsHaveBeenAdded = true

    return descriptions
  }

  destroy() {
    ArrowRenderer.unsubscribe(this)
  }
}

export default GridBox
