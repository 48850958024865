import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import { graphql } from '@apollo/client/react/hoc'
import { withProps } from 'recompact'

import { version } from '../../../package.json'
import { ISSUE_REPORT_LINK } from 'config/links'
import routes from 'config/routes'
import { purgeCache } from 'config/persistor'
import auth0Client from 'lib/Auth'
import { isDevelopment, isStaging } from 'lib/utils'
import { currentUserIsCustomer } from 'lib/currentUserIs'

import {
  toggleTouchUI,
  toggleDefaultUI,
  toggleFullscreen,
} from 'store/userInterface'
import { set2D } from 'store/camera'
import { hidePanel } from 'store/panel'
import { setAuthData } from 'store/auth'
import { deselectObjects } from 'store/selectedObjects'
import { lockAllLayers } from 'store/layers'
import withUser from 'client/decorators/withUser'

import Button from 'components/UIKit/Button'
import Icon from 'components/UIKit/Icon'
import Image from 'components/UIKit/Image'
import { ClickablePopoverItem } from 'components/UIKit/Popover'
import { ToolbarItem } from 'components/UIKit/Toolbar'
import { MenuTrigger, Popover, SubmenuTrigger, Header } from 'react-aria-components'
import { Pressable } from '@react-aria/interactions'
import { Menu, MenuItem, MenuSection, MenuSeparator } from '~/ui/Menu'
import { Icon as UIIcon } from '~/ui/Icon'
import VariantText from 'components/UIKit/VariantText'
import { CONTROLS_PANEL } from 'store/panel/types'

import { hasPermission } from '../RequiredPermission'
import { LOG_USER_ACTION_MUTATION } from 'client/mutations'
import { handleLogUserAction } from 'client/handlers'
import { useServiceWorker } from 'serviceWorkerProvider'

const getEnvironment = () => {
  const env = null
  if (isDevelopment()) return ' (dev)'
  if (isStaging()) return ' (staging)'
  return env
}

const UserDropdown = (props = {}) => {
  const serviceWorker = useServiceWorker()
  const isFacilityScreen =
    window.location.pathname.substring(0, 9) === '/facility'
  const shouldUseTouchStyles = isFacilityScreen && props.isTouchUI
  const setVariant = shouldUseTouchStyles ? 'dark' : 'light'
  if (props.loading) return null

  const customer = currentUserIsCustomer(props.user)

  return (
    <MenuTrigger>
      <Pressable>
        <ToolbarItem
          separator={shouldUseTouchStyles ? null : 'left'}
          paddingLeft={shouldUseTouchStyles ? null : 's'}
          paddingRight="s"
        >
          <Button
            dropdown
            noBorder
            size="xl"
            variant={setVariant}
            label={
              props.avatar ? (
                <Image
                  circle
                  src={props.avatar}
                  height={shouldUseTouchStyles ? '40px' : '30px'}
                  alt={props.name}
                />
              ) : (
                <Icon name="user" size={shouldUseTouchStyles ? 48 : 14} />
              )
            }
          />
        </ToolbarItem>
      </Pressable>
      <Menu>
        <MenuItem id="user">
          Signed in as <strong>{props.email}</strong>
        </MenuItem>
        <MenuItem id="version">
          App version:{' '}
          <strong>
            v{version} {getEnvironment()}
          </strong>
        </MenuItem>
        <MenuItem id="edit-profile" href={`${props.match.url}${routes.modals.userProfileInfo}`} isDisabled={customer}>
          <UIIcon name="user"/>
          Edit my profile
        </MenuItem>
        <MenuSeparator />
        <MenuItem id="facilities" href={routes.facilities} isDisabled={customer}>
          <UIIcon name="building"/>
          View my facilities
        </MenuItem>
        <MenuItem id="templates" href={routes.templates} isDisabled={customer}>
          <UIIcon name="interiorWall"/>
          View templates
        </MenuItem>
        <MenuItem id="reports" href={routes.reports} isDisabled={customer || props.hideReports}>
          <UIIcon name="clipboard"/>
          View reports
        </MenuItem>
        <MenuSeparator />
        <MenuItem id="faq" href={routes.faq} onAction={() => props.logUserAction({ action: 'FAQ' })} isDisabled={customer}>
          <UIIcon name="info"/>
          Open the FAQ
        </MenuItem>
        <MenuItem id="notes" href={`${props.match.url}${routes.modals.releaseNotes}`} isDisabled={customer}>
          <UIIcon name="feed"/>
          Release Notes
        </MenuItem>
        <MenuSeparator />
        <MenuItem id="mode" onAction={() => {
          props.isTouchUI
            ? props.onToggleDefaultUI()
            : props.onToggleTouchUI()
          props.onToggle2d(CONTROLS_PANEL)
        }} isDisabled={customer || !isFacilityScreen}>
          <UIIcon name={props.isTouchUI ? 'desktop' : 'mobilePhone'}/>
          {props.isTouchUI ? 'Use Desktop UI' : 'Use Touch UI'}
        </MenuItem>
        <MenuItem id="fullscreen" onAction={() => props.onToggleFullscreen()} isDisabled={customer || !isFacilityScreen}>
          <UIIcon name="fullscreen"/>
          Enter Fullscreen
        </MenuItem>
        <MenuSeparator />
        <MenuItem id="cache" onAction={async () => {
          await purgeCache()
          serviceWorker.updateAssets()
          window.localStorage.setItem('redirectUrl', routes.facilities)
          props.onLogout()
          auth0Client.logout()
        }} isDisabled={customer}>
          <UIIcon name="trash"/>
          Clear Cache
        </MenuItem>
        <MenuItem id="report" onAction={() => window.open(ISSUE_REPORT_LINK, '_blank')} isDisabled={customer}>
          <UIIcon name="highPriority"/>
          Report An Issue
        </MenuItem>
        <MenuItem id="settings" href={routes.settings} isDisabled={customer}>
          <UIIcon name="settingsGear"/>
          Settings
        </MenuItem>
        <MenuSeparator />
        <MenuItem id="logout" onAction={() => {
          window.localStorage.setItem('redirectUrl', routes.facilities)
          props.onLogout()
          auth0Client.logout()
        }}>
          <UIIcon name="logout"/>
          Log Out
        </MenuItem>
      </Menu>
    </MenuTrigger>
  )
}

UserDropdown.propTypes = {
  avatar: PropTypes.string,
  email: PropTypes.string,
  loading: PropTypes.bool,
  hideReports: PropTypes.bool,
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  name: PropTypes.string,
  onToggle2d: PropTypes.func,
  onToggleTouchUI: PropTypes.func,
  onToggleDefaultUI: PropTypes.func,
  onToggleFullscreen: PropTypes.func,
  onLogout: PropTypes.func,
  isFullscreen: PropTypes.bool,
  isTouchUI: PropTypes.bool,
  user: PropTypes.object,
  logUserAction: PropTypes.func,
}

const mapStateToProps = ({ auth, userInterface }) => ({
  name: auth.name,
  email: auth.email,
  avatar: auth.avatar,
  isTouchUI: userInterface.isTouchUI,
  isFullscreen: userInterface.isFullscreen,
})

const mapDispatchToProps = dispatch => ({
  onToggleTouchUI() {
    dispatch(deselectObjects({}))
    dispatch(toggleTouchUI())
  },
  onToggleDefaultUI() {
    dispatch(deselectObjects({}))
    dispatch(toggleDefaultUI())
  },
  onToggle2d(panel) {
    dispatch(set2D())
    dispatch(hidePanel({ type: panel }))
  },
  onToggleFullscreen() {
    dispatch(toggleFullscreen())
    dispatch(deselectObjects({}))
    dispatch(set2D())
    dispatch(lockAllLayers())
  },
  onLogout() {
    dispatch(setAuthData())
    window.localStorage.setItem('redirectUrl', routes.facilities)
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  hasPermission({
    name: 'Reports',
    withPermissions: true,
  }),
  withProps(props => ({
    hideReports: props.hideReports || !props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  withRouter,
  withUser,
  graphql(LOG_USER_ACTION_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      logUserAction: ({ action }) =>
        handleLogUserAction({ action, mutate, ownProps }),
    }),
  })
)(UserDropdown)
