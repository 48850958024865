import React from 'react'
import { func, string } from 'prop-types'

import Button from 'components/UIKit/Button'
import { ClickablePopoverItem } from 'components/UIKit/Popover'
import { ToolbarItem } from 'components/UIKit/Toolbar'

import Text from '../styled/Text'

const ViewFilterDropdown = ({ handleFilterChange, filterType }) => (
  <ClickablePopoverItem
    items={[
      {
        title: 'All Items',
        onClick: event => {
          event.preventDefault()
          handleFilterChange('All Items')
        },
        icon: { name: 'infinity' },
      },
      {
        title: 'Products',
        onClick: event => {
          event.preventDefault()
          handleFilterChange('Products')
        },
        icon: { name: 'fan' },
      },
      {
        title: 'Doors',
        onClick: event => {
          event.preventDefault()
          handleFilterChange('Doors')
        },
        icon: { name: 'interiorWall' },
      },
      {
        title: 'Obstructions',
        onClick: event => {
          event.preventDefault()
          handleFilterChange('Obstructions')
        },
        icon: { name: 'obstruction' },
      },
      {
        title: 'Utility Boxes',
        onClick: event => {
          event.preventDefault()
          handleFilterChange('Utility Boxes')
        },
        icon: { name: 'ruler' },
      },
    ]}
    renderTrigger={({ isDropdownVisible }) => (
      <ToolbarItem paddingLeft="s" paddingRight="s">
        <Button
          isDropdownVisible={isDropdownVisible}
          dropdown
          noBorder
          size="l"
          variant="dark"
        >
          <Text>{filterType}</Text>
        </Button>
      </ToolbarItem>
    )}
    right
    variant="dark"
  />
)

ViewFilterDropdown.propTypes = {
  handleFilterChange: func,
  filterType: string,
}

export default ViewFilterDropdown
