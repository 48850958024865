import React, { useState } from 'react'
import PropTypes from 'prop-types'

import A from 'components/UIKit/A'
import Icon from 'components/UIKit/Icon'
import SearchInput from 'components/UIKit/SearchInput'
import Space from 'components/UIKit/Space'

function InlineSearch(props = {}) {
  const [isExpanded, setIsExpanded] = useState(false)
  const dirty = props.query !== ''

  function open(event) {
    event.preventDefault()
    setIsExpanded(true)
    props.onOpen && props.onOpen()
  }

  function close(event) {
    event.preventDefault()
    setIsExpanded(false)
    props.onClose && props.onClose()
  }

  return (
    <Space right="base">
      {isExpanded ? (
        <SearchInput
          autoFocus
          placeholder={props.placeholder || 'Search...'}
          value={props.query}
          onBlur={!dirty ? close : null}
          onChange={props.onChange}
          onClear={close}
        />
      ) : (
        <A noBorder href="#0" onClick={open}>
          <Icon name="search" color="subdued" />
        </A>
      )}
    </Space>
  )
}

InlineSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  placeholder: PropTypes.string,
  query: PropTypes.string.isRequired,
}

export default InlineSearch
