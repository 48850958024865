/**
 * @type {{
    current?: {
      type: string
    }
   }} Camera
 */
const Camera = {}

export default Camera
