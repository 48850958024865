export const STORAGE_KEY = 'BAF:UI:ExportSettings'

// Removes local storage export settings for one area of facility
export const removeStorageItem = ({ facility, area }) => {
  window.localStorage.removeItem(`${STORAGE_KEY}:${facility}:${area}`)
}

// Removes local storage export settings for all areas of facility
export const removeStorageItems = ({ facility }) => {
  const keys = Object.keys(localStorage)
  keys.forEach(key => {
    if (key.includes(`${STORAGE_KEY}:${facility}`)) {
      localStorage.removeItem(key)
    }
  })
}

export const removeAllExportStorageItems = () => {
  let keys = Object.keys(localStorage)
  keys.forEach(key => {
    if (key.includes(`${STORAGE_KEY}`)) {
      localStorage.removeItem(key)
    }
  })
}
