import { ADD_BACKGROUND_IMAGE } from '../objects/action_types'
import { SAVE_BACKGROUND_IMAGE_FILE } from './actions'

const initialState = {}

export default function backgroundImageReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_BACKGROUND_IMAGE_FILE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case ADD_BACKGROUND_IMAGE: {
      return initialState
    }
    default: {
      return state
    }
  }
}
