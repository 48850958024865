import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const ButtonContainer = styled.div`
  border-bottom: 1px solid ${theme('colors.light.subdued')};
  margin: 0 ${theme('spacing.s')};
  flex-grow: 1;
`

export default ButtonContainer
