import { SYSTEMS } from '../constants'
import { round } from '../utils'
import {
  imperialToMetric as distanceImperialToMetric,
  metricToImperial as distanceMetricToImperial,
} from './distance'

/**
 * Defaults for each unit group:
 *
 * DISTANCE: IMPERIAL
 * VELOCITY: IMPERIAL
 * TEMPERATURE: IMPERIAL
 */

/**
 * Converts FROM ft/m TO m/s
 *
 * @data {float} velocity value to convert
 * @returns {float} velocity value in m/s
 */
function imperialToMetric(data, options = {}) {
  return data * 5.08e-3
}

/**
 * Converts TO ft/m FROM m/s
 *
 * @data {float} velocity value to convert
 * @returns {float} velocity value in ft/m
 */
function metricToImperial(data, options = {}) {
  // conversion factor from NIST Guide to the SI Appendix B.8
  return data / 5.08e-3
}

export function convertVelocity({ value, system }, toSystem, options = {}) {
  let newValue = value
  if (system === SYSTEMS.METRIC && toSystem === SYSTEMS.IMPERIAL) {
    newValue = metricToImperial(value, options)
  } else if (system === SYSTEMS.IMPERIAL && toSystem === SYSTEMS.METRIC) {
    newValue = imperialToMetric(value, options)
  }

  return round(newValue, options.round)
}
