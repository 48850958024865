import React from 'react'
import Button from 'components/UIKit/Button'
import { useMutation } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'
import routes from 'config/routes'
import { CANCEL_CFD_JOB_MUTATION } from 'client/mutations'

const CancelCFDButton = buttonProps => {
  const { versionId } = useRouteMatch(routes.facility.full)?.params
  const [cancelCFD, { loading, error }] = useMutation(CANCEL_CFD_JOB_MUTATION, {
    awaitRefetchQueries: true,
    onError(error) {
      console.error(error)
    },
    refetchQueries: ['GetCFDStatuses', 'GetCfdImageResults'],
  })

  const handleClick = () => {
    if (!versionId) return
    cancelCFD({ variables: { versionId } })
  }

  const buttonLabel = loading
    ? 'Cancelling...'
    : error
    ? 'Failed to Cancel CFD'
    : 'Cancel CFD Simulation'

  return <Button disabled={loading} label={buttonLabel} onClick={handleClick} {...buttonProps} />
}

export { CancelCFDButton }
