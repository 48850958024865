import { graphql } from '~/gql'

const PRODUCT_FRAGMENT = graphql(`
  fragment ProductFragment on Product {
    id
    model
    type
    application
    category
    sortIndex
    shippingPrice {
      id
      price
    }
    fanSpeeds {
      id
      speed
      power
      heightFromFloor
      overheadOnly
    }
    defaultVariation {
      ...ProductVariationFragment
    }
    variations {
      ...ProductVariationFragment
    }
  }
`)

export default PRODUCT_FRAGMENT
