import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const PlanViewLabel = styled.p`
  margin-bottom: ${theme('spacing.xs')};
  font-size: ${theme('fonts.sizes.l')};
  font-weight: bold;
`

export default PlanViewLabel
