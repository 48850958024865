import { graphql } from '~/gql'

const ALL_FACILITIES_QUERY = graphql(`
  query AllFacilities(
    $orderBy: String
    $filter: FacilityFilter
    $limit: Int
    $page: Int
    $allUsers: Boolean
  ) {
    allFacilities: allUserFacilities(
      orderBy: $orderBy
      filter: $filter
      limit: $limit
      page: $page
      allUsers: $allUsers
    ) {
      facilities {
        ...FacilityFragment
        floors {
          id
          versions {
            id
          }
        }
        floor {
          id
          version {
            id
          }
        }
      }
      count
    }
  }
`)

export default ALL_FACILITIES_QUERY
