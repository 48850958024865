import { Vector2, Vector2Like, Vector3, Vector3Like } from "three"
import Units from "../lib/units"

/** Converts an object from model units to UI units */
export function objectModelToUI<T extends Readonly<{}>>(it: T): T {
  return Object.fromEntries(Object.entries(it).map(([k, n]) => {
    return [k, Units.inchesToNative(n)]
  })) as T
}

/** Converts an object from UI units to model units */
export function objectUIToModel<T extends Readonly<{}>>(it: T): T {
  return Object.fromEntries(Object.entries(it).map(([k, n]) => {
    return [k, Units.nativeToInches(n)]
  })) as T
}

export function vectorModelToUI(v: Vector3Like): Vector3
export function vectorModelToUI(v: Vector2Like): Vector2
export function vectorModelToUI(v: Vector2Like | Vector3Like): Vector2 | Vector3 {
  if ('z' in v) {
    return new Vector3(modelToUI(v.x), modelToUI(v.y), modelToUI(v.z))
  }
  return new Vector2(modelToUI(v.x), modelToUI(v.y))
}

export function vectorUIToModel(v: Vector3Like): Vector3
export function vectorUIToModel(v: Vector2Like): Vector2
export function vectorUIToModel(v: Vector2Like | Vector3Like): Vector2 | Vector3 {
  if ('z' in v) {
    return new Vector3(uiToModel(v.x), uiToModel(v.y), uiToModel(v.z))
  }
  return new Vector2(uiToModel(v.x), uiToModel(v.y))
}

export function modelToUI(n: number): number {
  return Units.inchesToNative(n)
}

export function uiToModel(n: number): number {
  return Units.nativeToInches(n)
}
