import styled from 'styled-components'

const Container = styled.div<{ inline?: boolean; centered?: boolean }>`
  align-items: center;
  display: ${props => (props.inline ? 'inline-block' : 'flex')};

  ${props => props.inline && `margin-right: ${props.theme.spacing.xs};`};

  ${props =>
    props.centered &&
    `
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 100%;
  `};
`

export default Container
