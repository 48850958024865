import styled from 'styled-components'

const Container = styled.ul`
  list-style-type: none;
  margin: 0;
  margin-bottom: ${props =>
    props.flushBottom ? `0` : props.theme.spacing.base};
  padding: 0;

  ${props =>
    props.background &&
    `background-color: ${props.theme.colors.light[props.background]};`}

  ${props =>
    !props.borderless &&
    `
    border: ${props.theme.borderWidth} solid ${props.theme.colors.light.subdued};
    border-radius: ${props.theme.radius.base};
  `};

  ${props => !props.noBottom && 'border-bottom: 0;'};
`

export default /** @type any */(Container)
