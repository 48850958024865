import React from 'react'
import PropTypes from 'prop-types'
import FileSaver from 'file-saver'
import get from 'lodash-es/get'

import usePDF from 'hooks/usePdf'
import ErrorMessage from 'screens/FacilityScreen/styled/ErrorMessage'
import Loader from 'components/UIKit/Loader'
import Container from './styled/Container'

const exportFn = (fileName = 'export.pdf', blob) => {
  if (blob) FileSaver.saveAs(blob, fileName)
  else console.error('PDF is not available yet')
}

function ExportablePDF(props = {}) {
  const pdf = usePDF(props.data, props.handlePdfDidRender)
  pdf.url &&
    props.setExportFunction(() =>
      exportFn(get(props, 'fileName'), get(pdf, 'url'))
    )

  if (pdf.loading) return <Loader centered label="Generating your PDF…" />

  if (pdf.error)
    return (
      <ErrorMessage>
        {`PDF could not be generated: `}
        {get(pdf, 'error.message', 'Bad request.')}
      </ErrorMessage>
    )

  return <Container title="exportPDF" src={pdf.url} invisible={pdf.loading} />
}

ExportablePDF.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  data: PropTypes.object,
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  handlePdfDidRender: PropTypes.func,
  objectsLoading: PropTypes.bool,
  setExportFunction: PropTypes.func,
}

export default ExportablePDF
