import styled from 'styled-components'

import Icon from 'components/UIKit/Icon'
import { getZIndexOf } from 'lib/utils'

const ImageIcon = styled(Icon)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${getZIndexOf('imageIcon')};
`

export default ImageIcon
