import React, { Component } from 'react'
import { object, func, bool } from 'prop-types'
import ContextMenuContainer from './styled/ContextMenuContainer'
import RowContainer from './styled/RowContainer'

class ContextMenu extends Component {
  handleClick(type) {
    const { clickHandler } = this.props
    if (clickHandler) clickHandler(type)
  }

  render() {
    const { screenPos, shown } = this.props

    if (!screenPos || !shown) return null

    return (
      <>
        <ContextMenuContainer screenPos={screenPos}>
          <RowContainer onClick={() => this.handleClick('vertical')}>
            Center Vertical
          </RowContainer>
          <RowContainer onClick={() => this.handleClick('horizontal')}>
            Center Horizontal
          </RowContainer>
        </ContextMenuContainer>
      </>
    )
  }
}

ContextMenu.propTypes = {
  shown: bool,
  screenPos: object,
  clickHandler: func,
}

export default ContextMenu
