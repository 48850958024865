import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { useNetwork } from 'networkProvider'

import routes from 'config/routes'

import { ALL_FACILITY_TEMPLATES_QUERY } from 'client/queries'
import { FACILITY_PAGE_LIMIT } from 'client/decorators/withInitialData'

import EditFacilityTemplateModal from '../Modals/EditFacilityTemplateModal'

import PermissionDeniedRoute from '../PermissionDeniedRoute'
import { hasPermission } from '../RequiredPermission'
import Template from './Template'
import EnhancedSelectList from '../EnhancedSelectList'

import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

const Templates = (props = {}) => {
  const network = useNetwork()
  return (
    <>
      <EnhancedSelectList
        views={[
          {
            title: 'Templates',
            filter: null,
            icon: 'interiorWall',
            query: ALL_FACILITY_TEMPLATES_QUERY,
            emptyMessage: 'There are no templates right now.',
            emptySearchMessage: 'No templates match that query.',
            queryName: 'allFacilityTemplates.templates',
            count: 'allFacilityTemplates.count',
            limit: FACILITY_PAGE_LIMIT,
          },
        ]}
        initialView={'Templates'}
        paginateLocally={!network.online}
      >
        {({ data, variables }) => {
          return data.map((template, index) => (
            <Template
              key={`${index}-${template.id}`}
              {...template}
              history={props.history}
              match={props.match}
              variables={variables}
              offline={!network.online}
              {...props}
            />
          ))
        }}
      </EnhancedSelectList>
      <SentryRoute
        exact
        path={`${props.match.url}${routes.modals.editFacilityTemplate}`}
        render={modalProps => (
          <EditFacilityTemplateModal
            parentRoute={props.match.url}
            {...modalProps}
          />
        )}
      />
    </>
  )
}

Templates.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(
  withRouter,
  hasPermission({
    name: 'View Template',
    // eslint-disable-next-line react/display-name
    FallbackComponent: () => <PermissionDeniedRoute flush />,
  })
)(Templates)
