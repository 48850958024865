import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

// TODO: This should be abstracted with the styling that already exists for
//       the Export PDF modal image selection.
const SelectableImage = styled.a`
  border: 2px solid
    ${props =>
      props.selected
        ? props.theme.colors.light.primary
        : props.theme.colors.light.subdued};
  display: block;
  transition: opacity 0.2s ease-in-out;

  ${props => props.inactive && `opacity: 0.5;`};

  &:active,
  &:focus,
  &:hover {
    border-color: ${theme('colors.light.primary')};
  }
`

export default SelectableImage
