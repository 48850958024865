import React from 'react'
import styled from 'styled-components'
import theme from 'config/theme'
import { Link } from 'react-router-dom'
/* 
    FIXME:
    update styled-components to 5.1.0>= in order to use $ transient props 
    and convert this component back to standard styled-component syntax

    this specific syntax was required in order to avoid linting error
    due to passing invalid prop 'active' to react-router link
*/
const NavLink = styled(({ active, ...rest }) => <Link {...rest} />)(
  ({ active }) => ({
    color: active ? theme.colors.light.primary : theme.colors.dark.bg,
    padding: '5px 0',
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.25em',
    textDecoration: 'none',
    cursor: 'pointer',
  })
)

export default NavLink
