import React, { Component } from 'react'
import { shape, func, bool, string, number } from 'prop-types'

import ButtonsContainer from './styled/ButtonsContainer'
import Container from './styled/Container'
import InputContainer from './styled/InputContainer'
import Label from './styled/Label'
import TextField from './styled/TextField'

import TemperatureButton from './TemperatureButton'

import { Temperature } from 'store/units/types'

const DEFAULT_VALUE = 0
const EMPTY_VALUE_STRING = '--'

/**
 * @typedef {object} props
 * @property {{onChange?: (props: { degrees: Temperature }) => void}} props.onChange
 * @extends {Component<props>}
 */
class TemperatureInput extends Component {
  state = {}

  static defaultProps = {
    onChange: () => {},
  }

  constructor(props) {
    super(props)

    this.state = this.updatedState(props)
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.degrees ||
      this.props.units !== prevProps.units ||
      (this.props.degrees && !this.props.degrees.equals(this.state.degrees))
    ) {
      this.setState(this.updatedState(this.props))
    }
  }

  updatedState(props) {
    const { degrees, units } = props
    let newDegrees
    if (degrees) {
      newDegrees = degrees.clone()
      newDegrees.convertTo(units)
    } else {
      newDegrees = new Temperature({
        value: null,
        system: units,
      })
    }

    return {
      degrees: newDegrees,
      displayString: newDegrees.format({
        round: 2,
        nullValue: EMPTY_VALUE_STRING,
      }),
    }
  }

  handleFocus = event => {
    if (this.props.disabled) {
      return
    }

    event.target.select()
  }

  handleEnter = () => {
    const { units } = this.props
    let inputValue = this.state.displayString

    const degrees = new Temperature({
      value: Temperature.unformat({
        value: inputValue,
        system: units,
      }),
      system: units,
    })

    this.handleSetValue(degrees)
  }

  handleKeyDown = event => {
    const { disabled } = this.props

    if (disabled) {
      return
    }

    switch (event.keyCode) {
      // Enter
      case 13:
        this.handleEnter()
        event.preventDefault()
        break
      // Up
      case 38:
        this.handleIncreaseValue()
        event.preventDefault()
        break
      // Down
      case 40:
        this.handleDecreaseValue()
        event.preventDefault()
        break
      default:
        return
    }
  }

  getNewDegrees() {
    let newDegrees
    if (this.state.degrees) {
      newDegrees = this.state.degrees.clone()
    } else {
      newDegrees = new Temperature({
        value: DEFAULT_VALUE,
        system: this.props.units,
      })
    }

    return newDegrees
  }

  handleIncreaseValue = () => {
    const newDegrees = this.getNewDegrees()
    newDegrees.value += 1
    this.handleSetValue(newDegrees)
  }

  handleDecreaseValue = () => {
    const newDegrees = this.getNewDegrees()
    newDegrees.value -= 1
    this.handleSetValue(newDegrees)
  }

  handleChange = event => {
    if (this.props.disabled) {
      return
    }

    this.setState({
      displayString: event.target.value,
    })
  }

  handleSetValue = degrees => {
    const { onChange } = this.props

    onChange({ degrees })
    this.setState({
      degrees,
      displayString: degrees.format({
        round: 2,
        nullValue: EMPTY_VALUE_STRING,
      }),
    })
  }

  render() {
    const {
      disabled,
      inline,
      label,
      labelWidth,
      name,
      showFormattedUnits,
      tabIndex,
      width,
    } = this.props

    const value = showFormattedUnits
      ? this.state.displayString
      : this.state.degrees.value

    return (
      <Container inline={inline}>
        {label && (
          <Label inline={inline} labelWidth={labelWidth} htmlFor={name}>
            {label}
          </Label>
        )}
        <InputContainer>
          <TextField
            width={width}
            value={value}
            overrideValue={value}
            onFocus={this.handleFocus}
            onClick={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            onBlur={this.handleEnter}
            disabled={disabled}
            tabIndex={tabIndex}
          />
          <ButtonsContainer>
            <TemperatureButton
              direction="Up"
              onPress={this.handleIncreaseValue}
              disabled={disabled}
            />
            <TemperatureButton
              direction="Down"
              onPress={this.handleDecreaseValue}
              disabled={disabled}
            />
          </ButtonsContainer>
        </InputContainer>
      </Container>
    )
  }
}

TemperatureInput.propTypes = {
  degrees: shape({
    value: number,
    type: string,
    system: string,
  }),
  disabled: bool,
  inline: bool,
  input: string,
  label: string,
  labelWidth: string,
  name: string,
  onChange: func,
  showFormattedUnits: bool,
  tabIndex: number,
  units: string.isRequired,
  width: string,
}

export default /** @type any */(TemperatureInput)
