import * as detectIt from 'detect-it'
import get from 'lodash-es/get'
import upperFirst from 'lodash-es/upperFirst'

import theme from 'config/theme'
import cloudinary from 'config/cloudinary'

import { MeasurementSystem, SYSTEMS } from 'store/units/constants'
import { Distance } from 'store/units/types'
import { Transformation } from 'cloudinary-core'

/**
 * Formats an ENUM string
 *
 * @param string - the string to format
 * @returns a formatted string
 */
export const formatEnumString = (string: string): string => {
  if (!string) {
    return ''
  }

  return `${string.charAt(0)}${string.slice(1).toLowerCase()}`
}

/**
 * Formats a number to have a `0` in front, if necessary
 *
 * @param number - the number to format
 * @returns a formatted number
 */
export const formatNumberDigits = (number: string): string => {
  if (number.length > 1) {
    return number
  }

  return `0${number}`
}

/**
 * Formats a number as a currency
 *
 * @param number - the number to format
 * @returns a currency string
 */
export const formatCurrency = (number: number): string => {
  const formatted = number.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')

  return `$${formatted}`
}

/**
 * Returns a Cloudinary URL for a specified ID
 *
 * @param id - Cloudinary ID
 * @param options - optional options object
 * @returns a Cloudinary URL
 */
export const getCloudinaryUrl = (id: string, options: Parameters<typeof cloudinary.url>[1]): string => cloudinary.url(id, options)

/**
 * Returns a Cloudinary URL for a specified ID, formatted as png
 *
 * @param id - the Cloudinary ID
 * @param options - the optional options object
 * @returns a Cloudinary URL
 */
export const getCloudinaryImageUrl = (id: string, options: Parameters<typeof cloudinary.image>[1]): string => {
  const cloudinaryImage = cloudinary.image(id, {
    format: 'png',
    secure: true,
    ...options,
  })
  return cloudinaryImage.src
}

/**
 * Gets a specific icon for various status types
 *
 * @param type - the type of the icon
 * @returns the icon
 */
export const getIconForStatusType = (type: 'default'|'warning'|'error'|'success'|unknown) => {
  const icons = [
    { name: 'default', icon: 'info', color: 'subdued' },
    { name: 'warning', icon: 'warn', color: 'warning' },
    { name: 'error', icon: 'obstruction', color: 'error' },
    { name: 'success', icon: 'checkCircle', color: 'success' },
  ]

  return icons.filter(icon => icon.name === type)[0] || icons[0]
}

/**
 * Returns the index value in the array for a given key
 *
 * @param props
 * @param props.key - the key in the array
 * @param props.options - the given options object
 * @param props.list - the array of interest
 * @returns the given index
 */
export function getIndexOf<T>({ key, list, options }: { key: T; list: T[]; options?: { multiplier?: number }}): number {
  const multiplier = options?.multiplier ?? 10

  return (list.indexOf(key) + 1) * multiplier
}

/**
 * Returns the appropriate Cloudinary snapshot URL
 *
 * @param props
 * @param props.cropData - crop data
 * @param props.cloudinaryId - the Cloudinary ID
 * @param props.defaultSizes - the default sizes
 * @returns the Cloudinary snapshot URL
 */
export const getSnapshotUrl = ({
  cropData,
  cloudinaryId,
  defaultSizes = {
    width: 140,
    height: 80,
  },
  original = false,
}: {
  cropData: Transformation.Options
  cloudinaryId: string
  defaultSizes?: { width: number; height: number }
  original?: boolean
}): string => {
  if (cropData) {
    return cloudinary.url(cloudinaryId, {
      transformation: [
        {
          width: cropData.width,
          height: cropData.height,
          x: cropData.x,
          y: cropData.y,
          angle: cropData.angle,
          crop: 'crop',
          secure: true,
        },
        {
          height: defaultSizes.height * 2,
          crop: 'limit',
          secure: true,
        },
      ],
    })
  } else if (original) {
    return cloudinary.url(cloudinaryId, { secure: true })
  }

  return cloudinary.url(cloudinaryId, {
    width: defaultSizes.width * 2,
    height: defaultSizes.height * 2,
    crop: 'fill',
    secure: true,
  })
}

/**
 * Returns the HEX color from the `theme.js` file in three.js format
 *
 * @param key - the key in the array
 * @param props - the colors object
 * @returns the three.js HEX color
 */
export function getThreeHexFromTheme(key: string, { colors = theme.colors } = {}): number {
  const threeHexPrefix = '0x'
  const fallbackColor = `${threeHexPrefix}000000`
  const colorFromTheme = get(colors, key, fallbackColor)
  const formattedColor = colorFromTheme.replace('#', threeHexPrefix)

  return parseInt(formattedColor, 16)
}

/**
 * Returns the index value in the theme.order array for a given key
 *
 * @param key - the key in the array
 * @param order - the order array
 * @returns the given index
 */
export const getZIndexOf = (key: string, order = theme.order): number =>
  getIndexOf({ key, list: order })

/**
 * Checks if the given size matches the current viewport
 *
 * @param size - the size to check
 * @param viewport - the viewport to check
 * @returns if it matches or not
 */
export const viewportSizeMatches = (size: string, viewport = window.innerWidth): boolean =>
  `${viewport}px` === size

/**
 * Gets the appropriate theme var for the docked panel width
 *
 * @returns the docked panel width
 */
export const getDockedPanelWidth = (): number => {
  const size = 's'

  if (viewportSizeMatches(theme.breakpoints[size])) {
    return theme.dockedPanel.width[size]
  }

  return theme.dockedPanel.width.default
}

/**
 * Gets the appropriate environment
 *
 * @returns if the environment matched
 */
export const isEnv = (env: string): boolean =>
  import.meta.env.VITE_ENV === env || import.meta.env.MODE === env

/**
 * Checks if the environment is development
 *
 * @returns if the environment matches development
 */
export const isTesting = (): boolean => isEnv('development')

/**
 * Checks if the environment is development
 *
 * @returns if the environment matches development
 */
export const isDevelopment = (): boolean => isEnv('development')

/**
 * Checks if the environment is staging
 *
 * @returns if the environment matches staging
 */
export const isStaging = (): boolean => isEnv('staging')

/**
 * Checks if the environment is production
 *
 * @returns if the environment matches production
 */
export const isProduction = (): boolean => isEnv('production')

/**
 * Checks if the current device is a touch device
 *
 * @returns If it matches or not
 */
export const isTouchDevice = (): boolean => {
  return detectIt.deviceType === 'touchOnly' || detectIt.deviceType === 'hybrid'
}

/**
 * Gets the user-agent string
 *
 * @returns The browser user agent
 */
export const getUserAgent = (): string|null => {
  let ua = navigator.userAgent.match(
      /(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i
    ),
    browser: string|null

  if (
    navigator.userAgent.match(/Edge/i) ||
    navigator.userAgent.match(/Trident.*rv[ :]*11\./i)
  ) {
    browser = 'msie'
  } else if (
    navigator.userAgent.match(/CriOS/) ||
    navigator.vendor.match(/Google\sInc\./)
  ) {
    browser = 'chrome'
  } else {
    browser = ua && ua[1].toLowerCase()
  }

  return browser
}

/**
 * Formats the product size appropriately
 *
 * @param size - the size to format
 * @param unitType - unit type e.g. IMPERIAL, METRIC
 * @returns The formatted size
 */
export const showProductSize = (size: number, unitType: MeasurementSystem, power: number | undefined): string => {
  const distance = new Distance({
    value: size,
    system: SYSTEMS.IMPERIAL,
  })

  return distance.formattedValue(unitType, {
    both: true,
    round: true,
    roundCentimeters: true,
    power,
  })!
}

// TODO: Remove duplicate logic
/**
 * Capitalizes the first character in a string
 *
 * @param {string} the string to format
 * @returns {string} The formatted string
 */
export const capitalize = upperFirst

/**
 * Appends a string to the end of a URL
 *
 * @param url - the url to append to
 * @param string - the string to append
 * @returns the appended URL string
 */
export const appendURL = (url: string, string: string): string => {
  const urlHasEndingSlash = url.endsWith('/')
  const stringHasStartingSlash = string.startsWith('/')
  let finalUrl: string

  if (urlHasEndingSlash) {
    if (stringHasStartingSlash) {
      finalUrl = `${url}${string.slice(1)}`
    } else {
      finalUrl = `${url}${string}`
    }
  } else {
    if (stringHasStartingSlash) {
      finalUrl = `${url}${string}`
    } else {
      finalUrl = `${url}/${string}`
    }
  }

  return finalUrl
}

/**
 * Returns a valid dimension (greater than zero)
 *
 * @param value - the provided dimension size
 */
export const getDimensionValue = (value?: number): number => {
  return value && value > 0 ? value : 1
}

/**
 * Parses out the transformations in a Cloudinary URL.
 *
 * @param url - the Cloudinary URL.
 */
export const parseCloudinaryUrlTransforms = (url: string): string => {
  // Example: /c_limit,h_268
  const limitMatch = url.match(/\/c_limit.*\//)
  // Example: /c_fill,h_268,w_424
  const fillMatch = url.match(/\/c_fill.*\//)

  if (limitMatch) {
    return url.replace(limitMatch[0], '/')
  }

  if (fillMatch) {
    return url.replace(fillMatch[0], '/')
  }

  return url
}

/**
 * Checks for Notification API and if specified
 * permission check passes
 * @param checkGranted - check against 'granted' or not
 * @returns if check passes
 */
export const checkNotificationGranted = (checkGranted: boolean = true): boolean => {
  if (!('Notification' in window)) return false

  return checkGranted
    ? Notification.permission === 'granted'
    : Notification.permission !== 'granted'
}

export const guid = (): string => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}
