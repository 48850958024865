import React, { Component } from 'react'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import PropTypes from 'prop-types'

import flatten from 'lodash-es/flatten'
import get from 'lodash-es/get'
import uniq from 'lodash-es/uniq'

import withFacility from 'client/decorators/withFacility'
import { setCurrentLayer } from 'store/layers'
import { setActiveTool } from 'store/tools'
import { DETAILED_OBSTRUCTION_PANEL } from 'store/panel/types'
import { isTouchUI } from 'store/userInterface/selectors'

import LAYER_KEYS from 'config/layerKeys'
import detailedObstructions from 'config/detailedObstructions'
import {
  onSearchDetailedObstructions,
  onAddDetailedObstruction,
} from 'config/analytics'
import { trackEvent } from 'lib/analytics'

import Grid, { GridItem } from 'components/UIKit/Grid'
import Image from 'components/UIKit/Image'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'
import SearchInput from 'components/UIKit/SearchInput'
import Loader from 'components/UIKit/Loader'

import PrimaryUseFilter from 'components/PrimaryUseFilter'

class DetailedObstructionPanel extends Component {
  static propTypes = {
    insertItem: PropTypes.func,
    facility: PropTypes.object,
    loading: PropTypes.bool,
    alignment: PropTypes.string,
    isTouchUI: PropTypes.bool,
  }

  static defaultProps = {
    alignment: 'right',
  }

  state = {
    facilityPrimaryUse: '',
    initialLoad: true,
    primaryUse: '',
    searchQuery: '',
  }

  componentDidMount() {
    this.updatePrimaryUse()
  }

  componentDidUpdate() {
    // We only want to set the facility primary use on the initial load
    // since the component may be mounted after our data is done loading
    if (this.state.initialLoad) {
      this.updatePrimaryUse()
    }

    // The facility primary use type was changed so reset our state
    const primaryUse = get(this.props, 'facility.primaryUse')
    if (this.state.facilityPrimaryUse !== primaryUse) {
      this.setState({ facilityPrimaryUse: primaryUse, initialLoad: true })
    }
  }

  updatePrimaryUse() {
    const primaryUse = get(this.props, 'facility.primaryUse')
    const availablePrimaryUses = uniq(
      flatten(detailedObstructions.map(obs => obs.primaryUses))
    )
    const primaryUseIsAvailable = availablePrimaryUses.includes(primaryUse)
    const newPrimaryUse = primaryUseIsAvailable ? primaryUse : ''

    if (primaryUse) {
      this.setState({
        initialLoad: false,
        facilityPrimaryUse: primaryUse,
        primaryUse: newPrimaryUse,
      })
    }
  }

  addObstruction(obstruction) {
    this.props.insertItem({
      layerKey: LAYER_KEYS.OBSTRUCTIONS,
      tool: 'OBSTRUCTION_TOOL',
      props: {
        ...obstruction,
        rotation: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
    })
  }

  handleInputClear() {
    this.setState({ searchQuery: '' })
  }

  handleSearchChange(value) {
    this.setState({ searchQuery: value })
  }

  handlePrimaryUseChange(value) {
    this.setState({ primaryUse: value })
  }

  render() {
    const { alignment } = this.props
    const { searchQuery, primaryUse } = this.state

    const filteredByPrimaryUse =
      primaryUse === '' || searchQuery !== ''
        ? detailedObstructions
        : detailedObstructions.filter(obstruction =>
            obstruction.primaryUses.includes(primaryUse)
          )

    const filteredObstructions = filteredByPrimaryUse.filter(obs => {
      let isMatch = false
      obs.tags.forEach(tag => {
        if (tag.includes(searchQuery.toLowerCase())) isMatch = true
      })

      return isMatch
    })

    const images = filteredObstructions.map((obs, index) => {
      return (
        <GridItem key={index} title={obs.obstructionType} size="1of2">
          <Space bottom="base">
            <Image
              // TODO: Setup image paths once we decide where detailed
              // obstructions and their images will live
              src={`/obstructions/${obs.obstructionType}.png`}
              alt={obs.obstructionType}
              onClick={() => {
                this.addObstruction(obs)
                trackEvent(onAddDetailedObstruction(obs.obstructionType))
              }}
            />
          </Space>
        </GridItem>
      )
    })

    return (
      <Panel
        title="Detailed Obstructions"
        alignment={alignment}
        docked
        scrollable
        panelKey={DETAILED_OBSTRUCTION_PANEL}
        hasToolbar={get(this.props, 'isTouchUI', false)}
      >
        {this.props.loading ? (
          <Loader centered />
        ) : (
          <PanelSection>
            <Space bottom="base">
              <SearchInput
                type="search"
                placeholder="Search obstructions..."
                value={searchQuery}
                onBlur={event =>
                  trackEvent(onSearchDetailedObstructions(event.target.value))
                }
                onClear={() => this.handleInputClear()}
                onChange={event => this.handleSearchChange(event.target.value)}
              />
            </Space>
            <Space bottom="base">
              <PrimaryUseFilter
                isObstruction
                data={detailedObstructions}
                primaryUse={primaryUse}
                onChange={event =>
                  this.handlePrimaryUseChange(event.target.value)
                }
              />
            </Space>
            <Grid>{images}</Grid>
          </PanelSection>
        )}
      </Panel>
    )
  }
}

const mapStateToProps = ({ objects, selectedObjects, ...store }) => {
  return {
    selectedObjects,
    objects,
    isTouchUI: isTouchUI(store),
  }
}

const mapDispatchToProps = dispatch => ({
  insertItem({ layerKey, tool, props }) {
    dispatch(setCurrentLayer({ layerKey }))
    dispatch(setActiveTool({ tool, props }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withFacility
)(DetailedObstructionPanel)
