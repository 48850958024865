import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.div`
  border: 1px solid ${theme('colors.light.light')};
  margin-top: ${theme('spacing.base')};
`

export default Container
