import { useContext } from 'react';
import {
  Slider as AriaSlider,
  SliderProps as AriaSliderProps,
  SliderOutput,
  SliderThumb,
  SliderTrack,
  SliderStateContext
} from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Label } from './Field';
import { composeTailwindRenderProps, focusRing } from './utils';

const trackStyles = tv({
  base: 'rounded-full outline outline-gray-300 -outline-offset-1',
  variants: {
    orientation: {
      horizontal: 'w-full h-[6px]',
      vertical: 'h-full w-[6px] ml-[50%] -translate-x-[50%]'
    },
    isDisabled: {
      false: 'bg-gray-100 dark:bg-zinc-500 forced-colors:bg-[ButtonBorder]',
      true: 'opacity-50 bg-gray-100 dark:bg-zinc-800 forced-colors:bg-[GrayText]'
    }
  }
});

const thumbStyles = tv({
  extend: focusRing,
  base: 'z-10 w-4 h-4 group-orientation-horizontal:mt-4 group-orientation-vertical:ml-3 rounded-full bg-blue-600 dark:bg-zinc-900 dark:border-gray-300',
  variants: {
    isDragging: {
      true: 'z-10 bg-blue-900 dark:bg-gray-300 forced-colors:bg-[ButtonBorder]'
    },
    isDisabled: {
      true: 'bg-blue-300 border-gray-300 dark:border-zinc-700 forced-colors:border-[GrayText]'
    }
  }
});

const TrackFill = () => {
  const state = useContext(SliderStateContext)
  return <div className={`-z-1 absolute h-2 top-[50%] translate-y-[-50%] rounded-full bg-blue-${state.isDisabled ? '300': '600'}`} style={{ width: state.getThumbPercent(0) * 100 + '%' }}/>
}
export interface SliderProps<T> extends AriaSliderProps<T> {
  label?: string;
}

export function Slider<T extends number | number[]>(
  { label, ...props }: SliderProps<T>
) {
  return (
    <AriaSlider {...props} className={composeTailwindRenderProps(props.className, 'orientation-horizontal:grid orientation-vertical:flex grid-cols-[1fr_auto] flex-col items-center gap-1')}>
      <Label className='font-bold text-black text-xs'>{label}</Label>
      <SliderOutput className="text-xs text-black dark:text-zinc-400 font-bold orientation-vertical:hidden">
        {({ state }) => state.values.map((_, i) => state.getThumbValueLabel(i)).join(' – ')}
      </SliderOutput>
      <SliderTrack className="mx-2 group col-span-2 orientation-horizontal:h-6 orientation-vertical:w-6 orientation-vertical:h-64 flex items-center">
        {({ state, ...renderProps }) => <>
          <div className={trackStyles(renderProps)} />
          <SliderThumb className={thumbStyles} />
          <TrackFill />
        </>}
      </SliderTrack>
    </AriaSlider>
  );
}
