import { graphql } from '~/gql'

const CREATE_FACILITY_MUTATION = graphql(`
  mutation CreateFacility(
    $name: String!
    $primaryObjective: [PrimaryObjective]!
    $primaryUse: PrimaryUse!
    $primaryType: PrimaryType
    $ashraeCompliant: Boolean
    $airConditioned: Boolean
    $location: String
    $indoorSummerTemp: Int
    $indoorWinterTemp: Int
    $indoorHumidity: Float
    $indoorWinterHumidity: Float
    $opportunityId: ID
    $copyFromVersionId: ID
    $units: MeasurementSystem
    $isTemplate: Boolean
  ) {
    createFacility(
      name: $name
      primaryObjective: $primaryObjective
      primaryUse: $primaryUse
      primaryType: $primaryType
      ashraeCompliant: $ashraeCompliant
      airConditioned: $airConditioned
      location: $location
      indoorSummerTemp: $indoorSummerTemp
      indoorWinterTemp: $indoorWinterTemp
      indoorHumidity: $indoorHumidity
      indoorWinterHumidity: $indoorWinterHumidity
      opportunityId: $opportunityId
      copyFromVersionId: $copyFromVersionId
      units: $units
      isTemplate: $isTemplate
    ) {
      ...FacilityFragment
    }
  }
`)

export default CREATE_FACILITY_MUTATION
