import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Loader from 'components/UIKit/Loader'

const FacilitiesListItemLoader = styled(Loader)`
  padding: ${theme('spacing.s')};
`

export default FacilitiesListItemLoader
