import { Text, Line, Circle, DragControls, Ring } from "@react-three/drei"
import { Group, Matrix4, Object3DEventMap, Vector2, Vector3 } from "three"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import Units from "../../lib/units"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { updateColumnLine, updateMountingBeam } from "~/store/objects"
import { become3, getLineSegmentForPoint, getOrthoOrientation, inchesToNative2, useMountingStructureData, useRoofSectionOrientation } from "../util"
import { MountingStructureLineButtons } from "./Buttons"
import { MountingStructureLineMeasures } from "./Measures"

export function MountingStructureLine() {
  const props = useMountingStructureData()
  const parentGroup = useRef<Group<Object3DEventMap>>(null)
  const matrixRef = useRef(new Matrix4())
  const dragPreviewUpdateRef = useRef<() => void>(null)
  const dispatch = useAppDispatch()
  const is3D = useAppSelector(store => store.camera.is3D)
  const [isDragging, setDragging] = useState(false)
  useEffect(() => {
    matrixRef.current.identity()
  }, [props.model])

  const textZ = is3D ? 3 : 522
  const circleZ = is3D ? 2 : 518

  const orthogonal = getOrthoOrientation(props.orientation)

  const thisModel = getLineSegmentForPoint(props.model, props.perimeterPoints, props.orientation, false)
  const nextModel = props.next && getLineSegmentForPoint(props.next, props.perimeterPoints, props.orientation, false)

  if (thisModel === undefined) {
    return <></>
  }

  const isSelected = props.selected

  return (
    <group ref={parentGroup}>
      <DragControls autoTransform={false} matrix={matrixRef.current}
        onDragStart={() => {
          setDragging(true)
        }}
        onDrag={(_, deltaLocalMatrix) => {
          if (!isSelected) {
            return
          }
          const delta = new Vector3().setFromMatrixPosition(deltaLocalMatrix)
          delta.projectOnVector(orthogonal)
          const deltaMatrix = new Matrix4().makeTranslation(delta)
          matrixRef.current.multiply(deltaMatrix)
          dragPreviewUpdateRef.current?.()
        }}
        onDragEnd={() => {
          setDragging(false)
          if (!isSelected) {
            return
          }
          const deltaVector = new Vector3()
          deltaVector.setFromMatrixPosition(matrixRef.current)
          const newPosition = Units.nativeToUnitsV('inches', deltaVector).add(thisModel.position)
          if (props.type === 'beam') {
            dispatch(
              updateMountingBeam({
                roofSectionId: props.roofSectionId,
                beamId: thisModel.id,
                beamPosition: { x: newPosition.x, y: newPosition.y, z: newPosition.z },
                direction: { x: 0, y: 0, z: 0 }
              })
            )
          } else if (props.type === 'column') {
            dispatch(
              updateColumnLine({
                roofSectionId: props.roofSectionId,
                columnLineId: thisModel.id,
                columnLinePosition: { x: newPosition.x, y: newPosition.y, z: newPosition.z },
                direction: { x: 0, y: 0, z: 0 },
              })
            )
          } else {
            props.type satisfies never
            throw new Error("unreachable: mounting line is neither column nor beam")
          }
        }}>
        <group visible={!isDragging}>
          <MountingStructureLineLine model={props.model}/>
        </group>
      </DragControls>
      {isDragging && <MountingStructureLineDragPreview ref={dragPreviewUpdateRef} matrix={matrixRef} />}
      {isSelected && !isDragging && <MountingStructureLineButtons thisModel={thisModel} nextModel={nextModel} roofSectionId={props.roofSectionId} type={props.type} color={props.color} textZ={textZ} circleZ={circleZ} orthogonal={orthogonal} />}
      {isSelected && !isDragging && props.next && <MountingStructureLineMeasures other={props.next}/>}
      {isSelected && !isDragging && props.prev && <MountingStructureLineMeasures other={props.prev}/>}
    </group>
  )
}
const useManualRerender = (ref: React.ForwardedRef<() => void>) => {
  const [_, setState] = useState(0)
  useImperativeHandle(ref, () => () => {
    setState(it => it + 1)
  }, [])
}

const MountingStructureLineDragPreview = forwardRef(function MountingStructureLineDragPreview(props: { matrix: React.MutableRefObject<Matrix4> }, ref: React.ForwardedRef<() => void>) {
  useManualRerender(ref)
  const data = useMountingStructureData()

  const newModel = Units.nativeToUnitsV('inches', Units.unitsToNativeV('inches', data.model[0]).applyMatrix4(props.matrix.current))

  return <MountingStructureLineLine model={[newModel, data.model[1]]}/>
})

function MountingStructureLineLine(props: { model: [Vector3, number] }) {
  const data = useMountingStructureData()
  const is3D = useAppSelector(store => store.camera.is3D)
  const roofOrientation = useRoofSectionOrientation()

  const textZ = is3D ? 3 : 522
  const borderZ = is3D ? 3 : 520
  const circleZ = is3D ? 2 : 518
  const lineZ = is3D ? 1 : 516

  const thisModel = getLineSegmentForPoint(props.model, data.perimeterPoints, data.orientation, false)

  if (thisModel === undefined) {
    return <></>
  }

  const extensionMultiplier = roofOrientation === 'horizontal' ? -1 : 1
  const orientation2 = new Vector2(data.orientation.x, data.orientation.y)
  const makeP1 = (it: Vector2) => it.clone().add(orientation2.clone().setLength(data.type === 'column' ? -100 : 100).multiplyScalar(extensionMultiplier))
  const makeP2 = (it: Vector2) => it.clone().add(orientation2.clone().setLength(data.type === 'column' ? -100 : 100).multiplyScalar(-1 * extensionMultiplier))

  const p1 = makeP1(thisModel.point1)
  const p2 = makeP2(thisModel.point2)

  return (
    <group onClick={() => data.setSelected([data.roofSectionId, thisModel.id])}>
      <Text fontSize={6} position={become3(inchesToNative2(p1), textZ)} color={data.selected ? 'black' : '#777'}>{data.name}</Text>
      <Ring args={[4, 4.5]} position={become3(inchesToNative2(p1), borderZ)} material-color={data.selected ? data.color : '#777'}/>
      <Circle args={[4.5, 20]} position={become3(inchesToNative2(p1), circleZ)} material-color={data.selected ? data.color : '#fff'} material-toneMapped={data.selected} />
      <Text fontSize={6} position={become3(inchesToNative2(p2), textZ)} color={data.selected ? 'black' : '#777'}>{data.name}</Text>
      <Ring args={[4, 4.5]} position={become3(inchesToNative2(p2), borderZ)} material-color={data.selected ? data.color : '#777'}/>
      <Circle args={[4.5, 20]} position={become3(inchesToNative2(p2), circleZ)} material-color={data.selected ? data.color : '#fff'} material-toneMapped={data.selected} />
      <Line points={[become3(inchesToNative2(p1), lineZ), become3(inchesToNative2(p2), lineZ)]} lineWidth={2} dashed={true} color={data.selected ? data.color : '#777'}/>
    </group>
  )
}
