import { graphql } from '~/gql'

const SNAPSHOT_FRAGMENT = graphql(`
  fragment SnapshotFragment on Snapshot {
    id
    cloudinaryId
    cropData
    data
    title
    createdAt
  }
`)

export default SNAPSHOT_FRAGMENT
