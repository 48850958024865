import PropTypes from 'prop-types'
import { BadgeContainer } from 'components/Panels/CFDPanel/styled/BadgeContainer'
import { CFD_STATUS } from 'config/cfd'
import Icon from 'components/UIKit/Icon'

const getIconValues = cfdStatus => {
  switch (cfdStatus) {
    case CFD_STATUS.CANCELED:
    case CFD_STATUS.FAILED:
    case CFD_STATUS.UNAVAILABLE: {
      return { iconName: 'cross', color: 'error' }
    }
    case CFD_STATUS.COMPLETE: {
      return { iconName: 'check', color: 'success' }
    }
    case CFD_STATUS.IN_PROGRESS: {
      return { iconName: 'refresh', color: 'warning' }
    }
    default: {
      console.warn(`Unexpected CFD Status: ${cfdStatus}`)
      return { iconName: undefined, color: undefined }
    }
  }
}

const StatusBadge = ({ status, invalid, tooltip }) => {
  const { iconName, color } = getIconValues(invalid ? CFD_STATUS.FAILED : status)
  const titleCaseStatus = status
    .toLowerCase()
    .replace('_', ' ')
    .replace(/^([a-z])/, match => match.toUpperCase())
  const tooltipLabel = invalid ? 'Invalid model' : titleCaseStatus

  return iconName ? (
    <BadgeContainer tooltip={tooltip} color={color} tooltipLabel={tooltipLabel}>
      <Icon
        size={10}
        name={iconName}
        spin={!invalid && status === CFD_STATUS.IN_PROGRESS}
        color="bg"
      />
    </BadgeContainer>
  ) : (
    <></>
  )
}
StatusBadge.propTypes = {
  status: PropTypes.string,
}
export { StatusBadge }
