import styled from 'styled-components'

import { noSelect, breakpoints, theme } from 'lib/styleUtils'
import { Variant } from '~/config/theme'

const Label = styled.span<{ variant: Variant; responsive?: boolean }>`
  color: ${props => props.theme.colors[props.variant].fg};
  font-size: ${theme('fonts.sizes.s')};
  ${noSelect()};

  ${props =>
    props.responsive &&
    breakpoints.s`
    width: ${props.theme.spacing.xl};
  `};
`

export default Label
