import React from 'react'

import Modal from 'components/UIKit/Modal'

const RequestQuoteModal = ({ ...props }) => (
  <Modal title="Request a Quote" {...props}>
    <p>This modal is not yet implemented.</p>
  </Modal>
)

export default RequestQuoteModal
