import styled from 'styled-components'

const size = '20px'

const Toggle = styled.span<{ label?: string }>`
  height: ${size};
  width: 40px;

  ${props => props.label && `margin-right: ${props.theme.spacing.s};`};
`

export default Toggle
