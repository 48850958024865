import { graphql } from '~/gql'

const ALL_OPPORTUNITIES_QUERY = graphql(`
  query AllOpportunities($limit: Int, $search: String) {
    Salesforce {
      opportunities(limit: $limit, search: $search) {
        id
        name
        stageName
        closeDate
      }
    }
  }
`)

export default ALL_OPPORTUNITIES_QUERY
