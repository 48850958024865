export class IntensityInterpolator {
  THRESHOLD = 7
  AIR_TEMPERATURE_INCREASE = 10
  RADIANT_TEMPERATURE_INCREASE = 25
  constructor(intensity) {
    this.intensity = intensity
    this.scaleMeanRadiantTemp = this.scaleMeanRadiantTemp.bind(this)
    this.scaleMeanAirTemp = this.scaleMeanAirTemp.bind(this)
  }
  scaleMeanRadiantTemp(mrt) {
    return mrt + (this.intensity / this.THRESHOLD) * this.RADIANT_TEMPERATURE_INCREASE
  }
  scaleMeanAirTemp(mat) {
    return mat + (this.intensity / this.THRESHOLD) * this.AIR_TEMPERATURE_INCREASE
  }
}
