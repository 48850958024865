import React from 'react'
import { appConnect } from "~/store/hooks";
import { bool, string, func } from 'prop-types'
import { withNetwork } from 'networkProvider'

import { isTouchUI } from 'store/userInterface/selectors'

import Container from './styled/Container'

class SaveConfiguration extends React.Component {
  state = {
    isSaved: false,
  }

  componentDidUpdate(prevProps) {
    const { isSaved } = this.state
    const { selectedObjectId, isDupeConfig } = this.props

    // Set isSaved to false if the selected object has changed
    // or if the user changes a product value and creates a newly
    // saveable config
    if (
      isSaved &&
      (prevProps.selectedObjectId !== selectedObjectId ||
        (prevProps.isDupeConfig && !isDupeConfig))
    ) {
      this.setState({ isSaved: false })
    }
  }

  onSuccess = () => this.setState({ isSaved: true })

  render() {
    const { isDupeConfig, isTouchUI, onConfigurationSave, online } = this.props
    const { isSaved } = this.state
    const containerProps = {
      backgroundColor: isSaved ? 'success' : 'primary',
      color: isSaved ? 'bg' : 'fg',
    }

    // Render nothing if the default UI is enabled
    if (!isTouchUI) return null

    return (
      online && (
        <Container
          {...containerProps}
          show={!isDupeConfig || isSaved}
          onClick={() => !isSaved && onConfigurationSave(this.onSuccess)}
        >
          {isSaved ? (
            <div>Your configuration has been saved</div>
          ) : (
            <u>Save Configuration</u>
          )}
        </Container>
      )
    )
  }
}

SaveConfiguration.propTypes = {
  isDupeConfig: bool,
  isTouchUI: bool,
  selectedObjectId: string,
  onConfigurationSave: func,
  online: bool,
}

const mapStateToProps = state => ({
  isTouchUI: isTouchUI(state),
})

export default appConnect(mapStateToProps)(withNetwork(SaveConfiguration))
