import React from 'react'
import { func, object } from 'prop-types'

import { DefaultQueryProvider } from 'client/providers'
import { GET_AR_OPTIONS_QUERY } from 'client/queries'

import Menu from '../Menu'

function SizeMenu({ product, handleSelect }) {
  return (
    <DefaultQueryProvider
      query={GET_AR_OPTIONS_QUERY}
      variables={{ catalog: product.catalog, model: product.model }}
    >
      {({ data: { AROptions: sizes } }) => {
        return (
          <Menu
            items={sizes.map(({ option }) => ({
              key: option,
              title: `${option}"`,
            }))}
            handleSelect={handleSelect}
            value={product.size}
            displayValue={product.size ? `${product.size}"` : null}
          />
        )
      }}
    </DefaultQueryProvider>
  )
}

SizeMenu.propTypes = {
  product: object,
  handleSelect: func,
}

export default SizeMenu
