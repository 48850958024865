import React from 'react'
import PropTypes from 'prop-types'

import TextField from 'components/UIKit/TextField'

const Title = ({ disabled, value, labelWidth, onBlur } = {}) => (
  <TextField
    inline
    label="Title"
    labelWidth={labelWidth}
    name="title"
    value={value}
    onBlur={onBlur}
    size="s"
    disabled={disabled}
  />
)

Title.propTypes = {
  labelWidth: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.any, // ??
  disabled: PropTypes.bool,
}

export default Title
