import styled from 'styled-components'

import { COLLAPSIBLE_PANEL_HEIGHT } from '../PanelSection'

const collapsibleStyles = ({ collapsible, height, theme }) =>
  `
    overflow-y: scroll;
    padding: ${theme.spacing.base};
    height: ${COLLAPSIBLE_PANEL_HEIGHT - 35}px;
  `

const Content = styled.div`
  ${props => props.collapsible && collapsibleStyles(props)};
`

export default Content
