import styled from 'styled-components'

const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
`

export default Steps
