import React from 'react'

import { ToolbarGroup, ToolbarItem } from 'components/UIKit/Toolbar'
import Logo from 'components/UIKit/Logo'

const TopCenter = () => (
  <ToolbarGroup size="20%" alignment="center">
    <ToolbarItem>
      <Logo />
    </ToolbarItem>
  </ToolbarGroup>
)

export default TopCenter
