import styled from 'styled-components'

const ThumbnailGrid = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export default ThumbnailGrid
