import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom'
import { withRouter } from 'react-router-dom'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import Mousetrap from 'mousetrap'
import get from 'lodash-es/get'

import 'lib/mousetrapPause'

import { getTitle } from 'config/titles'
import routes from 'config/routes'
import theme from 'config/theme'
import { updateBackgroundImage } from 'store/objects'
import { SYSTEMS } from 'store/units/constants'
import { withUnits } from 'store/units/decorators'
import { Distance } from 'store/units/types'
import { onAddImage } from 'config/analytics'
import { trackEvent } from 'lib/analytics'

import A from 'components/UIKit/A'
import Button from 'components/UIKit/Button'
import DimensionInput from 'components/UIKit/DimensionInput'
import Flex, { FlexItem } from 'components/UIKit/Flex'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Modal from 'components/UIKit/Modal'

import Container from './styled/Container'

const POINT_SIZE = 76

class ScaleImageModal extends Component {
  // We store this part of the initial state here so we can
  // easily clear out this set of values in `handleClearPoints()`.
  static initialStatePoints = {
    hasPoint1: false,
    hasPoint2: false,
    point1X: 0,
    point1Y: 0,
    point2X: 0,
    point2Y: 0,
    point1Selected: false,
    point2Selected: false,
  }
  state = {
    referenceLength: new Distance({
      value: 120,
      system: SYSTEMS.IMPERIAL,
    }),
    isDragging: false,
    ...ScaleImageModal.initialStatePoints,
  }

  componentDidMount() {
    this.viewer && this.viewer.fitToViewer()
    this.handleLoadImage()
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleMouseDown)
    document.removeEventListener('keyup', this.handleMouseUp)

    // Restart the Mousetrap keyboard events
    Mousetrap.unpause()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.props.backgroundImage.BACKGROUND_IMAGE &&
      nextProps.backgroundImage.BACKGROUND_IMAGE
    ) {
      this.viewer && this.viewer.fitToViewer()
      this.handleLoadImage()
    }
  }

  handleLoadImage = () => {
    const image = this.props.backgroundImage.BACKGROUND_IMAGE

    // Temporarily pause the Mousetrap keyboard events
    Mousetrap.pause()

    if (this.viewer) this.viewer.fitToViewer()

    if (
      image &&
      image.referenceLength &&
      image.point1X &&
      image.point1Y &&
      image.point2X &&
      image.point2Y
    ) {
      this.setState({
        referenceLength: new Distance({
          value: image.referenceLength,
          system: SYSTEMS.IMPERIAL,
        }),
        hasPoint1: image.point1X > 0 || image.point1Y > 0,
        hasPoint2: image.point2X > 0 || image.point2Y > 0,
        point1X: image.point1X,
        point1Y: image.point1Y,
        point2X: image.point2X,
        point2Y: image.point2Y,
      })
    }
  }

  handleSelectPoint1 = () => {
    this.viewer.changeTool('none')
    this.setState({
      point1Selected: !this.state.point1Selected,
      point2Selected: false,
    })
  }

  handleSelectPoint2 = () => {
    this.viewer.changeTool('none')
    this.setState({
      point1Selected: false,
      point2Selected: !this.state.point2Selected,
    })
  }

  handleDeselectAll = () => {
    this.setState({
      point2Selected: false,
      point1Selected: false,
    })
  }

  handleMouseDown = event => {
    this.setState({
      isDragging: true,
    })
  }

  handleMouseUp = event => {
    const { x, y } = event
    const {
      isDragging,
      hasPoint1,
      hasPoint2,
      point1Selected,
      point2Selected,
      point1X,
      point1Y,
      point2X,
      point2Y,
    } = this.state

    if (!hasPoint1) {
      this.setState({
        isDragging: false,
        hasPoint1: true,
        point1X: x,
        point1Y: y,
      })
    } else if (!hasPoint2) {
      this.setState({
        isDragging: false,
        hasPoint2: true,
        point2X,
        point2Y,
      })
    } else if (isDragging && point1Selected) {
      this.setState({
        isDragging: false,
        point1X,
        point1Y,
      })
    } else if (isDragging && point2Selected) {
      this.setState({
        isDragging: false,
        point2X,
        point2Y,
      })
    } else {
      this.setState({
        isDragging: false,
      })
    }
  }

  handleMouseMove = event => {
    let { x, y } = event
    let {
      hasPoint1,
      hasPoint2,
      point1Selected,
      point2Selected,
      point1X,
      point2X,
      point1Y,
      point2Y,
    } = this.state
    const isSettingPoint1 = point1Selected
    const isSettingPoint2 = point2Selected || (hasPoint1 && !hasPoint2)

    if (isSettingPoint1) {
      const xDiff = Math.abs(x - point2X)
      const yDiff = Math.abs(y - point2Y)
      if (yDiff > xDiff) {
        x = point2X
      } else {
        y = point2Y
      }
      this.setState({
        point1X: x,
        point1Y: y,
      })
    } else if (isSettingPoint2) {
      const xDiff = Math.abs(x - point1X)
      const yDiff = Math.abs(y - point1Y)
      if (yDiff > xDiff) {
        x = point1X
      } else {
        y = point1Y
      }
      this.setState({
        point2X: x,
        point2Y: y,
      })
    }
  }

  handleClose = () => {
    this.props.history.push(
      this.props.match.url.replace(routes.modals.scaleImage, '')
    )
  }

  handleClearPoints = () => {
    this.setState(ScaleImageModal.initialStatePoints)
  }

  get canClearPoints() {
    return this.state.hasPoint1 || this.state.hasPoint2
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    const { point1X, point1Y, point2X, point2Y, referenceLength } = this.state
    const { backgroundImage } = this.props
    const image = backgroundImage.BACKGROUND_IMAGE

    const center1X = point1X + POINT_SIZE
    const center1Y = point1Y + POINT_SIZE
    const center2X = point2X + POINT_SIZE
    const center2Y = point2Y + POINT_SIZE
    const lineLength = Math.sqrt(
      Math.pow(center2X - center1X, 2) + Math.pow(center2Y - center1Y, 2)
    )

    const ratio = referenceLength.imperial() / lineLength

    trackEvent(onAddImage())
    this.props.onUpdateBackgroundImage({
      ...image,
      width: image.originalWidth * ratio,
      height: image.originalHeight * ratio,
      referenceLength: referenceLength.imperial(),
      point1X,
      point1Y,
      point2X,
      point2Y,
    })
    this.handleClose()
  }

  render() {
    const {
      parentRoute,
      history,
      props,
      backgroundImage,
      distanceUnits,
    } = this.props

    if (!backgroundImage || !backgroundImage.BACKGROUND_IMAGE) {
      return null
    }

    const image = backgroundImage.BACKGROUND_IMAGE
    const {
      point1X,
      point1Y,
      point2X,
      point2Y,
      point1Selected,
      point2Selected,
    } = this.state
    const shouldDisplayPoint1 = point1X > 0 && point1Y > 0
    const shouldDisplayPoint2 = point2X > 0 && point2Y > 0

    return (
      <div>
        <Helmet>
          <title>{getTitle('scaleImage')}</title>
        </Helmet>
        <Modal
          size="950px"
          title="Scale Image"
          parentRoute={parentRoute}
          history={history}
          onSubmit={this.handleSubmit}
          primaryAction={
            <Button primary onClick={this.handleSave} label="Save" />
          }
          secondaryAction={
            <Button
              onClick={event => {
                event.preventDefault()
                this.handleClose()
              }}
              label="Cancel"
            />
          }
          {...props}
        >
          <Grid vCenter>
            <GridItem size="3of5">
              <p>
                Click two points of a known length and enter the dimensions:
              </p>
            </GridItem>
            <GridItem size="2of5">
              <Flex alignItems="center" spacingRight="base">
                <FlexItem>
                  <DimensionInput
                    name="reference-length"
                    distance={this.state.referenceLength}
                    units={distanceUnits}
                    onChange={({ distance }) => {
                      this.setState({ referenceLength: distance })
                    }}
                  />
                </FlexItem>
                {this.canClearPoints && (
                  <FlexItem>
                    <A onClick={this.handleClearPoints}>Clear Points</A>
                  </FlexItem>
                )}
              </Flex>
            </GridItem>
          </Grid>
          <Container>
            <UncontrolledReactSVGPanZoom
              width={900}
              height={500}
              miniatureProps={{ miniaturePosition: 'none' }}
              SVGBackground="transparent"
              background="transparent"
              ref={viewer => {
                this.viewer = viewer
              }}
              onMouseDown={this.handleMouseDown}
              onMouseUp={this.handleMouseUp}
              onMouseMove={this.handleMouseMove}
            >
              <svg
                width={get(image, 'originalWidth')}
                height={get(image, 'originalHeight')}
              >
                <image
                  x={0}
                  y={0}
                  width={get(image, 'originalWidth')}
                  height={get(image, 'originalHeight')}
                  xlinkHref={get(image, 'src')}
                  onClick={this.handleDeselectAll}
                  style={{
                    cursor:
                      !shouldDisplayPoint1 || !shouldDisplayPoint2
                        ? 'crosshair'
                        : 'default',
                  }}
                />
                {shouldDisplayPoint1 && shouldDisplayPoint2 && (
                  <path
                    d={`M ${point1X} ${point1Y} L ${point2X} ${point2Y}`}
                    stroke="red"
                    strokeWidth="4"
                  />
                )}
                {shouldDisplayPoint1 && (
                  <svg
                    x={point1X - POINT_SIZE / 4}
                    y={point1Y - POINT_SIZE / 4}
                    width={POINT_SIZE}
                    height={POINT_SIZE}
                    fill={point1Selected ? theme.colors.light.primary : 'red'}
                    onMouseDown={() => this.handleSelectPoint1()}
                    onMouseUp={() => this.handleSelectPoint1()}
                  >
                    <path d="M19 14C20.104 14 21 13.3031 21 12.4444V1.55556C21 0.696889 20.104 0 19 0C17.894 0 17 0.696889 17 1.55556V12.4444C17 13.3031 17.894 14 19 14Z" />
                    <path d="M12.4444 17H1.55556C0.695333 17 0 17.896 0 19C0 20.104 0.695333 21 1.55556 21H12.4444C13.3031 21 14 20.104 14 19C14 17.896 13.3031 17 12.4444 17Z" />
                    <path d="M19 24C17.894 24 17 24.6969 17 25.5556V36.4444C17 37.3031 17.894 38 19 38C20.104 38 21 37.3031 21 36.4444V25.5556C21 24.6969 20.104 24 19 24Z" />
                    <path d="M36.4444 17H25.5556C24.6953 17 24 17.896 24 19C24 20.104 24.6953 21 25.5556 21H36.4444C37.3031 21 38 20.104 38 19C38 17.896 37.3031 17 36.4444 17Z" />
                    <circle cx={POINT_SIZE / 4} cy={POINT_SIZE / 4} r={5} />
                  </svg>
                )}
                {shouldDisplayPoint2 && (
                  <svg
                    x={point2X - POINT_SIZE / 4}
                    y={point2Y - POINT_SIZE / 4}
                    width={POINT_SIZE}
                    height={POINT_SIZE}
                    fill={point2Selected ? theme.colors.light.primary : 'red'}
                    onMouseDown={() => this.handleSelectPoint2()}
                    onMouseUp={() => this.handleSelectPoint2()}
                  >
                    <path d="M19 14C20.104 14 21 13.3031 21 12.4444V1.55556C21 0.696889 20.104 0 19 0C17.894 0 17 0.696889 17 1.55556V12.4444C17 13.3031 17.894 14 19 14Z" />
                    <path d="M12.4444 17H1.55556C0.695333 17 0 17.896 0 19C0 20.104 0.695333 21 1.55556 21H12.4444C13.3031 21 14 20.104 14 19C14 17.896 13.3031 17 12.4444 17Z" />
                    <path d="M19 24C17.894 24 17 24.6969 17 25.5556V36.4444C17 37.3031 17.894 38 19 38C20.104 38 21 37.3031 21 36.4444V25.5556C21 24.6969 20.104 24 19 24Z" />
                    <path d="M36.4444 17H25.5556C24.6953 17 24 17.896 24 19C24 20.104 24.6953 21 25.5556 21H36.4444C37.3031 21 38 20.104 38 19C38 17.896 37.3031 17 36.4444 17Z" />
                    <circle cx={POINT_SIZE / 4} cy={POINT_SIZE / 4} r={5} />
                  </svg>
                )}
              </svg>
            </UncontrolledReactSVGPanZoom>
          </Container>
        </Modal>
      </div>
    )
  }
}

ScaleImageModal.propTypes = {
  backgroundImage: PropTypes.object,
  distanceUnits: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  onUpdateBackgroundImage: PropTypes.func,
  parentRoute: PropTypes.string,
  props: PropTypes.object,
}

const mapStateToProps = ({ objects }) => ({
  backgroundImage: objects.present.backgroundImage,
})

const mapDispatchToProps = dispatch => ({
  onUpdateBackgroundImage(backgroundImage) {
    dispatch(updateBackgroundImage({ backgroundImage }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withUnits
)(withRouter(ScaleImageModal))
