import { useTexture } from '@react-three/drei'
import { ErrorBoundary } from '@sentry/react'
import { Suspense } from 'react'
import { useBackgroundImage } from '~/components/DrawingCanvas/BackgroundImage/hooks/useBackgroundImage'

const ImageMaterial = () => {
  const { src, opacity } = useBackgroundImage()
  const texture = useTexture(src)
  return <meshBasicMaterial transparent map={texture} opacity={opacity} toneMapped={false} />
}

const FallbackMaterial = () => <meshBasicMaterial color="#d8d8d8" toneMapped={false} />

export const BackgroundImageMaterial = () => {
  return (
    <ErrorBoundary fallback={<FallbackMaterial />}>
      <Suspense fallback={<FallbackMaterial />}>
        <ImageMaterial />
      </Suspense>
    </ErrorBoundary>
  )
}
