import PropTypes from 'prop-types'

import Container from './styled/Container'

const SelectList = Container

SelectList.propTypes = {
  background: PropTypes.string,
  borderless: PropTypes.bool,
  flushBottom: PropTypes.bool,
}

export default /** @type any */(SelectList)
