import React, { Component } from 'react'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'

import DefaultErrorScreen from 'screens/DefaultErrorScreen'

class ErrorBoundary extends Component {
  state = {
    error: null,
  }

  componentDidCatch(error, errorInfo) {
    const { onError, user } = this.props

    this.setState({ error })

    const captureContext = {
      extra: { ...errorInfo },
      user: { id: user.id, name: user.name, email: user.email },
    }

    Sentry.captureException(error, captureContext)

    onError && onError(error, errorInfo)
  }

  renderDefaultFallback() {
    return <DefaultErrorScreen />
  }

  render() {
    const { error } = this.state
    const { fallbackComponent } = this.props
    const defaultFallbackComponent = this.renderDefaultFallback()

    if (error) {
      return fallbackComponent ? fallbackComponent : defaultFallbackComponent
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  onError: PropTypes.func,
  fallbackComponent: PropTypes.node,
  user: PropTypes.object,
}

export default ErrorBoundary
