import styled from 'styled-components'

import { lighten, theme } from 'lib/styleUtils'

const Section = styled.div`
  background-color: ${props => lighten(props.theme.colors.light.subdued, 0.2)};
  border-bottom: ${theme('borderWidth')} solid ${theme('colors.light.subdued')};
  border-top: ${theme('borderWidth')} solid ${theme('colors.light.subdued')};

  ${props => props.inner && `margin-top: -1px;`};
`

export default Section
