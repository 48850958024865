import { SYSTEMS } from '../constants'
import { round } from '../utils'

export function imperialToMetric(value, options = {}) {
  return ((value - 32) * 5) / 9
}

export function metricToImperial(value, options = {}) {
  return (value * 9) / 5 + 32
}

export function convertTemperature({ value, system }, toSystem, options = {}) {
  let newValue = value
  if (system === SYSTEMS.METRIC && toSystem === SYSTEMS.IMPERIAL) {
    newValue = metricToImperial(value, options)
  } else if (system === SYSTEMS.IMPERIAL && toSystem === SYSTEMS.METRIC) {
    newValue = imperialToMetric(value, options)
  }

  return round(newValue, options.round)
}
