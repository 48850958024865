import styled from 'styled-components'

const setAlignment = alignment => {
  let rule

  switch (alignment) {
    case 'left':
      rule = `flex-start`
      break
    case 'center':
      rule = `center`
      break
    case 'right':
      rule = `flex-end`
      break
    case 'space-between':
      rule = `space-between`
      break
    default:
      rule = `flex-start`
  }

  return rule
}

const setSize = ({ size, flexGrow }) => {
  if (size) {
    return `flex-basis: ${size};`
  }

  return `flex-grow: ${flexGrow};`
}

const offlineStyles = ({ disabled }) => {
  if (disabled) {
    return `
      opacity: 0.6;
      pointer-events: none;
      `
  }
}

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: ${props => setAlignment(props.alignment)};

  ${props => setSize(props)};
  ${offlineStyles}
`

export default Container
