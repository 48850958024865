import { graphql } from '~/gql'

const PRODUCT_VARIATION_FRAGMENT = graphql(`
  fragment ProductVariationFragment on ProductVariation {
    id
    size
    label
    minObstructionClearance
    recommendedObstructionClearance
    minWallClearance
    recommendedWallClearance
    minProductClearance
    recommendedProductClearance
    minRoofClearance
    recommendedRoofClearance
    minFloorClearance
    recommendedFloorClearance
    canMountOnColumn
    canMountOnWall
    canMountOverhead
    canStandAlone
    cageHeight
    cfdId
    pedestals {
      id
      height
      fullHeight
    }
    voltages {
      id
      inputPower
      price {
        id
        price
      }
      mountingOptions {
        id
        tubeLength
        fullHeight
        label
        price {
          id
          price
        }
        forOverhead
        forWall
        forColumn
        forPedestal
      }
    }
    mountingOptionAdders {
      id
      mountingType
      price {
        id
        price
      }
    }
    degreesOfFreedom {
      id
      start
      end
      step
    }
    airVelocities {
      id
      variationId
      airfoilHeight
      evaluationHeight
      distanceFromFan
      airVelocity
      offsetFromFanCenter
      angle
    }
    product {
      id
      model
      type
      application
      category
      distinctFanSpeeds {
        speed
        overheadOnly
      }
    }
  }
`)

export default PRODUCT_VARIATION_FRAGMENT
