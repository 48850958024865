import { graphql } from '~/gql'

const HEATER_VARIATION_FRAGMENT = graphql(`
  fragment HeaterVariationFragment on ProductVariation {
    id
    size
    label
    minObstructionClearance
    recommendedObstructionClearance
    minWallClearance
    recommendedWallClearance
    minProductClearance
    recommendedProductClearance
    minRoofClearance
    recommendedRoofClearance
    minFloorClearance
    recommendedFloorClearance
    canMountOnColumn
    canMountOnWall
    canMountOverhead
    canStandAlone
    cageHeight
    cfdId
    pedestals {
      id
      height
      fullHeight
    }
    heaterData {
      id
      inputFiringRate
      minHeight
      radEfficiency
      minTubeLength
      tubeDiameter
      tubeCenterOffsetFromLeftX
      tubeCenterOffsetFromBottomY
      burnerBoxDepth
      burnerBoxHeight
      burnerBoxWidth
      burnerBoxClearanceDepth
      burnerBoxClearanceHeight
      burnerBoxClearanceWidth
      angle
      irhClearanceA
      irhClearanceB
      irhClearanceC
      irhClearanceD
      spotHeatHeight
      boxHeightA
      boxWidthB
      boxDepthF
      blowerDepthE
      uhClearanceTop
      uhClearanceFlueConnector
      uhClearanceAccessPanel
      uhClearanceNonAccessSide
      uhClearanceBottom
      uhClearanceRear
    }
    heaterPerformance {
      id
      distanceFromBurner
      axialRelativeIntensity
    }
    voltages {
      id
      inputPower
      price {
        id
        price
      }
      mountingOptions {
        id
        tubeLength
        fullHeight
        label
        price {
          id
          price
        }
        forOverhead
        forWall
        forColumn
        forPedestal
      }
    }
    mountingOptionAdders {
      id
      mountingType
      price {
        id
        price
      }
    }
    product {
      id
      model
      type
      application
      category
      sortIndex
    }
  }
`)

export default HEATER_VARIATION_FRAGMENT
