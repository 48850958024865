import { css } from 'styled-components'
import Color from 'color'
import get from 'lodash-es/get'

import defaultTheme from 'config/theme'

export const actionFocus = () =>
  css`
    outline: none;

    &:focus {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    }
  `

/** @type {any} */
export const breakpoints = Object.keys(defaultTheme.breakpoints).reduce(
  (accumulator, label) => {
    Object.assign(accumulator, {
      [label]: (...args) => css`
        @media (min-width: ${defaultTheme.breakpoints[label]}) {
          ${css(...args)};
        }
      `,
    })

    return accumulator
  },
  {}
)

export const darken = (color, percentage) =>
  Color(color).darken(percentage).hsl().string()

export const lighten = (color, percentage) =>
  Color(color).lighten(percentage).hsl().string()

export const linkStyles = () =>
  css`
    color: ${defaultTheme.colors.light.fg};
    text-decoration: underline;
  `

export const noSelect = () =>
  css`
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `

export const stretch = () =>
  css`
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  `

export const truncate = width =>
  `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: ${width};
  `

export const resetButtonStyles = () =>
  `
    background-color: transparent;
    border: 0;
    padding: 0;
  `

export const resetListStyles = () =>
  `
    list-style-type: none;
    margin: 0;
    padding: 0;
  `

export const theme = key => props => get(props.theme, key)
