type ObjectList<T> = { label: string; value: T }[]
const toObjects = <T extends string>(list: T[]): ObjectList<T> => list.map(item => ({ label: item, value: item }))

export const PrimaryTypes = {
  iBeams: 'I-Beams',
  girders: 'Girders',
  woodFrame: 'Wood Frame (24" Max C2C Spacing)',
  solidWoodBeam: 'Solid Wood Beam (3.75"-9.5" thickness)',
} as const
export type PrimaryTypes = (typeof PrimaryTypes)[keyof typeof PrimaryTypes]

const { iBeams, girders, woodFrame, solidWoodBeam } = PrimaryTypes

export const SecondaryTypes = {
  zPurlins: 'Z-purlins',
  barJoists: 'Bar Joists',
} as const

const { zPurlins, barJoists } = SecondaryTypes
export type SecondaryTypes = (typeof SecondaryTypes)[keyof typeof SecondaryTypes]

export type MountingTypes = PrimaryTypes | SecondaryTypes

// A map where keys are primary types and values are lists
// of available secondary types for a given primary type.
const primaryToSecondaryTypes: Record<PrimaryTypes, ObjectList<SecondaryTypes>> = {
  [iBeams]: toObjects([zPurlins, barJoists]),
  [girders]: toObjects([barJoists]),
  [woodFrame]: toObjects([]),
  [solidWoodBeam]: toObjects([]),
}

export const Style = {
  roofConforming: 'roof-conforming',
  flat: 'flat',
} as const
export type Style = (typeof Style)[keyof typeof Style]

export const beamDirections = toObjects(['vertical', 'horizontal'])

export const primaryMountingTypes = toObjects([
  iBeams,
  girders,
  woodFrame,
  solidWoodBeam,
])
export const secondaryMountingTypes = toObjects([zPurlins, barJoists])
export const allMountingTypes = (primaryMountingTypes as ObjectList<MountingTypes>).concat(
  secondaryMountingTypes
)

export const structureStyles = toObjects([Style.roofConforming, Style.flat])

export { primaryToSecondaryTypes }

export const primaryTypeToStyle: Record<PrimaryTypes, Style> = {
  [iBeams]: Style.roofConforming,
  [girders]: Style.roofConforming,
  [woodFrame]: Style.flat,
  [solidWoodBeam]: Style.roofConforming,
}
