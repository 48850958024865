import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { withRouter, matchPath } from 'react-router-dom'
import withProps from 'recompact/withProps'
import { format } from 'date-fns'
import get from 'lodash-es/get'

import { GET_FACILITY_QUERY } from '../queries'

const getFileName = facility => {
  // E.g. 2018-100-200-300-My Facility-BAF Layout.pdf
  return [
    format(new Date(), 'yyyy'),
    get(facility, 'id'),
    get(facility, 'floor.id'),
    get(facility, 'floor.version.id'),
    get(facility, 'name'),
    get(facility, 'floor.name'),
    'BAF Layout',
  ]
    .join('-')
    .concat('.pdf')
}

export default compose(
  withRouter,
  withProps(props => {
    const match = matchPath(props.location.pathname, {
      path: '/facility/:id/area/:floorId/version/:versionId',
    })
    const fallbackMatch = matchPath(props.location.pathname, {
      path: '/facility/:id',
    })
    return get(match, 'params') || get(fallbackMatch, 'params')
  }),
  graphql(GET_FACILITY_QUERY, {
    options: ({ id, floorId, versionId }) => ({
      variables: {
        id,
        floorId: floorId || null,
        versionId: versionId || null,
      },
    }),
    props: result => ({
      loading: get(result, 'data.loading') || get(result, 'ownProps.loading'),
      facility: get(result, 'data.facility'),
    }),
  }),
  withProps(({ facility }) => ({ fileName: getFileName(facility) }))
)
