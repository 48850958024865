import { compose } from 'redux'
import withProps from 'recompact/withProps'
import { get, sortBy, uniq } from 'lodash-es'
import { branch, renderComponent } from 'recompact'
import { showProductSize } from 'lib/utils'
import { withUnits } from 'store/units/decorators'
import Select from 'components/UIKit/Select'

const withVariationsMountingOptions = withProps(ownProps => {
  if (!ownProps.voltageId) {
    return {
      disabled: false,
      options: [
        {
          label: 'You must select a voltage before you can select a drop tube',
        },
      ],
      onChange: () => {}, // NOTE: Override onChange to make sure this option cannot be selected
    }
  }

  const { productVariations, productVariationId } = ownProps
  const variation = productVariations.find(v => v.id === productVariationId)
  const voltages = get(variation, 'voltages') || []
  const { mountingOptions } =
    voltages.find(v => v.id === ownProps.voltageId) || {}
  if (mountingOptions === undefined) {
    return {
      disabled: false,
      options: [
        {
          label: 'You must select a known voltage before you can select a drop tube',
        },
      ],
      onChange: () => {}, // NOTE: Override onChange to make sure this option cannot be selected
    }
  }
  const options = [
    { value: '', label: 'Choose a size...', disabled: true },
    ...sortBy(uniq(mountingOptions), 'tubeLength')
      .filter(o => {
        if (ownProps.mountedOn === 'WALL') return o.forWall
        if (ownProps.mountedOn === 'COLUMN') return o.forColumn
        if (ownProps.mountedOn === 'PEDESTAL') {
          if (variation.product.id === '10') {
            if (ownProps.mountingOptionAdderId === '46') {
              return o.forPedestal && o.label === 'Low Rider'
            } else {
              return o.forPedestal && o.label !== 'Low Rider'
            }
          }
          return o.forPedestal
        }
        return o.forOverhead
      })
      .map(o => {
        let label = o.label
        if (!label) {
          label = showProductSize(o.tubeLength, ownProps.distanceUnits, 2)
        }

        return {
          value: o.id,
          label,
        }
      }),
  ]

  return {
    disabled: ownProps.disabled,
    options,
  }
})

const MountingOptionsSelect = compose(
  withUnits,
  withVariationsMountingOptions,
  branch(
    props => (get(props, 'options') || []).length === 0,
    renderComponent(() => null)
  )
)(Select)

MountingOptionsSelect.displayName = 'MountingOptionsSelect'

export default /** @type {any} */(MountingOptionsSelect)
