import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

export const Divider = styled.div`
  background-color: ${theme('colors.dark.light')};
  height: 50%;
  margin: 0 ${theme('spacing.s')};
  width: 1px;
`

export default Divider
