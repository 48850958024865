import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const MenuColumn = styled.div`
  border-right: 1px solid ${theme('colors.light.subdued')};
  height: 100%;
  padding: ${theme('spacing.l')};
  width: 299px;
`

export default MenuColumn
