import theme from 'config/theme'
import { lighten } from 'lib/styleUtils'
import { comfortModel } from 'lib/thermalComfortTool/comfortModels'
import { util } from 'lib/thermalComfortTool/util'

const colorMap = {
  Cold: theme.colors.pmv.cold,
  Cool: lighten(theme.colors.pmv.cold, 0.2),
  'Slightly Cool': lighten(theme.colors.pmv.cold, 0.4),
  Neutral: theme.colors.light.subdued,
  'Slightly Warm': lighten(theme.colors.pmv.hot, 0.4),
  Warm: lighten(theme.colors.pmv.hot, 0.2),
  Hot: theme.colors.pmv.hot,
}

export const getSensationColor = sensation => colorMap[sensation] ?? theme.colors.light.subdued

/**
 * Calculates Thermal Comfort via the CBE Tool. Input data is in metric,
 * but will be converted if imperial values are passed in. Arguments such
 * as humidity, metabolicRate, and clothingLevel generally come from static
 * default facility data.
 * @typedef {import("store/units/types/temperature").default} Temperature
 * @typedef {import("store/units/types/velocity").default} Velocity
 * @typedef {object} input
 * @prop {Temperature} meanAirTemp
 * @prop {Temperature} [meanRadiantTemp] - Defaults to the value passed in for meanAirTemp
 * @prop {Velocity} airVelocity
 * @prop {Number} humidity - Percentage represented by an integer from 0 - 100
 * @prop {Number} metabolicRate
 * @prop {Number} clothingType
 * @prop {Number} [externalWork] - Defaults to 0. Rarely used.
 * @param {input} input
 *
 * @typedef {object} ThermalComfort
 * @prop {number} pmv
 * @prop {number} ppd
 * @prop {number} set
 * @prop {string} sensation
 * @prop {number} coolingEffect
 * @returns {ThermalComfort}
 */
export const getThermalComfort = ({
  meanAirTemp,
  meanRadiantTemp = meanAirTemp,
  airVelocity,
  humidity,
  metabolicRate,
  clothingType,
  externalWork = 0,
}) => {
  const ta = meanAirTemp.metric()
  const tr = meanRadiantTemp.metric()
  const vel = airVelocity.metric()
  const sanitizedHumidity = Math.max(Math.min(100, humidity), 0)
  const { ppd, pmv, set, cooling_effect } = comfortModel.pmvElevatedAirspeed(
    ta,
    tr,
    vel,
    sanitizedHumidity,
    metabolicRate,
    clothingType,
    externalWork
  )
  const isValidPmv = !isNaN(pmv)
  const sanitizedPmv = isValidPmv ? pmv : 0
  if (!isValidPmv) console.warn('Invalid parameters were entered into thermal comfort tool.')
  const sensation = util.getSensation(sanitizedPmv)
  return {
    pmv: sanitizedPmv,
    ppd,
    set,
    sensation,
    coolingEffect: cooling_effect,
  }
}
