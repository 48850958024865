import styled from 'styled-components'

const Track = styled.span<{ checked?: boolean }>`
  background-color: ${props =>
    props.checked
      ? props.theme.colors.light.primary
      : props.theme.colors.light.subdued};
  border: 0;
  border-radius: 20px;
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s ease-in-out;
  width: 40px;
`

export default Track
