import { graphql } from '~/gql'

const PROCESS_JOB_FRAGMENT = graphql(`
  fragment ProcessFragment on ProcessJob {
    id
    percentComplete
    step
  }
`)

export default PROCESS_JOB_FRAGMENT
