import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InfoTooltip from 'components/UIKit/InfoTooltip'
import routes from 'config/routes'
import theme from 'config/theme'
import { SIMULATION_TYPES } from 'config/cfd'
import { camelToTitleCase } from 'utils/string'
import { MainImage } from '../styled/MainImage'
import { Range, RangeContainer } from '../styled/Range'
import { Stack } from 'components/UIKit/Stack'

const baseTitleMap = new Map([
  ['sideX', 'X-Section Fans'],
  ['sideY', 'Y-Section Fans'],
])

const coolingTitleMap = new Map([
  ...baseTitleMap,
  ['overhead', 'Evaluation Height'],
  ['overhead_temperature', 'Temperature (Evaluation Height)'],
  ['sideX_temperature', 'Temperature (X-Section Fans)'],
  ['sideY_temperature', 'Temperature (Y-Section Fans)'],
])

const destratTitleMap = new Map([
  ...baseTitleMap,
  ['destrat', 'Coverage'],
  ['overhead', 'Draft Risk - Evaluation Height'],
])

const getCFDImageGroupTitle = (simulationType, view) => {
  if (simulationType === SIMULATION_TYPES.cooling) {
    return coolingTitleMap.get(view) ?? camelToTitleCase(view)
  } else if (simulationType === SIMULATION_TYPES.destrat) {
    return destratTitleMap.get(view) ?? camelToTitleCase(view)
  } else {
    console.warn('Unexpected simulation type: ', simulationType)
    return camelToTitleCase(view)
  }
}

export const ImageDisplay = ({ simulationType, imageGroup }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const { view, images } = imageGroup
  const safeIndex = Math.min(images.length - 1, selectedIndex)
  const selectedImage = images[safeIndex]
  const label =
    selectedImage?.fileProps.type === 'overhead' &&
    typeof selectedImage?.fileProps.level === 'number'
      ? `${selectedImage.fileProps.level}"`
      : camelToTitleCase(selectedImage.fileProps.level.toString())

  const handlePrimaryChange = e => setSelectedIndex(+e.target.value)

  return (
    <>
      <MainImage src={selectedImage?.url} />
      <RangeContainer>
        <Stack direction="row" spacing={0.5} alignItems="end">
          <Range
            title={getCFDImageGroupTitle(simulationType, view)}
            step={1}
            disabled={images.length < 2}
            options={images}
            value={safeIndex.toString()}
            onChange={handlePrimaryChange}
            label={label}
            endAdornment={
              <InfoTooltip title="Learn More" url={routes.faq} color={theme.colors.light.fg} />
            }
          />
        </Stack>
      </RangeContainer>
    </>
  )
}

ImageDisplay.propTypes = {
  simulationType: PropTypes.string,
  imageGroup: PropTypes.shape({
    thumbnail: PropTypes.string,
    view: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object),
  }),
}
