import { graphql } from '~/gql'

const UPDATE_SNAPSHOT_MUTATION = graphql(`
  mutation UpdateSnapshot(
    $snapshotId: ID
    $cloudinaryId: String
    $title: String
    $cropData: Json
  ) {
    updateSnapshot(
      snapshotId: $snapshotId
      cloudinaryId: $cloudinaryId
      title: $title
      cropData: $cropData
    ) {
      ...SnapshotFragment
    }
  }
`)

export default UPDATE_SNAPSHOT_MUTATION
