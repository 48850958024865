import React, { useEffect, useReducer } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'

import { onUpdateUserProfileInfo } from 'config/analytics'
import { trackEvent } from 'lib/analytics'
import withUser from 'client/decorators/withUser'
import { UPDATE_USER_MUTATION } from 'client/mutations'
import Button from 'components/UIKit/Button'
import Loader from 'components/UIKit/Loader'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'
import VariantText from 'components/UIKit/VariantText'

function reducer(state, action) {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const initialState = {
  name: '',
  email: '',
  phone: '',
}

function UserProfileInfoModal(props = {}) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const id = get(props, 'user.id')
  const name = get(props, 'user.name', '') || ''
  const email = get(props, 'user.email', '') || ''
  const phone = get(props, 'user.phone', '') || ''

  useEffect(() => {
    if (!props.loading) {
      dispatch({
        type: 'update',
        payload: {
          name,
          email,
          phone,
        },
      })
    }
  }, [props.loading, name, email, phone])

  function handleChange(event) {
    dispatch({
      type: 'update',
      payload: {
        ...state,
        [event.target.name]: event.target.value,
      },
    })
  }

  function handleSave(updateUser) {
    trackEvent(onUpdateUserProfileInfo())
    updateUser({
      variables: {
        id,
        ...state,
      },
    })
  }

  return (
    <Modal
      title="User Profile Info"
      primaryAction={
        <Mutation
          mutation={UPDATE_USER_MUTATION}
          key={id}
          onCompleted={data => {
            props.history.push(props.parentRoute)
          }}
        >
          {(updateUser, { loading, error }) => (
            <Button
              primary
              onClick={event => {
                event.preventDefault()
                handleSave(updateUser)
              }}
              disabled={loading || !id}
              label={loading ? 'Saving...' : 'Save'}
            />
          )}
        </Mutation>
      }
      secondaryAction={<Button to={props.parentRoute} label="Cancel" />}
      size="500px"
      {...props}
    >
      {props.loading ? (
        <Loader />
      ) : (
        <>
          <Space bottom="base">
            <TextField
              name="name"
              label="Name"
              value={get(state, 'name', '')}
              overrideValue={get(state, 'name', '')}
              onChange={handleChange}
            />
          </Space>
          <Space bottom="base">
            <Space bottom="xs">
              <TextField
                type="email"
                name="email"
                label="Email"
                disabled={true}
                value={get(state, 'email', '')}
                overrideValue={get(state, 'email', '')}
                onChange={handleChange}
              />
            </Space>
            <VariantText color="light" size="s">
              Your email is set by the authentication provider when you sign in.
            </VariantText>
          </Space>
          <Space bottom="base">
            <TextField
              name="phone"
              label="Phone Number"
              value={get(state, 'phone', '')}
              overrideValue={get(state, 'phone', '')}
              onChange={handleChange}
              // TODO: Format phone number `onBlur`.
            />
          </Space>
        </>
      )}
    </Modal>
  )
}

UserProfileInfoModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  loading: PropTypes.bool,
  parentRoute: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
  }),
}

export default compose(withUser, withRouter)(UserProfileInfoModal)
