import isNil from 'lodash-es/isNil'
import omitBy from 'lodash-es/omitBy'

import { UPDATE_INSTALL } from './actions'
import { LOAD_FACILITY, RESET_FACILITY } from '../objects/action_types'

export const initialState = {
  fireRelay: '',
  fireRelayType: '',
  liftType: '',
  level: '',
  installAdders: /** @type {any[]} */([]),
  installNotes: '',
  controllerType: '',
  controllerOther: '',
  controllerQty: 0,
}

export default function installReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_INSTALL: {
      const {
        fireRelay,
        fireRelayType,
        liftType,
        level,
        installAdders,
        controllerType,
        controllerQty,
        controllerOther,
        installNotes,
      } = action.payload[0]

      return {
        ...state,
        fireRelay,
        fireRelayType,
        liftType,
        level,
        installAdders,
        controllerType,
        controllerQty,
        controllerOther,
        installNotes,
      }
    }
    case LOAD_FACILITY: {
      return {
        ...state,
        ...omitBy(action.payload.install, isNil),
      }
    }
    case RESET_FACILITY: {
      return initialState
    }
    default: {
      return state
    }
  }
}
