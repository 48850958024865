import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const caretSize = '5px'

const disabledStyles = () =>
  `
    &::after {
      opacity: 0.2;
    }
  `

const InputContainer = styled.div`
  display: inline-block;
  max-width: ${props => (props.size ? props.size : '200px')};
  position: relative;
  width: 100%;

  ${props => (props.disabled || !props.online) && disabledStyles()};
  ${props => props.required && `border : 1px solid rgba(255,0,0,0.5);`};

  &::after {
    border-bottom: ${caretSize} solid transparent;
    border-left: ${caretSize} solid transparent;
    border-right: ${caretSize} solid transparent;
    border-top: ${caretSize} solid ${theme('colors.light.fg')};
    content: '';
    display: inline-block;
    height: 0;
    pointer-events: none;
    position: absolute;
    right: ${theme('spacing.s')};
    top: calc(50% - ${caretSize} / 2);
    width: 0;
  }
`

export default InputContainer
