import React from 'react'

import A from '../A'

import Link from './styled/Link'
import List from './styled/List'
import ListItem from './styled/ListItem'
import { FontSize } from '~/config/theme'

type BreadcrumbItem =
  { title: string } |
  { title: string; to: string } |
  { title: string; onClick: React.MouseEventHandler<HTMLAnchorElement> }

export default function Breadcrumbs(props: { size?: FontSize; items: BreadcrumbItem[] }) {
  return <List as="ol" spacingRight="base" size={props.size ?? 's'}>
    {props.items.map((i, ix) => {
      if ('to' in i) {
        return (
          <ListItem as="li" key={ix}>
            <Link to={i.to}>{i.title}</Link>
          </ListItem>
        )
      }

      if ('onClick' in i) {
        return (
          <ListItem as="li" key={ix}>
            <A href="#" onClick={i.onClick}>
              {i.title}
            </A>
          </ListItem>
        )
      }

      return (
        <ListItem as="li" key={ix}>
          <strong>{i.title}</strong>
        </ListItem>
      )
    })}
  </List>
}
