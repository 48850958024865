import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Label = styled.strong`
  padding-right: ${theme('spacing.s')};

  > *:first-child {
    margin-right: ${theme('spacing.xs')};
  }
`

export default Label
