import { useEffect, useMemo, useState } from 'react'
import CoolingWorker from '../workers/cooling.worker?worker'
import DestratWorker from '../workers/destrat.worker?worker'
import UnitHeatWorker from '../workers/unitHeat.worker?worker'
import RadiantHeatWorker from '../workers/radiantHeat.worker?worker'
import HeatingCompositeWorker from '../workers/heatingComposite.worker?worker'
import type { CoolingVTKData, CoolingVTKResults } from '../workers/cooling.worker'
import type { DestratVTKData, DestratVTKResults } from '../workers/destrat.worker'
import type { UnitHeatVTKData, UnitHeatVTKResults } from '../workers/unitHeat.worker'
import type { RadiantHeatVTKData, RadiantHeatVTKResults } from '../workers/radiantHeat.worker'
import type { HeatingCompositeVTKData, HeatingCompositeVTKResults } from '../workers/heatingComposite.worker'

type workerEventProps<Results> = {
  onMessage?: (e: MessageEvent<Results>) => void
  onError?: (e: ErrorEvent) => void
}
type useVtkWorkerProps<Data, Results> = workerEventProps<Results> & {
  VtkWorker: { new (): Worker }
  payload: Data
}
const useVtkWorker = <Data, Results>(props: useVtkWorkerProps<Data, Results>) => {
  const [status, setStatus] = useState({ loading: false, error: null as ErrorEvent | null, data: null as Results | null })
  const { onMessage, onError, VtkWorker, payload } = props

  useEffect(() => {
    const worker = new VtkWorker()
    worker.onmessage = e => {
      if (e.data?.error) {
        setStatus({ error: e.data.error, loading: false, data: null })
      } else {
        setStatus({ data: e.data, loading: false, error: null })
      }
      onMessage && onMessage(e)
    }
    worker.onerror = error => {
      setStatus({ error, loading: false, data: null })
      onError && onError(error)
    }
    setStatus({ loading: true, error: null, data: null })
    worker.postMessage(payload)
    return () => worker.terminate()
  }, [payload, onError, onMessage, VtkWorker])

  return {
    ...status,
    isLoading: status.loading,
    isError: !!status.error,
    isComplete: !!status.data,
  }
}

type workerHookProps<Results, Data> = workerEventProps<Results> & Data

export const useCoolingVtkWorker = ({
  onMessage,
  onError,
  temperatureUrls,
  velocityUrls,
  coverageThreshold,
  comfortZoneEdges,
}: workerHookProps<CoolingVTKResults, CoolingVTKData>) => {
  const payload = useMemo(
    () => ({ temperatureUrls, velocityUrls, coverageThreshold, comfortZoneEdges }),
    [temperatureUrls, velocityUrls, coverageThreshold, comfortZoneEdges]
  )
  return useVtkWorker({ onMessage, onError, payload, VtkWorker: CoolingWorker })
}

export const useDestratWorker = ({
  onMessage,
  onError,
  destratUrl,
  draftRiskUrls,
  comfortZoneEdges,
}: workerHookProps<DestratVTKResults, DestratVTKData>) => {
  const payload = useMemo(() => ({ destratUrl, draftRiskUrls, comfortZoneEdges }), [
    destratUrl,
    draftRiskUrls,
    comfortZoneEdges,
  ])
  return useVtkWorker({ onMessage, onError, payload, VtkWorker: DestratWorker })
}

export const useUnitHeatWorker = ({
  onMessage,
  onError,
  temperatureBeforeUrls,
  temperatureAfterUrls,
  velocityAfterUrls,
  coverageThreshold,
  comfortZoneEdges,
}: workerHookProps<UnitHeatVTKResults, UnitHeatVTKData>) => {
  const payload = useMemo(
    () => ({
      temperatureBeforeUrls,
      temperatureAfterUrls,
      velocityAfterUrls,
      coverageThreshold,
      comfortZoneEdges,
    }),
    [
      temperatureBeforeUrls,
      temperatureAfterUrls,
      velocityAfterUrls,
      coverageThreshold,
      comfortZoneEdges,
    ]
  )
  return useVtkWorker({ onMessage, onError, payload, VtkWorker: UnitHeatWorker })
}

export const useRadiantHeatWorker = ({
  onMessage,
  onError,
  coverageThreshold,
  intensityUrl,
  comfortZoneEdges,
}: workerHookProps<RadiantHeatVTKResults, RadiantHeatVTKData>) => {
  const payload = useMemo(() => ({ intensityUrl, coverageThreshold, comfortZoneEdges }), [
    intensityUrl,
    coverageThreshold,
    comfortZoneEdges,
  ])
  return useVtkWorker({ onMessage, onError, payload, VtkWorker: RadiantHeatWorker })
}

export const useHeatingCompositeWorker = ({
  onMessage,
  onError,
  temperatureBeforeUrls,
  temperatureAfterUrls,
  velocityAfterUrls,
  heatingCoverageThreshold,
  intensityUrl,
  comfortZoneEdges,
}: workerHookProps<HeatingCompositeVTKResults, HeatingCompositeVTKData>) => {
  const payload = useMemo(
    () => ({
      temperatureBeforeUrls,
      temperatureAfterUrls,
      velocityAfterUrls,
      heatingCoverageThreshold,
      intensityUrl,
      comfortZoneEdges,
    }),
    [
      temperatureBeforeUrls,
      temperatureAfterUrls,
      velocityAfterUrls,
      heatingCoverageThreshold,
      intensityUrl,
      comfortZoneEdges,
    ]
  )
  return useVtkWorker({ onMessage, onError, payload, VtkWorker: HeatingCompositeWorker })
}
