import styled from 'styled-components'

import { theme } from 'lib/styleUtils'
import { getZIndexOf } from 'lib/utils'

const Container = styled.div`
  align-items: center;
  background-color: ${theme('colors.dark.subdued')};
  color: ${theme('colors.dark.fg')};
  display: flex;
  justify-content: center;
  font-size: ${theme('fonts.sizes.s')};
  position: relative;
  width: 100%;
  z-index: ${getZIndexOf('alert')};
`

export default Container
