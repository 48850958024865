import { motion } from 'framer-motion'
import styled, { ThemedStyledProps } from 'styled-components'
import { Spacing, Theme, Variant } from '~/config/theme'

const colors = ({ variant, theme }: ThemedStyledProps<{ variant?: Variant }, Theme>) => {
  if (variant === 'dark') {
    return `
      background-color: ${theme.colors.dark.bg};
      color: ${theme.colors.dark.fg};
    `
  }

  return `
      background-color: ${theme.colors.light.bg};
      color: ${theme.colors.light.fg};
  `
}

const padding = ({ flush, padding, theme }: ThemedStyledProps<{ flush?: boolean; padding?: Spacing }, Theme>) => {
  if (flush) return

  if (padding) {
    return `padding: ${theme.spacing[padding]}`
  }

  return `padding: ${theme.spacing.base}`
}

const Container = styled(motion.div)<{ noRadius?: boolean; noShadow?: boolean; flush?: boolean; padding?: Spacing; variant?: Variant }>`
  ${colors};
  ${padding};

  ${props => !props.noRadius && `border-radius: ${props.theme.radius.base};`};
  ${props => !props.noShadow && `box-shadow: ${props.theme.shadows.base};`};
`

export default Container
