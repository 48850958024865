import { graphql } from '~/gql'

const ALL_PRODUCT_VARIATIONS_QUERY = graphql(`
  query AllProductVariations($limit: Int!, $filter: ProductFilter) {
    allProductVariations(first: $limit, filter: $filter, orderBy: "size_ASC") {
      ...ProductVariationFragment
    }
  }
`)

export default ALL_PRODUCT_VARIATIONS_QUERY
