import React, { Component } from 'react'
import { func, object, string } from 'prop-types'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";

import { addObject } from 'store/objects'
import { start2DFit } from 'store/camera'
import { SYSTEMS } from 'store/units/constants'
import { Distance } from 'store/units/types'
import { withUnits } from 'store/units/decorators'
import LAYER_KEYS from 'config/layerKeys'
import { onCreateRectangle } from 'config/analytics'
import { trackEvent } from 'lib/analytics'

import Wall from 'components/DrawingCanvas/lib/wall'

import Button from 'components/UIKit/Button'
import DimensionInput from 'components/UIKit/DimensionInput'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Icon from 'components/UIKit/Icon'
import Space from 'components/UIKit/Space'
import LengthLabel from './Styled/LengthLabel'
import WidthLabel from './Styled/WidthLabel'

class RectangleScreen extends Component {
  state = {
    width: new Distance({
      value: 600,
      system: SYSTEMS.IMPERIAL,
    }),
    height: new Distance({
      value: 288,
      system: SYSTEMS.IMPERIAL,
    }),
    length: new Distance({
      value: 600,
      system: SYSTEMS.IMPERIAL,
    }),
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    const thickness = new Distance({
      value: Wall.thicknessForLayerKey(LAYER_KEYS.EXTERIOR_WALLS, true),
      system: SYSTEMS.NATIVE,
    })
    const { width, height, length } = this.state
    const outsideLength = length.imperial() + thickness.imperial()
    const outsideWidth = width.imperial() + thickness.imperial()
    const {
      addWall,
      zoomToFit,
      currentLayer,
      parentRoute,
      history,
    } = this.props

    let perimeterPoints = [
      [-outsideWidth / 2, -outsideLength / 2],
      [outsideWidth / 2, -outsideLength / 2],
      [outsideWidth / 2, outsideLength / 2],
      [-outsideWidth / 2, outsideLength / 2],
      [-outsideWidth / 2, -outsideLength / 2],
    ]

    const model = Wall.createModel(perimeterPoints, 'inches', currentLayer, {
      height: height.imperial(),
      convertFromNativeUnits: false,
    })

    const wall = new Wall(model, 'INCHES')

    wall.segments.forEach(segment => (segment.wall = wall))

    addWall(wall.toModel())
    history.push(parentRoute)
    zoomToFit()
  }

  render() {
    const { width, height, length } = this.state
    const { distanceUnits } = this.props
    return (
      <Grid vCenter>
        <GridItem size="1of2">
          <form onSubmit={this.handleSubmit}>
            <Space bottom="base">
              <h2>Rectangle</h2>
            </Space>
            <Space bottom="l">
              <Space bottom="base">
                <DimensionInput
                  name="width"
                  label="Width"
                  distance={
                    width ||
                    new Distance({
                      value: 1200,
                      system: SYSTEMS.IMPERIAL,
                    })
                  }
                  tabIndex={1}
                  units={distanceUnits}
                  onChange={({ distance }) => {
                    this.handleChange({
                      name: 'width',
                      value: distance,
                    })
                  }}
                />
              </Space>
              <Space bottom="base">
                <DimensionInput
                  name="length"
                  value={length}
                  label="Length"
                  distance={
                    length ||
                    new Distance({
                      value: 240,
                      system: SYSTEMS.IMPERIAL,
                    })
                  }
                  tabIndex={2}
                  units={distanceUnits}
                  onChange={({ distance }) => {
                    this.handleChange({
                      name: 'length',
                      value: distance,
                    })
                  }}
                />
              </Space>
              <Space bottom="base">
                <DimensionInput
                  name="height"
                  tooltip="If the roof is sloped, enter eave height"
                  value={height}
                  label="Height"
                  distance={
                    height ||
                    new Distance({
                      value: 1200,
                      system: SYSTEMS.IMPERIAL,
                    })
                  }
                  tabIndex={3}
                  units={distanceUnits}
                  onChange={({ distance }) => {
                    this.handleChange({
                      name: 'height',
                      value: distance,
                    })
                  }}
                />
              </Space>
            </Space>
            <Button
              primary
              label="Create Rectangle"
              type="submit"
              tabIndex={4}
              onClick={() => trackEvent(onCreateRectangle())}
            />
          </form>
        </GridItem>
        <GridItem size="1of2">
          <WidthLabel>Width</WidthLabel>
          <LengthLabel>Length</LengthLabel>
          <Icon name="rectangle" size="200" />
        </GridItem>
      </Grid>
    )
  }
}

RectangleScreen.propTypes = {
  addWall: func,
  zoomToFit: func,
  currentLayer: string,
  history: object,
  parentRoute: string,
  distanceUnits: string,
}

const mapDispatchToProps = dispatch => ({
  addWall(wallModel) {
    dispatch(addObject({ object: wallModel }))
  },
  zoomToFit() {
    dispatch(start2DFit())
  },
})

const mapStateToProps = ({ layers }) => ({
  currentLayer: layers.currentLayer,
})

export default compose(
  withUnits,
  appConnect(mapStateToProps, mapDispatchToProps)
)(RectangleScreen)
