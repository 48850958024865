import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import { withProps } from 'recompact'

import {
  onFacilityTemplateViewed,
  onFacilityTemplateCopied,
  onFacilityTemplateDeleted,
} from 'config/analytics'
import { trackEvent } from 'lib/analytics'

import { parseCloudinaryUrlTransforms } from 'lib/utils'

import {
  DELETE_FACILITY_TEMPLATE_MUTATION,
  DUPLICATE_FACILITY_MUTATION,
} from 'client/mutations'
import {
  handleDeleteFacilityTemplate,
  handleCopyFacilityTemplate,
} from 'client/handlers'
import { showAlert } from 'store/alert'

import { hasPermission } from '../../RequiredPermission'
import Button from 'components/UIKit/Button'
import Flag from 'components/UIKit/Flag'
import Icon from 'components/UIKit/Icon'
import Image from 'components/UIKit/Image'
import ImageZoom from 'components/UIKit/ImageZoom'
import Popover from 'components/UIKit/Popover'
import {
  SelectListIconDownloading,
  SelectListIconDownload,
  SelectListItem,
  SelectListLink,
  SelectListIcon,
  SelectListTitle,
  SelectListActions,
} from 'components/UIKit/SelectList'
import VariantText from 'components/UIKit/VariantText'

import PlaceholderImage from './styled/PlaceholderImage'
import { SQUARE_THUMBNAIL_SIZE } from 'components/MetadataSection/MetadataSection'

import {
  queryIsCached,
  facilityQueryOptions,
  downloadFacility,
} from 'client/decorators/withInitialData'

const Template = ({
  id,
  name,
  description,
  imageUrl,
  facility,
  history,
  match,
  onDelete,
  onCopy,
  hideEditButton,
  hideDeleteButton,
  onShowAlert,
  offline,
}) => {
  const items = []

  const [downloading, setDownloading] = useState(false)
  const [downloaded, setDownloaded] = useState(
    queryIsCached(facilityQueryOptions({ facility }))
  )
  const disabled = offline && !downloaded

  if (!downloaded) {
    items.push({
      title: 'Download',
      icon: {
        name: 'disk',
      },
      onClick: async event => {
        setDownloading(true)
        event.preventDefault()
        event.stopPropagation()

        await downloadFacility({ facility })
        setDownloaded(queryIsCached(facilityQueryOptions({ facility })))
        setDownloading(false)
      },
    })
  }

  if (!hideEditButton) {
    items.push({
      title: 'Edit',
      icon: {
        name: 'edit',
      },
      onClick: event => {
        event.preventDefault()
        event.stopPropagation()
        history.push(`${match.url}/edit-template/${id}`)
      },
    })
  }

  items.push({
    title: 'Copy',
    icon: {
      name: 'duplicate',
    },
    onClick: async event => {
      event.preventDefault()
      event.stopPropagation()

      trackEvent(onFacilityTemplateCopied())

      const result = await onCopy({
        name: `${name} (Copy)`,
        facilityId: facility.id,
      })

      history.push(`/facility/${result.data.facility.id}`)
    },
  })

  if (!hideDeleteButton) {
    items.push({
      title: 'Delete',
      icon: {
        name: 'trash',
      },
      onClick: event => {
        event.preventDefault()
        event.stopPropagation()
        // eslint-disable-next-line
        if (confirm('Are you sure you want to delete this template?')) {
          onDelete({
            id: id,
          }).then(() => {
            trackEvent(onFacilityTemplateDeleted())
            onShowAlert({
              type: 'success',
              text: 'Template deleted!',
            })
          })
        }
      },
    })
  }

  const Media = imageUrl ? (
    <ImageZoom
      thumbnail={
        <PlaceholderImage
          alignItems="center"
          justifyContent="center"
          size={SQUARE_THUMBNAIL_SIZE}
        >
          <Image
            src={imageUrl}
            alt={`${name} Template Image`}
            width={SQUARE_THUMBNAIL_SIZE}
          />
        </PlaceholderImage>
      }
      fullImage={
        <Image
          src={parseCloudinaryUrlTransforms(imageUrl)}
          alt={`${name} Template Image`}
        />
      }
    />
  ) : (
    <PlaceholderImage
      alignItems="center"
      justifyContent="center"
      size={SQUARE_THUMBNAIL_SIZE}
    >
      <Icon name="interiorWall" size="40" color="subdued" />
    </PlaceholderImage>
  )

  return (
    <SelectListItem disabled={disabled}>
      <SelectListActions>
        {disabled ? (
          <Button noBorder icon="more" />
        ) : (
          <Popover
            items={items}
            renderTrigger={({ isDropdownVisible }) => (
              <Button noBorder icon="more" />
            )}
          />
        )}
      </SelectListActions>
      <SelectListLink as="div" actions>
        <Flag media={Media} vCenter>
          <SelectListLink
            nested
            paddingRight="l"
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()

              if (!disabled) {
                trackEvent(onFacilityTemplateViewed())
                history.push(`/facility/${facility.id}`)
              }
            }}
          >
            <span>
              <SelectListTitle>{name}</SelectListTitle>
              {description && <VariantText size="s">{description}</VariantText>}
            </span>
            <span>
              {(downloading && <SelectListIconDownloading />) ||
                (downloaded && <SelectListIconDownload />)}
              <SelectListIcon name="arrowRight" color="subdued" />
            </span>
          </SelectListLink>
        </Flag>
      </SelectListLink>
    </SelectListItem>
  )
}

Template.propTypes = {
  description: PropTypes.string,
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  hideDeleteButton: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
  onShowAlert: PropTypes.func,
  offline: PropTypes.bool,
}

const mapStateToProps = ({ auth }) => ({
  userId: auth.userId,
})

const mapDispatchToProps = dispatch => ({
  onShowAlert({ text, type }) {
    dispatch(showAlert({ text, type }))
  },
})

export default compose(
  withRouter,
  hasPermission({
    name: 'Edit Template',
    withPermissions: true,
  }),
  withProps(props => ({
    hideEditButton: props.hideEditButton || !props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  hasPermission({
    name: 'Delete Template',
    withPermissions: true,
  }),
  withProps(props => ({
    hideDeleteButton: props.hideDeleteButton || !props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  appConnect(mapStateToProps, mapDispatchToProps),
  graphql(DUPLICATE_FACILITY_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onCopy: ({ name, facilityId }) =>
        handleCopyFacilityTemplate({ name, facilityId, mutate, ownProps }),
    }),
  }),
  graphql(DELETE_FACILITY_TEMPLATE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onDelete: ({ id }) =>
        handleDeleteFacilityTemplate({ id, mutate, ownProps }),
    }),
  })
)(Template)
