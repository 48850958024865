import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.span`
  background-color: ${props =>
    props.type
      ? props.theme.colors.light[props.type]
      : props.theme.colors.light.fg};
  border-radius: ${theme('radius.l')};
  color: ${theme('colors.light.bg')};
  font-size: ${theme('fonts.sizes.s')};
  padding: 4px ${theme('spacing.s')};
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.15);
`

export default Container
