import theme from 'config/theme'
import styled from 'styled-components'

export const BadgeContainer = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  background-color: ${({ color }) => theme.colors.light[color] ?? theme.colors.light.bg};
  border-radius: 50%;
  width: 16px;
  height: 16px;

  &:hover::before {
    display: ${({ tooltip }) => (tooltip ? 'initial' : 'none')};
    content: '${({ tooltipLabel }) => tooltipLabel}';
    text-wrap: nowrap;
    color: white;
    background: ${theme.colors.dark.bg};
    position: absolute;
    top: -2em;
    z-index: 1;
    padding: 0.5em;
    border-radius: 5px;
  }

  &:hover::after {
    display: ${({ tooltip }) => (tooltip ? 'initial' : 'none')};
    content: '';
    background: ${theme.colors.dark.bg};
    position: absolute;
    width: 10px;
    height: 10px;
    rotate: 45deg;
    top: -6px;
  }
`
