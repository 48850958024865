import styled from 'styled-components'

import { lighten, darken, theme } from 'lib/styleUtils'

const collapsibleStyles = ({ collapsible, theme }) => {
  if (collapsible) {
    return `
      margin: 0;
    `
  }

  return `
    margin-bottom: ${theme.spacing.base};
    margin-left: -${theme.spacing.base};
    margin-right: -${theme.spacing.base};
  `
}

const Title = styled.h4`
  background-color: ${props => lighten(props.theme.colors.light.subdued, 0.2)};
  border-bottom: 1px solid ${theme('colors.light.subdued')};
  border-top: 1px solid ${theme('colors.light.subdued')};
  color: ${props => darken(props.theme.colors.light.subdued, 0.4)};
  font-size: ${theme('fonts.sizes.xs')};
  padding: ${theme('spacing.s')} ${theme('spacing.base')};

  ${collapsibleStyles};
`

export default Title
