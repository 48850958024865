import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: ${theme('spacing.s')};
`

export default ButtonsContainer
