import get from 'lodash-es/get'

import {
  selectedObjectsByRecency,
  getSelectedObjects,
  mostRecentSelectedObject,
} from './selectors'
import {
  duplicateObjects,
  duplicateGrid,
  deleteObjects,
} from '../objects/actions'
import {
  getObjectForDuplication,
  getObjectsForDuplication,
  COPYABLE_CLASS_NAMES,
} from '../objects/selectors'
import CLASS_NAMES from 'config/objectClassNames'
import Util from 'components/DrawingCanvas/lib/util'
import GridBox from 'components/DrawingCanvas/lib/gridBox'

export const SELECT_OBJECTS = 'SELECT_OBJECTS'
export const DESELECT_OBJECTS = 'DESELECT_OBJECTS'
export const DELETE_SELECTED_OBJECTS = 'DELETE_SELECTED_OBJECTS'

/**
 * @param {{ objects: { id: string; className: string }[] }} payload
 */
export const selectObjects = payload => (dispatch, getState) => dispatch({
  type: SELECT_OBJECTS,
  payload,
  globalState: getState(),
})

/**
 * @param {{ objects: { id: string }[] } | { selectedObjects: { id: string }[] } | {}} payload
 */
export const deselectObjects = payload => (dispatch, getState) => dispatch({
  type: DESELECT_OBJECTS,
  payload,
  globalState: getState(),
})

export const deleteSelectedObjects = () => (dispatch, getState) => {
  const globalState = getState()
  const selectedObjectIds = getSelectedObjects(globalState).map(o => o.id)
  if (!selectedObjectIds.length) {
    return
  }

  dispatch(deleteObjects(selectedObjectIds))
  dispatch({
    type: DELETE_SELECTED_OBJECTS,
    payload: selectedObjectIds,
    globalState,
  })
}

export const duplicateSelectedObjects = () => (dispatch, getState) => {
  const selectedObjects = selectedObjectsByRecency(getState())
  const newObjects = []

  // If copying more than one item keep the objects grouped
  if (selectedObjects.length > 1) {
    // Filter out any objects that aren't capable of being copied
    const validObjects = selectedObjects.filter(obj =>
      COPYABLE_CLASS_NAMES.includes(obj.className)
    )

    if (!validObjects.length) return

    // Some objects can be safley copied as a group while
    // others require being copied relative to their original
    const groupableTypes = [CLASS_NAMES.OBSTRUCTION, CLASS_NAMES.PRODUCT]
    const groupedObjects = validObjects.filter(obj =>
      groupableTypes.includes(obj.className)
    )
    const singleObjects = validObjects.filter(
      obj => !groupableTypes.includes(obj.className)
    )

    if (groupedObjects.length) {
      const dupeObjects = getObjectsForDuplication(groupedObjects)
      dupeObjects.forEach(obj => {
        if (obj.metadata) delete obj.metadata
        newObjects.push({
          obj,
          id: obj.id,
          className: obj.className,
          layerKey: obj.layerKey,
        })
      })
    }

    if (singleObjects.length) {
      singleObjects.forEach(obj => {
        const newObject = getObjectForDuplication(obj)
        if (newObject) {
          if (newObject.metadata) delete newObject.metadata
          newObjects.push({
            obj: newObject,
            id: newObject.id,
            className: obj.className,
            layerKey: obj.layerKey,
          })
        }
      })
    }
  } else {
    if (!COPYABLE_CLASS_NAMES.includes(selectedObjects[0].className)) return

    const newObject = getObjectForDuplication(selectedObjects[0])
    if (newObject) {
      if (newObject.metadata) delete newObject.metadata
      newObjects.push({
        obj: newObject,
        id: newObject.id,
        className: selectedObjects[0].className,
        layerKey: selectedObjects[0].layerKey,
      })
    }
  }

  dispatch(duplicateObjects(newObjects))
}

export const duplicateGridObjects = () => (dispatch, getState) => {
  const selectedObject = mostRecentSelectedObject(getState())
  const SHIFT = { x: 50, y: -50, z: 0 }
  const models = get(selectedObject, 'models', [])
  const newObjects = models.map(model => {
    switch (model.className) {
      case CLASS_NAMES.PRODUCT:
        return {
          ...model,
          id: Util.guid(),
          position: {
            x: model.position.x + SHIFT.x,
            y: model.position.y + SHIFT.y,
            z: model.position.z + SHIFT.z,
          },
        }
      case CLASS_NAMES.OBSTRUCTION:
        return {
          ...model,
          id: Util.guid(),
          position: {
            x: model.position.x + SHIFT.x,
            y: model.position.y + SHIFT.y,
            z: model.position.z + SHIFT.z,
          },
          positions: model.positions.map(pos => ({
            x: pos.x + SHIFT.x,
            y: pos.y + SHIFT.y,
            z: pos.z + SHIFT.z,
          })),
        }
      default:
        return {}
    }
  })
  const newGridBox = new GridBox({
    ...selectedObject,
    id: Util.guid(),
    xMin: get(selectedObject, 'xMin', 0) + SHIFT.x,
    xMax: get(selectedObject, 'xMax', 0) + SHIFT.x,
    yMin: get(selectedObject, 'yMin', 0) + SHIFT.y,
    yMax: get(selectedObject, 'yMax', 0) + SHIFT.y,
    models: newObjects,
  })
  dispatch(duplicateGrid({ objects: newObjects, gridBox: newGridBox.toModel() }))
  dispatch(selectObjects({ objects: [{ id: newGridBox.id, className: newGridBox.className }]}))
}
