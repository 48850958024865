import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'

import DropdownIcon from '../DropdownIcon'

import Container from './styled/Container'
import Media from './styled/Media'
import Content from './styled/Content'
import Inner from './styled/Inner'
import Actions from './styled/Actions'

const Flag = ({
  media,
  children,
  actions,
  dropdown,
  isDropdownVisible,
  flushMedia,
  ...props
}: {
  media?: ReactNode
  children?: ReactNode
  actions?: ReactNode
  dropdown?: boolean
  isDropdownVisible?: boolean
  flushMedia?: boolean
  vCenter?: boolean
  reverse?: boolean
} & React.ComponentPropsWithoutRef<'div'>) => (
  <Container {...props} dropdown={dropdown}>
    <Media flushMedia={flushMedia}>{media}</Media>
    <Content>
      <Inner>{children}</Inner>
      {actions && <Actions>{actions}</Actions>}
    </Content>
    {dropdown && <DropdownIcon isActive={isDropdownVisible} />}
  </Container>
)

export default Flag
