import styled from 'styled-components'

import { lighten, theme } from 'lib/styleUtils'
import { switchSize } from '../../Switch/styled/Button'

const Input = styled.input`
  align-self: center;
  margin: ${theme('spacing.s')} 0;
  -webkit-appearance: none;
  width: 100%;

  &:disabled::-webkit-slider-runnable-track {
    cursor: auto;
    opacity: 0.7;
  }
  &:disabled::-webkit-slider-thumb {
    background: ${theme('colors.light.subdued')};
    cursor: auto;
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
  &:focus::-webkit-slider-runnable-track {
    background: ${theme('colors.three.selected')};
  }

  &::-webkit-slider-thumb {
    background: ${theme('colors.light.primary')};
    border-radius: 50%;
    cursor: pointer;
    height: ${switchSize};
    margin-top: -5px;
    position: relative;
    -webkit-appearance: none;
    width: ${switchSize};
    z-index: 2;
  }
  &::-webkit-slider-runnable-track {
    background: ${props => lighten(props.theme.colors.light.subdued, 0.1)};
    border-radius: 5.3px;
    cursor: pointer;
    height: 10px;
    width: 100%;
  }
`

export default Input
