import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist'
import localforage from 'localforage'

import { cache } from '~/client'
import { FACILITY_LOCAL_STORAGE_KEY } from 'client/decorators/withInitialData'
import { removeAllExportStorageItems } from 'components/Modals/ExportPDFLayoutModal/Wizard/constants'
import { TOUCH_UI_LOCAL_STORAGE_KEY } from 'store/userInterface/constants'
import { GET_USER_CACHE_FLAG_QUERY } from 'client/queries'
import { UPDATE_USER_MUTATION } from 'client/mutations'
import get from 'lodash-es/get'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

const storeName = 'speclab'
const storageSize = 40980736
const speclabCacheVersionKey = "speclab-cache-version"
const speclabCacheVersion = "1"

const requiresCacheClear = () => {
  const currentVersion = localStorage?.getItem(speclabCacheVersionKey)
  localStorage?.setItem(speclabCacheVersionKey, speclabCacheVersion)
  return currentVersion !== speclabCacheVersion
}

localforage.config({
  name: `${storeName}-cache`,
  version: 1.0,
  storeName,
  description: 'The SpecLab cache used for offline mode.',
})

const persistor = new CachePersistor({
  cache,
  storage: new LocalForageWrapper(localforage),
  maxSize: storageSize,
  debug: true,
  key: `${storeName}-persist-cache`,
  // We don't want to persist the cache by default to avoid really
  // large cache sizes that ultimately impact performance. We will,
  // instead, rely on the "Download" button for each facility.
  trigger: false,
})

async function loadCache() {
  await persistor.restore()
}

async function purgeCache() {
  localStorage.removeItem(FACILITY_LOCAL_STORAGE_KEY)
  localStorage.removeItem(TOUCH_UI_LOCAL_STORAGE_KEY)
  removeAllExportStorageItems()
  await persistor.purge()
}

async function checkCacheClearStatus(client: ApolloClient<NormalizedCacheObject>) {
  let user: { requiresCacheClear?: boolean | undefined | null; id: string } = undefined as any
  await client
    .query({
      query: GET_USER_CACHE_FLAG_QUERY,
      fetchPolicy: 'network-only',
    })
    .then(res => {
      user = res.data.currentUser!
    })
    .catch(error => {
      return get(error, 'graphQLErrors[0].message') || get(error, 'message')
    })

  if (get(user, 'requiresCacheClear') || requiresCacheClear()) {
    await purgeCache()
    await client
      .mutate({
        mutation: UPDATE_USER_MUTATION,
        variables: {
          id: user?.id,
          requiresCacheClear: false,
        },
      })
      .catch(
        error => get(error, 'graphQLErrors[0].message') || get(error, 'message')
      )
  } else {
    await loadCache()
  }
}

export { persistor as default, loadCache, purgeCache, checkCacheClearStatus }
