import styled from 'styled-components'

import Icon from 'components/UIKit/Icon'

const StyledIcon = styled(Icon)<{ alignment?: string }>`
  ${props =>
    props.alignment === 'right'
      ? `
        position: absolute;
        right: ${props.theme.spacing.s};
        top: 50%;
        transform: translateY(-50%);
      `
      : `margin-right: ${props.theme.spacing.s};`};
`

export default StyledIcon
