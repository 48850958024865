import * as THREE from 'three'

import store from 'store'

import { SYSTEMS } from 'store/units/constants'
import { Distance } from 'store/units/types'
import { getDistanceUnits } from 'store/units/selectors'

class Units {
  /*
    If the contents of the text input are a valid feet and inches string, the
    usual border is displayed; if not, the text input is given a red border
  */
  static validateTextInput(textInput) {
    if (!textInput) return
    const distance = Units.fromDistanceString(textInput.value)

    if (distance === null) {
      textInput.style.border = '1px solid red'
    } else {
      textInput.style.border = '1px solid cornflowerblue'
    }
  }

  // Converts text input value to decimal
  static textInputToDecimal(value) {
    if (value.includes("'") || value.includes('"')) {
      const distance = Units.fromDistanceString(value)
      // Do nothing if we're given an invalid string
      if (distance === null) {
        return
      }
      return distance.native()
    } else if (isNaN(value)) {
      return false
    }

    return value
  }

  /*
    Input should be in native
  */
  // TODO: this is a selector
  static toDistanceString(
    value,
    formatterOptions = { round: true, roundCentimeters: true }
  ) {
    const distance = new Distance({
      value,
      system: SYSTEMS.NATIVE,
    })
    const distanceUnits = getDistanceUnits(store.getState())
    return distance.formattedValue(distanceUnits, formatterOptions)
  }

  static fromDistanceString(distanceString) {
    const distanceUnits = getDistanceUnits(store.getState())
    return new Distance({
      value: Distance.unformat({
        value: distanceString,
        system: distanceUnits,
      }),
      system: distanceUnits,
    })
  }

  /*
    Given a string holding the name of a measurement unit which we recognize,
    this will return the given length converted to native units.
  */
  static unitsToNative(unitsString, length) {
    const lcUnitsString = unitsString.toLowerCase()
    let nativeValue

    switch (lcUnitsString) {
      case 'feet':
        nativeValue = Units.feetToNative(length)
        break
      case 'inches':
        nativeValue = Units.inchesToNative(length)
        break
      case 'millimeters':
        nativeValue = Units.millimetersToNative(length)
        break
      case 'centimeters':
        nativeValue = Units.centimetersToNative(length)
        break
      case 'meters':
        nativeValue = Units.metersToNative(length)
        break
      default:
        throw new Error(`Unrecognized units: ${unitsString}`)
    }

    return nativeValue
  }

  static nativeToUnits(unitsString, length) {
    const lcUnitsString = unitsString.toLowerCase()
    let unitsValue

    switch (lcUnitsString) {
      case 'feet':
        unitsValue = Units.nativeToFeet(length)
        break
      case 'inches':
        unitsValue = Units.nativeToInches(length)
        break
      case 'millimeters':
        unitsValue = Units.nativeToMillimeters(length)
        break
      case 'centimeters':
        unitsValue = Units.nativeToCentimeters(length)
        break
      case 'meters':
        unitsValue = Units.nativeToMeters(length)
        break
      default:
        throw new Error(`Unrecognized units: ${unitsString}`)
    }

    return unitsValue
  }

  static isDigit(character) {
    return Number.isInteger(Number(character))
  }

  static millimetersToNative(millimeters) {
    return Units.metersToNative(millimeters / 1000)
  }

  static nativeToMillimeters(native) {
    return Units.nativeToMeters(native) * 1000
  }

  static centimetersToNative(centimeters) {
    return Units.metersToNative(centimeters / 100)
  }

  static nativeToCentimeters(native) {
    return Units.nativeToMeters(native) * 100
  }

  static metersToNative(meters) {
    const feet = meters / 0.3048
    return Units.feetToNative(feet)
  }

  static nativeToMeters(native) {
    const feet = Units.nativeToFeet(native)
    return feet * 0.3048
  }

  static inchesToNative(inches) {
    const feet = inches / 12
    return Units.feetToNative(feet)
  }

  static nativeToInches(native) {
    const feet = Units.nativeToFeet(native)
    return feet * 12
  }

  static nativeToFeet(native) {
    return native / Units.feetToNativeConversionFactor
  }

  static feetToNative(feet) {
    return feet * Units.feetToNativeConversionFactor
  }

  static feetToInches(feet) {
    return feet * 12
  }

  static inchesToFeet(inches) {
    return inches / 12
  }

  static unitsToNativeV(unitsString, vector3) {
    const vector3Clone = new THREE.Vector3(vector3.x, vector3.y, vector3.z)
    vector3Clone.x = Units.unitsToNative(unitsString, vector3Clone.x)
    vector3Clone.y = Units.unitsToNative(unitsString, vector3Clone.y)
    vector3Clone.z = Units.unitsToNative(unitsString, vector3Clone.z)
    return vector3Clone
  }

  static nativeToUnitsV(unitsString, vector3) {
    const vector3Clone = new THREE.Vector3(vector3.x, vector3.y, vector3.z)
    vector3Clone.x = Units.nativeToUnits(unitsString, vector3Clone.x)
    vector3Clone.y = Units.nativeToUnits(unitsString, vector3Clone.y)
    vector3Clone.z = Units.nativeToUnits(unitsString, vector3Clone.z)
    return vector3Clone
  }
}

// We convert all other units to feet before converting to native so that
// this factor is used in as few locations as possible (nativeToFeet and feetToNative).
// There is no precisely correct value for this, we have to adjust it manually
// so that things look right with the current camera configuration. It also gives
// us control over what kinds of numbers we deal with in the program. For instance,
// it could be set to something that looks normal on the screen, but whenever we
// are debugging we might see a 50 meter wall as 0.00035257429 or something like that,
// so we can adjust this to keep our values in a convenient range.
Units.feetToNativeConversionFactor = 2

export default Units
