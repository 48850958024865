import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Content = styled.div`
  margin-top: ${theme('spacing.l')};
  text-align: center;
`

export default Content
