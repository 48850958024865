import theme from 'config/theme'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Skeleton = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor ?? theme.colors.light.subdued};
  flex: ${({ flex }) => flex ?? '1 1 auto'};
  min-width: ${({ minWidth }) => minWidth ?? '300px'};
  animation: ${pulse} 750ms cubic-bezier(0.5, 0, 0.5, 1) infinite alternate;
`

Skeleton.propTypes = {
  backgroundColor: PropTypes.string,
  flex: PropTypes.string,
  width: PropTypes.string,
}

export { Skeleton }
