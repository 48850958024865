import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Item = styled.div`
  align-items: center;
  border-color: ${({ active }) => theme(`colors.dark.${active ? 'fg' : 'bg'}`)};
  border-bottom-style: solid;
  border-bottom-width: 3px;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 60px;
`

export default Item
