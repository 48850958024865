import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import A from 'components/UIKit/A'

const ImageContainer = styled(A)`
  background-color: ${theme('colors.light.subdued')};
  border: 0;
  display: block;
  height: ${props => `${props.height}px`};
  overflow: hidden;
  position: relative;
`

export default ImageContainer
