import styled from 'styled-components'

import { lighten } from 'lib/styleUtils'

import ToolbarItem from './ToolbarItem'

const ClickableToolbarItem = styled(ToolbarItem)`
  transition: background-color 0.3s ease-in-out;

  &:active,
  &:hover,
  &:focus {
    background-color: ${props =>
      props.variant === 'dark'
        ? lighten(props.theme.colors.dark.bg, 0.2)
        : lighten(props.theme.colors.light.subdued, 0.2)};
  }
`

export default /** @type any */(ClickableToolbarItem)
