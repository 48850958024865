import ShapeTool from './shapeTool'
import Obstruction from './obstruction'
import Util from './util'
import Units from './units'
import store from 'store'
import { addObstruction } from 'store/objects'
import { isOrthoModeEnabled } from 'store/tools/selectors'
import { setStatus } from 'store/status'

import * as THREE from 'three'

class CylinderTool extends ShapeTool {
  constructor() {
    super()
    this.name = 'CYLINDER_TOOL'
    this.createVisual()
  }

  createVisual() {
    this.obj3d = new THREE.Object3D()
  }

  updateVisual(startPos, endPos) {
    if (this.obstruction) {
      this.obstruction.hideLengthLabel()
      this.obj3d.remove(this.obstruction.obj3d)
    }

    // We are no longer drawing so return
    if (this.shapeCancelled) return

    const diff = endPos.clone().sub(startPos)

    if (isOrthoModeEnabled() || this.shiftModifier) {
      if (Math.abs(diff.x) > Math.abs(diff.y)) {
        diff.y = Math.abs(diff.x) * Math.sign(diff.y)
      } else {
        diff.x = Math.abs(diff.y) * Math.sign(diff.x)
      }
    }

    const positions = this.getPositions(
      new THREE.Vector3().copy(startPos),
      new THREE.Vector3().copy(startPos).add(diff)
    )
    const model = Obstruction.createModel(positions)
    this.obstruction = new Obstruction(model)
    this.obj3d.add(this.obstruction.obj3d)

    this.obstruction.showLengthLabel()
  }

  getPositions(startPos, endPos) {
    const width = endPos.x - startPos.x
    const height = (endPos.y - startPos.y) * -1
    const sampleCount = 27

    const points = []

    for (let i = 0; i < sampleCount - 1; i += 1) {
      const t = ((Math.PI * 2) / (sampleCount - 1)) * i
      const x = (width / 2) * Math.cos(t)
      const y = (height / 2) * Math.sin(t)
      points.push([x, y, 0])
    }
    points.push(points[0].slice())

    points.forEach(point => {
      point[0] += startPos.x + width / 2
      point[1] += startPos.y - height / 2
    })

    return points.map(p => ({ x: p[0], y: p[1], z: p[2] }))
  }

  deactivate() {
    if (this.obstruction) this.obstruction.hideLengthLabel()

    this.removeEventListeners()
  }

  finishShapeDescription(multiSelect) {
    super.finishShapeDescription()

    if (!this.obstruction) return

    this.obstruction.hideLengthLabel()
    this.obj3d.remove(this.obstruction.obj3d)

    const positions = this.obstruction.toModel().positions.map(position => ({
      x: Units.inchesToNative(position.x),
      y: Units.inchesToNative(position.y),
      z: Units.inchesToNative(position.z),
    }))
    positions.push(this.obstruction.obj3d.position)

    const isInsideFacility = Util.isPositionsOverFacility(positions)
    if (isInsideFacility) {
      if (this.shapeCancelled) {
        this.obstruction = undefined
        return
      }

      store.dispatch(
        addObstruction({ obstruction: this.obstruction.toModel(), multiSelect })
      )
    } else {
      const error = 'Obstructions must be placed inside the facility!'
      store.dispatch(setStatus({ text: error, type: 'error' }))
    }
  }
}

export default CylinderTool
