import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import { PopoverMenu } from 'components/UIKit/Popover'

const Menu = styled(PopoverMenu)`
  background-color: ${theme('colors.light.bg')};
  left: 0;
  padding: 0;
  position: relative;
  top: 0;
  width: 100%;
`

Menu.defaultProps = {
  adjustForTouch: true,
  variant: 'light',
}

export default Menu
