import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const FullImageContainer = styled.div`
  margin-top: ${theme('spacing.l')};

  > img {
    max-width: 100%;
  }
`

export default FullImageContainer
