import styled from 'styled-components'

import { theme } from 'lib/styleUtils'
import { getZIndexOf } from 'lib/utils'

import Flex from 'components/UIKit/Flex'

export const TOOL_BAR_HEIGHT = 60
export const TOOL_BAR_HEIGHT_PX = `${TOOL_BAR_HEIGHT}px`

const Container = styled(Flex)`
  align-items: center;
  background-color: ${theme('colors.dark.bg')};
  box-shadow: ${theme('shadows.menu')};
  height: ${TOOL_BAR_HEIGHT_PX};
  justify-content: center;
  left: 0;
  position: relative;
  top: 0;
  z-index: ${getZIndexOf('modal')};
`

export default Container
