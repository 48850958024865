import { BackgroundImageMesh } from "~/components/DrawingCanvas/BackgroundImage/components/BackgroundImageMesh"
import { BackgroundImageProvider } from "~/components/DrawingCanvas/BackgroundImage/components/BackgroundImageProvider"

export const BackgroundImage = () => {
  return (
    <BackgroundImageProvider>
      <BackgroundImageMesh />
    </BackgroundImageProvider>
  )
}
