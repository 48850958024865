import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import { Route, withRouter } from 'react-router-dom'
import { graphql } from '@apollo/client/react/hoc'
import get from 'lodash-es/get'
import { withProps } from 'recompact'

import { isTouchUI } from 'store/userInterface/selectors'

import { showPanel } from 'store/panel'
import { start2DFit } from 'store/camera'

import routes from 'config/routes'
import { currentUserIsCustomer } from 'lib/currentUserIs'
import { GET_QUOTE_PANEL } from 'store/panel/types'
import { GET_SALESFORCE_USER_ID_QUERY } from 'client/queries'
import withUser from 'client/decorators/withUser'

import Button from 'components/UIKit/Button'
import { ClickablePopoverItem } from 'components/UIKit/Popover'
import {
  ToolbarGroup,
  ToolbarItem,
  ClickableToolbarItem,
} from 'components/UIKit/Toolbar'

import ExportPDFLayoutModal from 'components/Modals/ExportPDFLayoutModal'
import RequestQuoteModal from 'components/Modals/RequestQuoteModal'
import SelectOpportunityModal from 'components/Modals/SelectOpportunityModal'
import UserProfileInfoModal from 'components/Modals/UserProfileInfoModal'
import ReleaseNotesModal from 'components/Modals/ReleaseNotesModal'
import { hasPermission } from 'components/RequiredPermission'
import UserDropdown from 'components/UserDropdown'
import SettingsScreen from 'screens/SettingsScreen/SettingsScreen'
import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

class TopRight extends Component {
  constructor(props) {
    super(props)
    this.touch = {
      active: props.isTouchUI,
      variant: props.isTouchUI ? 'dark' : 'light',
      separator: props.isTouchUI ? null : 'left',
      size: props.isTouchUI ? 'square' : null,
    }
  }

  renderCalculationsDropdown() {
    const {
      salesforceData,
      onGetQuote,
      match,
      hideGetQuote,
      online,
    } = this.props
    const items = []

    if (!hideGetQuote && !salesforceData.loading && online) {
      if (get(salesforceData, 'Salesforce.salesforceUserId')) {
        items.push({
          title: 'Generate Quote',
          onClick: onGetQuote,
        })
      } else {
        items.push({
          title: 'Generate Quote',
          to: routes.salesforceAuthentication,
        })
      }
    }

    items.push({
      title: 'Calculate Productivity',
      to: `${match.url}${routes.modals.savings}`,
    })

    return (
      <ClickablePopoverItem
        items={items}
        renderTrigger={({ isDropdownVisible }) => (
          <ToolbarItem
            space="s"
            separator={this.touch.separator}
            paddingLeft="s"
          >
            <Button
              dropdown={!this.touch.active}
              icon="calculator"
              isDropdownVisible={isDropdownVisible}
              label={this.touch.active ? null : 'Tools'}
              noBorder={!this.touch.active}
              responsive
              size={this.touch.size}
              variant={this.touch.variant}
            />
          </ToolbarItem>
        )}
        right
        variant={this.touch.variant}
        border={this.isTouch}
      />
    )
  }

  renderExportButton() {
    return (
      <ClickableToolbarItem
        paddingLeft="s"
        paddingRight="s"
        separator={this.touch.separator}
        variant={this.touch.variant}
      >
        <Button
          icon="export"
          label={this.touch.active ? null : 'Export'}
          noBorder={!this.touch.active}
          responsive
          size={this.touch.size}
          to={`${this.props.match.url}${routes.modals.exportPDFLayout}`}
          variant={this.touch.variant}
        />
      </ClickableToolbarItem>
    )
  }

  render() {
    const { match, online, facility } = this.props

    return (
      <ToolbarGroup size={this.touch.active ? '30%' : '40%'} alignment="right">
        <ClickableToolbarItem
          paddingLeft="s"
          paddingRight="s"
          separator={this.touch.separator}
        >
          <Button
            label="Review Installer Details"
            icon="clipboard"
            noBorder
            responsive
            to={`${match.url}${routes.modals.installReview}`}
          />
        </ClickableToolbarItem>
        {!currentUserIsCustomer(this.props.user) &&
          this.renderCalculationsDropdown()}
        {online &&
          !currentUserIsCustomer(this.props.user) &&
          this.renderExportButton()}
        <UserDropdown isTouchUI={this.touch.active} />
        <SentryRoute
          path={`${match.url}${routes.modals.selectOpportunity}`}
          render={props => (
            <SelectOpportunityModal parentRoute={match.url} {...props} />
          )}
        />
        <SentryRoute
          path={`${match.url}${routes.modals.releaseNotes}`}
          render={props => (
            <ReleaseNotesModal parentRoute={match.url} {...props} />
          )}
        />
        {!currentUserIsCustomer(this.props.user) && (
          <>
            <SentryRoute
              path={`${match.url}${routes.modals.requestQuote}`}
              render={() => <RequestQuoteModal parentRoute={match.url} />}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.exportPDFLayout}`}
              render={props => (
                <ExportPDFLayoutModal
                  facilityData={facility}
                  parentRoute={match.url}
                  {...props}
                />
              )}
            />
            <SentryRoute
              path={`${match.url}${routes.modals.userProfileInfo}`}
              render={props => (
                <UserProfileInfoModal parentRoute={match.url} {...props} />
              )}
            />
            <SentryRoute
              path={`${routes.settings}`}
              render={props => (
                <SettingsScreen parentRoute={match.url} {...props} />
              )}
            />
          </>
        )}
      </ToolbarGroup>
    )
  }
}

TopRight.propTypes = {
  flags: PropTypes.object,
  facility: PropTypes.object,
  hideGetQuote: PropTypes.bool,
  isTouchUI: PropTypes.bool,
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  onGetQuote: PropTypes.func,
  onUnimplementedFeatureClick: PropTypes.func,
  salesforceData: PropTypes.object,
  online: PropTypes.bool,
  user: PropTypes.object,
}

const mapDispatchToProps = dispatch => ({
  onGetQuote() {
    dispatch(start2DFit())
    dispatch(showPanel({ type: GET_QUOTE_PANEL }))
  },
})

const mapStateToProps = store => ({
  isTouchUI: isTouchUI(store),
})

export default withRouter(
  compose(
    withUser,
    hasPermission({
      name: 'Get Quote',
      withPermissions: true,
    }),
    withProps(props => ({
      hideGetQuote: props.hideGetQuote || !props.permissions.isAllowed,
      loading: props.loading || props.permissions.isLoading,
    })),
    graphql(GET_SALESFORCE_USER_ID_QUERY, {
      name: 'salesforceData',
    }),
    appConnect(mapStateToProps, mapDispatchToProps)
  )(TopRight)
)
