import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${theme('spacing.base')};
  background: ${theme('colors.light.bg')};
  border-top: 1px solid ${theme('colors.light.subdued')};
  position: sticky;
  bottom: 0;

  > *:not(:last-child) {
    margin-right: ${theme('spacing.s')};
  }
`

export default Footer
