import { graphql } from '~/gql'

const CREATE_USER_FAVORITE = graphql(`
  mutation CreateUserFavorite($facilityId: ID!) {
    createUserFavorite(facilityId: $facilityId) {
      id
    }
  }
`)

export default CREATE_USER_FAVORITE
