import { graphql } from '~/gql'

const UPLOAD_SNAPSHOT_MUTATION = graphql(`
  mutation uploadSnapshot($title: String!, $versionId: ID!, $dataUrl: String!) {
    uploadSnapshot(title: $title, versionId: $versionId, dataUrl: $dataUrl) {
      ...SnapshotFragment
    }
  }
`)

export default UPLOAD_SNAPSHOT_MUTATION
