import { TYPES } from '../constants'

import { convertDistance } from './distance'
import { convertTemperature } from './temperature'
import { convertVelocity } from './velocity'
import { round } from '../utils'

function convertUnits({ value, type, system }, toSystem, options = {}) {
  if (system === toSystem) {
    return passthroughConverter(value, options)
  }
  switch (type) {
    case TYPES.DISTANCE:
      return convertDistance({ value, type, system }, toSystem, options)
    case TYPES.TEMPERATURE:
      return convertTemperature({ value, type, system }, toSystem, options)
    case TYPES.VELOCITY:
      return convertVelocity({ value, type, system }, toSystem, options)
    default:
      return passthroughConverter(value, options)
  }
}

function passthroughConverter(value, options = {}) {
  return round(value, options.round)
}

export default convertUnits
