import PropTypes from 'prop-types'

import Container from './styled/Container'

const GridItem = Container

GridItem.propTypes = {
  hCenter: PropTypes.bool,
  size: PropTypes.string,
  vCenter: PropTypes.bool,
}

export default GridItem
