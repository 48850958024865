import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import PropTypes from 'prop-types'
import { graphql } from '~/gql'
import { omit, throttle } from 'lodash-es'
import InlineSearch from 'components/InlineSearch'
import Modal from 'components/UIKit/Modal'
import Button from 'components/UIKit/Button'
import routes from 'config/routes'

const userListQuery = graphql(`
  query users($filterByText: String!) {
    users(filterByText: $filterByText) {
      users {
        name
        email
        id
      }
      count
    }
  }
`)

const reassignFacilityMutation = graphql(`
  mutation UpdateFacilityReassign($id: ID!, $author: UserInput) {
    updateFacility(id: $id, author: $author) {
      id
      author {
        id
        email
        name
        phone
      }
    }
  }
`)

// TODO: Come up with a better name
const UserSearchModal = ({
  parentRoute = routes.facilities,
  title = 'Reassign Facility',
}) => {
  const [queryString, setQueryString] = useState('')
  const [reassignMutation, reassignStatus] = useMutation(
    reassignFacilityMutation
  )
  const [user, selectUser] = useState()
  const [buttonLabel, setButtonLabel] = useState('Submit')
  // fancy new hooks from react router dom
  const { id: facilityId } = useParams()
  const history = useHistory()

  useEffect(() => {
    const { data, loading, error } = reassignStatus
    if (loading) {
      setButtonLabel('Loading...')
    }

    if (error) {
      setButtonLabel('Error')
    }

    if (data) {
      setButtonLabel('Success')
    }
  }, [reassignStatus])

  return (
    <Modal
      title={title}
      scrollable
      onClose={() => {
        history.push(parentRoute)
      }}
    >
      <div style={{ margin: 5, padding: 5 }}>
        <InlineSearch
          query={queryString}
          onChange={throttle(event => setQueryString(event.target.value), 500)}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>Name</div>
        <div style={{ flex: 1 }}>Email</div>
      </div>
      <Query query={userListQuery} variables={{ filterByText: queryString }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <>Loading...</>
          }
          if (error) {
            return (
              <>
                error: <>{JSON.stringify(error, null, 4)}</>
              </>
            )
          }
          if (data) {
            const { users } = data
            return (
              <>
                {users.users.map((u, idx) => (
                  <UserRow
                    onClick={() => {
                      selectUser(u)
                    }}
                    key={idx}
                    name={u.name}
                    email={u.email}
                    selected={
                      user && u.name === user.name && u.email === user.email
                    }
                  />
                ))}
                <div>
                  {user && (
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      Assign Facility with id {facilityId} to user {user.name}
                    </div>
                  )}
                  <Button
                    label={buttonLabel}
                    disabled={!user}
                    danger={buttonLabel === 'Error'}
                    iconRight={buttonLabel === 'Success' && 'checkCircle'}
                    iconRightColor={buttonLabel === 'Success' && 'green'}
                    onClick={async () => {
                      await reassignMutation({
                        variables: {
                          id: facilityId,
                          author: omit(user, '__typename'),
                        },
                      })
                    }}
                  />
                </div>
              </>
            )
          }
        }}
      </Query>
    </Modal>
  )
}

const UserRow = ({ name, email, onClick, selected = false }) => {
  return (
    <div
      style={{
        display: 'flex',
        borderRadius: 5,
        marginTop: 3,
        marginBottom: 3,
        backgroundColor: selected ? 'rgba(0, 0, 0, 0.05)' : 'inherit',
        border: '2px solid rgba(0, 0, 0, 0.05)',
      }}
      onClick={onClick}
    >
      <div style={{ flex: 1, padding: 5 }}>{name}</div>
      <div style={{ flex: 1, padding: 5 }}>{email}</div>
    </div>
  )
}

UserRow.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}

UserSearchModal.propTypes = {
  title: PropTypes.string,
  parentRoute: PropTypes.string,
}

export default UserSearchModal
