import forEach from 'lodash-es/forEach'
import cleanProduct from './cleanProduct'

export default function cleanProducts(_products) {
  // TODO: This just shouldn't be in state >_<
  // Spread ftl
  const products = {}
  forEach(_products, (product, productId) => {
    products[productId] = cleanProduct(product)
  })
  return products
}
