import icons from 'config/icons'

import Container from './styled/Container'
import { Spacing, StandardColor } from '~/config/theme'

const Icon = ({
  name,
  color,
  height = '20',
  size = '20',
  width = '20',
  ...props
}: {
  name: string | keyof typeof icons
  color?: StandardColor | string
  height?: string | number
  size?: string | number
  width?: string | number
  spin?: boolean
  marginRight?: Spacing
  marginLeft?: Spacing
  marginBottom?: Spacing
}) => {
  const newWidth = size || width
  const newHeight = size || height
  const icon = icons[name as keyof typeof icons]

  return (
    <Container
      color={color}
      width={newWidth}
      height={newHeight}
      viewBox="0 0 20 20"
      {...props}
    >
      {Array.isArray(icon) ? (
        icon.map((d, i) => <path key={i} d={d} />)
      ) : (
        <path d={icon} />
      )}
    </Container>
  )
}

export default Icon
