import { graphql } from '~/gql'

const GET_USER_CACHE_FLAG_QUERY = graphql(`
  query GetUserCacheFlag {
    currentUser {
      id
      requiresCacheClear
    }
  }
`)

export default GET_USER_CACHE_FLAG_QUERY
