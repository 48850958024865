import PropTypes from 'prop-types'

import Container from './styled/Container'

const Row = Container

Row.propTypes = {
  bordered: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default /** @type any */(Row)
