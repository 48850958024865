import groupBy from 'lodash-es/groupBy'
import uniq from 'lodash-es/uniq'

export const getGroupedProductVariations = productVariations => {
  if (!productVariations) {
    return {}
  }
  const variations = Object.keys(productVariations).map(
    id => productVariations[id]
  )
  const groupedVariations = groupBy(
    variations,
    v => `${v.size}-${v.tubeLength}-${v.voltage}`
  )
  return groupedVariations
}

export const extractBundles = productVariations => {
  const bundles = {}
  const groupedVariations = getGroupedProductVariations(productVariations)

  Object.keys(groupedVariations).forEach(groupId => {
    const variations = groupedVariations[groupId]
    const bundleNames = uniq(variations.map(v => v.quoteBundle)).filter(
      name => name !== undefined
    )
    if (bundleNames.length > 0) {
      bundles[groupId] = bundleNames.map(b => ({
        name: b,
      }))
    }
  })

  return bundles
}

export const generateLineItems = (
  productVariation,
  quoteBundle = { lineItems: [] },
  updatedBundle = { lineItems: [] }
) => {
  const lineItems = []

  // voltage + size
  const voltage = productVariation.voltages.find(
    v => v.id === productVariation.voltageId
  )
  // mountingOption
  const mountingOption = voltage.mountingOptions.find(
    o => o.id === productVariation.mountingOptionId
  )

  if (
    !(
      productVariation.product.model === 'Haiku' &&
      mountingOption.tubeLength <= 32
    )
  ) {
    const voltageLI =
      quoteBundle.lineItems.find(li => li.priceId === voltage.price.id) || {}
    const updatedVoltageLI =
      updatedBundle.lineItems.find(li => li.priceId === voltage.price.id) || {}
    lineItems.push({
      priceId: voltage.price.id,
      salesPrice:
        updatedVoltageLI.salesPrice ||
        voltageLI.salesPrice ||
        voltage.price.price,
      quantity: productVariation.quantity,
    })
  }

  if (mountingOption) {
    const mountingOptionLI =
      quoteBundle.lineItems.find(
        li => li.priceId === mountingOption.price.id
      ) || {}
    const updatedMountingOptionLI =
      updatedBundle.lineItems.find(
        li => li.priceId === mountingOption.price.id
      ) || {}
    lineItems.push({
      priceId: mountingOption.price.id,
      salesPrice:
        updatedMountingOptionLI.salesPrice ||
        mountingOptionLI.salesPrice ||
        mountingOption.price.price,
      quantity: productVariation.quantity,
    })
  }

  // mountingOptionAdder
  const mountingOptionAdder = productVariation.mountingOptionAdders.find(
    o => o.id === productVariation.mountingOptionAdderId
  )
  if (mountingOptionAdder) {
    // NOTE: this is optional
    const mountingOptionAdderLI =
      quoteBundle.lineItems.find(
        li => li.priceId === mountingOptionAdder.price.id
      ) || {}
    const updatedMountingOptionAdderLI =
      updatedBundle.lineItems.find(
        li => li.priceId === mountingOptionAdder.price.id
      ) || {}
    lineItems.push({
      priceId: mountingOptionAdder.price.id,
      salesPrice:
        updatedMountingOptionAdderLI.salesPrice ||
        mountingOptionAdderLI.salesPrice ||
        mountingOptionAdder.price.price,
      quantity: productVariation.quantity,
    })
  }

  return lineItems
}

export const generateQuoteBundles = (
  productVariations,
  quoteBundles = [],
  updatedBundles = []
) => {
  const bundles = []

  const includedVariations = Object.keys(productVariations)
    .map(k => productVariations[k])
    .filter(v => v.includedInQuote)
    .filter(v => 'quoteBundle' in v && v.quoteBundle !== '')

  const groupedVariations = getGroupedProductVariations(includedVariations)

  Object.keys(groupedVariations).forEach(groupId => {
    const variations = groupedVariations[groupId]
    const bundledVariations = groupBy(variations, v => v.quoteBundle)

    const mergedVariations = Object.keys(bundledVariations).map(bundleName => {
      const bundledProducts = bundledVariations[bundleName]

      return {
        ...bundledProducts[0],
        quantity: bundledProducts.length,
      }
    })

    mergedVariations.forEach(v => {
      const bundle = {
        name: v.quoteBundle,
        lineItems: generateLineItems(
          v,
          quoteBundles.find(b => v.quoteBundle === b.name),
          updatedBundles.find(b => v.quoteBundle === b.name)
        ),
      }
      bundles.push(bundle)
    })
  })

  return bundles
}
