import styled, { css } from 'styled-components'

import { theme } from 'lib/styleUtils'

import Card from './Card'

const setPosition = (props: { position?: string }) => {
  if (props.position === 'bottom') {
    return css`
      left: 0;
      top: 100%;
    `
  }
  return css`
    left: 100%;
    top: 0;
  `
}

const setWidth = (props: { position?: string }) => {
  if (props.position === 'bottom') {
    return css`
      min-width: 100%;
    `
  }
  return css`
    min-width: 250px;
  `
}

const SubMenu = styled(Card)<{ position?: string }>`
  box-shadow: ${theme('shadows.subMenu')};
  position: absolute;

  ${setWidth}
  ${setPosition}
`

export default SubMenu
