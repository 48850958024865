import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import get from 'lodash-es/get'

import ApolloClient from 'client'
import { SNAPSHOT_FRAGMENT } from 'client/fragments'
import {
  GET_SNAPSHOT_QUERY,
  GET_SNAPSHOTS_QUERY,
  GET_FACILITY_QUERY,
} from 'client/queries'
import {
  DELETE_SNAPSHOT_MUTATION,
  UPDATE_SNAPSHOT_MUTATION,
} from 'client/mutations'

export default compose(
  withRouter,
  graphql(GET_SNAPSHOT_QUERY, {
    options: ({ match }) => ({
      variables: {
        snapshotId: match.params.snapshotId,
      },
    }),
    props: ({ data, ownProps }) => {
      let snapshot = data.getSnapshot
      if (!snapshot) {
        const snapshotId = get(ownProps, 'match.params.snapshotId')
        snapshot = ApolloClient.readFragment({
          id: `Snapshot:${snapshotId}`,
          fragment: SNAPSHOT_FRAGMENT,
        })
      }

      return {
        loading: get(data, 'loading') || get(ownProps, 'loading'),
        snapshot,
      }
    },
  }),
  graphql(DELETE_SNAPSHOT_MUTATION, {
    props: ({ mutate }) => ({
      onSnapshotDelete: ({ snapshotId }) =>
        mutate({ variables: { snapshotId } }),
    }),
    options: props => ({
      refetchQueries: [
        {
          query: GET_FACILITY_QUERY,
          variables: {
            id: props.facilityId,
            floorId: null,
            versionId: null,
          },
          name: 'facilityData',
        },
        {
          query: GET_SNAPSHOTS_QUERY,
          variables: {
            versionId: props.versionId,
          },
        },
      ],
    }),
  }),
  graphql(UPDATE_SNAPSHOT_MUTATION, {
    props: ({ mutate }) => ({
      onSave: variables => mutate({ variables }),
    }),
    options: ({ match }) => ({
      refetchQueries: [
        {
          query: GET_SNAPSHOT_QUERY,
          variables: {
            snapshotId: match.params.snapshotId,
          },
        },
      ],
    }),
  })
)
