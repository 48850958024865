import styled from 'styled-components'

const Media = styled.div<{ flushMedia?: boolean }>`
  flex-shrink: 0;

  ${props => !props.flushMedia && `margin-right: ${props.theme.spacing.base};`};

  > img {
    display: block;
  }
`

export default Media
