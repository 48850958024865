import { GOOGLE_MAPS_BASE_URL, GOOGLE_MAPS_QUERY } from 'config/geolocator'

class Geolocator {
  navigator: Navigator

  constructor() {
    if (this.isGeolocationAvailable()) {
      this.navigator = navigator
    } else {
      throw new Error('Your browser does not support geolocation.')
    }
  }

  isGeolocationAvailable = () => 'geolocation' in navigator

  getGoogleMapsUrl = (coords: GeolocationCoordinates) => {
    const latlon = `${coords.latitude},${coords.longitude}`

    return `${GOOGLE_MAPS_BASE_URL}${latlon}${GOOGLE_MAPS_QUERY}`
  }

  getCurrentPosition = () =>
    new Promise<GeolocationCoordinates>((resolve, reject) => {
      this.navigator.geolocation.getCurrentPosition(
        position => {
          resolve(position.coords)
        },
        () => reject('We could not get your location.')
      )
    })

  getAddressName = (coords: GeolocationCoordinates): Promise<string> =>
    fetch(this.getGoogleMapsUrl(coords))
      .then(response => response.json())
      .then(data => data.results[0].formatted_address)
      .catch(error => error)

  getLocation = async () => {
    return this.getAddressName(await this.getCurrentPosition())
  }
}

const geolocator = new Geolocator()

export default geolocator
