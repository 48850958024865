import { createAction } from "@reduxjs/toolkit"
import LayerKeys from "~/config/layerKeys"

export const SET_CURRENT_LAYER = 'SET_CURRENT_LAYER'
export const SET_FOCUSED_LAYER = 'SET_FOCUSED_LAYER'
export const TOGGLE_LAYER_VISIBILITY = 'TOGGLE_LAYER_VISIBILITY'
export const TOGGLE_LAYER_LOCKED = 'TOGGLE_LAYER_LOCKED'
export const TOGGLE_LAYER_EXPANDED = 'TOGGLE_LAYER_EXPANDED'
export const SET_VISIBLE_LAYERS = 'SET_VISIBLE_LAYERS'
export const LOCK_ALL_LAYERS = 'LOCK_ALL_LAYERS'
export const RESTORE_LAYERS = 'RESTORE_LAYERS'

export const setCurrentLayer = createAction<{ layerKey: LayerKeys }>(SET_CURRENT_LAYER)
export const setFocusedLayer = createAction<{ layerKey: LayerKeys }>(SET_FOCUSED_LAYER)
export const toggleLayerVisibility = createAction<{ layerKey: LayerKeys }>(TOGGLE_LAYER_VISIBILITY)
export const toggleLayerLocked = createAction<{ layerKey: LayerKeys }>(TOGGLE_LAYER_LOCKED)
export const toggleLayerExpanded = createAction<{ layerKey: LayerKeys }>(TOGGLE_LAYER_EXPANDED)
export const setVisibleLayers = createAction<{ visibleLayerIds: LayerKeys[] }>(SET_VISIBLE_LAYERS)
export const lockAllLayers = createAction<{}>(LOCK_ALL_LAYERS)
export const restoreLayers = createAction<{}>(RESTORE_LAYERS)
