import styled, { ThemedStyledProps, css } from 'styled-components'

import Card from '../../Card'
import { getZIndexOf } from 'lib/utils'
import { theme } from 'lib/styleUtils'
import { Theme } from '~/config/theme'

const height = ({ maxHeight, theme }: ThemedStyledProps<{ maxHeight?: string }, Theme>) => {
  if (maxHeight) {
    return css`
      max-height: ${maxHeight};
    `
  }

  return css`
    max-height: calc(100vh - ${theme.spacing.xl});
  `
}

const StyledCard = styled(Card)<{ size?: string; noMargin?: boolean; centered?: boolean; maxHeight?: string }>`
  backface-visibility: hidden;
  box-shadow: ${theme('shadows.menu')};
  max-width: ${props => (props.size ?? '700px')};
  margin: ${props => (props.noMargin ? '0' : theme('spacing.l'))} auto;
  overflow-y: auto;
  padding: 0;
  position: relative;
  width: auto;
  z-index: ${getZIndexOf('modal')};

  ${height};
  ${props => props.centered && `text-align: center;`};
`

export default StyledCard
