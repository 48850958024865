import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const CoolingValue = styled.div`
  color: ${theme('colors.light.success')};

  &:not(:last-child) {
    margin-bottom: ${theme('spacing.base')};
  }

  > *:first-child {
    margin-right: ${theme('spacing.s')};
  }
`

export default CoolingValue
