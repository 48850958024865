import React, { Component } from 'react'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'
import flatten from 'lodash-es/flatten'
import get from 'lodash-es/get'
import omit from 'lodash-es/omit'
import truncate from 'lodash-es/truncate'

import cloudinary from 'config/cloudinary'
import { formatCurrency } from 'lib/utils'

import { GET_QUOTE_PANEL } from 'store/panel/types'
import { showAlert } from 'store/alert'
import * as Quotes from 'store/quote'
import { GET_QUOTE_QUERY, ALL_DISCOUNT_REASONS_QUERY } from 'client/queries'

import A from 'components/UIKit/A'
import Bucket from 'components/UIKit/Bucket'
import Collapsible from 'components/UIKit/Collapsible'
import Grid, { GridItem } from 'components/UIKit/Grid'
import H1 from 'components/UIKit/H1'
import Icon from 'components/UIKit/Icon'
import Image from 'components/UIKit/Image'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'
import VariantText from 'components/UIKit/VariantText'
import Loader from 'components/UIKit/Loader'
import Select from 'components/UIKit/Select'

import Bundles from '../styled/Bundles'
import Section from '../styled/Section'

import SalesforceResourceLink from '../SalesforceResourceLink'
import SaveQuoteButton from '../SaveQuoteButton'

class DetailsScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dirty: false,
      bundles: get(props, 'quoteData.Version.quote.bundles', []),
      discountReasonId: get(
        props,
        'quoteData.Version.quote.discountReason.id',
        ''
      ),
      discountNotes: get(
        this.props,
        'quoteData.Version.quote.discountNotes',
        ''
      ),
      shippingPrice:
        get(this.props, 'quoteData.Version.quote.shippingPrice') || 0,
    }
  }

  static defaultProps = {
    onShippingPriceChanged() {},
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.quoteData &&
      this.props.quoteData.loading &&
      !nextProps.quoteData.loading &&
      get(nextProps, 'quoteData.Version.quote')
    ) {
      const bundles = get(nextProps, 'quoteData.Version.quote.bundles')

      this.setState({
        bundles,
        discountReasonId: get(
          nextProps,
          'quoteData.Version.quote.discountReason.id'
        ),
        discountNotes: get(nextProps, 'quoteData.Version.quote.discountNotes'),
        shippingPrice: get(nextProps, 'quoteData.Version.quote.shippingPrice'),
      })
    }
  }

  handlePreviousScreen = () => {
    this.props.onPreviousScreen()
  }

  handleInputChange = ({ bundleId, lineItemId, value }) => {
    const bundles = this.state.bundles.map(b => {
      if (b.id !== bundleId) {
        return b
      }

      return {
        ...b,
        lineItems: b.lineItems.map(l => {
          if (l.id !== lineItemId) {
            return l
          }

          return {
            ...l,
            salesPrice: value,
          }
        }),
      }
    })
    this.setState({
      dirty: true,
      bundles,
    })
    if (this.props.onBundlesChanged) {
      this.props.onBundlesChanged(
        bundles.map(b => ({
          ...omit(b, ['__typename', 'id']),
          lineItems: b.lineItems.map(l => ({
            ...omit(l, ['__typename', 'id', 'listPrice', 'meta']),
          })),
        }))
      )
    }
  }

  handleSelectDiscountReason = event => {
    const discountReasonId = event.target.value
    this.setState(
      {
        discountReasonId,
      },
      () => {
        if (this.props.onDiscountReasonIdChanged)
          this.props.onDiscountReasonIdChanged(discountReasonId)
      }
    )
  }

  handleChangeDiscountNotes = event => {
    const discountNotes = event.target.value
    this.setState(
      {
        discountNotes,
      },
      () => {
        if (this.props.onDiscountNotesChanged)
          this.props.onDiscountNotesChanged(discountNotes)
      }
    )
  }

  handleChangeShippingPrice = event => {
    const shippingPrice = event.currentTarget.value
    this.setState(
      {
        shippingPrice,
      },
      () => {
        this.props.onShippingPriceChanged(shippingPrice)
      }
    )
  }

  shouldRenderDiscountReasons = () => {
    const totalSalesPrice = this.getTotalPrice()
    const totalListPrice = this.getTotalListPrice()

    return totalSalesPrice < totalListPrice
  }

  getTotalPrice = (bundles = []) => {
    bundles = bundles.length > 0 ? bundles : this.state.bundles

    const totalPrice = flatten(bundles.map(bundle => bundle.lineItems))
      .map(lineItem => {
        return lineItem.salesPrice * lineItem.quantity
      })
      .reduce((price, total) => {
        return price + total
      }, 0)

    return totalPrice
  }

  getTotalListPrice = (bundles = []) => {
    bundles = bundles.length > 0 ? bundles : this.state.bundles

    const totalPrice = flatten(bundles.map(bundle => bundle.lineItems))
      .map(lineItem => {
        return lineItem.listPrice * lineItem.quantity
      })
      .reduce((price, total) => {
        return price + total
      }, 0)

    return totalPrice
  }

  get totalWithShipping() {
    return this.getTotalPrice() + (parseFloat(this.state.shippingPrice) || 0)
  }

  renderQuoteHeading() {
    const { quoteData } = this.props

    return (
      <PanelSection>
        <Grid vCenter>
          <GridItem size="5of8">
            <H1 flush>{get(quoteData, 'Version.quote.name')}</H1>
            <VariantText size="s">
              <SalesforceResourceLink
                resourceId={get(quoteData, 'Version.quote.salesforceQuoteId')}
              >
                {'View on Salesforce'}
              </SalesforceResourceLink>
            </VariantText>
          </GridItem>
          <GridItem size="3of8">
            <strong>{formatCurrency(this.totalWithShipping)}</strong>
            <VariantText size="xs" color="subdued">
              {get(quoteData, 'Version.quote.currencyISOCode')}
            </VariantText>
          </GridItem>
        </Grid>
      </PanelSection>
    )
  }

  renderProductHeading(bundle, index) {
    const fanKitLI = bundle.lineItems.find(li => li.meta.type === 'fanKit')
    const mountKitLI = bundle.lineItems.find(li => li.meta.type === 'mountKit')
    const cloudinaryId = get(fanKitLI, 'meta.cloudinaryId')
    const model = get(fanKitLI, 'meta.family')
    const size = get(fanKitLI, 'meta.size')
    const voltage = get(fanKitLI, 'meta.voltage')
    const tubeLength = get(mountKitLI, 'meta.size')

    return (
      <PanelSection>
        <Bucket
          media={
            <Image
              src={cloudinary.url(cloudinaryId, {
                width: 100,
                height: 100,
                secure: true,
              })}
              alt="placeholder"
              width="50"
              height="50"
            />
          }
          content={
            <div>
              <h3>{model}</h3>
              <VariantText size="s">
                {size}, {voltage}, {tubeLength} Tube
              </VariantText>
            </div>
          }
        />
      </PanelSection>
    )
  }

  renderBundles() {
    return this.state.bundles.map((bundle, index) => (
      <div key={bundle.name}>
        {this.renderProductHeading(bundle, index)}
        {this.renderBundle(bundle)}
      </div>
    ))
  }

  renderBundle(bundle) {
    const totalPrice = bundle.lineItems
      .map(lineItem => {
        return lineItem.salesPrice * lineItem.quantity
      })
      .reduce((price, total) => {
        return price + total
      }, 0)

    return (
      <Collapsible
        flushBottom
        withCaret
        renderTrigger={({ onTrigger, isVisible }) => (
          <Section>
            <PanelSection onClick={onTrigger}>
              <Grid>
                <GridItem size="1of8">
                  <Icon
                    size="12"
                    name={isVisible ? 'arrowDown' : 'arrowRight'}
                  />
                </GridItem>
                <GridItem size="3of8">
                  <h4>{bundle.name}</h4>
                  <VariantText size="s">
                    <A onClick={onTrigger}>
                      {isVisible ? 'Hide' : 'View'} Details
                    </A>
                  </VariantText>
                </GridItem>
                <GridItem size="4of8">
                  <p style={{ textAlign: 'right' }}>
                    <strong>{formatCurrency(totalPrice)}</strong>
                  </p>
                </GridItem>
              </Grid>
            </PanelSection>
          </Section>
        )}
      >
        {bundle.lineItems.map(l => this.renderLineItem(bundle, l))}
      </Collapsible>
    )
  }

  renderLineItem(bundle, lineItem) {
    return (
      <Section inner key={`${lineItem.priceId}-${lineItem.quantity}`}>
        <PanelSection>
          <Grid vCenter>
            <GridItem size="7of10">
              <Space bottom="xs">
                <VariantText size="s">
                  ({lineItem.quantity}) {lineItem.meta.description}
                </VariantText>
              </Space>
              <VariantText size="xs">
                List Price:{' '}
                <strong>{formatCurrency(lineItem.listPrice)}</strong>
              </VariantText>
            </GridItem>
            <GridItem size="3of10">
              <TextField
                value={lineItem.salesPrice}
                size="s"
                width="60px"
                inline
                onChange={event =>
                  this.handleInputChange({
                    bundleId: bundle.id,
                    lineItemId: lineItem.id,
                    value: event.target.value,
                  })
                }
              />
            </GridItem>
          </Grid>
        </PanelSection>
      </Section>
    )
  }

  renderDiscountReasons() {
    return (
      <PanelSection>
        <Select
          label="Discount Reason"
          size="100%"
          onChange={this.handleSelectDiscountReason}
          disabled={
            get(this.props, 'discountReasonsData.loading') ||
            get(this.props, 'quoteData.loading')
          }
          value={this.state.discountReasonId}
          options={get(
            this.props.discountReasonsData,
            'Salesforce.discountReasons',
            [{ label: 'Loading…', value: null }]
          ).map(o => ({
            label: o.name,
            value: o.id,
          }))}
        />
        <Space bottom="s" />
        {/* TODO: This should probably be a textarea instead */}
        <TextField
          label="Discount Notes"
          value={this.state.discountNotes}
          onChange={this.handleChangeDiscountNotes}
          disabled={get(this.props, 'quoteData.loading')}
        />
      </PanelSection>
    )
  }

  render() {
    const { quoteData, alignment } = this.props

    if (!this.props.quoteData) {
      return null
    }

    return (
      <Panel
        title="Quote Details"
        alignment={alignment}
        docked
        collapsible={false}
        scrollable
        panelKey={GET_QUOTE_PANEL}
        back={<A onClick={this.handlePreviousScreen}>Back to Products</A>}
        footer={
          <SaveQuoteButton
            versionId={get(this.props.facility, 'floor.version.id')}
            onError={err => {
              if (this.props.onShowAlert)
                this.props.onShowAlert(
                  `Salesforce Error: ${truncate(err.message, { length: 16 })}`
                )
            }}
          />
        }
      >
        {quoteData.loading && <Loader centered />}
        {get(quoteData, 'Version.quote') && this.renderQuoteHeading()}
        {get(quoteData, 'Version.quote') && (
          <Bundles>{this.renderBundles()}</Bundles>
        )}
        {!quoteData.loading && (
          <PanelSection>
            <TextField
              inline
              label="Shipping & Handling"
              labelWidth="150px"
              value={this.state.shippingPrice}
              onChange={this.handleChangeShippingPrice}
              disabled={get(this.props, 'quoteData.loading')}
            />
          </PanelSection>
        )}
        {this.shouldRenderDiscountReasons() && this.renderDiscountReasons()}
      </Panel>
    )
  }
}

DetailsScreen.propTypes = {
  alignment: PropTypes.string,
  discountReasonsData: PropTypes.object,
  facility: PropTypes.object,
  onBundlesChanged: PropTypes.func,
  onDiscountNotesChanged: PropTypes.func,
  onDiscountReasonIdChanged: PropTypes.func,
  onPreviousScreen: PropTypes.func,
  onShippingPriceChanged: PropTypes.func,
  onShowAlert: PropTypes.func,
  quoteData: PropTypes.object,
}

const mapDispatchToProps = dispatch => ({
  onShowAlert(text) {
    dispatch(
      showAlert({
        text,
        type: 'error',
        autoClose: true,
      })
    )
  },
  onBundlesChanged(bundles) {
    dispatch(Quotes.updateBundles(bundles))
  },
  onDiscountReasonIdChanged(id) {
    dispatch(Quotes.updateDiscountReasonId(id))
  },
  onDiscountNotesChanged(notes) {
    dispatch(Quotes.updateDiscountNotes(notes))
  },
  onShippingPriceChanged(price) {
    dispatch(Quotes.updateShippingPrice(price))
  },
})

export default compose(
  appConnect(null, mapDispatchToProps),
  graphql(GET_QUOTE_QUERY, {
    options: ({ facility }) => ({
      variables: {
        id: get(facility, 'floor.version.id'),
      },
    }),
    name: 'quoteData',
  }),
  graphql(ALL_DISCOUNT_REASONS_QUERY, {
    name: 'discountReasonsData',
  })
)(DetailsScreen)
