import React, { Component } from 'react'
import { func, object, string, bool } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import get from 'lodash-es/get'

import { withUnits } from 'store/units/decorators'
import { SYSTEMS } from 'store/units/constants'
import { Distance } from 'store/units/types'
import { updateCeiling } from 'store/objects'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { isTouchUI } from 'store/userInterface/selectors'
import { SELECTED_CEILING_PANEL } from 'store/panel/types'
import CLASS_NAMES from 'config/objectClassNames'
import store from 'store'
import LAYER_KEYS from 'config/layerKeys'

import Panel, { PanelSection } from 'components/UIKit/Panel'
import Switch from 'components/UIKit/Switch'
import Space from 'components/UIKit/Space'
import DimensionInput from 'components/UIKit/DimensionInput'

import Facility from 'components/DrawingCanvas/lib/facility'
import Units from 'components/DrawingCanvas/lib/units'

import MetadataSection from 'components/MetadataSection'
import { SwatchWell } from '~/ui/Color'
import { ColorPicker } from 'react-aria-components'
import { Label } from '~/ui/Field'
import theme from '~/config/theme'

class SelectedCeilingPanel extends Component {
  handleInputChange = (key, value) => {
    const { selectedObject, onUpdateCeiling } = this.props
    const wall = Facility.current
      .getWalls()
      .find(wall => wall.id === selectedObject.wallId)

    let height = 8 * 12 // 8ft in inches as default
    if (wall) {
      const sortedSegmentHeights = get(wall, 'segments', [])
        .map(seg => seg.height)
        .sort()
      height = Units.nativeToUnits(wall.units, sortedSegmentHeights[0])
    }

    onUpdateCeiling({
      ...selectedObject,
      height,
      [key]: value,
    })
  }

  renderContent() {
    const {
      selectedObject,
      onUpdateCeiling,
      distanceUnits,
      isTouchUI,
    } = this.props
    const inputWidth = isTouchUI ? '140px' : '70px'

    const isLocked = store.getState().layers.layers[LAYER_KEYS.CEILINGS].locked

    return (
      <>
        <PanelSection>
          <Space bottom="base">
            <Switch
              name="ceiling-enabled"
              disabled={isLocked ? true : false}
              label="Enabled"
              onClick={event =>
                this.handleInputChange('enabled', !selectedObject.enabled)
              }
              isChecked={selectedObject.enabled}
              responsive
            />
          </Space>
          <Space bottom="base">
            <ColorPicker value={selectedObject.color} onChange={color => this.handleInputChange('color', color.toString("hex"))}>
              <Label>Color</Label>
              <SwatchWell swatches={theme.colors.swatches} isDisabled={isLocked}/>
            </ColorPicker>
          </Space>
          {selectedObject.enabled && (
            <Space bottom="base">
              <DimensionInput
                label="Height"
                labelWidth="60px"
                width={inputWidth}
                disabled={isLocked ? true : false}
                name={`ceiling-height`}
                distance={
                  new Distance({
                    value: selectedObject.height,
                    system: SYSTEMS.IMPERIAL,
                  })
                }
                units={distanceUnits}
                onChange={({ distance }) => {
                  const value = distance.imperial() || 0
                  this.handleInputChange('height', value)
                }}
              />
            </Space>
          )}
        </PanelSection>
        <MetadataSection
          object={selectedObject}
          onBlur={onUpdateCeiling}
          disabled={isLocked ? true : false}
        />
      </>
    )
  }

  render() {
    const { selectedObject, alignment, isTouchUI } = this.props

    return (
      <Panel
        title="Ceiling"
        alignment={alignment}
        docked
        panelKey={SELECTED_CEILING_PANEL}
        hasToolbar={isTouchUI}
      >
        {selectedObject ? this.renderContent() : null}
      </Panel>
    )
  }
}

SelectedCeilingPanel.propTypes = {
  alignment: string,
  selectedObject: object,
  onUpdateCeiling: func,
  distanceUnits: string,
  isTouchUI: bool,
}

SelectedCeilingPanel.defaultProps = {
  alignment: 'right',
}

const mapStateToProps = ({ objects, selectedObjects, ...store }) => {
  const selectedObject = mostRecentSelectedObjectOfClassName(
    CLASS_NAMES.CEILING,
    {
      selectedObjects,
      objects,
    }
  )
  return {
    layers: store.layers,
    isTouchUI: isTouchUI(store),
    selectedObject,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateCeiling(ceiling) {
    dispatch(updateCeiling({ ceiling }))
  },
})

export default compose(
  withUnits,
  appConnect(mapStateToProps, mapDispatchToProps)
)(SelectedCeilingPanel)
