import { graphql } from '~/gql'

const UPDATE_FACILITY_MUTATION = graphql(`
  mutation UpdateFacility(
    $id: ID!
    $name: String!
    $primaryObjective: [PrimaryObjective]!
    $primaryUse: PrimaryUse!
    $primaryType: PrimaryType
    $ashraeCompliant: Boolean
    $airConditioned: Boolean
    $location: String
    $indoorSummerTemp: Int
    $indoorWinterTemp: Int
    $indoorHumidity: Float
    $indoorWinterHumidity: Float
    $opportunityId: ID
    $units: MeasurementSystem
  ) {
    updateFacility(
      id: $id
      name: $name
      primaryObjective: $primaryObjective
      primaryUse: $primaryUse
      primaryType: $primaryType
      ashraeCompliant: $ashraeCompliant
      airConditioned: $airConditioned
      location: $location
      indoorSummerTemp: $indoorSummerTemp
      indoorWinterTemp: $indoorWinterTemp
      indoorHumidity: $indoorHumidity
      indoorWinterHumidity: $indoorWinterHumidity
      opportunityId: $opportunityId
      units: $units
    ) {
      ...FacilityFragment
    }
  }
`)

export default UPDATE_FACILITY_MUTATION
