import { Html } from '@react-three/drei'
import { Vector3 } from 'three'
import { DimensionInputProps, InnerDimensionInput } from './DimensionInput';
import { useDistanceDimensions } from '~/hooks/useDistanceDimensions';

export function R3FDimensionInput({ position, center, ...props }: Omit<DimensionInputProps, 'distanceDimensions' | 'isOnCanvas'> & { position?: Vector3; center?: boolean }) {
  const distanceDimensions = useDistanceDimensions()
  return (
    <Html position={position} center={center} zIndexRange={[0,0]}>
      <InnerDimensionInput {...props} isOnCanvas distanceDimensions={distanceDimensions}/>
    </Html>
  )
}
