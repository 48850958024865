import { ColorRepresentation, Vector2, Vector2Like, Vector3, Vector3Like, Vector3Tuple } from "three"
import Util from "../lib/util"
import Units from "../lib/units"
import { sortBy } from "lodash-es"
import { createContext, useContext } from "react"

export type MountingStructureLineProps = {
  model: [Vector3, number]
  next: [Vector3, number] | undefined
  prev: [Vector3, number] | undefined
  name: string
  color: ColorRepresentation
  orientation: Vector3
  type: 'beam' | 'column'
  roofSectionId: string
  z: number
  perimeterPoints: Vector3Tuple[]
  selected: boolean
  setSelected: React.Dispatch<React.SetStateAction<[string, number] | null>>
}

export const MountingStructureData = createContext<MountingStructureLineProps | null>(null)
export function useMountingStructureData() {
  const data = useContext(MountingStructureData)
  if (data === null) {
    throw new Error("useMountingStructureData must be used within a MountingStructureData.Provider")
  }
  return data
}

export const RoofSectionOrientation = createContext<'horizontal' | 'vertical' | null>(null)
export function useRoofSectionOrientation() {
  const data = useContext(RoofSectionOrientation)
  if (data === null) {
    throw new Error("useRoofSectionOrientation must be used within a RoofSectionOrientation.Provider")
  }
  return data
}

const Z_AXIS = new Vector3(0, 0, 1)
export function getOrthoOrientation(beamOrientation: Vector3) {
  const orientation = new Vector3(
    Math.abs(beamOrientation.x),
    Math.abs(beamOrientation.y),
    beamOrientation.z
  )
  return orientation.clone().applyAxisAngle(Z_AXIS, -Math.PI / 2)
}
export function getBeamOrientation(beamRotation: number) {
  return new Vector3(0, -1, 0).applyAxisAngle(Z_AXIS, beamRotation)
}

function getOrientationAxis(orientation: Vector3): 'x' | 'y' | 'z' {
  const keys = ['x', 'y', 'z'] as const
  return keys.find(key => {
    return Number(orientation[key].toFixed(6))
  })!
}

export type AugmentedModel = { position: Vector3Like; point1: Vector2; point2: Vector2; id: number }
export function getLineSegmentForPoint(pos: [Vector2, number], sectionPoints: Vector3Tuple[], orientation: Vector3, useFurthest: boolean): { point1: Vector2; point2: Vector2; id: number; position: Vector2 } | undefined
export function getLineSegmentForPoint(pos: [Vector3, number], sectionPoints: Vector3Tuple[], orientation: Vector3, useFurthest: boolean): { point1: Vector2; point2: Vector2; id: number; position: Vector3 } | undefined
export function getLineSegmentForPoint(pos: [Vector2 | Vector3, number], sectionPoints: Vector3Tuple[], orientation: Vector3, useFurthest: boolean): { point1: Vector2; point2: Vector2; id: number; position: Vector2 | Vector3 } | undefined
export function getLineSegmentForPoint(pos: [Vector2 | Vector3, number], sectionPoints: Vector3Tuple[], orientation: Vector3, useFurthest: boolean) {
  const orientationKey = getOrientationAxis(orientation)
  const perimeterPoints = Util.toVec3Array(sectionPoints)

  const segPoint1 = pos[0]
    .clone()
    .addScaledVector(orientation, -Units.feetToNative(10000))
  const segPoint2 = pos[0]
    .clone()
    .addScaledVector(orientation, Units.feetToNative(10000))
  let intersectionPoints: Vector2[] = []

  for (let i = 0; i < perimeterPoints.length - 1; i++) {
    const wallSegPoint1 = perimeterPoints[i]
    const wallSegPoint2 = perimeterPoints[i + 1]
    const intersectionPoint = Util.lineSegmentsIntersectionPoint(
      wallSegPoint1,
      wallSegPoint2,
      segPoint1,
      segPoint2
    )
    if (intersectionPoint) {
      intersectionPoints.push(
        new Vector2(intersectionPoint.x, intersectionPoint.y)
      )
    }
  }

  // May happen with non-convex polygons or from imprecision in the
  // intersection calculation.
  if (intersectionPoints.length > 2) {
    if (useFurthest) {
      intersectionPoints = Util.getFurthestTwoPoints(intersectionPoints)
    } else {
      const position = new Vector2(pos[0].x, pos[0].y)
      const positionValue = Number(position[orientationKey as 'x'|'y'].toPrecision(6))
      intersectionPoints = intersectionPoints.filter(ip => {
        return Number(ip[orientationKey as 'x'|'y'].toPrecision(6)) > positionValue
      })
      if (intersectionPoints.length) {
        intersectionPoints = [
          position,
          Util.getClosestPoint(position, intersectionPoints),
        ]
      }
    }
  }

  // If the number of intersectionPoints isn't two, the line was either
  // tangent to the polygon or does not overlap it at all.
  if (intersectionPoints.length === 2) {
    intersectionPoints = sortBy(intersectionPoints, orientationKey)
    return {
      point1: intersectionPoints[0],
      point2: intersectionPoints[1],
      id: pos[1],
      position: pos[0],
    }
  } else {
    return undefined
  }
}

export function inchesToNative2(it: Vector2Like): Vector2 {
  return new Vector2(Units.inchesToNative(it.x), Units.inchesToNative(it.y))
}
export function become3(it: Vector2Like, z: number) {
  return new Vector3(it.x, it.y, z)
}

export function average(a: Vector3, b: Vector3): Vector3
export function average(a: Vector2, b: Vector2): Vector2
export function average(a: Vector2|Vector3, b: Vector2|Vector3): Vector2|Vector3 {
  return (a as Vector2).clone().add(b as Vector2).multiplyScalar(0.5)
}
