export const ObjectTypes = {
  CEILING: 'CEILING',
  COLUMN: 'COLUMN',
  COMFORT_ZONE: 'COMFORT_ZONE',
  CSS_SCENE: 'CSS_SCENE',
  DOOR: 'DOOR',
  FAN_BLADE_CYLINDER: 'FAN_BLADE_CYLINDER',
  FAN_COLLISION_CUBE: 'FAN_COLLISION_CUBE',
  DRAG_HANDLE: 'DRAG_HANDLE',
  FAN_TILT_GROUP: 'FAN_TILT_GROUP',
  FLOOR: 'FLOOR',
  GRID_BOX: 'GRID_BOX',
  OBSTRUCTION: 'OBSTRUCTION',
  PRIMARY_BEAM: 'PRIMARY_BEAM',
  PRODUCT_DISTANCE: 'PRODUCT_DISTANCE',
  RESIZE_HANDLE_RIGHT: 'RESIZE_HANDLE_RIGHT',
  RESIZE_HANDLE_LEFT: 'RESIZE_HANDLE_LEFT',
  RESIZE_HANDLE_TOP: 'RESIZE_HANDLE_TOP',
  RESIZE_HANDLE_BOTTOM: 'RESIZE_HANDLE_BOTTOM',
  RESIZE_HANDLE_BOTTOM_RIGHT: 'RESIZE_HANDLE_BOTTOM_RIGHT',
  RESIZE_HANDLE_BOTTOM_LEFT: 'RESIZE_HANDLE_BOTTOM_LEFT',
  RESIZE_HANDLE_TOP_RIGHT: 'RESIZE_HANDLE_TOP_RIGHT',
  RESIZE_HANDLE_TOP_LEFT: 'RESIZE_HANDLE_TOP_LEFT',
  RESIZE_HANDLE: 'RESIZE_HANDLE',
  ROOF: 'ROOF',
  ROOF_PANEL: 'ROOF_PANEL',
  SCENE: 'SCENE',
  SUB_MOUNTING_STRUCTURE: 'SUB_MOUNTING_STRUCTURE',
  UI_ARROW: 'UI_ARROW',
  UI_ARROW_LINE: 'UI_ARROW_LINE',
  UI_ARROW_CONE: 'UI_ARROW_CONE',
  WALL: 'WALL',
} as const
export type ObjectTypes = (typeof ObjectTypes)[keyof typeof ObjectTypes]
export default ObjectTypes