import React from 'react'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { getTitle } from 'config/titles'

import { CREATE_FACILITY_MUTATION } from 'client/mutations'

import FacilityForm from '../../FacilityForm'

const NewFacilityModal = ({ ...props }) => (
  <div>
    <Helmet>
      <title>{getTitle('newFacility')}</title>
    </Helmet>
    <FacilityForm
      isNew
      title="New Facility"
      buttonText="Create Facility"
      {...props}
    />
  </div>
)

const mapStateToProps = ({ auth }) => ({
  userId: auth.userId,
})

export default compose(
  appConnect(mapStateToProps),
  graphql(CREATE_FACILITY_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onSubmit: variables =>
        mutate({
          variables: {
            opportunityId: ownProps.opportunityId,
            ...variables,
          },
          refetchQueries: ['GetFacility', 'AllFacilities'],
        }),
    }),
  }),
  withRouter
)(NewFacilityModal)
