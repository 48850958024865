import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Selection = styled.a`
  border: ${theme('borderWidth')} solid ${theme('colors.light.subdued')};
  border-radius: ${theme('radius.base')};
  color: ${theme('colors.light.fg')};
  display: block;
  font-size: ${theme('fonts.sizes.l')};
  font-weight: bold;
  padding: ${theme('spacing.base')};
  text-align: center;
  text-decoration: none;
  cursor: pointer;
`

export default Selection
