import styled from 'styled-components'

import Image from 'components/UIKit/Image'

const Preview = styled(Image)`
  height: 100vh;
  margin: 0 auto;
  object-fit: contain;
`

export default Preview
