export const INIT_BUNDLES = 'INIT_BUNDLES'
export const ADD_BUNDLE = 'ADD_BUNDLE'
export const ADD_OPPORTUNITY = 'ADD_OPPORTUNITY'
export const CLEAR_OPPORTUNITY = 'CLEAR_OPPORTUNITY'
export const UPDATE_QUOTE_NAME = 'UPDATE_QUOTE_NAME'
export const UPDATE_BUNDLES = 'UPDATE_BUNDLES'
export const UPDATE_DISCOUNT_REASON_ID = 'UPDATE_DISCOUNT_REASON_ID'
export const UPDATE_DISCOUNT_NOTES = 'UPDATE_DISCOUNT_NOTES'
export const UPDATE_SHIPPING_PRICE = 'UPDATE_SHIPPING_PRICE'

export const initBundles = payload => ({
  type: INIT_BUNDLES,
  payload,
})

export const addBundle = payload => ({
  type: ADD_BUNDLE,
  payload,
})

export const addOpportunity = payload => ({
  type: ADD_OPPORTUNITY,
  payload,
})

export const clearOpportunity = () => ({
  type: CLEAR_OPPORTUNITY,
})

export const updateQuoteName = name => ({
  type: UPDATE_QUOTE_NAME,
  payload: { name },
})

export const updateBundles = bundles => ({
  type: UPDATE_BUNDLES,
  payload: { bundles },
})

export const updateDiscountReasonId = id => ({
  type: UPDATE_DISCOUNT_REASON_ID,
  payload: { id },
})

export const updateDiscountNotes = notes => ({
  type: UPDATE_DISCOUNT_NOTES,
  payload: { notes },
})

export const updateShippingPrice = price => ({
  type: UPDATE_SHIPPING_PRICE,
  payload: { price },
})
