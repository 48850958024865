import React, { createContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useFacilityVtkResults } from 'hooks/useFacilityVtkResults'
import { camelToTitleCase } from 'utils/string'
import { useAppSelector } from "~/store/hooks";
import { getComfortZoneEdges } from '../util/comfortZone'
import Flex from 'components/UIKit/Flex'
import Select from 'components/UIKit/Select'
import Label from '../styled/Label'
import { Loader } from './Loader'
import { Alert } from 'components/PerformanceMetrics/subcomponents/Alert'
import { PerformanceMetricsContainer } from 'components/PerformanceMetrics/subcomponents/PerformanceMetricsContainer'
import { useCFDResultChecksumFragment } from '~/hooks/useCFDResultChecksum'
import { useCFDUploadsContext } from '~/hooks/useCFDUploadsContext'
import { InvalidMetricsMessage } from './InvalidMetricsMessage'

const PerformanceMetricsContext = createContext(null)

const DEFAULT_INDEX = 0
const DEFAULT_ZONE = {
  label: 'Facility',
  value: DEFAULT_INDEX,
  edges: null,
}

const PerformanceMetricsControls = ({ children }) => {
  const { checksumResults } = useCFDResultChecksumFragment()
  const { uploads } = useCFDUploadsContext()
  const { data: facilityVtkResults, error, loading } = useFacilityVtkResults()
  const comfortZones = useAppSelector(state => state?.objects?.present?.comfortZones)
  const [selectedAreaOfConcernIndex, setSelectedAreaOfConcernIndex] = useState(DEFAULT_INDEX)
  const [selectedGoalIndex, setSelectedGoalIndex] = useState(DEFAULT_INDEX)

  const metricsConfig = useMemo(() => {
    const areaOfConcernOptions = [DEFAULT_ZONE].concat(
      Object.values(comfortZones).map(({ positions, name }, i) => ({
        label: name || `Zone ${i + 1}`,
        value: i + 1,
        edges: getComfortZoneEdges(positions),
      }))
    )
    const vtkFileOptions = facilityVtkResults?.vtkResults.map(({ type }, i) => ({
      label: camelToTitleCase(type),
      value: i,
    }))
    return {
      facility: facilityVtkResults?.facility,
      areaOfConcernOptions,
      areaOfConcernEdges: areaOfConcernOptions[selectedAreaOfConcernIndex]?.edges,
      vtkFileOptions,
      selectedVtkFiles: loading || error ? null : facilityVtkResults?.vtkResults[selectedGoalIndex],
    }
  }, [
    selectedGoalIndex,
    selectedAreaOfConcernIndex,
    comfortZones,
    loading,
    error,
    facilityVtkResults,
  ])

  const handleSelectZone = e => setSelectedAreaOfConcernIndex(parseInt(e.target.value))
  const handleSelectGoal = e => setSelectedGoalIndex(e.target.value)
  const selectedType = metricsConfig.selectedVtkFiles?.type
  const simulationResult = checksumResults?.find(r => !!r && r.internalType === selectedType)
  const simChecksum = simulationResult?.resultChecksum
  const modelChecksum = uploads[selectedType]?.checksum
  const isInvalidSimulation = Boolean(modelChecksum && simChecksum && modelChecksum !== simChecksum)

  return (
    <PerformanceMetricsContainer>
      {isInvalidSimulation && <InvalidMetricsMessage />}
      <Flex>
        <Select
          inline
          disablePlaceholder
          size="120px"
          name="select"
          label="Area of Concern"
          value={selectedAreaOfConcernIndex}
          options={metricsConfig.areaOfConcernOptions}
          onChange={handleSelectZone}
        />
        <Select
          inline
          disablePlaceholder={!error}
          placeholder={error ? 'Error' : undefined}
          disabled={!!error}
          loading={loading}
          size="120px"
          name="select"
          label="Goal"
          value={selectedGoalIndex}
          options={metricsConfig.vtkFileOptions}
          onChange={handleSelectGoal}
        />
      </Flex>
      {loading ? (
        <Loader label="Gathering simulation data..." style={{ minHeight: '400px' }} />
      ) : error ? (
        <Alert label="Failed to get metrics data" style={{ minHeight: '400px' }} />
      ) : !facilityVtkResults ? (
        <Label>Please run CFD analysis in order to view performance metrics.</Label>
      ) : (
        <PerformanceMetricsContext.Provider value={metricsConfig}>
          {children}
        </PerformanceMetricsContext.Provider>
      )}
    </PerformanceMetricsContainer>
  )
}

PerformanceMetricsControls.propTypes = {
  children: PropTypes.node,
}

export { PerformanceMetricsControls, PerformanceMetricsContext }
