import styled from 'styled-components'

import { getZIndexOf } from 'lib/utils'

const WebcamModal = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${getZIndexOf('modal')};
`

export default WebcamModal
