import React from 'react'
import PropTypes from 'prop-types'

import { getIconForStatusType } from 'lib/utils'

import Icon from '../Icon'

import Container from './styled/Container'
import Text from './styled/Text'

/**
 * Element to display to user various important messages
 * @param {Object} param0 banner text and type
 * @returns
 */
const FlashBanner = ({ text, type = 'default' }) => (
  <Container type={type}>
    <div>
      <Icon
        name={getIconForStatusType(type).icon}
        color={getIconForStatusType(type).color}
      />
    </div>
    <Text>{text}</Text>
  </Container>
)

FlashBanner.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
}

export default FlashBanner
