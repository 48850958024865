import React, { Component } from 'react'
import { object, func, string, bool } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'

import { removeElevationLineHeight, updateElevationPoint } from 'store/objects'
import { withUnits } from 'store/units/decorators'
import { Distance } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { isTouchUI } from 'store/userInterface/selectors'
import { SELECTED_ELEVATION_POINT_PANEL } from 'store/panel/types'
import CLASS_NAMES from 'config/objectClassNames'
import store from 'store'
import LAYER_KEYS from 'config/layerKeys'

import Facility from 'components/DrawingCanvas/lib/facility'

import DimensionInput from 'components/UIKit/DimensionInput'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'

import MetadataSection from '../../MetadataSection'

class SelectedElevationPointPanel extends Component {
  handleInputChange = (key, value) => {
    const {
      selectedObject,
      onUpdateElevationPoint,
      onRemoveElevationLineHeight,
    } = this.props

    const elevationLines = Facility.current.getElevationLines()
    const parentElevationLine = elevationLines.find(line =>
      line.elevationPoints.find(point => point.id === selectedObject.id)
    )

    if (parentElevationLine && parentElevationLine.height) {
      onRemoveElevationLineHeight({
        id: parentElevationLine.id,
        elevationPointIds: parentElevationLine.elevationPoints.map(ep => ep.id),
        roofId: parentElevationLine.roofId,
        height: undefined,
        layerKey: parentElevationLine.layerKey,
      })
    }

    onUpdateElevationPoint({
      ...selectedObject,
      layerKey: 'ROOFS',
      [key]: value,
    })
  }

  renderContent() {
    const {
      selectedObject,
      onUpdateElevationPoint,
      distanceUnits,
      isTouchUI,
    } = this.props
    const inputWidth = isTouchUI ? '140px' : '70px'

    const isLocked = store.getState().layers.layers[LAYER_KEYS.ROOFS].locked

    return (
      <>
        <PanelSection>
          <Space bottom="base">
            <DimensionInput
              label="Height"
              name="elevation-point-height"
              width={inputWidth}
              disabled={isLocked ? true : false}
              distance={
                new Distance({
                  value: selectedObject.position.z,
                  system: SYSTEMS.NATIVE,
                })
              }
              units={distanceUnits}
              onChange={({ distance }) => {
                const value = distance.native() || 0
                this.handleInputChange('position', {
                  ...selectedObject.position,
                  z: value,
                })
              }}
            />
          </Space>
        </PanelSection>
        <MetadataSection
          object={selectedObject}
          onBlur={onUpdateElevationPoint}
          disabled={isLocked ? true : false}
        />
      </>
    )
  }

  render() {
    const { selectedObject, alignment, isTouchUI } = this.props

    return (
      <Panel
        title="Elevation Point"
        alignment={alignment}
        docked
        panelKey={SELECTED_ELEVATION_POINT_PANEL}
        hasToolbar={isTouchUI}
      >
        {selectedObject ? this.renderContent() : null}
      </Panel>
    )
  }
}

SelectedElevationPointPanel.propTypes = {
  alignment: string,
  selectedObject: object,
  onUpdateElevationPoint: func,
  onRemoveElevationLineHeight: func,
  distanceUnits: string,
  isTouchUI: bool,
}

SelectedElevationPointPanel.defaultProps = {
  alignment: 'right',
}

const mapStateToProps = ({ objects, selectedObjects, ...store }) => {
  const selectedObject = mostRecentSelectedObjectOfClassName(
    CLASS_NAMES.ELEVATION_POINT,
    {
      selectedObjects,
      objects,
    }
  )
  return {
    layers: store.layers,
    isTouchUI: isTouchUI(store),
    selectedObject,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateElevationPoint(elevationPoint) {
    dispatch(updateElevationPoint({ elevationPoint }))
  },
  onRemoveElevationLineHeight(elevationLine) {
    dispatch(removeElevationLineHeight({ elevationLine }))
  },
})

export default compose(
  withUnits,
  appConnect(mapStateToProps, mapDispatchToProps)
)(SelectedElevationPointPanel)
