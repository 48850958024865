import React from 'react'
import PropTypes from 'prop-types'

import TextField from 'components/UIKit/TextField'

const Notes = ({ disabled, value, labelWidth, onBlur }) => (
  <TextField
    inline
    multiline
    noResize
    label="Notes"
    labelWidth={labelWidth}
    name="notes"
    disabled={disabled}
    value={value}
    onBlur={onBlur}
    rows="6"
    size="s"
  />
)

Notes.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  labelWidth: PropTypes.string,
  onBlur: PropTypes.func,
}

export default Notes
