import React, { Component } from 'react'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { graphql } from '@apollo/client/react/hoc'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import get from 'lodash-es/get'

import { getTitle } from 'config/titles'

import { onFacilityTemplateCopied } from 'config/analytics'
import { trackEvent } from 'lib/analytics'

import { DUPLICATE_FACILITY_MUTATION } from 'client/mutations'
import { GET_FACILITY_QUERY } from 'client/queries'

import Alert from 'components/UIKit/Alert'
import Button from 'components/UIKit/Button'
import Loader from 'components/UIKit/Loader'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'

import { LOG_USER_ACTION_MUTATION } from 'client/mutations'
import { handleLogUserAction } from 'client/handlers'

class DuplicateFacilityModal extends Component {
  static propTypes = {
    facilityData: PropTypes.object,
    parentRoute: PropTypes.string,
    history: PropTypes.object,
    userId: PropTypes.string,
    onSubmit: PropTypes.func,
    currentFacilityName: PropTypes.string,
    logUserAction: PropTypes.func,
  }

  state = {
    name: `${this.props.currentFacilityName} (Copy)` || '',
    error: '',
    saving: false,
  }

  UNSAFE_componentWillMount() {
    const facility = this.getDefaultFacilityName(this.props)

    if (facility) {
      this.setState({
        name: facility,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const facility = this.getDefaultFacilityName(nextProps)

    if (facility) {
      this.setState({
        name: facility,
      })
    }
  }

  getDefaultFacilityName(props) {
    const facility = get(props, 'facilityData.facility')

    if (facility) {
      return `${facility.name} (Copy)`
    }

    return undefined
  }

  handleNameChange = event => {
    this.setState({
      name: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      saving: true,
    })

    if (this.state.name.trim() === '') {
      this.setState({
        error: 'Please enter a name!',
        saving: false,
      })
      return
    }

    const { facility } = this.props.facilityData

    const data = {
      name: this.state.name,
      facilityId: facility.id,
    }

    this.props
      .onSubmit(data)
      .then(res => {
        // GA tracking for Template Copied
        if (facility.isTemplate) {
          trackEvent(onFacilityTemplateCopied())
          this.props.logUserAction({ action: 'copy template' })
        }

        this.props.history.push(`/facility/${res.data.facility.id}`)
      })
      .catch(error => {
        this.setState({
          error: get(error, 'graphQLErrors.0.message'),
          saving: false,
        })
      })
  }

  render() {
    const { error, name, saving } = this.state

    if (this.props.facilityData.loading) {
      return <Loader centered />
    }

    return (
      <div>
        <Helmet>
          <title>{getTitle('duplicateFacility')}</title>
        </Helmet>
        <Modal
          title="Duplicate Facility"
          onSubmit={this.handleSubmit}
          primaryAction={
            <Button
              primary
              onClick={this.handleSubmit}
              disabled={saving}
              label={saving ? 'Saving...' : 'Create Facility'}
            />
          }
          secondaryAction={
            <Button to={this.props.parentRoute} label="Cancel" />
          }
          {...this.props}
        >
          {error.length > 0 && (
            <Space bottom="base">
              <Alert type="error" text={error} />
            </Space>
          )}
          <Space bottom="base">
            <TextField
              label="Name"
              value={name}
              onChange={this.handleNameChange}
            />
          </Space>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  userId: auth.userId,
})

export default compose(
  appConnect(mapStateToProps),
  graphql(GET_FACILITY_QUERY, {
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
    name: 'facilityData',
  }),
  graphql(LOG_USER_ACTION_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      logUserAction: ({ action }) =>
        handleLogUserAction({ action, mutate, ownProps }),
    }),
  }),
  graphql(DUPLICATE_FACILITY_MUTATION, {
    props: ({ mutate }) => ({
      onSubmit: variables => mutate({ variables }), // TODO: Logic should be here, the form doesn't need to be aware of the app
    }),
    options: props => ({
      refetchQueries: ['AllFacilities'],
    }),
  }),
  withRouter
)(DuplicateFacilityModal)
