import styled from 'styled-components'

import theme from 'config/theme'

const margin = ({ verticalMargin }) => {
  if (verticalMargin) {
    return `margin: ${theme.spacing[verticalMargin]} auto;`
  }

  return `margin: 0 auto;`
}

const Content = styled.div`
  min-height: 200px;
  position: relative;
  width: ${props => props.width || '100%'};

  ${margin};
`

export default Content
