import React, { PureComponent } from 'react'
import isEmpty from 'lodash-es/isEmpty'
import PropTypes from 'prop-types'
import Button from 'components/UIKit/Button'
import withQuote from 'client/decorators/withQuote'

export class SaveQuoteButton extends PureComponent {
  static propTypes = {
    onUpdateQuote: PropTypes.func,
    onCreateQuote: PropTypes.func,
    onQuoteSaved: PropTypes.func,
    onError: PropTypes.func,
    loading: PropTypes.bool,
    salesforceQuoteId: PropTypes.string,
  }

  static defaultProps = {
    onUpdateQuote() {},
    onCreateQuote() {},
    onQuoteSaved() {},
    onError: console.error,
    loading: false,
    salesforceQuoteId: '',
  }

  state = {
    saving: false,
  }

  handleOnClick = async () => {
    try {
      const hasQuote = !isEmpty(this.props.salesforceQuoteId)
      this.setState({
        saving: true,
      })
      if (hasQuote) {
        await this.props.onUpdateQuote()
      } else {
        await this.props.onCreateQuote()
      }

      this.setState({
        saving: false,
      })

      this.props.onQuoteSaved({ action: hasQuote ? 'updated' : 'created' })
    } catch (err) {
      this.setState({
        saving: false,
      })

      this.props.onError(err)
    }
  }

  render() {
    return (
      <Button
        primary
        fullWidth
        onClick={this.handleOnClick}
        disabled={this.state.saving || this.props.loading}
        icon="check"
      >
        {this.state.saving ? 'Saving…' : 'Save'}
      </Button>
    )
  }
}

export default withQuote(SaveQuoteButton)
