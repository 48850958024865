import React from 'react'
import styled from 'styled-components'

import FormLabel from 'components/UIKit/FormLabel'

const setInlineStyles = props => {
  if (props.inline) {
    return `margin-right: ${props.theme.spacing.s};`
  }

  return `
    display: block;
    margin-bottom: ${props.theme.spacing.xs};
  `
}

const Label = styled(FormLabel)`
  ${setInlineStyles};
  ${props => props.labelWidth && `min-width: ${props.labelWidth};`};
`.withComponent(({ inline, labelWidth, ...rest }) => <FormLabel {...rest} />)

export default Label
