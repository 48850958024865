import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.td`
  padding: ${theme('spacing.base')};
  ${props =>
    props.bordered &&
    `border-bottom: 1px solid ${props.theme.colors.light.subdued}`};
`

export default Container
