import { showProductSize } from 'lib/utils'
import { SYSTEMS } from 'store/units/constants'

export const PRODUCT_TYPES = {
  OVERHEAD: 'OVERHEAD',
  DIRECTIONAL: 'DIRECTIONAL',
}
export type PRODUCT_TYPES = (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES]

export const PRODUCT_CATEGORIES = {
  EVAP: 'EVAP',
  HEAT: 'HEAT',
  FAN: 'FAN',
}
export type PRODUCT_CATEGORIES = (typeof PRODUCT_CATEGORIES)[keyof typeof PRODUCT_CATEGORIES]

const sizes = [52, 60, 84, 96, 120, 144, 168, 192, 216, 240, 288]

export const productSizes = sizes.map(size => ({
  label: showProductSize(size, SYSTEMS.IMPERIAL, undefined),
  value: size.toString(),
}))

export const defaultTubeLengths = [
  {
    model: 'Haiku',
    size: 7,
  },
  {
    model: 'Essence',
    size: 24,
  },
  {
    model: 'Yellow Jacket',
    size: 0,
  },
  {
    model: 'Black Jack',
    size: 0,
  },
  {
    model: 'Sweat Bee',
    size: 18,
  },
  {
    model: 'AirEye',
    size: 56.6,
  },
  {
    model: 'Pivot 2.0',
    size: 12,
  },
  {
    model: 'Powerfoil X3.0',
    size: 0,
  },
  {
    model: 'Powerfoil X4',
    size: 0,
  },
  {
    model: 'Powerfoil 8',
    size: 0,
  },
  {
    model: 'Powerfoil D',
    size: 0,
  },
  {
    model: 'Basic 6',
    size: 0,
  },
  {
    model: 'i6',
    size: 6,
  },
  {
    model: 'Sidekick',
    size: 0,
  },
  {
    model: 'Breeze',
    sizes: [
      {
        size: 0,
      },
    ],
  },
  {
    model: 'Default',
    size: 12,
  },
]

export const haloSizes = [
  {
    model: 'Sweat Bee',
    sizes: [
      {
        size: 18,
        haloHeight: 4.3,
      },
      {
        size: 30,
        haloHeight: 5.7,
      },
    ],
  },
  {
    model: 'AirEye',
    sizes: [
      {
        size: 20,
        haloHeight: 3.4,
      },
      {
        size: 24,
        haloHeight: 4.3,
      },
      {
        size: 30,
        haloHeight: 4.8,
      },
      {
        size: 36,
        haloHeight: 4.8,
      },
    ],
  },
  {
    model: 'Yellow Jacket',
    sizes: [
      {
        size: 30,
        haloHeight: 6,
      },
    ],
  },
  {
    model: 'Pivot 2.0',
    sizes: [
      {
        size: 72,
        haloHeight: 11,
      },
    ],
  },
  {
    model: 'AirGo 2.0',
    sizes: [
      {
        size: 96,
        haloHeight: 15,
      },
    ],
  },
  {
    model: 'Black Jack',
    sizes: [
      {
        size: 72,
        haloHeight: 13.8,
      },
    ],
  },
  {
    model: 'Cool-Space 200',
    sizes: [
      {
        size: 34,
        haloHeight: 17.5,
      },
    ],
  },
  {
    model: 'Cool-Space 300',
    sizes: [
      {
        size: 49,
        haloHeight: 12,
      },
    ],
  },
  {
    model: 'Cool-Space 400',
    sizes: [
      {
        size: 70,
        haloHeight: 21,
      },
    ],
  },
  {
    model: 'Cool-Space 500',
    sizes: [
      {
        size: 88,
        haloHeight: 24,
      },
    ],
  },
  {
    model: 'Sidekick',
    sizes: [
      {
        size: 48,
        haloHeight: 10,
      },
    ],
  },
  {
    model: 'ES6',
    sizes: [
      {
        size: 48,
        haloHeight: 10,
      },
    ],
  },
  {
    model: 'Breeze',
    sizes: [
      {
        size: 0,
      },
    ],
  },
]
