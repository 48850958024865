import React, { Component } from 'react'
import { string, func, object, bool } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'

import { getTitle } from 'config/titles'
import store from '~/store'
import { Distance } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'
import { withUnits } from 'store/units/decorators'
import { distributeDoors } from 'store/objects'
import { mostRecentSelectedObjectStateOfClassName } from 'store/selectedObjects/selectors'
import CLASS_NAMES from 'config/objectClassNames'

import Units from 'components/DrawingCanvas/lib/units'
import Util from 'components/DrawingCanvas/lib/util'
import Facility from 'components/DrawingCanvas/lib/facility'

import Button from 'components/UIKit/Button'
import DimensionInput from 'components/UIKit/DimensionInput'
import Modal from 'components/UIKit/Modal'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Space from 'components/UIKit/Space'
import Switch from 'components/UIKit/Switch'
import Table, { Row, Cell } from 'components/UIKit/Table'
import TextField from 'components/UIKit/TextField'
import Tooltip from 'components/UIKit/Tooltip'

import Container from './styled/Container'
import SwitchContainer from './styled/SwitchContainer'
import Note from './styled/Note'
import { handles } from '~/components/DrawingCanvas/CompatibilityObject3DHandles';
import { isHorizontal } from '~/components/DrawingCanvas/util/walls';

class DistributeDoorModal extends Component {
  state = {
    itemsCount: 1,
    spacing: new Distance({
      value: 144,
      system: SYSTEMS.IMPERIAL,
    }),
    loading: false,
    countAuto: false,
    isInverted: false,
  }

  componentDidUpdate() {
    const { itemsCount, spacing } = this.state

    if (!itemsCount && !spacing) {
      this.setState({
        spacing: new Distance({
          value: 1,
          system: spacing.system,
        }),
      })
    }
  }

  handleFocus = event => {
    event.target.select()
  }

  handleSubmit = event => {
    event.preventDefault()

    this.setState({
      loading: true,
    })

    setTimeout(this.handleGenerateGrid, 100)
  }

  handleGenerateGrid = () => {
    const {
      history,
      isHorizontal,
      onDistributeDoors,
      parentRoute,
      selectedObjectId,
    } = this.props
    const { itemsCount, isInverted, countAuto } = this.state
    const selectedDoor = Object.values(store.getState().objects.present.doors)
      .find(door => door.id === selectedObjectId)

    // If auto fill is enabled, fill the wall with doors
    const count = countAuto ? 100 : itemsCount

    const positions = Util.getObjectSpacingOnWall(
      handles.get(selectedDoor.id),
      count,
      this.state.spacing.value,
      isHorizontal,
      isInverted
    )

    const newDoors = positions.map(pos => ({
      ...selectedDoor,
      id: Util.guid(),
      position: {
        x: Units.nativeToInches(pos.x),
        y: Units.nativeToInches(pos.y),
        z: Units.nativeToInches(pos.z),
      },
    }))

    onDistributeDoors({ models: newDoors })
    history.push(parentRoute)
  }

  handleChange = ({ name, value }) => {
    // keep numeric value between 0 and 50
    const intValue = parseInt(value, 10) || 0
    let formattedValue = intValue >= 50 ? 50 : intValue < 0 ? 0 : intValue
    this.setState({ [name]: formattedValue })
  }

  handleToggleAuto = ({ event, name }) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      [name]: !this.state[name],
    })
  }

  getDirectionText() {
    const { isHorizontal } = this.props
    const { isInverted } = this.state

    if (isHorizontal) {
      return isInverted ? 'left' : 'right'
    } else {
      return isInverted ? 'up' : 'down'
    }
  }

  render() {
    const {
      parentRoute,
      distanceUnits,
      selectedObjectId,
      isHorizontal,
    } = this.props
    const { loading, isInverted } = this.state
    const isDisabled = loading || !selectedObjectId
    const direction = this.getDirectionText()
    const note = isHorizontal
      ? `The distributed objects will generate to the ${direction}`
      : `The distributed objects will generate ${direction}`

    return (
      <div>
        <Helmet>
          <title>{getTitle('distributeDoors')}</title>
        </Helmet>
        <Modal
          title="Distribute Door"
          primaryAction={
            isDisabled ? (
              <Tooltip content="You need to select a Door">
                <div>
                  <Button
                    primary
                    type="submit"
                    disabled={isDisabled}
                    label={loading ? 'Adding...' : 'Add'}
                  />
                </div>
              </Tooltip>
            ) : (
              <Button
                primary
                type="submit"
                label={loading ? 'Adding...' : 'Add'}
              />
            )
          }
          secondaryAction={<Button to={parentRoute} label="Cancel" />}
          flushContent
          onSubmit={this.handleSubmit}
          {...this.props}
        >
          <Container size="base">
            <Space bottom="l">
              <Table>
                <Row>
                  <Cell bordered>
                    <h2>Spacing</h2>
                    <p>Distance between items (edge-to-edge)</p>
                  </Cell>
                  <Cell bordered>
                    <h2>Count</h2>
                    <p>Number of additional items to distribute</p>
                  </Cell>
                </Row>
                <Row>
                  <Cell bordered>
                    <Grid vCenter>
                      <GridItem size="2of10">
                        <img
                          src={`/spacing-${
                            isHorizontal ? 'horizontal' : 'vertical'
                          }-edge.png`}
                          width={36}
                          height={36}
                          alt="Spacing"
                        />
                      </GridItem>
                      <GridItem size="3of10">
                        <DimensionInput
                          name="spacing"
                          distance={this.state.spacing}
                          units={distanceUnits}
                          onChange={({ distance }) => {
                            this.setState({ spacing: distance })
                          }}
                        />
                      </GridItem>
                    </Grid>
                  </Cell>
                  <Cell bordered>
                    <Grid vCenter>
                      <GridItem size="2of10">
                        <img
                          src={`/count-${
                            isHorizontal ? 'horizontal' : 'vertical'
                          }.png`}
                          width={36}
                          height={36}
                          alt="Count"
                        />
                      </GridItem>
                      <GridItem size="3of10">
                        <TextField
                          name="count"
                          onFocus={this.handleFocus}
                          onClick={this.handleFocus}
                          type={this.state.itemsCount ? 'number' : 'string'}
                          width="60px"
                          inline
                          value={this.state.itemsCount}
                          disabled={this.state.countAuto}
                          onChange={event => {
                            this.handleChange({
                              name: 'itemsCount',
                              value: event.target.value,
                            })
                          }}
                        />
                      </GridItem>
                      <GridItem size="2of10">
                        <Tooltip content="Automatically fill the available area">
                          <Button
                            icon="infinity"
                            primary={this.state.countAuto}
                            onClick={event =>
                              this.handleToggleAuto({
                                event,
                                name: 'countAuto',
                              })
                            }
                          />
                        </Tooltip>
                      </GridItem>
                    </Grid>
                  </Cell>
                </Row>
              </Table>
            </Space>
            <Space bottom="base">
              <SwitchContainer>
                <Switch
                  onClick={event => {
                    event.stopPropagation()
                    event.preventDefault()
                    this.setState({ isInverted: !isInverted })
                  }}
                  label={'Invert direction'}
                  isChecked={isInverted}
                />
              </SwitchContainer>
            </Space>
            <Space bottom="base">
              <Note size="s">
                <strong>Note:</strong> {note}
              </Note>
            </Space>
          </Container>
        </Modal>
      </div>
    )
  }
}

DistributeDoorModal.propTypes = {
  addDoors: func,
  distanceUnits: string,
  onDistributeDoors: func,
  doors: object,
  history: object,
  isHorizontal: bool,
  isInverted: bool,
  parentRoute: string,
  selectedObjectId: string,
}

const mapStateToProps = ({ selectedObjects, objects }) => {
  const selectedObject = mostRecentSelectedObjectStateOfClassName(
    CLASS_NAMES.DOOR,
    {
      selectedObjects,
    }
  )
  const selectedObjectId = selectedObject && selectedObject.id
  const selectedDoor = Object.values(objects.present.doors)
    .find(door => door.id === selectedObjectId)
  const segment = selectedDoor && Object.values(objects.present.segments)
    .find(segment => segment.id === selectedDoor.wallSegmentId)
  const horizontal = segment && isHorizontal(segment)
  return {
    selectedObjectId,
    isHorizontal: horizontal,
    doors: objects.present.doors,
  }
}

const mapDispatchToProps = dispatch => ({
  onDistributeDoors(models) {
    dispatch(distributeDoors(models))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withUnits
)(DistributeDoorModal)
