import styled, { css } from 'styled-components'

import { theme } from 'lib/styleUtils'
import { getDockedPanelWidth, getZIndexOf } from 'lib/utils'

const rightOffset = css`
  calc(2 * ${theme('spacing.base')} + ${theme('touchUI.buttonSize')})
`

const setWidth = props => {
  if (props.showDock) {
    return `
    width: calc(100% - (3 * ${props.theme.spacing.base} + ${
      props.theme.touchUI.buttonSize
    } + ${getDockedPanelWidth()}px));
    @media (min-height: 750px) {
      right: ${props.theme.spacing.base};
      width: ${largeScreenWidth};
    }
  `
  }
  return
}

const setDisplay = props => {
  if (props.showDock) {
    return `display: flex;`
  }
  return `display: block;`
}

const largeScreenWidth = css`
  calc(100% - (2 * ${theme('spacing.base')} + ${getDockedPanelWidth()}px))
`

const Container = styled.div`
  align-items: flex-end;
  bottom: ${theme('spacing.base')};
  font-size: ${theme('fonts.sizes.s')};
  justify-content: flex-end;
  padding: 0;
  position: absolute;
  right: ${rightOffset};
  z-index: ${getZIndexOf('modal')};

  ${setDisplay}
  ${setWidth}
`

export default Container
