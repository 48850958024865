import React, { PureComponent } from 'react'
import { func, string } from 'prop-types'

import { parseCloudinaryUrlTransforms } from 'lib/utils'

import UIKitImage from 'components/UIKit/Image'

import { getBase64FromImage } from './utils'

class Image extends PureComponent {
  static defaultProps = {
    onClick: () => {},
  }

  async componentDidMount() {
    if (this.props.saveBase64) {
      await this.getBase64Image()
    }
  }

  async getBase64Image() {
    const { url, saveBase64 } = this.props
    const parsedUrl = parseCloudinaryUrlTransforms(url)
    const base64 = await getBase64FromImage(parsedUrl)

    saveBase64({ url, base64 })
  }

  render() {
    const { url, onClick, saveBase64, ...rest } = this.props
    return (
      <UIKitImage
        src={this.props.url}
        onClick={event => this.props.onClick({ url }, event)}
        {...rest}
      />
    )
  }
}

Image.propTypes = {
  url: string,
  onClick: func,
  saveBase64: func,
}

export default Image
