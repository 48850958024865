import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Card from 'components/UIKit/Card'

const isTouch = ({ theme, isTouchUI }) =>
  isTouchUI &&
  `
    left: auto;
    right: calc(100% + ${theme.spacing.s});
  `

const Menu = styled(Card)`
  position: absolute;
  left: calc(100% + ${theme('spacing.s')});
  top: 0;

  > *:last-child {
    border-bottom: 0;
  }

  ${isTouch}
`

export default Menu
