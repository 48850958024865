import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { appConnect } from "~/store/hooks";
import { Helmet } from 'react-helmet'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import get from 'lodash-es/get'
import debounce from 'lodash-es/debounce'
import hasIn from 'lodash-es/hasIn'
import qs from 'qs'
import fileSaver from 'file-saver'

import { GET_CLOUDINARY_SIGNATURE_QUERY } from 'client/queries'

import { showAlert } from 'store/alert'
import { addMetadataImage, updateObject } from 'store/objects'
import CanvasDraw from './reactCanvasDraw'

import cloudinary from 'config/cloudinary'
import routes from 'config/routes'
import { getTitle } from 'config/titles'
import { parseCloudinaryUrlTransforms } from 'lib/utils'
import sendToSentry from 'lib/sendToSentry'

import { getBase64FromImage } from 'components/Modals/ExportPDFLayoutModal/Wizard/utils'
import Modal from 'components/UIKit/Modal'

import { scaleImgToCanvas } from './utils'
import Tools from './Tools'
import { TOOL_BAR_HEIGHT } from './Tools/styled/Container'
import BackgroundImage, { ASPECT_RATIO } from './styled/BackgroundImage'
import CanvasContainer from './styled/CanvasContainer'

class AnnotateImageModal extends Component {
  viewer

  state = {
    color: '#ffc600',
    width: (window.innerHeight - TOOL_BAR_HEIGHT) * ASPECT_RATIO,
    height: window.innerHeight - TOOL_BAR_HEIGHT,
    brushRadius: 4,
    lazyRadius: 1,
  }

  debouncedHandleResize = () => debounce(this.handleResize, 250)

  componentDidMount() {
    this.viewer && this.viewer.fitToViewer()

    // Load line data into the canvas
    const lineData = this.getLineData()
    if (lineData) this.saveableCanvas.loadSaveData(lineData)

    window.addEventListener('resize', this.debouncedHandleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleResize)
  }

  getLineData = () => {
    const { object, image } = this.props
    const images = get(object, 'metadata.images', [])
    const matchedImage = images.find(
      img => img.cloudinaryId === get(image, 'cloudinaryId')
    )
    return get(matchedImage, 'lines')
  }

  handleCloudinaryUpload = async data => {
    try {
      // Upload image to cloudinary and store the results.
      const { cloudinaryAuth = {} } = this.props
      const { CloudinarySignature = {} } = cloudinaryAuth
      const response = await cloudinary.upload(data, CloudinarySignature)
      const { public_id: cloudinaryId } = await response.json()
      return cloudinaryId
    } catch (error) {
      sendToSentry({
        prefix: 'Cloudinary upload failure',
        error,
        action: 'AnnotateImageModal.handleCloudinaryUpload',
      })
      throw error
    }
  }

  saveMetaData = async () => {
    const { object, image, onUpdateObject, onShowAlert } = this.props

    const images = object.metadata.images.map(img => {
      const imageModel = { ...img }
      if (img.cloudinaryId === image.cloudinaryId) {
        imageModel.lines = this.saveableCanvas.getSaveData()
      }
      return imageModel
    })

    const model = {
      ...object,
      metadata: {
        ...object.metadata,
        images,
      },
    }

    onUpdateObject(model)

    const drawingSrc = this.saveableCanvas.ctx.drawing.canvas.toDataURL()
    const bgImageUrl = this.getCloudinaryImage({
      cloudinaryId: get(image, 'cloudinaryId', ''),
    })
    /**
     * Create annotated PNG by combining background image
     * and drawings into a new canvas and using toDataURL
     */
    const { height, width } = this.state
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    // Background image
    const bgImg = new Image()
    // Line drawings image
    const drawingImg = new Image(width, height)

    const parsedUrl = parseCloudinaryUrlTransforms(bgImageUrl.src)
    const bgBase64 = await getBase64FromImage(parsedUrl)
    bgImg.src = bgBase64
    bgImg.onload = () => {
      // TODO: PDF's don't seem to scale properly
      // Scale background image and add it to canvas
      const s = scaleImgToCanvas(width, height, bgImg.width, bgImg.height)
      ctx.drawImage(bgImg, s.offsetX, s.offsetY, s.width, s.height)
      drawingImg.src = drawingSrc
      drawingImg.onload = async () => {
        // Add line drawing image to canvas
        ctx.drawImage(drawingImg, 0, 0)

        // Generate combined image
        const annotatedImage = canvas.toDataURL('image/png')

        // Save the image to the user's machine
        fileSaver.saveAs(
          annotatedImage,
          `metadata-${get(image, 'cloudinaryId')}.jpg`
        )

        // Upload result to Cloudinary and update object metadata
        const cloudinaryId = await this.handleCloudinaryUpload(
          annotatedImage,
          this.props.object
        )
        this.props.onUpdateObjectMetadata({
          cloudinaryId,
          objectId: this.props.object.id,
          width,
          height,
        })
      }
    }

    onShowAlert({
      text: 'Annotation saved successfully',
      type: 'success',
    })
  }

  getCloudinaryImage = ({ cloudinaryId }) => {
    return cloudinary.image(cloudinaryId, {
      format: 'png',
      secure: true,
      transformation: [{ crop: 'fill' }],
    })
  }

  handleClose = () => {
    const { history, match } = this.props
    history.push(match.url.replace(routes.modals.annotateImage, ''))
  }

  handleBrushSelection = brushRadius => this.setState({ brushRadius })

  handleUndo = () => this.saveableCanvas.undo()

  handleClear = () => {
    if (hasIn(this.saveableCanvas, 'clear')) {
      this.saveableCanvas.clear()
    }
  }

  handleColorSelection = color => this.setState({ color })

  handleResize = async () => {
    const lineData = this.getLineData()
    const width = (window.innerHeight - TOOL_BAR_HEIGHT) * ASPECT_RATIO
    const height = window.innerHeight - TOOL_BAR_HEIGHT
    await this.setState({
      height,
      width,
    })

    if (hasIn(this.saveableCanvas, 'loadSaveData')) {
      this.saveableCanvas.loadSaveData(lineData)
    }
  }

  render() {
    const { parentRoute, history, props, image } = this.props
    const cloudinaryImage = this.getCloudinaryImage({
      cloudinaryId: get(image, 'cloudinaryId', ''),
    })

    return (
      <>
        <Helmet>
          <title>{getTitle('annotateImage')}</title>
        </Helmet>

        <Modal
          size="100%"
          parentRoute={parentRoute}
          history={history}
          flushContent
          noMargin
          {...props}
        >
          <Tools
            brushRadius={this.state.brushRadius}
            color={this.state.color}
            handleBrushSelection={this.handleBrushSelection}
            handleClear={this.handleClear}
            handleClose={this.handleClose}
            handleColorSelection={this.handleColorSelection}
            handleSave={this.saveMetaData}
            handleUndo={this.handleUndo}
          />

          {cloudinaryImage && (
            <CanvasContainer
              ref={canvasContainer => (this.canvasContainer = canvasContainer)}
            >
              <CanvasDraw
                ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
                brushColor={this.state.color}
                brushRadius={this.state.brushRadius}
                lazyRadius={this.state.lazyRadius}
                loadTimeOffset={1}
                canvasHeight={this.state.height}
                canvasWidth={this.state.width}
              />
              <BackgroundImage
                src={cloudinaryImage.src}
                alt="Background Image"
              />
            </CanvasContainer>
          )}
        </Modal>
      </>
    )
  }
}

AnnotateImageModal.propTypes = {
  props: PropTypes.object,
  parentRoute: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  image: PropTypes.object,
  object: PropTypes.object,
  onUpdateObject: PropTypes.func,
  onUpdateObjectMetadata: PropTypes.func,
  onShowAlert: PropTypes.func,
  cloudinaryAuth: PropTypes.object,
}

const mapStateToProps = ({ objects }, ownProps) => {
  const queryParams = qs.parse(get(ownProps, 'location.search'), {
    ignoreQueryPrefix: true,
  })
  const objectId = get(queryParams, 'objectId')
  const objectType = get(queryParams, 'objectType')
  const imageIndex = get(queryParams, 'imageIndex')
  const object = get(objects.present, `[${objectType}][${objectId}]`)
  const metadata = get(object, 'metadata')
  const image = get(metadata, `images[${imageIndex}]`)

  return {
    image,
    object,
  }
}

const mapDispatchToProps = dispatch => ({
  onShowAlert({ text, type }) {
    dispatch(showAlert({ text, type }))
  },
  onUpdateObject(model) {
    dispatch(updateObject({ object: model }))
  },
  onUpdateObjectMetadata: attrs => {
    dispatch(addMetadataImage({ ...attrs, ignoreForCFD: true }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  graphql(GET_CLOUDINARY_SIGNATURE_QUERY, {
    options: {
      fetchPolicy: 'network-only',
    },
    name: 'cloudinaryAuth',
  }),
  withRouter
)(AnnotateImageModal)
