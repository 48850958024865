import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

// This is done to match the height of the Select input with the TextInput
// without having to make the font-size larger.
const adjustedPadding = ({ theme }) => {
  const originalPadding = theme.spacing.s.split('px')[0]
  const newPadding = parseInt(originalPadding, 10) + 1.5

  return `${newPadding}px`
}

const disabledStyles = () =>
  `
    opacity: 0.5;
  `

const Input = styled.select`
  appearance: none;
  background-color: ${theme('colors.light.bg')};
  border-radius: ${theme('radius.base')};
  border: ${theme('borderWidth')} solid
    ${props =>
      props.required ? 'rgba(255, 0, 0, 0.5)' : theme('colors.light.subdued')};
  box-shadow: none;
  color: ${theme('colors.light.fg')};
  display: inline-block;
  font-size: ${theme('fonts.sizes.s')};
  margin: 0;
  max-width: ${props => (props.size ? props.size : '200px')};
  padding-bottom: ${adjustedPadding};
  padding-left: ${adjustedPadding};
  padding-right: calc(${theme('spacing.l')});
  padding-top: ${adjustedPadding};
  outline: 0;
  vertical-align: top;
  width: 100%;
  cursor: pointer;
  line-height: normal;

  ${props => (props.disabled || !props.online) && disabledStyles()};

  &:focus {
    border-color: ${theme('colors.light.fg')};

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 ${theme('colors.light.fg')};
    }
  }
`

export default Input
