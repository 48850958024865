import React from 'react'
import PropTypes from 'prop-types'

import { firePanel, electricPanel, controlPanel } from '~/config/utilityBoxes'
import LAYER_KEYS from 'config/layerKeys'
import { trackEvent } from 'lib/analytics'
import { onClickObstructionLibrary } from 'config/analytics'

import TouchPopover from '../styled/TouchPopover'

import ItemMenu from '../ItemMenu'
import AddItemMenuItem from '../AddItemMenuItem'
import withStandardProps from '../withStandardProps'

const ObstructionsMenu = ({ flags, history, insertItem, openPanel, match }) => (
  <TouchPopover
    renderMenu={({ isDropdownVisible, handleTriggerClick }) => (
      <ItemMenu
        title="Obstructions"
        isVisible={isDropdownVisible}
        closeMenu={handleTriggerClick}
        items={[
          {
            title: 'Basic Obstruction',
            icon: {
              name: 'obstruction',
              size: '14',
            },
            onClick: () =>
              insertItem({
                layerKey: LAYER_KEYS.OBSTRUCTIONS,
                tool: 'CUBE_TOOL',
              }),
          },
          {
            title: 'Fire Panel',
            icon: {
              name: 'panelFire',
              size: '14',
            },
            onClick: () => {
              insertItem({
                layerKey: LAYER_KEYS.UTILITY_BOXES,
                tool: 'UTILITY_BOX_TOOL',
                props: firePanel,
              })
            },
          },
          {
            title: 'Electric Panel',
            icon: {
              name: 'panelElectric',
              size: '14',
            },
            onClick: () => {
              insertItem({
                layerKey: LAYER_KEYS.UTILITY_BOXES,
                tool: 'UTILITY_BOX_TOOL',
                props: electricPanel,
              })
            },
          },
          {
            title: 'Controller',
            icon: {
              name: 'panelControl',
              size: '14',
            },
            onClick: () => {
              insertItem({
                layerKey: LAYER_KEYS.UTILITY_BOXES,
                tool: 'UTILITY_BOX_TOOL',
                props: controlPanel,
              })
            },
          },
          {
            title: 'Obstruction Library',
            icon: {
              name: 'window',
              size: '16',
            },
            onClick: () => {
              openPanel({ type: 'DETAILED_OBSTRUCTION_PANEL' })
            },
          },
        ]}
      />
    )}
    renderTrigger={({ isDropdownVisible }) => (
      <AddItemMenuItem
        icon="obstruction"
        isDropdownVisible={isDropdownVisible}
        label="Obstructions"
        onClick={() => {
          trackEvent(onClickObstructionLibrary())
        }}
      />
    )}
  />
)

ObstructionsMenu.propTypes = {
  flags: PropTypes.object,
  history: PropTypes.object,
  insertItem: PropTypes.func,
  openPanel: PropTypes.func,
  match: PropTypes.object,
}

export default withStandardProps(ObstructionsMenu)
