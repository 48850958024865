import React from 'react'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'

import Button from 'components/UIKit/Button'
import Card from 'components/UIKit/Card'
import Flex, { FlexItem } from 'components/UIKit/Flex'
import H1 from 'components/UIKit/H1'
import Space from 'components/UIKit/Space'

import Dashboard from 'components/Dashboard'

/**
 * Generic error screen
 * @param {Object} props object containing title and desc for error screen message
 * @returns Error Screen
 */
function DefaultErrorScreen(props = {}) {
  function refresh(event) {
    event.preventDefault()
    window.location.reload()
  }

  function report(event) {
    event.preventDefault()
    Sentry.showReportDialog()
  }

  return (
    <Dashboard noAction>
      <Card>
        <H1>{props.title}</H1>
        <Space bottom="base">
          <p>{props.description}</p>
        </Space>
        <Flex spacingRight="s">
          {props.refresh && (
            <FlexItem>
              <Button
                primary
                onClick={refresh}
                icon="refresh"
                label="Refresh"
              />
            </FlexItem>
          )}
          {props.report && (
            <FlexItem>
              <Button onClick={report} icon="warn" label="Report Error" />
            </FlexItem>
          )}
        </Flex>
      </Card>
    </Dashboard>
  )
}

DefaultErrorScreen.propTypes = {
  description: PropTypes.string.isRequired,
  refresh: PropTypes.bool.isRequired,
  report: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

DefaultErrorScreen.defaultProps = {
  description: 'Looks like something went wrong.',
  refresh: true,
  report: true,
  title: 'Uh oh!',
}

export default DefaultErrorScreen
