import type { ChangeEventHandler } from 'react'
import { useAppSelector } from "~/store/hooks";

import Flex from 'components/UIKit/Flex'

import Input from './styled/Input'
import { RootState } from '~/store'
import { isTouchUI } from '~/store/userInterface/selectors'

export default function InputContainer({
  id,
  name,
  label = '',
  value = '',
  checked = false,
  onChange,
  disabled,
  online,
}: {
  id?: string
  name: string
  label?: string
  value?: string
  checked?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  online?: boolean
}) {
  const touchUI = useAppSelector(state => isTouchUI(state))
  return <Flex alignItems="center">
    <Input
      type="checkbox"
      name={name}
      id={id || name}
      value={value}
      checked={checked}
      onChange={onChange}
      disabled={disabled || !online}
      hasLabel={!!label}
      isTouchUI={touchUI}
      style={{ cursor: disabled ? 'default' : 'pointer' }}
    />
    {label}
  </Flex>
}
