import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Flex from 'components/UIKit/Flex'

const Container = styled(Flex)`
  bottom: ${theme('spacing.base')};
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
`

export default Container
