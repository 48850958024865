import { graphql } from '~/gql'

const HEATER_FRAGMENT = graphql(`
  fragment HeaterFragment on Product {
    id
    model
    type
    application
    category
    sortIndex
    shippingPrice {
      id
      price
    }
    defaultVariation {
      ...HeaterVariationFragment
    }
    variations {
      ...HeaterVariationFragment
    }
  }
`)

// TODO: shippingPrice deprecated

export default HEATER_FRAGMENT
