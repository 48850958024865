import styled from 'styled-components'

import { lighten } from 'lib/styleUtils'

import Popover from './Popover'

const activeBackground = props => {
  if (props.variant === 'dark' && props.border) {
    return `
      background-color: transparent;
    `
  }
  if (props.variant === 'dark') {
    return `
      background-color: ${lighten(props.theme.colors.dark.bg, 0.2)};
    `
  }

  return `
    background-color: ${lighten(props.theme.colors.light.subdued, 0.2)};
  `
}

const ClickablePopoverItem = styled(Popover)`
  align-items: center;
  cursor: default;
  display: flex;
  height: 100%;
  transition: background-color 0.3s ease-in-out;

  &:active,
  &:hover,
  &:focus {
    ${activeBackground}
  }
`

export default /** @type any */(ClickablePopoverItem)
