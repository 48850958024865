import { createGlobalStyle } from 'styled-components'

import theme from './theme'

const FilestackStyles = createGlobalStyle`

  .fsp-picker-open {
    overflow: auto !important;
  }

  .fsp-picker__close-button {
    display: none !important;
  }

  .fsp-picker, .fsp-button {
    font-family: ${theme.fonts.family.base} !important;
  }

  .fsp-picker {
    background-color: transparent !important;
    position: static !important;
  }

  .fsp-picker__footer {
    display: none !important;
  }

  .fsp-modal {
    background-color: ${theme.colors.light.bg} !important;
    border-bottom: none !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    height: 470px !important;
    margin: ${theme.spacing.base} auto ${theme.spacing.xl} auto;
    width: 680px !important;
  }

  .fsp-header {
    display: none !important;
  }

  .fsp-footer {
    background-color: ${theme.colors.light.bg} !important;
  }

  .fsp-modal__sidebar {
    background-color: ${theme.colors.light.bg} !important;
    border-radius: 0 !important;
    border-right: 1px solid ${theme.colors.light.subdued};
    box-shadow: none !important;
    display: block !important;
    top: 0 !important;
    width: 240px !important;

    &:hover {
      box-shadow: none !important;
    }
  }

  .fsp-modal__body {
    left: auto !important;
    padding-bottom: 0 !important;
    width: 440px !important;
  }

  .fsp-modal__body--transformer {
    width: calc(100% - 80px) !important;
  }

  .fsp-drop-area-container {
    padding: 0 30px !important;
  }

  .fsp-drop-area {
    background-color: ${theme.colors.light.bg} !important;
    border-radius: 4px !important;
    height: 450px !important;

    @media screen and (min-width: 980px) {
      height: 386px !important;
    }

    &:hover {
      background-color: ${theme.colors.light.subdued}; !important;
    }
  }

  .fsp-drop-area__title {
    margin-top: 0 !important;
  }

  .fsp-drop-area__subtitle {
    display: block !important;
  }

  .fsp-select-labels {
    background-image: none !important;
  }

  .fsp-button--primary, .fsp-button--auth, .fsp-badge--source {
    background-color: ${theme.colors.light.primary} !important;
    color: ${theme.colors.light.fg} !important;
    font-weight: ${theme.fonts.weights.bold} !important;
  }

  .fsp-source-list {
    height: 100% !important;
    overflow: hidden !important;
    width: 240px !important;

    @media screen and (min-width: 979px) {
      overflow-y: auto;
    }
  }

  .fsp-source-list__logout {
    color: ${theme.colors.light.fg} !important;
    font-weight: ${theme.fonts.weights.bold} !important;
  }

  .fsp-source-list__item {
    height: 50px !important;
    width: 240px !important;
  }

  .fsp-source-list__item.active {
    background-color: #dddddd !important;
  }

  .fsp-source-auth__wrapper {
    padding-top: 0 !important;
  }
`

export default FilestackStyles
