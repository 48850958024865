import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

export const switchSize = '20px'

const Button = styled.span<{ checked?: boolean }>`
  background-color: ${theme('colors.light.bg')};
  border-radius: 50%;
  box-shadow: ${theme('shadows.base')};
  cursor: pointer;
  display: block;
  height: ${switchSize};
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 0.3s ease-in-out;
  width: ${switchSize};

  ${props => props.checked && `transform: translateX(100%);`};
`

export default Button
