import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash-es/isEqual'
import debounce from 'lodash-es/debounce'
import { appConnect } from "~/store/hooks";
import { withRouter } from 'react-router-dom'
import Mousetrap from 'mousetrap'
import { ActionCreators } from 'redux-undo'
import get from 'lodash-es/get'
import hasIn from 'lodash-es/hasIn'
import * as THREE from 'three'
import ApolloClient from 'client'
import { ALL_DEFAULT_PRODUCT_VARIATIONS_QUERY } from 'client/queries'

import store from 'store'
import { addProduct, updateProducts } from 'store/objects'
import { showAlert } from 'store/alert'
import { start2DFit, zoomIn, zoomOut } from 'store/camera'
import { setCurrentLayer, getLayerKey } from 'store/layers'
import { setActiveTool } from 'store/tools'
import { updateAirflowStatus, updateHeatMapStatus } from 'store/objects'
import { toggleTouchUI, toggleDefaultUI } from 'store/userInterface'
import {
  anyObjectSelected,
  deselectObjects,
  getSelectedObjects,
} from 'store/selectedObjects/selectors'
import {
  duplicateSelectedObjects,
  deleteSelectedObjects,
} from 'store/selectedObjects'
import OBJECT_TYPES from 'config/objectTypes'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import { isOrthoModeEnabled } from 'store/tools/selectors'
import { updateAirflow } from 'lib/airflow/airflow'
import { updateHeatMap } from 'lib/heatMap/heatMap'

import Facility from './lib/facility'
import Camera from './lib/camera'
import Interactifier from './lib/interactifier'
import LineTool from './lib/lineTool'
import SelectTool from './lib/selectTool'
import MultiSelectTouchTool from './lib/multiSelectTouchTool'
import MoveTool from './lib/moveTool'
import CubeTool from './lib/cubeTool'
import CylinderTool from './lib/cylinderTool'
import DrawTool from './lib/drawTool'
import RectangleTool from './lib/rectangleTool'
import EllipseTool from './lib/ellipseTool'
import ElevationPointTool from './lib/elevationPointTool'
import ElevationLineTool from './lib/elevationLineTool'
import RoofSectionTool from './lib/roofSectionTool'
import ProductTool from './lib/productTool'
import ObstructionTool from './lib/obstructionTool'
import UtilityBoxTool from './lib/utilityBoxTool'
import ComfortPointTool from './lib/comfortPointTool'
import ComfortZoneTool from './lib/comfortZoneTool'
import EmptyTool from './lib/emptyTool'
import Util from './lib/util'
import Units from './lib/units'
import Primitives from './lib/primitives'
import FloatingElementManager from './lib/floatingElementManager'
import SceneBuilder from './lib/sceneBuilder'
import RenderLoop from './lib/renderLoop'
import ArrowRenderer from './lib/arrowRenderer'
import QuickState from './lib/quickState'
import ContextMenu from '../ContextMenu'
import { withCanvas, withThree } from './withDrawingCanvas'
import { Html } from '@react-three/drei'
import layers from '~/config/layers';

export class DrawingCanvas extends Component {
  /*
    -= properties =-

    renderer : Three.js WebGLRenderer object
    mainCanvasWidth
    mainCanvasHeight
    stats : ThreeJS rendering stats tracking
    raycaster : used to find where the mouse intersects objects in the 3d scene
  */

  static propTypes = {
    // TODO: Convert back to PropTypes.arrayOf() once store is updated
    objects: PropTypes.shape({
      id: PropTypes.string,
      position: PropTypes.object,
      segments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          startPoint: PropTypes.object,
          endPoint: PropTypes.object,
        })
      ),
      type: PropTypes.string,
    }).isRequired,
    products: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      position: PropTypes.object,
      mountingStructure: PropTypes.string,
      voltage: PropTypes.string,
    }),
    obstructions: PropTypes.shape({
      id: PropTypes.string,
      position: PropTypes.object,
    }),
    doors: PropTypes.shape({
      id: PropTypes.string,
      position: PropTypes.object,
    }),
    utilityBoxes: PropTypes.shape({
      id: PropTypes.string,
      position: PropTypes.object,
    }),
    comfortPoints: PropTypes.shape({
      id: PropTypes.string,
      position: PropTypes.object,
    }),
    comfortZones: PropTypes.shape({
      id: PropTypes.string,
      position: PropTypes.object,
    }),
    ceilings: PropTypes.shape({
      id: PropTypes.string,
    }),
    segments: PropTypes.shape({
      id: PropTypes.string,
    }),
    units: PropTypes.string,
    layers: PropTypes.shape({
      EXTERIOR_WALLS: PropTypes.shape({
        visible: PropTypes.bool,
        locked: PropTypes.bool,
        expanded: PropTypes.bool,
      }),
      INTERIOR_WALLS: PropTypes.shape({
        visible: PropTypes.bool,
        locked: PropTypes.bool,
        expanded: PropTypes.bool,
      }),
      CEILINGS: PropTypes.shape({
        visible: PropTypes.bool,
        locked: PropTypes.bool,
        expanded: PropTypes.bool,
      }),
      PRODUCTS: PropTypes.shape({
        visible: PropTypes.bool,
        locked: PropTypes.bool,
        expanded: PropTypes.bool,
      }),
      OBSTRUCTIONS: PropTypes.shape({
        visible: PropTypes.bool,
        locked: PropTypes.bool,
        expanded: PropTypes.bool,
      }),
      COMFORT_POINTS: PropTypes.shape({
        visible: PropTypes.bool,
        locked: PropTypes.bool,
        expanded: PropTypes.bool,
      }),
      AIRFLOW: PropTypes.shape({
        visible: PropTypes.bool,
        locked: PropTypes.bool,
        expanded: PropTypes.bool,
      }),
      CFD: PropTypes.shape({
        visible: PropTypes.bool,
        locked: PropTypes.bool,
        expanded: PropTypes.bool,
      }),
      HEAT_MAP: PropTypes.shape({
        visible: PropTypes.bool,
        locked: PropTypes.bool,
        expanded: PropTypes.bool,
      }),
    }).isRequired,
    currentLayer: PropTypes.string,
    activeTool: PropTypes.string.isRequired,
    activeToolProps: PropTypes.shape({}), // blackbox props
    defaultZoom: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    toolBarHeight: PropTypes.number,
    isPerspective: PropTypes.bool,
    showStats: PropTypes.bool.isRequired,
    selectedObjects: PropTypes.array,
    onSetActiveTool: PropTypes.func.isRequired,
    onUpdateProducts: PropTypes.func.isRequired,
    onAddProduct: PropTypes.func.isRequired,
    onSetCurrentLayer: PropTypes.func.isRequired,
    onSnapshot: PropTypes.func,
    shouldBeFullWidth: PropTypes.bool,
    airflow: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          airflowVelocities: PropTypes.arrayOf(
            PropTypes.arrayOf(PropTypes.number)
          ),
          evaluationHeight: PropTypes.number,
          topLeftAnchorPosition: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
          }),
          gridSize: PropTypes.number,
        })
      ),
      selected: PropTypes.object,
    }),
    heatMap: PropTypes.object,
    onShowAlert: PropTypes.func,
    onUpdateAirflowStatus: PropTypes.func,
    onUpdateHeatMapStatus: PropTypes.func,
    onToggleTouchUI: PropTypes.func,
    onToggleDefaultUI: PropTypes.func,
    onUpdateAirflow: PropTypes.func,
    onUpdateHeatMap: PropTypes.func,
    facility: PropTypes.object,
    productVariations: PropTypes.array,
    history: PropTypes.object,
    isPanelClosed: PropTypes.bool,
    isTouchUI: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    cameraLocation: PropTypes.object,
    getCameraPosition: PropTypes.bool,
    saveCameraPosition: PropTypes.func,
    online: PropTypes.bool,
  }

  static defaultProps = {
    width: 800,
    height: 600,
    toolBarHeight: 143,
    defaultZoom: 0.146,
    units: 'inches',
    showStats: false,
    products: {},
    doors: {},
    utilityBoxes: {},
    obstructions: {},
    comfortPoints: {},
    comfortZones: {},
    roofs: {},
    roofSections: {},
    ceilings: {},
    airflow: {},
    heatMap: {},
    online: true,
  }

  projectedMousePos = new THREE.Vector3(0,0,0)

  constructor(props) {
    super(props)

    Facility.current = new Facility(this.props.getState().raycaster)

    RenderLoop.subscribe(FloatingElementManager)

    this.state = {contextMenuShown: false}
  }

  componentDidMount() {
    this.props.instantiate(this)

    this.threejsInit()

    this.initScene()
    this.initInteractifier()
    this.initTools()
    this.initKeyboardShortcuts(this.props)

    this.switchTool(this.props.activeTool, this.props.activeToolProps)

    this.loadScene()
    const facilityCenter = {
      x: Facility.current.center().x,
      y: Facility.current.center().y,
    }

    if (hasIn(this.controls, 'setFacilityCenter')) {
      this.controls.setFacilityCenter(facilityCenter)
    }

    FloatingElementManager.online = this.props.online

    ArrowRenderer.init()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activeTool !== nextProps.activeTool) {
      this.switchTool(nextProps.activeTool, nextProps.activeToolProps)
    }

    // If tool props change, notify tools context props
    if (!isEqual(this.props.activeToolProps, nextProps.activeToolProps)) {
      this.activeTool.onPropsDidChange &&
        this.activeTool.onPropsDidChange(nextProps.activeToolProps)
    }

    if (nextProps.isPerspective !== QuickState.inPerspectiveMode) {
      QuickState.inPerspectiveMode = nextProps.isPerspective
    }

    // why should we rebuild when props change ??
    // it is very intensive to do so
    if (hasIn(this.sceneBuilder, 'rebuild')) {
      this.sceneBuilder.rebuild(nextProps)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.online !== this.props.online) {
      FloatingElementManager.online = this.props.online
    }
    if (this.isAirflowOutdated(prevProps)) {
      this.props.onUpdateAirflowStatus(false)

      const {
        layers,
        objects,
        products,
        obstructions,
        segments,
        onUpdateAirflow,
      } = this.props
      if (layers.AIRFLOW.visible) {
        onUpdateAirflow({ objects, products, obstructions, segments })
      }
    }
    if (this.isHeatMapOutdated(prevProps)) {
      this.props.onUpdateHeatMapStatus(false)

      const {
        layers,
        objects,
        products,
        obstructions,
        segments,
        onUpdateHeatMap,
      } = this.props
      if (layers.HEAT_MAP.visible) {
        onUpdateHeatMap({ objects, products, obstructions, segments })
      }
    }

    // Make sure context menu is closed when switching camera types
    if (prevProps.isPerspective !== this.props.isPerspective) {
      if (this.state.contextMenuShown) {
        this.setState({ contextMenuShown: false })
      }
    }
  }

  componentWillUnmount() {
    if (this.sceneBuilder) this.sceneBuilder.unload()
    this.props.instantiate(null)

    Mousetrap.unbind([
      'backspace',
      'del',
      'esc',
      'mod+d',
      'mod+z',
      'shift+mod+z',
      'mod+=',
      'mod+-',
      'mod+0',
    ])
    ArrowRenderer.destroy()
  }

  getAllSnapRegions() {
    return this.activeTool.getSnapRegions(
      Facility.current,
      this.interactifier.getObjectBeingDragged()
    )
  }

  isAirflowOutdated(prevProps) {
    // Check if products, walls, or obstructions have changed
    if (!isEqual(this.props.products, prevProps.products)) return true
    if (!isEqual(this.props.segments, prevProps.segments)) return true
    if (!isEqual(this.props.obstructions, prevProps.obstructions)) return true

    return false
  }

  isHeatMapOutdated(prevProps) {
    // Check if products, walls, or obstructions have changed
    if (!isEqual(this.props.products, prevProps.products)) return true
    if (!isEqual(this.props.segments, prevProps.segments)) return true
    if (!isEqual(this.props.obstructions, prevProps.obstructions)) return true

    return false
  }

  handleMouseMove = e => {
    if (isOrthoModeEnabled()) {
      QuickState.inOrthoMode = false
      if (getSelectedObjects().length) {
        this.interactifier.orthoModeEntered(null)
      } else {
        this.interactifier.orthoModeEntered(this.getToolOrthoReferencePoint())
      }
    }

    this.lastMouseMoveEvent = e

    this.lastMouseMoveEvent = e
    const mousePos = Util.canvasMousePos(e, e.target)

    this.interactifier.mouseMove(
      mousePos,
      this.projectedMousePos,
      this.shouldDisableSnapping(),
      this.isShiftDown
    )

    this.moveUIVisualsToTop()

    this.checkForToolPan(e.pageX, e.pageY)

    this.activeTool.toolMoved(
      this.interactifier.getProjectedMousePosition(),
      this.interactifier.getSnappedMousePosition(),
      this.interactifier.getLastSceneIntersectionPoint(),
      this.interactifier.getObjectWithCursor(),
      this.interactifier.getObjectWithSnappedCursor(),
      this.interactifier.getActiveSnapRegion()
    )

    this.setPan(this.getPanStatus())
  }

  handleContextMenu = e => {
    e.preventDefault()
    this.contextMenuPosition = this.projectedMousePos
    this.interactifier.rightClick(this.projectedMousePos)

    this.activeTool.toolFinish(
      this.interactifier.getProjectedMousePosition(),
      this.interactifier.getSnappedMousePosition(),
      this.interactifier.getLastSceneIntersectionPoint(),
      this.interactifier.getObjectWithCursor(),
      this.interactifier.getObjectWithSnappedCursor()
    )

    const isProductTool = this.activeTool.name === 'PRODUCT_TOOL'
    const selectedProducts = this.props.selectedObjects.filter(
      obj => obj.className === 'Product'
    )
    const isGridBoxWithProducts =
      this.props.selectedObjects.length &&
      this.props.selectedObjects.filter(obj => {
        let hasProducts = false
        if (obj.className === 'GridBox') {
          obj.models.forEach(model => {
            if (model.className === 'Product') hasProducts = true
          })
        }
        return hasProducts
      }).length

    if (selectedProducts.length || isProductTool || isGridBoxWithProducts) {
      this.setState({ contextMenuShown: true })
    }

    return false
  }

  handleTouchMove = e => {
    if (isOrthoModeEnabled()) {
      QuickState.inOrthoMode = false
      if (getSelectedObjects().length) {
        this.interactifier.orthoModeEntered(null)
      } else {
        this.interactifier.orthoModeEntered(this.getToolOrthoReferencePoint())
      }
    }

    const cursorPos = Util.canvasMousePos(e, e.target)

    this.interactifier.mouseMove(
      cursorPos,
      this.projectedMousePos,
      this.shouldDisableSnapping()
    )

    this.moveUIVisualsToTop()

    this.checkForToolPan(e.pageX, e.pageY)

    this.activeTool.toolMoved(
      this.interactifier.getProjectedMousePosition(),
      this.interactifier.getSnappedMousePosition(),
      this.interactifier.getLastSceneIntersectionPoint(),
      this.interactifier.getObjectWithCursor(),
      this.interactifier.getObjectWithSnappedCursor(),
      this.interactifier.getActiveSnapRegion()
    )

    this.setPan(this.getPanStatus())
  }

  handleMouseDown = e => {
    if (this.checkForR3FHandlers(e)) return
    this.interactifier.mouseDownCameraPos.copy(Camera.current.position)

    // If it's the left mouse button
    if (e.button === 0) {
      const mousePos = Util.canvasMousePos(e, e.target)

      this.haltMouseDown =
        QuickState.inPanMode ||
        this.activeTool.toolDown(
          this.interactifier.getProjectedMousePosition(),
          this.interactifier.getSnappedMousePosition(),
          this.interactifier.getLastSceneIntersectionPoint(),
          this.interactifier.getObjectWithCursor(),
          this.interactifier.getObjectWithSnappedCursor()
        )

      this.interactifier.mouseDown(
        mousePos,
        this.projectedMousePos,
        this.haltMouseDown
      )
    }
  }

  handleTouchDown = e => {
    this.interactifier.mouseDownCameraPos.copy(Camera.current.position)

    const cursorPos = Util.canvasMousePos(e, e.target)

    this.interactifier.setProjectedMouseDownPosition(this.projectedMousePos)

    this.haltMouseDown =
      QuickState.inPanMode ||
      this.activeTool.toolDown(
        this.interactifier.getProjectedMouseDownPosition(),
        this.interactifier.getSnappedMousePosition(),
        this.interactifier.getLastSceneIntersectionPoint(),
        this.interactifier.getObjectWithCursor(),
        this.interactifier.getObjectWithSnappedCursor(),
        true
      )

    this.interactifier.mouseDown(
      cursorPos,
      this.projectedMousePos,
      this.haltMouseDown,
      true
    )
  }

  handleMouseUp = (e, isDoubleClick) => {
    if (this.checkForR3FHandlers(e)) return
    // If it's the left mouse
    if (e.button === 0) {
      const mousePos = Util.canvasMousePos(e, e.target)
      this.interactifier.mouseUpCameraPos.copy(Camera.current.position)

      if (!this.haltMouseDown) {
        this.interactifier.mouseUp(
          this.projectedMousePos,
          QuickState.inMultiSelectMode,
          isDoubleClick
        )
      }

      this.setState({ contextMenuShown: false })

      this.activeTool.toolUp({
        mousePos: this.interactifier.getProjectedMousePosition(),
        snappedMousePos: this.interactifier.getSnappedMousePosition(),
        sceneIntersectionPoint: this.interactifier.getLastSceneIntersectionPoint(),
        objectUnderTool: this.interactifier.getObjectWithCursor(),
        objectUnderSnappedTool: this.interactifier.getObjectWithSnappedCursor(),
        multiSelect: QuickState.inMultiSelectMode,
        allIntersectedObjects: this.interactifier.getAllIntersectedObjects(),
      })
    }
  }

  handleTouchUp = e => {
    this.interactifier.mouseUpCameraPos.copy(Camera.current.position)

    const cursorPos = Util.canvasMousePos(e, e.target)

    if (!this.haltMouseDown) {
      this.interactifier.mouseUp(
        this.projectedMousePos,
        QuickState.inMultiSelectMode
      )
    }

    this.activeTool.toolUp({
      mousePos: this.interactifier.getProjectedMousePosition(),
      snappedMousePos: this.interactifier.getSnappedMousePosition(),
      sceneIntersectionPoint: this.interactifier.getLastSceneIntersectionPoint(),
      objectUnderTool: this.interactifier.getObjectWithCursor(),
      objectUnderSnappedTool: this.interactifier.getObjectWithSnappedCursor(),
      multiSelect: QuickState.inMultiSelectMode,
      allIntersectedObjects: this.interactifier.getAllIntersectedObjects(),
      isTouch: true,
    })
  }

  handleDoubleClick = e => {
    this.handleMouseUp(e, true)
  }

  moveUIVisualsToTop() {
    this.scene.remove(this.interactifier.obj3d)
    this.scene.add(this.interactifier.obj3d)
    this.scene.remove(this.activeTool.obj3d)
    this.scene.add(this.activeTool.obj3d)
  }

  getPanStatus() {
    if (QuickState.inPanMode) return true

    const supportedTools = [
      'LINE_TOOL',
      'RECTANGLE_TOOL',
      'ELLIPSE_TOOL',
      'CUBE_TOOL',
      'COMFORT_ZONE_TOOL',
      'CYLINDER_TOOL',
      'MULTI_SELECT_TOUCH_TOOL',
      'MOVE_TOOL',
    ]

    if (supportedTools.includes(this.activeTool.name)) return false

    if (this.activeTool.name === 'SELECT_TOOL' && !this.props.isTouchUI) {
      return false
    }

    if (anyObjectSelected()) {
      if (this.props.isTouchUI) {
        return !this.interactifier.isObjectWithCursorSelected()
      }

      return !this.interactifier.getObjectWithCursor()
    }

    return true
  }

  getAllInteractables() {
    const walls = Facility.current.getWalls()
    const products = Facility.current.getProducts()
    const utilityBoxes = Facility.current.getUtilityBoxes()
    const comfortPoints = Facility.current.getComfortPoints()
    const comfortZones = Facility.current.getComfortZones()
    const roofs = Facility.current.getRoofs()
    const roofSections = Facility.current.getRoofSections()
    const obstructions = Facility.current.getObstructions()
    const ceilings = Facility.current.getCeilings()
    const elevationPoints = roofs.reduce(
      (array, roof) => array.concat(roof.getAllElevationPoints()),
      []
    )
    const elevationLines = roofs.reduce(
      (array, roof) => array.concat(roof.elevationLines),
      []
    )

    const allInteractables = [
      ...walls,
      ...products,
      ...utilityBoxes,
      ...comfortPoints,
      ...comfortZones,
      ...roofs,
      ...roofSections,
      ...elevationPoints,
      ...elevationLines,
      ...obstructions,
      ...ceilings,
    ]

    const gridBox = Facility.current.getGridBox()
    if (gridBox) {
      allInteractables.push(gridBox)
    }

    return allInteractables
  }

  initKeyboardShortcuts(props) {
    Mousetrap.bind(['backspace', 'del'], () => {
      if (this.activeTool && this.activeTool.finishShapeDescription) {
        this.activeTool.finishShapeDescription()
      }
      store.dispatch(deleteSelectedObjects())
      return false
    })

    Mousetrap.bind('esc', () => {
      deselectObjects({})

      if (this.switchToolOnEscape) {
        this.props.onSetActiveTool({ tool: this.switchToolOnEscape })
      }
      return false
    })

    Mousetrap.bind('mod+d', () => {
      duplicateSelectedObjects()
      return false
    })

    Mousetrap.bind('mod+z', () => {
      store.dispatch(ActionCreators.undo())
      return false
    })

    Mousetrap.bind('shift+mod+z', () => {
      store.dispatch(ActionCreators.redo())
      return false
    })

    Mousetrap.bind('mod+=', () => {
      store.dispatch(zoomIn())
      return false
    })

    Mousetrap.bind('mod+-', () => {
      store.dispatch(zoomOut())
      return false
    })

    Mousetrap.bind('mod+0', () => {
      store.dispatch(start2DFit())
      return false
    })

    // Camera arrow key panning
    Mousetrap.bind('left', () => {
      this.toolPan('LEFT')
      return false
    })
    Mousetrap.bind('right', () => {
      this.toolPan('RIGHT')
      return false
    })
    Mousetrap.bind('up', () => {
      this.toolPan('UP')
      return false
    })
    Mousetrap.bind('down', () => {
      this.toolPan('DOWN')
      return false
    })

    // We use this rather than Mousetrap for the shift key since
    // other event handlers will steal the event when using Mousetrap.
    document.onkeydown = e => {
      // Shift key
      if (e.which === 16) {
        if (!this.isShiftDown) {
          this.isShiftDown = true
          QuickState.inOrthoMode = true
          QuickState.inMultiSelectMode = true
          this.interactifier.orthoModeEntered(this.getToolOrthoReferencePoint())
        }
      }
    }
    document.onkeyup = e => {
      // Shift key
      if (e.which === 16) {
        this.isShiftDown = false
        QuickState.inOrthoMode = false
        QuickState.inMultiSelectMode = false
      }
    }
  }

  getToolOrthoReferencePoint() {
    if (typeof this.activeTool.getOrthoReferencePoint === 'function') {
      return this.activeTool.getOrthoReferencePoint()
    } else {
      return null
    }
  }

  toolPan(direction) {
    if (direction === 'RIGHT') {
      this.props.getState().controls._truckInternal(+10, 0)
    } else if (direction === 'LEFT') {
      this.props.getState().controls._truckInternal(-10, 0)
    } else if (direction === 'UP') {
      this.props.getState().controls._truckInternal(0, -10)
    } else if (direction === 'DOWN') {
      this.props.getState().controls._truckInternal(0, +10)
    }
  }

  checkForToolPan(x, y) {
    const supportedToolsWithPan = [
      'SELECT_TOOL',
      'LINE_TOOL',
      'RECTANGLE_TOOL',
      'ELLIPSE_TOOL',
      'CUBE_TOOL',
      'COMFORT_ZONE_TOOL',
    ]
    const isSupportedTool = supportedToolsWithPan.includes(this.activeTool.name)
    const isCurrentlyDrawing =
      this.activeTool.startedShapeCreation || this.activeTool.startedWall
    const isCurrentlyDragging = this.interactifier.objectBeingDragged
    const rightWidth = this.props.isPanelClosed ? 20 : 300

    this.setPan(isSupportedTool && (isCurrentlyDrawing || isCurrentlyDragging))
    if (isSupportedTool && (isCurrentlyDrawing || isCurrentlyDragging)) {
      if (this.props.isTouchUI) {
        if (x < 295) this.toolPan('LEFT')
        else if (x + 100 > window.innerWidth) this.toolPan('RIGHT')
      } else {
        if (x < 20) this.toolPan('LEFT')
        else if (x + rightWidth > window.innerWidth) this.toolPan('RIGHT')
      }

      if (y < 125) this.toolPan('UP')
      else if (y + 70 > window.innerHeight) this.toolPan('DOWN')
    }
  }

  loadScene() {
    // this.controls.start(this.props.rotation)

    this.sceneBuilder = new SceneBuilder(this)
    if (hasIn(this.sceneBuilder, 'rebuild')) {
      this.sceneBuilder.rebuild(this.props)
    }

    // this.handleWindowResize()
    store.dispatch(start2DFit())
    // this.fitViewportToFacility()
    // this.containerElement.style.display = 'block'

    // Uncomment for debug rendering
    // this.showDebugRendering(this.props.objects, this.props.segments, this.props.units);
  }

  showDebugRendering(objects, segmentsObject, units) {
    const keys = Object.keys(objects)

    keys.forEach(key => {
      const object = objects[key]
      const segments = Object.keys(segmentsObject).map(
        segKey => segmentsObject[segKey]
      )

      if (
        getLayerKey(object) === LAYER_KEYS.EXTERIOR_WALLS ||
        getLayerKey(object) === LAYER_KEYS.INTERIOR_WALLS
      ) {
        const verts = []
        segments.forEach(segment => {
          const startPoint = [
            Units.unitsToNative(units, segment.startPoint.x),
            Units.unitsToNative(units, segment.startPoint.y),
          ]
          const endPoint = [
            Units.unitsToNative(units, segment.endPoint.x),
            Units.unitsToNative(units, segment.endPoint.y),
          ]
          verts.push(startPoint)
          verts.push(endPoint)
        })
        const lines = Primitives.getPolyline(verts, true, 0x0000ff, 0.5)
        this.scene.add(lines)
      }
    })
  }

  shouldDisableSnapping() {
    return !(
      this.activeTool.name === 'LINE_TOOL' ||
      this.activeTool.name === 'RECTANGLE_TOOL' ||
      this.activeTool.name === 'COMFORT_POINT_TOOL' ||
      this.activeTool.name === 'ELEVATION_POINT_TOOL' ||
      this.activeTool.name === 'ELEVATION_LINE_TOOL' ||
      this.activeTool.name === 'DOOR_TOOL' ||
      this.activeTool.name === 'UTILITY_BOX_TOOL' ||
      this.activeTool.name === 'ROOF_SECTION_TOOL' ||
      this.activeTool.name === 'PRODUCT_TOOL' ||
      this.activeTool.name === 'OBSTRUCTION_TOOL' ||
      (this.activeTool.name === 'SELECT_TOOL' &&
        this.interactifier.anyObjectBeingDragged())
    )
  }

  initTools() {
    this.moveTool = new MoveTool()
    this.activeTool = new EmptyTool()
  }

  normalizedMousePosition(mouseEvent) {
    const canvasPos = Util.canvasMousePos(mouseEvent, mouseEvent.target)

    const posVec = new THREE.Vector2()
    posVec.x = (canvasPos.x / this.mainCanvasWidth) * 2 - 1
    posVec.y = -(canvasPos.y / this.mainCanvasHeight) * 2 + 1

    return posVec
  }

  checkForR3FHandlers(mouseEvent) {
    const r3fState = this.props.getState()
    const occludingObjects = this.props.raycaster.intersectObjects(this.scene.children).filter(
      ({object}) => {
        const isVisibilityEnabled = object.visible
        const isOpaque = (object.material?.opacity ?? 0) > 0
        const isFloor = object.userData.objectType === OBJECT_TYPES.FLOOR
        return isVisibilityEnabled && isOpaque && !isFloor 
      }
    )
    const intersectedHandlers = r3fState.internal.interaction
      .reduce((intersections, object) => {
        const raycasterIntersections = this.props.raycaster.intersectObject(object).filter((intersection) => {
          // TODO: remove this check after all interactivity has been migrated to R3F
          const isHelperGrid = intersection.object.name === 'r3f-grid'
          const isBackgroundImage = intersection.object.name === 'background-image'
          const isLockedBackgroundImage = this.props.layers[LAYER_KEYS.BACKGROUND_IMAGE]?.locked
          const isBackgroundImageVisible = this.props.layers[LAYER_KEYS.BACKGROUND_IMAGE]?.visible
          return !isHelperGrid && !(isBackgroundImage && (occludingObjects.length || isLockedBackgroundImage | !isBackgroundImageVisible))
      })
        return [...intersections, ...raycasterIntersections]
      }, [])
    return intersectedHandlers.length > 0
  }

  initInteractifier() {
    this.interactifier = new Interactifier(this, this, this.props.raycaster, this.props.getState)

    this.interactifier.addEventListener(this)
    this.interactifier.addEventListener(Facility.current)
    this.scene.add(this.interactifier.obj3d)
  }

  switchTool(toolName, toolProps = {}) {
    if (toolName !== 'SELECT_TOOL' && anyObjectSelected())
      deselectObjects({ tool: toolName })
    this.props.onSetActiveTool({ tool: toolName, props: toolProps })
    this.deactivateTool()
    this.activateTool(toolName, toolProps)
  }

  deactivateTool() {
    this.scene.remove(this.activeTool.obj3d)
    RenderLoop.unsubscribe(this.activeTool)
    ArrowRenderer.unsubscribe(this.activeTool)

    if (this.activeTool.name === 'SELECT_TOOL') {
      this.setPan(false)

      this.interactifier.allowSelection = false
      this.interactifier.allowDrag = false
    }

    if (this.activeTool.deactivate !== undefined) {
      this.activeTool.deactivate()
    }
  }

  allowTouchToolToEscape() {
    const { onShowAlert } = this.props

    if (this.props.isTouchUI) {
      onShowAlert({
        text: 'Currently Drawing...',
        action: {
          text: 'Done',
          onClick: (event, hideAlert) => {
            event.preventDefault()

            this.touchObject = null
            this.switchTool('SELECT_TOOL')

            // Triggering the `mousemove` event causes any "artifacts"
            // like snap guidelines to disappear
            this.props.canvas.dispatchEvent(new Event('mousemove'))

            hideAlert()
          },
        },
        autoClose: false,
      })
    }
  }

  setPan(active) {
    this.props.setPan(active)
  }

  activateTool(toolName, toolProps) {
    this.switchToolOnEscape = false
    let cursor = 'default'

    if (toolName === 'LINE_TOOL') {
      this.activeTool = new LineTool()
      cursor = 'crosshair'
      this.setPan(false)
      this.interactifier.selectOnLastClick = true
      this.allowTouchToolToEscape()
    } else if (toolName === 'SELECT_TOOL') {
      this.activeTool = new SelectTool()
      cursor = 'default'
      this.setPan(true)
      this.interactifier.allowSelection = true
      this.interactifier.allowDrag = true
      this.interactifier.selectOnLastClick = false
    } else if (toolName === 'MULTI_SELECT_TOUCH_TOOL') {
      this.activeTool = new MultiSelectTouchTool()
      cursor = 'default'
      this.setPan(false)
      this.interactifier.allowSelection = true
      this.interactifier.allowDrag = false
      this.interactifier.selectOnLastClick = false
    } else if (toolName === 'MOVE_TOOL') {
      this.activeTool = this.moveTool
      cursor = 'move'
      this.setPan(true)
      this.interactifier.allowSelection = true
      this.interactifier.allowDrag = true
      this.interactifier.selectOnLastClick = false
    } else if (toolName === 'DRAW_TOOL') {
      let productVariations = []
      try {
        const data = ApolloClient.readQuery({
          query: ALL_DEFAULT_PRODUCT_VARIATIONS_QUERY,
        })
        const products = get(data, 'products') || []
        productVariations = products.map(p => p.defaultVariation)
      } catch (error) {
        console.warn('No product data cached!', error)
      }
      this.activeTool = new DrawTool(toolProps, this.scene, productVariations)
      this.setPan(false)
      cursor = 'crosshair'
    } else if (toolName === 'RECTANGLE_TOOL') {
      this.activeTool = new RectangleTool(toolProps)
      this.setPan(false)
      cursor = 'crosshair'
    } else if (toolName === 'CUBE_TOOL') {
      this.activeTool = new CubeTool(toolProps)
      this.setPan(false)
      cursor = 'crosshair'
    } else if (toolName === 'CYLINDER_TOOL') {
      this.activeTool = new CylinderTool(toolProps)
      this.setPan(false)
      cursor = 'crosshair'
    } else if (toolName === 'ELLIPSE_TOOL') {
      this.activeTool = new EllipseTool(toolProps)
      this.setPan(false)
      cursor = 'crosshair'
    } else if (toolName === 'ELEVATION_POINT_TOOL') {
      this.activeTool = new ElevationPointTool(toolProps)
      cursor = 'none'
      this.setPan(false)
      this.allowTouchToolToEscape()
    } else if (toolName === 'ELEVATION_LINE_TOOL') {
      this.activeTool = new ElevationLineTool(toolProps)
      cursor = 'default'
      this.setPan(false)
      this.allowTouchToolToEscape()
    } else if (toolName === 'PRODUCT_TOOL') {
      this.activeTool = new ProductTool(toolProps)
      cursor = 'default'
      this.setPan(false)
      this.switchToolOnEscape = 'SELECT_TOOL'
    } else if (toolName === 'OBSTRUCTION_TOOL') {
      this.activeTool = new ObstructionTool(toolProps)
      cursor = 'default'
      this.setPan(false)
      this.switchToolOnEscape = 'SELECT_TOOL'
    } else if (toolName === 'UTILITY_BOX_TOOL') {
      this.activeTool = new UtilityBoxTool(toolProps)
      cursor = 'default'
      this.setPan(false)
    } else if (toolName === 'COMFORT_POINT_TOOL') {
      this.activeTool = new ComfortPointTool(toolProps)
    } else if (toolName === 'COMFORT_ZONE_TOOL') {
      this.activeTool = new ComfortZoneTool(toolProps)
    } else if (toolName === 'ROOF_SECTION_TOOL') {
      this.activeTool = new RoofSectionTool(toolProps)
      cursor = 'default'
      this.setPan(false)
    } else {
      this.activeTool = new EmptyTool()
      cursor = 'default'
    }

    if (!QuickState.inPanMode) {
      this.props.setCursor(cursor === 'default' ? undefined : cursor)
    }

    if (this.activeTool.activate !== undefined) {
      this.activeTool.activate(this.interactifier.getProjectedMousePosition())
    }

    if (this.activeTool.obj3d !== undefined) {
      this.scene.add(this.activeTool.obj3d)
    }
    RenderLoop.subscribe(this.activeTool)
    ArrowRenderer.subscribe(this.activeTool)
  }

  threejsInit() {
    this.scene = new THREE.Scene()
    this.scene.userData.objectType = OBJECT_TYPES.SCENE
  }

  initScene() {
    this.scene.add(Facility.current.obj3d)

    this.scene.add(ArrowRenderer.obj3d)
  }

  /*
    Interactifier event handler
  */
  objectWithCursorChanged(lastObject, newObject) {
    if (this.activeTool.name === 'SELECT_TOOL' && !QuickState.inPanMode) {
      if (
        newObject !== undefined &&
        newObject.className !== CLASS_NAMES.WALL
      ) {
        this.props.onPointerOver()
      } else {
        this.props.onPointerOut()
      }
    }

    this.activeTool.objectWithCursor = newObject
  }

  handleContextMenuClick = type => {
    let products
    const gridBox = this.props.selectedObjects.find(
      obj => obj.className === 'GridBox'
    )

    if (gridBox) {
      // Update products inside gridbox
      products = []
      const models = get(gridBox, 'models', [])
      models.forEach(model => {
        if (model.className === 'Product') products.push(model)
      })
    } else if (this.activeTool.name === 'PRODUCT_TOOL') {
      // Update product on product tool
      const toolProps = {
        ...this.activeTool.props,
        rotation: this.activeTool.props.rotation || { x: 90, y: 0, z: 0 },
      }
      const model = ProductTool.getProductModel(
        this.projectedMousePos,
        toolProps
      )
      model.position = this.projectedMousePos
      products = [model]
    } else {
      // Update selected products
      products = this.props.selectedObjects.filter(
        obj => obj.className === 'Product'
      )
    }

    if (products.length) {
      let updatedProducts
      // If more than one product is selected update them as a
      // group, keeping their original orientation to each other
      if (products.length > 1) {
        updatedProducts = Util.getUpdatedCenteredProductGroup(type, products)
        this.props.onUpdateProducts(updatedProducts)
      } else {
        updatedProducts = Util.getUpdatedCenteredProducts(type, products)
        if (updatedProducts.length) {
          if (this.activeTool.name === 'PRODUCT_TOOL') {
            // If using the product tool add the product centered
            const model = updatedProducts[0]
            model.id = Util.guid()
            this.props.onAddProduct(model)
          } else {
            // Update the selected products centered
            this.props.onUpdateProducts(updatedProducts)
          }
        }
      }
    }

    this.setState({ contextMenuShown: false })
  }

  render() {
    const contextMenuShown = get(this.state, 'contextMenuShown')
    const isPerspective = get(this.props, 'isPerspective')

    return this.scene
      ? <>
          <primitive object={this.scene}/>
          <Html position={this.contextMenuPosition}>
            <ContextMenu
              shown={contextMenuShown && !isPerspective}
              screenPos={this.contextMenuPosition}
              clickHandler={this.handleContextMenuClick}
            />
          </Html>
        </>
      : <></>
  }
}

const mapStateToProps = ({
  camera,
  tools,
  objects,
  layers,
  selectedObjects,
  panel,
  cfd,
  userInterface,
}) => ({
  isPerspective: camera.is3D,
  activeTool: tools.activeTool,
  activeToolProps: tools.activeToolProps,
  objects: objects.present.objects,
  roofs: objects.present.roofs,
  roofSections: objects.present.roofSections,
  elevationPoints: objects.present.elevationPoints,
  elevationLines: objects.present.elevationLines,
  products: objects.present.products,
  doors: objects.present.doors,
  utilityBoxes: objects.present.utilityBoxes,
  comfortPoints: objects.present.comfortPoints,
  comfortZones: objects.present.comfortZones,
  obstructions: objects.present.obstructions,
  segments: objects.present.segments,
  ceilings: objects.present.ceilings,
  units: objects.present.units,
  airflow: objects.present.airflow,
  heatMap: objects.present.heatMap,
  backgroundImage: objects.present.backgroundImage,
  gridBox: objects.present.gridBox,
  layers: layers.layers,
  currentLayer: layers.currentLayer,
  selectedObjects,
  getCameraPosition: camera.getCameraPosition,
  shouldBeFullWidth: panel.right.visiblePanel === null,
  isPanelClosed: panel.right.collapsed,
  isTouchUI: userInterface.isTouchUI,
  isFullscreen: userInterface.isFullscreen,
  cfd,
})

const mapDispatchToProps = dispatch => ({
  onSetActiveTool({ tool, props }) {
    dispatch(setActiveTool({ tool, props }))
  },
  onSetCurrentLayer({ layerKey }) {
    dispatch(setCurrentLayer({ layerKey }))
  },
  onShowAlert(payload) {
    dispatch(showAlert(payload))
  },
  onToggleTouchUI() {
    dispatch(toggleTouchUI())
  },
  onToggleDefaultUI() {
    dispatch(toggleDefaultUI())
  },
  onUpdateProducts(products) {
    dispatch(updateProducts(products))
  },
  onAddProduct(product) {
    dispatch(addProduct({ product: product }))
  },
  async onUpdateAirflow({ products, objects, obstructions, segments }) {
    await updateAirflow(dispatch, {
      products,
      objects,
      obstructions,
      segments,
    })
  },
  onUpdateAirflowStatus(isValid) {
    dispatch(updateAirflowStatus(isValid))
  },
  async onUpdateHeatMap({ products, objects, obstructions, segments }) {
    await updateHeatMap(dispatch, {
      products,
      objects,
      obstructions,
      segments,
    })
  },
  onUpdateHeatMapStatus(isValid) {
    dispatch(updateHeatMapStatus(isValid))
  },
})

export default
  appConnect(
    mapStateToProps,
    mapDispatchToProps
  )(withCanvas(withThree(withRouter(DrawingCanvas))))
