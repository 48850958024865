import isNil from 'lodash-es/isNil'

import Unit from './unit'
import type { FormattedUnitData, UnformattedUnitData, SomeUnitData } from './unit'
import { TYPES, SYSTEMS } from '../constants'
import type { FormatOptions } from '../formatters'

class Distance<Data extends FormattedUnitData | UnformattedUnitData> extends Unit<Data> {
  constructor({ value, system }: Omit<Data, 'type'>) {
    if (isNil(value)) {
      console.warn('No value provided to Distance unit!')
    }
    if (isNil(system)) {
      console.warn('No system provided to Distance unit!')
    }
    super({ value, system, type: TYPES.DISTANCE } as Data)
  }

  clone() {
    return new Distance<Data>({
      value: this.value,
      system: this.system,
    } as Data)
  }

  native(options?: FormatOptions) {
    return this.convertedValue(SYSTEMS.NATIVE, options)
  }

  static unformat({ value, system }: Omit<FormattedUnitData, 'type'>, options?: FormatOptions) {
    return Unit.unformat({ value, system, type: TYPES.DISTANCE }, options)
  }

  static isValid({ value, system }: Omit<SomeUnitData, 'type'>) {
    return (
      typeof value === 'number' &&
      [SYSTEMS.NATIVE, SYSTEMS.IMPERIAL, SYSTEMS.METRIC].includes(system)
    )
  }
}

export type AnyDistance = Distance<FormattedUnitData | UnformattedUnitData>

export default Distance
