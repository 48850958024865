import isEmpty from 'lodash-es/isEmpty'

import {
  CLEAR_STATUS,
  SET_STATUS,
  DISABLE_CANVAS,
  ENABLE_CANVAS,
} from './actions'

const initialState = {
  type: '',
  text: '',
  disabledCanvas: false,
}

export const shouldNotChangeStatus = ({ state, action }) => {
  const isDirtyState = !isEmpty(state.type) || !isEmpty(state.text)
  const isImportantState = state.type !== 'info'
  const isUnimportantAction = action.payload.type === 'info'
  const hasImportantStatusAlready = isDirtyState && isImportantState

  return isUnimportantAction && hasImportantStatusAlready
}

export default function statusReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STATUS: {
      const hasAction = !isEmpty(action.payload)

      if (hasAction && shouldNotChangeStatus({ state, action })) {
        return state
      }

      return initialState
    }
    case SET_STATUS: {
      if (shouldNotChangeStatus({ state, action })) {
        return state
      }

      return {
        ...state,
        type: action.payload.type,
        text: action.payload.text,
      }
    }
    case DISABLE_CANVAS: {
      return {
        ...state,
        disabledCanvas: true,
      }
    }
    case ENABLE_CANVAS: {
      return {
        ...state,
        disabledCanvas: false,
      }
    }
    default: {
      return state
    }
  }
}
