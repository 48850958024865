import React from 'react'
import PropTypes from 'prop-types'

import Container from './styled/Container'
import Input from './styled/Input'
import Toggle from './styled/Toggle'
import Track from './styled/Track'
import Button from './styled/Button'
import Label from './styled/Label'
import { Spacing, Variant } from '~/config/theme'

const renderLabel = (props: { label?: string; responsive?: boolean; variant: Variant }) => {
  if (props.label === '') {
    return
  }

  if (typeof props.label === 'string') {
    return (
      <Label responsive={props.responsive} variant={props.variant}>
        {props.label}
      </Label>
    )
  }

  return props.label
}

const Switch = ({
  name,
  label = '',
  onClick = () => true,
  isChecked = false,
  responsive,
  disabled = false,
  variant = 'light',
  marginRight,
}: {
  name?: string
  label?: string
  onClick?: React.MouseEventHandler<HTMLLabelElement>
  isChecked?: boolean
  responsive?: boolean
  disabled?: boolean
  variant?: Variant
  marginRight?: Spacing
}) => (
  <Container
    disabled={disabled}
    role="presentation"
    onClick={disabled ? () => false : onClick}
    htmlFor={name}
    marginRight={marginRight}
  >
    <Input type="checkbox" name={name} value={isChecked ? 'yes' : 'no'} />
    <Toggle label={label}>
      <Track checked={isChecked} />
      <Button checked={isChecked} />
    </Toggle>
    {renderLabel({ label, responsive, variant })}
  </Container>
)

export default Switch
