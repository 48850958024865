import React, { PureComponent } from 'react'
import { shape, arrayOf, func, string, number, bool } from 'prop-types'
import { compose } from 'redux'

import { getSuggestedImageData } from 'lib/cfd'
import get from 'lodash-es/get'

import Grid, { GridItem } from 'components/UIKit/Grid'
import Icon from 'components/UIKit/Icon'
import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'

import BlankSlate from './styled/BlankSlate'
import Container from './styled/Container'
import Item from './styled/Item'
import PlanViewLabel from './styled/PlanViewLabel'

import { withImageSelector } from './selectors'
import { withUnits } from 'store/units/decorators'

import Image from './Image'
import Select from 'components/UIKit/Select'

// parsedUrl is used to cache the root url or ID,
// but preserve the full url in the img object
const parsedUrl = url => {
  if (!url) return
  // If its a screenshot - we may need to get an ID here in the future
  if (url.includes('cloudinary')) {
    return url
  }
  // If its a CFD Images

  return url.slice(url.indexOf('version'), url.indexOf('?'))
}

const getDateFromUrl = url => {
  let urlDate = url.slice(url.indexOf('date-') + 5, url.indexOf('/output/'))

  return urlDate
}

class Images extends PureComponent {
  static defaultProps = {
    limit: Infinity,
    selectedImages: [],
    imageTypes: [],
  }

  state = {
    selectedImages: {},
  }

  componentDidMount() {
    // Checking if images have been selected previously, and if images are available from CFD
    // If the S3 date for the files is newer on available images due to re-run CFD, selected images are cleared.
    if (this.props.selectedImages.length > 0 && this.props.availableImages[0]) {
      if (
        getDateFromUrl(this.props.availableImages[0]) >
        getDateFromUrl(this.props.selectedImages[0].url)
      ) {
        for (let i = 0; i < this.props.selectedImages.length; i++) {
          delete this.props.selectedImages[i]
        }
      }
    }

    const selectedImages = {}
    this.props.selectedImages.forEach(img => {
      selectedImages[parsedUrl(img.url)] = img
    })
    this.props.setImages(this.props.selectedImages)
    this.setState({
      selectedImages,
      selectedImagesPerPage: this.props.selectedImagesPerPage,
    })
  }

  handleClick = ({ url }, event) => {
    event.preventDefault()
    const { selectedImages } = this.state
    const copy = Object.entries(selectedImages).reduce((rtn, [key, img]) => {
      rtn[key] = { ...img }
      return rtn
    }, {})

    // If they have already selected the image, remove it.
    if (this.isSelectedImage(url)) {
      delete copy[parsedUrl(url)]
    } else {
      // Otherwise, we want to add it to the set, if there is space.
      if (this.canAddImage()) {
        const { naturalWidth, naturalHeight } = event.currentTarget
        const parsed = parsedUrl(url)
        copy[parsed] = {
          naturalWidth,
          naturalHeight,
          aspectRatio: naturalWidth / naturalHeight,
          url,
          root: parsed,
        }
      }
    }

    this.props.setImages(Object.values(copy))
    this.setState({
      selectedImages: copy,
    })
  }

  isSelectedImage = img => !!this.state.selectedImages[parsedUrl(img)]

  canAddImage = () =>
    Object.keys(this.state.selectedImages).length < this.props.limit

  getImageNumber = img =>
    [...Object.keys(this.state.selectedImages)].indexOf(parsedUrl(img)) + 1

  getEmptyImagesMessage() {
    const options1 = []
    const options2 = []
    this.props.imageTypes.forEach((imageType, i) => {
      let string1 = ''
      let string2 = ''
      if (i > 0 && i === this.props.imageTypes.length - 1) {
        string1 = 'or '
        string2 = 'and/or '
      }
      switch (imageType) {
        case 'snapshot':
          string1 += 'snapshots'
          string2 += 'take snapshots'
          break
        case 'object':
          string1 += 'object images'
          string2 += 'save object images'
          break
        default:
          string1 += imageType
          string2 += `save ${imageType}`
          break
      }

      options1.push(string1)
      options2.push(string2)
    })

    return [
      `You do not have any ${
        options1.length > 2 ? options1.join(', ') : options1.join(' ')
      } available.`,
      `Make sure you ${
        options2.length > 2 ? options2.join(', ') : options2.join(' ')
      } to have images available for the PDF Layout.`,
    ]
  }

  get snapshotsOnly() {
    return (
      this.props.imageTypes.includes('snapshot') &&
      this.props.imageTypes.length === 1
    )
  }

  get noImages() {
    return this.props.availableImages.length === 0
  }

  renderBlankSlate(options = null) {
    const messages = this.getEmptyImagesMessage()
    return (
      <BlankSlate>
        <Space bottom="base">
          <Icon name="image" size="40px" color="subdued" />
        </Space>
        <Space bottom="xs">
          <VariantText size="s">{get(options, 'm1', messages[0])}</VariantText>
        </Space>
        <VariantText size="s">{get(options, 'm2', messages[1])}</VariantText>
      </BlankSlate>
    )
  }

  getVariantText(image) {
    const { distanceUnits } = this.props
    const imageData = getSuggestedImageData(distanceUnits, image)

    if (!imageData) return null

    return (
      <Space bottom="base">
        <VariantText bold size="s">
          {imageData.title}
        </VariantText>
        <VariantText color="light" size="s">
          {imageData.description}
        </VariantText>
      </Space>
    )
  }

  renderImageGrid(images, options = null) {
    if (images.length === 0) return this.renderBlankSlate(options)

    return (
      <Grid>
        {images.map((img, idx) => (
          <GridItem key={idx} size="1of4">
            <Item
              active={this.isSelectedImage(img)}
              disabled={!this.isSelectedImage(img) && !this.canAddImage()}
              number={this.isSelectedImage(img) && this.getImageNumber(img)}
            >
              <Image
                url={img}
                onClick={({ url }, event) => this.handleClick({ url }, event)}
                role="img"
                aria-label={this.isSelectedImage(img) ? `Image ${idx+1}, selected as number ${this.getImageNumber(img)}` : `Image ${idx+1}`}
              />
            </Item>
            {this.getVariantText(img)}
          </GridItem>
        ))}
      </Grid>
    )
  }

  render() {
    if (this.snapshotsOnly) {
      return (
        <Container>
          {this.renderImageGrid(this.props.snapshots, {
            m1: 'You do not have any snapshots available',
            m2:
              'Make sure you take snapshots to have images available for the PDF Layout.',
          })}
        </Container>
      )
    }
    return (
      <Container>
        {this.noImages
          ? this.renderBlankSlate()
          : this.props.availableImages.length > 0 && (
              <>
                <Select
                  inline
                  name="sort"
                  label="Images per page:"
                  onChange={event => {
                    const newValue = parseInt(event.target.value)
                    this.props.setImagesPerPage(newValue)
                    this.setState({ selectedImagesPerPage: newValue })
                  }}
                  value={this.state.selectedImagesPerPage}
                  placeholder=""
                  options={[
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                    { value: 4, label: '4' },
                  ]}
                />
                <PlanViewLabel>Additional Images</PlanViewLabel>
                {this.renderImageGrid(this.props.availableImages)}
              </>
            )}
      </Container>
    )
  }
}

Images.propTypes = {
  selectedImages: arrayOf(
    shape({
      url: string,
      base64: string,
      naturalWidth: number,
      naturalHeight: number,
    })
  ),
  availableImages: arrayOf(string),
  snapshots: arrayOf(string),
  showDocumentExplanation: bool,
  limit: number,
  setImages: func,
  selectedImagesPerPage: number,
  setImagesPerPage: func,
  imageTypes: arrayOf(string),
  distanceUnits: string,
}

export default compose(withImageSelector, withUnits)(Images)
