import React from 'react'
import styled, { css } from 'styled-components'

import { theme } from 'lib/styleUtils'

import A from 'components/UIKit/A'

const padding = ({ nested, paddingRight, theme }) => {
  if (nested && !paddingRight) {
    return `padding: 0;`
  }

  if (paddingRight) {
    return `padding-right: ${theme.spacing[paddingRight]};`
  }

  return `padding: ${theme.spacing.s};`
}

const styles = css`
  align-items: center;
  border-bottom: 0;
  display: flex;
  flex: 1;
  position: relative;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  ${padding};

  ${props =>
    props.actions &&
    `border-left: 1px solid ${props.theme.colors.light.subdued};`}
  ${props => props.textRight && `text-align: right;`}
  ${props => props.noPadded && `padding: 0;`}

  &:active,
  &:focus,
  &:hover {
    > svg > path {
      fill: ${theme('colors.light.fg')};
      transition: 0.3s ease-in-out;
    }
  }

  > *:first-child {
    flex-grow: 1;
  }
`

const Container = styled(A)`
  ${styles}
`.withComponent(({ actions, ...rest }) => <A {...rest} />)

export default Container
