import get from 'lodash-es/get'
import styled from 'styled-components'

const setGradient = props => {
  const colors =
    get(props.options, 'colors') || props.theme.colors.airflow.map(c => c.color)
  const colorsString = colors.join(', ')

  return `
    background: linear-gradient(to right, ${colorsString});
  `
}

const Gradient = styled.div`
  height: 100%;
  width: 100%;

  ${props => props.options && setGradient(props)};
`

export default Gradient
