import React from 'react'
import PropTypes from 'prop-types'

import Container from './styled/Container'
import StyledInput from './styled/StyledInput'
import Label from './styled/Label'
import Step from './styled/Step'
import Steps from './styled/Steps'

const StyledRange = ({
  name,
  onChange,
  options,
  label = '',
  value,
  max,
  min,
  steps,
  disabled,
  step = 0.1,
  ...props
}) => (
  <Container popover {...props}>
    {label && <Label htmlFor={name}>{label}</Label>}
    <StyledInput
      type="range"
      name={name}
      id={name}
      disabled={disabled}
      options={options}
      value={value}
      step={step}
      min={min || 0}
      max={max || options.length - 1}
      onInput={e => onChange(e, name)}
    />
    {steps && (
      <Steps>
        {steps.map((step, i) => {
          let value
          if (i === 0) {
            value = min
          } else if (i === steps.length - 1) {
            value = max
          } else {
            value = min + ((max - min) / (steps.length - 1)) * i
          }
          return (
            <Step
              key={step + i}
              name={name}
              // onClick event simply synthesizes an event to mimic clicking on the slider.
              // It needs to be async to make sure the 'value' of the slider is updated
              // before dispatching the event.
              onClick={async e => {
                await onChange(e, name)
                let event = new Event('input', {
                  bubbles: true,
                  cancelable: true,
                })
                document.getElementById(name).dispatchEvent(event)
              }}
              value={value}
            >
              {step}
            </Step>
          )
        })}
      </Steps>
    )}
  </Container>
)

StyledRange.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.number,
  steps: PropTypes.array,
}

export default /** @type any */(StyledRange)
