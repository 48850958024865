import React, { Component } from 'react'
import { string, func, object } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import * as THREE from 'three'

import { getTitle } from 'config/titles'
import { Distance } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'
import { withUnits } from 'store/units/decorators'
import { distributeObstructions } from 'store/objects'
import { mostRecentSelectedObjectStateOfClassName } from 'store/selectedObjects/selectors'
import CLASS_NAMES from 'config/objectClassNames'

import Facility from 'components/DrawingCanvas/lib/facility'
import Util from 'components/DrawingCanvas/lib/util'
import Units from 'components/DrawingCanvas/lib/units'
import ObstructionUtil from 'components/DrawingCanvas/lib/obstructionUtil'

import Button from 'components/UIKit/Button'
import Checkbox from 'components/UIKit/Checkbox'
import DimensionInput from 'components/UIKit/DimensionInput'
import Modal from 'components/UIKit/Modal'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Space from 'components/UIKit/Space'
import Table, { Row, Cell } from 'components/UIKit/Table'
import TextField from 'components/UIKit/TextField'
import Tooltip from 'components/UIKit/Tooltip'

import Container from './styled/Container'
import Note from './styled/Note'

class DistributeObstructionModal extends Component {
  state = {
    verticalItemsCount: 1,
    verticalSpacing: new Distance({
      value: 144,
      system: SYSTEMS.IMPERIAL,
    }),
    horizontalItemsCount: 1,
    horizontalSpacing: new Distance({
      value: 144,
      system: SYSTEMS.IMPERIAL,
    }),
    loading: false,
    verticalCountAuto: false,
    horizontalCountAuto: false,
    verticalSpacingAuto: false,
    horizontalSpacingAuto: false,
    verticalEnabled: true,
    horizontalEnabled: true,
  }

  componentDidUpdate() {
    const {
      verticalItemsCount,
      verticalSpacing,
      horizontalItemsCount,
      horizontalSpacing,
    } = this.state

    if (!horizontalItemsCount && !horizontalSpacing) {
      this.setState({
        horizontalSpacing: new Distance({
          value: 1,
          system: horizontalSpacing.system,
        }),
      })
    }

    if (!verticalItemsCount && !verticalSpacing) {
      this.setState({
        verticalSpacing: new Distance({
          value: 1,
          system: verticalSpacing.system,
        }),
      })
    }
  }

  handleFocus = event => {
    event.target.select()
  }

  getVerticalAutoSpacing(count, height) {
    const facilityHeight = Facility.current.getYCompositeSize()
    const offset = count * height
    const spacing = (Units.nativeToInches(facilityHeight) - offset) / count
    return spacing
  }

  getHorizontalAutoSpacing(count, width) {
    const facilityWidth = Facility.current.getXCompositeSize()
    const offset = count * width
    const spacing = (Units.nativeToInches(facilityWidth) - offset) / count
    return spacing
  }

  handleGenerateGrid = () => {
    const { selectedObjectId, obstructions } = this.props
    const selectedObstruction = obstructions[selectedObjectId]

    if (!selectedObstruction) return

    const { parentRoute, history } = this.props

    const {
      verticalItemsCount,
      verticalSpacing,
      verticalCountAuto,
      verticalSpacingAuto,
      verticalEnabled,
      horizontalItemsCount,
      horizontalSpacing,
      horizontalCountAuto,
      horizontalSpacingAuto,
      horizontalEnabled,
    } = this.state

    let verticalCount = verticalCountAuto
      ? 50
      : parseInt(verticalItemsCount, 10) + 1
    let horizontalCount = horizontalCountAuto
      ? 50
      : parseInt(horizontalItemsCount, 10) + 1
    if (!verticalEnabled) {
      verticalCount = 1
    }
    if (!horizontalEnabled) {
      horizontalCount = 1
    }

    const newPositions = selectedObstruction.positions
    const rotatedPositions = ObstructionUtil.getRotatedPositions(
      newPositions,
      selectedObstruction.position,
      selectedObstruction.rotation && selectedObstruction.rotation.z
    )
    let { xDiff, yDiff } = ObstructionUtil.getPositionDiffs(rotatedPositions)

    let xMin = Infinity
    let yMin = Infinity
    let xMax = -Infinity
    let yMax = -Infinity
    rotatedPositions.forEach(pos => {
      xMin = Math.min(xMin, pos.x)
      yMin = Math.min(yMin, pos.y)
      xMax = Math.max(xMax, pos.x)
      yMax = Math.max(yMax, pos.y)
    })

    const vertSpacing = verticalSpacingAuto
      ? this.getVerticalAutoSpacing(verticalCount, yDiff)
      : verticalSpacing.imperial()

    const horizSpacing = horizontalSpacingAuto
      ? this.getHorizontalAutoSpacing(horizontalCount, xDiff)
      : horizontalSpacing.imperial()

    const regions = Facility.current.getExteriorWallRegions()
    const newObstructions = []

    for (let i = 0; i < verticalCount; i++) {
      for (let j = 0; j < horizontalCount; j++) {
        const isOriginObject = i === 0 && j === 0
        if (!isOriginObject) {
          const verticalSpacing = (vertSpacing + yDiff) * i * -1
          const horizontalSpacing = (horizSpacing + xDiff) * j

          const delta = new THREE.Vector3(horizontalSpacing, verticalSpacing, 0)

          regions.forEach(region => {
            let isValidPosition = true
            const positions = selectedObstruction.positions.map(pos => ({
              x: pos.x + delta.x,
              y: pos.y + delta.y,
              z: pos.z,
            }))
            const newObsRotatedPositions = rotatedPositions.map(pos => ({
              x: pos.x + delta.x,
              y: pos.y + delta.y,
              z: pos.z,
            }))
            const nativePositions = newObsRotatedPositions.map(pos => ({
              x: Units.inchesToNative(pos.x),
              y: Units.inchesToNative(pos.y),
              z: 1,
            }))
            nativePositions.forEach(pos => {
              if (!Util.isPointInPolygon(pos, region.points)) {
                isValidPosition = false
              }
            })

            if (isValidPosition) {
              newObstructions.push({
                positions,
                position: {
                  x: selectedObstruction.position.x + delta.x,
                  y: selectedObstruction.position.y + delta.y,
                  z: selectedObstruction.position.z,
                },
                id: Util.guid(),
                className: CLASS_NAMES.OBSTRUCTION,
              })
            }
          })
        }
      }
    }

    const models = []
    newObstructions.forEach(newObstruction => {
      const positions = newObstruction.positions
      positions.forEach(pos => {
        xMin = Math.min(xMin, pos.x)
        yMin = Math.min(yMin, pos.y)
        xMax = Math.max(xMax, pos.x)
        yMax = Math.max(yMax, pos.y)
      })
      models.push(Object.assign({}, selectedObstruction, newObstruction))
    })

    const gridBox = {
      id: Util.guid(),
      xMin,
      yMin,
      xMax,
      yMax,
      height: selectedObstruction.height,
      type: CLASS_NAMES.OBSTRUCTION,
      className: CLASS_NAMES.GRID_BOX,
      models: [
        ...models,
        { ...selectedObstruction, className: CLASS_NAMES.OBSTRUCTION },
      ],
    }

    this.props.onDistributeObstructions(models, gridBox)
    history.push(parentRoute)
  }

  handleSubmit = event => {
    event.preventDefault()

    this.setState({
      loading: true,
    })

    setTimeout(this.handleGenerateGrid, 100)
  }

  handleChange = ({ name, value, enabled }) => {
    // keep numeric value between 0 and 50
    const intValue = parseInt(value, 10) || 0
    let formattedValue = intValue >= 50 ? 50 : intValue < 0 ? 0 : intValue

    if (formattedValue < 1) {
      this.setState({
        [enabled]: !this.state[enabled],
      })
    }

    this.setState({ [name]: formattedValue })
  }

  handleToggleAuto = ({ event, name }) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      [name]: !this.state[name],
    })
  }

  handleToggleVerticalEnabled = () => {
    if (!this.state.horizontalEnabled) {
      return
    }
    this.setState({
      verticalEnabled: !this.state.verticalEnabled,
    })
  }

  handleToggleHorizontalEnabled = () => {
    if (!this.state.verticalEnabled) {
      return
    }
    this.setState({
      horizontalEnabled: !this.state.horizontalEnabled,
    })
  }

  render() {
    const { parentRoute, distanceUnits, selectedObjectId } = this.props
    const { loading } = this.state
    const isDisabled = loading || !selectedObjectId

    return (
      <div>
        <Helmet>
          <title>{getTitle('distributeObstructions')}</title>
        </Helmet>
        <Modal
          title="Distribute Obstruction"
          primaryAction={
            isDisabled ? (
              <Tooltip content="You need to select an object">
                <div>
                  <Button
                    primary
                    type="submit"
                    disabled={isDisabled}
                    label={loading ? 'Adding...' : 'Add'}
                  />
                </div>
              </Tooltip>
            ) : (
              <Button
                primary
                type="submit"
                label={loading ? 'Adding...' : 'Add'}
              />
            )
          }
          secondaryAction={<Button to={parentRoute} label="Cancel" />}
          flushContent
          onSubmit={this.handleSubmit}
          {...this.props}
        >
          <Container size="base">
            <Space bottom="l">
              <Table>
                <Row>
                  <Cell />
                  <Cell bordered>
                    <h2>Spacing</h2>
                    <p>Distance between items (edge-to-edge)</p>
                  </Cell>
                  <Cell bordered>
                    <h2>Count</h2>
                    <p>Number of additional items to distribute</p>
                  </Cell>
                </Row>
                <Row disabled={!this.state.verticalEnabled}>
                  <Cell>
                    <Checkbox
                      checked={this.state.verticalEnabled}
                      onChange={this.handleToggleVerticalEnabled}
                    />
                  </Cell>
                  <Cell bordered>
                    <Grid vCenter>
                      <GridItem size="2of10">
                        <img
                          src="/spacing-vertical-edge.png"
                          width={36}
                          height={36}
                          alt="Vertical Spacing"
                        />
                      </GridItem>
                      <GridItem size="3of10">
                        <DimensionInput
                          name="vertical-spacing"
                          distance={this.state.verticalSpacing}
                          units={distanceUnits}
                          disabled={
                            this.state.verticalSpacingAuto ||
                            !this.state.verticalEnabled
                          }
                          onChange={({ distance }) => {
                            this.setState({ verticalSpacing: distance })
                          }}
                        />
                      </GridItem>
                      <GridItem size="2of10">
                        {!this.state.verticalCountAuto && (
                          <Tooltip content="Automatically space based on count">
                            <Button
                              icon="wand"
                              primary={this.state.verticalSpacingAuto}
                              disabled={!this.state.verticalEnabled}
                              onClick={event =>
                                this.handleToggleAuto({
                                  event,
                                  name: 'verticalSpacingAuto',
                                })
                              }
                            />
                          </Tooltip>
                        )}
                      </GridItem>
                    </Grid>
                  </Cell>
                  <Cell bordered>
                    <Grid vCenter>
                      <GridItem size="2of10">
                        <img
                          src="/count-vertical.png"
                          width={36}
                          height={36}
                          alt="Vertical Count"
                        />
                      </GridItem>
                      <GridItem size="3of10">
                        <TextField
                          name="vertical-count"
                          onFocus={this.handleFocus}
                          onClick={this.handleFocus}
                          type={
                            this.state.verticalItemsCount ? 'number' : 'string'
                          }
                          width="60px"
                          inline
                          value={this.state.verticalItemsCount}
                          disabled={
                            this.state.verticalCountAuto ||
                            !this.state.verticalEnabled
                          }
                          onChange={event => {
                            this.handleChange({
                              name: 'verticalItemsCount',
                              value: event.target.value,
                              enabled: 'verticalEnabled',
                            })
                          }}
                        />
                      </GridItem>
                      <GridItem size="2of10">
                        {!this.state.verticalSpacingAuto && (
                          <Tooltip content="Automatically fill the available area">
                            <Button
                              icon="infinity"
                              primary={this.state.verticalCountAuto}
                              disabled={!this.state.verticalEnabled}
                              onClick={event =>
                                this.handleToggleAuto({
                                  event,
                                  name: 'verticalCountAuto',
                                })
                              }
                            />
                          </Tooltip>
                        )}
                      </GridItem>
                    </Grid>
                  </Cell>
                </Row>
                <Row disabled={!this.state.horizontalEnabled}>
                  <Cell>
                    <Checkbox
                      checked={this.state.horizontalEnabled}
                      onChange={this.handleToggleHorizontalEnabled}
                    />
                  </Cell>
                  <Cell>
                    <Grid vCenter>
                      <GridItem size="2of10">
                        <img
                          src="/spacing-horizontal-edge.png"
                          width={36}
                          height={36}
                          alt="Horizontal Spacing"
                        />
                      </GridItem>
                      <GridItem size="3of10">
                        <DimensionInput
                          name="horizontal-spacing"
                          distance={this.state.horizontalSpacing}
                          units={distanceUnits}
                          disabled={
                            this.state.horizontalSpacingAuto ||
                            !this.state.horizontalEnabled
                          }
                          onChange={({ distance }) => {
                            this.setState({ horizontalSpacing: distance })
                          }}
                        />
                      </GridItem>
                      <GridItem size="2of10">
                        {!this.state.horizontalCountAuto && (
                          <Tooltip content="Automatically space based on count">
                            <Button
                              icon="wand"
                              primary={this.state.horizontalSpacingAuto}
                              disabled={!this.state.horizontalEnabled}
                              onClick={event =>
                                this.handleToggleAuto({
                                  event,
                                  name: 'horizontalSpacingAuto',
                                })
                              }
                            />
                          </Tooltip>
                        )}
                      </GridItem>
                    </Grid>
                  </Cell>
                  <Cell>
                    <Grid vCenter>
                      <GridItem size="2of10">
                        <img
                          src="/count-horizontal.png"
                          width={36}
                          height={36}
                          alt="Horizontal Count"
                        />
                      </GridItem>
                      <GridItem size="3of10">
                        <TextField
                          name="horizontal-count"
                          onFocus={this.handleFocus}
                          onClick={this.handleFocus}
                          type={
                            this.state.horizontalItemsCount
                              ? 'number'
                              : 'string'
                          }
                          width="60px"
                          inline
                          value={this.state.horizontalItemsCount}
                          disabled={
                            this.state.horizontalCountAuto ||
                            !this.state.horizontalEnabled
                          }
                          onChange={event => {
                            this.handleChange({
                              name: 'horizontalItemsCount',
                              value: event.target.value,
                              enabled: 'horizontalEnabled',
                            })
                          }}
                        />
                      </GridItem>
                      <GridItem size="2of10">
                        {!this.state.horizontalSpacingAuto && (
                          <Tooltip
                            content="Automatically fill the available area"
                            placement="top"
                          >
                            <Button
                              icon="infinity"
                              primary={this.state.horizontalCountAuto}
                              disabled={!this.state.horizontalEnabled}
                              onClick={event =>
                                this.handleToggleAuto({
                                  event,
                                  name: 'horizontalCountAuto',
                                })
                              }
                            />
                          </Tooltip>
                        )}
                      </GridItem>
                    </Grid>
                  </Cell>
                </Row>
              </Table>
            </Space>
            <Space bottom="base">
              <Note size="s">
                <strong>Note:</strong> The distributed objects will generate
                down and to the right.
              </Note>
            </Space>
          </Container>
        </Modal>
      </div>
    )
  }
}

DistributeObstructionModal.propTypes = {
  parentRoute: string,
  addObstruction: func,
  onDistributeObstructions: func,
  history: object,
  selectedObjectId: string,
  obstructions: object,
  distanceUnits: string,
}

const mapStateToProps = ({ selectedObjects, objects }) => {
  const selectedObject = mostRecentSelectedObjectStateOfClassName(
    CLASS_NAMES.OBSTRUCTION,
    {
      selectedObjects,
    }
  )
  return {
    selectedObjectId: selectedObject && selectedObject.id,
    obstructions: objects.present.obstructions,
  }
}

const mapDispatchToProps = dispatch => ({
  onDistributeObstructions(models, gridBox) {
    dispatch(distributeObstructions({ models, gridBox }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withUnits
)(DistributeObstructionModal)
