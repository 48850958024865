import styled from 'styled-components'

import Card from '../../Card'

const Container = styled(Card)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: ${props => (props.width ? props.width : '75px')};

  > *:last-child {
    border-bottom: 0;
  }
`

export default Container
