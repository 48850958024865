import { useQuery } from '@apollo/client'
import { facilityData } from 'config/comfortPointFacilityData'
// TODO: This is a placeholder because the facility default data is stored in Celsius. The
// rest of the app is in Fahrenheit. Remove this function after refactoring the default
// data to use Fahrenheit.
import { metricToImperial } from 'store/units/converters/temperature'
import { useFacilityMatch } from 'config/routes'
import { GET_VERSION_FACILITY } from 'client/queries'
import { useMemo } from 'react'
import { CFD_OBJECTIVES } from '~/config/cfd'
import { PrimaryTypes, PrimaryUses } from '~/config/facility'
import { ResultOf } from '@graphql-typed-document-node/core/typings'

export type Facility = NonNullable<NonNullable<NonNullable<ResultOf<typeof GET_VERSION_FACILITY>['Version']>['floor']>['facility']>

export type FacilityDefaults = {
  indoorHumidity: number
  indoorSummerTemp: number
  indoorWinterHumidity: number
  indoorWinterTemp: number
  primaryObjective: keyof typeof CFD_OBJECTIVES
  primaryType: PrimaryTypes
  primaryUse: PrimaryUses
  activityLevel: number
  clothingType: number
  winterClothingType: number
}

export const getFacilityDefaultDetails = (facility: Facility | undefined): FacilityDefaults | undefined => {
  if (!facility) return
  const { primaryType, primaryUse } = facility
  const facilityType = primaryUse === 'OTHER' ? primaryType ?? 'COMMERCIAL' : primaryUse
  const defaultData = facilityData.find(({ type }) => facilityType === type)
  if (!defaultData) throw new Error(`Comfort data was not found for ${facilityType}`)
  return {
    indoorHumidity: facility.indoorHumidity ?? defaultData.humidity,
    indoorSummerTemp: facility.indoorSummerTemp ?? metricToImperial(defaultData.airTemp),
    indoorWinterHumidity: facility.indoorWinterHumidity ?? defaultData.indoorWinterHumidity,
    indoorWinterTemp: facility.indoorWinterTemp ?? metricToImperial(defaultData.winterAirTemp),
    primaryObjective: facility.primaryObjective?.find(it => it !== null)! as 'COOLING' | 'DESTRATIFICATION' | 'HEATING',
    primaryType: facility.primaryType!,
    primaryUse: facility.primaryUse ?? defaultData.type,
    activityLevel: defaultData.activityLevel,
    clothingType: defaultData.clothingType,
    winterClothingType: defaultData.winterClothingType,
  }
}

export const useFacilityDefaults = () => {
  const versionId = useFacilityMatch().versionId ?? ''
  const { data, loading, error } = useQuery(GET_VERSION_FACILITY, {
    variables: { versionId: versionId },
    skip: !versionId,
  })
  return {
    loading,
    error,
    data: useMemo(() => getFacilityDefaultDetails(data?.Version?.floor?.facility ?? undefined), [data]),
  }
}
