import { manDoor, dockDoor, Door } from '~/config/doors'
import LAYER_KEYS, { LayerKeys } from '~/config/layerKeys'
import { MenuDropDownButton } from '../components/MenuDropDownButton'
import { MenuItem } from '~/ui/Menu'
import { useAppDispatch } from '~/store/hooks'
import { setCurrentLayer } from '~/store/layers'
import { setActiveTool } from '~/store/tools'
import { Icon } from '~/ui/Icon'

export default function WallsAndDoorsMenu() {
  const dispatch = useAppDispatch()
  const action = (layerKey: LayerKeys, tool: string, props?: Door) => () => {
    dispatch(setCurrentLayer({ layerKey }))
    dispatch(setActiveTool({ tool, props }))
  }
  return (
    <MenuDropDownButton label="Walls & Doors" icon="interiorWall">
      <MenuItem onAction={action(LAYER_KEYS.EXTERIOR_WALLS, 'LINE_TOOL')} variant="square">
        <Icon name='square' size="14"/>
        Exterior Wall
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.INTERIOR_WALLS, 'LINE_TOOL')} variant="square">
        <Icon name='interiorWall' size="14"/>
        Interior Wall
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.DOORS, 'DOOR_TOOL', manDoor)} variant="square">
        <Icon name='doorMan' size="14"/>
        Man Door
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.DOORS, 'DOOR_TOOL', dockDoor)} variant="square">
        <Icon name='doorDock' size="14"/>
        Dock Door
      </MenuItem>
    </MenuDropDownButton>
  )
}
