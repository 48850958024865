import React from 'react'
import PropTypes from 'prop-types'
import { Query } from '@apollo/client/react/components'

import Alert from 'components/UIKit/Alert'
import Loader from 'components/UIKit/Loader'

function DefaultQueryProvider(props = {}) {
  return (
    <Query query={props.query} variables={props.variables}>
      {({ loading, error, data }) => {
        if (!props.passThrough) {
          if (loading) return <Loader label={props.loadingText} />
          if (error) return <Alert type="error" text={error} />
        }

        return props.children({ loading, error, data })
      }}
    </Query>
  )
}

DefaultQueryProvider.propTypes = {
  children: PropTypes.func,
  loadingText: PropTypes.string,
  passThrough: PropTypes.bool,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
}

DefaultQueryProvider.defaultProps = {
  loadingText: 'Loading...',
  passThrough: false,
  variables: null,
}

export default DefaultQueryProvider
