const zoom = {
  // Perspective (3D) Camera Multiplier - used to get the relative 3D zoom
  perspectiveMultiplier: 0.004,

  // Orthographic (2D) Camera Multiplier - used to get the relative 2D zoom
  orthographicMultiplier: 7.65,

  // Perspective Camera Divider - used to find the zoom ratio by dividing by
  // the perspective cameras z axis
  persepectiveDivider: 718,

  // Base number to subtract from to find the relative zoom value when the
  // ratio is less than 100%
  negativeZoomBase: 960,

  // Base number to divide by to find the relative zoom value when the ratio
  // is greater than 100%
  positiveZoomBase: 700,

  // Base number for Comfort Points scale
  comfortPointBase: 50,

  // Comfort Points Multiplier for scale with Orthographic (2D) Camera
  comfortPointMultiplier: 0.75,

  // Scale factor for comfort point scaling with Perspective (3D) Camera
  comfortPointScaleFactor: 0.5,

  // Comfort Point default depth with Perspective (3D) Camera
  comfortPointDefaultDepth: 15,

  // Base number for CFD grid density
  CFDGridBase: 25,

  // CFD Grid Multiplier for scale with Orthographic (2D) Camera
  CFDGridZoomBase: 25,

  // Scale factor for CFD grid scaling with Perspective (3D) Camera
  CFDGridScaleFactor: 650,

  // Ratio for determining grid density adjustment
  CFDGridRatio: 0.8,
}

export default zoom
