// https://github.com/react-ga/react-ga#readme
// Unique Google Analytics tracking code for SpecLab
export const GA_TRACKING_CODE = 'UA-734189-35'

// Below are all currently tracked event args

// Auto Dimensions
export const onGenerateAutoDimensions = () => ({
  category: 'Auto Dimensions',
  action: 'Auto Dimensions Generated',
})

export const onMeasurementLabelDrag = () => ({
  category: 'Auto Dimensions',
  action: 'Measurement Label Dragged',
})
/* ---- */

// Comfort Zones
export const onAddComfortZone = () => ({
  category: 'Comfort Zones',
  action: 'Add',
})
/* ---- */

// Create Facility
export const onCreateFacility = () => ({
  category: 'Create Facility',
  action: 'Create Facility',
})

export const onSkipWizard = () => ({
  category: 'Create Facility',
  action: 'Skip Wizard',
  label: 'Onboarding',
})

export const onNextOnboardingStep = () => ({
  category: 'Create Facility',
  action: 'Next',
  label: 'Onboarding',
})

export const onFinishOnboarding = () => ({
  category: 'Create Facility',
  action: 'Finish',
  label: 'Onboarding',
})
/* ---- */

// Facility Templates
export const onFacilityTemplateCreated = () => ({
  category: 'Facility Templates',
  action: 'Created Template',
})

export const onFacilityTemplateViewed = () => ({
  category: 'Facility Templates',
  action: 'Viewed Template',
})

export const onFacilityTemplateCopied = () => ({
  category: 'Facility Templates',
  action: 'Copied Template',
})

export const onFacilityTemplateEdited = () => ({
  category: 'Facility Templates',
  action: 'Edited Template',
})

export const onFacilityTemplateDeleted = () => ({
  category: 'Facility Templates',
  action: 'Deleted Template',
})
/* ---- */

// Detailed Obstructions
export const onClickObstructionLibrary = () => ({
  category: 'Detailed Obstructions',
  action: 'View',
})

export const onSearchDetailedObstructions = (label: string) => ({
  category: 'Detailed Obstructions',
  action: 'Search',
  label,
})

export const onAddDetailedObstruction = (label: string) => ({
  category: 'Detailed Obstructions',
  action: 'Add',
  label,
})
/* ---- */

// Roof Templates
export const onCreateGableRoof = () => ({
  category: 'Roof Templates',
  action: 'Create Gable Roof',
})

export const onCreateMonoslopeRoof = () => ({
  category: 'Roof Templates',
  action: 'Create Monoslope Roof',
})
/* ---- */

// Shape Templates
export const onCreateRectangle = () => ({
  category: 'Shape Templates',
  action: 'Create Rectangle',
})

export const onCreateLShape = () => ({
  category: 'Shape Templates',
  action: 'Create L-Shape',
})
/* ---- */

// Trace Image
export const onAddImage = () => ({
  category: 'Trace Image',
  action: 'Add Image',
})
/* ---- */

// User Profile Info
export const onUpdateUserProfileInfo = () => ({
  category: 'User Profile',
  action: 'Updated User Profile Info',
})

export const onChooseToUpdateProfileInfo = () => ({
  category: 'User Profile',
  action: 'Choose to Update Profile Info',
})

export const onDismissUpdateProfileInfo = () => ({
  category: 'User Profile',
  action: 'Dismiss Update Profile Info',
})
/* ---- */
