import { ThreeEvent, useThree } from "@react-three/fiber"
import { Line3, Mesh, PlaneGeometry, Vector2, Vector2Like, Vector3 } from "three"
import { useAppSelector } from "~/store/hooks"
import { CSSProperties, useCallback, useMemo, useState, type MouseEvent } from 'react'
import { modelToUI, objectModelToUI } from "../util/units"
import { useCursor as useCursorDrei } from "@react-three/drei"

export function useCursor(cursor: CSSProperties['cursor'] = 'pointer') {
  const [hovered, setHovered] = useState(false)
  useCursorDrei(hovered, cursor)
  const onPointerOver = useCallback((_: ThreeEvent<PointerEvent>) => setHovered(true), [])
  const onPointerOut = useCallback((_: ThreeEvent<PointerEvent>) => setHovered(false), [])
  return { onPointerOver, onPointerOut }
}

const plane = new PlaneGeometry(5000, 5000)
const planarMesh = new Mesh(plane)

/**
 * Hook that returns a function that can project a mouse event onto the floor
 * of the facility.
 */
export function useProject(): (event: MouseEvent) => Vector2Like | undefined {
  const raycaster = useThree(it => it.raycaster)
  const { width, height } = useThree(it => it.size)
  const camera = useThree(it => it.camera)

  // force a rerender whenever we switch between perspectives
  useAppSelector(it => it.camera.is3D)

  return (event: MouseEvent) => {
    const x = ( event.nativeEvent.offsetX / width ) * 2 - 1
    const y = -( event.nativeEvent.offsetY / height ) * 2 + 1
    raycaster.setFromCamera(new Vector2(x, y), camera)
    const intersections = raycaster.intersectObject(planarMesh)
    if (intersections.length > 0) {
      return { x: intersections[0].point.x, y: intersections[0].point.y }
    }
  }
}

/**
 * Hook that returns snaplines for the wall segments in the facility.
 */
export function useWallSegmentSnapLines() {
  const segments = useAppSelector(it => it.objects.present.segments)
  const lines = useMemo(() => {
    return Object.entries(segments).map(([id, segment]) => {
      const a = new Vector3().copy(objectModelToUI(segment.startPoint))
      const b = new Vector3().copy(objectModelToUI(segment.endPoint))
      const line = new Line3(a, b)
      return { id, line }
    })
  }, [segments])
  return lines
}

/**
 * Hook that returns snaplines for the exterior/interiors of the wall segments in the facility.
 */
export function useWallSegmentEdgeSnapLines() {
  const segments = useAppSelector(it => it.objects.present.segments)
  const lines = useMemo(() => {
    return Object.entries(segments).flatMap(([id, segment]) => {
      const insetA = new Vector3().copy(objectModelToUI(segment.insetPoints.start))
      const insetB = new Vector3().copy(objectModelToUI(segment.insetPoints.end))
      const insetLine = new Line3(insetA, insetB)

      const outsetA = new Vector3().copy(objectModelToUI(segment.outsetPoints.start))
      const outsetB = new Vector3().copy(objectModelToUI(segment.outsetPoints.end))
      const outsetLine = new Line3(outsetA, outsetB)
      return [{ id, line: insetLine }, { id, line: outsetLine }]
    })
  }, [segments])
  return lines
}

/**
 * Hook that returns snappoints for products in the facility
 */
export function useProductSnapPoints() {
  const products = useAppSelector(it => it.objects.present.products)
  const points = useMemo(() => {
    return Object.entries(products).flatMap(([id, product]) => {
      const radius = modelToUI(product.size / 2)
      const cx = modelToUI(product.position.x)
      const cy = modelToUI(product.position.y)

      return [
        { id, point: new Vector3(cx + radius, cy) },
        { id, point: new Vector3(cx - radius, cy) },
        { id, point: new Vector3(cx, cy + radius) },
        { id, point: new Vector3(cx, cy - radius) },
        { id, point: new Vector3(cx, cy) },
      ]
    })
  }, [products])
  return points
}
