import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Thumbnail = styled.img`
  background-color: ${theme('colors.light.bg')};
  border: 1px solid ${theme('colors.light.subdued')};
  border-radius: ${theme('radius.base')};
  cursor: pointer;
  height: 100px;
  margin-top: ${theme('spacing.base')};
  object-fit: contain;
  padding: ${theme('spacing.s')};
  transition: border-color 0.3s ease-in-out;
  width: 100%;
  &:hover {
    border-color: ${theme('colors.light.fg')};
  }
`

export default Thumbnail
