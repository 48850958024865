import withProps from 'recompact/withProps'
import { compose } from 'redux'
import sortBy from 'lodash-es/sortBy'
import { showProductSize } from 'lib/utils'
import { withUnits } from 'store/units/decorators'
import Select from 'components/UIKit/Select'

const withVariations = withProps(ownProps => {
  const variations = ownProps.variations

  return {
    disabled: ownProps.disabled,
    options: [
      { value: '', label: 'Choose a size...', disabled: true },
      ...sortBy(variations, 'size')
        .sort((a, b) => {
          if (a.label?.split('-')[0] === 'UH') {
            return (
              parseInt(a.label.split('-')[1]) - parseInt(b.label.split('-')[1])
            )
          } else {
            return 0
          }
        })
        .map((o, i) => {
          let label = o.label
          if (!label) {
            label = showProductSize(o.size, ownProps.distanceUnits)
          }
          return {
            value: o.id,
            label,
          }
        }),
    ],
  }
})

const DiameterSelect = compose(withUnits, withVariations)(Select)
DiameterSelect.displayName = 'DiameterSelect'

export default /** @type {any} */(DiameterSelect)
