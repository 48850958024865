import React, { Component } from 'react'
import { object, func, string, bool } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { withNetwork } from 'networkProvider'

import get from 'lodash-es/get'
import isEqual from 'lodash-es/isEqual'
import omit from 'lodash-es/omit'

import { withUnits } from 'store/units/decorators'
import { SYSTEMS } from 'store/units/constants'
import { Distance } from 'store/units/types'
import { updateUtilityBox } from 'store/objects'
import { addLoadingDockUtilityBox } from 'store/loadingDock'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { isTouchUI } from 'store/userInterface/selectors'
import { SELECTED_UTILITY_BOX_PANEL } from 'store/panel/types'
import CLASS_NAMES from 'config/objectClassNames'

import DimensionInput from 'components/UIKit/DimensionInput'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'

import AddInstallInfoButton from 'components/AddInstallInfoButton'
import MetadataSection from 'components/MetadataSection'
import SaveConfiguration from 'components/SaveConfiguration'
import { ControllerTypeSelect } from './ControllerTypeSelect'

import * as THREE from 'three'

// These are the utility-box types we need to show the
// "Add Install Info" button for.
const ADD_INSTALL_TYPES = ['electricPanel']

class SelectedUtilityBoxPanel extends Component {
  handleSaveConfig = onSuccess => {
    const { selectedObject, onAddLoadingDockUtilityBox } = this.props
    const utilityBoxType = get(selectedObject, 'utilityBoxType')

    if (utilityBoxType) {
      onAddLoadingDockUtilityBox(selectedObject)
      if (onSuccess) onSuccess()
    }
  }

  getConfigFromObject(obj) {
    if (!obj) return

    return {
      ...omit(obj, ['id', 'position', 'layerKey', 'metadata', 'rotation']),
    }
  }

  isDupeConfig() {
    const { selectedObject, utilityBoxes } = this.props
    if (!utilityBoxes) return false
    const selectedUtilityBoxConfig = this.getConfigFromObject(selectedObject)
    let isDupe = false

    Object.keys(utilityBoxes).forEach((key, index) => {
      const config = this.getConfigFromObject(utilityBoxes[key])
      if (isEqual(config, selectedUtilityBoxConfig)) isDupe = true
    })

    return isDupe
  }

  renderContent() {
    const {
      selectedObject,
      onUpdateUtilityBox,
      distanceUnits,
      isTouchUI,
      online,
    } = this.props
    const height = selectedObject && selectedObject.height
    const centerPointHeight = selectedObject && selectedObject.centerPointHeight
    const width = selectedObject && selectedObject.width
    const inputWidth = isTouchUI ? '140px' : '70px'
    const type = selectedObject && selectedObject.utilityBoxType
    const rotation = get(selectedObject, 'rotation.z', 0)
    const rotationValue = Math.round(THREE.MathUtils.radToDeg(rotation))
    const detailedLabel = get(selectedObject, 'detailedLabel', '')
    const isController =
      get(selectedObject, 'utilityBoxType') === 'controlPanel'
    const controllerType = get(selectedObject, 'controllerType', '')

    return (
      <>
        <SaveConfiguration
          selectedObjectId={selectedObject.id}
          isDupeConfig={this.isDupeConfig()}
          onConfigurationSave={onSuccess => this.handleSaveConfig(onSuccess)}
        />
        <PanelSection>
          <>
            <Space bottom="base">
              <DimensionInput
                label="Height"
                labelWidth="70px"
                width={inputWidth}
                name="height"
                distance={
                  new Distance({
                    value: height,
                    system: SYSTEMS.IMPERIAL,
                  })
                }
                units={distanceUnits}
                tabIndex={1}
                onChange={({ distance }) => {
                  onUpdateUtilityBox({
                    ...selectedObject,
                    height: distance.imperial() || 0,
                  })
                }}
              />
            </Space>
            <Space bottom="base">
              <DimensionInput
                label="Width"
                labelWidth="70px"
                width={inputWidth}
                name="width"
                distance={
                  new Distance({
                    value: width,
                    system: SYSTEMS.IMPERIAL,
                  })
                }
                units={distanceUnits}
                tabIndex={2}
                onChange={({ distance }) => {
                  onUpdateUtilityBox({
                    ...selectedObject,
                    width: distance.imperial() || 0,
                  })
                }}
              />
            </Space>
            <Space bottom="base">
              <DimensionInput
                inline
                label="Height From Floor"
                labelWidth="70px"
                name={'height'}
                units={distanceUnits}
                distance={
                  new Distance({
                    value: centerPointHeight,
                    system: SYSTEMS.IMPERIAL,
                  })
                }
                onChange={({ distance }) => {
                  const value = distance.imperial() || 0
                  onUpdateUtilityBox({
                    ...selectedObject,
                    centerPointHeight: value,
                  })
                }}
              />
            </Space>
            <Space bottom="base">
              <TextField
                inline
                label="Rotation"
                labelWidth="70px"
                value={rotationValue}
                overrideValue={rotationValue}
                type="number"
                onChange={event => {
                  const value = event.target.value || 0
                  const z = THREE.MathUtils.degToRad(value)
                  const newRotation = { x: 0, y: 0, z }
                  onUpdateUtilityBox({
                    ...selectedObject,
                    rotation: newRotation,
                  })
                }}
              />
            </Space>
            {isController && (
              <Space bottom="base">
                <ControllerTypeSelect
                  label="Controller Type"
                  value={controllerType}
                  onChange={e => {
                    const newType = e.target.value
                    onUpdateUtilityBox({
                      ...selectedObject,
                      controllerType: newType,
                    })
                  }}
                />
              </Space>
            )}
            <Space bottom="base">
              <TextField
                label="Detailed Label"
                value={detailedLabel || ''}
                overrideValue={detailedLabel || ''}
                inline
                onChange={e => {
                  const newLabel = e.target.value || ''
                  onUpdateUtilityBox({
                    ...selectedObject,
                    detailedLabel: newLabel,
                  })
                }}
              />
            </Space>
            {ADD_INSTALL_TYPES.includes(type) ? (
              <AddInstallInfoButton
                type={type}
                selectedObject={selectedObject}
                online={online}
              />
            ) : null}
          </>
        </PanelSection>
        <MetadataSection object={selectedObject} onBlur={onUpdateUtilityBox} />
      </>
    )
  }
  render() {
    const { selectedObject, alignment, isTouchUI } = this.props
    const label = get(selectedObject, 'label', 'Utility Box')

    return (
      <Panel
        title={label}
        alignment={alignment}
        docked
        panelKey={SELECTED_UTILITY_BOX_PANEL}
        hasToolbar={isTouchUI}
      >
        {selectedObject ? this.renderContent() : null}
      </Panel>
    )
  }
}

SelectedUtilityBoxPanel.propTypes = {
  alignment: string,
  selectedObject: object,
  utilityBoxes: object,
  onUpdateUtilityBox: func,
  onAddLoadingDockUtilityBox: func,
  distanceUnits: string,
  isTouchUI: bool,
  online: bool,
}

SelectedUtilityBoxPanel.defaultProps = {
  alignment: 'right',
}

const mapStateToProps = ({
  objects,
  selectedObjects,
  loadingDock,
  ...store
}) => {
  const selectedObject = mostRecentSelectedObjectOfClassName(
    CLASS_NAMES.UTILITY_BOX,
    {
      selectedObjects,
      objects,
    }
  )
  const utilityBoxes = loadingDock.utilityBoxes
  return {
    isTouchUI: isTouchUI(store),
    selectedObject,
    utilityBoxes,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateUtilityBox(utilityBox) {
    dispatch(updateUtilityBox({ utilityBox }))
  },
  onAddLoadingDockUtilityBox(door) {
    dispatch(addLoadingDockUtilityBox(door))
  },
})

export default compose(
  withUnits,
  withNetwork,
  appConnect(mapStateToProps, mapDispatchToProps)
)(SelectedUtilityBoxPanel)
