import { SET_AUTH_DATA } from './actions'

const initialState = {
  userId: /** @type {string|null} */(null),
  name: /** @type {string|null} */(null),
  email: /** @type {string|null} */(null),
  avatar: /** @type {string|null} */(null),
  state: /** @type {string|null} */(null),
  idToken: /** @type {string|null} */(null),
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_DATA: {
      const payload = action.payload || initialState
      return {
        ...state,
        ...payload,
      }
    }
    default: {
      return state
    }
  }
}
