import React from 'react'
import { Helmet } from 'react-helmet'

import { getStepNumber } from 'config/onboarding'
import { getTitle } from 'config/titles'

import Onboarding from 'components/Onboarding'
import AppContainer from 'components/App/styled/AppContainer'

const OnboardingCeilingsScreen = ({ ...props }) => (
  <AppContainer>
    <Helmet>
      <title>{getTitle('ceilings')}</title>
    </Helmet>
    <Onboarding
      {...props}
      step={getStepNumber('Ceilings')}
      message="Draw and configure the ceilings of your facility."
    />
  </AppContainer>
)

export default OnboardingCeilingsScreen
