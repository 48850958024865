import { graphql } from '~/gql'

const GET_SALESFORCE_INSTANCE_URL_QUERY = graphql(`
  query SalesforceInstanceUrl {
    Salesforce {
      instanceUrl
    }
  }
`)

export default GET_SALESFORCE_INSTANCE_URL_QUERY
