import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'
import { withRouter } from 'react-router-dom'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'

import cloudinary from 'config/cloudinary'

import { ALL_DEFAULT_PRODUCT_VARIATIONS_QUERY } from 'client/queries'
import { DefaultQueryProvider } from 'client/providers'
import { setActiveTool } from 'store/tools'

import Util from 'components/DrawingCanvas/lib/util'

import TouchPopover from '../styled/TouchPopover'
import ItemMenu from '../ItemMenu'
import AddItemMenuItem from '../AddItemMenuItem'

const items = (productVariations, addProduct) =>
  (productVariations || []).map(productVariation => {
    return {
      title: productVariation.product.model,
      image: {
        src: cloudinary.url(
          `products/${productVariation.product.cloudinaryId}.jpg`,
          {
            width: 60,
            height: 60,
            secure: true,
          }
        ),
        alt: 'placeholder',
        width: '30',
        height: '30',
      },
      onClick: () => {
        addProduct({
          product: {
            ...productVariation,
            id: Util.guid(),
            variationId: productVariation.id,
            voltageId: get(productVariation, 'voltages[0].id'),
            voltage: get(productVariation, 'voltages[0].inputPower'),
            mountingOptionAdderId: get(
              productVariation,
              'mountingOptionAdders[0].id'
            ),
          },
        })
      },
    }
  })

const renderProductPopoverItem = ({
  loading,
  productVariations,
  match,
  addProduct,
  onClickMenuItem,
}) => (
  <TouchPopover
    renderMenu={({ isDropdownVisible, handleTriggerClick }) => (
      <ItemMenu
        title={loading ? 'Loading...' : 'Products'}
        isVisible={isDropdownVisible}
        items={loading ? [] : items(productVariations, addProduct)}
        closeMenu={handleTriggerClick}
      />
    )}
    renderTrigger={({ isDropdownVisible }) => (
      <AddItemMenuItem
        icon="fan"
        label={loading ? 'Loading...' : 'Products'}
        isDropdownVisible={isDropdownVisible}
      />
    )}
  />
)

const ProductsMenu = ({
  match,
  productVariations,
  addProduct,
  onClickMenuItem,
  flags,
}) => (
  <DefaultQueryProvider
    query={ALL_DEFAULT_PRODUCT_VARIATIONS_QUERY}
    passThrough={true}
  >
    {({ loading, error, data }) => {
      let productVariations = (data?.products ?? []).map(p => p.defaultVariation)
      if (loading) {
        return renderProductPopoverItem({
          loading,
          productVariations,
          match,
          addProduct,
          onClickMenuItem,
          flags,
        })
      }

      productVariations = productVariations || []

      return renderProductPopoverItem({
        loading,
        productVariations,
        match,
        addProduct,
        onClickMenuItem,
        flags,
      })
    }}
  </DefaultQueryProvider>
)

const propTypes = {
  match: PropTypes.object,
  productVariations: PropTypes.array,
  addProduct: PropTypes.func,
  onClickMenuItem: PropTypes.func,
  flags: PropTypes.object,
  loading: PropTypes.bool,
}

ProductsMenu.propTypes = propTypes
renderProductPopoverItem.propTypes = propTypes

const mapStateToProps = ({ panel, flags }) => ({
  flags,
})

const mapDispatchToProps = dispatch => ({
  addProduct({ product }) {
    dispatch(setActiveTool({ tool: 'PRODUCT_TOOL', props: product }))
  },
})

export default compose(
  withRouter,
  appConnect(mapStateToProps, mapDispatchToProps)
)(ProductsMenu)
