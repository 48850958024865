import { ALL_FACILITIES_QUERY } from 'client/queries'
import { FACILITY_PAGE_LIMIT } from 'client/decorators/withInitialData'

const handleArchiveFacility = ({ id, isArchived, mutate, ownProps }) =>
  mutate({
    variables: { id, isArchived },
    optimisticResponse: {
      __typename: 'Mutation',
      archiveFacility: {
        __typename: 'Facility',
        id,
      },
    },
    refetchQueries: [
      {
        query: ALL_FACILITIES_QUERY,
        name: 'allFacilities',
        variables: {
          ...ownProps.variables,
          filter: null,
          limit: FACILITY_PAGE_LIMIT,
        },
      },
      {
        query: ALL_FACILITIES_QUERY,
        name: 'allFacilities',
        variables: {
          ...ownProps.variables,
          filter: { isArchived: true },
          limit: FACILITY_PAGE_LIMIT,
        },
      },
    ],
  })

export default handleArchiveFacility
