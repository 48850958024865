import defaultTo from 'lodash-es/defaultTo'
import get from 'lodash-es/get'
import Color from 'color'
import Util from './util'
import Units from './units'
import Primitives from './primitives'
import ElevationPoint from './elevationPoint'
import ElevationLine from './elevationLine'
import getRenderOrder from 'config/canvasRenderOrder'
import OBJECT_TYPES from 'config/objectTypes'
import CLASS_NAMES from 'config/objectClassNames'
import LAYER_KEYS from 'config/layerKeys'
import theme from 'config/theme'

import store from 'store'
import { objectIsSelected } from 'store/selectedObjects/selectors'
import { clearStatus, setStatus } from 'store/status'
import { getThreeHexFromTheme } from 'lib/utils'

import * as THREE from 'three'
import CLICK_PRIORITY from 'config/clickPriority'

class Roof {
  static Z_FIGHT_SHIFT = Units.inchesToNative(0.5)

  constructor(model, units) {
    this.id = model.id
    this.className = CLASS_NAMES.ROOF
    this.units = units
    this.layerKey = defaultTo(model.category, model.layerKey)
    this.clickPriority = CLICK_PRIORITY[this.layerKey]
    this.perimeterPoints = model.perimeterPoints
      ? model.perimeterPoints.map(point => [
          Units.unitsToNative(units, point[0]),
          Units.unitsToNative(units, point[1]),
          0,
        ])
      : []
    this.height = Units.unitsToNative(units, model.height) + Roof.Z_FIGHT_SHIFT

    this.elevationPoints = []
    this.elevationLines = []

    const defaultColor = theme.colors.three.objects.roof.default
    this.color = model.color || defaultColor

    // Construct ElevationPoint wrapper objects from models
    model.elevationPoints.forEach(elevationPointModel =>
      this.addElevationPoint(elevationPointModel)
    )

    // Construct ElevationLine wrapper objects from models
    model.elevationLines.forEach(elevationLineModel =>
      this.addElevationLine(elevationLineModel)
    )

    this.obj3d = this.createVisual()

    // If the roof geometry is invalid set a status message
    if (this.obj3d.userData.hasInvalidPoints) {
      store.dispatch(
        setStatus({
          text: 'Roof too complex. Try a simpler design.',
          type: 'error',
        })
      )
    } else {
      store.dispatch(clearStatus())
    }

    this.elevationPoints.forEach(ep => {
      // Elevation point z is relative to floor z, so
      // we need to subtract the roof height before
      // making it a child of the roof.
      ep.obj3d.position.z -= this.height

      this.obj3d.add(ep.obj3d)
    })

    this.elevationLines.forEach(el => {
      el.obj3d.position.z -= this.height

      this.obj3d.add(el.obj3d)
    })

    this.obj3d.position.copy(new THREE.Vector3(0, 0, this.height))

    // Used by Interactifier
    this.selectable = true
    this.draggable = false
    this.obj3d.wrapperId = this.id
    this.obj3d.userData.objectType = OBJECT_TYPES.ROOF
    this.obj3d.renderOrder = getRenderOrder('roofs')

    if (objectIsSelected(this.id)) {
      this.select()
    }
  }

  createVisual() {
    const elevationPoints = this.getAllElevationPoints().filter(ep => {
      const epInBounds = ep.isInRoofBounds()
      const parentInBounds = ep.parentLine
        ? ep.parentLine.allPointsInBounds()
        : true

      return epInBounds && parentInBounds
    })

    const perimeterPoints = this.perimeterPoints.map(point => ({
      x: point[0],
      y: point[1],
    }))
    const interiorPoints = []
    let allPoints
    const edgeIndices = []

    // Pretty much everywhere else in the program we represent polygons by repeating the first
    // point as the last point, but cdt2d doesn't like that, so we remove the last point here.
    perimeterPoints.pop()

    // Find any elevation points which are on the perimeter of the roof and add them to
    // perimeterPoints; all other elevation points are added to interiorPoints.
    elevationPoints.forEach(ep => {
      let wasOnPerimeter = false
      let xChange = 0
      let yChange = 0
      let basePerimeter = {}
      for (let i = 0; i < perimeterPoints.length; i += 1) {
        const index1 = i
        const index2 = (i + 1) % perimeterPoints.length
        const p1 = perimeterPoints[index1]
        const p2 = perimeterPoints[index2]
        let distance = Util.distanceToLineSegment(
          p1.x,
          p1.y,
          p2.x,
          p2.y,
          ep.position.x,
          ep.position.y
        )
        if (
          typeof distance === 'number' &&
          Units.nativeToInches(distance) < 3
        ) {
          const p1x = Math.round(p1.x)
          const p1y = Math.round(p1.y)
          const p2x = Math.round(p2.x)
          const p2y = Math.round(p2.y)
          // If the point is on a perimeter point, its a corner. Set a tiny offset away from the corner
          if (Util.pointsAreEqual2D(p1, ep.position, Units.inchesToNative(3))) {
            if (p1x === p2x) {
              p1y > p2y ? (yChange += -0.1) : (yChange += 0.1)
            }
            if (p1y === p2y) {
              p1x > p2x ? (xChange += -0.1) : (xChange += 0.1)
            }
            basePerimeter = p1
          } else if (
            Util.pointsAreEqual2D(p2, ep.position, Units.inchesToNative(3))
          ) {
            if (p1x === p2x) {
              p1y < p2y ? (yChange += -0.1) : (yChange += 0.1)
            }
            if (p1y === p2y) {
              p1x < p2x ? (xChange += -0.1) : (xChange += 0.1)
            }
            basePerimeter = p2
          } else {
            // If the point is on a perimeter line, set a tiny offset based on elavation point
            // height. This will cause them to draw in the correct order, and the tiny offset
            // will not be noticable.
            // We are no longer adding these points to the 'perimeter' list, we're just
            // keeping them very close to it
            if (p1x === p2x) {
              p1x > ep.position.x
                ? (xChange = -(ep.position.z * 0.001))
                : (xChange = ep.position.z * 0.001)
              basePerimeter.x = p1.x
            }
            if (p1y === p2y) {
              p1y > ep.position.y
                ? (yChange = -(ep.position.z * 0.001))
                : (yChange = ep.position.z * 0.001)
              basePerimeter.y = p1.y
            }
          }
          wasOnPerimeter = true
        }
      }

      if (!wasOnPerimeter) {
        interiorPoints.push(ep.position)
      } else {
        const interiorPoint = {
          x: (xChange && basePerimeter.x + xChange) || ep.position.x,
          y: (yChange && basePerimeter.y + yChange) || ep.position.y,
          z: ep.position.z,
          originalX: ep.position.x,
          originalY: ep.position.y,
        }
        // If point is outside of roof polygon after x and y change has been applied,
        // then it is an interior corner inside the bounds of the facility. Reverse the
        // direction of x and y changes to inverse direction of displacement
        if (
          (xChange || yChange) &&
          !Util.isPointInPolygon(interiorPoint, perimeterPoints) // Inside roof bounds
        ) {
          interiorPoint.x =
            (xChange && basePerimeter.x - xChange) || ep.position.x
          interiorPoint.y =
            (yChange && basePerimeter.y - yChange) || ep.position.y
        }
        interiorPoints.push(interiorPoint)
      }
    })

    allPoints = perimeterPoints.slice()

    // Form edges connecting all the perimeter points
    for (let i = 0; i < allPoints.length; i += 1) {
      edgeIndices.push([i, (i + 1) % allPoints.length])
    }

    allPoints = allPoints.concat(interiorPoints)

    this.addEdgeConstraintsForElevationLines(
      this.elevationLines,
      allPoints,
      edgeIndices
    )

    allPoints = allPoints.map(point => [point.x, point.y])

    const mesh = Primitives.getRoofMesh(
      allPoints,
      interiorPoints, // Just take interiorPoints since roofs can't go out of bounds
      this.height,
      edgeIndices,
      this.color
    )

    mesh.userData.objectType = OBJECT_TYPES.ROOF

    return mesh
  }

  /*
    SceneBuilder event
  */
  visibilityChanged(visible) {
    const isLayerVisible = store.getState().layers.layers.ROOFS.visible
    if (this.obj3d) this.obj3d.visible = isLayerVisible && visible
  }

  addEdgeConstraintsForElevationLines(
    elevationLines,
    allElevationPoints,
    edgeIndices
  ) {
    elevationLines
      .filter(el => el.allPointsInBounds())
      .forEach(line => {
        for (let i = 0; i < line.elevationPoints.length - 1; i += 1) {
          const ep1 = line.elevationPoints[i]
          const ep2 = line.elevationPoints[i + 1]
          const ep1Index = allElevationPoints.findIndex(point =>
            Util.pointsAreEqual2D(
              { x: point.originalX || point.x, y: point.originalY || point.y },
              ep1.position
            )
          )
          const ep2Index = allElevationPoints.findIndex(point =>
            Util.pointsAreEqual2D(
              { x: point.originalX || point.x, y: point.originalY || point.y },
              ep2.position
            )
          )

          edgeIndices.push([ep1Index, ep2Index])
        }
      })
  }

  /*
    Returns an array of objects which each describe a roof 'panel'. A roof
    panel is a polygonal section of the 3d roof geometry which lies in a single plane.
    Panel objects have the properties:

    polygon: the set of 3d points on the perimeter of the panel
    normal: the surface normal of the panel
    centroid: centroid of the panel's polygon
    rotationParams: rotation axis and angle require to lay the panel flat (z = constant)

    @return [{polygon, normal, centroid, rotationParams}]
  */
  getPanels() {
    const flatPoints = this.obj3d.userData.triPoints
    const trianglePoints = []
    for (let i = 0; i < flatPoints.length; i += 3) {
      trianglePoints.push(
        new THREE.Vector3(flatPoints[i], flatPoints[i + 1], flatPoints[i + 2])
      )
    }

    const triangleGroups = Util.collectCoPlanarTriangleGroups(trianglePoints)
    const centroids = []
    const normals = []
    const rotationParams = []
    const zAxis = new THREE.Vector3(0, 0, 1)

    triangleGroups.forEach(group => {
      const allGroupPoints = group.reduce((array, triangle) => {
        return array.concat([triangle.p1, triangle.p2, triangle.p3])
      }, [])

      const centroid = Util.polygonCentroid(allGroupPoints)
      const normal = Util.surfaceNormalForTriangle(group[0])
      centroids.push(centroid)
      normals.push(normal)

      const angle = normal.angleTo(zAxis)
      const rotationAxis = new THREE.Vector3()
        .crossVectors(normal, zAxis)
        .normalize()
      rotationParams.push({ axis: rotationAxis, angle })
    })

    let panels = triangleGroups.map((group, i) => ({
      polygon: Util.polygonForTriangleGroup(group),
      normal: normals[i],
      centroid: centroids[i],
      rotationParams: rotationParams[i],
    }))

    return panels
  }

  addElevationPoint(elevationPointModel) {
    this.elevationPoints.push(new ElevationPoint(elevationPointModel))
  }

  addElevationLine(elevationLineModel) {
    this.elevationLines.push(new ElevationLine(elevationLineModel))
  }

  getElevationLinePoints() {
    return this.elevationLines.reduce(
      (array, line) => array.concat(line.elevationPoints),
      []
    )
  }

  /*
    Returns all the elevation points belonging to elevation lines, and those
    not belonging to lines.
  */
  getAllElevationPoints() {
    return this.getElevationLinePoints().concat(this.elevationPoints)
  }

  toModel() {
    return {
      id: this.id,
      layerKey: this.layerKey,
      perimeterPoints: this.perimeterPoints.map(point => [
        Units.nativeToUnits(this.units, point[0]),
        Units.nativeToUnits(this.units, point[1]),
        0,
      ]),
      elevationPointIds: this.elevationPoints.map(ep => ep.id),
      elevationLineIds: this.elevationLines.map(el => el.id),
      height: Units.nativeToUnits(this.units, this.height) - Roof.Z_FIGHT_SHIFT,
      position: Units.nativeToUnitsV(this.units, this.obj3d.position),
      color: this.color,
    }
  }

  static createModel(
    perimeterPoints,
    position,
    height = Units.feetToInches(12.1),
    color = getThreeHexFromTheme('three.objects.roof.default')
  ) {
    return {
      id: Util.guid(),
      layerKey: LAYER_KEYS.ROOFS,
      perimeterPoints,
      elevationPointIds: [],
      elevationLineIds: [],
      height,
      position,
      color,
    }
  }

  static getDenormalizedModel(model) {
    const currentState = store.getState().objects.present

    // Collect elevation points
    let elevationPoints = []

    const elevationPointIds = get(model, 'elevationPointIds', [])
    if (elevationPointIds && elevationPointIds.length > 0) {
      elevationPoints = elevationPointIds.map(
        elevationPointId => currentState.elevationPoints[elevationPointId]
      )
    }

    // Collect elevation lines
    let elevationLines = []
    const elevationLineIds = get(model, 'elevationLineIds', [])
    if (elevationLineIds && elevationLineIds.length > 0) {
      elevationLines = elevationLineIds
        .map(elevationLineId => currentState.elevationLines[elevationLineId])
        .filter(line => line !== undefined)

      // Collect elevation points belonging to each elevation line
      for (let i = 0; i < elevationLines.length; i += 1) {
        elevationLines[i] = {
          ...elevationLines[i],
          elevationPoints: elevationLines[i].elevationPointIds.map(
            epId => currentState.elevationPoints[epId]
          ),
        }
      }
    }

    return {
      ...model,
      elevationPoints: elevationPoints,
      elevationLines: elevationLines,
    }
  }

  static getAllElevationPoints(model) {
    const denormModel = Roof.getDenormalizedModel(model)
    const eps = denormModel.elevationPoints.concat(
      denormModel.elevationLines.reduce(
        (array, el) => array.concat(el.elevationPoints),
        []
      )
    )

    return eps
  }

  static isFlat(model) {
    let allPointsAreRoofLevel = true
    Roof.getAllElevationPoints(model).forEach(point => {
      if (point.height - model.height !== 0) {
        allPointsAreRoofLevel = false
      }
    })

    return allPointsAreRoofLevel
  }

  select() {
    const currentColor = Color(this.color)
    const selectedColor = currentColor.darken(0.2).rgbNumber()
    this.obj3d.material.color.setHex(selectedColor)
  }

  deselect() {
    this.obj3d.material.color.setHex(Color(this.color).rgbNumber())
  }

  // ///
  // Interactable methods
  // ///

  getSnappableEdgePoints() {
    return []
  }

  drop() {}

  drag({ dragDelta }) {
    this.obj3d.position.add(dragDelta)
  }

  snap(snapDelta) {
    this.obj3d.position.add(snapDelta)
  }

  destroy() {}
}

export default Roof
