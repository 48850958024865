import React, { Component } from 'react'
import { object, string } from 'prop-types'
import { compose } from 'redux'

import Bar from './styled/Bar'
import Container from './styled/Container'
import Gradient from './styled/Gradient'
import GradientContainer from './styled/GradientContainer'
import Label from './styled/Label'
import Labels from './styled/Labels'

import { SYSTEMS } from 'store/units/constants'
import { Velocity } from 'store/units/types'
import { withUnits } from 'store/units/decorators'

const MIN_WIDTH = 600

class FPMLegend extends Component {
  state = {
    width: 0,
  }

  constructor(props) {
    super(props)

    this.container = React.createRef()
  }

  componentDidMount() {
    this.setState({
      width: this.container.current.offsetWidth,
    })
  }

  getXLabels() {
    const { options, velocityUnits } = this.props
    const { width } = this.state
    let velocities = options.xLabels.map(value => {
      return new Velocity({
        value,
        system: SYSTEMS.IMPERIAL,
      })
    })
    let suffix = 'FPM'
    switch (velocityUnits) {
      case SYSTEMS.METRIC:
        suffix = 'MPS'
        break
      case SYSTEMS.IMPERIAL:
      default:
        suffix = 'FPM'
        break
    }

    let parsedLabels = velocities.map(
      (velocity, index) =>
        `${velocity.convertedValue(velocityUnits, { round: 2 })}${
          // Only show "FPM" label on the last value
          index === velocities.length - 1 ? ` ${suffix}` : ''
        }`
    )

    if (width === undefined || width === 0) {
      return parsedLabels
    }

    if (width < MIN_WIDTH) {
      const firstValue = velocities[0]
      const middleValue = velocities[Math.round(velocities.length / 2)]
      const lastValue = velocities.pop()

      parsedLabels = [firstValue, middleValue, lastValue].map(
        velocity => `${velocity.value} ${suffix}`
      )
    }

    return parsedLabels
  }

  render() {
    const { options } = this.props

    return (
      <Container ref={this.container}>
        <Label alignment="left" coordinate="y">
          {options.yLabels[0]}
        </Label>
        <Bar>
          <GradientContainer>
            <Gradient options={options} />
          </GradientContainer>
          <Labels>
            {this.getXLabels().map((label, key) => (
              <Label key={key} coordinate="x">
                {label}
              </Label>
            ))}
          </Labels>
        </Bar>
        <Label alignment="right" coordinate="y">
          {options.yLabels[1]}
        </Label>
      </Container>
    )
  }
}

FPMLegend.propTypes = {
  options: object,
  velocityUnits: string,
}

export default compose(withUnits)(FPMLegend)
