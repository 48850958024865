import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import routes from 'config/routes'

import FacilitiesList from 'components/FacilitiesList'
import DuplicateFacilityModal from 'components/Modals/DuplicateFacilityModal'
import EditFacilityModal from 'components/Modals/EditFacilityModal'
import SelectOpportunityModal from 'components/Modals/SelectOpportunityModal'
import UserSearchModal from 'components/Modals/TestFolder'
import ReassignFacilitiesScreen from 'screens/ReassignFacilitiesScreen'
import SettingsScreen from 'screens/SettingsScreen'
import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

const Routes = ({ match }) => (
  <>
    <SentryRoute
      path={`${match.url}${routes.modals.duplicateFacility}`}
      render={props => (
        <DuplicateFacilityModal parentRoute={match.url} {...props} />
      )}
    />
    <SentryRoute
      exact
      path={`${match.url}${routes.modals.editFacility}`}
      render={props => <EditFacilityModal parentRoute={match.url} {...props} />}
    />
    <SentryRoute
      exact
      path={`${match.url}${routes.modals.userSearch}`}
      render={props => <UserSearchModal parentRoute={match.url} {...props} />}
    />
    <SentryRoute
      path={`${match.url}${routes.reassign}`}
      render={props => (
        <ReassignFacilitiesScreen parentRoute={match.url} {...props} />
      )}
    />
    <SentryRoute
      path={`${match.url}${routes.settings}`}
      render={props => <SettingsScreen parentRoute={match.url} {...props} />}
    />
    <SentryRoute
      path={`${match.url}${routes.modals.editFacility}${routes.modals.selectOpportunity}`}
      render={props => <SelectOpportunityModal {...props} />}
    />
    <SentryRoute
      path={`${match.url}${routes.modals.newFacility}${routes.modals.selectOpportunity}`}
      render={props => <SelectOpportunityModal {...props} />}
    />
  </>
)

Routes.propTypes = {
  match: PropTypes.object,
}

const MyFacilities = ({ view, match, user }) => (
  <>
    <FacilitiesList match={match} view={view} user={user} />
    <SentryRoute match={match} />
  </>
)

MyFacilities.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  view: PropTypes.string,
  user: PropTypes.object,
}

export default withRouter(MyFacilities)
