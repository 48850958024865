export const TOGGLE_TOUCH_UI = 'TOGGLE_TOUCH_UI'
export const TOGGLE_DEFAULT_UI = 'TOGGLE_DEFAULT_UI'
export const TOGGLE_FULLSCREEN = 'TOGGLE_FULLSCREEN'

export const toggleTouchUI = payload => ({
  type: TOGGLE_TOUCH_UI,
  payload,
})

export const toggleDefaultUI = payload => ({
  type: TOGGLE_DEFAULT_UI,
  payload,
})

export const toggleFullscreen = payload => ({
  type: TOGGLE_FULLSCREEN,
  payload,
})
