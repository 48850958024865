import * as Sentry from '@sentry/react'

function sendToSentry({ prefix = 'Manual error report', error, action }: { prefix?: string; error: Record<string, unknown>; action: string }) {
  const scope = Sentry.getCurrentScope()
  Object.keys(error).forEach(key => {
    scope.setExtra(key, error[key])
  })
  Sentry.captureMessage(`${prefix}: ${action}`)
}

export default sendToSentry
