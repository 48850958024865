import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import TextField from '../../TextField'

const StyledInput = styled(TextField)`
  border-radius: ${theme('radius.l')};
`

export default StyledInput
