import { Component } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'recompact'
import isEqual from 'lodash-es/isEqual'
import pick from 'lodash-es/pick'
import * as router from 'store/router/actions'

export class RouteListener extends Component {
  static propTypes = {
    onRoute: PropTypes.func,
    location: PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      key: PropTypes.string,
      search: PropTypes.string,
      state: PropTypes.any,
    }),
  }

  static defaultProps = {
    onRoute() {},
  }

  shouldComponentUpdate(nextProps) {
    // We're setting this here rather than pulling from
    // `RouteListener.propTypes` to prevent an issue with Babel
    // removing the propTypes in production, thus braking this.
    const keys = ['onRoute', 'location']

    return !isEqual(pick(nextProps, keys), pick(this.props, keys))
  }

  componentDidMount() {
    this.routeChanged()
  }

  componentDidUpdate() {
    this.routeChanged()
  }

  routeChanged() {
    this.props.onRoute(this.props.location)
  }

  render() {
    return null
  }
}

export default compose(
  withRouter,
  appConnect(null, { onRoute: router.changeRoute })
)(RouteListener)
