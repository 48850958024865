import { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from "~/store/hooks";
import { useFacilityVtkResults } from 'hooks/useFacilityVtkResults'
import {
  COMPOSITE_SIMULATION_TYPES,
  COVERAGE_THRESHOLDS,
  HEIGHTS,
  SIMULATION_TYPES,
  STILL_AIR_VELOCITY,
  getCoolingThreshold,
  getUnitHeatThreshold,
} from 'config/cfd'
import CoolingWorker from '../workers/cooling.worker?worker'
import DestratWorker from '../workers/destrat.worker?worker'
import UnitHeatWorker from '../workers/unitHeat.worker?worker'
import RadiantHeatWorker from '../workers/radiantHeat.worker?worker'
import HeatingCompositeWorker from '../workers/heatingComposite.worker?worker'
import { getComfortZoneEdges } from 'components/PerformanceMetrics/util/comfortZone'
import { getThermalComfort } from 'lib/thermalComfortTool'
import { IntensityInterpolator } from 'components/PerformanceMetrics/util/interpolateHeatIntensity'
import { useFormatTemperature } from 'components/PerformanceMetrics/hooks/useFormatTemperature'
import { useFormatVelocity } from 'components/PerformanceMetrics/hooks/useFormatVelocity'
import { ImperialTemperature, MetricTemperature } from 'store/units/types/temperature'
import { ImperialVelocity } from 'store/units/types/velocity'
import { formatPercent } from 'components/PerformanceMetrics/util/format'
import { useCFDUploadsContext } from '~/hooks/useCFDUploadsContext'

const FACILITY_ZONE = null

const getDefaultZoneState = () => ({
  zoneMetrics: undefined,
  loading: true,
  error: undefined,
})

const isWinterSimulation = goal =>
  goal === COMPOSITE_SIMULATION_TYPES.heatingComposite ||
  goal === SIMULATION_TYPES.radiantHeat ||
  goal === SIMULATION_TYPES.unitHeating ||
  goal === SIMULATION_TYPES.destrat

const initializeWorker = ({ worker, messagePayload, onResult }) => {
  worker.onmessage = ({ data }) => onResult(data?.error, data)
  worker.onerror = error => onResult(error, undefined)
  worker.postMessage(messagePayload)
  return worker
}
/**
 * @typedef {object} context
 * @prop {import("hooks/useFacilityDefaults").facilityDetailDefaults} facility
 * @prop {number} index
 * @prop {string} goal - One of SIMULATION_TYPES: "cooling", "destrat", etc
 * @prop {import('components/PerformanceMetrics/hooks/useFormatTemperature').temperatureFormatter} temperatureFormatter
 * @prop {import('components/PerformanceMetrics/hooks/useFormatVelocity').formatVelocity} formatVelocity
 * @prop {object} comfortZoneEdges
 * @prop {string} height - One of HEIGHTS.standing or HEIGHTS.seated
 * @prop {import("hooks/useFacilityVtkResults").vtkUrlGroups} vtkUrlGroups
 * @prop {object} zone
 * @prop {object} settings
 *
 * @callback handleResult
 * @param {number} index
 * @param {Error} error
 * @param {ZoneMetrics} data
 *
 * @callback workerHandler
 * @returns {Worker}
 *
 * @param {context} context
 * @param {handleResult} handleResult
 * @returns {Object<string, workerHandler>}
 */
const workerFactory = (context, handleResult) => {
  const {
    facility,
    goal,
    comfortZoneEdges,
    height,
    formatVelocity,
    temperatureFormatter,
    vtkUrlGroups,
    zone,
    settings,
    advancedClimate,
  } = context
  const { formatTemperature, formatTemperatureDelta } = temperatureFormatter
  const {
    indoorSummerTemp: facilityIndoorSummerTemp,
    indoorHumidity: facilityIndoorHumidity,
    indoorWinterHumidity: facilityIndoorWinterHumidity,
    indoorWinterTemp: facilityIndoorWinterTemp,
    winterClothingType,
    clothingType,
    primaryType,
    primaryUse,
    activityLevel,
  } = facility
  const indoorSummerTemp = advancedClimate.indoorSummerTemp ?? facilityIndoorSummerTemp
  const indoorHumidity = advancedClimate.indoorHumidity ?? facilityIndoorHumidity
  const indoorWinterHumidity = advancedClimate.indoorWinterHumidity ?? facilityIndoorWinterHumidity
  const indoorWinterTemp = advancedClimate.indoorWinterTemp ?? facilityIndoorWinterTemp
  const defaultTemp = new ImperialTemperature(
    isWinterSimulation(goal) ? indoorWinterTemp : indoorSummerTemp
  )
  const humidity = isWinterSimulation(goal) ? indoorWinterHumidity : indoorHumidity
  const { sensation, ppd, pmv, set } = getThermalComfort({
    airVelocity: STILL_AIR_VELOCITY,
    meanAirTemp: defaultTemp,
    humidity: isWinterSimulation(goal) ? indoorWinterHumidity : indoorHumidity,
    clothingType: isWinterSimulation(goal) ? winterClothingType : clothingType,
    metabolicRate: activityLevel,
  })
  const withoutFansMetrics = {
    averageRadiantTemperature: formatTemperature(defaultTemp),
    averageAirTemperature: formatTemperature(defaultTemp),
    averageAirVelocity: formatVelocity(STILL_AIR_VELOCITY),
    averageIntensity: 0,
    coolingCoverage: '0%',
    // Technically, there is no cooling effect without fans
    coolingEffect: '---',
    destratCoverage: '0%',
    heatingCoverage: '0%',
    pmv,
    ppd,
    set: formatTemperature(set),
    sensation,
  }
  const resultsPartial = {
    height,
    goal,
    zone,
    facility: { primaryUse, humidity, season: isWinterSimulation(goal) ? 'WINTER' : 'SUMMER' },
    settings,
  }
  return {
    [SIMULATION_TYPES.cooling]: () => {
      const { temperature, velocity } = vtkUrlGroups
      const messagePayload = {
        comfortZoneEdges,
        temperatureUrls: temperature[height],
        velocityUrls: velocity[height],
        coverageThreshold: getCoolingThreshold({ primaryType, primaryUse }),
      }
      const onResult = (error, data) => {
        if (error || !data) return handleResult(context.index, error, undefined)
        const { averageTemperature, averageAirVelocity, coverage } = data
        const isEvap = averageTemperature !== null
        const averageTemp = new ImperialTemperature(isEvap ? averageTemperature : indoorSummerTemp)
        const airVelocity = new ImperialVelocity(averageAirVelocity)
        const { pmv, coolingEffect } = getThermalComfort({
          airVelocity,
          meanAirTemp: averageTemp,
          humidity: indoorHumidity,
          clothingType: facility.clothingType,
          metabolicRate: facility.activityLevel,
        })
        const results = {
          ...resultsPartial,
          metrics: {
            noFans: withoutFansMetrics,
            withFans: {
              averageAirTemperature: formatTemperature(averageTemp),
              averageAirVelocity: formatVelocity(airVelocity),
              coolingCoverage: formatPercent(coverage),
              coolingEffect: formatTemperatureDelta(new MetricTemperature(coolingEffect)),
              pmv,
              ppd,
              sensation,
            },
          },
        }
        handleResult(context.index, undefined, results)
      }
      return initializeWorker({ worker: new CoolingWorker(), messagePayload, onResult })
    },

    [SIMULATION_TYPES.destrat]: () => {
      const { destrat, velocity } = vtkUrlGroups
      const messagePayload = {
        comfortZoneEdges,
        destratUrl: destrat,
        draftRiskUrls: velocity[height],
      }
      const onResult = (error, data) => {
        if (error || !data) handleResult(context.index, error, undefined)
        const { averageAirVelocity, destratCoverage } = data
        const airVelocity = new ImperialVelocity(averageAirVelocity)
        const { pmv, ppd, sensation } = getThermalComfort({
          meanAirTemp: defaultTemp,
          airVelocity,
          humidity: indoorWinterHumidity,
          clothingType: winterClothingType,
          metabolicRate: activityLevel,
        })
        const results = {
          ...resultsPartial,
          height,
          metrics: {
            withFans: {
              averageAirTemperature: formatTemperature(defaultTemp),
              averageAirVelocity: formatVelocity(airVelocity),
              destratCoverage: formatPercent(destratCoverage),
              pmv,
              ppd,
              sensation,
            },
            noFans: withoutFansMetrics,
          },
        }
        handleResult(context.index, undefined, results)
      }
      return initializeWorker({ worker: new DestratWorker(), messagePayload, onResult })
    },

    [SIMULATION_TYPES.radiantHeat]: () => {
      const { intensity } = vtkUrlGroups
      const messagePayload = {
        comfortZoneEdges,
        intensityUrl: intensity,
        coverageThreshold: COVERAGE_THRESHOLDS.intensity,
      }
      const onResult = (error, data) => {
        if (error || !data) return handleResult(context.index, error, undefined)
        const { averageIntensity, coverage } = data
        const { scaleMeanRadiantTemp, scaleMeanAirTemp } = new IntensityInterpolator(averageIntensity)
        const meanAirTemp = new ImperialTemperature(scaleMeanAirTemp(defaultTemp.value))
        const radiantTemperature = new ImperialTemperature(scaleMeanRadiantTemp(defaultTemp.value))
        const { ppd, sensation, pmv } = getThermalComfort({
          airVelocity: STILL_AIR_VELOCITY,
          humidity,
          meanAirTemp: meanAirTemp,
          meanRadiantTemp: radiantTemperature,
          clothingType: winterClothingType,
          metabolicRate: activityLevel,
        })
        const results = {
          ...resultsPartial,
          // TODO: @refactor There is no height with IRH, but defaulting to "standing" for backend compatibility
          height: HEIGHTS.standing,
          // TODO: @refactor There is no height with IRH, but defaulting to "standing" for backend compatibility
          metrics: {
            noHeaters: withoutFansMetrics,
            withHeaters: {
              averageAirTemperature: formatTemperature(meanAirTemp),
              averageRadiantTemperature: formatTemperature(radiantTemperature),
              averageAirVelocity: formatVelocity(STILL_AIR_VELOCITY),
              heatingCoverage: formatPercent(coverage),
              averageIntensity: averageIntensity.toFixed(2),
              pmv,
              ppd,
              sensation,
            },
          },
        }
        handleResult(context.index, undefined, results)
      }
      return initializeWorker({ worker: new RadiantHeatWorker(), messagePayload, onResult })
    },

    [SIMULATION_TYPES.unitHeating]: () => {
      const { temperatureBefore, temperatureAfter, velocityAfter } = vtkUrlGroups
      const messagePayload = {
        comfortZoneEdges,
        coverageThreshold: getUnitHeatThreshold(facility),
        temperatureBeforeUrls: temperatureBefore[height],
        temperatureAfterUrls: temperatureAfter[height],
        velocityAfterUrls: velocityAfter[height],
      }
      const onResult = (error, data) => {
        if (error || !data) return handleResult(context.index, error, undefined)
        const {
          averageAirTemperatureBefore: avgBeforeTemp,
          coverageBefore,
          averageAirTemperatureAfter: avgAfterTemp,
          coverageAfter,
          averageAirVelocityAfter: afterVelocity,
        } = data
        const beforeTemp = new ImperialTemperature(avgBeforeTemp)
        const afterTemp = new ImperialTemperature(avgAfterTemp)
        const meanRadiantTemp = new ImperialTemperature(indoorWinterTemp)
        const turnoverVelocity = new ImperialVelocity(afterVelocity)
        const staticThermalComfortProps = {
          meanRadiantTemp,
          humidity,
          clothingType: winterClothingType,
          metabolicRate: activityLevel,
        }
        const heaters = getThermalComfort({
          ...staticThermalComfortProps,
          meanAirTemp: beforeTemp,
          airVelocity: STILL_AIR_VELOCITY,
        })
        const heatersAndFans = getThermalComfort({
          ...staticThermalComfortProps,
          meanAirTemp: afterTemp,
          airVelocity: turnoverVelocity,
        })
        const results = {
          ...resultsPartial,
          metrics: {
            noHeaters: withoutFansMetrics,
            withHeaters: {
              averageAirTemperature: formatTemperature(beforeTemp),
              heatingCoverage: formatPercent(coverageBefore),
              pmv: heaters.pmv,
              ppd: heaters.ppd,
              sensation: heaters.sensation,
            },
            withHeatersAndFans: {
              averageAirTemperature: formatTemperature(afterTemp),
              heatingCoverage: formatPercent(coverageAfter),
              pmv: heatersAndFans.pmv,
              ppd: heatersAndFans.ppd,
              sensation: heatersAndFans.sensation,
            },
          },
        }
        handleResult(context.index, undefined, results)
      }
      return initializeWorker({ worker: new UnitHeatWorker(), messagePayload, onResult })
    },

    [COMPOSITE_SIMULATION_TYPES.heatingComposite]: () => {
      const { radiantHeat, unitHeating } = vtkUrlGroups
      const { intensity } = radiantHeat
      const { temperatureBefore, temperatureAfter, velocityAfter } = unitHeating
      const messagePayload = {
        comfortZoneEdges,
        intensityUrl: intensity,
        heatingCoverageThreshold: getUnitHeatThreshold(facility),
        temperatureBeforeUrls: temperatureBefore[height],
        temperatureAfterUrls: temperatureAfter[height],
        velocityAfterUrls: velocityAfter[height],
      }
      const onResult = (error, data) => {
        if (error || !data) return handleResult(context.index, error, undefined)
        const {
          averageIntensity,
          averageAirTemperatureBefore,
          averageAirTemperatureAfter,
          unitHeatingCoverageBefore,
          unitHeatingCoverageAfter,
          averageAirVelocityAfter,
        } = data
        const { scaleMeanAirTemp, scaleMeanRadiantTemp } = new IntensityInterpolator(
          averageIntensity
        )
        const meanRadiantTemp = new ImperialTemperature(scaleMeanRadiantTemp(defaultTemp.value))
        const beforeTemp = new ImperialTemperature(scaleMeanAirTemp(averageAirTemperatureBefore))
        const afterTemp = new ImperialTemperature(scaleMeanAirTemp(averageAirTemperatureAfter))
        const turnoverVelocity = new ImperialVelocity(averageAirVelocityAfter)
        const staticThermalComfortProps = {
          meanRadiantTemp,
          humidity,
          clothingType: winterClothingType,
          metabolicRate: activityLevel,
        }
        const heaters = getThermalComfort({
          ...staticThermalComfortProps,
          meanAirTemp: beforeTemp,
          airVelocity: STILL_AIR_VELOCITY,
        })
        const heatersAndFans = getThermalComfort({
          ...staticThermalComfortProps,
          meanAirTemp: afterTemp,
          airVelocity: turnoverVelocity,
        })
        const results = {
          ...resultsPartial,
          metrics: {
            noHeaters: withoutFansMetrics,
            withHeaters: {
              averageAirTemperature: formatTemperature(beforeTemp),
              averageRadiantTemperature: formatTemperature(meanRadiantTemp),
              averageIntensity: averageIntensity.toFixed(2),
              heatingCoverage: formatPercent(unitHeatingCoverageBefore),
              pmv: heaters.pmv,
              ppd: heaters.ppd,
              sensation: heaters.sensation,
            },
            withHeatersAndFans: {
              averageAirTemperature: formatTemperature(afterTemp),
              averageRadiantTemperature: formatTemperature(meanRadiantTemp),
              averageIntensity: averageIntensity.toFixed(2),
              heatingCoverage: formatPercent(unitHeatingCoverageAfter),
              pmv: heatersAndFans.pmv,
              ppd: heatersAndFans.ppd,
              sensation: heatersAndFans.sensation,
            },
          },
        }
        handleResult(context.index, undefined, results)
      }
      return initializeWorker({ worker: new HeatingCompositeWorker(), messagePayload, onResult })
    },
  }
}

/**
 * @typedef {object} Metrics
 * @prop {string} sensation
 * @prop {number} pmv
 * @prop {string} ppd
 * @prop {string} averageAirTemperature
 * @prop {string} averageRadiantTemperature
 * @prop {string} averageAirVelocity
 * @prop {number} averageIntensity
 * @prop {string} coolingEffect
 * @prop {string} heatingCoverage
 * @prop {string} coolingCoverage
 * @prop {string} destratCoverage
 *
 * @typedef {object} ZoneMetrics
 * @prop {string} height - "standing" | "seated" | undefined
 * @prop {string} goal - The simulation type: cooling, destrat, etc
 * @prop {object} zone - An object with the zone id and name
 * @prop {import("hooks/useFacilityDefaults").facilityDetailDefaults} facility
 * @prop {Object<string, boolean>} settings
 * @prop {object} metrics - Collection of metrics
 * @prop {Metrics} metrics.noFans
 * @prop {Metrics} metrics.noHeaters
 * @prop {Metrics} metrics.withFans
 * @prop {Metrics} metrics.withHeaters
 * @prop {Metrics} metrics.withHeatersAndFans
 *
 * @typedef {object} zone
 * @prop {string} goal - The simulation type. Eg, "cooling", "destrat", etc.
 * @prop {string|null} zoneId - The id of the comfort zone or null if referring to the facility as a zone
 * @prop {string} height - HEIGHT.standing or HEIGHT.seated - Only applies to cooling, unitHeat, and destrat
 * @param {zone[]} zones
 *
 * @typedef {object} ZoneMetricsPayload
 * @prop {boolean} loading
 * @prop {Error} error
 * @prop {ZoneMetrics[]} comfortZoneMetrics
 * @returns {ZoneMetricsPayload}
 */
export const useZoneMetrics = (zones) => {
  const comfortZones = useAppSelector(state => state?.objects?.present?.comfortZones)
  const temperatureFormatter = useFormatTemperature()
  const { formatVelocity } = useFormatVelocity()
  const {
    data: facilityVtkResults,
    loading: isFetchingVtk,
    error: vtkError,
  } = useFacilityVtkResults()
  const { configure: { advancedClimate } } = useCFDUploadsContext()

  const [results, setResults] = useState(zones?.map(getDefaultZoneState) ?? [])

  const handleResult = useCallback((index, error, zoneMetrics) => {
    setResults(prev =>
      prev.map((result, i) => (index === i ? { loading: false, error, zoneMetrics } : result))
    )
  }, [])

  useEffect(() => {
    if (!facilityVtkResults || !zones) return
    const { facility } = facilityVtkResults

    setResults(zones.map(getDefaultZoneState))

    const workers = zones.map(({ goal, zone, height, settings }, index) => {
      const comfortZone = comfortZones[zone.id]
      const comfortZoneEdges = comfortZone
        ? getComfortZoneEdges(comfortZone.positions)
        : FACILITY_ZONE
      const vtkUrlGroups = facilityVtkResults.vtkResults.find(({ type }) => type === goal)
        ?.vtkUrlGroups
      if (!vtkUrlGroups) return null
      const context = {
        facility,
        goal,
        temperatureFormatter,
        formatVelocity,
        comfortZoneEdges,
        height,
        vtkUrlGroups,
        zone,
        settings,
        index,
        advancedClimate,
      }
      const startWorker = workerFactory(context, handleResult)[goal]
      if (!startWorker) return null
      return startWorker()
    })
    return () => workers.forEach(worker => worker?.terminate())
  }, [zones, comfortZones, facilityVtkResults, handleResult, formatVelocity, temperatureFormatter])

  const loading = results.some(({ loading }) => loading) || isFetchingVtk
  const error = results.find(({ error }) => !!error)?.error || vtkError
  const hasResults = !loading && !error
  const comfortZoneMetrics = hasResults ? results.map(({ zoneMetrics }) => zoneMetrics) : undefined

  return {
    loading,
    error,
    comfortZoneMetrics,
  }
}
