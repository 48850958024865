import { ALL_FACILITY_TEMPLATES_QUERY } from '../queries'

const handleDeleteFacilityTemplate = ({ id, mutate, ownProps }) =>
  mutate({
    variables: { id },
    optimisticResponse: {
      __typename: 'Mutation',
      deleteFacilityTemplate: {
        __typename: 'FacilityTemplate',
        id,
      },
    },
    refetchQueries: [
      {
        query: ALL_FACILITY_TEMPLATES_QUERY,
        name: 'allFacilityTemplates',
        variables: { ...ownProps.variables, filter: null },
      },
    ],
  })

export default handleDeleteFacilityTemplate
