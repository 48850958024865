import React from 'react'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import PropTypes from 'prop-types'

import theme from 'config/theme'
import { setHeatMapLayer } from 'store/objects'
import { HEAT_MAP_PANEL } from 'store/panel/types'
import { withUnits } from 'store/units/decorators'
import { toggleLayerVisibility } from 'store/layers'
import { isTouchUI } from 'store/userInterface/selectors'

import routes from 'config/routes'
import LAYER_KEYS from 'config/layerKeys'

import Panel from 'components/UIKit/Panel'
import Select from 'components/UIKit/Select'
import Switch from 'components/UIKit/Switch'

import Controls from './styled/Controls'
import Control from './styled/Control'
import Grid from './styled/Grid'
import Label from './styled/Label'

import withFacility from 'client/decorators/withFacility'
import FPMLegend from 'components/FPMLegend'

const HeatMapPanel = ({
  selectedLayer,
  onUpdateHeatMapLayer,
  distanceUnits,
  layers,
  onUpdateHeatMap,
  products,
  objects,
  segments,
  obstructions,
  airflowValid,
  onToggleVisibility,
  isTouchUI,
  facility,
}) => {
  const heatMapColorValues = theme.colors.heat
  return (
    <Panel
      title="Heat Map"
      helpUrl={routes.help.heatmap}
      landscape
      panelKey={HEAT_MAP_PANEL}
    >
      <Grid>
        <Controls>
          {isTouchUI && (
            <Control>
              <Label>Heat Map Toggle</Label>
              <Switch
                name="heatmap"
                isChecked={layers.HEAT_MAP.visible}
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  if (!layers.HEAT_MAP.visible) {
                    onUpdateHeatMap({
                      products,
                      objects,
                      segments,
                      obstructions,
                    })
                  }
                  onToggleVisibility({ layerKey: LAYER_KEYS.HEAT_MAP })
                }}
              />
            </Control>
          )}
          <Control>
            <Select
              disabled
              inline
              labelWidth="200px"
              name="select"
              label="View"
              value="Basic Heat Map"
              options={[
                {
                  label: 'Basic Heat Map',
                  value: 'Basic Heat Map',
                },
              ]}
              onChange={event => {
                return
              }}
            />
          </Control>
        </Controls>
        <FPMLegend
          options={{
            colors: heatMapColorValues.map(c => c.color),
            xLabels: [],
            yLabels: ['Cool', 'Hot'],
          }}
        />
      </Grid>
    </Panel>
  )
}

HeatMapPanel.propTypes = {
  airflowValid: PropTypes.bool,
  distanceUnits: PropTypes.string,
  isTouchUI: PropTypes.bool,
  layers: PropTypes.object,
  objects: PropTypes.object,
  obstructions: PropTypes.object,
  onToggleVisibility: PropTypes.func,
  onUpdateHeatMap: PropTypes.func,
  onUpdateHeatMapLayer: PropTypes.func,
  products: PropTypes.object,
  segments: PropTypes.object,
  selectedLayer: PropTypes.string,
  facility: PropTypes.object,
}

const mapStateToProps = ({ objects, layers, ...store }) => {
  let selectedLayer = '67'

  const airflow = objects.present.airflow
  if (airflow && airflow.selectedLayer) {
    selectedLayer = airflow.selectedLayer.evaluationHeight.toString()
  }

  return {
    airflowValid: objects.present.airflow.isValid,
    isTouchUI: isTouchUI(store),
    layers: layers.layers,
    objects: objects.present.objects,
    obstructions: objects.present.obstructions,
    products: objects.present.products,
    segments: objects.present.segments,
    selectedLayer,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateHeatMapLayer(layerKey) {
    dispatch(setHeatMapLayer({ layerKey }))
  },
  onUpdateHeatMap({ products, objects, segments, obstructions }) {
    // updateAirflow(dispatch, { products, objects, segments, obstructions });
  },
  onToggleVisibility({ layerKey }) {
    dispatch(toggleLayerVisibility({ layerKey }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withUnits,
  withFacility
)(HeatMapPanel)
