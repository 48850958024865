import sortBy from 'lodash-es/sortBy'
import get from 'lodash-es/get'
import { Vector2 } from 'three'

import Util from './util'
import Units from './units'
import Primitives from './primitives'
import { getThreeHexFromTheme } from 'lib/utils'
import getRenderOrder from 'config/canvasRenderOrder'

import * as THREE from 'three'

class SnapRegion {
  constructor(snapRange) {
    this.snapRange = snapRange
    SnapRegion.COLOR = getThreeHexFromTheme('three.objects.snapRegion.default')
  }

  snappedPointForPoint(point) {
    if (this.isWithinSnapRange(point)) {
      const pointObject = this.nearestPointInSnapLocus(point)
      return new THREE.Vector3(pointObject.x, pointObject.y, 0)
    }
    return point
  }

  isWithinSnapRange(/* point */) {
    throw new Error('Subclasses must implement isWithinSnapRange method')
  }

  nearestPointInSnapLocus(/* point */) {
    throw new Error('Subclasses must implement nearestPointInSnapLocus method')
  }

  draw() {
    throw new Error('Subclasses must implement draw method')
  }
}

class SnapPoint extends SnapRegion {
  constructor(point, snapRange = Units.feetToNative(2), targetVisual = false) {
    super(snapRange)

    this.point = point
    this.obj3d = this.getVisual(snapRange, targetVisual)
    this.obj3d.position.x = this.point.x
    this.obj3d.position.y = this.point.y
    this.obj3d.renderOrder = getRenderOrder('snapRegions')
  }

  getVisual(snapRange, targetVisual) {
    if (targetVisual) {
      const size = snapRange * 10
      return Primitives.getCircle(size)
    } else {
      const size = snapRange * 0.2
      const geometry = new THREE.BoxGeometry(size, size, size)
      const material = new THREE.MeshBasicMaterial({
        color: SnapRegion.COLOR,
        depthTest: false,
        transparent: true, // set to true in order to get later render order
      })
      return new THREE.Mesh(geometry, material)
    }
  }

  isWithinSnapRange(point) {
    const distance = this.getDistanceFromPoint(point)
    return distance < this.snapRange
  }

  getDistanceFromPoint(point) {
    const distance = Math.sqrt(
      (point.x - this.point.x) ** 2 + (point.y - this.point.y) ** 2
    )

    return distance
  }

  nearestPointInSnapLocus(/* point */) {
    return {
      x: this.point.x,
      y: this.point.y,
    }
  }

  equals(otherPoint) {
    return Util.pointsAreEqual2D(this, otherPoint)
  }
}

class SnapLine extends SnapRegion {
  constructor(
    point1,
    point2,
    snapRange = Units.feetToNative(2),
    isLineSegment = false,
    thickness = 0
  ) {
    super(snapRange)

    this.slope = Util.slopeFromPoints(point1, point2)

    this.yShift = point1.y - this.slope * point1.x

    this.point1 = point1
    this.point2 = point2

    this.isLineSegment = isLineSegment

    this.obj3d = this.getLineObj3D(snapRange, thickness)
  }

  getLineObj3D(snapRange, extraThickness = 0) {
    const vec1 = new THREE.Vector3(this.point1.x, this.point1.y, 0)
    const vec2 = new THREE.Vector3(this.point2.x, this.point2.y, 0)
    const direction = vec2
      .clone()
      .sub(vec1)
      .normalize()

    // For our start and end points, we find two other points on the line
    // at an arbitrary far out distance which will exceed the screen bounds.
    const start = vec1.clone().addScaledVector(direction, -10000)
    const end = vec2.clone().addScaledVector(direction, 10000)

    const thickness = snapRange * 0.1 + extraThickness
    const line = Primitives.getLine(start, end, SnapRegion.COLOR, thickness)

    // set to true in order to get later render order
    line.material.transparent = true

    return line
  }

  isWithinSnapRange(point) {
    const nearest = this.nearestPointInSnapLocus(point)
    const distance = this.getDistanceFromPoint(point)
    const isWithinSnapRange = distance <= this.snapRange

    if (this.isLineSegment) {
      const pointIsOnSegment = Util.isPointOnSegment(
        this.point1,
        nearest,
        this.point2
      )
      return isWithinSnapRange && pointIsOnSegment
    }

    return isWithinSnapRange
  }

  getDistanceFromPoint(point) {
    const nearest = this.nearestPointInSnapLocus(point)
    const distance = Math.sqrt(
      (point.x - nearest.x) ** 2 + (point.y - nearest.y) ** 2
    )

    return distance
  }

  nearestPointInSnapLocus(point) {
    // 1) Get slope and yshift of perpindicular line (nearest point is connected by perpendicular line).
    // 2) Find intersection of perp line with snap line.

    const perpSlope = -1 / this.slope
    const perpYShift = point.y - point.x * perpSlope

    const intersectionPoint = SnapLine.getIntersectionPoint(
      this.slope,
      this.yShift,
      perpSlope,
      perpYShift
    )

    /* eslint no-self-assign: 0 */
    // TODO: Fix this self-assignment issue
    intersectionPoint.x = intersectionPoint.x
    intersectionPoint.y = intersectionPoint.y

    return intersectionPoint
  }

  static getIntersectionPoint(slope1, yShift1, slope2, yShift2) {
    const intersectionX = (yShift2 - yShift1) / (slope1 - slope2)
    const intersectionY = slope2 * intersectionX + yShift2

    return { x: intersectionX, y: intersectionY }
  }

  static linesIntersect(slope1, yShift1, slope2, yShift2) {
    const intersection = SnapLine.getIntersectionPoint(
      slope1,
      yShift1,
      slope2,
      yShift2
    )

    return (
      !isNaN(intersection.x) &&
      isFinite(intersection.x) &&
      !isNaN(intersection.y) &&
      isFinite(intersection.y)
    )
  }

  isPointOnLine(point) {
    // 'diff' is based on the following:
    // y = mx + b
    // y - (mx + b) = 0
    const diff = point.y - (this.slope * point.x + this.yShift)
    return Math.abs(diff) < 0.1
  }

  equals(otherLine) {
    if (Math.abs(this.slope) > 10000 && Math.abs(otherLine.slope) > 10000) {
      return true
    }

    // We only check the slope since parallel lines can't intersect.
    return Math.abs(this.slope - otherLine.slope) < 0.01
  }

  yForX(x) {
    return this.slope * x + this.yShift
  }

  xForY(y) {
    return (y - this.yShift) / this.slope
  }
}

/*
  Used to represent multiple active SnapPoints. It is extending SnapPoint
  rather than just SnapRegion since its behavior depends on a common point
  of intersection between multiple SnapRegions, and so much of its behavior
  is point-like. This is specifically for dealing with multiple SnapRegions
  which are influencing a point (most likely the user's cursor).
*/
class CompoundCursorSnapRegion extends SnapPoint {
  constructor(subRegions, commonPoint, snapRange) {
    super(commonPoint, snapRange)

    this.subRegions = subRegions

    this.obj3d = new THREE.Object3D()

    this.subRegions.forEach(subRegion => {
      this.obj3d.add(subRegion.obj3d)
    })
  }

  draw(canvasWidth, canvasHeight) {
    this.subRegions.forEach(subRegion =>
      subRegion.draw(canvasWidth, canvasHeight)
    )

    super.draw()
  }
}

/*
  A SnapRegion made up of multiple other SnapRegions which may or may
  not intersect with one another. Used for representing the multiple
  SnapLines which may be influencing a dragged wall at any point in time.
*/
class CompoundEdgeSnapRegion extends SnapRegion {
  constructor(subRegions) {
    super()

    this.subRegions = subRegions

    this.calculateSnapDelta()

    this.obj3d = new THREE.Object3D()

    this.subRegions.forEach(subRegion => {
      this.obj3d.add(subRegion.obj3d)
    })
  }

  /*
    Combines the snapDelta vectors from each SnapRegion into a single snapDelta
    vector. The sub-SnapRegions comprising this compound region must not
    compete with one another in accordance with the behavior of SnapRegionUtils.doSnapsCompete(...)
  */
  calculateSnapDelta() {
    this.snapDelta = this.subRegions[0].snapDelta
    const alreadyAddedDeltas = [this.snapDelta.clone()]

    this.subRegions.forEach(subRegion => {
      // We allow multiple SnapRegions applying the same snapDelta to be part of this compound region,
      // but we only want them both to draw, not for their influences to be added multiple times.
      const alreadyAddedEquivalentDelta = alreadyAddedDeltas.find(
        accountedForDelta =>
          Util.pointsAreEqual2D(subRegion.snapDelta, accountedForDelta)
      )

      if (!alreadyAddedEquivalentDelta) {
        this.snapDelta.add(subRegion.snapDelta)
        alreadyAddedDeltas.push(subRegion.snapDelta)
      }
    })
  }
}

class SnapRegionUtils {
  /*
    When the mouse position is within range of multiple SnapRegions, we need
    some way of deciding how to use the multiple regions. The approach here
    is to sort the regions by distance from the mouse cursor so we can still
    snap when in very close range.

    Priority is given to Snap Points over Snap Lines
  */
  static reconcileCursorSnapRegions(snapRegions, mousePos) {
    if (!snapRegions || !snapRegions.length) return

    // Filter out any regions out of snap range
    const regionsInSnapRange = snapRegions.filter(region =>
      region.isWithinSnapRange(mousePos)
    )

    // Get snap points sorted by distance from mouse
    const points = regionsInSnapRange.filter(
      region => region instanceof SnapPoint
    )
    const sortedPoints = sortBy(points, region =>
      region.getDistanceFromPoint(mousePos)
    )

    // Get snap lines sorted by distance from mouse
    const lines = regionsInSnapRange.filter(
      region => region instanceof SnapLine
    )
    const sortedLines = sortBy(lines, region =>
      region.getDistanceFromPoint(mousePos)
    )

    // Snap points get added first for priority
    const availableRegions = [...sortedPoints, ...sortedLines]

    // If more than one region try to find a common point to snap to
    if (availableRegions.length > 1) {
      const commonPoint = SnapRegionUtils.commonPointOfCompetingRegions(
        availableRegions[0],
        availableRegions[1]
      )
      if (commonPoint) {
        const range = get(availableRegions, '[0].snapRange', 10)
        const tempSnapPoint = new SnapPoint(commonPoint, range)
        const isInRange = tempSnapPoint.isWithinSnapRange(mousePos)
        if (isInRange) {
          return new CompoundCursorSnapRegion(
            [availableRegions[0], availableRegions[1]],
            commonPoint,
            tempSnapPoint.snapRange
          )
        }
      }
    }

    return availableRegions.length ? availableRegions[0] : snapRegions[0]
  }

  /*
    Of the given snapRegions, we must choose a sub set which are compatible with one another
    when activating at the same time—this method decides which subset that is and returns
    a single CompoundEdgeSnapRegion representing the compatible subset.
  */
  static reconcileEdgeSnapRegions(snapRegions, mousePos, mouseDeltaVector) {
    const finalists = []

    const mostInLineRegion = SnapRegionUtils.getSnapRegionMostInLineWithMouseMotion(
      snapRegions,
      mousePos,
      mouseDeltaVector
    )

    // Add the most in line snap as a finalist, and remove it from the snapRegions
    finalists.push(mostInLineRegion)
    snapRegions.splice(snapRegions.indexOf(mostInLineRegion), 1)

    snapRegions.forEach(snapRegion => {
      const competesWithSomeSnap = finalists.find(finalist =>
        SnapRegionUtils.doSnapsCompete(finalist, snapRegion)
      )

      if (!competesWithSomeSnap) {
        finalists.push(snapRegion)
      }
    })

    return new CompoundEdgeSnapRegion(finalists)
  }

  /*
    Find the snap region most in the direction of the mouse's current motion
  */
  static getSnapRegionMostInLineWithMouseMotion(
    snapRegions,
    mousePos,
    mouseDeltaVector
  ) {
    const vectorMousePos = new Vector2(mousePos.x, mousePos.y)

    let mostInLineRegion = snapRegions[0]
    for (let i = 1; i < snapRegions.length; i += 1) {
      let nearestPointOnMostInLineRegion = mostInLineRegion.nearestPointInSnapLocus(
        vectorMousePos
      )
      let nearestPointOnCurrentRegion = snapRegions[i].nearestPointInSnapLocus(
        vectorMousePos
      )

      // Convert to vectors
      nearestPointOnMostInLineRegion = new Vector2(
        nearestPointOnMostInLineRegion.x,
        nearestPointOnMostInLineRegion.y
      )
      nearestPointOnCurrentRegion = new Vector2(
        nearestPointOnCurrentRegion.x,
        nearestPointOnCurrentRegion.y
      )

      const mouseTomostInLineRegionVec = nearestPointOnMostInLineRegion
        .sub(vectorMousePos)
        .normalize()
      const mouseToCurrentRegionVec = nearestPointOnCurrentRegion
        .sub(vectorMousePos)
        .normalize()

      // Check whether the vector going from the mouse to the nearest point on the current snap region is
      // more in line (as measured by the dot product) than the most in line snap region so far.
      if (
        mouseToCurrentRegionVec.dot(mouseDeltaVector) >
        mouseTomostInLineRegionVec.dot(mouseDeltaVector)
      ) {
        mostInLineRegion = snapRegions[i]
      }
    }

    return mostInLineRegion
  }

  /*
    Checks whether two snap regions would compete with one another if
    they were both active at once
  */
  static doSnapsCompete(snapRegion1, snapRegion2) {
    const sameDelta = Util.pointsAreEqual2D(
      snapRegion1.snapDelta,
      snapRegion2.snapDelta
    )

    // Ideally we would compare the snapDeltas instead, but there is an issue if
    // the snapDelta is zero (since the edge is at the snap line), since the dot
    // product would be zero in that case. However, the snap lines themselves work
    // as a proxy.
    const reg1Vec = new Vector2(
      snapRegion1.point2.x - snapRegion1.point1.x,
      snapRegion1.point2.y - snapRegion1.point1.y
    )
    const reg2Vec = new Vector2(
      snapRegion2.point2.x - snapRegion2.point1.x,
      snapRegion2.point2.y - snapRegion2.point1.y
    )

    reg1Vec.normalize()
    reg2Vec.normalize()

    const orthogonal = Math.abs(reg1Vec.dot(reg2Vec)) <= 0.01

    return !sameDelta && !orthogonal
  }

  /*
    Finds an intersection point between the two given regions if possible. If no
    intersection point exists, null is returned.
  */
  static commonPointOfCompetingRegions(region1, region2) {
    let commonPoint = null

    const lineAndPoint =
      (region1 instanceof SnapLine && region2 instanceof SnapPoint) ||
      (region2 instanceof SnapLine && region1 instanceof SnapPoint)
    const lineAndLine =
      region1 instanceof SnapLine && region2 instanceof SnapLine
    const pointAndPoint =
      region1 instanceof SnapPoint && region2 instanceof SnapPoint

    if (lineAndPoint) {
      const snapLine = region1 instanceof SnapLine ? region1 : region2
      const snapPoint = region1 instanceof SnapPoint ? region1 : region2

      if (snapLine.isPointOnLine(snapPoint.point)) {
        commonPoint = snapPoint.point
      }
    } else if (lineAndLine) {
      if (
        SnapLine.linesIntersect(
          region1.slope,
          region1.yShift,
          region2.slope,
          region2.yShift
        )
      ) {
        commonPoint = SnapLine.getIntersectionPoint(
          region1.slope,
          region1.yShift,
          region2.slope,
          region2.yShift
        )
      }
    } else if (pointAndPoint) {
      if (Util.pointsAreEqual2D(region1.point, region2.point)) {
        commonPoint = region1.point
      }
    }

    return commonPoint
  }
}

export default SnapRegion
export {
  SnapLine,
  SnapPoint,
  CompoundCursorSnapRegion,
  CompoundEdgeSnapRegion,
  SnapRegionUtils,
}
