import { graphql } from '~/gql'

const FACILITY_FRAGMENT = graphql(`
  fragment FacilityFragment on Facility {
    id
    name
    primaryObjective
    primaryUse
    primaryType
    ashraeCompliant
    airConditioned
    location
    indoorSummerTemp
    indoorWinterTemp
    indoorHumidity
    indoorWinterHumidity
    createdAt
    updatedAt
    units
    isTemplate
    isArchived
    isFavorite
    author {
      id
      name
      email
    }
  }
`)

export default FACILITY_FRAGMENT
