/* eslint-disable no-loop-func */
import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'
import { Vector3 } from 'three'

import store from 'store'
import { hidePanel } from 'store/panel'
import { isLayersPanelVisible } from 'store/panel/selectors'
import { LAYERS_PANEL } from 'store/panel/types'
import {
  getSelectedFacilityObjects,
  getSelectedObjects,
  selectObjects,
  anyObjectSelected,
  deselectObjects,
  clearGridBox,
} from 'store/selectedObjects/selectors'
import { updateObjects } from 'store/objects'
import {
  isSnapEnabled,
  isOrthoModeEnabled,
  activeTool,
} from 'store/tools/selectors'
import { isTouchUI } from 'store/userInterface/selectors'
import CLASS_NAMES from 'config/objectClassNames'

import FixedSizeQueue from './fixedSizeQueue'
import { SnapRegionUtils } from './snapRegion'
import Util from './util'
import Wall from './wall'
import WallSegment from './wallSegment'
import Facility from './facility'
import Camera from './camera'
import Units from './units'
import QuickState from './quickState'
import GridBox from './gridBox'

import * as THREE from 'three'

/*
  Interactifier provides a system for detecting and notifying others when
  mouse events occur on objects which are not backed by DOM elements (e.g.
  walls or obstructions, or drag resize handles). The idea is to allow these
  objects to implement their own behavior which executes in response to
  these events occurring, and to allow other systems to be notified when
  the events occur (e.g. when an object in the scene gains or loses focus,
  or when an object which was being dragged is dropped).

  Other systems may subscrube to Interactifier events by using the
  'addEventListener(listener)' method, and implementing handlers for
  the various events dealt with by Interactifier. See 'eventNameToFuncMap'
  for a list of event names and handler function names which may be implemented
  by listeners.
*/
class Interactifier {
  /*
    -= properties =-

    snapRegionProvider
    interactablesProvider
    eventListeners
    eventNameToFuncMap
    mousePos
    snappedMousePos
    mouseDownPos
    isMouseDown
    objectWithCursor
    objectBeingDragged
    obj3d: We attached snap lines and other UI elements to this for rendering
    allowSelection
  */

  static MOUSE_POS_QUEUE_SIZE = 10

  constructor(snapRegionProvider, interactablesProvider, raycaster, getState) {
    this.snapRegionProvider = snapRegionProvider
    this.interactablesProvider = interactablesProvider
    this.eventListeners = []
    this.eventNameToFuncMap = {
      // Params: lastObjectWithCursor, newObjectWithCursor
      HOVER_CHANGED: 'objectWithCursorChanged',

      // Params: lastSelectedObject, thisSelectedObject
      SELECTION_CHANGED: 'selectedObjectChanged',

      // Params: draggedObject, dragVector
      OBJECT_WAS_DRAGGED: 'objectWasDragged',
    }

    this.projectedMousePos = { x: 0, y: 0 }
    this.snappedMousePos = { x: 0, y: 0 }
    this.pastProjectedMousePositions = new FixedSizeQueue(
      Interactifier.MOUSE_POS_QUEUE_SIZE
    )
    this.projectedMouseDownPos = new THREE.Vector3()
    this.isMouseDown = false

    this.objectWithCursor = undefined
    this.objectBeingDragged = undefined

    this.allowSelection = false
    this.allowDrag = false

    this.obj3d = new THREE.Object3D()
    this.mouseDownCameraPos = new THREE.Vector3()
    this.mouseUpCameraPos = new THREE.Vector3()

    this.raycaster = raycaster
    this.getState = getState
    this.lastSceneIntersection = { point: { x: 0, y: 0, z: 0 } }
  }

  addEventListener(listener) {
    this.eventListeners.push(listener)
  }

  notifyEventListeners(eventName, eventData) {
    const funcName = this.eventNameToFuncMap[eventName]

    // Notify event listeners of the occurance of events
    // which they have implemented handlers for
    this.eventListeners.forEach(listener => {
      if (listener[funcName] !== undefined) {
        listener[funcName](...eventData)
      }
    })
  }

  mouseDown(mousePos, projectedMousePos, suppressMouseDown, isTouch = false) {
    document.activeElement.blur()
    this.isMouseDown = true
    this.projectedMouseDownPos = projectedMousePos
    this.orthoModeReferencePoint = this.projectedMouseDownPos.clone()
    projectedMousePos = this.transformProjectedMousePos(projectedMousePos)

    // The `projectedMousePos` needs to be set properly for
    // touch devices since they don't track these values on
    // "hover" in `mouseMove()`
    if (isTouch) {
      this.projectedMousePos = projectedMousePos
      this.snappedMousePos = projectedMousePos
      this.lastProjectedMousePos = projectedMousePos
    }

    if (!suppressMouseDown) {
      this.updateObjectWithCursor(mousePos)

      if (this.anyObjectHasCursor()) {
        const selectedObjects = getSelectedFacilityObjects(Facility.current)
        this.selectedObjectPositions = selectedObjects.map(o => ({
          id: o.id,
          position: o.obj3d.position.clone(),
        }))
        this.selectedObjectWasMouseDownOn =
          selectedObjects.length &&
          selectedObjects.some(
            selectedObject => selectedObject.id === this.objectWithCursor.id
          )
        this.objectWithCursor.positionBeforeDrag = this.objectWithCursor.obj3d.position.clone()
      } else {
        this.selectedObjectWasMouseDownOn = false
      }
    }
  }

  mouseUp(projectedMousePos, multiSelect, isDoubleClick) {
    this.isMouseDown = false
    projectedMousePos = this.transformProjectedMousePos(projectedMousePos)

    if (this.anyObjectBeingDragged()) {
      let updatedObjectModels = []
      const updatedObjectIds = []
      let draggedObjects = []
      if (activeTool() === 'MOVE_TOOL') {
        draggedObjects = [this.objectBeingDragged]
      } else {
        draggedObjects = getSelectedFacilityObjects(Facility.current)
      }
      draggedObjects.forEach(object => {
        const useParent = object instanceof WallSegment
        const id = useParent ? object.getParent().id : object.id
        if (object.draggable && !updatedObjectIds.includes(id)) {
          object.drop(
            this.lastSceneIntersection.object,
            this.allIntersectedObjects,
            false
          )
          if (useParent) {
            updatedObjectModels.push(object.getParent().toModel())
          } else {
            updatedObjectModels.push(object.toModel())
          }
          updatedObjectIds.push(id)
        }
      })
      if (this.objectBeingDragged.className !== CLASS_NAMES.GRID_BOX) {
        store.dispatch(updateObjects(updatedObjectModels))
      }
      this.objectBeingDragged.positionBeforeDrag = undefined
      this.objectBeingDragged = undefined
      this.selectedObjectPositions = []
    }

    const mouseMovementDistance = this.projectedMouseDownPos
      .clone()
      .sub(projectedMousePos)
      .length()

    const cameraMovementDistance = this.mouseDownCameraPos.distanceTo(
      this.mouseUpCameraPos
    )

    // This way we don't generate click events when the user drags their mouse
    if (
      mouseMovementDistance < Units.inchesToNative(12) &&
      cameraMovementDistance < Units.inchesToNative(12) &&
      (!this.isGridBoxAndObject || isDoubleClick)
    ) {
      if (this.isGridBoxAndObject && isDoubleClick) {
        this.objectWithCursor = this.isGridBoxAndObject
      }

      if (!(this.objectWithCursor instanceof GridBox)) {
        clearGridBox()
      }
      this.click(projectedMousePos, multiSelect)
    }
  }

  click(projectedMousePos, multiSelect) {
    if (this.allowSelection) {
      this.updateSelection({
        forceSelect: this.selectOnLastClick,
        multiSelect,
      })
    }
  }

  rightClick(/* projectedMousePos */) {}

  mouseMove(mousePos, projectedMousePos, disableSnapping, isShiftDown) {
    this.isShiftDown = isShiftDown
    this.projectedMousePos = this.transformProjectedMousePos(projectedMousePos)
    this.snappedMousePos = projectedMousePos
    this.activeSnapRegion = undefined
    this.pastProjectedMousePositions.add(projectedMousePos)

    if (!this.anyObjectBeingDragged()) {
      this.updateObjectWithCursor(mousePos)
    }

    if (this.shouldSnapMousePosition() && !disableSnapping) {
      this.updateSnappedMousePos()
    }

    if (this.allowDrag && this.anyObjectHasCursor()) {
      // This way know the mouse went down on the object in question, we didn't just enter
      // its bounds after the mouse down event.
      if (
        this.isMouseDown &&
        this.objectWithCursor.draggable &&
        (this.selectedObjectWasMouseDownOn || activeTool() === 'MOVE_TOOL')
      ) {
        let objects = []
        let positions = []
        if (activeTool() === 'MOVE_TOOL') {
          objects = [this.objectWithCursor]
          positions = [
            {
              position: this.objectWithCursor.positionBeforeDrag,
              id: this.objectWithCursor.id,
            },
          ]
        } else if (this.selectedObjectWasMouseDownOn) {
          objects = getSelectedFacilityObjects(Facility.current)
          positions = this.selectedObjectPositions
        }

        this.objectBeingDragged = this.objectWithCursor
        this.updateObjectsPosition(objects, positions, projectedMousePos)
      }
    }

    this.updateSnapVisual()
    this.lastProjectedMousePos = this.projectedMousePos
  }

  /*
    Right now the only transformation is for ortho-mode,
    but others could be added here in the future.
  */
  transformProjectedMousePos(pos) {
    const inOrthoMode = isOrthoModeEnabled() || QuickState.inOrthoMode
    const isSelectTool = activeTool() === 'SELECT_TOOL'
    if (inOrthoMode && this.orthoModeReferencePoint && !isSelectTool) {
      const pos2d = new THREE.Vector2(pos.x, pos.y)
      const lastDownPos = new THREE.Vector2(
        this.orthoModeReferencePoint.x,
        this.orthoModeReferencePoint.y
      )

      const toDownPos = pos2d.clone().sub(lastDownPos)
      const localMouseVector = this.getMouseDeltaVector()

      const xScore =
        Math.min(50, localMouseVector.x ** 2) + Math.abs(toDownPos.x)
      const yScore =
        Math.min(50, localMouseVector.y ** 2) + Math.abs(toDownPos.y)

      if (xScore > yScore) {
        return new THREE.Vector3(pos.x, lastDownPos.y, pos.z)
      } else {
        return new THREE.Vector3(lastDownPos.x, pos.y, pos.z)
      }
    }

    return pos
  }

  orthoModeEntered(toolReferencePoint) {
    if (toolReferencePoint) {
      this.orthoModeReferencePoint = toolReferencePoint.clone()
    } else {
      this.orthoModeReferencePoint = null
    }
  }

  updateSelection({ forceSelect, multiSelect } = {}) {
    const state = store.getState()
    if (forceSelect || this.anyObjectHasCursor()) {
      const layers = state.layers.layers
      const layerKey = get(this.objectWithCursor, 'layerKey')
      if (layers[layerKey]) {
        selectObjects({
          objects: [this.objectWithCursor],
          multiSelect,
        })

        // If in touch mode and the layers panel is visible,
        // we should hide it.
        if (isTouchUI(state) && isLayersPanelVisible())
          store.dispatch(hidePanel({ type: LAYERS_PANEL }))
      }

      const selectedObjects = getSelectedFacilityObjects(Facility.current)
      this.notifyEventListeners('SELECTION_CHANGED', [
        selectedObjects,
        getSelectedFacilityObjects(Facility.current),
      ])
    } else if (anyObjectSelected(state) || state.objects.present.gridBox) {
      deselectObjects({})
    }
  }

  updateSnapVisual() {
    const active = this.getActiveSnapRegion()
    if (this.lastActive !== undefined && this.lastActive !== active) {
      this.obj3d.remove(this.lastActive.obj3d)
    }

    if (active !== undefined && active !== this.lastActive) {
      this.obj3d.add(active.obj3d)
      this.lastActive = active
    }
  }

  getSnappedEdgePoints(point1, point2) {
    const snapRegions = this.snapRegionProvider.getAllSnapRegions()

    const activeSnapRegion = snapRegions.find(
      snapRegion =>
        snapRegion.isWithinSnapRange(point1) &&
        snapRegion.isWithinSnapRange(point2)
    )

    if (activeSnapRegion !== undefined) {
      const snappedPoint1 = activeSnapRegion.snappedPointForPoint(point1)
      const snappedPoint2 = activeSnapRegion.snappedPointForPoint(point2)

      return {
        first: snappedPoint1,
        second: snappedPoint2,
        snapRegion: activeSnapRegion,
        snapRegionWasActive: true,
      }
    }
    return { first: point1, second: point2, snapRegionWasActive: false }
  }

  updateObjectWithCursor(mousePos) {
    const lastObjectWithCursor = this.objectWithCursor
    const interactables = this.interactablesProvider.getAllInteractables()

    this.objectWithCursor = this.findObjectWithCursor(
      mousePos,
      interactables,
      true
    )

    if (
      this.objectWithCursorChanged(this.objectWithCursor, lastObjectWithCursor)
    ) {
      this.notifyEventListeners('HOVER_CHANGED', [
        lastObjectWithCursor,
        this.objectWithCursor,
      ])

      // Trigger mouse events on object unless layer is locked
      const state = store.getState()
      const layers = state.layers.layers
      const layerKey = get(this.objectWithCursor, 'layerKey')
      if (layers[layerKey] && !layers[layerKey].locked) {
        if (this.objectWithCursor && this.objectWithCursor.mouseEntered) {
          this.objectWithCursor.mouseEntered(mousePos, this.snappedMousePos)
        }

        if (lastObjectWithCursor && lastObjectWithCursor.mouseExited) {
          lastObjectWithCursor.mouseExited(mousePos, this.snappedMousePos)
        }
      }

      QuickState.objectWithCursorId = this.objectWithCursor
        ? this.objectWithCursor.id
        : null
    }
  }

  objectWithCursorChanged(objectWithCursor, lastObjectWithCursor) {
    if (lastObjectWithCursor !== objectWithCursor) {
      if (!lastObjectWithCursor || !objectWithCursor) return true
      return objectWithCursor.id !== lastObjectWithCursor.id
    }

    return false
  }

  findObjectWithCursor(mousePos, interactables, saveIntersection) {
    if (interactables === null || interactables === undefined) {
      return undefined
    }

    // Filter out objects on hidden layers
    const layers = store.getState().layers.layers
    const visibleInteractables = interactables.filter(
      interactable =>
        interactable.layerKey && layers[interactable.layerKey].visible
    )

    const camera = Camera.current

    // this.raycaster.setFromCamera(this.getNDCMousePosition(mousePos), camera)

    let intersections = []

    // Search for these objects first
    const firstToCheck = ['GridBox', 'Obstruction', 'Product']
    const selectedObjects = getSelectedFacilityObjects(Facility.current)
    const filteredObjects = selectedObjects.filter(obj =>
      firstToCheck.includes(obj.className)
    )

    filteredObjects.forEach(obj => {
      // We don't want to use recursive rays on complex objects like
      // fans due to the huge hit on performance
      const isRecursive = this.isComplexObject(
        get(obj, 'obj3d.userData.objectType')
      )

      intersections = intersections.concat(
        this.raycaster.intersectObject(obj.obj3d, isRecursive)
      )
    })

    // If no selected objects are found under the cursor, then search the rest
    if (!intersections.length) {
      visibleInteractables
        .map(o => o.obj3d)
        .forEach(child => {
          // TODO: Consider adding more items to isComplex filter
          // We don't want to use recursive rays on complex objects like
          // fans due to the huge hit on performance
          const isRecursive = this.isComplexObject(
            get(child, 'userData.objectType')
          )

          intersections = intersections.concat(
            this.raycaster.intersectObject(child, isRecursive)
          )
        })
    }

    // Sort intersections by distance in ascending order
    const sortedIntersections = sortBy(intersections, 'distance')

    if (saveIntersection && sortedIntersections.length) {
      this.lastSceneIntersection = sortedIntersections[0]
    }

    let wrapper

    if (sortedIntersections.length) {
      const hits = this.matchInteractablesWithIntersections(
        sortedIntersections,
        visibleInteractables
      )
      const nearest = hits[0]

      if (nearest) {
        this.allIntersectedObjects = hits.map(hit => hit.interactable)
        wrapper = nearest.interactable
        this.isGridBoxAndObject = this.checkGridBoxAndObject(wrapper)
      } else {
        return undefined
      }
    }

    return wrapper
  }

  checkGridBoxAndObject(gridBox) {
    if (!(gridBox instanceof GridBox)) return false

    const model = gridBox.models.find(model => {
      const object = Facility.current.findObjectWithId(model.id)
      if (!object) return false
      return this.raycaster.intersectObject(object.obj3d).length
    })

    return model && Facility.current.findObjectWithId(model.id)
  }

  get canvasWidth() {
    return this.getState().size.width
  }
  get canvasHeight() {
    return this.getState().size.width
  }

  /*
    NDC = normalized device coordinates
  */
  getNDCMousePosition(mousePos) {
    const ndcX = (mousePos.x / this.canvasWidth) * 2 - 1
    const ndcY = -(mousePos.y / this.canvasHeight) * 2 + 1
    const ndcMousePos = new THREE.Vector2(ndcX, ndcY)

    return ndcMousePos
  }

  isObjectWithCursorSelected() {
    const selectedObjects = getSelectedObjects()
    const objectWithCursor = this.getObjectWithCursor()
    const selectedObjectWithCursor = selectedObjects.find(obj => {
      const selectedObjectId = get(obj, 'id')
      const objectWithCursorId = get(objectWithCursor, 'id')
      return objectWithCursorId === selectedObjectId
    })

    return Boolean(selectedObjectWithCursor)
  }

  isComplexObject(objectType) {
    return objectType !== 'FAN_BLADE_CYLINDER'
  }

  /*
    Match each intersected scene graph node with its associated interactable.
    Returns an array of {interactable, intersection} objects.
  */
  matchInteractablesWithIntersections(intersections, interactables) {
    intersections = this.filterCompetingIntersectedObjects(intersections)

    const result = intersections
      .map(intersection => {
        let matchedInteractable = interactables.find(
          interactable =>
            interactable.id &&
            intersection.object.wrapperId &&
            interactable.id === intersection.object.wrapperId
        )

        // Since the .obj3d of Wall objects is made up of a number of parts,
        // here we determine whether the intersection was with the floor
        // or a wall segment, and if was a wall segment, we determine which.
        if (matchedInteractable instanceof Wall) {
          const wall = matchedInteractable

          // It's a wall segment
          if (!intersection.object.isFloor) {
            matchedInteractable = wall.getSegmentWithVertexIndex(
              intersection.faceIndex
            )
          }
        }

        return {
          interactable: matchedInteractable,
          intersection: intersection,
        }
      })
      .filter(a => !!a.interactable) // .map still returns if undefined, so without this line we can potentially get an array with `undefined` at [0]

    // sort by 'click priority'
    result.sort(
      (a, b) =>
        (b.interactable.clickPriority || 0) -
        (a.interactable.clickPriority || 0)
    )

    return result
  }

  // When a user clicks somewhere on the screen, their cursor may be positioned over multiple
  // overlapping objects. In most cases it's okay to just selecte the object nearest the screen,
  // but sometimes we need custom logic for determining which of those multiple objects should
  // be selected (e.g. if we have multiple overlapping floors which are at the same Z).
  filterCompetingIntersectedObjects(intersections) {
    // If more than one floor was intersected, remove all except the smallest floor.
    const allFloors = intersections
      .filter(intersection => intersection.object.isFloor)
      .map(intersection => intersection.object)
    if (allFloors.length > 1) {
      let smallestFloor = allFloors[0]
      let smallestRadius = smallestFloor.geometry.boundingSphere.radius
      for (let i = 1; i < allFloors.length; i++) {
        const curFloor = allFloors[i]
        const curRadius = curFloor.geometry.boundingSphere.radius
        if (curRadius < smallestRadius) {
          smallestRadius = curRadius
          smallestFloor = curFloor
        }
      }

      intersections = intersections.filter(
        intersection =>
          !intersection.object.isFloor || intersection.object === smallestFloor
      )
    }

    return intersections
  }

  getObjectWithSnappedCursor() {
    const camera = Camera.current

    const snappedScreenPoint = Util.vec3ToScreenPoint(
      new THREE.Vector3(this.snappedMousePos.x, this.snappedMousePos.y, 0),
      camera,
      this.canvasWidth,
      this.canvasHeight
    )

    const interactables = this.interactablesProvider.getAllInteractables()

    const objectWithSnappedCursor = this.findObjectWithCursor(
      snappedScreenPoint,
      interactables,
      false
    )

    return objectWithSnappedCursor
  }

  getObjectWithCursor() {
    return this.objectWithCursor
  }

  getAllIntersectedObjects() {
    return this.allIntersectedObjects || []
  }

  getLastSceneIntersectionPoint() {
    return this.lastSceneIntersection.point
  }

  updateObjectsPosition(objects, positions, projectedMousePos) {
    projectedMousePos = this.transformProjectedMousePos(projectedMousePos)

    const mouseXChange = projectedMousePos.x - this.lastProjectedMousePos.x
    const mouseYChange = projectedMousePos.y - this.lastProjectedMousePos.y
    const totalMouseXChange = projectedMousePos.x - this.projectedMouseDownPos.x
    const totalMouseYChange = projectedMousePos.y - this.projectedMouseDownPos.y

    const dragDelta = new THREE.Vector3(mouseXChange, mouseYChange, 0)
    const totalDragDelta = new THREE.Vector3(
      totalMouseXChange,
      totalMouseYChange,
      0
    )

    objects.forEach(object => {
      if (!object.draggable || (objects.length > 1 && !object.multiDraggable)) {
        return
      }
      const initialPosition = positions.find(o => o.id === object.id)

      if (get(initialPosition, 'position')) {
        const newPosition = initialPosition.position.clone().add(totalDragDelta)
        object.drag({
          dragDelta,
          newPosition,
          projectedMousePos,
          lastSceneIntersectionObject: this.lastSceneIntersection.object,
          allIntersectedObjects: this.allIntersectedObjects,
          objectWithCursor: this.objectWithCursor,
          lastProjectedMousePos: this.lastProjectedMousePos,
          isShiftDown: this.isShiftDown,
        })
      }

      this.notifyEventListeners('OBJECT_WAS_DRAGGED', [object, dragDelta])

      const activeSnapRegion = this.getActiveSnapRegionForObject(object)

      if (activeSnapRegion && isSnapEnabled()) {
        this.activeSnapRegion = activeSnapRegion
        object.snap(
          this.activeSnapRegion.snapDelta,
          this.lastSceneIntersection.object,
          this.snappedMousePos,
          this.activeSnapRegion.point1,
          this.activeSnapRegion.point2
        )
      }
    })
  }

  /*
    Find the active snap region for the object being dragged. Interactables may
    use the property .snapToMousePosition to control whether snapping is based
    on current mouse position, or the object's edges.
  */
  getActiveSnapRegionForObject(object) {
    if (object.snapToMousePosition) {
      return this.getActiveSnapRegionForPoint(this.projectedMousePos)
    } else {
      const edgePoints = object.getSnappableEdgePoints(
        this.lastSceneIntersection.object
      )

      // The naming is a bit misleading here... we snap to a point
      // instead of to edges if the edgePoints array we're given
      // only has one element.
      if (edgePoints.length === 1) {
        return this.getActiveSnapRegionForPoint(edgePoints[0])
      }

      return this.getActiveSnapRegionForEdgePoints(edgePoints)
    }
  }

  setCanvasSize(width, height) {
    this.canvasWidth = width
    this.canvasHeight = height
  }

  getMouseDeltaVector() {
    const first = this.pastProjectedMousePositions.getFirst()
    const last = this.pastProjectedMousePositions.getLast()

    if (first && last) {
      return new Vector3(first.x - last.x, first.y - last.y, 0)
    }
    return new Vector3(0, 0, 0)
  }

  updateSnappedMousePos() {
    const activeSnapRegion = this.getActiveSnapRegionForPoint(
      this.projectedMousePos
    )

    if (activeSnapRegion) {
      this.snappedMousePos = activeSnapRegion.snappedPointForPoint(
        this.projectedMousePos
      )
      this.activeSnapRegion = activeSnapRegion
    } else {
      this.snappedMousePos = this.projectedMousePos
    }
  }

  getActiveSnapRegionForPoint(point) {
    const snapRegions = this.snapRegionProvider.getAllSnapRegions()

    const activeSnapRegions = snapRegions.filter(snapRegion =>
      snapRegion.isWithinSnapRange(point)
    )

    if (activeSnapRegions.length > 0) {
      const finalSnapRegion = SnapRegionUtils.reconcileCursorSnapRegions(
        activeSnapRegions,
        point
      )

      const snappedPoint = finalSnapRegion.snappedPointForPoint(point)
      finalSnapRegion.snapDelta = new Vector3(
        snappedPoint.x - point.x,
        snappedPoint.y - point.y,
        0
      )

      return finalSnapRegion
    }
  }

  getActiveSnapRegionForEdgePoints(edgePoints) {
    if (edgePoints.length % 2 !== 0) {
      throw new Error(
        "Something's wrong: getSnappableEdgePoints() should give an even number of points."
      )
    }

    const candidateSnapRegions = []

    for (let i = 0; i < edgePoints.length; i += 2) {
      edgePoints[i] = Util.arrayPointToObjectPoint(edgePoints[i])
      edgePoints[i + 1] = Util.arrayPointToObjectPoint(edgePoints[i + 1])

      const snappedPointsData = this.getSnappedEdgePoints(
        edgePoints[i],
        edgePoints[i + 1]
      )

      if (snappedPointsData.snapRegionWasActive) {
        const snapRegion = snappedPointsData.snapRegion

        const point = edgePoints[i]
        const snappedPoint = snappedPointsData.first
        const snappedEdgeDelta = new Vector3(
          snappedPoint.x - point.x - 0.0001,
          snappedPoint.y - point.y - 0.0001,
          0
        )

        snapRegion.snapDelta = snappedEdgeDelta

        candidateSnapRegions.push(snapRegion)
      }
    }

    if (candidateSnapRegions.length > 0) {
      const activeSnapRegion = SnapRegionUtils.reconcileEdgeSnapRegions(
        candidateSnapRegions,
        this.projectedMousePos,
        this.getMouseDeltaVector()
      )

      return activeSnapRegion
    }
  }

  getSnappedMousePosition() {
    return this.snappedMousePos
  }

  getProjectedMousePosition() {
    return this.projectedMousePos
  }

  getProjectedMouseDownPosition() {
    return this.projectedMouseDownPos
  }

  setProjectedMouseDownPosition(mousePos) {
    this.projectedMouseDownPos = mousePos
  }

  getActiveSnapRegion() {
    return this.activeSnapRegion
  }

  getObjectBeingDragged() {
    return this.objectBeingDragged
  }

  anyObjectHasCursor() {
    return this.objectWithCursor !== undefined
  }

  anyObjectBeingDragged() {
    return this.objectBeingDragged !== undefined
  }

  shouldSnapMousePosition() {
    return (
      !this.anyObjectBeingDragged() ||
      this.objectBeingDragged.snapToMousePosition
    )
  }

  objectBeingDraggedId() {
    if (this.anyObjectBeingDragged()) {
      return this.objectBeingDragged.id
    }

    return undefined
  }
}

export default Interactifier
