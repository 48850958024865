import Facility from './facility'
import get from 'lodash-es/get'

import store from 'store'
import { clearStatus, setStatus } from 'store/status'

import { getThreeHexFromTheme } from 'lib/utils'
import Units from './units'
import Primitives from './primitives'

import * as THREE from 'three'

const errors = {
  wall: `Too close to a wall! (must not be overlapping a wall)`,
  floor: `Obstruction must be inside facility!`,
}

function getRaycasterPoints(wallPoints, delta) {
  const point1 = new THREE.Vector3(
    wallPoints[0][0] + delta.x,
    wallPoints[0][1] + delta.y,
    0
  )
  const point2 = new THREE.Vector3(
    wallPoints[1][0] + delta.x,
    wallPoints[1][1] + delta.y,
    0
  )

  return [point1, point2]
}

// Create rows of raycasters from one end of the wall to the other
// Using both inset and outset points
// Using multiple raycasters at different heights allows us to check intersections
// Even if obstruction is offset
function checkWallIntersection([startPoint, endPoint], wall, obstruction) {
  const object = obstruction.selectionBox.clone()
  object.position.copy(obstruction.obj3d.position)
  object.children = []
  // Make obstruction height a little smaller in case rays are at exact top and bottom of obstruction
  // and keep the height from going to zero
  const obstructionHeight = Units.inchesToNative(obstruction.height) - 1 || 1
  const numRaycasters = Math.ceil(wall.height / obstructionHeight)
  const heightDelta = wall.height / numRaycasters

  // <= because we want to go from bottom to top (inclusive)
  for (let i = 0; i <= numRaycasters; ++i) {
    startPoint.setZ(i * heightDelta)
    endPoint.setZ(i * heightDelta)

    const length = startPoint.distanceTo(endPoint) * 0.98
    const direction = new THREE.Vector3()
      .subVectors(endPoint, startPoint)
      .normalize()

    // Add small offset to prevent raycaster being slighly
    // outside wall and causing invalid hits
    startPoint.addScaledVector(direction, 0.1)

    const raycaster = new THREE.Raycaster(startPoint, direction, 0, length)

    const intersections = raycaster.intersectObject(object)

    if (intersections.length) {
      return true
    }
  }
}

function getAdjustedPoints(startPoints, endPoints) {
  const centerStartPoint = startPoints[0].clone().add(startPoints[1])
  const centerEndPoint = endPoints[0].clone().add(endPoints[1])
  const direction = centerEndPoint.sub(centerStartPoint).normalize()

  const adjustedPoints = startPoints.map(point =>
    new THREE.Vector3().copy(point).addScaledVector(direction, 0.1)
  )

  return adjustedPoints
}

function isInsideFacility(obstruction) {
  const pos = obstruction.obj3d.position.clone()
  const walls = Facility.current.getWalls()

  if (!walls || !walls.length) return false

  let isInside = false

  walls.forEach(wall => {
    const bbox = new THREE.Box3().setFromObject(wall.obj3d)
    if (bbox.containsPoint(pos)) {
      isInside = true
    }
  })

  const roofs = Facility.current.getRoofs()
  roofs.forEach(roof => {
    const bbox = new THREE.Box3().setFromObject(roof.obj3d)
    if (bbox.containsPoint(pos)) {
      isInside = true
    }
  })

  return !!isInside
}

export function wallDistanceCheck(obstruction) {
  obstruction.obj3d.updateMatrixWorld()
  const walls = Facility.current.getWallSegments()

  const isWallError = walls.some(wall => {
    const parentPosition = wall.obj3d.parent.position
    const insetPoints = getRaycasterPoints(wall.insetPoints, parentPosition)
    const outsetPoints = getRaycasterPoints(wall.outsetPoints, parentPosition)
    const adjustedInsetPoints = getAdjustedPoints(insetPoints, outsetPoints)
    const adjustedOutsetPoints = getAdjustedPoints(outsetPoints, insetPoints)
    const intersectsInsetPoints = checkWallIntersection(
      adjustedInsetPoints,
      wall,
      obstruction
    )
    const intersectsOutsetPoints = checkWallIntersection(
      adjustedOutsetPoints,
      wall,
      obstruction
    )

    return intersectsInsetPoints || intersectsOutsetPoints
  })

  const isInside = isInsideFacility(obstruction)

  if (isWallError) {
    addErrorState(obstruction)
    showErrorAlert(errors.wall)
  } else if (!isInside) {
    addErrorState(obstruction)
    showErrorAlert(errors.floor)
  } else if (obstruction.hasDangerousCollisions) {
    removeErrorState(obstruction)
  }

  return !isWallError || isInside
}

export function addErrorState(obstruction) {
  if (!obstruction.selectionBox.material) return

  obstruction.errorSign = Primitives.getErrorSign(obstruction)
  obstruction.selectionBox.add(obstruction.errorSign)

  // set obstruction material color to red
  obstruction.selectionBox.material.opacity = 1
  if (get(obstruction.selectionBox, 'material.color')) {
    obstruction.selectionBox.material.color.setHex(
      getThreeHexFromTheme('three.invalid')
    )
  }
}

export function removeErrorState(obstruction) {
  hideErrorState(obstruction)
  store.dispatch(clearStatus())
}

export function hideErrorState(obstruction) {
  if (!obstruction.selectionBox.material) return

  if (obstruction.errorSign) {
    obstruction.obj3d.remove(obstruction.errorSign)
    obstruction.errorSign = null
  }

  const color = get(obstruction.selectionBox, 'material.color')
  if (obstruction.draggable && color) {
    obstruction.selectionBox.material.color.setHex(
      getThreeHexFromTheme('three.objects.obstruction.selected')
    )
  } else {
    obstruction.selectionBox.material.color.setHex(
      getThreeHexFromTheme('three.objects.obstruction.deselected')
    )
  }
}

export function showErrorAlert(error) {
  store.dispatch(setStatus({ text: error, type: 'error' }))
}
