import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const TITLE_HEIGHT = 35

const collapsibleStyles = ({ collapsible, collapsed, height, theme }) => {
  if (collapsible) {
    return `
      bottom: ${collapsed ? `-${height - TITLE_HEIGHT}px` : '0'};
      padding: 0;
      height: ${height}px;
      position: absolute;
      right: 0;
      cursor: pointer;
    `
  }

  return `
    padding: ${theme.spacing.base};
  `
}

const Container = styled.div`
  background-color: ${theme('colors.light.bg')};
  width: 100%;

  ${collapsibleStyles};

  ${props => props.flushBottom && `padding-bottom: 0;`};
  ${props => props.title && `padding-top: 0;`};
`

export default Container
