import React, { Component } from 'react'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { format } from 'date-fns'
import ReactCrop, { convertToPixelCrop } from 'react-image-crop'
import { Helmet } from 'react-helmet'
import { object, func, string, bool } from 'prop-types'
import get from 'lodash-es/get'

import 'react-image-crop/dist/ReactCrop.css'

import withSnapshot from 'client/decorators/withSnapshot'
import withUser from 'client/decorators/withUser'

import cloudinary from 'config/cloudinary'
import { getTitle } from 'config/titles'
import { currentUserIsCustomer } from 'lib/currentUserIs'

import { showAlert } from 'store/alert'

import Button from 'components/UIKit/Button'
import Loader from 'components/UIKit/Loader'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'

import CancelLink from './styled/CancelLink'
import DownloadImageButton from 'components/DownloadImageButton'
import Content from './styled/Content'
import EditIcon from './styled/EditIcon'
import EditInputContainer from './styled/EditInputContainer'
import EditTitleContainer from './styled/EditTitleContainer'
import Image from './styled/Image'
import ImageButtons from './styled/ImageButtons'
import ImageContainer from './styled/ImageContainer'
import LoaderContainer from './styled/LoaderContainer'
import Placeholder from './styled/Placeholder'
import TitleContainer from './styled/TitleContainer'

const defaultImageSizes = {
  width: 800,
  height: 600,
}

class SnapshotModal extends Component {
  state = {
    initialTitle: '',
    editedTitle: '',
    isEditing: false,
    isSaving: false,
    isCropping: false,
    isLoadingImage: true,
    canCancel: true,
    crop: {
      unit: '%',
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },
    imageWidth: defaultImageSizes.width * 2,
    imageHeight: 0,
    imageUrl: '',
    originalImageUrl: '',
    angle: 0,
  }

  getImageUrl = (size, excludeCropData = false) => {
    const { width, height, angle } = size
    const pixelCrop = convertToPixelCrop(this.state.crop, this.state.imageWidth, this.state.imageHeight)
    const { snapshot } = this.props
    let cropData =
      snapshot.cropData === null || excludeCropData
        ? {
            x: excludeCropData ? 0 : pixelCrop.x,
            y: excludeCropData ? 0 : pixelCrop.y,
            width,
            height,
            crop: 'crop',
            secure: true,
          }
        : {
            x: snapshot.cropData.x,
            y: snapshot.cropData.y,
            width: snapshot.cropData.width,
            height: snapshot.cropData.height,
            angle: snapshot.cropData.angle,
            crop: 'crop',
            secure: true,
          }
    if (angle) {
      cropData = {
        x: excludeCropData ? 0 : pixelCrop.x,
        y: excludeCropData ? 0 : pixelCrop.y,
        width,
        height,
        angle,
        crop: 'crop',
        secure: true,
      }
    }
    return cloudinary.url(snapshot.cloudinaryId, cropData)
  }

  handleDeleteClick = event => {
    const { onSnapshotDelete, onShowAlert, parentRoute, history, snapshot } =
      this.props

    event.preventDefault()
    event.stopPropagation()

    // eslint-disable-next-line
    if (confirm('Are you sure you want to delete this snapshot?')) {
      onSnapshotDelete({
        snapshotId: snapshot.id,
      }).then(() => {
        onShowAlert({
          type: 'success',
          text: 'Snapshot deleted!',
        })
        history.push(parentRoute)
      })
    }
  }

  handleEditIconClick = (event, title) => {
    event.preventDefault()

    this.setState({
      initialTitle: title,
      isEditing: true,
    })
  }

  handleCancelEditClick = event => {
    event.preventDefault()

    this.setState({
      isEditing: false,
      editedTitle: this.state.initialTitle,
    })
  }

  handleSaveClick = event => {
    event.preventDefault()

    const { onSave, match } = this.props

    this.setState({
      isSaving: true,
    })

    onSave({
      title: this.state.editedTitle,
      snapshotId: match.params.snapshotId,
    }).then(() => {
      this.setState({
        isEditing: false,
        isSaving: false,
      })
    })
  }

  handleTitleChange = event => {
    const { value } = event.target
    this.setState({
      editedTitle: value,
    })
  }

  handleImageCropChange = (_pixelCrop, percentCrop) => {
    this.setState({
      crop: percentCrop,
      canCancel: false,
    })
  }

  handleLoadedImage = size => {
    const { snapshot } = this.props
    const { angle } = this.state
    const { width, height } = size
    const { cropData } = snapshot
    let canCancel = true
    const savedAngle = get(cropData, 'angle', 0)

    if (cropData && cropData.width !== width && cropData.height !== height) {
      canCancel = false
    }
    if (savedAngle !== angle) {
      canCancel = false
    }
    this.setState({
      isLoadingImage: false,
      imageWidth: width,
      imageHeight: height,
      canCancel,
    })
  }

  handleCropClick = event => {
    event.preventDefault()
    this.setState({
      isCropping: true,
    })
  }

  handleImageRotate = rotation => {
    let newAngle = this.state.angle + rotation
    if (newAngle > 360) newAngle = 0 + Math.abs(rotation)
    if (newAngle < 0) newAngle = 360 - Math.abs(rotation)
    if (newAngle === 360) newAngle = 0

    this.setState({
      angle: newAngle,
      isLoadingImage: true,
      crop: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
      },
    })
  }

  handleCropSaveClick = event => {
    event.preventDefault()

    const { angle } = this.state
    const { onSave, match, snapshot } = this.props
    const savedAngle = get(snapshot, 'cropData.angle', 0)

    const pixelCrop = convertToPixelCrop(this.state.crop, this.state.imageWidth, this.state.imageHeight)
    const roundedCrop = Object.fromEntries(Object.entries(pixelCrop).map(([k, v]) => [k, typeof v !== 'number' ? v : Math.round(v)]))

    let height = snapshot.data.height
    let width = snapshot.data.width
    let pixelWidth = pixelCrop.width
    let pixelHeight = pixelCrop.height

    // If image is rotated, swap width and height to accomodate
    if (angle === 270 || angle === 90) {
      height = snapshot.data.width
      width = snapshot.data.height
      pixelWidth = pixelCrop.height
      pixelWidth = pixelCrop.width
    }
    // Ignore pixel crop if there is none
    if (roundedCrop.height === 0) {
      this.setState({
        isCropping: false,
      })
      if (savedAngle !== angle) {
        // Save data if only image was rotated
        this.setState({
          isLoadingImage: true,
          canCancel: false,
        })
        onSave({
          snapshotId: match.params.snapshotId,
          cropData: { angle, crop: 'crop' },
        }).then(() => {
          this.setState({
            angle,
            isCropping: false,
            imageUrl: this.getImageUrl({
              width,
              height,
              angle,
            }),
          })
        })
      }
      return
    }

    this.setState({
      isLoadingImage: true,
      canCancel: false,
    })
    onSave({
      snapshotId: match.params.snapshotId,
      cropData: { ...roundedCrop, angle, crop: 'crop' },
    }).then(() => {
      this.setState({
        angle,
        isCropping: false,
        imageUrl: this.getImageUrl({
          width: pixelWidth,
          height: pixelHeight,
          angle,
        }),
      })
    })
  }

  renderTitle = title => {
    const { isEditing, isSaving, editedTitle } = this.state

    if (isEditing) {
      return (
        <EditTitleContainer>
          <EditInputContainer>
            <TextField
              value={editedTitle !== '' ? editedTitle : title}
              autoSelect
              onChange={this.handleTitleChange}
            />
          </EditInputContainer>
          <Button
            primary
            label={isSaving ? 'Saving...' : 'Save'}
            onClick={this.handleSaveClick}
          />
          <CancelLink onClick={this.handleCancelEditClick}>Cancel</CancelLink>
        </EditTitleContainer>
      )
    }

    return (
      <TitleContainer>
        {title}
        {!currentUserIsCustomer(this.props.user) && (
          <EditIcon
            name="edit"
            size="24"
            onClick={event => this.handleEditIconClick(event, title)}
          />
        )}
      </TitleContainer>
    )
  }

  renderImageButtons = () => {
    if (currentUserIsCustomer(this.props.user)) return null
    const { isCropping, isLoadingImage, canCancel } = this.state

    const deleteButton = () => (
      <Button
        icon="trash"
        onClick={event => this.handleDeleteClick(event)}
        label="Delete"
      />
    )

    const setEditButtonLabel = () => {
      if (isLoadingImage) {
        return 'Saving...'
      }

      if (canCancel) {
        return 'Cancel'
      }

      return 'Save'
    }

    if (isCropping) {
      return (
        <>
          <Space bottom="s">
            <ImageButtons>
              <Button
                icon="imageRotateLeft"
                onClick={e => {
                  e.preventDefault()
                  this.handleImageRotate(-90)
                }}
              />
              <Button
                icon="imageRotateRight"
                onClick={e => {
                  e.preventDefault()
                  this.handleImageRotate(90)
                }}
              />
            </ImageButtons>
          </Space>
          <ImageButtons>
            <Button
              icon="crop"
              label={setEditButtonLabel()}
              onClick={this.handleCropSaveClick}
            />
            {deleteButton()}
          </ImageButtons>
        </>
      )
    }

    return (
      <ImageButtons>
        <Button
          icon="crop"
          label="Edit"
          onClick={this.handleCropClick}
          disabled={isLoadingImage}
        />
        {deleteButton()}
      </ImageButtons>
    )
  }

  renderLoader = () => {
    const { isLoadingImage, imageHeight } = this.state

    if (isLoadingImage) {
      return (
        <LoaderContainer>
          <Loader centered />
        </LoaderContainer>
      )
    }

    if (isLoadingImage && imageHeight > 0) {
      return <Placeholder height={imageHeight / 2} />
    }

    return null
  }

  renderImage = () => {
    const { isCropping, imageUrl, crop, imageWidth, angle } = this.state
    const { snapshot } = this.props
    let height = snapshot.data.height
    let width = snapshot.data.width

    // If image is rotated, swap width and height to accomodate
    if (angle === 270 || angle === 90) {
      height = snapshot.data.width
      width = snapshot.data.height
    }

    const originalImageUrl = this.getImageUrl(
      {
        width,
        height,
        angle,
      },
      true
    )
    const modifiedImageUrl = this.getImageUrl({
      width,
      height,
      angle: snapshot.data.angle,
    })

    if (isCropping) {
      return (
        <ImageContainer>
          <Space bottom="base">
            <ReactCrop
              onChange={this.handleImageCropChange}
              crop={crop}>
              <img src={originalImageUrl} onLoad={event => this.handleLoadedImage({
                width: event.currentTarget.naturalWidth,
                height: event.currentTarget.naturalHeight,
              })}/>
            </ReactCrop>
            {this.renderLoader()}
          </Space>
          {this.renderImageButtons()}
        </ImageContainer>
      )
    }

    return (
      <ImageContainer>
        <Space bottom="base">
          {this.renderLoader()}
          <Image
            src={imageUrl === '' ? modifiedImageUrl : imageUrl}
            width={imageWidth}
            onLoaded={size => this.handleLoadedImage(size)}
          />
        </Space>
        {this.renderImageButtons()}
      </ImageContainer>
    )
  }

  render() {
    const { loading, snapshot, parentRoute, history, ...props } = this.props
    const cloudinaryImage = get(snapshot, 'data.url')

    return (
      <div>
        <Helmet>
          <title>{getTitle('snapshot')}</title>
        </Helmet>
        <Modal
          title={loading ? 'Snapshot' : this.renderTitle(snapshot.title)}
          description={
            loading
              ? null
              : format(new Date(snapshot.createdAt), 'MM/dd/yyyy h:mm a')
          }
          primaryAction={
            <DownloadImageButton cloudinaryImage={cloudinaryImage} />
          }
          parentRoute={parentRoute}
          history={history}
          {...props}
        >
          <Content isLoading={loading} height={defaultImageSizes.height}>
            {loading ? (
              <Placeholder height={defaultImageSizes.height} />
            ) : (
              this.renderImage()
            )}
          </Content>
        </Modal>
      </div>
    )
  }
}

SnapshotModal.propTypes = {
  loading: bool,
  snapshot: object,
  props: object,
  parentRoute: string,
  history: object,
  match: object,
  facilityId: string,
  userToken: string,
  onSnapshotDelete: func,
  onShowAlert: func,
  onSave: func,
  user: object,
}

const mapStateToProps = ({ auth }) => ({
  userToken: auth.idToken,
})

const mapDispatchToProps = dispatch => ({
  onShowAlert({ text, type }) {
    dispatch(showAlert({ text, type }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withSnapshot,
  withUser
)(SnapshotModal)
