import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Stack } from 'components/UIKit/Stack'
import theme from 'config/theme'
import Icon from 'components/UIKit/Collapsible/styled/Icon'
import { AccordionDetails } from 'components/PerformanceMetrics/styled/AccordionDetails'
import VariantText from 'components/UIKit/VariantText'

const Accordion = ({ children, title, transitionDuration, ...stackProps }) => {
  const [open, setOpen] = useState(false)
  return (
    <Stack {...stackProps}>
      <Stack direction="row" onClick={() => setOpen(isOpen => !isOpen)}>
        <Icon name="arrowRight" size="14" color={theme.colors.light.subdued} isVisible={open} />
        <VariantText bold size="l">
          {title}
        </VariantText>
      </Stack>
      <AccordionDetails open={open} transitionDuration={transitionDuration}>
        {children}
      </AccordionDetails>
    </Stack>
  )
}

Accordion.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  transitionDuration: PropTypes.number,
}

export { Accordion }
