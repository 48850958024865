import { useAppSelector, useAppDispatch } from "~/store/hooks";

import { zoomIn, zoomOut, set2DZoom, start2DFit } from 'store/camera'

import { ToolbarGroup, ToolbarItem } from 'components/UIKit/Toolbar'
import Action from 'components/UIKit/Action'
import Icon from 'components/UIKit/Icon'

import ZoomInput from '../ZoomInput'
import ZoomButton from '../ZoomButton'

const ZoomToolbarGroup = ({
  size = '20%',
  alignment = 'right',
} = {}) => {
  const dispatch = useAppDispatch()
  const is3D = useAppSelector(state => state.camera.is3D)
  const two = useAppSelector(state => state.camera.two)

  return (
    <ToolbarGroup size={size} alignment={alignment}>
      <ToolbarItem space="base">
        <ZoomButton type="Out" onZoom={() => dispatch(zoomOut())} />
      </ToolbarItem>
      <ToolbarItem space="base">
        <ZoomInput
          disabled={is3D}
          onSubmit={({ zoomLevel: factor }) => dispatch(set2DZoom({ factor, passive: false }))}
          value={two.zoom.factor}
          onZoomIn={() => dispatch(zoomIn())}
          onZoomOut={() => dispatch(zoomOut())}
        />
      </ToolbarItem>
      <ToolbarItem paddingRight="base">
        <ZoomButton type="In" onZoom={() => dispatch(zoomIn())} />
      </ToolbarItem>
      <ToolbarItem paddingRight="base">
        <Action
          disabled={is3D}
          onClick={() => dispatch(start2DFit())}
          variant="dark"
          text="Fit"
        >
          <Icon size="14" name="fullscreen" color="#fff" />
        </Action>
      </ToolbarItem>
    </ToolbarGroup>
  )
}

export default ZoomToolbarGroup