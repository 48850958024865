import { graphql } from '~/gql'

const GET_QUOTE_QUERY = graphql(`
  query getQuote($id: ID!) {
    Version(id: $id) {
      id
      quote {
        ...QuoteFragment
      }
    }
  }
`)

export default GET_QUOTE_QUERY
