import React, { useState } from 'react'
import { MetricsTable } from 'components/PerformanceMetrics/subcomponents/MetricsTable'
import PropTypes from 'prop-types'
import { usePerformanceMetricsProps } from 'components/PerformanceMetrics/hooks/usePerformanceMetricsProps'
import { ImperialTemperature } from 'store/units/types/temperature'
import { ImperialVelocity } from 'store/units/types/velocity'
import InfoTooltip from 'components/UIKit/InfoTooltip'
import Heading from 'components/PerformanceMetrics/styled/Heading'
import { useUnitHeatWorker } from 'components/PerformanceMetrics/hooks/useVtkWorker'
import { SpinningIcon } from 'components/PerformanceMetrics/styled/SpinningIcon'
import { PieChart } from 'components/PerformanceMetrics/subcomponents/PieChart'
import Tabs from 'components/UIKit/Tabs/Tabs'
import { Tab } from 'components/UIKit/Tabs/index'
import { formatHeights, formatPercent } from '../util/format'
import { HEIGHTS, HEIGHT_VALUES, STILL_AIR_VELOCITY, getUnitHeatThreshold } from 'config/cfd'
import { camelToTitleCase } from 'utils/string'
import { ThermometerIcon, PredictedMeanVoteIcon, DissatisfiedOccupantsIcon } from '../icons'
import { Stack } from 'components/UIKit/Stack'
import { getSensationColor, getThermalComfort } from 'lib/thermalComfortTool/index'
import Icon from 'components/UIKit/Icon/Icon'
import { useFormatTemperature } from 'components/PerformanceMetrics/hooks/useFormatTemperature'

const useNoHeatersMetrics = () => {
  const { formatTemperature } = useFormatTemperature()
  const { facility } = usePerformanceMetricsProps()
  const { activityLevel, winterClothingType, indoorWinterHumidity, indoorWinterTemp } = facility
  const defaultAirTemp = new ImperialTemperature(indoorWinterTemp)
  const { pmv, ppd, sensation } = getThermalComfort({
    airVelocity: STILL_AIR_VELOCITY,
    humidity: indoorWinterHumidity,
    meanAirTemp: defaultAirTemp,
    clothingType: winterClothingType,
    metabolicRate: activityLevel,
  })
  const formattedTemperature = formatTemperature(defaultAirTemp)
  return {
    heatingCoverage: 0,
    meanAirTemperature: formattedTemperature,
    meanRadiantTemperature: formattedTemperature,
    operativeTemperature: formattedTemperature,
    sensation,
    sensationColor: getSensationColor(sensation),
    pmv: pmv.toFixed(2),
    ppd: ppd.toFixed(2) + '%',
  }
}

const useUnitHeatMetrics = ({ temperatureBeforeUrls, temperatureAfterUrls, velocityAfterUrls }) => {
  const { formatTemperature } = useFormatTemperature()
  const { areaOfConcernEdges, facility } = usePerformanceMetricsProps()
  const { activityLevel, winterClothingType, indoorWinterHumidity, indoorWinterTemp } = facility
  const unitHeatMetrics = useUnitHeatWorker({
    comfortZoneEdges: areaOfConcernEdges,
    coverageThreshold: getUnitHeatThreshold(facility),
    temperatureAfterUrls,
    temperatureBeforeUrls,
    velocityAfterUrls,
  })
  if (unitHeatMetrics.loading) {
    return { loading: true, error: undefined, data: undefined }
  } else if (unitHeatMetrics.error || !unitHeatMetrics.data) {
    return { loading: false, error: unitHeatMetrics.error, data: undefined }
  } else {
    /** @type {import("../workers/unitHeat.worker.js").UnitHeatVTKResults} */
    const {
      averageAirTemperatureAfter,
      averageAirTemperatureBefore,
      averageAirVelocityAfter,
      coverageAfter,
      coverageBefore,
    } = unitHeatMetrics.data
    // Unit Heat does not impact radiant temperature. Fallback to facility default.
    const meanRadiantTemp = new ImperialTemperature(indoorWinterTemp)
    const meanAirTempBefore = new ImperialTemperature(averageAirTemperatureBefore)
    const operativeAirTempBefore = new ImperialTemperature(
      (meanRadiantTemp.value + averageAirTemperatureBefore) / 2
    )
    const withHeatersThermalComfort = getThermalComfort({
      airVelocity: STILL_AIR_VELOCITY,
      humidity: indoorWinterHumidity,
      meanAirTemp: meanAirTempBefore,
      meanRadiantTemp,
      clothingType: winterClothingType,
      metabolicRate: activityLevel,
    })

    const meanAirTempAfter = new ImperialTemperature(averageAirTemperatureAfter)
    const operativeAirTempAfter = new ImperialTemperature(
      (meanRadiantTemp.value + averageAirTemperatureAfter) / 2
    )
    const withHeatersAndFansThermalComfort = getThermalComfort({
      airVelocity: new ImperialVelocity(averageAirVelocityAfter),
      humidity: indoorWinterHumidity,
      meanAirTemp: meanAirTempAfter,
      meanRadiantTemp,
      clothingType: winterClothingType,
      metabolicRate: activityLevel,
    })

    const data = {
      withHeaters: {
        heatingCoverage: coverageBefore,
        meanAirTemperature: formatTemperature(meanAirTempBefore),
        meanRadiantTemperature: formatTemperature(meanRadiantTemp),
        operativeTemperature: formatTemperature(operativeAirTempBefore),
        sensation: withHeatersThermalComfort.sensation,
        sensationColor: getSensationColor(withHeatersThermalComfort.sensation),
        pmv: withHeatersThermalComfort.pmv.toFixed(2),
        ppd: withHeatersThermalComfort.ppd.toFixed(2) + '%',
      },
      withHeatersAndFans: {
        heatingCoverage: coverageAfter,
        meanAirTemperature: formatTemperature(meanAirTempAfter),
        meanRadiantTemperature: formatTemperature(meanRadiantTemp),
        operativeTemperature: formatTemperature(operativeAirTempAfter),
        sensation: withHeatersAndFansThermalComfort.sensation,
        sensationColor: getSensationColor(withHeatersAndFansThermalComfort.sensation),
        pmv: withHeatersAndFansThermalComfort.pmv.toFixed(2),
        ppd: withHeatersAndFansThermalComfort.ppd.toFixed(2) + '%',
      },
    }
    return { loading: false, error: undefined, data }
  }
}

/**
 * @typedef {import('hooks/useFacilityVtkResults').vtkResult} vtkFiles
 * @typedef {object} props
 * @prop {vtkFiles} vtkFiles
 * @param {props} props
 */
const UnitHeatResults = ({ vtkFiles: { vtkUrlGroups } }) => {
  const [selectedHeight, setSelectedHeight] = useState(HEIGHTS.standing)
  const noHeatersMetrics = useNoHeatersMetrics()
  const { data: unitHeatMetrics, error, loading } = useUnitHeatMetrics({
    temperatureBeforeUrls: vtkUrlGroups.temperatureBefore[selectedHeight],
    temperatureAfterUrls: vtkUrlGroups.temperatureAfter[selectedHeight],
    velocityAfterUrls: vtkUrlGroups.velocityAfter[selectedHeight],
  })

  const formattedHeights = formatHeights(HEIGHT_VALUES[selectedHeight])
  const legend = {
    header: (
      <InfoTooltip
        title={`Calculated using evaluation heights: ${formattedHeights}`}
        iconSize="16px"
      />
    ),
    cells: [
      { content: 'Heating Coverage' },
      { icon: <ThermometerIcon />, content: 'Mean Radiant Temp' },
      { icon: <ThermometerIcon />, content: 'Mean Air Temp' },
      { content: 'Thermal Sensation' },
      { content: 'Operative Temperature' },
      {
        icon: <PredictedMeanVoteIcon />,
        content: 'PMV',
      },
      {
        icon: <DissatisfiedOccupantsIcon />,
        content: 'PPD',
      },
    ],
  }

  const noHeaters = {
    header: 'No Heaters',
    cells: [
      {
        icon: <PieChart percentComplete={noHeatersMetrics.heatingCoverage} />,
        content: formatPercent(noHeatersMetrics.heatingCoverage),
      },
      { content: noHeatersMetrics.meanRadiantTemperature },
      { content: noHeatersMetrics.meanAirTemperature },
      {
        content: (
          <Heading color={noHeatersMetrics.sensationColor}>{noHeatersMetrics.sensation}</Heading>
        ),
      },
      { content: noHeatersMetrics.operativeTemperature },
      { content: noHeatersMetrics.pmv },
      { content: noHeatersMetrics.ppd },
    ],
  }

  const withHeaters = {
    header: 'Heaters Only',
    cells: loading
      ? [{ content: <SpinningIcon name="refresh" size="20" /> }]
      : error || !unitHeatMetrics
      ? [
          {
            icon: <Icon name="warn" size="20" />,
            content: error?.message || 'Failed to calculate metrics',
          },
        ]
      : [
          {
            icon: <PieChart percentComplete={unitHeatMetrics.withHeaters.heatingCoverage} />,
            content: formatPercent(unitHeatMetrics.withHeaters.heatingCoverage),
          },
          { content: unitHeatMetrics.withHeaters.meanRadiantTemperature },
          { content: unitHeatMetrics.withHeaters.meanAirTemperature },
          {
            content: (
              <Heading color={unitHeatMetrics.withHeaters.sensationColor}>
                {unitHeatMetrics.withHeaters.sensation}
              </Heading>
            ),
          },
          { content: unitHeatMetrics.withHeaters.operativeTemperature },
          { content: unitHeatMetrics.withHeaters.pmv },
          { content: unitHeatMetrics.withHeaters.ppd },
        ],
  }

  const withFansAndHeaters = {
    header: (
      <Stack>
        Heaters + Fans<Heading>(1 Turnover)</Heading>
      </Stack>
    ),
    cells: loading
      ? [{ content: <SpinningIcon name="refresh" size="20" /> }]
      : error || !unitHeatMetrics
      ? [
          {
            icon: <Icon name="warn" size="20" />,
            content: error?.message || 'Failed to calculate metrics',
          },
        ]
      : [
          {
            icon: <PieChart percentComplete={unitHeatMetrics.withHeatersAndFans.heatingCoverage} />,
            content: formatPercent(unitHeatMetrics.withHeatersAndFans.heatingCoverage),
          },
          { content: unitHeatMetrics.withHeatersAndFans.meanRadiantTemperature },
          { content: unitHeatMetrics.withHeatersAndFans.meanAirTemperature },
          {
            content: (
              <Heading color={unitHeatMetrics.withHeatersAndFans.sensationColor}>
                {unitHeatMetrics.withHeatersAndFans.sensation}
              </Heading>
            ),
          },
          { content: unitHeatMetrics.withHeatersAndFans.operativeTemperature },
          { content: unitHeatMetrics.withHeatersAndFans.pmv },
          { content: unitHeatMetrics.withHeatersAndFans.ppd },
        ],
  }
  return (
    <Tabs size="s" activeIndex={Object.values(HEIGHTS).indexOf(selectedHeight)}>
      {Object.values(HEIGHTS).map((height, i) => (
        <Tab key={i} title={camelToTitleCase(height)} onClick={() => setSelectedHeight(height)}>
          <MetricsTable columns={[legend, noHeaters, withHeaters, withFansAndHeaters]} />
        </Tab>
      ))}
    </Tabs>
  )
}

UnitHeatResults.propTypes = {
  vtkFiles: PropTypes.object,
}

export { UnitHeatResults }
