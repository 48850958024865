import { useMemo } from 'react'
import { useAppSelector } from "~/store/hooks";
import { SYSTEMS } from 'store/units/constants'
import { Temperature } from 'store/units/types'
import { ImperialTemperature } from 'store/units/types/temperature'

const TEMPERATURE_CONVERSION_FACTOR = 1.8

/**
 * Returns functions that formats temperatures and temperature deltas according to the facility's units.
 * @callback formatTemperature
 * @param {(Temperature|number)} imperialTemperature
 * @param {object} [options]
 * @param {number} options.round - The number of decimal places to round to.
 * @returns {string}
 *
 * @callback formatTemperatureDelta
 * @param {Temperature} delta
 * @param {object} [options]
 * @param {number} options.round - The number of decimal places to round to.
 * @returns {string}
 *
 *
 * @typedef {object} temperatureFormatter
 * @prop {formatTemperature} formatTemperature
 * @prop {formatTemperatureDelta} formatTemperatureDelta
 * @returns {temperatureFormatter}
 */
export const useFormatTemperature = () => {
  const { TEMPERATURE } = useAppSelector(({ units }) => units)
  return useMemo(() => {
    const isMetric = TEMPERATURE === SYSTEMS.METRIC
    const formatter = isMetric ? 'formatMetric' : 'formatImperial'
    return {
      formatTemperature: (imperialTemperature, options = { round: 2 }) => {
        return (imperialTemperature instanceof Temperature
          ? imperialTemperature
          : new ImperialTemperature(imperialTemperature))[formatter](options)
      },
      formatTemperatureDelta: (delta, options = { round: 2 }) => {
        if (delta.system !== TEMPERATURE) {
          const { value } = delta
          const convertedValue =
            delta.system === SYSTEMS.METRIC
              ? value * TEMPERATURE_CONVERSION_FACTOR
              : value / TEMPERATURE_CONVERSION_FACTOR
          const convertedDelta = new Temperature({ value: convertedValue, system: TEMPERATURE })
          return convertedDelta[formatter](options)
        }
        return delta[formatter](options)
      },
    }
  }, [TEMPERATURE])
}
