import { useState, useEffect, useMemo } from 'react'
import Tabs, { Tab } from 'components/UIKit/Tabs'
import { useCFDImages } from 'components/Modals/CFDImagesModal/hooks/useCFDImages'
import { Alert } from 'components/PerformanceMetrics/subcomponents/Alert'
import { camelToTitleCase } from 'utils/string'
import Icon from 'components/UIKit/Icon'
import A from 'components/UIKit/A'
import ImageLink from 'components/Panels/CFDPanel/styled/ImageLink'
import Image from 'components/UIKit/Image'
import { CFD_STATUS, SIMULATION_TYPES } from 'config/cfd'
import Controls from 'components/Panels/CFDPanel/styled/Controls'
import { Skeleton } from 'components/Panels/CFDPanel/styled/Skeleton'
import { StatusBadge } from 'components/Panels/CFDPanel/subcomponents/StatusBadge'
import { TabHeader, Heading, HeadingText } from 'components/Panels/CFDPanel/styled/Typography'
import {
  ThumbnailContainer,
  ThumbnailPlaceholder,
} from 'components/Panels/CFDPanel/styled/Thumbnails'
import { useCFDUploadsContext } from '~/hooks/useCFDUploadsContext'
import { useCFDResultChecksumFragment } from '~/hooks/useCFDResultChecksum'
import { useCFDStatusFragment } from '~/hooks/useCFDStatus'

export const CFDImagesPreview = () => {
  const { data, loading, error, refetch } = useCFDImages()
  const { cfdStatus } = useCFDStatusFragment()
  const { checksumResults } = useCFDResultChecksumFragment()
  const { uploads } = useCFDUploadsContext()
  const [selectedIndex, setSelectedIndex] = useState(0)

  const allComplete = useMemo(() => cfdStatus?.isAllComplete, [cfdStatus])

  useEffect(() => {
    if (allComplete) refetch()
  }, [allComplete])

  const handleChange = index => {
    setSelectedIndex(index)
  }

  if (loading) {
    return <Skeleton />
  } else if (error || !data) {
    return (
      <Controls flexGrow={1}>
        <Alert label="Failed to get CFD images" />
      </Controls>
    )
  }

  const currentImages = data[selectedIndex]
  const simType = currentImages?.type
  const previewImages = currentImages?.imageUrls?.slice(0, 3) ?? []
  const isIncomplete = currentImages?.status !== CFD_STATUS.COMPLETE
  const modelChecksum = uploads?.[simType]?.checksum
  const { resultChecksum } = checksumResults?.find(r => !!r && r.internalType === simType) ?? {}
  const isInvalidModel = modelChecksum && resultChecksum && modelChecksum !== resultChecksum
  const isDisabledImages = isIncomplete || isInvalidModel

  return (
    <Controls>
      <Tabs size="s" onChange={handleChange}>
        {data.map((images, i) => {
          const imagesResultChecksum = checksumResults?.find(
            r => !!r && r.internalType === images.type
          )?.resultChecksum
          const imagesModelChecksum = uploads?.[images.type]?.checksum
          const isInvalidSimulation = Boolean(
            imagesModelChecksum &&
              imagesResultChecksum &&
              imagesModelChecksum !== imagesResultChecksum
          )
          return (
            <Tab
              key={i}
              title={
                <TabHeader>
                  <span>{camelToTitleCase(images.type)}</span>
                  <StatusBadge tooltip status={images.status} invalid={isInvalidSimulation} />
                </TabHeader>
              }
            />
          )
        })}
      </Tabs>
      <Heading>
        <div style={{ marginRight: 'auto' }}>
          <Icon name="grid" />
          <HeadingText>Image Gallery</HeadingText>
        </div>
        <A
          style={{ pointerEvents: isDisabledImages ? 'none' : 'initial' }}
          to={({ pathname }) => `${pathname}/cfd-images/${currentImages?.type ?? ''}`}
        >
          View All
        </A>
      </Heading>
      <ThumbnailContainer>
        {previewImages.length === 0
          ? Array(3)
              .fill(null)
              .map((_, i) => <ThumbnailPlaceholder key={i} />)
          : previewImages.map((urls, i) => {
              const src =
                currentImages?.type === SIMULATION_TYPES.unitHeating
                  ? urls?.unitHeatImages?.[0]?.airflowImgs?.[0]?.url
                  : urls?.images[0]?.url
              return (
                <ImageLink
                  key={i}
                  disabled={isDisabledImages}
                  to={({ pathname }) => `${pathname}/cfd-images/${currentImages?.type}`}
                >
                  <Image src={src} alt="cfd image" />
                </ImageLink>
              )
            })}
      </ThumbnailContainer>
    </Controls>
  )
}
