import React from 'react'
import Loader from 'components/UIKit/Loader'
import { LoadingContainer } from '../styled/LoadingContainer'

export const InProgressLoader = () => {
  return (
    <LoadingContainer>
      <Loader label="" />
      <p>Processing CFD data…</p>
      <p>Feel free to close this window, you will be notified when the process is complete.</p>
    </LoadingContainer>
  )
}
