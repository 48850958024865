import styled from 'styled-components'

import { lighten } from 'lib/styleUtils'
import { Theme, Variant } from '~/config/theme';

const setScrollable = (props: { height?: boolean; scrollable?: boolean }) =>
  props.scrollable &&
  `
      max-height: ${props.height ? props.height : '220px'};
      overflow-y: auto;
    `

const setBottomPositionStyles = (props: { position?: string; theme: Theme }) => {
  if (props.position === 'bottom') {
    return `
      background-color: ${lighten(props.theme.colors.light.subdued, 0.2)};
      box-shadow: ${props.theme.shadows.subMenuBottom};
    `
  }
}

const List = styled.div<{ position?: string; variant?: Variant; height?: boolean; scrollable?: boolean; theme: Theme }>`
  margin: 0;
  padding: 0;

  > *:not(:last-child) {
    border-bottom: 1px solid
      ${props =>
        props.variant === 'dark'
          ? props.theme.colors.dark.subdued
          : props.theme.colors.light.subdued};
  }

  ${setScrollable};
  ${setBottomPositionStyles}
`

export default List
