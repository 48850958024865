import styled from 'styled-components'

import { darken, theme } from 'lib/styleUtils'

const Description = styled.div`
  color: ${props => darken(props.theme.colors.light.subdued, 0.4)};
  font-size: ${theme('fonts.sizes.s')};
`

export default Description
