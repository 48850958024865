import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";
import { Router, Route } from 'react-router-dom'
import { CompatRouter, useNavigate, useHref } from "react-router-dom-v5-compat"
import { RouterProvider } from 'react-aria-components'
import { compose } from 'redux'

import { useServiceWorker } from 'serviceWorkerProvider'
import { useNetwork } from 'networkProvider'

import routes from 'config/routes'
import auth0Client from 'lib/Auth'
import { trackPage } from 'lib/analytics'

import withAppSettings from 'client/decorators/withAppSettings'
import withUser from 'client/decorators/withUser'
import getInitialData from 'client/decorators/withInitialData'

import Container from './styled/Container'

import MaintenanceScreen from 'screens/MaintenanceScreen'
import Routes from 'components/Routes'

import Alert from 'components/UIKit/Alert'
import Loader from 'components/UIKit/Loader'
import Modal from 'components/UIKit/Modal'
import Button from 'components/UIKit/Button'
import ProgressBar from 'components/UIKit/ProgressBar'
import FlashBanner from 'components/UIKit/FlashBanner'

import ErrorBoundary from 'components/ErrorBoundary'

import { withProfiler } from '@sentry/react'

/**
 * Assign a redirect URL for navigation after user logs in
 */
function setRedirectUrl() {
  const currentUrl = window.location.pathname

  // So long as they're not on the root page `/` or the `/facilities` page,
  // we want to set the `redirectUrl` so that the `AuthScreen` can pick it
  // up and send them back to their originally requested URL. We also want
  // to ignore the current URL if it's `/silent_auth.html` since that is
  // what Auth0 uses to renew the authentication.
  if (
    currentUrl !== routes.auth &&
    currentUrl !== routes.authCallback &&
    currentUrl !== routes.facilities &&
    currentUrl !== '/silent_auth.html'
  ) {
    window.localStorage.setItem('redirectUrl', currentUrl)
  } else if (currentUrl === routes.facilities) {
    if (window.localStorage.getItem('redirectUrl')) {
      window.localStorage.removeItem('redirectUrl')
    }
  }
}

function App({
  alert,
  appSettings,
  auth,
  history,
  loading: appSettingsLoading,
  user,
}) {
  const [loaded, setLoaded] = useState(false)
  const [versionAlert, setVersionAlert] = useState()
  const [loadingLabel, setLoadingLabel] = useState('')
  const [progress, setProgress] = useState(0)

  const serviceWorker = useServiceWorker()
  const network = useNetwork()

  useEffect(() => {
    trackPage(window.location.pathname)
  }, [])

  useEffect(() => {
    if (serviceWorker.assetsUpdateReady) {
      setVersionAlert(
        <Alert
          text="New app version is available!"
          type="warning"
          href="#"
          action={{
            onClick: (event, hideAlert) => {
              event.preventDefault()
              serviceWorker.updateAssets()
              hideAlert()
            },
            text: 'Update',
          }}
          closeable
          floating
        />
      )
    }
  }, [serviceWorker])

  useEffect(() => {
    async function getData() {
      await getInitialData(setLoadingLabel, setProgress)
      setLoaded(true)
    }

    // Grab data only once they're logged in
    if (auth0Client.isAuthenticated && !loaded) getData()
  }, [loaded, auth])

  if (!auth0Client.isAuthenticated) {
    setRedirectUrl()
    return <ReactDOMRouter history={history}><AppRouter user={{}}/></ReactDOMRouter>
  }

  const dataIsLoading = appSettingsLoading || !loaded
  if (dataIsLoading) {
    return (
      <>
        <Loader centered sublabel={loadingLabel}>
          <ProgressBar bgcolor="#f7c127" completed={progress} />
        </Loader>
      </>
    )
  }

  if (appSettings.isUnderMaintenance) {
    return <MaintenanceScreen />
  }

  return (
    <Container>
      {!network.status && (
        <FlashBanner
          type="error"
          text="No internet connection detected. Some functionality may not work."
        />
      )}
      {!network.apiStatus && (
        <FlashBanner
          type="warning"
          text="The server appears to be down. Some functionality may not work."
        />
      )}
      <ReactDOMRouter history={history}><AppRouter user={user}/></ReactDOMRouter>
      {versionAlert}
      {alert.text && alert.text.length > 0 && (
        <Alert
          text={alert.text}
          type={alert.type}
          action={alert.action}
          closeable
          floating
        />
      )}
    </Container>
  )
}

function ReactDOMRouter(props) {
  return (
    <Router history={props.history}>
      <CompatRouter>
        {props.children}
      </CompatRouter>
    </Router>
  )
}

/**
 * Route the application to the proper page based on defined Routes
 * @param {Object} props contains router location history object
 * @returns SpecLab primary UI router
 */
function AppRouter(props = {}) {
  const network = useNetwork()
  const navigate = useNavigate()

  return (
    <RouterProvider navigate={navigate} useHref={useHref}>
      <ErrorBoundary user={props.user}>
        <Route component={Routes} />
      </ErrorBoundary>
      {network.showReloadNotification && (
        <Modal title="Application Updated" uncloseable>
          <p style={{textAlign: 'center', fontSize: '1.2rem'}}>
            <b>A new version of SpecLab is available!</b><br/>
            Reload in order to continue working. <br/>
          </p>
          <br/>
          <div style={{textAlign: 'center'}}>
            <Button onClick={() => window.location.reload()} label="Reload" primary/>
          </div>
        </Modal>
      )}
    </RouterProvider>
  )
}

AppRouter.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
}

App.propTypes = {
  alert: PropTypes.shape({
    action: PropTypes.any,
    text: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  appSettings: PropTypes.shape({
    isUnderMaintenance: PropTypes.bool,
    uiVersion: PropTypes.string,
  }).isRequired,
  auth: PropTypes.object,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  loadingStorage: PropTypes.bool,
  user: PropTypes.object,
}

const mapStateToProps = ({ auth, alert }) => ({
  alert,
  auth,
})

export default withProfiler(compose(withAppSettings, withUser, appConnect(mapStateToProps))(App))
