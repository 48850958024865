import React from 'react'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'
import { GET_VERSION_CFD_RESULTS } from 'client/queries'
import Button from 'components/UIKit/Button'

const CSVDownloadButton = ({ goal }) => {
  const { versionId } = useRouteMatch(routes.facility.full)?.params
  const { data, loading, error } = useQuery(GET_VERSION_CFD_RESULTS, {
    variables: { versionId },
    skip: !versionId,
  })
  const csvData = data?.Version?.cfd?.reduce((prev, curr) => {
    if (curr?.URLs) {
      curr.URLs.forEach(({ url }) => url.includes('.csv') && prev.set(curr.internalType, url))
    }
    return prev
  }, new Map())
  const selectedCSVDataUrl = csvData?.get(goal)

  const downloadCSV = () => {
    if (!selectedCSVDataUrl) return
    const link = document.createElement('a')
    link.setAttribute('href', selectedCSVDataUrl)
    link.setAttribute('target', '_blank')
    link.click()
  }

  const label = loading ? 'Loading CSV...' : error ? 'CSV Unavailable' : 'Get CSV'
  const isDisabled = Boolean(loading || error)

  return selectedCSVDataUrl ? (
    <Button disabled={isDisabled} label={label} onClick={downloadCSV} />
  ) : (
    <></>
  )
}

CSVDownloadButton.propTypes = {
  goal: PropTypes.string,
}

export { CSVDownloadButton }
