import { useQuery } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'
import routes from 'config/routes'
import { graphql } from '~/gql'

const GET_FACILITY_AUTHOR = graphql(`
  query GetFacilityAuthor($facilityId: ID!) {
    Facility(id: $facilityId) {
      id
      author {
        id
      }
    }
  }
`)

export const useFacilityAuthor = () => {
  const { id: facilityId } = useRouteMatch(routes.facility.full)?.params
  const { data, loading, error } = useQuery(GET_FACILITY_AUTHOR, {
    variables: { facilityId },
    skip: !facilityId,
  })
  return { author: data?.Facility?.author, loading, error }
}
