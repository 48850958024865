import { graphql } from '~/gql'

const CREATE_FACILITY_TEMPLATE_MUTATION = graphql(`
  mutation CreateFacilityTemplate(
    $facilityId: ID!
    $name: String!
    $description: String
    $imageUrl: String
  ) {
    createFacilityTemplate(
      facilityId: $facilityId
      name: $name
      description: $description
      imageUrl: $imageUrl
    ) {
      ...FacilityTemplateFragment
    }
  }
`)

export default CREATE_FACILITY_TEMPLATE_MUTATION
