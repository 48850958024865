import styled from 'styled-components'
import { breakpoints } from 'lib/styleUtils'

const LogoContainer = styled.div`
  display: none;

  ${breakpoints.m`
    display: block;
  `};
`

export default LogoContainer
