import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const offset = '-8px'
const size = '16px'

const Close = styled.div`
  background-color: ${theme('colors.light.subdued')};
  border-radius: 100%;
  color: ${theme('colors.light.fg')};
  cursor: pointer;
  display: block;
  height: ${size};
  line-height: 13px;
  position: absolute;
  right: ${offset};
  text-align: center;
  text-decoration: none;
  top: ${offset};
  width: ${size};
`

export default Close
