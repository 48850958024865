import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Title = styled.div`
  flex: 1;
  font-size: ${theme('fonts.sizes.s')};
  font-weight: ${theme('fonts.weights.semiBold')};
  margin-left: ${props => props.marginLeft};
`

export default Title
