import { forwardRef, useImperativeHandle, useState } from "react"
import { ToolManagerRef } from "../ToolManager"
import { useProductSnapPoints, useProject, useWallSegmentEdgeSnapLines } from "../hooks"
import { Vector3 } from "three"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useModifiers } from "../hooks/modifiers"
import { snapTo, snapToCardinals } from "../util/snaplines"
import { addDimension } from "~/store/objects"
import { guid } from "~/lib/utils"
import LayerKeys from "~/config/layerKeys"
import { objectUIToModel } from "../util/units"
import { setActiveTool } from "~/store/tools"
import { Circle, useCursor } from "@react-three/drei"
import { Arrow } from "./Arrow"

export const DimensionLabelTool = forwardRef<ToolManagerRef, { activeToolProps: unknown }>(function DimensionLabelTool(props, ref) {
  const project = useProject()
  const [[x, y], setPoint] = useState([0, 0])
  type State =
    | { status: 'nil' }
    | { status: 'placedOne'; point: Vector3 }
  const [state, setState] = useState<State>({ status: 'nil' })

  const snaplines = useWallSegmentEdgeSnapLines()
  const snappoints = useProductSnapPoints()
  const dispatch = useAppDispatch()
  useCursor(true, 'crosshair', 'auto')

  const shiftDown = useModifiers(modifiers => modifiers.shift)

  const currentPointRaw = () => new Vector3(x, y, 0)
  const currentPoint = () => {
    const result = (() => {
      if (shiftDown && state.status === 'placedOne') {
        return snapToCardinals(state.point, currentPointRaw())
      } else {
        return currentPointRaw()
      }
    })()
    const snapPoint = snapTo(snaplines, snappoints, result)
    if (snapPoint && snapPoint.distance <= 100) {
      return [snapPoint, snapPoint.snapPoint] as const
    }
    return [undefined, result] as const
  }
  const [snapPoint, current] = currentPoint()

  const continuousMode = useAppSelector(it => it.tools.isContinuousModeEnabled)

  useImperativeHandle(ref, () => ({
    onMouseMove(event) {
      const point = project(event)
      if (point) {
        setPoint([point.x, point.y])
      }
      return true
    },
    onMouseDown(_event) {
      return true
    },
    onMouseUp(_event) {
      if (state.status === 'nil') {
        setState({ status: 'placedOne', point: current })
      } else if (state.status === 'placedOne') {
        dispatch(addDimension({
          dimension: {
            id: guid(),
            layerKey: LayerKeys.DIMENSIONS,
            startPos: objectUIToModel(state.point),
            endPos: objectUIToModel(current),
            labelPos: objectUIToModel(new Vector3().addVectors(state.point, current).divideScalar(2)),
            labelOffset: 0.5,
            anchorStartPos: state.point,
            anchorEndPos: current,
            snapToMousePosition: true,
            dimensionFinished: true,
          }
        }))
        if (continuousMode) {
          dispatch(setActiveTool({
            tool: 'SELECT_TOOL',
            props: {},
          }))
        } else {
          setState({ status: 'nil' })
        }
      }
    },
  }), [project, current, state, snapPoint])

  return (
    <>
      {snapPoint && <Circle position={snapPoint.snapPoint} args={[2, 20]} material-color="cyan" />}
      {state.status === 'nil' && <Circle position={current} args={[5 / 4, 20]} material-color="black" />}
      {state.status === 'placedOne' && <Arrow points={[state.point, current]} />}
    </>
  )
})
