import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Icon from '../../Icon'

const StyledIcon = styled(Icon)`
  bottom: ${theme('spacing.xs')};
  position: absolute;
  right: ${theme('spacing.xs')};
`

export default StyledIcon
