import React from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";

import { isEnv } from 'lib/utils'
import { isTouchUI } from 'store/userInterface/selectors'
import Container from './styled/Container'

function SubmitFeedbackButton(props = {}) {
  // We only want to show this button when Usersnap is available
  // *AND* we're in production.
  const shouldShowButton = window.Usersnap && isEnv('production')
  if (!shouldShowButton) return null
  return (
    <Container
      isTouchUI={props.isTouchUI}
      onClick={event => {
        if (window.Usersnap) {
          window.Usersnap.open()
        }
      }}
    >
      Feedback
    </Container>
  )
}

SubmitFeedbackButton.propTypes = {
  isTouchUI: PropTypes.bool,
}

const mapStateToProps = store => ({ isTouchUI: isTouchUI(store) })

export default appConnect(mapStateToProps)(SubmitFeedbackButton)
