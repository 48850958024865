import React from 'react'
import PropTypes from 'prop-types'
import { SpinningIcon } from '../styled/SpinningIcon'
import { Stack } from 'components/UIKit/Stack'

const Loader = ({ label, ...stackProps }) => {
  return (
    <Stack direction="row" spacing={1} padding={1} {...stackProps}>
      <SpinningIcon name="refresh" size="20" />
      {label ?? 'Loading...'}
    </Stack>
  )
}

Loader.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

export { Loader }
