import styled from 'styled-components'

import Action from 'components/UIKit/Action'

const StyledAction = styled(Action)`
  &:active,
  &:focus {
    box-shadow: none;
  }
`

export default StyledAction
