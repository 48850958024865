import React, { useState } from 'react'

import { Query } from '@apollo/client/react/components'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'

import get from 'lodash-es/get'
import throttle from 'lodash-es/throttle'
import {
  BAF_USAGE_REPORT_QUERY,
  DISTRIBUTOR_USAGE_REPORT_QUERY,
  ALL_USAGE_REPORT_QUERY,
} from 'client/queries'

import Flex, { FlexItem } from 'components/UIKit/Flex'
import H1 from 'components/UIKit/H1'
import Icon from 'components/UIKit/Icon'
import Loader from 'components/UIKit/Loader'
import Select from 'components/UIKit/Select'
import Space from 'components/UIKit/Space'
import Button from 'components/UIKit/Button'
import Table, { Row, Cell, Header } from 'components/UIKit/Table'

import InlineSearch from '../InlineSearch'

import PermissionDeniedRoute from '../PermissionDeniedRoute'
import { hasPermission } from '../RequiredPermission'

const Reports = () => {
  const [sort, setSort] = useState('email')
  const [sortDirection, setSortDirection] = useState(1) // Ascending = 1; Decending = -1;
  const [reportQuery, setReportQuery] = useState('allUsageReport')
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  Table.displayName = 'Table'
  Row.displayName = 'Row'
  Cell.displayName = 'Cell'

  const sortTable = value => {
    if (sort === value) {
      setSortDirection(sortDirection === 1 ? -1 : 1)
    } else {
      setSort(value)
    }
  }

  const variables = {
    filter: null,
  }

  if (searchQuery !== '') {
    variables.filter = { ...variables.filter, name_contains: searchQuery }
  }

  let query = DISTRIBUTOR_USAGE_REPORT_QUERY

  switch (reportQuery) {
    case 'allUsageReport':
      query = ALL_USAGE_REPORT_QUERY
      break
    case 'BAFUsageReport':
      query = BAF_USAGE_REPORT_QUERY
      break
    case 'distributorUsageReport':
      query = DISTRIBUTOR_USAGE_REPORT_QUERY
      break
    default:
      query = ALL_USAGE_REPORT_QUERY
      break
  }
  function generateCSV(data) {
    // eslint-disable-next-line no-useless-concat
    let headers = 'Email,Company,YearToDate,Last30Days' + '\n'
    let csvContent = 'data:text/csv;charset=utf-8,' + headers
    data.forEach(record => {
      csvContent +=
        record.email +
        ',' +
        record.company +
        ',' +
        record.yearToDate +
        ',' +
        record.last30Days +
        '\n'
    })

    let encodedData = encodeURI(csvContent)
    let filename = 'UsageReport.csv'
    let link = document.createElement('a')
    link.setAttribute('href', encodedData)
    link.setAttribute('download', filename)
    link.click()
  }

  return (
    <Query
      query={query}
      variables={variables}
      notifyOnNetworkStatusChange
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        const reportData = (data?.[reportQuery]?.reports ?? []).toSorted((a, b) => a[sort] > b[sort] ? sortDirection : -sortDirection)

        return (
          <>
            <Space bottom="base">
              <Flex alignItems="center" positionRelative>
                <Flex flexGrow="1">
                  <FlexItem>
                    <InlineSearch
                      query={searchQuery}
                      onChange={throttle(
                        event => setSearchQuery(event.target.value),
                        500
                      )}
                      onOpen={() => setIsSearching(true)}
                      onClose={() => {
                        setIsSearching(false)
                        setSearchQuery('')
                      }}
                    />
                  </FlexItem>
                  {!isSearching && (
                    <H1 flush>
                      <Icon
                        name={'clipboard'}
                        color="subdued"
                        marginRight="s"
                      />
                      <b>Usage Reports</b>
                    </H1>
                  )}
                </Flex>
                <Flex spacingRight="base" marginLeft="auto" alignItems="center">
                  <FlexItem>
                    <Button
                      primary
                      label="Download"
                      onClick={() => generateCSV(reportData)}
                    />
                  </FlexItem>
                  <FlexItem>
                    <Select
                      noBottom
                      inline
                      name="filter"
                      label="Filter:"
                      labelWidth="50px"
                      onChange={event => setReportQuery(event.target.value)}
                      value={reportQuery}
                      disablePlaceholder={false}
                      options={[
                        { label: 'All Organizations', value: 'allUsageReport' },
                        {
                          label: 'BAF Internal Usage',
                          value: 'BAFUsageReport',
                        },
                        {
                          label: 'Distributor Usage',
                          value: 'distributorUsageReport',
                        },
                      ]}
                    />
                  </FlexItem>
                </Flex>
              </Flex>
            </Space>

            {loading ? (
              <Loader />
            ) : reportData.length ? (
              <>
                <Table>
                  <Row>
                    <Header
                      direction={sortDirection}
                      active={sort === 'email'}
                      onClick={() => sortTable('email')}
                    >
                      Email
                    </Header>
                    <Header
                      direction={sortDirection}
                      active={sort === 'company'}
                      onClick={() => sortTable('company')}
                    >
                      Company
                    </Header>
                    <Header
                      direction={sortDirection}
                      active={sort === 'yearToDate'}
                      onClick={() => sortTable('yearToDate')}
                    >
                      Year To Date
                    </Header>
                    <Header
                      direction={sortDirection}
                      active={sort === 'last30Days'}
                      onClick={() => sortTable('last30Days')}
                    >
                      Last 30 Days
                    </Header>
                  </Row>
                  {reportData.map((i, index) => (
                    // put a table here!
                    <Row key={index}>
                      <Cell bordered>{i.email}</Cell>
                      <Cell bordered>{i.company}</Cell>
                      <Cell bordered>{i.yearToDate}</Cell>
                      <Cell bordered>{i.last30Days}</Cell>
                    </Row>
                  ))}
                </Table>
              </>
            ) : (
              <p data-testid="reports-empty">
                {searchQuery === ''
                  ? 'There are no reports currently.'
                  : 'No items match that query.'}
              </p>
            )}
          </>
        )
      }}
    </Query>
  )
}

export default compose(
  withRouter,
  hasPermission({
    name: 'Reports',
    // eslint-disable-next-line react/display-name
    FallbackComponent: () => <PermissionDeniedRoute flush />,
  })
)(Reports)
