import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Text = styled.p`
  flex-grow: 1;
  margin: 0;
  padding: ${theme('spacing.base')};
`

export default Text
