import React from 'react'
import styled, { StyledConfig, ThemedStyledProps, css } from 'styled-components'
import { Link } from 'react-router-dom'

import { theme } from 'lib/styleUtils'
import { Theme, Variant } from '~/config/theme'

const isDarkVariant =  (props: { variant?: Variant; 'data-variant'?: Variant })  =>
  props.variant === 'dark' || props['data-variant'] === 'dark'

const setColor = (props: ThemedStyledProps<{ variant?: Variant; 'data-variant'?: Variant; primaryAction?: boolean }, Theme>) => {
  if (props.primaryAction) {
    return props.theme.colors.light.fg
  }

  if (isDarkVariant(props)) {
    return props.theme.colors.dark.fg
  }

  return props.theme.colors.light.fg
}

const isDisabled = (props: { disabled?: boolean }) => {
  if (props.disabled) {
    return `
      opacity: 0.5;
      pointer-events: none;
    `
  }
}

const styles = css<{ variant?: Variant; 'data-variant'?: Variant; primaryAction?: boolean; disabled?: boolean }>`
  color: ${setColor};
  display: block;
  flex: 1;
  cursor: pointer;
  padding: ${theme('spacing.s')} ${theme('spacing.base')};
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  ${isDisabled}
`

const ButtonContainer = styled.button<{ variant?: Variant; 'data-variant'?: Variant; primaryAction?: boolean; disabled?: boolean }>`
  background: 0;
  border: 0;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }

  ${styles};
`

const config: StyledConfig<any> = { shouldForwardProp: prop => prop !== 'primaryAction'}

const AnchorContainer = (styled.a<{ variant?: Variant; 'data-variant'?: Variant; primaryAction?: boolean; disabled?: boolean }>).withConfig(config)`
  ${styles};
`

const LinkContainer = (styled(Link)<{ variant?: Variant; 'data-variant'?: Variant; primaryAction?: boolean; disabled?: boolean }>).withConfig(config)`
  ${styles};
`

export { ButtonContainer, AnchorContainer, LinkContainer }
