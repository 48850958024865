import styled from 'styled-components'

import { truncate, theme } from 'lib/styleUtils'

const Title = styled.h3`
  font-size: ${theme('fonts.sizes.xs')};
  text-align: center;

  ${props => truncate(`${props.width}px`)};
`

export default Title
