import isEqual from 'lodash-es/isEqual'
import get from 'lodash-es/get'

import Util from './util'
import Units from './units'
import Primitives from './primitives'
import Facility from './facility'
import FloatingElementManager from './floatingElementManager'
import ObstructionUtil from './obstructionUtil'
import ArrowRenderer from './arrowRenderer'
import * as ObstructionDistanceEngine from './obstructionDistance'
import * as MeshLoader from './meshLoader'

import store from 'store'
import { getDistanceUnits } from 'store/units/selectors'
import { Distance } from 'store/units/types'
import { updateObstruction } from 'store/objects'
import { isContinuousModeEnabled } from 'store/tools/selectors'
import { setStatus } from 'store/status'
import {
  objectIsSelected,
  getSelectedObjects,
} from 'store/selectedObjects/selectors'

import OBJECT_TYPES from 'config/objectTypes'
import LAYER_KEYS from 'config/layerKeys'
import theme from 'config/theme'
import CLASS_NAMES from 'config/objectClassNames'
import CLICK_PRIORITY from 'config/clickPriority'
import { getThreeHexFromTheme } from 'lib/utils'

import * as THREE from 'three'

class Obstruction {
  constructor(model) {
    this.layerKey = LAYER_KEYS.OBSTRUCTIONS
    this.className = CLASS_NAMES.OBSTRUCTION
    this.units = 'INCHES'

    this.id = model.id ? model.id : Util.guid()
    this.clickPriority = CLICK_PRIORITY[this.layerKey]
    this.centerLines = model.centerLines || []
    this.obstructionType = model.obstructionType || 'basic'
    this.startLocation = model.startLocation || 'floor'
    this.rotation = model.rotation || { x: 0, y: 0, z: 0 }
    this.offset = model.offset
    this.detailedModelScale = model.scale || { x: 1, y: 1, z: 1 }
    this.detailedRatio = model.length && { x: model.width, y: model.length }
    this.lastDescriptionPos = new THREE.Vector3()
    this.position = new THREE.Vector3().copy({
      x: Units.inchesToNative(model.position.x),
      y: Units.inchesToNative(model.position.y),
      z: Units.inchesToNative(model.position.z),
    })
    this.lastValidPos = new THREE.Vector3().copy(this.position)

    const defaultColor = theme.colors.three.objects.obstruction.deselected
    this.color = model.color || defaultColor

    // Set obstruction height in inches, default is 48 inches
    this.height = model.height || 48
    this.positions = model.positions.map(position => ({
      x: Units.inchesToNative(position.x),
      y: Units.inchesToNative(position.y),
      z: Units.inchesToNative(position.z),
    }))
    this.lastValidPositions = this.positions

    this.resizable = this.isResizable(model)

    this.obj3d = new THREE.Object3D()

    this.constructObstruction(model, Facility.current)
    this.reinitializeKeypressHandler = true
    this.ignoreErrors = model.ignoreErrors || false

    if (isContinuousModeEnabled()) ArrowRenderer.unsubscribe(this)
    ArrowRenderer.subscribe(this)
  }

  constructObstruction = async (model, facility) => {
    try {
      const nativeHeight = Units.inchesToNative(this.height)
      const isSelected = objectIsSelected(this.id)
      const isBasicType = this.obstructionType === 'basic'
      const is3dViewMode = store.getState().camera.is3D
      const isRectangle = ObstructionUtil.isRectangle(this.positions)
      const isValidResizeState = isSelected && !is3dViewMode && isRectangle
      const isMultiSelected = getSelectedObjects().length > 1

      // Create the obstruction object
      this.createCustomObstruction(this.positions, nativeHeight)

      this.updateSnapBox()

      // Create the rotation drag handle
      if (!is3dViewMode && !isMultiSelected) this.buildDragHandle()

      // Create the resize handles
      const isResizable = isBasicType || this.resizable
      if (isResizable && isValidResizeState && !isMultiSelected) {
        this.resizeHandles = ObstructionUtil.buildResizeHandles(
          this.snapBox,
          this.id
        )
        this.obj3d.add(this.resizeHandles)
      } else if (
        isSelected &&
        !is3dViewMode &&
        !isMultiSelected &&
        this.positions.length < 27
      ) {
        this.customResizeHandles = ObstructionUtil.buildCustomResizeHandles(
          this.positions,
          this.id
        )
        this.obj3d.add(this.customResizeHandles)
      }

      // Set obstruction anchor location (ceiling or floor)
      this.updateHeight(Facility.current)

      this.obj3d.rotation.set(0, 0, (this.rotation.z * Math.PI) / 180)

      // Set the position on the obstruction
      this.obj3d.position.copy(
        new THREE.Vector3(this.position.x, this.position.y, this.startPosition)
      )

      // Used by Interactifier
      this.obj3d.wrapperId = this.id
      this.selectable = true
      this.draggable = false
      this.multiDraggable = true

      if (isSelected) {
        this.select()
      } else {
        this.deselect()
      }

      this.updateSnapBox()

      // Build detailed obstruction model and add it to our object
      if (this.obstructionType !== 'basic') {
        this.modelContainer = new THREE.Object3D()
        this.construction = this.buildMesh({
          root: this.modelContainer,
          model: this.obstructionType,
        })
        this.modelContainer.rotation.set((90 * Math.PI) / 180, 0, 0)
        this.modelContainer.scale.copy(this.detailedModelScale)

        this.root = new THREE.Object3D()
        this.root.add(this.modelContainer)
        this.obj3d.add(this.root)
      }
    } catch (err) {
      throw err
    }
  }

  static createModel(positions) {
    const position = Util.polygonCentroid(positions)
    const height = 48

    return {
      positions: positions.map(p => ({
        x: Units.nativeToInches(p.x),
        y: Units.nativeToInches(p.y),
        z: Units.nativeToInches(p.z),
      })),
      height,
      position: {
        x: Units.nativeToInches(position.x),
        y: Units.nativeToInches(position.y),
        z: Units.nativeToInches(position.z),
      },
    }
  }

  toModel = () => {
    // Use obj3d's current position or get a new one if not found
    const position =
      get(this, 'obj3d.position') || Util.polygonCentroid(this.positions)
    const model = {
      id: this.id,
      className: this.className,
      layerKey: this.layerKey,
      position: {
        x: Units.nativeToInches(position.x),
        y: Units.nativeToInches(position.y),
        z: Units.nativeToInches(position.z),
      },
      positions: this.positions.map(position => ({
        x: Units.nativeToInches(position.x),
        y: Units.nativeToInches(position.y),
        z: Units.nativeToInches(position.z),
      })),
      rotation: {
        x: this.rotation.x,
        y: this.rotation.y,
        z: this.rotation.z,
      },
      height: this.height,
      offset: this.offset,
      draggable: this.draggable,
      resizable: this.resizable,
      startLocation: this.startLocation,
      obstructionType: this.obstructionType,
      color: this.color,
      ignoreErrors: this.ignoreErrors,
    }

    return model
  }

  sceneDidRebuild(facility, thisChanged) {
    // Update height after scene rebuilds since height is
    // impacted by other objects in the scene: e.g. wall height,
    // mounting structure depth.
    if (this.startLocation === 'ceiling') {
      this.updateHeight(facility)
    }

    if (ObstructionDistanceEngine && !this.ignoreErrors) {
      this.hasDangerousCollisions = !ObstructionDistanceEngine.wallDistanceCheck(
        this
      )
    }
  }

  buildMesh = async (props, __didCatchError) => {
    try {
      await MeshLoader.buildObstructionMesh({
        ...props,
        type: 'obstruction',
      })

      // Get a box of the unrotated detailed obstruction model for sizing
      const rootClone = this.root.clone()
      rootClone.rotation.z = 0
      this.rootBox = new THREE.Box3().setFromObject(rootClone)
      if (this.resizable) this.scaleDetailedObstructionToSelectionBox()
    } catch (err) {
      // NOTE: Make sure we break the potential for an infinite loop
      // if neither the fallback nor the original models were found.
      if (__didCatchError) throw err
      const model = 'Car'

      return this.buildMesh(
        {
          ...props,
          model,
          type: 'obstruction',
        },
        true
      )
    }
  }

  updateHeight(facility) {
    let height
    const offset = Units.inchesToNative(this.offset) || 0

    if (this.startLocation === 'ceiling') {
      const distanceFromFloor = ObstructionUtil.getDistanceFromFloor(
        this.obj3d,
        this.height,
        facility
      )
      height =
        distanceFromFloor === Units.inchesToNative(this.height) - 0.01
          ? this.obj3d.position.z
          : distanceFromFloor - offset
    } else {
      height = offset
    }

    this.startPosition = height
    this.obj3d.position.z = height
  }

  scaleDetailedObstructionToSelectionBox = () => {
    if (this.obstructionType === 'basic' || !this.rootBox) return

    // Resize detailed obstruciton to the size of the selection box
    const xOffset = Math.abs(this.rootBox.max.x - this.rootBox.min.x)
    const yOffset = Math.abs(this.rootBox.max.y - this.rootBox.min.y)
    const zOffset = Math.abs(this.rootBox.max.z - this.rootBox.min.z)
    const xInches = Units.nativeToInches(xOffset)
    const yInches = Units.nativeToInches(yOffset)
    const zInches = Units.nativeToInches(zOffset)
    const dimensions = Util.getObjectDimensionInInches(this.selectionBox)
    const x = dimensions.width / xInches || 1
    const y = dimensions.length / yInches || 1
    const z = dimensions.height / zInches

    this.root.scale.set(x, y, z)
  }

  createCustomObstruction(positions, height) {
    if (this.selectionBox) this.obj3d.remove(this.selectionBox)
    if (this.resizeHandles) this.obj3d.remove(this.resizeHandles)

    this.selectionBox = Primitives.getCustomMesh(positions, height)
    if (get(this.selectionBox, 'material.color')) {
      this.selectionBox.material.color.set(this.color)
    }
    this.selectionBox.material.transparent = true
    this.selectionBox.userData.objectType = OBJECT_TYPES.OBSTRUCTION
    this.obj3d.userData.objectType = OBJECT_TYPES.OBSTRUCTION
    this.selectionBox.wrapperId = this.id

    const position = Util.polygonCentroid(positions)
    this.selectionBox.geometry.translate(-position.x, -position.y, -position.z)

    this.obj3d.add(this.selectionBox)
  }

  buildDragHandle() {
    if (!this.snapBox) return
    if (this.dragHandle) this.obj3d.remove(this.dragHandle)
    const box = this.snapBox
    const distanceToEdge = box.max.x + 5
    this.dragHandle = Primitives.getDragHandle(distanceToEdge)
    this.dragHandle.userData.objectType = OBJECT_TYPES.DRAG_HANDLE
    this.dragHandle.wrapperId = this.id
    this.obj3d.add(this.dragHandle)
  }

  select(draggable = true) {
    const isLocked = store.getState().layers.layers[LAYER_KEYS.OBSTRUCTIONS]
      .locked
    this.draggable = !isLocked
    if (draggable === false) {
      this.draggable = false
    }
    if (this.selectionBox) {
      if (this.obstructionType !== 'basic') {
        if (get(this.selectionBox, 'material.color')) {
          this.selectionBox.material.color.setHex(
            getThreeHexFromTheme('three.objects.obstruction.selected')
          )
        }
        this.selectionBox.material.opacity = 0.3
        this.selectionBox.material.visible = true
      } else {
        this.selectionBox.material.opacity = 0.75
      }
    }
  }

  deselect() {
    this.draggable = false

    if (this.selectionBox) {
      if (this.obstructionType !== 'basic') {
        this.selectionBox.material.visible = false
      } else {
        this.selectionBox.material.opacity = 1
      }
    }

    if (this.dragHandle) this.dragHandle.visible = false
  }

  /*
    SceneBuilder event
  */
  visibilityChanged(visible) {
    if (this.obj3d) this.obj3d.visible = visible
  }

  drop(_, __, saveModel = true) {
    const positions = ObstructionUtil.getRotatedPositions(
      this.positions,
      this.obj3d.position.clone(),
      this.rotation.z
    )
    const isInsideFacility = Util.isPositionsOverFacility(positions)

    if (isInsideFacility || this.ignoreErrors) {
      this.resizeHandleUnderMouse = false
      this.isResizing = false
      this.isRotating = false
      this.isDragging = false
      this.rotateTick = 0

      if (saveModel) {
        store.dispatch(updateObstruction({ obstruction: this.toModel() }))
      }
    } else {
      this.obj3d.position.copy(this.lastValidPos)
      this.positions = this.lastValidPositions

      // Force reset the obstruction back to its previous state
      const model = this.toModel()
      model.updateId = Util.guid()
      store.dispatch(updateObstruction({ obstruction: model }))

      // Use timeout so status message isn't prematurely cleared
      setTimeout(() => {
        const error = 'Obstructions must be placed inside the facility!'
        store.dispatch(setStatus({ text: error, type: 'error' }))
      }, 500)
    }
  }

  showLengthLabel() {
    const { xDiff, yDiff } = ObstructionUtil.getPositionDiffs(this.positions)
    const formattedLength = Units.toDistanceString(Math.abs(yDiff))
    this.textLengthLabelAnchor = new THREE.Object3D()
    this.obj3d.add(this.textLengthLabelAnchor)
    this.textLengthLabelAnchor.position.set(xDiff / 2 - 2, 0, 0)
    this.textLengthLabel = FloatingElementManager.showFloatingElement(
      this.id,
      this.textLengthLabelAnchor,
      undefined,
      'label',
      'length'
    )
    this.textLengthLabel.innerHTML = formattedLength

    const formattedWidth = Units.toDistanceString(Math.abs(xDiff))
    this.textWidthLabelAnchor = new THREE.Object3D()
    this.obj3d.add(this.textWidthLabelAnchor)
    this.textWidthLabelAnchor.position.set(0, yDiff / 2 - 1.2, 0)
    this.textWidthLabel = FloatingElementManager.showFloatingElement(
      this.id,
      this.textWidthLabelAnchor,
      undefined,
      'label',
      'width'
    )
    this.textWidthLabel.innerHTML = formattedWidth
  }

  hideLengthLabel() {
    if (this.textWidthLabel !== undefined) {
      this.textWidthLabel = undefined
    }
    FloatingElementManager.hideFloatingElement(this.id, 'width')

    if (this.textLengthLabel !== undefined) {
      this.textLengthLabel = undefined
    }
    FloatingElementManager.hideFloatingElement(this.id, 'length')
  }

  isResizable(model) {
    if (typeof model.resizable !== 'undefined') return model.resizable

    const isRectangle = ObstructionUtil.isRectangle(this.positions)
    const isBasic = this.obstructionType === 'basic'
    if (isRectangle && isBasic) return true

    if (!isBasic && this.obstructionType !== 'Worker') return true

    return false
  }

  getSnappableEdgePoints() {
    if (this.obstructionType !== 'basic') return []

    const outsetPoints = [
      [this.snapBox.min.x, this.snapBox.min.y, 0],
      [this.snapBox.min.x, this.snapBox.max.y, 0],
      [this.snapBox.min.x, this.snapBox.max.y, 0],
      [this.snapBox.max.x, this.snapBox.max.y, 0],
      [this.snapBox.max.x, this.snapBox.max.y, 0],
      [this.snapBox.max.x, this.snapBox.min.y, 0],
      [this.snapBox.max.x, this.snapBox.min.y, 0],
      [this.snapBox.min.x, this.snapBox.min.y, 0],
    ]

    return outsetPoints
  }

  setPosition({ x = 0, y = 0, z = 0 }) {
    this.position = new THREE.Vector3(x, y, z)
  }

  setPositions(newPositions) {
    this.positions = newPositions.map(
      pos => new THREE.Vector3(pos.x, pos.y, pos.z)
    )
  }

  updatePositions({ x: deltaX = 0, y: deltaY = 0, z: deltaZ = 0 }) {
    this.positions = this.positions.map(pos => ({
      x: pos.x + deltaX,
      y: pos.y + deltaY,
      z: pos.z + deltaZ,
    }))
  }

  drag({
    dragDelta,
    newPosition,
    projectedMousePos,
    lastProjectedMousePos,
    isShiftDown,
  }) {
    const isLocked = store.getState().layers.layers[LAYER_KEYS.OBSTRUCTIONS]
      .locked
    if (isLocked) {
      return
    }

    const pos = projectedMousePos || newPosition
    let isOverDragIcon = true
    if (!this.isRotating) {
      isOverDragIcon = Util.isPositionOverObjectTypeWithId(
        pos,
        OBJECT_TYPES.DRAG_HANDLE,
        this.id,
        Facility
      )
    }

    if (!this.isResizing) {
      this.resizeHandleUnderMouse = ObstructionUtil.getResizeHandleOverPosition(
        pos,
        this.id
      )
    }

    const isValidRotateState =
      !this.isDragging &&
      !this.resizeHandleUnderMouse &&
      isOverDragIcon &&
      dragDelta
    const isValidResizeState =
      !this.isDragging &&
      this.resizeHandleUnderMouse &&
      !this.isRotating &&
      dragDelta
    const isValidDragState = !this.isRotating && !this.isResizing

    if (isValidRotateState) {
      this.handleRotationDrag(dragDelta, pos)
    } else if (isValidResizeState) {
      this.handleResizeDrag(
        projectedMousePos,
        lastProjectedMousePos,
        newPosition,
        isShiftDown,
        this.customResizeHandles
      )
    } else if (isValidDragState) {
      this.move(newPosition)
    }

    this.updateSnapBox()
    // this.reinitializeKeypressHandler = true;
  }

  move(newPosition) {
    // If we just started dragging force reset the arrow renderer
    if (!this.isDragging && ArrowRenderer) {
      ArrowRenderer.unsubscribe(this)
      ArrowRenderer.subscribe(this)
    }

    this.isDragging = true

    this.updatePositions({
      x: newPosition.x - this.obj3d.position.x,
      y: newPosition.y - this.obj3d.position.y,
    })
    this.obj3d.position.copy(newPosition)
  }

  updateSnapBox() {
    const box = this.selectionBox.clone()
    box.position.copy(this.obj3d.position)
    this.snapBox = new THREE.Box3().setFromObject(box)
  }

  snap(snapDelta) {
    if (!this.isResizing) {
      this.obj3d.position.add(snapDelta)
      this.updatePositions(snapDelta)
    }
  }

  handleResizeDrag(
    mousePos,
    lastMousePos,
    newPosition,
    isShiftDown,
    customType = false
  ) {
    this.dragHandle.visible = false
    this.isResizing = true

    // Get the unrotated mouse positions before and after the drag so
    // we can use them to determine the unrotated drag delta
    const unrotatedPoints = ObstructionUtil.getRotatedPositions(
      [lastMousePos, mousePos],
      this.obj3d.position.clone(),
      -this.rotation.z
    )
    const mouseXChange = unrotatedPoints[1].x - unrotatedPoints[0].x
    const mouseYChange = unrotatedPoints[1].y - unrotatedPoints[0].y
    const unrotatedDragDelta = new THREE.Vector3(mouseXChange, mouseYChange, 0)

    if (customType) {
      // find the closest position to mouse position
      const rotatedPositions = ObstructionUtil.getRotatedPositions(
        this.positions,
        this.obj3d.position.clone(),
        this.rotation.z
      )

      const closestPoint = rotatedPositions.reduce((a, b) =>
        Math.pow(mousePos.x - a.x, 2) + Math.pow(mousePos.y - a.y, 2) <
        Math.pow(mousePos.x - b.x, 2) + Math.pow(mousePos.y - b.y, 2)
          ? a
          : b
      )
      const index = rotatedPositions.findIndex(
        pos => pos.x === closestPoint.x && pos.y === closestPoint.y
      )
      this.positions = ObstructionUtil.getRotatedPositions(
        this.positions,
        this.obj3d.position.clone(),
        this.rotation.z
      )

      this.positions[index] = {
        x: mousePos.x,
        y: mousePos.y,
        z: 0,
      }

      this.positions = ObstructionUtil.getRotatedPositions(
        this.positions,
        this.obj3d.position.clone(),
        -this.rotation.z
      )
    } else {
      // Get new positions based on the unrotated drag delta
      this.positions = ObstructionUtil.getResizedPositionsFromBox(
        this.snapBox,
        unrotatedDragDelta,
        this.positions,
        this.resizeHandleUnderMouse
      )
    }

    // If holding the shift key down, constrain the proportions of
    // the object so it stays uniform with its original shape
    if (isShiftDown && !this.customResizeHandles) {
      const diff = ObstructionUtil.getPositionDiffs(this.positions)
      const dominateDelta = Util.getDominateDeltaAxisDistance(
        unrotatedDragDelta
      )
      const xIsDominate = dominateDelta === unrotatedDragDelta.x
      const startPos = { x: diff.xMin, y: diff.yMax }
      const endPos = { x: diff.xMax, y: diff.yMin }
      const isBasic = this.obstructionType === 'basic'
      let multiplier = 1

      if (xIsDominate) {
        if (!isBasic) multiplier = this.detailedRatio.y / this.detailedRatio.x
        const yDistance = diff.xDiff * multiplier
        endPos.y = diff.yMax - yDistance
      } else {
        if (!isBasic) multiplier = this.detailedRatio.x / this.detailedRatio.y
        const xDistance = diff.yDiff * multiplier
        endPos.x = diff.xMin + xDistance
      }

      this.positions = Util.getBoxPositions(startPos, endPos)
    }

    const originalPosition = this.obj3d.position.clone()

    // Build the new resized obstruction from the updated positions
    const nativeHeight = Units.inchesToNative(this.height)
    this.createCustomObstruction(this.positions, nativeHeight)

    // Get the new center position for the obstruction
    const newCenterPos = Util.polygonCentroid(this.positions)

    // Apply the obstructions rotation to the new obstruction center
    // position relative to the old center position. This will allow
    // the corners to anchor in place while resizing when rotated
    const rotatedOrigin = ObstructionUtil.getRotatedPositions(
      [newCenterPos],
      originalPosition,
      this.rotation.z
    )
    // this is a current fix for rotated custom obstructions
    if (
      !this.customResizeHandles ||
      (this.customResizeHandles && this.rotation.z === 0)
    )
      this.obj3d.position.copy(rotatedOrigin[0])
    else {
      this.obj3d.position.copy(newCenterPos)
    }

    // Update resize handles
    this.updateSnapBox()

    if (this.resizeHandles) {
      this.obj3d.remove(this.resizeHandles)
      this.resizeHandles = ObstructionUtil.buildResizeHandles(
        this.snapBox,
        this.id
      )
      this.obj3d.add(this.resizeHandles)
    }
    if (this.customResizeHandles) {
      this.obj3d.remove(this.customResizeHandles)
      this.customResizeHandles = ObstructionUtil.buildCustomResizeHandles(
        this.positions,
        this.id
      )
      this.obj3d.add(this.customResizeHandles)
    }

    // Update the size of detailed obstruction models
    this.scaleDetailedObstructionToSelectionBox()

    // Reselect obstruction while resizing so it looks selected still
    this.select()
  }

  handleRotationDrag(dragDelta, projectedMousePos) {
    this.isRotating = true

    if (this.rotateTick < 4) {
      this.rotateTick++
      return
    }

    this.rotateTick = 0
    const offset =
      Math.abs(dragDelta.x) > Math.abs(dragDelta.y) ? dragDelta.x : dragDelta.y

    if (Math.abs(offset) < 0.1) return

    const direction = new THREE.Vector3()
      .subVectors(projectedMousePos, this.obj3d.position)
      .normalize()

    let isPositive = true
    let isOffsetX = offset === dragDelta.x

    if (direction.x < 0 && direction.y < 0) {
      // Bottom Left Quadrant
      if ((offset > 0 && !isOffsetX) || (offset < 0 && isOffsetX)) {
        isPositive = false
      }
    } else if (direction.x > 0 && direction.y < 0) {
      // Bottom Right Quadrant
      if ((offset < 0 && !isOffsetX) || (offset < 0 && isOffsetX)) {
        isPositive = false
      }
    } else if (direction.x > 0 && direction.y > 0) {
      // Top Right Quadrant
      if ((offset < 0 && !isOffsetX) || (offset > 0 && isOffsetX)) {
        isPositive = false
      }
    } else if (direction.x < 0 && direction.y > 0) {
      // Top Left Quadrant
      if ((offset > 0 && !isOffsetX) || (offset > 0 && isOffsetX)) {
        isPositive = false
      }
    }

    const degree = (this.obj3d.rotation.z * 180) / Math.PI
    if (isPositive) {
      this.obj3d.rotation.z = ((degree + 10) * Math.PI) / 180
    } else {
      this.obj3d.rotation.z = ((degree - 10) * Math.PI) / 180
    }

    this.rotation = {
      x: this.rotation.x,
      y: this.rotation.y,
      z: (this.obj3d.rotation.z * 180) / Math.PI,
    }
  }

  getArrowDescriptions(showArrowsOverride) {
    // If more than one object is selected return
    if (getSelectedObjects().length > 1) return

    // Don't show arrows when in error state
    if (this.hasDangerousCollisions) return

    // Don't show arrows when not selected
    if (!this.draggable && !showArrowsOverride) return

    // If the obstruction didn't move, use old arrow descriptions
    if (isEqual(this.lastDescriptionPos, this.obj3d.position)) {
      // Make sure keypress handler doesn't reinitialize causing massive listener bloat
      if (this.arrowDescriptions) {
        this.arrowDescriptions.forEach(desc => {
          desc.options.reinitializeKeypressHandler = false
        })
        return this.arrowDescriptions
      }
    }

    const descriptions = []

    const directions = [
      { direction: 'up', vector: new THREE.Vector3(0, 1, 0) },
      { direction: 'down', vector: new THREE.Vector3(0, -1, 0) },
      { direction: 'right', vector: new THREE.Vector3(1, 0, 0) },
      { direction: 'left', vector: new THREE.Vector3(-1, 0, 0) },
    ]

    const options = {
      measureFrom: Facility.SURFACE,
      measureTo: Facility.SURFACE,
      includedTypes: [OBJECT_TYPES.WALL, OBJECT_TYPES.OBSTRUCTION],
      xyOnly: true,
      obstructionMode: true,
    }

    // Create a clone of our selection box to use for collisions
    const clone = this.selectionBox.clone()
    clone.position.add(this.obj3d.position)
    clone.rotation.copy(this.obj3d.rotation)

    directions.forEach(({ direction, vector }) => {
      const measurements = Facility.current.measureObjectsInDirectionFromObject(
        vector,
        clone,
        options
      )

      if (measurements.length > 0) {
        const measurement = measurements[0]

        // Don't show measurements that will round to 0' 0"
        if (measurement.distance <= 0.05) return

        const keyPressHandler = e => {
          if (e.which === 13) {
            // Enter was pressed
            const distanceUnits = getDistanceUnits(store.getState())
            const distance = new Distance({
              value: Distance.unformat({
                value: e.target.value,
                system: distanceUnits,
              }),
              system: distanceUnits,
            })

            if (distance.value === null) return

            const nativeValue = distance.native()
            const newVector = vector.multiplyScalar(nativeValue)
            const vectorDiff = measurement.vector.sub(newVector)
            let newPosition = this.obj3d.position.clone()
            newPosition = newPosition.add(vectorDiff)
            this.drag({ newPosition })
            this.drop()
          }
        }

        descriptions.push({
          key: direction,
          vector: measurement.vector,
          position: measurement.startPoint,
          showLength: true,
          editable: !this.isDragging,
          keyPressHandler,
          options: {
            reinitializeKeypressHandler: this.reinitializeKeypressHandler,
          },
        })
      }
    })

    this.reinitializeKeypressHandler = false
    this.arrowDescriptions = descriptions
    this.lastDescriptionPos.copy(this.obj3d.position)

    return descriptions
  }

  destroy() {
    if (ObstructionDistanceEngine)
      ObstructionDistanceEngine.removeErrorState(this)

    if (ArrowRenderer) ArrowRenderer.unsubscribe(this)

    // https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
    this.obj3d.traverse(child => {
      if (child.dispose) {
        child.dispose()
      }
    })
  }
}

export default Obstruction
