import { motion } from 'framer-motion'
import { useState } from 'react'
import { MenuTrigger } from 'react-aria-components'
import { useDispatch } from 'react-redux'
import { IconVariant } from '~/config/icons'
import { hidePanel } from '~/store/panel'
import { LAYERS_PANEL } from '~/store/panel/types'
import { Button, ButtonProps } from '~/ui/Button'
import { Icon } from '~/ui/Icon'
import { Menu } from '~/ui/Menu'
import { PopoverProps } from '~/ui/Popover'

type MenuDropDownButtonProps = Omit<ButtonProps, 'children'> & {
  label: string
  icon: IconVariant
  children: PopoverProps['children']
}

export const MenuDropDownButton = ({
  label,
  icon,
  children,
  ...props
}: MenuDropDownButtonProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  return (
    <MenuTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
      <Button
        className="px-3 h-full border-0 bg-dark-gray-900 hover:bg-dark-gray-800 pressed:bg-dark-gray-800 disabled:opacity-50 text-white flex items-center rounded-none gap-1"
        onPress={() => dispatch(hidePanel({ type: LAYERS_PANEL }))}
        {...props}
      >
        <Icon className="text-baf-yellow" size="14" name={icon} />
        <span className="hidden min-[1200px]:block text-xs">{label}</span>
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.25 }}
        >
          <Icon size="14" name="arrowDown" color="white" />
        </motion.div>
      </Button>
      <Menu
        variant="square"
        popoverProps={{
          offset: 0,
          placement: 'bottom left',
          transition: { duration: 0 },
          className: 'dark',
        }}
      >
        {children}
      </Menu>
    </MenuTrigger>
  )
}
