import { graphql } from '~/gql'

export const GET_DEFAULT_VARIATION = graphql(`
  query GetDefaultVariation($productId: ID!, $isHeater: Boolean!) {
    Product(id: $productId) {
      defaultVariation {
        ...ProductVariationFragment @skip(if: $isHeater)
        ...HeaterVariationFragment @include(if: $isHeater)
      }
    }
  }
`)
