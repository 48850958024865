import React from 'react'
import PropTypes from 'prop-types'

import primaryLogo from 'images/logo.svg'
import secondaryLogo from 'images/secondaryLogo.svg'

import Container from './styled/Container'
import StyledLink from '../A/styled/Link'

const defaultSizes = {
  secondaryWidth: '50',
  width: '200',
  height: '40',
}

const Logo = ({
  centered,
  secondaryWidth = defaultSizes.secondaryWidth,
  width = defaultSizes.width,
  height = defaultSizes.height,
  secondary,
  ...props
}) => (
  <Container
    width={secondary ? secondaryWidth : width}
    height={height}
    centered={centered}
  >
    <StyledLink to="/">
      <img
        src={secondary ? secondaryLogo : primaryLogo}
        alt="Big Ass Fans"
        width={secondary ? secondaryWidth : width}
        height={height}
        {...props}
      />
    </StyledLink>
  </Container>
)

Logo.propTypes = {
  centered: PropTypes.bool,
  height: PropTypes.string,
  secondary: PropTypes.bool,
  secondaryWidth: PropTypes.string,
  width: PropTypes.string,
}

export default Logo
