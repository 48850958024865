import styled from 'styled-components'
import { theme } from 'lib/styleUtils'

export const LoadingContainer = styled.div`
  align-items: center;
  background-color: ${theme('colors.light.extraLight')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 80vh;
  min-height: 80vh;
  text-align: center;
`
