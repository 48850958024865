import styled from 'styled-components'

const Container = styled.iframe`
  border: 0;
  width: 100%;
  height: 700px;
  ${props => props.invisible && 'display: none;'};
`

export default Container
