import React from 'react'
import styled, { ThemedStyledProps } from 'styled-components'

import FormLabel from '../../FormLabel'
import { Theme } from '~/config/theme'

const setInlineStyles = (props: ThemedStyledProps<{ inline?: boolean }, Theme>) => {
  if (props.inline) {
    return `margin-right: ${props.theme.spacing.s};`
  }

  return `
    display: block;
    margin-bottom: ${props.theme.spacing.xs};
  `
}

const Label = styled(FormLabel)<{ labelWidth?: string; inline?: boolean }>`
  ${setInlineStyles};
  ${props => props.labelWidth && `min-width: ${props.labelWidth};`};
`.withComponent(({ inline, labelWidth, ...rest }) => <FormLabel {...rest} />)

export default Label
