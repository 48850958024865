import { graphql } from '~/gql'

const CREATE_ENTRY_MUTATION = graphql(`
  mutation createEntry(
    $name: String!
    $type: EntryTypes!
    $facilityId: ID
    $opportunityId: ID
    $accountId: ID
  ) {
    createEntry(
      name: $name
      type: $type
      facilityId: $facilityId
      opportunityId: $opportunityId
      accountId: $accountId
    ) {
      id
    }
  }
`)

export default CREATE_ENTRY_MUTATION
