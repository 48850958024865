import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Content = styled.div`
  align-items: stretch;
  color: ${theme('colors.light.fg')};
  display: flex;
  justify-content: start;
  min-height: 215px;
  overflow-x: scroll;
  width: 100%;
`

export default Content
