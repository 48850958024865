import { graphql } from '~/gql'

export const GET_VERSION_CFD_RESULTS_WITH_FACILITY = graphql(`
  query GetVersionCFDResultsWithFacility($versionId: ID!) {
    Version(id: $versionId) {
      id
      floor {
        id
        facility {
          id
          primaryObjective
          primaryUse
          indoorSummerTemp
          indoorWinterTemp
          indoorHumidity
          indoorWinterHumidity
          primaryType
        }
      }
      cfd {
        id
        URLs {
          id
          url
          fileExtension
          fileProps
        }
        status
        internalType
      }
    }
  }
`)
