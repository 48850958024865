import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Indicator = styled.div`
  border-right: ${theme('borderWidth')} solid rgba(255, 255, 255, 0.2);
  padding: ${theme('spacing.base')};
`

export default Indicator
