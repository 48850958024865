const convertInchesToMeters = (inches: number): number => inches / 39.97

export interface Edge {
  p1: [number, number]
  p2: [number, number]
}

export interface Position {
  x: number
  y: number
  z: number
}

export const getComfortZoneEdges = (positions: Position[]): Edge[] =>
  positions.reduce((prev, _, i, positions) => {
    if (i === positions.length - 1) return prev
    const { x: x1, y: y1 } = positions[i]
    const { x: x2, y: y2 } = positions[i + 1]
    prev.push({
      p1: [convertInchesToMeters(x1), convertInchesToMeters(y1)],
      p2: [convertInchesToMeters(x2), convertInchesToMeters(y2)],
    })
    return prev
  }, [] as Edge[])

/**
 * @param {number[]} coordinate
 * @param {Edge[]} edges
 * @returns {boolean}
 */
export const isWithinComfortZone = ([x, y]: [number, number], edges: Edge[]) => {
  // https://observablehq.com/@tmcw/understanding-point-in-polygon
  if (!edges) return true
  let isInZone = false
  for (const { p1, p2 } of edges) {
    const [x1, y1] = p1
    const [x2, y2] = p2
    const isIntersection = y1 > y !== y2 > y && x < ((x2 - x1) * (y - y1)) / (y2 - y1) + x1
    if (!isIntersection) isInZone = !isInZone
  }
  return isInZone
}
