import React from 'react'
import PropTypes from 'prop-types'
import { appConnect, useAppSelector } from "~/store/hooks";

import {
  setOrthoModeToggle,
  setSnapToggle,
  setContinuousModeToggle,
  setActiveTool,
  setActiveToolCategory,
} from 'store/tools'
import {
  activeTool,
  activeToolCategory,
  isSnapEnabled,
  isOrthoModeEnabled,
  isContinuousModeEnabled,
} from 'store/tools/selectors'
import { deselectObjects } from 'store/selectedObjects'
import {
  getSelectedObjects,
  getSelectedObjectState,
} from 'store/selectedObjects/selectors'
import { isTouchUI } from 'store/userInterface/selectors'
import withUser from 'client/decorators/withUser'

import LAYER_KEYS from 'config/layerKeys'
import CATEGORY_KEYS from 'config/toolCategoryKeys'
import { currentUserIsCustomer } from 'lib/currentUserIs'

import Caret from 'components/UIKit/Caret'
import Icon from 'components/UIKit/Icon'
import Popover from 'components/UIKit/Popover'
import Space from 'components/UIKit/Space'
import Switch from 'components/UIKit/Switch'

import Action from './styled/Action'
import Card from './styled/Card'
import Menu from './styled/Menu'
import { compose } from 'redux'

const TOOLS_WITH_SETTINGS = [
  'SELECT_TOOL',
  'LINE_TOOL',
  'RECTANGLE_TOOL',
  'ELLIPSE_TOOL',
  'CUBE_TOOL',
  'CYLINDER_TOOL',
  'DIMENSION_TOOL',
  'ELEVATION_POINT_TOOL',
  'ELEVATION_LINE_TOOL',
  'PRODUCT_TOOL',
  'OBSTRUCTION_TOOL',
  'DOOR_TOOL',
  'DRAW_TOOL',
]

const TOOLS_WITH_SNAP = [
  'SELECT_TOOL',
  'LINE_TOOL',
  'DIMENSION_TOOL',
  'ELEVATION_POINT_TOOL',
  'ELEVATION_LINE_TOOL',
]

const TOOLS_WITH_ORTHO = [
  'LINE_TOOL',
  'RECTANGLE_TOOL',
  'ELLIPSE_TOOL',
  'CUBE_TOOL',
  'CYLINDER_TOOL',
  'DIMENSION_TOOL',
  'ELEVATION_LINE_TOOL',
]

const CONTINUOUS_OPTIONS = [
  {
    tool: 'PRODUCT_TOOL',
    object: LAYER_KEYS.PRODUCTS,
  },
  {
    tool: 'OBSTRUCTION_TOOL',
  },
  {
    tool: 'DOOR_TOOL',
  },
  {
    tool: 'DRAW_TOOL',
  },
]

function DrawingSettings(props = {}) {
  const state = useAppSelector(x => x)
  if (currentUserIsCustomer(props.user)) return null

  const selectedObjects = getSelectedObjects(state)
  const selectedObject =
    selectedObjects.length === 1 && getSelectedObjectState(selectedObjects[0], state)
  const activeToolNotAllowed = !TOOLS_WITH_SETTINGS.includes(props.activeTool)
  const isOrthoSupported = TOOLS_WITH_ORTHO.includes(props.activeTool)
  const isSelectToolActive = props.activeTool === 'SELECT_TOOL'
  const isContinuousTool = CONTINUOUS_OPTIONS.some(
    opt => opt.tool === props.activeTool
  )
  const isContinuousObject =
    isSelectToolActive &&
    selectedObject &&
    CONTINUOUS_OPTIONS.some(opt => selectedObject.layerKey === opt.object)
  const isContinuousSupported = isContinuousTool || isContinuousObject
  const noSelectedObjects = props.selectedObjects.length === 0

  // The wall line tool supports snapping but the obstruction line tool does not
  const isObstructionLineTool =
    props.currentLayer === 'OBSTRUCTIONS' && props.activeTool === 'LINE_TOOL'

  // The ceilings select tool should not support snapping
  const isCeilingSelectTool =
    props.currentLayer === 'CEILINGS' && props.activeTool === 'SELECT_TOOL'

  const isSnapSupported =
    TOOLS_WITH_SNAP.includes(props.activeTool) && !isObstructionLineTool

  // 1. The `SELECT_TOOL` is active *AND* there are no selected objects.
  // 2. The currently active tool is not in the `TOOLS_WITH_SETTINGS` list.
  // 3. The currently active tool is the ceilings select tool
  if (
    (isSelectToolActive && noSelectedObjects) ||
    activeToolNotAllowed ||
    isCeilingSelectTool ||
    !props.online
  )
    return null

  const isDropdownVisible = props.activeToolCategory === CATEGORY_KEYS.settings
  return (
    <Popover
      closeOnDocumentClick={false}
      onTriggerClick={() => {
        props.setActiveToolCategory({
          activeToolCategory: CATEGORY_KEYS.settings,
        })
      }}
      renderTrigger={() => (
        <Card padding="s">
          <Action text="Settings" href="#0">
            <Icon name="panelControl" size="16" />
            {
              <Caret
                color="subdued"
                size="12"
                isDropdownVisible={isDropdownVisible}
                isTouchUI={props.isTouchUI}
              />
            }
          </Action>
        </Card>
      )}
      renderMenu={() =>
        isDropdownVisible && (
          <Menu padding="s" variant="dark" isTouchUI={props.isTouchUI}>
            <Space bottom="s">
              <Switch
                onClick={() =>
                  props.setOrthoModeToggle({
                    isOrthoModeEnabled: !props.isOrthoModeEnabled,
                  })
                }
                name="ortho"
                label="Ortho"
                variant="dark"
                disabled={!isOrthoSupported}
                isChecked={isOrthoSupported && props.isOrthoModeEnabled}
              />
            </Space>
            <Space bottom="s">
              <Switch
                onClick={() =>
                  props.setSnapToggle({ isSnapEnabled: !props.isSnapEnabled })
                }
                name="snapping"
                label="Snapping"
                variant="dark"
                disabled={!isSnapSupported}
                isChecked={isSnapSupported && props.isSnapEnabled}
              />
            </Space>
            <Space bottom="s">
              <Switch
                onClick={() =>
                  props.setContinuousModeToggle({
                    isContinuousModeEnabled: !props.isContinuousModeEnabled,
                  })
                }
                name="continuous"
                label="Continuous"
                variant="dark"
                disabled={!isContinuousSupported}
                isChecked={
                  isContinuousSupported && props.isContinuousModeEnabled
                }
              />
            </Space>
          </Menu>
        )
      }
    />
  )
}

DrawingSettings.propTypes = {
  activeTool: PropTypes.string,
  activeToolCategory: PropTypes.string,
  currentLayer: PropTypes.string,
  deselectObjects: PropTypes.func,
  isOrthoModeEnabled: PropTypes.bool,
  isSnapEnabled: PropTypes.bool,
  isTouchUI: PropTypes.bool,
  isContinuousModeEnabled: PropTypes.bool,
  selectedObjects: PropTypes.array,
  setActiveTool: PropTypes.func,
  setActiveToolCategory: PropTypes.func,
  setOrthoModeToggle: PropTypes.func,
  setSnapToggle: PropTypes.func,
  setContinuousModeToggle: PropTypes.func,
  online: PropTypes.bool,
  user: PropTypes.object,
}

const mapStateToProps = state => ({
  activeTool: activeTool(state),
  activeToolCategory: activeToolCategory(state),
  isOrthoModeEnabled: isOrthoModeEnabled(state),
  isSnapEnabled: isSnapEnabled(state),
  isContinuousModeEnabled: isContinuousModeEnabled(state),
  selectedObjects: getSelectedObjects(state),
  currentLayer: state.layers.currentLayer,
  isTouchUI: isTouchUI(state),
})

const mapDispatchToProps = {
  setOrthoModeToggle,
  setSnapToggle,
  setContinuousModeToggle,
  setActiveTool,
  setActiveToolCategory,
  deselectObjects,
}

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withUser
)(DrawingSettings)
