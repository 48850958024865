import React from 'react'
import LoaderComponent from 'components/UIKit/Loader'
import { LoadingContainer } from '../styled/LoadingContainer'

export const Loader = () => {
  return (
    <LoadingContainer>
      <LoaderComponent centered />
    </LoadingContainer>
  )
}
