import routes from '~/config/routes'
import LAYER_KEYS, { LayerKeys } from '~/config/layerKeys'
import Facility from '~/components/DrawingCanvas/lib/facility'
import Util from '~/components/DrawingCanvas/lib/util'
import { useAppDispatch } from '~/store/hooks'
import { setCurrentLayer } from '~/store/layers'
import { setActiveTool } from '~/store/tools'
import { useNavigate } from 'react-router-dom-v5-compat'
import { MenuDropDownButton } from '../components/MenuDropDownButton'
import { MenuItem } from '~/ui/Menu'
import { Icon } from '~/ui/Icon'
import { useRouteMatch } from 'react-router'

export default function RoofsAndCeilingsMenu() {
  const dispatch = useAppDispatch()
  const action = (layerKey: LayerKeys, tool: string) => () => {
    dispatch(setCurrentLayer({ layerKey }))
    dispatch(setActiveTool({ tool }))
  }
  const match = useRouteMatch()
  const navigate = useNavigate()

  return (
    <MenuDropDownButton label="Roofs & Ceilings" icon="home">
      {Facility.current && Util.isFacilityRectangle() && <MenuItem onAction={() => {
        navigate(match.url+routes.modals.roofTemplates)
        action(LAYER_KEYS.ROOFS, 'TEMPLATE_TOOL')()
      }} variant="square">
        <Icon name='crop' size="14"/>
        Roof Templates
      </MenuItem>}
      <MenuItem onAction={action(LAYER_KEYS.ELEVATION_LINE, 'ELEVATION_LINE_TOOL')} variant="square">
        <Icon name='caretUp' size="14"/>
        Elevation Line
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.ELEVATION_POINT, 'ELEVATION_POINT_TOOL')} variant="square">
        <Icon name='elevationPoint' size="14"/>
        Elevation Point
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.ROOF_SECTIONS, 'SELECT_TOOL')} variant="square">
        <Icon name='mountingStructures' size="14"/>
        Mounting Structures
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.CEILINGS, 'SELECT_TOOL')} variant="square">
        <Icon name='ceilings' size="14"/>
        Ceilings
      </MenuItem>
    </MenuDropDownButton>
  )
}
