import { useQuery } from '@apollo/client'
import { useAppSelector } from "~/store/hooks";
import { graphql } from '~/gql'

const GET_USER_PERMISSIONS_QUERY = graphql(`
  query GetUserPermissions($id: ID) {
    User(id: $id) {
      id
      roles {
        id
        name
      }
      features {
        id
        name
      }
    }
  }
`)

export const useGetUserPermissions = () => {
  const userId = useAppSelector(({ auth }) => auth.userId)
  const { data, loading: loadingUser, error: userError } = useQuery(GET_USER_PERMISSIONS_QUERY, {
    variables: { id: userId },
    skip: !userId,
  })
  return { user: data?.User, loadingUser, userError }
}
