import styled from 'styled-components'

import { theme } from 'lib/styleUtils'
import { getZIndexOf } from 'lib/utils'
import Button from 'components/UIKit/Button'

const SIZE = '50px'

const Container = styled(Button)`
  border-radius: 50%;
  height: ${SIZE};
  line-height: ${SIZE};
  position: absolute;
  right: ${theme('spacing.l')};
  top: ${theme('spacing.l')};
  width: ${SIZE};
  z-index: ${getZIndexOf('closeFullscreenButton')};
`

export default Container
