import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/UIKit/Select'

const controllerTypeOptions = [
  { label: 'Standard Controller', key: 'Standard Controller' },
  { label: 'BAFCon', key: 'BAFCon' },
  { label: 'BAFCon Multi-Fan', key: 'BAFCon Multi-Fan' },
  { label: 'BAFWorks', key: 'BAFWorks' },
  { label: 'Dewtect', key: 'Dewtect' },
]

export const ControllerTypeSelect = ({ value, onChange, label }) => {
  return (
    <Select
      name="controllerType"
      label={label}
      inline
      value={value}
      onChange={onChange}
      options={controllerTypeOptions}
    />
  )
}

ControllerTypeSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
}
