import styled from 'styled-components'

import FormLabel from '../../FormLabel'

const setInlineStyles = props => {
  if (props.inline) {
    return `margin-right: ${props.theme.spacing.s};`
  }

  return `
    display: block;
    margin-bottom: ${props.theme.spacing.xs};
  `
}

const Label = styled(FormLabel)`
  width: ${props => props.width || 'auto'};

  ${setInlineStyles};
`

export default Label
