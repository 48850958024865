import { graphql } from '~/gql'

const ALL_USER_FAVORITES_QUERY = graphql(`
  query AllUserFavorites {
    allFacilities: allUserFavorites {
      ...FacilityFragment
      floor {
        id
        version {
          id
        }
      }
    }
  }
`)

export default ALL_USER_FAVORITES_QUERY
