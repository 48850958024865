import React from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";
import { ActionCreators } from 'redux-undo'

import Action from 'components/UIKit/Action'
import Icon from 'components/UIKit/Icon'
import { ToolbarGroup, ToolbarItem } from 'components/UIKit/Toolbar'

import { isTouchUI } from 'store/userInterface/selectors'

const HistoryToolbarGroup = ({
  size = '20%',
  alignment = 'left',
  past,
  future,
  isTouchUI,
  onUndo,
  onRedo,
  actionSize,
  online,
} = {}) => {
  // If 'online' is undefined we might be online still so
  // explicitly check that it has been set to false
  const isOffline = online === false

  return (
    <ToolbarGroup size={size} alignment={alignment}>
      <ToolbarItem space="base" paddingLeft="base">
        <Action
          text="Undo"
          variant="dark"
          onClick={() => {
            if (past.length) {
              onUndo()
            }
          }}
          disabled={past.length < 1 || isOffline}
          size={actionSize}
        >
          <Icon name="undo" size={isTouchUI ? '16' : '14'} color="bg" />
        </Action>
      </ToolbarItem>
      <ToolbarItem space="base">
        <Action
          text="Redo"
          variant="dark"
          onClick={future.length > 0 ? onRedo : () => null}
          disabled={future.length < 1 || isOffline}
          size={actionSize}
        >
          <Icon name="redo" size={isTouchUI ? '16' : '14'} color="bg" />
        </Action>
      </ToolbarItem>
    </ToolbarGroup>
  )
}

const mapStateToProps = store => ({
  past: store.objects.past,
  future: store.objects.future,
  isTouchUI: isTouchUI(store),
})

const mapDispatchToProps = dispatch => ({
  onUndo() {
    dispatch(ActionCreators.undo())
  },

  onRedo() {
    dispatch(ActionCreators.redo())
  },
})

HistoryToolbarGroup.propTypes = {
  actionSize: PropTypes.string,
  alignment: PropTypes.string,
  future: PropTypes.arrayOf(PropTypes.object),
  isTouchUI: PropTypes.bool,
  online: PropTypes.bool,
  onRedo: PropTypes.func,
  onUndo: PropTypes.func,
  past: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.string,
}

export default appConnect(mapStateToProps, mapDispatchToProps)(HistoryToolbarGroup)
