import styled, { ThemedStyledProps } from 'styled-components'
import { Spacing, Theme } from '~/config/theme'

const size = '20px'

const spacing = (props: ThemedStyledProps<{ marginRight?: Spacing }, Theme>) => {
  if (props.marginRight) {
    return `margin-right: ${props.theme.spacing[props.marginRight]};`
  }

  return null
}

const Container = styled.label<{ disabled?: boolean; marginRight?: Spacing }>`
  align-items: center;
  cursor: ${props => (props.disabled ? 'wait' : 'default')};
  display: flex;
  height: ${size};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  position: relative;
  ${spacing};
`

export default Container
