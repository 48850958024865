export interface Door {
  doorType: string
  width: number
  height: number
}

export const manDoor: Door =
  {
    doorType: 'manDoor',
    width: 36,
    height: 80,
  }

export const dockDoor: Door =
  {
    doorType: 'rollbackDoor',
    width: 96,
    height: 120,
  }
