import { graphql } from '~/gql'

export const GET_CFD_UPLOAD_SIGNATURE = graphql(`
  query GetCFDUploadSignature($versionId: ID, $fileName: String, $timestamp: DateTime) {
    signature: getCFDUploadSignature(
      versionId: $versionId
      filename: $fileName
      timestamp: $timestamp
    ) {
      url
      fields
    }
  }
`)
