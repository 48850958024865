import ReactGA from 'react-ga'

import { GA_TRACKING_CODE } from 'config/analytics'
import { isProduction } from './utils'

/**
 * Initialize Google Analytics for the app
 */
export const initializeAnalytics = () =>
  isProduction() ? ReactGA.initialize(GA_TRACKING_CODE) : null

/**
 * Add event interaction to Google Analytics tracking
 * @param args GA event args object
 */
export const trackEvent = (args: ReactGA.EventArgs) =>
  isProduction() ? args && ReactGA.event(args) : console.log(args)

/**
 * Add URL path to Google Analytics tracking
 * @param {string} page URL path name
 */
export const trackPage = (page: string) => {
  if (isProduction()) {
    ReactGA.set({ page })
    ReactGA.pageview(page)
  }
}
