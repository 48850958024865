import Grid, { GridItem } from 'components/UIKit/Grid'
import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'
import React from 'react'
import PropTypes from 'prop-types'

import Container from './styled/Container'
import H1 from 'components/UIKit/H1'
import DocTypeItem from './styled/DocTypeItem'

export default function DetailedView({ type = 'ITEMIZED', setType }) {
  return (
    <Container>
      <Grid>
        <GridItem size="1of2">
          <DocTypeItem
            href=""
            onClick={e => {
              e.preventDefault()
              setType('ITEMIZED')
            }}
            active={type === 'ITEMIZED'}
          >
            <Space>
              <H1>Itemized Product Table</H1>
              <VariantText>
                Attach a product table with a row for each product
              </VariantText>
            </Space>
          </DocTypeItem>
        </GridItem>
        <GridItem size="1of2">
          <Space>
            <DocTypeItem
              href=""
              onClick={e => {
                e.preventDefault()
                setType('CONDENSED')
              }}
              active={type === 'CONDENSED'}
            >
              <H1>Condensed Product Table</H1>
              <VariantText>
                Attach a product table with similar products sharing a row
              </VariantText>
            </DocTypeItem>
          </Space>
        </GridItem>
      </Grid>
    </Container>
  )
}

DetailedView.propTypes = {
  type: PropTypes.string,
  setType: PropTypes.func,
}
