import Util from './util'
import Tool from './tool'
import Facility from './facility'
import WallSegment from './wallSegment'
import isEqual from 'lodash-es/isEqual'
import get from 'lodash-es/get'

import UtilityBox from './utilityBox'
import SnapQueries from './snapQueries'

import { isTouchDevice } from 'lib/utils'

import store from 'store'
import { addUtilityBox } from 'store/objects'
import { clearStatus, setStatus } from 'store/status'

import OBJECT_TYPES from 'config/objectTypes'

import * as THREE from 'three'

class UtilityBoxTool extends Tool {
  constructor(props = {}) {
    super()
    this.name = 'UTILITY_BOX_TOOL'
    this.isValidPosition = false
    this.props = {
      ...props,
    }

    this.createVisual()
    this.createEventListeners()
  }

  setPosition = ({ x, y }) => {
    if (this.utilityBox) {
      this.utilityBox.obj3d.position.copy(new THREE.Vector3(x, y, 72))
    }
  }

  createVisual() {
    this.obj3d = new THREE.Object3D()
  }

  createEventListeners = () => {
    document.addEventListener('keyup', this.handleKeyUp)
  }

  removeEventListeners = () => {
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyUp = e => {
    // Esc key up
    if (e.which === 27) {
      this.deactivate()
    }
  }

  insert = multiSelect => {
    this.deactivate()
    store.dispatch(
      addUtilityBox({
        utilityBox: this.utilityBox.toModel(),
        multiSelect,
        ignoreForCFD: true,
      })
    )
  }

  getSegmentIndex(segments, id) {
    return segments.findIndex(segment => segment.id === id)
  }

  updateMountedDirection(startPoint, endPoint, object) {
    if (!startPoint || !endPoint) return

    const direction = new THREE.Vector3()
      .subVectors(startPoint, endPoint)
      .normalize()

    object.quaternion.setFromUnitVectors(new THREE.Vector3(1, 0, 0), direction)

    // Keep box opening facing inside
    if (object.quaternion.y !== 0) {
      object.quaternion.y = 0
      object.rotation.z = Math.PI
    }
  }

  isOverSnappableObject() {
    if (!this.utilityBox) return

    const pos = this.utilityBox.obj3d.position.clone()
    const positions = [
      new THREE.Vector3(pos.x + 1, pos.y, pos.z),
      new THREE.Vector3(pos.x - 1, pos.y, pos.z),
      new THREE.Vector3(pos.x, pos.y + 1, pos.z),
      new THREE.Vector3(pos.x, pos.y - 1, pos.z),
    ]
    let objectFound = false

    for (let i = 0; i < positions.length; i++) {
      const objects = Util.getObjectsUnderPositionByType(
        positions[i],
        [OBJECT_TYPES.WALL, OBJECT_TYPES.COLUMN],
        Facility
      )
      if (objects.length) {
        const box = new THREE.Box3().setFromObject(objects[0].obj3d)
        this.handleHeight = box.max.z + 20
        objectFound = true
        break
      }
    }

    return objectFound
  }

  // Context functions
  toolMoved(
    mousePos,
    snappedMousePos,
    lastSceneIntersectionPoint,
    objectWithCursor,
    objectWithSnappedCursor,
    activeSnapRegion
  ) {
    if (this.utilityBox !== undefined) this.obj3d.remove(this.utilityBox.obj3d)

    let isSnappedPosition = false
    let isOverWall = false
    const position = new THREE.Vector3()
    const object = new THREE.Object3D()

    if (!isEqual(mousePos, snappedMousePos)) {
      isSnappedPosition = true
      position.set(snappedMousePos.x, snappedMousePos.y, 20)
    } else {
      position.set(mousePos.x, mousePos.y, 20)
    }

    this.setPosition({
      x: position.x,
      y: position.y,
    })

    if (objectWithCursor instanceof WallSegment) {
      isOverWall = true
      this.wallSegmentId = objectWithCursor.id
      this.updateMountedDirection(
        objectWithCursor.startPoint,
        objectWithCursor.endPoint,
        object
      )
    }

    if (isSnappedPosition && !isOverWall) {
      // Mouse might not be over a wall, but the box could be.
      // Try to see if box point lies on wall segment
      const segments = Facility.current.getWallSegments()
      for (let i = 0; i < segments.length; i++) {
        const seg = segments[i]
        const outsetPoints = seg.getOutsetPoints()
        const insetPoints = seg.getInsetPoints()
        const boxPosition = get(this, 'utilityBox.obj3d.position')

        if (boxPosition && insetPoints.length && outsetPoints.length) {
          const isOnInsetLine =
            insetPoints.length &&
            Util.isPointOnSegment(
              Util.arrayPointToObjectPoint(insetPoints[0]),
              boxPosition,
              Util.arrayPointToObjectPoint(insetPoints[1])
            )
          const isOnOutsetLine =
            outsetPoints.length &&
            Util.isPointOnSegment(
              Util.arrayPointToObjectPoint(outsetPoints[0]),
              boxPosition,
              Util.arrayPointToObjectPoint(outsetPoints[1])
            )

          if (isOnInsetLine || isOnOutsetLine) {
            isOverWall = true
            this.wallSegmentId = seg.id
            this.updateMountedDirection(seg.startPoint, seg.endPoint, object)
            break
          }
        }
      }
    }

    // Update rotation if over snappable objects other than walls
    const isOverSnappableObject = this.isOverSnappableObject()
    if (!isOverWall && isOverSnappableObject && activeSnapRegion) {
      const point1 = get(activeSnapRegion, 'point1')
      const point2 = get(activeSnapRegion, 'point2')
      if (point1 && point2) this.updateMountedDirection(point1, point2, object)
    }

    // Check if box is over a valid object and snapped
    const isOverValidObject = isOverWall || isOverSnappableObject
    this.isValidPosition = isSnappedPosition && isOverValidObject

    const model = UtilityBox.createModel(
      position,
      this.props.utilityBoxType,
      this.props.width,
      this.props.height,
      this.props.centerPointHeight,
      this.props.color,
      this.props.label,
      object.rotation,
      this.isValidPosition,
      this.layerKey,
      this.handleHeight,
      this.wallSegmentId,
      true,
      this.props.brand,
      this.props.voltage,
      this.props.type,
      this.props.fans
    )
    this.utilityBox = new UtilityBox(model)
    this.obj3d.add(this.utilityBox.obj3d)
  }

  toolUp({ mousePos, multiSelect }) {
    if (isTouchDevice()) {
      this.setPosition({
        x: mousePos.x,
        y: mousePos.y,
      })
    }

    const label = get(this.utilityBox, 'label', 'Utility Boxe')
    const isInsideFacility = Util.isPositionsOverFacility([
      this.utilityBox.obj3d.position,
    ])

    if (isInsideFacility) {
      this.insert(multiSelect)
    } else {
      const error = `${label}s must be placed inside the facility!`
      store.dispatch(setStatus({ text: error, type: 'error' }))
    }
  }

  activate(mousePos) {
    this.createVisual()
    const label = get(this.props, 'label', 'Utility Boxe')
    store.dispatch(
      setStatus({
        type: 'info',
        text: `${label}s can only be placed on front of walls or columns.`,
      })
    )
  }

  deactivate() {
    store.dispatch(clearStatus())
    this.obj3d.remove(this.utilityBox)
    this.removeEventListeners()
  }

  getSnapRegions(facility, draggedObject) {
    const draggedObjectId = draggedObject ? draggedObject.id : ''
    const wallSnapRegions = SnapQueries.getAllWallInsetAndOutsetLines(
      draggedObjectId,
      true
    )

    let columnSnapRegions = []
    if (
      this.props.utilityBoxType === 'controlPanel' ||
      this.props.utilityBoxType === 'electricPanel'
    ) {
      columnSnapRegions = SnapQueries.getAllColumnOutsetLines(
        [draggedObjectId],
        true
      )
    }
    return wallSnapRegions.concat(columnSnapRegions)
  }

  onPropsDidChange(nextProps) {
    this.props = {
      ...this.props,
      ...nextProps,
    }
  }

  getArrowDescriptions() {}
}

export default UtilityBoxTool
