/*global THREE*/
/*eslint no-undef: "error"*/

import defaultTo from 'lodash-es/defaultTo'
import get from 'lodash-es/get'

import theme from 'config/theme'
import store from 'store'
import { clearStatus } from 'store/status'

import getRenderOrder from 'config/canvasRenderOrder'
import LAYER_KEYS from 'config/layerKeys'
import { SYSTEMS } from 'store/units/constants'
import CLASS_NAMES from 'config/objectClassNames'
import ColorWorker from './cfdColor.worker?worker'

import Util from './util'
import Facility from './facility'

import * as T from 'three'

import { primaryUseVelocityTypes } from 'config/facility'

const CFD_OPACITY = 1
export const LOW_VELOCITY_COLORS = theme.colors.lowVelocityAirflow
export const HIGH_VELOCITY_COLORS = theme.colors.highVelocityAirflow
export const DESTRAT_VELOCITY_COLORS = theme.colors.destrat
export const DRAFT_RISK_VELOCITY_COLORS = theme.colors.draftRisk
export const HEAT_MAP_COLORS = theme.colors.heat
export const INTENSITY_COLORS = theme.colors.intensity

class CFD {
  constructor(cfd, units, type, level, facility) {
    this.worker = new ColorWorker()
    const model = this.createModel(cfd)
    this.goal = get(store.getState(), 'cfd.selectedLayer.goal', 'cooling')
    this.className = CLASS_NAMES.CFD
    this.type = type
    this.level = level
    this.facility = facility

    const walls = Facility.current.getWalls()
    const wall = walls.find(w => w.floorMesh)

    if (!wall) {
      return
    } // if facility is empty

    this.units = units
    this.velocityUnits = get(
      store.getState(),
      'units.VELOCITY',
      SYSTEMS.IMPERIAL
    )
    this.layerKey = defaultTo(model.category, model.layerKey)

    const obj3d = this.getCFDMesh(model.geometry)
    this.obj3d = obj3d
    this.obj3d.layerKey = this.layerKey
    this.obj3d.renderOrder = getRenderOrder('cfd')

    // Used by Interactifier
    this.selectable = false
    this.draggable = false
    this.obj3d.wrapperId = this.id
  }

  getColorData = (velocities = [], isHeat) => {
    let colors = HIGH_VELOCITY_COLORS
    const primaryUse = get(this, 'facility.primaryUse')
    if (primaryUse === 'OTHER') {
      const primaryType = get(this, 'facility.primaryType')
      if (primaryType === 'RESIDENTIAL' || primaryType === 'COMMERCIAL') {
        colors = LOW_VELOCITY_COLORS
      }
    } else if (primaryUseVelocityTypes.low.includes(primaryUse)) {
      colors = LOW_VELOCITY_COLORS
    }

    if (this.goal === 'destrat') {
      colors =
        this.type === 'destrat'
          ? DESTRAT_VELOCITY_COLORS
          : DRAFT_RISK_VELOCITY_COLORS
    }

    if (isHeat) colors = INTENSITY_COLORS

    return new Promise((resolve, reject) => {
      this.worker.onmessage = (event) => {
        resolve(new Float32Array(event.data.data))
      }
      this.worker.onerror = (event) => {
        reject(event)
      }
      this.worker.postMessage({
        array: velocities.array,
        count: velocities.count,
        colors,
        isHeat,
      })
    })
  }

  getCFDMesh(geometry) {
    if (geometry) {
      const velocities = geometry.attributes.velocity
      const intensity = geometry.attributes.intensity

      // TODO: There should be some form of loading indicator here
      this.getColorData(velocities || intensity, intensity?.count)
        .then(buffer => {
          geometry.setAttribute(
            'color',
            new T.BufferAttribute(
              buffer,
              4,
            )
          )
        })
        .catch(console.error)
      let obj3d
      if (['track', 'isometric', 'streamlines'].includes(this.type)) {
        obj3d = new T.LineSegments(
          geometry,
          new T.LineBasicMaterial({
            vertexColors: true,
          })
        )
      } else if (
        ['overhead', 'destrat', 'overhead-fpm', 'sideY', 'sideX'].includes(
          this.type
        )
      ) {
        obj3d = new T.Mesh(
          geometry,
          new T.MeshBasicMaterial({
            side: T.DoubleSide,
            vertexColors: true,
            opacity: CFD_OPACITY,
            transparent: true,
          })
        )
      }

      return obj3d
    }
  }

  /*
    SceneBuilder event
  */
  visibilityChanged(visible) {
    store.dispatch(clearStatus())

    if (this.obj3d) this.obj3d.visible = visible
  }

  createModel(cfd) {
    return {
      id: Util.guid(),
      layerKey: LAYER_KEYS.CFD,
      geometry: cfd,
    }
  }

  // ///
  // Interactable methods
  // ///

  drop() {}

  drag() {}

  snap() {}

  destroy() {}
}

export default CFD
