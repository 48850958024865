import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'

export function mountedOn({
  isDirectional,
  isMounted,
  isMountedToCeiling,
} = {}) {
  if (isDirectional && isMountedToCeiling) return 'OVERHEAD'
  if (!isDirectional) return 'OVERHEAD'

  // TODO: We need to differentiate from columns
  if (isDirectional && isMounted && !isMountedToCeiling) return 'WALL'
  if (isDirectional && !isMounted && !isMountedToCeiling) return 'PEDESTAL'
}

export function defaultMountingOption({
  mountingOptions,
  isDirectional,
  isMounted,
  isMountedToCeiling,
} = {}) {
  const isMountedOn = mountedOn({
    isDirectional,
    isMounted,
    isMountedToCeiling,
  })
  const options = sortBy(mountingOptions, 'tubeLength').filter(o => {
    if (isMountedOn === 'WALL') return o.forWall
    if (isMountedOn === 'COLUMN') return o.forColumn
    if (isMountedOn === 'PEDESTAL') return o.forPedestal
    return o.forOverhead
  })
  return get(options, '[0]')
}
