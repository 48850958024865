import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Icon from 'components/UIKit/Icon'

export const CloseIcon = styled(Icon)`
  position: absolute;
  right: ${theme('spacing.base')};
  top: ${theme('spacing.base')};
`

export default CloseIcon
