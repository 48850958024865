import React, { Component, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { match, withRouter } from 'react-router-dom'
import { motion } from "framer-motion"

import Button from '../Button'
import Icon from '../Icon'
import Space from '../Space'

import Container from './styled/Container'
import Card from './styled/Card'
import Overlay from './styled/Overlay'
import Header from './styled/Header'
import Title from './styled/Title'
import Description from './styled/Description'
import Close from './styled/Close'
import Content from './styled/Content'
import Footer from './styled/Footer'
import Wrapper from './styled/Wrapper'
import { History, Location } from 'history'

type ModalProps = {
  title?: string
  backLink?: ReactNode
  description?: string
  primaryAction?: ReactNode
  secondaryAction?: ReactNode
  centered?: boolean
  size?: string
  history: History<unknown>
  parentRoute: string
  flushContent?: boolean
  onSubmit?: (it: any) => any
  onClose?: () => void
  noMargin?: boolean
  maxHeight?: string
  children?: ReactNode
  location: Location<unknown>
  match: match<any>
  uncloseable?: boolean
}

export class Modal extends Component<ModalProps> {
  state = {
    isVisible: true,
  }
  shouldClose: boolean | null

  constructor(props: ModalProps) {
    super(props)

    this.shouldClose = null
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown)
  }

  handleKeydown = (event: KeyboardEvent) => {
    if (event.code === "Escape" && !this.props.uncloseable) {
      this.handleClose(event)
    }
  }

  handleClose = (event: { preventDefault(): void }, options?: { direct?: boolean }) => {
    event.preventDefault()

    // NOTE: We're doing this because the close icon in the modal is being
    // blocked because it's inside of the modal content.
    if (options?.direct) {
      this.shouldClose = true
    }

    if (this.shouldClose === null) {
      this.shouldClose = true
    }

    const { onClose } = this.props

    if (this.shouldClose) {
      this.setState(
        {
          isVisible: !this.state.isVisible,
        },
        () => onClose && onClose()
      )
    }

    this.shouldClose = null
  }

  handleAnimationEnd = () => {
    const { history, parentRoute } = this.props

    if (history.action === 'POP' || history.location.pathname === parentRoute) {
      return history.push(parentRoute)
    }

    return history.goBack()
  }

  handleInnerClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    this.shouldClose = false
  }

  render() {
    const {
      title,
      backLink,
      description,
      children,
      primaryAction,
      secondaryAction,
      centered,
      size,
      flushContent,
      onSubmit,
      noMargin,
      maxHeight,
      uncloseable,
    } = this.props

    const { isVisible } = this.state

    const wrapperProps = onSubmit ? { onSubmit } : {}

    return (
      <>
        {isVisible && <Container onClick={this.handleClose}>
          <Overlay initial={{opacity: 0}} animate={{opacity: 0.85}} exit={{opacity: 0}} key="overlay" />
          <Card
            centered={centered}
            size={size}
            noMargin={noMargin}
            maxHeight={maxHeight}
            onClick={this.handleInnerClick}
            onMouseDown={this.handleInnerClick}
            onMouseUp={this.handleInnerClick}
            initial={{opacity: 0, y: -20}} animate={{opacity: 1, y: 0}} exit={{opacity: 0, y: 20}}
          >
            <Wrapper as={onSubmit ? 'form' : 'div'} {...wrapperProps}>
              <Header title={title}>
                {backLink && <Space bottom="s">{backLink}</Space>}
                {title && <Title>{title}</Title>}
                {description && <Description>{description}</Description>}
                {!uncloseable && <Close
                  href="#"
                  onClick={event => this.handleClose(event, { direct: true })}
                  role="button"
                  aria-label="Close"
                >
                  <Icon name="cross" size="24" />
                </Close>}
              </Header>
              <Content
                flushContent={flushContent}
              >
                {children}
              </Content>
              {(primaryAction || secondaryAction) && (
                <Footer>
                  {secondaryAction && secondaryAction}
                  {typeof primaryAction === 'string' ? (
                    <Button
                      primary
                      onClick={this.handleClose}
                      label={primaryAction}
                    />
                  ) : (
                    primaryAction
                  )}
                </Footer>
              )}
            </Wrapper>
          </Card>
        </Container>}
      </>
    )
  }
}

export default withRouter(Modal)
