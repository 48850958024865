import React, { ComponentProps, Fragment, ReactNode, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import Bucket from 'components/UIKit/Bucket'
import Flag from 'components/UIKit/Flag'
import Image from 'components/UIKit/Image'
import VariantText from 'components/UIKit/VariantText'

import {
  ActionButtonContainer,
  ActionAnchorContainer,
  ActionLinkContainer,
} from './styled/Action'
import Container from './styled/Container'
import Icon from './styled/Icon'
import { ButtonContainer, AnchorContainer, LinkContainer } from './styled/Link'
import SubMenuIcon from './styled/SubMenuIcon'
import { Variant } from '~/config/theme'
import { Link } from 'react-router-dom'

const renderIcon = (icon: ComponentProps<typeof Icon>) => <Icon size="12" {...icon} />

type ActionProps = ComponentProps<typeof ActionButtonContainer> | ComponentProps<typeof ActionLinkContainer> | ComponentProps<typeof ActionAnchorContainer>

const renderAction = (action: ActionProps, index: number) => {
  if (!(action.to || action.onClick || action.href || action.component))
    return renderIcon({ key: index, ...action.icon })

  const Link: any =
    action.to ? ActionLinkContainer :
    action.href ? ActionAnchorContainer :
    ActionButtonContainer

  if (action.component) {
    return <Fragment key={index}>{action.component}</Fragment>
  }

  return (
    <Link key={index} {...action}>
      {action.icon && renderIcon(action.icon)}
      {action.title}
    </Link>
  )
}

type ContentProps = {
  icon?: ComponentProps<typeof Icon>
  image?: unknown
  title?: ReactNode
  description?: ReactNode
  variant: Variant
  hasSubMenu?: boolean
  position?: unknown
}

const renderContent = (props: ContentProps) => {
  const {
    icon,
    image,
    title,
    description,
    variant,
    hasSubMenu,
    position,
  } = props
  const hasImage = image !== undefined
  const hasIcon = icon !== undefined

  const subMenuIcon = (
    <SubMenuIcon
      name={position === 'bottom' ? 'arrowDown' : 'arrowRight'}
      size="12"
      variant={variant}
    />
  )

  if (hasImage) {
    return (
      <>
        <Bucket centered media={<Image {...image} />} content={title} />
        {hasSubMenu && subMenuIcon}
      </>
    )
  }

  if (hasIcon) {
    return (
      <Flag media={<Icon {...icon} />} flushMedia vCenter>
        {title}
        {hasSubMenu && subMenuIcon}
        {description && <VariantText size="xs">{description}</VariantText>}
      </Flag>
    )
  }

  return (
    <>
      {title}
      {hasSubMenu && subMenuIcon}
    </>
  )
}

type PopoverItemProps = {
  to?: unknown
  href?: unknown
  onClick: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
  actions?: ActionProps[]
  adjustForTouch?: boolean
  successIcon?: ComponentProps<typeof Icon>
} & ContentProps & (ComponentProps<typeof Container>)

const PopoverItem = ({
  variant,
  to,
  href,
  onClick,
  icon,
  image,
  title,
  description,
  actions,
  hasSubMenu,
  adjustForTouch,
  successIcon,
  position,
  ...props
}: PopoverItemProps) => {
  const [clicked, setClicked] = useState(false)

  const successIconCallback = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    onClick(event)
    setClicked(true)
  }

  const handleClick = successIcon ? successIconCallback : onClick

  const Link: any =
    to ? LinkContainer :
    href ? AnchorContainer :
    ButtonContainer

  const showSuccessIcon = successIcon && clicked
  return (
    <Container variant={variant} adjustForTouch={adjustForTouch} {...props}>
      <Link
        to={to}
        href={href}
        onClick={handleClick}
        data-variant={variant}
        aria-label={title}
        data-testid={title}
        {...props}
      >
        {renderContent({
          icon,
          image,
          title,
          description,
          variant,
          hasSubMenu,
          position,
        })}
      </Link>
      {!showSuccessIcon && actions && actions.map(renderAction)}
      {showSuccessIcon && renderIcon(successIcon)}
    </Container>
  )
}

export default PopoverItem
