import Util from './util'
import Units from './units'
import store from 'store'
import { isLayerVisible } from 'store/layers'
import { updateElevationPoint } from 'store/objects'
import { objectIsSelected } from 'store/selectedObjects/selectors'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import { getThreeHexFromTheme } from 'lib/utils'
import * as THREE from 'three'
import CLICK_PRIORITY from 'config/clickPriority'

const COLOR = getThreeHexFromTheme('three.valid')
const SELECTED_COLOR = getThreeHexFromTheme('three.objects.elevation.selected')
const ERROR_COLOR = getThreeHexFromTheme('three.invalid')
const SELECTED_ERROR_COLOR = getThreeHexFromTheme('three.invalidSelected')
const ELEVATION_POINT_INDICATOR_RADIUS = 0.4
const ELEVATION_POINT_RADIUS = 1
const ELEVATION_POINT_SELECTED_RADIUS = 5

class ElevationPoint {
  constructor(model) {
    this.id = model.id
    this.className = CLASS_NAMES.ELEVATION_POINT
    this.layerKey = LAYER_KEYS.ROOFS
    this.clickPriority = CLICK_PRIORITY[this.layerKey]
    this.position = new THREE.Vector3(
      model.position.x,
      model.position.y,
      model.position.z
    )
    this.roofId = model.roofId
    this.wallSegmentId = model.wallSegmentId
    this.wallId = model.wallId
    this.selectable = true
    this.draggable = true
    this.multiDraggable = true
    this.isSelected = false

    this.createMesh()

    if (objectIsSelected(this.id)) {
      this.select()
    }
  }

  select() {
    this.isSelected = true
    this.indicator.material.color.setHex(
      this.isInRoofBounds() ? SELECTED_COLOR : SELECTED_ERROR_COLOR
    )
    this.indicator.scale.set(
      (this.indicator.scale.x = ELEVATION_POINT_SELECTED_RADIUS),
      (this.indicator.scale.y = ELEVATION_POINT_SELECTED_RADIUS),
      (this.indicator.scale.z = ELEVATION_POINT_SELECTED_RADIUS)
    )
  }

  deselect() {
    this.isSelected = false
    this.indicator.material.color.setHex(
      this.isInRoofBounds() ? COLOR : ERROR_COLOR
    )
  }

  mouseEntered() {
    if (!this.isSelected) {
      this.indicator.scale.set(
        (this.indicator.scale.x = ELEVATION_POINT_SELECTED_RADIUS),
        (this.indicator.scale.y = ELEVATION_POINT_SELECTED_RADIUS),
        (this.indicator.scale.z = ELEVATION_POINT_SELECTED_RADIUS)
      )
    }
  }

  mouseExited() {
    if (!this.isSelected) {
      this.indicator.scale.set(
        (this.indicator.scale.x = ELEVATION_POINT_RADIUS),
        (this.indicator.scale.y = ELEVATION_POINT_RADIUS),
        (this.indicator.scale.z = ELEVATION_POINT_RADIUS)
      )
    }
  }

  createMesh() {
    const geometry = new THREE.SphereGeometry(
      Units.feetToNative(ELEVATION_POINT_RADIUS),
      10,
      10
    )
    const material = new THREE.MeshBasicMaterial({
      color: COLOR,
      opacity: 1,
      transparent: true,
      depthTest: true,
    })
    const sphere = new THREE.Mesh(geometry, material)
    sphere.position.copy(this.position)
    sphere.wrapperId = this.id

    this.obj3d = sphere

    const indicatorGeometry = new THREE.SphereGeometry(
      Units.feetToNative(ELEVATION_POINT_INDICATOR_RADIUS),
      10,
      10
    )
    const indicatorMaterial = new THREE.MeshBasicMaterial({
      color: this.isInRoofBounds() ? COLOR : ERROR_COLOR,
    })
    const indicatorSphere = new THREE.Mesh(indicatorGeometry, indicatorMaterial)
    indicatorSphere.position.set(0, 0, 0)

    this.indicator = indicatorSphere
    this.obj3d.add(this.indicator)
  }

  isInRoofBounds() {
    const roofModel = store.getState().objects.present.roofs[this.roofId]
    const perimeterPoints = roofModel.perimeterPoints
    const inchesPos = Units.nativeToUnitsV('inches', this.position)
    const position = { x: inchesPos.x, y: inchesPos.y }

    const pointWithinRoof = Util.isPointInPolygon(position, perimeterPoints)

    const pointOnRoofBorder = Util.isPointOnPolygonPerimeter(
      position,
      perimeterPoints,
      Units.inchesToNative(0.1)
    )

    return pointWithinRoof || pointOnRoofBorder
  }

  visibilityChanged(visible) {
    if (this.obj3d)
      this.obj3d.visible =
        isLayerVisible(store.getState(), LAYER_KEYS.ELEVATION_POINT) && visible
  }

  toModel() {
    return {
      id: this.id,
      position: this.position,
      roofId: this.roofId,
      wallSegmentId: this.wallSegmentId,
      wallId: this.wallId,
      layerKey: this.layerKey,
    }
  }

  static createModel(position, roofId, height, wallSegmentId, wallId) {
    return {
      id: Util.guid(),
      position,
      roofId,
      wallSegmentId,
      wallId,
      layerKey: LAYER_KEYS.ROOFS,
    }
  }

  drag({ dragDelta }) {
    this.obj3d.position.add(dragDelta)
    this.position.add(dragDelta)

    // We update this on drag since the error state may
    // change on drag
    this.indicator.material.color.setHex(
      this.isInRoofBounds() ? SELECTED_COLOR : SELECTED_ERROR_COLOR
    )

    if (this.parentLine) {
      this.parentLine.recreateMesh()
    }
  }

  drop(_, __, saveModel = true) {
    if (saveModel) {
      store.dispatch(updateElevationPoint({ elevationPoint: this.toModel() }))
    }
  }

  getSnappableEdgePoints() {
    return []
  }
}

export default ElevationPoint
