import styled from 'styled-components'

const ProgressBarWrapper = styled.div`
  flex-direction: column;
  height: 3.5%;
  justify-content: center;
  text-align: center;
  background-color: #e0e0de;
  border-radius: 50px;
  width: 50%;
  margin-top: ${props => props.theme.spacing.xs};
`

export default ProgressBarWrapper
