import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextField from './styled/TextField'

class ZoomInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.number,
    onSubmit: PropTypes.func,
    onZoomIn: PropTypes.func,
    onZoomOut: PropTypes.func,
  }
  constructor(props) {
    super(props)

    this.state = {
      realValue: props.value,
      userValue: parseInt(props.value * 100, 10),
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      realValue: nextProps.value,
      userValue: parseInt(nextProps.value * 100, 10),
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.onSubmit({ zoomLevel: this.state.realValue })
  }

  handleFocus = event => {
    event.target.select()
  }

  handleChange = event => {
    let { value } = event.target
    value = value.replace('%', '')
    if (isNaN(value)) {
      value = 0
    }

    const realValue = parseInt(value, 10) / 100
    const userValue = parseInt(value, 10)

    this.setState({
      realValue,
      userValue,
    })
  }

  handleKeyDown = event => {
    if (event.keyCode === 38) {
      this.props.onZoomIn()
      event.preventDefault()
    }

    if (event.keyCode === 40) {
      this.props.onZoomOut()
      event.preventDefault()
    }

    // Don't activate panning
    if (event.keyCode === 37 || event.keyCode === 39) {
      event.stopPropagation()
    }
  }

  render() {
    const { disabled } = this.props

    return (
      <form action="#" onSubmit={this.handleSubmit} className="Zoom-form">
        <TextField
          variant="dark"
          width="60px"
          value={`${this.state.userValue}%`}
          overrideValue={`${this.state.userValue}%`}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          disabled={disabled}
        />
      </form>
    )
  }
}

export default ZoomInput
