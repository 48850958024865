import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from '@apollo/client/react/hoc'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import get from 'lodash-es/get'
import capitalize from 'lodash-es/capitalize'
import isEmpty from 'lodash-es/isEmpty'
import { withProps } from 'recompact'

import routes from 'config/routes'
import { primaryUses, primaryTypes } from 'config/facility'
import { onCreateFacility } from 'config/analytics'
import { trackEvent } from 'lib/analytics'

import { GET_QUOTE_QUERY } from 'client/queries'
import { resetFacility } from 'store/objects'
import { resetPanels } from 'store/panel'
import { SYSTEMS } from 'store/units/constants'
import { Temperature } from 'store/units/types'
import { updateCFD } from 'store/cfd/actions'
import { toggleLayerLocked } from 'store/layers'
import LAYER_KEYS from 'config/layerKeys'

import Geolocator from 'lib/geolocator'

import A from 'components/UIKit/A'
import Alert from 'components/UIKit/Alert'
import Button from 'components/UIKit/Button'
import Collapsible from 'components/UIKit/Collapsible'
import Loader from 'components/UIKit/Loader'
import Modal from 'components/UIKit/Modal'
import Select from 'components/UIKit/Select'
import Space from 'components/UIKit/Space'
import TemperatureInput from 'components/UIKit/TemperatureInput'
import TextField from 'components/UIKit/TextField'
import CheckBox from 'components/UIKit/Checkbox'
import Grid, { GridItem } from 'components/UIKit/Grid'

import OpportunitySelect from '../OpportunitySelect'
import OpportunitiesList from '../OpportunitiesList'
import RequiredPermission, { hasPermission } from '../RequiredPermission'

import FacilityFormEditNotAllowed from './FacilityFormEditNotAllowed'
import { facilityData } from 'config/comfortPointFacilityData'
class FacilityForm extends Component {
  screens = ['DEFAULT', 'OPPORTUNITY']
  state = {
    // Fields
    airConditioned: false,
    ashraeCompliant: false,
    indoorHumidity: 50,
    indoorWinterHumidity: 5,
    indoorSummerTemp: new Temperature({
      value: 74,
      system: SYSTEMS.IMPERIAL,
    }),
    indoorWinterTemp: new Temperature({
      value: 45,
      system: SYSTEMS.IMPERIAL,
    }),
    location: null,
    name: null,
    opportunityId: null,
    opportunityName: null,
    primaryObjective: ['COOLING', 'DESTRATIFICATION', 'HEATING'],
    primaryType: null,
    primaryUse: null,
    units: SYSTEMS.IMPERIAL,
    // Extra data
    error: null,
    initialDataLoaded: false,
    isOpportunityCleared: false,
    locating: false,
    saving: false,
    screen: 'DEFAULT',
    locked: null,
  }

  static defaultProps = {
    NotAllowedComponent() {
      return (
        <p>Sorry. You do not have the permissions required to view this.</p>
      )
    },
  }

  static propTypes = {
    buttonText: PropTypes.string,
    updateCFD: PropTypes.func,
    facilityData: PropTypes.object,
    history: PropTypes.shape({
      push: PropTypes.func,
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
    isNew: PropTypes.bool,
    isTouchUI: PropTypes.bool,
    loading: PropTypes.bool,
    match: PropTypes.object,
    NotAllowedComponent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
    onSubmit: PropTypes.func,
    opportunityId: PropTypes.string,
    opportunityLoading: PropTypes.bool,
    parentRoute: PropTypes.string,
    resetFacility: PropTypes.func,
    resetPanels: PropTypes.func,
    selectedOpportunity: PropTypes.string,
    showNotAllowed: PropTypes.bool,
    title: PropTypes.string,
    userId: PropTypes.string,
    locked: PropTypes.bool,
    toggleLayerLocked: PropTypes.func,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.initialDataLoaded) return

    if (this.isEditScreen) {
      if (get(nextProps, 'facilityData.facility')) {
        const { facility } = nextProps.facilityData

        this.setState({
          name: facility.name,
          ashraeCompliant: facility.ashraeCompliant,
          airConditioned: facility.airConditioned,
          primaryUse: facility.primaryUse,
          primaryType: facility.primaryType,
          primaryObjective: facility.primaryObjective,
          location: facility.location,
          indoorSummerTemp: new Temperature({
            value: get(facility, 'indoorSummerTemp'),
            system: SYSTEMS.IMPERIAL,
          }),
          indoorWinterTemp: new Temperature({
            value: get(facility, 'indoorWinterTemp'),
            system: SYSTEMS.IMPERIAL,
          }),
          indoorHumidity: facility.indoorHumidity,
          indoorWinterHumidity: facility.indoorWinterHumidity,
          opportunityId: nextProps.opportunityId,
          units: facility.units,
          locked: nextProps.locked,

          // The data has been loaded...
          initialDataLoaded: true,
        })
      }
    }
  }

  get isEditScreen() {
    return this.props.title === 'Edit Facility'
  }

  get type() {
    return this.isEditScreen ? 'edit' : 'new'
  }

  handleFieldChanged = event => {
    const { name, value } = event.target
    if (name === 'primaryUse') {
      const {
        winterAirTemp,
        indoorWinterHumidity,
        airTemp,
        humidity,
      } = facilityData.find(f => f.type === value)
      this.setState({
        [name]: value,
        indoorSummerTemp: new Temperature({
          value: airTemp,
          system: SYSTEMS.METRIC,
        }),
        indoorHumidity: humidity,
        indoorWinterTemp: new Temperature({
          value: winterAirTemp,
          system: SYSTEMS.METRIC,
        }),
        indoorWinterHumidity,
      })
    }

    this.setState({
      [name]: value,
    })
  }

  handleAshraeChanged = () => {
    this.setState({
      ashraeCompliant: !this.state.ashraeCompliant,
    })
  }

  handleAirConditionedChanged = () => {
    this.setState({
      airConditioned: !this.stateairConditioned,
    })
  }

  handlePrimaryObjectiveChanged = event => {
    const primaryObjectiveArray = this.state.primaryObjective
    const primaryObjectiveArrayCopy = [...primaryObjectiveArray]
    const value = event.target.value

    if (event.target.checked) {
      primaryObjectiveArrayCopy.push(value)
    } else {
      const index = primaryObjectiveArrayCopy.indexOf(value)
      primaryObjectiveArrayCopy.splice(index, 1)
    }

    this.setState({
      primaryObjective: primaryObjectiveArrayCopy,
    })
  }

  handleLocationClick = async event => {
    event.preventDefault()

    this.setState({
      locating: true,
    })

    const location = await Geolocator.getLocation()

    this.setState({
      location,
      locating: false,
    })
  }

  handleOpportunityCleared = event => {
    this.setState({
      isOpportunityCleared: true,
      screen: 'DEFAULT',
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      saving: true,
    })

    if (!this.state.name) {
      this.setState({
        error: 'Please enter a name!',
        saving: false,
      })
      return
    }

    if (!this.state.primaryUse) {
      this.setState({
        error: 'Please select a Primary Use!',
        saving: false,
      })
      return
    }

    if (this.state.primaryUse === 'OTHER' && !this.state.primaryType) {
      this.setState({
        error: 'Please select a Primary Type!',
        saving: false,
      })
      return
    }

    const id = this.isEditScreen
      ? { id: this.props.facilityData.facility.id }
      : { userId: this.props.userId }

    let shouldInvalidateCFD = false
    if (this.state.units !== get(this.props, 'facilityData.facility.units')) {
      shouldInvalidateCFD = true
    }

    // TODO: This app logic should not be in the form
    this.props
      .onSubmit({
        ...id,
        name: this.state.name,
        primaryObjective: this.state.primaryObjective,
        primaryUse: this.state.primaryUse,
        primaryType: this.state.primaryType,
        ashraeCompliant: this.state.ashraeCompliant,
        airConditioned: this.state.airConditioned,
        location: this.state.location,
        indoorSummerTemp: Math.round(this.state.indoorSummerTemp.imperial()),
        indoorWinterTemp: Math.round(this.state.indoorWinterTemp.imperial()),
        indoorHumidity: parseFloat(this.state.indoorHumidity),
        indoorWinterHumidity: parseFloat(this.state.indoorWinterHumidity),
        opportunityId: this.props.opportunityId,
        locked: this.props.locked,
        units: this.state.units,
      })
      .then(res => {
        if (shouldInvalidateCFD) {
          this.props.updateCFD({ isValid: false, validTypes: [] })
        }

        const pathname = this.props.history.location.pathname
        let nextUrl = pathname.substring(0, pathname.indexOf('/edit-facility'))

        // We only want to reset state when creating a new facility.
        if (!this.isEditScreen) {
          const newFacilityId = res.data.createFacility.id
          // Skip the onboarding flow if using Touch UI
          nextUrl = this.props.isTouchUI
            ? `/facility/${newFacilityId}`
            : `/new-facility/${newFacilityId}${routes.onboarding['1'].short}`
          this.props.resetFacility()
          this.props.resetPanels()
          trackEvent(onCreateFacility())
        }

        this.props.history.push(nextUrl)
      })
      .catch(error => {
        this.setState({
          error:
            get(error, 'graphQLErrors.0.message') || 'An error has occurred',
          saving: false,
        })
      })
  }

  renderContent() {
    const { opportunityLoading } = this.props
    const unitTypes = [SYSTEMS.IMPERIAL, SYSTEMS.METRIC].map(system => ({
      value: system,
      label: capitalize(system),
    }))

    if (this.state.screen === 'DEFAULT') {
      return (
        <>
          {this.state.error && !this.props.isTouchUI && (
            <Space bottom="base">
              <Alert type="error" text={this.state.error} />
            </Space>
          )}
          <Space bottom="base">
            <TextField
              name="name"
              label="Name"
              value={this.state.name}
              onChange={this.handleFieldChanged}
            />
          </Space>
          <Space bottom="base">
            <Select
              name="primaryUse"
              label="Primary Use"
              onChange={this.handleFieldChanged}
              value={this.state.primaryUse || ''}
              options={primaryUses}
              data-testid="primary-use-select"
              disablePlaceholder={false}
            />
            {this.state.primaryUse === 'OTHER' && (
              <Space top="s">
                <Select
                  name="primaryType"
                  label="Primary Type"
                  onChange={this.handleFieldChanged}
                  value={this.state.primaryType || ''}
                  options={primaryTypes}
                />
              </Space>
            )}
          </Space>
          <Space bottom="base">
            <Select
              name="units"
              label="Units"
              onChange={this.handleFieldChanged}
              value={this.state.units || ''}
              options={unitTypes}
            />
          </Space>
          <RequiredPermission name="Select Opportunity">
            <Space bottom="base">
              {opportunityLoading ? (
                <Loader />
              ) : (
                <OpportunitySelect
                  truncate="410px"
                  type={this.type}
                  selectedOpportunity={
                    this.state.isOpportunityCleared
                      ? ''
                      : this.props.selectedOpportunity
                  }
                  // If this is the new facility form
                  // *OR* there is already a quote on this facility
                  canClear={
                    this.props.isNew ||
                    get(
                      this.props.facilityData,
                      'facility.floor.version.quote.salesforceQuoteId'
                    ) === null
                  }
                  onClick={event => {
                    event.preventDefault()
                    this.setState({
                      screen: 'OPPORTUNITY',
                    })
                  }}
                  onClear={this.handleOpportunityCleared}
                />
              )}
            </Space>
          </RequiredPermission>

          <Space bottom="base">
            <Collapsible trigger="Advanced Climate Settings">
              <Grid>
                <GridItem size="1of2">
                  <Space bottom="base">
                    <TemperatureInput
                      label="Indoor Summer Temp"
                      name="indoorSummerTemp"
                      degrees={this.state.indoorSummerTemp}
                      units={this.state.units}
                      showFormattedUnits={true}
                      onChange={({ degrees }) => {
                        this.setState({
                          indoorSummerTemp: degrees,
                        })
                      }}
                      width="140px"
                    />
                  </Space>
                </GridItem>
                <GridItem size="1of2">
                  <Space bottom="base">
                    <TemperatureInput
                      label="Indoor Winter Temp"
                      name="indoorWinterTemp"
                      degrees={this.state.indoorWinterTemp}
                      units={this.state.units}
                      showFormattedUnits={true}
                      onChange={({ degrees }) => {
                        this.setState({
                          indoorWinterTemp: degrees,
                        })
                      }}
                      width="140px"
                    />
                  </Space>
                </GridItem>
                <GridItem size="1of2">
                  <Space bottom="base">
                    <TextField
                      name="indoorHumidity"
                      label="Summer Humidity (%)"
                      onChange={this.handleFieldChanged}
                      value={this.state.indoorHumidity}
                      type="number"
                      width="140px"
                    />
                  </Space>
                </GridItem>
                <GridItem size="1of2">
                  <Space bottom="base">
                    <TextField
                      name="indoorWinterHumidity"
                      label="Winter Humidity (%)"
                      onChange={this.handleFieldChanged}
                      value={this.state.indoorWinterHumidity}
                      type="number"
                      width="140px"
                    />
                  </Space>
                </GridItem>
              </Grid>
            </Collapsible>
            {this.state.error && this.props.isTouchUI && (
              <Space bottom="base">
                <Alert type="error" text={this.state.error} />
              </Space>
            )}
          </Space>
          {this.props.title !== 'New Facility' && (
            <Space>
              <CheckBox
                label="Allow others to edit this facility"
                checked={!this.state.locked}
                onChange={e => {
                  this.setState({ locked: !this.state.locked })
                  this.props.toggleLayerLocked({
                    layerKey: LAYER_KEYS.FACILITY,
                  })
                }}
              />
            </Space>
          )}
        </>
      )
    }

    if (this.state.screen === 'OPPORTUNITY') {
      return (
        <OpportunitiesList
          onSelect={event => {
            this.setState({
              screen: 'DEFAULT',
            })
          }}
        />
      )
    }
  }

  render() {
    const { loading, showNotAllowed, NotAllowedComponent } = this.props
    const showLoader = loading
    const showNotAllowedComponent = !loading && showNotAllowed
    const showContent = !loading && !showNotAllowed

    const backLink = event => {
      if (this.state.screen !== 'DEFAULT') {
        return (
          <A
            href="#0"
            onClick={event => {
              event.preventDefault()
              this.setState({
                screen: 'DEFAULT',
              })
            }}
          >
            &larr; Back
          </A>
        )
      }

      return null
    }

    return (
      <Modal
        {...this.props}
        title={this.props.title}
        backLink={backLink()}
        onSubmit={this.handleSubmit}
        primaryAction={
          !this.props.showNotAllowed && (
            <Button
              primary
              onClick={this.handleSubmit}
              disabled={this.state.saving || this.state.locating}
              label={this.state.saving ? 'Saving...' : this.props.buttonText}
            />
          )
        }
        secondaryAction={
          !this.props.showNotAllowed && (
            <Button to={this.props.parentRoute} label="Cancel" />
          )
        }
        size="500px"
        {...this.props}
      >
        {showLoader && <Loader />}
        {showNotAllowedComponent && <NotAllowedComponent />}
        {showContent && this.renderContent()}
      </Modal>
    )
  }
}

const mapStateToProps = ({ quote, userInterface, layers }, ownProps) => ({
  selectedOpportunity:
    get(quote, 'opportunity.name') || ownProps.selectedOpportunity,
  opportunityId: get(quote, 'opportunity.id') || null,
  isTouchUI: userInterface.isTouchUI,
  locked: layers.layers['FACILITY'].locked,
})

const mapDispatchToProps = {
  resetFacility,
  resetPanels,
  updateCFD,
  toggleLayerLocked,
}

export default compose(
  hasPermission({
    name: 'Create Facility',
    withPermissions: true,
  }),
  withProps(props => ({
    showNotAllowed:
      props.showNotAllowed || (props.isNew && !props.permissions.isAllowed),
    loading: props.loading || props.permissions.isLoading,
  })),
  hasPermission({
    name: 'Edit Facility',
    withPermissions: true,
  }),
  withProps(props => ({
    showNotAllowed:
      props.showNotAllowed || (!props.isNew && !props.permissions.isAllowed),
    loading: props.loading || props.permissions.isLoading,
    NotAllowedComponent: !props.isNew
      ? FacilityFormEditNotAllowed
      : props.NotAllowedComponent,
  })),
  graphql(GET_QUOTE_QUERY, {
    options: ({ facilityData }) => ({
      variables: {
        id: get(facilityData, 'facility.floor.version.id'),
      },
      fetchPolicy: 'network-only',
    }),
    props: ({ data }) => ({
      selectedOpportunity: get(data, 'Version.quote.opportunity.name') || '',
      opportunityLoading: data.loading,
    }),
    skip: ({ facilityData }) =>
      isEmpty(get(facilityData, 'facility.floor.version.id')),
  }),
  withProps(props => ({
    loading: get(props, 'facilityData.loading') || props.loading,
  })),
  appConnect(mapStateToProps, mapDispatchToProps)
)(FacilityForm)
