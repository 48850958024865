import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { appConnect, useAppSelector, useAppDispatch } from "~/store/hooks";
import { graphql } from '@apollo/client/react/hoc'

import routes from 'config/routes'

import { updateAirflow } from 'lib/airflow/airflow'
// import { updateHeatMap } from 'lib/heatMap/heatMap'
import { currentUserIsCustomer } from 'lib/currentUserIs'
import RequiredPermission from 'components/RequiredPermission'

import { showAlert } from 'store/alert'
import { clearStatus, setStatusOn } from 'store/status'
import {
  AIRFLOW_PANEL,
  CFD_PANEL,
  PERFORMANCE_METRICS_PANEL,
  // HEAT_MAP_PANEL,
} from 'store/panel/types'
import { togglePanel } from 'store/panel'
import { toggleLayerVisibility } from 'store/layers'
import { setLoadingStatus } from 'store/status'
import withUser from 'client/decorators/withUser'
import withFacility from 'client/decorators/withFacility'
import { handleLogUserAction } from 'client/handlers'
import { LOG_USER_ACTION_MUTATION } from 'client/mutations'

import Button from 'components/UIKit/Button'
import Switch from 'components/UIKit/Switch'
import { StatusBadge } from 'components/Panels/CFDPanel/subcomponents/StatusBadge'
import { ToolbarGroup, ToolbarItem } from 'components/UIKit/Toolbar'
import { CFDImagesModal } from 'components/Modals/CFDImagesModal'
import CFDHelpModal from 'components/Modals/CFDHelpModal'
import ConfigureCFDModal from 'components/Modals/ConfigureCFDModal'
import InstallationReviewModal from 'components/Modals/InstallationReviewModal'
import { withUnits } from 'store/units/decorators'
import LAYER_KEYS from 'config/layerKeys'
// import { checkNotificationGranted } from 'lib/utils'
import { useCFDStatus } from 'hooks/useCFDStatus'
import { CFD_STATUS } from 'config/cfd'
import { useCFDUploadsContext } from '~/hooks/useCFDUploadsContext'
import { useCFDResultChecksum } from '~/hooks/useCFDResultChecksum'
import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

const CFDStatusBadge = () => {
  // Provides cfd status and checksum data for subsequent fragments
  const { checksumResults } = useCFDResultChecksum()
  const { cfdStatus } = useCFDStatus()
  const { uploads, loading } = useCFDUploadsContext()

  if (!cfdStatus || loading || !checksumResults) return <></>

  const { isSomeCanceled, isSomeFailed, isSomeInProgress, isAllComplete } = cfdStatus
  const isSomeInvalidCfd = checksumResults.some(result => {
    if (!result) return false
    const { internalType, resultChecksum } = result
    const modelChecksum = uploads?.[internalType]?.checksum
    return !!modelChecksum && modelChecksum !== resultChecksum
  })
  const inProgress = isSomeInProgress && CFD_STATUS.IN_PROGRESS
  const failed = (isSomeInvalidCfd || isSomeCanceled || isSomeFailed) && CFD_STATUS.FAILED
  const complete = isAllComplete && CFD_STATUS.COMPLETE
  const status = inProgress || failed || complete

  return status ? (
    <div style={{ position: 'absolute', right: 5 }}>
      <StatusBadge status={status} />
    </div>
  ) : (
    <></>
  )
}

const CFDSwitch = () => {
  const { cfdStatus } = useCFDStatus()
  const cfdVisible = useAppSelector(state => state.layers.layers.CFD.visible)
  const dispatch = useAppDispatch()

  if (!cfdStatus?.isAllComplete) return <></>

  return (
    <Switch
      name="cfd"
      isChecked={cfdVisible}
      onClick={() => dispatch(toggleLayerVisibility({ layerKey: LAYER_KEYS.CFD }))}
    />
  )
}

class BottomRight extends Component {
  async componentDidUpdate(prevProps) {
    // TODO: Try to show a desktop notification when CFD finishes
    // const approvedNotifications = checkNotificationGranted(true)
    // if (approvedNotifications) {
    //   const notify = new Notification('CFD Complete', {
    //     body: 'Your CFD data is now available.',
    //     tag: 'FLT_CFD', // FIXME: rename flt to speclab
    //     renotify: true,
    //     icon: '/android-chrome-192x192.png',
    //   })
    //   notify.onclick = function() {
    //     window.focus()
    //     notify.close()
    //   }
    // }
    // this.props.showAlert({
    //   text: 'Your CFD data is now available',
    //   type: 'success',
    //   autoClose: false,
    //   action: {
    //     text: 'View',
    //     onClick: (event, hideAlert) => {
    //       this.props.history.push(
    //         `${this.props.match.url}${routes.modals.cfdImages}`
    //       )
    //       hideAlert()
    //     },
    //   },
    // })
    // if (cfdFailed) {
    //   if (approvedNotifications) {
    //     const notify = new Notification('CFD Failed!', {
    //       body: 'There was an error generating your CFD data.',
    //       tag: 'FLT_CFD', // FIXME: rename flt to speclab
    //       renotify: true,
    //       icon: '/android-chrome-192x192.png',
    //     })
    //     notify.onclick = function() {
    //       window.focus()
    //       notify.close()
    //     }
    //   }
    //   this.props.showAlert({
    //     text: 'There was an error generating your CFD data',
    //     type: 'error',
    //     autoClose: false,
    //   })
    // }
  }

  render() {
    const {
      airflowValid,
      layers,
      loadingCfdStatus,
      match,
      objects,
      obstructions,
      onClearStatus,
      onSetStatus,
      onTogglePanel,
      onToggleVisibility,
      onUpdateAirflow,
      // onUpdateHeatMap,
      products,
      segments,
      type,
      user,
    } = this.props

    return (
      <ToolbarGroup flexGrow="0" alignment="right">
        <RequiredPermission name="View Performance Metrics">
          <ToolbarItem
            responsive
            separator="left"
            role="presentation"
            onClick={() => onTogglePanel(PERFORMANCE_METRICS_PANEL)}
            active={type === PERFORMANCE_METRICS_PANEL}
          >
            <Button
              dropdown
              noBorder
              responsive
              dropdownReverse
              isDropdownVisible={type === PERFORMANCE_METRICS_PANEL}
              icon="chartBar"
              label="Performance"
            />
          </ToolbarItem>
        </RequiredPermission>
        {/* {!currentUserIsCustomer(user) && (
          <ToolbarItem
            separator="left"
            paddingLeft="base"
            paddingRight="base"
            active={type === HEAT_MAP_PANEL}
            onMouseEnter={() =>
              onSetStatus({ action: 'hover', show: 'heatmap' })
            }
            onMouseLeave={() => onClearStatus({ type: 'info' })}
          >
            <Switch
              name="heatmap"
              isChecked={layers.HEAT_MAP.visible}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                if (!layers.HEAT_MAP.visible) {
                  onUpdateHeatMap({
                    products,
                    objects,
                    segments,
                    obstructions,
                  });
                }
                onToggleVisibility({ layerKey: LAYER_KEYS.HEAT_MAP });
              }}
            />
            <Button
              dropdown
              noBorder
              dropdownReverse
              label="Heat Map"
              isDropdownVisible={type === HEAT_MAP_PANEL}
              onClick={() => onTogglePanel(HEAT_MAP_PANEL)}
            />
          </ToolbarItem>
        )} */}
        {!currentUserIsCustomer(user) && (
          <ToolbarItem
            separator="left"
            paddingLeft="base"
            paddingRight="base"
            active={type === AIRFLOW_PANEL}
            onMouseEnter={() => onSetStatus({ action: 'hover', show: 'airflow' })}
            onMouseLeave={() => onClearStatus({ type: 'info' })}
          >
            <Switch
              name="airflow"
              isChecked={layers.AIRFLOW.visible}
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()

                if (!layers.AIRFLOW.visible && !airflowValid) {
                  this.props.logUserAction({ action: 'airflow' })
                  onUpdateAirflow({
                    products,
                    objects,
                    segments,
                    obstructions,
                  })
                }
                onToggleVisibility({ layerKey: LAYER_KEYS.AIRFLOW })
              }}
            />
            <Button
              dropdown
              noBorder
              dropdownReverse
              label="Airflow"
              isDropdownVisible={type === AIRFLOW_PANEL}
              onClick={() => onTogglePanel(AIRFLOW_PANEL)}
            />
          </ToolbarItem>
        )}
        <ToolbarItem
          separator="left"
          paddingLeft="base"
          paddingRight="base"
          active={type === CFD_PANEL}
        >
          <CFDSwitch />
          <Button
            dropdown
            noBorder
            dropdownReverse
            icon="chartArea"
            label="CFD Simulation"
            disabled={loadingCfdStatus}
            isDropdownVisible={type === CFD_PANEL}
            onClick={() => onTogglePanel(CFD_PANEL)}
          />
          <CFDStatusBadge />
        </ToolbarItem>
        <SentryRoute
          path={`${match.url}${routes.modals.cfdImages}`}
          render={() => <CFDImagesModal parentRoute={match.url} />}
        />
        <SentryRoute
          path={`${match.url}${routes.help.cfd}`}
          render={props => <CFDHelpModal parentRoute={match.url} />}
        />
        <SentryRoute
          path={`${match.url}${routes.modals.configureCFD}`}
          render={props => <ConfigureCFDModal parentRoute={match.url} />}
        />
        <SentryRoute
          path={`${match.url}${routes.modals.installReview}`}
          render={props => <InstallationReviewModal parentRoute={match.url} />}
        />
      </ToolbarGroup>
    )
  }
}

BottomRight.propTypes = {
  airflowValid: PropTypes.bool,
  clearStatus: PropTypes.func,
  comfortZones: PropTypes.object,
  history: PropTypes.object,
  layers: PropTypes.object,
  loadingCfdStatus: PropTypes.bool,
  match: PropTypes.object,
  objects: PropTypes.object,
  obstructions: PropTypes.object,
  onClearStatus: PropTypes.func,
  onSetStatus: PropTypes.func,
  onTogglePanel: PropTypes.func,
  onToggleVisibility: PropTypes.func,
  onUpdateAirflow: PropTypes.func,
  // onUpdateHeatMap: PropTypes.func,
  products: PropTypes.object,
  segments: PropTypes.object,
  showAlert: PropTypes.func,
  type: PropTypes.string,
  user: PropTypes.object,
  versionId: PropTypes.string,
  logUserAction: PropTypes.func,
}

const mapStateToProps = ({ layers, panel, camera, objects, cfd }) => ({
  airflowValid: objects.present.airflow.isValid,
  layers: layers.layers,
  objects: objects.present.objects,
  obstructions: objects.present.obstructions,
  products: objects.present.products,
  segments: objects.present.segments,
  type: panel.bottom.visiblePanel,
})

const mapDispatchToProps = dispatch => ({
  showAlert,
  onClearStatus({ type }) {
    dispatch(clearStatus({ type }))
  },
  onSetStatus({ action, show }) {
    dispatch(setStatusOn({ action, show }))
  },
  onTogglePanel(panel) {
    dispatch(togglePanel({ type: panel }))
  },
  onToggleVisibility({ layerKey }) {
    // Only setting the loading status for CFD since airflow is handled
    // directly within its async function.
    layerKey === LAYER_KEYS.CFD && dispatch(setLoadingStatus())
    dispatch(toggleLayerVisibility({ layerKey }))
  },
  onUpdateAirflow({ products, objects, segments, obstructions }) {
    updateAirflow(dispatch, { products, objects, segments, obstructions })
  },
  // onUpdateHeatMap({ products, objects, segments, obstructions }) {
  //   updateHeatMap(dispatch, { products, objects, segments, obstructions })
  // },
})

export default withRouter(
  compose(
    appConnect(mapStateToProps, mapDispatchToProps),
    withUnits,
    withUser,
    withFacility,
    graphql(LOG_USER_ACTION_MUTATION, {
      props: ({ mutate, ownProps }) => ({
        logUserAction: ({ action }) => handleLogUserAction({ action, mutate, ownProps }),
      }),
    })
  )(BottomRight)
)
