import styled from 'styled-components'

import { theme } from 'lib/styleUtils'
import Button from 'components/UIKit/Button'

const ToolButton = styled(Button)`
  margin-left: ${theme('spacing.base')};
  text-transform: uppercase;

  &:last-child {
    margin-right: ${theme('spacing.base')};
  }
`

ToolButton.defaultProps = {
  primary: true,
}

export default ToolButton
