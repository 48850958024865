import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Controls = styled.div`
  border-right: 1px solid ${theme('colors.light.subdued')};
  flex-shrink: 0;
  padding: ${theme('spacing.l')};
  padding-right: ${theme('spacing.l')};
`

export default Controls
