import React, { Component } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash-es/omit'
import { appConnect } from "~/store/hooks";

import { isTouchUI } from 'store/userInterface/selectors'

import PopoverMenu from './PopoverMenu'

import Container from './styled/Container'
import TriggerContainer from './styled/TriggerContainer'

export class PopoverContainer extends Component {
  state = {
    isDropdownVisible: false,
  }

  componentWillUnmount() {
    this.handleCleanup()
  }

  handleCleanup = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }

    document.removeEventListener('click', this.handleDocumentClick, true)
    document.removeEventListener('touchstart', this.handleDocumentClick, true)
  }

  handleClick = event => {
    event.preventDefault()
    const { onTriggerClick } = this.props

    if (!this.state.isDropdownVisible) {
      document.addEventListener('click', this.handleDocumentClick, true)
      if (this.props.isTouchUI)
        document.addEventListener('touchstart', this.handleDocumentClick, true)
    } else {
      this.handleCleanup()
    }

    const newIsDropdownVisible = !this.state.isDropdownVisible
    onTriggerClick && onTriggerClick(newIsDropdownVisible)
    this.setState({
      isDropdownVisible: newIsDropdownVisible,
    })
  }

  handleDocumentClick = event => {
    // If the user specifically requests *NOT* to have the document click
    // close the popover, let's just return.
    if (!this.props.closeOnDocumentClick) return

    // If the clicked item was in the popover, but was *not*
    // the trigger itself, we want to hide the dropdown and return
    if (
      this.containerNode &&
      this.containerNode.contains(event.target) &&
      this.triggerContainerNode &&
      !this.triggerContainerNode.contains(event.target)
    ) {
      // If it is a touch event, we return and wait for a
      // click event to hide the dropdown
      if (event.type === 'touchstart') return

      const timeout = this.props.isTouchUI ? 100 : 0

      this.timer = setTimeout(() => {
        this.setState({
          isDropdownVisible: false,
        })
      }, timeout)

      return
    }

    // If the clicked item *was* the trigger itself, return
    if (
      this.triggerContainerNode &&
      this.triggerContainerNode.contains(event.target)
    )
      return

    // Otherwise, we're going to hide the dropdown and then
    // remove the document click handler
    this.setState({
      isDropdownVisible: false,
    })

    this.handleCleanup()
  }

  render() {
    const { trigger, items, renderTrigger, renderMenu, ...props } = this.props
    const { isDropdownVisible } = this.state

    return (
      <Container
        {...omit(props, ['onTriggerClick'])}
        ref={node => {
          this.containerNode = node
        }}
      >
        <TriggerContainer
          onClick={this.handleClick}
          role="presentation"
          ref={node => {
            this.triggerContainerNode = node
          }}
        >
          {renderTrigger && renderTrigger({ isDropdownVisible })}
        </TriggerContainer>
        {renderMenu ? (
          renderMenu({
            isDropdownVisible,
            handleTriggerClick: this.handleClick,
          })
        ) : (
          <PopoverMenu
            isVisible={isDropdownVisible}
            loading={
              this.props.itemsLoading ? this.props.itemsLoading : undefined
            }
            items={items}
            right={props.right}
            bottom={props.bottom}
            attached={props.attached}
            action={props.action}
            variant={props.variant}
            scrollable={props.scrollable}
            height={props.height}
          />
        )}
      </Container>
    )
  }
}

PopoverContainer.propTypes = {
  action: PropTypes.any,
  attached: PropTypes.bool,
  bottom: PropTypes.bool,
  closeOnDocumentClick: PropTypes.bool,
  height: PropTypes.string,
  isTouchUI: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  itemsLoading: PropTypes.bool,
  onTriggerClick: PropTypes.func,
  renderTrigger: PropTypes.func,
  renderMenu: PropTypes.func,
  right: PropTypes.bool,
  scrollable: PropTypes.bool,
  variant: PropTypes.string,
  trigger: PropTypes.node,
}

PopoverContainer.defaultProps = {
  closeOnDocumentClick: true,
  variant: 'light',
}

const mapStateToProps = store => ({
  isTouchUI: isTouchUI(store),
})

export default /** @type any */(appConnect(mapStateToProps)(PopoverContainer));
