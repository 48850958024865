import React from 'react'
import PropTypes from 'prop-types'

import { GET_SNAPSHOTS_QUERY } from '../../queries'

import { DefaultQueryProvider } from '../'

function Snapshots(props = {}) {
  return (
    <DefaultQueryProvider
      query={GET_SNAPSHOTS_QUERY}
      variables={{ versionId: props.versionId }}
      loadingText="Loading snapshots..."
    >
      {({ data }) => {
        const { getSnapshots: snapshots } = data

        return props.children(snapshots)
      }}
    </DefaultQueryProvider>
  )
}

Snapshots.propTypes = {
  children: PropTypes.func.isRequired,
  versionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

export default Snapshots
