import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.div`
  align-items: center;
  display: flex;
  padding-left: ${theme('spacing.l')};
  padding-right: ${theme('spacing.l')};
  width: 100%;
`

export default Container
