import { graphql } from '~/gql'

const BAF_USAGE_REPORT_QUERY = graphql(`
  query BAFUsageReport($filter: ReportFilter) {
    BAFUsageReport(filter: $filter) {
      reports {
        email
        company
        yearToDate
        last30Days
      }
    }
  }
`)

export default BAF_USAGE_REPORT_QUERY
