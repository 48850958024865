import React, { Component } from 'react'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import { graphql } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'
import without from 'lodash-es/without'
import isNil from 'lodash-es/isNil'

import { hasFeature } from 'lib/hasFeature'

import { showAlert } from 'store/alert'

import withPDFFileName from 'client/decorators/withPDFFileName'
import withUser from 'client/decorators/withUser'

import { LOG_USER_ACTION_MUTATION } from 'client/mutations'
import { handleLogUserAction } from 'client/handlers'

import Breadcrumbs from 'components/UIKit/Breadcrumbs'
import Button from 'components/UIKit/Button'
import Loader from 'components/UIKit/Loader'
import Space from 'components/UIKit/Space'
import UIModal from 'components/UIKit/Modal'
import { STORAGE_KEY } from './constants'

import SelectType from './SelectType'
import Main from './Main'
import Preview from './Preview'
import Images from './Images'
import ContactInfo from './ContactInfo'
import Comments from './Comments'
import ComfortZone from './ComfortZones'
import DocumentExplanation from './DocumentExplanation'
import { withObjects } from './selectors'

import Content from './styled/Content'
import DetailedView from './DetailedView'
import TypedImages from './TypedImages'
import { COMPOSITE_SIMULATION_TYPES, SIMULATION_TYPES } from 'config/cfd'

const loadInitialValue = ({ key, defaultValue = null }) => ({
  facilityId,
  areaId,
  versionId,
}) => {
  if (!facilityId || !areaId) return defaultValue
  const localStorage = JSON.parse(
    window.localStorage.getItem(
      `${STORAGE_KEY}:${facilityId}:${areaId}:${versionId}`
    )
  )
  if (!localStorage) return defaultValue
  const localStorageValue = get(localStorage, key)
  return !isNil(localStorageValue) ? localStorageValue : defaultValue
}

const isValidZoneObject = zone => {
  const isObject = typeof zone === "object" && !!zone
  return isObject && "height" in zone && "settings" in zone && "zone" in zone && "goal" in zone && (zone.goal in SIMULATION_TYPES || zone.goal in COMPOSITE_SIMULATION_TYPES)
}

// TODO: @refactor - localStorage items should ALWAYS be validated. Validate the entire export settings schema after refactoring this component into a functional one.
const loadValidatedComfortZones = ({
  facilityId,
  areaId,
  versionId,
}) => {
  if (!facilityId || !areaId) return []
  const exportDataKey = `${STORAGE_KEY}:${facilityId}:${areaId}:${versionId}`
  const persistedExportData = JSON.parse(window.localStorage.getItem(exportDataKey))
  if (!persistedExportData) return []
  const savedComfortZones = persistedExportData.savedComfortZones ?? []
  const validatedComfortZones = savedComfortZones.filter(isValidZoneObject)
  window.localStorage.setItem(exportDataKey, JSON.stringify({...persistedExportData, savedComfortZones: validatedComfortZones}))
  return validatedComfortZones
}

const loadInitialContact = ({ key, defaultValue = null }) => ({
  facilityId,
  areaId,
}) => {
  const localStorage = JSON.parse(
    window.localStorage.getItem(`${STORAGE_KEY}:${facilityId}`)
  )
  if (!facilityId || !localStorage) return defaultValue
  const localStorageValue = get(localStorage, key)
  return !isNil(localStorageValue) ? localStorageValue : defaultValue
}

class Modal extends Component {
  state = {
    screen: 'SELECT_TYPE',
    rendering: false,
    docType: 'SOLUTION',
    exportSettingsLoaded: false,
    mainImage: 'PLAN_VIEW',
    additionalOptions: [],
    selectedMainImageSnapshot: null,
    selectedAdditionalImages: [],
    selectedCoolingImages: [],
    selectedDestratImages: [],
    selectedHeatingImages: [],
    selectedObjects: {},
    savedMainImageSnapshot: null,
    savedAdditionalImages: [],
    savedImagesPerPage: 4,
    selectedImagesPerPage: 4,
    coolingImagesPerPage: 4,
    destratImagesPerPage: 4,
    heatingImagesPerPage: 4,
    selectedDocumentExplanationImages: [],
    savedDocumentExplanationImages: [],
    savedObjects: null,
    tempComments: '',
    savedComments: '',
    savedComfortZones: [],
    tempContactInfo: {
      name: '',
      email: '',
      phone: '',
    },
    savedContactInfo: {
      name: '',
      email: '',
      phone: '',
    },
    detailedType: 'ITEMIZED',
  }

  componentDidMount() {
    this.initSavedObjects()
  }

  componentDidUpdate() {
    this.initExportSettings()
    this.initUserInfo()
    this.initSavedObjects()
  }

  initExportSettings() {
    const { loading } = this.props
    if (!loading && !this.state.exportSettingsLoaded) {
      const facilityIds = {
        facilityId: get(this, 'props.facilityData.id'),
        areaId: get(this, 'props.facilityData.floor.id'),
        versionId: get(this, 'props.facilityData.floor.version.id'),
      }
      this.setState({
        exportSettingsLoaded: true,
        mainImage: loadInitialValue({
          key: 'mainImage',
          defaultValue: 'PLAN_VIEW',
        })(facilityIds),
        additionalOptions: loadInitialValue({
          key: 'additionalOptions',
          defaultValue: [],
        })(facilityIds),
        selectedMainImageSnapshot: loadInitialValue({
          key: 'selectedMainImageSnapshot',
          defaultValue: null,
        })(facilityIds),
        selectedAdditionalImages: loadInitialValue({
          key: 'selectedAdditionalImages',
          defaultValue: [],
        })(facilityIds),
        selectedCoolingImages: loadInitialValue({
          key: 'selectedCoolingImages',
          defaultValue: [],
        })(facilityIds),
        selectedDestratImages: loadInitialValue({
          key: 'selectedDestratImages',
          defaultValue: [],
        })(facilityIds),
        selectedHeatingImages: loadInitialValue({
          key: 'selectedHeatingImages',
          defaultValue: [],
        })(facilityIds),
        selectedObjects: loadInitialValue({
          key: 'selectedObjects',
          defaultValue: {},
        })(facilityIds),
        savedMainImageSnapshot: loadInitialValue({
          key: 'savedMainImageSnapshot',
          defaultValue: null,
        })(facilityIds),
        savedAdditionalImages: loadInitialValue({
          key: 'savedAdditionalImages',
          defaultValue: [],
        })(facilityIds),
        savedImagesPerPage: loadInitialValue({
          key: 'savedImagesPerPage',
          defaultValue: 4,
        })(facilityIds),
        selectedImagesPerPage: loadInitialValue({
          key: 'selectedImagesPerPage',
          defaultValue: 4,
        })(facilityIds),
        coolingImagesPerPage: loadInitialValue({
          key: 'coolingImagesPerPage',
          defaultValue: 4,
        })(facilityIds),
        destratImagesPerPage: loadInitialValue({
          key: 'destratImagesPerPage',
          defaultValue: 4,
        })(facilityIds),
        heatingImagesPerPage: loadInitialValue({
          key: 'heatingImagesPerPage',
          defaultValue: 4,
        })(facilityIds),
        selectedDocumentExplanationImages: [],
        savedDocumentExplanationImages: [],
        savedObjects: loadInitialValue({
          key: 'savedObjects',
          defaultValue: '',
        })(facilityIds),
        tempComments: loadInitialValue({
          key: 'tempComments',
          defaultValue: '',
        })(facilityIds),
        savedComments: loadInitialValue({
          key: 'savedComments',
          defaultValue: '',
        })(facilityIds),
        savedComfortZones: loadValidatedComfortZones(facilityIds),
        tempContactInfo: loadInitialContact({
          key: 'contact',
          defaultValue: {
            name: '',
            email: '',
            phone: '',
          },
        })(facilityIds),
        savedContactInfo: loadInitialContact({
          key: 'savedContactInfo',
          defaultValue: {
            name: '',
            email: '',
            phone: '',
          },
        })(facilityIds),
      })
    }
  }

  initUserInfo() {
    const { loading, user } = this.props
    const { exportSettingsLoaded } = this.state
    const savedEmail = this.state.savedContactInfo.email !== ''
    // The user has to have an email, so we're assuming that, if they
    // have the email set, then we can ignore this update block.
    if (!loading && exportSettingsLoaded && !savedEmail) {
      const name = get(user, 'name', '') || ''
      const email = get(user, 'email', '') || ''
      const phone = get(user, 'phone', '') || ''
      const info = { name, email, phone }
      if (email !== '') {
        this.setState({
          tempContactInfo: info,
          savedContactInfo: info,
        })
      }
    }
  }

  initSavedObjects() {
    const { objects } = this.props
    if (!this.state.savedObjects && objects) {
      const savedObjects = {
        products: {},
        others: {},
      }
      Object.keys(get(objects, 'products', {})).forEach(key => {
        savedObjects.products[key] = {
          options: {
            isChecked: true,
            notes: true,
            images: true,
          },
        }
      })
      Object.keys(get(objects, 'others', {})).forEach(key => {
        savedObjects.others[key] = {
          options: {
            isChecked: true,
            notes: true,
            images: true,
          },
        }
      })

      this.setState({ savedObjects })
    }
  }

  setDocType(event, docType) {
    event.preventDefault()
    if (docType === 'INTERNAL') {
      this.setState({ docType, mainImage: 'DETAILED_VIEW' })
      return
    }

    this.setState({ docType })
  }

  handleFieldChanged = (event, key) => {
    const { name, value } = event.target
    if (key) {
      this.setState(prevState => ({
        [name]: {
          ...prevState[name],
          [key]: value,
        },
      }))
    } else {
      this.setState({
        [name]: value,
      })
    }
  }

  updateZones = setState => {
    this.setState(({ savedComfortZones }) => {
      const updatedZones = setState(savedComfortZones)
      this.saveToLocalStorage({ savedComfortZones: updatedZones })
      return {savedComfortZones: updatedZones}
    })
  }

  getContactInfo() {
    return (
      get(
        JSON.parse(
          window.localStorage.getItem(`${STORAGE_KEY}:${get(this.props.facilityData, 'id')}`)
        ),
        'contact'
      ) || this.state.savedContactInfo
    )
  }

  get screens() {
    const canShowFPMGridOption = hasFeature('FPM Grid', this.props.user)
    return {
      // TODO: create docType pages for summary and internal
      SELECT_TYPE: {
        component: SelectType,
        props: {
          docType: this.state.docType,
          setDocType: this.setDocType.bind(this),
        },
        contentProps: {
          verticalMargin: 'l',
          width: '650px',
        },
        primaryAction: {
          label: 'Next',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN')
          },
        },
        secondaryAction: {
          label: 'Cancel',
          to: this.props.parentRoute,
        },
      },
      MAIN: {
        component: Main,
        breadcrumbs: [
          {
            title: 'Document Type',
            onClick: event => {
              event.preventDefault()
              this.navigate('SELECT_TYPE', {})
            },
          },
          {
            title: 'Options',
          },
        ],
        props: {
          mainImage: this.state.mainImage,
          additionalOptions: this.state.additionalOptions,
          setAdditionalOptions: additionalOption =>
            this.setAdditionalOptions(additionalOption),
          setMainImageOption: mainImage => this.setMainImageOption(mainImage),
          canShowFPMGridOption,
          docType: this.state.docType,
          detailedType: this.state.detailedType,
        },
        contentProps: {
          verticalMargin: 'l',
          width: '500px',
        },
        primaryAction: {
          label: 'Preview',
          onClick: event => {
            event.preventDefault()
            this.navigate('PREVIEW')
          },
          disabled: !this.canExport(),
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            this.navigate('SELECT_TYPE')
          },
        },
      },
      MAIN_SNAPSHOT: {
        component: Images,
        breadcrumbs: [
          {
            title: 'Document Type',
            onClick: event => {
              event.preventDefault()
              this.navigate('SELECT_TYPE', {})
            },
          },
          {
            title: 'Options',
            onClick: event => {
              event.preventDefault()
              this.navigate('MAIN', { selectedMainImageSnapshot: [] })
            },
          },
          {
            title: 'Select Snapshot',
          },
        ],
        props: {
          selectedImages: this.state.savedMainImageSnapshot
            ? [this.state.savedMainImageSnapshot]
            : [],
          limit: 1,
          setImages: images => {
            let selectedMainImageSnapshot = null
            if (images.length) {
              selectedMainImageSnapshot = images[0]
            }
            this.setState({ selectedMainImageSnapshot })
          },
          imageTypes: ['snapshot'],
        },
        primaryAction: {
          label: 'Save',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN', {
              savedMainImageSnapshot: this.state.selectedMainImageSnapshot,
            })
            this.navigate('MAIN')
          },
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            // Clear the selected image
            this.navigate('MAIN', { selectedMainImageSnapshot: null })
          },
        },
      },
      CONTACT_INFO: {
        component: ContactInfo,
        breadcrumbs: [
          {
            title: 'Document Type',
            onClick: event => {
              event.preventDefault()
              this.navigate('SELECT_TYPE', {})
            },
          },
          {
            title: 'Options',
            onClick: event => {
              event.preventDefault()
              this.navigate('MAIN', { selectedObjects: {} })
            },
          },
          {
            title: 'Customize Contact Info',
          },
        ],
        props: {
          tempContactInfo: this.getContactInfo(),
          handleFieldChanged: this.saveContactInfo,
          facilityData: this.props.facilityData,
        },
        primaryAction: {
          label: 'Save',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN', {
              savedContactInfo: Object.assign({}, this.state.tempContactInfo),
            })
          },
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN', {
              tempContactInfo: { ...this.state.savedContactInfo },
            })
          },
        },
      },
      COMMENTS: {
        component: Comments,
        breadcrumbs: [
          {
            title: 'Document Type',
            onClick: event => {
              event.preventDefault()
              this.navigate('SELECT_TYPE', {})
            },
          },
          {
            title: 'Options',
            onClick: event => {
              event.preventDefault()
              this.navigate('MAIN', { selectedObjects: {} })
            },
          },
          {
            title: 'Comments',
          },
        ],
        props: {
          tempComments: this.state.tempComments,
          handleFieldChanged: this.handleFieldChanged,
        },
        primaryAction: {
          label: 'Save',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN', {
              savedComments: this.state.tempComments,
            })
          },
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN', {
              tempComments: this.state.savedComments,
            })
          },
        },
      },

      // Document Explanation screen is currently unreachable.
      // It allows you to select your own images for the
      // 'Sample Images' on the DocumentExplanation page
      // To enable, set configurable: true in Main.js

      DOCUMENT_EXPLANATION: {
        component: DocumentExplanation,
        breadcrumbs: [
          {
            title: 'Document Type',
            onClick: event => {
              event.preventDefault()
              this.navigate('SELECT_TYPE', {})
            },
          },
          {
            title: 'Options',
            onClick: event => {
              event.preventDefault()
              this.navigate('MAIN', { selectedAdditionalImages: [] })
            },
          },
          {
            title: 'Choose Snapshots for Document Explanation Page',
          },
        ],
        props: {
          selectedImages: this.state.savedDocumentExplanationImages,
          setImages: selectedDocumentExplanationImages => {
            this.setState({ selectedDocumentExplanationImages })
          },
          imageTypes: ['cfd', 'snapshot', 'object'],
        },
        primaryAction: {
          label: 'Save',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN', {
              savedDocumentExplanationImages: this.state.selectedDocumentExplanationImages.map(
                img => ({
                  ...img,
                })
              ),
            })
          },
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            // Clear saved images
            this.navigate('MAIN', { selectedDocumentExplanationImages: [] })
          },
        },
      },
      COMFORT_ZONE_METRICS: {
        component: ComfortZone,
        breadcrumbs: [
          {
            title: 'Document Type',
            onClick: event => {
              event.preventDefault()
              this.navigate('SELECT_TYPE', {})
            },
          },
          {
            title: 'Options',
            onClick: event => {
              event.preventDefault()
              this.navigate('MAIN')
            },
          },
          {
            title: 'Area Metrics',
          },
        ],
        props: {
          selectedZones: this.state.savedComfortZones,
          updateZones: this.updateZones,
        },
        primaryAction: {
          label: 'Save',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN')
          },
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN')
          },
        },
      },
      COOLING_IMAGES: {
        component: TypedImages,
        breadcrumbs: [
          {
            title: 'Document Type',
            onClick: event => {
              event.preventDefault()
              this.navigate('SELECT_TYPE', {})
            },
          },
          {
            title: 'Options',
            onClick: event => {
              event.preventDefault()
              this.navigate('MAIN', { selectedCoolingImages: [] })
            },
          },
          {
            title: 'Select Cooling Images',
          },
        ],
        primaryAction: {
          label: 'Save',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN')
          },
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            // Clear saved images
            this.navigate('MAIN', {
              selectedCoolingImages: [],
            })
          },
        },
        props: {
          selectedImages: this.state.selectedCoolingImages,
          setImages: selectedCoolingImages => {
            this.setState({ selectedCoolingImages })
            this.saveToLocalStorage({ selectedCoolingImages })
          },
          imagesPerPage: this.state.coolingImagesPerPage,
          setImagesPerPage: coolingImagesPerPage => {
            this.setState({ coolingImagesPerPage })
          },
          docType: this.state.docType,
          type: 'cooling',
        },
      },
      DESTRAT_IMAGES: {
        component: TypedImages,
        breadcrumbs: [
          {
            title: 'Document Type',
            onClick: event => {
              event.preventDefault()
              this.navigate('SELECT_TYPE', {})
            },
          },
          {
            title: 'Options',
            onClick: event => {
              event.preventDefault()
              this.navigate('MAIN', { selectedDestratImages: [] })
            },
          },
          {
            title: 'Select Destrat Images',
          },
        ],
        primaryAction: {
          label: 'Save',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN')
          },
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            // Clear saved images
            this.navigate('MAIN', {
              selectedDestratImages: [],
            })
          },
        },
        props: {
          selectedImages: this.state.selectedDestratImages,
          setImages: selectedDestratImages => {
            this.setState({ selectedDestratImages })
            this.saveToLocalStorage({ selectedDestratImages })
          },
          imagesPerPage: this.state.destratImagesPerPage,
          setImagesPerPage: destratImagesPerPage => {
            this.setState({ destratImagesPerPage })
          },
          docType: this.state.docType,
          type: 'destrat',
        },
      },
      HEATING_IMAGES: {
        component: TypedImages,
        breadcrumbs: [
          {
            title: 'Document Type',
            onClick: event => {
              event.preventDefault()
              this.navigate('SELECT_TYPE', {})
            },
          },
          {
            title: 'Options',
            onClick: event => {
              event.preventDefault()
              this.navigate('MAIN', { selectedHeatingImages: [] })
            },
          },
          {
            title: 'Select Heating Images',
          },
        ],
        primaryAction: {
          label: 'Save',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN')
          },
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            // Clear saved images
            this.navigate('MAIN', {
              selectedHeatingImages: [],
            })
          },
        },
        props: {
          selectedImages: this.state.selectedHeatingImages,
          setImages: selectedHeatingImages => {
            this.setState({ selectedHeatingImages })
            this.saveToLocalStorage({ selectedHeatingImages })
          },
          imagesPerPage: this.state.heatingImagesPerPage,
          setImagesPerPage: heatingImagesPerPage => {
            this.setState({ heatingImagesPerPage })
          },
          docType: this.state.docType,
          type: 'heating',
        },
      },
      ADDITIONAL_IMAGES: {
        component: Images,
        breadcrumbs: [
          {
            title: 'Document Type',
            onClick: event => {
              event.preventDefault()
              this.navigate('SELECT_TYPE', {})
            },
          },
          {
            title: 'Options',
            onClick: event => {
              event.preventDefault()
              this.navigate('MAIN', { selectedAdditionalImages: [] })
            },
          },
          {
            title: 'Select Additional Images',
          },
        ],
        props: {
          selectedImages: this.state.savedAdditionalImages.filter(img => !!img),
          setImages: selectedAdditionalImages => {
            this.setState({ selectedAdditionalImages })
          },
          selectedImagesPerPage: this.state.savedImagesPerPage,
          setImagesPerPage: selectedImagesPerPage => {
            this.setState({ selectedImagesPerPage })
          },
          imageTypes: ['snapshot', 'object'],
          docType: this.state.docType,
        },
        primaryAction: {
          label: 'Save',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN', {
              savedAdditionalImages: this.state.selectedAdditionalImages
                .filter(img => !!img)
                .map((img, i) => ({ ...img, index: i })),
              savedImagesPerPage: this.state.selectedImagesPerPage,
            })
          },
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            // Clear saved images
            this.navigate('MAIN', {
              selectedAdditionalImages: [],
              selectedImagesPerPage: this.state.savedImagesPerPage,
            })
          },
        },
      },
      DETAILED_VIEW: {
        component: DetailedView,
        breadcrumbs: [
          {
            title: 'Document Type',
            onClick: event => {
              event.preventDefault()
              this.navigate('SELECT_TYPE', {})
            },
          },
          {
            title: 'Options',
            onClick: event => {
              event.preventDefault()
              this.navigate('MAIN', { selectedAdditionalImages: [] })
            },
          },
          {
            title: 'Select Detailed Type',
          },
        ],
        props: {
          type: this.state.detailedType,
          setType: detailedType => {
            this.setState({ detailedType })
          },
        },
        primaryAction: {
          label: 'Save',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN')
          },
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN')
          },
        },
      },
      PREVIEW: {
        component: Preview,
        props: {
          docType: this.state.docType,
          mainImage: this.state.mainImage,
          savedMainImageSnapshot: this.state.savedMainImageSnapshot,
          showSchedule: this.state.additionalOptions.includes(
            'PRODUCT_SCHEDULE'
          ),
          customContactInfo: this.getContactInfo(),
          showComments: this.state.additionalOptions.includes('COMMENTS'),
          comments: this.state.savedComments,
          documentExplanationImages: this.state.savedDocumentExplanationImages,
          showDocumentExplanation: this.state.additionalOptions.includes(
            'DOCUMENT_EXPLANATION'
          ),
          showDetailedView: this.state.additionalOptions.includes(
            'DETAILED_VIEW'
          ),
          detailedType: this.state.detailedType,
          showComfortZoneMetrics: this.state.additionalOptions.includes(
            'COMFORT_ZONE_METRICS'
          ),
          comfortZones: this.state.savedComfortZones,
          showFPMGrid: this.state.additionalOptions.includes('FPM_GRID'),
          showObjectInventory: this.state.additionalOptions.includes(
            'OBJECT_INVENTORY'
          ),
          objectsToShow: this.state.savedObjects,
          additionalImages: this.state.savedAdditionalImages,
          selectedCoolingImages: this.state.selectedCoolingImages,
          selectedDestratImages: this.state.selectedDestratImages,
          selectedHeatingImages: this.state.selectedHeatingImages,
          imagesPerPage: this.state.savedImagesPerPage,
          coolingImagesPerPage: this.state.coolingImagesPerPage,
          destratImagesPerPage: this.state.destratImagesPerPage,
          heatingImagesPerPage: this.state.heatingImagesPerPage,
          showAdditionalImages: this.state.additionalOptions.includes(
            'ADDITIONAL_IMAGES'
          ),
          showDeckHeight: this.state.additionalOptions.includes('ROOF_DECK'),
          setExportFunction: exportFn => (this.exportFn = exportFn),
          facilityUnits: this.props.facilityData.units,
          showMountStructureLines: this.state.additionalOptions.includes(
            'MOUNT_STRUCTURE_LINES'
          ),
          showDimensionLines: this.state.additionalOptions.includes(
            'DIMENSION_LINES'
          ),
        },
        primaryAction: {
          label: 'Export',
          onClick: event => {
            event.preventDefault()
            this.props.logUserAction({
              action: `export ${
                this.state.docType === 'INTERNAL' ? 'internal' : 'customer'
              } pdf`,
            })
            this.exportFn && this.exportFn()
          },
        },
        secondaryAction: {
          label: 'Go Back',
          onClick: event => {
            event.preventDefault()
            this.navigate('MAIN')
          },
        },
      },
    }
  }

  canExport() {
    const isPlanView = this.state.mainImage === 'PLAN_VIEW'
    const isSnapshotSelected =
      this.state.mainImage === 'SNAPSHOT' && this.state.savedMainImageSnapshot
    const isDetailedViewSelected = this.state.mainImage === 'DETAILED_VIEW'
    const isObjectInventoryValid =
      !this.state.additionalOptions.includes('OBJECT_INVENTORY') ||
      this.state.savedObjects !== null
    const isAdditionalImagesValid =
      !this.state.additionalOptions.includes('ADDITIONAL_IMAGES') ||
      !!this.state.savedAdditionalImages
    const isComfortZoneValid =
      !this.state.additionalOptions.includes('COMFORT_ZONE_METRICS') ||
      this.state.savedComfortZones.length > 0
    return (
      (isPlanView || isSnapshotSelected || isDetailedViewSelected) &&
      isObjectInventoryValid &&
      isAdditionalImagesValid &&
      isComfortZoneValid
    )
  }

  // pull facility contact out to facility scope
  saveToLocalStorage(key) {
    const { facilityData } = this.props
    window.localStorage.setItem(
      `${STORAGE_KEY}:${get(facilityData, 'id')}:${get(
        facilityData,
        'floor.id'
      )}:${get(facilityData, 'floor.version.id')}`,
      JSON.stringify({ ...this.state, ...key })
    )
  }

  saveContactInfo = ({ name, email, phone }) => {
    const facilityId =
      get(this.props.facilityData, 'facility.id') || this.props.facilityData.id // look for facilityId
    const prev = get(
      JSON.parse(window.localStorage.getItem(`${STORAGE_KEY}:${facilityId}`)),
      'contact'
    )
    if (!name && get(prev, 'name')) {
      name = prev.name
    }

    if (!email && get(prev, 'email')) {
      email = prev.email
    }

    if (!phone && get(prev, 'phone')) {
      phone = prev.phone
    }

    window.localStorage.setItem(
      `${STORAGE_KEY}:${facilityId}`,
      JSON.stringify({ contact: { name, email, phone } })
    )

    this.setState({ tempContactInfo: { name, email, phone } })
    this.setState({ savedContactInfo: { name, email, phone } })
  }

  navigate(screen, otherState = {}) {
    // Save to local storage
    this.saveToLocalStorage(otherState)
    // Save to state
    this.setState({
      screen,
      ...otherState,
    })
  }

  setMainImageOption(mainImage) {
    this.saveToLocalStorage({ mainImage })
    this.setState({ mainImage })
  }

  setAdditionalOptions(key) {
    let additionalOptions = [...this.state.additionalOptions]
    if (additionalOptions.includes(key)) {
      if (key === 'COOLING_IMAGES') {
        this.saveToLocalStorage({selectedCoolingImages: []})
        this.setState({selectedCoolingImages: []})
      } else if (key === 'DESTRAT_IMAGES') {
        this.saveToLocalStorage({selectedDestratImages: []})
        this.setState({selectedDestratImages: []})
      } else if (key === 'HEATING_IMAGES') {
        this.saveToLocalStorage({selectedHeatingImages: []})
        this.setState({selectedHeatingImages: []})
      }
      additionalOptions = without(additionalOptions, key)
    } else {
      additionalOptions.push(key)
    }

    this.saveToLocalStorage({ additionalOptions })
    this.setState({ additionalOptions })
  }

  renderScreenContent() {
    const { screen } = this.state
    const screenOptions = this.screens[screen]
    const { contentProps = {}, props = {}, breadcrumbs } = screenOptions

    return (
      <>
        {breadcrumbs && (
          <Space bottom="base">
            <Breadcrumbs items={breadcrumbs} />
          </Space>
        )}
        <Content {...contentProps}>
          <screenOptions.component
            {...props}
            navigate={screen => this.navigate(screen)}
          />
        </Content>
      </>
    )
  }

  renderPrimaryAction() {
    const { screen } = this.state
    const { primaryAction } = this.screens[screen]

    // Include a key so that if we hit the button on a different screen
    // It is rerendered on this screen to get rid of the selection/highlight
    return <Button key={`${screen}-primary`} primary {...primaryAction} />
  }

  renderSecondaryAction() {
    const { screen } = this.state
    const { secondaryAction } = this.screens[screen]

    // Include a key so that if we hit the button on a different screen
    // It is rerendered on this screen to get rid of the selection/highlight
    return <Button key={`${screen}-secondary`} {...secondaryAction} />
  }

  handleClose() {
    this.props.history.push(this.props.parentRoute)
  }

  render() {
    const { parentRoute, loading, ...props } = this.props
    return (
      <UIModal
        title="Export BAF Template"
        size="950px"
        primaryAction={this.renderPrimaryAction()}
        secondaryAction={this.renderSecondaryAction()}
        parentRoute={parentRoute}
        onClose={() => this.handleClose()}
        {...props}
      >
        {loading ? <Loader /> : this.renderScreenContent()}
      </UIModal>
    )
  }
}

Modal.propTypes = {
  cooling: PropTypes.object,
  destrat: PropTypes.object,
  heating: PropTypes.object,
  fileName: PropTypes.string,
  facilityData: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
  logUserAction: PropTypes.func,
  objects: PropTypes.objectOf(PropTypes.shape({})),
  parentRoute: PropTypes.string,
  showAlert: PropTypes.func,
  user: PropTypes.object,
}

const mapStateToProps = ({ cfd }) => ({ cfd })
const mapDispatchToProps = { showAlert }

export default compose(
  withPDFFileName,
  withObjects,
  withUser,
  appConnect(mapStateToProps, mapDispatchToProps),
  graphql(LOG_USER_ACTION_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      logUserAction: ({ action }) =>
        handleLogUserAction({ action, mutate, ownProps }),
    }),
  })
)(Modal)
