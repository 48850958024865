import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Flex from 'components/UIKit/Flex'

const MenuContainer = styled(Flex)`
  background-color: ${theme('colors.light.bg')};
  height: 500px;
  width: 900px;
`

export default MenuContainer
