import React from 'react'
import PropTypes from 'prop-types'
import { useNetwork } from 'networkProvider'

import withUser from 'client/decorators/withUser'

import { currentUserIsCustomer } from 'lib/currentUserIs'

import { ToolbarGroup } from 'components/UIKit/Toolbar'

import AnnotationsMenu from './AnnotationsMenu'
import ObstructionsAndPanelsMenu from './ObstructionsAndPanelsMenu'
import ProductsMenu from './ProductsMenu'
import RoofsAndCeilingsMenu from './RoofsAndCeilingsMenu'
import WallsAndDoorsMenu from './WallsAndDoorsMenu'

const MiddleCenter = (props = {}) => {
  const network = useNetwork()
  return (
    <ToolbarGroup size="60%" alignment="center" disabled={!network.online}>
      {!currentUserIsCustomer(props.user) && (
        <>
          <ProductsMenu />
          <WallsAndDoorsMenu />
          <RoofsAndCeilingsMenu />
          <ObstructionsAndPanelsMenu />
          <AnnotationsMenu />
        </>
      )}
    </ToolbarGroup>
  )
}

MiddleCenter.propTypes = {
  user: PropTypes.object,
}

export default withUser(MiddleCenter)
