import React, { Component } from 'react'
import { object, func, string, bool } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'

import { updateElevationLine } from 'store/objects'
import { withUnits } from 'store/units/decorators'
import { SYSTEMS } from 'store/units/constants'
import { Distance } from 'store/units/types'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { isTouchUI } from 'store/userInterface/selectors'
import { SELECTED_ELEVATION_LINE_PANEL } from 'store/panel/types'
import CLASS_NAMES from 'config/objectClassNames'
import store from 'store'
import LAYER_KEYS from 'config/layerKeys'

import DimensionInput from 'components/UIKit/DimensionInput'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'

import MetadataSection from 'components/MetadataSection'

class SelectedElevationLinePanel extends Component {
  handleInputChange = (key, value) => {
    const { selectedObject, onUpdateElevationLine } = this.props

    onUpdateElevationLine({
      ...selectedObject,
      layerKey: 'ROOFS',
      [key]: value,
    })
  }

  renderContent() {
    const {
      selectedObject,
      onUpdateElevationLine,
      distanceUnits,
      isTouchUI,
    } = this.props
    const inputWidth = isTouchUI ? '140px' : '70px'

    const isLocked = store.getState().layers.layers[LAYER_KEYS.ROOFS].locked

    return (
      <>
        <PanelSection>
          <Space bottom="base">
            <DimensionInput
              label="Height"
              name="elevation-line-height"
              width={inputWidth}
              disabled={isLocked ? true : false}
              distance={
                new Distance({
                  value: selectedObject.height,
                  system: SYSTEMS.NATIVE,
                })
              }
              units={distanceUnits}
              onChange={({ distance }) => {
                const value = distance.native() || 0
                this.handleInputChange('height', value)
              }}
            />
          </Space>
        </PanelSection>
        <MetadataSection
          object={selectedObject}
          onBlur={onUpdateElevationLine}
          disabled={isLocked ? true : false}
        />
      </>
    )
  }

  render() {
    const { selectedObject, alignment, isTouchUI } = this.props

    return (
      <Panel
        title="Elevation Line"
        alignment={alignment}
        docked
        panelKey={SELECTED_ELEVATION_LINE_PANEL}
        hasToolbar={isTouchUI}
      >
        {selectedObject ? this.renderContent() : null}
      </Panel>
    )
  }
}

SelectedElevationLinePanel.propTypes = {
  alignment: string,
  selectedObject: object,
  onUpdateElevationLine: func,
  distanceUnits: string,
  isTouchUI: bool,
}

SelectedElevationLinePanel.defaultProps = {
  alignment: 'right',
}

const mapStateToProps = ({ objects, selectedObjects, ...store }) => {
  const selectedObject = mostRecentSelectedObjectOfClassName(
    CLASS_NAMES.ELEVATION_LINE,
    {
      selectedObjects,
      objects,
    }
  )
  return {
    layers: store.layers,
    isTouchUI: isTouchUI(store),
    selectedObject,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateElevationLine(elevationLine) {
    dispatch(updateElevationLine({ elevationLine }))
  },
})

export default compose(
  withUnits,
  appConnect(mapStateToProps, mapDispatchToProps)
)(SelectedElevationLinePanel)
