import styled, { css } from 'styled-components'

import { noSelect, theme } from 'lib/styleUtils'

const setPadding = ({ isTouchUI }) =>
  isTouchUI
    ? css`
        padding: ${theme('spacing.base')} ${theme('spacing.s')};
      `
    : css`
        padding: ${theme('spacing.s')} ${theme('spacing.base')};
      `

const ListItem = styled.div`
  align-items: center;
  border-bottom: 1px solid ${theme('colors.light.subdued')};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;

  ${props =>
    (props.selected || props.isChild) &&
    `background-color: ${props.theme.colors.light.extraLight};`};

  &:active,
  &:focus,
  &:hover {
    background-color: ${theme('colors.light.extraLight')};
  }

  ${setPadding};
  ${noSelect()};
`

export default ListItem
