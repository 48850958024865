import theme from 'config/theme'
import styled from 'styled-components'

export const TabHeader = styled.div`
  display: flex;
  gap: 5px;
  & span {
    text-wrap: nowrap;
  }
`

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
`

export const HeadingText = styled.span`
  font-size: ${theme.fonts.sizes.s};
  font-weight: ${theme.fonts.weights.semiBold};
  margin-left: ${theme.spacing.xs};
`
