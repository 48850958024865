import styled, { keyframes } from 'styled-components'
import Icon from 'components/UIKit/Icon'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
`

export const SpinningIcon = styled(Icon)`
  animation: ${rotate} 1s linear infinite;
`
