import styled from 'styled-components'
import PropTypes from 'prop-types'

import Popover from 'components/UIKit/Popover'

const TouchPopover = styled(Popover)`
  position: static;
`

export default TouchPopover

TouchPopover.propTypes = {
  closeOnDocumentClick: PropTypes.bool,
  variant: PropTypes.string,
}

TouchPopover.defaultProps = {
  closeOnDocumentClick: false,
}
