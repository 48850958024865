import isEmpty from 'lodash-es/isEmpty'

import OBJECT_TYPES from 'config/objectTypes'

import Facility from './facility'
import QuickState from './quickState'
import Units from './units'
import Util from './util'
import WallSegment from './wallSegment'

import * as THREE from 'three'

class Tool {
  /*
    -= properties =-

    obj3d : the root THREE.Object3D node for this tool's visual representation
  */

  constructor() {
    this.obj3d = new THREE.Object3D()
    this.hideArrows = false
    this.shiftModifier = false
  }

  toolDown(/* mousePos, snappedMousePos, sceneIntersectionPoint, objectUnderTool */) {
    return false
  }

  toolMoved(/* mousePos, snappedMousePos, sceneIntersectionPoint, objectUnderTool */) {}

  toolUp(/* mousePos, snappedMousePos, sceneIntersectionPoint, objectUnderTool */) {}

  toolFinish() {}

  keyPressed() {}

  addEventListeners = () => {
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
  }

  removeEventListeners() {
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyDown = e => {
    // shift key down
    if (e.which === 16) {
      this.shiftModifier = true
    }
  }

  handleKeyUp = e => {
    // shift key up
    if (e.which === 16) {
      this.shiftModifier = false
    }
  }

  activate() {
    this.addEventListeners()
  }

  deactivate() {
    this.removeEventListeners()
  }

  getSnapRegions() {
    return []
  }

  frameRendered(/* deltaMillis, canvasState */) {}

  onPropsDidChange(/* nextProps */) {}

  getUpdatedPoint(point, objectWithCursor) {
    const directions = []

    // To prevent the arrow pointing to inside a wall,
    // We need to move the point from which the arrows comes
    if (objectWithCursor instanceof WallSegment) {
      const centerLinePoints = objectWithCursor.getCenterLinePoints()

      const clp1 = Util.arrayPointToObjectPoint(centerLinePoints[0])
      const clp2 = Util.arrayPointToObjectPoint(centerLinePoints[1])
      point = Util.nearestPointOnSegment(clp1, clp2, point)

      const wall = Facility.current.findObjectWithId(objectWithCursor.parentId)
      const direction = Util.getVectorToVectorDirection(
        objectWithCursor.startPoint,
        objectWithCursor.endPoint
      )

      directions.push(
        { direction: 'extra', vector: direction.clone() },
        {
          direction: 'extra-reversed',
          vector: direction.clone().multiplyScalar(-1),
        }
      )

      direction.set(-1 * direction.y, direction.x, 0)

      const translationVector = direction
        .clone()
        .multiplyScalar(objectWithCursor.thickness / 2 + 0.11)

      const newPosition = point.clone().add(translationVector)
      const newPositionNegative = point.clone().sub(translationVector)

      const newPositionInWall = Util.isPointInPolygon(
        newPosition,
        wall.toGlobalPoints(wall.insetPoints)
      )
      const newPositionNegativeInWall = Util.isPointInPolygon(
        newPositionNegative,
        wall.toGlobalPoints(wall.insetPoints)
      )

      if (newPositionInWall) {
        point = newPosition
      } else if (newPositionNegativeInWall) {
        point = newPositionNegative
      }
    }

    return { point, directions }
  }

  getArrowDescriptions() {
    // If the object over which the cursor is hovering is empty
    // We return no arrow descriptions, i.e. don't show arrows over empty space
    const objectWithCursor = Facility.current.findObjectWithId(
      QuickState.objectWithCursorId
    )

    if (this.hideArrows || isEmpty(objectWithCursor)) {
      return
    }

    const descriptions = []

    const directions = [
      { direction: 'up', vector: new THREE.Vector3(0, 1, 0) },
      { direction: 'down', vector: new THREE.Vector3(0, -1, 0) },
      { direction: 'right', vector: new THREE.Vector3(1, 0, 0) },
      { direction: 'left', vector: new THREE.Vector3(-1, 0, 0) },
    ]

    const options = {
      measureFrom: Facility.SURFACE,
      measureTo: Facility.SURFACE,
      xyOnly: true,
      includedTypes: [OBJECT_TYPES.WALL],
    }

    const { point, directions: extraDirections } = this.getUpdatedPoint(
      this.cursor.position,
      objectWithCursor
    )

    directions.push(...extraDirections)
    directions.forEach(({ direction, vector }) => {
      const measurements = Facility.current.measureObjectsInDirectionFromPoint(
        vector,
        point,
        options
      )

      if (measurements.length > 0) {
        const measurement = measurements[0]
        const vectorLength = Units.nativeToInches(measurement.vector.length())

        if (Math.floor(vectorLength) > 0) {
          descriptions.push({
            key: direction,
            vector: measurement.vector,
            position: point,
            showLength: true,
            editable: false,
          })
        }
      }
    })

    return descriptions
  }
}

export default Tool
