import omit from 'lodash-es/omit'

export default function cleanProduct(product) {
  return omit(product, [
    // Data DB already knows
    'airVelocities',
    'mountingOptionAdders',
    'mountingOptions',
    'voltages',
    'product.shippingPrice',
    'product.variations',
    'minFloorClearance',
    'minObstructionClearance',
    'minProductClearance',
    'minRoofClearance',
    'minWallClearance',
    'recommendedFloorClearance',
    'recommendedObstructionClearance',
    'recommendedProductClearance',
    'recommendedRoofClearance',
    'recommendedWallClearance',
    // Instances
    'obj3d',
    'positionBeforeDrag',
  ])
}
