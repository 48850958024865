import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.div`
  align-items: center;
  background-color: ${theme('colors.dark.bg')};
  border-top-left-radius: ${theme('radius.base')};
  border-top-right-radius: ${theme('radius.base')};
  color: ${theme('colors.dark.fg')};
  display: flex;
  justify-content: space-between;
  padding-left: ${theme('spacing.l')};
`

export default Container
