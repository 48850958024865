import {
  OverlayArrow,
  Popover as AriaPopover,
  PopoverContext,
  useSlottedContext
} from 'react-aria-components';
import React, { ComponentProps } from 'react';
import { composeTailwindRenderProps } from './utils';
import { motion } from 'framer-motion';
import { useDialogTriggerAnimations } from './Modal';

const MotionPopover = motion(AriaPopover)

export interface PopoverProps extends Omit<ComponentProps<typeof MotionPopover>, 'children'> {
  showArrow?: boolean,
  children: React.ReactNode
}


const styles = 'bg-white dark:bg-dark-gray-900 dark:backdrop-blur-2xl dark:backdrop-saturate-200 forced-colors:bg-[Canvas] shadow-2xl rounded bg-clip-padding outline outline-black/10 dark:outline-0 text-gray-700 dark:text-zinc-300'

export function Popover({ children, showArrow, className, ...props }: PopoverProps) {
  const animationProps = useDialogTriggerAnimations()
  const popoverContext = useSlottedContext(PopoverContext)!;
  const isSubmenu = popoverContext?.trigger === 'SubmenuTrigger';
  let offset = showArrow ? 12 : 8;
  offset = isSubmenu ? offset - 6 : offset;
  return (
    <MotionPopover
      offset={offset}
      variants={{hidden: { opacity: 0, y: 8 }, visible: { opacity: 1, y: 0 }}}
      {...animationProps}
      {...props}
      className={composeTailwindRenderProps(className, styles)}>
      {showArrow &&
        <OverlayArrow className="group">
          <svg width={12} height={12} viewBox="0 0 12 12" className="block fill-white dark:fill-[#1f1f21] forced-colors:fill-[Canvas] stroke-1 stroke-black/10 dark:stroke-zinc-600 forced-colors:stroke-[ButtonBorder] group-placement-bottom:rotate-180 group-placement-left:-rotate-90 group-placement-right:rotate-90">
            <path d="M0 0 L6 6 L12 0" />
          </svg>
        </OverlayArrow>
      }
      {children}
    </MotionPopover>
  );
}
