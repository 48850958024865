import React from 'react'
import PropTypes from 'prop-types'
import { motion } from "framer-motion"

import theme from 'config/theme'

import Container from './styled/Container'
import Icon from './styled/Icon'
import Item from './styled/Item'
import Label from './styled/Label'
import Number from './styled/Number'

const isComplete = (step, current) => current > step
const isLastComplete = (step, current) => step === current - 1

const StepProgress = ({ steps, current }) => (
  <Container>
    {steps.map((step, index) => (
      <Item
        index={index + 1}
        steps={steps.length}
        key={index}
        current={current}
      >
        <Number index={index + 1} current={current}>
          {isComplete(index + 1, current) ? (
            <motion.div animate={{opacity: [1, 0, 1, 0, 1]}} transition={{duration: 5}}>
              <Icon name="check" size="12px" color={theme.colors.light.bg} />
            </motion.div>
          ) : (
            index + 1
          )}
        </Number>
        <Label>{step.label}</Label>
      </Item>
    ))}
  </Container>
)

StepProgress.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  current: PropTypes.number,
}

export default StepProgress
