import React from 'react'
import { Helmet } from 'react-helmet'

import { getTitle } from 'config/titles'

import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import Icon from 'components/UIKit/Icon'
import Blockquote from 'components/UIKit/Blockquote'

const AirflowHelpModal = ({ ...props }) => (
  <div>
    <Helmet>
      <title>{getTitle('airflowHelp')}</title>
    </Helmet>
    <Modal title="About Airflow" {...props}>
      <Space bottom="base">
        <p>
          A two-dimensional approximation of the airflow that a fan
          configuration will generate within a space. Based on interpolation
          between air speed data collected at floor level in an open test
          facility.
        </p>
      </Space>
      <Space bottom="base">
        <Blockquote>
          <p>
            <Icon name="info" size="14" color="subdued" /> This feature is less
            reliable than CFD Simulation and should be used only as a guideline
            to check the effectiveness of a fan layout before using the more
            accurate CFD feature to analyze the configuration.
          </p>
        </Blockquote>
      </Space>
    </Modal>
  </div>
)

export default AirflowHelpModal
