import styled, { keyframes } from 'styled-components'

import Icon from '../../Icon'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
`

const StyledIcon = styled(Icon)<{ hasLabel?: boolean }>`
  animation: ${rotate} 1s linear infinite;

  ${props => props.hasLabel && `margin-right: ${props.theme.spacing.xs}`};
`

export default StyledIcon
