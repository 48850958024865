import React from 'react'
import { withRouter, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import { getStepNumber } from 'config/onboarding'
import { getTitle } from 'config/titles'
import routes from 'config/routes'

import Onboarding from 'components/Onboarding'
import RoofTemplatesModal from 'components/Modals/RoofTemplatesModal'
import AppContainer from 'components/App/styled/AppContainer'
import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

const OnboardingRoofsScreen = ({ match, ...props }) => (
  <AppContainer>
    <Helmet>
      <title>{getTitle('roofs')}</title>
    </Helmet>
    <Onboarding
      match={match}
      {...props}
      step={getStepNumber('Roofs')}
      message="Draw and configure the roofs of your facility."
    />
    <SentryRoute
      path={`${match.url}${routes.modals.roofTemplates}`}
      render={props => (
        <RoofTemplatesModal parentRoute={match.url} {...props} />
      )}
    />
  </AppContainer>
)

OnboardingRoofsScreen.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string }),
}

export default withRouter(OnboardingRoofsScreen)
