import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Labels = styled.div`
  display: flex;
  margin-top: ${theme('spacing.xs')};
  justify-content: space-between;
`

export default Labels
