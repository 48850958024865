import { useState } from 'react'

import { updateBackgroundImage } from 'store/objects'
import { SELECTED_BACKGROUND_IMAGE_PANEL } from 'store/panel/types'
import LAYER_KEYS from 'config/layerKeys'

import Panel, { PanelSection } from 'components/UIKit/Panel'
import { NumberField } from '~/ui/NumberField'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { Icon } from '~/ui/Icon'
import { Slider } from '~/ui/Slider'
import { DimensionInput } from '~/ui/DimensionInput'

const SelectedBackgroundImagePanel = () => {
  const [aspectLocked, setAspectLocked] = useState(true)
  const isTouchUI = useAppSelector(({ userInterface }) => userInterface.isTouchUI)
  const backgroundImage = useAppSelector(
    ({ objects }) => objects.present.backgroundImage.BACKGROUND_IMAGE
  )
  const isLocked = useAppSelector(({ layers }) => layers.layers[LAYER_KEYS.BACKGROUND_IMAGE].locked)
  const dispatch = useAppDispatch()

  const handleChangeDimension = (dimension: 'width' | 'height') => (value: number) => {
    if (!backgroundImage) return
    if (aspectLocked) {
      const otherDimension = dimension === 'width' ? 'height' : 'width'
      const ratio = value / backgroundImage[dimension]
      const otherDimensionValue = backgroundImage[otherDimension] * ratio
      dispatch(
        updateBackgroundImage({
          backgroundImage: { [dimension]: value, [otherDimension]: otherDimensionValue },
        })
      )
    } else {
      dispatch(updateBackgroundImage({ backgroundImage: { [dimension]: value } }))
    }
  }

  const handleSliderChange = (value: number) => {
    dispatch(updateBackgroundImage({ backgroundImage: { opacity: value } }))
  }

  const handleChangeRotation = (radians: number) => {
    dispatch(updateBackgroundImage({ backgroundImage: { rotation: { x: 0, y: 0, z: radians } } }))
  }

  return (
    <Panel
      title="Background Image"
      alignment="right"
      docked
      panelKey={SELECTED_BACKGROUND_IMAGE_PANEL}
      hasToolbar={isTouchUI}
    >
      <PanelSection>
        {backgroundImage ? (
          <div className="flex flex-col gap-5">
            <div className="flex items-center justify-start gap-3">
              <div className="flex flex-col gap-3">
                <DimensionInput
                  showStepperButtons
                  label="Width"
                  value={backgroundImage.width}
                  onCommit={handleChangeDimension('width')}
                />
                <DimensionInput
                  showStepperButtons
                  label="Height"
                  value={backgroundImage.height}
                  onCommit={handleChangeDimension('height')}
                />
              </div>
              <div className="flex flex-col gap-3 mt-6">
                <span>¬</span>
                <Icon
                  name={aspectLocked ? 'locked' : 'unlocked'}
                  onClick={() => !isLocked && setAspectLocked(locked => !locked)}
                />
                <span className="-scale-y-100">¬</span>
              </div>
            </div>
            <Slider
              isDisabled={isLocked}
              label="Opacity"
              maxValue={1}
              step={0.1}
              formatOptions={{ style: 'percent' }}
              value={backgroundImage.opacity}
              onChange={handleSliderChange}
            />
            <NumberField
              isDisabled={isLocked}
              label="Rotation"
              value={backgroundImage.rotation.z}
              minValue={0}
              maxValue={360}
              onChange={handleChangeRotation}
              formatOptions={{ style: 'unit', unit: 'degree', unitDisplay: 'narrow' }}
            />
          </div>
        ) : null}
      </PanelSection>
    </Panel>
  )
}

export default SelectedBackgroundImagePanel
