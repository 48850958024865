import styled from 'styled-components'

import { getZIndexOf } from 'lib/utils'
import { theme } from 'lib/styleUtils'

const position = ({ isActive, theme, isTouchUI }) =>
  isTouchUI &&
  `
    left: auto;
    right: ${theme.spacing.base};
  `

const DrawingPanel = styled.div`
  left: ${theme('spacing.base')};
  position: absolute;
  top: ${theme('spacing.base')};
  z-index: ${getZIndexOf('tools')};

  ${position}
`

export default DrawingPanel
