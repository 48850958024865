import * as THREE from 'three'

import Util from './util'

import compact from 'lodash-es/compact'

const TOLERANCE_MULTIPLIER = 0.25
const RECTANGLE_TOLERANCE_MULTIPLIER = 0.05
const CIRCLE_TOLERANCE_MULTIPLIER = 0.1
const DEFAULT_TOLERANCE = 3

class TouchUtil {
  static randomColor() {
    var letters = '0123456789ABCDEF'
    var color = '#'
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  static isInRange(x, min, max) {
    return x >= min && x <= max
  }

  static getRectCenterLine(origin, width, height) {
    const points = []

    points.push([origin.x, origin.y, 0])
    points.push([origin.x + width, origin.y, 0])
    points.push([origin.x + width, origin.y - height, 0])
    points.push([origin.x, origin.y - height, 0])
    points.push([origin.x, origin.y, 0])

    return points
  }

  static getEllipseCenterLine(origin, width, height, sampleCount) {
    const points = []

    for (let i = 0; i < sampleCount - 1; i += 1) {
      const t = ((Math.PI * 2) / (sampleCount - 1)) * i
      const x = (width / 2) * Math.cos(t)
      const y = (height / 2) * Math.sin(t)
      points.push([x, y, 0])
    }
    points.push(points[0].slice())

    points.forEach(point => {
      point[0] += origin.x + width / 2
      point[1] += origin.y - height / 2
    })

    return points
  }

  // Finds all lines that run in the same direction based on a key (x, y)
  static findParallelLines(lines, positions, key) {
    if (!lines || !lines.length || !positions || !positions.length) return []

    const parallelLines = []
    lines.forEach((line, index) => {
      const linePositions = positions[index]
      const startPoint = linePositions[0]
      const endPoint = linePositions[linePositions.length - 1]
      if (startPoint && endPoint) {
        const tolerance = TouchUtil.getLineToleranceByLength(
          startPoint,
          endPoint
        )
        const difference = startPoint[key] - endPoint[key]
        if (TouchUtil.isInRange(difference, -tolerance, tolerance)) {
          parallelLines.push({ line, index, startPoint, endPoint })
        }
      }
    })

    return parallelLines
  }

  // Finds pairs of lines that are parallel to each other based on a key (x, y)
  static findParallelPair(line, vLines, key) {
    return vLines.find(vLine => {
      if (line.index === vLine.index) return false
      const tolerance = TouchUtil.getLineToleranceByLength(
        line.startPoint,
        line.endPoint
      )
      const startDiff = Math.abs(line.startPoint[key] - vLine.startPoint[key])
      const endDiff = Math.abs(line.endPoint[key] - vLine.endPoint[key])
      const isMatch = startDiff <= tolerance && endDiff <= tolerance

      const startInverseDiff = Math.abs(
        line.startPoint[key] - vLine.endPoint[key]
      )
      const endInverseDiff = Math.abs(
        line.endPoint[key] - vLine.startPoint[key]
      )
      const isInverseMatch =
        startInverseDiff <= tolerance && endInverseDiff <= tolerance

      return isMatch || isInverseMatch
    })
  }

  // Find sibling lines based on their start and end points
  static findSiblingLines(vLines, hLines) {
    const rectangleLines = []
    for (let i = 0; i < vLines.length; i++) {
      const match = hLines.find(hLine => {
        const vLine1 = vLines[i].line1
        const hLine1 = hLine.line1
        const hLine2 = hLine.line2
        const startDiff = vLine1.startPoint.distanceTo(hLine1.startPoint)
        const endDiff = vLine1.startPoint.distanceTo(hLine1.endPoint)
        const startDiff2 = vLine1.startPoint.distanceTo(hLine2.startPoint)
        const endDiff2 = vLine1.startPoint.distanceTo(hLine2.endPoint)
        const tolerance = TouchUtil.getLineToleranceByLength(
          vLine1.startPoint,
          vLine1.endPoint
        )
        return (
          startDiff <= tolerance ||
          endDiff <= tolerance ||
          startDiff2 <= tolerance ||
          endDiff2 <= tolerance
        )
      })

      if (match) {
        rectangleLines.push(vLines[i].line1)
        rectangleLines.push(vLines[i].line2)
        rectangleLines.push(match.line1)
        rectangleLines.push(match.line2)
        break
      }
    }

    return rectangleLines
  }

  // The detection tolerance should scale with the object size
  // We want a tight tolerance on small objects but loose on larger ones
  static getLineToleranceByLength(startPoint, endPoint) {
    if (!startPoint || !endPoint || (!startPoint.x && !startPoint.y))
      return DEFAULT_TOLERANCE

    const distance = startPoint.distanceTo(endPoint)
    const tolerance = distance * TOLERANCE_MULTIPLIER + DEFAULT_TOLERANCE
    return tolerance
  }

  static getX(lines, linePositions) {
    const LENGTH_THRESHOLD = 0.1
    const CENTER_THRESHOLD = 0.1

    if (lines.length <= 1) return

    for (let i = 0; i < linePositions.length; ++i) {
      const linePositions1 = linePositions[i]

      const point1_1 = linePositions1[0]
      const point1_2 = linePositions1[linePositions1.length - 1]
      if (!point1_1 || !point1_2) break
      const length1 = point1_1.distanceTo(point1_2)

      const center1 = Util.getLineSegmentCenter(point1_1, point1_2)

      for (let j = i + 1; j < linePositions.length; ++j) {
        const linePositions2 = linePositions[j]

        const point2_1 = linePositions2[0]
        const point2_2 = linePositions2[linePositions2.length - 1]
        if (!point2_1 || !point2_2) break
        const length2 = point2_1.distanceTo(point2_2)

        const center2 = Util.getLineSegmentCenter(point2_1, point2_2)

        let intersection = Util.lineSegmentsIntersectionPoint(
          point1_1,
          point1_2,
          point2_1,
          point2_2
        )

        if (!intersection) continue
        intersection = new THREE.Vector3(intersection.x, intersection.y, 0)

        const centerIntersectionDistance1 = intersection.distanceTo(center1)
        const centerIntersectionDistance2 = intersection.distanceTo(center2)
        if (
          // Lengths are similar
          Math.abs(length1 / (length1 + length2) - 0.5) <= LENGTH_THRESHOLD &&
          // Meet near the center of both lines
          centerIntersectionDistance1 / length1 <= CENTER_THRESHOLD &&
          centerIntersectionDistance2 / length2 <= CENTER_THRESHOLD
          // Angles
        ) {
          // It's an X

          return {
            position: intersection,
            i,
            j,
          }
        }
      }
    }
  }

  static getRectangle(vLines, hLines) {
    // Pair up vertical lines for detection
    let pairedVerticalLines = []
    for (let i = 0; i < vLines.length; i++) {
      const match = TouchUtil.findParallelPair(vLines[i], vLines, 'y')
      if (match) {
        const indexes = []
        pairedVerticalLines.forEach(lines => {
          indexes.push(lines.line1.index)
          indexes.push(lines.line2.index)
        })
        const isDuplicate =
          indexes.includes(vLines[i].index) && indexes.includes(match.index)
        if (!isDuplicate) {
          pairedVerticalLines.push({
            line1: vLines[i],
            line2: match,
          })
        }
      }
    }

    // Pair up horizontal lines for detection
    let pairedHorizontalLines = []
    for (let i = 0; i < hLines.length; i++) {
      const match = TouchUtil.findParallelPair(hLines[i], hLines, 'x')
      if (match) {
        const indexes = []
        pairedHorizontalLines.forEach(lines => {
          indexes.push(lines.line1.index)
          indexes.push(lines.line2.index)
        })
        const isDuplicate =
          indexes.includes(hLines[i].index) && indexes.includes(match.index)
        if (!isDuplicate) {
          pairedHorizontalLines.push({
            line1: hLines[i],
            line2: match,
          })
        }
      }
    }

    // If we have both vertical and horizontal pairs of lines
    // check if we can match siblings to form a rectangle
    if (pairedVerticalLines.length && pairedHorizontalLines.length) {
      const siblings = TouchUtil.findSiblingLines(
        pairedVerticalLines,
        pairedHorizontalLines
      )
      return siblings.length ? siblings : []
    }

    return []
  }

  // Gets the perimeter length of a given box
  static getBoxPerimeterLength(box) {
    if (!box) return -1

    const sizes = box.getSize()
    const verticalDistance = sizes.y * 2
    const horizontalDistance = sizes.x * 2

    return verticalDistance + horizontalDistance
  }

  static getSingleLineRectangle(lines) {
    if (!lines || !lines.length) return false

    const boxes = []
    lines.forEach(line => {
      // Get only valid positions from our drawn line
      const positions = compact(line.geometry.attributes.position.array)

      // Add the positions to the new geometry vertices
      const geometry = new THREE.BufferGeometry()
      for (let i = 0; i < positions.length; i += 3) {
        geometry.vertices.push(
          new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2])
        )
      }

      // Create a box with the valid positions and get its perimeter length
      const box = new THREE.Box3().setFromArray(positions)
      const boxPerimeterLength = TouchUtil.getBoxPerimeterLength(box)

      // Create a new line from the valid positions and get its length
      const newLine = new THREE.Line(geometry)
      newLine.computeLineDistances()
      const lineDistances = newLine.geometry.lineDistances
      const lineLength = lineDistances[lineDistances.length - 1]

      if (lineLength < 10) return

      // If the difference between the drawn line length and the potential
      // wall size is within the specified tolerance add the wall
      const difference = Math.abs(boxPerimeterLength - lineLength)
      const tolerance =
        boxPerimeterLength * RECTANGLE_TOLERANCE_MULTIPLIER + DEFAULT_TOLERANCE
      if (difference < tolerance) {
        const yDiff = Math.abs(box.max.y - box.min.y)
        const xDiff = Math.abs(box.max.x - box.min.x)
        const startPos = new THREE.Vector3(box.min.x, box.max.y, 0)
        const centerLines = TouchUtil.getRectCenterLine(startPos, xDiff, yDiff)
        boxes.push({
          centerLines,
          lines: [line],
        })
      }
    })

    if (boxes.length) {
      return boxes[0]
    }
  }

  static getMultiLineRectangle(lines, linePositions) {
    if (!lines || lines.length < 4) return false

    const hLines = TouchUtil.findParallelLines(lines, linePositions, 'y')
    const vLines = TouchUtil.findParallelLines(lines, linePositions, 'x')
    const rectangle = TouchUtil.getRectangle(vLines, hLines)

    if (rectangle.length === 4) {
      const positions = []
      rectangle.forEach(line => {
        positions.push(line.startPoint)
        positions.push(line.endPoint)
      })
      const box = new THREE.Box3().setFromPoints(positions)
      const startPos = new THREE.Vector3(box.min.x, box.max.y, 0)
      const yDiff = Math.abs(
        rectangle[0].endPoint.y - rectangle[0].startPoint.y
      )
      const xDiff = Math.abs(
        rectangle[2].endPoint.x - rectangle[2].startPoint.x
      )
      const centerLines = TouchUtil.getRectCenterLine(startPos, xDiff, yDiff)

      return {
        centerLines,
        lines: rectangle,
      }
    }
  }

  static getSingleLineCircle(lines) {
    const spheres = []
    lines.forEach(line => {
      // Get only valid positions from our drawn line
      const positions = compact(line.geometry.attributes.position.array)

      // Add the positions to the new geometry vertices
      const geometry = new THREE.BufferGeometry()
      for (let i = 0; i < positions.length; i += 3) {
        geometry.vertices.push(
          new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2])
        )
      }

      // Create a bounding sphere and get its circumference
      geometry.computeBoundingSphere()
      const circumference = 2 * Math.PI * geometry.boundingSphere.radius

      // Create a new line from the valid positions and get its length
      const newLine = new THREE.Line(geometry)
      newLine.computeLineDistances()
      const lineDistances = newLine.geometry.lineDistances
      const lineLength = lineDistances[lineDistances.length - 1]

      if (lineLength < 10) return

      // If the difference between the drawn line length and the potential
      // wall size is within the specified tolerance add the wall
      const difference = Math.abs(circumference - lineLength)
      const tolerance =
        circumference * CIRCLE_TOLERANCE_MULTIPLIER + DEFAULT_TOLERANCE
      if (difference < tolerance) {
        const box = new THREE.Box3().setFromArray(positions)
        const yDiff = Math.abs(box.max.y - box.min.y)
        const xDiff = Math.abs(box.max.x - box.min.x)
        const startPos = new THREE.Vector3(box.min.x, box.max.y, 0)
        const centerLines = TouchUtil.getEllipseCenterLine(
          startPos,
          xDiff,
          yDiff,
          9
        )

        spheres.push({
          centerLines,
          lines: [line],
        })
      }
    })

    if (spheres.length) {
      return spheres[0]
    }
  }
}

export default TouchUtil
