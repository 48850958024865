import React from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter } from 'react-router-dom'
import { compose } from 'redux'

import routes from 'config/routes'
import { isTouchDevice } from 'lib/utils'
import { currentUserIsCustomer } from 'lib/currentUserIs'
import withUser from 'client/decorators/withUser'

import RequiredPermission from 'components/RequiredPermission'
import Button from 'components/UIKit/Button'
import Logo from 'components/UIKit/Logo'
import Toolbar, { ToolbarGroup, ToolbarItem } from 'components/UIKit/Toolbar'
import CreateFacilityButton from 'components/CreateFacilityButton'
import UserDropdown from 'components/UserDropdown'
import NewFacilityModal from 'components/Modals/NewFacilityModal'
import UserProfileInfoModal from 'components/Modals/UserProfileInfoModal'
import ReleaseNotesModal from 'components/Modals/ReleaseNotesModal'

import Main from './styled/Main'
import MainContainer from './styled/MainContainer'

const isTouch = isTouchDevice()
const setVariant = isTouch ? 'dark' : 'light'
const setSeparator = isTouch ? null : 'right'

const toolbarProps = {
  size: isTouch ? 'xxl' : 'xl',
  borderBottom: isTouch ? false : true,
  variant: setVariant,
}

import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

const Dashboard = ({ noAction = false, push = false, sticky = false, wide = false, ...props}) => {
  const isActivePage = route => props.location.pathname === route
  return (
    <>
      <Toolbar {...toolbarProps} sticky={sticky}>
        <ToolbarGroup size={isTouch ? '70%' : '30%'} alignment="left">
          {isTouch && (
            <ToolbarItem paddingLeft="m" paddingRight="m">
              <Logo secondary />
            </ToolbarItem>
          )}
          {!currentUserIsCustomer(props.user) && (
            <>
              <ToolbarItem
                separator={setSeparator}
                paddingLeft="s"
                paddingRight="s"
                active={isActivePage(routes.facilities)}
                variant={setVariant}
              >
                <Button
                  responsive
                  truncate="100px"
                  icon="building"
                  noBorder
                  label="My Facilities"
                  to={routes.facilities}
                  variant={setVariant}
                />
              </ToolbarItem>
              <ToolbarItem
                separator={setSeparator}
                paddingLeft="s"
                paddingRight="s"
                active={isActivePage(routes.templates)}
                variant={setVariant}
              >
                <Button
                  responsive
                  icon="interiorWall"
                  noBorder
                  label="Templates"
                  to={routes.templates}
                  variant={setVariant}
                />
              </ToolbarItem>
              <RequiredPermission name="Reports">
                <ToolbarItem
                  separator={setSeparator}
                  paddingLeft="s"
                  paddingRight="s"
                  active={isActivePage(routes.reports)}
                  variant={setVariant}
                >
                  <Button
                    responsive
                    icon="clipboard"
                    noBorder
                    label="Reports"
                    to={routes.reports}
                    variant={setVariant}
                  />
                </ToolbarItem>
              </RequiredPermission>
              <RequiredPermission name="Reassign Facility">
                <ToolbarItem
                  separator={setSeparator}
                  paddingLeft="s"
                  paddingRight="s"
                  active={isActivePage(routes.reassign)}
                  variant={setVariant}
                >
                  <Button
                    responsive
                    icon="building"
                    noBorder
                    label="Reassign"
                    to={routes.reassign}
                    variant={setVariant}
                  />
                </ToolbarItem>
              </RequiredPermission>
            </>
          )}
        </ToolbarGroup>
        {!isTouch && (
          <ToolbarGroup size="40%" alignment="center">
            <ToolbarItem>
              <Logo />
            </ToolbarItem>
          </ToolbarGroup>
        )}
        <ToolbarGroup size="30%" alignment="right">
          {!noAction && (
            <ToolbarItem paddingRight="base">
              <CreateFacilityButton match={props.match} />
            </ToolbarItem>
          )}
          <UserDropdown />
        </ToolbarGroup>
      </Toolbar>
      <MainContainer push={push}>
        <Main wide={wide}>{props.children}</Main>
      </MainContainer>
      <SentryRoute
        exact
        path={`${props.match.url}${routes.modals.newFacility}`}
        render={modalProps => (
          <NewFacilityModal parentRoute={props.match.url} {...modalProps} />
        )}
      />
      <SentryRoute
        path={`${props.match.url}${routes.modals.userProfileInfo}`}
        render={modalProps => (
          <UserProfileInfoModal parentRoute={props.match.url} {...modalProps} />
        )}
      />
      <SentryRoute
        path={`${props.match.url}${routes.modals.releaseNotes}`}
        render={modalProps => (
          <ReleaseNotesModal parentRoute={props.match.url} {...modalProps} />
        )}
      />
    </>
  )
}

Dashboard.propTypes = {
  children: PropTypes.any,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  noAction: PropTypes.bool,
  push: PropTypes.bool,
  sticky: PropTypes.bool,
  user: PropTypes.object,
  wide: PropTypes.bool,
}

export default compose(withRouter, withUser)(Dashboard)
