import styled from 'styled-components'

import { lighten, theme } from 'lib/styleUtils'

const Footer = styled.div`
  align-items: center;
  background-color: ${props => lighten(props.theme.colors.light.subdued, 0.2)};
  border-bottom: 1px solid ${theme('colors.light.subdued')};
  bottom: 0px;
  color: ${theme('colors.light.fg')};
  display: flex;
  font-size: ${theme('fonts.sizes.s')};
  height: 50px;
  left: 0;
  padding: ${theme('spacing.s')};
  position: absolute;
  right: 0;
`

export default Footer
