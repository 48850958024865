import PropTypes from 'prop-types'

import Container from './styled/Container'

const Grid = Container

Grid.propTypes = {
  alignItems: PropTypes.string,
  hCenter: PropTypes.bool,
  vCenter: PropTypes.bool,
}

export default Grid
