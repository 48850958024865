import styled from 'styled-components'

import { lighten } from 'lib/styleUtils'
import { theme } from 'lib/styleUtils'

import Button from 'components/UIKit/Button'

const AddComfortZoneButton = styled(Button)`
  &:focus,
  &:active,
  &:hover {
    background-color: ${props =>
      lighten(theme('colors.light.subdued')(props), 0.2)};
  }
`

export default AddComfortZoneButton
