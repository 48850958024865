import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Description = styled.div`
  color: ${theme('colors.light.light')};
  font-size: ${theme('fonts.sizes.base')};
  margin-top: ${theme('spacing.xs')};
  padding-right: ${theme('spacing.s')};
`

export default Description
