import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Flex from 'components/UIKit/Flex'

const PlaceholderImage = styled(Flex)`
  border: 1px solid ${theme('colors.light.subdued')};
  border-radius: ${theme('radius.base')};
  padding: ${theme('spacing.s')};
  width: ${props => `${props.size}px`};
`

export default PlaceholderImage
