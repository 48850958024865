import A from 'components/UIKit/A'
import { graphql } from '@apollo/client/react/hoc'
import get from 'lodash-es/get'
import QUERY from 'client/queries/getSalesforceInstanceUrlQuery'

const withInstanceUrl = graphql(QUERY, {
  props({ ownProps, data } = {}) {
    const instanceUrl = get(data, 'Salesforce.instanceUrl')
    if (data.loading || !instanceUrl) return { href: '#' }

    return {
      href: `${instanceUrl}/${ownProps.resourceId}`,
      target: '_blank',
    }
  },
})

const SalesforceResourceLink = withInstanceUrl(A)
SalesforceResourceLink.displayName = 'SalesforceResourceLink'

export default SalesforceResourceLink
