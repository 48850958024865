import styled from 'styled-components'

import { TOOL_BAR_HEIGHT_PX } from '../Tools/styled/Container'

export const ASPECT_RATIO = 4 / 3

const BackgroundImage = styled.img`
  height: calc(100vh - ${TOOL_BAR_HEIGHT_PX});
  left: 50%;
  margin-left: calc(-0.5 * ((100vh - ${TOOL_BAR_HEIGHT_PX}) * ${ASPECT_RATIO}));
  object-fit: contain;
  position: absolute;
  pointer-events: none;
  top: 0;
  width: calc((100vh - ${TOOL_BAR_HEIGHT_PX}) * ${ASPECT_RATIO});
`

export default BackgroundImage
