import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Button from './Button'

const EditButton = styled(Button)`
  right: calc(2 * ${theme('spacing.base')} + ${theme('touchUI.buttonSize')});
`

export default EditButton
