import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  margin: -${theme('spacing.xl')} auto 0;
  max-width: 600px;
  text-align: center;
`

export default Container
