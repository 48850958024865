import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { breakpoints, theme } from 'lib/styleUtils'
import { getDockedPanelWidth, getZIndexOf } from 'lib/utils'

import Card from '../../Card'

const getPositionStyles = alignment =>
  `
    border-bottom-${alignment}-radius: 0;
    border-top-${alignment}-radius: 0;
    ${alignment}: 0;
  `

const setPosition = props => {
  if (!props.alignment) {
    return getPositionStyles('left')
  }

  return getPositionStyles(props.alignment)
}

const setSize = props => {
  if (props.responsiveWidth) {
    return css`
      height: calc(100% - ${props.theme.spacing.base});
      max-width: ${props.responsiveWidth + 'px'};
    `
  }

  return css`
    height: calc(100% - ${props.theme.spacing.base});
    max-width: ${props.width || getDockedPanelWidth() + 'px'};
  `
}

const setLandscapeStyles = props => {
  let left, width
  width = `calc(100% - ${props.theme.spacing.base})`
  if (props.isTouchUI) {
    left = `${props.theme.dockedPanel.width.default + 10}px`
    width = `calc(100% - ${props.theme.dockedPanel.width.default + 20}px)`
  } else if (props.flushRight) {
    left = null
  } else {
    left = props.theme.spacing.s
  }
  return css`
    left: ${left};
    right: ${props.flushLeft ? null : props.theme.spacing.s};
    top: auto;
    width: ${width};
  `
}

const setTriggerStyles = props =>
  `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: calc(100% + 1px);
    box-shadow: none;
    left: 0;
    margin: 0;
    min-width: 350px;
    right: auto;
    top: auto;

    ${props.flushLeft && `border-top-left-radius: 0;`};
    ${props.flushRight && `border-top-right-radius: 0;`};
  `

const setHeight = props => {
  if (props.hasToolbar) {
    return `height: auto;`
  }

  return 'height: 100%;'
}

const setBottomOffset = props => {
  if (props.hasToolbar) {
    return `bottom: 60px;`
  }

  return 'bottom: 0;'
}

const setDockedStyles = props =>
  `
    border-${props.isTouchUI ? 'right' : 'left'}: 1px solid ${
    props.theme.colors.light.subdued
  };
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
    margin-top: 0;
  `

/**
 * The optional zIndex param overrides the default
 * z-index value for panels
 */
const zIndex = ({ landscape, zIndex }) => {
  const zIndexString = landscape ? 'dropdown' : 'panel'

  return `
    z-index: ${getZIndexOf(zIndex || zIndexString)};
  `
}

const stretchContainer = ({ stretchContainer }) =>
  stretchContainer &&
  `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const StyledCard = styled(Card)`
  ${setBottomOffset};
  margin-bottom: ${theme('spacing.s')};
  margin-top: ${theme('spacing.s')};
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;

  ${stretchContainer};
  ${zIndex};
  ${props => !props.renderTrigger && !props.landscape && setPosition(props)};
  ${props => !props.renderTrigger && !props.landscape && setSize(props)};
  ${props => props.landscape && setLandscapeStyles(props)};
  ${props => props.renderTrigger && setTriggerStyles(props)};
  ${props => props.docked && setDockedStyles(props)};
  ${breakpoints.m`
    ${props => props.width && `width: ${props.width};`}
  `};
  ${props => props.docked && setHeight(props)};
`

StyledCard.propTypes = {
  isTouchUI: PropTypes.bool,
}

export default StyledCard
