import React from 'react'
import styled, { css } from 'styled-components'

import { theme } from 'lib/styleUtils'
import { FontSize, Spacing, Variant } from '~/config/theme'

const e = React.createElement

const disallowedProps = [
  'iconLeft',
  'iconRight',
  'inline',
  'labelWidth',
  'multiline',
  'noResize',
  'overrideValue',
] as const
type disallowedProps = (typeof disallowedProps)[number]

const filteredProps = <T extends Record<string, any>>(props: T): Omit<T, disallowedProps> => {
  return Object.fromEntries(Object.entries(props).filter(prop => !disallowedProps.includes(prop[0] as disallowedProps))) as Omit<T, disallowedProps>
}

const getTag = ({ multiline }: { multiline?: boolean | undefined }) => (multiline ? 'textarea' : 'input')

type StyleProps = { size?: FontSize & Spacing; variant?: Variant; width?: string; disabled?: boolean; iconLeft?: boolean; iconRight?: boolean; noResize?: boolean }
const styles = css<StyleProps>`
  background-color: ${props =>
    props.variant === 'dark'
      ? props.theme.colors.dark.bg
      : props.theme.colors.light.bg};
  border: 0;
  border: ${theme('borderWidth')} solid ${theme('colors.light.subdued')};
  border-radius: ${theme('radius.base')};
  box-sizing: border-box;
  color: ${props =>
    props.variant === 'dark'
      ? props.theme.colors.dark.fg
      : props.theme.colors.light.fg};
  font-family: ${theme('fonts.family.base')};
  font-size: ${props =>
    props.size
      ? props.theme.fonts.sizes[props.size]
      : props.theme.fonts.sizes.base};
  line-height: 1.2;
  padding: ${props =>
    props.size ? props.theme.spacing[props.size] : props.theme.spacing.s};
  transition: border-color 0.3s ease-in-out;
  max-width: 100%;
  width: ${props => (props.width ? props.width : '100%')};

  &:focus {
    border-color: ${props =>
      props.variant === 'dark'
        ? props.theme.colors.dark.fg
        : props.theme.colors.light.fg};
    outline: none;
  }

  ${props => props.disabled && `opacity: 0.5;`};
  ${props => props.iconLeft && `padding-left: ${props.theme.spacing.l};`};
  ${props => props.iconRight && `padding-right: ${props.theme.spacing.l};`};
  ${props => props.noResize && 'resize: none;'};
`

const Field = styled(({ children, ...props }) =>
  e(getTag(props), filteredProps(props), children)
)<StyleProps>`
  ${styles};
`

export default Field
