import React from 'react'
import PropTypes from 'prop-types'

import Space from 'components/UIKit/Space'

import Button from 'components/UIKit/Button'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Field from 'components/UIKit/TextField/styled/Field'
import Label from 'components/UIKit/Button/styled/Label'
import Select from 'components/UIKit/Select'

import { LETTERS, controllerTypeOptions } from './constants'
import { ControllerTypeSelect } from 'components/Panels/SelectedUtilityBoxPanel/ControllerTypeSelect'

const UtilityBoxesTab = ({
  utilityBoxesArray,
  onUpdateUtilityBoxes,
  productsArray,
  onUpdateProducts,
}) => {
  return (
    <>
      <Space>
        <Grid>
          <GridItem size="1of6">
            <div>
              <b>Detailed Label</b>
              <Button
                noBorder
                icon="refresh"
                responsive
                onClick={e => {
                  const newUtilityBoxes = utilityBoxesArray.map((p, j) => {
                    let newLabel = ''
                    const labelLength = Math.floor(j / 26) + 1
                    for (let i = 0; i < labelLength; i++) {
                      newLabel += LETTERS[j % LETTERS.length]
                    }
                    return { ...p, detailedLabel: newLabel }
                  })
                  onUpdateUtilityBoxes(newUtilityBoxes)
                }}
              />
            </div>
          </GridItem>
          <GridItem size="1of6">
            <b>Type</b>
          </GridItem>
          <GridItem size="1of6">
            <b>Voltage</b>
          </GridItem>
          <GridItem size="1of6">
            <b>Brand</b>
          </GridItem>
          <GridItem size="1of6">
            <b>Controller Type</b>
          </GridItem>
        </Grid>
      </Space>
      {utilityBoxesArray.map((utilityBox, index) => {
        return (
          <Space key={index} bottom="m">
            <Grid>
              <GridItem size="1of6">
                <Field
                  width="80%"
                  value={utilityBox.detailedLabel || ''}
                  onChange={e => {
                    const value = e.target.value
                    const newUtilityBox = {
                      ...utilityBox,
                      detailedLabel: value,
                    }
                    onUpdateUtilityBoxes([newUtilityBox])
                  }}
                />
              </GridItem>
              <GridItem size="1of6">
                <b>{utilityBox.label}</b>
              </GridItem>
              <GridItem size="1of6">
                <b>
                  {utilityBox.label === 'Controller'
                    ? 'N/A'
                    : utilityBox.voltage || utilityBox.voltageOther || ''}
                </b>
              </GridItem>
              <GridItem size="1of6">
                <b>
                  {utilityBox.label === 'Controller'
                    ? 'BAF'
                    : utilityBox.brand || utilityBox.brandOther || ''}
                </b>
              </GridItem>
              <GridItem size="1of6">
                {utilityBox.label === 'Controller' ? (
                  <ControllerTypeSelect
                    value={utilityBox.controllerType}
                    onChange={e => {
                      const value = e.target.value
                      const newUtilityBox = {
                        ...utilityBox,
                        controllerType: value,
                      }
                      onUpdateUtilityBoxes([newUtilityBox])
                    }}
                  />
                ) : (
                  <b>N/A</b>
                )}
              </GridItem>
            </Grid>
          </Space>
        )
      })}
      <Space bottom="base">
        <Select
          name="controllerType"
          label="Controller Type"
          inline
          value={productsArray[0].controllerType || ''}
          options={controllerTypeOptions}
          onChange={e => {
            const newProducts = productsArray.map(p => ({
              ...p,
              controllerType: e.target.value,
            }))
            onUpdateProducts(newProducts)
          }}
        />
      </Space>
      {productsArray[0].controllerType === 'Other' && (
        <Space bottom="base">
          <Label inline bold>
            <b style={{ fontSize: 12, marginRight: 10 }}>Specify</b>
          </Label>
          <Field
            title="test"
            value={productsArray[0].controllerOther || ''}
            width="15%"
            onChange={e => {
              const value = e.target.value
              const newProducts = productsArray.map(p => ({
                ...p,
                controllerOther: value,
              }))
              onUpdateProducts(newProducts)
            }}
          />
        </Space>
      )}
      <Space bottom="base">
        <Label inline>
          <b style={{ fontSize: 12, marginRight: 10 }}>Controller Quantity</b>
          <Field
            type="number"
            width="15%"
            value={productsArray[0].controllerQty || ''}
            onChange={e => {
              const value = e.target.value
              const newProducts = productsArray.map(p => ({
                ...p,
                controllerQty: value,
              }))
              onUpdateProducts(newProducts)
            }}
          />
        </Label>
      </Space>
    </>
  )
}

UtilityBoxesTab.propTypes = {
  utilityBoxesArray: PropTypes.array,
  onUpdateUtilityBoxes: PropTypes.func,
  productsArray: PropTypes.array,
  onUpdateProducts: PropTypes.func,
}

export default UtilityBoxesTab
