import React, { Component } from 'react'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import mapProps from 'recompact/mapProps'
import qs from 'qs'
import PropTypes from 'prop-types'

import { AUTHORIZE_SALESFORCE_MUTATION } from 'client/mutations'
import Loader from 'components/UIKit/Loader'

class SalesforceCallbackScreen extends Component {
  static defaultProps = {
    error: undefined,
    authorizeCode() {},
  }

  static propTypes = {
    authorizeCode: PropTypes.func,
    error: PropTypes.string,
    history: PropTypes.object,
  }

  componentDidMount() {
    this.props
      .authorizeCode()
      .then(() => {
        const lastViewedFacilityId = window.localStorage.getItem(
          'lastViewedFacilityId'
        )
        if (lastViewedFacilityId) {
          this.props.history.push({
            pathname: `/facility/${lastViewedFacilityId}`,
            state: {
              getQuote: true,
            },
          })
        } else {
          this.props.history.push('/')
        }
      })
      .catch(console.error)
  }

  get hasError() {
    return !isEmpty(this.props.error)
  }

  render() {
    if (this.hasError) return <p>Unable to authorize</p>
    return <Loader centered label="Authorizing" />
  }
}

const enhance = compose(
  mapProps(props => {
    const queryParams = qs.parse(get(props, 'location.search'), {
      ignoreQueryPrefix: true,
    })
    return {
      code: get(queryParams, 'code'),
      error: get(queryParams, 'error'),
    }
  }),
  graphql(AUTHORIZE_SALESFORCE_MUTATION, {
    props: ({ ownProps, mutate }) => ({
      authorizeCode: () =>
        mutate({
          variables: {
            redirectUrl: `https://${window.location.host}${window.location.pathname}`,
            code: ownProps.code,
          },
        }),
    }),
  })
)

export default enhance(withRouter(SalesforceCallbackScreen))
