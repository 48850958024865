import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.b`
  color: ${theme('colors.light.subdued')};
  display: block;
  font-size: ${theme('fonts.sizes.xs')};
  font-weight: ${theme('fonts.weights.normal')};
`

export default Container
