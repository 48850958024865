import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import P from './styled/P'

const defaultOptions = {
  ALLOWED_TAGS: ['b', 'i', 'u', 'a'],
  ALLOWED_ATTR: ['href', 'target'],
}

const sanitize = (dirty, options) => ({
  __html: DOMPurify.sanitize(dirty, { ...defaultOptions, ...options }),
})

const SanitizeHTML = ({ html, options, tabOver }) => (
  <P dangerouslySetInnerHTML={sanitize(html, options)} tabOver={tabOver} />
)

SanitizeHTML.propTypes = {
  html: PropTypes.string.isRequired,
  options: PropTypes.object,
  tabOver: PropTypes.number,
}

export default SanitizeHTML
