import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const ThumbnailContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  height: ${props => props.height}px;
  margin-right: ${theme('spacing.s')};
  margin-top: ${theme('spacing.xs')};
  margin-bottom: ${theme('spacing.xs')};
  position: relative;
  width: ${props => props.width}px;
`

export default ThumbnailContainer
