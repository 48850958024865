import { useAppSelector } from "~/store/hooks";
import styled from 'styled-components'
import Button from 'components/UIKit/Button'
import { useRouteMatch } from 'react-router-dom'
import routes from 'config/routes'
import { useCFDStatusFragment } from 'hooks/useCFDStatus'
import theme from 'config/theme'

const TooltipButton = styled(Button)`
  position: relative;
  &:hover::after {
    color: white;
    background-color: ${theme.colors.dark.extraLight};
    padding: 0.25em 0.5em;
    content: '${props => props.tooltip ?? ''}';
    width: 100%;
    position: absolute;
    top: calc(-100% - 0.5em);
    border-radius: 3px;
  }
  &:hover::before {
    position: absolute;
    content: '';
    background-color: ${theme.colors.dark.extraLight};
    width: 20px;
    height: 20px;
    top: -100%;
    transform: rotate(45deg);
  }
`

export const ConfigureCFDButton = () => {
  const { cfdStatus, complete } = useCFDStatusFragment()
  const match = useRouteMatch()
  const products = useAppSelector(({ objects }) => objects.present.products)

  const hasInvalidProduct = Object.values(products).some(product => product?.isOutOfBounds === true)
  const isDisabled = hasInvalidProduct || !complete || cfdStatus?.isSomeInProgress

  const buttonTitleText = cfdStatus?.isSomeInProgress
    ? 'Please cancel existing simulation before running a new one.'
    : hasInvalidProduct
    ? 'A fan is out of bounds. Please delete or move the fan to a valid location'
    : 'CFD Simulation'

  return (
    <TooltipButton
      disabled={isDisabled}
      tooltip={buttonTitleText}
      primary
      label="Configure CFD"
      to={`${match.url}${routes.modals.configureCFD}`}
    />
  )
}
