import styled from 'styled-components'

import { breakpoints } from 'lib/styleUtils'

const Container = styled.div<{ inline?: boolean }>`
  ${props =>
    props.inline &&
    `
  align-items: center;
  `};

  align-items: left;
  display: flex;
  flex-direction: row;

  ${breakpoints.s`
    display: block;
  `};

  ${breakpoints.m`
    display: flex;
  `};

  ${breakpoints.l`
    display: flex;
  `};
`

export default Container
