import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNetwork } from 'networkProvider'

import { getTitle } from 'config/titles'
import withUser from 'client/decorators/withUser'

import Dashboard from 'components/Dashboard'
import FAQ from './FAQ'

/**
 * main page for frequently asked questions, with table of contents
 * @param {Object} props
 * @returns FAQ Screen
 */
const FAQScreen = props => {
  const network = useNetwork()
  const [open, setOpen] = useState(false)
  const handleOpen = event => {
    setOpen(!open)
  }
  return (
    <>
      <Helmet>
        <title>{getTitle('faq')}</title>
      </Helmet>
      <Dashboard noAction={!network.online} push={open} sticky wide>
        <FAQ handleOpen={handleOpen} open={open} />
      </Dashboard>
    </>
  )
}

// TODO: comment required in both places to trick intellisense

/**
 * main page for frequently asked questions, with table of contents
 * @param {Object} props
 * @returns FAQ Screen
 */
export default withUser(FAQScreen)
