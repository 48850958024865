import defaultTo from 'lodash-es/defaultTo'
import Color from 'color'
import sample from 'lodash-es/sample'

import Util from './util'
import Units from './units'
import Primitives from './primitives'
import theme from 'config/theme'
import getRenderOrder from 'config/canvasRenderOrder'
import { objectIsSelected } from 'store/selectedObjects/selectors'
import { updateCeiling } from 'store/objects'
import store from 'store'
import OBJECT_TYPES from 'config/objectTypes'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import { getThreeHexFromTheme } from 'lib/utils'
import * as THREE from 'three'
import CLICK_PRIORITY from 'config/clickPriority'

class Ceiling {
  static borderColor = getThreeHexFromTheme('three.objects.ceiling.border')

  constructor(model, units) {
    this.id = model.id
    this.className = CLASS_NAMES.CEILING
    this.units = units
    this.layerKey = defaultTo(model.category, model.layerKey)
    this.clickPriority = CLICK_PRIORITY[this.layerKey]
    this.perimeterPoints = model.perimeterPoints
    this.height = Units.unitsToNative(units, model.height)
    this.enabled = model.enabled
    this.color = model.color
    this.wallId = model.wallId

    this.createVisual()
    // Used by Interactifier
    this.selectable = true
    this.draggable = false
  }

  sceneDidRebuild(facility, thisChanged) {
    // If ceilings disabled and ceiling layer is locked, scale down ceiliing
    // so it doesn't get in the way when trying to select the floor underneath
    if (!this.enabled && this.isLayerLocked()) {
      this.obj3d.scale.set(0.00001, 0.00001, 0.00001)
      return
    } else if (this.obj3d.scale.x !== 1) this.obj3d.scale.set(1, 1, 1)

    if (!this.wallId && thisChanged) {
      const wallId = this.getParentWallId(facility)
      if (wallId) {
        this.wallId = wallId
        store.dispatch(updateCeiling({ ceiling: this.toModel() }))
      }
    }
  }

  createVisual() {
    this.obj3d = this.enabled
      ? this.createEnabledVisual()
      : this.createDisabledVisual()
    this.obj3d.position.z = this.height
    this.obj3d.wrapperId = this.id
    this.obj3d.renderOrder = getRenderOrder('ceilings')
    this.obj3d.userData.objectType = OBJECT_TYPES.CEILING

    if (objectIsSelected(this.id)) {
      this.select()
    }
  }

  createEnabledVisual() {
    return Primitives.getRegionMesh(this.perimeterPoints, this.color)
  }

  createDisabledVisual() {
    const material = Primitives.getOutlineMaterial(
      this.perimeterPoints,
      Ceiling.borderColor,
      Units.feetToNative(2.5), // Switch to 1.5 once regions are detected on inset polygons
      0
    )

    return Primitives.getRegionMesh(
      this.perimeterPoints,
      getThreeHexFromTheme('three.light'),
      material
    )
  }

  getParentWallId(facility) {
    // get ceiling positions from geometry attribute
    const points = this.obj3d.geometry.attributes.position.array
    const positions = []
    for (var i = 0; i < points.length - 2; i = i + 3) {
      positions.push({
        x: points[i],
        y: points[i + 1],
        z: points[i + 2],
      })
    }

    const intersects = []
    positions.forEach(pos => {
      const negativeZ = new THREE.Vector3(0, 0, -1)
      const options = {
        includedTypes: [OBJECT_TYPES.WALL],
        measureTo: facility.CENTER,
      }

      const hits = facility.measureObjectsInDirectionFromPoint(
        negativeZ,
        new THREE.Vector3(pos.x, pos.y, 400),
        options
      )

      const interiorWalls = hits.filter(
        wall => wall.wrapper.layerKey === 'INTERIOR_WALLS'
      )

      if (!interiorWalls.length) return

      intersects.push(interiorWalls[0])
    })

    if (!intersects.length) return

    return intersects[0].wrapper.id
  }

  /*
    SceneBuilder event
  */
  visibilityChanged(visible) {
    if (this.obj3d) this.obj3d.visible = visible
  }

  toModel() {
    return {
      id: this.id,
      layerKey: this.layerKey,
      enabled: this.enabled,
      perimeterPoints: this.perimeterPoints,
      height: Units.nativeToUnits(this.units, this.height),
      wallId: this.wallId,
    }
  }

  static createModel(perimeterPoints, height = 97) {
    return {
      id: Util.guid(),
      layerKey: LAYER_KEYS.CEILINGS,
      enabled: false,
      color: sample(theme.colors.swatches),
      perimeterPoints,
      height,
    }
  }

  isLayerLocked() {
    const isLocked = store.getState().layers.layers[LAYER_KEYS.CEILINGS].locked
    return isLocked
  }

  mouseEntered(mousePos, snappedMousePos) {
    if (this.isLayerLocked()) return

    if (!this.enabled && !objectIsSelected(this.id)) {
      this.obj3d.material.uniforms.outlineOpacity.value = 0.5
    }

    this.hasMouse = true
  }

  mouseExited(mousePos, snappedMousePos) {
    if (!this.enabled && !objectIsSelected(this.id)) {
      this.obj3d.material.uniforms.outlineOpacity.value = 0.0
    }

    this.hasMouse = false
  }

  getSnappableEdgePoints(objectBeingDragged) {}

  snap(snapDelta, object, snappedMousePos) {}

  select() {
    if (this.enabled) {
      const currentColor = Color(this.color)
      const selectedColor = currentColor.darken(0.2).rgbNumber()
      this.obj3d.material.color.setHex(selectedColor)
    } else {
      this.obj3d.material.uniforms.outlineOpacity.value = 1
    }
  }

  deselect() {
    if (this.enabled) {
      this.obj3d.material.color.setHex(Color(this.color).rgbNumber())
    } else {
      this.obj3d.material.uniforms.outlineOpacity.value = this.hasMouse
        ? 0.5
        : 0
    }
  }

  drag() {}

  drop(object) {}

  destroy() {}
}

export default Ceiling
