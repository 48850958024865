import styled, { css } from 'styled-components'

import { getZIndexOf, getDockedPanelWidth } from 'lib/utils'

const colors = props => {
  if (props.variant === 'dark') {
    return css`
      background-color: ${props.theme.colors.dark.bg};
      color: ${props.theme.colors.dark.fg};
    `
  }

  return css`
    background-color: ${props.theme.colors.light.bg};
    color: ${props.theme.colors.light.fg};
  `
}

const setPanelToolbarPosition = ({ panelToolbarPosition, isTouchUI }) => {
  if (isTouchUI && panelToolbarPosition === 'bottom') {
    return `
      bottom: 0;
      position: absolute;
      width: ${getDockedPanelWidth()}px;
      z-index: ${getZIndexOf('panelToolbar')};
    `
  }

  return null
}

const Container = styled.div`
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  height: ${props => (props.size ? props.theme.spacing[props.size] : '42px')};
  min-height: ${props =>
    props.size ? props.theme.spacing[props.size] : '42px'};
  width: 100%;

  ${colors};

  ${props =>
    props.borderBottom &&
    `border-bottom: 1px solid ${props.theme.colors.light.subdued};`}

  ${props =>
    props.borderTop &&
    `border-top: 1px solid ${props.theme.colors.light.subdued};`}
  ${setPanelToolbarPosition}
  
  ${props => props.sticky && `position: fixed;`}
`

export default Container
