import "./apolloCacheSettings"
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { history } from './config/sentry'
import { ThemeProvider } from 'styled-components'

import './config/sentry'

import 'normalize.css'
import './ui/index.css'

import { NetworkProvider } from 'networkProvider'
import { ServiceWorkerProvider } from 'serviceWorkerProvider'

import GlobalStyles from './config/globalStyles'
import FilestackStyles from './config/filestackStyles'
import theme from './config/theme'

import store from './store'
import client, { ApolloProvider } from './client'
import { checkCacheClearStatus } from 'config/persistor'

import App from 'components/App'
import Loader from 'components/UIKit/Loader'

import { initializeAnalytics, trackPage } from './lib/analytics'

initializeAnalytics()
history.listen((location, action) => trackPage(location.pathname))

const setupAndRender = async () => {
  // Render the loader before loading the cache
  // as it may take a few seconds,
  // and we don't want a blank screen
  const container = document.getElementById('root')
  const root = createRoot(container)

  root.render(
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Loader centered sublabel="Loading cache..."></Loader>
      </>
    </ThemeProvider>,
  )

  await checkCacheClearStatus(client)

  root.render(
    <ApolloProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ServiceWorkerProvider>
            <GlobalStyles />
            <FilestackStyles />
            <NetworkProvider>
              <App history={history} />
            </NetworkProvider>
          </ServiceWorkerProvider>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>,
  )
}

setupAndRender()
