import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import FileSaver from 'file-saver'
import get from 'lodash-es/get'

import { getTitle } from 'config/titles'
import { showAlert } from 'store/alert'
import { loadFacility, resetFacility } from 'store/objects'
import { saveObjects } from 'store/objectsPersistence'

import Button from 'components/UIKit/Button'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Icon from 'components/UIKit/Icon'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'

import FlippedIcon from './styled/FlippedIcon'
import FileInput from './styled/FileInput'
import ModalContent from './styled/ModalContent'

function ImportExportFacilityDataModal(props = {}) {
  const [exporting, setExporting] = useState(false)

  function handleImport(event) {
    const versionId = get(props, 'facility.floor.version.id')
    const reader = new FileReader()
    reader.addEventListener('load', e => {
      const data = get(reader, 'result')
      if (data) {
        try {
          const _data = JSON.parse(data)
          props.onUpdateObjects({ payload: _data, versionId })
          props.onShowAlert({
            type: 'success',
            text: 'Facility data imported',
          })
        } catch (e) {
          props.onShowAlert({
            type: 'error',
            text: 'Problem with import file. Bad format.',
          })
        }
      }
    })
    const file = get(event, 'target.files[0]')
    reader.readAsText(file)
    props.history.push(props.parentRoute)
  }

  function handleExport(event) {
    event.preventDefault()
    const versionData = get(props, 'facility.floor.version.data')
    const blob = new Blob([JSON.stringify(versionData)], {
      type: 'application/json',
    })
    const filename = `${get(props, 'facility.name').replace(' ', '-')}-${get(
      props,
      'facility.floor.id'
    )}-${get(props, 'facility.floor.version.id')}.json`
    setExporting(true)
    FileSaver.saveAs(blob, filename)
    props.onShowAlert({
      type: 'success',
      text: 'Facility data exported',
    })
    setExporting(false)
  }

  return (
    <>
      <Helmet>
        <title>{getTitle('importExportFacilityData')}</title>
      </Helmet>
      <Modal title="Import &amp; Export Facility Data" {...props}>
        <ModalContent>
          <Grid>
            <GridItem size="1of2">
              <Space bottom="s">
                <h3>
                  <Icon
                    name="download"
                    color="subdued"
                    size="16"
                    marginRight="s"
                  />
                  Import
                </h3>
              </Space>
              <Space bottom="s">
                <FileInput type="file" onChange={handleImport} />
              </Space>
              <VariantText color="light" size="s">
                Select the exported JSON file and it will overwrite the data in
                the current version of this facility.
              </VariantText>
            </GridItem>
            <GridItem size="1of2">
              <Space bottom="s">
                <h3>
                  <FlippedIcon
                    name="download"
                    color="subdued"
                    size="16"
                    marginRight="s"
                  />
                  Export
                </h3>
              </Space>
              <Space bottom="s">
                <Button
                  label={`${
                    exporting
                      ? 'Exporting Facility Data...'
                      : 'Export Facility Data'
                  }`}
                  onClick={handleExport}
                />
              </Space>
              <VariantText color="light" size="s">
                This will download a JSON file to your computer that you can
                then import to any facility (on any environment) to overwrite
                its data.
              </VariantText>
            </GridItem>
          </Grid>
        </ModalContent>
      </Modal>
    </>
  )
}

ImportExportFacilityDataModal.propTypes = {
  history: PropTypes.object,
  onShowAlert: PropTypes.func,
  onUpdateObjects: PropTypes.func,
  parentRoute: PropTypes.string,
}

const mapDispatchToProps = dispatch => ({
  onShowAlert({ text, type }) {
    dispatch(showAlert({ text, type }))
  },
  onUpdateObjects({ payload, versionId }) {
    dispatch(resetFacility())
    dispatch(loadFacility({ units: payload.units, data: payload }))
    dispatch(saveObjects({ versionId }))
  },
})

export default compose(
  appConnect(null, mapDispatchToProps),
  withRouter
)(ImportExportFacilityDataModal)
