import withProps from 'recompact/withProps'
import uniqBy from 'lodash-es/uniqBy'
import capitalize from 'lodash-es/capitalize'
import Select from 'components/UIKit/Select'

const withFanSpeeds = withProps(ownProps => {
  const defaultFanSpeedOptions = ['75%', '80%', 'SPEED 5']
  const fanSpeeds = ownProps.fanSpeeds
  const fanSpeedOptions = uniqBy(fanSpeeds, 'speed')
    .filter(
      speed =>
        (speed.overheadOnly && ownProps.mountedOn === 'OVERHEAD') ||
        !speed.overheadOnly
    )
    .map(speed => ({
      value: speed.speed,
      label: capitalize(speed.speed),
    }))
  let value = ownProps.value
  if (fanSpeedOptions.findIndex(opt => opt.value === value) < 0) {
    value = defaultFanSpeedOptions.find(
      speed => fanSpeedOptions.findIndex(opt => opt.value === speed) >= 0
    )
  }

  return {
    disabled: ownProps.disabled,
    value,
    options: [
      { value: '', label: 'Choose speed...', disabled: true },
      ...fanSpeedOptions,
    ],
  }
})

const SpeedSelect = withFanSpeeds(Select)
SpeedSelect.displayName = 'SpeedSelect'

export default SpeedSelect
