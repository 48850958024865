import styled from 'styled-components'

import Image from 'components/UIKit/Image'

const StyledImage = styled(Image)`
  height: auto;
  left: 50%;
  max-height: ${props => props.height}px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`

export default StyledImage
