import React from 'react'
import PropTypes from 'prop-types'
import { useNetwork } from 'networkProvider'

import Icon from 'components/UIKit/Icon'
import Image from 'components/UIKit/Image'
import Close from '../styled/Close'
import ThumbnailContainer from '../styled/ThumbnailContainer'

const Thumbnail = ({ src, width, height, onDelete, onClickHandler }) => {
  const network = useNetwork()

  return (
    <ThumbnailContainer width={width} height={height}>
      <Image
        alt={`metadata ${src}`}
        height={height}
        onClick={onClickHandler}
        src={src}
        width={width}
      />
      {onDelete && network.online && (
        <Close onClick={onDelete}>
          <Icon name="cross" size={12} />
        </Close>
      )}
    </ThumbnailContainer>
  )
}

Thumbnail.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onDelete: PropTypes.func,
  onClickHandler: PropTypes.func,
}

export default Thumbnail
