import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Container from './styled/Container'
import Content from './styled/Content'
import Title from './styled/Title'
import Icon from './styled/Icon'
import IconRight from './styled/IconRight'

export const COLLAPSIBLE_PANEL_HEIGHT = 325

class PanelSection extends Component {
  state = {
    collapsed: true,
  }

  static defaultProps = {
    height: COLLAPSIBLE_PANEL_HEIGHT,
  }

  toggleCollapse = event => {
    event.preventDefault()

    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  renderHeader() {
    const { icon, title, collapsible } = this.props
    const { collapsed } = this.state

    if (!title) {
      return null
    }

    if (collapsible) {
      return (
        <Title collapsible={collapsible} onClick={this.toggleCollapse}>
          {icon && <Icon name={icon} size="14" />}
          {title}
          <IconRight
            name="arrowUp"
            className="caret"
            size={14}
            isDropdownVisible={collapsed}
          />
        </Title>
      )
    }

    return (
      <Title>
        {icon && <Icon name={icon} size="14" />}
        {title}
      </Title>
    )
  }

  render() {
    const { height, collapsible, children, title, ...props } = this.props
    const { collapsed } = this.state

    return (
      <Container
        title={title}
        collapsible={collapsible}
        collapsed={collapsed}
        height={height}
        {...props}
      >
        {this.renderHeader()}
        <Content collapsible={collapsible} height={height}>
          {(!collapsible || !collapsed) && children}
        </Content>
      </Container>
    )
  }
}

PanelSection.propTypes = {
  children: PropTypes.node,
  collapsible: PropTypes.bool,
  height: PropTypes.number,
  icon: PropTypes.node,
  props: PropTypes.object,
  title: PropTypes.string,
}

export default PanelSection
