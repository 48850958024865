import { graphql } from '~/gql'

const BUCKET_QUERY = graphql(`
  query Bucket {
    Bucket {
      ...BucketFragment
    }
  }
`)

export default BUCKET_QUERY
