import { Fragment, RefCallback, useCallback, useMemo } from 'react'
import { createPortal, useThree } from '@react-three/fiber'
import FloatingElementManager, { FloatingElement } from './lib/floatingElementManager'
import { Html } from '@react-three/drei'
import { Vector3 } from 'three'
import Facility from './lib/facility'
import { CalculatePosition } from '@react-three/drei/web/Html'

const zIndexRange = [0, 0]

function Element({ element }: { element: FloatingElement }) {
  const camera = useThree(it => it.camera.type)
  const calculatePosition = useCallback<CalculatePosition>((object, camera, size) => {
    const facilityObject = ((Facility as any).current as Facility).findObjectWithId(element.objectId)
    const objectPos = (new Vector3()).setFromMatrixPosition(object.matrixWorld)
    if (facilityObject && facilityObject.labelPos) {
      objectPos.copy(facilityObject.labelPos)
    }
    objectPos.project(camera)
    const widthHalf = size.width / 2
    const heightHalf = size.height / 2
    return [objectPos.x * widthHalf + widthHalf - element.element.offsetWidth * element.offset.x, -(objectPos.y * heightHalf) + heightHalf - element.element.offsetHeight * element.offset.y]
  }, [element])
  const style = useMemo(() => ({width:"200px"}), [])
  const append = useCallback<RefCallback<HTMLDivElement>>(ref => { ref?.appendChild(element.element) }, [element])
  return (
    <Fragment key={`${element.objectId}${camera}`}>
      {createPortal(<Html key={`${element.objectId}${camera}`} zIndexRange={zIndexRange} calculatePosition={calculatePosition}>
        <div key="content" style={style} ref={append}/>
      </Html>, element.objectToFollow)}
    </Fragment>
  )
}

export function FloatingElementBridge() {
  const elements = FloatingElementManager.useFloatingElements(it => it.elements)
  const camera = useThree(it => it.camera.type)
  return (
    <>
      {elements.map(element => <Element key={`${element.objectId}${camera}`} element={element}/>)}
    </>
  )
}