import React from 'react'
import { arrayOf, bool, func, number, object, objectOf, oneOfType, shape, string } from 'prop-types'
import capitalize from 'lodash-es/capitalize'
import get from 'lodash-es/get'
import padStart from 'lodash-es/padStart'

import LAYER_KEYS from 'config/layerKeys'

import PDF from '../ExportablePDF'
import ErrorMessage from 'screens/FacilityScreen/styled/ErrorMessage'
import Loader from 'components/UIKit/Loader'
import { useZoneMetrics } from 'components/PerformanceMetrics/hooks/useZoneMetrics'
import { useCFDImagesFlat } from 'components/Modals/CFDImagesModal/hooks/useCFDImages'
import { SIMULATION_TYPES } from 'config/cfd'

function selectedProducts({ fans, objects, objectsToShow, objectImages }) {
  if (!objectsToShow) return
  const products = {}

  Object.entries(objectsToShow.products)
    .filter(([key, data]) => data.options.isChecked)
    .forEach(([key, data]) => {
      const images = objectImages[key]
      products[key] = {
        options: get(data, 'options'),
        data: {
          images,
          notes: get(data, 'options.notes'),
          title: get(data, 'options.title'),
        },
      }
    })
  return products
}

function selectedOthers({ objectsToShow, objects, objectImages }) {
  const objectTypes = {}

  const others = {}

  Object.entries(objectsToShow.others)
    .filter(([key, data]) => data.options.isChecked)
    .forEach(([key, data], i) => {
      const object = objects.others[key]
      const images = objectImages[key]
      let objectType = object.label
      if (!objectType) {
        if (object.obstructionType) {
          const obstructionType = object.obstructionType
          objectType = obstructionType
            .split(/(?=[A-Z])/)
            .map(part => capitalize(part.trim()))
            .join(' ')
        } else {
          objectType = object.className
        }
      }
      objectTypes[objectType] = objectType in objectTypes ? objectTypes[objectType] + 1 : 1
      const identifier = objectType
        .split(' ')
        .map(word => word && word[0].toUpperCase())
        .join('')
      const otherData = {
        tag: `${identifier}-${padStart(objectTypes[objectType], 2, '0')}`,
        title: get(object, 'metadata.title'),
        dimensions: {},
        metadata: object.metadata,
        images,
        options: {
          notes: data.options.notes,
          images: data.options.images,
        },
      }

      switch (object.layerKey) {
        case LAYER_KEYS.UTILITY_BOXES:
          otherData.dimensions.w = object.width
          otherData.dimensions.h = object.height
          break
        case LAYER_KEYS.OBSTRUCTIONS:
          let minX = Infinity
          let minY = Infinity
          let maxX = -Infinity
          let maxY = -Infinity
          object.positions.forEach(pos => {
            minX = Math.min(minX, pos.x)
            minY = Math.min(minY, pos.y)
            maxX = Math.max(maxX, pos.x)
            maxY = Math.max(maxY, pos.y)
          })
          otherData.dimensions.w = maxX - minX
          otherData.dimensions.h = maxY - minY
          otherData.dimensions.d = object.height
          break
        default:
          break
      }

      others[key] = {
        options: {
          isChecked: true,
          notes: data.options.notes,
          images: data.options.images,
        },
        data: { ...otherData },
      }
    })
  return others
}

function PreviewPDF({
  additionalImages,
  imagesPerPage,
  comfortZones,
  contactInfo,
  comments,
  customerName,
  fans,
  fileName,
  handlePdfDidRender,
  logoSrc,
  mainImage,
  objects,
  objectsLoading,
  objectImages,
  objectsToShow,
  pdfType,
  rendered,
  salesRepEmail,
  salesRepName,
  savedMainImageSnapshot,
  setExportFunction,
  showComments,
  showAdditionalImages,
  showDocumentExplanation,
  showDetailedView,
  detailedType,
  showFPMGrid,
  showObjectInventory,
  showDeckHeight,
  facilityUnits,
  showSchedule,
  versionId,
  name,
  showMountStructureLines,
  showDimensionLines,
  coolingImagesPerPage,
  destratImagesPerPage,
  heatingImagesPerPage,
  selectedCoolingImages,
  selectedDestratImages,
  selectedHeatingImages,
}) {
  const objectsWithData = showObjectInventory
    ? {
        products: selectedProducts({
          fans,
          objects,
          objectsToShow,
          objectImages,
        }),
        others: selectedOthers({
          objectsToShow,
          objects,
          objectImages,
        }),
      }
    : {}

  const { comfortZoneMetrics, error: zoneMetricsError, loading: zoneMetricsLoading } = useZoneMetrics(comfortZones)
  const {data: cfdImages, error: cfdImagesError, loading: cfdImagesLoading} = useCFDImagesFlat()
  if (zoneMetricsLoading) {
    return <Loader centered label="Calculating zone metrics…" />
  } else if (cfdImagesLoading) {
    return <Loader centered label="Calculating zone metrics…" />
  } else if (zoneMetricsError) {
    console.error(zoneMetricsError)
    return <ErrorMessage>Failed to get zone metrics</ErrorMessage>
  } else if (cfdImagesError) {
    console.error(cfdImagesError)
    return <ErrorMessage>Failed to get CFD images</ErrorMessage>
  } else {
    const coolingImages = cfdImages.filter(({goal}) => goal === SIMULATION_TYPES.cooling).map(({images}) =>images).flat()
    const selectedCoolingUrls = selectedCoolingImages.map(imageId => coolingImages.find(({id}) => imageId === id)?.url).filter(url => !!url)
    const destratImages = cfdImages.filter(({goal}) => goal === SIMULATION_TYPES.destrat).map(({images}) =>images).flat()
    const selectedDestratUrls = selectedDestratImages.map(imageId => destratImages.find(({id}) => imageId === id)?.url).filter(url => !!url)
    const heatingImages = cfdImages.filter(({goal}) => goal === SIMULATION_TYPES.radiantHeat || goal === SIMULATION_TYPES.unitHeating).map(({images}) => images).flat()
    const selectedHeatingUrls = selectedHeatingImages.map(imageId => heatingImages.find(({id}) => imageId === id)?.url).filter(url => !!url)
    
    const data = {
      additionalImages: showAdditionalImages ? additionalImages : [],
      imagesPerPage,
      comfortZones: comfortZoneMetrics,
      contactInfo,
      comments: showComments ? comments : '',
      customerName,
      fans,
      logoSrc,
      mainImage,
      objects,
      objectsLoading,
      objectsToShow: objectsWithData,
      pdfType,
      salesRepEmail,
      salesRepName,
      savedMainImageSnapshot,
      showDocumentExplanation,
      showDetailedView,
      detailedType,
      showFPMGrid,
      showObjectInventory,
      showDeckHeight,
      facilityUnits,
      showSchedule,
      versionId,
      name,
      showMountStructureLines,
      showDimensionLines,
      coolingImagesPerPage,
      destratImagesPerPage,
      heatingImagesPerPage,
      selectedCoolingImages: selectedCoolingUrls,
      selectedDestratImages: selectedDestratUrls,
      selectedHeatingImages: selectedHeatingUrls,
    }
    return (
      <PDF
        setExportFunction={setExportFunction}
        fileName={fileName}
        handlePdfDidRender={() => handlePdfDidRender()}
        rendered={rendered}
        data={data}
      />
    )
  }
}

PreviewPDF.propTypes = {
  additionalImages: arrayOf(
    shape({
      base64: string,
    })
  ),
  imagesPerPage: number,
  cfdUrls: arrayOf(object),
  customerName: string,
  contactInfo: object,
  exportFn: func,
  fans: arrayOf(
    shape({
      tag: oneOfType([string, number]),
      quantity: oneOfType([string, number]),
      description: string,
    })
  ),
  handlePdfDidRender: func,
  logoSrc: string,
  mainImage: string,
  objectImages: object,
  objects: objectOf(shape({})),
  objectsToShow: object,
  salesRepName: string,
  salesRepEmail: string,
  fileName: string,
  pdfType: string,
  rendered: bool,
  showDocumentExplanation: bool,
  showDetailedView: bool,
  detailedType: string,
  showComments: bool,
  comments: string,
  comfortZones: arrayOf(object),
  objectsLoading: bool,
  setExportFunction: func,
  savedMainImageSnapshot: shape({}),
  showAdditionalImages: bool,
  showFPMGrid: bool,
  showObjectInventory: bool,
  showDeckHeight: bool,
  facilityUnits: string,
  showSchedule: bool,
  versionId: string,
  name: string,
  showMountStructureLines: bool,
  showDimensionLines: bool,
  coolingImagesPerPage: number,
  destratImagesPerPage: number,
  heatingImagesPerPage: number,
  selectedCoolingImages: arrayOf(string),
  selectedDestratImages: arrayOf(string),
  selectedHeatingImages: arrayOf(string),
}

export default PreviewPDF
