import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'
import flatten from 'lodash-es/flatten'

import { primaryUses } from 'config/facility'

import Select from 'components/UIKit/Select'

function filterData(data, primaryUse) {
  return data && data.length
    ? data.filter(item => {
        if (primaryUse === '') return true
        const primaryUseVerified = getPrimaryUse(item)
        return primaryUseVerified === primaryUse
      })
    : null
}

function getPrimaryUse(item) {
  return (
    get(item, 'facility.primaryUse') || // For templates
    get(item, 'primaryUse') || // for facilities
    get(item, 'value') || // for detailed obstructions
    ''
  )
}

// Get a list of primary uses we've used already in our facilities
function getAvailablePrimaryUses(data, isObstruction = false) {
  let uniquePrimaryUses

  if (isObstruction) {
    const pUses = []
    data.forEach(obs => {
      pUses.push(obs.primaryUses)
    })
    uniquePrimaryUses = new Set(flatten(pUses))
  } else {
    uniquePrimaryUses = new Set()
    data.length &&
      data.forEach(item => {
        uniquePrimaryUses.add(getPrimaryUse(item))
      })
  }

  const foundUses = primaryUses.filter(primaryUse =>
    uniquePrimaryUses.has(primaryUse.value)
  )

  return foundUses
}

const PrimaryUseFilter = (props = {}) => (
  <Select
    inline
    name="filter"
    label="Filter:"
    labelWidth="50px"
    onChange={props.onChange}
    value={props.primaryUse}
    placeholder={props.loading ? 'Loading...' : 'All Primary Uses'}
    disablePlaceholder={false}
    options={
      props.loading
        ? [{ label: 'Loading...', value: '' }]
        : getAvailablePrimaryUses(props.data, props.isObstruction)
    }
  />
)

PrimaryUseFilter.propTypes = {
  data: PropTypes.any,
  error: PropTypes.any,
  isObstruction: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  primaryUse: PropTypes.string,
}

export { PrimaryUseFilter as default, filterData }
