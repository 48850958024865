import { graphql } from '~/gql'

const CREATE_FLOOR_MUTATION = graphql(`
  mutation CreateFloor(
    $name: String!
    $facilityId: ID!
    $copyFromVersionId: ID
  ) {
    floor: createFloor(
      name: $name
      facilityId: $facilityId
      copyFromVersionId: $copyFromVersionId
    ) {
      ...FloorFragment
      version {
        id
      }
    }
  }
`)

export default CREATE_FLOOR_MUTATION
