import styled from 'styled-components'
import { Link } from 'react-router-dom'

import theme from 'config/theme'

const StyledLink = styled(Link)`
  border-bottom: 1px solid ${theme.colors.light.fg};
  color: ${theme.colors.light.fg};
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    border-bottom-color: transparent;
  }
`

export default StyledLink
