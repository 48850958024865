import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import { withRouter } from 'react-router-dom'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import get from 'lodash-es/get'

import { version } from '../../../package.json'
import routes from 'config/routes'
import auth0Client from 'lib/Auth'
import { setAuthData } from 'store/auth'

import { GET_USER_QUERY } from 'client/queries'
import {
  CREATE_USER_MUTATION,
  LOG_USER_ACTION_MUTATION,
} from 'client/mutations'

import Container from './styled/Container'
import { handleLogUserAction } from 'client/handlers'

/**
 * main page for auth, allows user login and secure session creation
 * @returns Auth Screen
 */
class AuthScreen extends Component {
  componentDidMount() {
    if (this.props.isCallback) {
      this.handleCallback()
    } else {
      auth0Client.login()
    }
  }

  /**
   * Run auth process on mount
   */
  handleCallback() {
    auth0Client
      .handleAuthentication()
      .then(this.handleAuthResult.bind(this))
      .catch(this.handleAuthError)
  }

  /**
   * Process auth results and pass to success handler or throw error
   * @param {*} result auth results object
   */
  async handleAuthResult(result) {
    const { idToken, idTokenPayload: profile } = result
    try {
      const { user, userAttrs } = await this.findOrCreateUser({
        profile,
        idToken,
      })
      this.handleAuthSuccess({ userId: user.id, idToken, userAttrs })
    } catch (error) {
      throw new Error(error)
    }
  }

  /**
   * Determine the error and throw an alert
   * @param {Object} error generic error object
   */
  handleAuthError(error) {
    const description =
      get(error, 'errorDescription') || 'Check the console for further details.'
    const actualError = get(error, 'error') || 'Generic Error'
    alert(`Error: ${actualError}. ${description}`)
  }

  /**
   * Search database for user and return; else create user if possible
   * @param {*} param0 user profile object and token
   * @returns user object
   */
  async findOrCreateUser({ profile, idToken }) {
    const auth0UserId = profile.sub
    const userAttrs = {
      // Note: We're setting the name to be the email IF the name is
      // not present to allow Auth0-created users to be created in
      // the database.
      name: profile.name || profile.email,
      email: profile.email,
      avatar: profile.picture,
    }
    let user = {}

    await this.props.client
      .query({
        query: GET_USER_QUERY,
        variables: {
          auth0UserId,
        },
        fetchPolicy: 'network-only',
      })
      .then(res => {
        user = res.data.User
      })
      .catch(error => {
        return get(error, 'graphQLErrors[0].message') || get(error, 'message')
      })

    if (!user) {
      await this.props.client
        .mutate({
          mutation: CREATE_USER_MUTATION,
          variables: {
            idToken,
            email: userAttrs.email,
            name: userAttrs.name,
          },
        })
        .then(res => {
          user = res.data.createUser
        })
        .catch(error => {
          return get(error, 'graphQLErrors[0].message') || get(error, 'message')
        })
    }

    this.props.logUserAction({ action: 'Speclab Auth' })

    return { user, userAttrs }
  }

  /**
   * Set auth data and route to redirect url
   * @param {*} param0 user object return from auth
   */
  handleAuthSuccess({ userId, idToken, userAttrs }) {
    const redirectUrl = window.localStorage.getItem('redirectUrl')
    if (import.meta.env.VITE_ENV === 'production') {
      // This is used to identify the user in Fullstory
      window.FS.identify(userId, {
        displayName: userAttrs.name,
        email: userAttrs.email,
        version,
      })
    }
    this.props.setAuthData({ userId, idToken, ...userAttrs })

    if (redirectUrl) {
      this.props.history.push(redirectUrl)
      window.localStorage.removeItem('redirectUrl')
    } else {
      this.props.history.push(routes.facilities)
    }
  }

  render() {
    return (
      <Container>
        <div id="auth0-container" />
      </Container>
    )
  }
}

AuthScreen.propTypes = {
  history: PropTypes.object,
  isCallback: PropTypes.bool,
  location: PropTypes.object,
  setAuthData: PropTypes.func,
  client: PropTypes.object,
  logUserAction: PropTypes.func,
}

const mapDispatchToProps = { setAuthData }

// TODO: comment required in both places to trick intellisense
/**
 * main page for auth, allows user login and secure session creation
 * @returns Auth Screen
 */
export default compose(
  appConnect(null, mapDispatchToProps),
  withRouter,
  withApollo,
  graphql(LOG_USER_ACTION_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      logUserAction: ({ action }) =>
        handleLogUserAction({ action, mutate, ownProps }),
    }),
  })
)(AuthScreen)
