import { GET_CFD_UPLOAD_SIGNATURE } from 'client/queries/getCFDUploadSignature'
import { client } from 'client'

export async function uploadCFDInput({ files, versionId }) {
  const timestamp = new Date()
  const statuses = await Promise.all(
    files.map(async ({ fileName, MIME, file }) => {
      const { data } = await client.query({
        query: GET_CFD_UPLOAD_SIGNATURE,
        variables: { timestamp, fileName, versionId },
      })
      const { signature } = data
      const form = new FormData()
      Object.entries(signature.fields).forEach(([key, value]) => form.append(key, value))
      const fileBlob = new Blob([file], { type: MIME })
      form.append('file', fileBlob)
      const response = await fetch(signature.url, { body: form, method: 'POST' })
      return response.status
    })
  )
  const isSuccess = statuses.every(status => status === 204)
  if (!isSuccess) {
    throw new Error(
      `Failed to upload CFD input files: ${files.map(({ fileName }) => fileName).join(', ')}`
    )
  }
  return timestamp
}
