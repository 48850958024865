import React from 'react'
import PropTypes from 'prop-types'

import Container from './styled/Container'

const Table = ({ children }) => (
  <Container>
    <tbody>{children}</tbody>
  </Container>
)

Table.propTypes = {
  children: PropTypes.node,
}

export default /** @type any */(Table)
