import type { FormatOptions } from '.'
import { SYSTEMS } from '../constants'
import type { FormattedUnitData, UnformattedUnitData } from '../types/unit'
import { round } from '../utils'

function formatCelsius(data: number, options: FormatOptions = {}) {
  return `${round(data, options.round)} °C`
}

function formatFahrenheit(degreesF: number, options: FormatOptions = {}) {
  return `${round(degreesF, options.round)} °F`
}

export function formatTemperature({ value, system }: Omit<UnformattedUnitData, 'type'>, options: FormatOptions): string|null {
  if (value === null) {
    return options.nullValue ?? null
  }
  switch (system) {
    case SYSTEMS.IMPERIAL:
      return formatFahrenheit(value, options)
    case SYSTEMS.METRIC:
      return formatCelsius(value, options)
    default:
      return round(value, options.round).toString()
  }
}

function unformatCelsius(data: string, options: FormatOptions = {}) {
  const regex = new RegExp(/(\d*\.?\d*\s*)?°?C?/)
  const trimmedString = data.trim()
  const matches = trimmedString.match(regex)!

  const temp = matches[1] ? Number(matches[1]) : 0

  if (matches[1] === undefined) {
    return null
  }

  return round(temp, options.round)
}

function unformatFahrenheit(data: string, options: FormatOptions = {}) {
  const regex = new RegExp(/(\d*\.?\d*\s*)?°?F?/)
  const trimmedString = data.trim()
  const matches = trimmedString.match(regex)!

  const temp = matches[1] ? Number(matches[1]) : 0

  if (matches[1] === undefined) {
    return null
  }

  return round(temp, options.round)
}

export function unformatTemperature({ value, system }: Omit<FormattedUnitData, 'type'>, options: FormatOptions = {}) {
  switch (system) {
    case SYSTEMS.IMPERIAL:
      return unformatFahrenheit(value.toString(), options)
    case SYSTEMS.METRIC:
      return unformatCelsius(value.toString(), options)
    default:
      return round(Number(value), options.round)
  }
}
