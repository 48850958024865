import PropTypes from 'prop-types'

import Container from './styled/Container'

const SelectListLink = Container

SelectListLink.propTypes = {
  actions: PropTypes.any,
}

export default /** @type any */(SelectListLink)
