import { ArrowHelperProps } from "@react-three/fiber"
import { ColorRepresentation, Vector3 } from "three";

export function Arrow({ points, color, ...rest }: {
  points: [Vector3, Vector3]
  color?: ColorRepresentation,
} & ArrowHelperProps) {
  const midPoint = new Vector3().addVectors(points[0], points[1]).divideScalar(2)
  return (
    <>
      <arrowHelper args={[points[0].clone().sub(midPoint).normalize(), midPoint, points[0].distanceTo(midPoint), color ?? 0x000, 1, 1]} {...rest}/>
      <arrowHelper args={[points[1].clone().sub(midPoint).normalize(), midPoint, points[1].distanceTo(midPoint), color ?? 0x000, 1, 1]} {...rest}/>
    </>
  )
}
