import get from 'lodash-es/get'
import isEqual from 'lodash-es/isEqual'
import sortBy from 'lodash-es/sortBy'
import {
  HEATER_VARIATION_FRAGMENT,
  PRODUCT_VARIATION_FRAGMENT,
} from 'client/fragments'
import Units from './units'
import store from '~/store'
import { SYSTEMS } from 'store/units/constants'
import { getDistanceUnits } from 'store/units/selectors'
import { Distance } from 'store/units/types'
import { activeTool } from 'store/tools/selectors'
import { setStatus } from 'store/status'
import { updateProduct, updateProductHeight } from 'store/objects'
import {
  getSelectedObjects,
  objectIsSelected,
  mostRecentSelectedObjectOfClassName,
  mostRecentSelectedObject,
  numObjectsSelected,
} from 'store/selectedObjects/selectors'
import ApolloClient from '~/client'
import * as MeshLoader from './meshLoader'
import Facility from './facility'
import Primitives from './primitives'
import WallSegment from './wallSegment'
import Util from './util'
import ProductDistanceEngine from './productDistance'
import ArrowRenderer from './arrowRenderer'
import getRenderOrder from 'config/canvasRenderOrder'
import OBJECT_TYPES from 'config/objectTypes'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import { defaultTubeLengths, haloSizes } from 'config/product'
import { getThreeHexFromTheme } from 'lib/utils'
import * as THREE from 'three'
import CLICK_PRIORITY from 'config/clickPriority'
import { graphql } from '~/gql'
import { captureException } from '@sentry/react'

class Product {
  static bladeAssetInstanceCache = {}
  static mountAssetInstanceCache = {}
  static CENTER_CUBE_SIZE = 4

  /**
   * @param {import('~/store/objects/types').Product} model
   * @param {unknown} units
   */
  constructor(model, units) {
    if (!model.productId) {
      model.productId = model.product.id
    }
    if (!model.productId || !model.variationId) {
      throw new Error(`product is missing model: ${model.productId} and/or variation: ${model.variationId}`)
    }
    this.id = model.id
    this.className = CLASS_NAMES.PRODUCT
    this.layerKey = LAYER_KEYS.PRODUCTS
    if (model.category === "HEAT") {
      this.layerKey = LAYER_KEYS.PRODUCTS_HEATERS
    } else if (model.category === "EVAP") {
      this.layerKey = LAYER_KEYS.PRODUCTS_EVAP
    } else if (model.product.type === "OVERHEAD") {
      this.layerKey = LAYER_KEYS.PRODUCTS_OVERHEAD
    } else if (model.category === "FAN" || model.product.type === "DIRECTIONAL") {
      this.layerKey = LAYER_KEYS.PRODUCTS_DIRECTIONAL
    } else {
      if (import.meta.env.DEV) throw new Error(`unknown layer key for product: ${JSON.stringify(model)}`)
      captureException(new Error(`unknown layer key for product: ${JSON.stringify(model)}`))
    }
    this.constructProduct(model, Facility.current)
    this.reinitializeKeypressHandler = true
    this.lastDescriptionPos = new THREE.Vector3()
    this.obj3d = new THREE.Object3D()
    this.clearanceMaterial = new THREE.MeshStandardMaterial({
      color: getThreeHexFromTheme('three.objects.product.default'),
      transparent: true,
      opacity: this.hasDangerousCollisions && !this.ignoreErrors ? 0.95 : 0,
      emissive: new THREE.Color(0, 0, 0),
      depthWrite: false,
    })

    ArrowRenderer.subscribe(this)
  }

  /**
   * @param {any} model
   * @param {THREE.Vector3Like} pos
   */
  static quickCreate(model, pos) {
    const modelName = model.product.model
    const mountToCeiling = modelName === 'Haiku'
    const product = {
      size: 54,
      height: 96,
      modelName,
      mountToCeiling,
      ...model,
      id: Util.guid(),
      variationId: model.id,
      voltageId: get(model, 'voltages[0].id'),
      voltage: get(model, 'voltages[0].inputPower'),
      mountingOptionAdderId: get(model, 'mountingOptionAdders[0].id'),
      mountingOption: get(model, 'mountingOption'),
      position: {
        x: Units.nativeToInches(pos.x),
        y: Units.nativeToInches(pos.y),
        z: Units.nativeToInches(pos.z),
      },
      tiltStart: get(model, 'degreesOfFreedom.start'),
      tiltEnd: get(model, 'degreesOfFreedom.end'),
      tiltStep: get(model, 'degreesOfFreedom.step'),
      tubeLength: Product.getDefaultTubeSize(modelName),
      ignoreErrors: model.ignoreErrors || false,
      hasUnknownVoltage: model.hasUnknownVoltage,
      detailedLabel: model.detailedLabel || '',
      connectInstallET: model.connectInstallET || true,
      controllerOther: model.controllerOther || '',
      category: model.category,
    }

    return product
  }

  /** @param {string} modelName */
  static getDefaultTubeSize(modelName) {
    // Find default tube size based on model type
    const matchedSize = defaultTubeLengths.find(
      product => product.model === modelName
    )
    if (matchedSize) return matchedSize.size

    // Use default tube size if no match is found
    // @ts-expect-error null assertion
    return defaultTubeLengths.find(product => product.model === 'Default').size
  }

  destroy() {
    if (this.productDistanceEngine)
      this.productDistanceEngine.removeErrorState()
    this.productDistanceEngine = null
    ArrowRenderer.unsubscribe(this)

    // https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
    // @ts-expect-error null assertion
    this.obj3d.traverse(child => {
      // @ts-expect-error
      if (child.dispose) {
        // @ts-expect-error
        child.dispose()
      }
    })
  }

  /**
   * SceneBuilder event
   * @param {boolean} visible
   */
  visibilityChanged(visible) {
    if (this.obj3d) this.obj3d.visible = visible
  }

  /**
   * SceneBuilder event
   * @param {Facility} facility
   * @param {boolean} thisChanged
   */
  sceneDidRebuild(facility, thisChanged) {
    // Update height after scene rebuilds since height is
    // impacted by other objects in the scene: e.g. wall height,
    // mounting structure depth
    if (this.useDefinedHeight) {
      setTimeout(() => {
        this.updateHeight(facility)
      }, 500)
    }

    // Check if other updated objects affect this products clearance
    if (!this.ignoreErrors) {
      setTimeout(() => {
        this.checkCollisions()
      }, 500)
    }

    // Used for fan height debugging. Uncomment to see total height
    // const boundingBox = Util.computeCompositeBoundingBox(this.obj3d);
    // const totalMeshHeightInInches = Units.nativeToInches(boundingBox.max.z - boundingBox.min.z);
    // console.log('Total Mesh Height:', totalMeshHeightInInches, '"');
  }

  /**
   * @param {any} props
   * @param {boolean} [__didCatchError]
   */
  async buildMesh(props, __didCatchError) {
    try {
      const {
        size,
        mountStyle,
        tubeLength,
        model,
        fanRoot,
        tiltGroup,
        facility,
        tiltEnd,
        isMounted,
        isDirectionalOverhead,
        rotation,
        heaterData,
      } = props
      await MeshLoader.buildProductMesh({
        size,
        mountStyle,
        tubeLength,
        model,
        root: fanRoot,
        tiltGroup,
        tiltEnd,
        isMounted,
        isDirectionalOverhead,
        rotation,
        heaterData,
      })

      this.totalMeshHeight = this.fullHeight

      if (this.useDefinedHeight) {
        const heightChanged = this.updateHeight(facility)
        if (heightChanged) {
          store.dispatch(updateProductHeight(this.height))
        }
      }
    } catch (err) {
      // NOTE: Make sure we break the potential for an infinite loop
      // if neither the fallback nor the original models were found.
      if (__didCatchError) throw err

      const model =
        this.isDirectional && !this.isDirectionalOverhead
          ? 'Black Jack'
          : 'Powerfoil X3.0'

      this.buildMesh(
        {
          ...props,
          model,
        },
        true
      )
    }
  }

  get mountedOn() {
    let mountedOn = 'OVERHEAD'
    if (this.isDirectional && this.isMounted && !this.isDirectionalOverhead)
      mountedOn = 'WALL' // TODO: We need to differentiate from columns
    if (this.isDirectional && !this.isMounted && !this.isDirectionalOverhead)
      mountedOn = 'PEDESTAL'

    return mountedOn
  }

  /** @param {any} voltage */
  defaultMountingOption = voltage => {
    const mountingOptions = get(voltage, 'mountingOptions', [])
    const options = sortBy(mountingOptions, 'tubeLength').filter(o => {
      if (this.mountedOn === 'WALL') return o.forWall
      if (this.mountedOn === 'COLUMN') return o.forColumn
      if (this.mountedOn === 'PEDESTAL') return o.forPedestal
      return o.forOverhead
    })

    const foundOption = get(options, '[0]')
    if (foundOption) return foundOption

    // If no option is found get default option based on products default tube size
    const modelName = get(this, 'product.model')
    // @ts-expect-error
    const defaultTubeSize = Product.getDefaultTubeSize(modelName)
    return this.getMountingOptionBasedOnTubeSize(voltage, defaultTubeSize)
  }

  /**
   * @param {import('~/store/objects/types').Product} model
   * @param {Facility} facility
   */
  constructProduct = async (model, facility) => {
    model = { ...model }
    const currentDetailedLabel = get(model, 'detailedLabel')
    if (!currentDetailedLabel && currentDetailedLabel !== '') {
      const LETTERS = Array.from(Array(26))
        .map((e, i) => i + 65)
        .map(x => String.fromCharCode(x))
      const products = facility.getProducts()
      let newLabel = ''

      const labelLength = Math.floor(products.length / 26) + 1
      for (let i = 0; i < labelLength; i++) {
        newLabel += LETTERS[products.length % LETTERS.length]
      }

      model.detailedLabel = newLabel
    }
    try {

      const productData = await ApolloClient.query({
        query:
          graphql(`
            query DrawingCanvasProductData($productID: ID!, $variationID: ID!) {
              ProductVariation(id: $variationID) {
                id
                cfdId
                voltages {
                  id
                  mountingOptions {
                    id
                    tubeLength
                    fullHeight
                  }
                }
                mountingOptionAdders {
                  id
                  mountingType
                }
                size
                minFloorClearance
                minObstructionClearance
                minProductClearance
                minRoofClearance
                minWallClearance
                recommendedFloorClearance
                recommendedObstructionClearance
                recommendedProductClearance
                recommendedRoofClearance
                recommendedWallClearance
                canMountOnColumn
                canMountOnWall
                canMountOverhead
                canStandAlone
                cageHeight
                degreesOfFreedom {
                  id
                  start
                  end
                  step
                }
                heaterData {
                  id
                  inputFiringRate
                  minHeight
                  radEfficiency
                  minTubeLength
                  tubeDiameter
                  tubeCenterOffsetFromLeftX
                  tubeCenterOffsetFromBottomY
                  burnerBoxDepth
                  burnerBoxHeight
                  burnerBoxWidth
                  burnerBoxClearanceDepth
                  burnerBoxClearanceHeight
                  burnerBoxClearanceWidth
                  angle
                  irhClearanceA
                  irhClearanceB
                  irhClearanceC
                  irhClearanceD
                  spotHeatHeight
                  boxHeightA
                  boxWidthB
                  boxDepthF
                  blowerDepthE
                  uhClearanceTop
                  uhClearanceFlueConnector
                  uhClearanceAccessPanel
                  uhClearanceNonAccessSide
                  uhClearanceBottom
                  uhClearanceRear
                }
                heaterPerformance {
                  id
                  distanceFromBurner
                  axialRelativeIntensity
                }
              }
              Product(id: $productID) {
                id
                model
                category
                type
                application
                distinctFanSpeeds {
                  speed
                  overheadOnly
                }
              }
            }
          `),
        variables: {
          productID: model.productId,
          variationID: model.variationId,
        }
      })
      /** @type {NonNullable<import('~/gql/graphql').DrawingCanvasProductDataQuery['Product']>} Product */
      const Product = productData.data.Product
      /** @type {NonNullable<import('~/gql/graphql').DrawingCanvasProductDataQuery['ProductVariation']>} ProductVariation */
      const Variation = productData.data.ProductVariation

      this.id = model.id
      this.product = Product
      this.productId = Product.id
      this.inputPower = model.inputPower
      this.model = Product.model
      this.application = Product.application
      this.mountToCeiling = model.mountToCeiling
      this.isMounted = model.isMounted
      this.isForcedWallMount = model.isForcedWallMount
      this.isRotating = model.isRotating
      this.isDragging = model.isDragging
      this.height = Units.inchesToNative(model.height)
      this.size = Variation.size
      this.rotation = model.rotation || { x: 0, y: 0, z: 0 }
      this.isDirectional = Product.type === 'DIRECTIONAL'
      this.variationId = model.variationId
      this.hasDangerousCollisions = model.hasDangerousCollisions
      this.isOutOfBounds = model.isOutOfBounds
      this.ignoreErrors = model.ignoreErrors
      this.category = Product.category

      // Directional Fan Mounting Options
      this.canMountOnColumn = Variation.canMountOnColumn
      this.canMountOnWall = Variation.canMountOnWall
      this.canMountOverhead = Variation.canMountOverhead
      this.canStandAlone = Variation.canStandAlone
      this.cageHeight = Variation.cageHeight
      this.wallSegmentId = model.wallSegmentId
      this.isDirectionalOverhead =
        model.isDirectionalOverhead && Variation.canMountOverhead
      this.mountPosition = model.mountPosition
      this.mountingOptionAdderId = model.mountingOptionAdderId
      this.hasUnknownVoltage = model.hasUnknownVoltage

      // Directional Fan Tilt Options
      this.tiltStart = Variation.degreesOfFreedom?.start
      this.tiltEnd = Variation.degreesOfFreedom?.end
      this.tiltStep = Variation.degreesOfFreedom?.step

      // Install information
      this.adderOther = model.adderOther
      this.adders = model.adders
      this.level = model.level
      this.liftNeeded = model.liftNeeded
      this.liftType = model.liftType
      this.deckHeight = model.deckHeight
      this.heightToAttachPoint = model.heightToAttachPoint
      this.installAdders = model.installAdders
      this.fireRelay = model.fireRelay
      this.fireRelayType = model.fireRelayType
      this.detailedLabel = model.detailedLabel || ''
      this.connectInstallET = model.connectInstallET || true
      this.controllerOther = model.controllerOther || ''

      // Heater information
      this.heaterData = Variation.heaterData
      this.heaterPerformance = Variation.heaterPerformance
      this.useDefinedHeight =
        (this.category !== 'HEAT' && !this.isDirectional) ||
        this.isDirectionalOverhead
      this.angle = model.angle || 15 // default lvr angle of Unit Heaters
      this.fuelType =
        this.category === 'HEAT' ? get(model, 'fuelType', 'Natural Gas') : null

      // Hornet information
      const isHornet = this.model === 'Hornet'

      const isSelectedObject = objectIsSelected(this.id)
      const isCS350 = this.model === 'Cool-Space 350'
      const radius = Units.inchesToNative(isCS350 ? 38 : this.size) / 2
      const clearanceHeight = this.getClearanceHeight()
      const clearanceCylinder = new THREE.CylinderGeometry(
        radius,
        radius,
        clearanceHeight + isHornet ? 4 : 0,
        32
      )

      const { HeaterData, width, height, depth } = this.getHeaterClearance()

      // const clearanceBox = new THREE.BoxBufferGeometry(width, depth, height, 32)
      // change the height here ??
      const clearanceBox = new THREE.BoxGeometry(width, depth, height, 32)

      clearanceCylinder.rotateX(Math.PI / 2)
      if (isHornet)
        clearanceCylinder.translate(0, 0, 2.6)
      if (this.rotation.y === 90 || this.rotation.y === 270)
        clearanceBox.rotateZ(Math.PI / 2)

      let clearanceColor = getThreeHexFromTheme('three.objects.product.default')
      if (this.hasDangerousCollisions && !this.ignoreErrors) {
        clearanceColor = getThreeHexFromTheme('three.invalid')
      } else if (isSelectedObject) {
        clearanceColor = getThreeHexFromTheme('three.objects.product.selected')
      }
      // this.clearanceMaterial.color = clearanceColor

      this.clearance = new THREE.Mesh(
        this.category === 'HEAT' ? clearanceBox : clearanceCylinder,
        this.clearanceMaterial
      )

      if (
        this.product.model === 'IRH Straight' ||
        this.product.model === 'IRH U-Tube'
      ) {
        let w = -Units.inchesToNative(6) // 6 inch clearance on end
        // TODO: Kevin needs to explain the IRH clearance / spot heat height better
        // @ts-expect-error
        let h = -((height * 2) / 5)
        // Units.inchesToNative(
        //   HeaterData.spotHeatHeight ||
        //     HeaterData.irhClearanceA + HeaterData.tubeDiameter
        // )
        // let h = Units.inchesToNative(60)
        let d = 0
        if (this.rotation.x === 45) {
          d = Units.inchesToNative(
            HeaterData.irhClearanceB + HeaterData.tubeDiameter
          )
        }
        if (this.rotation.x === -45) {
          d = -Units.inchesToNative(
            HeaterData.irhClearanceB + HeaterData.tubeDiameter
          )
        }

        // set the clearance box position
        this.clearance.position.set(w, d, h)
      }

      if (this.product.model === 'Unit Heater') {
        let w, d, h

        w = 0
        d = 0
        h = 0

        this.clearance.position.set(w, d, h)
      }

      if (isHornet) {
        let w, d, h

        w = 0
        d = 0
        h = -0.6

        this.clearance.position.set(w, d, h)
      }

      this.clearance.userData.objectType = OBJECT_TYPES.FAN_BLADE_CYLINDER
      // @ts-expect-error
      this.clearance.wrapperId = this.id
      this.obj3d.add(this.clearance)

      if (this.isDirectional) {
        // Add direction arrow to show the direction product is facing
        if (this.category === 'HEAT') {
          this.obj3d.add(
            this.getDirectionArrow(Primitives.getHeaterArrowMesh())
          )
        } else {
          this.obj3d.add(this.getDirectionArrow())
        }
      } else {
        // Add a cube to the product center for fan to fan measurement lines
        this.obj3d.add(this.getProductCenterCube())
      }

      if (
        this.category === 'HEAT' &&
        (this.rotation.x === 45 || this.rotation.x === -45)
      ) {
        this.obj3d.add(this.getHeaterDirectionArrow())
      }

      const fanRoot = new THREE.Object3D()
      fanRoot.rotation.x = Math.PI / 2

      // Setup fan tilt
      const tiltGroup = new THREE.Object3D()
      if (this.tiltEnd) {
        const rotation = 90 - this.rotation.x
        tiltGroup.rotateX(((rotation * Math.PI) / 180) * -1)

        this.tiltGroup = tiltGroup
        fanRoot.add(tiltGroup)
      }

      this.obj3d.add(fanRoot)

      this.cfdId = Variation.cfdId
      const voltage =
        (Variation.voltages ?? []).find(v => v.id === model.voltageId) ||
        Variation.voltages?.[0]
      const matchedVariationByTubeSize = this.getMountingOptionBasedOnTubeSize(
        voltage,
        model.tubeLength
      )
      const mountingOption =
        matchedVariationByTubeSize ||
        (voltage?.mountingOptions ?? []).find(
          o => o.id === model.mountingOptionId
        ) ||
        this.defaultMountingOption(voltage)

      this.tubeLength = Units.inchesToNative(
        get(mountingOption, 'tubeLength', 0)
      )
      this.fullHeight =
        this.isDirectional && !this.isDirectionalOverhead
          ? get(mountingOption, 'fullHeight', 0)
          : Units.inchesToNative(get(mountingOption, 'fullHeight', 0))

      let mountStyle
      const productModel = Product.model
      if (productModel === 'Haiku') {
        if (voltage) {
          mountStyle = ['Low Profile', 'Standard'].includes(
            mountingOption.label
          )
            ? { style: 'a2', option: mountingOption }
            : { style: 'xmount', option: mountingOption }
        }
      }
      if (productModel === 'AirEye') {
        if (
          this.mountingOptionAdderId === '46' &&
          !this.isDirectionalOverhead &&
          !this.isForcedWallMount
        ) {
          mountStyle = { style: 'lowrider', option: mountingOption }
        }
      }
      this.buildMesh({
        size: model.size,
        mountStyle,
        tubeLength: get(mountingOption, 'tubeLength') || 0,
        model: model.product.model,
        fanRoot,
        tiltGroup,
        facility,
        tiltEnd: this.tiltEnd,
        isMounted: this.isMounted,
        rotation: this.rotation,
        isDirectionalOverhead: this.isDirectionalOverhead,
        heaterData: this.heaterData,
      })

      const isUnitHeater = this.product.model === 'Unit Heater'
      const positionX = Units.inchesToNative(model.position.x)
      const positionY = Units.inchesToNative(model.position.y)
      const positionZ =
        this.isDirectional && !this.isDirectionalOverhead && !isUnitHeater
          ? this.getDirectionalProductHeight(radius)
          : this.height

      this.obj3d.position.copy(
        new THREE.Vector3(positionX, positionY, positionZ)
      )
      this.position = this.obj3d.position
      this.lastValidPos = new THREE.Vector3().copy(this.position)

      // Setup rotation drag icon
      if (this.isDirectional) {
        this.buildProductDragHandle()
      }

      // hide drag handles when multi selecting products
      const otherProductsSelected = numObjectsSelected() > 1
      if (otherProductsSelected) {
        const selectedProducts = Facility.current
          .getProducts()
          .filter(p => objectIsSelected(p.id))

        selectedProducts.forEach(p => p.hideProductDragHandle())
        this.hideProductDragHandle()
      }

      if (!isSelectedObject) {
        if (!this.hasDangerousCollisions) this.clearance.material.opacity = 0

        if (this.isDirectional) {
          if (this.dragHandle) this.dragHandle.visible = false
          if (this.directionArrow) this.directionArrow.visible = false
        }
      }

      // Used by Interactifier
      // @ts-expect-error
      this.obj3d.wrapperId = this.id
      this.clickPriority = CLICK_PRIORITY[this.layerKey]
      this.selectable = true
      this.draggable = false
      this.multiDraggable = true
      this.obj3d.renderOrder = getRenderOrder('products')

      // Minimum clearance distances
      this.minFloorClearance = Variation.minFloorClearance
      this.minObstructionClearance = Variation.minObstructionClearance
      this.minProductClearance = Variation.minProductClearance
      this.minRoofClearance = Variation.minRoofClearance
      // @ts-expect-error
      this.minWallClearance =
        get(this, 'product.type') === 'OVERHEAD'
          ? this.size / 2
          : Variation.minWallClearance
      this.mountingOption = get(
        (Variation.mountingOptionAdders ?? []).find(
          opt => opt.id === this.mountingOptionAdderId
        ),
        'mountingType',
        'Unknown'
      )
      // Recommended clearance distances
      this.recommendedFloorClearance = Variation.recommendedFloorClearance
      this.recommendedProductClearance = Variation.recommendedProductClearance
      this.recommendedRoofClearance = Variation.recommendedRoofClearance
      this.recommendedWallClearance = Variation.recommendedWallClearance
      this.recommendedObstructionClearance = Variation.recommendedObstructionClearance

      this.productDistanceEngine = new ProductDistanceEngine(this)

      if (isSelectedObject) {
        this.select()
      }

      // Handle product rotation
      if (this.isMounted) {
        this.handleMountedRotation()
      } else {
        this.obj3d.rotation.set(0, 0, (this.rotation.z * Math.PI) / 180)
      }
    } catch (err) {
      throw err
    }
  }

  getHeaterClearance() {
    if (this.category !== 'HEAT') {
      return {}
    }
    const HeaterData =
      this.product.model === 'Unit Heater'
        ? this.heaterData[0]
        : this.heaterData.find(
            h =>
              h.angle === Math.abs(this.rotation.x) ||
              (h.angle === 90 && 0 === this.rotation.x)
          )
    const width =
      this.product.model === 'Unit Heater'
        ? Units.inchesToNative(
            HeaterData.uhClearanceNonAccessSide +
              HeaterData.boxWidthB +
              HeaterData.uhClearanceAccessPanel
          )
        : Units.inchesToNative(
            6 + // 6 inch clearance on end
              HeaterData.minTubeLength +
              HeaterData.burnerBoxWidth +
              HeaterData.burnerBoxClearanceWidth
          )
    const depth =
      this.product.model === 'Unit Heater'
        ? Units.inchesToNative(
            HeaterData.uhClearanceFlueConnector +
              HeaterData.blowerDepthE +
              HeaterData.uhClearanceRear
          )
        : Units.inchesToNative(
            HeaterData.irhClearanceB +
              HeaterData.tubeDiameter +
              HeaterData.irhClearanceD
          )
    const height =
      this.product.model === 'Unit Heater'
        ? Units.inchesToNative(
            HeaterData.uhClearanceTop +
              HeaterData.boxHeightA +
              HeaterData.uhClearanceBottom
          )
        : Units.inchesToNative(HeaterData.irhClearanceC)

    return { width, height, depth, HeaterData }
  }

  select(draggable) {
    const isLocked = store.getState().layers.layers[LAYER_KEYS.PRODUCTS].locked
    this.draggable = !isLocked
    if (draggable === false) {
      this.draggable = false
    }
    if (this.clearance) {
      this.clearance.visible = true
      this.clearance.material.opacity = 0.95
      this.clearance.material.color.set(
        getThreeHexFromTheme('three.objects.product.default')
      )
    }
    if (this.isMounted && (this.canMountOnWall || this.canMountOnColumn)) {
      this.mountedIndicator = this.getMountedIndicator()
      this.obj3d.add(this.mountedIndicator)
    }
  }

  deselect() {
    if (this.clearance) this.clearance.visible = false
  }

  static productsAreEqual = (product1, product2) => isEqual(product1, product2)

  toModel() {
    return {
      application: this.application,
      cageHeight: this.cageHeight,
      canMountOnColumn: this.canMountOnColumn,
      canMountOnWall: this.canMountOnWall,
      canMountOverhead: this.canMountOverhead,
      canStandAlone: this.canStandAlone,
      category: this.category,
      className: this.className,
      clickPriority: this.clickPriority,
      height: Units.nativeToInches(this.height),
      hasDangerousCollisions: this.hasDangerousCollisions,
      isOutOfBounds: this.isOutOfBounds,
      id: this.id,
      inputPower: this.inputPower,
      isDirectional: this.isDirectional,
      isDirectionalOverhead: this.isDirectionalOverhead,
      isMounted: this.isMounted,
      isForcedWallMount: this.isForcedWallMount,
      layerKey: this.layerKey,
      metadata: this.metadata,
      minFloorClearance: this.minFloorClearance,
      minObstructionClearance: this.minObstructionClearance,
      minProductClearance: this.minProductClearance,
      minRoofClearance: this.minRoofClearance,
      minWallClearance: this.minWallClearance,
      model: this.model,
      mountingOptionAdderId: this.mountingOptionAdderId,
      mountingOption: this.mountingOption,
      mountPosition: this.mountPosition,
      mountToCeiling: this.mountToCeiling,
      pedestals: this.pedestals,
      position: {
        x: Units.nativeToInches(this.position.x),
        y: Units.nativeToInches(this.position.y),
        z: Units.nativeToInches(this.position.z),
      },
      product: this.product,
      productId: this.productId,
      recommendedFloorClearance: this.recommendedFloorClearance,
      recommendedObstructionClearance: this.recommendedObstructionClearance,
      recommendedProductClearance: this.recommendedProductClearance,
      recommendedRoofClearance: this.recommendedRoofClearance,
      recommendedWallClearance: this.recommendedWallClearance,
      rotation: this.rotation,
      size: this.size,
      tiltEnd: this.tiltEnd,
      tiltStart: this.tiltStart,
      tiltStep: this.tiltStep,
      tubeLength: Units.nativeToInches(this.tubeLength),
      variationId: this.variationId,
      wallSegmentId: this.wallSegmentId,
      hasUnknownVoltage: this.hasUnknownVoltage,
      ignoreErrors: this.ignoreErrors,

      // Install Information
      adderOther: this.adderOther,
      adders: this.adders,
      level: this.level,
      liftNeeded: this.liftNeeded,
      liftType: this.liftType,
      deckHeight: this.deckHeight,
      heightToAttachPoint: this.heightToAttachPoint,
      installAdders: this.installAdders,
      fireRelay: this.fireRelay,
      fireRelayType: this.fireRelayType,

      // Heater Information
      heaterData: this.heaterData,
      heaterPerformance: this.heaterPerformance,
      detailedLabel: this.detailedLabel || '',
      connectInstallET: this.connectInstallET || true,
      controllerOther: this.controllerOther || '',
      angle: this.angle || 15,
    }
  }

  buildProductDragHandle() {
    const distanceToEdge = Units.inchesToNative(this.size) / 2 + 5
    this.dragHandle = Primitives.getDragHandle(distanceToEdge, 'l')
    this.dragHandle.userData.objectType = OBJECT_TYPES.DRAG_HANDLE
    this.dragHandle.wrapperId = this.id
    this.obj3d.add(this.dragHandle)
  }

  hideProductDragHandle() {
    if (this.dragHandle) {
      this.dragHandle.visible = false
    }
  }

  getMountedIndicator() {
    const ringGeometry = new THREE.CircleGeometry(1, 128)
    const ringMaterial = new THREE.MeshBasicMaterial({
      color: getThreeHexFromTheme('three.objects.snapRegion.default'),
      side: THREE.DoubleSide,
    })
    const mountedIndicator = new THREE.Mesh(ringGeometry, ringMaterial)
    const radius = Units.inchesToNative(this.size) / 2

    mountedIndicator.position.set(0, -radius, radius * 1.25)

    return mountedIndicator
  }

  getMountingOptionBasedOnTubeSize(voltage, tubeLength) {
    if (!tubeLength) return

    return get(voltage, 'mountingOptions', []).find(
      variation => variation.tubeLength === tubeLength
    )
  }

  getClearanceHeight() {
    if (!this.isDirectional || this.isDirectionalOverhead) return 1

    const productConfig = haloSizes.find(
      haloSize => haloSize.model === this.model
    )

    if (!productConfig) return Units.inchesToNative(this.size * 2)

    return productConfig.sizes.find(size => size.size === this.size).haloHeight
  }

  getDirectionalProductHeight(radius) {
    const cageHeight = get(this, 'cageHeight', 0)
    const nativeCageHeight = Units.inchesToNative(cageHeight)
    const fullHeight = get(this, 'fullHeight', 0)
    const nativeFullHeight = Units.inchesToNative(fullHeight)
    // Adjust the height of a mounted directional to start at the bottom
    // of the fan cage. If no cage height is found, use blade radius.
    if (this.isMounted) {
      const cageOffset = cageHeight ? nativeCageHeight / 2 : radius
      return this.height + cageOffset
    }

    // Adjust the height of a floor standing directional fan. If no
    // 'fullHeight' is found in pedestal data, we use half the
    // cage height + 4.5" to account for the rollers height
    if (!this.isMounted && !this.isDirectionalOverhead) {
      if (!nativeFullHeight) {
        return nativeCageHeight / 2 + Units.inchesToNative(4.5)
      }
      return nativeFullHeight - nativeCageHeight / 2
    }

    return 0
  }

  getProductCenterCube() {
    // Use a small square area (4") in the center of the product for collision
    // detection with product to product arrow rendering
    const size = Units.inchesToNative(Product.CENTER_CUBE_SIZE)
    const geometry = new THREE.BoxGeometry(size, size, size)
    const material = new THREE.MeshBasicMaterial({
      color: getThreeHexFromTheme('three.invalid'),
      transparent: false,
      side: THREE.DoubleSide,
    })
    const cube = new THREE.Mesh(geometry, material)
    cube.userData.objectType = OBJECT_TYPES.FAN_COLLISION_CUBE
    cube.visible = true

    return cube
  }

  getDirectionArrow(arrowMesh = Primitives.getArrowMesh()) {
    this.directionArrow = arrowMesh
    const distanceToEdge = Units.inchesToNative(this.size - 4) / 2
    this.directionArrow.position.set(0, distanceToEdge, 0.1)

    return this.directionArrow
  }

  getHeaterDirectionArrow() {
    this.directionArrow = Primitives.getArrowMesh()
    let posY = 0
    let posX = 0
    if (this.rotation.x === 45) {
      switch (this.rotation.y) {
        case 0:
          posY = 4
          break
        case 90:
          posX = -6
          break
        case 180:
          posY = -6
          break
        case 270:
          posX = 4
          break
        default:
          break
      }
    }
    if (this.rotation.x === -45) {
      switch (this.rotation.y) {
        case 0:
          posY = -6
          break
        case 90:
          posX = 4
          break
        case 180:
          posY = 4
          break
        case 270:
          posX = -6
          break
        default:
          break
      }
    }
    const rotationZ =
      (((this.rotation.x === 45 ? 0 : 180) + this.rotation.y) * Math.PI) / 180
    this.directionArrow.position.set(
      Units.inchesToNative(posX),
      Units.inchesToNative(posY),
      0.1
    )
    this.directionArrow.rotation.set(0, 0, rotationZ)

    return this.directionArrow
  }

  getBoundingRectEdges() {
    const box = new THREE.Box3().setFromObject(this.obj3d)

    const outsetPoints = [
      [box.min.x, box.min.y, 0],
      [box.min.x, box.max.y, 0],
      [box.min.x, box.max.y, 0],
      [box.max.x, box.max.y, 0],
      [box.max.x, box.max.y, 0],
      [box.max.x, box.min.y, 0],
      [box.max.x, box.min.y, 0],
      [box.min.x, box.min.y, 0],
    ]

    return outsetPoints
  }

  getBoundingBoxEdges() {
    const box = new THREE.Box3().setFromObject(this.obj3d)

    const outsetPoints = [
      { x: box.min.x, y: box.min.y, z: box.min.z },
      { x: box.min.x, y: box.min.y, z: box.max.z },
      { x: box.min.x, y: box.max.y, z: box.min.z },
      { x: box.min.x, y: box.max.y, z: box.max.z },
      { x: box.max.x, y: box.min.y, z: box.min.z },
      { x: box.max.x, y: box.min.y, z: box.max.z },
      { x: box.max.x, y: box.max.y, z: box.min.z },
      { x: box.max.x, y: box.max.y, z: box.max.z },
    ]

    return outsetPoints
  }

  pointIsInClearance(point) {
    const box = new THREE.Box3().setFromObject(this.clearance)
    const collide = box.containsPoint(point)

    return collide
  }

  handleMountedRotation() {
    if (!this.isMounted) return
    this.obj3d.rotation.z = (this.rotation.z * Math.PI) / 180
  }

  handleRotationDrag(dragDelta, projectedMousePos) {
    if (this.rotateTick < 4) {
      this.rotateTick++
      return
    }

    this.rotateTick = 0
    const offset =
      Math.abs(dragDelta.x) > Math.abs(dragDelta.y) ? dragDelta.x : dragDelta.y

    if (Math.abs(offset) < 0.1) return

    const direction = new THREE.Vector3()
      .subVectors(projectedMousePos, this.obj3d.position)
      .normalize()

    let isPositive = true
    let isOffsetX = offset === dragDelta.x

    if (direction.x < 0 && direction.y < 0) {
      // Bottom Left Quadrant
      if ((offset > 0 && !isOffsetX) || (offset < 0 && isOffsetX)) {
        isPositive = false
      }
    } else if (direction.x > 0 && direction.y < 0) {
      // Bottom Right Quadrant
      if ((offset < 0 && !isOffsetX) || (offset < 0 && isOffsetX)) {
        isPositive = false
      }
    } else if (direction.x > 0 && direction.y > 0) {
      // Top Right Quadrant
      if ((offset < 0 && !isOffsetX) || (offset > 0 && isOffsetX)) {
        isPositive = false
      }
    } else if (direction.x < 0 && direction.y > 0) {
      // Top Left Quadrant
      if ((offset > 0 && !isOffsetX) || (offset > 0 && isOffsetX)) {
        isPositive = false
      }
    }

    const radius = Units.inchesToNative(this.size) / 2

    // If mounted we want to setup the rotation from the mount point
    if (this.isMounted) {
      this.obj3d.translateY(-radius)
    }

    const degree = (this.obj3d.rotation.z * 180) / Math.PI
    if (isPositive) {
      this.obj3d.rotation.z = ((degree + 10) * Math.PI) / 180
    } else {
      this.obj3d.rotation.z = ((degree - 10) * Math.PI) / 180
    }

    this.rotation = {
      x: this.rotation.x,
      y: this.rotation.y,
      z: (this.obj3d.rotation.z * 180) / Math.PI,
    }

    if (this.isMounted) {
      this.obj3d.translateY(radius)
    }
  }

  static getUpdatedHeight(height, distance) {
    const heightBefore = height
    const newHeight = distance ? distance : height

    // If no object was found to measure from, use the same height
    if (newHeight < 0) {
      return { heightChanged: false, height }
    }

    // The difference between the old height and new height plus the
    // offset to the products center
    const heightDiff = newHeight - heightBefore

    const heightChanged = Units.nativeToInches(Math.abs(heightDiff)) > 0.001

    return { heightChanged, height: newHeight }
  }

  updateHeight(facility = Facility.current, shouldSave = true) {
    const { distance } = Product.getDistanceFromFloor(
      facility,
      this.obj3d.position,
      this.totalMeshHeight,
      this.mountToCeiling,
      this.isUnderCeiling(this.obj3d.position)
    )

    const { heightChanged, height } = Product.getUpdatedHeight(
      this.height,
      distance
    )

    if (heightChanged) {
      this.height = height
      this.obj3d.position.z = this.height

      const selectedObject = mostRecentSelectedObject()
      if (selectedObject && selectedObject.id === this.id) {
        if (shouldSave) {
          store.dispatch(updateProduct({ product: this.toModel() }))
          store.dispatch(updateProductHeight(this.height))
        }
      }
    }

    return heightChanged
  }

  updateMountingOptionId(facility = Facility.current, mountingType) {
    let mt = undefined
    if (mountingType === OBJECT_TYPES.PRIMARY_BEAM) {
      mt = get(facility, 'roofSections[0].primaryStructureType')
    } else if (mountingType === OBJECT_TYPES.SUB_MOUNTING_STRUCTURE) {
      mt = get(facility, 'roofSections[0].secondaryStructureType')
    }

    if (mt) {
      // Data inconsistency correction
      if (mt === 'Girders') mt = 'Girder'
      if (mt === 'I-Beams') mt = 'I-Beam'

      const variation = ApolloClient.readFragment({
        id: `ProductVariation:${this.variationId}`,
        fragment:
          this.category === 'HEAT'
            ? HEATER_VARIATION_FRAGMENT
            : PRODUCT_VARIATION_FRAGMENT,
      })
      const mountingOptionAdders = get(variation, 'mountingOptionAdders')
      const mountingId = mountingOptionAdders.find(
        option => option.mountingType === mt
      )
      if (mountingId) {
        this.mountingOptionAdderId = mountingId.id
      }
    }
  }

  updateMountedDirection(startPoint, endPoint, interiorWall) {
    if (!startPoint || !endPoint) return

    const direction = new THREE.Vector3()
      .subVectors(startPoint, endPoint)
      .normalize()

    if (isNaN(direction.z)) direction.z = 0

    this.obj3d.quaternion.setFromUnitVectors(
      new THREE.Vector3(1, 0, 0),
      direction
    )

    let isClosed = true
    if (interiorWall) {
      interiorWall.wrapper.segments.forEach(seg => {
        if (!seg.nextSegment || !seg.previousSegment) {
          isClosed = false
        }
      })
    }

    // Keep product facing inside
    if (isClosed) {
      this.obj3d.rotation.z = this.obj3d.rotation.z + (180 * Math.PI) / 180
    }

    this.obj3d.rotation.x = 0

    this.rotation = {
      x: this.rotation.x,
      y: (this.obj3d.rotation.y * 180) / Math.PI,
      z: (this.obj3d.rotation.z * 180) / Math.PI,
    }
  }

  updateMountedPosition(snapDelta) {
    // Use the radius of the product plus a little buffer room
    const radius = Units.inchesToNative(this.size) / 2 + 1.75
    this.obj3d.translateY(radius)
    this.obj3d.position.add(snapDelta)
  }

  static getDistanceFromFloor(
    facility,
    position,
    totalMeshHeight,
    mountToCeiling,
    isUnderCeiling
  ) {
    const floorPosition = new THREE.Vector3(position.x, position.y, 0)
    const positiveZ = new THREE.Vector3(0, 0, 1)
    const options = {
      includedTypes: [
        OBJECT_TYPES.PRIMARY_BEAM,
        OBJECT_TYPES.SUB_MOUNTING_STRUCTURE,
        OBJECT_TYPES.ROOF,
      ],
      measureTo: Facility.SURFACE,
    }

    if (mountToCeiling && isUnderCeiling) {
      options.includedTypes = [OBJECT_TYPES.CEILING]
    }

    const hits = facility.measureObjectsInDirectionFromPoint(
      positiveZ,
      floorPosition,
      options
    )

    // Distance to primary or secondary mounting structure or roof
    let floorToCeilObjectDistance = -1
    let mountingType = undefined

    if (hits.length > 0) {
      floorToCeilObjectDistance = hits[0].distance
      mountingType = get(hits, '[0].obj3d.userData.objectType')
    } else {
      return floorToCeilObjectDistance
    }

    // There's a chance this will be undefined since
    // the mesh needs to have loaded in order to measure
    // its height.
    const boundingBoxHeight = totalMeshHeight

    if (boundingBoxHeight) {
      return {
        distance: floorToCeilObjectDistance - boundingBoxHeight,
        mountingType,
      }
    } else {
      return { distance: undefined, mountingType: undefined }
    }
  }

  isUnderCeiling(position) {
    const floorPosition = new THREE.Vector3(position.x, position.y, 0)
    const positiveZ = new THREE.Vector3(0, 0, 1)
    const options = {
      includedTypes: [OBJECT_TYPES.CEILING],
      measureTo: Facility.SURFACE,
    }

    const hits = Facility.current.measureObjectsInDirectionFromPoint(
      positiveZ,
      floorPosition,
      options
    )

    // Check for enabled ceilings
    if (hits.length) {
      const enabledCeiling = hits.filter(hit => hit.wrapper.enabled === true)
      return !!enabledCeiling.length
    }

    return false
  }

  isLastSelectedProduct() {
    const lastSelectedProduct = mostRecentSelectedObjectOfClassName(
      CLASS_NAMES.PRODUCT
    )
    const isLastSelectedProduct =
      lastSelectedProduct && lastSelectedProduct.id === this.id

    return isLastSelectedProduct
  }

  // ///
  // Interactable methods
  // ///

  drag({ dragDelta, newPosition, projectedMousePos, allIntersectedObjects }) {
    const isLocked = store.getState().layers.layers[LAYER_KEYS.PRODUCTS].locked
    if (!this.draggable || isLocked) {
      this.draggable = false
      return
    }

    const pos = projectedMousePos || newPosition
    let isOverDragIcon = true
    if (!this.isRotating && this.isDirectional) {
      isOverDragIcon = Util.isPositionOverObjectTypeWithId(
        pos,
        OBJECT_TYPES.DRAG_HANDLE,
        this.id,
        Facility
      )
    }

    const isDirectional = this.isDirectional
    const isValidRotateState =
      !this.isDragging && isDirectional && isOverDragIcon && dragDelta
    const wall = allIntersectedObjects
      ? allIntersectedObjects.find(obj => obj instanceof WallSegment)
      : null
    this.wallSegmentId = null

    if (wall && isDirectional) this.wallSegmentId = wall.id

    const isValidDragState = !this.isRotating
    if (isValidRotateState) {
      this.handleRotationDrag(dragDelta, pos)
      this.isRotating = true
    } else if (isValidDragState) {
      this.move(newPosition)
    }
    // this.reinitializeKeypressHandler = true;

    // Hide the error state of all other products
    Facility.current
      .getProducts()
      .filter(product => product.id !== this.id)
      .forEach(product => product.productDistanceEngine.hideErrorState())
  }

  move(newPosition) {
    // Make sure the fan isn't below the floor
    const z = newPosition.z <= 0 ? 1 : newPosition.z

    this.obj3d.position.set(newPosition.x, newPosition.y, z)

    if (this.useDefinedHeight) {
      // Update overhead fan height from ceiling \ mounting structure
      this.updateHeight(Facility.current, false)
    } else {
      if (!this.isForcedWallMount && this.category !== 'HEAT') {
        this.isMounted = false
        this.height = 0
      }
    }

    // If we just started dragging force reset the arrow renderer
    if (!this.isDragging && ArrowRenderer) {
      ArrowRenderer.unsubscribe(this)
      ArrowRenderer.subscribe(this)
    }

    this.isDragging = true

    if (!this.isMounted && this.mountedIndicator) {
      this.obj3d.remove(this.mountedIndicator)
      this.mountedIndicator = null
    }
  }

  drop(_, __, saveModel = true) {
    const isTouchingWall = !!Util.checkFoilWallCollisions(
      this.obj3d.position,
      this.size
    )
    const isInsideFacility = Util.isPositionsOverFacility([this.obj3d.position])
    // TODO: temp remove heater clearance checks
    if (
      (isInsideFacility && !isTouchingWall) ||
      this.ignoreErrors ||
      this.product.category === 'HEAT'
    ) {
      this.isDragging = false
      this.isRotating = false
      this.rotateTick = 0
      const prevMountingOptionId = this.mountingOptionAdderId
      let mountingOptionChanged = false

      // Update fan mounting structure based on primary/secondary roof mounting structure
      if (!this.isDirectional || this.isDirectionalOverhead) {
        const facility = Facility.current
        const { mountingType } = Product.getDistanceFromFloor(
          facility,
          this.obj3d.position,
          this.totalMeshHeight,
          this.mountToCeiling,
          this.isUnderCeiling(this.obj3d.position)
        )

        if (mountingType) {
          this.updateMountingOptionId(facility, mountingType)
          if (prevMountingOptionId !== this.mountingOptionAdderId) {
            mountingOptionChanged = true
          }
        }
      }

      if (!this.mountedIndicator || saveModel) {
        if (this.isMounted) {
          this.mountedIndicator = this.getMountedIndicator()
          this.obj3d.add(this.mountedIndicator)

          this.mountPosition = new THREE.Vector3()
          this.mountedIndicator.parent.updateMatrixWorld()
          this.mountPosition.setFromMatrixPosition(
            this.mountedIndicator.matrixWorld
          )
          this.mountPosition.z = this.obj3d.position.z
        }

        if (saveModel || mountingOptionChanged) {
          store.dispatch(
            updateProduct({
              product: this.toModel(),
            })
          )
        }
      }
    } else {
      this.obj3d.position.copy(this.lastValidPos)

      // Use timeout so status message isn't prematurely cleared
      setTimeout(() => {
        const error = 'Products must be placed inside the facility!'
        store.dispatch(
          setStatus({
            text: error,
            type: 'error',
          })
        )
      }, 500)
    }
  }

  getSnappableEdgePoints() {
    return [this.obj3d.position]
  }

  setPosition({ x, y, z }) {
    this.position = new THREE.Vector3(x, y, z)
  }

  snap(snapDelta, object, snappedMousePos, startPoint, endPoint) {
    if (this.isDirectional && !this.isDirectionalOverhead) {
      if (!startPoint || !endPoint) return

      const measurements = Facility.current.measureObjectsInRangeOfPoint(
        snappedMousePos,
        Units.inchesToNative(24),
        { sort: true }
      )
      const interiorWall = measurements.find(
        obj => obj.wrapper.layerKey === 'INTERIOR_WALLS'
      )
      const column = measurements.find(
        obj => obj.obj3d.userData.objectType === 'COLUMN'
      )

      const snapPos = this.obj3d.position.clone().add(snapDelta)
      const wall = this.findWallSegment(snapPos)
      if (wall) this.wallSegmentId = wall.id

      const mountableSurfaceInRange = !!(wall || interiorWall || column)

      // Mount the fan and update its position and direction
      if (!this.isRotating && mountableSurfaceInRange) {
        this.updateMountedDirection(startPoint, endPoint, interiorWall)
        this.updateMountedPosition(snapDelta)
      }

      if (!this.isMounted && mountableSurfaceInRange) {
        // Set the height of the mounted directional fan based on its
        // min floor clearance or default to 5'
        this.height =
          this.height ||
          Units.inchesToNative(this.minFloorClearance) ||
          Units.inchesToNative(60)

        this.isMounted = true
      }
    } else {
      this.obj3d.position.add(snapDelta)
    }
  }

  findWallSegment(pos) {
    let foundSegment = null
    const segments = Facility.current.getWallSegments()
    for (let i = 0; i < segments.length; i++) {
      const seg = segments[i]
      const outsetPoints = seg.getOutsetPoints()
      const insetPoints = seg.getInsetPoints()
      const isOnInsetLine =
        insetPoints.length > 1 &&
        Util.isPointOnSegment(
          Util.arrayPointToObjectPoint(insetPoints[0]),
          pos,
          Util.arrayPointToObjectPoint(insetPoints[1])
        )
      const isOnOutsetLine =
        outsetPoints.length > 1 &&
        Util.isPointOnSegment(
          Util.arrayPointToObjectPoint(outsetPoints[0]),
          pos,
          Util.arrayPointToObjectPoint(outsetPoints[1])
        )

      if (isOnInsetLine || isOnOutsetLine) {
        foundSegment = seg
        break
      }
    }

    return foundSegment
  }

  checkCollisions(isReceiver) {
    if (this.ignoreErrors) return

    const isProductTool = activeTool() === 'PRODUCT_TOOL'
    const draggingProducts = Facility.current
      .getProducts()
      .filter(product => product.isDragging)

    // Only update if no other products are being dragged
    if ((!isProductTool && !draggingProducts.length) || this.isDragging) {
      if (this.productDistanceEngine) {
        this.hasDangerousCollisions = !this.productDistanceEngine.productDistanceCheck(
          this,
          isReceiver
        )
      }
    }
  }

  get showHeightArrow() {
    const state = store.getState()

    const showHeightArrow =
      state.layers.layers[LAYER_KEYS.PRODUCT_HEIGHTS].visible &&
      state.camera.is3D

    return showHeightArrow
  }

  /*
    `showArrowsOverride` is not part of the general ArrowRenderer system, it's just
    a small hack used by ProductTool since its logic for rendering arrows is identical
    to the logic here except that in ProductTool we don't need this.isLastSelectedProduct()
    to be true in order to render arrows.
  */
  getArrowDescriptions(showArrowsOverride) {
    // If more than one object is selected return
    if (getSelectedObjects().length > 1) return

    // Don't show arrow descriptions when in the process of mounting so the
    // dimension labels don't get in the way of dropping the product
    if (this.isMounted && this.isDragging) return

    // Only update product distance if product moved
    const hasMoved = !isEqual(this.lastDescriptionPos, this.obj3d.position)
    if (hasMoved && !this.ignoreErrors) {
      this.checkCollisions()
    }

    const descriptions = this.getSingleProductArrowDescriptions(
      showArrowsOverride
    )

    this.reinitializeKeypressHandler = false
    this.arrowDescriptions = descriptions
    this.lastDescriptionPos.copy(this.obj3d.position)
    this.lastCameraType = store.getState().camera.is3D

    return descriptions
  }

  getSingleProductArrowDescriptions(showArrowsOverride) {
    const descriptions = []
    const showXYArrows =
      (this.draggable && this.isLastSelectedProduct()) || showArrowsOverride

    if (showXYArrows) {
      let directions = [
        new THREE.Vector3(0, 1, 0),
        new THREE.Vector3(0, -1, 0),
        new THREE.Vector3(1, 0, 0),
        new THREE.Vector3(-1, 0, 0),
      ]

      const toFanOptions = {
        measureFrom: Facility.CENTER,
        measureTo: Facility.CENTER,
        includedTypes: [OBJECT_TYPES.FAN_COLLISION_CUBE],
        xyOnly: true,
      }

      const toWallOptions = {
        measureFrom: Facility.CENTER,
        measureTo: Facility.SURFACE,
        includedTypes: [OBJECT_TYPES.WALL],
        xyOnly: true,
        measureFromFloor: true,
      }

      directions.forEach(direction => {
        const fanMeasurements = Facility.current.measureObjectsInDirectionFromObject(
          direction,
          this.obj3d,
          toFanOptions
        )
        const wallMeasurements = Facility.current.measureObjectsInDirectionFromObject(
          direction,
          this.obj3d,
          toWallOptions
        )
        const measurements = fanMeasurements.concat(wallMeasurements)
        measurements.sort((a, b) => a.distance - b.distance)

        if (measurements.length > 0) {
          const radius = Units.inchesToNative(this.size) / 2
          const measurement = measurements[0]

          // Don't show measurement to wall that product is mounted to
          if (this.isDirectional && measurement.distance - 1 <= radius) return

          const type = get(measurement, 'obj3d.userData.objectType')
          const isFan = type === OBJECT_TYPES.FAN_COLLISION_CUBE

          const keyPressHandler = e => {
            if (e.which === 13) {
              // Enter was pressed
              const distanceUnits = getDistanceUnits(store.getState())
              const distance = new Distance({
                value: Distance.unformat({
                  value: e.target.value,
                  system: distanceUnits,
                }),
                system: distanceUnits,
              })

              if (distance.value === null) return

              distance.convertTo(SYSTEMS.IMPERIAL)
              distance.value =
                distance.value - (isFan && Product.CENTER_CUBE_SIZE / 2)
              const nativeValue = distance.native()
              const newVector = direction.multiplyScalar(nativeValue)
              const vectorDiff = measurement.vector.sub(newVector)
              let newPosition = this.obj3d.position.clone()
              newPosition = newPosition.add(vectorDiff)
              const wasMounted = this.isMounted
              this.drag({ newPosition })
              this.isMounted = wasMounted
              this.drop()
            }
          }
          const arrowKey = `x:${direction.x},y:${direction.y},z:${direction.z}`
          const xAdjustment = isFan
            ? (Math.sign(measurement.vector.x) * Product.CENTER_CUBE_SIZE) / 2
            : 0
          const yAdjustment = isFan
            ? (Math.sign(measurement.vector.y) * Product.CENTER_CUBE_SIZE) / 2
            : 0

          const description = {
            key: arrowKey,
            vector: new THREE.Vector3(
              Units.inchesToNative(
                Units.nativeToInches(measurement.vector.x) + xAdjustment
              ),
              Units.inchesToNative(
                Units.nativeToInches(measurement.vector.y) + yAdjustment
              ),
              0
            ),
            position: measurement.startPoint,
            showLength: true,
            editable: !this.isDragging,
            keyPressHandler,
            reinitializeKeypressHandler: this.reinitializeKeypressHandler,
          }

          descriptions.push(description)
        }
      })
    }

    if (!this.isDirectional || this.isDirectionalOverhead) {
      if (this.showHeightArrow) {
        descriptions.push({
          key: 'height',
          vector: new THREE.Vector3(0, 0, -1 * this.height),
          position: new THREE.Vector3(
            this.obj3d.position.x,
            this.obj3d.position.y,
            this.height
          ),
          showLength: true,
          editable: false,
        })
      }
    }

    return descriptions
  }
}

export default Product
