import React, { Component } from 'react'
import { string, func, object, bool, arrayOf } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { withNetwork } from 'networkProvider'

import store from '~/store'
import LAYER_KEYS from 'config/layerKeys'

import defaultTo from 'lodash-es/defaultTo'
import isEqual from 'lodash-es/isEqual'
import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'
import { graphql } from '~/gql'

import Util from 'components/DrawingCanvas/lib/util'

import * as THREE from 'three'

import withUser from 'client/decorators/withUser'

import { updateProducts } from 'store/objects'
import { addLoadingDockProduct } from 'store/loadingDock'
import { withUnits } from 'store/units/decorators'
import { SYSTEMS } from 'store/units/constants'
import { Distance } from 'store/units/types'
import { SELECTED_PRODUCT_PANEL } from 'store/panel/types'
import withEnrichedProduct, { EnrichedProduct, getMounting } from 'client/decorators/withEnrichedProduct'
import { getAllProducts } from 'client/queries/productsQuery'
import { getAllThumbnails } from 'client/queries/getProductThumbnailQuery'
import {
  HEATER_VARIATION_FRAGMENT,
  PRODUCT_VARIATION_FRAGMENT,
} from 'client/fragments'
import ApolloClient from '~/client'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { isTouchUI } from 'store/userInterface/selectors'
import { setStatus } from 'store/status'

import CLASS_NAMES from 'config/objectClassNames'
import { DEFAULT_DESTRAT_FAN_SPEED } from 'lib/airflow/airflow'
import Units from 'components/DrawingCanvas/lib/units'
import Facility from 'components/DrawingCanvas/lib/facility'

import Flag from 'components/UIKit/Flag'
import Image from 'components/UIKit/Image'
import DimensionInput from 'components/UIKit/DimensionInput'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Select from 'components/UIKit/Select'
import Space from 'components/UIKit/Space'
import Switch from 'components/UIKit/Switch'
import Popover from 'components/UIKit/Popover'
import TextField from 'components/UIKit/TextField'
import { ProductsContext } from '~/components/PanelManager/ProductsContext'

import EmptyPanelSection from '../EmptyPanelSection'

import AddInstallInfoButton from 'components/AddInstallInfoButton'
import MetadataSection from 'components/MetadataSection'
import SaveConfiguration from 'components/SaveConfiguration'

import MountingOptionsSelect from './MountingOptionsSelect'
import MountingStructuresSelect from './MountingStructuresSelect'
import DiameterSelect from './DiameterSelect'
import VoltageSelect from './VoltageSelect'
import SpeedSelect from './SpeedSelect'
import HeightFromFloorIndicator from './HeightFromFloorIndicator'
import Checkbox from 'components/UIKit/Checkbox'
import { AppDispatch, RootState } from '~/store';
import { HeaterFragmentFragment, HeaterVariationFragmentFragment, ProductFragmentFragment, ProductVariationFragmentFragment } from '~/gql/graphql';
import { ResultOf } from '@graphql-typed-document-node/core'
import { FormattedUnitData, UnformattedUnitData } from '~/store/units/types/unit';
import { Product } from '~/store/objects/types';

const loadingStyles = (loading: boolean) => {
  if (loading) {
    return { opacity: 0.5, cursor: 'wait' }
  }

  return {}
}

export function existingVoltage<T extends { id?: string | null; inputPower?: string | null }>(voltages: T[], curVoltage: Partial<T> = {}) {
  return voltages.find(v => {
    const hasSameId = v.id === curVoltage.id
    // Some voltages have a power using –
    // and others have a power using -
    // 😊
    const hasSamePower =
      (get(v, 'inputPower') || '').replace('–', '-') ===
      (get(curVoltage, 'inputPower') || '').replace('–', '-')

    return hasSameId || hasSamePower
  })
}

function formatRotation(rotation: number) {
  rotation = rotation % 360
  if (rotation < 0) rotation += 360
  return rotation
}

function notNil<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

type Props = {
  selectedObjects: EnrichedProduct[]
  selectedObject: RootState['objects']['present']['products'][string]
  onUpdateProducts: (products: unknown) => void
  onAddLoadingDockProduct: (product: unknown) => void
  objects: RootState['objects']
  products: RootState['loadingDock']['products']
  variationId: string
  productTypesDiffer: boolean
  data: unknown
  alignment: string
  distanceUnits: string
  isTouchUI: boolean
  isLocked: boolean
  online: boolean
  user: unknown
  layers: RootState['layers']
  height: RootState['objects']['present']['selectedProductHeight']
}

type State = {
  loading: boolean
  products: (HeaterFragmentFragment | ProductFragmentFragment)[] | null
  thumbnails: Partial<Record<string, string | null>>
}

class SelectedProductPanel extends Component<Props, State> {
  state: State = {
    loading: false,
    products: null,
    thumbnails: {},
  }

  static propTypes = {
    selectedObjects: arrayOf(object),
    selectedObject: object,
    onUpdateProducts: func,
    onAddLoadingDockProduct: func,
    objects: object,
    products: object,
    variationId: string,
    productTypesDiffer: bool,
    data: object, // ProductVariation query result
    alignment: string,
    distanceUnits: string,
    isTouchUI: bool,
    isLocked: bool,
    online: bool,
    user: object,
    layers: object,
  }
  static defaultProps = {
    alignment: 'right',
  }
  static contextType = ProductsContext
  declare context: React.ContextType<typeof ProductsContext>

  async componentDidMount() {
    if (!this.state.loading && !this.state.products) {
      this.setState({ loading: true })
      const data = this.context
      this.setState({ products: data })
      const models = data!.map(p => p.model)
      const allThumbnails = await getAllThumbnails(models)
      this.setState({ thumbnails: allThumbnails })
      this.setState({ loading: false })
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (!this.state.products) return false

    return (
      !isEqual(
        this.props.objects.present.products,
        nextProps.objects.present.products
      ) ||
      !isEqual(this.props.selectedObjects, nextProps.selectedObjects) ||
      !isEqual(this.props.products, nextProps.products) ||
      !isEqual(this.props.online, nextProps.online) ||
      !isEqual(this.state.loading, nextState.loading) ||
      !isEqual(this.state.products, nextState.products) ||
      !isEqual(this.props.layers, nextProps.layers)
    )
  }

  handleChangeVariation = ({ id, isHeater }: { id: string; isHeater: boolean }) => {
    const variation = ApolloClient.readFragment<HeaterVariationFragmentFragment | ProductVariationFragmentFragment>({
      id: `ProductVariation:${id}`,
      fragment: isHeater
        ? HEATER_VARIATION_FRAGMENT
        : PRODUCT_VARIATION_FRAGMENT,
    })!
    let isTouchingWall = false
    const products = this.props.selectedObjects.map(obj => {
      const attrs = {
        ...obj,
        ...variation,
        id: obj.id,
        position: obj.position,
        variationId: variation.id,
        isMounted: obj.isMounted,
        isDirectionalOverhead:
          obj.isDirectionalOverhead && obj.canMountOverhead,
      }

      const voltages = (variation.voltages ?? []).filter(notNil)
      const curVoltages = (obj.voltages ?? []).filter(notNil)
      const curVoltage = curVoltages.find(it => it!.id === obj.voltageId)
      const newVoltage = existingVoltage(voltages, curVoltage!)

      const currentMounting = getMounting(obj)

      // Find the new mountingOption that has the same tubeLength as before
      const newMountingOption = newVoltage?.mountingOptions!.find(
        o => o!.tubeLength === currentMounting?.tubeLength
      )

      if (newVoltage && newVoltage.id) {
        attrs.voltageId = newVoltage.id
      } else {
        const firstOption = voltages[0]
        attrs.voltageId = firstOption && firstOption.id
      }

      if (newMountingOption && newMountingOption.id) {
        attrs.mountingOptionId = newMountingOption.id
      } else if ((newVoltage?.mountingOptions?.length ?? 0) > 0) {
        const firstOption = newVoltage!.mountingOptions![0]
        attrs.mountingOptionId = firstOption.id
      }

      // If new variation has no tilt, reset value;
      const positionNative = {
        x: Units.inchesToNative(attrs.position.x),
        y: Units.inchesToNative(attrs.position.y),
        z: Units.inchesToNative(attrs.position.z),
      }

      isTouchingWall =
        !isHeater && !!Util.checkFoilWallCollisions(positionNative, attrs.size)
      const isDirectional = obj.product.type === 'DIRECTIONAL'
      const isUnitHeater = obj.product.model === 'Unit Heater'

      if (!('degreesOfFreedom' in variation && variation.degreesOfFreedom) && isDirectional && !isUnitHeater)
        attrs.rotation.x = 90

      return attrs
    })

    if (isTouchingWall) {
      const error = 'Resizing Fan Not Allowed Due To Size Restraints'
      store.dispatch(setStatus({ text: error, type: 'error' }))
    } else {
      this.props.onUpdateProducts(products)
    }
  }

  handleInputChange = (key: string, value: unknown) => {
    const { selectedObjects, onUpdateProducts } = this.props
    onUpdateProducts(
      selectedObjects.map(obj => ({
        ...obj,
        [key]: value,
      }))
    )
  }

  handleInputsChange = (keys: string[], values: unknown[]) => {
    const { selectedObjects, onUpdateProducts } = this.props
    const newData: Record<string, unknown> = {}
    keys.forEach((k, kdx) => (newData[k] = values[kdx]))
    onUpdateProducts(
      selectedObjects.map(obj => ({
        ...obj,
        ...newData,
      }))
    )
  }

  handleDirectionalOverheadChange = (isDirectionalOverhead: boolean) => {
    const { selectedObjects, onUpdateProducts } = this.props
    const rotationX = isDirectionalOverhead ? 0 : 90

    onUpdateProducts(
      selectedObjects.map(obj => ({
        ...obj,
        rotation: {
          x: rotationX,
          y: obj.rotation.y,
          z: obj.rotation.z,
        },
        isDirectionalOverhead,
        isForcedWallMount: isDirectionalOverhead
          ? false
          : obj.isForcedWallMount,
        isMounted: isDirectionalOverhead ? false : obj.isMounted,
        tubeLength: 0,
      }))
    )
  }

  handleForceWallMount = (isMounted: boolean) => {
    const { selectedObjects } = this.props

    this.props.onUpdateProducts(
      selectedObjects.map(obj => ({
        ...obj,
        rotation: {
          x: !isMounted && get(obj, 'mountingOptionAdderId') === '46' ? 90 : 0,
          y: obj.rotation.y,
          z: obj.rotation.z,
        },
        isMounted: isMounted,
        isDirectionalOverhead: isMounted ? false : obj.isDirectionalOverhead,
        isForcedWallMount: isMounted,
        height: get(obj, 'product.model') === 'Pivot 2.0' ? 120 : 60, // 10ft min for pivot
      }))
    )
  }

  handleMountedRotationChange = (value: { x: number; y: number; z: number }) => {
    const { selectedObjects, onUpdateProducts } = this.props

    onUpdateProducts(
      selectedObjects.map(obj => ({
        ...obj,
        rotation: value,
      }))
    )
  }

  handleSaveConfig = (onSuccess: () => void) => {
    const { selectedObjects, onAddLoadingDockProduct } = this.props
    if (selectedObjects.length && selectedObjects.length === 1) {
      onAddLoadingDockProduct(selectedObjects[0])
      if (onSuccess) onSuccess()
    }
  }

  handleMountingStructureChange = (value: string, mountedOn: string) => {
    const { selectedObjects, onUpdateProducts } = this.props

    const updatedProducts = selectedObjects.map(obj => {
      const voltages = obj.voltages || []
      const mountingOptions =
        voltages.find(v => v.id === obj.voltageId)?.mountingOptions ?? []
      const selectedMountingOption =
        mountingOptions
          .filter(o => {
            if (mountedOn === 'WALL') return o!.forWall
            if (mountedOn === 'COLUMN') return o!.forColumn
            if (mountedOn === 'PEDESTAL') {
              if (obj.product.id === '10') {
                if (value === '46') {
                  // @ts-expect-error not sure where that's supposed to come from but I don't want to delete the logic
                  return o!.forPedestal && o!.label === 'Low Rider'
                } else {
                  // @ts-expect-error not sure where that's supposed to come from but I don't want to delete the logic
                  return o!.forPedestal && o!.label !== 'Low Rider'
                }
              }
              return o!.forPedestal
            }
            return o!.forOverhead
          })
          .find(o => obj.product.id === '10') || {}

      const newRotation = {
        x: obj.rotation.x,
        y: obj.rotation.y,
        z: obj.rotation.z,
      }

      if (value === '46') {
        newRotation.x = 90
      }

      return {
        ...obj,
        rotation: newRotation,
        mountingOptionAdderId: value,
        mountingOptionId: get(selectedMountingOption, 'id'),
      }
    })

    onUpdateProducts(updatedProducts)
  }

  handleMountingOptionChange = (value: string) => {
    const { selectedObjects, onUpdateProducts } = this.props

    const updatedProducts = selectedObjects.map(obj => {
      const voltages = obj.voltages || []
      const { mountingOptions = [] } =
        voltages.find(v => v.id === obj.voltageId) || {}
      const selectedMountingOption = mountingOptions!.find(o => o.id === value)

      return {
        ...obj,
        tubeLength: get(selectedMountingOption, 'tubeLength'),
        mountingOptionId: value,
      }
    })

    onUpdateProducts(updatedProducts)
  }

  handleVoltageChange = (value: string) => {
    const { selectedObjects, onUpdateProducts } = this.props

    const updatedProducts = selectedObjects.map(obj => {
      const selectedVoltage = obj.voltages!.find(v => v.id === value)
      const currentVoltage = obj.voltages!.find(v => v.id === obj.voltageId) || {
        mountingOptions: [],
      }

      const currentOptions = get(currentVoltage, 'mountingOptions', [])
      const currentMountingOption =
        currentOptions!.find(v => v.id === obj.mountingOptionId)

      const selectedOptions = get(selectedVoltage, 'mountingOptions', [])
      const selectedMountingOption =
        selectedOptions!.find(o => o.id === currentMountingOption?.id) ||
        selectedOptions![0]

      return {
        ...obj,
        voltage: get(selectedVoltage, 'inputPower'),
        voltageId: value,
        tubeLength: get(selectedMountingOption, 'tubeLength'),
        mountingOptionId: get(selectedMountingOption, 'id'),
        hasUnknownVoltage: false,
      }
    })

    onUpdateProducts(updatedProducts)
  }

  handleChangeType = async (selectedProductVariation: any) => {
    const { selectedObjects, onUpdateProducts } = this.props

    const allProductVariations = graphql(`
      query ProductSelectedProductPanel($productId: ID!) {
        Product(id: $productId) {
          id
          variations {
            id
            size
            label
            cfdId
            voltages {
              id
              inputPower
              mountingOptions {
                id
                tubeLength
              }
            }
            mountingOptionAdders {
              id
              mountingType
            }
            heaterData {
              id
              minTubeLength
              tubeDiameter
              burnerBoxWidth
              burnerBoxClearanceDepth
              burnerBoxClearanceHeight
              burnerBoxClearanceWidth
              angle
              irhClearanceA
              irhClearanceB
              irhClearanceC
              irhClearanceD
              spotHeatHeight
              boxHeightA
              boxWidthB
              boxDepthF
              blowerDepthE
              uhClearanceTop
              uhClearanceFlueConnector
              uhClearanceAccessPanel
              uhClearanceNonAccessSide
              uhClearanceBottom
              uhClearanceRear
            }
          }
        }
      }
    `)
    const selectedProductVariations: NonNullable<NonNullable<ResultOf<(typeof allProductVariations)>['Product']>['variations']>[] = []

    for (let i = 0; i < selectedObjects.length; i++) {
      const ret = await ApolloClient.query({
        query: allProductVariations,
        variables: { productId: selectedProductVariation.product.id },
      })
      const variations = ret.data.Product?.variations ?? []
      selectedProductVariations.push(variations)
    }

    const updatedProducts = selectedObjects.map((obj, idx) => {
      const matchedVariation = obj.label?.includes('IRH')
        ? selectedProductVariations[idx].find(v =>
          (v.label ?? '').includes(obj.label?.split('-')[1] ?? '')
        )
        : selectedProductVariations[idx].find(v => v.size === obj.size)
      const newVariation = matchedVariation ?? selectedProductVariation

      const curVoltages =
        get(this.props, 'data.ProductVariation.voltages') || []
      const curVoltage = curVoltages.find(({ id }) => id === obj.voltageId)

      // default to first available options
      const defaultVoltage = newVariation.voltages?.[0]
      const defaultMountingOption = defaultVoltage.mountingOptions?.[0]
      const defaultMountingOptionAdder = get(
        newVariation,
        'mountingOptionAdders[0]'
      )

      // find new option that matches existing option (if possible)
      const newVoltage = existingVoltage(newVariation.voltages, curVoltage)
      const newMountingOption =
        defaultMountingOption &&
        (newVoltage ?? defaultVoltage).mountingOptions.find(
          (m: { tubeLength?: number }) => m.tubeLength === getMounting(obj)?.tubeLength
        )
      const currentMountingOptionAdder = obj.mountingOptionAdders?.find(
        m => m.id === obj.mountingOptionAdderId
      )
      const currentMountingOptionAdderType = get(
        currentMountingOptionAdder,
        'mountingType'
      )
      const newMountingOptionAdder = newVariation.mountingOptionAdders.find(
        (m: { mountingType?: string }) => m.mountingType === currentMountingOptionAdderType
      )

      return {
        ...obj,
        ...newVariation,
        id: obj.id,
        variationId: newVariation.id,
        productId: selectedProductVariation.product.id,
        isDirectional: selectedProductVariation.product.type === 'DIRECTIONAL',
        modelName: selectedProductVariation.product.model,
        model: selectedProductVariation.product.model,
        product: {
          ...obj.product,
          ...selectedProductVariation.product,
          heaterData: matchedVariation?.heaterData,
        },
        voltageId: defaultTo(get(newVoltage, 'id'), get(defaultVoltage, 'id')),
        voltage: defaultTo(
          get(newVoltage, 'inputPower'),
          get(defaultVoltage, 'inputPower')
        ),
        mountingOptionId: defaultTo(
          get(newMountingOption, 'id'),
          get(defaultMountingOption, 'id')
        ),
        mountingOptionAdderId: defaultTo(
          get(newMountingOptionAdder, 'id'),
          get(defaultMountingOptionAdder, 'id')
        ),
        tubeLength: defaultTo(
          get(newMountingOption, 'tubeLength'),
          get(defaultMountingOption, 'tubeLength')
        ),
        cageHeight: get(selectedProductVariation, 'cageHeight'),
        tiltEnd: get(selectedProductVariation, 'degreesOfFreedom.end'),
        tiltStart: get(selectedProductVariation, 'degreesOfFreedom.start'),
        tiltStep: get(selectedProductVariation, 'degreesOfFreedom.step'),
      }
    })

    onUpdateProducts(updatedProducts)
  }

  getConfigFromObject(obj: Product) {
    if (!obj) return

    return {
      coolingFanSpeedId: obj.coolingFanSpeedId,
      destratFanSpeedId: obj.destratFanSpeedId,
      mountingOptionAdderId: obj.mountingOptionAdderId,
      mountingOptionId: obj.mountingOptionId,
      rotation: obj.rotation,
      variationId: obj.variationId,
      voltageId: obj.voltageId,
    }
  }

  isDupeConfig() {
    const { selectedObject, products } = this.props
    if (!products) return false
    const selectedProductConfig = this.getConfigFromObject(selectedObject)
    let isDupe = false

    Object.keys(products).forEach((key, index) => {
      // @ts-expect-error loading dock has no types yet
      const config = this.getConfigFromObject(products[key])
      if (isEqual(config, selectedProductConfig)) isDupe = true
    })

    return isDupe
  }

  getObjectValue<T>(obj: T, value: T[keyof T] | undefined, key: keyof T, defaultValue?: T[keyof T]) {
    if (value === undefined) {
      return get(obj, key, defaultValue)
    } else if (value !== get(obj, key, defaultValue)) {
      return false
    } else {
      return value
    }
  }

  getDirectionalTiltValues(obj: { degreesOfFreedom?: {} | null }) {
    if (!obj.degreesOfFreedom) return []

    return [
      { value: '', label: 'Choose a tilt...', disabled: true },
      { value: '0', label: '0º' },
      { value: '22.5', label: '22.5º' },
      { value: '45', label: '45º' },
      { value: '67.5', label: '67.5º' },
      { value: '90', label: '90º' },
    ]
  }
  getHeaterRotationValues() {
    return [
      { value: '0', label: '0º' },
      { value: '90', label: '90º' },
      { value: '180', label: '180º' },
      { value: '270', label: '270º' },
    ]
  }

  getHeaterTiltValues() {
    return [
      { value: '0', label: '0º' },
      { value: '45', label: '45º' },
      { value: '-45', label: '-45º' },
    ]
  }
  getHeaterAngleValues() {
    return [
      { value: '15', label: '15º' },
      { value: '30', label: '30º' },
      { value: '45', label: '45º' },
      { value: '60', label: '60º' },
    ]
  }

  getHeaterFuelTypeValues(variationId: string) {
    const vId = parseInt(variationId)
    let fuelTypeArray = [{ value: 'Natural Gas', label: 'Natural Gas' }]

    if (vId !== 89 && vId !== 90)
      fuelTypeArray.push({ value: 'LPG Propane', label: 'LPG Propane' })

    return fuelTypeArray
  }

  mountingOptionsLabel(mountedOn: string) {
    if (mountedOn === 'PEDESTAL') return 'Pedestal Height'
    if (mountedOn === 'OVERHEAD') return 'Drop Tube'
    return 'Mount'
  }

  renderSaveConfiguration = () => {
    const { selectedObject } = this.props
    return (
      <SaveConfiguration
        selectedObjectId={selectedObject.id}
        isDupeConfig={this.isDupeConfig()}
        onConfigurationSave={(onSuccess: () => void) => this.handleSaveConfig(onSuccess)}
      />
    )
  }

  renderMountingDetails(isLocked: boolean, isHeater: boolean, isUnitHeater: boolean) {
    const { variationId, selectedObjects, distanceUnits } = this.props
    let height: EnrichedProduct['height'] | undefined | false
    let mountingOptionAdderId: EnrichedProduct['mountingOptionAdderId'] | undefined | false
    let mountingOptionId: string | null | undefined
    let voltageId: EnrichedProduct['voltageId'] | undefined | false
    let isUnderCeiling = true
    let mountToCeiling = true
    let rotation = { x: 0, y: 0, z: 0 }
    let angle = 15
    let isDirectional = false
    let canMountOverhead = false
    let canMountOnWall = false
    let isDirectionalOverhead = false
    let isMounted = false
    let isForcedWallMount = false
    let tiltOptions: { value: string; label: string; disabled?: boolean }[] = []
    let mountedOn = 'OVERHEAD'
    let isHornet = false

    const variations: (HeaterVariationFragmentFragment | ProductVariationFragmentFragment)[] =
      this.state.products!.find(p => p.id === selectedObjects[0].product.id)?.variations ?? []

    selectedObjects.forEach(obj => {
      height = this.getObjectValue(obj, height, 'height')
      voltageId = this.getObjectValue(obj, voltageId, 'voltageId')
      mountingOptionAdderId = this.getObjectValue(
        obj,
        mountingOptionAdderId,
        'mountingOptionAdderId'
      )

      const mountingInfo = getMounting(obj)
      const tubeLength = mountingInfo?.tubeLength
      // Get the product variation that corresponds to the size and tube size
      if (tubeLength) {
        mountingOptionId = null
        const variationBySize = variations?.find(
          variation => variation.size === obj.size
        )
        if (variationBySize) {
          const variationByVoltage = variationBySize.voltages!.find(
            voltage => voltage.id === obj.voltageId
          )
          if (variationByVoltage) {
            const matchedVariationById = variationByVoltage.mountingOptions!.find(
              variation => variation.id === obj.mountingOptionId
            )
            const matchedVariationBySize = variationByVoltage.mountingOptions!.find(
              variation => variation.tubeLength === tubeLength
            )

            // get id match first, for case of yellow jacket, where it has multiple entries with the same pedestal height
            const matchedVariation =
              matchedVariationById || matchedVariationBySize

            if (matchedVariation) {
              mountingOptionId = matchedVariation.id
            }
          }
        }
      }

      mountingOptionId =
        mountingOptionId ||
        this.getObjectValue(obj, mountingOptionId, 'mountingOptionId')

      rotation = get(obj, 'rotation', { x: 0, y: 0, z: 0 })
      angle = get(obj, 'angle', 15)

      const nativePos = new THREE.Vector3(
        Units.inchesToNative(obj.position.x),
        Units.inchesToNative(obj.position.y),
        0
      )

      const product = Facility.current
        .getProducts()
        .find(product => product.id === obj.id)
      if (product && !product.isUnderCeiling(nativePos)) {
        isUnderCeiling = false
      }

      isHornet = product?.model === 'Hornet'

      mountToCeiling = obj.mountToCeiling || false

      if (!isDirectional) {
        isDirectional = obj.product.type === 'DIRECTIONAL'
      }
      isMounted = obj.isMounted ?? false
      isForcedWallMount = obj.isForcedWallMount ?? false
      canMountOnWall = obj.canMountOnWall ?? false
      canMountOverhead = obj.canMountOverhead ?? false
      isDirectionalOverhead = obj.isDirectionalOverhead ?? false
      tiltOptions = this.getDirectionalTiltValues(obj)
      if (product) mountedOn = product.mountedOn
    })

    const mountedSwitch =
      isUnderCeiling && !isDirectional ? (
        <Space bottom="base">
          <Switch
            disabled={isLocked ? true : false}
            onClick={event => {
              event.stopPropagation()
              event.preventDefault()
              this.handleInputChange('mountToCeiling', !mountToCeiling)
            }}
            label={'Ceiling Mounted'}
            isChecked={mountToCeiling}
          />
        </Space>
      ) : null
    const mountedDirectionalOverheadSwitch =
      canMountOverhead && isDirectional && !isUnitHeater ? (
        <Space bottom="base">
          <Switch
            disabled={isLocked ? true : false}
            onClick={event => {
              event.stopPropagation()
              event.preventDefault()
              this.handleDirectionalOverheadChange(!isDirectionalOverhead)
            }}
            label={'Overhead Mounted'}
            isChecked={isDirectionalOverhead}
          />
        </Space>
      ) : null
    const forceWallMountSwitch =
      canMountOnWall && isDirectional ? (
        <Space bottom="base">
          <Switch
            disabled={isLocked ? true : false}
            onClick={event => {
              event.stopPropagation()
              event.preventDefault()
              this.handleForceWallMount(!isMounted)
            }}
            label={'Force Wall Mount'}
            isChecked={isForcedWallMount}
          />
        </Space>
      ) : null
    const mountingStructureSelect =
      variationId && !isHornet ? (
        <Space bottom="base">
          <MountingStructuresSelect
            inline
            label="Structure"
            labelWidth="80px"
            name="product-mountingStructure"
            size="150px"
            placeholder=""
            disabled={isLocked ? true : false}
            productVariationId={variationId}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              this.handleMountingStructureChange(event.target.value, mountedOn)
            }
            value={mountingOptionAdderId || ''}
            isHeater={isHeater}
          />
        </Space>
      ) : null
    const rotationField = isDirectional ? (
      <Space bottom="base">
        <TextField
          inline
          label="Rotation"
          labelWidth="80px"
          width="150px"
          value={rotation.z ? Math.round(rotation.z) : '0'}
          overrideValue={Math.round(rotation.z).toString()}
          type="number"
          disabled={isLocked ? true : false}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const value = formatRotation(parseFloat(event.target.value)) || 0
            const newRotation = {
              x: rotation.x,
              y: rotation.y,
              z: Math.round(value),
            }
            if (isMounted) {
              this.handleMountedRotationChange(newRotation)
            } else {
              this.handleInputChange('rotation', newRotation)
            }
          }}
        />
      </Space>
    ) : isHeater ? (
      <>
        <Space bottom="base">
          <Select
            inline
            label="Rotation"
            labelWidth="80px"
            name="product-rotation"
            placeholder=""
            size="150px"
            disabled={isLocked ? true : false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newRotation = {
                x: rotation.x,
                y: parseFloat(event.target.value),
                z: rotation.z,
              }
              this.handleInputChange('rotation', newRotation)
            }}
            value={rotation.y}
            options={this.getHeaterRotationValues()}
          />
        </Space>
        <Space bottom="base">
          <Select
            inline
            label="Tilt"
            labelWidth="80px"
            name="product-tilt"
            placeholder=""
            size="150px"
            disabled={isLocked ? true : false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newRotation = {
                x: parseFloat(event.target.value),
                y: rotation.y,
                z: rotation.z,
              }
              this.handleInputChange('rotation', newRotation)
            }}
            value={rotation.x}
            options={this.getHeaterTiltValues()}
          />
        </Space>
      </>
    ) : null
    const tiltSelect = tiltOptions.length ? (
      <Space bottom="base">
        <Select
          inline
          label="Tilt"
          labelWidth="80px"
          name="product-tilt"
          placeholder=""
          size="150px"
          disabled={isLocked ? true : false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newRotation = {
              x: parseFloat(event.target.value),
              y: rotation.y,
              z: rotation.z,
            }
            this.handleInputChange('rotation', newRotation)
          }}
          value={rotation.x}
          options={tiltOptions}
        />
      </Space>
    ) : isUnitHeater ? (
      <Space bottom="base">
        <Select
          inline
          label="Louver  Angle"
          labelWidth="80px"
          name="louver-angle"
          placeholder=""
          size="150px"
          disabled={isLocked ? true : false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = formatRotation(parseFloat(event.target.value)) || 0
            this.handleInputsChange(['angle'], [value])
          }}
          value={angle}
          options={this.getHeaterAngleValues()}
        />
      </Space>
    ) : null
    const mountingOptionSelect =
      variationId && voltageId && !isHornet && !isHeater ? (
        <Space bottom="base">
          <MountingOptionsSelect
            inline
            label={this.mountingOptionsLabel(mountedOn)}
            labelWidth="80px"
            name="product-tubeLength"
            size="150px"
            placeholder=""
            disabled={isLocked ? true : false}
            mountedOn={mountedOn}
            productVariationId={variationId}
            voltageId={voltageId}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              this.handleMountingOptionChange(event.target.value)
            }
            value={mountingOptionId}
            mountingOptionAdderId={mountingOptionAdderId || ''}
            productVariations={variations}
          />
        </Space>
      ) : null
    const heightInput =
      isHeater || (isDirectional && isMounted) || isHornet ? (
        <Space bottom="base">
          <DimensionInput
            inline
            label="Height From Floor"
            labelWidth="80px"
            name={`product-height`}
            units={distanceUnits}
            disabled={isLocked ? true : false}
            distance={
              new Distance({
                value: height || null,
                system: SYSTEMS.IMPERIAL,
              })
            }
            onChange={({ distance }: { distance: Distance<FormattedUnitData | UnformattedUnitData> }) => {
              const value = distance.imperial() || 0
              this.handleInputChange('height', value)
            }}
          />
        </Space>
      ) : (
        <HeightFromFloorIndicator />
      )
    return (
      <PanelSection title="Mounting Details">
        {mountedSwitch}
        {mountedDirectionalOverheadSwitch}
        {forceWallMountSwitch}
        {mountingStructureSelect}
        {rotationField}
        {tiltSelect}
        {mountingOptionSelect}
        {heightInput}
      </PanelSection>
    )
  }

  renderProductDetails(isLocked: boolean, isHeater: boolean) {
    const { variationId, selectedObjects, selectedObject } = this.props
    // NOTE: selectedObjects should not be passed down to this component
    // It is application logic not visual logic
    const variation = selectedObjects.find(o => o.variationId === variationId)
    if (!variation) return null

    const threeJSProduct = Facility.current
      .getProducts()
      .find(p => p.id === variation.id)

    let voltageId!: EnrichedProduct['voltageId'] | null | false
    let coolingFanSpeedId!: EnrichedProduct['coolingFanSpeedId'] | null | false
    let destratFanSpeedId!: EnrichedProduct['destratFanSpeedId'] | null | false
    let hasUnknownVoltage
    let fuelType!: EnrichedProduct['fuelType'] | null | false

    selectedObjects.forEach(obj => {
      voltageId = this.getObjectValue(obj, voltageId, 'voltageId')
      hasUnknownVoltage = get(obj, 'hasUnknownVoltage', true)
      if (obj.hasUnknownVoltage !== hasUnknownVoltage) {
        this.handleInputChange('hasUnknownVoltage', hasUnknownVoltage)
      }
      const availableFanSpeeds = get(obj, 'product.distinctFanSpeeds') || []
      const defaultFanSpeedOptions = ['75%', '80%', 'SPEED 5']
      const defaultCoolingFanSpeed = defaultFanSpeedOptions.find(
        speed =>
          availableFanSpeeds.findIndex(
            availableSpeed => availableSpeed.speed === speed
          ) >= 0
      )
      coolingFanSpeedId = this.getObjectValue(
        obj,
        coolingFanSpeedId,
        'coolingFanSpeedId',
        defaultCoolingFanSpeed
      )
      destratFanSpeedId = this.getObjectValue(
        obj,
        destratFanSpeedId,
        'destratFanSpeedId',
        DEFAULT_DESTRAT_FAN_SPEED
      )
      fuelType = this.getObjectValue(obj, fuelType, 'fuelType', 'Natural Gas')
    })
    const detailedLabel = get(selectedObject, 'detailedLabel', '')
    const { variations, fanSpeeds } = this.state.products!.find(it => it.id === variation.product.id)! as ProductFragmentFragment
    const voltageValue = hasUnknownVoltage ? 'unknown' : voltageId || ''
    return (
      <PanelSection title="Product Details">
        {variationId && (
          <Space bottom="base">
            <DiameterSelect
              inline
              label={isHeater ? 'Size' : 'Diameter'}
              labelWidth="125px"
              name="product-size"
              placeholder=""
              size="150px"
              disabled={isLocked ? true : false}
              productId={variation.product.id}
              variations={variations}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                this.handleChangeVariation({ id: event.target.value, isHeater })
              }
              value={variationId || ''}
              isHeater={isHeater}
            />
          </Space>
        )}
        {variationId && (
          <Space bottom="base">
            <VoltageSelect
              inline
              label="Voltage"
              labelWidth="125px"
              name="product-voltage"
              placeholder=""
              size="150px"
              disabled={isLocked ? true : false}
              productVariationId={variationId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value === 'unknown') {
                  this.handleInputChange('hasUnknownVoltage', true)
                } else {
                  this.handleVoltageChange(event.target.value)
                }
              }}
              value={voltageValue}
              isHeater={isHeater}
            />
          </Space>
        )}
        {isHeater && (
          <Space bottom="base">
            <Select
              inline
              label="Fuel Type"
              labelWidth="80px"
              name="product-fuel-type"
              placeholder=""
              size="150px"
              disabled={isLocked ? true : false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                this.handleInputChange('fuelType', event.target.value)
              }
              value={fuelType}
              options={this.getHeaterFuelTypeValues(variationId)}
            />
          </Space>
        )}
        {!isHeater && (
          <>
            <Space bottom="base">
              <SpeedSelect
                inline
                label="Cooling Speed"
                labelWidth="125px"
                name="cooling-speed"
                placeholder=""
                size="150px"
                disabled={isLocked ? true : false}
                productId={get(variation, 'product.id')}
                mountedOn={get(threeJSProduct, 'mountedOn')}
                fanSpeeds={fanSpeeds}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.handleInputChange(
                    'coolingFanSpeedId',
                    event.target.value
                  )
                }
                value={coolingFanSpeedId || ''}
              />
            </Space>
            <Space bottom="base">
              <SpeedSelect
                inline
                label="Destrat Speed"
                labelWidth="125px"
                name="destrat-speed"
                placeholder=""
                size="150px"
                disabled={isLocked ? true : false}
                productId={get(variation, 'product.id')}
                mountedOn={get(threeJSProduct, 'mountedOn')}
                fanSpeeds={fanSpeeds}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.handleInputChange(
                    'destratFanSpeedId',
                    event.target.value
                  )
                }
                value={destratFanSpeedId || ''}
              />
            </Space>
          </>
        )}
        <Space bottom="base">
          <TextField
            label="Detailed Label"
            value={detailedLabel || ''}
            overrideValue={detailedLabel || ''}
            inline
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const newLabel = e.target.value || ''
              this.handleInputChange('detailedLabel', newLabel)
            }}
          />
        </Space>
        <Space>
          <Checkbox
            checked={selectedObjects[0].ignoreErrors}
            label="Acknowledge and Ignore Errors"
            onChange={e => {
              this.handleInputChange(
                'ignoreErrors',
                !selectedObjects[0].ignoreErrors
              )
            }}
          />
        </Space>
      </PanelSection>
    )
  }

  renderContent() {
    const { products, loading, thumbnails } = this.state

    // If still waiting on products return empty
    if (!products || !products.length) return null

    const { selectedObjects, onUpdateProducts, online } = this.props
    const obj = selectedObjects[0]
    const model = obj.product.model
    const category = obj.product.category
    const isLocked = store.getState().layers.layers[LAYER_KEYS.PRODUCTS].locked
    const isBAFProduct = !model.match('Existing')
    const isHeater = category === 'HEAT'
    const isUnitHeater = model === 'Unit Heater'

    const productVariations = sortBy(products, 'sortIndex')
      .filter(p => p.category === category)
      .map(p => p.defaultVariation)
    return (
      <div style={loadingStyles(loading)}>
        <PanelSection>
          {!isLocked ? (
            <Popover
              scrollable
              height={'400px'}
              items={(productVariations || []).map(productVariation => {
                return {
                  title: productVariation!.product.model,
                  image: {
                    src: thumbnails[productVariation!.product.model],
                    alt: 'placeholder',
                    width: '30',
                    height: '30',
                  },
                  onClick: async () => this.handleChangeType(productVariation),
                }
              })}
              renderTrigger={({ isDropdownVisible }: { isDropdownVisible: boolean }) => (
                <Flag
                  vCenter
                  dropdown={online}
                  isDropdownVisible={isDropdownVisible}
                  media={
                    <Image
                      src={thumbnails[model] ?? undefined}
                      alt="placeholder"
                      width="75"
                      height="75"
                    />
                  }
                >
                  {model && <strong>{model}</strong>}
                </Flag>
              )}
            />
          ) : (
            <Flag
              vCenter
              media={
                <Image
                  src={thumbnails[model] ?? undefined}
                  alt="placeholder"
                  width="75"
                  height="75"
                />
              }
            >
              {model && <strong>{model}</strong>}
            </Flag>
          )}
        </PanelSection>
        {this.renderSaveConfiguration()}
        {this.renderMountingDetails(isLocked, isHeater, isUnitHeater)}
        {this.renderProductDetails(isLocked, isHeater)}
        {isBAFProduct && (
          <Space bottom="l">
            <AddInstallInfoButton
              type="product"
              selectedObject={this.props.selectedObject}
              online={online}
              disabled={isLocked ? true : false}
            />
          </Space>
        )}
        <MetadataSection
          objects={selectedObjects}
          onBlur={onUpdateProducts}
          disabled={isLocked ? true : false}
        />
      </div>
    )
  }

  render() {
    const {
      selectedObjects,
      productTypesDiffer,
      alignment,
      isTouchUI,
    } = this.props
    let content = null
    if (selectedObjects.length) {
      if (productTypesDiffer) {
        content = (
          <EmptyPanelSection icon="duplicate">
            Multiple product types selected.
          </EmptyPanelSection>
        )
      } else {
        content = this.renderContent()
      }
    }

    return (
      <Panel
        title="Product"
        alignment={alignment}
        docked
        panelKey={SELECTED_PRODUCT_PANEL}
        scrollable
        hasToolbar={isTouchUI}
      >
        {content}
      </Panel>
    )
  }
}

// TODO: Height should be updated directly on the product representation in redux (this duplicates data/logic)
// We should instead be able to access the current height from selectedObject
const mapStateToProps = ({
  objects,
  selectedObjects,
  loadingDock,
  ...store
}: RootState) => ({
  selectedObject: mostRecentSelectedObjectOfClassName(CLASS_NAMES.PRODUCT, {
    selectedObjects,
    objects,
  }),
  height: objects.present.selectedProductHeight,
  products: loadingDock.products,
  isTouchUI: isTouchUI(store),
  layers: store.layers,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onUpdateProducts(products: unknown[]) {
    dispatch(updateProducts(products))
  },
  onAddLoadingDockProduct(product: unknown) {
    dispatch(addLoadingDockProduct(product))
  },
})

export default compose(
  withEnrichedProduct,
  withUnits,
  withNetwork,
  withUser,
  appConnect(mapStateToProps, mapDispatchToProps)
)(SelectedProductPanel)
