import { TYPES } from '../constants'

import { unformatDistance, formatDistance } from './distance'
import { unformatTemperature, formatTemperature } from './temperature'
import { unformatVelocity, formatVelocity } from './velocity'
import type { FormattedUnitData, UnformattedUnitData } from '../types/unit'

export interface FormatOptions {
  nullValue?: string
  round?: true|number
  roundCentimeters?: boolean
  both?: boolean
  power?: true|number
  feetOnly?: boolean
}

export function formatUnits({ value, type, system }: UnformattedUnitData, options: FormatOptions = {}): string|null {
  switch (type) {
    case TYPES.DISTANCE:
      return formatDistance({ value, system }, options)
    case TYPES.TEMPERATURE:
      return formatTemperature({ value, system }, options)
    case TYPES.VELOCITY:
      return formatVelocity({ value, system }, options)
    default:
      return value?.toString() ?? options.nullValue!
  }
}

export function unformatUnits({ value, type, system }: FormattedUnitData, options: FormatOptions = {}): number|null {
  switch (type) {
    case TYPES.DISTANCE:
      return unformatDistance({ value, system }, options)
    case TYPES.TEMPERATURE:
      return unformatTemperature({ value, system }, options)
    case TYPES.VELOCITY:
      return unformatVelocity({ value, system }, options)
    default:
      return Number(value)
  }
}
