import styled from 'styled-components'

const Image = styled.img<{ isLoaded?: boolean; circle?: boolean }>`
  opacity: ${props => (props.isLoaded ? `1` : `0`)};
  transition: opacity 0.3s ease-in;
  visibility: ${props => (props.isLoaded ? `visible` : `hidden`)};
  cursor: ${props => (props.onClick ? 'pointer' : null)};
  ${props => props.circle && `border-radius: 50%;`};
`

export default Image
