import React from 'react'
import PropTypes from 'prop-types'

import theme from 'config/theme'

import Container from './styled/Container'
import Content from './styled/Content'
import Mask from './styled/Mask'

const Overlay = ({
  backgroundColor = theme.colors.light.bg,
  children,
  offsetSide,
  offsetValue,
  opacity = '0.75',
  textColor = theme.colors.light.fg,
}) => (
  <Container offsetSide={offsetSide} offsetValue={offsetValue}>
    <Content textColor={textColor}>{children}</Content>
    <Mask backgroundColor={backgroundColor} opacity={opacity} />
  </Container>
)

Overlay.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  offsetSide: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  offsetValue: PropTypes.string,
  opacity: PropTypes.string,
  textColor: PropTypes.string,
}

export default Overlay
