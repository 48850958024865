export const primaryUses = [
  { label: 'Agriculture', value: 'AGRICULTURE' },
  { label: 'Automotive Service Center', value: 'AUTOMOTIVE_SERVICE_CENTER' },
  { label: 'Automotive Showroom', value: 'AUTOMOTIVE_SHOWROOM' },
  { label: 'Cafeteria', value: 'CAFETERIA' },
  { label: 'Cardio Equipment', value: 'CARDIO_EQUIPMENT' },
  { label: 'Church/Religion', value: 'CHURCH_RELIGION' },
  { label: 'Classroom', value: 'CLASSROOM' },
  { label: 'Group Exercise Room', value: 'GROUP_EXERCISE_ROOM' },
  { label: 'Gymnasium', value: 'GYMNASIUM' },
  { label: 'Hangar', value: 'HANGAR' },
  { label: 'Library', value: 'LIBRARY' },
  { label: 'Manufacturing', value: 'MANUFACTURING' },
  { label: 'Office', value: 'OFFICE' },
  { label: 'Picking/Sorting', value: 'PICKING_SORTING' },
  { label: 'Residential', value: 'RESIDENTIAL' },
  { label: 'Shipping/Receiving', value: 'SHIPPING_RECEIVING' },
  { label: 'Storage (racking)', value: 'STORAGE_RACKING' },
  { label: 'Strength Training', value: 'STRENGTH_TRAINING' },
  { label: 'Warehouse', value: 'WAREHOUSE' },
  { label: 'Other', value: 'OTHER' },
] as const
export type PrimaryUses = (typeof primaryUses)[number]['value']
export const objectives = [
  {
    label: 'Cooling',
    value: 'COOLING',
    description: "Provide a cooling effect for your facility's occupants",
  },
  {
    label: 'Destratification',
    value: 'DESTRATIFICATION',
    description:
      'Mixing hot air at the ceiling with cold air at the floor, creating a uniform temperature.',
  },
  {
    label: 'Heating',
    value: 'HEATING',
    description: "Provide a heating effect for your facility's occupants",
  },
] as const
export type Objectives = (typeof objectives)[number]['value']

export const primaryTypes = [
  { label: 'Residential', value: 'RESIDENTIAL' },
  { label: 'Commercial', value: 'COMMERCIAL' },
  { label: 'Industrial', value: 'INDUSTRIAL' },
] as const
export type PrimaryTypes = (typeof primaryTypes)[number]['value']

export const primaryUseVelocityTypes = {
  low: [
    'AUTOMOTIVE_SHOWROOM',
    'CAFETERIA',
    'CHURCH_RELIGION',
    'CLASSROOM',
    'LIBRARY',
    'OFFICE',
    'RESIDENTIAL',
  ] as PrimaryUses[],
  high: [
    'AGRICULTURE',
    'AUTOMOTIVE_SERVICE_CENTER',
    'CARDIO_EQUIPMENT',
    'GROUP_EXERCISE_ROOM',
    'GYMNASIUM',
    'HANGAR',
    'MANUFACTURING',
    'PICKING_SORTING',
    'SHIPPING_RECEIVING',
    'STORAGE_RACKING',
    'STRENGTH_TRAINING',
    'WAREHOUSE',
    'OTHER',
  ] as PrimaryUses[],
}
