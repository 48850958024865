import React from 'react'
import { withRouter, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import { getStepNumber } from 'config/onboarding'
import { getTitle } from 'config/titles'
import routes from 'config/routes'

import Onboarding from 'components/Onboarding'
import DistributeObstructionModal from 'components/Modals/DistributeObstructionModal'
import AppContainer from 'components/App/styled/AppContainer'
import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

const OnboardingObstructionsScreen = ({ match, ...props }) => (
  <AppContainer>
    <Helmet>
      <title>{getTitle('obstructions')}</title>
    </Helmet>
    <Onboarding
      match={match}
      {...props}
      step={getStepNumber('Obstructions')}
      message="Add obstructions to your facility."
    />
    <SentryRoute
      path={`${match.url}${routes.modals.distributeObstruction}`}
      render={props => (
        <DistributeObstructionModal parentRoute={match.url} {...props} />
      )}
    />
  </AppContainer>
)

OnboardingObstructionsScreen.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string }),
}

export default withRouter(OnboardingObstructionsScreen)
