import { graphql } from '~/gql'

const batchReassignFacilitiesMutation = graphql(`
  mutation batchReassignFacilities($ids: [ID]!, $author: UserInput) {
    batchReassignFacilities(ids: $ids, author: $author) {
      id
      author {
        id
        email
        name
        phone
      }
    }
  }
`)

export default batchReassignFacilitiesMutation
