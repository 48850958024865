import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Label = styled.label<{ width?: string }>`
  font-family: ${theme('fonts.family.base')};
  font-size: ${theme('fonts.sizes.s')};
  font-weight: ${theme('fonts.weights.bold')};
  width: ${props => props.width || 'auto'};
  min-width: 60px;
  margin-right: ${theme('spacing.s')};
`

export default Label
