import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const TYPES = ['error', 'success', 'warning']

const setBoxShadow = ({ type }) => {
  if (TYPES.includes(type)) {
    return `
      box-shadow: inset 0 0 8px ${props => props.theme.colors.light[type]};
    `
  }

  return null
}

const Container = styled.div`
  align-items: center;
  background-color: ${theme('colors.light.extraLight')};
  display: flex;
  font-size: ${theme('fonts.sizes.s')};
  height: 100%;
  justify-items: center;
  width: 100%;

  ${props => setBoxShadow(props)};
`

export default Container
