import styled, { StyledProps, css } from 'styled-components'

import { theme } from 'lib/styleUtils'

const setTouchStyles = (props: StyledProps<{ isTouchUI?: boolean }>) =>
  props.isTouchUI &&
  css`
    height: 28px;
    width: 28px;
  `

const Input = styled.input<{ isTouchUI?: boolean; hasLabel?: boolean }>`
  ${props =>
    props.hasLabel &&
    css`
      margin-right: ${theme('spacing.xs')};
    `}
  ${setTouchStyles};
`

export default Input
