// Node Modules
import isEqual from 'lodash-es/isEqual'

// Local Imports
import LAYER_KEYS from 'config/layerKeys'
import { getLayerKey } from 'store/layers'

/**
 * Utility functions for airflowUtil
 */
export const evalPointSpacing = 36

export const isExteriorWall = object =>
  getLayerKey(object) === LAYER_KEYS.EXTERIOR_WALLS &&
  object.segments.length > 1

export const isInteriorWall = object =>
  getLayerKey(object) === LAYER_KEYS.INTERIOR_WALLS

export const isValidSegment = segment =>
  !isEqual(segment.startPoint, segment.endPoint)

// cx and cy are point to rotate around (probably center)
// x and y are points to translate
// angle is angle in which to rotate by
export function rotate(cx, cy, x, y, angle) {
  // If there is no angle or it is 0 return the original points
  if (!angle) return [x, y]
  const radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (x - cx) + sin * (y - cy) + cx,
    ny = cos * (y - cy) - sin * (x - cx) + cy
  return [nx, ny]
}

export const nearestValueFromArray = (num, arr) => {
  let curr = arr[0]
  let diff = Math.abs(num - curr)
  for (let val = 1; val < arr.length; val++) {
    const newdiff = Math.abs(num - arr[val])
    if (newdiff < diff) {
      diff = newdiff
      curr = arr[val]
    }
  }
  return curr
}
