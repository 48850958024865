import React from 'react'
import { Helmet } from 'react-helmet'
import Dashboard from 'components/Dashboard'
import ReassignAllFacilities from 'components/ReassignAllFacilities'
import Flex, { FlexItem } from 'components/UIKit/Flex'
import H1 from 'components/UIKit/H1'
import Space from 'components/UIKit/Space'
import UserInterfaceSettings from 'components/UserInterfaceSettings'
import { getTitle } from 'config/titles'

export const SettingsScreen = props => {
  return (
    <>
      <Helmet>{getTitle('Settings')}</Helmet>
      <Dashboard>
        <Space bottom="base">
          <Flex alignItems="center">
            <Flex>
              <FlexItem>
                <H1>Settings</H1>
              </FlexItem>
            </Flex>
          </Flex>
        </Space>
        <Space bottom="base">
          <UserInterfaceSettings />
        </Space>
        <Space bottom="base">
          <ReassignAllFacilities />
        </Space>
      </Dashboard>
    </>
  )
}

export default SettingsScreen
