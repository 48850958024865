import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { useNetwork } from 'networkProvider'
import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

const ConditionalRoute = ({
  component: Component,
  condition,
  redirect,
  view,
  isCallback,
  ...rest
}) => {
  const network = useNetwork()
  return (
    <SentryRoute
      {...rest}
      render={props => {
        if (condition) {
          return <Redirect to={redirect} />
        }
        return (
          <Component
            {...props}
            view={view}
            isCallback={isCallback}
            online={network.online}
          />
        )
      }}
    />
  )
}

ConditionalRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isCallback: PropTypes.bool,
  redirect: PropTypes.string,
  view: PropTypes.string,
}

export default ConditionalRoute
