import type { FormatOptions } from '.'
import { SYSTEMS } from '../constants'
import type { FormattedUnitData, UnformattedUnitData } from '../types/unit'
import { round } from '../utils'

function formatCentimeters(centimeters: number, options: FormatOptions = {}): string {
  const meters = options.roundCentimeters
    ? round(centimeters, options.round) / 100
    : round(centimeters / 100, options.round)
  if (options.both) {
    const cm = options.roundCentimeters
      ? (meters * 100).toFixed(options.round === true ? 0 : options.round)
      : meters * 100
    return `${cm}cm (${meters}m)`
  } else {
    return `${meters}m`
  }
}

function formatInches(inches: number, options: FormatOptions = {}): string {
  if (options.both) {
    const power = options.power || options.round
    const feet = round(inches / 12, power)
    return `${inches}" (${feet}ft)`
  } else if (options.feetOnly) {
    const feet = round(inches / 12, options.round)
    return `${feet}'`
  } else {
    // We need to round the inches first, otherwise we could get into a situation
    // where inches could get rounded up to 12, but the feet wouldn't increase
    const allInches = round(inches, options.round)
    const feet = Math.floor(allInches / 12)
    const ins = round(allInches % 12, options.round)
    return `${feet}' ${ins}"`
  }
}

export function formatDistance({ value, system }: Omit<UnformattedUnitData, 'type'>, options: FormatOptions): string|null {
  if (value === null) {
    return options.nullValue ?? null
  }
  switch (system) {
    case SYSTEMS.IMPERIAL:
      return formatInches(value, options)
    case SYSTEMS.METRIC:
      return formatCentimeters(value, options)
    default:
      return round(value, options.round).toString()
  }
}

function unformatCentimeters(data: string, options: FormatOptions = {}): number|null {
  const regex = new RegExp(/(\d*\.?\d*\s*)?m?\D*(\d*\.?\d*\s*)?[cm]?/)
  const trimmedString = data.trim()
  const matches = trimmedString.match(regex)! as Partial<RegExpMatchArray>

  // check to see if we are only in inches
  // and swap to ensure right conversion
  if (!matches[2] && matches[0]!.includes('cm')) {
    matches[2] = matches[1]
    matches[1] = undefined
  }

  const meters = matches[1] ? Number(matches[1]) : 0
  const centimeters = matches[2] ? Number(matches[2]) : 0

  if (matches[1] === undefined && matches[2] === undefined) {
    return null
  }

  return round(meters * 100 + centimeters, options.round)
}

function unformatInches(data: string, options: FormatOptions = {}): number|null {
  const regex = new RegExp(/(\d*\.?\d*\s*)?'?\D*(\d*\.?\d*\s*)?"?/)
  const trimmedString = data.trim()
  const matches = trimmedString.match(regex)! as Partial<RegExpMatchArray>

  // check to see if we are only in inches
  // and swap to ensure right conversion
  if (!matches[2] && matches[0]!.includes('"')) {
    matches[2] = matches[1]
    matches[1] = undefined
  }

  const feet = matches[1] ? Number(matches[1]) : 0
  const inches = matches[2] ? Number(matches[2]) : 0

  if (matches[1] === undefined && matches[2] === undefined) {
    return null
  }

  return round(feet * 12 + inches, options.round)
}

export function unformatDistance({ value, system }: Omit<FormattedUnitData, 'type'>, options: FormatOptions = {}): number|null {
  switch (system) {
    case SYSTEMS.IMPERIAL:
      return unformatInches(value, options)
    case SYSTEMS.METRIC:
      return unformatCentimeters(value, options)
    default:
      return round(Number(value), options.round)
  }
}
