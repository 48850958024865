import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Controls = styled.div`
  border-right: 1px solid ${theme('colors.light.subdued')};
  flex-grow: ${props => props.flexGrow ?? 0};
  padding: ${theme('spacing.base')} ${theme('spacing.l')};
  min-width: ${props => props.minWidth ?? 'initial'};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default Controls
