import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { theme } from 'lib/styleUtils'

const StyledLink = styled(Link)<{ noBorder?: boolean }>`
  color: ${theme('colors.light.fg')};
  cursor: pointer;
  text-decoration: none;

  ${props =>
    !props.noBorder &&
    `border-bottom: 1px solid ${props.theme.colors.light.fg};`};

  &:hover {
    border-bottom-color: transparent;
  }
`

export default StyledLink
