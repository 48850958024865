import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import { getTitle } from 'config/titles'

import WebcamCapture from 'components/WebcamCapture'

import Button from 'components/WebcamCapture/styled/Button'

import Icon from 'components/UIKit/Icon'
import WebcamModal from './styled/WebcamModal'

const WebcamCaptureModal = ({ parentRoute, history, match, props }) => {
  useEffect(() => {
    const handleKeydown = event => {
      if (event.which === 27) {
        handleClose(event)
      }
    }
    document.addEventListener('keydown', handleKeydown)
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  })

  const handleClose = () => {
    // Could be 'webcam-capture/metadata' or 'webcam-capture/background'
    history.push(match.url.replace(/webcam-capture\/[a-z]+/g, ''))
  }

  return (
    <>
      <Helmet>
        <title>{getTitle('webcamCapture')}</title>
      </Helmet>
      <WebcamModal>
        <WebcamCapture />
        <Button onClick={handleClose} top>
          <Icon name="cross" size="24" />
        </Button>
      </WebcamModal>
    </>
  )
}

WebcamCaptureModal.propTypes = {
  parentRoute: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  props: PropTypes.object,
}

export default WebcamCaptureModal
