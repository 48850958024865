import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import Button from './styled/Button'
import Input from './styled/Input'

export function SearchInput({ value, onClear, onChange, ...props }) {
  const [internalValue, setInternalValue] = useState(value || '')
  const dirty = internalValue !== ''

  function handleChange(event) {
    setInternalValue(event.target.value)
    onChange && onChange(event)
  }

  function handleClear(event) {
    event.preventDefault()
    setInternalValue('')
    // TODO: Make sure to re-focus input!
    onClear && onClear(event)
  }

  return (
    <Input
      onChange={handleChange}
      value={internalValue}
      overrideValue={internalValue}
      iconLeft={<Icon name="search" size="14" color="subdued" />}
      iconRight={
        dirty ? (
          <Button onClick={handleClear} noBorder>
            <Icon name="cross" size="12" />
          </Button>
        ) : null
      }
      {...props}
    />
  )
}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  value: PropTypes.string,
}

export default /** @type any */(SearchInput)
