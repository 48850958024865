import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Item = styled.li`
  flex: 0 0 auto;

  &:not(:last-child) {
    margin-right: ${theme('spacing.base')};
  }
`

export default Item
