import styled from 'styled-components'

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

export default InputContainer
