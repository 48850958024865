import React, { PureComponent, forwardRef } from 'react'
import pick from 'lodash-es/pick'
import RequiredPermission from './RequiredPermission'

export default function hasPermission(props = {}) {
  const allowedProps = [
    'name',
    'query',
    'LoadingComponent',
    'ErrorComponent',
    'FallbackComponent',
  ]
  return function (Child) {
    class PermissionsWrappedChild extends PureComponent {
      render() {
        if (props.withPermissions) {
          return (
            <RequiredPermission {...pick(props, allowedProps)}>
              {providedProps => (
                <Child {...this.props} permissions={providedProps} />
              )}
            </RequiredPermission>
          )
        }
        return (
          <RequiredPermission {...pick(props, allowedProps)}>
            <Child {...this.props} />
          </RequiredPermission>
        )
      }
    }
    const forwarder = (props, ref) => (
      <PermissionsWrappedChild {...props} ref={ref} />
    )
    forwarder.displayName = `permissions${Child.displayName || Child.name}`

    return forwardRef(forwarder)
  }
}
