import React from 'react'
import styled from 'styled-components'

import Button from 'components/UIKit/Button'

const StyledButton = styled(Button)`
  ${props => props.selectedOpportunity && `width: 95%;`};
`.withComponent(({ selectedOpportunity, ...rest }) => <Button {...rest} />)

export default StyledButton
