import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Icon from 'components/UIKit/Icon'

const StyledIcon = styled(Icon)`
  margin-right: ${theme('spacing.xs')};
`

export default StyledIcon
