import { useRouteMatch } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_VERSION_CFD_RESULTS_WITH_FACILITY } from 'client/queries'
import routes from 'config/routes'
import { useMemo } from 'react'
import {
  CFD_FILE_PROPS_TYPE,
  CFD_STATUS,
  COMPOSITE_SIMULATION_TYPES,
  HEIGHTS,
  HEIGHT_VALUES,
  SIMULATION_TYPES,
} from 'config/cfd'
import { getFacilityDefaultDetails } from 'hooks/useFacilityDefaults'

const getCoolingReducerInitialValue = () => ({
  velocity: {
    [HEIGHTS.standing]: [],
    [HEIGHTS.seated]: [],
  },
  temperature: {
    [HEIGHTS.standing]: [],
    [HEIGHTS.seated]: [],
  },
})

const getDestratReducerInitialValue = () => ({
  destrat: '',
  velocity: {
    [HEIGHTS.standing]: [],
    [HEIGHTS.seated]: [],
  },
})

const getRadiantHeatReducerInitialValue = () => ({
  intensity: '',
})

const getUnitHeatReducerInitialValue = () => ({
  temperatureBefore: {
    [HEIGHTS.standing]: [],
    [HEIGHTS.seated]: [],
  },
  temperatureAfter: {
    [HEIGHTS.standing]: [],
    [HEIGHTS.seated]: [],
  },
  velocityBefore: {
    [HEIGHTS.standing]: [],
    [HEIGHTS.seated]: [],
  },
  velocityAfter: {
    [HEIGHTS.standing]: [],
    [HEIGHTS.seated]: [],
  },
})

const VTK_URL_REDUCERS = {
  [SIMULATION_TYPES.cooling]: [
    (accumulator, { url, id, fileProps, fileExtension }) => {
      const isRelevantVtk = fileExtension === 'vtk' && !id.includes('UmBySec') && !id.includes('U_')
      if (isRelevantVtk && fileProps.type === CFD_FILE_PROPS_TYPE.overhead) {
        const vtkType = id.includes('UftByMin') ? 'velocity' : 'temperature'
        if (HEIGHT_VALUES.standing.includes(fileProps.level)) {
          accumulator[vtkType][HEIGHTS.standing].push(url)
        }
        if (HEIGHT_VALUES.seated.includes(fileProps.level)) {
          accumulator[vtkType][HEIGHTS.seated].push(url)
        }
      }
      return accumulator
    },
    getCoolingReducerInitialValue,
  ],
  [SIMULATION_TYPES.destrat]: [
    (accumulator, { url, fileProps, fileExtension, id }) => {
      const isDestratFile = fileProps.type === CFD_FILE_PROPS_TYPE.destrat
      const isVelocityFile = fileProps.type === CFD_FILE_PROPS_TYPE.overhead
      const isRelevantVtk = fileExtension === 'vtk' && !id.includes('UmBySec') && !id.includes('U_')
      if (isRelevantVtk && (isDestratFile || isVelocityFile)) {
        if (isDestratFile) accumulator.destrat = url
        else if (isVelocityFile) {
          if (HEIGHT_VALUES.standing.includes(fileProps.level)) {
            accumulator.velocity[HEIGHTS.standing].push(url)
          }
          if (HEIGHT_VALUES.seated.includes(fileProps.level)) {
            accumulator.velocity[HEIGHTS.seated].push(url)
          }
        }
      }
      return accumulator
    },
    getDestratReducerInitialValue,
  ],
  [SIMULATION_TYPES.radiantHeat]: [
    (accumulator, { url, id, fileExtension }) => {
      const isIntensityFile = fileExtension === 'vtk' && id.includes('intensity_zNormal')
      if (isIntensityFile) accumulator.intensity = url
      return accumulator
    },
    getRadiantHeatReducerInitialValue,
  ],
  [SIMULATION_TYPES.unitHeating]: [
    (accumulator, { url, id, fileProps, fileExtension }) => {
      const isZeroTurnoverImage = /_z[\d]+inch\.vtk/.test(id)
      const isFullTurnoverImage = /_z[\d]+inch-100turnover\.vtk/.test(id)
      const isRelevantVtk = fileExtension === 'vtk' && !id.includes('UmBySec')
      if (isRelevantVtk && (isZeroTurnoverImage || isFullTurnoverImage)) {
        const vtkType = id.includes('UftByMin') ? 'velocity' : 'temperature'
        const vtkGroup = isZeroTurnoverImage ? 'Before' : 'After'
        const key = vtkType + vtkGroup
        if (HEIGHT_VALUES.standing.includes(fileProps.level)) {
          accumulator[key][HEIGHTS.standing].push(url)
        }
        if (HEIGHT_VALUES.seated.includes(fileProps.level)) {
          accumulator[key][HEIGHTS.seated].push(url)
        }
      }
      return accumulator
    },
    getUnitHeatReducerInitialValue,
  ],
}
/**
 * @param {vtkResult[]} vtkFileResults
 */
const getHeatingResultsComposite = vtkFileResults => {
  const heatingResults = vtkFileResults?.filter(
    ({ type }) => type === SIMULATION_TYPES.radiantHeat || type === SIMULATION_TYPES.unitHeating
  )
  if (heatingResults?.length !== 2) return []
  const status =
    heatingResults.find(({ status }) => status !== CFD_STATUS.COMPLETE)?.status ??
    CFD_STATUS.COMPLETE
  const vtkUrlGroups = Object.fromEntries(
    heatingResults.map(({ type, vtkUrlGroups }) => [type, vtkUrlGroups])
  )
  const heatingResultsComposite = {
    type: COMPOSITE_SIMULATION_TYPES.heatingComposite,
    status,
    vtkUrlGroups,
  }
  return [heatingResultsComposite]
}

/**
 * Returns grouped vtk files to be used for extrapolating metrics data. Note: although metric simulations
 * return both UmBySec and UftByMin files, we are only interested in the imperial files. Performance metrics
 * are converted for metric facilities *after* the calculations have been run.
 * @typedef {object} standingHeightUrlGroup
 * @prop {string[]} standing - 4, 43, and 67 inches
 * @prop {string[]} seated - 4, 24, and 43 inches
 *
 * @typedef {object} vtkUrlGroups
 * @prop {string} destrat
 * @prop {string} intensity
 * @prop {standingHeightUrlGroup} velocity
 * @prop {standingHeightUrlGroup} temperature
 * @prop {standingHeightUrlGroup} temperatureBefore
 * @prop {standingHeightUrlGroup} temperatureAfter
 * @prop {standingHeightUrlGroup} velocityBefore
 * @prop {standingHeightUrlGroup} velocityAfter
 *
 * @typedef {object} vtkResult
 * @prop {string} type - The simulation: cooling, destrat, radiantHeat or unitHeating
 * @prop {string} status - Indicates the completion status of a CFD simulation
 * @prop {vtkUrlGroups} vtkUrlGroups
 *
 * @typedef {object} data
 * @prop {facilityDetailDefaults} facility
 * @prop {vtkResult[]} vtkResults
 *
 * @typedef {object} FacilityVtkResults
 * @prop {boolean} loading
 * @prop {Error} error
 * @prop {data} data
 *
 * @returns {FacilityVtkResults}
 */
export const useFacilityVtkResults = () => {
  const { versionId } = useRouteMatch(routes.facility.full)?.params ?? {}
  const { data, loading, error } = useQuery(GET_VERSION_CFD_RESULTS_WITH_FACILITY, {
    variables: { versionId },
    skip: !versionId,
  })

  return useMemo(() => {
    const { facility } = data?.Version?.floor ?? { facility: undefined }
    const facilityDefaults = getFacilityDefaultDetails(facility)
    const vtkResults = data?.Version?.cfd
      .filter(result => !!result)
      .map(({ internalType, URLs, status }) => {
        const [reducer, getInitialValue] = VTK_URL_REDUCERS[internalType] ?? [
          () => null,
          () => null,
        ]
        return {
          type: internalType,
          status,
          vtkUrlGroups: URLs.reduce(reducer, getInitialValue()),
        }
      })

    if (!vtkResults || !facilityDefaults) return { loading, error, data: undefined }

    return {
      loading,
      error,
      data: {
        facility: facilityDefaults,
        vtkResults: [...vtkResults, ...getHeatingResultsComposite(vtkResults)],
      },
    }
  }, [data, loading, error])
}
