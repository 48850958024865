import { graphql } from '~/gql'

const DELETE_FACILITY_TEMPLATE_MUTATION = graphql(`
  mutation DeleteFacilityTemplate($id: ID!) {
    deleteFacilityTemplate(id: $id) {
      id
    }
  }
`)

export default DELETE_FACILITY_TEMPLATE_MUTATION
