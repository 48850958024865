import React from 'react'
import PropTypes from 'prop-types'
import ApolloClient from 'client'
import { HEATER_VARIATION_FRAGMENT } from 'client/fragments'

import Space from '../../UIKit/Space'

import VoltageSelect from 'components/Panels/SelectedProductPanel/VoltageSelect'
import MountingStructuresSelect from 'components/Panels/SelectedProductPanel/MountingStructuresSelect'
import DiameterSelect from 'components/Panels/SelectedProductPanel/DiameterSelect'
import Button from 'components/UIKit/Button'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Field from 'components/UIKit/TextField/styled/Field'
import Select from 'components/UIKit/Select'

import get from 'lodash-es/get'

import { LETTERS } from './constants'

const HeaterProductsTab = ({
  productsArray,
  onUpdateProducts,
  handleChangeVariation,
}) => {
  return (
    <>
      <Space>
        <Grid>
          <GridItem size="1of6">
            <div>
              <b>Detailed Label</b>
              <Button
                noBorder
                icon="refresh"
                responsive
                onClick={e => {
                  const newProducts = productsArray.map((p, j) => {
                    let newLabel = ''
                    const labelLength = Math.floor(j / 26) + 1
                    for (let i = 0; i < labelLength; i++) {
                      newLabel += LETTERS[j % LETTERS.length]
                    }
                    return { ...p, detailedLabel: newLabel }
                  })
                  onUpdateProducts(newProducts)
                }}
              />
            </div>
          </GridItem>
          <GridItem size="1of6">
            <b>Model</b>
          </GridItem>
          <GridItem size="1of6">
            <b>Size</b>
          </GridItem>
          <GridItem size="1of6">
            <Grid>
              <b>Voltage</b>
              <Button
                noBorder
                icon="duplicate"
                responsive
                onClick={e => {
                  if (get(productsArray[0], 'hasUnknownVoltage')) {
                    const newProducts = productsArray.map(p => {
                      return {
                        ...p,
                        hasUnknownVoltage: true,
                      }
                    })
                    onUpdateProducts(newProducts)
                  } else {
                    const variation = ApolloClient.readFragment({
                      id: `ProductVariation:${productsArray[0].variationId}`,
                      fragment: HEATER_VARIATION_FRAGMENT,
                    })
                    const voltages = variation.voltages || []
                    const voltage = voltages.find(
                      v => v.id === productsArray[0].voltageId
                    )
                    const newProducts = productsArray.map(p => {
                      const variation = ApolloClient.readFragment({
                        id: `ProductVariation:${p.variationId}`,
                        fragment: HEATER_VARIATION_FRAGMENT,
                      })
                      const newVoltage = variation.voltages.find(
                        v => v.inputPower === voltage.inputPower
                      )
                      return {
                        ...p,
                        voltageId: newVoltage ? newVoltage.id : p.voltageId,
                        hasUnknownVoltage: newVoltage
                          ? false
                          : p.hasUnknownVoltage,
                      }
                    })
                    onUpdateProducts(newProducts)
                  }
                }}
              />
            </Grid>
          </GridItem>
          <GridItem size="1of6">
            <div>
              <b>Fuel Type</b>
              <Button
                noBorder
                icon="duplicate"
                responsive
                onClick={e => {
                  const newFuelType = get(productsArray[0], 'fuelType')
                  const newProducts = productsArray.map(p => {
                    return {
                      ...p,
                      fuelType: newFuelType,
                    }
                  })
                  onUpdateProducts(newProducts)
                }}
              />
            </div>
          </GridItem>
          <GridItem size="1of6">
            <div>
              <b>Structure</b>
              <Button
                noBorder
                icon="duplicate"
                responsive
                onClick={e => {
                  const {
                    variationId,
                    mountingOptionAdderId,
                  } = productsArray[0]

                  const productVariation = ApolloClient.readFragment({
                    id: `ProductVariation:${variationId}`,
                    fragment: HEATER_VARIATION_FRAGMENT,
                  })

                  const mountingOptionAdders =
                    get(productVariation, 'mountingOptionAdders') || []
                  const currentMountingOption = mountingOptionAdders.find(
                    item => item.id === mountingOptionAdderId
                  )

                  const newProducts = productsArray.map(p => {
                    const productVariation = ApolloClient.readFragment({
                      id: `ProductVariation:${p.variationId}`,
                      fragment: HEATER_VARIATION_FRAGMENT,
                    })

                    const childMountingOptionAdders =
                      get(productVariation, 'mountingOptionAdders') || []
                    const childMountingOption = childMountingOptionAdders.find(
                      item => item.id === p.mountingOptionAdderId
                    )

                    if (childMountingOption !== currentMountingOption) {
                      if (
                        currentMountingOption === 'unknown' ||
                        !currentMountingOption
                      ) {
                        return {
                          ...p,
                          mountingOptionAdderId: currentMountingOption,
                          mountingOptionAdder: currentMountingOption,
                        }
                      }
                      const match = childMountingOptionAdders.find(
                        item => item.id === mountingOptionAdderId
                      )

                      return {
                        ...p,
                        mountingOptionAdderId: get(match, 'id', 'unknown'),
                        mountingOptionAdder: get(
                          match,
                          'mountingType',
                          'unknown'
                        ),
                      }
                    }

                    return {
                      ...p,
                    }
                  })
                  onUpdateProducts(newProducts)
                }}
              />
            </div>
          </GridItem>
        </Grid>
      </Space>
      {productsArray.map((p, index) => {
        const voltageValue = p.hasUnknownVoltage ? 'unknown' : p.voltageId || ''

        const isHeater = p.category === 'HEAT'

        return (
          <Space bottom="base" key={index}>
            <Grid>
              <GridItem size="1of6">
                <Field
                  width="80%"
                  value={p.detailedLabel || ''}
                  onChange={e => {
                    const value = e.target.value
                    const newProduct = { ...p, detailedLabel: value }
                    onUpdateProducts([newProduct])
                  }}
                />
              </GridItem>
              <GridItem size="1of6">
                <b>{p.model}</b>
              </GridItem>
              <GridItem size="1of6">
                <DiameterSelect
                  name="product-size"
                  placeholder=""
                  productId={p.productId}
                  onChange={event => {
                    handleChangeVariation({
                      id: event.target.value,
                      product: p,
                      onUpdateProducts,
                      isHeater,
                    })
                  }}
                  value={p.variationId || ''}
                  isHeater
                />
              </GridItem>
              <GridItem size="1of6">
                <VoltageSelect
                  name="product-voltage"
                  placeholder=""
                  productVariationId={p.variationId}
                  value={voltageValue}
                  onChange={event => {
                    const value = event.target.value
                    if (value === 'unknown') {
                      onUpdateProducts([{ ...p, hasUnknownVoltage: true }])
                    } else {
                      onUpdateProducts([
                        {
                          ...p,
                          voltageId: event.target.value,
                          hasUnknownVoltage: false,
                        },
                      ])
                    }
                  }}
                  isHeater={p.category === 'HEAT'}
                />
              </GridItem>
              <GridItem size="1of6">
                <Select
                  inline
                  placeholder=""
                  onChange={e => {
                    const value = e.target.value
                    onUpdateProducts([{ ...p, fuelType: value }])
                  }}
                  value={p.fuelType}
                  options={[
                    {
                      value: 'Natural Gas',
                      label: 'Natural Gas',
                    },
                    {
                      value: 'LPG Propane',
                      label: 'LPG Propane',
                    },
                  ]}
                />
              </GridItem>
              <GridItem size="1of6">
                <MountingStructuresSelect
                  placeholder=""
                  productVariationId={p.variationId}
                  value={p.mountingOptionAdderId}
                  onChange={e => {
                    const value = e.target.value
                    onUpdateProducts([{ ...p, mountingOptionAdderId: value }])
                  }}
                  isHeater={isHeater}
                />
              </GridItem>
            </Grid>
          </Space>
        )
      })}
    </>
  )
}

HeaterProductsTab.propTypes = {
  productsArray: PropTypes.array,
  onUpdateProducts: PropTypes.func,
  handleMountingOptionChange: PropTypes.func,
  handleChangeVariation: PropTypes.func,
}

export default HeaterProductsTab
