import React, { Component } from 'react'
import { array, func, string, object, bool } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import isEqual from 'lodash-es/isEqual'
import isNil from 'lodash-es/isNil'
import get from 'lodash-es/get'
import omit from 'lodash-es/omit'
import omitBy from 'lodash-es/omitBy'
import * as THREE from 'three'

import { updateObjects } from 'store/objects'
import { addLoadingDockObstruction } from 'store/loadingDock'
import { Distance } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'
import { withUnits } from 'store/units/decorators'
import { SELECTED_OBSTRUCTION_PANEL } from 'store/panel/types'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { isTouchUI } from 'store/userInterface/selectors'

import CLASS_NAMES from 'config/objectClassNames'
import { roundTwoDecimals } from 'lib/thermalComfortTool/util'
import theme from 'config/theme'

import DimensionInput from 'components/UIKit/DimensionInput'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Select from 'components/UIKit/Select'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'
import Image from 'components/UIKit/Image'

import MetadataSection from 'components/MetadataSection'
import SaveConfiguration from 'components/SaveConfiguration'

import store from 'store'
import LAYER_KEYS from 'config/layerKeys'

import ObstructionUtil from 'components/DrawingCanvas/lib/obstructionUtil'
import Units from 'components/DrawingCanvas/lib/units'
import Checkbox from 'components/UIKit/Checkbox'
import { ColorPicker } from 'react-aria-components'
import { ChooserWell } from '~/ui/Color'
import { Label } from '~/ui/Field'

const startingPositions = [
  {
    label: 'From Ceiling',
    value: 'ceiling',
  },
  {
    label: 'From Floor',
    value: 'floor',
  },
]

class SelectedObstructionPanel extends Component {
  static propTypes = {
    selectedObjects: array,
    selectedObject: object,
    onUpdateObstructions: func,
    onAddLoadingDockObstruction: func,
    alignment: string,
    distanceUnits: string,
    obstructions: object,
    isTouchUI: bool,
  }

  static defaultProps = {
    alignment: 'right',
  }

  getValue(distance) {
    let value = get(distance, 'value', 0.1)
    if (value < 0.1) return

    value = distance.imperial() || undefined

    return value
  }

  handleInputChange = (key, value) => {
    if (!key || !value) return

    const { selectedObjects, onUpdateObstructions } = this.props

    onUpdateObstructions(
      selectedObjects.map(obj => ({
        ...obj,
        [key]: value,
      }))
    )
  }

  handleIgnoreErrorChange = (key, value) => {
    const { selectedObjects, onUpdateObstructions } = this.props

    onUpdateObstructions(
      selectedObjects.map(obj => ({
        ...obj,
        [key]: value,
      }))
    )
  }

  handleOffsetChange = value => {
    const newValue = value < 0 ? 0 : value
    const { selectedObjects, onUpdateObstructions } = this.props

    onUpdateObstructions(
      selectedObjects.map(obj => ({
        ...obj,
        offset: newValue,
      }))
    )
  }

  handleSaveConfig = onSuccess => {
    const { selectedObjects, onAddLoadingDockObstruction } = this.props
    if (selectedObjects.length && selectedObjects.length === 1) {
      onAddLoadingDockObstruction(selectedObjects[0])
      if (onSuccess) onSuccess()
    }
  }

  getUpdatedPosition(xOffset, yOffset, pos) {
    const position = new THREE.Vector3()
      .copy({
        x: Units.inchesToNative(pos.x),
        y: Units.inchesToNative(pos.y),
        z: Units.inchesToNative(pos.z),
      })
      .add(new THREE.Vector3(xOffset, yOffset, 0))

    return {
      x: Units.nativeToInches(position.x),
      y: Units.nativeToInches(position.y),
      z: Units.nativeToInches(position.z),
    }
  }

  handleDimensionsChange = (newWidth, newLength) => {
    if (!newWidth && !newLength) return

    const { selectedObjects, onUpdateObstructions } = this.props

    const updatedObstructions = selectedObjects.map(obj => {
      const { position, positions, rotation } = obj
      // Grab the width and length from the original obstruction
      let { xDiff, xMin, yDiff, yMin } = ObstructionUtil.getPositionDiffs(
        positions
      )
      // Rounding the difference to keep the math cleaner
      xDiff = Math.round(xDiff * 100) / 100
      yDiff = Math.round(yDiff * 100) / 100

      // Calculate the changes in the length and width input for the obstruction.
      const deltaWidth = Units.inchesToNative((newWidth || xDiff) - xDiff)
      const deltaLength = Units.inchesToNative((newLength || yDiff) - yDiff)

      // Calculate the translation vector to be used for the object.
      // First grab the rotation angle and convert it to radians
      const angle = (((rotation && rotation.z) || 0) * Math.PI) / 180

      // Vector is [deltaX, deltaY]* rotation matrix to orient in the direction of the obstruction
      // Since the object grows in 2 directions, we only want to translate points by 1/2 the unit vector
      // If deltaLength is negative, wen want vX to move to the left at 90 rotation
      // Therefore we add deltaLength versus subtracting
      const vX =
        (deltaWidth * Math.abs(Math.cos(angle)) +
          deltaLength * Math.abs(Math.sin(angle))) /
        2
      const vY =
        -(
          deltaWidth * Math.abs(Math.sin(angle)) +
          deltaLength * Math.abs(Math.cos(angle))
        ) / 2
      // Adjust the center based on the new vector
      // The object moves down if y is > 0, so we want to subtract the y
      const newPosition = this.getUpdatedPosition(vX, vY, position)

      // Get the center values of the shifts;
      const centerX = xMin + xDiff / 2
      const centerY = yMin + yDiff / 2
      // Move each position based on the translation
      const newPositions = positions.map(pos => {
        const { x, y, z } = pos
        // Get the percentage of width and height the point was away from the center
        const xPercent = (x - centerX) / xDiff
        const yPercent = (y - centerY) / yDiff
        const newPos = {
          x: centerX + xPercent * (newWidth || xDiff),
          y: centerY + yPercent * (newLength || yDiff),
          z,
        }
        // Translate the new calculated points and return
        return this.getUpdatedPosition(vX, vY, newPos)
      })
      // Return the updated Obstruction
      return {
        ...obj,
        position: newPosition,
        positions: newPositions,
      }
    })
    // Update the Obstruction
    onUpdateObstructions(updatedObstructions)
  }

  getConfigFromObject(obj) {
    if (!obj) return
    const model = {
      ...omit(obj, [
        'id',
        'position',
        'positions',
        'layerKey',
        'metadata',
        'draggable',
      ]),
    }

    model.positions = obj.positions.map(pos => {
      return new THREE.Vector3()
        .copy(obj.position)
        .sub(pos)
        .ceil()
    })

    return omitBy(model, isNil)
  }

  isDupeConfig() {
    const { selectedObject, obstructions } = this.props
    if (!obstructions) return false
    const selectedObstructionConfig = this.getConfigFromObject(selectedObject)
    let isDupe = false
    Object.keys(obstructions).forEach((key, index) => {
      const config = this.getConfigFromObject(obstructions[key])
      if (isEqual(config, selectedObstructionConfig)) isDupe = true
    })

    return isDupe
  }

  getDistance(value) {
    return new Distance({
      value,
      system: SYSTEMS.IMPERIAL,
    })
  }

  renderContent() {
    const {
      selectedObjects,
      onUpdateObstructions,
      distanceUnits,
      selectedObject,
      isTouchUI,
    } = this.props

    const getObjectValue = (obj, value, key, defaultValue) => {
      let returnValue = value
      if (value === undefined) {
        returnValue = get(obj, key, defaultValue)
      } else if (value !== get(obj, key, defaultValue)) {
        returnValue = false
      }

      return returnValue
    }

    let height
    let width
    let offset
    let length
    let obstructionType
    let startLocation
    let rotation = { x: 0, y: 0, z: 0 }
    let resizable

    const ids = []

    selectedObjects.forEach(obj => {
      ids.push(obj.id)

      height = getObjectValue(obj, height, 'height', 48)
      const {
        xDiff: objWidth,
        yDiff: objLength,
      } = ObstructionUtil.getPositionDiffs(obj.positions)

      if (width === undefined) {
        width = Math.abs(objWidth)
      } else if (
        roundTwoDecimals(width) !== roundTwoDecimals(Math.abs(objWidth))
      ) {
        width = false
      }

      if (length === undefined) {
        length = Math.abs(objLength)
      } else if (
        roundTwoDecimals(length) !== roundTwoDecimals(Math.abs(objLength))
      ) {
        length = false
      }

      if (offset === undefined) {
        offset = get(obj, 'offset', 0)
      } else if (
        roundTwoDecimals(offset) !==
        roundTwoDecimals(Math.abs(get(obj, 'offset', 0)))
      ) {
        offset = false
      }

      rotation = get(obj, 'rotation', { x: 0, y: 0, z: 0 })
      resizable = getObjectValue(obj, resizable, 'resizable')
      obstructionType = getObjectValue(obj, obstructionType, 'obstructionType')
      startLocation = getObjectValue(obj, startLocation, 'startLocation')
    })
    const key = ids.join('-')
    const inputWidth = isTouchUI ? '140px' : '70px'
    const defaultColor = theme.colors.three.objects.obstruction.deselected
    const isLocked = store.getState().layers.layers[LAYER_KEYS.OBSTRUCTIONS]
      .locked
    const showObstructionInfo = obstructionType
      ? obstructionType.match(/[A-Z][a-z]+|[0-9]+/g)
      : obstructionType

    return (
      <>
        <SaveConfiguration
          selectedObjectId={selectedObject.id}
          isDupeConfig={this.isDupeConfig()}
          onConfigurationSave={onSuccess => this.handleSaveConfig(onSuccess)}
        />
        <PanelSection>
          {showObstructionInfo && (
            <Space bottom="base">
              <h3>{showObstructionInfo.join(' ')}</h3>
            </Space>
          )}
          {showObstructionInfo && (
            <Space bottom="base">
              <Image
                src={`/obstructions/${obstructionType}.png`}
                alt={obstructionType}
              />
            </Space>
          )}
          <Space bottom="base">
            <Select
              key={`${key}-start-location`}
              inline
              labelWidth="70px"
              name="start"
              label="Starting Location"
              size="150px"
              placeholder="Select..."
              options={startingPositions}
              disablePlaceholder={true}
              disabled={isLocked ? true : false}
              value={startLocation || ''}
              onChange={event => {
                this.handleInputChange('startLocation', event.target.value)
              }}
            />
          </Space>
          <Space bottom="base">
            <TextField
              inline
              label="Rotation"
              labelWidth="70px"
              value={rotation.z ? Math.round(rotation.z) : '0'}
              overrideValue={Math.round(rotation.z)}
              type="number"
              disabled={isLocked ? true : false}
              onChange={event => {
                const value = event.target.value || 0
                const newRotation = {
                  x: 0,
                  y: 0,
                  z: Math.round(parseFloat(value)),
                }
                this.handleInputChange('rotation', newRotation)
              }}
            />
          </Space>
          <Space bottom="base">
            <DimensionInput
              label="Offset"
              labelWidth="70px"
              width={inputWidth}
              tooltip="Creates a floating object by defining offset from the starting location"
              name="offset"
              distance={this.getDistance(offset)}
              tabIndex={1}
              disabled={isLocked ? true : false}
              units={distanceUnits}
              onChange={({ distance }) => {
                this.handleOffsetChange(distance.imperial() || 0)
              }}
            />
          </Space>
          <>
            <Space bottom="base">
              <DimensionInput
                label="Height"
                labelWidth="70px"
                width={inputWidth}
                name="height"
                disabled={
                  (!resizable && obstructionType !== 'basic') || isLocked
                    ? true
                    : false
                }
                distance={this.getDistance(height)}
                tabIndex={1}
                units={distanceUnits}
                onChange={({ distance }) => {
                  this.handleInputChange('height', this.getValue(distance))
                }}
              />
            </Space>
            <Space bottom="base">
              <DimensionInput
                label="Width"
                labelWidth="70px"
                width={inputWidth}
                name="width"
                disabled={
                  (!resizable && obstructionType !== 'basic') || isLocked
                    ? true
                    : false
                }
                distance={this.getDistance(width)}
                tabIndex={2}
                units={distanceUnits}
                onChange={({ distance }) => {
                  this.handleDimensionsChange(this.getValue(distance), 0)
                }}
              />
            </Space>
            <Space bottom="base">
              <DimensionInput
                label="Length"
                labelWidth="70px"
                width={inputWidth}
                name="Length"
                disabled={
                  (!resizable && obstructionType !== 'basic') || isLocked
                    ? true
                    : false
                }
                distance={this.getDistance(length)}
                tabIndex={3}
                units={distanceUnits}
                onChange={({ distance }) => {
                  this.handleDimensionsChange(0, this.getValue(distance))
                }}
              />
            </Space>
            {obstructionType === 'basic' && (
              <Space bottom="base">
                <ColorPicker value={selectedObject.color} onChange={color => this.handleInputChange('color', color.toString("hex"))}>
                  <Label>Color</Label>
                  <ChooserWell swatches={theme.colors.three.objects.obstruction.swatches}/>
                </ColorPicker>
              </Space>
            )}
            <Space>
              <Checkbox
                label="Acknowledge and Ignore Errors"
                checked={selectedObject.ignoreErrors}
                disabled={isLocked}
                onChange={e => {
                  this.handleIgnoreErrorChange(
                    'ignoreErrors',
                    !selectedObject.ignoreErrors
                  )
                }}
              />
            </Space>
          </>
        </PanelSection>
        <MetadataSection
          objects={selectedObjects}
          onBlur={onUpdateObstructions}
          disabled={isLocked ? true : false}
        />
      </>
    )
  }

  render() {
    const { selectedObjects, alignment, isTouchUI } = this.props

    return (
      <Panel
        title="Obstruction"
        alignment={alignment}
        docked
        scrollable
        panelKey={SELECTED_OBSTRUCTION_PANEL}
        hasToolbar={isTouchUI}
      >
        {selectedObjects.length ? this.renderContent() : null}
      </Panel>
    )
  }
}

const mapStateToProps = ({
  objects,
  selectedObjects,
  loadingDock,
  ...store
}) => ({
  isTouchUI: isTouchUI(store),
  selectedObjects: selectedObjects
    .map(obj => get(objects, `present.obstructions.${obj.id}`))
    .filter(obj => obj !== undefined),
  selectedObject: mostRecentSelectedObjectOfClassName(CLASS_NAMES.OBSTRUCTION, {
    selectedObjects,
    objects,
  }),
  obstructions: loadingDock.obstructions,
})

const mapDispatchToProps = dispatch => ({
  onUpdateObstructions(obstructions) {
    dispatch(updateObjects(obstructions))
  },
  onAddLoadingDockObstruction(obstruction) {
    dispatch(addLoadingDockObstruction(obstruction))
  },
})

export default compose(
  withUnits,
  appConnect(mapStateToProps, mapDispatchToProps)
)(SelectedObstructionPanel)
