import { graphql } from '~/gql'

const USER_FRAGMENT = graphql(`
  fragment UserFragment on User {
    id
    name
    email
    phone
    auth0UserId
    preferences
    facilities {
      ...FacilityFragment
    }
    roles {
      name
    }
    features {
      name
    }
    requiresCacheClear
  }
`)

export default USER_FRAGMENT
