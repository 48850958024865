import styled from 'styled-components'

import theme from 'config/theme'

import { FlexItem } from '../../Flex'

const ListItem: any = styled(FlexItem)`
  position: relative;

  &:not(:last-child)::after {
    color: ${theme.colors.light.subdued};
    content: '/';
    position: absolute;
    right: -10px; /* FIXME: Magic number! */
  }
`

export default ListItem
