import styled from 'styled-components'

import { theme } from 'lib/styleUtils'
import Card from 'components/UIKit/Card'

const Dock = styled(Card)`
  background-color: ${theme('colors.light.bg')};
  display: block;
  flex-grow: 1;
  font-size: ${theme('fonts.sizes.s')};
  padding: 0;
`

export default Dock
