import { useEffect, useReducer } from 'react'
import apiBaseUrl from 'config/apiBaseUrl'

const ENDPOINT = '/pdf'

const API_URL = apiBaseUrl + ENDPOINT

const FETCH_INIT = 'FETCH_INIT'
const FETCH_SUCCESS = 'FETCH_SUCCESS'
const FETCH_ERROR = 'FETCH_ERROR'

const initialState = {
  loading: null,
  error: null,
  blob: null,
  iframe: null,
}

function pdfReducer(state, action) {
  switch (action.type) {
    case FETCH_INIT: {
      return {
        ...state,
        loading: true,
      }
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

async function post(data) {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
    const response = await fetch(API_URL, options)
    if (response.status === 400) throw new Error(response.statusText)
    const buffer = await response.arrayBuffer()
    const blob = new Blob([buffer], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob)
    return url
  } catch (error) {
    throw error
  }
}

function usePDF(data = {}, handlePdfDidRender) {
  const [state, dispatch] = useReducer(pdfReducer, initialState)
  useEffect(() => {
    async function fetchPDF() {
      try {
        dispatch({ type: FETCH_INIT })
        const url = await post(data)
        dispatch({ type: FETCH_SUCCESS, payload: { url } })
        handlePdfDidRender()
      } catch (error) {
        dispatch({ type: FETCH_ERROR, payload: error })
      }
    }
    if (state.loading === null) {
      fetchPDF()
    }
  }, [data, handlePdfDidRender, state])

  return state
}

export default usePDF
