import sample from 'lodash-es/sample'

import ShapeTool from './shapeTool'
import ComfortZone from './comfortZone'
import Util from './util'

import store from 'store'
import { clearStatus, setStatus } from 'store/status'
import { addComfortZone } from 'store/objects'

import theme from 'config/theme'
import { onAddComfortZone } from 'config/analytics'
import { trackEvent } from 'lib/analytics'

import * as THREE from 'three'

const MINIMUM_ZONE_SIZE = 2

class ComfortZoneTool extends ShapeTool {
  constructor() {
    super()

    this.name = 'COMFORT_ZONE_TOOL'
    this.createVisual()
  }

  createVisual() {
    this.obj3d = new THREE.Object3D()
    this.color = sample(theme.colors.swatches)
  }

  toolDown(
    mousePos,
    snappedMousePos,
    lastSceneIntersectionPoint,
    objectWithCursor,
    objectWithSnappedCursor,
    isTouch = false
  ) {
    if (isTouch) {
      this.startPos = mousePos
    }
    if (!this.startedShapeCreation) {
      this.startShapeDescription(mousePos)
    }
  }

  updateVisual(startPos, endPos) {
    const startPosition = this.startPos || startPos
    if (this.comfortZone) {
      this.obj3d.remove(this.comfortZone.obj3d)
    }

    // We are no longer drawing so return
    if (this.shapeCancelled) return

    const positions = Util.getBoxPositions(startPosition, endPos)
    const model = ComfortZone.createModel(positions, this.color)
    this.comfortZone = new ComfortZone(model)
    this.obj3d.add(this.comfortZone.obj3d)
  }

  finishShapeDescription(multiSelect) {
    super.finishShapeDescription()

    if (!this.comfortZone) return

    this.obj3d.remove(this.comfortZone.obj3d)

    if (this.shapeCancelled) {
      this.comfortZone = undefined
      return
    }

    const bbox = new THREE.Box3().setFromObject(this.comfortZone.obj3d)
    const xOffset = Math.abs(bbox.max.x - bbox.min.x)
    const yOffset = Math.abs(bbox.max.y - bbox.min.y)

    // Prevent comfort zones being created below the mininum size
    if (xOffset < MINIMUM_ZONE_SIZE || yOffset < MINIMUM_ZONE_SIZE) {
      const error = 'Comfort zone too small to create!'
      store.dispatch(setStatus({ text: error, type: 'error' }))
    } else {
      // Pick new color for next comfort zone
      this.color = sample(theme.colors.swatches)

      store.dispatch(
        addComfortZone({ comfortZone: this.comfortZone.toModel() })
      )
      store.dispatch(clearStatus())
      trackEvent(onAddComfortZone())
    }
  }
}

export default ComfortZoneTool
