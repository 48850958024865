import { PrimaryTypes, PrimaryUses } from "./facility"

type FacilityData = {
  type: PrimaryUses | PrimaryTypes
  activityLevel: number
  clothingType: number
  winterClothingType: number
  elevationHeight: 'Standing' | 'Seated'
  airTemp: number
  winterAirTemp: number
  humidity: number
  indoorWinterHumidity: number
  airConditioned: boolean
  airVelocity: number
  maxAirVelocity: number | null
}

export const facilityData: FacilityData[] = [
  {
    type: 'AGRICULTURE',
    activityLevel: 2.1,
    clothingType: 0.57,
    winterClothingType: 0.7,
    elevationHeight: 'Standing',
    airTemp: 29.44, // 85f
    winterAirTemp: 12.77, // 55f
    humidity: 60,
    indoorWinterHumidity: 40,
    airConditioned: false,
    airVelocity: 999, // max
    maxAirVelocity: null,
  },
  {
    type: 'AUTOMOTIVE_SERVICE_CENTER',
    activityLevel: 2.1,
    clothingType: 0.57,
    winterClothingType: 0.7,
    elevationHeight: 'Standing',
    airTemp: 26.66, // 80f
    winterAirTemp: 12.77, // 55f
    humidity: 60,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'AUTOMOTIVE_SHOWROOM',
    activityLevel: 1.4,
    clothingType: 0.57,
    winterClothingType: 1,
    elevationHeight: 'Standing',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 100,
    maxAirVelocity: 200,
  },
  {
    type: 'CAFETERIA',
    activityLevel: 1.2,
    clothingType: 0.57,
    winterClothingType: 1,
    elevationHeight: 'Seated',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 100,
    maxAirVelocity: 200,
  },
  {
    type: 'CARDIO_EQUIPMENT',
    activityLevel: 3.2,
    clothingType: 0.36,
    winterClothingType: 0.36,
    elevationHeight: 'Standing',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'CHURCH_RELIGION',
    activityLevel: 1.2,
    clothingType: 0.57,
    winterClothingType: 1,
    elevationHeight: 'Seated',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 100,
    maxAirVelocity: 200,
  },
  {
    type: 'CLASSROOM',
    activityLevel: 1.2,
    clothingType: 0.57,
    winterClothingType: 1,
    elevationHeight: 'Seated',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 50,
    maxAirVelocity: 100,
  },
  {
    type: 'COMMERCIAL',
    activityLevel: 1.2,
    clothingType: 0.57,
    winterClothingType: 1,
    elevationHeight: 'Standing',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 100,
    maxAirVelocity: 200,
  },
  {
    type: 'GROUP_EXERCISE_ROOM',
    activityLevel: 3.2,
    clothingType: 0.36,
    winterClothingType: 0.36,
    elevationHeight: 'Standing',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'GYMNASIUM',
    activityLevel: 3.0,
    clothingType: 0.5,
    winterClothingType: 0.5,
    elevationHeight: 'Standing',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'HANGAR',
    activityLevel: 2.1,
    clothingType: 0.57,
    winterClothingType: 0.7,
    elevationHeight: 'Standing',
    airTemp: 29.44, // 85f
    winterAirTemp: 10, // 50f
    humidity: 60,
    indoorWinterHumidity: 40,
    airConditioned: false,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'INDUSTRIAL',
    activityLevel: 1.7,
    clothingType: 0.57,
    winterClothingType: 1,
    elevationHeight: 'Standing',
    airTemp: 26.66, // 80f
    winterAirTemp: 12.78, // 55f
    humidity: 60,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'LIBRARY',
    activityLevel: 1.2,
    clothingType: 0.57,
    winterClothingType: 1,
    elevationHeight: 'Seated',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 50,
    maxAirVelocity: 100,
  },
  {
    type: 'MANUFACTURING',
    activityLevel: 1.7,
    clothingType: 0.57,
    winterClothingType: 0.7,
    elevationHeight: 'Standing',
    airTemp: 26.66, // 80f
    winterAirTemp: 12.78, // 55f
    humidity: 60,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'OFFICE',
    activityLevel: 1.1,
    clothingType: 0.64,
    winterClothingType: 1,
    elevationHeight: 'Seated',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 70,
    maxAirVelocity: 120,
  },
  {
    type: 'PICKING_SORTING',
    activityLevel: 1.7,
    clothingType: 0.57,
    winterClothingType: 0.7,
    elevationHeight: 'Standing',
    airTemp: 26.66, // 80f
    winterAirTemp: 12.78, // 55f
    humidity: 60,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'RESIDENTIAL',
    activityLevel: 1.2,
    clothingType: 0.57,
    winterClothingType: 0.74,
    elevationHeight: 'Seated',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 70,
    maxAirVelocity: 200,
  },
  {
    type: 'SHIPPING_RECEIVING',
    activityLevel: 1.7,
    clothingType: 0.57,
    winterClothingType: 0.7,
    elevationHeight: 'Seated',
    airTemp: 26.66, // 80f
    winterAirTemp: 12.78, // 55f
    humidity: 60,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'STORAGE_RACKING',
    activityLevel: 1.7,
    clothingType: 0.57,
    winterClothingType: 0.7,
    elevationHeight: 'Standing',
    airTemp: 26.66, // 80f
    winterAirTemp: 12.78, // 55f
    humidity: 60,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'STRENGTH_TRAINING',
    activityLevel: 3.2,
    clothingType: 0.36,
    winterClothingType: 0.36,
    elevationHeight: 'Standing',
    airTemp: 23.33, // 74f
    winterAirTemp: 20, // 68f
    humidity: 50,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'WAREHOUSE',
    activityLevel: 1.7,
    clothingType: 0.57,
    winterClothingType: 0.7,
    elevationHeight: 'Standing',
    airTemp: 26.66, // 80f
    winterAirTemp: 12.78, // 55f
    humidity: 60,
    indoorWinterHumidity: 40,
    airConditioned: true,
    airVelocity: 200,
    maxAirVelocity: null,
  },
  {
    type: 'OTHER',
    activityLevel: 2.0,
    clothingType: 0.57,
    winterClothingType: 1,
    elevationHeight: 'Standing',
    airTemp: 26.66, // 80f
    winterAirTemp: 12.78, // 55f
    humidity: 60,
    indoorWinterHumidity: 40,
    airConditioned: false,
    airVelocity: 200,
    maxAirVelocity: null,
  },
]
