import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Icon from 'components/UIKit/Icon'

const StyledIcon = styled(Icon)`
  position: absolute;
  right: ${theme('spacing.base')};
  transform: ${props =>
    `rotate(${props.isDropdownVisible ? '0deg' : '180deg'});`};
  transition: transform 0.2s ease-in-out;
`

export default StyledIcon
