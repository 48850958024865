import { useFragment, useQuery } from '@apollo/client'
import { graphql } from '~/gql'
import { useFacilityMatch } from '~/config/routes'
import { ResultOf } from '@graphql-typed-document-node/core/typings'

const CFD_RESULT_CHECKSUMS_FRAGMENT = graphql(`
  fragment CfdResultChecksums on Version {
    cfd {
      id
      internalType
      resultChecksum
      status
    }
  }
`)

export const GET_CFD_CHECKSUM_QUERY = graphql(`
  query GetCFDChecksums($versionId: ID!) {
    Version(id: $versionId) {
      id
      ...CfdResultChecksums
    }
  }
`)

export const useCFDResultChecksum = () => {
  const versionId = useFacilityMatch().versionId ?? ''
  const { data, loading, error, ...results } = useQuery(
    GET_CFD_CHECKSUM_QUERY,
    {
      variables: { versionId: versionId },
      skip: !versionId,
    }
  )
  if (loading || error || !data?.Version) {
    return { checksumResults: undefined, loading, error, ...results }
  }
  const checksumResults = data.Version.cfd as ResultOf<typeof CFD_RESULT_CHECKSUMS_FRAGMENT>
  return { checksumResults, loading, error, ...results }
}

export const useCFDResultChecksumFragment = () => {
  const versionId = useFacilityMatch().versionId ?? ''
  const { data, ...fragmentResults } = useFragment({
    fragment: CFD_RESULT_CHECKSUMS_FRAGMENT,
    from: {
      __typename: 'Version',
      id: versionId,
    },
  })
  return { checksumResults: data.cfd, ...fragmentResults }
}
