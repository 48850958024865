import { useAppSelector, useAppDispatch } from "~/store/hooks";

import { CONTROLS_PANEL } from 'store/panel/types'

import Panel, { PanelSection } from 'components/UIKit/Panel'
import { StyledRange } from 'components/UIKit/Range'
import Space from 'components/UIKit/Space'
import { MathUtils } from 'three'
import { set3DRotation } from '~/store/camera'

export default function Camera3DControlsPanel({
  alignment = 'right'
} = {}) {
  const dispatch = useAppDispatch()
  const is3D = useAppSelector((state) => state.camera.is3D)
  const three = useAppSelector((state) => state.camera.three)
  const { azimuth, polar } = three.rotation
  const normalAzimuth = MathUtils.euclideanModulo(azimuth, Math.PI * 2)
  return (
    <Panel
      alignment={alignment}
      canMinimize
      landscape
      panelKey={CONTROLS_PANEL}
      stretchContainer={true}
      title="3D Controls"
      width="330px"
    >
      <PanelSection>
        <Space bottom="s">
          <StyledRange
            disabled={!is3D}
            name="sliderZ"
            type="range"
            steps={['Top', 'Ground']}
            min={0}
            max={0.5 * Math.PI}
            step={(0.5 * Math.PI) / 50}
            value={polar}
            onChange={(event) => dispatch(set3DRotation({ polar: event.target.value, azimuth, passive: false }))}
            label="Vertical"
          />
        </Space>
        <StyledRange
          disabled={!is3D}
          type="range"
          name="sliderXY"
          min={0}
          steps={['Front', 'Left', 'Back', 'Right', 'Front']}
          max={Math.PI * 2}
          value={normalAzimuth}
          step={(Math.PI * 2) / 50}
          onChange={(event) => dispatch(set3DRotation({ polar, azimuth: event.target.value, passive: false }))}
          label="Horizontal"
        />
      </PanelSection>
    </Panel>
  )
}
