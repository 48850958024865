import styled, { ThemedStyledProps } from 'styled-components'

import { noSelect, theme } from 'lib/styleUtils'
import type { FontSize, Theme } from '~/config/theme';

const size = ({ size, theme }: ThemedStyledProps<{ size?: FontSize }, Theme>) => {
  if (size) {
    return `font-size: ${theme.fonts.sizes[size]};`
  }
  return `font-size: ${theme.fonts.sizes.xs};`
}

const Label = styled.b<{ variant?: 'dark' | 'light'; size?: FontSize }>`
  color: ${props =>
    props.variant === 'dark'
      ? props.theme.colors.dark.fg
      : props.theme.colors.light.fg};
  ${size};
  font-weight: ${theme('fonts.weights.normal')};
  text-transform: uppercase;
  ${noSelect()};
`

export default Label
