import { ALL_FACILITIES_QUERY } from '../queries'

const handleCopyFacilityTemplate = ({ name, facilityId, mutate, ownProps }) =>
  mutate({
    variables: { name, facilityId },
    update: (client, { data: { facility } }) => {
      const { allFacilities } = client.readQuery({
        query: ALL_FACILITIES_QUERY,
        variables: ownProps.variables,
      })

      allFacilities.facilities.unshift(facility)

      client.writeQuery({
        query: ALL_FACILITIES_QUERY,
        variables: ownProps.variables,
        data: {
          allFacilities,
        },
      })
    },
  })

export default handleCopyFacilityTemplate
