import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Value = styled.p`
  font-size: ${theme('fonts.sizes.h1')};
  font-weight: bold;
`

export default Value
