const liftTypes = [
  "Up to 30' Scissor Lift",
  "30' to 45' Scissor Lift / High Lift Adder",
  'Special Lift (Any type of Boom, All Terrain, Atrium, or Scaffolding)',
  'None',
]
const levels = ['Mechanical', 'Standard']
const fireRelayTypes = ['Within Scope', 'Outside of Scope']
const fanAdderOptions = [
  'Z-Purlin Kit',
  'L-Bracket Kit',
  'Wood Frame Kit',
  'LED Light Kit',
  'No Ass Packaging',
  'Move a light',
  'Other',
]

const controllerTypes = [
  'Standard Controller',
  'BAFCon',
  'BAFCon Multi-Fan',
  'BAFWorks',
  'Dewtect',
  'Other',
]

const installAdderOptions = [
  'After Hours Install',
  'Custom colors',
  'Electrical runs outside of scope',
  'Painted conduit',
  'Permits required',
  'Prevailing wage',
  'Union labor',
  'Rigid conduit',
  'Structural Engineering',
  'Training classes required',
  'Wall puncture',
  'Other',
]

const makeOptions = arr => arr.map(val => ({ label: val, key: val }))

const liftTypeOptions = makeOptions(liftTypes)
const levelOptions = makeOptions(levels)
const fireRelayTypeOptions = makeOptions(fireRelayTypes)
const controllerTypeOptions = makeOptions(controllerTypes)

const LETTERS = Array.from(Array(26))
  .map((e, i) => i + 65)
  .map(x => String.fromCharCode(x))

export {
  liftTypeOptions,
  levelOptions,
  fireRelayTypeOptions,
  fanAdderOptions,
  controllerTypeOptions,
  installAdderOptions,
  LETTERS,
}
