import type { PropsWithChildren } from 'react'

import Wrapper from './styled/Wrapper'
import Link from './styled/Link'
import type { LinkProps } from 'react-router-dom'
import type { Optional } from '~/lib/typeUtils'

export default function A({ children, to = '', ...props }: PropsWithChildren<Optional<LinkProps, 'to'> & { noBorder?: boolean }>) {
  if (to !== '') {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    )
  }

  return <Wrapper {...props}>{children}</Wrapper>
}
