import { graphql } from '~/gql'

const GET_FACILITY_TEMPLATE_QUERY = graphql(`
  query GetFacilityTemplate($id: ID!) {
    getFacilityTemplate: FacilityTemplate(id: $id) {
      ...FacilityTemplateFragment
      facility {
        floor {
          id
          version {
            id
            snapshots {
              ...SnapshotFragment
            }
          }
        }
      }
    }
  }
`)

export default GET_FACILITY_TEMPLATE_QUERY
