import { graphql } from '~/gql'

const DELETE_SNAPSHOT_MUTATION = graphql(`
  mutation DeleteSnapshot($snapshotId: ID, $cloudinaryId: String) {
    deleteSnapshot(snapshotId: $snapshotId, cloudinaryId: $cloudinaryId) {
      id
    }
  }
`)

export default DELETE_SNAPSHOT_MUTATION
