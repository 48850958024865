import isNil from 'lodash-es/isNil'
import omitBy from 'lodash-es/omitBy'

import { SYSTEMS, TYPES } from './constants'

import { LOAD_FACILITY } from '../objects/actions'

/** @type {Record<import('./constants.ts').UnitTypes, import('./constants.ts').MeasurementSystem>} */
const initialState = {
  [TYPES.DISTANCE]: SYSTEMS.IMPERIAL,
  [TYPES.VELOCITY]: SYSTEMS.IMPERIAL,
  [TYPES.TEMPERATURE]: SYSTEMS.IMPERIAL,
}

/**
 * @param {Record<import('./constants.ts').UnitTypes, import('./constants.ts').MeasurementSystem> | undefined} state
 * @param {import('redux').UnknownAction} action
 * @returns {Record<import('./constants.ts').UnitTypes, import('./constants.ts').MeasurementSystem>}
 */
export default function unitsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_FACILITY: {
      return {
        ...state,
        ...omitBy(action.payload.units, isNil),
      }
    }
    default: {
      return state
    }
  }
}
