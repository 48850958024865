import React from 'react'
import ApolloClient from 'client'
import {
  HEATER_VARIATION_FRAGMENT,
  PRODUCT_VARIATION_FRAGMENT,
} from 'client/fragments'
import PropTypes from 'prop-types'
import Select from 'components/UIKit/Select'
import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'
import uniq from 'lodash-es/uniq'
import { compose } from 'redux'
import { showProductSize } from 'lib/utils'
import { withUnits } from 'store/units/decorators'

const getOptions = ({ product, distanceUnits, isHeater }) => {
  const variation = ApolloClient.readFragment({
    id: `ProductVariation:${product.variationId}`,
    fragment: isHeater ? HEATER_VARIATION_FRAGMENT : PRODUCT_VARIATION_FRAGMENT,
  })

  const voltages = variation.voltages || []
  const mountingOptions = get(
    voltages.find(v => v.id === product.voltageId) || {},
    'mountingOptions',
    []
  )

  const options = [
    ...sortBy(uniq(mountingOptions), 'tubeLength').map(o => {
      let label = o.label
      if (!label) {
        label = showProductSize(o.tubeLength, distanceUnits, 2)
      }

      return {
        value: o.tubeLength,
        label,
      }
    }),
  ]
  return options
}

const InstallTubeSelect = ({
  value,
  product,
  onUpdateProducts,
  distanceUnits,
  isHeater,
}) => {
  const options = getOptions({ product, distanceUnits, isHeater })
  return (
    <Select
      options={options}
      placeholder="Choose a size..."
      value={value}
      onChange={e => {
        const newProduct = { ...product, installTube: e.target.value }
        onUpdateProducts([newProduct])
      }}
    />
  )
}

InstallTubeSelect.propTypes = {
  value: PropTypes.number,
  product: PropTypes.object,
  onUpdateProducts: PropTypes.func,
  distanceUnits: PropTypes.string,
  isHeater: PropTypes.bool,
}

export default compose(withUnits)(InstallTubeSelect)
