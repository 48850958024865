import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";
import { Helmet } from 'react-helmet'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import qs from 'qs'

import { getTitle } from 'config/titles'
import { updateObjects } from 'store/objects'

import Button from 'components/UIKit/Button'
import Modal from 'components/UIKit/Modal'
import Select from 'components/UIKit/Select'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'
import Tooltip from 'components/UIKit/Tooltip'
import Facility from 'components/DrawingCanvas/lib/facility'

const makeOptions = arr => arr.map(val => ({ label: val, key: val }))

const voltages = [
  '100-125/1',
  '200-240/1',
  '200-240/3',
  '400-480/3',
  '575-600/3',
  'Other',
]

const brands = ['Square D', 'GE', 'Eaton', 'Siemens', 'Other']

const voltageOptions = makeOptions(voltages)
const brandOptions = makeOptions(brands)

const AddElectricPanelInstallInfoModal = ({
  parentRoute,
  online,
  selectedElectricPanels,
  ...props
}) => {
  const [voltage, setVoltage] = useState(
    props.selectedUtilityBox.voltage || null
  )
  const [voltageOther, setVoltageOther] = useState(
    props.selectedUtilityBox.voltageOther || null
  )
  const [brand, setBrand] = useState(props.selectedUtilityBox.brand || null)
  const [brandOther, setBrandOther] = useState(
    props.selectedUtilityBox.brandOther || null
  )

  function handleSubmit(event) {
    event.preventDefault()
    const data = {
      voltage,
      brand,
    }
    // We only want to include the other options in the data when
    // `Other` is selected as an option.
    const _voltageOther =
      voltage.toLowerCase() === 'other' ? voltageOther : null
    const _brandOther = brand.toLowerCase() === 'other' ? brandOther : null
    const canSubmit = voltage !== null && brand !== ''
    if (!canSubmit) return false

    const updatedBoxes = selectedElectricPanels.map(box => {
      const allBoxes = Facility.current.getUtilityBoxes()
      const utilityBox = allBoxes.find(o => o.id === box.id)
      return {
        ...utilityBox.toModel(),
        ...data,
        voltageOther: _voltageOther,
        brandOther: _brandOther,
      }
    })
    props.onUpdateUtilityBox(updatedBoxes)
    props.history.push(parentRoute)
  }

  const disableSubmit =
    !voltage ||
    !brand ||
    (voltage.toLowerCase() === 'other' && isEmpty(voltageOther)) ||
    (brand.toLowerCase() === 'other' && isEmpty(brandOther)) ||
    !online

  return (
    <div>
      <Helmet>
        <title>{getTitle('installInfo')}</title>
      </Helmet>
      <Modal
        title="Install Information"
        onSubmit={handleSubmit}
        parentRoute={parentRoute}
        primaryAction={
          disableSubmit ? (
            <Tooltip
              content={
                online
                  ? 'You need to fill out all the fields before submitting'
                  : 'Internet connection required to update install information'
              }
            >
              {/*
                Tooltips around a disabled Button require a wrapping element
                because of how browsers handle events on disabled buttons.
              */}
              <div>
                <Button primary label="Submit" disabled={true} />
              </div>
            </Tooltip>
          ) : (
            <Button primary label="Submit" />
          )
        }
        secondaryAction={<Button to={parentRoute} label="Cancel" />}
        {...props}
      >
        <>
          <Space bottom="base">
            <Select
              name="voltage"
              label="Voltage"
              onChange={event => setVoltage(event.target.value)}
              value={voltage}
              options={voltageOptions}
              disablePlaceholder={false}
            />
            {voltage === 'Other' && (
              <Space top="s">
                <TextField
                  name="voltageOther"
                  label="Please specify:"
                  value={voltageOther}
                  width="50%"
                  onChange={event => setVoltageOther(event.target.value)}
                />
              </Space>
            )}
          </Space>
          <Space bottom="base">
            <Select
              name="brand"
              label="Brand"
              onChange={event => setBrand(event.target.value)}
              value={brand}
              options={brandOptions}
              disablePlaceholder={false}
            />
            {brand === 'Other' && (
              <Space top="s">
                <TextField
                  name="brandOther"
                  label="Please specify:"
                  value={brandOther}
                  width="50%"
                  onChange={event => setBrandOther(event.target.value)}
                />
              </Space>
            )}
          </Space>
        </>
      </Modal>
    </div>
  )
}

AddElectricPanelInstallInfoModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  onUpdateUtilityBox: PropTypes.func,
  parentRoute: PropTypes.string,
  selectedUtilityBox: PropTypes.object,
  selectedElectricPanels: PropTypes.array,
  online: PropTypes.bool,
}

const mapStateToProps = ({ objects, selectedObjects }, ownProps) => {
  const queryParams = qs.parse(get(ownProps, 'location.search'), {
    ignoreQueryPrefix: true,
  })
  const selectedUtilityBoxIds = selectedObjects
    .filter(obj => obj.className === 'UtilityBox')
    .map(obj => obj.id)
  const selectedElectricPanels = []
  const utilityBoxes = get(objects, 'present.utilityBoxes', {})
  Object.keys(utilityBoxes).forEach(key => {
    const utilityBoxType = get(utilityBoxes[key], 'utilityBoxType', '')
    const isElectricPanel = utilityBoxType === 'electricPanel'
    const isSelected = selectedUtilityBoxIds.includes(utilityBoxes[key].id)
    if (isElectricPanel && isSelected) {
      selectedElectricPanels.push(utilityBoxes[key])
    }
  })

  const objectId = get(queryParams, 'objectId')
  const selectedUtilityBox = get(objects.present, `utilityBoxes[${objectId}]`)

  return {
    selectedElectricPanels,
    selectedUtilityBox,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateUtilityBox(utilityBoxes) {
    dispatch(updateObjects(utilityBoxes))
  },
})

export default appConnect(
  mapStateToProps,
  mapDispatchToProps
)(AddElectricPanelInstallInfoModal)
