import { graphql } from '~/gql'

const LOG_USER_ACTION_MUTATION = graphql(`
  mutation logUserAction($action: String) {
    logUserAction(action: $action) {
      action
      userId
    }
  }
`)

export default LOG_USER_ACTION_MUTATION
