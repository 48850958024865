import { graphql } from '~/gql'

const FACILITY_TEMPLATE_FRAGMENT = graphql(`
  fragment FacilityTemplateFragment on FacilityTemplate {
    id
    name
    description
    imageUrl
    facility {
      ...FacilityFragment
    }
  }
`)

export default FACILITY_TEMPLATE_FRAGMENT
