import styled from 'styled-components'

import { breakpoints } from 'lib/styleUtils'

const Container = styled.div`
  display: block;
  width: 100%;

  ${props =>
    props.inline &&
    breakpoints.m`
    align-items: center;
    display: flex;
  `};
`

export default Container
