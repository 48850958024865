import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import compact from 'lodash-es/compact'
import uniq from 'lodash-es/uniq'
import pick from 'lodash-es/pick'
import get from 'lodash-es/get'

import { withUnits } from 'store/units/decorators'
import { SYSTEMS } from 'store/units/constants'
import { Distance } from 'store/units/types'
import { updateObjects, updateSegments } from 'store/objects'
import { isTouchUI } from 'store/userInterface/selectors'
import { SELECTED_WALL_PANEL } from 'store/panel/types'
import { getDimensionValue } from 'lib/utils'
import store from 'store'
import LAYER_KEYS from 'config/layerKeys'

import Switch from 'components/UIKit/Switch'
import DimensionInput from 'components/UIKit/DimensionInput'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'

import MetadataSection from 'components/MetadataSection'

class SelectedWallPanel extends Component {
  static defaultProps = {
    onUpdateWalls() {},
    onUpdateWallSegments() {},
  }

  handleSegmentsInputChange = (key, value) => {
    const { onUpdateWallSegments } = this.props

    onUpdateWallSegments({
      isFullHeight: false,
      [key]: value,
    })
  }

  renderContent() {
    const {
      segments,
      selectedObjects,
      isExteriorWall,
      onUpdateWalls,
      distanceUnits,
      isTouchUI,
    } = this.props

    const allSegments = Object.values(segments).reduce((accum, objSegments) => {
      return accum.concat(Object.values(objSegments))
    }, [])
    const heights = allSegments.map(({ height }) => height)
    const uniqueHeights = uniq(compact(heights))
    const height = uniqueHeights.length === 1 && uniqueHeights[0]

    const objectsWithSegments = selectedObjects.map(obj => ({
      ...obj,
      segments: Object.values(segments[obj.id]).map(segment => ({
        ...segment,
      })),
    }))

    const tooltip = {
      tooltip: isExteriorWall
        ? 'If the roof is sloped, enter eave height'
        : null,
    }

    const isFullHeight = allSegments.every(segment => segment.isFullHeight)
    const inputWidth = isTouchUI ? '140px' : '70px'

    const currentLayer = store.getState().layers.currentLayer
    const isLocked = store.getState().layers.layers[currentLayer].locked

    let thickness
    allSegments.forEach(segment => {
      if (thickness === undefined && segment.thickness) {
        thickness = segment.thickness
      } else if (thickness !== segment.thickness) {
        thickness = false
      }
    })

    return (
      <>
        <PanelSection flushBottom>
          <Space bottom="base">
            <DimensionInput
              label="Height"
              {...tooltip}
              labelWidth="75px"
              width={inputWidth}
              name={`wall-height`}
              disabled={isLocked ? true : false}
              distance={
                new Distance({
                  value: height || 0,
                  system: SYSTEMS.IMPERIAL,
                })
              }
              units={distanceUnits}
              tabIndex={2}
              onChange={({ distance }) => {
                this.handleSegmentsInputChange(
                  'height',
                  getDimensionValue(distance.imperial() || 0)
                )
              }}
            />
          </Space>
          {thickness && (
            <Space>
              <DimensionInput
                label="Thickness"
                labelWidth="75px"
                width={inputWidth}
                units={distanceUnits}
                disabled={isLocked}
                distance={
                  new Distance({
                    value: thickness,
                    system: SYSTEMS.IMPERIAL,
                  })
                }
                onChange={({ distance }) => {
                  const value = distance.imperial() || 0
                  this.handleSegmentsInputChange(
                    'thickness',
                    getDimensionValue(value)
                  )
                }}
              />
            </Space>
          )}
          {!isExteriorWall && (
            <Space bottom="base">
              <Switch
                name="full-height"
                label="Full height"
                tabIndex={3}
                disabled={isLocked}
                isChecked={isFullHeight}
                onClick={() =>
                  this.handleSegmentsInputChange('isFullHeight', !isFullHeight)
                }
              />
            </Space>
          )}
        </PanelSection>
        <MetadataSection
          objects={objectsWithSegments}
          onBlur={onUpdateWalls}
          disabled={isLocked}
        />
      </>
    )
  }

  render() {
    const { selectedObjects, alignment, isTouchUI } = this.props

    return (
      <Panel
        title="Walls"
        alignment={alignment}
        scrollable
        docked
        panelKey={SELECTED_WALL_PANEL}
        hasToolbar={isTouchUI}
      >
        {selectedObjects.length ? this.renderContent() : null}
      </Panel>
    )
  }
}

SelectedWallPanel.propTypes = {
  distanceUnits: PropTypes.string,
  selectedObjects: PropTypes.arrayOf(PropTypes.object),
  segments: PropTypes.object,
  onUpdateWalls: PropTypes.func,
  onUpdateWallSegments: PropTypes.func,
  isExteriorWall: PropTypes.bool,
  isTouchUI: PropTypes.bool,
  alignment: PropTypes.string,
}

SelectedWallPanel.defaultProps = {
  alignment: 'right',
}

const mapStateToProps = ({
  objects,
  selectedObjects: selectedObjectsState,
  ...store
}) => {
  let segments = {}
  let isExteriorWall = false
  const selectedObjects = selectedObjectsState
    .map(obj => get(objects, `present.objects.${obj.id}`))
    .filter(obj => obj !== undefined)

  if (selectedObjects.length) {
    isExteriorWall = !!selectedObjects.find(
      obj => obj.layerKey === LAYER_KEYS.EXTERIOR_WALLS
    )
    segments = selectedObjects.reduce((accum, obj) => {
      accum[obj.id] = pick(get(objects, 'present.segments'), obj.segments)
      return accum
    }, {})
  }

  return {
    isTouchUI: isTouchUI(store),
    segments,
    selectedObjects,
    isExteriorWall,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUpdateWalls(walls) {
    dispatch(updateObjects(walls))
  },
  onUpdateWallSegments(props) {
    const segmentKeys = Object.keys(ownProps.segments).reduce((accum, id) => {
      const segments = ownProps.segments[id]
      const keys = Object.keys(segments)
      return accum.concat(keys)
    }, [])
    dispatch(updateSegments(segmentKeys, props))
  },
})

const withSelectedWall = compose(
  withUnits,
  appConnect(mapStateToProps),
  appConnect(null, mapDispatchToProps)
)

export default withSelectedWall(SelectedWallPanel)
