import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Actions = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  padding-left: ${theme('spacing.base')};
`

export default Actions
