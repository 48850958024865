import React from 'react'
import { Helmet } from 'react-helmet'

import { getStepNumber } from 'config/onboarding'
import { getTitle } from 'config/titles'

import Onboarding from 'components/Onboarding'
import AppContainer from 'components/App/styled/AppContainer'

const OnboardingInteriorWallsScreen = ({ ...props }) => (
  <AppContainer>
    <Helmet>
      <title>{getTitle('interiorWalls')}</title>
    </Helmet>
    <Onboarding
      {...props}
      step={getStepNumber('Int. Walls')}
      message="Modify the interior walls of your facility."
    />
  </AppContainer>
)

export default OnboardingInteriorWallsScreen
