import React from 'react'
import PropTypes from 'prop-types'
import { Button } from './styled/ButtonGroup'
import Icon from 'components/UIKit/Icon'

const TypeButton = props => {
  const selected = props.type === props.selectedType
  return (
    <Button
      onClick={props.onClick}
      bg={selected ? 'rgba(39, 39, 39, 0.098)' : 'transparent'}
      color={selected ? 'black' : 'rgba(39, 39, 39, 0.4)'}
    >
      <Icon
        name="check"
        size="16"
        color={selected ? 'success' : 'rgba(39, 39, 39, 0.4)'}
        marginRight="xs"
      />
      {props.label}
    </Button>
  )
}

TypeButton.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  selectedType: PropTypes.string,
}

export default TypeButton
