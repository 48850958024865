import styled from 'styled-components'

import { getZIndexOf } from 'lib/utils'

const Content = styled.div`
  color: ${props => props.textColor};
  z-index: ${getZIndexOf('loadingOverlay') + 1};
`

export default Content
