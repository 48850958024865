import styled from 'styled-components'

import Icon from '../../Icon'

const down = `
  transition: transform 200ms ease-in-out;
  transform: rotate(0deg);
`

const up = `
  transition: transform 200ms ease-in-out;
  transform: rotate(180deg);
`

const caretPosition = ({ isActive, reverse }: { isActive?: boolean; reverse?: boolean }) => {
  if (reverse && !isActive) {
    return up
  }

  if (reverse && isActive) {
    return down
  }

  if (isActive) {
    return up
  }

  return down
}

const StyledIcon = styled(Icon).attrs(({ flush, reverse, size }: { flush?: boolean; reverse?: boolean; size?: string }) => ({
  flush: flush || false,
  name: 'arrowDown',
  reverse: reverse || false,
  size: size || '14',
}))<{ isActive?: boolean; hidden?: boolean; dimmable?: boolean }>`
  flex-shrink: 0;
  transition: opacity 200ms linear;
  ${props =>
    props.hidden &&
    `
  opacity: 0;
  visibility: hidden;`}
  ${props =>
    props.dimmable &&
    `
    opacity: 1;
    visibility: visible;
    color: ${props.theme.colors.light.subdued};
  `}
  ${props =>
    props.marginRight &&
    `margin-right: ${props.theme.spacing[props.marginRight]}`}
  ${props => !props.flush && `margin-left: ${props.theme.spacing.xs};`};
  ${props => caretPosition(props)};
`

export default StyledIcon
