import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '../Button'
import Flex from '../Flex'
import H1 from '../H1'
import Icon from '../Icon'

import Navigation from './Navigation'

import Bar from './styled/Bar'
import Container from './styled/Container'

const CloseButton = styled(Button)`
  margin-left: auto;
`

const FlexH1 = styled(H1)`
  flex-grow: 1;
`

const Offcanvas = props => {
  const [activeIndex, setActiveIndex] = useState(0)
  const updateActiveIndex = index => setActiveIndex(index)
  return (
    <Container open={props.open}>
      <Bar open={props.open} marginTop={props.marginTop}>
        <Flex numberOfItems={props.header ? 2 : 1}>
          {props.header && <FlexH1>{props.header}</FlexH1>}
          <CloseButton noBorder onClick={props.handleOpen}>
            <Icon name="cross" />
          </CloseButton>
        </Flex>
        <Navigation
          data={props.data}
          activeIndex={activeIndex}
          updateActiveIndex={updateActiveIndex}
        />
      </Bar>
    </Container>
  )
}

Offcanvas.propTypes = {
  data: PropTypes.array.isRequired,
  handleOpen: PropTypes.func.isRequired,
  header: PropTypes.string,
  marginTop: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
}

export default Offcanvas
