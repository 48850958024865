import React, { Component } from 'react'
import { compose } from 'redux'
import { string, func, object } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'
import * as THREE from 'three'
import get from 'lodash-es/get'

import { getTitle } from 'config/titles'
import { distributeProducts } from 'store/objects'
import { mostRecentSelectedObjectStateOfClassName } from 'store/selectedObjects/selectors'
import { Distance } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'
import { withUnits } from 'store/units/decorators'
import CLASS_NAMES from 'config/objectClassNames'

import Facility from 'components/DrawingCanvas/lib/facility'
import Util from 'components/DrawingCanvas/lib/util'
import Units from 'components/DrawingCanvas/lib/units'

import Button from 'components/UIKit/Button'
import DimensionInput from 'components/UIKit/DimensionInput'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import Table, { Row, Cell } from 'components/UIKit/Table'
import TextField from 'components/UIKit/TextField'
import Tooltip from 'components/UIKit/Tooltip'

import Container from './styled/Container'
import Note from './styled/Note'

class DistributeProductsModal extends Component {
  state = {
    verticalItemsCount: 1,
    verticalSpacing: new Distance({
      value: 240,
      system: SYSTEMS.IMPERIAL,
    }),
    horizontalItemsCount: 1,
    horizontalSpacing: new Distance({
      value: 240,
      system: SYSTEMS.IMPERIAL,
    }),
    loading: false,
    verticalCountAuto: false,
    horizontalCountAuto: false,
    verticalSpacingAuto: false,
    horizontalSpacingAuto: false,
    verticalEnabled: true,
    horizontalEnabled: true,
  }

  componentDidUpdate(nextProps) {
    const {
      verticalItemsCount,
      verticalSpacing,
      horizontalItemsCount,
      horizontalSpacing,
    } = this.state

    if (!horizontalItemsCount && !horizontalSpacing) {
      this.setState({
        horizontalSpacing: new Distance({
          value: 1,
          system: SYSTEMS.IMPERIAL,
        }),
      })
    }

    if (!verticalItemsCount && !verticalSpacing) {
      this.setState({
        verticalSpacing: new Distance({
          value: 1,
          system: SYSTEMS.IMPERIAL,
        }),
      })
    }
  }

  handleFocus = event => {
    event.target.select()
  }

  getVerticalAutoSpacing(count) {
    const facilityHeight = Facility.current.getYCompositeSize()
    const spacing = Units.nativeToInches(facilityHeight) / count
    return spacing
  }

  getHorizontalAutoSpacing(count) {
    const facilityWidth = Facility.current.getXCompositeSize()
    const spacing = Units.nativeToInches(facilityWidth) / count
    return spacing
  }

  handleGenerateGrid = () => {
    const { selectedObjectId, products } = this.props
    const selectedProduct = products[selectedObjectId]

    if (!selectedProduct) return

    const { parentRoute, history } = this.props

    const {
      verticalItemsCount,
      verticalSpacing,
      verticalCountAuto,
      verticalSpacingAuto,
      verticalEnabled,
      horizontalItemsCount,
      horizontalSpacing,
      horizontalCountAuto,
      horizontalSpacingAuto,
      horizontalEnabled,
    } = this.state

    let verticalCount = verticalCountAuto
      ? 50
      : parseInt(verticalItemsCount, 10) + 1
    let horizontalCount = horizontalCountAuto
      ? 50
      : parseInt(horizontalItemsCount, 10) + 1
    if (!verticalEnabled) {
      verticalCount = 1
    }
    if (!horizontalEnabled) {
      horizontalCount = 1
    }

    const isIRH = (get(selectedProduct, 'label') || '').includes('IRH')

    const getHorizontalSpacing = () => {
      const result = horizontalSpacingAuto
        ? this.getHorizontalAutoSpacing(horizontalCount)
        : horizontalSpacing.imperial()

      return isIRH ? result + selectedProduct.size : result
    }

    const vertSpacing = verticalSpacingAuto
      ? this.getVerticalAutoSpacing(verticalCount)
      : verticalSpacing.imperial()

    const horizSpacing = getHorizontalSpacing()

    const regions = Facility.current.getExteriorWallRegions()
    const positions = []

    let xMin = selectedProduct.position.x
    let yMin = selectedProduct.position.y
    let xMax = selectedProduct.position.x
    let yMax = selectedProduct.position.y

    const delta = new THREE.Vector3()

    for (let i = 0; i < verticalCount; i++) {
      for (let j = 0; j < horizontalCount; j++) {
        const isOriginObject = i === 0 && j === 0
        if (!isOriginObject) {
          const verticalSpacing = i > 0 ? vertSpacing * i * -1 : 0
          const horizontalSpacing = j > 0 ? horizSpacing * j : 0

          delta.set(horizontalSpacing, verticalSpacing, 0)

          const pos = new THREE.Vector3()
            .copy(selectedProduct.position)
            .add(delta)

          const positionX = Units.inchesToNative(pos.x)
          const positionY = Units.inchesToNative(pos.y)
          const nativePos = new THREE.Vector3().set(positionX, positionY, 1)
          const offset = Units.inchesToNative(selectedProduct.size) / 2

          const points = [
            new THREE.Vector3().set(
              nativePos.x + offset,
              nativePos.y + offset,
              1
            ),
            new THREE.Vector3().set(
              nativePos.x + offset,
              nativePos.y - offset,
              1
            ),
            new THREE.Vector3().set(
              nativePos.x - offset,
              nativePos.y + offset,
              1
            ),
            new THREE.Vector3().set(
              nativePos.x - offset,
              nativePos.y - offset,
              1
            ),
          ]

          regions.forEach(region => {
            let isValidPosition = true
            points.forEach(point => {
              if (!Util.isPointInPolygon(point, region.points)) {
                isValidPosition = false
              }
            })

            if (isValidPosition) {
              positions.push(pos)
            }
          })
        }
      }
    }

    const models = []
    positions.forEach(pos => {
      models.push(
        Object.assign({}, selectedProduct, {
          position: pos,
          id: Util.guid(),
          className: CLASS_NAMES.PRODUCT,
        })
      )

      xMin = Math.min(xMin, pos.x)
      yMin = Math.min(yMin, pos.y)
      xMax = Math.max(xMax, pos.x)
      yMax = Math.max(yMax, pos.y)
    })

    const size = get(selectedProduct, 'size', 0)
    const radius = size / 2
    const gridBox = {
      id: Util.guid(),
      xMin: xMin - radius,
      yMin: yMin - radius,
      xMax: xMax + radius,
      yMax: yMax + radius,
      height: selectedProduct.height,
      type: CLASS_NAMES.PRODUCT,
      className: CLASS_NAMES.GRID_BOX,
      models: [
        ...models,
        { ...selectedProduct, className: CLASS_NAMES.PRODUCT },
      ],
    }

    this.props.onDistributeProducts(models, gridBox)
    history.push(parentRoute)
  }

  handleSubmit = event => {
    event.preventDefault()

    this.setState({
      loading: true,
    })

    setTimeout(this.handleGenerateGrid, 100)
  }

  handleChange = ({ name, value, enabled }) => {
    // keep numeric value between 1 and 50
    const intValue = parseInt(value, 10)
    let formattedValue = intValue >= 50 ? 50 : intValue < 1 ? 0 : intValue

    if (formattedValue < 1) {
      this.setState({
        [enabled]: !this.state[enabled],
      })
    }

    this.setState({ [name]: formattedValue })
  }

  handleToggleAuto = ({ event, name }) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      [name]: !this.state[name],
    })
  }

  handleToggleVerticalEnabled = () => {
    if (!this.state.horizontalEnabled) {
      return
    }
    this.setState({
      verticalEnabled: !this.state.verticalEnabled,
    })
  }

  handleToggleHorizontalEnabled = () => {
    if (!this.state.verticalEnabled) {
      return
    }
    this.setState({
      horizontalEnabled: !this.state.horizontalEnabled,
    })
  }

  render() {
    const { parentRoute, distanceUnits, selectedObjectId } = this.props
    const { loading } = this.state
    const isDisabled = loading || !selectedObjectId

    return (
      <div>
        <Helmet>
          <title>{getTitle('distributeProducts')}</title>
        </Helmet>
        <Modal
          title="Distribute Products"
          primaryAction={
            isDisabled ? (
              <Tooltip content="You need to select an object">
                <div>
                  <Button
                    primary
                    type="submit"
                    disabled={isDisabled}
                    label={loading ? 'Adding...' : 'Add'}
                  />
                </div>
              </Tooltip>
            ) : (
              <Button
                primary
                type="submit"
                label={loading ? 'Adding...' : 'Add'}
              />
            )
          }
          secondaryAction={<Button to={parentRoute} label="Cancel" />}
          flushContent
          onSubmit={this.handleSubmit}
          {...this.props}
        >
          <Container size="base">
            <Space bottom="l">
              <Table>
                <Row>
                  <Cell />
                  <Cell bordered>
                    <h2>Spacing</h2>
                    <p>Distance between items (center-to-center)</p>
                  </Cell>
                  <Cell bordered>
                    <h2>Count</h2>
                    <p>Number of additional items to distribute</p>
                  </Cell>
                </Row>
                <Row disabled={!this.state.verticalEnabled}>
                  <Cell>
                    <input
                      type="checkbox"
                      checked={this.state.verticalEnabled}
                      onClick={this.handleToggleVerticalEnabled}
                    />
                  </Cell>
                  <Cell bordered>
                    <Grid vCenter>
                      <GridItem size="2of10">
                        <img
                          src="/spacing-vertical-center.png"
                          width={36}
                          height={36}
                          alt="Vertical Spacing"
                        />
                      </GridItem>
                      <GridItem size="3of10">
                        <DimensionInput
                          name="vertical-spacing"
                          distance={this.state.verticalSpacing}
                          units={distanceUnits}
                          disabled={
                            this.state.verticalSpacingAuto ||
                            !this.state.verticalEnabled
                          }
                          onChange={({ distance }) => {
                            this.setState({ verticalSpacing: distance })
                          }}
                        />
                      </GridItem>
                      <GridItem size="2of10">
                        {!this.state.verticalCountAuto && (
                          <Tooltip content="Automatically space based on count">
                            <Button
                              icon="wand"
                              primary={this.state.verticalSpacingAuto}
                              disabled={!this.state.verticalEnabled}
                              onClick={event =>
                                this.handleToggleAuto({
                                  event,
                                  name: 'verticalSpacingAuto',
                                })
                              }
                            />
                          </Tooltip>
                        )}
                      </GridItem>
                    </Grid>
                  </Cell>
                  <Cell bordered>
                    <Grid vCenter>
                      <GridItem size="2of10">
                        <img
                          src="/count-vertical.png"
                          width={36}
                          height={36}
                          alt="Vertical Count"
                        />
                      </GridItem>
                      <GridItem size="3of10">
                        <TextField
                          name="vertical-count"
                          onFocus={this.handleFocus}
                          onClick={this.handleFocus}
                          type={
                            this.state.verticalItemsCount ? 'number' : 'string'
                          }
                          width="60px"
                          inline
                          value={this.state.verticalItemsCount}
                          disabled={
                            this.state.verticalCountAuto ||
                            !this.state.verticalEnabled
                          }
                          onChange={event => {
                            this.handleChange({
                              name: 'verticalItemsCount',
                              value: event.target.value,
                              enabled: 'verticalEnabled',
                            })
                          }}
                        />
                      </GridItem>
                      <GridItem size="2of10">
                        {!this.state.verticalSpacingAuto && (
                          <Tooltip content="Automatically fill the available area">
                            <Button
                              icon="infinity"
                              primary={this.state.verticalCountAuto}
                              disabled={!this.state.verticalEnabled}
                              onClick={event =>
                                this.handleToggleAuto({
                                  event,
                                  name: 'verticalCountAuto',
                                })
                              }
                            />
                          </Tooltip>
                        )}
                      </GridItem>
                    </Grid>
                  </Cell>
                </Row>
                <Row disabled={!this.state.horizontalEnabled}>
                  <Cell>
                    <input
                      type="checkbox"
                      checked={this.state.horizontalEnabled}
                      onClick={this.handleToggleHorizontalEnabled}
                    />
                  </Cell>
                  <Cell>
                    <Grid vCenter>
                      <GridItem size="2of10">
                        <img
                          src="/spacing-horizontal-center.png"
                          width={36}
                          height={36}
                          alt="Horizontal Spacing"
                        />
                      </GridItem>
                      <GridItem size="3of10">
                        <DimensionInput
                          name="horizontal-spacing"
                          distance={this.state.horizontalSpacing}
                          units={distanceUnits}
                          disabled={
                            this.state.horizontalSpacingAuto ||
                            !this.state.horizontalEnabled
                          }
                          onChange={({ distance }) => {
                            this.setState({ horizontalSpacing: distance })
                          }}
                        />
                      </GridItem>
                      <GridItem size="2of10">
                        {!this.state.horizontalCountAuto && (
                          <Tooltip content="Automatically space based on count">
                            <Button
                              icon="wand"
                              primary={this.state.horizontalSpacingAuto}
                              disabled={!this.state.horizontalEnabled}
                              onClick={event =>
                                this.handleToggleAuto({
                                  event,
                                  name: 'horizontalSpacingAuto',
                                })
                              }
                            />
                          </Tooltip>
                        )}
                      </GridItem>
                    </Grid>
                  </Cell>
                  <Cell>
                    <Grid vCenter>
                      <GridItem size="2of10">
                        <img
                          src="/count-horizontal.png"
                          width={36}
                          height={36}
                          alt="Horizontal Count"
                        />
                      </GridItem>
                      <GridItem size="3of10">
                        <TextField
                          name="horizontal-count"
                          onFocus={this.handleFocus}
                          onClick={this.handleFocus}
                          type={
                            this.state.horizontalItemsCount
                              ? 'number'
                              : 'string'
                          }
                          width="60px"
                          inline
                          value={this.state.horizontalItemsCount}
                          disabled={
                            this.state.horizontalCountAuto ||
                            !this.state.horizontalEnabled
                          }
                          onChange={event => {
                            this.handleChange({
                              name: 'horizontalItemsCount',
                              value: event.target.value,
                              enabled: 'horizontalEnabled',
                            })
                          }}
                        />
                      </GridItem>
                      <GridItem size="2of10">
                        {!this.state.horizontalSpacingAuto && (
                          <Tooltip content="Automatically fill the available area">
                            <Button
                              icon="infinity"
                              primary={this.state.horizontalCountAuto}
                              disabled={!this.state.horizontalEnabled}
                              onClick={event =>
                                this.handleToggleAuto({
                                  event,
                                  name: 'horizontalCountAuto',
                                })
                              }
                            />
                          </Tooltip>
                        )}
                      </GridItem>
                    </Grid>
                  </Cell>
                </Row>
              </Table>
            </Space>
            <Space bottom="base">
              <Note size="s">
                <strong>Note:</strong> The distributed objects will generate
                down and to the right.
              </Note>
            </Space>
          </Container>
        </Modal>
      </div>
    )
  }
}

DistributeProductsModal.propTypes = {
  parentRoute: string,
  addProduct: func,
  onDistributeProducts: func,
  history: object,
  selectedObjectId: string,
  products: object,
  distanceUnits: string,
}

const mapStateToProps = ({ selectedObjects, objects }) => {
  const selectedObject = mostRecentSelectedObjectStateOfClassName(
    CLASS_NAMES.PRODUCT,
    {
      selectedObjects,
    }
  )
  return {
    selectedObjectId: selectedObject && selectedObject.id,
    products: objects.present.products,
  }
}

const mapDispatchToProps = dispatch => ({
  onDistributeProducts(models, gridBox) {
    dispatch(distributeProducts({ models, gridBox }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withUnits
)(DistributeProductsModal)
