import { Vector3 } from "three"
import LayerKeys from "~/config/layerKeys"
import { useAppSelector } from "~/store/hooks"
import Units from "../lib/units"
import { RoofSection } from "~/store/objects/types"
import { useState } from "react"
import { getBeamOrientation, getOrthoOrientation, MountingStructureData, RoofSectionOrientation } from "./util"
import { MountingStructureLine } from "./components/Lines"

export function MountingStructures() {
  const isVisible = useAppSelector(it => it.layers.layers[LayerKeys.MOUNTING_STRUCTURE_GUIDELINES].visible)
  const comfortPoints = useAppSelector(it => it.objects.present.roofSections)
  if (!isVisible) {
    return <></>
  }

  return (
    <>
      {Object.entries(comfortPoints).map(([key, value]) => <RoofSectionMountingStructures key={key} section={value}/>)}
    </>
  )
}

function slidingWindow<T>(items: T[]) {
  return items.map((value, idx) => {
    return { item: value, previous: items[idx-1], next: items[idx+1] }
  })
}
function RoofSectionMountingStructures(props: { section: RoofSection }) {
  const beamOrientation = getBeamOrientation(props.section.beamRotation)
  const columnOrientation = getOrthoOrientation(beamOrientation)

  const [selected, setSelected] = useState<[string, number] | null>(null)

  if (props.section.beamModels === undefined || props.section.columnLineModels === undefined) {
    return <></>
  }

  // Augment models with segment endpoints
  const augmentedBeamModels = slidingWindow(props.section.beamModels.map(model => [new Vector3(model.position.x, model.position.y, model.position.z), model.id] as [Vector3, number]).sort((a, b) => a[0].x - b[0].x))

  // Augment models with segment endpoints
  const augmentedColumnLineModels = slidingWindow(props.section.columnLineModels.map(model => [new Vector3(model.position.x, model.position.y, model.position.z), model.id] as [Vector3, number]).sort((a, b) => a[0].y - b[0].y))

  return (
    <RoofSectionOrientation.Provider value={props.section.beamRotation === 0 ? 'vertical' : 'horizontal'}>
      <group onPointerMissed={() => {
        if (!(document.activeElement instanceof HTMLInputElement)) {
          setSelected(null)
        }
      }}>
        {augmentedColumnLineModels.map((it, i) => {
          return (
            <MountingStructureData.Provider key={it.item[1]} value={{ selected: !!selected && selected[0] == props.section.id && selected[1] == it.item[1], setSelected, model: it.item, next: it.next, prev: it.previous, name: props.section.beamNames[i % props.section.beamNames.length].toString(), roofSectionId: props.section.id, z: Units.inchesToNative(props.section.height), color: "#00AAFF", type: 'column', orientation: columnOrientation, perimeterPoints: props.section.perimeterPoints}}>
              <MountingStructureLine/>
            </MountingStructureData.Provider>
          )
        })}
        {augmentedBeamModels.map((it, i) => {
          return (
            <MountingStructureData.Provider key={it.item[1]} value={{ selected: !!selected && selected[0] == props.section.id && selected[1] == it.item[1], setSelected, model: it.item, next: it.next, prev: it.previous, name: props.section.columnNames[i % props.section.columnNames.length], roofSectionId: props.section.id, z: Units.inchesToNative(props.section.height), color: "#00AAFF", type: 'beam', orientation: beamOrientation, perimeterPoints: props.section.perimeterPoints}}>
              <MountingStructureLine/>
            </MountingStructureData.Provider>
          )
        })}
      </group>
    </RoofSectionOrientation.Provider>
  )
}
