import styled, { css } from 'styled-components'

import { lighten, theme } from 'lib/styleUtils'

const setTouchStyles = props =>
  props.isTouchUI
    ? css`
        padding: ${theme('spacing.base')};
      `
    : css`
        padding: ${theme('spacing.s')};
        padding-left: ${theme('spacing.base')};
      `

const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${theme('colors.light.subdued')};
  background-color: ${props => lighten(props.theme.colors.light.subdued, 0.2)};
  color: ${theme('colors.light.fg')};
  font-size: ${theme('fonts.sizes.s')};
  ${setTouchStyles}
`

export default Header
