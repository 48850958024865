import { withRouter } from 'react-router-dom'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'

import { setCurrentLayer } from 'store/layers'
import { showPanel } from 'store/panel'
import { setActiveTool } from 'store/tools'

const mapStateToProps = ({ flags }) => ({
  flags,
})

const mapDispatchToProps = dispatch => ({
  insertItem({ layerKey, tool, props }) {
    dispatch(setCurrentLayer({ layerKey }))
    dispatch(setActiveTool({ tool, props }))
  },
  openPanel({ type }) {
    dispatch(showPanel({ type }))
  },
})

export default compose(withRouter, appConnect(mapStateToProps, mapDispatchToProps))
