import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import A from 'components/UIKit/A'

const CancelLink = styled(A)`
  font-size: ${theme('fonts.sizes.s')};
  font-weight: normal;
`

export default CancelLink
