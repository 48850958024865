import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Icon from 'components/UIKit/Icon'

const Container = styled(Icon)`
  position: absolute;
  right: ${theme('spacing.s')};
  top: 50%;
  transform: translateY(-50%);

  > path {
    transition: 0.3s ease-in-out;
  }
`

export default Container
