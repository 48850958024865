import Tool from './tool'
import Units from './units'
import Util from './util'
import Obstruction from './obstruction'

import { isTouchDevice } from 'lib/utils'

import store from 'store'
import { addObstruction } from 'store/objects'
import { deselectObjects } from 'store/selectedObjects'
import { showAlert } from 'store/alert'
import { setActiveTool } from 'store/tools'
import { isContinuousModeEnabled } from 'store/tools/selectors'
import { setStatus } from 'store/status'

import * as THREE from 'three'

class ObstructionTool extends Tool {
  constructor(props = {}) {
    super()

    this.name = 'OBSTRUCTION_TOOL'
    const position = props.position || Util.getCameraPosition()
    this.props = {
      height: props.height,
      length: props.length,
      obstructionType: props.obstructionType,
      primaryUses: props.primaryUses,
      position,
      positions: [
        {
          x: 0,
          y: 0,
          z: 0,
        },
      ],
      rotation: props.rotation,
      resizable: props.resizable,
      width: props.width,
    }

    this.id = Util.guid()

    this.init()
  }

  get model() {
    let positions = []
    let width = this.props.width
    let length = this.props.length
    const height = this.props.height || 48

    if (!length || !width) {
      const deltas = this.obstruction.positions.map(pos =>
        new THREE.Vector3()
          .set(pos.x, pos.y, pos.z)
          .sub(this.obstruction.position)
      )

      positions = deltas.map(delta => {
        const pos = this.obstruction.obj3d.position.clone().add(delta)
        return {
          x: Units.nativeToInches(pos.x),
          y: Units.nativeToInches(pos.y),
          z: Units.nativeToInches(pos.z),
        }
      })
    } else {
      positions = [
        {
          x: Units.nativeToInches(this.obstruction.obj3d.position.x + width),
          y: Units.nativeToInches(this.obstruction.obj3d.position.y + length),
          z: Units.nativeToInches(this.obstruction.obj3d.position.z),
        },
        {
          x: Units.nativeToInches(this.obstruction.obj3d.position.x + width),
          y: Units.nativeToInches(this.obstruction.obj3d.position.y - length),
          z: Units.nativeToInches(this.obstruction.obj3d.position.z),
        },
        {
          x: Units.nativeToInches(this.obstruction.obj3d.position.x - width),
          y: Units.nativeToInches(this.obstruction.obj3d.position.y - length),
          z: Units.nativeToInches(this.obstruction.obj3d.position.z),
        },
        {
          x: Units.nativeToInches(this.obstruction.obj3d.position.x - width),
          y: Units.nativeToInches(this.obstruction.obj3d.position.y + length),
          z: Units.nativeToInches(this.obstruction.obj3d.position.z),
        },
      ]
    }

    return {
      id: this.id,
      height,
      length,
      obstructionType: this.props.obstructionType,
      position: {
        x: Units.nativeToInches(this.obstruction.obj3d.position.x),
        y: Units.nativeToInches(this.obstruction.obj3d.position.y),
        z: Units.nativeToInches(this.obstruction.obj3d.position.z),
      },
      positions,
      rotation: this.props.rotation,
      resizable: this.props.resizable,
      scale: this.props.scale,
      tags: this.props.tags,
      width,
    }
  }

  didEnd = false

  setPosition = ({ x, y }) => {
    this.obstruction.obj3d.position.copy(new THREE.Vector3(x, y, 0))
  }

  init() {
    this.createVisual()
    this.createEventListeners()
  }

  createVisual() {
    if (this.obstruction) {
      this.obj3d.remove(this.obstruction.obj3d)
      this.obstruction.destroy()
    }

    this.obstruction = new Obstruction(this.props)
    this.obstruction.showArrowsOverride = true

    this.obj3d.add(this.obstruction.obj3d)
    this.setPosition(this.props.position)
  }

  createEventListeners = () => {
    document.addEventListener('keyup', this.handleKeyUp)
  }

  removeEventListeners = () => {
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyUp = e => {
    // Esc key up
    if (e.which === 27) {
      this.deactivate(true)
    }
  }

  insert = multiSelect => {
    if (!isContinuousModeEnabled()) this.deactivate()

    store.dispatch(addObstruction({ obstruction: this.model, multiSelect }))

    if (isContinuousModeEnabled()) {
      store.dispatch(deselectObjects({}))
      this.id = Util.guid()
      store.dispatch(
        setActiveTool({
          tool: 'OBSTRUCTION_TOOL',
          props: this.model,
        })
      )
    }
  }

  // Context functions
  toolMoved(
    mousePos,
    snappedMousePos,
    lastSceneIntersectionPoint,
    objectWithCursor,
    objectWithSnappedCursor,
    activeSnapRegion
  ) {
    if (this.didEnd) return

    this.setPosition({
      x: snappedMousePos.x,
      y: snappedMousePos.y,
    })
  }

  toolUp({ snappedMousePos, multiSelect }) {
    if (this.didEnd) return

    const positions = this.model.positions.map(position => ({
      x: Units.inchesToNative(position.x),
      y: Units.inchesToNative(position.y),
      z: Units.inchesToNative(position.z),
    }))
    positions.push(this.obstruction.obj3d.position)

    const isInsideFacility = Util.isPositionsOverFacility(positions)
    if (isInsideFacility) {
      if (isTouchDevice()) {
        this.setPosition({
          x: snappedMousePos.x,
          y: snappedMousePos.y,
        })
      }

      this.insert(multiSelect)
    } else {
      const error = 'Objects must be placed inside the facility!'
      store.dispatch(setStatus({ text: error, type: 'error' }))
    }
  }

  activate(mousePos) {
    this.didEnd = false

    if (isTouchDevice()) {
      store.dispatch(
        showAlert({
          text: 'Tap the canvas to place the obstruction',
        })
      )
    } else {
      this.setPosition({
        x: mousePos.x,
        y: mousePos.y,
      })
    }
  }

  deactivate(forceDeactivate = false) {
    if (isContinuousModeEnabled() && !forceDeactivate) return

    this.didEnd = true
    this.obj3d.remove(this.obstruction.obj3d)
    this.removeEventListeners()
    this.obstruction.destroy()
  }

  onPropsDidChange(nextProps) {
    this.didEnd = false
    this.props = {
      ...this.props,
      ...nextProps,
    }
    this.init()
  }

  getSnapRegions(facility, draggedObject) {
    return []
  }

  getArrowDescriptions() {
    this.obstruction.isDragging = true
    return this.obstruction.getArrowDescriptions(true)
  }
}

export default ObstructionTool
