import styled, { ThemedStyledProps } from 'styled-components'

import Icon from 'components/UIKit/Icon'
import { Theme } from '~/config/theme'

const touch = (props: ThemedStyledProps<{ isTouchUI?: boolean; isDropdownVisible?: boolean }, Theme>) =>
  props.isTouchUI &&
  `
    left: 0;
    right: auto;
    transform: rotate(${props.isDropdownVisible ? '90deg' : '-90deg'});`

const Container = styled(Icon)<{ isTouchUI?: boolean; isDropdownVisible?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(${props => (props.isDropdownVisible ? '-90deg' : '90deg')});

  ${touch}
`

export default Container
