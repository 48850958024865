import React from 'react'
import { func, node, string } from 'prop-types'

import Icon from 'components/UIKit/Icon'
import Image from 'components/UIKit/Image'
import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'

import Container from './styled/Container'
import RemoveIcon from './styled/RemoveIcon'

const Item = ({ children, icon, imageSrc, name, onClick, removeItem }) => {
  return (
    <Container>
      <RemoveIcon onClick={removeItem} />
      <div onClick={onClick}>
        {imageSrc && <Image src={imageSrc} width="80px" height="80px" />}
        {icon && <Icon name={icon} size="50px" />}
        <Space bottom="xs" top="s">
          <VariantText size="l">{name}</VariantText>
        </Space>
        {children}
      </div>
    </Container>
  )
}

Item.propTypes = {
  children: node,
  icon: string,
  imageSrc: string,
  name: string.isRequired,
  onClick: func.isRequired,
  removeItem: func,
}

export default Item
