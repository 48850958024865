import React from 'react'
import PropTypes from 'prop-types'

import Link from './styled/Link'

const Tab = ({
  onClick,
  title,
  isActive,
  size,
  children,
  flush,
  index,
}) => (
  <>
    <Link
      onClick={onClick}
      isActive={isActive}
      flush={flush}
      aria-selected={isActive}
      role="tab"
      size={size}
      index={index}
    >
      {title}
    </Link>
    {children}
  </>
)

Tab.propTypes = {
  children: PropTypes.node,
  flush: PropTypes.bool,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
}

export default /** @type any */(Tab)
