import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import get from 'lodash-es/get'

import { updateObjects } from 'store/objects'
import { isTouchUI } from 'store/userInterface/selectors'
import { withUnits } from 'store/units/decorators'
import { Distance } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'
import { SELECTED_WALL_SEGMENT_PANEL } from 'store/panel/types'
import { getDimensionValue } from 'lib/utils'
import LAYER_KEYS from 'config/layerKeys'

import DimensionInput from 'components/UIKit/DimensionInput'
import FormLabel from 'components/UIKit/FormLabel'
import Range from 'components/UIKit/Range'
import Space from 'components/UIKit/Space'
import Switch from 'components/UIKit/Switch'

import store from 'store'

import Facility from 'components/DrawingCanvas/lib/facility'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Units from 'components/DrawingCanvas/lib/units'
import Util from 'components/DrawingCanvas/lib/util'

import MetadataSection from 'components/MetadataSection'

const getWallLength = wall => {
  const selectedSegment = Facility.current
    .getWallSegments()
    .find(seg => seg.id === wall.id)

  if (selectedSegment) {
    const insetPointVectors = Util.toVec3Array(selectedSegment.insetPoints)
    const length = insetPointVectors[0].sub(insetPointVectors[1]).length()
    return Units.nativeToInches(length)
  }
}

const OPACITIES = [1.0, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0.0]
class SelectedWallSegmentPanel extends Component {
  handleInputChange = (key, value, ignoreCFD = false) => {
    const { selectedObjects, onUpdateSegments } = this.props

    onUpdateSegments(
      selectedObjects.map(obj => ({
        ...obj,
        isFullHeight: get(obj, 'isFullHeight', false),
        ignoreForCFD: ignoreCFD,
        [key]: value,
      }))
    )
  }

  renderContent() {
    const {
      selectedObjects,
      isExteriorWall,
      onUpdateSegments,
      distanceUnits,
      isTouchUI,
    } = this.props

    const index = -1
    let length
    let thickness
    let height
    let materialIndex

    const wallLengths = selectedObjects.map(obj => getWallLength(obj))

    const isLockedExteriorWalls = store.getState().layers.layers[
      LAYER_KEYS.EXTERIOR_WALLS
    ].locked

    const isLockedInteriorWalls = store.getState().layers.layers[
      LAYER_KEYS.INTERIOR_WALLS
    ].locked

    selectedObjects.forEach((obj, i) => {
      const wallLength = wallLengths[i]
      if (length === undefined && obj.startPoint && obj.endPoint) {
        length = wallLengths
      } else {
        if (wallLength !== length) {
          length = false
        }
      }

      if (thickness === undefined && obj.thickness) {
        thickness = obj.thickness
      } else if (thickness !== obj.thickness) {
        thickness = false
      }

      if (height === undefined && obj.height) {
        height = obj.height
      } else if (height !== obj.height) {
        height = false
      }

      if (materialIndex === undefined && obj.materialIndex === undefined) {
        materialIndex = 0
      } else if (
        materialIndex === undefined &&
        obj.materialIndex !== undefined
      ) {
        materialIndex = obj.materialIndex
      }
    })

    const tooltip = {
      tooltip: isExteriorWall
        ? 'If the roof is sloped, enter eave height'
        : null,
    }

    const isFullHeight = selectedObjects.every(obj => {
      return obj.isFullHeight
    })
    const inputWidth = isTouchUI ? '140px' : '70px'

    return (
      <>
        <PanelSection flushBottom panelKey={SELECTED_WALL_SEGMENT_PANEL}>
          {length && (
            <Space bottom="base">
              <DimensionInput
                label="Length"
                labelWidth="75px"
                width={inputWidth}
                name={`wall-${index}-length`}
                distance={
                  new Distance({
                    value: length,
                    system: SYSTEMS.IMPERIAL,
                  })
                }
                units={distanceUnits}
                onChange={() => {}}
                disabled
                tabIndex={index * 3 + 1}
              />
            </Space>
          )}
          {height && (
            <Space bottom="base">
              <DimensionInput
                label="Height"
                {...tooltip}
                labelWidth="75px"
                width={inputWidth}
                name={`wall-${index}-height`}
                disabled={
                  isLockedExteriorWalls && isExteriorWall
                    ? true
                    : false || (isLockedInteriorWalls && !isExteriorWall)
                    ? true
                    : false
                }
                distance={
                  new Distance({
                    value: height,
                    system: SYSTEMS.IMPERIAL,
                  })
                }
                units={distanceUnits}
                tabIndex={index * 3 + 3}
                onChange={({ distance }) => {
                  const value = distance.imperial() || 0
                  this.handleInputChange('height', getDimensionValue(value))
                }}
              />
            </Space>
          )}
          {thickness && (
            <Space>
              <DimensionInput
                label="Thickness"
                name={`wall-${index}-thickness`}
                labelWidth="75px"
                width={inputWidth}
                units={distanceUnits}
                disabled={
                  isLockedExteriorWalls && isExteriorWall
                    ? true
                    : false || (isLockedInteriorWalls && !isExteriorWall)
                    ? true
                    : false
                }
                distance={
                  new Distance({
                    value: thickness,
                    system: SYSTEMS.IMPERIAL,
                  })
                }
                onChange={({ distance }) => {
                  const value = distance.imperial() || 0
                  this.handleInputChange('thickness', getDimensionValue(value))
                }}
              />
            </Space>
          )}
          {!isExteriorWall && (
            <Space bottom="base">
              <Switch
                name="full-height"
                label="Full height"
                isChecked={isFullHeight}
                tabIndex={index * 3 + 4}
                disabled={
                  isLockedInteriorWalls && !isExteriorWall ? true : false
                }
                onClick={() => {
                  this.handleInputChange('isFullHeight', !isFullHeight)
                }}
              />
            </Space>
          )}
          {materialIndex !== undefined && (
            <Space bottom="base">
              <FormLabel>Transparency</FormLabel>
              <Range
                name="transparency"
                disabled={
                  isLockedExteriorWalls && isExteriorWall
                    ? true
                    : false || (isLockedInteriorWalls && !isExteriorWall)
                    ? true
                    : false
                }
                step={1}
                value={materialIndex}
                label={`${materialIndex * 10}%`}
                options={OPACITIES}
                onChange={event => {
                  const newOpacityIndex = parseInt(event.target.value || 0, 10)
                  const ignoreCFD = true
                  this.handleInputChange(
                    'materialIndex',
                    newOpacityIndex,
                    ignoreCFD
                  )
                }}
              />
            </Space>
          )}
        </PanelSection>
        {isExteriorWall ? (
          <MetadataSection
            objects={selectedObjects}
            onBlur={onUpdateSegments}
            disabled={isLockedExteriorWalls ? true : false}
          />
        ) : (
          <MetadataSection
            objects={selectedObjects}
            onBlur={onUpdateSegments}
            disabled={isLockedInteriorWalls ? true : false}
          />
        )}
      </>
    )
  }

  render() {
    const { selectedObjects, alignment, isTouchUI } = this.props

    return (
      <Panel
        title="Wall"
        alignment={alignment}
        scrollable
        docked
        panelKey={SELECTED_WALL_SEGMENT_PANEL}
        hasToolbar={isTouchUI}
      >
        {selectedObjects.length ? this.renderContent() : null}
      </Panel>
    )
  }
}

SelectedWallSegmentPanel.propTypes = {
  distanceUnits: PropTypes.string,
  selectedObjects: PropTypes.arrayOf(PropTypes.object),
  onUpdateSegments: PropTypes.func,
  isExteriorWall: PropTypes.bool,
  isInteriorWall: PropTypes.bool,
  isTouchUI: PropTypes.bool,
  alignment: PropTypes.string,
}

SelectedWallSegmentPanel.defaultProps = {
  alignment: 'right',
}

const mapStateToProps = ({ objects, selectedObjects, ...store }) => {
  const isExteriorWall = !!selectedObjects.find(
    obj => obj.layerKey === LAYER_KEYS.EXTERIOR_WALLS
  )

  return {
    isTouchUI: isTouchUI(store),
    units: objects.present.units,
    selectedObjects: selectedObjects
      .map(obj => get(objects, `present.segments.${obj.id}`))
      .filter(obj => obj !== undefined),
    isExteriorWall,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateSegments(segments) {
    dispatch(updateObjects(segments))
  },
})

export default compose(
  withUnits,
  appConnect(mapStateToProps, mapDispatchToProps)
)(SelectedWallSegmentPanel)
