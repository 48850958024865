import { graphql } from '~/gql'

const UPDATE_VERSION_MUTATION = graphql(`
  mutation updateVersion($id: ID!, $name: String, $data: Json) {
    updateVersion(id: $id, name: $name, data: $data) {
      ...VersionFragment
    }
  }
`)

export default UPDATE_VERSION_MUTATION
