import { graphql } from '~/gql'

const UPDATE_FACILITY_TEMPLATE_MUTATION = graphql(`
  mutation UpdateFacilityTemplate(
    $id: ID!
    $name: String!
    $description: String
    $imageUrl: String
  ) {
    updateFacilityTemplate(
      id: $id
      name: $name
      description: $description
      imageUrl: $imageUrl
    ) {
      ...FacilityTemplateFragment
    }
  }
`)

export default UPDATE_FACILITY_TEMPLATE_MUTATION
