import React, { ComponentProps } from 'react'
import styled, { ThemedStyledProps, css } from 'styled-components'
import { Link } from 'react-router-dom'

import { actionFocus, theme } from 'lib/styleUtils'
import { Theme } from '~/config/theme'

const styles = css<ThemedStyledProps<ComponentProps<"button">, Theme>>`
  background-color: transparent;
  border: 0;
  color: ${theme('colors.light.fg')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  line-height: 1;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  text-decoration: none;

  ${actionFocus()};
`

const ButtonContainer = styled.button`
  ${styles};
`

const LinkContainer = styled(Link)`
  ${styles};
`

export { ButtonContainer, LinkContainer }
