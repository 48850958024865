import { client } from '~/client'
import { GET_FAN_POWERS_QUERY } from '~/client/queries'
import { SIMULATION_TYPES } from '~/config/cfd'
import { PRODUCT_CATEGORIES } from '~/config/product'
import { UNITS } from '~/hooks/contexts/CFDUploadsContext/constants/units'
import { FormattedProducts } from '~/hooks/contexts/CFDUploadsContext/util/formatProducts'
import { ProductsUpload, Upload } from '~/hooks/contexts/CFDUploadsContext/util/types'
import { FacilityDefaults } from '~/hooks/useFacilityDefaults'
import { SYSTEMS } from '~/store/units/constants'

const { EVAP, HEAT } = PRODUCT_CATEGORIES

const getRadiantProductOrientation = (radiantProduct: FormattedProducts[0]) => {
  const { rotation, size, positionData } = radiantProduct
  const offset = size / 2
  const xRotation = rotation?.x
  const tiltValue = [45, -45].includes(xRotation) ? xRotation : 0
  const radianTiltValue = (tiltValue * Math.PI) / 180

  const orientation = {
    x: positionData.x,
    y: positionData.y,
    z: positionData.z,
    ax: 0,
    ay: 0,
    az: 0,
    nx: 0,
    ny: 0,
    nz: 0,
  }
  const yRotation = rotation?.y ?? 0
  if (yRotation === 90) {
    orientation.ay = 1
    orientation.y -= offset
    orientation.nx = -radianTiltValue
  } else if (yRotation === 180) {
    orientation.ax = -1
    orientation.x += offset
    orientation.ny = -radianTiltValue
  } else if (yRotation === 270) {
    orientation.ay = -1
    orientation.y += offset
    orientation.nx = radianTiltValue
  } else {
    orientation.ax = 1
    orientation.x -= offset
    orientation.ny = radianTiltValue
  }
  const { nx, ny, nz } = orientation
  const isRequiresTiltVectorValues = [nx, ny, nz].some(tiltVector => tiltVector !== 0)
  if (isRequiresTiltVectorValues) {
    return orientation
  } else {
    const { x, y, z, ax, ay, az } = orientation
    return { x, y, z, ax, ay, az }
  }
}

type GetFansJsonArgs = {
  formattedProducts: FormattedProducts
  facilityDefaults: FacilityDefaults
  temperatureUnits: keyof typeof SYSTEMS
}

export const getFansJson = async ({
  facilityDefaults,
  formattedProducts,
  temperatureUnits,
}: GetFansJsonArgs): Promise<ProductsUpload> => {
  const productsBySimulationType = formattedProducts.reduce<
    Map<keyof typeof SIMULATION_TYPES, FormattedProducts>
  >((simulationProducts, product) => {
    product.simulationTypes.forEach(type => {
      const products = simulationProducts.get(type) ?? []
      products.push(product)
      simulationProducts.set(type, products)
    })
    return simulationProducts
  }, new Map())

  if (productsBySimulationType.has(SIMULATION_TYPES.unitHeating)) {
    const unitHeatProducts = productsBySimulationType.get(SIMULATION_TYPES.unitHeating)
    const hasHeaterProducts = unitHeatProducts?.some(({ category }) => category === HEAT)
    if (!hasHeaterProducts) productsBySimulationType.delete(SIMULATION_TYPES.unitHeating)
  }

  const simulationTypes = Array.from(productsBySimulationType.keys())

  const uploadsEntries = await Promise.all(
    simulationTypes.map(async simulationType => {
      const relevantProducts = formattedProducts.filter(({ simulationTypes }) =>
        simulationTypes.has(simulationType)
      )
      const params = relevantProducts.map(
        ({ heightFromFloor, variationId, coolingFanSpeed, destratFanSpeed }) => ({
          variationId,
          heightFromFloor,
          speed: simulationType === SIMULATION_TYPES.cooling ? coolingFanSpeed : destratFanSpeed,
        })
      )
      const powers = await client.query({
        query: GET_FAN_POWERS_QUERY,
        variables: {
          params,
        },
      })
      const cfdProductsInput = Object.fromEntries(
        relevantProducts.reduce((productMap, currentProduct, i) => {
          const { cfdId, positionData, normals, angle, category } = currentProduct
          switch (simulationType) {
            case SIMULATION_TYPES.cooling:
            case SIMULATION_TYPES.destrat: {
              const power = powers.data?.ProductFanSpeeds?.[i]?.power ?? 100
              const product = productMap.get(cfdId) ?? { positions: [] }
              product.positions.push({
                ...positionData,
                power,
                ...normals,
              })
              productMap.set(cfdId, product)
              break
            }
            case SIMULATION_TYPES.radiantHeat: {
              const product = productMap.get(cfdId) ?? { orientations: [] }
              product.orientations.push(getRadiantProductOrientation(currentProduct))
              productMap.set(cfdId, product)
              break
            }
            case SIMULATION_TYPES.unitHeating: {
              const product = productMap.get(cfdId) ?? { positions: [] }
              if (category === HEAT) {
                product.positions.push({
                  ...positionData,
                  ...normals,
                  angle,
                })
              } else {
                const power = powers.data?.ProductFanSpeeds?.[i]?.power ?? 100
                product.positions.push({
                  ...positionData,
                  power,
                  ...normals,
                })
              }
              productMap.set(cfdId, product)
              break
            }
            default: {
              throw new Error(
                `Unexpected simulation type in prepareFacilityUploads: ${simulationType}`
              )
            }
          }
          return productMap
        }, new Map())
      )

      const isUnitHeatSimulation = simulationType === SIMULATION_TYPES.unitHeating
      const hasEvapProducts = !!relevantProducts.find(({ category }) => category === EVAP)
      const isTemperatureSimulation = isUnitHeatSimulation || hasEvapProducts
      const {
        indoorHumidity,
        indoorSummerTemp,
        indoorWinterTemp,
        indoorWinterHumidity,
      } = facilityDefaults
      const temperatureSimulationInputs = isTemperatureSimulation
        ? simulationType === SIMULATION_TYPES.cooling
          ? { humidity: indoorHumidity, temperature: indoorSummerTemp }
          : { humidity: indoorWinterHumidity, temperature: indoorWinterTemp }
        : {}

      const unitsT = temperatureUnits === SYSTEMS.IMPERIAL ? 'F' : 'C'
      const productsInput = {
        units: UNITS,
        ...(simulationType !== SIMULATION_TYPES.radiantHeat ? { unitsT } : {}),
        ...cfdProductsInput,
        ...temperatureSimulationInputs,
      }

      return [
        simulationType,
        {
          file: JSON.stringify(productsInput),
          fileName: simulationType === SIMULATION_TYPES.radiantHeat ? 'heaters.json' : 'fans.json',
          MIME: 'application/json',
        },
      ]
    })
  )
  return Object.fromEntries(uploadsEntries)
}
