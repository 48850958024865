// https://github.com/facebook/create-react-app/issues/5316#issuecomment-496292914

import React, { useState, useEffect, useMemo, createContext } from 'react'
import * as serviceWorker from './serviceWorker'

import { FACILITY_LOCAL_STORAGE_KEY } from 'client/decorators/withInitialData'

export const ServiceWorkerContext = createContext()

function ServiceWorkerProvider(props) {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null)
  const [assetsUpdateReady, setAssetsUpdateReady] = useState(false)
  const [assetsCached, setAssetsCached] = useState(false)

  const value = useMemo(
    () => ({
      assetsUpdateReady,
      assetsCached,
      // Call when the user confirm update of application and reload page
      updateAssets: () => {
        if (waitingServiceWorker) {
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
        }
      },
    }),
    [assetsUpdateReady, assetsCached, waitingServiceWorker]
  )

  useEffect(() => {
    if (waitingServiceWorker) {
      const handler = waitingServiceWorker.addEventListener(
        'statechange',
        event => {
          if (event.target.state === 'activated') {
            caches.delete('main').then(() => {
              localStorage.removeItem(FACILITY_LOCAL_STORAGE_KEY)
              window.location.reload()
            })
          }
        }
      )
      return () => {
        waitingServiceWorker.removeEventListener('statechange', handler)
      }
    }
  }, [waitingServiceWorker])

  // Once on component mounted subscribe to Update and Succes events in
  // CRA's service worker wrapper
  useEffect(() => {
    serviceWorker.register({
      onUpdate: registration => {
        setWaitingServiceWorker(registration.waiting)
        setAssetsUpdateReady(true)
      },
      onSuccess: () => {
        setAssetsCached(true)
      },
    })
  }, [])

  return <ServiceWorkerContext.Provider value={value} {...props} />
}

function useServiceWorker() {
  const context = React.useContext(ServiceWorkerContext)

  if (!context) {
    throw new Error(
      'useServiceWorker must be used within a ServiceWorkerProvider'
    )
  }

  return context
}

export { ServiceWorkerProvider, useServiceWorker }
