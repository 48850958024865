import type { FormatOptions } from '.'
import { SYSTEMS } from '../constants'
import type { UnformattedUnitData, FormattedUnitData } from '../types/unit'
import { round } from '../utils'

function formatMPS(mps: number, options: FormatOptions = {}) {
  return `${round(mps, options.round)} m/s`
}

function formatFPM(fpm: number, options: FormatOptions = {}) {
  return `${round(fpm, options.round)} ft/min`
}

export function formatVelocity({ value, system }: Omit<UnformattedUnitData, 'type'>, options: FormatOptions): string|null {
  if (value === null) {
    return options.nullValue ?? null
  }
  switch (system) {
    case SYSTEMS.IMPERIAL:
      return formatFPM(value, options)
    case SYSTEMS.METRIC:
      return formatMPS(value, options)
    default:
      return round(value, options.round).toString()
  }
}

function unformatMPS(data: string, options: FormatOptions = {}) {
  const regex = new RegExp(/(\d*\.?\d*\s*)(?:m(?:p|\/)?s(?:ec)?)?/i)
  const trimmedString = data.trim()
  const matches = trimmedString.match(regex)!

  const metersPerSecond = matches[1] ? Number(matches[1]) : 0

  return round(metersPerSecond, options.round)
}

function unformatFPM(data: string, options: FormatOptions = {}) {
  const regex = new RegExp(/(\d*\.?\d*\s*)(?:ft?(?:p|\/)?m(?:in)?)?/i)
  const trimmedString = data.trim()
  const matches = trimmedString.match(regex)!

  const feetPerMinute = matches[1] ? Number(matches[1]) : 0

  return round(feetPerMinute, options.round)
}

export function unformatVelocity({ value, system }: Omit<FormattedUnitData, 'type'>, options: FormatOptions = {}): number|null {
  switch (system) {
    case SYSTEMS.IMPERIAL:
      return unformatFPM(value, options)
    case SYSTEMS.METRIC:
      return unformatMPS(value, options)
    default:
      return round(Number(value), options.round)
  }
}
