import styled from 'styled-components'

const setTransform = ({ xFlipped, yFlipped }) => {
  const x = xFlipped ? '-1' : '1'
  const y = yFlipped ? '-1' : '1'

  return `scaleX(${x}) scaleY(${y})`
}

const Shape = styled.svg`
  display: inline-flex;
  transform: ${props => setTransform(props)};
`

export default Shape
