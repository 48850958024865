import { produce, Immutable } from "immer"
import Offset from "../components/DrawingCanvas/lib/offset";

type Vec3 = { x: number; y: number; z: number }

type Tuple<T, L extends number> = L extends L ? number extends L ? T[] : _TupleOf<T, L, []> : never
type _TupleOf<T, L extends number, R extends unknown[]> = R['length'] extends L ? R : _TupleOf<T, L, [T, ...R]>

function slidingWindow<T, L extends number>(array: T[], windowSize: L): Tuple<T, L>[] {
  return Array.from(
    { length: array.length - (windowSize - 1) },
    (_, idx) => array.slice(idx, idx+windowSize)
  ) as Tuple<T, L>[]
}
function negate({ x, y, z }: Vec3) {
  return { x: -x, y: -y, z: -z }
}
function add({ x: x1, y: y1, z: z1 }: Vec3, { x: x2, y: y2, z: z2 }: Vec3) {
  return { x: x1+x2, y: y1+y2, z: z1+z2 }
}
function sub(a: Vec3, b: Vec3) {
  return add(a, negate(b))
}
function magnitude({ x, y, z }: Vec3) {
  return Math.sqrt(x*x + y*y + z*z)
}
function vec3([ x, y, z ]: Tuple<number, 3>): Vec3 {
  return {x, y, z}
}
function arr3({ x, y, z }: Vec3): Tuple<number, 3> {
  return [x, y, z]
}
export function patchupImport(facility: any): any {
  return produce(facility, patchup)
}
function patchup(this: never, facility: Immutable<any>): void {
  for (const wallID in facility.objects) {
    const wall = facility.objects[wallID]
    // if it's not a wall we don't care
    if (wall.layerKey !== "EXTERIOR_WALLS") {
      continue
    }
    // if it's not enclosed we also don't care
    if (!wall.isEnclosed) {
      continue
    }
    const segmentIDs = wall.segments as string[]

    // search for a discontinuity
    const windows = slidingWindow(segmentIDs, 2)
    let discontinuity: [string, string] | undefined = undefined
    for (const [prevID, nextID] of windows) {
      const nextSegment = facility.segments[nextID]
      const prevSegment = facility.segments[prevID]

      if (magnitude(sub(prevSegment.endPoint, nextSegment.startPoint)) >= 0.01) {
        discontinuity = [prevID, nextID]
        break
      }
    }

    // if there's no discontinuity we don't care
    if (discontinuity === undefined) {
      continue
    }

    // we reconstruct the walls the way the DrawingCanvas/lib/wall.js does them
    const polygonPoints = [] as Vec3[]

    segmentIDs.forEach(segment => {
      polygonPoints.push(facility.segments[segment].startPoint)
    })

    const lastSegment = segmentIDs[segmentIDs.length - 1]
    if (lastSegment) {
      polygonPoints.push(facility.segments[lastSegment].endPoint)
    }

    segmentIDs.forEach((segmentID, i) => {
      const segment = facility.segments[segmentID]
      segment.startPoint = polygonPoints[i]
      segment.endPoint = polygonPoints[i+1]
    })

    // we patch up the startPoint/endPoint (that really shouldn't be being persisted)
    const offsetData = Offset.offset(
      polygonPoints,
      segmentIDs.map(id => facility.segments[id].thickness)
    )
    const { insetEdges, outsetEdges } = offsetData

    segmentIDs.forEach((segmentID, i) => {
      const segment = facility.segments[segmentID]
      segment.insetPoints.start = vec3(insetEdges[i][0])
      segment.insetPoints.end = vec3(insetEdges[i][1])
      segment.outsetPoints.start = vec3(outsetEdges[i][0])
      segment.outsetPoints.end = vec3(outsetEdges[i][1])
    })

    // now we need to clean up the roofs too
    for (const roofSectionID in facility.roofSections) {
      const roofSection = facility.roofSections[roofSectionID]
      if (roofSection.wallId !== wallID) {
        continue
      }
      const roof = facility.roofs[roofSection.roofId]

      roof.perimeterPoints = polygonPoints.map(arr3)
      roofSection.perimeterPoints = polygonPoints.map(arr3)
    }
  }
}