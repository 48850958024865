import React, { Component, ComponentProps, useEffect } from 'react'
import PropTypes from 'prop-types'
import { appConnect, useAppSelector, useAppDispatch } from "~/store/hooks";
import isEmpty from 'lodash-es/isEmpty'

import { getIconForStatusType } from 'lib/utils'

import { hideAlert } from 'store/alert'

import Icon from '../Icon'

import Action, { DivAction } from './styled/Action'
import Container from './styled/Container'
import Indicator from './styled/Indicator'
import Text from './styled/Text'
import { RootState } from '~/store'

type AlertProps = {
  text?: string
  floating?: boolean
} & ComponentProps<typeof Container>

export default Alert
export function Alert(props: AlertProps) {
  const { text = '', href = '#', type = 'default' } = props
  const autoClose = useAppSelector(state => state.alert.autoClose)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(hideAlert())
    }, 3000)
    return () => clearTimeout(timeout)
  }, [autoClose])

  return (
    <Container
      {...props}
    >
      <Indicator>
        <Icon
          name={getIconForStatusType(type).icon}
          color={getIconForStatusType(type).color}
        />
      </Indicator>
      <Text>{text}</Text>
      {!isEmpty(props.action) && (
        <Action
          href={href}
          onClick={event => props.action.onClick(event, hideAlert)}
        >
          {props.action.text}
        </Action>
      )}
      {props.closeable && isEmpty(props.action) && (
        <DivAction onClick={hideAlert}>
          <Icon name="cross" size="20" />
        </DivAction>
      )}
    </Container>
  )
}
