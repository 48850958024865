import findIndex from 'lodash-es/findIndex'

import LAYER_KEYS from './layerKeys'

export const STEPS = [
  {
    label: 'Ext. Walls',
    layerKey: LAYER_KEYS.EXTERIOR_WALLS,
    visibleLayerIds: [
      LAYER_KEYS.BACKGROUND_IMAGE,
      LAYER_KEYS.EXTERIOR_WALLS,
      LAYER_KEYS.DIMENSIONS,
    ],
  },
  {
    label: 'Roofs',
    layerKey: LAYER_KEYS.ROOFS,
    visibleLayerIds: [
      LAYER_KEYS.BACKGROUND_IMAGE,
      LAYER_KEYS.EXTERIOR_WALLS,
      LAYER_KEYS.ROOFS,
      LAYER_KEYS.ELEVATION_LINE,
      LAYER_KEYS.ELEVATION_POINT,
      LAYER_KEYS.DIMENSIONS,
    ],
  },
  {
    label: 'Int. Walls',
    layerKey: LAYER_KEYS.INTERIOR_WALLS,
    visibleLayerIds: [
      LAYER_KEYS.BACKGROUND_IMAGE,
      LAYER_KEYS.EXTERIOR_WALLS,
      LAYER_KEYS.INTERIOR_WALLS,
      LAYER_KEYS.DIMENSIONS,
    ],
  },
  {
    label: 'Ceilings',
    layerKey: LAYER_KEYS.CEILINGS,
    visibleLayerIds: [
      LAYER_KEYS.BACKGROUND_IMAGE,
      LAYER_KEYS.EXTERIOR_WALLS,
      LAYER_KEYS.INTERIOR_WALLS,
      LAYER_KEYS.CEILINGS,
      LAYER_KEYS.DIMENSIONS,
    ],
  },
  {
    label: 'Obstructions',
    layerKey: LAYER_KEYS.OBSTRUCTIONS,
    visibleLayerIds: [
      LAYER_KEYS.BACKGROUND_IMAGE,
      LAYER_KEYS.EXTERIOR_WALLS,
      LAYER_KEYS.INTERIOR_WALLS,
      LAYER_KEYS.OBSTRUCTIONS,
      LAYER_KEYS.DIMENSIONS,
    ],
  },
  {
    label: 'Zones',
    layerKey: LAYER_KEYS.COMFORT_ZONES,
    visibleLayerIds: [
      LAYER_KEYS.BACKGROUND_IMAGE,
      LAYER_KEYS.EXTERIOR_WALLS,
      LAYER_KEYS.INTERIOR_WALLS,
      LAYER_KEYS.OBSTRUCTIONS,
      LAYER_KEYS.DIMENSIONS,
    ],
  },
]

export const getStepNumber = (label: string) =>
  findIndex(STEPS, item => item.label === label) + 1

export const getStepLayerId = (index: number) => STEPS[index - 1].layerKey

export const getStepVisibleLayerIds = (index: number) => STEPS[index - 1].visibleLayerIds

export const getStepLabel = (index: number) => STEPS[index - 1].label
