import React from 'react'
import PropTypes from 'prop-types'
import { usePerformanceMetricsProps } from 'components/PerformanceMetrics/hooks/usePerformanceMetricsProps'
import { ImperialTemperature } from 'store/units/types/temperature'
import { MetricsTable } from 'components/PerformanceMetrics/subcomponents/MetricsTable'
import { Stack } from 'components/UIKit/Stack'
import InfoTooltip from 'components/UIKit/InfoTooltip'
import Heading from 'components/PerformanceMetrics/styled/Heading'
import { COVERAGE_THRESHOLDS, STILL_AIR_VELOCITY } from 'config/cfd'
import { useRadiantHeatWorker } from 'components/PerformanceMetrics/hooks/useVtkWorker'
import { SpinningIcon } from 'components/PerformanceMetrics/styled/SpinningIcon'
import { PieChart } from 'components/PerformanceMetrics/subcomponents/PieChart'
import { formatPercent } from '../util/format'
import { DissatisfiedOccupantsIcon, ThermometerIcon, PredictedMeanVoteIcon } from '../icons'
import { getSensationColor, getThermalComfort } from 'lib/thermalComfortTool/index'
import { IntensityInterpolator } from 'components/PerformanceMetrics/util/interpolateHeatIntensity'
import Icon from 'components/UIKit/Icon/Icon'
import { useFormatTemperature } from 'components/PerformanceMetrics/hooks/useFormatTemperature'

const legend = {
  header: null,
  cells: [
    { content: 'Heating Coverage' },
    {
      content: (
        <Stack direction="row" spacing={1}>
          <InfoTooltip title="Heat intensity on a scale of 1-10" iconSize="16px" />
          <Heading>Average Heat Intensity</Heading>
        </Stack>
      ),
    },
    { icon: <ThermometerIcon />, content: 'Mean Radiant Temp' },
    { icon: <ThermometerIcon />, content: 'Mean Air Temp' },
    { content: 'Thermal Sensation' },
    { content: 'Operative Temperature' },
    {
      icon: <PredictedMeanVoteIcon />,
      content: 'PMV',
    },
    {
      icon: <DissatisfiedOccupantsIcon />,
      content: 'PPD',
    },
  ],
}

const useNoHeatersMetrics = () => {
  const { formatTemperature } = useFormatTemperature()
  const { facility } = usePerformanceMetricsProps()
  const { activityLevel, winterClothingType, indoorWinterHumidity, indoorWinterTemp } = facility
  const defaultAirTemp = new ImperialTemperature(indoorWinterTemp)
  const { pmv, ppd, sensation } = getThermalComfort({
    airVelocity: STILL_AIR_VELOCITY,
    humidity: indoorWinterHumidity,
    meanAirTemp: defaultAirTemp,
    clothingType: winterClothingType,
    metabolicRate: activityLevel,
  })
  const formattedTemperature = formatTemperature(defaultAirTemp)
  return {
    heatingCoverage: 0,
    averageHeatIntensity: '---',
    meanAirTemperature: formattedTemperature,
    meanRadiantTemperature: formattedTemperature,
    operativeTemperature: formattedTemperature,
    sensation,
    sensationColor: getSensationColor(sensation),
    pmv: pmv.toFixed(2),
    ppd: ppd.toFixed(2) + '%',
  }
}

const useWithHeatersMetrics = ({ intensityUrl }) => {
  const { formatTemperature } = useFormatTemperature()
  const { areaOfConcernEdges, facility } = usePerformanceMetricsProps()
  const radiantHeat = useRadiantHeatWorker({
    intensityUrl,
    coverageThreshold: COVERAGE_THRESHOLDS.intensity,
    comfortZoneEdges: areaOfConcernEdges,
  })
  if (radiantHeat.loading) {
    return { loading: true, error: undefined, data: undefined }
  } else if (radiantHeat.error || !radiantHeat.data) {
    return { loading: false, error: radiantHeat.error, data: undefined }
  } else {
    const { averageIntensity, coverage } = radiantHeat.data
    const { indoorWinterHumidity, indoorWinterTemp, winterClothingType, activityLevel } = facility
    const { scaleMeanRadiantTemp, scaleMeanAirTemp } = new IntensityInterpolator(averageIntensity)
    const meanAirTemp = new ImperialTemperature(scaleMeanAirTemp(indoorWinterTemp))
    const radiantTemperature = new ImperialTemperature(scaleMeanRadiantTemp(indoorWinterTemp))
    const operativeTemperature = new ImperialTemperature(
      (indoorWinterTemp + radiantTemperature.value) / 2
    )

    const { pmv, ppd, sensation } = getThermalComfort({
      airVelocity: STILL_AIR_VELOCITY,
      humidity: indoorWinterHumidity,
      meanAirTemp: meanAirTemp,
      meanRadiantTemp: radiantTemperature,
      clothingType: winterClothingType,
      metabolicRate: activityLevel,
    })
    const data = {
      heatingCoverage: coverage,
      averageHeatIntensity: averageIntensity.toFixed(2),
      meanAirTemperature: formatTemperature(meanAirTemp),
      meanRadiantTemperature: formatTemperature(radiantTemperature),
      operativeTemperature: formatTemperature(operativeTemperature),
      sensation,
      sensationColor: getSensationColor(sensation),
      pmv: pmv.toFixed(2),
      ppd: ppd.toFixed(2) + '%',
    }
    return {
      loading: false,
      error: undefined,
      data,
    }
  }
}

/**
 * @typedef {import('hooks/useFacilityVtkResults').vtkResult} vtkFiles
 * @typedef {object} props
 * @prop {vtkFiles} vtkFiles
 * @param {props} props
 */
const RadiantHeatResults = ({ vtkFiles: { vtkUrlGroups } }) => {
  const noHeatersMetrics = useNoHeatersMetrics()
  const withHeatersMetrics = useWithHeatersMetrics({ intensityUrl: vtkUrlGroups.intensity })

  const noHeaters = {
    header: 'No Heaters',
    cells: [
      {
        icon: <PieChart percentComplete={noHeatersMetrics.heatingCoverage} />,
        content: formatPercent(noHeatersMetrics.heatingCoverage),
      },
      { content: '---' },
      { content: noHeatersMetrics.meanRadiantTemperature },
      { content: noHeatersMetrics.meanAirTemperature },

      {
        content: (
          <Heading color={noHeatersMetrics.sensationColor}>{noHeatersMetrics.sensation}</Heading>
        ),
      },
      { content: noHeatersMetrics.operativeTemperature },
      { content: noHeatersMetrics.pmv },
      { content: noHeatersMetrics.ppd },
    ],
  }

  const withHeaters = {
    header: 'With Heaters',
    cells: withHeatersMetrics.loading
      ? [{ content: <SpinningIcon name="refresh" size="20" /> }]
      : withHeatersMetrics.error || !withHeatersMetrics.data
      ? [
          {
            icon: <Icon name="warn" size="20" />,
            content: withHeatersMetrics.error?.message || 'Failed to calculate metrics',
          },
        ]
      : [
          {
            icon: <PieChart percentComplete={withHeatersMetrics.data.heatingCoverage} />,
            content: formatPercent(withHeatersMetrics.data.heatingCoverage),
          },
          { content: withHeatersMetrics.data.averageHeatIntensity },
          { content: withHeatersMetrics.data.meanRadiantTemperature },
          { content: withHeatersMetrics.data.meanAirTemperature },
          {
            content: (
              <Heading color={withHeatersMetrics.data.sensationColor}>
                {withHeatersMetrics.data.sensation}
              </Heading>
            ),
          },
          { content: withHeatersMetrics.data.operativeTemperature },
          { content: withHeatersMetrics.data.pmv },
          { content: withHeatersMetrics.data.ppd },
        ],
  }
  return <MetricsTable columns={[legend, noHeaters, withHeaters]} />
}

RadiantHeatResults.propTypes = {
  vtkFiles: PropTypes.object,
}

export { RadiantHeatResults }
