import styled from 'styled-components'

const Container = styled.div`
  touch-action: none;
  display: ${props => (props.open ? 'block' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  ::before {
    opacity: 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    transition: opacity 0.15s linear;
  }
`

export default Container
