import store from 'store'

export function activeTool(state = store.getState()) {
  const { activeTool } = state.tools
  return activeTool
}

export function activeToolCategory(state = store.getState()) {
  const { activeToolCategory } = state.tools
  return activeToolCategory
}

export function isSnapEnabled(state = store.getState()) {
  const { isSnapEnabled } = state.tools
  return isSnapEnabled
}

export function isContinuousModeEnabled(state = store.getState()) {
  const { isContinuousModeEnabled } = state.tools
  return isContinuousModeEnabled
}

export function isContinuousModeActive(state = store.getState()) {
  const { isContinuousModeEnabled } = state.tools
  const continuousTools = ['PRODUCT_TOOL', 'OBSTRUCTION_TOOL', 'DOOR_TOOL']
  const isContinuousTool = continuousTools.includes(state.tools.activeTool)

  return isContinuousModeEnabled && isContinuousTool
}

export function isOrthoModeEnabled(state = store.getState()) {
  const { isOrthoModeEnabled } = state.tools
  return isOrthoModeEnabled
}
