import get from 'lodash-es/get'
import groupBy from 'lodash-es/groupBy'
import map from 'lodash-es/map'
import some from 'lodash-es/some'

import { formatNumberDigits } from 'lib/utils'

export function productGroups(products) {
  const groupedProducts = groupBy(
    products,
    product => `${product.variationId}_${product.voltageId}`
  )
  const indexedProducts = map(groupedProducts, group => group[0])

  return { groupedProducts, indexedProducts }
}

export function productTag(productIndex) {
  if (typeof productIndex !== 'number') return ''

  return formatNumberDigits(productIndex.toString())
}

function singleDigit(n) {
  return parseFloat(parseFloat(n).toFixed(1))
}

export function description(variation) {
  const model = get(variation, 'product.model')
  const voltage = (get(variation, 'voltage') || '').replace(
    /\u2013|\u2014/g,
    '-'
  ) // Remove weird dashes
  const sizeInInches = singleDigit(get(variation, 'size'))
  const modelsInInches = [
    /haiku/gi,
    /sweat.*bee/gi,
    /aireye/gi,
    /yellow.*jacket/gi,
  ]

  let size = `${singleDigit(sizeInInches / 12)}'`
  if (some(modelsInInches.map(m => m.exec(model)))) size = `${sizeInInches}"`

  return `${size} ${model} ${voltage}`
}
