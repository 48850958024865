import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InfoTooltip from 'components/UIKit/InfoTooltip'
import { CFD_FILE_PROPS_TYPE } from 'config/cfd'
import routes from 'config/routes'
import theme from 'config/theme'
import { MainImage } from '../styled/MainImage'
import { Range, RangeContainer } from '../styled/Range'
import { Stack } from 'components/UIKit/Stack'

const DEFAULT_SLICE = 0
const DEFAULT_AIRFLOW_INDEX = 0

export const UnitHeatImageDisplay = ({ imageGroup: { unitHeatImages, view } }) => {
  const [airflowIndex, setAirflowIndex] = useState(DEFAULT_AIRFLOW_INDEX)
  const [slice, setSlice] = useState(DEFAULT_SLICE)

  const safeSliceIndex = Math.min(slice, unitHeatImages.length - 1)
  const selectedGroup = unitHeatImages[safeSliceIndex]
  const selectedImg = selectedGroup?.airflowImgs[airflowIndex]
  const sliceLabel =
    selectedImg?.fileProps.type === CFD_FILE_PROPS_TYPE.overhead
      ? `${selectedGroup?.slice}"`
      : selectedGroup?.slice.toString()

  const handleAirflowChange = ({ target }) => {
    setAirflowIndex(+target.value)
  }
  const handleSliceChange = ({ target }) => {
    setAirflowIndex(DEFAULT_AIRFLOW_INDEX)
    setSlice(+target.value)
  }

  return (
    <>
      <MainImage src={selectedImg?.url} />
      <RangeContainer>
        <Stack direction="row" spacing={1}>
          <Range
            title="Total Air Turnovers"
            disabled={selectedGroup?.airflowImgs?.length === 1}
            options={selectedGroup?.airflowImgs}
            step={1}
            value={airflowIndex.toString()}
            onChange={handleAirflowChange}
            label={selectedImg?.turnover}
          />
          <Range
            title="Slice"
            options={unitHeatImages}
            step={1}
            value={safeSliceIndex.toString()}
            onChange={handleSliceChange}
            label={sliceLabel}
            endAdornment={
              <InfoTooltip title="Learn More" url={routes.faq} color={theme.colors.light.fg} />
            }
          />
        </Stack>
      </RangeContainer>
    </>
  )
}

UnitHeatImageDisplay.propTypes = {
  imageGroup: PropTypes.shape({
    view: PropTypes.string,
    unitHeatImages: PropTypes.arrayOf(
      PropTypes.shape({
        slice: PropTypes.number,
        airflowImgs: PropTypes.arrayOf(PropTypes.object),
      })
    ),
  }),
}
