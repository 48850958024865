import styled, { css } from 'styled-components'

import { theme } from 'lib/styleUtils'

const active = ({ isActive, flush, index, theme }) => {
  if (isActive) {
    if (flush) {
      const first = index === 0
      return css`
        border: 1px solid ${theme.colors.light.subdued};
        border-bottom-color: ${theme.colors.light.bg};
        ${first && `border-left-color: transparent;`};
        border-top-color: transparent;
      `
    }

    return css`
      border: 1px solid ${theme.colors.light.subdued};
      border-bottom-color: ${theme.colors.light.bg};
      border-top-left-radius: ${theme.radius.base};
      border-top-right-radius: ${theme.radius.base};
    `
  }
}

const padding = ({ size, theme }) => {
  if (size === 's') {
    return `padding: ${theme.spacing.s};`
  }

  return `padding: ${theme.spacing.s} ${theme.spacing.base};`
}

const Link = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  color: ${theme('colors.light.fg')};
  display: block;
  font-size: ${theme('fonts.sizes.s')};
  font-weight: bold;
  margin-bottom: -1px;

  ${padding};

  /* Needs to be handled this way for *only* mouse interactions */
  &:focus {
    outline: none;
  }

  ${active};
`

export default Link
