import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/UIKit/Button'
import Checkbox from 'components/UIKit/Checkbox'
import Flex, { FlexItem } from 'components/UIKit/Flex'
import HR from 'components/UIKit/HR'
import Icon from 'components/UIKit/Icon'
import Select from 'components/UIKit/Select'

import AddComfortZoneButton from './styled/AddComfortZoneButton'
import { useFacilityVtkResults } from 'hooks/useFacilityVtkResults'
import { useAppSelector } from "~/store/hooks";
import { SpinningIcon } from 'components/PerformanceMetrics/styled/SpinningIcon'
import { COMPOSITE_SIMULATION_TYPES, HEIGHTS, SIMULATION_TYPES } from 'config/cfd'
import { camelToTitleCase } from 'utils/string'
import VariantText from 'components/UIKit/VariantText'
import { IconButton } from 'components/Modals/ExportPDFLayoutModal/Wizard/styled/IconButton'
import InfoTooltip from 'components/UIKit/InfoTooltip'

const Loader = () => <SpinningIcon name="refresh" size="20" />

const SETTINGS = {
  coolingCoverage: 'coolingCoverage',
  coolingEffect: 'coolingEffect',
  includeAirTemp: 'includeAirTemp',
  includeSpeed: 'includeSpeed',
  pmvScale: 'pmvScale',
  includeHeatIntensity: 'includeHeatIntensity',
  includeMRT: 'includeMRT',
}

const HEIGHT_OPTIONS = Object.values(HEIGHTS).map(height => ({
  label: camelToTitleCase(height),
  value: height,
}))

const goalConfig = {
  [SIMULATION_TYPES.cooling]: {
    settings: {
      [SETTINGS.includeSpeed]: { label: 'Air Velocity', defaultChecked: true },
      [SETTINGS.includeAirTemp]: { label: 'Air Temp', defaultChecked: true },
      [SETTINGS.coolingCoverage]: { label: 'Cooling Coverage', defaultChecked: true },
      [SETTINGS.coolingEffect]: { label: 'Cooling Effect', defaultChecked: false },
      [SETTINGS.pmvScale]: { label: 'PMV Scale', defaultChecked: false },
    },
    heightOptions: HEIGHT_OPTIONS,
  },
  [SIMULATION_TYPES.destrat]: {
    settings: {
      [SETTINGS.includeSpeed]: { label: 'Air Velocity', defaultChecked: true },
      [SETTINGS.includeAirTemp]: { label: 'Air Temp', defaultChecked: false },
      [SETTINGS.coolingCoverage]: { label: 'Destrat %', defaultChecked: true }, // TODO: @refector cooling coverage should not be used to represent other values
    },
    heightOptions: [{ label: 'Ceiling', value: HEIGHTS.standing }], // TODO: @refactor destrat should not be using standing as a height
  },
  [SIMULATION_TYPES.radiantHeat]: {
    settings: {
      [SETTINGS.includeAirTemp]: { label: 'Air Temp', defaultChecked: true },
      [SETTINGS.coolingCoverage]: { label: 'Heating Coverage', defaultChecked: true }, // TODO: @refector cooling coverage should not be used to represent other values
      [SETTINGS.pmvScale]: { label: 'PMV Scale', defaultChecked: true },
      [SETTINGS.includeMRT]: { label: 'Mean Radiant Temp', defaultChecked: true },
      [SETTINGS.includeHeatIntensity]: { label: 'Intensity', defaultChecked: true },
    },
    heightOptions: [],
  },
  [SIMULATION_TYPES.unitHeating]: {
    settings: {
      [SETTINGS.coolingCoverage]: { label: 'Heating Coverage', defaultChecked: true }, // TODO: @refector cooling coverage should not be used to represent other values
      [SETTINGS.includeAirTemp]: { label: 'Air Temp', defaultChecked: true },
      [SETTINGS.pmvScale]: { label: 'PMV Scale', defaultChecked: false },
    },
    heightOptions: HEIGHT_OPTIONS,
  },
  [COMPOSITE_SIMULATION_TYPES.heatingComposite]: {
    settings: {
      [SETTINGS.coolingCoverage]: { label: 'Heating Coverage', defaultChecked: true }, // TODO: @refector cooling coverage should not be used to represent other values
      [SETTINGS.includeAirTemp]: { label: 'Air Temp', defaultChecked: true },
      [SETTINGS.pmvScale]: { label: 'PMV Scale', defaultChecked: false },
      [SETTINGS.includeMRT]: { label: 'Mean Radiant Temp', defaultChecked: true },
      [SETTINGS.includeHeatIntensity]: { label: 'Intensity', defaultChecked: true },
    },
    heightOptions: HEIGHT_OPTIONS,
  },
}

const ComfortZones = ({ selectedZones, updateZones }) => {
  const { data, error, loading } = useFacilityVtkResults()
  const comfortZones = useAppSelector(state => state?.objects?.present?.comfortZones)
  const areaOfConcernOptions = [{ label: 'Facility', value: 'facility' }].concat(
    Object.values(comfortZones).map(({ name, id }, i) => ({
      label: name || `Zone ${i + 1}`,
      value: id,
    }))
  )

  const addZone = goal => {
    const defaultAreaOfConcern = areaOfConcernOptions[0]
    const defaultZoneConfig = goalConfig[goal]
    if (!defaultZoneConfig || !defaultAreaOfConcern) return
    const zoneSettingsEntries = Object.entries(
      defaultZoneConfig.settings
    ).map(([setting, { defaultChecked }]) => [setting, defaultChecked])
    const settings = Object.fromEntries(zoneSettingsEntries)
    const height = defaultZoneConfig.heightOptions[0]?.value
    const { label, value } = defaultAreaOfConcern
    const zone = { name: label, id: value }
    updateZones(prev => [...prev, { goal, height, zone, settings }])
  }

  const removeZone = index => {
    updateZones(prev => prev.filter((_, i) => i !== index))
  }

  const updateZone = (index, updates) => {
    updateZones(prev => prev.map((zone, i) => (i === index ? { ...zone, ...updates } : zone)))
  }

  const updateSelectedGoal = (index, goal) => {
    const defaults = goalConfig[goal]
    if (!defaults) return
    const { heightOptions, settings } = defaults
    const height = heightOptions[0]?.value ?? HEIGHTS.standing
    const settingEntries = Object.entries(settings)
    const defaultSettings = settingEntries.map(([key, { defaultChecked }]) => [key, defaultChecked])
    updateZone(index, { goal, height, settings: Object.fromEntries(defaultSettings) })
  }

  const updateAreaOfConcern = (index, zoneId) => {
    const newZone = areaOfConcernOptions.find(({ value }) => value === zoneId)
    if (!newZone) return
    const zone = { id: newZone.value, name: newZone.label }
    updateZone(index, { zone })
  }

  const updateZoneSetting = (index, updates) => {
    updateZones(prev =>
      prev.map((zone, i) =>
        i === index ? { ...zone, settings: { ...zone.settings, ...updates } } : zone
      )
    )
  }

  const copyToAllZones = selectedZone => {
    const { zone, ...zoneSettings } = selectedZone
    const copiedZones = areaOfConcernOptions
      .filter(({ value }) => zone.id !== value)
      .map(({ value, label }) => ({
        zone: { id: value, name: label },
        ...zoneSettings,
      }))
    updateZones(() => [selectedZone, ...copiedZones])
  }

  if (loading) {
    return (
      <Flex flexDirection="row" justifyContent="center" gap={1}>
        <Loader />
        <VariantText>Loading...</VariantText>
      </Flex>
    )
  } else if (error) {
    return (
      <Flex flexDirection="row" justifyContent="center" gap={1}>
        <Icon name="warn" size="20" />
        <VariantText>Failed to get CFD results</VariantText>
      </Flex>
    )
  } else if (!data.vtkResults.length) {
    return (
      <Flex flexDirection="row" justifyContent="center" gap={1}>
        <VariantText>Please run CFD analysis in order to view area metrics.</VariantText>
      </Flex>
    )
  }

  const goalOptions = data.vtkResults
    .map(({ type }) => ({ value: type, label: camelToTitleCase(type) }))
    .sort((a, b) => a.value.localeCompare(b.value))

  return (
    <Flex flexDirection="column" alignItems="center">
      <FlexItem width="600px">
        {selectedZones.length ? (
          selectedZones.map((selectedZone, i) => {
            const { goal, height, zone, settings } = selectedZone
            const goalDefaults = goalConfig[goal]
            const currentSettings = Object.entries(
              goalDefaults.settings
            ).map(([setting, { defaultChecked }]) => [setting, settings[setting] ?? defaultChecked])
            const heightOptions = goalDefaults?.heightOptions ?? []
            return (
              <Flex key={`${goal}-${i}`} flexDirection="column" gap={1.5} marginBottom="base">
                <Flex flexDirection="row" alignItems="center" gap={1}>
                  <Select
                    label="Select Goal"
                    value={goal}
                    onChange={e => updateSelectedGoal(i, e.target.value)}
                    options={goalOptions}
                  />
                  <Select
                    label="Area of Concern"
                    value={zone.id}
                    onChange={e => updateAreaOfConcern(i, e.target.value)}
                    options={areaOfConcernOptions}
                  />
                  <Select
                    disabled={heightOptions.length <= 1}
                    label="Select Level"
                    value={height}
                    onChange={e => updateZone(i, { height: e.target.value })}
                    options={heightOptions}
                  />
                  <IconButton name="cross" size="75" onClick={() => removeZone(i)} />
                </Flex>
                <Flex flexDirection="row" alignItems="center" gap={1}>
                  {currentSettings.map(([setting, checked], settingsIndex) => {
                    const label = goalDefaults?.settings?.[setting]?.label ?? ''
                    return (
                      <Checkbox
                        key={settingsIndex}
                        label={label}
                        checked={checked}
                        onChange={() => updateZoneSetting(i, { [setting]: !checked })}
                      />
                    )
                  })}
                  {i === 0 && (
                    <Flex flexDirection="row" alignItems="center" gap={0.25}>
                      <Button label="Copy" onClick={() => copyToAllZones(selectedZone)} />
                      <InfoTooltip title="Copy settings to each zone" url="" iconSize="16" />
                    </Flex>
                  )}
                </Flex>
                <HR />
              </Flex>
            )
          })
        ) : (
          <Flex justifyContent="center" alignItems="center" gap={1}>
            <Select
              placeholder="--"
              label="Select Goal"
              onChange={e => addZone(e.target.value)}
              options={goalOptions}
              disablePlaceholder={false}
              required={true}
            />
            <Select options={[]} disabled label="Area of Concern" />
            <Select options={[]} disabled label="Select Level" />
          </Flex>
        )}
        <AddComfortZoneButton
          noBorder
          responsive
          label="Add Another Area"
          icon="plus"
          onClick={() => addZone(goalOptions[0]?.value)}
        />
      </FlexItem>
    </Flex>
  )
}

ComfortZones.propTypes = {
  selectedZones: PropTypes.array,
  updateZones: PropTypes.func,
}

export default ComfortZones
