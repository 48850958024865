import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Flex from 'components/UIKit/Flex'

const Container = styled(Flex)`
  align-items: center;
  background-color: ${theme('colors.dark.bg')};
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
`

export default Container
