import defaultTo from 'lodash-es/defaultTo'
import get from 'lodash-es/get'
import Util from './util'
import Units from './units'

import Primitives from './primitives'
import Facility from './facility'
import getRenderOrder from 'config/canvasRenderOrder'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'

import * as THREE from 'three'

const HEAT_MAP_OPACITY = 0.4

class HeatMap {
  constructor(heatMap, units, colors) {
    this.wall = Facility.current.findObjectWithId(
      heatMap?.selectedLayer?.objectId
    )
    this.units = units
    this.colors = colors

    const model = this.createModel(heatMap)
    this.className = CLASS_NAMES.HEAT_MAP
    this.id = model.id
    this.layerKey = defaultTo(model.category, model.layerKey)
    this.height = Units.unitsToNative(units, model.evaluationHeight)
    let mockHeatMapPowers = []
    for (let i = 0; i < 34; i++) {
      let mockHeatMapPowersSubArray = []
      for (let j = 0; j < 68; j++) {
        mockHeatMapPowersSubArray.push(45)
      }
      mockHeatMapPowers.push(mockHeatMapPowersSubArray)
    }
    this.powers = model.powers || mockHeatMapPowers
    this.gridSize = heatMap.gridSize

    this.obj3d = this.getHeatMapMesh()

    if (this.wall.obj3d) {
      this.obj3d.position.copy(this.wall.obj3d.position)
    } else {
      this.obj3d.position.x = -444.4444444444445
      this.obj3d.position.y = 221.26624767102444
    }
    this.obj3d.position.z = this.height
    this.obj3d.layerKey = this.layerKey

    // Used by Interactifier
    this.selectable = false
    this.draggable = false
    this.obj3d.wrapperId = this.id
    this.obj3d.renderOrder = getRenderOrder('heatMap')
  }

  getColorData() {
    const rootArrayLength = this.powers.length
    const nestedArrayLength = this.powers[0].length
    const numVertices = rootArrayLength * nestedArrayLength

    const colors = new Float32Array(numVertices * 3) // r, g, b = 3;

    let n = 0

    const addColors = c => {
      colors[n++] = c.r
      colors[n++] = c.g
      colors[n++] = c.b
    }

    for (let i = 0; i < rootArrayLength; ++i) {
      const powersArray = this.powers[i]
      for (let j = 0; j < nestedArrayLength; ++j) {
        const power = powersArray[j]
        const color = HeatMap.getHeatPowerColor(power, this.colors)

        addColors(color)
      }
    }

    return colors
  }

  static getHeatPowerColor(power, colors) {
    let j = 0
    // If the power is lower than the min, set its color to the minimum
    if (colors[j].power >= power) return new THREE.Color(colors[j].color)
    // Traverse  the colors array to find the two colors the point is between
    for (let i = 1; i < colors.length; j = i++) {
      if (power > colors[j].power && power < colors[i].power) {
        // Find the percentage the power is between the two colors
        let percent =
          (power - colors[j].power) / (colors[i].power - colors[j].power)
        // Return the gradient of the two colors
        return new THREE.Color(
          Util.getGradientColor(colors[j].color, colors[i].color, percent)
        )
      } else if (power === colors[i].power) {
        return new THREE.Color(colors[i].color)
      }
    }
    // If we did not return a color, make sure the power isn't higher than the maximum
    // If it is, return the color of the maximum
    if (power > colors[colors.length - 1].power) {
      return new THREE.Color(colors[colors.length - 1].color)
    }
    return new THREE.Color(colors[0].color)
  }

  getHeatMapMesh() {
    const vertexShader = `
      varying vec2 vUv;

      void main() {
        vUv = uv;

        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
        gl_Position = projectionMatrix * mvPosition;
      }
    `

    const fragmentShader = `
      uniform float opacity;
      uniform sampler2D texture1;

      varying vec2 vUv;

      void main() {
        vec4 color = texture2D(texture1, vUv);
        gl_FragColor = vec4(color.rgb, opacity);
      }
    `

    const colors = this.getColorData()

    const texture = new THREE.DataTexture(
      colors,
      this.powers[0].length,
      this.powers.length,
      THREE.RGBFormat,
      THREE.FloatType
    )
    texture.magFilter = THREE.LinearFilter
    texture.needsUpdate = true

    const uniforms = {
      opacity: { value: HEAT_MAP_OPACITY },
      texture1: { value: texture },
    }

    const material = new THREE.ShaderMaterial({
      uniforms,
      vertexShader,
      fragmentShader,
      side: THREE.FrontSide,
      transparent: true,
    })

    const insetPointsArray = get(this.wall, 'insetPoints', [])
    const mesh = Primitives.getRegionMesh(insetPointsArray, null, material)
    const insetPoints = insetPointsArray.map(Util.arrayPointToObjectPoint)
    const boundingRect = Util.boundingRectForPoints(insetPoints)
    const positions = mesh.geometry.attributes.position
    const uvs = new Float32Array(positions.count * 2)
    let i = 0
    for (let j = 0; j < positions.count; ++j) {
      const x = positions.array[j * 3]
      const y = positions.array[j * 3 + 1]
      uvs[i++] = (x - boundingRect.x) / boundingRect.width
      uvs[i++] = (y - boundingRect.y) / boundingRect.height
    }

    mesh.geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2))

    return mesh
  }

  /*
    SceneBuilder event
  */
  visibilityChanged(visible) {
    if (this.obj3d) this.obj3d.visible = visible
  }

  createModel(heatMap) {
    return {
      id: Util.guid(),
      layerKey: LAYER_KEYS.HEAT_MAP,
      ...heatMap?.data?.[0],
    }
  }

  // ///
  // Interactable methods
  // ///

  drop() {}

  drag() {}

  snap() {}

  destroy() {}
}

export default HeatMap
