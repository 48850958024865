import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { appConnect } from "~/store/hooks";
import { Line } from 'rc-progress'
import * as filestack from 'filestack-js'
import get from 'lodash-es/get'
import last from 'lodash-es/last'
import isEmpty from 'lodash-es/isEmpty'
import PropTypes from 'prop-types'

import routes from 'config/routes'
import { getTitle } from 'config/titles'
import theme from 'config/theme'
import { addBackgroundImage } from 'store/objects'
import FILESTACK_API_KEY from 'config/filestack'
import LAYER_KEYS from 'config/layerKeys'

import Space from 'components/UIKit/Space'
import Alert from 'components/UIKit/Alert'
import Modal from 'components/UIKit/Modal'
import Container from './styled/Container'

const CLOUD_CONVERT_API_KEY =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZDBkYmYyNWRmNWM0NWY0MTMxN2Q0NzA0YjJjZDQ3ZjY1ZmE1MzA3MjU1NDk2NjM5N2ZjMDI5NzJhYjcwOTIyOGQ0NTcwZmExMjhkZDk4YjYiLCJpYXQiOjE2NDEyMjU5NzIuNjQ5MzM1LCJuYmYiOjE2NDEyMjU5NzIuNjQ5MzM3LCJleHAiOjQ3OTY4OTk1NzIuNjQxMDczLCJzdWIiOiIyOTI2MTYwOSIsInNjb3BlcyI6WyJ1c2VyLnJlYWQiLCJ1c2VyLndyaXRlIiwidGFzay5yZWFkIiwidGFzay53cml0ZSIsIndlYmhvb2sucmVhZCIsIndlYmhvb2sud3JpdGUiLCJwcmVzZXQucmVhZCIsInByZXNldC53cml0ZSJdfQ.D7_q27UHVgBO1c_8n_1FHtjDwDORA6V9jukWwXnj-veXYivQVcyVBpSNbaVhFrcI8OA_f5m6a6bV-sfKOuxlWxUoMeMpburMmPySpxPjqxCsQuzRxaxE2Z7EkDVtIPZ5jOvHG7Lw7N7Lhp87a7vJvMG2zMYOxG5f1PeUSMD838lDyOSv0c7SRfOrl4UCM3cxqtl5QJ3l24y0SdfqA8wkW-VwxTTUP4whRSCj9KP9Qhcmmznu3sLiSGRPfABfGrJUT-M8KKhepb3-zPp0X4JZ19N32B8T1vmn_4IoP9gwAOimUjzdhwtDO6Dnbk5Yap26_1xpOri3OKfOMNCR4V8sf6ThwTu6ezwvD5a7XydylwF7Gtogg3ymzRbpKxFmxoaUnzHChJcxwEXxaUkTqHc69FKeHS4pYrp9yCvOFZgBZFoMZI2hy36oJcdCF-tHFD_gCUB-5TirvixV2Xu4x_SdS4gK05e0dbPW19IwaB-0ZngLKolLF8jNYqd4Jpsop3ZKR8RxnAyY0umZeb2k_Hn6umXWcjGZYl2BKhSSC4f5azXGB5b4_bXGC4eRV4WY8k3nJRrV0PwS5g_risOs40AuxX7WOP37Sz0xRi_3TjJ-jCNwOUBF8Wu7hoq80YMCqM5JtSBzuoa6m5D_oN1J58EKDhRsWNosuSJorqv0tczwhnM'

class ConvertImageModal extends Component {
  state = {
    isProcessing: true,
    percent: 0,
    message: 'Preparing file…',
    error: '',
  }

  static propTypes = {
    addBackgroundImage: PropTypes.func,
    backgroundImageForm: PropTypes.object,
    parentRoute: PropTypes.string,
    history: PropTypes.object,
    props: PropTypes.object,
  }

  componentDidMount() {
    this.checkStatusInterval = null
    this.client = filestack.init(FILESTACK_API_KEY)

    if (!isEmpty(this.props.backgroundImageForm)) {
      this.handleCreateJob()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      isEmpty(prevProps.backgroundImageForm) &&
      !isEmpty(this.props.backgroundImageForm)
    ) {
      this.handleCreateJob()
    }
  }

  handleCreateJob = () => {
    const { backgroundImageForm } = this.props
    const filename = get(this.props, 'backgroundImageForm.filename')
    if (isEmpty(filename)) {
      this.setState({
        error: 'Nothing to convert',
        isProcessing: false,
      })
      return
    }

    fetch('https://api.cloudconvert.com/v2/jobs', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${CLOUD_CONVERT_API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tasks: {
          import_file: {
            operation: 'import/url',
            url: backgroundImageForm.src,
          },
          convert_file: {
            operation: 'convert',
            input: ['import_file'],
            input_format: last(filename.split('.')),
            output_format: 'png',
          },
          export_file: {
            operation: 'export/url',
            input: ['convert_file'],
          },
        },
        tag: 'speclab-convert',
      }),
    })
      .then(res => res.json())
      .then(({ data }) => {
        this.setState(
          {
            jobId: data.id,
          },
          () => {
            this.checkStatusInterval = setInterval(this.handleWaitJob, 5000)
          }
        )
      })
      .catch(error => {
        this.setState({ error: error.message, isProcessing: false })
      })
  }

  handleWaitJob = () => {
    const { jobId } = this.state

    fetch(`https://sync.api.cloudconvert.com/v2/jobs/${jobId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${CLOUD_CONVERT_API_KEY}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(({ data }) => {
        if (data.status === 'finished') {
          const exportTask = data.tasks.find(task =>
            task.operation.match('export')
          )
          if (exportTask.result.files.length > 0) {
            const fileUrl = exportTask.result.files[0].url
            this.setState(
              {
                percent: 100,
                message: 'Conversion complete!',
                fileUrl,
              },
              () => {
                clearInterval(this.checkStatusInterval)
                this.handleUpload()
              }
            )
          }
        } else if (data.status === 'error') {
          this.setState({
            isProcessing: false,
            error: 'Conversion error! Please submit a ticket.',
          })
        }
      })
      .catch(error => {
        this.setState({ error: error.message, isProcessing: false })
      })
  }

  handleUpload = () => {
    const { fileUrl } = this.state

    if (isEmpty(fileUrl)) {
      this.setState({
        error: 'Nothing to upload',
        isProcessing: false,
      })
      return
    }

    this.client
      .storeURL(fileUrl)
      .then(file => {
        this.client
          .metadata(file.handle, { width: true, height: true })
          .then(res => {
            this.handleSave({
              src: file.url,
              mimetype: file.mimetype,
              width: res.width,
              height: res.height,
              filename: file.filename,
            })
          })
          .catch(error => {
            this.setState({ error: error.message, isProcessing: false })
          })
      })
      .catch(error => {
        this.setState({ error: error.message, isProcessing: false })
      })
  }

  handleSave = ({ src, width, height }) => {
    this.props.addBackgroundImage({
      backgroundImage: {
        BACKGROUND_IMAGE: {
          src,
          originalWidth: width,
          originalHeight: height,
          width,
          height,
          opacity: 1.0,
          position: {
            x: 0,
            y: 0,
            z: 0,
          },
          rotation: {
            x: 0,
            y: 0,
            z: 0,
          },
          layerKey: LAYER_KEYS.BACKGROUND_IMAGE,
          id: LAYER_KEYS.BACKGROUND_IMAGE,
        },
      },
    })

    this.props.history.push(`.${routes.modals.editImage}`)
  }

  render() {
    const { parentRoute, history, props } = this.props
    const { isProcessing, error } = this.state
    return (
      <div>
        <Helmet>
          <title>{getTitle('convertImage')}</title>
        </Helmet>
        <Modal
          title="Convert Image"
          parentRoute={parentRoute}
          history={history}
          {...props}
        >
          <Container>
            {error.length > 0 && (
              <Space bottom="base">
                <Alert type="error" text={error} />
              </Space>
            )}
            {isProcessing && (
              <>
                <Space bottom="base">
                  <p>
                    {parseInt(this.state.percent, 10)}% - {this.state.message}
                  </p>
                </Space>
                <Line
                  percent={parseInt(this.state.percent, 10)}
                  strokeColor={theme.colors.dark.primary}
                />
              </>
            )}
          </Container>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ backgroundImageForm }) => ({
  backgroundImageForm,
})

const mapDispatchToProps = { addBackgroundImage }

export default appConnect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConvertImageModal))
