import styled from 'styled-components'

const NavItem = styled.li`
  font-size: 14px;
  margin: 0;
  padding: 0;
  list-style: none;
`

export default NavItem
