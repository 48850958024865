import styled from 'styled-components'

const ProgressBarFill = styled.div`
  height: 100%;
  width: ${props => props.completed}%;
  background-color: ${props => props.bgColor};
  transition: width 1s ease-in-out;
  border-radius: inherit;
  text-align: right;
`

export default ProgressBarFill
