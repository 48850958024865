import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.div`
  ${props => props.vCenter && `align-items: center;`};
  ${props => props.alignItems && `align-items: ${props.alignItems};`};
  display: flex;
  ${props => props.fullWidth && `flex-basis: 100%`};
  flex-wrap: wrap;
  ${props => props.hCenter && `justify-content: center;`};
  margin-left: -${theme('spacing.base')};
`

export default Container
