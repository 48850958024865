import {
  Menu as AriaMenu,
  MenuItem as AriaMenuItem,
  MenuProps as AriaMenuProps,
  MenuItemProps as AriaMenuItemProps,
  Separator,
  SeparatorProps,
  composeRenderProps,
} from 'react-aria-components'
import { DropdownSection, DropdownSectionProps } from './ListBox'
import { Popover, PopoverProps } from './Popover'
import { Icon } from './Icon'
import { tv, VariantProps } from 'tailwind-variants'
import { composeTailwindRenderProps } from '~/ui/utils'

const menu = tv({
  base: 'outline outline-0 border-0 max-h-[inherit]',
  variants: {
    variant: {
      default: 'p-1 overflow-auto [clip-path:inset(0_0_0_0_round_.75rem)]',
      square: 'rounded-none',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

const menuItem = tv({
  base:
    'group flex items-center gap-4 cursor-default select-none py-2 pl-3 pr-1 rounded outline outline-0 text-sm forced-color-adjust-none dark:shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)]',
  variants: {
    isDisabled: {
      false: 'text-gray-900 dark:text-zinc-100',
      true: 'text-gray-300 dark:text-zinc-600 forced-colors:text-[GrayText]',
    },
    isFocused: {
      true:
        'bg-baf-yellow dark:bg-[#303030] forced-colors:bg-[Highlight] forced-colors:text-[HighlightText] cursor-pointer',
    },
    variant: {
      square: 'p-4 text-xs w-[250px]',
    },
  },
  compoundVariants: [
    {
      isFocused: false,
      isOpen: true,
      className: 'bg-gray-100 dark:bg-dark-gray-800',
    },
  ],
})

interface MenuProps<T> extends AriaMenuProps<T> {
  popoverProps?: Omit<PopoverProps, 'children'>
  variant?: VariantProps<typeof menu>['variant']
}

export function Menu<T extends object>({ popoverProps = {}, variant, ...props }: MenuProps<T>) {
  const popoverStyles = variant === 'square' ? 'rounded-none' : ''
  return (
    <Popover
      {...popoverProps}
      className={composeTailwindRenderProps(popoverProps.className, popoverStyles)}
    >
      <AriaMenu {...props} className={menu({ className: props.className, variant })} />
    </Popover>
  )
}

interface MenuItemProps extends AriaMenuItemProps {
  variant?: 'square'
}

export function MenuItem(props: MenuItemProps) {
  return (
    <AriaMenuItem
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        menuItem({ ...renderProps, className, variant: props.variant })
      )}
    >
      {composeRenderProps(props.children, (children, { selectionMode, isSelected, hasSubmenu }) => (
        <>
          {selectionMode !== 'none' && (
            <span className="flex items-center w-4">
              {isSelected && <Icon name="check" aria-hidden className="w-4 h-4" />}
            </span>
          )}
          <span className="flex items-center flex-1 gap-2 font-normal truncate group-selected:font-semibold">
            {children}
          </span>
          {hasSubmenu && (
            <Icon name="arrowRight" size="12" aria-hidden className="absolute right-2 opacity-50" />
          )}
        </>
      ))}
    </AriaMenuItem>
  )
}

export function MenuSeparator(props: SeparatorProps) {
  return <Separator {...props} className="mx-3 my-1 border-b border-gray-300 dark:border-zinc-700" />
}

export function MenuSection<T extends object>(props: DropdownSectionProps<T>) {
  return <DropdownSection {...props} />;
}
