import { ChangeEventHandler, Component, FocusEvent, FocusEventHandler, KeyboardEventHandler, MouseEvent } from 'react'
import { withNetwork } from 'networkProvider'

import InfoTooltip from '../InfoTooltip'

import ButtonsContainer from './styled/ButtonsContainer'
import Container from './styled/Container'
import InputContainer from './styled/InputContainer'
import Label from './styled/Label'
import TextField from './styled/TextField'

import DimensionButton from './DimensionButton'

import { Distance } from 'store/units/types'
import { MeasurementSystem } from '~/store/units/constants'

const DEFAULT_VALUE = 0
const EMPTY_VALUE_STRING = '--'

type DimensionInputProps = {
  onChange: (props: { distance: Distance<any> }) => void
  units?: MeasurementSystem
  distance?: Distance<any>
  disabled?: boolean
  inline?: boolean
  comfortConfig?: boolean
  online?: boolean
  label?: string
  name?: string
  labelWidth?: string
  tooltip?: string
  width?: string
  tabIndex?: number
  maxHeight?: number
  required?: boolean
}

type DimensionInputState = {
  distance: Distance<any>
  displayString: string
}

class DimensionInput extends Component<DimensionInputProps, DimensionInputState> {
  static defaultProps = {
    onChange: () => {},
  }

  constructor(props: DimensionInputProps) {
    super(props)

    this.state = this.updatedState(props)
  }

  componentDidUpdate(prevProps: DimensionInputProps) {
    if (
      !this.state.distance ||
      this.props.units !== prevProps.units ||
      (this.props.distance && !this.props.distance.equals(this.state.distance))
    ) {
      // Prevent setting state of invalid NaN values
      if (!isNaN(this.props.distance!.value)) {
        this.setState(this.updatedState(this.props))
      }
    }
  }

  updatedState(props: DimensionInputProps): DimensionInputState {
    const { distance, units } = props
    let newDistance
    if (distance) {
      newDistance = distance.clone()
      newDistance.convertTo(units!)
    } else {
      newDistance = new Distance({
        value: null,
        system: units!,
      })
    }

    return {
      distance: newDistance,
      displayString: newDistance.format({
        round: units === 'METRIC' ? 2 : 0,
        nullValue: EMPTY_VALUE_STRING,
      })!,
    }
  }

  handleFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement> | MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.props.disabled) {
      return
    }

    (event.target as HTMLInputElement|HTMLTextAreaElement).select()
  }

  handleEnter = () => {
    const { units } = this.props
    let inputValue = this.state.displayString

    const distance = new Distance({
      value: Distance.unformat({
        value: inputValue,
        system: units!,
      }),
      system: units!,
    })

    this.handleSetValue(distance)
  }

  handleKeyDown: KeyboardEventHandler<HTMLElement> = event => {
    const { disabled } = this.props

    if (disabled) {
      return
    }

    switch (event.keyCode) {
      // Enter
      case 13:
        this.handleEnter()
        event.preventDefault()
        break
      // Up
      case 38:
        this.handleIncreaseValue()
        event.preventDefault()
        break
      // Down
      case 40:
        this.handleDecreaseValue()
        event.preventDefault()
        break
      default:
        return
    }
  }

  getNewDistance() {
    let newDistance
    if (this.state.distance) {
      newDistance = this.state.distance.clone()
    } else {
      newDistance = new Distance({
        value: DEFAULT_VALUE,
        system: this.props.units!,
      })
    }

    return newDistance
  }

  handleIncreaseValue = () => {
    const newDistance = this.getNewDistance()
    newDistance.value += 1
    this.handleSetValue(newDistance)
  }

  handleDecreaseValue = () => {
    const newDistance = this.getNewDistance()
    newDistance.value -= 1
    this.handleSetValue(newDistance)
  }

  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    if (this.props.disabled) {
      return
    }

    this.setState({
      displayString: event.target.value,
    })
  }

  handleSetValue = (distance: Distance<any>) => {
    const { onChange, units } = this.props

    onChange({ distance })
    this.setState({
      distance,
      displayString: distance.format({
        round: units === 'METRIC' ? 2 : 0,
        nullValue: EMPTY_VALUE_STRING,
      })!,
    })
  }

  render() {
    const disabled = this.props.disabled || !this.props.online
    const comfortConfig = this.props.comfortConfig

    return (
      <Container
        inline={this.props.inline}
        style={comfortConfig ? { flexDirection: 'column' } : undefined}
      >
        {this.props.label && (
          <Label
            htmlFor={this.props.name}
            width={this.props.labelWidth}
          >
            {this.props.label}
            {this.props.tooltip && (
              <>
                {' '}
                <InfoTooltip title={this.props.tooltip} iconSize="15" />
              </>
            )}
          </Label>
        )}
        <InputContainer style={comfortConfig ? { marginTop: '5px' } : undefined}>
          <TextField
            name={this.props.name}
            width={this.props.width || '70px'}
            value={this.state.displayString}
            overrideValue={this.state.displayString}
            onFocus={this.handleFocus}
            onClick={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            onBlur={this.handleEnter}
            disabled={disabled}
            tabIndex={this.props.tabIndex}
            comfortconfig={comfortConfig ? true : undefined}
            required={this.props.required}
          />
          <ButtonsContainer>
            <DimensionButton
              direction="Up"
              onPress={this.handleIncreaseValue}
              disabled={
                disabled ||
                (this.props.maxHeight
                  ? this.props.distance!.value >= this.props.maxHeight
                  : undefined)
              }
            />
            <DimensionButton
              direction="Down"
              onPress={this.handleDecreaseValue}
              disabled={
                disabled ||
                (this.props.distance ? this.props.distance.value <= 0 : undefined)
              }
            />
          </ButtonsContainer>
        </InputContainer>
      </Container>
    )
  }
}

export default withNetwork(DimensionInput)
