import styled from 'styled-components'
import Image from 'components/UIKit/Image'

export const MainImageContainer = styled.div`
  background-color: #cdcdcd;
  position: relative;
  flex: 3;
`
export const MainImage = styled(Image)`
  margin-block-end: 25px;
  height: calc(100% - 25px);
  margin-inline: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
`
