import { usePerformanceMetricsProps } from 'components/PerformanceMetrics/hooks/usePerformanceMetricsProps'
import { Alert } from 'components/PerformanceMetrics/subcomponents/Alert'
import { CoolingResults } from 'components/PerformanceMetrics/subcomponents/CoolingResults'
import { DestratResults } from 'components/PerformanceMetrics/subcomponents/DestratResults'
import { HeatingCompositResults } from 'components/PerformanceMetrics/subcomponents/HeatingCompositeResults'
import { UnitHeatResults } from 'components/PerformanceMetrics/subcomponents/UnitHeatResults'
import { RadiantHeatResults } from 'components/PerformanceMetrics/subcomponents/RadiantHeatResults'
import { COMPOSITE_SIMULATION_TYPES, SIMULATION_TYPES } from 'config/cfd'
import React from 'react'

export const MetricsDisplay = () => {
  const { selectedVtkFiles } = usePerformanceMetricsProps()
  switch (selectedVtkFiles?.type) {
    case SIMULATION_TYPES.destrat: {
      return <DestratResults vtkFiles={selectedVtkFiles} />
    }
    case SIMULATION_TYPES.cooling: {
      return <CoolingResults vtkFiles={selectedVtkFiles} />
    }
    case SIMULATION_TYPES.radiantHeat: {
      return <RadiantHeatResults vtkFiles={selectedVtkFiles} />
    }
    case SIMULATION_TYPES.unitHeating: {
      return <UnitHeatResults vtkFiles={selectedVtkFiles} />
    }
    case COMPOSITE_SIMULATION_TYPES.heatingComposite: {
      return <HeatingCompositResults vtkFiles={selectedVtkFiles} />
    }
    default: {
      console.warn(`Unexpected goal type: ${selectedVtkFiles?.type}`)
      return <Alert label="Failed to get metrics data" />
    }
  }
}
