import store from 'store'

export function isTouchUI(state = store.getState()) {
  const { isTouchUI } = state.userInterface
  return Boolean(isTouchUI)
}

export function isFullscreen(state = store.getState()) {
  const { isFullscreen } = state.userInterface
  return Boolean(isFullscreen)
}
