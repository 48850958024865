import styled from 'styled-components'

const Container = styled.div`
  align-items: stretch;
  display: flex;
  width: 100%;
  min-height: 225px;
  overflow-x: auto;
`

export default Container
