import React from 'react'
import PropTypes from 'prop-types'
import { Loader as StyledLoader } from 'components/PerformanceMetrics/subcomponents/Loader'

const Loader = ({ label = '', ...rest }) => (
  <StyledLoader label={label} padding={[6, 0]} {...rest} />
)

Loader.propTypes = {
  label: PropTypes.string,
}

export { Loader }
