import React from 'react'
import PropTypes from 'prop-types'
import theme from 'config/theme'

const PieChart = ({ size = 25, percentComplete = 0 }) => {
  const radius = size
  const angle = Math.ceil(percentComplete * 360) - 180
  const endX = radius - Math.cos((Math.PI * angle) / 180) * radius
  const endY = radius - Math.sin((Math.PI * angle) / 180) * radius

  const largeArcFlag = angle < 0 ? 0 : 1
  const sweepFlag = 1
  const pathData = `
      M ${radius * 2} ${radius} 
      A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${endX} ${endY}
      L ${radius} ${radius} Z
    `

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      width={radius * 2}
      height={radius * 2}
    >
      <circle
        fill={percentComplete > 0.99 ? theme.colors.light.primary : theme.colors.light.extraLight}
        cx={radius}
        cy={radius}
        r={radius}
      />
      <path d={pathData} fill={theme.colors.light.primary} stroke="none" />
    </svg>
  )
}

PieChart.propTypes = {
  size: PropTypes.number,
  percentComplete: PropTypes.number,
}

export { PieChart }
