import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash-es/isEmpty'
import { toPng } from 'html-to-image';

import { getSnapshotUrl } from 'lib/utils'
import { currentUserIsCustomer } from 'lib/currentUserIs'

import { SNAPSHOTS_PANEL } from 'store/panel/types'
import withUser from 'client/decorators/withUser'

import Icon from 'components/UIKit/Icon'
import Loader from 'components/UIKit/Loader'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'

import Action from './styled/Action'
import ImageContainer from './styled/ImageContainer'
import ImageIcon from './styled/ImageIcon'
import Image from './styled/Image'
import Item from './styled/Item'
import List from './styled/List'
import LoaderContainer from './styled/LoaderContainer'
import Title from './styled/Title'

const defaultSizes = {
  width: 140,
  height: 80,
}

const SnapshotsPanel = ({
  snapshots,
  onSnapshot,
  uploadingSnapshot,
  match,
  online,
  user,
}) => {
  const [takingSnapshot, setTakingSnapshot] = useState(false)
  const takeSnapshot = useCallback(async () => {
    try {
      setTakingSnapshot(true)
      const shot = await toPng(document.getElementById("drawing-canvas"))
      onSnapshot(shot)
    } finally {
      setTakingSnapshot(false)
    }
  }, [])

  return <Panel title="Snapshots" landscape panelKey={SNAPSHOTS_PANEL}>
    <PanelSection>
      <List>
        {online && !currentUserIsCustomer(user) && (
          <Item>
            <Action text="Add New" onClick={takeSnapshot}>
              <Space bottom="xs">
                {(takingSnapshot || uploadingSnapshot) ? (
                  <LoaderContainer>
                    <Loader label="" iconSize="28" />
                  </LoaderContainer>
                ) : (
                  <Icon name="plus" size="28" />
                )}
              </Space>
            </Action>
          </Item>
        )}
        {snapshots &&
          snapshots.map(snapshot => (
            <Item key={snapshot.id}>
              <Space bottom="xs">
                <ImageContainer
                  to={`${match.url}/snapshot/${snapshot.id}`}
                  height={defaultSizes.height}
                >
                  <ImageIcon name="zoomIn" size="24" />
                  <Image
                    src={getSnapshotUrl(snapshot)}
                    height={defaultSizes.height}
                  />
                </ImageContainer>
              </Space>
              <Title width={defaultSizes.width.toString()}>
                {snapshot.title}
              </Title>
            </Item>
          ))}
      </List>
      {isEmpty(snapshots) && !online && (
        <Space bottom="base" top="base">
          <p>No snapshots availiable.</p>
        </Space>
      )}
      {isEmpty(snapshots) && currentUserIsCustomer(user) && (
        <Space bottom="base" top="base">
          <p>There are no snapshots in this facility.</p>
        </Space>
      )}
    </PanelSection>
  </Panel>
}

SnapshotsPanel.propTypes = {
  snapshots: PropTypes.arrayOf(PropTypes.object),
  match: PropTypes.object,
  online: PropTypes.bool,
  user: PropTypes.object,
}

export default compose(
  withRouter,
  withUser
)(SnapshotsPanel)
