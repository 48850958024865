import React from 'react'
import PropTypes from 'prop-types'

import Container from './styled/Container'
import ItemBreadcrumb from './styled/ItemBreadcrumb'
import BackIcon from './styled/BackIcon'
import Menu from './styled/Menu'

const ItemMenu = ({ title, items, closeMenu, ...props }) => {
  return (
    <Container isVisible={props.isVisible}>
      <ItemBreadcrumb onClick={closeMenu}>
        <BackIcon name="caretLeft" />
        {title}
      </ItemBreadcrumb>
      <Menu items={items} {...props} />
    </Container>
  )
}

ItemMenu.propTypes = {
  closeMenu: PropTypes.func,
  isVisible: PropTypes.bool,
  items: PropTypes.array,
  title: PropTypes.string.isRequired,
}

export default ItemMenu
