import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Content = styled.div`
  color: ${theme('colors.light.fg')};
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`

export default Content
