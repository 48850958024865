import React from 'react'
import { useNetwork } from 'networkProvider'

import { DOCK_ITEM_PANEL } from 'store/panel/types'

import Panel from 'components/UIKit/Panel'

import ProductsMenu from './ProductsMenu'
import WallsAndDoorsMenu from './WallsAndDoorsMenu'
import RoofsAndCeilingsMenu from './RoofsAndCeilingsMenu'
import ObstructionsMenu from './ObstructionsMenu'
import AnnotationsMenu from './AnnotationsMenu'

import MenusContainer from './styled/MenusContainer'
import DockItemPanelOffline from './styled/DockItemPanelOffline'

export const PANEL_TITLE = 'Add an Item'
const OFFLINE_TITLE = 'Oops, You are Offline'

const description =
  'Tap items to inspect or find new items to add to your project dock.'
const offlineDescription =
  'Sorry, adding items to your facility only works while online.'

const DockItemPanel = () => {
  const network = useNetwork()
  return (
    <Panel
      title={network.online ? PANEL_TITLE : OFFLINE_TITLE}
      description={network.online ? description : offlineDescription}
      alignment="left"
      docked
      panelKey={DOCK_ITEM_PANEL}
      stretchContainer
      scrollable
      hasToolbar
    >
      {network.online ? (
        <MenusContainer>
          <ProductsMenu />
          <WallsAndDoorsMenu />
          <RoofsAndCeilingsMenu />
          <ObstructionsMenu />
          <AnnotationsMenu />
        </MenusContainer>
      ) : (
        <DockItemPanelOffline>
          <MenusContainer>
            <ProductsMenu />
            <WallsAndDoorsMenu />
            <RoofsAndCeilingsMenu />
            <ObstructionsMenu />
            <AnnotationsMenu />
          </MenusContainer>
        </DockItemPanelOffline>
      )}
    </Panel>
  )
}

export default DockItemPanel
