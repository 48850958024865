import { Line3, Vector3 } from "three"

export type SnapLine = {
  id: string
  line: Line3
}

export type SnapPoint = {
  id: string
  point: Vector3
}

export type SnapData = {
  objectID: string
  snapPoint: Vector3
  objectLine: Line3
  distance: number
}

export type SnapDataPoint = {
  objectID: string
  snapPoint: Vector3
  distance: number
}

/**
 * Finds which line from {@link lines} is closest the given {@link point}, and returns the closest point on it
 */
export function snapToLine(lines: SnapLine[], point: Vector3): SnapData|undefined {
  const target = new Vector3()
  let distance = Infinity
  let objectID: string | undefined = undefined
  let objectLine: Line3 | undefined = undefined
  let snapPoint = new Vector3()
  for (const { id, line } of lines) {
    line.closestPointToPoint(point, true, target)
    const targetDistance = target.distanceToSquared(point)
    if (targetDistance < distance) {
      distance = targetDistance
      objectID = id
      objectLine = line
      snapPoint.copy(target)
    }
  }
  if (objectID === undefined) {
    return undefined
  }
  return { objectID, snapPoint, objectLine: objectLine!, distance }
}

/**
 * Finds which point from {@link points} is closest to the given {@link point}, and returns it
 */
export function snapToPoint(points: SnapPoint[], point: Vector3): SnapDataPoint|undefined {
  let distance = Infinity
  let objectID: string | undefined = undefined
  let snapPoint = new Vector3()

  for (const snap of points) {
    const targetDistance = snap.point.distanceToSquared(point)
    if (snap.point.distanceToSquared(point) < distance) {
      distance = targetDistance
      objectID = snap.id
      snapPoint.copy(snap.point)
    }
  }
  if (objectID === undefined) {
    return undefined
  }
  return { objectID, snapPoint, distance }
}

/**
 * Snaps {@link point} to the closest line or point from the given collections
 */
export function snapTo(lines: SnapLine[], points: SnapPoint[], point: Vector3): SnapDataPoint|SnapData|undefined {
  const snapLineResult = snapToLine(lines, point)
  const snapPointResult = snapToPoint(points, point)

  if (!snapLineResult || !snapPointResult) {
    return undefined
  } if (snapLineResult && !snapPointResult) {
    return snapLineResult
  } else if (snapPointResult && !snapLineResult) {
    return snapPointResult
  }

  if (snapLineResult.distance <= snapPointResult.distance) {
    return snapLineResult
  } else {
    return snapPointResult
  }
}

/**
 * Snaps the point to be cardinal to the other point
 */
export function snapToCardinals(base: Vector3, exterior: Vector3): Vector3 {
  const dx = Math.abs(base.x - exterior.x)
  const dy = Math.abs(base.y - exterior.y)

  if (dx >= dy) {
    return exterior.clone().setY(base.y)
  } else {
    return exterior.clone().setX(base.x)
  }
}
