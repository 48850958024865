import theme from 'config/theme'
import styled from 'styled-components'

export const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 2em;
`

export const ThumbnailPlaceholder = styled.div`
  background-color: ${theme.colors.light.subdued};
  aspect-ratio: 4/3;
  width: 120px;
`
