import { graphql } from '~/gql'

export const NOTIFY_CFD_UPLOAD_COMPLETE_MUTATION = graphql(`
  mutation NotifyCFDUploadComplete(
    $versionId: ID!
    $timestamp: DateTime!
    $outputUnits: String
    $type: String
    $internalType: String
  ) {
    notifyCFDUploadCompleteV2(
      versionId: $versionId
      timestamp: $timestamp
      outputUnits: $outputUnits
      type: $type
      internalType: $internalType
    ) {
      id
      status
      type
    }
  }
`)
