class FixedSizeQueue {
  constructor(size) {
    this.size = size
    this.data = []
  }

  add(element) {
    this.data.unshift(element)

    if (this.data.length > this.size) {
      this.data.length = this.size
    }
  }

  getFirst() {
    return this.data[0]
  }

  getLast() {
    return this.data[this.data.length - 1]
  }

  get(index) {
    return this.data[index]
  }
}

export default FixedSizeQueue
