import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import A from '../../A'

const Container = styled(A)`
  svg > path {
    fill: ${theme('colors.light.subdued')};
    transition: 0.3s ease-in-out;
  }

  &:active,
  &:focus,
  &:hover {
    svg > path {
      fill: ${theme('colors.light.fg')};
      transform: translateX(${theme('spacing.xs')});
    }
  }
`

export default Container
