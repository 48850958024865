import React, { Component } from 'react'
import { object, func, string, bool } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import get from 'lodash-es/get'

import { updateRoofSection } from 'store/objects'
import { Distance } from 'store/units/types'
import { withUnits } from 'store/units/decorators'
import { SYSTEMS } from 'store/units/constants'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { isTouchUI } from 'store/userInterface/selectors'
import { SELECTED_ROOF_SECTION_PANEL } from 'store/panel/types'
import CLASS_NAMES from 'config/objectClassNames'
import store from 'store'
import LAYER_KEYS from 'config/layerKeys'

import Checkbox from 'components/UIKit/Checkbox'
import DimensionInput from 'components/UIKit/DimensionInput'
import Panel, { PanelSection } from 'components/UIKit/Panel'
import Select from 'components/UIKit/Select'
import Space from 'components/UIKit/Space'
import Tabs, { Tab } from 'components/UIKit/Tabs'
import TextField from 'components/UIKit/TextField'
import InfoTooltip from 'components/UIKit/InfoTooltip'

import MetadataSection from 'components/MetadataSection'

import {
  beamDirections,
  primaryMountingTypes,
  primaryToSecondaryTypes,
  structureStyles,
  primaryTypeToStyle,
} from 'config/mountingStructures'

const LETTERS = Array.from(Array(26))
  .map((e, i) => i + 65)
  .map(x => String.fromCharCode(x))

class SelectedRoofSectionPanel extends Component {
  handleInputChange = (key, value) => {
    const { selectedObject, onUpdateRoofSection } = this.props
    const updatedObject = { ...selectedObject }

    if (Array.isArray(key)) {
      key.forEach((k, i) => {
        updatedObject[k] = value[i]
      })
    } else {
      updatedObject[key] = value
    }

    onUpdateRoofSection(updatedObject)
  }

  handleChangeDirection = (key, value) => {
    const rotation = value === 'vertical' ? 0 : Math.PI / 2

    this.handleInputChange(
      [key, 'beamModels', 'columnLineModels'],
      [rotation, undefined, undefined]
    )
  }

  handleChangePrimaryType = ({ primaryStructureType }) => {
    const secondaryTypes = primaryToSecondaryTypes[primaryStructureType]
    const secondaryStructureType =
      secondaryTypes && secondaryTypes[0] ? secondaryTypes[0].value : undefined

    const structureStyle = primaryTypeToStyle[primaryStructureType]

    this.props.onUpdateRoofSection({
      ...this.props.selectedObject,
      primaryStructureType,
      secondaryStructureType,
      structureStyle,
    })
  }

  checkCustomSpacing(models, spacing, axis) {
    let customSpacing = false

    if (models && models.length > 1) {
      for (let i = 0; i < models.length - 1; ++i) {
        const modelSpacing = Number(
          (models[i + 1].position[axis] - models[i].position[axis]).toFixed(6)
        )
        const spacingMultiple = Number((modelSpacing % spacing).toFixed(6))
        if (spacingMultiple !== 0) {
          customSpacing = true
          break
        }
      }
    }

    return customSpacing
  }

  newDistance(value) {
    return new Distance({
      value,
      system: SYSTEMS.IMPERIAL,
    })
  }

  renderContent() {
    const {
      selectedObject,
      onUpdateRoofSection,
      distanceUnits,
      isTouchUI,
    } = this.props

    const beamModels = get(selectedObject, 'beamModels', [])
    const columnLineModels = get(selectedObject, 'columnLineModels', [])
    const hasCustomBeamSpacing = beamModels.some(beam => beam.edited)
    const hasCustomColumnSpacing = columnLineModels.some(
      columnLine => columnLine.edited
    )

    const beamSpacing = hasCustomBeamSpacing ? 0 : selectedObject.beamSpacing
    const columnSpacing = hasCustomColumnSpacing
      ? 0
      : selectedObject.columnSpacing
    const columnSize = selectedObject.columnWidth || 24
    const inputWidth = isTouchUI ? '140px' : '70px'

    const isLocked = store.getState().layers.layers[LAYER_KEYS.ROOF_SECTIONS]
      .locked

    const primaryTab = (
      <Tab title="Primary">
        <Space bottom="base">
          <Checkbox
            name={`primary-structures-enabled`}
            disabled={isLocked ? true : false}
            value={selectedObject.primaryStructuresEnabled}
            checked={selectedObject.primaryStructuresEnabled}
            label={`Primary Structures Enabled`}
            onChange={event =>
              this.handleInputChange(
                'primaryStructuresEnabled',
                !this.props.selectedObject.primaryStructuresEnabled
              )
            }
          />
        </Space>
        <Space bottom="base">
          <Select
            inline
            label="Type"
            labelWidth="90px"
            name="primary-mountingType"
            options={primaryMountingTypes}
            disabled={isLocked ? true : false}
            size="145px"
            onChange={event =>
              this.handleChangePrimaryType({
                primaryStructureType: event.target.value,
              })
            }
            value={selectedObject.primaryStructureType}
          />
        </Space>
        <Space bottom="base">
          <Select
            inline
            label="Style"
            labelWidth="90px"
            name="structure-style"
            options={structureStyles}
            disabled={isLocked ? true : false}
            size="145px"
            onChange={event =>
              this.handleInputChange('structureStyle', event.target.value)
            }
            value={selectedObject.structureStyle}
          />
        </Space>
        <Space bottom="base">
          <Select
            inline
            label="Direction"
            labelWidth="90px"
            name="beam-direction"
            options={beamDirections}
            disabled={isLocked ? true : false}
            size="145px"
            onChange={event =>
              this.handleChangeDirection('beamRotation', event.target.value)
            }
            value={
              selectedObject.beamRotation === 0 ? 'vertical' : 'horizontal'
            }
          />
        </Space>
        <Space bottom="base">
          <DimensionInput
            label="Beam Width"
            labelWidth="90px"
            width={inputWidth}
            name={`beam-width`}
            disabled={isLocked ? true : false}
            distance={this.newDistance(selectedObject.beamWidth)}
            units={distanceUnits}
            tabIndex={1}
            onChange={({ distance }) => {
              this.handleInputChange('beamWidth', distance.imperial() || 0)
            }}
          />
        </Space>
        <Space bottom="base">
          <DimensionInput
            label="Beam Spacing"
            labelWidth="90px"
            width={inputWidth}
            name={`beam-spacing`}
            inches={beamSpacing}
            disabled={isLocked ? true : false}
            distance={this.newDistance(beamSpacing)}
            units={distanceUnits}
            tabIndex={2}
            onChange={({ distance }) => {
              if (distance.imperial() > 0) {
                this.handleInputChange(
                  ['beamSpacing', 'beamModels'],
                  [distance.imperial(), undefined]
                )
              } else {
                return true
              }
            }}
          />
        </Space>
        <Space bottom="base">
          <DimensionInput
            label="Beam Depth"
            labelWidth="90px"
            width={inputWidth}
            name={`beam-depth`}
            disabled={isLocked ? true : false}
            distance={this.newDistance(selectedObject.beamDepth)}
            units={distanceUnits}
            tabIndex={3}
            onChange={({ distance }) => {
              const value = distance.imperial() || 0
              this.handleInputChange('beamDepth', value)
            }}
          />
        </Space>
        <Space bottom="base">
          <TextField
            name="beamLabels"
            label={'Beam Labels'}
            iconRight={
              <InfoTooltip
                title={
                  'Enter a list to be used for your beam labels, EX: A1, B1, C1'
                }
                iconSize={'16px'}
                style={{ marginBottom: '3px' }}
              />
            }
            type="string"
            disabled={isLocked}
            value={get(selectedObject, 'columnNames', []).toString()}
            onChange={e => {
              const value = e.target.value
              if (!value) {
                this.handleInputChange(
                  'columnNames',
                  LETTERS.map((e, i) => i + 1)
                )
              } else {
                this.handleInputChange('columnNames', e.target.value.split(','))
              }
            }}
          />
        </Space>
        <MetadataSection
          object={selectedObject}
          onBlur={onUpdateRoofSection}
          disabled={isLocked ? true : false}
        />
      </Tab>
    )

    let secondaryTab = (
      <Tab title="Secondary">
        <Space bottom="base">
          <Select
            inline
            label="Type"
            labelWidth="70px"
            size="165px"
            name="secondary-mountingType"
            disabled={isLocked ? true : false}
            options={
              primaryToSecondaryTypes[selectedObject.primaryStructureType]
            }
            onChange={event =>
              this.handleInputChange(
                'secondaryStructureType',
                event.target.value
              )
            }
            value={selectedObject.secondaryStructureType}
          />
        </Space>
        <Space bottom="base">
          <DimensionInput
            label="Depth"
            labelWidth="70px"
            width={inputWidth}
            name={`secondary-structure-depth`}
            disabled={isLocked ? true : false}
            distance={this.newDistance(selectedObject.secondaryStructureDepth)}
            units={distanceUnits}
            onChange={({ distance }) => {
              const value = distance.imperial() || 0
              this.handleInputChange('secondaryStructureDepth', value)
            }}
          />
        </Space>
        <Space bottom="base">
          <Select
            inline
            label="Style"
            labelWidth="60px"
            name="structure-style"
            options={structureStyles}
            disabled={isLocked}
            size="145px"
            onChange={event =>
              this.handleInputChange(
                'secondaryStructureStyle',
                event.target.value
              )
            }
            value={selectedObject.secondaryStructureStyle}
          />
        </Space>
      </Tab>
    )

    let columnsTab = (
      <Tab title="Columns">
        <Space bottom="base">
          <Checkbox
            name={`has-columns`}
            disabled={isLocked ? true : false}
            value={selectedObject.hasColumns}
            checked={selectedObject.hasColumns}
            label={`Has columns`}
            onChange={event =>
              this.handleInputChange(
                'hasColumns',
                !this.props.selectedObject.hasColumns
              )
            }
          />
        </Space>
        <Space bottom="base">
          <DimensionInput
            label="Column Spacing"
            labelWidth="110px"
            width={inputWidth}
            disabled={!selectedObject.hasColumns || isLocked ? true : false}
            name={`column-spacing`}
            distance={this.newDistance(columnSpacing)}
            units={distanceUnits}
            tabIndex={5}
            onChange={({ distance }) => {
              if (distance.imperial() > 0) {
                this.handleInputChange(
                  ['columnSpacing', 'columnLineModels'],
                  [distance.imperial(), undefined]
                )
              } else {
                return true
              }
            }}
          />
        </Space>
        <Space bottom="base">
          <DimensionInput
            label="Column Size"
            labelWidth="110px"
            width={inputWidth}
            disabled={!selectedObject.hasColumns || isLocked ? true : false}
            name={`column-size`}
            distance={this.newDistance(columnSize)}
            units={distanceUnits}
            tabIndex={6}
            onChange={({ distance }) => {
              if (distance.imperial() > 0) {
                this.handleInputChange(
                  ['columnWidth', 'columnLineModels'],
                  [distance.imperial(), undefined]
                )
              } else {
                return true
              }
            }}
          />
        </Space>
        <Space>
          <Checkbox
            name={`is-cylinder`}
            value={selectedObject.isCylinder}
            checked={selectedObject.isCylinder}
            disabled={!selectedObject.hasColumns || isLocked}
            label={`Round Columns`}
            onChange={e => {
              this.handleInputChange(
                ['isCylinder', 'columnLineModels'],
                [!selectedObject.isCylinder, undefined]
              )
            }}
          />
        </Space>
        <Space bottom="base">
          <TextField
            name="columnLabels"
            label="Column Labels"
            type="string"
            iconRight={
              <InfoTooltip
                title={
                  'Enter a list to be used for your column labels, EX: A1, B1, C1'
                }
                iconSize={'16px'}
                style={{ marginBottom: '3px' }}
              />
            }
            disabled={isLocked}
            value={get(selectedObject, 'beamNames', []).toString()}
            onChange={e => {
              const value = e.target.value
              value
                ? this.handleInputChange('beamNames', value.split(','))
                : this.handleInputChange('beamNames', LETTERS)
            }}
          />
        </Space>
      </Tab>
    )

    const secondaryTypes =
      primaryToSecondaryTypes[selectedObject.primaryStructureType]
    if (!secondaryTypes || !secondaryTypes.length|| secondaryTypes[0]?.value === null) {
      secondaryTab = <div />
    }

    return (
      <PanelSection>
        <Tabs size="s">
          {primaryTab}
          {secondaryTab}
          {columnsTab}
        </Tabs>
      </PanelSection>
    )
  }

  render() {
    const { selectedObject, alignment, isTouchUI } = this.props

    return (
      <Panel
        title="Mounting Structures"
        alignment={alignment}
        docked
        scrollable
        panelKey={SELECTED_ROOF_SECTION_PANEL}
        hasToolbar={isTouchUI}
      >
        {selectedObject ? this.renderContent() : null}
      </Panel>
    )
  }
}

SelectedRoofSectionPanel.defaultProps = {
  alignment: 'right',
}

SelectedRoofSectionPanel.propTypes = {
  alignment: string,
  selectedObject: object,
  onUpdateRoofSection: func,
  distanceUnits: string,
  isTouchUI: bool,
}

const mapStateToProps = ({ objects, selectedObjects, ...store }) => {
  const selectedObject = mostRecentSelectedObjectOfClassName(
    CLASS_NAMES.ROOF_SECTION,
    {
      selectedObjects,
      objects,
    }
  )
  return {
    layers: store.layers,
    isTouchUI: isTouchUI(store),
    selectedObject,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateRoofSection(roofSection) {
    dispatch(updateRoofSection({ roofSection }))
  },
})

export default compose(
  withUnits,
  appConnect(mapStateToProps, mapDispatchToProps)
)(SelectedRoofSectionPanel)
