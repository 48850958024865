import React from 'react'
import PropTypes from 'prop-types'
import times from 'lodash-es/times'

import A from 'components/UIKit/A'
import Flex, { FlexItem } from 'components/UIKit/Flex'

const MAX_PAGES = 20
function Pagination(props = {}) {
  if (isNaN(props.totalPages) || !props.totalPages || props.totalPages <= 1) {
    return null
  }
  let pagesFromEnd = props.totalPages - props.currentPage - 1
  if (pagesFromEnd > 10) pagesFromEnd = 10

  return (
    <Flex spacingRight="s" justifyContent="center">
      {props.currentPage > 0 ? (
        <FlexItem>
          <A
            onClick={event => {
              event.preventDefault()
              props.onPreviousPage()
            }}
          >
            <strong>Previous</strong>
          </A>
        </FlexItem>
      ) : null}
      {props.totalPages <= MAX_PAGES ? (
        times(props.totalPages, page => {
          const pageNumber = page + 1

          return (
            <FlexItem key={page}>
              {page === props.currentPage ? (
                <strong>{pageNumber}</strong>
              ) : (
                <A
                  onClick={event => {
                    event.preventDefault()
                    props.onPage(page)
                  }}
                >
                  {pageNumber}
                </A>
              )}
            </FlexItem>
          )
        })
      ) : (
        <>
          {props.currentPage > MAX_PAGES / 2 && (
            <>
              <FlexItem key={0}>
                {props.totalPages === props.currentPage ? (
                  <strong>{1}</strong>
                ) : (
                  <A
                    onClick={event => {
                      event.preventDefault()
                      props.onPage(0)
                    }}
                  >
                    {1}
                  </A>
                )}
              </FlexItem>
              <p>...</p>
            </>
          )}
          {times(MAX_PAGES / 2 + pagesFromEnd + 1, page => {
            let _page = page
            if (props.currentPage > 10) {
              _page = props.currentPage - 10 + page
            }
            const pageNumber = _page + 1

            return (
              <FlexItem key={_page}>
                {_page === props.currentPage ? (
                  <strong>{pageNumber}</strong>
                ) : (
                  <A
                    onClick={event => {
                      event.preventDefault()
                      props.onPage(_page)
                    }}
                  >
                    {pageNumber}
                  </A>
                )}
              </FlexItem>
            )
          })}
        </>
      )}
      {pagesFromEnd >= 10 && (
        <>
          <p>...</p>
          <FlexItem key={props.totalPages}>
            {props.totalPages === props.currentPage ? (
              <strong>{props.totalPages + 1}</strong>
            ) : (
              <A
                onClick={event => {
                  event.preventDefault()
                  props.onPage(props.totalPages - 1)
                }}
              >
                {props.totalPages}
              </A>
            )}
          </FlexItem>
        </>
      )}
      {props.currentPage < props.totalPages - 1 ? (
        <FlexItem>
          <A
            onClick={event => {
              event.preventDefault()
              props.onNextPage()
            }}
          >
            <strong>Next</strong>
          </A>
        </FlexItem>
      ) : null}
    </Flex>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  onPreviousPage: PropTypes.func,
  onPage: PropTypes.func,
  onNextPage: PropTypes.func,
  totalPages: PropTypes.number,
}

export default Pagination
