import { useState } from 'react'
import { useNetwork } from 'networkProvider'
import { useCFDStatusFragment } from 'hooks/useCFDStatus'
import { Stack } from 'components/UIKit/Stack'
import VariantText from 'components/UIKit/VariantText'
import Container from '../styled/Container'
import { Loader } from '../styled/Loader'
import { ConfigureCFDButton } from './ConfigureCFDButton'
import { CFDImagesPreview } from './CFDImagesPreview'
import { VisualizationToggle } from './VisualizationToggle'
import { LegendPanel } from './LegendPanel'
import { CancelCFDButton } from './CancelCFDButton'
import { CSVDownloadButton } from './CSVDownloadButton'
import { useCFDUploadsContext } from '~/hooks/useCFDUploadsContext'
import { useCFDResultChecksumFragment } from '~/hooks/useCFDResultChecksum'

const Offline = () => (
  <Stack padding={2} spacing={1}>
    <VariantText>Sorry, you need to be online to run CFD simulation</VariantText>
  </Stack>
)

const FirstCFDPrompt = () => (
  <Stack padding={[4, 2]} spacing={1}>
    <VariantText align="center">
      Click below to generate CFD data for your facility. The process may take up to 10 minutes. You
      will receive a notification when complete.
    </VariantText>
    <ConfigureCFDButton />
  </Stack>
)

const AllInProgress = () => {
  return (
    <Stack padding={2} spacing={1}>
      <Loader label="" padding={1} />
      <VariantText align="center">Processing CFD data&hellip;</VariantText>
      <VariantText align="center">
        Feel free to close this window. You will be notified when the process is complete.
      </VariantText>
      <CancelCFDButton primary />
    </Stack>
  )
}

export const CFDPanelContent = () => {
  const { cfdStatus, complete } = useCFDStatusFragment()
  const { checksumResults } = useCFDResultChecksumFragment()
  const { uploads } = useCFDUploadsContext()
  const { online } = useNetwork()
  const [selectedGoal, setSelectedGoal] = useState()

  if (!online) {
    return <Offline />
  } else if (!complete) {
    return <Loader />
  }
  const {
    isFirstSimulation,
    isAllInProgress,
    successfulSimulationTypes,
    isSomeInProgress,
    isSomeCanceled,
    isSomeFailed,
  } = cfdStatus

  if (isFirstSimulation) return <FirstCFDPrompt />
  else if (isAllInProgress) return <AllInProgress />

  const goal = selectedGoal ?? successfulSimulationTypes[0]
  const isSomeInvalidModel = checksumResults?.some(result => {
    if (!uploads || !result) return false
    const { internalType, resultChecksum } = result
    const uploadChecksum = uploads[internalType]?.checksum
    return !!uploadChecksum && !!resultChecksum && uploadChecksum !== resultChecksum
  })

  return (
    <Container>
      <VisualizationToggle selectedGoal={goal} setSelectedGoal={setSelectedGoal} />
      <CFDImagesPreview />
      <LegendPanel goal={goal}>
        <Stack direction="row" spacing={1}>
          {(isSomeFailed || isSomeCanceled || isSomeInvalidModel) && <ConfigureCFDButton />}
          {isSomeInProgress && <CancelCFDButton />}
          <CSVDownloadButton goal={goal} />
        </Stack>
        {isSomeInvalidModel && <VariantText>Some CFD data may be invalid</VariantText>}
      </LegendPanel>
    </Container>
  )
}
