import { Component, MouseEventHandler } from 'react'
import Button from './styled/Button'

const PRESS_DOWN_DELAY = 300

class DimensionButton extends Component<{ onPress: () => void; direction?: string; disabled?: boolean }> {
  pressDownTimeout: ReturnType<typeof setTimeout> | null
  pressDownDelay: number

  constructor(props: { onPress: () => void; direction?: string; disabled?: boolean }) {
    super(props)

    this.pressDownDelay = PRESS_DOWN_DELAY
    this.pressDownTimeout = null
  }

  componentWillUnmount() {
    if (this.pressDownTimeout) {
      clearTimeout(this.pressDownTimeout)
      this.pressDownTimeout = null
    }
  }

  handlePress = () => {
    if (this.props.disabled) {
      return
    }

    this.props.onPress()
    this.pressDownTimeout = setTimeout(this.handlePress, this.pressDownDelay)
    this.pressDownDelay = this.pressDownDelay / 2
  }

  handleMouseDown = () => {
    if (this.props.disabled) {
      return
    }

    this.handlePress()
  }

  handleMouseUp = () => {
    if (this.props.disabled) {
      return
    }

    if (this.pressDownTimeout) {
      clearTimeout(this.pressDownTimeout)
      this.pressDownTimeout = null
    }
    this.pressDownDelay = PRESS_DOWN_DELAY
  }

  handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
  }

  render() {
    const { direction, disabled } = this.props
    return (
      <Button
        size="s"
        paddingLeft="0"
        paddingRight="0"
        noBorder
        icon={`arrow${direction}`}
        onMouseOut={this.handleMouseUp}
        onPointerDown={this.handleMouseDown}
        onPointerUp={this.handleMouseUp}
        onClick={this.handleClick}
        disabled={disabled}
      />
    )
  }
}

export default DimensionButton
