import { graphql } from '~/gql'

const GET_CLOUDINARY_SIGNATURE_QUERY = graphql(`
  query GetCloudinarySignature {
    CloudinarySignature {
      timestamp
      signature
      api_key
      url
    }
  }
`)

export default GET_CLOUDINARY_SIGNATURE_QUERY
