export function bisect(a, b, fn, epsilon, target) {
  let a_T, b_T, midpoint, midpoint_T
  while (Math.abs(b - a) > 2 * epsilon) {
    midpoint = (b + a) / 2
    a_T = fn(a)
    b_T = fn(b)
    midpoint_T = fn(midpoint)
    if ((a_T - target) * (midpoint_T - target) < 0) b = midpoint
    else if ((b_T - target) * (midpoint_T - target) < 0) a = midpoint
    else return -999
  }
  return midpoint
}
export function secant(a, b, fn, epsilon) {
  let f1 = fn(a)
  if (Math.abs(f1) <= epsilon) return a
  let f2 = fn(b)
  if (Math.abs(f2) <= epsilon) return b
  let slope, c, f3
  for (var i = 0; i < 100; i++) {
    slope = (f2 - f1) / (b - a)
    c = b - f2 / slope
    f3 = fn(c)
    if (Math.abs(f3) < epsilon) return c
    a = b
    b = c
    f1 = f2
    f2 = f3
  }
  return NaN
}
export function getSensation(pmv) {
  if (pmv < -2.5) return 'Cold'
  else if (pmv < -1.5) return 'Cool'
  else if (pmv < -0.5) return 'Slightly Cool'
  else if (pmv < 0.5) return 'Neutral'
  else if (pmv < 1.5) return 'Slightly Warm'
  else if (pmv < 2.5) return 'Warm'
  else return 'Hot'
}
export function CtoF(x) {
  return (x * 9) / 5 + 32
}
export function FtoC(x) {
  return ((x - 32) * 5) / 9
}
export function roundTwoDecimals(value) {
  return Math.round(value * 100) / 100
}

export const util = { bisect, secant, getSensation, CtoF, FtoC, roundTwoDecimals }
