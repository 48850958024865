import React from 'react'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import get from 'lodash-es/get'

import { getTitle } from 'config/titles'

import { UPDATE_FACILITY_MUTATION } from 'client/mutations'
import {
  ALL_FACILITIES_QUERY,
  GET_FACILITY_QUERY,
  GET_QUOTE_QUERY,
  GET_VERSION_FACILITY,
} from 'client/queries'

import FacilityForm from '../../FacilityForm'

const EditFacilityModal = ({ ...props }) => (
  <div>
    <Helmet>
      <title>{getTitle('editFacility')}</title>
    </Helmet>
    <FacilityForm title="Edit Facility" buttonText="Save Facility" {...props} />
  </div>
)

const mapStateToProps = ({ auth, quote }) => ({
  userId: auth.userId,
  opportunityId: get(quote, 'opportunity.id'),
})

export default compose(
  appConnect(mapStateToProps),
  graphql(GET_FACILITY_QUERY, {
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
      fetchPolicy: 'network-only',
    }),
    name: 'facilityData',
  }),
  graphql(UPDATE_FACILITY_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onSubmit: variables =>
        mutate({
          variables: {
            opportunityId: ownProps.opportunityId,
            ...variables,
          },
          refetchQueries: [
            {
              query: GET_VERSION_FACILITY,
              name: 'GetVersionFacility',
            },
            {
              query: ALL_FACILITIES_QUERY,
              name: 'allFacilitiesData',
            },
            {
              query: GET_FACILITY_QUERY,
              variables: {
                id: ownProps.match.params.id,
                floorId: ownProps.match.params.floorId || null,
                versionId: ownProps.match.params.versionId || null,
              },
              name: 'facilityData',
            },
            {
              query: GET_QUOTE_QUERY,
              variables: {
                id: get(ownProps, 'facilityData.facility.floor.version.id'),
              },
            },
          ],
        }),
    }),
  }),
  withRouter
)(EditFacilityModal)
