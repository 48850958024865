import styled from 'styled-components'

import { truncate, theme } from 'lib/styleUtils'

const Label = styled.b`
  display: block;
  font-size: ${theme('fonts.sizes.xxs')};
  text-transform: uppercase;

  ${truncate('66px')};
`

export default Label
