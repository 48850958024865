import { graphql } from '~/gql'

export default graphql(`
  query ProductGroups {
    ProductGroups {
      products
      label
    }
  }
`)
