import React from 'react'

import TemplatesModal from '../TemplatesModal'

import GableScreen from './screens/GableScreen'
import MonoslopeScreen from './screens/MonoslopeScreen'

const screens = [
  {
    key: 'gable',
    title: 'Gable',
    component: GableScreen,
    icon: 'gable',
  },
  {
    key: 'monoslope',
    title: 'Monoslope',
    component: MonoslopeScreen,
    icon: 'monoslope',
  },
]

const RoofTemplatesModal = props => (
  <TemplatesModal title="Roof Templates" screens={screens} {...props} />
)

export default RoofTemplatesModal
