import { graphql } from '~/gql'

const CREATE_PROCESS_JOB_MUTATION = graphql(`
  mutation CreateProcessJob($facilityId: ID!, $fileUrl: String!) {
    process(facilityId: $facilityId, fileUrl: $fileUrl) {
      id
    }
  }
`)

export default CREATE_PROCESS_JOB_MUTATION
