import { graphql } from '~/gql'

export default graphql(`
  query ProductFanSpeed(
    $productId: ID!
    $variationId: ID
    $fanSpeedId: String!
    $heightFromFloor: Int!
  ) {
    Product(id: $productId) {
      id
      variations {
        id
        cfdId
      }
      fanSpeed(
        speed: $fanSpeedId
        heightFromFloor: $heightFromFloor
        variationId: $variationId
      ) {
        id
        speed
        power
        heightFromFloor
      }
    }
  }
`)
