import styled from 'styled-components'

import Button from 'components/UIKit/Button'

const AddImageButton = styled(Button)`
  cursor: pointer;
  display: inline-flex;
  margin-right: ${props => props.theme.spacing.s};
`

export default AddImageButton
