import { graphql } from '~/gql'

const UPDATE_FLOOR_MUTATION = graphql(`
  mutation UpdateFloor($id: ID!, $name: String, $currentVersionId: ID) {
    floor: updateFloor(
      id: $id
      name: $name
      currentVersionId: $currentVersionId
    ) {
      ...FloorFragment
    }
  }
`)

export default UPDATE_FLOOR_MUTATION
