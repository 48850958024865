import React, { useState } from 'react'
import { appConnect } from "~/store/hooks";
import InlineSearch from 'components/InlineSearch'
import Flex, { FlexItem } from 'components/UIKit/Flex'
import H1 from 'components/UIKit/H1'
import Icon from 'components/UIKit/Icon'
import Space from 'components/UIKit/Space'

import { useQuery } from '@apollo/client'

import FACILITY_SEARCH_QUERY from 'client/queries/facilitySearchQuery'
import { graphql } from '~/gql'
import { omit } from 'lodash-es'
import styled from 'styled-components'
import MutationButton from 'components/MutationButton'
import { batchReassignFacilitiesMutation } from 'client/mutations'

const userListQuery = graphql(`
  query users($filterByText: String!) {
    users(filterByText: $filterByText) {
      users {
        name
        email
        id
      }
      count
    }
  }
`)

const ReassignFacilities = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [userSearchQuery, setUserSearchQuery] = useState('')
  const [selectedFacilities, setSelectedFacilities] = useState([])
  const [selectedUser, setSelectedUser] = useState()

  return (
    <>
      <Space bottom="base">
        <Flex alignItems="center">
          <Flex>
            <FlexItem>
              <InlineSearch
                query={searchQuery}
                onChange={event => setSearchQuery(event.target.value)}
              />
            </FlexItem>
            <FlexItem>
              <H1 flush>
                <Icon name={'clipboard'} color="subdued" marginRight="s" />
                Reassign Facilities
              </H1>
            </FlexItem>
          </Flex>
        </Flex>
      </Space>
      <Space bottom="base">
        <Flex>
          <FlexItem>
            {searchQuery && (
              <FacilityQueryResults
                searchQuery={searchQuery}
                selectFacility={f => {
                  setSelectedFacilities([...selectedFacilities, f])
                  selectedFacilities.includes(f)
                    ? setSelectedFacilities(
                        selectedFacilities.filter(i => i !== f)
                      )
                    : setSelectedFacilities([...selectedFacilities, f])
                }}
                selectedFacilities={selectedFacilities}
              />
            )}
          </FlexItem>
        </Flex>
      </Space>
      <Space bottom="base">
        <Flex>
          <FlexItem>
            {!!selectedFacilities.length &&
              selectedFacilities.map((f, idx) => {
                return (
                  <OnHoverContainer background="#696969" key={idx}>
                    <Flex justifyContent="space-between">
                      <FlexItem>{f.name}</FlexItem>
                      <FlexItem>
                        <Icon
                          color="red"
                          name="trash"
                          size="14"
                          onClick={e =>
                            setSelectedFacilities(
                              selectedFacilities.filter(_f => _f !== f)
                            )
                          }
                        />
                      </FlexItem>
                    </Flex>
                  </OnHoverContainer>
                )
              })}
          </FlexItem>
        </Flex>
      </Space>
      <Space bottom="base">
        <Flex alignItems="center">
          <Flex>
            <FlexItem>
              <InlineSearch
                query={userSearchQuery}
                onChange={event => setUserSearchQuery(event.target.value)}
              />
            </FlexItem>
            <FlexItem>
              <H1 flush>
                <Icon name={'clipboard'} color="subdued" marginRight="s" />
                Destination User
              </H1>
            </FlexItem>
          </Flex>
        </Flex>
      </Space>
      <Space bottom="base">
        <Flex>
          <FlexItem>
            {userSearchQuery && (
              <UserQueryResults
                searchQuery={userSearchQuery}
                select={u => {
                  setSelectedUser(u)
                }}
                selectedUser={selectedUser}
              />
            )}
          </FlexItem>
        </Flex>
      </Space>
      <Space bottom="base">
        <Flex>
          <FlexItem>
            {selectedUser && !!selectedFacilities.length && (
              <>
                <div>
                  Assigning {selectedFacilities.length} facilities to user
                  {selectedUser.name} with email {selectedUser.email}
                </div>
                <MutationButton
                  label="Submit"
                  variables={{
                    ids: selectedFacilities.map(f => f.id),
                    author: omit(selectedUser, '__typename'),
                  }}
                  mutation={batchReassignFacilitiesMutation}
                />
              </>
            )}
          </FlexItem>
        </Flex>
      </Space>
    </>
  )
}

const FacilityQueryResults = ({
  searchQuery,
  selectFacility = () => {},
  selectedFacilities = [],
}) => {
  const { loading, error, data } = useQuery(FACILITY_SEARCH_QUERY, {
    variables: { filter: { name_contains: searchQuery } },
  })

  if (loading) {
    return <>Loading...</>
  }

  if (error) {
    return 'Error'
  }

  if (data) {
    return (
      <>
        <div>
          {data.facilitySearch.facilities.map((f, idx) => {
            const selected = selectedFacilities.includes(f)

            return (
              <div
                style={{
                  margin: 5,
                  padding: 5,
                  borderRadius: 10,
                  backgroundColor: selected ? 'dimgray' : 'inherit',
                }}
                onClick={() => {
                  selectFacility(f)
                }}
                key={idx}
              >
                {f.name} {f.author.name} {f.id}
              </div>
            )
          })}
        </div>
      </>
    )
  }
}

const UserQueryResults = ({ searchQuery, select = () => {}, selectedUser }) => {
  const { loading, error, data } = useQuery(userListQuery, {
    variables: { filterByText: searchQuery },
  })

  if (loading) {
    return <>Loading...</>
  }

  if (error) {
    return <>Error</>
  }

  if (data) {
    return (
      <>
        <div>
          {data.users.users.map((u, udx) => {
            const selected = selectedUser === u
            return (
              <div
                style={{ backgroundColor: selected ? 'dimgray' : 'inherit' }}
                onClick={() => {
                  select(u)
                }}
                key={udx}
              >
                {u.name} {u.email}
              </div>
            )
          })}
        </div>
      </>
    )
  }
}

const OnHoverContainer = styled.div`
  transition: all 0.3s ease-in-out;
  &:hover {
    ${props => `background : ${props.background};`}
  }
`

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default appConnect(mapStateToProps, mapDispatchToProps)(ReassignFacilities)
