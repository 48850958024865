import { TYPES } from './constants'

export function getDistanceUnits({ units }) {
  return units[TYPES.DISTANCE]
}
export function getTemperatureUnits({ units }) {
  return units[TYPES.TEMPERATURE]
}
export function getVelocityUnits({ units }) {
  return units[TYPES.VELOCITY]
}
