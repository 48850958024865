import { graphql } from '~/gql'

export default graphql(`
  query ProductVariationAirVelocities(
    $id: ID!
    $fanSpeedId: String!
    $heightFromFloor: Int!
  ) {
    ProductVariation(id: $id) {
      id
      airVelocities {
        id
        variationId
        airfoilHeight
        evaluationHeight
        distanceFromFan
        offsetFromFanCenter
        airVelocity
        angle
      }
      product {
        id
        fanSpeed(speed: $fanSpeedId, heightFromFloor: $heightFromFloor) {
          id
          speed
          power
          heightFromFloor
        }
      }
    }
  }
`)
