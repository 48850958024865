import withUser from 'client/decorators/withUser'
import { batchReassignFacilitiesMutation } from 'client/mutations'

import MutationButton from 'components/MutationButton'
import Flex, { FlexItem } from 'components/UIKit/Flex'
import Icon from 'components/UIKit/Icon'
import { graphql } from '~/gql'
import { get, omit } from 'lodash-es'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { compose } from 'redux'
import styled from 'styled-components'

export const userListQuery = graphql(`
  query users($filterByText: String!) {
    users(filterByText: $filterByText) {
      users {
        name
        email
        id
      }
      count
    }
  }
`)

const ReassignAllFacilities = props => {
  const [selectedUser, setSelectedUser] = useState()
  const [searchQuery, setSearchQuery] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [reassignSuccess, setReassignSuccess] = useState()
  const { loading, error, data } = useQuery(userListQuery, {
    variables: { filterByText: searchQuery },
  })

  const facilityCount = get(props, 'user.facilities.length')
  const ids = get(props, 'user.facilities', []).map(f => f.id)

  return (
    <div style={{ backgroundColor: 'white', padding: 10, borderRadius: 5 }}>
      <h4>Reassign All of your Facilities</h4>
      <div>
        User Search
        <Icon style={{ margin: 5 }} size="12" name="search" />
        <input
          style={{ margin: 5 }}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search.."
        ></input>
        {loading && <>Loading...</>}
        {error && <>error</>}
        {data &&
          searchQuery !== '' &&
          data.users.users.map((u, udx) => (
            <OnHoverContainer
              background="#696969"
              onClick={e => setSelectedUser(u)}
              key={udx}
            >
              <div
                style={{
                  backgroundColor: u === selectedUser ? '#696969' : 'inherit',
                  marginTop: 2,
                  marginBottom: 2,
                  padding: 2,
                  borderRadius: 5,
                }}
                onClick={e => setSelectedUser(u)}
                key={udx}
              >
                {u.email}
              </div>
            </OnHoverContainer>
          ))}
        {selectedUser && (
          <>
            <hr></hr>
            <Flex>
              <FlexItem style={{ color: 'red' }}>
                WARNING: Assign ALL {facilityCount} facilities to user:
              </FlexItem>
              <FlexItem>{selectedUser.email}</FlexItem>
            </Flex>
            <div>
              To confirm, please type your email:
              <div>
                <input
                  style={{ margin: 5 }}
                  onPaste={e => {
                    e.preventDefault()
                    return false
                  }}
                  onChange={e => setUserEmail(e.target.value)}
                ></input>
                {props.user.email}

                <Icon
                  size="12"
                  style={{ margin: 5 }}
                  name={
                    userEmail === props.user.email ? 'checkCircle' : 'cross'
                  }
                  color={userEmail === props.user.email ? 'green' : 'red'}
                />
              </div>
            </div>
            <MutationButton
              label="Submit"
              variables={{
                ids,
                author: omit(selectedUser, '__typename'),
              }}
              disabled={!(selectedUser && userEmail === props.user.email)}
              mutation={batchReassignFacilitiesMutation}
              onSuccess={() => {
                setReassignSuccess(true)
              }}
            />
            {reassignSuccess && (
              <MutationButton
                label="Undo"
                variables={{
                  ids,
                  author: {
                    id: props.user.id,
                  },
                }}
                mutation={batchReassignFacilitiesMutation}
                onSuccess={() => {
                  setReassignSuccess(false)
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

const OnHoverContainer = styled.div`
  transition: all 0.3s ease-in-out;
  &:hover {
    ${props => `background : ${props.background};`}
  }
`

ReassignAllFacilities.propTypes = {
  user: PropTypes.object,
}

export default compose(withUser)(ReassignAllFacilities)
