import styled from 'styled-components'

const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;
  margin: 0;
  overflow-x: auto;
  padding: 0;
`

export default List
