import PropTypes from 'prop-types'

import Container from './styled/Container'

const ToolbarItem = Container

ToolbarItem.propTypes = {
  borderLeft: PropTypes.bool,
  borderRight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  responsive: PropTypes.bool,
  separator: PropTypes.string,
  space: PropTypes.string,
}

export default /** @type any */(ToolbarItem)
