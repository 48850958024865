import styled from 'styled-components'

import { lighten } from 'lib/styleUtils'

const MenusContainer = styled.div`
  background-color: ${props => lighten(props.theme.colors.light.subdued, 0.2)};
  height: 100%;
  min-height: 320px;
  position: relative;
`

export default MenusContainer
