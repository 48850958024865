import { graphql } from '~/gql'

const CREATE_VERSION_MUTATION = graphql(`
  mutation CreateVersion($floorId: ID!, $name: String!, $fromVersionId: ID) {
    createVersion(
      floorId: $floorId
      name: $name
      fromVersionId: $fromVersionId
    ) {
      ...VersionFragment
    }
  }
`)

export default CREATE_VERSION_MUTATION
