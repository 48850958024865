import React from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'
import { Route } from 'react-router-dom'

import routes from 'config/routes'
import {
  AIRFLOW_PANEL,
  CFD_PANEL,
  CONTROLS_PANEL,
  LAYERS_PANEL,
  PERFORMANCE_METRICS_PANEL,
  SNAPSHOTS_PANEL,
} from 'store/panel/types'
import { set2D, set3D } from 'store/camera'
import { setCFDLayer } from 'store/cfd'
import { deselectObjects } from 'store/selectedObjects'
import { anyObjectSelected } from 'store/selectedObjects/selectors'
import { togglePanel, hidePanel, showPanel } from 'store/panel'
import StatusBadge from 'components/UIKit/StatusBadge'
import Switch from 'components/UIKit/Switch'
import { ToolbarGroup, ToolbarItem } from 'components/UIKit/Toolbar'
import { CFDImagesModal } from 'components/Modals/CFDImagesModal'
import SnapshotModal from 'components/Modals/SnapshotModal'
import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

function MiddleTouch(props = {}) {
  let toolTipCFD = 'Visualize CFD data for this facility'
  let toolTipPerformanceMetrics = 'View performance metrics for this facility'
  let performanceStatusComponent = (
    <StatusBadge
      icon="help"
      tooltipTitle={toolTipPerformanceMetrics}
      trigger={['click']}
      placement="right"
    />
  )
  let cfdStatusComponent = (
    <StatusBadge
      icon="help"
      tooltipTitle={toolTipCFD}
      trigger={['click']}
      placement="right"
    />
  )
  let performanceMetricsDisabled = false

  if (props.cfdStatus === 'FAILED') {
    toolTipCFD = 'Failed to generate CFD data'
    toolTipPerformanceMetrics = 'Unable to generate performance metrics'
    performanceStatusComponent = (
      <StatusBadge
        type="error"
        tooltipTitle={toolTipPerformanceMetrics}
        trigger={['click']}
        placement="right"
      />
    )
    cfdStatusComponent = (
      <StatusBadge
        type="error"
        tooltipTitle={toolTipCFD}
        trigger={['click']}
        placement="right"
      />
    )
    performanceMetricsDisabled = true
  }
  if (props.cfdStatus === 'COMPLETE') {
    toolTipCFD = 'CFD data is ready to view'
    performanceStatusComponent = <StatusBadge type="success" />
    cfdStatusComponent = (
      <StatusBadge
        type="success"
        trigger={['click']}
        tooltipTitle={toolTipCFD}
        placement="right"
      />
    )
  }
  if (props.cfdStatus === 'IN_PROGRESS' || props.cfdIsUploading) {
    toolTipCFD = 'Processing CFD data…'
    toolTipPerformanceMetrics = 'Processing CFD data…'
    performanceStatusComponent = (
      <StatusBadge
        type="warning"
        icon="refresh"
        animated
        tooltipTitle={toolTipPerformanceMetrics}
        trigger={['click']}
        placement="right"
      />
    )
    cfdStatusComponent = (
      <StatusBadge
        type="warning"
        icon="refresh"
        animated
        tooltipTitle={toolTipCFD}
        trigger={['click']}
        placement="right"
      />
    )
    performanceMetricsDisabled = true
  }
  if (!props.cfdIsValid && props.cfdStatus === 'COMPLETE') {
    toolTipCFD = 'CFD data may be invalid'
    performanceStatusComponent = (
      <StatusBadge
        type="error"
        tooltipTitle={toolTipCFD}
        trigger={['click']}
        placement="right"
      />
    )
    cfdStatusComponent = (
      <StatusBadge
        type="error"
        tooltipTitle={toolTipCFD}
        trigger={['click']}
        placement="right"
      />
    )
  }
  if (!props.cfdIsValid && props.cfdStatus === 'UNAVAILABLE') {
    toolTipPerformanceMetrics = 'Unable to generate performance metrics'
    performanceStatusComponent = (
      <StatusBadge
        icon="help"
        tooltipTitle={toolTipPerformanceMetrics}
        trigger={['click']}
        placement="right"
      />
    )
    performanceMetricsDisabled = true
  }

  return (
    <ToolbarGroup size="100%" alignment="center">
      <ToolbarItem separator="left" paddingLeft="base" paddingRight="base">
        <Switch
          name="3d"
          label="3D View"
          onClick={event => {
            event.stopPropagation()
            event.preventDefault()
            return props.rotation < 0
              ? props.onToggle3d(CONTROLS_PANEL)
              : props.onToggle2d(CONTROLS_PANEL)
          }}
          isChecked={props.rotation >= 1}
          responsive
        />
      </ToolbarItem>
      <ToolbarItem separator="left" paddingLeft="base" paddingRight="base">
        <Switch
          name="layers"
          label="Layers"
          onClick={event => {
            props.onTogglePanel(LAYERS_PANEL)
            if (props.isObjectSelected) props.deselectAllObjects()
          }}
          isChecked={props.type === LAYERS_PANEL}
          responsive
        />
      </ToolbarItem>
      <ToolbarItem separator="left" paddingLeft="base" paddingRight="base">
        <Switch
          name="snapshots"
          label="Snapshots"
          onClick={event => {
            props.onTogglePanel(SNAPSHOTS_PANEL)
          }}
          isChecked={props.type === SNAPSHOTS_PANEL}
          responsive
        />
      </ToolbarItem>
      <ToolbarItem separator="left" paddingLeft="base" paddingRight="base">
        <Switch
          name="airflow"
          label="Airflow"
          onClick={event => {
            props.onTogglePanel(AIRFLOW_PANEL)
          }}
          isChecked={props.type === AIRFLOW_PANEL}
          responsive
        />
      </ToolbarItem>
      <ToolbarItem separator="left" paddingLeft="base" paddingRight="base">
        <Switch
          name="cfd"
          label="CFD"
          onClick={event => {
            props.onTogglePanel(CFD_PANEL)
          }}
          isChecked={props.type === CFD_PANEL}
          disabled={props.loadingCfdStatus}
          responsive
        />
        {cfdStatusComponent}
      </ToolbarItem>
      <ToolbarItem
        separator="left"
        borderRight
        paddingLeft="base"
        paddingRight="base"
      >
        <Switch
          name="metrics"
          label="Metrics"
          onClick={event => {
            props.onTogglePanel(PERFORMANCE_METRICS_PANEL)
          }}
          isChecked={props.type === PERFORMANCE_METRICS_PANEL}
          disabled={performanceMetricsDisabled}
          responsive
        />
        {performanceStatusComponent}
      </ToolbarItem>
      <SentryRoute
        path={`${props.match.url}${routes.modals.snapshot}`}
        render={modalProps => (
          <SnapshotModal
            parentRoute={props.match.url}
            facilityId={props.match.params.id}
            versionId={props.versionId}
            {...modalProps}
          />
        )}
      />
      <SentryRoute
        path={`${props.match.url}${routes.modals.cfdImages}`}
        render={() => <CFDImagesModal parentRoute={props.match.url} />}
      />
    </ToolbarGroup>
  )
}

MiddleTouch.propTypes = {
  cfdIsUploading: PropTypes.bool,
  cfdIsValid: PropTypes.bool,
  cfdStatus: PropTypes.string,
  deselectAllObjects: PropTypes.func,
  facility: PropTypes.object,
  isObjectSelected: PropTypes.bool,
  loadingCfdStatus: PropTypes.bool,
  match: PropTypes.object,
  onToggle3d: PropTypes.func,
  onToggle2d: PropTypes.func,
  onTogglePanel: PropTypes.func,
  rotation: PropTypes.number,
  type: PropTypes.string,
  versionId: PropTypes.string,
}

const mapStateToProps = (state) => {
  const { camera, cfd, layers, panel } = state
  return {
    cfdVisible: layers.layers.CFD.visible,
    cfdIsValid: cfd.isValid,
    cfdType: cfd.selectedLayer.type,
    cfdLevel: cfd.selectedLayer.level,
    cfdIsUploading: cfd.isUploading,
    isObjectSelected: anyObjectSelected(state),
    type: panel.bottom.visiblePanel,
    rotation: camera.rotation,
  }
}

const mapDispatchToProps = dispatch => ({
  deselectAllObjects() {
    dispatch(deselectObjects({}))
  },
  onSetCFDLayer({ level, type, url }) {
    dispatch(setCFDLayer({ level, type, url }))
  },
  onToggle2d(panel) {
    dispatch(set2D())
    dispatch(hidePanel({ type: panel }))
  },
  onToggle3d(panel) {
    dispatch(set3D())
    dispatch(showPanel({ type: panel }))
  },
  onTogglePanel(panel) {
    dispatch(togglePanel({ type: panel }))
  },
})

export default appConnect(mapStateToProps, mapDispatchToProps)(MiddleTouch)
