import React from 'react'
import { Helmet } from 'react-helmet'

import { getTitle } from 'config/titles'

import Dashboard from 'components/Dashboard'
import Reports from 'components/Reports'

export const ReportsScreen = () => {
  return (
    <>
      <Helmet>
        <title>{getTitle('Reports')}</title>
      </Helmet>
      <Dashboard>
        <Reports />
      </Dashboard>
    </>
  )
}
