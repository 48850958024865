import { graphql } from '~/gql'

export const GET_FAN_POWERS_QUERY = graphql(`
  query ProductFanSpeeds($params: [FanSpeedsInput!]!) {
    ProductFanSpeeds(params: $params) {
      id
      power
    }
  }
`)
