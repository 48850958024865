import styled from 'styled-components'

import { theme } from 'lib/styleUtils'
import VariantText from 'components/UIKit/VariantText'

const Title = styled(VariantText)`
  font-weight: ${theme('fonts.weights.semiBold')};
`

Title.defaultProps = {
  size: 'l',
}

export default Title
