import { graphql } from '~/gql'

const GET_APP_SETTINGS_QUERY = graphql(`
  query GetAppSettings {
    AppSettings {
      ...AppSettingsFragment
    }
  }
`)

export default GET_APP_SETTINGS_QUERY
