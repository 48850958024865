import React, { PropsWithChildren } from 'react'
import PropTypes from 'prop-types'

import VariantText from '../VariantText'
import Icon from '../Icon'

import Container from './styled/Container'
import { FontSize } from '~/config/theme'

const ActionLink = ({ onClick, size, bold, iconSize, children, ...rest }: PropsWithChildren<{ onClick: React.MouseEventHandler<HTMLAnchorElement>; size: FontSize; bold: boolean; iconSize: string|number }>) => (
  <Container noBorder onClick={onClick} {...rest}>
    <VariantText as="span" size={size} bold={bold}>
      {children} <Icon name="arrowRight" size={iconSize} />
    </VariantText>
  </Container>
)

ActionLink.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.any.isRequired,
  iconSize: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
}

ActionLink.defaultProps = {
  bold: true,
  iconSize: '16',
  onClick: () => null,
  size: 's',
}

export default ActionLink
