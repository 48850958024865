import React, { Component } from 'react'
import omit from 'lodash-es/omit'

import Placeholder from './styled/Placeholder'
import StyledImage from './styled/Image'

type ImageProps = {
  alt?: string
  height?: string | number
  width?: string | number
  onLoaded?: (dimensions: { width: number; height: number }) => void
  src?: string
}

class Image extends Component<ImageProps> {
  state = {
    isLoaded: false,
    hasError: false,
  }
  nodeRef = React.createRef<HTMLImageElement>()

  handleLoad = () => {
    const { onLoaded } = this.props
    const width = this.nodeRef.current?.width ?? 0
    const height = this.nodeRef.current?.height ?? 0

    this.setState(
      {
        isLoaded: true,
      }
    )
    onLoaded?.({ width, height })
  }

  handleError = () => {
    this.setState({
      hasError: true,
    })
  }

  render() {
    const { isLoaded, hasError } = this.state
    const { src, alt, height, width, ...props } = this.props
    const placeholder = <Placeholder width={width} height={height} />

    if (hasError || src === null) {
      return placeholder
    }

    return (
      <>
        {!isLoaded && placeholder}
        <StyledImage
          ref={this.nodeRef}
          src={src}
          alt={alt}
          height={height}
          width={width}
          style={{height, width}}
          onLoad={this.handleLoad}
          onError={this.handleError}
          isLoaded={isLoaded}
          {...omit(props, ['onLoaded'])}
        />
      </>
    )
  }
}

export default Image
