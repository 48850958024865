import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.div`
  align-items: center;
  background-color: ${theme('colors.light.primary')};
  border-radius: 50%;
  box-shadow: ${theme('shadows.menu')};
  display: flex;
  height: ${theme('touchUI.buttonSize')};
  justify-content: center;
  margin-left: ${props => props.showDock && theme('spacing.base')};
  width: ${theme('touchUI.buttonSize')};
`

export default Container
