import styled from 'styled-components'

const Container = styled.div`
  max-height: ${props => props.height}px;
  max-width: ${props => props.width}px;
  position: relative;

  ${props =>
    props.centered &&
    `
    margin-left: auto;
    margin-right: auto;
  `};
`

export default Container
