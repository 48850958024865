import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Close = styled.a`
  color: ${theme('colors.light.fg')};
  display: block;
  cursor: pointer;
  position: absolute;
  right: ${theme('spacing.s')};
  text-decoration: none;
  top: ${theme('spacing.s')};
  z-index: 100;
  cursor: pointer;
`

export default Close
