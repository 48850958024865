import type { ComponentProps, PropsWithChildren, ReactNode } from 'react'
import { useAppSelector } from "~/store/hooks";

import { isTouchUI } from 'store/userInterface/selectors'

import DropdownIcon from '../DropdownIcon'

import {
  ButtonContainer,
  LinkContainer,
  AnchorContainer,
} from './styled/Container'
import IconLeft from './styled/IconLeft'
import IconRight from './styled/IconRight'
import Label from './styled/Label'
import { FontSize, Spacing, Variant } from '~/config/theme'
import { RootState } from '~/store'
import icons from '~/config/icons'

type BaseButtonProps = {
  dropdown?: boolean
  dropdownReverse?: boolean
  icon?: string | keyof typeof icons
  iconColor?: string
  iconRight?: string
  iconRightColor?: string
  isDropdownVisible?: boolean
  label?: ReactNode
  responsive?: boolean
  variant?: Variant
  truncate?: string

  noBorder?: boolean
  primary?: boolean
  danger?: boolean
  display?: string
  fullWidth?: boolean
  disabled?: boolean
  paddingLeft?: string
  paddingRight?: string
  size?: Spacing
}

type LinkButtonProps = BaseButtonProps & ComponentProps<typeof LinkContainer> & { href: string }
type AnchorButtonProps = BaseButtonProps & ComponentProps<typeof AnchorContainer> & { to: string }
type PlainButtonProps = BaseButtonProps & ComponentProps<typeof ButtonContainer> & {}

type ButtonProps =
  | LinkButtonProps
  | AnchorButtonProps
  | PlainButtonProps

export default function Button(props: PropsWithChildren<ButtonProps>) {
  const {
    label,
    icon,
    children,
    dropdown,
    dropdownReverse = false,
    size,
    iconColor,
    truncate,
    responsive,
    iconRight,
    iconRightColor,
    isDropdownVisible,
  } = props
  const touchUI = useAppSelector(state => isTouchUI(state))

  let Container: any = 'to' in props ? LinkContainer :
    'href' in props ? AnchorContainer :
    ButtonContainer

  const setIconSize = (size?: Spacing) => {
    if (size === 's') {
      return '12'
    }
    return '14'
  }

  return (
    <Container {...props}>
      {icon && (
        <IconLeft
          name={icon as keyof typeof icons}
          size={touchUI ? 18 : setIconSize(size)}
          hasRightSibling={label || children}
          color={iconColor}
          responsive={responsive}
        />
      )}
      {label && (
        <Label
          truncate={truncate}
          size={size}
          responsive={responsive}
        >
          {label}
        </Label>
      )}
      {children}
      {iconRight && (
        <IconRight
          name={iconRight as keyof typeof icons}
          size={touchUI ? 18 : setIconSize(size)}
          hasLeftSibling={icon || label || children}
          color={iconRightColor}
        />
      )}
      {dropdown && (
        <DropdownIcon
          size={touchUI ? '18' : setIconSize(size)}
          flush={!icon && !label && !children}
          reverse={dropdownReverse}
          isActive={isDropdownVisible}
        />
      )}
    </Container>
  )
}
