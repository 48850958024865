import { graphql } from '~/gql'

const GET_AR_IMAGES_QUERY = graphql(`
  query GetARImages($catalog: String!, $model: String!, $size: String) {
    ARImages(catalog: $catalog, model: $model, size: $size) {
      url
    }
  }
`)

export default GET_AR_IMAGES_QUERY
