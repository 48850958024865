import Camera from './camera'
import Tool from './tool'
import TouchObject from './touchObject'
import get from 'lodash-es/get'
import Wall from './wall'
import store from 'store'
import { getThreeHexFromTheme } from 'lib/utils'

import * as THREE from 'three'

class DrawTool extends Tool {
  constructor(toolProps, scene, productVariations) {
    super()

    this.name = 'DRAW_TOOL'
    this.scene = scene
    this.productVariations = productVariations

    this.touchObject = new TouchObject(Camera.current, this.scene)

    this.obj3d = new THREE.Object3D()
    this.cursor = this.createCursor(1)

    const appState = store.getState()
    this.layer = appState.layers.currentLayer
    this.units = appState.objects.present.units

    this.obj3d.add(this.cursor)

    this.startedDrawing = false
  }

  toolDown(projectedMousePos) {
    if (!this.touchObject) {
      this.touchObject = new TouchObject(Camera.current, this.scene)
    }
    this.startedDrawing = true
    this.touchObject.addLine()
  }

  toolUp() {
    this.startedDrawing = false

    if (this.touchObject) {
      // Check for drawn products
      const model = get(this.productVariations, '[0]')
      const isX = this.touchObject.checkForX()
      if (isX && model) {
        this.touchObject.createFan({ model, ...isX })
      }

      // Check for drawn rectangle walls
      const isRectangle = this.touchObject.checkForRectangle()
      if (isRectangle) {
        const units = store.getState().objects.present.units
        const model = Wall.createModel(
          isRectangle.centerLines,
          units,
          'EXTERIOR_WALLS',
          { convertFromNativeUnits: true }
        )

        this.touchObject.createWall({ model, ...isRectangle })
      }

      // Check for drawn circle walls
      const isCircle = this.touchObject.checkForCircle()
      if (isCircle) {
        const units = store.getState().objects.present.units
        const model = Wall.createModel(
          isCircle.centerLines,
          units,
          'EXTERIOR_WALLS',
          { convertFromNativeUnits: true }
        )

        this.touchObject.createWall({ model, ...isCircle })
      }
    }
  }

  toolMoved(projectedMousePos) {
    if (this.touchObject && this.startedDrawing) {
      this.touchObject.addPosition(projectedMousePos)
    }
  }

  toolFinish() {}

  activate(mousePos) {
    this.cursor.position.set(mousePos.x, mousePos.y, 0)
  }

  deactivate() {
    this.touchObject.removeAllLines()
  }

  getSnapRegions(facility, draggedObject) {
    return []
  }

  createCursor(size) {
    const geometry = new THREE.BoxGeometry(size, size, 1)
    const material = new THREE.MeshBasicMaterial({
      color: getThreeHexFromTheme('three.activeToolCursor'),
      depthTest: false,
    })
    return new THREE.Mesh(geometry, material)
  }
}

export default DrawTool
