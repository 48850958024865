import Tool from './tool'
import Util from './util'
import Units from './units'
import Camera from './camera'

import store from 'store'
import { addComfortPoint } from 'store/objects'
import { showAlert } from 'store/alert'
import { isTouchUI } from 'store/userInterface/selectors'
import zoomConfig from 'config/zoom'

import * as THREE from 'three'

class ComfortPointTool extends Tool {
  constructor(props = {}) {
    super()

    this.name = 'COMFORT_POINT_TOOL'
    this.units = 'INCHES'
    this.props = props
    this.currentlyDrawing = false

    this.init()
  }

  getModel() {
    return {
      id: Util.guid(),
      position: Units.nativeToUnitsV(this.units, this.comfortPoint.position),
    }
  }

  setPosition = ({ x, y }) => {
    this.comfortPoint.position.copy(new THREE.Vector3(x, y, 8))
  }

  init() {
    this.createVisual()
    this.createEventListeners()
  }

  createVisual() {
    this.obj3d.remove(this.comfortPoint)

    this.currentlyDrawing = true

    const texture = new THREE.TextureLoader().load(
      '/comfort-point-selected-sprite.png'
    )
    const spriteMaterial = new THREE.SpriteMaterial({ map: texture })
    this.comfortPoint = new THREE.Sprite(spriteMaterial)

    // Set the scale of the sprite based on the camera zoom
    let scale
    const camera = Camera.current
    if (camera.type === 'PerspectiveCamera') {
      const vector = new THREE.Vector3()
      const scaleFactor = zoomConfig.comfortPointScaleFactor
      const defaultDepth = zoomConfig.comfortPointDefaultDepth
      scale =
        (scaleFactor *
          vector
            .setFromMatrixPosition(this.comfortPoint.matrixWorld)
            .sub(camera.position)
            .length()) /
        defaultDepth
    } else {
      scale =
        (zoomConfig.comfortPointBase - camera.zoom * 100) *
        zoomConfig.comfortPointMultiplier
    }

    // Make sure the scale does not drop below 5
    if (scale < 10) scale = 10
    this.comfortPoint.scale.set(scale, scale, scale)
    this.comfortPoint.material.depthTest = false

    this.obj3d.add(this.comfortPoint)
    this.setPosition(Util.getCameraPosition())
  }

  createEventListeners = () => {
    document.addEventListener('keyup', this.handleKeyUp)
  }

  removeEventListeners = () => {
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyUp = e => {
    // Esc key up
    if (e.which === 27) {
      this.deactivate()
    }
  }

  insert = multiSelect => {
    this.deactivate()
    store.dispatch(
      addComfortPoint({ comfortPoint: this.getModel(), multiSelect })
    )
  }

  toolMoved(mousePos) {
    if (!this.currentlyDrawing) return

    this.setPosition({
      x: mousePos.x,
      y: mousePos.y,
    })
  }

  toolUp({ mousePos, multiSelect }) {
    if (!this.currentlyDrawing) return

    if (isTouchUI()) {
      this.setPosition({
        x: mousePos.x,
        y: mousePos.y,
      })
    }

    this.insert(multiSelect)
  }

  activate(mousePos) {
    if (isTouchUI()) {
      store.dispatch(
        showAlert({
          text: 'Tap the canvas to place the comfort point',
        })
      )
    } else {
      this.setPosition({
        x: mousePos.x,
        y: mousePos.y,
      })
    }
  }

  deactivate() {
    this.currentlyDrawing = false
    this.obj3d.remove(this.comfortPoint)
    this.removeEventListeners()
  }

  onPropsDidChange(nextProps) {
    this.currentlyDrawing = true
    this.props = {
      ...this.props,
      ...nextProps,
    }
    this.init()
  }

  getArrowDescriptions() {}
}

export default ComfortPointTool
