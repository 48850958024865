/*eslint no-undef: "error"*/

import * as T from 'three'
import isEmpty from 'lodash-es/isEmpty'
import vtkWorker from './vtk.worker?worker'

export class VTKLoader {
  constructor() {
    this.worker = new vtkWorker()
  }

  load = async (url, cacheKey, callback) => {
    try {
      if (isEmpty(url)) throw new Error('url cannot be empty!')
      this.worker.onmessage = event => {
        const { data } = event
        if (data.type === 'BufferGeometry') {
          callback(this.parseTransferableJSON(data))
        } else {
          callback(T.JSONLoader.prototype.parse(data))
        }
      }
      this.worker.postMessage({ url })
    } catch (err) {
      this.worker.terminate()
      throw err
    }
  }

  terminate = () => {
    this.worker.terminate()
  }

  parseTransferableJSON = json => {
    var geometry = new T.BufferGeometry()
    var index = json.data.index

    if (index) {
      geometry.setIndex(
        new T.BufferAttribute(index.array, index.itemSize, index.normalized)
      )
    }

    var attributes = json.data.attributes
    for (var key in attributes) {
      var attribute = attributes[key]
      geometry.setAttribute(
        key,
        new T.BufferAttribute(
          attribute.array,
          attribute.itemSize,
          attribute.normalized
        )
      )
    }

    var groups = json.data.groups || json.data.drawcalls || json.data.offsets

    if (groups) {
      for (var i = 0, n = groups.length; i !== n; ++i) {
        var group = groups[i]
        geometry.addGroup(group.start, group.count, group.materialIndex)
      }
    }

    var boundingSphere = json.data.boundingSphere
    if (boundingSphere) {
      var center = new T.Vector3()
      if (boundingSphere.center) {
        center.fromArray(boundingSphere.center)
      }

      geometry.boundingSphere = new T.Sphere(center, boundingSphere.radius)
    }

    return geometry
  }
}
