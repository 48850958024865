import React from 'react'
import { func, object } from 'prop-types'

import { DefaultQueryProvider } from 'client/providers'
import { GET_AR_OPTIONS_QUERY } from 'client/queries'

import Menu from '../Menu'

function ModelMenu({ product, handleSelect }) {
  return (
    <DefaultQueryProvider
      query={GET_AR_OPTIONS_QUERY}
      variables={{ catalog: product.catalog }}
    >
      {({ data: { AROptions: models } }) => {
        const items = models.map(item => ({ key: item.option }))
        return (
          <Menu
            items={items}
            handleSelect={handleSelect}
            value={product.model}
            disabled
          />
        )
      }}
    </DefaultQueryProvider>
  )
}

ModelMenu.propTypes = {
  product: object,
  handleSelect: func,
}

export default ModelMenu
