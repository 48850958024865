import React, { useState, Children, cloneElement, isValidElement } from 'react'
import PropTypes from 'prop-types'

import { TabsContainer } from 'components/UIKit/Tabs/styled/TabsContainer'

export const Tabs = ({
  activeIndex,
  onChange,
  children,
  size,
  splitEvenly,
}) => {
  const [uncontrolledIndex, setUncontrolledIndex] = useState(0)

  const childrenArray = Children.toArray(children)
  const selectedIndex = activeIndex ?? uncontrolledIndex
  const selectedContent = childrenArray.find((_, i) => i === selectedIndex)
    ?.props?.children

  return (
    <>
      <TabsContainer splitEvenly={splitEvenly}>
        {Children.map(children, (child, i) => {
          if (!isValidElement(child)) return null // TODO: remove after refactoring CFDPanel
          const onClick = e => {
            onChange && onChange(i)
            setUncontrolledIndex(i)
            child.props.onClick && child.props.onClick(e)
          }
          return cloneElement(child, {
            onClick,
            isActive: selectedIndex === i,
            title: child.props.title,
            children: null,
            splitEvenly,
            size,
          })
        })}
      </TabsContainer>
      {selectedContent}
    </>
  )
}

Tabs.propTypes = {
  children: PropTypes.node,
  activeIndex: PropTypes.number,
  onChange: PropTypes.func,
  size: PropTypes.string,
  splitEvenly: PropTypes.bool,
}

export default /** @type any */(Tabs)
