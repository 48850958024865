import styled from 'styled-components'

import theme, { FontSize } from 'config/theme'
import { resetListStyles } from 'lib/styleUtils'

import Flex from '../../Flex'

const size = ({ size }: { size: FontSize }) => {
  if (size) {
    return `font-size: ${theme.fonts.sizes[size]};`
  }
}

const List: any = styled(Flex)`
  ${resetListStyles};
  ${size};
`

export default List
