import styled, { ThemedStyledProps } from 'styled-components'

import { noSelect } from 'lib/styleUtils'
import { getZIndexOf } from 'lib/utils'

import Card from 'components/UIKit/Card'
import { Theme } from '~/config/theme'

const setHorizontalPosition = (props: { right?: boolean }) => (props.right ? `right: 0;` : `left: 0;`)

const setVerticalPosition = (props: ThemedStyledProps<{ attached?: boolean; bottom?: boolean }, Theme>) => {
  if (props.attached) {
    return `bottom: calc(100% + ${props.theme.borderWidth})`
  }

  return props.bottom ? `bottom: calc(100%);` : `top: calc(100%);`
}

const setBoxShadow = (props: ThemedStyledProps<{ adjustForTouch?: boolean; bottom?: boolean; }, Theme>) => {
  if (props.adjustForTouch) return `box-shadow: none;`
  return props.bottom
    ? `box-shadow: ${props.theme.shadows.menuReversed}`
    : `box-shadow: ${props.theme.shadows.menu}`
}

const setPadding = (props: ThemedStyledProps<{ adjustForTouch?: boolean }, Theme>) =>
  props.adjustForTouch ? `padding: 0 ${props.theme.spacing.s}` : `padding: 0`

const StyledCard = styled(Card)<{ attached?: boolean; bottom?: boolean; adjustForTouch?: boolean; right?: boolean; isVisible?: boolean }>`
  border-radius: 0;
  display: none;
  min-width: 250px;
  position: absolute;
  text-align: left;
  z-index: ${getZIndexOf('dropdown')};

  ${noSelect()};

  ${setPadding};
  ${setBoxShadow};
  ${setHorizontalPosition};
  ${setVerticalPosition};

  ${props =>
    props.bottom &&
    props.attached &&
    `border-bottom: ${props.theme.borderWidth} solid ${props.theme.colors.light.subdued};`};

  ${props => props.isVisible && `display: block;`};
`

export default StyledCard
