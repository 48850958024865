import React from 'react'
import PropTypes from 'prop-types'
import { Query, Mutation } from '@apollo/client/react/components'
import { Helmet } from 'react-helmet'
import get from 'lodash-es/get'
import pick from 'lodash-es/pick'

import { getTitle } from 'config/titles'

import { UPDATE_FACILITY_TEMPLATE_MUTATION } from 'client/mutations'
import { GET_FACILITY_TEMPLATE_QUERY } from 'client/queries'

import Alert from 'components/UIKit/Alert'
import TemplateForm from 'components/TemplateForm'

const EditFacilityModal = (props = {}) => {
  const id = get(props, 'match.params.id')
  return (
    <>
      <Helmet>
        <title>{getTitle('newFacilityTemplate')}</title>
      </Helmet>
      <Mutation
        mutation={UPDATE_FACILITY_TEMPLATE_MUTATION}
        variables={{ id }}
        key={id}
      >
        {(updateFacilityTemplate, { loading, error }) => (
          <Query query={GET_FACILITY_TEMPLATE_QUERY} variables={{ id }}>
            {({ loading: formDataLoading, error: formDataError, data }) => {
              if (formDataLoading) {
                return <Alert floating text="Loading template data..." />
              }
              if (formDataError) {
                return <Alert floating type="error" text="There was an error" />
              }
              return (
                <TemplateForm
                  title="Edit Template"
                  buttonText="Update Template"
                  onSubmit={updateFacilityTemplate}
                  error={error}
                  loading={loading}
                  formDataLoading={formDataLoading}
                  formDataError={formDataError}
                  formData={{
                    name: get(data, 'getFacilityTemplate.name', ''),
                    description: get(
                      data,
                      'getFacilityTemplate.description',
                      ''
                    ),
                    imageUrl: get(data, 'getFacilityTemplate.imageUrl', ''),
                  }}
                  versionId={get(
                    data.getFacilityTemplate,
                    'facility.floor.version.id'
                  )}
                  {...pick(props, ['parentRoute'])}
                />
              )
            }}
          </Query>
        )}
      </Mutation>
    </>
  )
}

EditFacilityModal.propTypes = {
  match: PropTypes.object,
}

export default EditFacilityModal
