import React from 'react'
import { arrayOf, func, string } from 'prop-types'

import Checkbox from 'components/UIKit/Checkbox'
import Flex from 'components/UIKit/Flex'
import Space from 'components/UIKit/Space'

import StyledLabel from './styled/Label'

function AddersColumn({ addersValue, onChange, options, type }) {
  return (
    <Space right="l">
      {options.map((option, idx) => (
        <Space bottom="xs" key={option}>
          {/*
           * Note: We are providing a label externally here because the Checkbox
           * component's default label has padding we do not want.
           */}
          <Flex alignItems="center">
            <Checkbox
              externalLabel
              name="adders"
              id={`${option}-${idx}`}
              onChange={() => onChange(option, type)}
              value={option}
              checked={addersValue.includes(option)}
            />
            <StyledLabel htmlFor={`${option}-${idx}`}>{option}</StyledLabel>
          </Flex>
        </Space>
      ))}
    </Space>
  )
}

AddersColumn.propTypes = {
  addersValue: arrayOf(string),
  onChange: func,
  options: arrayOf(string),
  type: string,
}

export default AddersColumn
