export const Lights = () => {
  return (
    <>
      <directionalLight
        color={0xefefef}
        intensity={Math.PI}
        position={[100, 0, 30]}
        target-position={[0, 0, 0]}
      />
      <directionalLight
        color={0xefefef}
        intensity={Math.PI}
        position={[-100, -50, 60]}
        target-position={[0, 0, 0]}
      />
      <ambientLight color={0x999999} intensity={Math.PI} />
    </>
  )
}
