import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Desc = styled.p`
  color: ${theme('colors.light.subdued')};
  font-size: ${theme('fonts.sizes.xs')};
`

export default Desc
