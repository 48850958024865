import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.h1<{ flush?: boolean }>`
  font-family: ${theme('fonts.family.heading')};
  font-size: ${theme('fonts.sizes.h1')};
  line-height: ${theme('fonts.lineHeight.heading')};
  margin-bottom: ${props => (props.flush ? '0' : props.theme.spacing.s)};
  margin-top: 0;
`

export default Container
