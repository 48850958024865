import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Container from './styled/Container'
import DropdownIcon from 'components/UIKit/DropdownIcon'

const Header = ({ active, bordered, children, direction, onClick }) => {
  const [hover, setHover] = useState(false)

  return (
    <Container
      hover={!active && hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <span>
        {children}
        <DropdownIcon
          dimmable={!active && hover}
          flush={true}
          reverse={direction === 1}
          hidden={!active}
        />
      </span>
    </Container>
  )
}

Header.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.string,
  bordered: PropTypes.bool,
  direction: PropTypes.number,
  onClick: PropTypes.func,
}

export default /** @type any */(Header)
