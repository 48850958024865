import styled from 'styled-components'

const Header = styled.div`
  position: relative;

  ${props =>
    props.title &&
    `
      border-bottom: 1px solid ${props.theme.colors.light.subdued};
      padding: ${props.theme.spacing.base};
    `};
`

export default Header
