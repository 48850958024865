import { STATES } from 'config/productivity'

class ProductivityCalculator {
  states = STATES
  state: (typeof STATES)[number]
  employees: number
  avgWages: number
  avgHours: number
  loss?: number
  savings?: number
  reduction?: number
  results?: { loss: string; savings: string; percentage: string; reduction: string; }

  constructor({ state, employees, avgWages, avgHours }: { state: string; employees: number; avgWages: number; avgHours: number }) {
    this.state = this.getState(state)
    this.employees = employees
    this.avgWages = avgWages
    this.avgHours = avgHours

    this.calculate()
  }

  calculate() {
    this.loss = this.annualWages * this.stateNoFan
    this.savings = this.loss - this.annualLossWithFan
    this.reduction = this.reducePerc

    this.setResults()
  }

  get annualWages() {
    return this.avgWages * this.employees * this.avgHours * 52
  }

  get annualLossWithFan() {
    return (this.annualWages / (1 - this.stateNoFan)) * this.stateWithFan
  }

  get reducePerc() {
    return Math.round(
      ((this.stateWithFan - this.stateNoFan) / this.stateNoFan) * 100
    )
  }

  get stateNoFan() {
    return this.calculatePercentage(this.state.noFan)
  }

  get stateWithFan() {
    return this.calculatePercentage(this.state.withFan)
  }

  calculatePercentage(number: number) {
    return parseFloat((number / 100).toFixed(4))
  }

  formatNumber(number: number) {
    return (
      '$' +
      Math.round(number)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    )
  }

  formatPercentage(number: string|number) {
    return `${number}%`
  }

  formatReduction(number: number) {
    const positiveNumber = number.toString().replace('-', '')

    return this.formatPercentage(positiveNumber)
  }

  setResults() {
    this.results = {
      loss: this.formatNumber(this.loss!),
      savings: this.formatNumber(this.savings!),
      percentage: this.formatPercentage(this.state.noFan),
      reduction: this.formatReduction(this.reduction!),
    }
  }

  getState = (state: string) => this.states.filter(s => s.name === state)[0]
}

export default ProductivityCalculator
