import { graphql } from '~/gql'

const CANCEL_CFD_JOB_MUTATION = graphql(`
  mutation cancelCFDJob($versionId: ID!) {
    cancelCFDJob(versionId: $versionId) {
      id
      status
    }
  }
`)

export default CANCEL_CFD_JOB_MUTATION
