import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import get from 'lodash-es/get'
import pick from 'lodash-es/pick'

import { UPDATE_QUOTE_MUTATION, CREATE_QUOTE_MUTATION } from '../mutations'
import { GET_QUOTE_QUERY } from '../queries'
import withEnrichedProducts from '../decorators/withEnrichedProducts'
import { VERSION_FRAGMENT } from '../fragments'
import { generateQuoteBundles } from 'store/quote'

export default compose(
  graphql(GET_QUOTE_QUERY, {
    options: ({ versionId } = {}) => ({
      variables: {
        id: versionId,
      },
    }),
    props: ({ data, loading, ...other } = {}) => ({
      loading,
      salesforceQuoteId: get(data, 'Version.quote.salesforceQuoteId') || '',
      quote: get(data, 'Version.quote'),
    }),
  }),
  withEnrichedProducts,
  appConnect(({ quote, objects }, ownProps) => ({
    bundles: ownProps.loading
      ? []
      : generateQuoteBundles(
          ownProps.enrichedProducts,
          get(ownProps, 'quote.bundles'),
          get(quote, 'updatedBundles') || []
        ),
    opportunityId: get(quote, 'opportunity.id'),
    name: get(quote, 'name'),
    discountNotes: get(quote, 'discountNotes'),
    discountReasonId: get(quote, 'discountReasonId'),
    shippingPrice: get(quote, 'shippingPrice'),
  })),
  graphql(CREATE_QUOTE_MUTATION, {
    props: ({ ownProps, mutate }) => ({
      onCreateQuote: () =>
        mutate({
          variables: pick(ownProps, [
            'name',
            'opportunityId',
            'versionId',
            'bundles',
          ]),
          update: (client, { data }) => {
            const versionData = client.readFragment({
              id: `Version:${ownProps.versionId}`,
              fragment: VERSION_FRAGMENT,
            })
            client.writeFragment({
              id: `${versionData.__typename}:${versionData.id}`,
              fragment: VERSION_FRAGMENT,
              data: {
                ...versionData,
                quote: data.createQuote,
              },
            })
          },
        }),
    }),
  }),
  graphql(UPDATE_QUOTE_MUTATION, {
    props: ({ ownProps, mutate }) => ({
      onUpdateQuote: () =>
        mutate({
          variables: {
            bundles: ownProps.bundles,
            name: ownProps.name || ownProps.quote.name,
            opportunityId:
              ownProps.opportunityId || ownProps.quote.opportunityId,
            discountReasonId:
              ownProps.discountReasonId || ownProps.quote.discountReasonId,
            discountNotes:
              ownProps.discountNotes || ownProps.quote.discountNotes,
            shippingPrice:
              ownProps.shippingPrice || ownProps.quote.shippingPrice,
            id: ownProps.quote.id,
          },
        }),
    }),
  })
)
