import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import TextField from '../../TextField'

const StyledTextField = styled(TextField)<{ disabled?: boolean; comfortconfig?: boolean; required?: boolean }>`
  font-size: ${theme('fonts.sizes.s')};

  ${props => !props.comfortconfig && `text-align: center;`};
  ${props => props.disabled && `opacity: 0.5;`};
  ${props => props.required && `border : 1px solid rgba(255,0,0,0.5);`};
`

export default StyledTextField
