import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import upperCase from 'lodash-es/upperCase'
import uniq from 'lodash-es/uniq'

import {
  getLayerLabel,
  getLayerIcon,
  shouldShowLayerInTools,
} from 'config/layers'
import { getSelectedObjects } from 'store/selectedObjects/selectors'

import Caret from 'components/UIKit/Caret'
import Icon from 'components/UIKit/Icon'
import Popover from 'components/UIKit/Popover'
import Tooltip from 'components/UIKit/Tooltip'
import ActiveLayer from './styled/ActiveLayer'
import Action from './styled/Action'
import Container from './styled/Container'
import Label from './styled/Label'
import LayerIcon from './styled/LayerIcon'

// TODO: Move this to its own component!
import Menu from 'components/DrawingSettings/styled/Menu'
import { useAppSelector } from "~/store/hooks";

function renderTool({ tool, index, props }) {
  const toolId = upperCase(tool.text)
  const isDropdownVisible = props.activeToolCategory === toolId

  // const pointer = tool.disabled ? 'default' : 'pointer'
  if (tool.tools) {
    return (
      <Popover
        closeOnDocumentClick={false}
        fullWidth
        renderTrigger={() => (
          <Action
            href="#0"
            text={tool.text}
            last={index === props.tools.length - 1 ? true : undefined}
            onClick={tool.onClick}
            cursor={tool.disable ? 'default' : 'pointer'}
          >
            <Icon name={tool.icon} size="16" />
            <Caret
              color="subdued"
              size="12"
              isDropdownVisible={isDropdownVisible}
              isTouchUI={props.isTouchUI}
            />
          </Action>
        )}
        renderMenu={() =>
          isDropdownVisible && (
            <Menu padding="0" isTouchUI={props.isTouchUI}>
              {Object.values(tool.tools).map((tool, i) => (
                <Tool key={i} tool={tool} />
              ))}
            </Menu>
          )
        }
      />
    )
  }

  if (tool.onHover) {
    return (
      <Action isActive {...tool}>
        <Tooltip content={tool.onHover} placement={'right'}>
          <Icon size="16" name={tool.icon} />
        </Tooltip>
      </Action>
    )
  }

  return <Tool tool={tool} />
}

const ToolPanel = (props = {}) => {
  const state = useAppSelector(x => x)
  const objectTypeCount = uniq(getSelectedObjects(state).map(obj => obj.className))
    .length
  const label =
    objectTypeCount > 1
      ? 'OBJECTS'
      : getLayerLabel(props.currentLayer, { includeOverrides: true })
  const icon =
    objectTypeCount > 1
      ? 'multiSelect'
      : getLayerIcon(props.currentLayer, { includeOverrides: true })

  return (
    <>
      {shouldShowLayerInTools(props.currentLayer) && (
        <ActiveLayer>
          <LayerIcon name={icon} size="12" />
          <Label>{label}</Label>
        </ActiveLayer>
      )}
      <Container>
        {props.tools &&
          props.tools.map((tool, index) => (
            <Fragment key={index}>
              {renderTool({
                tool,
                index,
                props,
              })}
            </Fragment>
          ))}
      </Container>
    </>
  )
}

const Tool = ({ tool }) => (
  <Action {...tool}>
    <Icon size="16" name={tool.icon} />
  </Action>
)

renderTool.propTypes = {
  ...Tool.propTypes,
}

Tool.propTypes = {
  tool: PropTypes.object,
}

ToolPanel.propTypes = {
  activeToolCategory: PropTypes.string,
  currentLayer: PropTypes.string,
  isTouchUI: PropTypes.bool,
  tools: PropTypes.arrayOf(PropTypes.object),
}

export default /** @type any */(ToolPanel)
