import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Item from './Item'

const BrushRadius = styled(Item)`
  &:before {
    content: '';
    background-color: ${theme('colors.dark.fg')};
    border-radius: 50%;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
  }
`

export default BrushRadius
