import React from 'react'

import Loader from 'components/UIKit/Loader'

import Container from './styled/Container'

const SelectListIconDownloading = () => (
  <Container>
    <Loader label="" />
  </Container>
)

export default /** @type any */(SelectListIconDownloading)
