import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const GradientContainer = styled.div`
  border: 1px solid ${theme('colors.light.subdued')};
  height: 34px;
  padding: ${theme('spacing.xs')};
  width: 100%;
`

export default GradientContainer
