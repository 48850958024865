import React from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter } from 'react-router-dom'
import { appConnect } from "~/store/hooks";

import routes from 'config/routes'

import { LAYERS_PANEL, SNAPSHOTS_PANEL } from 'store/panel/types'
import { togglePanel } from 'store/panel'
import { toggleLayerVisibility } from 'store/layers'
import { clearStatus, setStatusOn } from 'store/status'
import { CONTROLS_PANEL } from 'store/panel/types'

import Button from 'components/UIKit/Button'
import { ToolbarGroup, ToolbarItem } from 'components/UIKit/Toolbar'
import PerspectiveSwitch from 'components/PerspectiveSwitch'
import SavingsModal from 'components/Modals/SavingsModal'
import SnapshotModal from 'components/Modals/SnapshotModal'
import AirflowHelpModal from 'components/Modals/AirflowHelpModal'
import HeatMapHelpModal from 'components/Modals/HeatMapHelpModal'
import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

const BottomLeft = ({
  currentLayer,
  match,
  onClearStatus,
  onSetStatus,
  onTogglePanel,
  onToggleVisibility,
  onUnimplementedFeatureClick,
  type,
  controlsActive,
  rotation,
  versionId,
}) => (
  <ToolbarGroup flexGrow="0" alignment="left">
    <ToolbarItem
      responsive
      separator="right"
      role="presentation"
      onClick={() => onTogglePanel(LAYERS_PANEL)}
      active={type === LAYERS_PANEL}
    >
      <Button
        dropdown
        noBorder
        responsive
        dropdownReverse
        isDropdownVisible={type === LAYERS_PANEL}
        icon="layers"
        label="Layers"
      />
    </ToolbarItem>

    <ToolbarItem
      responsive
      separator="right"
      role="presentation"
      onMouseEnter={() => onSetStatus({ action: 'hover', show: 'snapshots' })}
      onMouseLeave={() => onClearStatus({ type: 'info' })}
      onClick={() => onTogglePanel(SNAPSHOTS_PANEL)}
      active={type === SNAPSHOTS_PANEL}
    >
      <Button
        dropdown
        noBorder
        responsive
        dropdownReverse
        isDropdownVisible={type === SNAPSHOTS_PANEL}
        icon="camera"
        label="Snapshots"
      />
    </ToolbarItem>
    <ToolbarItem
      separator="right"
      paddingLeft="base"
      paddingRight="base"
      active={controlsActive === CONTROLS_PANEL}
    >
      <PerspectiveSwitch showControls responsive />
      <Button
        dropdown={rotation >= 1}
        paddingLeft="0px"
        paddingRight="0px"
        display={rotation >= 1 ? 'inline-flex' : 'none'}
        noBorder
        dropdownReverse
        onClick={() => onTogglePanel(CONTROLS_PANEL)}
        isDropdownVisible={controlsActive === CONTROLS_PANEL}
      />
    </ToolbarItem>
    <SentryRoute
      path={`${match.url}${routes.modals.snapshot}`}
      render={props => (
        <SnapshotModal
          parentRoute={match.url}
          facilityId={match.params.id}
          versionId={versionId}
          {...props}
        />
      )}
    />
    <SentryRoute
      path={`${match.url}${routes.modals.savings}`}
      render={() => <SavingsModal parentRoute={match.url} />}
    />
    <SentryRoute
      path={`${match.url}${routes.help.airflow}`}
      render={() => <AirflowHelpModal parentRoute={match.url} />}
    />
    <SentryRoute
      path={`${match.url}${routes.help.heatmap}`}
      render={() => <HeatMapHelpModal parentRoute={match.url} />}
    />
  </ToolbarGroup>
)

BottomLeft.propTypes = {
  currentLayer: PropTypes.string,
  controlsActive: PropTypes.string,
  match: PropTypes.object,
  onClearStatus: PropTypes.func,
  onSetStatus: PropTypes.func,
  onTogglePanel: PropTypes.func,
  onToggleVisibility: PropTypes.func,
  onUnimplementedFeatureClick: PropTypes.func,
  rotation: PropTypes.number,
  type: PropTypes.string,
  versionId: PropTypes.string,
}

const mapStateToProps = ({ layers, panel, camera }) => ({
  currentLayer: layers.currentLayer,
  cameraLocation: camera.cameraLocation,
  type: panel.bottom.visiblePanel,
  controlsActive: panel.other.visiblePanel,
  rotation: camera.rotation,
})

const mapDispatchToProps = dispatch => ({
  onClearStatus({ type }) {
    dispatch(clearStatus({ type }))
  },
  onSetStatus({ action, show }) {
    dispatch(setStatusOn({ action, show }))
  },
  onTogglePanel(panel) {
    dispatch(togglePanel({ type: panel }))
  },
  onToggleVisibility({ layerKey }) {
    dispatch(toggleLayerVisibility({ layerKey }))
  },
})

export default withRouter(
  appConnect(mapStateToProps, mapDispatchToProps)(BottomLeft)
)
