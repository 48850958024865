import styled, { css } from 'styled-components'

import { theme } from 'lib/styleUtils'

const styles = css`
  color: ${theme('colors.dark.fg')};
  display: block;
  padding: ${theme('spacing.base')};
  position: relative;
  text-decoration: none;

  &::before {
    background-color: rgba(255, 255, 255, 0.2);
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
  }
`

const AnchorAction = styled.a`
  ${styles}
`

const DivAction = styled.div`
  ${styles}
`

export { AnchorAction as default, DivAction }
