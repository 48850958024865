import { ComponentType, forwardRef, MouseEventHandler, RefAttributes, TouchEventHandler } from "react";
import { useAppSelector } from "~/store/hooks";
import { DoorTool } from "./Doors/DoorTool";
import { DimensionLabelTool } from "./DimensionLabels/Tool";

type ReturnBoolean<T extends (...a: any) => any> = (...a: Parameters<T>) => boolean | void
export type ToolManagerRef = {
  onMouseMove?: ReturnBoolean<MouseEventHandler<HTMLDivElement>>
  onMouseDown?: ReturnBoolean<MouseEventHandler<HTMLDivElement>>
  onMouseUp?: ReturnBoolean<MouseEventHandler<HTMLDivElement>>
  onDoubleClick?: ReturnBoolean<MouseEventHandler<HTMLDivElement>>
  onContextMenu?: ReturnBoolean<MouseEventHandler<HTMLDivElement>>
  onTouchMove?: ReturnBoolean<TouchEventHandler<HTMLDivElement>>
  onTouchStart?: ReturnBoolean<TouchEventHandler<HTMLDivElement>>
  onTouchEnd?: ReturnBoolean<TouchEventHandler<HTMLDivElement>>
}

const toolMap = new Map<string, ComponentType<{ activeTool: string; activeToolProps: any } & RefAttributes<ToolManagerRef>>>([
  ['DOOR_TOOL', DoorTool],
  ['DIMENSION_TOOL', DimensionLabelTool],
])

export const ToolManager = forwardRef<ToolManagerRef>(function ToolManager(_props: {}, ref) {
  const activeTool = useAppSelector(it => it.tools.activeTool)
  const activeToolProps = useAppSelector(it => it.tools.activeToolProps)

  const Component = toolMap.get(activeTool)
  if (Component === undefined) {
    return <></>
  }
  return <Component activeTool={activeTool} activeToolProps={activeToolProps} ref={ref}/>
})
