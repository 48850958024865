/* eslint-disable no-mixed-operators */
import get from 'lodash-es/get'
import Facility from './facility'
import Units from './units'
import OBJECT_TYPES from 'config/objectTypes'
import { getThreeHexFromTheme } from 'lib/utils'
import Util from './util'

import * as THREE from 'three'

class ComfortZoneUtil {
  static boxMaterial = new THREE.MeshLambertMaterial({
    color: getThreeHexFromTheme('three.dark'),
    emissive: getThreeHexFromTheme('three.dark'),
    transparent: true,
    side: THREE.DoubleSide,
    opacity: 0.99,
  })

  static customHandleMaterial = new THREE.MeshLambertMaterial({
    color: 0xffff00,
    emissive: getThreeHexFromTheme('three.dark'),
    transparent: true,
    side: THREE.DoubleSide,
    opacity: 0.3,
  })

  static getResizeHandleSize(xDiff, yDiff) {
    const base = yDiff > xDiff ? xDiff : yDiff
    return base / 2
  }

  static getResizeHandle(id, size) {
    const geometry = new THREE.BoxGeometry(size, size, size)
    const handle = new THREE.Mesh(geometry, ComfortZoneUtil.boxMaterial)
    handle.wrapperId = id

    return handle
  }

  static getCustomResizeHandle(id, size) {
    const geometry = new THREE.CircleGeometry(size)
    const material = new THREE.MeshBasicMaterial({
      color: 0xffff00,
      transparent: true,
      opacity: 0.5,
    })
    const handle = new THREE.Mesh(geometry, material)
    handle.wrapperId = id

    return handle
  }

  static getResizeHandleOverPosition(pos, id, facility = Facility.current) {
    const position = new THREE.Vector3(pos.x, pos.y, 400)
    const negativeZ = new THREE.Vector3(0, 0, -1).normalize()
    const intersections = facility.measureObjectsInDirectionFromPoint(
      negativeZ,
      position
    )

    const resizeHandle = intersections.find(obj => {
      const type = get(obj, 'obj3d.userData.objectType', '')
      return type.includes('RESIZE_HANDLE') && obj.wrapper.id === id
    })

    return resizeHandle
  }

  // Takes the bounding box of the obstruction and its ID
  // Returns an object3D containing all resize handles
  static buildResizeHandles(box, id) {
    const xDiff = Math.abs(box.max.x - box.min.x) / 2
    const yDiff = Math.abs(box.max.y - box.min.y) / 2
    const height = box.max.z * 2 + 1
    const size = ComfortZoneUtil.getResizeHandleSize(xDiff, yDiff)
    const offset = size / 2

    // Add resize handles to each corner of the selection box
    const bottomRightHandle = ComfortZoneUtil.getResizeHandle(id, size)
    bottomRightHandle.position.set(xDiff - offset, -yDiff + offset, height)
    bottomRightHandle.userData.objectType =
      OBJECT_TYPES.RESIZE_HANDLE_BOTTOM_RIGHT
    const bottomLeftHandle = ComfortZoneUtil.getResizeHandle(id, size)
    bottomLeftHandle.position.set(-xDiff + offset, -yDiff + offset, height)
    bottomLeftHandle.userData.objectType =
      OBJECT_TYPES.RESIZE_HANDLE_BOTTOM_LEFT
    const topRightHandle = ComfortZoneUtil.getResizeHandle(id, size)
    topRightHandle.position.set(xDiff - offset, yDiff - offset, height)
    topRightHandle.userData.objectType = OBJECT_TYPES.RESIZE_HANDLE_TOP_RIGHT
    const topLeftHandle = ComfortZoneUtil.getResizeHandle(id, size)
    topLeftHandle.position.set(-xDiff + offset, yDiff - offset, height)
    topLeftHandle.userData.objectType = OBJECT_TYPES.RESIZE_HANDLE_TOP_LEFT

    // Add resize handles to each edge of the selection box
    const topHandle = ComfortZoneUtil.getResizeHandle(id, size / 2)
    topHandle.position.set(0, yDiff - offset / 2, height)
    topHandle.userData.objectType = OBJECT_TYPES.RESIZE_HANDLE_TOP
    const bottomHandle = ComfortZoneUtil.getResizeHandle(id, size / 2)
    bottomHandle.position.set(0, -yDiff + offset / 2, height)
    bottomHandle.userData.objectType = OBJECT_TYPES.RESIZE_HANDLE_BOTTOM
    const rightHandle = ComfortZoneUtil.getResizeHandle(id, size / 2)
    rightHandle.position.set(xDiff - offset / 2, 0, height)
    rightHandle.userData.objectType = OBJECT_TYPES.RESIZE_HANDLE_RIGHT
    const leftHandle = ComfortZoneUtil.getResizeHandle(id, size / 2)
    leftHandle.position.set(-xDiff + offset / 2, 0, height)
    leftHandle.userData.objectType = OBJECT_TYPES.RESIZE_HANDLE_LEFT

    // Create a container object to encapsulate all handles
    const resizeHandlesContainer = new THREE.Object3D()
    resizeHandlesContainer.add(topHandle)
    resizeHandlesContainer.add(bottomHandle)
    resizeHandlesContainer.add(rightHandle)
    resizeHandlesContainer.add(leftHandle)
    resizeHandlesContainer.add(bottomRightHandle)
    resizeHandlesContainer.add(bottomLeftHandle)
    resizeHandlesContainer.add(topLeftHandle)
    resizeHandlesContainer.add(topRightHandle)

    // Use a box helper to create an outline around the selection box
    // to visually connect each resize handle
    const outlineBox = new THREE.BoxHelper(
      resizeHandlesContainer,
      getThreeHexFromTheme('three.dark')
    )
    resizeHandlesContainer.add(outlineBox)

    return resizeHandlesContainer
  }

  static buildCustomHandles(positions, id) {
    const resizeHandlesContainer = new THREE.Object3D()
    const position = Util.polygonCentroid(positions)

    const xArr = positions.map(p => p.x)
    const yArr = positions.map(p => p.y)

    const minX = Math.min(...xArr)
    const minY = Math.min(...yArr)
    const maxX = Math.max(...xArr)
    const maxY = Math.max(...yArr)

    const dX = maxX - minX
    const dY = maxY - minY

    const size = Math.min(Math.min(dX, dY) / 15, 5)

    positions.forEach((p, idx) => {
      const handle = ComfortZoneUtil.getCustomResizeHandle(id, size)
      handle.position.set(p.x, p.y, p.z)
      handle.userData.objectType = OBJECT_TYPES.RESIZE_HANDLE
      handle.userData.index = idx

      resizeHandlesContainer.add(handle)
    })

    // shift handles to be on model
    resizeHandlesContainer.position.copy(
      new THREE.Vector3(-position.x, -position.y, size)
    )

    return resizeHandlesContainer
  }

  static getCustomResizedPositions(positions, dragDelta, resizeHandle) {
    let newPositions = [...positions]
    const index = get(resizeHandle, 'obj3d.userData.index', 0)
    const match = newPositions[index]

    match.x += dragDelta.x
    match.y += dragDelta.y
    newPositions[index] = match

    return newPositions
  }

  // Updates the size of an obstruction based on an updated bounding box
  static getResizedPositionsFromBox(box, dragDelta, positions, resizeHandle) {
    const type = get(resizeHandle, 'obj3d.userData.objectType', '')
    let xMaxDelta = 0
    let yMaxDelta = 0
    let xMinDelta = 0
    let yMinDelta = 0

    if (type === 'RESIZE_HANDLE_BOTTOM_RIGHT') {
      xMaxDelta = dragDelta.x
      yMinDelta = dragDelta.y
    } else if (type === 'RESIZE_HANDLE_TOP_RIGHT') {
      xMaxDelta = dragDelta.x
      yMaxDelta = dragDelta.y
    } else if (type === 'RESIZE_HANDLE_TOP_LEFT') {
      xMinDelta = dragDelta.x
      yMaxDelta = dragDelta.y
    } else if (type === 'RESIZE_HANDLE_BOTTOM_LEFT') {
      xMinDelta = dragDelta.x
      yMinDelta = dragDelta.y
    } else if (type === 'RESIZE_HANDLE_TOP') {
      yMaxDelta = dragDelta.y
    } else if (type === 'RESIZE_HANDLE_BOTTOM') {
      yMinDelta = dragDelta.y
    } else if (type === 'RESIZE_HANDLE_LEFT') {
      xMinDelta = dragDelta.x
    } else if (type === 'RESIZE_HANDLE_RIGHT') {
      xMaxDelta = dragDelta.x
    }

    return ComfortZoneUtil.getPositionsFromBox(
      (box.max.x += xMaxDelta),
      (box.max.y += yMaxDelta),
      (box.min.x += xMinDelta),
      (box.min.y += yMinDelta)
    )
  }

  static getPositionsFromBox(maxX, maxY, minX, minY) {
    return [
      { x: minX, y: maxY, z: 0 },
      { x: maxX, y: maxY, z: 0 },
      { x: maxX, y: minY, z: 0 },
      { x: minX, y: minY, z: 0 },
      { x: minX, y: maxY, z: 0 },
    ]
  }

  // Tries to determine if the obstruction is a rectangle by checking
  // if its most extreme x and y positions line up in a rectangle shape
  static isRectangle(positions) {
    // A rectangle obsruction can have 4 or 5 positions based on how it is drawn
    if (positions.length !== 5 && positions.length !== 4) return false

    const xValues = positions.map(pos => pos.x).sort()
    const yValues = positions.map(pos => pos.y).sort()

    return (
      xValues[0] === xValues[1] &&
      yValues[0] === yValues[1] &&
      xValues[xValues.length - 1] === xValues[xValues.length - 2] &&
      yValues[yValues.length - 1] === yValues[yValues.length - 2]
    )
  }

  static getRotatedPositions(
    positions,
    origin = new THREE.Vector3(0, 0, 0),
    rotation = 0
  ) {
    // If the object is rotated, we need to rotate the positions as well
    const rads = (Math.PI / 180) * rotation
    const sine = Math.sin(rads)
    const cosine = Math.cos(rads)
    return positions.map(pos => {
      const xOrig = pos.x - origin.x
      const yOrig = pos.y - origin.y
      return new THREE.Vector3(
        xOrig * cosine - yOrig * sine + origin.x,
        xOrig * sine + yOrig * cosine + origin.y,
        pos.z
      )
    })
  }

  static getPositionDiffs(positions) {
    const xMin = positions.reduce((min, position) => {
      return position.x < min ? position.x : min
    }, Infinity)
    const xMax = positions.reduce((max, position) => {
      return position.x > max ? position.x : max
    }, -Infinity)
    const yMin = positions.reduce((min, position) => {
      return position.y < min ? position.y : min
    }, Infinity)
    const yMax = positions.reduce((max, position) => {
      return position.y > max ? position.y : max
    }, -Infinity)

    const xDiff = xMax - xMin
    const yDiff = yMax - yMin

    return {
      xDiff,
      xMin,
      xMax,
      yDiff,
      yMin,
      yMax,
    }
  }

  static getDistanceFromFloor(obj3d, height, facility = Facility.current) {
    const floorPosition = new THREE.Vector3(
      obj3d.position.x,
      obj3d.position.y,
      0
    )
    const positiveZ = new THREE.Vector3(0, 0, 1)
    const options = {
      includedTypes: [
        OBJECT_TYPES.PRIMARY_BEAM,
        OBJECT_TYPES.SUB_MOUNTING_STRUCTURE,
        OBJECT_TYPES.ROOF,
      ],
      measureTo: Facility.SURFACE,
      sort: true,
    }
    const hits = facility.measureObjectsInDirectionFromPoint(
      positiveZ,
      floorPosition,
      options
    )

    const heightOffset = Units.inchesToNative(height) - 0.01

    if (hits.length) {
      const distance = hits[0].distance - heightOffset
      if (distance < 0) return obj3d.position.z
      return distance
    }

    return heightOffset
  }
}

export default ComfortZoneUtil
