import get from 'lodash-es/get'

import LAYER_KEYS from 'config/layerKeys'

// For backwards compatibility with deprecated `category` property:
export function getLayerKey(object = {}) {
  // Return the layer key or category which should always be strings
  return LAYER_KEYS[object.layerKey || object.category]
}

export const isLayerVisible = (state, layerKey) =>
  get(state, `layers.layers.${layerKey}.visible`)
