import React from 'react'
import { func, string, bool } from 'prop-types'

import Input from './styled/Input'
import Label from './styled/Label'
import Flex from '../Flex'

const Radio = ({
  name,
  label = '',
  group = name,
  checked,
  defaultChecked,
  onChange,
  ...props
}) => (
  <Flex alignItems="center" {...props}>
    <Input
      type="radio"
      name={group}
      id={name}
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
    />
    {label && <Label htmlFor={name}>{label}</Label>}
  </Flex>
)

Radio.propTypes = {
  name: string,
  label: string,
  group: string,
  checked: bool,
  defaultChecked: bool,
  onChange: func,
}

export default /** @type any */(Radio)
