import React, { ComponentProps, useId, useState } from 'react'
import { string, number, bool, func, object, oneOfType } from 'prop-types'
import { useNetwork } from 'networkProvider'
import pick from 'lodash-es/pick'

import Container from './styled/Container'
import Field from './styled/Field'
import FieldContainer from './styled/FieldContainer'
import IconLeft from './styled/IconLeft'
import IconRight from './styled/IconRight'
import Label from './styled/Label'
import VariantText from '../VariantText'

import Camera from 'components/DrawingCanvas/lib/camera'
import { FontSize, Spacing, Variant } from '~/config/theme'

type TextFieldProps = {
  value?: string | number
  overrideValue?: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  autoFocus?: boolean
  autoSelect?: boolean
  name?: string
  label?: string

  inline?: boolean
  labelWidth?: string
  size?: FontSize & Spacing
  variant?: Variant
  width?: string
  disabled?: boolean
  iconLeft?: boolean
  iconRight?: boolean
  noResize?: boolean
} & ComponentProps<"input" | "textarea">

function TextField(props: TextFieldProps) {
  const [value, setValue] = useState(props.value || '')
  const _value =
    typeof props.overrideValue !== 'undefined' ? props.overrideValue : value
  const fieldID = useId()

  function handleBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.onBlur && props.onBlur(event)
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setValue(event.target.value)
    props.onChange && props.onChange(event)
  }

  function handleFocus(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.autoSelect) {
      event.target.select()
    }

    props.onFocus && props.onFocus(event)
  }

  const inputProps = {
    ...props,
    autoComplete: 'off',
    id: fieldID,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    value: _value,
    autoFocus: props.autoFocus || props.autoSelect,
  }

  function characterCounter() {
    const limit = inputProps.maxLength
    if (!limit) return null

    const charactersLeft = limit - (typeof value !== 'number' ? value.length : value.toString().length)
    const textProps = {
      size: 's',
      color: charactersLeft === 0 ? 'error' : 'fg',
    } as const

    return (
      <VariantText {...textProps}>{charactersLeft} characters left</VariantText>
    )
  }

  const network = useNetwork()

  return (
    <Container inline={props.inline}>
      {props.label && (
        <Label inline={props.inline} labelWidth={props.labelWidth} htmlFor={fieldID}>
          {props.label}
        </Label>
      )}
      <FieldContainer>
        {props.iconLeft && <IconLeft>{props.iconLeft}</IconLeft>}
        <Field disabled={!network.online} {...inputProps} />
        {props.iconRight && <IconRight>{props.iconRight}</IconRight>}
      </FieldContainer>
      {characterCounter()}
    </Container>
  )
}

export default TextField
