import styled, { keyframes } from 'styled-components'

import { theme } from 'lib/styleUtils'
import { getZIndexOf } from 'lib/utils'

const flash = keyframes`
  0% {
    transform: scale(1);
  }
  14% {
    transform: scale(1.3);
  }
  28% {
    transform: scale(1);
  }
  42% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(1);
  }
`

const Container = styled.button`
  align-items: center;
  animation: ${flash} 1.3s 2s ease-in-out 1;
  animation-fill-mode: forwards;
  background-color: ${theme('colors.light.primary')};
  border: 0;
  border-radius: 50%;
  bottom: ${theme('spacing.base')};
  display: flex;
  left: ${theme('spacing.base')};
  padding: ${theme('spacing.s')};
  position: absolute;
  text-align: center;
  z-index: ${getZIndexOf('lock')};
`

export default Container
