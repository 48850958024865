import { Euler, Quaternion, Vector3, Vector3Like } from "three"
import { WallSegment } from "~/store/objects/types"

/** Checks if the wall segment is horizontal */
export function isHorizontal(wallSegment: WallSegment) {
  const rotation = orthogonalVectorOfWallSegment(wallSegment)
  return Math.abs(rotation.z).toFixed(2) === '3.14'
}

/** Checks if the wall segment is vertical */
export function isVertical(wallSegment: WallSegment) {
  const rotation = orthogonalVectorOfWallSegment(wallSegment)
  return Math.abs(rotation.z).toFixed(2) === '1.57'
}

/** Gets a rotation that is orthogonal to the wall segment */
export function orthogonalVectorOfWallSegment(wallSegment: WallSegment): Euler {
  const direction = new Vector3()
    .subVectors(wallSegment.startPoint, wallSegment.endPoint)
    .normalize()

  direction.set(-direction.y, direction.x, direction.z)

  const from = new Vector3(1, 0, 0)
  from.normalize()

  const quaternion = new Quaternion()
  quaternion.setFromUnitVectors(
    from,
    direction
  )

  const rotation = new Euler().setFromQuaternion(quaternion)

  if (quaternion.y !== 0) {
    quaternion.y = 0
    rotation.setFromQuaternion(quaternion)
    rotation.z = Math.PI
  }

  return rotation
}

/** Gets a rotation that is parallel to the wall segment */
export function parallelVectorOfWallSegment(wallSegment: WallSegment): Euler {
  const direction = new Vector3()
    .subVectors(wallSegment.startPoint, wallSegment.endPoint)
    .normalize()

  direction.set(direction.x, direction.y, direction.z)

  const from = new Vector3(1, 0, 0)
  from.normalize()

  const quaternion = new Quaternion()
  quaternion.setFromUnitVectors(from, direction)

  const rotation = new Euler().setFromQuaternion(quaternion)

  if (quaternion.y !== 0) {
    quaternion.y = 0
    rotation.setFromQuaternion(quaternion)
    rotation.z = Math.PI
  }

  return rotation
}

/**
 * Bisects the line specified by {@link wallSegmentStart} and {@link wallSegmentEnd} into two z-flat vectors
 * pointing away from {@link point}
 */
export function bisectLine(wallSegmentStart: Vector3Like, wallSegmentEnd: Vector3Like, point: Vector3Like) {
  const v1 = new Vector3()
    .subVectors(wallSegmentStart, point)
  v1.z = 0
  const v1Half = new Vector3()
  v1Half.copy(v1)
  v1Half.divideScalar(2)
  const v2 = new Vector3()
    .subVectors(wallSegmentEnd, point)
  v2.z = 0
  const v2Half = new Vector3()
  v2Half.copy(v2)
  v2Half.divideScalar(2)
  return { v1, v2, v1Half, v2Half }
}
