import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const opposite = side => {
  switch (side) {
    case 'left': {
      return 'right'
    }
    case 'right': {
      return 'left'
    }
    default: {
      return null
    }
  }
}

const setPositionStyles = props => {
  const side = opposite(props.alignment)

  if (props.coordinate === 'y') {
    return `
      margin-bottom: ${props.theme.spacing.base};
      margin-${side}: ${props.theme.spacing.base};
    `
  }
}

const Label = styled.div`
  font-size: ${theme('fonts.sizes.xs')};
  font-weight: ${theme('fonts.weights.bold')};

  ${props => setPositionStyles(props)};
`

export default Label
