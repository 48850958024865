/*
  Unit gives us a way to pass around values
  without having to assume what the unit is for that value.

  Unit needs to have a
  - value (number)
  - system (METRIC | IMPERIAL | NATIVE)
  - type (DISTANCE | TEMPERATURE | VELOCITY)
*/

import isEqual from 'lodash-es/isEqual'

import convertUnits from '../converters'
import { FormatOptions, formatUnits, unformatUnits } from '../formatters'
import { MeasurementSystem, SYSTEMS, UnitTypes } from '../constants'

export type FormattedUnitData = {
  value: string
  type: UnitTypes
  system: MeasurementSystem
}
export type UnformattedUnitData = {
  value: number | null
  type: UnitTypes
  system: MeasurementSystem
}
export type SomeUnitData = FormattedUnitData | UnformattedUnitData

class Unit<Data extends FormattedUnitData | UnformattedUnitData> {
  value: Data['value']
  system: MeasurementSystem
  type: UnitTypes

  constructor({ value, system, type }: Data) {
    this.value = value
    this.system = system
    this.type = type
  }

  // Check if values between two units are equal, independent of system
  equals(unit: Unit<any>) {
    return unit.value === this.convertedValue(unit.system)
  }

  // Check if two units are exactly equal, including system
  exactly(unit: Unit<any>) {
    return isEqual(
      {
        value: this.value,
        system: this.system,
        type: this.type,
      },
      {
        value: unit.value,
        system: unit.system,
        type: unit.type,
      }
    )
  }

  // Return the value converted into metric
  metric(options?: FormatOptions) {
    return this.convertedValue(SYSTEMS.METRIC, options)
  }

  // Return the value converted into imperial
  imperial(options?: FormatOptions) {
    return this.convertedValue(SYSTEMS.IMPERIAL, options)
  }

  // Return the value converted into `system`
  convertedValue(system: MeasurementSystem, options?: FormatOptions) {
    return convertUnits(
      {
        value: this.value,
        system: this.system,
        type: this.type,
      },
      system,
      options
    )
  }

  // Convert the unit's stored value to `system`
  convertTo(system: MeasurementSystem, options?: FormatOptions) {
    this.value = this.convertedValue(system, options)
    this.system = system
  }

  // Return the value formatted as metric
  formatMetric(options?: FormatOptions) {
    return this.formattedValue(SYSTEMS.METRIC, options)
  }

  // Return the value formatted as imperial
  formatImperial(options?: FormatOptions) {
    return this.formattedValue(SYSTEMS.IMPERIAL, options)
  }

  // Return the value formatted as `system`
  formattedValue(system: MeasurementSystem, options?: FormatOptions) {
    const convertedValue = this.convertedValue(system)
    return formatUnits(
      {
        value: convertedValue,
        type: this.type,
        system,
      },
      options
    )
  }

  // Return the value formatted as the stored system
  format(options?: FormatOptions): Data extends UnformattedUnitData ? string|null : never {
    if (typeof this.value !== 'number') {
      console.error("unit is already formatted")
      return (this.value ?? options?.nullValue ?? null) as any
    }
    return formatUnits(
      { value: this.value, system: this.system, type: this.type },
      options
    ) as Data extends UnformattedUnitData ? string|null : never
  }

  // Return the value unformatted
  static unformat({ value, system, type }: FormattedUnitData, options?: FormatOptions): number|null {
    if (typeof value !== 'string') {
      console.error("unit is not formatted")
      return (value ?? options?.nullValue ?? null) as any
    }
    return unformatUnits({ value, system, type }, options)
  }
}

export default Unit
