import DimensionInput from 'components/UIKit/DimensionInput'
import Flex, { FlexItem } from 'components/UIKit/Flex'
import PropTypes from 'prop-types'
import Space from 'components/UIKit/Space'
import React from 'react'
import { SYSTEMS } from 'store/units/constants'
import { Distance } from 'store/units/types'
import VariantText from 'components/UIKit/VariantText'
import Remove from 'components/Modals/ExportPDFLayoutModal/Wizard/styled/Remove'
import Icon from 'components/UIKit/Icon'

const EvalHeightRow = props => {
  const { height, index, units, handleRemove, handleChange } = props
  return (
    <Space bottom="base">
      <Flex alignItems="center">
        <FlexItem width="50px" marginRight="base">
          <VariantText bold>{index + 1 + '.)'}</VariantText>
        </FlexItem>
        <FlexItem width="300px" marginRight="base">
          <DimensionInput
            disabled={index < 4}
            width="90px"
            distance={
              new Distance({
                value: height,
                system: SYSTEMS.IMPERIAL,
              })
            }
            tabIndex={2}
            units={units}
            onChange={({ distance }) => {
              handleChange(distance.imperial() || 0)
            }}
          />
        </FlexItem>
        {index >= 4 && (
          <FlexItem>
            <Remove onClick={handleRemove}>
              <Icon name="cross" size="24" />
            </Remove>
          </FlexItem>
        )}
      </Flex>
    </Space>
  )
}

EvalHeightRow.propTypes = {
  height: PropTypes.number,
  index: PropTypes.number,
  units: PropTypes.string,
  handleRemove: PropTypes.func,
  handleChange: PropTypes.func,
}

export default EvalHeightRow
