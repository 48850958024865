import { TooltipTrigger } from 'react-aria-components'
import { Button } from '~/ui/Button'

import { Tooltip } from '~/ui/Tooltip'

export const scale = [
  { number: -3, label: 'Cold', color: 'bg-[#5083c1]' },
  { number: -2, label: 'Cool', color: 'bg-[#a7c4e5]' },
  { number: -1, label: 'Slightly Cool', color: 'bg-[#d3e1f1]' },
  { number: 0, label: 'Neutral', color: 'bg-[#d9d9d9]' },
  { number: 1, label: 'Slightly Warm', color: 'bg-[#eececd]' },
  { number: 2, label: 'Warm', color: 'bg-[#de9d9b]' },
  { number: 3, label: 'Hot', color: 'bg-[#d16d6a]' },
] as const

const { format: formatSignedInteger } = new Intl.NumberFormat('en-US', {
  signDisplay: 'exceptZero',
})

const PMVScale = ({ value }: { value: number }) => {
  return (
    <div className="flex justify-around">
      {scale.map((item, i) => {
        const isMatch = Math.round(value) === item.number
        return (
          <TooltipTrigger delay={0} key={i}>
            <Button
              className={`rounded-none first:rounded-l-md last:rounded-r-md grow p-0 border-0 relative ${item.color}`}
            >
              <span className={`absolute top-full ${isMatch ? '' : 'hidden'}`}>^</span>
              {formatSignedInteger(item.number)}
            </Button>
            <Tooltip>{item.label}</Tooltip>
          </TooltipTrigger>
        )
      })}
    </div>
  )
}

export default PMVScale
