import styled from 'styled-components'

const Container = styled.b`
  align-items: center;
  display: flex;
  flex-direction: row;
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`};
`

export default Container
