import get from 'lodash-es/get'
import store from 'store'
import { Distance } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'
import { objectStateKeyFromClassName } from '../objects/selectors'
import {
  selectObjects as selectObjectsAction,
  deselectObjects as deselectObjectsAction,
} from './actions'
import { deleteObjects as deleteObjectsAction } from '../objects'
import CLASS_NAMES from 'config/objectClassNames'
import Facility from 'components/DrawingCanvas/lib/facility' // :'(

export function getSelectedObjects(state = store.getState()) {
  const { selectedObjects = [] } = state
  return selectedObjects
}

export function getSelectedObjectState(object, state = store.getState()) {
  const stateKey = objectStateKeyFromClassName(object.className)
  return get(state, `objects.present.${stateKey}.${object.id}`)
}

export function getSelectedFacilityObjects(
  facility = Facility.current,
  state = store.getState()
) {
  const objects = []
  for (const selectedObject of getSelectedObjects(state)) {
    const facilityObject = facility.findObjectWithId(selectedObject.id)
    if (facilityObject) {
      objects.push(facilityObject)
    }
  }

  return objects
}

export function numObjectsSelected(state = store.getState()) {
  return getSelectedObjects(state).length
}

export function anyObjectSelected(state = store.getState()) {
  return !!numObjectsSelected(state)
}

export function selectedObjectIds(state = store.getState()) {
  return getSelectedObjects(state).map(obj => obj.id)
}

export function selectedObjectsByRecency(state = store.getState()) {
  const selectedObjects = getSelectedObjects(state)
  return selectedObjects.slice().reverse()
}

export function mostRecentSelectedObjectState(state = store.getState()) {
  const selectedObjects = getSelectedObjects(state)
  if (selectedObjects.length) {
    return selectedObjects[selectedObjects.length - 1]
  }
  return
}

export function mostRecentSelectedObject(state = store.getState()) {
  const selectedObjectState = mostRecentSelectedObjectState(state)
  if (selectedObjectState) {
    const stateKey = objectStateKeyFromClassName(selectedObjectState.className)
    return get(state, `objects.present.${stateKey}.${selectedObjectState.id}`)
  }
  return
}

export function mostRecentSelectedObjectHeightFromFloor(state = store.getState()) {
  const selectedProduct = mostRecentSelectedObject(state)
  // NOTE: 2 sources of truth :/
  const threeStateProduct = Facility.current
    .getProducts()
    .find(product => product.id === selectedProduct?.id)

  const distance = new Distance({
    value: get(threeStateProduct, 'height') || 0,
    system: SYSTEMS.NATIVE,
  })

  return distance
}

export function mostRecentSelectedObjectStateOfClassName(
  className,
  state = store.getState()
) {
  const selectedObjects = selectedObjectsByRecency(state)

  if (selectedObjects.length) {
    return selectedObjects.find(so => so.className === className)
  }
  return
}

export function mostRecentSelectedObjectOfClassName(
  className,
  state = store.getState()
) {
  const selectedObjectState = mostRecentSelectedObjectStateOfClassName(
    className,
    state
  )

  if (selectedObjectState) {
    const statePropName = objectStateKeyFromClassName(className)

    return get(
      state,
      `objects.present.${statePropName}.${selectedObjectState.id}`
    )
  }
  return
}

export function mostRecentSelectedObjectStateOfClassNames(
  classNames,
  state = store.getState()
) {
  const selectedObjects = selectedObjectsByRecency(state)

  return selectedObjects.find(so => classNames.includes(so.className))
}

export function mostRecentSelectedObjectOfTypes(
  classNames,
  state = store.getState()
) {
  const selectedObjectState = mostRecentSelectedObjectStateOfClassNames(
    classNames,
    state
  )
  if (selectedObjectState) {
    const statePropName = objectStateKeyFromClassName(
      selectedObjectState.className
    )
    return get(
      state,
      `objects.present.${statePropName}.${selectedObjectState.id}`
    )
  }
  return
}

export function objectIsSelected(objectId, state = store.getState()) {
  const selectedObjects = getSelectedObjects(state)
  return selectedObjects.some(selectedObject => selectedObject.id === objectId)
}

export function selectObjects({ objects, multiSelect, ignorePanel }) {
  const state = store.getState()
  const selectedObjects = getSelectedObjects(state)
  let updatedObjects = multiSelect ? [...selectedObjects] : []
  let objectsToSelect = []

  // if object is already selected and user is holding multiselect modifier,
  // remove the object from the selectedObjects array and keep others selected
  if (multiSelect) {
    const objectsToDeselect = []
    objects.forEach(object => {
      if (selectedObjects.find(o => o.id === object.id)) {
        objectsToDeselect.push(object)
      } else {
        objectsToSelect.push({
          id: object.id,
          className: object.className,
          layerKey: object.layerKey,
          ignorePanel: object.ignorePanel,
        })
      }
    })
    if (objectsToDeselect.length > 0) {
      deselectObjects({ objects: objectsToDeselect, ignorePanel })
      return
    }
  } else {
    objectsToSelect = objects.map(object => ({
      id: object.id,
      className: object.className,
      layerKey: object.layerKey,
      ignorePanel: object.ignorePanel,
      models: object.models,
    }))
  }

  updatedObjects = updatedObjects.concat(objectsToSelect)

  store.dispatch(
    selectObjectsAction({
      objects: updatedObjects.map(obj => ({
        id: obj.id,
        className: obj.className,
        layerKey: obj.layerKey,
        ignorePanel: obj.ignorePanel,
        models: obj.models,
      })),
      ignorePanel,
    })
  )
}

export function deselectObjects({ objects, ignorePanel, tool }) {
  const state = store.getState()
  let selectedObjects
  if (objects) {
    const objIds = objects.map(obj => obj.id)
    selectedObjects = getSelectedObjects(state).filter(
      obj => !objIds.includes(obj.id)
    )
  }
  store.dispatch(
    deselectObjectsAction({ objects, selectedObjects, ignorePanel, tool })
  )
}

export function clearGridBox(state = store.getState()) {
  const objects = getSelectedObjects(state).filter(
    obj => obj.className === CLASS_NAMES.GRID_BOX
  )

  if (objects.length) {
    store.dispatch(
      deleteObjectsAction(objects.map(obj => obj.id), clearGridBox),
    )
  }
}
