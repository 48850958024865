import React, { Component } from 'react'

export default class FacilityFormEditNotAllowed extends Component {
  // NOTE: This is more efficient than PureComponent or stateless functions
  // because react won't need to diff, it just knows to ignore it
  shouldComponentUpdate() {
    return false
  }

  render() {
    return <p>Sorry. You do not have the permissions required to edit this.</p>
  }
}
