import icons from 'config/icons'
import { ComponentPropsWithoutRef } from 'react'

interface IconProps extends Omit<ComponentPropsWithoutRef<'svg'>, 'children'> {
  name: keyof typeof icons
  size?: string | number
  height?: string | number
  width?: string | number
}

export function Icon({ name, height = '20', size = '20', width = '20', ...props }: IconProps) {
  const newWidth = size || width
  const newHeight = size || height
  const icon = icons[name]

  return (
    <svg
      width={newWidth}
      height={newHeight}
      viewBox="0 0 20 20"
      {...props}
    >
      {Array.isArray(icon) ? (
        icon.map((d, i) => <path fill="currentColor" key={i} d={d} />)
      ) : (
        <path fill="currentColor" d={icon} />
      )}
    </svg>
  )
}
