import { graphql } from '~/gql'

export const GET_VERSION_CFD_RESULTS = graphql(`
  query GetCfdImageResults($versionId: ID!) {
    Version(id: $versionId) {
      id
      cfd {
        id
        URLs {
          id
          url
          fileProps
          fileExtension
        }
        status
        type
        internalType
      }
    }
  }
`)
