import styled from 'styled-components'

const Container = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
  width: 100%;
`

export default Container
