import { firePanel, electricPanel, controlPanel, dewtectSensor, UtilityBox } from '~/config/utilityBoxes'
import LAYER_KEYS, { LayerKeys } from '~/config/layerKeys'
import { MenuDropDownButton } from '../components/MenuDropDownButton'
import { MenuItem } from '~/ui/Menu'
import { Icon } from '~/ui/Icon'
import { useAppDispatch } from '~/store/hooks'
import { setCurrentLayer } from '~/store/layers'
import { setActiveTool } from '~/store/tools'
import { showPanel } from '~/store/panel'

export default function ObstructionsAndPanelsMenu() {
  const dispatch = useAppDispatch()
  const action = (layerKey: LayerKeys, tool: string, props?: UtilityBox) => () => {
    dispatch(setCurrentLayer({ layerKey }))
    dispatch(setActiveTool({ tool, props }))
  }

  return (
    <MenuDropDownButton label="Obstructions & Panels" icon="obstruction">
      <MenuItem onAction={action(LAYER_KEYS.OBSTRUCTIONS, 'CUBE_TOOL')} variant="square">
        <Icon name='obstruction' size="14"/>
        Basic Obstruction
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.UTILITY_BOXES, 'UTILITY_BOX_TOOL', firePanel)} variant="square">
        <Icon name='panelFire' size="14"/>
        Fire Panel
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.UTILITY_BOXES, 'UTILITY_BOX_TOOL', electricPanel)} variant="square">
        <Icon name='panelElectric' size="14"/>
        Electric Panel
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.UTILITY_BOXES, 'UTILITY_BOX_TOOL', controlPanel)} variant="square">
        <Icon name='panelControl' size="14"/>
        Controller
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.UTILITY_BOXES, 'UTILITY_BOX_TOOL', dewtectSensor)} variant="square">
        <Icon name='sensor' size="14"/>
        Dewtect Sensor
      </MenuItem>
      <MenuItem onAction={() => dispatch(showPanel({ type: 'DETAILED_OBSTRUCTION_PANEL' }))} variant="square">
        <Icon name='window' size="14"/>
        Obstruction Library
      </MenuItem>
    </MenuDropDownButton>
  )
}
