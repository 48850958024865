import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import Action from 'components/UIKit/Action'

const StyledAction = styled(Action)`
  height: 100%;
  padding: ${theme('spacing.base')};
  width: 100%;

  &:focus {
    box-shadow: none;
  }
`

export default StyledAction
