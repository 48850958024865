import { graphql } from '~/gql'

const GET_USER_QUERY = graphql(`
  query GetUser($auth0UserId: String, $id: ID) {
    User(auth0UserId: $auth0UserId, id: $id) {
      ...UserFragment
    }
  }
`)

export default GET_USER_QUERY
