import React from 'react'
import PropTypes from 'prop-types'
import FPMLegend from 'components/FPMLegend'
import { useAppSelector } from "~/store/hooks";
import theme from 'config/theme'
import { SIMULATION_TYPES, isLowVelocityFacility } from 'config/cfd'
import { useFacilityDefaults } from 'hooks/useFacilityDefaults'
import { Alert } from 'components/PerformanceMetrics/subcomponents/Alert'
import { Stack } from 'components/UIKit/Stack'
import { Skeleton } from 'components/Panels/CFDPanel/styled/Skeleton'
import Controls from 'components/Panels/CFDPanel/styled/Controls'

const themeColorReducer = key => (prev, current) => {
  prev.colors.push(current.color)
  prev.xLabels.push(current[key])
  return prev
}

const defaultLegendOptions = theme.colors.lowVelocityAirflow.reduce(themeColorReducer('velocity'), {
  colors: [],
  xLabels: [],
  yLabels: [],
})

const getLegendOptions = (facility, type) => ({
  [SIMULATION_TYPES.cooling]: (isLowVelocityFacility(facility)
    ? theme.colors.lowVelocityAirflow
    : theme.colors.highVelocityAirflow
  ).reduce(themeColorReducer('velocity'), { colors: [], xLabels: [], yLabels: [] }),

  [SIMULATION_TYPES.destrat]: theme.colors[
    type === 'destrat' || type === '' ? 'destrat' : 'draftRisk'
  ].reduce(themeColorReducer('velocity'), {
    colors: [],
    xLabels: [],
    yLabels: ['Warm', 'Cool'],
  }),

  [SIMULATION_TYPES.radiantHeat]: theme.colors.intensity.reduce(themeColorReducer('intensity'), {
    colors: [],
    xLabels: [],
    yLabels: [],
  }),

  [SIMULATION_TYPES.unitHeating]: theme.colors.heat.reduce(themeColorReducer('power'), {
    colors: [],
    xLabels: [],
    yLabels: [],
  }),
})

const LegendPanel = ({ goal, children }) => {
  const type = useAppSelector(({ cfd }) => cfd.selectedLayer.type)
  const { data: facility, loading, error } = useFacilityDefaults()

  if (loading) {
    return <Skeleton />
  } else if (error || !facility) {
    return (
      <Controls flexGrow={1}>
        <Alert label="Failed to get facility data" />
      </Controls>
    )
  }

  const legendOptions = getLegendOptions(facility, type)[goal] ?? defaultLegendOptions

  return (
    <Controls minWidth="600px" flexGrow={1}>
      <Stack spacing={1}>
        <FPMLegend options={legendOptions} />
        {children}
      </Stack>
    </Controls>
  )
}

LegendPanel.propTypes = {
  goal: PropTypes.string,
  children: PropTypes.node,
}

export { LegendPanel }
