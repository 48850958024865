import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useNetwork } from 'networkProvider'

import routes from 'config/routes'
import auth0Client from 'lib/Auth'

import AuthScreen from 'screens/AuthScreen'
import DefaultErrorScreen from 'screens/DefaultErrorScreen'
import FacilitiesScreen from 'screens/FacilitiesScreen'
import FAQScreen from 'screens/FAQScreen'
import TemplatesScreen from 'screens/TemplatesScreen'
import FacilityScreen from 'screens/FacilityScreen'
import ReportsScreen from 'screens/ReportsScreen'
import OnboardingExteriorWallsScreen from 'screens/OnboardingExteriorWallsScreen'
import OnboardingRoofsScreen from 'screens/OnboardingRoofsScreen'
import OnboardingInteriorWallsScreen from 'screens/OnboardingInteriorWallsScreen'
import OnboardingCeilingsScreen from 'screens/OnboardingCeilingsScreen'
import OnboardingObstructionsScreen from 'screens/OnboardingObstructionsScreen'
import OnboardingComfortZonesScreen from 'screens/OnboardingComfortZonesScreen'
import SalesforceAuthenticationScreen from 'screens/SalesforceAuthenticationScreen'
import SalesforceCallbackScreen from 'screens/SalesforceCallbackScreen'

import NotFoundRoute from 'components/NotFoundRoute'
import ConditionalRoute from 'components/ConditionalRoute'

import ConnectedRouteListener from './ConnectedRouteListener'
import ReassignFacilitiesScreen from 'screens/ReassignFacilitiesScreen'
import SettingsScreen from 'screens/SettingsScreen'

const onboardingComponents = [
  OnboardingExteriorWallsScreen,
  OnboardingRoofsScreen,
  OnboardingInteriorWallsScreen,
  OnboardingCeilingsScreen,
  OnboardingObstructionsScreen,
  OnboardingComfortZonesScreen,
]

import { withSentryRouting } from '@sentry/react'
const SentryRoute = withSentryRouting(Route)

/**
 * Router to all screens of the site
 * @returns Route to current URL page
 */
const Routes = () => {
  const network = useNetwork()
  const authOrOffline = auth0Client.isAuthenticated || !network.online
  return (
    <>
      <ConnectedRouteListener />
      <Switch>
        <ConditionalRoute
          exact
          path={routes.auth}
          condition={authOrOffline}
          component={AuthScreen}
          redirect={routes.facilities}
        />
        <ConditionalRoute
          exact
          path={routes.authCallback}
          condition={authOrOffline}
          component={AuthScreen}
          redirect={routes.facilities}
          isCallback // Makes sure the AuthScreen knows to handle the callback.
        />
        {onboardingComponents.map((component, index) => (
          <ConditionalRoute
            key={index}
            path={routes.onboarding[index + 1].full}
            condition={!authOrOffline}
            component={component}
            redirect={routes.auth}
          />
        ))}
        {/* This is to redirect from the old URL with `/floors/` to `/areas/` */}
        <Redirect from={routes.facility.fullLegacy} to={routes.facility.full} />
        <ConditionalRoute
          path={routes.facility.full}
          condition={!authOrOffline}
          component={FacilityScreen}
          redirect={routes.auth}
        />
        <ConditionalRoute
          path={routes.facility.short}
          condition={!authOrOffline}
          component={FacilityScreen}
          redirect={routes.auth}
        />
        <ConditionalRoute
          path={routes.facilityFavorites}
          condition={!authOrOffline}
          component={FacilitiesScreen}
          redirect={routes.auth}
          view="My Favorites"
        />
        <ConditionalRoute
          path={routes.facilityArchived}
          condition={!authOrOffline}
          component={FacilitiesScreen}
          redirect={routes.auth}
          view="Archived"
        />
        <ConditionalRoute
          path={routes.facilities}
          condition={!authOrOffline}
          component={FacilitiesScreen}
          redirect={routes.auth}
          view="My Facilities"
        />
        <ConditionalRoute
          path={routes.faq}
          condition={!authOrOffline}
          component={FAQScreen}
          redirect={routes.auth}
          view="Frequenty Asked Questions"
        />
        <ConditionalRoute
          path={routes.templates}
          condition={!authOrOffline}
          component={TemplatesScreen}
          redirect={routes.auth}
        />
        <ConditionalRoute
          path={routes.reports}
          condition={!authOrOffline}
          component={ReportsScreen}
          redirect={routes.auth}
        />
        <ConditionalRoute
          path={routes.settings}
          condition={!authOrOffline}
          component={SettingsScreen}
          redirect={routes.auth}
        />
        <ConditionalRoute
          path={routes.reassign}
          condition={!authOrOffline}
          component={ReassignFacilitiesScreen}
          redirect={routes.auth}
        />
        <ConditionalRoute
          path={routes.salesforceAuthentication}
          condition={!authOrOffline}
          component={SalesforceAuthenticationScreen}
          redirect={routes.auth}
        />
        <ConditionalRoute
          path={routes.salesforceCallback}
          condition={!authOrOffline}
          component={SalesforceCallbackScreen}
          redirect={routes.auth}
        />
        <SentryRoute
          path={routes.canvasRenderError}
          render={props => (
            <DefaultErrorScreen
              title="An error has caused the drawing canvas to crash"
              description={`
              Please try refreshing the application, or restarting all
              browser instances.
            `}
              refresh={false}
            />
          )}
        />
        <SentryRoute component={NotFoundRoute} />
      </Switch>
    </>
  )
}

export default Routes
