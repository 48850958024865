import React from 'react'
import { appConnect } from "~/store/hooks";
import PropTypes from 'prop-types'
import isEmpty from 'lodash-es/isEmpty'

import { getIconForStatusType } from 'lib/utils'

import Loader from 'components/UIKit/Loader'

import Container from './styled/Container'
import Content from './styled/Content'
import Icon from './styled/Icon'
import Text from './styled/Text'

const renderLoader = text => <Loader label={text} />

const renderStatus = ({ type, text }) => {
  if (isEmpty(type) || isEmpty(text) || type === 'overlay') {
    return null
  }

  if (type === 'loading') {
    return renderLoader(text)
  }

  return (
    <>
      <Icon
        size="16"
        name={getIconForStatusType(type).icon}
        color={getIconForStatusType(type).color}
      />
      <Text>{text}</Text>
    </>
  )
}

renderStatus.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
}

const StatusBar = ({ status, isSaving } = {}) => (
  <Container type={isSaving || status.type === 'loading' ? null : status.type}>
    <Content>
      {isSaving ? renderLoader('Saving...') : renderStatus(status)}
    </Content>
  </Container>
)

StatusBar.propTypes = {
  status: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
  }),
  isSaving: PropTypes.bool,
}

const mapStateToProps = ({ status, objectsPersistence }) => ({
  status,
  isSaving: objectsPersistence.isSaving,
})

export default appConnect(mapStateToProps)(StatusBar)
