import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.div`
  display: flex;
  margin-right: ${theme('spacing.s')};
`

export default Container
