import Util from './util'
import Units from './units'
import Facility from './facility'
import WallSegment from './wallSegment'
import store from 'store'
import { setStatus } from 'store/status'
import { updateUtilityBox } from 'store/objects'
import { objectIsSelected } from 'store/selectedObjects/selectors'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import OBJECT_TYPES from 'config/objectTypes'
import CLICK_PRIORITY from 'config/clickPriority'
import { getThreeHexFromTheme } from 'lib/utils'

import * as THREE from 'three'
const DEFAULT_THICKNESS = Units.inchesToNative(24)
const DEFAULT_HANDLE_HEIGHT = Units.feetToNative(200)

class UtilityBox {
  constructor(model) {
    this.units = 'INCHES'
    this.className = CLASS_NAMES.UTILITY_BOX
    this.utilityBoxType = model.utilityBoxType
    this.canMountOnColumn =
      this.utilityBoxType === 'controlPanel' ||
      this.utilityBoxType === 'dewtectSensor' ||
      this.utilityBoxType === 'electricPanel'
    this.id = model.id ? model.id : Util.guid()
    this.centerLines = model.centerLines || []
    this.height = model.height
    this.width = model.width
    this.thickness = model.thickness || DEFAULT_THICKNESS
    this.handleHeight = model.handleHeight || DEFAULT_HANDLE_HEIGHT
    this.centerPointHeight = model.centerPointHeight
    this.color = model.color
    this.label = model.label
    this.layerKey = LAYER_KEYS.UTILITY_BOXES
    this.clickPriority = CLICK_PRIORITY[this.layerKey]
    this.wallSegmentId = model.wallSegmentId

    const wallSegment = Facility.current.getWallSegments().find(seg => {
      return this.wallSegmentId === seg.id
    })
    this.wallLayerKey = wallSegment ? wallSegment.layerKey : 'INTERIOR_WALLS'

    const position = new THREE.Vector3().copy({
      x: Units.inchesToNative(model.position.x),
      y: Units.inchesToNative(model.position.y),
      z: Units.inchesToNative(model.centerPointHeight),
    })

    this.lastValidPos = new THREE.Vector3().copy(position)
    this.lastValidRotation = model.rotation

    const utilityBoxWidth = Units.inchesToNative(this.width)
    const utilityBoxHeight = Units.inchesToNative(this.height)
    const utilityBoxThickness = Units.inchesToNative(this.thickness / 2 + 24)

    const geometry = new THREE.BoxGeometry(
      utilityBoxWidth,
      utilityBoxThickness,
      utilityBoxHeight
    )
    const color = model.isValidPosition
      ? getThreeHexFromTheme('three.valid')
      : this.color
    const material = new THREE.MeshLambertMaterial({ color })
    this.obj3d = new THREE.Mesh(geometry, material)

    // Set the position
    this.obj3d.position.copy(new THREE.Vector3().copy(position))

    // Set the rotation
    const z = model.rotation.z || model.rotation._z || 0
    this.obj3d.rotation.set(0, 0, z)

    // Install information
    this.brand = model.brand
    this.voltage = model.voltage
    this.type = model.type
    this.fans = model.fans

    // Used by Interactifier
    this.obj3d.wrapperId = this.id
    this.selectable = true
    this.draggable = false
    this.multiDraggable = false

    // Add drag handle in 2D mode
    if (!store.getState().camera.is3D) {
      this.addDragHandle(utilityBoxWidth, utilityBoxThickness, color)
    }

    if (objectIsSelected(this.id)) {
      this.select()
    }

    this.changeVisibilityBasedOnVisibleLayers()
  }

  static createModel(
    position,
    utilityBoxType,
    width,
    height,
    centerPointHeight,
    color,
    label,
    rotation,
    isValidPosition,
    layerKey,
    handleHeight,
    wallSegmentId,
    isDrawing,
    brand,
    voltage,
    type,
    fans
  ) {
    return {
      id: Util.guid(),
      position: {
        x: Units.nativeToInches(position.x),
        y: Units.nativeToInches(position.y),
        z: Units.nativeToInches(position.z),
      },
      rotation,
      wallSegmentId,
      utilityBoxType,
      width,
      height,
      centerPointHeight,
      color,
      label,
      isValidPosition,
      layerKey,
      handleHeight,
      isDrawing,
      brand,
      voltage,
      type,
      fans,
    }
  }

  toModel = () => {
    const model = {
      brand: this.brand,
      voltage: this.voltage,
      type: this.type,
      fans: this.fans,
      id: this.id,
      layerKey: LAYER_KEYS.UTILITY_BOXES,
      position: {
        x: Units.nativeToInches(this.obj3d.position.x),
        y: Units.nativeToInches(this.obj3d.position.y),
        z: Units.nativeToInches(this.obj3d.position.z),
      },
      rotation: this.obj3d.rotation,
      utilityBoxType: this.utilityBoxType,
      canMountOnColumn: this.canMountOnColumn,
      width: this.width,
      height: this.height,
      handleHeight: this.handleHeight,
      centerPointHeight: this.centerPointHeight,
      color: this.color,
      label: this.label,
      wallSegmentId: this.wallSegmentId,
    }

    return model
  }

  select(draggable = true) {
    this.draggable = draggable

    // If the parent wall layer is locked, prevent utility boxes from moving
    if (this.wallLayerKey) {
      const isLocked = store.getState().layers.layers[this.wallLayerKey].locked
      if (isLocked) return
    }

    this.obj3d.material.color.setHex(
      getThreeHexFromTheme('three.objects.utilityBox.selected')
    )
    if (this.handle) {
      this.handle.material.color.setHex(
        getThreeHexFromTheme('three.objects.utilityBox.selected')
      )
    }
  }

  deselect() {
    this.draggable = false
    this.obj3d.material.color.setHex(this.color)
    if (this.handle) {
      this.handle.material.color.setHex(this.color)
    }
  }

  isLayerVisible() {
    if (!this.wallLayerKey) return true

    const layers = store.getState().layers
    return layers.layers[this.wallLayerKey].visible
  }

  changeVisibilityBasedOnVisibleLayers() {
    // We want to change utility boxes visibility
    // based on its parent wall visibility.
    if (!this.wallLayerKey && !this.wallSegmentId) return

    if (!this.isLayerVisible()) {
      this.obj3d.material.transparent = true
      this.obj3d.material.opacity = 0
      if (this.handle) {
        this.handle.material.transparent = true
        this.handle.material.opacity = 0
      }
    }
  }

  addDragHandle(utilityBoxWidth, utilityBoxThickness, color) {
    const geometry = new THREE.BoxGeometry(
      utilityBoxWidth,
      utilityBoxThickness,
      1
    )
    const material = new THREE.MeshLambertMaterial({ color })
    this.handle = new THREE.Mesh(geometry, material)
    this.handle.wrapperId = this.id
    this.handle.position.set(0, 0, this.handleHeight)
    this.obj3d.add(this.handle)
  }

  updateMountedDirection(startPoint, endPoint) {
    if (!startPoint || !endPoint) return

    const direction = new THREE.Vector3()
      .subVectors(startPoint, endPoint)
      .normalize()

    this.obj3d.quaternion.setFromUnitVectors(
      new THREE.Vector3(1, 0, 0),
      direction
    )

    // Keep box opening facing inside
    if (this.obj3d.quaternion.y !== 0) {
      this.obj3d.quaternion.y = 0
      this.obj3d.rotation.z = Math.PI
    }
  }

  isOverSnappableObject() {
    const pos = this.obj3d.position.clone()
    const positions = [
      new THREE.Vector3(pos.x + 1, pos.y, pos.z),
      new THREE.Vector3(pos.x - 1, pos.y, pos.z),
      new THREE.Vector3(pos.x, pos.y + 1, pos.z),
      new THREE.Vector3(pos.x, pos.y - 1, pos.z),
    ]
    let objectFound = false

    for (let i = 0; i < positions.length; i++) {
      const objects = Util.getObjectsUnderPositionByType(
        positions[i],
        [OBJECT_TYPES.WALL, OBJECT_TYPES.COLUMN],
        Facility
      )
      if (objects.length) {
        const box = new THREE.Box3().setFromObject(objects[0].obj3d)
        this.handleHeight = box.max.z + 20
        objectFound = true
        break
      }
    }

    return objectFound
  }

  /*
    SceneBuilder event
  */
  visibilityChanged(visible) {
    if (this.obj3d) this.obj3d.visible = visible
  }

  findWallSegment() {
    let foundSegment = null
    const segments = Facility.current.getWallSegments()
    for (let i = 0; i < segments.length; i++) {
      const seg = segments[i]
      const outsetPoints = seg.getOutsetPoints()
      const insetPoints = seg.getInsetPoints()
      const isOnInsetLine =
        insetPoints.length > 1 &&
        Util.isPointOnSegment(
          Util.arrayPointToObjectPoint(insetPoints[0]),
          this.obj3d.position,
          Util.arrayPointToObjectPoint(insetPoints[1])
        )
      const isOnOutsetLine =
        outsetPoints.length > 1 &&
        Util.isPointOnSegment(
          Util.arrayPointToObjectPoint(outsetPoints[0]),
          this.obj3d.position,
          Util.arrayPointToObjectPoint(outsetPoints[1])
        )

      if (isOnInsetLine || isOnOutsetLine) {
        foundSegment = seg
        break
      }
    }

    return foundSegment
  }

  drop(_, __, saveModel = true) {
    const isInsideFacility = Util.isPositionsOverFacility([this.obj3d.position])
    if (isInsideFacility) {
      const isOverSnapObject = this.isOverWall || this.isOverSnapObject
      if (this.isSnapped && !isOverSnapObject) {
        // Mouse might not be over a wall, but the box could be.
        // Try to see if box point lies on wall segment
        const segment = this.findWallSegment()
        if (segment) {
          this.isOverWall = true
          this.wallSegmentId = segment.id
          this.updateMountedDirection(segment.startPoint, segment.endPoint)
        }
      }

      // If not snapped reset wall segment id
      if (!this.isSnapped) this.wallSegmentId = null

      if (saveModel) {
        store.dispatch(updateUtilityBox({ utilityBox: this.toModel() }))
      }
    } else {
      this.obj3d.rotation.copy(this.lastValidRotation)
      this.obj3d.position.copy(this.lastValidPos)

      // Use timeout so status message isn't prematurely cleared
      setTimeout(() => {
        const error = 'Utility boxes must be placed inside the facility!'
        store.dispatch(setStatus({ text: error, type: 'error' }))
      }, 500)
    }
  }

  getSnappableEdgePoints() {
    return [this.obj3d.position]
  }

  drag({ newPosition, allIntersectedObjects }) {
    this.obj3d.remove(this.clearance)
    this.obj3d.remove(this.edges)
    this.obj3d.position.copy(newPosition)
    this.isSnapped = false
    this.isOverWall = false
    this.isOverSnapObject = false

    const wall = allIntersectedObjects
      ? allIntersectedObjects.find(obj => obj instanceof WallSegment)
      : null

    if (wall) {
      this.isOverWall = true
      this.wallSegmentId = wall.id

      if (this.handle) {
        this.handle.position.set(0, 0, wall.height)
      }

      this.updateMountedDirection(wall.startPoint, wall.endPoint)
    }

    this.obj3d.material.opacity = 0.2
    this.obj3d.material.color.set(
      getThreeHexFromTheme('three.objects.utilityBox.selected')
    )
    if (this.handle) {
      this.handle.material.opacity = 0.2
      this.handle.material.color.setHex(
        getThreeHexFromTheme('three.objects.utilityBox.selected')
      )
    }
  }

  snap(
    snapDelta,
    lastSceneIntersection,
    snappedMousePos,
    startPoint,
    endPoint
  ) {
    if (!startPoint || !endPoint) return

    this.isSnapped = true
    this.obj3d.position.add(snapDelta)
    this.isOverSnapObject = this.isOverSnappableObject()

    // Snapping, but no wall found. Try to find the wall and update orientation
    if (!this.isOverWall) {
      const wall = this.findWallSegment()
      if (wall) {
        this.isOverWall = true
        this.wallSegmentId = wall.id

        if (this.handle) {
          this.handle.position.set(0, 0, wall.height)
        }

        this.updateMountedDirection(wall.startPoint, wall.endPoint)
      }
    }

    // Update rotation if over snappable objects other than walls
    if (!this.isOverWall && this.isOverSnapObject) {
      this.updateMountedDirection(startPoint, endPoint)
    }

    if (this.isOverWall || this.isOverSnapObject) {
      this.obj3d.material.color.set(getThreeHexFromTheme('three.valid'))
      this.obj3d.material.opacity = 1
    }
    if (this.handle) {
      this.handle.material.opacity = 1
      this.handle.material.color.setHex(getThreeHexFromTheme('three.valid'))
    }
  }

  setPosition(pos) {
    this.obj3d.position.set(new THREE.Vector3().copy(pos))
  }

  destroy() {
    return
  }
}

export default UtilityBox
