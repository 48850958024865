import React, { PureComponent } from 'react'
import { graphql } from '~/gql'
import PropTypes from 'prop-types'
import { Query } from '@apollo/client/react/components'
import isFunction from 'lodash-es/isFunction'
import get from 'lodash-es/get'

export const HasPermissionQuery = graphql(`
  query UserHasPermission($permissionName: String!) {
    isAllowed: currentUserHasPermission(permissionName: $permissionName)
  }
`)

export default class Permissions extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    query: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    LoadingComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    ErrorComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    FallbackComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  }

  static defaultProps = {
    query: HasPermissionQuery,
    children: () => {},
    LoadingComponent: () => null,
    ErrorComponent: () => null,
    FallbackComponent: () => null,
  }

  FallbackRenderer = (props = {}) => {
    const {
      isAllowed,
      isLoading,
      didError,
      isRefetching,
      LoadingComponent,
      ErrorComponent,
      FallbackComponent,
      children,
    } = props

    if (isLoading) return <LoadingComponent />
    if (didError) return <ErrorComponent />
    if (isRefetching) return <LoadingComponent />

    if (isAllowed) return children
    return <FallbackComponent />
  }

  render() {
    return (
      <Query
        query={this.props.query}
        variables={{
          permissionName: this.props.name,
        }}
        displayName="PermissionsQuery"
        notifyOnNetworkStatusChange
      >
        {queryResult => {
          const isRefetching = queryResult.networkStatus === 4
          const isAllowed = get(queryResult, 'data.isAllowed') || false
          const providedProps = {
            isAllowed,
            isLoading: queryResult.loading,
            didError: queryResult.error,
            isRefetching,
          }

          if (isFunction(this.props.children)) {
            return this.props.children(providedProps)
          }
          return (
            <this.FallbackRenderer
              {...providedProps}
              LoadingComponent={this.props.LoadingComponent}
              ErrorComponent={this.props.ErrorComponent}
              FallbackComponent={this.props.FallbackComponent}
            >
              {this.props.children}
            </this.FallbackRenderer>
          )
        }}
      </Query>
    )
  }
}
