import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_VERSION_CFD_RESULTS } from 'client/queries/getVersionCFDResults'
import { SIMULATION_TYPES } from 'config/cfd'
import routes from 'config/routes'

const isRelevantImage = (simulationType, id, fileExtension) => {
  switch (simulationType) {
    case SIMULATION_TYPES.radiantHeat:
    case SIMULATION_TYPES.destrat:
    case SIMULATION_TYPES.cooling: {
      const isValidImage = fileExtension === 'png'
      return isValidImage
    }
    case SIMULATION_TYPES.unitHeating: {
      const isValidUnitHeating = id.includes('T_') && fileExtension === 'png'
      return isValidUnitHeating
    }
    default: {
      console.warn('Unexpected internalType: ', simulationType)
      return false
    }
  }
}

/**
 * Matches ids to pull out the turnover percentage value and display it as a decimal.
 * For example, the id "...T_x1-025turnover.png" would have "025" extracted and returned
 * as "0.25". Note that 0% values are represented without a "turnover" suffix: T_x1.png
 * @param {String} id
 * @returns {String}
 */
const parseTurnover = id => {
  const turnoverIdRegex = new RegExp(/T_[xyz]\d+(?:inch)?[-.](\d*)/)
  const match = turnoverIdRegex.exec(id)
  const matchedTurnoverValue = match?.[1]
  if (!match || !matchedTurnoverValue) return '0'
  const decimal = parseInt(matchedTurnoverValue) / 100
  return decimal.toString()
}

/**
 * @param {string} id
 * @returns {boolean}
 */
const isTemperatureImage = id => {
  const temperatureRegex = new RegExp(/T_[xyz]/)
  return temperatureRegex.test(id)
}
const sortByTurnover = (a, b) => parseFloat(a.turnover) - parseFloat(b.turnover)

const groupImageUrls = data => {
  const nonNullCfdResults = data.Version.cfd.filter(cfd => !!cfd)
  return nonNullCfdResults.map(({ URLs, status, internalType }) => {
    const groupedImgUrls = URLs.reduce((accumulator, current) => {
      if (isRelevantImage(internalType, current.id, current.fileExtension)) {
        if (internalType === SIMULATION_TYPES.radiantHeat) {
          const type = current.id.includes('intensity_zNormal_iso') ? 'Plan' : 'Overhead'
          accumulator.set(type, [current])
        } else if (internalType === SIMULATION_TYPES.unitHeating) {
          const { fileProps } = current
          const roomSlices = accumulator.get(fileProps.type) ?? new Map()
          const airflowTurnoverImgs = roomSlices.get(fileProps.level) ?? []
          const currentImg = { ...current, turnover: parseTurnover(current.id) }
          roomSlices.set(fileProps.level, [...airflowTurnoverImgs, currentImg])
          accumulator.set(fileProps.type, roomSlices)
        } else {
          const { id, fileProps } = current
          const view = isTemperatureImage(id) ? `${fileProps.type}_temperature` : fileProps.type
          const urlCollection = accumulator.get(view) ?? []
          accumulator.set(view, [current, ...urlCollection])
        }
      }
      return accumulator
    }, new Map())

    const imageUrls = Array.from(groupedImgUrls.entries()).map(([view, images]) => {
      const isUnitHeatSim = images instanceof Map
      if (isUnitHeatSim) {
        const unitHeatEntries = Array.from(images.entries())
        const unitHeatImages = unitHeatEntries.map(([slice, unsortedAirflowImgs]) => ({
          slice,
          airflowImgs: unsortedAirflowImgs.sort(sortByTurnover),
        }))
        return {
          view,
          unitHeatImages,
          thumbnail: unitHeatImages[0].airflowImgs[0].url,
        }
      } else {
        return {
          view,
          images: images.sort((a, b) => a.fileProps.level - b.fileProps.level),
          thumbnail: images[0].url,
        }
      }
    })

    return { type: internalType, status, imageUrls }
  })
}

export const useCFDImages = () => {
  const { versionId } = useRouteMatch(routes.facility.full)?.params
  const { data, loading, error, ...queryResults } = useQuery(GET_VERSION_CFD_RESULTS, {
    variables: { versionId },
    skip: !versionId,
    fetchPolicy: 'network-only',
  })
  return {
    loading,
    error,
    data: useMemo(() => data && groupImageUrls(data), [data]),
    ...queryResults
  }
}

/**
 * @typedef {object} Image
 * @prop {string} id
 * @prop {string} url
 *
 * @typedef {object} CFDImagesFlat
 * @prop {string} status - An enum value from the database. "COMPLETE", "FAILED", etc
 * @prop {string} goal - The simulation type. "cooling", "destrat", etc
 * @prop {Image[]} images
 *
 * @typedef {object} QueryResult
 * @prop {boolean} loading
 * @prop {Error} error
 * @prop {CFDImagesFlat[]} data
 *
 * @returns {QueryResult}
 */
export const useCFDImagesFlat = () => {
  const { versionId } = useRouteMatch(routes.facility.full)?.params
  const { data, loading, error } = useQuery(GET_VERSION_CFD_RESULTS, {
    variables: { versionId },
    skip: !versionId,
  })
  const formattedData = useMemo(() => {
    const nonNullResults = data?.Version?.cfd.filter(cfdResult => !!cfdResult)
    return nonNullResults?.map(({ URLs, status, internalType }) => {
      const images = URLs.filter(({ fileExtension }) => fileExtension === 'png').map(
        ({ id, url }) => ({
          id,
          url,
        })
      )
      return {
        status,
        goal: internalType,
        images,
      }
    })
  }, [data])

  return {
    loading,
    error,
    data: formattedData,
  }
}
