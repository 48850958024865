import React from 'react'
import PropTypes from 'prop-types'

import Flex from 'components/UIKit/Flex'
import Popover from 'components/UIKit/Popover'
import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'

import Image from './styled/Image'
import TriggerButton from './styled/TriggerButton'

const imageSize = '40'

function Menu({ displayValue, items, handleSelect, selectedImg, value }) {
  return (
    <Popover
      items={items.map(item => {
        const titleSting = item.title || item.key
        const title = titleSting && titleSting.replace(/-/g, ' ')
        const returnItem = {
          title: title,
          value: item.key,
          onClick: () => handleSelect(item.key),
        }
        if (item.imageSrc)
          returnItem.image = {
            height: imageSize,
            src: item.imageSrc,
            style: { objectFit: 'contain' },
            width: imageSize,
          }
        return returnItem
      })}
      renderTrigger={({ isDropdownVisible }) => {
        const titleString = displayValue || value || 'Select'
        const title = titleString.replace(/-/g, ' ')
        return (
          <TriggerButton
            dropdown
            fullWidth
            isDropdownVisible={isDropdownVisible}
            size="l"
          >
            <Flex alignItems="center">
              {selectedImg ? (
                <Image src={selectedImg} alt={selectedImg} />
              ) : null}
              <Space left={value && selectedImg && 'base'} right="base">
                <VariantText size="l" color={value ? 'fg' : 'light'}>
                  {title}
                </VariantText>
              </Space>
            </Flex>
          </TriggerButton>
        )
      }}
    />
  )
}

Menu.propTypes = {
  displayValue: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
    })
  ),
  handleSelect: PropTypes.func,
  selectedImg: PropTypes.string,
  value: PropTypes.string,
}

export default Menu
