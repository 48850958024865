import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Stack } from 'components/UIKit/Stack'
import VariantText from 'components/UIKit/VariantText'
import theme from 'config/theme'

const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(${({ columnCount }) => columnCount}, 1fr);
  grid-template-rows: repeat(${({ rowCount }) => rowCount}, 1fr);
  gap: ${({ gap }) => (Array.isArray(gap) ? gap.map(g => `${g}em`).join(' ') : `${gap}em`)};
  place-items: ${({ placeItems }) => placeItems};
`

const BasicGrid = ({ columns, gap = 1, placeItems = 'center' }) => {
  const maxCellCount = columns.reduce((prev, columnCells) => Math.max(prev, columnCells.length), 0)
  return (
    <GridContainer
      columnCount={columns.length}
      rowCount={maxCellCount}
      gap={gap}
      placeItems={placeItems}
    >
      {columns.map(columnCells =>
        columnCells.map(({ content, icon }, i) => (
          <div key={i}>
            {
              <Stack>
                {icon}
                <VariantText fontWeight={theme.fonts.weights.semiBold} size="l">
                  {content}
                </VariantText>
              </Stack>
            }
          </div>
        ))
      )}
    </GridContainer>
  )
}

BasicGrid.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.node,
        icon: PropTypes.node,
      })
    )
  ),
  gap: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  placeItems: PropTypes.string,
  width: PropTypes.string,
}

export { BasicGrid }
