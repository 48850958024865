import styled from 'styled-components'

import { getZIndexOf } from 'lib/utils'
import { stretch } from 'lib/styleUtils'

const Mask = styled.div`
  ${stretch()};

  background-color: ${props => props.backgroundColor};
  opacity: ${props => props.opacity};
  pointer-events: none;
  z-index: ${getZIndexOf('loadingOverlay')};
`

export default Mask
