import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Container = styled.blockquote`
  border-left: 3px solid ${theme('colors.light.subdued')};
  font-style: italic;
  padding-left: ${theme('spacing.base')};
`

export default Container
