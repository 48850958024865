import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/UIKit/Button'

const getCloudinaryImageDownload = cloudinaryImage => {
  if (!cloudinaryImage) return null
  const [fullUrl, partialMatch] = cloudinaryImage.match(/.*(upload).*/)
  if (!fullUrl || !partialMatch) return null
  const newUrl = fullUrl.replace(partialMatch, `${partialMatch}/fl_attachment`)
  return newUrl
}

function DownloadImageButton(props = {}) {
  const downloadImageUrl = getCloudinaryImageDownload(props.cloudinaryImage)
  if (!downloadImageUrl) return null
  return <Button href={downloadImageUrl} {...props} />
}

DownloadImageButton.propTypes = {
  ...Button.propTypes,
  cloudinaryImage: PropTypes.string.isRequired,
}

DownloadImageButton.defaultProps = {
  icon: 'download',
  label: 'Download',
  primary: true,
}

export default DownloadImageButton
