import React, { Component } from 'react'
import { string, func, object } from 'prop-types'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'

import { addObject } from 'store/objects'
import { start2DFit } from 'store/camera'
import { withUnits } from 'store/units/decorators'
import { Distance } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'
import LAYER_KEYS from 'config/layerKeys'
import { onCreateLShape } from 'config/analytics'
import { trackEvent } from 'lib/analytics'

import Wall from 'components/DrawingCanvas/lib/wall'

import Action from 'components/UIKit/Action'
import Button from 'components/UIKit/Button'
import DimensionInput from 'components/UIKit/DimensionInput'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Icon from 'components/UIKit/Icon'
import Space from 'components/UIKit/Space'

import Actions from './styled/Actions'
import ShapeContainer from './styled/ShapeContainer'
import Shape from './styled/Shape'

class LShapeScreen extends Component {
  state = {
    xFlipped: false,
    yFlipped: false,
    sideA: new Distance({
      value: 432,
      system: SYSTEMS.IMPERIAL,
    }),
    sideB: new Distance({
      value: 216,
      system: SYSTEMS.IMPERIAL,
    }),
    sideC: new Distance({
      value: 168,
      system: SYSTEMS.IMPERIAL,
    }),
    sideD: new Distance({
      value: 624,
      system: SYSTEMS.IMPERIAL,
    }),
    height: new Distance({
      value: 288,
      system: SYSTEMS.IMPERIAL,
    }),
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value,
    })
  }

  handleFlipClick = (side, event) => {
    event.preventDefault()

    let name = 'xFlipped'

    if (side === 'y') {
      name = 'yFlipped'
    }

    this.setState({
      [name]: !this.state[name],
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    const thickness = new Distance({
      value: Wall.thicknessForLayerKey(LAYER_KEYS.EXTERIOR_WALLS, true),
      system: SYSTEMS.NATIVE,
    })
    const {
      addWall,
      zoomToFit,
      currentLayer,
      parentRoute,
      history,
    } = this.props
    const {
      xFlipped,
      yFlipped,
      sideA,
      sideB,
      sideC,
      sideD,
      height,
    } = this.state

    const outsideSideA = sideA.imperial() + thickness.imperial()
    const outsideSideB = sideB.imperial() + thickness.imperial()
    const outsideSideC = sideC.imperial() + thickness.imperial()
    const outsideSideD = sideD.imperial() + thickness.imperial()

    let perimeterPoints = [
      [0, outsideSideA - outsideSideC],
      [-outsideSideB, outsideSideA - outsideSideC],
      [-outsideSideB, -outsideSideC],
      [outsideSideD - outsideSideB, -outsideSideC],
      [outsideSideD - outsideSideB, 0],
      [0, 0],
      [0, outsideSideA - outsideSideC],
    ]

    if (xFlipped) {
      perimeterPoints = perimeterPoints.map(point => [point[0] * -1, point[1]])
    }
    if (yFlipped) {
      perimeterPoints = perimeterPoints.map(point => [point[0], point[1] * -1])
    }

    const wallModel = Wall.createModel(
      perimeterPoints,
      'inches',
      currentLayer,
      { height: height.imperial(), convertFromNativeUnits: false }
    )

    const wall = new Wall(wallModel, 'INCHES')

    wall.segments.forEach(segment => (segment.wall = wall))

    addWall(wall.toModel())
    history.push(parentRoute)
    zoomToFit()
  }

  setTextTransform = position => {
    const positions = {
      A: {
        translate: '0, 110',
        scale: '1 -1',
        yFlipped: {
          translate: '0, 110',
        },
      },
      B: {
        translate: '60, 10',
        scale: '-1 -1',
        xFlipped: {
          translate: '65, 10',
        },
        yFlipped: {
          translate: '60, 0',
        },
        xyFlipped: {
          translate: '60, 0',
        },
      },
      C: {
        translate: '190, 140',
        scale: '-1 1',
        xFlipped: {
          translate: '200, 140',
        },
      },
      D: {
        translate: '95, 200',
        scale: '-1 1',
        xFlipped: {
          translate: '95, 200',
        },
      },
    }

    const { xFlipped, yFlipped } = this.state
    const [xDirection, yDirection] = positions[position].scale.split(' ')

    let coordinates = positions[position].translate

    if (xFlipped && yFlipped && positions[position].xyFlipped) {
      coordinates = positions[position].xyFlipped.translate
    }

    if (xFlipped && positions[position].xFlipped) {
      coordinates = positions[position].xFlipped.translate
    }

    if (yFlipped && positions[position].yFlipped) {
      coordinates = positions[position].yFlipped.translate
    }

    const x = xFlipped && xDirection === '-1' ? '-1' : '1'
    const y = yFlipped && yDirection === '-1' ? '-1' : '1'

    return `translate(${coordinates}) scale(${x}, ${y})`
  }

  render() {
    const {
      xFlipped,
      yFlipped,
      sideA,
      sideB,
      sideC,
      sideD,
      height,
    } = this.state
    const { distanceUnits } = this.props

    return (
      <Grid vCenter>
        <GridItem size="1of2">
          <form onSubmit={this.handleSubmit}>
            <Space bottom="base">
              <h2>L-Shape</h2>
            </Space>
            <Space bottom="l">
              <Space bottom="base">
                <DimensionInput
                  name="sideA"
                  label="Side A"
                  value={sideA}
                  distance={sideA}
                  tabIndex={1}
                  units={distanceUnits}
                  onChange={({ distance }) => {
                    this.handleChange({
                      name: 'sideA',
                      value: distance,
                    })
                  }}
                />
              </Space>
              <Space bottom="base">
                <DimensionInput
                  name="sideB"
                  label="Side B"
                  value={sideB}
                  distance={sideB}
                  tabIndex={2}
                  units={distanceUnits}
                  onChange={({ distance }) => {
                    this.handleChange({
                      name: 'sideB',
                      value: distance,
                    })
                  }}
                />
              </Space>
              <Space bottom="base">
                <DimensionInput
                  name="sideC"
                  label="Side C"
                  value={sideC}
                  distance={sideC}
                  tabIndex={3}
                  units={distanceUnits}
                  onChange={({ distance }) => {
                    this.handleChange({
                      name: 'sideC',
                      value: distance,
                    })
                  }}
                />
              </Space>
              <Space bottom="base">
                <DimensionInput
                  name="sideD"
                  label="Side D"
                  value={sideD}
                  distance={sideD}
                  tabIndex={4}
                  units={distanceUnits}
                  onChange={({ distance }) => {
                    this.handleChange({
                      name: 'sideD',
                      value: distance,
                    })
                  }}
                />
              </Space>
              <Space bottom="base">
                <DimensionInput
                  name="height"
                  tooltip="If the roof is sloped, enter eave height"
                  label="Height"
                  value={height}
                  distance={height}
                  tabIndex={5}
                  units={distanceUnits}
                  onChange={({ distance }) => {
                    this.handleChange({
                      name: 'height',
                      value: distance,
                    })
                  }}
                />
              </Space>
            </Space>
            <Button
              primary
              label="Create L-Shape"
              type="submit"
              tabIndex={6}
              onClick={() => trackEvent(onCreateLShape())}
            />
          </form>
        </GridItem>
        <GridItem size="1of2">
          <ShapeContainer>
            <Shape
              xFlipped={xFlipped}
              yFlipped={yFlipped}
              xmlns="http://www.w3.org/2000/svg"
              width="200"
              height="200"
              viewBox="0 0 200 200"
            >
              <path d="M103.6 95.8V21.6H21.9v155.8h155.8V95.8h-74.1zm66.4 73.8H29.7V29.4h66v74.2H170v66z" />
              <text transform={this.setTextTransform('A')}>A</text>
              <text transform={this.setTextTransform('B')}>B</text>
              <text transform={this.setTextTransform('C')}>C</text>
              <text transform={this.setTextTransform('D')}>D</text>
            </Shape>
          </ShapeContainer>
          <Actions>
            <Action
              text="Flip Horizontal"
              onClick={event => this.handleFlipClick('x', event)}
            >
              <Icon name="swapHorizontal" />
            </Action>
            <Action
              text="Flip Vertical"
              onClick={event => this.handleFlipClick('y', event)}
            >
              <Icon name="swapVertical" />
            </Action>
          </Actions>
        </GridItem>
      </Grid>
    )
  }
}

LShapeScreen.propTypes = {
  addWall: func,
  zoomToFit: func,
  currentLayer: string,
  history: object,
  parentRoute: string,
  distanceUnits: string,
}

const mapDispatchToProps = dispatch => ({
  addWall(wallModel) {
    dispatch(addObject({ object: wallModel }))
  },
  zoomToFit() {
    dispatch(start2DFit())
  },
})

const mapStateToProps = ({ layers }) => ({
  currentLayer: layers.currentLayer,
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withUnits
)(LShapeScreen)
