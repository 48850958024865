import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const ShapeContainer = styled.div`
  margin-bottom: ${theme('spacing.l')};
  text-align: center;
`

export default ShapeContainer
