import React, { PureComponent } from 'react'
import { shape, arrayOf, func, string, number } from 'prop-types'

import Grid, { GridItem } from 'components/UIKit/Grid'
import Icon from 'components/UIKit/Icon'
import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'

import BlankSlate from './styled/BlankSlate'
import Container from './styled/Container'
import Item from './styled/Item'

import { withImageSelector } from './selectors'

import Image from './Image'

class DocumentExplanation extends PureComponent {
  static defaultProps = {
    limit: 2,
    selectedImages: [],
    imageTypes: [],
  }

  state = {
    selectedImages: {},
  }

  componentDidMount() {
    const selectedImages = {}
    this.props.selectedImages.forEach(img => {
      selectedImages[img.url] = img
    })

    this.props.setImages(this.props.selectedImages)
    this.setState({ selectedImages })
  }

  handleClick = ({ url }, event) => {
    event.preventDefault()
    const { selectedImages } = this.state
    const copy = Object.entries(selectedImages).reduce((rtn, [key, img]) => {
      rtn[key] = { ...img }
      return rtn
    }, {})

    // If they have already selected the image, remove it.
    if (this.isSelectedImage(url)) {
      delete copy[url]
    } else {
      // Otherwise, we want to add it to the set, if there is space.
      if (this.canAddImage()) {
        const { naturalWidth, naturalHeight } = event.currentTarget
        copy[url] = {
          naturalWidth,
          naturalHeight,
          aspectRatio: naturalWidth / naturalHeight,
          url,
        }
      }
    }

    this.props.setImages(Object.values(copy))
    this.setState({
      selectedImages: copy,
    })
  }

  isSelectedImage = img => !!this.state.selectedImages[img]

  canAddImage = () =>
    Object.keys(this.state.selectedImages).length < this.props.limit

  getImageNumber = img =>
    [...Object.keys(this.state.selectedImages)].indexOf(img) + 1

  getEmptyImagesMessage() {
    const options1 = []
    const options2 = []
    this.props.imageTypes.forEach((imageType, i) => {
      let string1 = ''
      let string2 = ''
      if (i > 0 && i === this.props.imageTypes.length - 1) {
        string1 = 'or '
        string2 = 'and/or '
      }
      switch (imageType) {
        case 'cfd':
          string1 += 'CFD images'
          string2 += 'run CFD analysis'
          break
        case 'snapshot':
          string1 += 'snapshots'
          string2 += 'take snapshots'
          break
        case 'object':
          string1 += 'object images'
          string2 += 'save object images'
          break
        default:
          string1 += imageType
          string2 += `save ${imageType}`
          break
      }

      options1.push(string1)
      options2.push(string2)
    })

    return [
      `You do not have any ${
        options1.length > 2 ? options1.join(', ') : options1.join(' ')
      } available.`,
      `Make sure you ${
        options2.length > 2 ? options2.join(', ') : options2.join(' ')
      } to have images available for the PDF Layout.`,
    ]
  }

  render() {
    const messages = this.getEmptyImagesMessage()
    return (
      <>
        {this.props.availableImages.length === 0 ? (
          <BlankSlate>
            <Space bottom="base">
              <Icon name="image" size="40px" color="subdued" />
            </Space>
            <Space bottom="xs">
              <VariantText size="s">{messages[0]}</VariantText>
            </Space>
            <VariantText size="s">{messages[1]}</VariantText>
          </BlankSlate>
        ) : (
          <>
            <Container>
              <Grid vCenter>
                {this.props.availableImages.map((img, idx) => (
                  <GridItem key={idx} size="1of4">
                    <Item
                      active={this.isSelectedImage(img)}
                      disabled={
                        !this.isSelectedImage(img) && !this.canAddImage()
                      }
                      number={
                        this.isSelectedImage(img) && this.getImageNumber(img)
                      }
                    >
                      <Image
                        url={img}
                        onClick={({ url }, event) =>
                          this.handleClick({ url }, event)
                        }
                      />
                    </Item>
                  </GridItem>
                ))}
              </Grid>
            </Container>
          </>
        )}
      </>
    )
  }
}

DocumentExplanation.propTypes = {
  selectedImages: arrayOf(
    shape({
      url: string,
      base64: string,
      naturalWidth: number,
      naturalHeight: number,
    })
  ),
  availableImages: arrayOf(string),
  limit: number,
  setImages: func,
  imageTypes: arrayOf(string),
}

export default withImageSelector(DocumentExplanation)
