import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const ActiveLayer = styled.div`
  background-color: ${theme('colors.light.extraLight')};
  border: ${theme('borderWidth')} solid ${theme('colors.light.subdued')};
  border-radius: ${theme('radius.base')};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
  width: 75px;
`

export default ActiveLayer
