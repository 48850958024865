import React, { useState, useEffect } from 'react'
import { appConnect } from "~/store/hooks";
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'
import * as filestack from 'filestack-js'
import fileSaver from 'file-saver'
import get from 'lodash-es/get'

import LAYER_KEYS from 'config/layerKeys'
import { GET_CLOUDINARY_SIGNATURE_QUERY } from 'client/queries'
import FILESTACK_API_KEY from 'config/filestack'
import routes from 'config/routes'
import { appendURL, getCloudinaryUrl } from 'lib/utils'
import sendToSentry from 'lib/sendToSentry'

import { showAlert } from 'store/alert'
import { mostRecentSelectedObject } from 'store/selectedObjects/selectors'
import { addBackgroundImage, addMetadataImage } from 'store/objects'
import { objectStateKeyFromClassName } from 'store/objects/selectors'

import cloudinary from 'config/cloudinary'

import Icon from 'components/UIKit/Icon'

import Camera from './styled/Camera'
import CaptureButton from './styled/Button'
import EditButton from './styled/EditButton'
import ProductButton from './styled/ProductButton'
import Preview from './styled/Preview'
import StyledWebcam from './styled/StyledWebcam'
import WebcamLoader from './styled/Loader'

/*
 * TODO: Retrieve the selected object via
 * query params so refreshing is better accomodated.
 */

const WebcamCapture = props => {
  let webcam = React.createRef()
  const client = filestack.init(FILESTACK_API_KEY)
  const [image, setImage] = useState('')
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    let timer
    // Faking a loading state to account for the delay of a device camera stream turning on.
    timer = setTimeout(() => setVisible(false), 2500)
    return () => clearInterval(timer)
  }, [image, visible])

  const handleCloudinaryUpload = async data => {
    try {
      // Upload image to cloudinary and store the results.
      const { cloudinaryAuth = {} } = props
      const { CloudinarySignature = {} } = cloudinaryAuth
      const response = await cloudinary.upload(data, CloudinarySignature)
      const { public_id: cloudinaryId } = await response.json()
      return cloudinaryId
    } catch (error) {
      sendToSentry({
        prefix: 'Cloudinary upload failure',
        error,
        action: 'WebcamCapture.handleCloudinaryUpload',
      })
      throw error
    }
  }

  const handleSave = async () => {
    const {
      history,
      match,
      onAddBackgroundImage,
      onShowAlert,
      onUpdateObjectMetadata,
    } = props
    const selectedObject = mostRecentSelectedObject()
    // Could be 'webcam-capture/metadata' or 'webcam-capture/background'
    const facilityUrl = match.url.replace(/webcam-capture\/[a-z]+/g, '')

    switch (match.params.type) {
      case 'background':
        // Size of ThinkPad captured picture
        const width = 1200
        const height = 900
        const response = await client.upload(image.src)
        await onAddBackgroundImage({
          backgroundImage: {
            BACKGROUND_IMAGE: {
              src: response.url,
              originalWidth: width,
              originalHeight: height,
              width,
              height,
              opacity: 0.5,
              layerKey: LAYER_KEYS.BACKGROUND_IMAGE,
              id: LAYER_KEYS.BACKGROUND_IMAGE,
            },
          },
        })
        history.push(appendURL(facilityUrl, routes.modals.editImage))
        break
      case 'metadata':
        if (selectedObject === undefined) {
          // Close modal to force product selection
          history.push(facilityUrl)
          onShowAlert({
            text: 'No product selected. Please try again.',
            type: 'error',
          })
          return
        }

        const cloudinaryId = await handleCloudinaryUpload(
          image.src,
          selectedObject
        )
        await onUpdateObjectMetadata({
          cloudinaryId,
          objectId: selectedObject.id,
          width: image.width,
          height: image.height,
        })
        history.push(facilityUrl)
        const cloudinaryImage = `${getCloudinaryUrl(cloudinaryId, {
          width: image.width,
          height: image.height,
        })}.jpg`
        fileSaver.saveAs(cloudinaryImage, `metadata-${cloudinaryId}.jpg`)
        onShowAlert({
          text: 'Image added',
          type: 'success',
        })
        break
      default:
        break
    }
  }

  const handleCapture = () => {
    const photo = {
      src: webcam.current.getScreenshot(),
      height: webcam.current.canvas.height,
      width: webcam.current.canvas.width,
    }
    setImage(photo)
  }

  const handleReset = () => {
    setImage('')
    setVisible(true)
  }

  const videoConstraints = {
    width: { ideal: 4096 },
    height: { ideal: 2160 },
    facingMode: { ideal: 'environment' },
  }

  const handleEdit = route => async () => {
    const { selectedObject, match } = props
    // Could be '/webcam-capture/metadata' or '/webcam-capture/background'
    const facilityUrl = match.url.replace(/\/webcam-capture\/[a-z]+/g, '')

    if (!selectedObject) {
      // Close modal to force product selection
      props.history.push(facilityUrl)
      props.onShowAlert({
        text: 'No product selected. Please try again.',
        type: 'error',
      })
      return
    }

    const objectStateKey = objectStateKeyFromClassName(
      get(selectedObject, 'className')
    )
    const existingImages = get(selectedObject, 'metadata.images')
    const newImageIndex = existingImages ? existingImages.length : 0
    const editUrl = `${facilityUrl}${route}?objectId=${get(
      selectedObject,
      'id'
    )}&objectType=${objectStateKey}&imageIndex=${newImageIndex}`

    // Upload the image to Cloudinary
    // before annotating the image
    const cloudinaryId = await handleCloudinaryUpload(image.src, selectedObject)
    await props.onUpdateObjectMetadata({
      cloudinaryId,
      objectId: selectedObject.id,
      width: image.width,
      height: image.height,
    })
    props.history.push(editUrl)
  }

  if (image) {
    return (
      <Camera>
        <Preview src={image.src} alt="Preview" />
        <CaptureButton onClick={handleReset} align="left">
          <Icon name="undo" size="24" />
        </CaptureButton>
        <ProductButton
          onClick={handleEdit(routes.modals.addProductOverlayImage)}
        >
          <Icon name="fan" size="24" />
        </ProductButton>
        <EditButton onClick={handleEdit(routes.modals.annotateImage)}>
          <Icon name="edit" size="24" />
        </EditButton>
        <CaptureButton onClick={handleSave}>
          <Icon name="check" size="24" />
        </CaptureButton>
      </Camera>
    )
  }

  return (
    <>
      <Camera>
        <StyledWebcam
          audio={false}
          width="100%"
          height="auto"
          ref={webcam}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />

        <CaptureButton onClick={handleCapture} primaryActionButton>
          <Icon name="camera" size="24" />
        </CaptureButton>
      </Camera>
      {visible && <WebcamLoader centered />}
    </>
  )
}

WebcamCapture.propTypes = {
  history: PropTypes.object,
  props: PropTypes.object,
  match: PropTypes.object,
  onAddBackgroundImage: PropTypes.func,
  onShowAlert: PropTypes.func,
  onUpdateObjectMetadata: PropTypes.func,
  cloudinaryAuth: PropTypes.object,
  primaryActionButton: PropTypes.bool,
  selectedObject: PropTypes.object,
}

const mapStateToProps = store => ({
  selectedObject: mostRecentSelectedObject(store),
})

const mapDispatchToProps = dispatch => ({
  onAddBackgroundImage(attrs) {
    dispatch(addBackgroundImage(attrs))
  },
  onShowAlert({ text, type }) {
    dispatch(showAlert({ text, type }))
  },
  onUpdateObjectMetadata: attrs => {
    dispatch(addMetadataImage({ ...attrs, ignoreForCFD: true }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  graphql(GET_CLOUDINARY_SIGNATURE_QUERY, {
    options: {
      fetchPolicy: 'network-only',
    },
    name: 'cloudinaryAuth',
  }),
  withRouter
)(WebcamCapture)
