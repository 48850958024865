import isEmpty from 'lodash-es/isEmpty'
import get from 'lodash-es/get'
import { matchPath } from 'react-router'

import Facility from './facility'
import FloatingElementManager from './floatingElementManager'
import QuickState from './quickState'

import cloudinary from 'config/cloudinary'
import routes from 'config/routes'
import LAYER_KEYS from 'config/layerKeys'

import store from 'store'
import { deselectObjects, selectObjects } from 'store/selectedObjects/selectors'
import { objectStateKeyFromClassName } from 'store/objects/selectors'
import { getLayerKey } from 'store/layers'
import { appendURL } from 'lib/utils'

const metadataKeys = ['title', 'notes', 'images']

class MetadataIcon {
  constructor(object, { history }) {
    this.popupVisible = false
    this.history = history

    this.updateObject(object)

    this.showIcon()
    this.showPopup()

    this.hidePopup()
  }

  static hasMetadata(metadata) {
    if (!metadata) {
      return false
    }

    return Object.keys(metadata).some(
      key => metadataKeys.includes(key) && !isEmpty(metadata[key])
    )
  }

  getImageUrl({ cloudinaryId }) {
    const cloudnaryImage = cloudinary.image(cloudinaryId, {
      format: 'png',
      secure: true,
      transformation: [
        {
          width: 80,
          height: 80,
          crop: 'fill',
        },
      ],
    })
    return cloudnaryImage.src
  }

  clickHandler() {
    if (store.getState().layers.layers[getLayerKey(this.object)].locked) {
      return
    }
    const object = Facility.current.findObjectWithId(this.objectId)
    if (this.popupVisible) {
      deselectObjects({ objects: [object] })
      this.hidePopup()
    } else {
      selectObjects({
        objects: [object],
        multiSelect: QuickState.inMultiSelectMode,
      })
      this.showPopup()
    }
  }

  updateObject(object) {
    this.objectId = object.id
    this.object = object
    this.facilityObject = Facility.current.findObjectWithId(this.objectId)
  }

  update(object) {
    this.updateObject(object)
    this.updateVisibility()
    this.updatePopup()
  }

  canShow() {
    const state = store.getState()
    const layers = state.layers.layers
    return (
      this.facilityObject &&
      layers[LAYER_KEYS.NOTES].visible &&
      layers[getLayerKey(this.facilityObject)].visible &&
      MetadataIcon.hasMetadata(this.object.metadata)
    )
  }

  updateVisibility(changedLayers) {
    if (
      !changedLayers ||
      changedLayers.includes(LAYER_KEYS.NOTES) ||
      changedLayers.includes(getLayerKey(this.object))
    ) {
      if (this.canShow()) {
        this.showIcon()
      } else {
        this.hideIcon()
        this.hidePopup()
      }
    }
  }

  showIcon() {
    if (this.canShow()) {
      this.element = FloatingElementManager.showFloatingElement(
        this.objectId,
        this.facilityObject.obj3d,
        { clickHandler: () => this.clickHandler() },
        'label',
        'metadata-trigger',
        {}
      )
      this.element.innerHTML = `<svg width="12" height="12" x="0px" y="0px" viewBox="0 0 20 20" enable-background="new 0 0 20 20" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"></path></svg>`
      this.element.classList.add('metadata-trigger')
    }
  }

  showPopup() {
    if (this.canShow() && !this.popupVisible) {
      this.popupElement = FloatingElementManager.showFloatingElement(
        this.objectId,
        this.facilityObject.obj3d,
        undefined,
        'div',
        'metadata-popup',
        { offset: { x: 0, y: 0.5 } }
      )

      this.popupElement.classList.add('metadata-popup')
      this.popupElement.style.marginLeft = `25px`
      this.element.classList.add('open')
      this.popupVisible = true
    }

    this.updatePopup()
  }

  updatePopup() {
    if (this.popupElement) {
      this.popupElement.innerHTML = ''

      metadataKeys.forEach(type => {
        const value = get(this.object, `metadata[${type}]`)
        if (value) {
          let element
          const child = document.createElement('div')
          child.classList.add(`metadata-${type}`)
          switch (type) {
            case 'title':
            case 'notes':
              element = document.createElement('label')
              element.innerHTML = value
              break
            case 'images':
              const onboardingRoutes = Object.values(routes.onboarding).map(
                step => step.full
              )
              let routesToCheck = [
                routes.facility.full,
                routes.facility.short, // short needs to come after since it's a substring of full
                ...onboardingRoutes,
              ]

              let match
              routesToCheck.find(route => {
                match = matchPath(window.location.pathname, {
                  path: route,
                })
                return match
              })

              element = document.createElement('div')
              const object = Facility.current.findObjectWithId(this.objectId)
              const objectStateKey = objectStateKeyFromClassName(
                object.className
              )
              value.forEach((v, index) => {
                const src = this.getImageUrl({ cloudinaryId: v.cloudinaryId })
                const href = appendURL(
                  match.url,
                  `view-image?objectId=${this.objectId}&objectType=${objectStateKey}&imageIndex=${index}`
                )
                const anchorEl = document.createElement('a')
                anchorEl.href = href
                anchorEl.classList.add(`metadata-thumbnail-link`)
                anchorEl.addEventListener('click', event => {
                  event.preventDefault()
                  this.history.push(href)
                })
                const imgEl = document.createElement('img')
                imgEl.src = src
                imgEl.width = 80
                imgEl.height = 80
                imgEl.classList.add(`metadata-thumbnail`)
                anchorEl.appendChild(imgEl)
                element.appendChild(anchorEl)
              })
              break
            default:
              break
          }

          child.appendChild(element)
          this.popupElement.appendChild(child)
        }
      })
    }
  }

  hideIcon() {
    FloatingElementManager.hideFloatingElement(
      this.objectId,
      'metadata-trigger'
    )
  }

  hidePopup() {
    FloatingElementManager.hideFloatingElement(this.objectId, 'metadata-popup')

    if (this.element) {
      this.element.classList.remove('open')
    }

    this.popupVisible = false
  }

  destroy() {
    this.hideIcon()
    this.hidePopup()
  }
}

export default MetadataIcon
