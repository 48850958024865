import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import pick from 'lodash-es/pick'

import { getTitle } from 'config/titles'
import ConfigureCFDForm from 'components/ConfigureCFDForm'

const ConfigureCFDModal = (props = {}) => {
  return (
    <>
      <Helmet>
        <title>{getTitle('configureCFD')}</title>
      </Helmet>
      <ConfigureCFDForm
        title="Configure CFD"
        {...pick(props, ['parentRoute'])}
      />
    </>
  )
}

ConfigureCFDModal.propTypes = {
  match: PropTypes.object,
}

export default ConfigureCFDModal
