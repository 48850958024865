import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Remove = styled.a`
  cursor: pointer;
  color: ${theme('colors.light.fg')};
  display: ${props => props.hide && 'none'};
  text-decoration: none;
`
export default Remove
