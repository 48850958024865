import React from 'react'

import Icon from 'components/UIKit/Icon'

import Container from './styled/Container'

const SelectListIconDownload = () => (
  <Container>
    <Icon name="cloudDownload" color="subdued" size="14" />
  </Container>
)

export default /** @type any */(SelectListIconDownload)
