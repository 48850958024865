import get from 'lodash-es/get'
import last from 'lodash-es/last'
import {
  SELECT_OBJECTS,
  DESELECT_OBJECTS,
  DELETE_SELECTED_OBJECTS,
} from './actions'
import {
  ADD_OBJECT,
  ADD_PRODUCT,
  ADD_DOOR,
  ADD_UTILITY_BOX,
  ADD_OBSTRUCTION,
  ADD_COMFORT_POINT,
  ADD_COMFORT_ZONE,
  ADD_ELEVATION_LINE,
  ADD_ELEVATION_POINT,
  UPDATE_ELEVATION_LINE,
  DUPLICATE_OBJECTS,
  DISTRIBUTE_OBSTRUCTIONS,
  DISTRIBUTE_PRODUCTS,
} from '../objects'
import CLASS_NAMES from 'config/objectClassNames'
import { isContinuousModeActive } from '../tools/selectors'

/**
 * @type {{
 *   id: string
 *   className: string
 * }[]} initialState
 */
const initialState = []

/**
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.className
 * @param {typeof initialState} props.State
 * @param {boolean} props.multiSelect
 */
function createNewObject({ id, className, state, multiSelect }) {
  const newObject = { id, className }
  return multiSelect ? [...state, newObject] : [newObject]
}

/**
 * @param {{ id: string; className: string }[] | undefined} state
 * @returns {{ id: string; className: string }[]}
 */
export default function selectedObjectReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PRODUCT: {
      if (isContinuousModeActive(action.globalState)) return []

      return createNewObject({
        id: action.payload.product.id,
        className: CLASS_NAMES.PRODUCT,
        state,
        multiSelect: action.payload.multiSelect,
      })
    }
    case ADD_DOOR: {
      return createNewObject({
        id: action.payload.door.id,
        className: CLASS_NAMES.DOOR,
        state,
        multiSelect: action.payload.multiSelect,
      })
    }
    case ADD_UTILITY_BOX: {
      return createNewObject({
        id: action.payload.utilityBox.id,
        className: CLASS_NAMES.UTILITY_BOX,
        state,
        multiSelect: action.payload.multiSelect,
      })
    }
    case ADD_OBJECT: {
      const isEnclosedWall = action.payload.object.isEnclosed
      return createNewObject({
        id: isEnclosedWall
          ? action.payload.object.id
          : get(last(action.payload.object.segments), 'id'),
        className: isEnclosedWall ? CLASS_NAMES.WALL : CLASS_NAMES.WALL_SEGMENT,
        state,
        multiSelect: action.payload.multiSelect,
      })
    }
    case ADD_OBSTRUCTION: {
      if (isContinuousModeActive(action.globalState)) return []

      return createNewObject({
        id: action.payload.obstruction.id,
        className: CLASS_NAMES.OBSTRUCTION,
        state,
        multiSelect: action.payload.multiSelect,
      })
    }
    case ADD_COMFORT_POINT: {
      return createNewObject({
        id: action.payload.comfortPoint.id,
        className: CLASS_NAMES.COMFORT_POINT,
        state,
        multiSelect: action.payload.multiSelect,
      })
    }
    case ADD_COMFORT_ZONE: {
      return createNewObject({
        id: action.payload.comfortZone.id,
        className: CLASS_NAMES.COMFORT_ZONE,
        state,
        multiSelect: action.payload.multiSelect,
      })
    }
    case ADD_ELEVATION_LINE: {
      return createNewObject({
        id: action.payload.elevationLine.id,
        className: CLASS_NAMES.ELEVATION_LINE,
        state,
        multiSelect: action.payload.multiSelect,
      })
    }
    case UPDATE_ELEVATION_LINE: {
      return [
        ...state,
        {
          id: action.payload.elevationLine.id,
          className: CLASS_NAMES.ELEVATION_LINE,
          layerKey: action.payload.elevationLine.layerKey,
        },
      ]
    }
    case ADD_ELEVATION_POINT: {
      return createNewObject({
        id: action.payload.elevationPoint.id,
        className: CLASS_NAMES.ELEVATION_POINT,
        state,
        multiSelect: action.payload.multiSelect,
      })
    }
    case SELECT_OBJECTS: {
      const { payload = {} } = action
      const { objects = [] } = payload
      return objects.map(object => ({ ...object }))
    }
    case DESELECT_OBJECTS: {
      const { payload = {} } = action
      const { selectedObjects = [], objects = [] } = payload
      // If objects to keep selected is provided, use them
      if (selectedObjects.length) {
        const selectIds = selectedObjects.map(i => i.id)
        return state.filter(i => selectIds.includes(i.id))
      }
      // Otherwise, if objects to deselect are provided, use them
      if (objects.length) {
        const deselectIds = objects.map(i => i.id)
        return state.filter(i => !deselectIds.includes(i.id))
      }
      // Otherwise, deselect everything
      return initialState
    }
    case DELETE_SELECTED_OBJECTS: {
      return initialState
    }
    case DUPLICATE_OBJECTS: {
      return action.payload.map(obj => ({
        id: obj.id,
        className: obj.className,
        layerKey: obj.layerKey,
      }))
    }
    case DISTRIBUTE_PRODUCTS:
    case DISTRIBUTE_OBSTRUCTIONS: {
      return [action.payload.gridBox]
    }
    default: {
      return state
    }
  }
}
