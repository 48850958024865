import { useDispatch } from "react-redux";
import { ColorRepresentation, Vector3, Vector3Like } from "three";
import { updateDoor } from "~/store/objects";
import { Door, WallSegment } from "~/store/objects/types";
import { objectModelToUI, uiToModel, modelToUI, objectUIToModel } from "../util/units";
import { bisectLine } from "../util/walls";
import { R3FDimensionInput } from "~/ui/R3FDimensionInput";

export function VectorVisualizer(props: { vector: Vector3; length?: number; color: ColorRepresentation; }) {
  return (
    <arrowHelper args={[props.vector.clone().normalize(), new Vector3(), props.length ?? props.vector.length(), props.color, 5, 5]} />
  );
}

export function DoorArrows(props: { door: Door; wallSegment: WallSegment }) {
  const { wallSegment, door } = props;
  const { position: { x, y }, id } = door;
  const { v1, v2, v1Half, v2Half } = bisectLine(objectModelToUI(wallSegment.startPoint), objectModelToUI(wallSegment.endPoint), objectModelToUI(props.door.position));

  const dispatch = useDispatch();

  const doorX = modelToUI(x);
  const doorY = modelToUI(y);

  const onCommit = (
    vectorFromCorner: Vector3,
    corner: Vector3Like
  ) => (value: number) => {
    const invertedSegment = vectorFromCorner.clone().multiplyScalar(-1).setLength(modelToUI(value));
    const newPosition = new Vector3()
      .addVectors(corner, objectUIToModel(invertedSegment));
    const { x, y, z } = newPosition;
    dispatch(updateDoor({
      door: {
        id,
        position: { x, y, z }
      },
    }))
  };

  return (
    <>
      <group position={[doorX, doorY, modelToUI(wallSegment.height)]}>
        <VectorVisualizer vector={v1} color='black' />
        <R3FDimensionInput position={v1Half} center value={uiToModel(v1.length())} onCommit={onCommit(v1, wallSegment.startPoint)} aria-label="Distance from first corner" />
        <VectorVisualizer vector={v2} color='black' />
        <R3FDimensionInput position={v2Half} center value={uiToModel(v2.length())} onCommit={onCommit(v2, wallSegment.endPoint)} aria-label="Distance from second corner" />
      </group>
    </>
  );
}
