import styled from 'styled-components'

import { resetListStyles, theme } from 'lib/styleUtils'

const MetaList = styled.ul`
  display: flex;

  > *:not(:last-child) {
    margin-right: ${theme('spacing.base')};
  }

  ${resetListStyles()};
`

export default MetaList
