import React, { Component } from 'react'
import { object, string, func } from 'prop-types'
import { appConnect } from "~/store/hooks";

import store from 'store'
import { updateRoofStructure } from 'store/objects'
import { setActiveTool } from 'store/tools'
import { Distance } from 'store/units/types'
import { getDistanceUnits } from 'store/units/selectors'
import { SYSTEMS } from 'store/units/constants'
import { onCreateMonoslopeRoof } from 'config/analytics'
import { trackEvent } from 'lib/analytics'
import { capitalize } from 'lib/utils'
import RoofToolUtils from 'components/DrawingCanvas/lib/roofToolUtil'
import Facility from 'components/DrawingCanvas/lib/facility'

import Button from 'components/UIKit/Button'
import DimensionInput from 'components/UIKit/DimensionInput'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Select from 'components/UIKit/Select'
import Space from 'components/UIKit/Space'

import RectangleIcon from '../../RectangleIcon'

class MonoslopeScreen extends Component {
  state = {
    side: 'left',
    height: null,
  }

  static sides = ['left', 'right', 'top', 'bottom']
  static options = MonoslopeScreen.sides.map(side => ({
    label: capitalize(side),
    value: side,
  }))

  get areFieldsValid() {
    const { side, height } = this.state

    return side !== '' && height !== null
  }

  componentDidMount() {
    if (!this.state.height) {
      const offset = 120 // 10'
      this.setState({
        height: new Distance({
          value: Facility.current.getWallHeight() + offset,
          system: SYSTEMS.IMPERIAL,
        }),
      })
    }
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    const { side, height } = this.state
    const { history, onUpdateRoofStructure, parentRoute } = this.props
    const wallWithRoof = Facility.current.getWalls().find(wall => wall.roofId)
    const nativeHeight = height.native()

    if (wallWithRoof) {
      const model = RoofToolUtils.getMonoslopeRoofModel(
        wallWithRoof.roofId,
        side,
        nativeHeight,
        wallWithRoof.id
      )
      onUpdateRoofStructure(model)
    }

    history.push(parentRoute)
  }

  render() {
    const { side, height } = this.state
    const distanceUnits = getDistanceUnits(store.getState())

    return (
      <Grid vCenter>
        <GridItem size="1of2">
          <form onSubmit={this.handleSubmit}>
            <Space bottom="base">
              <h2>Monoslope</h2>
            </Space>
            <Space bottom="l">
              <Space bottom="base">
                <Select
                  inline
                  labelWidth="80px"
                  size="100px"
                  name="side"
                  label="Raised Side"
                  value={side}
                  tabIndex={1}
                  onChange={event => {
                    this.handleChange({
                      name: event.target.name,
                      value: event.target.value,
                    })
                  }}
                  options={MonoslopeScreen.options}
                />
              </Space>
              <Space bottom="base">
                <DimensionInput
                  name="height"
                  label="Peak Height"
                  labelWidth="80px"
                  distance={height}
                  tabIndex={2}
                  units={distanceUnits}
                  onChange={({ distance }) => {
                    this.handleChange({
                      name: 'height',
                      value: distance,
                    })
                  }}
                />
              </Space>
            </Space>
            <Button
              primary
              label="Create Monoslope Roof"
              type="submit"
              tabIndex={4}
              disabled={!this.areFieldsValid}
              onClick={() => {
                trackEvent(onCreateMonoslopeRoof())
                this.props.onSetActiveTool({ tool: 'SELECT_TOOL' })
              }}
            />
          </form>
        </GridItem>
        <GridItem size="1of2">
          <RectangleIcon side={side} peak="monoslope" />
        </GridItem>
      </Grid>
    )
  }
}

MonoslopeScreen.propTypes = {
  history: object,
  onUpdateRoofStructure: func,
  onSetActiveTool: func,
  parentRoute: string,
}

const mapStateToProps = ({ objects }) => {
  return { objects }
}

const mapDispatchToProps = dispatch => ({
  onUpdateRoofStructure(model) {
    dispatch(updateRoofStructure(model))
  },
  onSetActiveTool({ tool }) {
    dispatch(setActiveTool({ tool }))
  },
})

export default appConnect(mapStateToProps, mapDispatchToProps)(MonoslopeScreen)
