import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'lib/styleUtils'
import UIRange from 'components/UIKit/Range'
import { Stack } from 'components/UIKit/Stack'

export const RangeContainer = styled.div`
  bottom: ${theme('spacing.base')};
  left: ${theme('spacing.base')};
  position: absolute;
`

const StyledRange = styled(UIRange)`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: ${theme('radius.base')};
  padding: ${theme('spacing.xs')} ${theme('spacing.base')};
`
const StyledTitle = styled.h2`
  font-size: ${theme('fonts.sizes.base')};
  margin-bottom: ${theme('spacing.xs')};
  text-align: left;
`

export const Range = ({ title, endAdornment, ...props }) => (
  <div>
    <StyledTitle>{title}</StyledTitle>
    <Stack direction="row" alignItems="center">
      <StyledRange {...props} />
      {endAdornment}
    </Stack>
  </div>
)

Range.propTypes = {
  title: PropTypes.node,
  endAdornment: PropTypes.node,
}
