import React, { Component } from 'react'
import { appConnect } from "~/store/hooks";
import PropTypes from 'prop-types'
import get from 'lodash-es/get'

import { updateRoof } from 'store/objects'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { SELECTED_ROOF_PANEL } from 'store/panel/types'
import CLASS_NAMES from 'config/objectClassNames'
import theme from 'config/theme'
import store from 'store'
import LAYER_KEYS from 'config/layerKeys'

import Panel, { PanelSection } from 'components/UIKit/Panel'
import { ColorPicker } from 'react-aria-components'
import { SwatchWell } from '~/ui/Color'
import { Label } from '~/ui/Field'
import MetadataSection from 'components/MetadataSection'

class SelectedRoofPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      color: props.selectedObject && props.selectedObject.color,
      name: props.selectedObject && props.selectedObject.name,
    }
  }

  handleInputChange = (key, value) => {
    const { selectedObject, onUpdateRoof } = this.props

    onUpdateRoof({
      ...selectedObject,
      [key]: value,
    })
  }

  handleChangeColor = color => {
    this.setState(
      {
        color,
      },
      () => {
        this.props.onUpdateRoof({
          ...this.props.selectedObject,
          color: this.state.color,
        })
      }
    )
  }

  // We're checking to see if the `selectedObject.color` is a number,
  // which means it was a converted Three.js number that we can safely
  // just pull the default roof color out of the theme file and return.
  get color() {
    const { color } = this.state
    if (typeof color === 'number')
      return get(theme, 'colors.three.objects.roof.default')
    return color
  }

  renderContent() {
    const { selectedObject, onUpdateRoof } = this.props

    const isLocked = store.getState().layers.layers[LAYER_KEYS.ROOFS].locked

    return (
      <>
        <PanelSection>
          <ColorPicker value={this.color} onChange={color => this.handleChangeColor(color.toString("hex"))}>
            <Label>Color</Label>
            <SwatchWell swatches={theme.colors.swatches}/>
          </ColorPicker>
        </PanelSection>
        <MetadataSection
          object={selectedObject}
          onBlur={onUpdateRoof}
          disabled={isLocked ? true : false}
        />
      </>
    )
  }

  render() {
    const { selectedObject, alignment, isTouchUI } = this.props

    return (
      <Panel
        title="Roof"
        alignment={alignment}
        docked
        panelKey={SELECTED_ROOF_PANEL}
        hasToolbar={isTouchUI}
      >
        {selectedObject ? this.renderContent() : null}
      </Panel>
    )
  }
}

SelectedRoofPanel.propTypes = {
  alignment: PropTypes.string,
  selectedObject: PropTypes.object,
  onUpdateRoof: PropTypes.func,
  isTouchUI: PropTypes.bool,
}

SelectedRoofPanel.defaultProps = {
  alignment: 'right',
}

const mapStateToProps = ({
  objects,
  selectedObjects,
  userInterface,
  ...store
}) => {
  const selectedObject = mostRecentSelectedObjectOfClassName(CLASS_NAMES.ROOF, {
    selectedObjects,
    objects,
  })
  const isTouchUI = userInterface.isTouchUI
  return { layers: store.layers, selectedObject, isTouchUI }
}

const mapDispatchToProps = dispatch => ({
  onUpdateRoof(roof) {
    dispatch(updateRoof({ roof }))
  },
})

export default appConnect(mapStateToProps, mapDispatchToProps)(SelectedRoofPanel)
