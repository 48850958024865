import { graphql } from '~/gql'

export default graphql(`
  mutation DuplicateFacility($name: String!, $facilityId: ID!) {
    facility: duplicateFacility(name: $name, facilityId: $facilityId) {
      ...FacilityFragment
      floors {
        id
        name
        versions {
          id
        }
      }
      floor {
        id
        version {
          id
        }
      }
    }
  }
`)
