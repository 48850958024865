import PropTypes from 'prop-types'

import Container from './styled/Container'

const Cell = Container

Cell.propTypes = {
  bordered: PropTypes.bool,
}

export default /** @type any */(Cell)
