import Facility from '~/components/DrawingCanvas/lib/facility'
import { Object3D, Vector2, Vector3, Mesh, SphereGeometry, MeshBasicMaterial } from 'three'
import { isEqual, uniqWith } from 'lodash-es'
import Util from '~/components/DrawingCanvas/lib/util'
import Units from '~/components/DrawingCanvas/lib/units'
import { FormattedProducts } from './formatProducts'
import detailedObstructionConfigs from 'config/detailedObstructions'
import { StoreObstructions, Vector } from './types'

const getWallClones = (facility: Facility) => {
  return facility.getWalls().map(wall => {
    const wallClone = wall.obj3d.clone()
    wallClone.visible = true
    return wallClone
  })
}

const getRoofClones = (facility: Facility) => {
  return facility.getRoofs().reduce((roofs, currentRoof) => {
    if (currentRoof.height <= 0) return roofs
    const roofClone = currentRoof.obj3d.clone()
    roofClone.visible = true
    roofClone.remove(...roofClone.children)
    roofs.push(roofClone)
    return roofs
  }, [])
}

const getCeilingClones = (facility: Facility) => {
  return facility.getCeilings().reduce<Object3D[]>((ceilings, currentCeiling) => {
    if (!currentCeiling.enabled) return ceilings
    ceilings.push(currentCeiling.obj3d!.clone())
    return ceilings
  }, [])
}

const getLocationMarkers = (facility: Facility, productPositions: FormattedProducts) => {
  // Get all distinct areas of the facility, sort them by size
  const distinctRegions = facility
    .getRegionMeshes(false)
    .map(mesh => {
      const positionArray: Float32Array = mesh?.geometry?.attributes?.position?.array
      const positions = positionArray.reduce<Vector2[]>((prev, _, i, arr) => {
        if (i % 3 === 0) {
          const [x, y] = arr.slice(i, i + 2)
          prev.push(new Vector2(x, y))
        }
        return prev
      }, [])
      return uniqWith(positions, isEqual)
    })
    .sort(
      (regionPointsA, regionPointsB) =>
        Util.polygonArea(regionPointsA) - Util.polygonArea(regionPointsB)
    )

  const locationMarkers = distinctRegions.reduce<Mesh[]>((markers, regionPolygonPoints) => {
    const intersectingProductPosition = productPositions.find(({ unscaledPositionData }) => {
      const { x, y, z } = unscaledPositionData
      const locationVector = new Vector3(x, y, z)
      return Util.isPointInPolygon(locationVector, regionPolygonPoints)
    })
    if (!intersectingProductPosition) return markers
    const { x, y, z } = intersectingProductPosition.unscaledPositionData
    const sphereMarker = new Mesh(
      new SphereGeometry(5, 32, 32),
      new MeshBasicMaterial({ color: 0x00ffff })
    )
    sphereMarker.position.set(x, y, z - 2)
    markers.push(sphereMarker)
    return markers
  }, [])

  return locationMarkers
}

const getObstructionClones = async (facility: Facility, storeObstructions: StoreObstructions) => {
  const facilityObstructions = Object.fromEntries(
    facility.getObstructions().map(obstruction => [obstruction.id, obstruction])
  )
  return await Promise.all(
    Object.values(storeObstructions).map(async storeObstruction => {
      const obstruction = facilityObstructions[storeObstruction.id]
      const { obstructionType } = obstruction
      const isDetailedObstruction = obstructionType !== 'basic'
      if (isDetailedObstruction) {
        await obstruction.construction
        const modelContainerClone = obstruction.modelContainer.clone()
        const rotation = obstruction.modelContainer.rotation.clone()
        modelContainerClone.rotation.copy(rotation)
        const obstructionConfig = detailedObstructionConfigs.find(
          obs => obs.obstructionType === obstructionType
        )
        if (obstructionConfig?.scale) {
          const { x, y, z } = obstructionConfig.scale
          modelContainerClone.scale.set(x, y, z)
        }
        const container = new Object3D()
        container.add(modelContainerClone)
        container.scale.set(
          obstruction.root.scale.x,
          obstruction.root.scale.y,
          obstruction.root.scale.z
        )
        container.rotateZ((obstruction.rotation.z * Math.PI) / 180)
        const objectClone = obstruction.obj3d.clone()
        objectClone.getWorldPosition(container.position)
        return container
      } else {
        const selectionBoxClone = obstruction.selectionBox.clone()
        selectionBoxClone.position.copy(obstruction.obj3d.position)
        selectionBoxClone.rotation.set(0, 0, (obstruction.rotation.z * Math.PI) / 180)
        return selectionBoxClone
      }
    })
  )
}

export const getFacilityClone = async (
  facility: Facility,
  {
    productPositions,
    obstructions,
  }: { productPositions: FormattedProducts; obstructions: StoreObstructions }
): Promise<{ facilityClone: Object3D; locations: Vector[] }> => {
  const facilityClone = new Object3D()
  const wallClones = getWallClones(facility)
  const roofClones = getRoofClones(facility)
  const obstructionClones = await getObstructionClones(facility, obstructions)

  const ceilingClones = getCeilingClones(facility)
  const locationMarkers = getLocationMarkers(facility, productPositions)

  facilityClone.add(...wallClones)
  facilityClone.add(...roofClones)
  if (obstructionClones.length) facilityClone.add(...obstructionClones)
  if (ceilingClones.length) facilityClone.add(...ceilingClones)

  // TODO: how to dispatch this only on upload?
  // const isContainedFacility = !checkFacilityGaps(
  //   facilityClone,
  //   productPositions.map(({ unscaledPositionData }) => unscaledPositionData)
  // )
  // if (!isContainedFacility) {
  //   // dispatch alert
  // }

  facilityClone.add(...locationMarkers)

  // Scale to inches for acceptable CFD input
  facilityClone.scale.set(
    Units.nativeToInches(facilityClone.scale.x),
    Units.nativeToInches(facilityClone.scale.y),
    Units.nativeToInches(facilityClone.scale.z)
  )

  const isMultiRoomFacility = locationMarkers.length > 1
  const locationMarkerPositions = isMultiRoomFacility
    ? locationMarkers.map(marker => {
        const vector = new Vector3()
        const { x, y, z } = marker.getWorldPosition(vector)
        return { x, y, z }
      })
    : []

  // Prevent location markers from being included in model file
  facilityClone.remove(...locationMarkers)

  facilityClone.updateMatrixWorld()

  return { facilityClone, locations: locationMarkerPositions }
}
