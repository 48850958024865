import styled, { css, keyframes } from 'styled-components'

import { getZIndexOf } from 'lib/utils'
import { theme } from 'lib/styleUtils'

const size = '20px'

const isCurrentActive = props => props.index === props.current
const isCurrentComplete = props => props.index < props.current
const isLastComplete = props => props.index === props.current - 1

const pulse = props => keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(39, 39, 39, 0.4), 0 0 0 2px ${props.theme.colors.light.bg};
  }

  70% {
    box-shadow: 0 0 0 10px rgba(39, 39, 39, 0), 0 0 0 2px ${props.theme.colors.light.bg};
  }

  100% {
    box-shadow: 0 0 0 0 rgba(39, 39, 39, 0), 0 0 0 2px ${props.theme.colors.light.bg};
  }
`

const grow = keyframes`
  0% {
    transform: scaleX(0);
  }
`

const dotStyles = props => {
  // Currently active step
  if (isCurrentActive(props)) {
    return css`
      animation: ${pulse(props)} 2s 1;
      background-color: ${props.theme.colors.light.fg};
      border-color: ${props.theme.colors.light.fg};
    `
    // Completed step
  } else if (isCurrentComplete(props)) {
    return `
      background-color: ${props.theme.colors.light.success};
      border-color: ${props.theme.colors.light.success};
    `
  }

  // Default step
  return `
    background-color: ${props.theme.colors.light.bg};
    border-color: ${props.theme.colors.light.subdued};
  `
}

const barStyles = props => {
  // Currently active step
  if (isCurrentActive(props)) {
    return `
      background-color: transparent;
      border-color: ${props.theme.colors.light.subdued};
    `
    // Completed step
  } else if (isCurrentComplete(props)) {
    if (isLastComplete(props)) {
      return css`
        animation: ${grow} 1.5s 1;
        background-color: ${props.theme.colors.light.success};
        border-color: ${props.theme.colors.light.success};
        transform-origin: 0;
      `
    }

    return `
      background-color: ${props.theme.colors.light.success};
      border-color: ${props.theme.colors.light.success};
    `
  }

  // Default step
  return `
    background-color: transparent;
    border-color: ${props.theme.colors.light.subdued};
  `
}

const Item = styled.li`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${props => 100 / props.steps}%;

  &::before {
    border: 1px solid;
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(39, 39, 39, 0), 0 0 0 2px ${theme('colors.light.bg')};
    content: '';
    display: block;
    height: ${size};
    margin-bottom: ${theme('spacing.xs')};
    margin-top: -5px;
    width: ${size};
    z-index: ${getZIndexOf('step2')};

    ${props => dotStyles(props)};
  }

  &::after {
    border: 1px solid;
    content: '';
    display: block;
    height: 8px;
    left: 50%;
    position: absolute;
    width: 100%;
    z-index: ${getZIndexOf('step1')};

    ${props => barStyles(props)};
  }
`

export default Item
