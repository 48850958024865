import styled from 'styled-components'

import { theme } from 'lib/styleUtils'
import defaultTheme from 'config/theme'

function getColor(props) {
  const color = props.disabled
    ? theme('colors.light.subdued')({ theme: defaultTheme })
    : theme('colors.light.fg')({ theme: defaultTheme })
  return `color: ${color};`
}

const Label = styled.label`
  font-family: ${theme('fonts.family.base')};
  font-size: ${theme('fonts.sizes.s')};
  font-weight: ${theme('fonts.weights.bold')};
  ${getColor}
`

export default Label
