import styled, { ThemedStyledProps } from 'styled-components'

import { getZIndexOf } from 'lib/utils'
import { Theme } from '~/config/theme'

const Container = styled.div<{ isVisible?: boolean }>`
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${getZIndexOf('modal')};
`

export default Container
