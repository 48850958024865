import React from 'react'
import PropTypes from 'prop-types'
import { appConnect } from "~/store/hooks";

import { set2D } from 'store/camera'
import { restoreLayers } from 'store/layers'
import { hidePanel } from 'store/panel'
import { CONTROLS_PANEL } from 'store/panel/types'
import { toggleFullscreen } from 'store/userInterface'
import Icon from 'components/UIKit/Icon'
import Container from './styled/Container'

function CloseFullscreenButton(props = {}) {
  function handleClick(event) {
    event.preventDefault()
    props.onToggleFullscreen()
  }

  return (
    <Container onClick={handleClick} primary>
      <Icon name="cross" />
    </Container>
  )
}

CloseFullscreenButton.propTypes = {
  onToggleFullscreen: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  onToggleFullscreen() {
    dispatch(toggleFullscreen())
    dispatch(set2D())
    dispatch(hidePanel({ type: CONTROLS_PANEL }))
    dispatch(restoreLayers())
  },
})

export default appConnect(null, mapDispatchToProps)(CloseFullscreenButton)
