import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import get from 'lodash-es/get'

import { GET_VARIATIONS_QUERY } from '../queries'

export default compose(
  appConnect(({ objects } = {}) => {
    const products = get(objects, 'present.products') || {}
    const productsKeys = Object.keys(products)
    const productsArray = productsKeys.map(key => products[key])
    return {
      variationIds: productsArray.map(p => p.variationId),
    }
  }),
  graphql(GET_VARIATIONS_QUERY, {
    options: ({ variationIds } = {}) => ({
      variables: {
        ids: variationIds,
      },
    }),
  }),
  appConnect(({ objects } = {}, ownProps) => {
    const products = get(objects, 'present.products') || {}
    const productsArray = []
    const enrichedProducts = {}
    const variations = get(ownProps, 'data.ProductVariations') || []

    productsArray.push(...Object.values(products))

    productsArray.map(product => {
      return (enrichedProducts[product.id] = {
        ...product,
        ...variations.find(v => v.id === product.variationId),
      })
    })

    return {
      loading: get(ownProps, 'data.loading') || get(ownProps, 'loading'),
      enrichedProducts,
    }
  })
)
