import React, { Component } from 'react'
import PropTypes from 'prop-types'
import find from 'lodash-es/find'

import A from 'components/UIKit/A'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Icon from 'components/UIKit/Icon'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'

import PaddedContainer from './styled/PaddedContainer'
import Selection from './styled/Selection'

class TemplatesModal extends Component {
  state = { screen: '' }

  get gridSize() {
    return this.props.screens.length
  }

  handleScreenClick = (screen, event) => {
    event.preventDefault()

    this.setState({
      screen,
    })
  }

  handleBackClick = event => {
    event.preventDefault()
    this.setState({ screen: '' })
  }

  renderSelection() {
    const { screens } = this.props

    return (
      <PaddedContainer>
        <Grid>
          {screens.map(screen => (
            <GridItem size={`1of${this.gridSize}`} key={screen.key}>
              <Selection
                href=""
                onClick={event => this.handleScreenClick(screen.key, event)}
              >
                {screen.icon && (
                  <Space bottom="base">
                    <Icon name={screen.icon} size="100" />
                  </Space>
                )}
                {screen.title}
              </Selection>
            </GridItem>
          ))}
        </Grid>
      </PaddedContainer>
    )
  }

  renderScreen() {
    const { screens, history, parentRoute } = this.props
    const { screen } = this.state
    const selectedScreen = find(screens, { key: screen })

    return (
      <PaddedContainer size="base">
        <selectedScreen.component parentRoute={parentRoute} history={history} />
      </PaddedContainer>
    )
  }

  render() {
    const { title } = this.props
    const { screen } = this.state

    // TODO: Ideally we need to trap user input (keyevents / tabs / etc) to the modal itself
    // by disabling everything behind it and adding aria- controls. If the user ends up
    // tabbing enough times, items in the background get selected and cause unexpected behavior.
    return (
      <Modal
        title={title}
        {...this.props}
        backLink={
          screen === '' ? null : (
            <A href="" onClick={this.handleBackClick}>
              &larr; Back
            </A>
          )
        }
      >
        {screen === '' ? this.renderSelection() : this.renderScreen()}
      </Modal>
    )
  }
}

TemplatesModal.propTypes = {
  children: PropTypes.any,
  history: PropTypes.object,
  parentRoute: PropTypes.string,
  props: PropTypes.object,
  screens: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      component: PropTypes.element,
      icon: PropTypes.string,
    })
  ),
  selection: PropTypes.element,
  title: PropTypes.string,
}

export default TemplatesModal
