import React from 'react'
import { Helmet } from 'react-helmet'

import { getTitle } from 'config/titles'

import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import Icon from 'components/UIKit/Icon'
import Blockquote from 'components/UIKit/Blockquote'
import Image from 'components/UIKit/Image'

const HeatMapHelpModal = props => (
  <div>
    <Helmet>
      <title>{getTitle('heatMapHelp')}</title>
    </Helmet>
    <Modal title="About Heat Map" {...props}>
      <Space bottom="base">
        <p>
          A two-dimensional approximation of the heat map that a heater
          configuration will generate within a space. Based on hot stuff.
        </p>
      </Space>
      <Space bottom="base">
        <Blockquote>
          <p>
            <Icon name="info" size="14" color="subdued" /> Heat is hot!
          </p>
        </Blockquote>
      </Space>
      <Space bottom="base">
        <Image
          alt="FIRE"
          src="https://static1.srcdn.com/wordpress/wp-content/uploads/2020/05/Elmo-Flames-Meme.jpg?q=50&fit=crop&w=960&h=500&dpr=1.5"
        />
      </Space>
    </Modal>
  </div>
)

export default HeatMapHelpModal
