import styled, { css } from 'styled-components'

import { theme } from 'lib/styleUtils'
import { getZIndexOf } from 'lib/utils'
import Button from 'components/UIKit/Button'

const position = ({ isTouchUI }) => {
  // FIXME: Magic number!
  const value = '-25px'

  if (isTouchUI) {
    return css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      right: ${value};
    `
  }

  return css`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    left: ${value};
  `
}

const Container = styled(Button)`
  font-size: ${theme('fonts.sizes.s')};
  font-weight: ${theme('fonts.weights.bold')};
  position: absolute;
  top: 50%;
  transform: rotate(-90deg);
  z-index: ${getZIndexOf('feedbackButton')};

  ${position};
`

export default Container
