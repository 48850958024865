import { appConnect } from "~/store/hooks";

import {
  getDistanceUnits,
  getTemperatureUnits,
  getVelocityUnits,
} from './selectors'

export const withUnits = appConnect(store => ({
  distanceUnits: getDistanceUnits(store),
  temperatureUnits: getTemperatureUnits(store),
  velocityUnits: getVelocityUnits(store),
}))
