import isNil from 'lodash-es/isNil'
import omitBy from 'lodash-es/omitBy'

import Util from 'components/DrawingCanvas/lib/util'

import {
  ADD_LOADING_DOCK_PRODUCT,
  ADD_LOADING_DOCK_OBSTRUCTION,
  ADD_LOADING_DOCK_DOOR,
  ADD_LOADING_DOCK_UTILITY_BOX,
  REMOVE_LOADING_DOCK_ITEM,
  SHOW_LOADING_DOCK,
  HIDE_LOADING_DOCK,
} from './actions'
import { LOAD_FACILITY, RESET_FACILITY } from '../objects/action_types'

export const initialState = {
  doors: {},
  obstructions: {},
  products: {},
  utilityBoxes: {},
  show: false,
}

export default function loadingDockReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADING_DOCK: {
      return {
        ...state,
        show: true,
      }
    }
    case RESET_FACILITY: {
      return initialState
    }
    case HIDE_LOADING_DOCK: {
      return {
        ...state,
        show: false,
      }
    }
    case LOAD_FACILITY: {
      return {
        ...state,
        ...omitBy(action.payload.loadingDock, isNil),
      }
    }
    case ADD_LOADING_DOCK_PRODUCT: {
      const id = Util.guid()
      return {
        ...state,
        products: {
          ...state.products,
          [id]: {
            ...action.payload,
            id,
          },
        },
      }
    }
    case ADD_LOADING_DOCK_OBSTRUCTION: {
      const id = Util.guid()
      return {
        ...state,
        obstructions: {
          ...state.obstructions,
          [id]: {
            ...action.payload,
            id,
          },
        },
      }
    }
    case ADD_LOADING_DOCK_DOOR: {
      const id = Util.guid()
      return {
        ...state,
        doors: {
          ...state.doors,
          [id]: {
            ...action.payload,
            id,
          },
        },
      }
    }
    case ADD_LOADING_DOCK_UTILITY_BOX: {
      const id = Util.guid()
      return {
        ...state,
        utilityBoxes: {
          ...state.utilityBoxes,
          [id]: {
            ...action.payload,
            id,
          },
        },
      }
    }
    case REMOVE_LOADING_DOCK_ITEM: {
      const products = omitBy(
        state.products,
        product => product.id === action.payload.id
      )
      const obstructions = omitBy(
        state.obstructions,
        obs => obs.id === action.payload.id
      )
      const doors = omitBy(state.doors, door => door.id === action.payload.id)
      const utilityBoxes = omitBy(
        state.utilityBoxes,
        utilityBox => utilityBox.id === action.payload.id
      )

      return {
        ...state,
        obstructions: {
          ...obstructions,
        },
        products: {
          ...products,
        },
        doors: {
          ...doors,
        },
        utilityBoxes: {
          ...utilityBoxes,
        },
      }
    }
    default: {
      return state
    }
  }
}
