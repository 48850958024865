import Button from 'components/UIKit/Button'
import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'

const MutationButton = ({
  disabled = false,
  mutation,
  variables = {},
  label = 'Submit',
  onSuccess = () => {},
}) => {
  const [mutationFunc, { data, loading, error }] = useMutation(mutation)
  const [status, setStatus] = useState(label)

  if (loading && status !== 'Loading') {
    setStatus('Loading')
  }

  if (error && status !== 'Error') {
    setStatus('Error')
  }

  if (data && status !== 'Success') {
    setStatus('Success')
    onSuccess()
  }

  return (
    <Button
      label={status}
      iconRight={status === 'Success' && 'checkCircle'}
      iconRightColor={status === 'Success' && 'green'}
      danger={status === 'Error'}
      disabled={disabled}
      onClick={async event => {
        event.preventDefault()
        await mutationFunc({ variables }).catch(e => {
          console.log(e)
        })
      }}
    />
  )
}

MutationButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  mutation: PropTypes.object,
  variables: PropTypes.object,
  onSuccess: PropTypes.func,
}

export default MutationButton
