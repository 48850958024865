import React from 'react'
import PropTypes from 'prop-types'

import VariantText from 'components/UIKit/VariantText'
import Space from 'components/UIKit/Space'
import Flex, { FlexItem } from 'components/UIKit/Flex'
import Image from 'components/UIKit/Image'
import TextField from 'components/UIKit/TextField'

const Comments = ({ tempComments, handleFieldChanged }) => (
  <Flex justifyContent="space-around" alignItems="center">
    <FlexItem width="350px">
      <Flex flexDirection="column" justifyContent="center">
        <Space bottom="base">
          <TextField
            name="tempComments"
            label="Comments"
            multiline
            noResize
            rows={12}
            value={tempComments}
            onChange={e => handleFieldChanged(e)}
          />
        </Space>
      </Flex>
    </FlexItem>
    <FlexItem width="350px">
      <Space bottom="base">
        <VariantText bold italic>
          Will show in the right column of the first page
        </VariantText>
      </Space>
      <Image src="/comments-screenshot.png" alt="comments ss" width="350px" />
    </FlexItem>
  </Flex>
)

Comments.propTypes = {
  tempComments: PropTypes.string,
  handleFieldChanged: PropTypes.func,
}

export default Comments
