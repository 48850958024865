import { graphql } from '~/gql'

const DELETE_USER_FAVORITE = graphql(`
  mutation DeleteUserFavorite($facilityId: ID!) {
    deleteUserFavorite(facilityId: $facilityId) {
      id
    }
  }
`)

export default DELETE_USER_FAVORITE
