import {
  TextField as AriaTextField,
  TextFieldProps as AriaTextFieldProps,
  Button,
  ButtonProps,
} from 'react-aria-components'
import { FieldGroup, Input, Label, fieldBorderStyles } from './Field'
import { composeTailwindRenderProps } from './utils'
import { Icon } from './Icon'
import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, useState } from 'react'
import { DistanceDimensions, useDistanceDimensions } from '~/hooks/useDistanceDimensions'

export interface DimensionInputProps extends Omit<AriaTextFieldProps, 'value'> {
  label?: string
  /** value is in model units (inches) */
  value: number
  /** value is in model units (inches) */
  onCommit: (value: number) => void
  step?: number
  showStepperButtons?: boolean
  isOnCanvas?: boolean
  distanceDimensions: DistanceDimensions
}

export function DimensionInput(props: Omit<DimensionInputProps, 'distanceDimensions'>) {
  const distanceDimensions = useDistanceDimensions()
  return <InnerDimensionInput {...props} distanceDimensions={distanceDimensions}/>
}

export function InnerDimensionInput({
  value,
  onCommit,
  step,
  label,
  showStepperButtons = false,
  isOnCanvas,
  distanceDimensions,
  ...props
}: DimensionInputProps) {
  const { displayFormattedValue, parseUserInputToInches, oneDistanceUnit } = distanceDimensions
  const [displayValue, setDisplayValue] = useState<string>(displayFormattedValue(value))

  const commit = (newValue: number) => {
    onCommit(newValue)
    setDisplayValue(displayFormattedValue(newValue))
  }

  const handleCommit = (inputValue: string) => {
    const inches = parseUserInputToInches(inputValue)
    if (!inches) {
      setDisplayValue(displayFormattedValue(value))
      return
    }
    commit(inches)
  }

  const handleBlur: FocusEventHandler<HTMLInputElement> = e => {
    if (isOnCanvas) {
      setDisplayValue(displayFormattedValue(value))
    } else {
      handleCommit(e.target.value)
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      handleCommit(e.currentTarget.value)
    } else if (e.key === "Escape") {
      setDisplayValue(displayFormattedValue(value))
    }
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    setDisplayValue(e.target.value)
  }

  const handleIncrement = () => {
    commit(value + oneDistanceUnit)
  }

  const handleDecrement = () => {
    const newValue = value - oneDistanceUnit
    if (newValue < 0) return
    commit(newValue)
  }

  return (
    <AriaTextField
      {...props}
      className={composeTailwindRenderProps(props.className, 'group flex flex-col gap-1')}
    >
      {label && <Label className="font-bold text-black text-xs">{label}</Label>}
      <FieldGroup isOnCanvas={isOnCanvas}>
        {renderProps => (
          <>
            <Input
              value={displayValue}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              size={isOnCanvas ? 6 : undefined}
              isOnCanvas={isOnCanvas}
            />
            {showStepperButtons && (
              <div className={fieldBorderStyles({ ...renderProps, class: 'flex flex-col border-s-2' })}>
                <StepperButton
                  aria-label={`Increase ${label ?? 'value'}`}
                  onPress={handleIncrement}
                >
                  <Icon name="arrowUp" aria-hidden className="w-4 h-4" />
                </StepperButton>
                <StepperButton
                  aria-label={`Decrease ${label ?? 'value'}`}
                  onPress={handleDecrement}
                >
                  <Icon name="arrowDown" aria-hidden className="w-4 h-4" />
                </StepperButton>
              </div>
            )}
          </>
        )}
      </FieldGroup>
    </AriaTextField>
  )
}

function StepperButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      className="px-0.5 cursor-default text-gray-500 pressed:bg-gray-100 group-disabled:text-gray-200 dark:text-zinc-400 dark:pressed:bg-zinc-800 dark:group-disabled:text-zinc-600 forced-colors:group-disabled:text-[GrayText]"
    />
  )
}
