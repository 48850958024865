import styled from 'styled-components'

import theme from 'config/theme'

import { stretch, breakpoints } from 'lib/styleUtils'
import { getDockedPanelWidth } from 'lib/utils'
import { isTouchUI, isFullscreen } from 'store/userInterface/selectors'

const setDockedPanelStyles = () =>
  `
  ${breakpoints.s`
    right: ${theme.iPadPortraitDockedPanelWidth}px;
  `};

  ${breakpoints.m`
    right: ${theme.dockedPanel.width}px;
  `}

  ${breakpoints.l`
    right: ${theme.dockedPanel.width}px;
  `}

  ${isTouchUI() &&
    !isFullscreen() &&
    `
    padding-left: ${getDockedPanelWidth()}px;
  `}

    width: auto;
`

const CanvasContainer = styled.div`
  min-height: 100%;
  overflow: hidden;

  ${stretch()};

  /* For the right-side panel */
  ${props => props.hasDockedPanel && setDockedPanelStyles()};
`

export default CanvasContainer
