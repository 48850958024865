// TODO: Eventually this will get moved out to the DB once we decide

import { PrimaryUses } from "./facility"

// how we are going to handle additional detailed obstructions
type DetailedObstruction = {
  obstructionType: string
  width: number
  length: number
  height: number
  resizable: boolean
  tags: string[]
  primaryUses: PrimaryUses[]
  scale: { x: number; y: number; z: number }
  rotation?: { x: number; y: number; z: number }
}
const detailedObstructions: DetailedObstruction[] = [
  {
    obstructionType: 'AlignmentRack',
    width: 12,
    length: 6,
    height: 64,
    resizable: true,
    tags: ['alignment', 'racking', 'shelf', 'shelves', 'warehouse', 'shop'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 14, y: 14, z: 14 },
  },
  {
    obstructionType: 'AlignmentRackUp',
    width: 12,
    length: 6,
    height: 78,
    resizable: true,
    tags: ['alignment', 'racking', 'shelf', 'shelves', 'warehouse', 'shop'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 14, y: 14, z: 14 },
  },
  {
    obstructionType: 'Ambulance',
    width: 8,
    length: 22,
    height: 105,
    resizable: true,
    tags: ['ambulance', 'vehicle', 'service', 'emergency'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 200, y: 200, z: 200 },
  },
  {
    obstructionType: 'AllInOne1',
    width: 3,
    length: 4.8,
    height: 75,
    resizable: true,
    tags: ['fitness', 'all', 'in', 'one', 'gym', 'workout'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.15, y: 0.15, z: 0.15 },
    rotation: { x: 0, y: Math.PI, z: Math.PI / 2 },
  },
  {
    obstructionType: 'AllInOne2',
    width: 3,
    length: 5,
    height: 65,
    resizable: true,
    tags: ['fitness', 'all', 'in', 'one', 'gym', 'workout'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.12, y: 0.12, z: 0.12 },
    rotation: { x: 0, y: Math.PI, z: -Math.PI / 2 },
  },
  {
    obstructionType: 'AllInOneWeightless',
    width: 2,
    length: 4.4,
    height: 85,
    resizable: true,
    tags: ['fitness', 'weightless', 'all', 'in', 'one', 'gym', 'workout'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.15, y: 0.15, z: 0.15 },
    rotation: { x: 0, y: Math.PI, z: -Math.PI / 2 },
  },
  {
    obstructionType: 'Bench',
    width: 1.5,
    length: 5,
    height: 50,
    resizable: true,
    tags: ['fitness', 'workout', 'bench', 'diy', 'gym'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 1.9, y: 1.9, z: 1.9 },
    rotation: { x: Math.PI / 2, y: Math.PI / 2, z: 0 },
  },
  {
    obstructionType: 'Bike',
    width: 1.8,
    length: 3.7,
    height: 60,
    resizable: true,
    tags: ['fitness', 'bike', 'gym', 'legs', 'workout'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.16, y: 0.16, z: 0.16 },
    rotation: { x: 0, y: Math.PI, z: Math.PI },
  },
  {
    obstructionType: 'Boeing787',
    width: 183,
    length: 172,
    height: 170,
    resizable: true,
    scale: { x: 7, y: 7, z: 7 },
    tags: ['boeing', '787', 'plane', 'jet', 'aircraft'],
    primaryUses: ['HANGAR'],
  },
  {
    obstructionType: 'Car',
    width: 6,
    length: 12,
    height: 55,
    resizable: true,
    scale: { x: 7, y: 7, z: 7 },
    tags: ['car', 'vehicle', 'sedan'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER', 'AUTOMOTIVE_SHOWROOM'],
  },
  {
    obstructionType: 'CarLift',
    width: 5,
    length: 10,
    height: 105,
    resizable: true,
    tags: ['carlift', 'garage', 'shop'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 12.8, y: 12.8, z: 12.8 },
  },
  {
    obstructionType: 'CarLiftUp',
    width: 5,
    length: 10,
    height: 105,
    resizable: true,
    tags: ['carlift', 'garage', 'shop'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 1.5, y: 1.5, z: 1.5 },
  },
  {
    obstructionType: 'CarLift2',
    width: 5,
    length: 10,
    height: 105,
    resizable: true,
    tags: ['carlift', 'garage', 'shop'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 0.127, y: 0.127, z: 0.127 },
    rotation: { x: 0, y: Math.PI, z: 0 },
  },
  {
    obstructionType: 'Chair',
    width: 2.5,
    length: 2.5,
    height: 36,
    resizable: true,
    tags: ['chair', 'home', 'living room'],
    primaryUses: ['RESIDENTIAL'],
    scale: { x: 1.6, y: 1.6, z: 1 },
    rotation: { x: 0, y: Math.PI, z: -Math.PI },
  },
  {
    obstructionType: 'Charter Bus',
    width: 9,
    length: 48,
    height: 160,
    resizable: true,
    tags: ['charter', 'bus', 'vehicle', 'transportation', 'service'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 162, y: 162, z: 162 },
  },
  {
    obstructionType: 'CncMachine1',
    width: 7,
    length: 5.8,
    height: 95,
    resizable: true,
    tags: ['manufacturing', 'cnc', 'machine'],
    primaryUses: ['MANUFACTURING'],
    scale: { x: 0.004, y: 0.004, z: 0.004 },
    rotation: { x: 0, y: Math.PI, z: Math.PI },
  },
  {
    obstructionType: 'CncMachine2',
    width: 3,
    length: 3,
    height: 25,
    resizable: true,
    tags: ['manufacturing', 'cnc', 'machine'],
    primaryUses: ['MANUFACTURING'],
    scale: { x: 0.45, y: 0.45, z: 0.45 },
    rotation: { x: 0, y: Math.PI, z: Math.PI },
  },
  {
    obstructionType: 'CoffeeTable',
    width: 2,
    length: 3.5,
    height: 24,
    resizable: true,
    tags: ['table', 'home', 'living room', 'coffee table'],
    primaryUses: ['RESIDENTIAL'],
    scale: { x: 120, y: 130, z: 1 },
    rotation: { x: 0, y: Math.PI, z: -Math.PI },
  },
  {
    obstructionType: 'Coil',
    width: 4,
    length: 4,
    height: 49,
    resizable: true,
    tags: ['coil', 'parts', 'shop', 'warehouse'],
    primaryUses: [
      'WAREHOUSE',
      'SHIPPING_RECEIVING',
      'MANUFACTURING',
      'STORAGE_RACKING',
      'PICKING_SORTING',
    ],
    scale: { x: 16, y: 16, z: 16 },
  },
  {
    obstructionType: 'Conveyor',
    width: 2.5,
    length: 10,
    height: 40,
    resizable: true,
    tags: ['manufacturing', 'conveyor', 'belt', 'assembly', 'line'],
    primaryUses: ['MANUFACTURING'],
    scale: { x: 0.165, y: 0.165, z: 0.165 },
    rotation: { x: 0, y: Math.PI, z: 0 },
  },
  {
    obstructionType: 'Couch',
    width: 6,
    length: 3,
    height: 36,
    resizable: true,
    tags: ['couch', 'home', 'living room'],
    primaryUses: ['OFFICE', 'RESIDENTIAL'],
    scale: { x: 56, y: 70, z: 1 },
    rotation: { x: 0, y: Math.PI, z: -Math.PI },
  },
  {
    obstructionType: 'Cow',
    width: 2.6,
    length: 8.7,
    height: 65,
    resizable: true,
    tags: ['cow', 'animal', 'agriculture', 'farm', 'barn'],
    primaryUses: ['AGRICULTURE'],
    scale: { x: 3, y: 3, z: 3 },
    rotation: { x: Math.PI / 2, y: Math.PI / 2, z: 0 },
  },
  {
    obstructionType: 'Cubicle',
    width: 8.7,
    length: 8.7,
    height: 65,
    resizable: true,
    tags: ['desk', 'office', 'cubicle'],
    primaryUses: ['OFFICE'],
    scale: { x: 0.08, y: 0.08, z: 0.08 },
    rotation: { x: Math.PI / 2, y: Math.PI / 2, z: 0 },
  },
  {
    obstructionType: 'DeliveryTruck',
    width: 8.6,
    length: 25,
    height: 120,
    resizable: true,
    tags: ['delivery', 'truck', 'cargo', 'service', 'vehicle'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 10.1, y: 10.1, z: 10.1 },
    rotation: { x: 0, y: -Math.PI / 2, z: -Math.PI / 2 },
  },
  {
    obstructionType: 'DodgeRamDually',
    width: 8,
    length: 21,
    height: 77,
    resizable: true,
    scale: { x: 7, y: 7, z: 7 },
    tags: ['dodge', 'ram', 'dually', 'truck', 'pickup', 'vehicle'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER', 'AUTOMOTIVE_SHOWROOM'],
  },
  {
    obstructionType: 'DoubleRackingEmpty',
    width: 7,
    length: 10,
    height: 200,
    resizable: true,
    tags: ['racking', 'rack', 'storage', 'shelf', 'shelves', 'warehouse'],
    primaryUses: [
      'MANUFACTURING',
      'PICKING_SORTING',
      'SHIPPING_RECEIVING',
      'STORAGE_RACKING',
      'WAREHOUSE',
    ],
    scale: { x: 2, y: 2, z: 2 },
  },
  {
    obstructionType: 'DoubleRackingWithBoxes',
    width: 7,
    length: 10,
    height: 200,
    resizable: true,
    tags: ['racking', 'rack', 'storage', 'shelf', 'shelves', 'warehouse'],
    primaryUses: [
      'MANUFACTURING',
      'PICKING_SORTING',
      'SHIPPING_RECEIVING',
      'STORAGE_RACKING',
      'WAREHOUSE',
    ],
    scale: { x: 2, y: 2, z: 2 },
  },
  {
    obstructionType: 'DumbbellRack',
    width: 5,
    length: 1.6,
    height: 40,
    resizable: true,
    tags: ['fitness', 'dumbbell', 'rack', 'weights', 'gym'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.16, y: 0.16, z: 0.16 },
    rotation: { x: 0, y: Math.PI, z: Math.PI },
  },
  {
    obstructionType: 'Elliptical',
    width: 2,
    length: 4.7,
    height: 55,
    resizable: true,
    tags: ['fitness', 'elliptical', 'workout', 'legs', 'gym'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.16, y: 0.16, z: 0.16 },
    rotation: { x: 0, y: Math.PI, z: 0 },
  },
  {
    obstructionType: 'F16',
    width: 35,
    length: 49,
    height: 170,
    resizable: true,
    tags: ['f16', '16', 'plane', 'jet', 'aircraft'],
    primaryUses: ['HANGAR'],
    scale: { x: 0.07, y: 0.07, z: 0.07 },
    rotation: { x: Math.PI, y: 0, z: 0 },
  },
  {
    obstructionType: 'FireTruck',
    width: 11,
    length: 35,
    height: 160,
    resizable: true,
    tags: ['firetruck', 'vehicle', 'service', 'emergency'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 110, y: 110, z: 110 },
  },
  {
    obstructionType: 'Fleetwood',
    width: 7,
    length: 18,
    height: 52,
    resizable: true,
    tags: ['car', 'sedan', 'vehicle', 'cadillac', 'fleetwood', 'classic'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER', 'AUTOMOTIVE_SHOWROOM'],
    scale: { x: 189, y: 189, z: 189 },
  },
  {
    obstructionType: 'Forklift',
    width: 4.3,
    length: 11,
    height: 75,
    resizable: true,
    scale: { x: 7, y: 7, z: 7 },
    tags: ['forklift', 'service', 'vehicle', 'warehouse'],
    primaryUses: [
      'WAREHOUSE',
      'SHIPPING_RECEIVING',
      'MANUFACTURING',
      'STORAGE_RACKING',
      'PICKING_SORTING',
    ],
  },
  {
    obstructionType: 'G450',
    width: 80,
    length: 85,
    height: 170,
    resizable: true,
    tags: ['G450', '450', 'plane', 'jet', 'aircraft', 'private'],
    primaryUses: ['HANGAR'],
    scale: { x: 9, y: 9, z: 9 },
    rotation: { x: Math.PI, y: 0, z: -Math.PI / 2 },
  },
  {
    obstructionType: 'HeavyBagStand',
    width: 5,
    length: 5,
    height: 60,
    resizable: true,
    tags: ['fitness', 'punching', 'bag', 'stand', 'gym'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.1, y: 0.1, z: 0.1 },
    rotation: { x: 0, y: Math.PI, z: 0 },
  },
  {
    obstructionType: 'Horse',
    width: 2.4,
    length: 8.7,
    height: 80,
    resizable: true,
    tags: ['agriculture', 'animal', 'horse', 'barn'],
    primaryUses: ['AGRICULTURE'],
    scale: { x: 6, y: 6, z: 6 },
    rotation: { x: Math.PI / 2, y: 0, z: 0 },
  },
  {
    obstructionType: 'KingBed',
    width: 7,
    length: 5.5,
    height: 48,
    resizable: true,
    tags: ['bed', 'bedroom'],
    primaryUses: ['OFFICE', 'RESIDENTIAL'],
    scale: { x: 8.5, y: 6.5, z: 1 },
    rotation: { x: 0, y: Math.PI, z: -Math.PI },
  },
  {
    obstructionType: 'Lathe',
    width: 14,
    length: 5.6,
    height: 100,
    resizable: true,
    tags: ['manufacturing', 'lathe', 'metal'],
    primaryUses: ['MANUFACTURING'],
    scale: { x: 0.5, y: 0.5, z: 0.5 },
    rotation: { x: 0, y: Math.PI, z: Math.PI },
  },
  {
    obstructionType: 'LatPulldown',
    width: 2,
    length: 2,
    height: 70,
    resizable: true,
    tags: ['fitness', 'pulldown', 'lateral', 'arm', 'workout', 'gym'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.12, y: 0.12, z: 0.12 },
    rotation: { x: 0, y: Math.PI, z: -Math.PI },
  },
  {
    obstructionType: 'LegMachine',
    width: 3.8,
    length: 5,
    height: 50,
    resizable: true,
    tags: ['fitness', 'legs', 'workout', 'gym'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.165, y: 0.165, z: 0.165 },
    rotation: { x: 0, y: Math.PI, z: Math.PI / 2 },
  },
  {
    obstructionType: 'LegMachine2',
    width: 3,
    length: 3.5,
    height: 80,
    resizable: true,
    tags: ['fitness', 'legs', 'workout', 'gym'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.12, y: 0.12, z: 0.12 },
    rotation: { x: 0, y: Math.PI, z: Math.PI },
  },
  {
    obstructionType: 'Mercedes',
    width: 6,
    length: 15,
    height: 50,
    resizable: true,
    scale: { x: 7, y: 7, z: 7 },
    tags: ['car', 'vehicle', 'mercedes', 'sportscar'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER', 'AUTOMOTIVE_SHOWROOM'],
  },
  {
    obstructionType: 'OfficeChair',
    width: 2,
    length: 2,
    height: 48,
    resizable: true,
    tags: ['office', 'chair', 'desk'],
    primaryUses: ['OFFICE', 'RESIDENTIAL'],
    scale: { x: 5900, y: 50, z: 5900 },
    rotation: { x: Math.PI / 2, y: Math.PI / 2, z: 0 },
  },
  {
    obstructionType: 'Pallet',
    width: 4,
    length: 3.5,
    height: 8,
    resizable: true,
    tags: ['pallet', 'warehouse', 'shop'],
    primaryUses: [
      'MANUFACTURING',
      'PICKING_SORTING',
      'SHIPPING_RECEIVING',
      'STORAGE_RACKING',
      'WAREHOUSE',
    ],
    scale: { x: 16.8, y: 16.8, z: 16.8 },
  },
  {
    obstructionType: 'PalletWithBoxes',
    width: 2.5,
    length: 3.7,
    height: 25,
    resizable: true,
    tags: ['pallet', 'warehouse', 'shop'],
    primaryUses: [
      'MANUFACTURING',
      'PICKING_SORTING',
      'SHIPPING_RECEIVING',
      'STORAGE_RACKING',
      'WAREHOUSE',
    ],
    scale: { x: 0.15, y: 0.15, z: 0.15 },
    rotation: { x: Math.PI / 2, y: Math.PI / 2, z: 0 },
  },
  {
    obstructionType: 'PoliceCharger',
    width: 6,
    length: 17,
    height: 60,
    resizable: true,
    tags: [
      'police',
      'cop',
      'car',
      'charger',
      'vehicle',
      'service',
      'emergency',
    ],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 86, y: 86, z: 86 },
  },
  {
    obstructionType: 'PoliceSUV',
    width: 6,
    length: 17,
    height: 70,
    resizable: true,
    tags: ['police', 'cop', 'suv', 'vehicle', 'service', 'emergency'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 67, y: 67, z: 67 },
  },
  {
    obstructionType: 'PoliceSUV2',
    width: 6,
    length: 17,
    height: 75,
    resizable: true,
    tags: ['police', 'cop', 'suv', 'vehicle', 'service', 'emergency'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 70, y: 70, z: 70 },
  },
  {
    obstructionType: 'Racking Empty',
    width: 3,
    length: 10,
    height: 200,
    resizable: true,
    tags: ['racking', 'rack', 'storage', 'shelf', 'shelves', 'warehouse'],
    primaryUses: [
      'MANUFACTURING',
      'PICKING_SORTING',
      'SHIPPING_RECEIVING',
      'STORAGE_RACKING',
      'WAREHOUSE',
    ],
    scale: { x: 2, y: 2, z: 2 },
  },
  {
    obstructionType: 'Racking With Boxes',
    width: 3,
    length: 10,
    height: 200,
    resizable: true,
    tags: [
      'racking',
      'rack',
      'boxes',
      'storage',
      'shelf',
      'shelves',
      'warehouse',
    ],
    primaryUses: [
      'MANUFACTURING',
      'PICKING_SORTING',
      'SHIPPING_RECEIVING',
      'STORAGE_RACKING',
      'WAREHOUSE',
    ],
    scale: { x: 2, y: 2, z: 2 },
  },
  {
    obstructionType: 'SchoolBus',
    width: 9,
    length: 40,
    height: 160,
    resizable: true,
    tags: ['school', 'bus', 'service', 'vehicle', 'transportation'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 110, y: 140, z: 170 },
  },
  {
    obstructionType: 'Semi1',
    width: 8,
    length: 55,
    height: 170,
    resizable: true,
    tags: ['semi', 'vehicle', 'trailer', 'truck', 'cargo', 'service'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 74, y: 74, z: 74 },
  },
  {
    obstructionType: 'Semi2',
    width: 8,
    length: 62,
    height: 160,
    resizable: true,
    tags: ['semi', 'vehicle', 'trailer', 'truck', 'cargo', 'service'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 84, y: 84, z: 84 },
  },
  {
    obstructionType: 'Semi4',
    width: 8,
    length: 60,
    height: 160,
    resizable: true,
    tags: ['semi', 'vehicle', 'trailer', 'truck', 'cargo', 'service'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 84, y: 84, z: 84 },
  },
  {
    obstructionType: 'SemiCab',
    width: 10,
    length: 22,
    height: 162,
    resizable: true,
    tags: ['semi', 'vehicle', 'trailer', 'truck', 'cargo', 'service'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 84, y: 84, z: 84 },
  },
  {
    obstructionType: 'SemiCab2',
    width: 9,
    length: 24,
    height: 158,
    resizable: true,
    tags: ['semi', 'vehicle', 'trailer', 'truck', 'cargo', 'service'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 0.07, y: 0.07, z: 0.07 },
  },
  {
    obstructionType: 'Staircase',
    width: 9,
    length: 24,
    height: 158,
    resizable: true,
    tags: ['staircase', 'stair', 'escalator', 'floors', 'levels'],
    primaryUses: ['OFFICE', 'CHURCH_RELIGION'],
    scale: { x: 0.1368, y: 0.2, z: 0.375 },
  },
  {
    obstructionType: 'Student Desk',
    width: 1.89,
    length: 2.58,
    height: 35.75,
    resizable: true,
    tags: ['school', 'desk', 'classroom', 'student'],
    primaryUses: ['OFFICE', 'CLASSROOM'],
    scale: { x: 6.2, y: 5, z: 6 },
  },
  {
    obstructionType: 'TankerSemi',
    width: 8,
    length: 62,
    height: 148,
    resizable: true,
    tags: ['semi', 'vehicle', 'trailer', 'truck', 'cargo', 'service', 'tanker'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 84, y: 84, z: 84 },
  },
  {
    obstructionType: 'Taxi',
    width: 6,
    length: 18,
    height: 74,
    resizable: true,
    tags: ['taxi', 'service', 'transportation', 'cab', 'uber'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 90, y: 90, z: 90 },
  },
  {
    obstructionType: 'TeacherDesk',
    width: 2.2,
    length: 5.8,
    height: 40,
    resizable: true,
    tags: ['school', 'desk', 'classroom', 'teacher'],
    primaryUses: ['OFFICE', 'CLASSROOM', 'RESIDENTIAL'],
    scale: { x: 5.4, y: 5, z: 6.5 },
  },
  {
    obstructionType: 'Transit Bus',
    width: 8,
    length: 40,
    height: 128,
    resizable: true,
    tags: ['transit', 'bus', 'service', 'vehicle', 'transportation'],
    primaryUses: ['AUTOMOTIVE_SERVICE_CENTER'],
    scale: { x: 115, y: 115, z: 115 },
  },
  {
    obstructionType: 'Treadmill',
    width: 2.5,
    length: 5.5,
    height: 40,
    resizable: true,
    tags: ['fitness', 'leg', 'workout', 'gym', 'treadmill'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.15, y: 0.15, z: 0.15 },
    rotation: { x: 0, y: Math.PI, z: Math.PI },
  },
  {
    obstructionType: 'WeightBench',
    width: 4,
    length: 4.2,
    height: 50,
    resizable: true,
    tags: ['fitness', 'arms', 'workout', 'weight', 'bench', 'gym'],
    primaryUses: [
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'STRENGTH_TRAINING',
    ],
    scale: { x: 0.12, y: 0.12, z: 0.12 },
    rotation: { x: 0, y: Math.PI, z: Math.PI },
  },
  {
    obstructionType: 'Worker',
    width: 3,
    length: 3,
    height: 74,
    resizable: false,
    tags: ['person', 'worker', 'model', 'construction'],
    primaryUses: [
      'AUTOMOTIVE_SERVICE_CENTER',
      'CARDIO_EQUIPMENT',
      'GROUP_EXERCISE_ROOM',
      'MANUFACTURING',
      'STRENGTH_TRAINING',
      'WAREHOUSE',
    ],
    scale: { x: 0.165, y: 0.165, z: 0.165 },
    rotation: { x: 0, y: Math.PI, z: Math.PI },
  },
  {
    obstructionType: 'Yacht',
    width: 8,
    length: 53,
    height: 74,
    resizable: true,
    tags: ['yacht', 'boat', 'transportation'],
    primaryUses: [],
    scale: { x: 15.7, y: 15.7, z: 15.7 },
  },
]

export default detailedObstructions
