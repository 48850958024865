import { graphql } from '~/gql'

const ARCHIVE_FACILITY_MUTATION = graphql(`
  mutation ArchiveFacility($id: ID!, $isArchived: Boolean!) {
    archiveFacility(id: $id, isArchived: $isArchived) {
      id
    }
  }
`)

export default ARCHIVE_FACILITY_MUTATION
