import React from 'react'
import PropTypes from 'prop-types'

import Container from './styled/Container'
import StyledButton from './styled/Button'

function CanvasTools({
  handleBack,
  handleRedoImageChange,
  handleUndoImageChange,
  history,
  historyIndex,
  resetProductImage,
  resetProductImageHistory,
  saveMetaData,
}) {
  return (
    <Container>
      <StyledButton
        label="Back"
        onClick={e => {
          e.preventDefault()
          handleBack()
        }}
      />

      <div>
        <StyledButton
          label="Undo"
          disabled={!historyIndex}
          onClick={e => {
            e.preventDefault()
            handleUndoImageChange()
          }}
        />
        <StyledButton
          label="Redo"
          disabled={history.length === historyIndex}
          onClick={e => {
            e.preventDefault()
            handleRedoImageChange()
          }}
        />
        <StyledButton
          label="Reset"
          onClick={e => {
            e.preventDefault()
            resetProductImage()
            resetProductImageHistory()
          }}
        />
        <StyledButton
          label="Save"
          onClick={e => {
            e.preventDefault()
            saveMetaData()
          }}
        />
      </div>
    </Container>
  )
}

CanvasTools.propTypes = {
  handleBack: PropTypes.func,
  handleRedoImageChange: PropTypes.func,
  handleUndoImageChange: PropTypes.func,
  history: PropTypes.array,
  historyIndex: PropTypes.number,
  resetProductImage: PropTypes.func,
  resetProductImageHistory: PropTypes.func,
  saveMetaData: PropTypes.func,
}

export default CanvasTools
