import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/UIKit/Button'
import { ToolbarItem } from 'components/UIKit/Toolbar'

import ButtonContainer from './styled/ButtonContainer'
import Label from './styled/Label'

export default function ItemMenuItem({
  icon,
  isDropdownVisible,
  label,
  onClick,
}) {
  return (
    <ToolbarItem fullWidth>
      <ButtonContainer>
        <Button
          fullWidth
          isDropdownVisible={isDropdownVisible}
          noBorder
          variant="light"
          icon={icon}
          iconRight="arrowRight"
          iconRightColor="light"
          size="l"
          onClick={onClick}
          responsive
        >
          <Label>{label}</Label>
        </Button>
      </ButtonContainer>
    </ToolbarItem>
  )
}

ItemMenuItem.propTypes = {
  icon: PropTypes.string.isRequired,
  isDropdownVisible: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
