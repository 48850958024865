import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MetricsTable } from './MetricsTable'
import { useCoolingVtkWorker } from 'components/PerformanceMetrics/hooks/useVtkWorker'
import { usePerformanceMetricsProps } from 'components/PerformanceMetrics/hooks/usePerformanceMetricsProps'
import InfoTooltip from 'components/UIKit/InfoTooltip'
import {
  SeatedVelocityIcon,
  StandingVelocityIcon,
  ThermometerIcon,
  PredictedMeanVoteIcon,
  CoolingCoverageIcon,
  DissatisfiedOccupantsIcon,
} from '../icons'
import Tabs from 'components/UIKit/Tabs/Tabs'
import { Tab } from 'components/UIKit/Tabs/index'
import { formatHeights, formatPercent } from '../util/format'
import { SpinningIcon } from 'components/PerformanceMetrics/styled/SpinningIcon'
import { PieChart } from 'components/PerformanceMetrics/subcomponents/PieChart'
import { camelToTitleCase } from 'utils/string'
import { HEIGHTS, HEIGHT_VALUES, STILL_AIR_VELOCITY, getCoolingThreshold } from 'config/cfd'
import { ImperialTemperature, MetricTemperature } from 'store/units/types/temperature'
import { ImperialVelocity } from 'store/units/types/velocity'
import { getSensationColor, getThermalComfort } from 'lib/thermalComfortTool/index'
import Heading from 'components/PerformanceMetrics/styled/Heading'
import { Accordion } from 'components/PerformanceMetrics/subcomponents/Accordion'
import { BasicGrid } from 'components/PerformanceMetrics/subcomponents/BasicGrid'
import Icon from 'components/UIKit/Icon/Icon'
import { useFormatTemperature } from 'components/PerformanceMetrics/hooks/useFormatTemperature'
import { useFormatVelocity } from 'components/PerformanceMetrics/hooks/useFormatVelocity'
import { metricToImperial } from 'store/units/converters/temperature'

const HEIGHT_ICONS = {
  [HEIGHTS.standing]: <StandingVelocityIcon />,
  [HEIGHTS.seated]: <SeatedVelocityIcon />,
}

const useNoFansData = () => {
  const { formatTemperature } = useFormatTemperature()
  const { formatVelocity } = useFormatVelocity()
  const { facility } = usePerformanceMetricsProps()
  const defaultAirTemp = new ImperialTemperature(facility.indoorSummerTemp)
  const { sensation, pmv, ppd, set } = getThermalComfort({
    airVelocity: STILL_AIR_VELOCITY,
    humidity: facility.indoorHumidity,
    meanAirTemp: defaultAirTemp,
    clothingType: facility.clothingType,
    metabolicRate: facility.activityLevel,
  })
  return {
    airVelocity: formatVelocity(STILL_AIR_VELOCITY),
    sensation,
    sensationColor: getSensationColor(sensation),
    averageAirTemperature: formatTemperature(defaultAirTemp),
    pmv: pmv.toFixed(2),
    ppd: ppd.toFixed(2) + '%',
    set: formatTemperature(metricToImperial(set)),
    // Technically, there is no cooling effect when fans are not running
    coolingEffect: '---',
  }
}

const useWithFansData = ({ velocityUrls, temperatureUrls }) => {
  const { formatTemperature, formatTemperatureDelta } = useFormatTemperature()
  const { formatVelocity } = useFormatVelocity()
  const { areaOfConcernEdges, facility } = usePerformanceMetricsProps()
  const { primaryType, primaryUse, indoorSummerTemp, indoorHumidity } = facility
  const coverageThreshold = getCoolingThreshold({ primaryType, primaryUse })

  const coolingResults = useCoolingVtkWorker({
    temperatureUrls,
    velocityUrls,
    coverageThreshold,
    comfortZoneEdges: areaOfConcernEdges,
  })

  if (coolingResults.loading) {
    return { loading: true, error: undefined, data: undefined }
  } else if (coolingResults.error || !coolingResults.data) {
    return { loading: false, error: coolingResults.error, data: undefined }
  } else {
    const { averageTemperature, coverage, averageAirVelocity } = coolingResults.data
    const isEvap = averageTemperature !== null
    const averageTemp = new ImperialTemperature(isEvap ? averageTemperature : indoorSummerTemp)
    const airVelocity = new ImperialVelocity(averageAirVelocity)
    const { pmv, ppd, set, coolingEffect, sensation } = getThermalComfort({
      airVelocity,
      humidity: indoorHumidity,
      meanAirTemp: averageTemp,
      clothingType: facility.clothingType,
      metabolicRate: facility.activityLevel,
    })
    const data = {
      airVelocity: formatVelocity(airVelocity),
      sensation,
      sensationColor: getSensationColor(sensation),
      coverage,
      pmv: pmv.toFixed(2),
      ppd: ppd.toFixed(2) + '%',
      set: formatTemperature(metricToImperial(set)),
      coolingEffect: formatTemperatureDelta(new MetricTemperature(coolingEffect)),
      averageTemperature: formatTemperature(averageTemp),
    }
    return { data, loading: false, error: undefined }
  }
}

/**
 * @typedef {import('hooks/useFacilityVtkResults').vtkResult} vtkFiles
 * @param {object} props
 * @param {vtkFiles} props.vtkFiles
 */
const CoolingResults = ({ vtkFiles: { vtkUrlGroups } }) => {
  const [selectedHeight, setSelectedHeight] = useState(HEIGHTS.standing)
  const noFansMetrics = useNoFansData()
  const withFansMetrics = useWithFansData({
    velocityUrls: vtkUrlGroups.velocity[selectedHeight],
    temperatureUrls: vtkUrlGroups.temperature[selectedHeight],
  })

  const formattedHeights = formatHeights(HEIGHT_VALUES[selectedHeight])
  const legend = {
    header: (
      <InfoTooltip
        title={`Calculated using evaluation heights: ${formattedHeights}`}
        iconSize="16px"
      />
    ),
    cells: [
      { icon: HEIGHT_ICONS[selectedHeight], content: 'Avg Air Velocity' },
      { icon: <ThermometerIcon />, content: 'Avg Air Temp' },
      { icon: <PredictedMeanVoteIcon />, content: 'Occupant PMV Value' },
      { content: 'Thermal Sensation' },
      { icon: <CoolingCoverageIcon />, content: 'Cooling Coverage' },
    ],
  }

  const noFans = {
    header: 'No Fans',
    cells: [
      { content: noFansMetrics.airVelocity },
      { content: noFansMetrics.averageAirTemperature },
      { content: noFansMetrics.pmv },
      {
        content: <Heading color={noFansMetrics.sensationColor}>{noFansMetrics.sensation}</Heading>,
      },
      { icon: <PieChart percentComplete={0} />, content: '0%' },
    ],
  }

  const withFans = {
    header: 'With Fans',
    cells: withFansMetrics.loading
      ? [{ content: <SpinningIcon name="refresh" size="20" /> }]
      : withFansMetrics.error || !withFansMetrics.data
      ? [
          {
            icon: <Icon name="warn" size="20" />,
            content: withFansMetrics.error?.message || 'Failed to calculate metrics',
          },
        ]
      : [
          { content: withFansMetrics.data.airVelocity },
          { content: withFansMetrics.data.averageTemperature },
          { content: withFansMetrics.data.pmv },
          {
            content: (
              <Heading color={withFansMetrics.data.sensationColor}>
                {withFansMetrics.data.sensation}
              </Heading>
            ),
          },
          {
            icon: <PieChart percentComplete={withFansMetrics.data.coverage} />,
            content: formatPercent(withFansMetrics.data.coverage),
          },
        ],
  }

  return (
    <>
      <Tabs size="s" activeIndex={Object.values(HEIGHTS).indexOf(selectedHeight)}>
        {Object.values(HEIGHTS).map((height, i) => (
          <Tab key={i} title={camelToTitleCase(height)} onClick={() => setSelectedHeight(height)}>
            <MetricsTable columns={[legend, noFans, withFans]} />
          </Tab>
        ))}
      </Tabs>
      <Accordion title="Advanced" alignItems="start" spacing={1}>
        <BasicGrid
          gap={[2, 0]}
          columns={[
            [
              { content: 'Cooling Effect' },
              { icon: <DissatisfiedOccupantsIcon />, content: 'PPD' },
              { content: 'SET' },
            ],
            [
              { content: noFansMetrics.coolingEffect },
              { content: noFansMetrics.ppd },
              { content: noFansMetrics.set },
            ],
            withFansMetrics.loading
              ? [{ content: <SpinningIcon name="refresh" size="20" /> }]
              : withFansMetrics.error || !withFansMetrics.data
              ? [
                  {
                    icon: <Icon name="warn" size="20" />,
                    content: withFansMetrics.error?.message || 'Failed to calculate metrics',
                  },
                ]
              : [
                  { content: withFansMetrics.data.coolingEffect },
                  { content: withFansMetrics.data.ppd },
                  { content: withFansMetrics.data.set },
                ],
          ]}
        />
      </Accordion>
    </>
  )
}

CoolingResults.propTypes = {
  vtkFiles: PropTypes.object,
}

export { CoolingResults }
