import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Main = styled.main`
  flex: 0 1 ${props => (props.wide ? '1150px' : '750px')};
  margin: 0 auto;
  padding: ${theme('spacing.xl')} 0;
`

export default Main
