export const sections = [
  {
    title: `Home Page Basics`,
    articles: [
      {
        header: `Do I need to create an account to use SpecLab?`,
        paragraphs: [
          `<b>Yes.</b> The first time you navigate to <a href='https://lab.bigassfans.com' target='_blank'>lab.bigassfans.com</a> you will be prompted to <b><i>Sign Up.</i></b> Any email address with an approved domain (@bigassfans.com or the majority of distribution partners) can create an account without administrative assistance.`,
        ],
      },
      {
        header: `How do I login and create a facility?`,
        paragraphs: [
          `If not logged in already: Go to <a href='https://lab.bigassfans.com' target='_blank'>lab.bigassfans.com</a> → click <b><i>Log In With Google</i></b> → select your BAF Google account → You should be redirected to the <b><i>My Facilities page</i></b>, click <b><i>New Facility</i></b> and choose <b><i>From Scratch</i></b>.`,
          {
            html: `If already logged in and on a facility: Click on the facility menu in the top left → Click <b><i>Create New Facility</i></b>`,
            tabOver: 1,
          },
        ],
      },
      {
        header: `Can I view other users' facilities in addition to my own?`,
        paragraphs: [
          `<b>Yes.</b> On the SpecLab homepage, click the carat (^) icon next to <b><i>My Facilities</i></b> and then select <b><i>All Facilities</i></b>. From there, you can search by creator email address or facility name. Note that you only have the option to View other facilities, but will need to make a copy to make and save any edits to the layout.`,
        ],
      },
      {
        header: `When should I use one of the built-in facility templates?`,
        paragraphs: [
          `Templates are most often used for preliminary customer conversations prior to a customized model of their facility. By showing the customer an example of what a SpecLab model looks like, it helps them to understand what is required for their own simulation.`,
        ],
      },
    ],
  },
  {
    title: `SpecLab Navigation Commands`,
    articles: [
      {
        header: `2D View (Default)`,
        paragraphs: [
          `<b>Pan</b> - Click & Hold Center Wheel`,
          `<b>Zoom</b> - Roll Center Wheel`,
          `<b>Select</b> - Left-Click`,
          `<b>Multi-Select</b> - Left-Click, Hold & Drag rectangle over all objects to select`,
        ],
      },
      {
        header: `3D View (Toggle On/Off)`,
        paragraphs: [
          `<b>Pan</b> - Click & Hold Center Wheel`,
          `<b>Zoom</b> - Roll Center Wheel`,
          `<b>Rotate 3D</b> - Right-Click, Hold, and Move mouse OR use predefined view bars at bottom left`,
        ],
      },
      {
        header: `Miscellaneous`,
        paragraphs: [
          `<b>To exit a command</b>: Esc key OR Click <b><i>Select</i></b> on left pane OR Left-Click anywhere outside the building`,
          `<b>To manually multi-select</b>: Hold <b><i>Shift</i></b> and left-click on all components`,
          `<b>To delete a component/components</b>: Left-Click, Click <b><i>Delete</i></b> on keyboard OR click <b><i>Edit</i></b> → <b><i>Delete</i></b> on left pane`,
        ],
      },
    ],
  },
  {
    title: `Exterior Walls`,
    articles: [
      {
        header: `How do I create exterior walls?`,
        paragraphs: [
          `When creating exterior walls, you will have the option to draw a Custom shape, Rectangle, or Circle. In general, a custom shape should only be used for tracing existing images or to draw unusual-shaped buildings. Otherwise, the rectangle is the most commonly used shape.`,
          `<b><i>Custom:</i></b>`,
          {
            html: `When using a custom shape, simply click and move the cursor to create line segments. You must <u>close</u> the shape, meaning your final line segment should connect to your first. Intersections will be noted by a green circle.`,
            tabOver: 1,
          },
          `<b><i>Rectangle:</i></b>`,
          {
            html: `Choose Draw → Rectangle → select any corner of the rectangle → click, hold, and drag to create your shape.`,
            tabOver: 1,
          },
          `<b><i>Circle:</i></b>`,
          {
            html: `Choose Draw → Circle → select any edge of the circle → click, hold, and drag to create your shape.`,
            tabOver: 1,
          },
          ` If you do not have an existing build to trace and your building is either a Rectangle OR an L-Shape, you may add a Template.`,
          `<b><i>Template:</i></b>`,
          {
            html: `Click Template → select your shape → enter the needed dimensions.`,
            tabOver: 1,
          },
          `Tips:`,
          {
            html: `You can extend existing shapes by selecting an individual line segment and dragging it.`,
            tabOver: 1,
          },
          {
            html: `Anywhere you see height you should assume that means the eave height.`,
            tabOver: 1,
          },
          `<a href='https://www.loom.com/share/c8c7f63b739844a1a996b2a66ea5e04a' target='_blank'>Video showing these steps</a>`,
        ],
      },
      {
        header: `Can I import and trace an existing image?`,
        paragraphs: [
          `SpecLab allows users to upload existing layouts and trace over them. This makes it easier to create the layout and also provides a more meaningful visual for the customer.`,
          `<b>Prerequisites:</b>`,
          {
            html: `You need a clean & scaled layout, preferably an original copy and not something you've taken a picture of.`,
            tabOver: 1,
          },
          {
            html: `You need to know the distance between two points somewhere on the layout.`,
            tabOver: 1,
          },
          `Click Trace Image → Upload your PDF, DWG, or image file → Rotate or crop the file as needed → Use the zoom and pan functions to center the layout so you can see both of the points you wish to measure → Select your first point → Select your second point → Enter the distance between the two points in the measurement input.`,
          `Once you've imported the layout → select the custom tool to trace over the layout.`,
          `<a href='https://www.loom.com/share/6b20d87e3b2c4d9bb4c9df4be87cdab9' target='_blank'>Video showing these steps</a>`,
        ],
      },
      {
        header: `How do I change my eave height?`,
        paragraphs: [
          `Your exterior wall height should always be your eave height. It's best to change exterior wall height at once as opposed to individual wall segments. Clicking anywhere inside the footprint of the building allows you to change the height of all exterior walls at once.`,
          `<a href='https://www.loom.com/share/974c19a49bc3408caa56b10885c0e3e3' target='_blank'>Video showing these steps</a>`,
        ],
      },
    ],
  },
  {
    title: `Roofs`,
    articles: [
      {
        header: `Does my facility have a roof if I don't add one?`,
        paragraphs: [
          `<b>Yes.</b> After creating a closed loop of exterior walls, a flat roof will cover the extents of the space. A successfully created roof of a closed facility will be blue. Changes to the roof structure can be made using roof templates, elevation lines, and elevation points.`,
        ],
      },
      {
        header: `How do I create a gabled roof?`,
        paragraphs: [
          `<b>Prerequisites:</b>`,
          `You must have your roof selected/active. To do this:`,

          {
            html: `While in <u>onboarding</u>, click next/back to navigate to <b><i>Roofs</i></b> stage → click somewhere on the roof.`,
            tabOver: 1,
          },
          {
            html: `While in <u>core</u> experience, click the layers panel → make sure the roof layer is toggled on → click somewhere on the roof.`,
            tabOver: 1,
          },
          {
            html: `Important Note: your wall height should always be your eave height. <a href='https://www.loom.com/share/974c19a49bc3408caa56b10885c0e3e3' target='_blank'>Video showing these steps</a>`,
            tabOver: 2,
          },
          `<b>To create a gabled roof using Roof Templates (Recommended):</b>`,
          `Once your roof is selected… → Select <b><i>Add</i></b> → <b><i>Gable</i></b> → Choose whether you want the peak to run <b><i>horizontal</i></b> or <b><i>vertical</i></b> across the plan view of the space → Choose <b><i>peak height</i></b> → Choose between Centered or Offset From Wall. Most applications will have a centered gable.`,
          `<b>To manually create a gabled roof:</b>`,
          `Once your roof is selected… → Select elevation line tool → Move your cursor to the edge of the roof and towards the center (you should see crosshair gridlines like the image below). Once you see the crosshair gridlines, click to set the first point → move your cursor to the opposite wall (again you should see crosshair gridlines), and click to set the second point → the elevation line should be selected; adjust the height as needed in the side panel.`,
          `<a href='https://www.loom.com/share/31a473c64c4e47c385025930c30ff343' target='_blank'>Video showing these steps</a>`,
        ],
      },
      {
        header: `How do I create a monosloped roof?`,
        paragraphs: [
          `<b>Prerequisites:</b>`,
          {
            html: `Important Note: your wall height should always be your eave height. <a href='https://www.loom.com/share/974c19a49bc3408caa56b10885c0e3e3' target='_blank'>Video showing these steps</a>`,
            tabOver: 1,
          },
          {
            html: `You must have your roof selected/active. To do this…`,
            tabOver: 1,
          },
          {
            html: `While in <u>onboarding</u>, click next/back to navigate to <b><i>Roofs</i></b> stage → click somewhere on the roof.`,
            tabOver: 2,
          },
          {
            html: `While in <u>core</u> experience, click the layers panel → make sure the roof layer is toggled on → click somewhere on the roof.`,
            tabOver: 2,
          },
          `<b>To create a monoslope roof using Roof Templates (Recommended):</b>`,
          `Once your roof is selected… → Select <b>Add</b> → <b>Monoslope</b> → Choose <b>Raised Side</b> (left, top, right, bottom)  → Choose <b>peak height.</b>`,
          `<b>To manually create a monoslope roof:</b>`,
          `Once your roof is selected… → Select elevation line tool → Move your cursor to the edge of the roof and towards the center (you should see crosshair gridlines like the image below). Once you see the crosshair gridlines, click to set the first point → move your cursor to the opposite wall (again you should see crosshair gridlines), and click to set the second point → the elevation line should be selected; adjust the height as needed in the side panel.`,
          `<a href='https://www.loom.com/share/57613c3cfa274070bf3aeb96305d377c' target='_blank'>Video showing these steps</a>`,
        ],
      },
      {
        header: `How do I change my peak height?`,
        paragraphs: [
          `Roof heights are defined by exterior wall height (flat roof) or elevation points/lines if they exist (all other roof types). `,
          {
            html: `Given your facility does not have an elevation point or line, look at <b><i>Exterior Walls</i></b> FAQ above for how to change eave height.`,
            tabOver: 1,
          },
          {
            html: `Given your facility does have at least one elevation point or line, click the <b><i>Layers</i></b> panel → make sure the <b><i>Roofs</i></b> layer is toggled on → move your cursor towards the elevation point/line → the elevation point/line should get larger, click to select → adjust the height as needed.`,
            tabOver: 1,
          },
          `<a href='https://www.loom.com/share/fdfc9230da8b4b9681bdea0bb828a3de' target='_blank'>Video showing these steps</a>`,
        ],
      },
      {
        header: `What is the difference between the elevation point and elevation line tools?`,
        paragraphs: [
          `<b><i>Elevation Point</i></b> is meant to alter the roof at one specific point (such as all 4 corners of a roof sloping to 1 peak). `,
          `<b><i>Elevation Line</i></b> is meant to represent a consistent slope (gable, single slope, etc). `,
        ],
      },
    ],
  },
  {
    title: `Interior Walls`,
    articles: [
      {
        header: `How do I draw interior walls?`,
        paragraphs: [
          `When creating interior walls, you will have the option of <b><i>Custom</i></b>, <b><i>Rectangle</i></b>, or <b><i>Circle</i></b>. <b><i>Custom</i></b> will by far be the most common choice.`,
          `While on the <b><i>Interior Walls</i></b> onboarding stage OR after selecting <b><i>Walls & Doors</i></b> → <b><i>Interior Walls</i></b> in the core experience:`,
          {
            html: `Custom Tool:`,
            tabOver: 1,
          },
          {
            html: `Note: Custom interior walls should always start or end at an adjacent interior or exterior wall. When creating an interior wall with multiple segments, a green circle will highlight when you've reached an intersection with an interior or exterior wall. As a best practice, use obstructions for wall segments that do not connect two other walls.`,
            tabOver: 2,
          },
          {
            html: `Select <b><i>Draw</i></b> → <b><i>Custom</i></b> → move cursor to a point along an exterior wall where you want the line to start and click. Note that while moving along the wall, you should see a green line to signify that you are on the midpoint → move cursor to the next point → if connecting back to an exterior wall, make sure you see the green snapline to signify you are on the midpoint.`,
            tabOver: 2,
          },
          {
            html: `To change the wall height, select an individual line segment and update the height field or multi-select by holding <b><i>shift</i></b> and selecting applicable wall segments.`,
            tabOver: 2,
          },
          {
            html: `Rectangle Tool:`,
            tabOver: 1,
          },
          {
            html: `Choose the Rectangle tool → select where you want the shape to start (this will become the top-left position of the rectangle) → click, hold, and drag to create your shape. Rectangles should only be used for interior spaces not sharing an intersection with other interior or exterior walls.`,
            tabOver: 2,
          },
          {
            html: `To change the wall height, select an individual line segment and update the height field or multi-select by holding <b>shift</b> and selecting applicable wall segments.`,
            tabOver: 2,
          },
          {
            html: `Tips:`,
            tabOver: 1,
          },
          {
            html: `You should <u>not</u> leave space for doors. Doors can be added after this step and do not require <i>gaps</i> in the wall.`,
            tabOver: 2,
          },
          `<a href='https://www.loom.com/share/7b92b2fcc7a6483f864634a09c8789bb' target='_blank'>Video showing these steps</a>`,
        ],
      },
      {
        header: `When should I use interior walls?`,
        paragraphs: [
          `Interior walls should primarily be used when you have two adjacent spaces with different ceiling heights that BOTH have ceiling fans. If an area is partitioned off and does NOT have ceiling fans, this area can be represented by a block obstruction for simplicity.`,
        ],
      },
      {
        header: `Why is a "full height" interior wall 1' shorter than the exterior wall height?`,
        paragraphs: [
          `In order for CFD simulations to run, there must be a gap between the roof and any interior walls. For flat roofs, this means the interior wall must be 1' below the exterior wall (or eave) height.`,
        ],
      },
    ],
  },
  {
    title: `Ceilings`,
    articles: [
      {
        header: `When do I need a ceiling?`,
        paragraphs: [
          `In most cases, ceilings are only useful when modeling residential fans in a much larger building with a taller roof deck or when defining two separate ceiling heights for 2 areas divided by an interior wall.`,
        ],
      },
      {
        header: `How do I draw ceilings?`,
        paragraphs: [
          `<b>Prerequisites:</b>`,
          {
            html: `You've drawn interior walls.`,
            tabOver: 1,
          },
          `You are on the <b><i>Ceilings</i></b> stage in onboarding OR have the <b><i>Ceilings</i></b> layer active in the core experience`,
          `Move your cursor over the space you wish to toggle ceilings, area should show as highlighted, click to select → enable ceilings, update height, color, name if needed. `,
          `<a href='https://www.loom.com/share/d5970593f9dc44c39df8403b5dc3fc36' target='_blank'>Video showing these steps</a>`,
        ],
      },
    ],
  },
  {
    title: `Obstructions`,
    articles: [
      {
        header: `What are obstructions?`,
        paragraphs: [
          `Obstructions are anything that can impede airflow in the model space. Obstructions are typically found at floor level in the form of equipment, vehicles, racking, or product. When validating a fan recommendation in SpecLab, it is important to place as many of the relevant obstructions in the space as possible. `,
          `The types of obstruction available in SpecLab are as follows:`,
          `Custom: Any shape can be created, and the object is treated as a block.`,
          `Rectangle`,
          `Circle`,
          `Detailed: A variety of realistic obstructions based on facility type, such as exercise equipment, product racking, motor vehicles, etc.`,
        ],
      },
      {
        header: `How do I draw obstructions?`,
        paragraphs: [
          `<b>Prerequisites:</b>`,
          {
            html: `You've drawn exterior walls and set up your roof.`,
            tabOver: 1,
          },
          {
            html: `You are on the <b><i>Obstructions</i></b> stage in onboarding OR have the selected to add obstructions in core through <b><i>Obstructions & Panels</i></b>.`,
            tabOver: 1,
          },
          {
            html: `Custom: Choose Draw → Custom → Move cursor in facility → Select the point you'd like to start the obstruction → Move the cursor and click each vertex in order (turn off Snapping in Settings for increased flexibility)  → Green circle will appear when you are about to reach a shared vertex and can complete the obstruction.`,
            tabOver: 1,
          },
          {
            html: `Rectangle: Choose Draw → Rectangle → select any corner of the rectangle → Click, Hold, and drag to create your shape.`,
            tabOver: 1,
          },
          {
            html: `Circle Tool: Choose Draw → Circle → Move cursor in facility → Click a point to start the circle (top left quadrant if tracing an existing circle)  → Click, Hold, and Drag to create your circular shape.`,
            tabOver: 1,
          },
          {
            html: `NOTE: To create a true circle, you can hold Shift while dragging to keep the X and Y dimensions of the circle similar.`,
            tabOver: 2,
          },
          {
            html: `Detailed: Choose Add → Select from the Detailed Obstruction options on the right-hand side of the screen → Move cursor into facility → Click to drop.`,
            tabOver: 1,
          },
          `Tips:`,
          {
            html: `To move the shape you can select → drag to desired location OR type dimensions within input labels.`,
            tabOver: 1,
          },
          {
            html: `You can update attributes as needed:`,
            tabOver: 1,
          },
          {
            html: `Type: Defines what type of obstruction this is.`,
            tabOver: 2,
          },
          {
            html: `Starting Location: From Floor = shape starts on the floor, From Ceiling = shape starts on the ceiling (lighting, HVAC, etc).`,
            tabOver: 2,
          },
          {
            html: `Height/Width/Length = update as needed.`,
            tabOver: 2,
          },
          `<a href='https://www.loom.com/share/dad66d15131f483f94ef24124e164825' target='_blank'>Video showing these steps</a>`,
        ],
      },
      {
        header: `When should I use detailed obstructions vs. basic obstructions?`,
        paragraphs: [
          `Detailed obstructions are best in smaller spaces with 10-20 such items or when block obstructions cannot capture the true impact of the piece of product or equipment, like a fitness center. We do not recommend placing more than 50 detailed obstructions in a space for most simulations due to the complexity and time they add to the CFD model. Instead, we recommend using block obstructions wherever possible. An example of such a space would be a large warehouse with many rows of bulk racking. Single rectangles can be used to model an entire row of racking, and in some instances could even be used to represent a full area with multiple racking rows.`,
        ],
      },
    ],
  },
  {
    title: `Mounting Structures`,
    articles: [
      {
        header: `When should I use mounting structures?`,
        paragraphs: [
          `<b><i>Secondary</i></b> mounting structure is shown visually through a yellow plane when visibility is turned on. The secondary mounting structure should be used for all applications to place fans at a precise height AFF, and cannot be disabled. The secondary mounting structure is the only structure that should be enabled for models where all fans will be mounted to the same structure type and depth or there is little information about the overall mounting structure and a preliminary simulation is being provided. `,
          `<b><i>Primary</i></b> mounting structure should be used for accuracy when you have detailed information about the types, locations, and depths of mounting structures provided by a customer drawing, site visit, or phone conversation AND there are fans that will be mounting to 2 different types of structure, ie. I-beams and z-purlins. The primary mounting structure is also aesthetically pleasing for highly detailed models.`,
          `<b><i>Columns</i></b> can be used for visual effect or to ensure fan clearances in applications where fans will be spinning in close proximity to them. Columns are no longer required to place directional fans at column locations since you now have the ability to "Force Wall/Column Mount" at any location within the space.`,
        ],
      },
      {
        header: `How do I use mounting structures?`,
        paragraphs: [
          `<b>Prerequisites:</b>`,
          {
            html: `You've drawn your exterior walls and defined roof heights.`,
            tabOver: 1,
          },
          {
            html: `You have the Mounting Structures layer active and selected.`,
            tabOver: 1,
          },
          {
            html: `<b>Primary</b>: This represents your main members (I-beams, girders, etc)`,
            tabOver: 1,
          },
          {
            html: `<b><i>Enable Primary Structure.</i></b>`,
            tabOver: 2,
          },
          {
            html: `Toggle visibility on/off.`,
            tabOver: 2,
          },
          {
            html: `Type = select the structure type.`,
            tabOver: 2,
          },
          {
            html: `Style = *applicable if roof is sloped. Select conforming if beams are sloped, otherwise select flat.`,
            tabOver: 2,
          },
          {
            html: `Direction = members run horizontal or vertical.`,
            tabOver: 2,
          },
          {
            html: `Width/Spacing/Depth = enter measurements.`,
            tabOver: 2,
          },
          {
            html: `Shift = enter measurement to shift beams off the wall if needed.`,
            tabOver: 2,
          },
          {
            html: `<b>Secondary</b>: This represents your secondary structure (purlins, bar joists, etc)`,
            tabOver: 1,
          },
          {
            html: `Toggle visibility on/off.`,
            tabOver: 2,
          },
          {
            html: `Type = select the structure type.`,
            tabOver: 2,
          },
          {
            html: `Depth = distance from the roof to the underside of the secondary structure (eg: distance from the roof to the underside of the purlins). This is needed to calculate fan height.`,
            tabOver: 2,
          },
          {
            html: `<b>Columns</b>`,
            tabOver: 1,
          },
          {
            html: `Helpful tip: it's best to toggle secondary off when laying out columns, this makes it easier to see them.`,
            tabOver: 2,
          },
          {
            html: `Toggle visibility on/off.`,
            tabOver: 2,
          },
          {
            html: `Check <i>Has Columns</i> to show columns.`,
            tabOver: 2,
          },
          {
            html: `Enter spacing apart from one another, enter shift if you need to move off the wall.`,
            tabOver: 2,
          },
          `<a href='https://www.loom.com/share/53f8efd1aa1445258a296c6304448e6c' target='_blank'>Video showing these steps</a>`,
        ],
      },
    ],
  },
  {
    title: `Doors`,
    articles: [
      {
        header: `How do I add doors?`,
        paragraphs: [
          `<b>Prerequisites</b>:`,
          {
            html: `You've drawn your exterior walls and interior walls.`,
            tabOver: 1,
          },
          `<b>To add</b>:`,
          {
            html: `Click Insert/Draw → select main door or dock door → move your cursor to where you want to locate along the wall (must be on the wall), the door will highlight green when in an acceptable location, click to locate → update properties as needed (height, width, type).`,
            tabOver: 1,
          },
          `<b>To copy</b>:`,
          {
            html: `Select the door you wish to duplicate → click copy on the left → relocate as needed.`,
            tabOver: 1,
          },
          `<b>To delete</b>:`,
          {
            html: `Select the door you wish to delete → click delete on the left OR delete on the keyboard.`,
            tabOver: 1,
          },
          `<a href='https://www.loom.com/share/fb679915210b42608288dd6328ea1648' target='_blank'>Video showing these steps</a>`,
        ],
      },
    ],
  },
  {
    title: `Products`,
    articles: [
      {
        header: `How do I add an overhead fan?`,
        paragraphs: [
          `<b>Prerequisites</b>:`,
          {
            html: `You've drawn your exterior walls, interior walls, setup your roofs/mounting structures, and drawn obstructions if applicable.`,
            tabOver: 1,
          },
          `<b>To add</b>:`,
          {
            html: `Click <b><i>Add Product</i></b> → select the fan you wish to add.`,
            tabOver: 1,
          },
          {
            html: `Move your cursor to where you want to locate the fan.`,
            tabOver: 1,
          },
          {
            html: `You should see dimension lines coming from the center of the fan to nearby fans/walls. <i>You can type your desired dimensions into each label.</i>`,
            tabOver: 2,
          },
          {
            html: `When placing nearby other fans you will see a green snap line suggesting you are lined up with the other fan vertically or horizontally.`,
            tabOver: 2,
          },
          `<b>To copy</b>:`,
          {
            html: `Select the fan you wish to duplicate → click <b><i>Copy</i></b> on the left → relocate as needed.`,
            tabOver: 1,
          },
          `<b>To delete</b>:`,
          {
            html: `Select the fan you wish to delete → click delete on the left OR delete on the keyboard.`,
            tabOver: 1,
          },
          `<b>To update</b>: **important for generating a quote from the tool.`,
          {
            html: `Select the fan you wish to update and update attributes as needed.`,
            tabOver: 1,
          },
          `<b>To grid</b>:`,

          {
            html: `Select the fan you wish to grid → Select Edit → Grid → Enter the number of additional fans to the right and below and the spacing between each.`,
            tabOver: 1,
          },
          {
            html: `Note that you can only grid fans to the right and down. Keep this in mind when placing a standard grid of fans.`,
            tabOver: 2,
          },
          `<a href='https://www.loom.com/share/750184a882e547c3b4b288b6436a8224' target='_blank'>Video showing these steps</a>`,
        ],
      },
      {
        header: `How do I add a directional fan?`,
        paragraphs: [
          `<b>Prerequisites:</b>`,
          {
            html: `You've drawn your exterior walls, interior walls, setup your roofs/mounting structures, and drawn obstructions if applicable.`,
            tabOver: 1,
          },
          `<b>Adding a pedestal fan:</b> relevant for AirGo, Black Jack, Sweat Bee, Yellow Jacket, and AirEye.`,
          {
            html: `Select a fan from the Product Menu.`,
            tabOver: 1,
          },
          {
            html: `Place it inside the facility as you would an overhead fan.`,
            tabOver: 1,
          },
          {
            html: `Use the rotation icon to change which way the fan is facing.`,
            tabOver: 1,
          },
          {
            html: `Update relevant properties as you would an overhead fan.`,
            tabOver: 1,
          },
          {
            html: `<a href='https://www.loom.com/share/276cd83eae6d44fb906877ad5cb05786' target='_blank'>Video showing these steps</a>`,
            tabOver: 1,
          },
          `<b>Attaching to a wall:</b> relevant for AirEye, Yellow Jacket, and Sweat Bee.`,
          {
            html: `Select a fan from the Product menu.`,
            tabOver: 1,
          },
          {
            html: `Move the fan to a nearby exterior wall.`,
            tabOver: 1,
          },
          {
            html: `Hint: you'll want to zoom in.`,
            tabOver: 2,
          },
          {
            html: `When your cursor is lined up with the inside of the wall then the fan will snap to the wall.`,
            tabOver: 1,
          },
          {
            html: `The green icon signals a successful attachment to the wall.`,
            tabOver: 2,
          },
          {
            html: `<a href='https://www.loom.com/share/49b641b2fca648b3a99e45c6ab68c906' target='_blank'>Video showing these steps</a>`,
            tabOver: 1,
          },
          `<b>Attaching to a column:</b> relevant for Aireye, Yellow Jacket, Sweat Bee, and Pivot.`,
          {
            html: `<b>Note:</b> Selecting a fan and toggling <b><i><u>Force Wall Mount</u></i></b> allows you to skip the below steps.`,
            tabOver: 1,
          },
          {
            html: `Make sure you have mounting structures enabled and have defined your column spacing.`,
            tabOver: 1,
          },
          {
            html: `Hint: you can hide all aspects of mounting structures besides columns via the layer panel.`,
            tabOver: 2,
          },
          {
            html: `Select a fan from the Product menu.`,
            tabOver: 1,
          },
          {
            html: `Move the fan to a nearby column.`,
            tabOver: 1,
          },
          {
            html: `Hint: you'll want to zoom in.`,
            tabOver: 2,
          },
          {
            html: `The fan will snap when your cursor is lined up to the column.`,
            tabOver: 1,
          },
          {
            html: `This green icon signals a successful attachment to the column.`,
            tabOver: 2,
          },
          {
            html: `<a href='https://www.loom.com/share/b1622b7919a74088ad282953dac7675b' target='_blank'>Video showing these steps</a>`,
            tabOver: 1,
          },
          `<b>Overhead mounted directional:</b> relevant for Yellow Jacket, Sweat Bee 30, and Pivot`,
          {
            html: `Select a fan from the Product menu.`,
            tabOver: 1,
          },
          {
            html: `Place it in the facility, define your rotation either by using the rotation handle or Rotation field in the properties menu.`,
            tabOver: 1,
          },
          {
            html: `Select Overhead Mounted in the properties menu.`,
            tabOver: 1,
          },
          {
            html: `Adjust your tilt and drop tube as needed.`,
            tabOver: 1,
          },
          {
            html: `<a href='https://www.loom.com/share/6b9c8231ebab49df804a55db18d02810' target='_blank'>Video showing these steps</a>`,
            tabOver: 1,
          },
        ],
      },
      {
        header: `What does height from floor mean for my fan?`,
        paragraphs: [
          `<b><i>Height from floor</i></b> is the height of fan blades above finished floor (AFF) based on deck height, mounting structure depth, and extension tube length.`,
        ],
      },
    ],
  },
  {
    title: `Performance Metrics`,
    articles: [
      {
        header: `Why would I want to enter the climate settings when creating a comfort zone?`,
        paragraphs: [
          `Climate settings should be entered when a customer provides you with specific design values for indoor temperature and humidity. Otherwise, use the default temperature and humidity associated with the typical use for the facility.`,
        ],
      },

      {
        header: `What is the difference between seated and standing height?`,
        paragraphs: [
          `Seated height analyzes the average of air velocities at 4", 24", and 43" above finished floor (AFF). Standing height analyzes the average at 4", 43", and 67" AFF.`,
        ],
      },
      {
        header: `What is the difference between Airflow and CFD Simulation?`,
        paragraphs: [
          `Airflow = A two-dimensional approximation of the airflow that a fan configuration will generate within a space based on interpolation between airspeed data collected at floor level in an open test facility. <b><i>This feature is less reliable than CFD Simulation and should be used only as a guideline to check the effectiveness of a fan layout before using the more accurate CFD feature to analyze the configuration.</i></b>`,
          `CFD = A three-dimensional simulation of the airflow generated by a fan layout. This is based on computed fan performance and validated with empirical data collected per AMCA 230-15. <b><i>This feature should be used to assess the effectiveness of a fan configuration.</i></b>`,
          `<b>In general, use Airflow when first laying out your fans to approximate coverage. For anything customer-facing, use CFD to verify and validate your layout.</b>`,
        ],
      },
    ],
  },
  {
    title: `Organization Best Practices`,
    articles: [
      {
        header: `What is the purpose of versions?`,
        paragraphs: [
          `Versions allow you to create a copy of your layout within the same project. The most common use case for multiple versions is when you want to compare 2 or more layouts (PFX vs PF8, 2 fans vs 1 fan, etc). `,
        ],
      },
      {
        header: `Why should I use versions instead of overwriting my model?`,
        paragraphs: [
          `SpecLab does not keep an archive of past CFD simulations for a given facility, so once a design is modified the results are gone. By creating multiple versions, you can keep a record of all designs provided for a given facility and go back to reference them as needed.`,
        ],
      },
      {
        header: `What is the purpose of areas?`,
        paragraphs: [
          `Areas allow you to simplify the modeling process for facilities with several different functional areas, or for a customer with multiple buildings at the same facility. Each area keeps its own version history, and should essentially be treated as its own separate model.`,
        ],
      },
      {
        header: `Should I ever edit another user's model?`,
        paragraphs: [
          `<b>No.</b> You should always make a copy prior to making any edits to their model. In most cases, SpecLab will not allow your changes to save when editing another user's facility unless they grant you access for this very reason.`,
        ],
      },
      {
        header: `What happens to my facilities when I leave the company?`,
        paragraphs: [
          `Navigate to the user dropdown pannel in the top right of the screen and select settings. In the settings go to 'Reassign All of your Facilities' search for a user to give your facilities to. You may want to reassign to either a speclab admin or your manager. Confirm this action by typing your email (pasting it will not work) and hit submit.`,
        ],
      },
    ],
  },
]
