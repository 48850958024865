import { Group, Matrix4, Vector3 } from "three"
import LayerKeys from "~/config/layerKeys"
import { RootState } from "~/store"
import { useAppDispatch, useAppSelector, useAppStore } from "~/store/hooks"
import { getThreeHexFromTheme } from "~/lib/utils"
import { selectObjects } from "~/store/selectedObjects"
import { DragControls, Edges } from "@react-three/drei"
import { useEffect, useRef, useState, useTransition } from "react"
import { updateDoor } from "~/store/objects"
import ObjectClassName from "~/config/objectClassNames"
import { orthogonalVectorOfWallSegment, parallelVectorOfWallSegment } from "../util/walls"
import { Door } from "~/store/objects/types"
import { useWallSegmentSnapLines } from "../hooks"
import { snapToLine } from "../util/snaplines"
import { modelToUI, uiToModel } from "../util/units"
import { SnapLine, SnapPreview } from "./SnapPreview"
import { DoorArrows } from "./DoorArrows"
import { handles } from "../CompatibilityObject3DHandles"

export function Doors() {
  const isVisible = useAppSelector(it => it.layers.layers[LayerKeys.DOORS].visible)
  const interiorWallsVisible = useAppSelector(it => it.layers.layers[LayerKeys.INTERIOR_WALLS].visible)
  const exteriorWallsVisible = useAppSelector(it => it.layers.layers[LayerKeys.EXTERIOR_WALLS].visible)
  const doors = useAppSelector(it => it.objects.present.doors)
  if (!isVisible) {
    return <></>
  }

  return (
    <>
      {Object.entries(doors).map(([key, value]) => <ADoor key={key} door={value} { ...{ interiorWallsVisible, exteriorWallsVisible, isVisible }}/>)}
    </>
  )
}

function RollbackDoorClearance(props: { door: RootState['objects']['present']['doors'][string]; thickness: number }) {
  const is3D = useAppSelector(s => s.camera.is3D)
  const nativeHeight = modelToUI(props.door.height)
  const doorWidth = modelToUI(props.door.width)
  const offset = Math.abs(nativeHeight / 2)

  return (
    <mesh position={[offset * -1, 0, offset]}>
      <boxGeometry args={[doorWidth, doorWidth, props.thickness / 2]} />
      <meshLambertMaterial color={getThreeHexFromTheme('three.objects.door.border')} transparent={true} opacity={is3D ? 1 : 0}/>
      <Edges
        linewidth={1}
        color={getThreeHexFromTheme("three.objects.door.border")}/>
    </mesh>
  )
}
function VerticalDoorClearance(props: { door: RootState['objects']['present']['doors'][string]; thickness: number }) {
  const is3D = useAppSelector(s => s.camera.is3D)
  const nativeHeight = modelToUI(props.door.height)
  const doorWidth = modelToUI(props.door.width)
  const offset = Math.abs(props.thickness / 2)

  return (
    <mesh position={[offset * -1, 0, nativeHeight]}>
      <boxGeometry args={[props.thickness / 2, doorWidth, nativeHeight]} />
      <meshLambertMaterial color={getThreeHexFromTheme('three.objects.door.border')} transparent={true} opacity={is3D ? 1 : 0}/>
      <Edges
        linewidth={1}
        color={getThreeHexFromTheme("three.objects.door.border")}/>
    </mesh>
  )
}
function ADoor(props: { door: Door; interiorWallsVisible: boolean; exteriorWallsVisible: boolean; isVisible: boolean }) {
  const { position: { x, y }, width, height, wallSegmentId, doorType, id } = props.door
  const wallSegment = useAppSelector(it => it.objects.present.segments[wallSegmentId])
  const dispatch = useAppDispatch()
  const selected = useAppSelector(it => it.selectedObjects.some((sel: { id: string }) => sel.id === id))
  const is3D = useAppSelector(it => it.camera.is3D)
  const [isDragging, setDragging] = useState(false)
  const snaplines = useWallSegmentSnapLines()
  const [deltaAccumulator, setDeltaAccumulator] = useState([0, 0] as [number, number])
  const [isPending, startTransition] = useTransition()
  const dragControls = useRef<Group>(null!)
  useEffect(() => {
    dragControls.current.matrix = new Matrix4()
  }, [x, y])
  const snapPoint = isDragging ? snapToLine(snaplines, new Vector3(modelToUI(x) + deltaAccumulator[0], modelToUI(y) + deltaAccumulator[1], 0)) : undefined

  const thickness = wallSegment.thickness

  const store = useAppStore();
  const doorX = modelToUI(x)
  const doorY = modelToUI(y)
  const doorZ = modelToUI(height) / 2

  const doorWidth = modelToUI(width)
  const doorHeight = modelToUI(height)
  const doorThickness = modelToUI(thickness) + 0.2

  const color =
    isDragging ?
      getThreeHexFromTheme('three.valid') :
      selected ?
        getThreeHexFromTheme('three.objects.door.selected') :
        getThreeHexFromTheme('three.objects.door.default')

  const rotation = orthogonalVectorOfWallSegment(wallSegment)

  const visible = props.isVisible && !(wallSegment.layerKey === LayerKeys.EXTERIOR_WALLS && !props.exteriorWallsVisible) && !(wallSegment.layerKey === LayerKeys.INTERIOR_WALLS && !props.interiorWallsVisible)

  return (
    <object3D ref={handles.ref(id)} onClick={() => dispatch(selectObjects({ objects: [{ ...props.door, className: ObjectClassName.DOOR }] }))} visible={visible}>
      {selected && !isDragging && <DoorArrows door={props.door} wallSegment={wallSegment}/>}
      <DragControls
        axisLock="z"
        ref={dragControls}
        onDragStart={() => {
          setDragging(true)
        }}
        onDrag={(_, deltaMatrix) => {
          const position = new Vector3()
          position.setFromMatrixPosition(deltaMatrix)
          startTransition(() => {
            setDeltaAccumulator(([x, y]) => [x + position.x, y + position.y])
          })
        }}
        onDragEnd={() => {
          setDragging(false)
          if (snapPoint !== undefined) {
            const wallSegment = store.getState().objects.present.segments[snapPoint.objectID]
            const rotation = parallelVectorOfWallSegment(wallSegment)
            dispatch(updateDoor({
              door: {
                id,
                position: { x: uiToModel(snapPoint.snapPoint.x), y: uiToModel(snapPoint.snapPoint.y), z: 0 },
                wallSegmentId: snapPoint.objectID,
                rotation,
              },
            }))
          }
          setDeltaAccumulator([0, 0])
        }}
      >
        <group visible={!isDragging}>
          <mesh position={[doorX, doorY, doorZ]} rotation={rotation}>
            <boxGeometry args={[doorThickness, doorWidth, doorHeight]}/>
            <meshLambertMaterial color={color}/>
          </mesh>
          {!is3D && (
            <mesh position={[doorX, doorY, modelToUI(wallSegment.height)]} rotation={rotation}>
              <boxGeometry args={[doorThickness, doorWidth, 1]}/>
              <meshLambertMaterial color={color}/>
            </mesh>
          )}
        </group>
      </DragControls>
      {snapPoint && (
        <SnapPreview snapPoint={snapPoint} door={props.door}/>
      )}
      {snapPoint && (
        <SnapLine snapPoint={snapPoint}/>
      )}
      <object3D position={[doorX, doorY, doorZ]} rotation={rotation} visible={!isDragging && doorType === 'rollbackDoor'}>
        <RollbackDoorClearance door={props.door} thickness={doorThickness}/>
      </object3D>
      <object3D position={[doorX, doorY, doorZ]} rotation={rotation} visible={!isDragging && doorType === 'verticalDoor'}>
        <VerticalDoorClearance door={props.door} thickness={doorThickness}/>
      </object3D>
    </object3D>
  )
}