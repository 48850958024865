import { graphql } from '~/gql'

const VERSION_FRAGMENT = graphql(`
  fragment VersionFragment on Version {
    id
    name
    data
    quote {
      id
      salesforceQuoteId
    }
    cfd {
      id
      URLs {
        id
        url
        isArchived
        fileExtension
        fileProps
        timestamp
        lastModified
      }
      objURL {
        id
        url
        isArchived
        timestamp
        lastModified
      }
      status
      availableDates
      type
      internalType
    }
    createdAt
    updatedAt
  }
`)

export default VERSION_FRAGMENT
