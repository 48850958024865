import React from 'react'
import PropTypes from 'prop-types'

import H1 from 'components/UIKit/H1'
import Button from 'components/UIKit/Button'
import Space from 'components/UIKit/Space'

import Container from './styled/Container'

const PermissionDeniedRoute = (props = {}) => (
  <Container flush={props.flush}>
    <Space bottom="base">
      <H1>Not Allowed</H1>
      <p>{props.message}</p>
    </Space>
    <Button primary to="/" label="Go Home" />
  </Container>
)

PermissionDeniedRoute.defaultProps = {
  message: 'Sorry. You do not have the permissions required to view this.',
}

PermissionDeniedRoute.propTypes = {
  flush: PropTypes.bool,
  message: PropTypes.string,
}

export default PermissionDeniedRoute
