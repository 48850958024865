import { graphql } from '~/gql'

const APP_SETTINGS_FRAGMENT = graphql(`
  fragment AppSettingsFragment on AppSettings {
    isUnderMaintenance
    uiVersion
  }
`)

export default APP_SETTINGS_FRAGMENT
