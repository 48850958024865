import React, { FC, MouseEventHandler, PropsWithChildren, ReactNode, useState } from 'react'
import styled from 'styled-components'

import theme from 'config/theme'

import A from '../A'
import Space from '../Space'

import Icon from './styled/Icon'
import Flex from './styled/Flex'

const IconContainer = styled.div`
  cursor: pointer;
`

type CollapsibleProps = {
  fullWidth?: boolean
  marginTop?: number
  noBorder?: boolean
  renderTrigger?: FC<{ onTrigger: MouseEventHandler<HTMLElement>; isVisible: boolean }>
  swapDirection?: boolean
  trigger?: ReactNode
  withCaret?: boolean
  flushBottom?: boolean
  iconSize?: string
}

export default function Collapsible(props: PropsWithChildren<CollapsibleProps>) {
  const [isVisible, setIsVisible] = useState(false)
  const {
    fullWidth,
    marginTop,
    noBorder,
    renderTrigger,
    swapDirection,
    trigger,
    withCaret,
    children,
    flushBottom,
    iconSize,
  } = props

  const handleClick: MouseEventHandler<HTMLElement> = event => {
    event.preventDefault()
    setIsVisible(it => !it)
  }
  const generateTrigger = () => {
    return (
      <Flex
        fullWidth={fullWidth}
        swapDirection={swapDirection}
      >
        {withCaret && (
          <IconContainer onClick={handleClick}>
            {/*
            FIXME: this extra div is needed for some reason
                   in order for the flexbox to work correctly
          */}
            <Icon
              name="arrowRight"
              size={iconSize ?? '14'}
              color={
                renderTrigger
                  ? theme.colors.light.subdued
                  : theme.colors.light.light
              }
              marginTop={marginTop ? marginTop.toString() : '0'}
              isVisible={isVisible}
            />
          </IconContainer>
        )}
        <div>
          {/*
            FIXME: this extra div is needed for some reason
                   in order for the flexbox to work correctly
          */}
          {renderTrigger ? (
            renderTrigger({
              onTrigger: handleClick,
              isVisible,
            })
          ) : (
            <A href="#" noBorder={noBorder} onClick={handleClick}>
              {trigger}
            </A>
          )}
        </div>
      </Flex>
    )
  }

  return (
    <>
      <Space bottom={flushBottom ? undefined : 'base'}>
        {generateTrigger()}
      </Space>
      {isVisible && children}
    </>
  )
}
