import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { appConnect } from "~/store/hooks";
import { object, func, string, bool, any, oneOf } from 'prop-types'
import { motion } from "framer-motion"
import omit from 'lodash-es/omit'

import theme from 'config/theme'

import { getDockedPanelWidth } from 'lib/utils'

import { togglePanel, hidePanel } from 'store/panel/actions'
import {
  DEFAULT_SELECTED_PANEL,
  GET_QUOTE_PANEL,
  CONTROLS_PANEL,
} from 'store/panel/types'
import { deselectObjects } from 'store/selectedObjects/actions'

import { PANEL_TITLE as ITEM_PANEL_TITLE } from '../../Panels/DockItemPanel'
import { PANEL_TITLE as LAYERS_PANEL_TITLE } from '../../Panels/LayersPanel'
import Icon from '../Icon'

import Card from './styled/Card'
import Close from './styled/Close'
import Container from './styled/Container'
import Content from './styled/Content'
import Footer from './styled/Footer'
import Header from './styled/Header'
import HelpLink from './styled/HelpLink'
import Mask from './styled/Mask'
import Title from './styled/Title'
import TitleContainer from './styled/TitleContainer'
import Description from './styled/Description'
import DropdownIcon from '../DropdownIcon'

export class Panel extends Component {
  state = {
    isVisible: false,
    responsiveWidth: getDockedPanelWidth(),
    minimized: false,
  }

  componentDidMount() {
    this.resizeWindow()
    window.addEventListener('orientationchange', this.handlePanelResize)
  }

  componentWillUnmount() {
    this.resizeWindow()
    window.removeEventListener('orientationchange', this.handlePanelResize)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.type && nextProps.type === this.props.title) {
      this.setState({
        isVisible: true,
      })
    } else {
      this.setState({
        isVisible: false,
      })
    }
  }

  get cardProps() {
    const {
      alignment,
      landscape,
      renderTrigger,
      flushLeft,
      flushRight,
      docked,
      width,
      closeOnDocumentClick,
      stretchContainer,
      zIndex,
      isTouchUI,
      ...props
    } = this.props
    const { responsiveWidth, active } = this.state
    return {
      active,
      alignment,
      landscape,
      renderTrigger,
      responsiveWidth,
      flushLeft,
      flushRight,
      docked,
      width,
      closeOnDocumentClick,
      stretchContainer,
      isTouchUI,
      zIndex,
      ...omit(props, ['onHidePanel', 'onTogglePanel', 'title']),
    }
  }

  setAnimation = () => {
    const { alignment, landscape, docked, renderTrigger } = this.props
    const animations = {
      left: {
        initial: {opacity: 0, x: -20},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: -20},
      },
      right: {
        initial: {opacity: 0, x: 20},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: 20},
      },
      bottom: {
        initial: {opacity: 0, y: 20},
        animate: {opacity: 1, y: 0},
        exit: {opacity: 0, y: 20},
      },
    }

    if (docked) {
      return {}
    }

    if (landscape || renderTrigger) {
      return animations.bottom
    }

    if (!alignment) {
      return animations.left
    }

    return animations[alignment]
  }

  resizeWindow = () => {
    const { docked } = this.props

    // FIXME: We don't want this to run every time the panel is triggered!
    docked && window.dispatchEvent(new Event('resize'))
  }

  handlePanelResize = () => {
    const responsiveWidth = getDockedPanelWidth()

    this.setState({ responsiveWidth })
  }

  handleMaskClick = event => {
    const { panelKey, onHidePanel } = this.props

    onHidePanel(panelKey)
  }

  handleMaskContextMenu = event => {
    event.preventDefault()
  }

  handleClick = event => {
    const {
      panelKey,
      onTogglePanel,
      deselectObjects,
      landscape,
      isTouchUI,
      title,
    } = this.props

    event.preventDefault()

    // Deselect objects and return if closing
    // a selected item panel in touch mode
    if (isTouchUI && !landscape && title !== LAYERS_PANEL_TITLE) {
      deselectObjects()
      return
    }

    if (panelKey === GET_QUOTE_PANEL) {
      onTogglePanel(panelKey)
      onTogglePanel(DEFAULT_SELECTED_PANEL)
    } else {
      onTogglePanel(panelKey)
    }
  }

  minimize = () => {
    this.setState({ minimized: !this.state.minimized })
  }

  handleAnimationEnd = () => {
    const { renderTrigger, onHidePanel, panelKey } = this.props

    if (renderTrigger) return

    onHidePanel(panelKey)
  }

  render() {
    const {
      back,
      canMinimize,
      children,
      closeOnDocumentClick,
      collapsible,
      description,
      docked,
      footer,
      hasToolbar,
      helpUrl,
      isTouchUI,
      match,
      renderTrigger,
      scrollable,
      stretchContainer,
      title,
      panelKey,
    } = this.props
    const { isVisible } = this.state
    // The non-trigger panel needs to be shown by default.
    const shouldShowPanel = renderTrigger ? isVisible : !isVisible
    const shouldShowClose = title !== ITEM_PANEL_TITLE
    const arrow = isTouchUI ? 'caretLeft' : 'arrowRight'

    return (
      <>
        <Container renderTrigger={renderTrigger}>
          {renderTrigger && (
            <Container role="presentation" onClick={this.handleClick}>
              {renderTrigger({ isVisible })}
            </Container>
          )}
          <>
            {shouldShowPanel && (
              <Card {...this.cardProps} {...this.setAnimation()} key="card">
                {title && (
                  // Exclude controls panel from Touch UI styling here to keep it as small as possible
                  <Header
                    docked={docked}
                    onClick={
                      (isTouchUI && canMinimize && this.minimize) || null
                    }
                    isTouchUI={isTouchUI && !panelKey !== CONTROLS_PANEL}
                  >
                    <TitleContainer>
                      {isTouchUI && canMinimize && (
                        <DropdownIcon
                          flush
                          onClick={this.minimize}
                          marginRight={'s'}
                          reverse={this.state.minimized}
                          size={20}
                        />
                      )}
                      <Title
                        isTouchUI={isTouchUI && panelKey !== CONTROLS_PANEL}
                      >
                        {back ? back : title}{' '}
                        {helpUrl && (
                          <HelpLink to={`${match.url}${helpUrl}`}>
                            <Icon
                              name="help"
                              size="12"
                              color={theme.colors.light.fg}
                            />{' '}
                            What&apos;s this?
                          </HelpLink>
                        )}
                      </Title>
                      {shouldShowClose && (
                        <Close
                          href=""
                          marginLeft={'s'}
                          onClick={this.handleClick}
                        >
                          {docked && collapsible && !isTouchUI ? (
                            <Icon name={arrow} color={theme.colors.light.fg} />
                          ) : (
                            <Icon name="cross" color={theme.colors.light.fg} />
                          )}
                        </Close>
                      )}
                    </TitleContainer>
                    {description && <Description>{description}</Description>}
                  </Header>
                )}
                <Content
                  hasFooter={footer ? true : false}
                  minimized={this.state.minimized}
                  hasToolbar={hasToolbar}
                  scrollable={scrollable}
                  stretchContainer={stretchContainer}
                >
                  {children}
                </Content>
                {footer && <Footer>{footer}</Footer>}
              </Card>
            )}
          </>
        </Container>
        {closeOnDocumentClick && shouldShowPanel && (
          <Mask
            onClick={this.handleMaskClick}
            onContextMenu={this.handleMaskContextMenu}
          />
        )}
      </>
    )
  }
}

Panel.propTypes = {
  alignment: string,
  back: any,
  canMinimize: bool,
  children: any,
  closeOnDocumentClick: bool,
  collapsible: bool,
  description: string,
  deselectObjects: func,
  disabled: bool,
  docked: bool,
  flushLeft: bool,
  flushRight: bool,
  footer: any,
  hasToolbar: bool,
  helpUrl: string,
  hidePanel: func,
  isTouchUI: bool,
  landscape: bool,
  match: object,
  onHidePanel: func,
  onTogglePanel: func,
  panelKey: string,
  renderTrigger: func,
  scrollable: bool,
  stretchContainer: bool,
  title: string,
  togglePanel: func,
  type: string,
  width: string,
  zIndex: oneOf(theme.order),
}

Panel.defaultProps = {
  collapsible: true,
}

const mapStateToProps = ({ userInterface }) => ({
  isTouchUI: userInterface.isTouchUI,
})

const mapDispatchToProps = dispatch => ({
  onHidePanel(type) {
    dispatch(hidePanel({ type }))
  },
  onTogglePanel(type) {
    dispatch(togglePanel({ type }))
  },
  deselectObjects() {
    dispatch(deselectObjects({}))
  },
})

export default appConnect(mapStateToProps, mapDispatchToProps)(withRouter(Panel))
