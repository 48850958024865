import get from 'lodash-es/get'
import { VTKLoader } from 'components/DrawingCanvas/lib/VTKLoader'

import { showAlert } from '../alert'

const ROOT = 'CFD'

export const UPLOAD_OBJ = `${ROOT}/UPLOAD_OBJ`
export const UPLOAD_COMPLETE = `${ROOT}/UPLOAD_COMPLETE`
export const UPLOAD_OBJ_FAILED = `${ROOT}/UPLOAD_OBJ_FAILED`
export const ABORT_UPLOAD_OBJ = `${ROOT}/ABORT_UPLOAD_OBJ`
export const UPLOAD_OBJ_ABORTED = `${ROOT}/UPLOAD_OBJ_ABORTED`
export const NOTIFY_UPLOAD_OBJ_PROGRESS = `${ROOT}/NOTIFY_UPLOAD_OBJ_PROGRESS`
export const CANCEL_JOB = `${ROOT}/CANCEL_JOB`
export const JOB_CANCELLED = `${ROOT}/JOB_CANCELLED`
export const OBJ_FILE_FILENAME = 'model.obj'
export const UPDATE_CFD = 'UPDATE_CFD'
export const SET_CFD_LAYER = 'SET_CFD_LAYER'
export const SET_VTK_DATA = 'SET_VTK_DATA'
export const GET_VTK_DATA = 'GET_VTK_DATA'
export const GET_VTK_DATA_STARTED = 'GET_VTK_DATA_STARTED'

const notifyUploadObjAborted = type => ({
  type: UPLOAD_OBJ_ABORTED,
  payload: { type },
})

export const getVTKData = ({ level, type, url, goal }) => async (
  dispatch,
  getState
) => {
  try {
    const model = get(getState(), `cfd.models.${goal}.${type}.${level}`)
    if (!model || (!model.isValid && !model.isLoading)) {
      dispatch(
        getVTKDataStarted({
          level,
          type,
          goal,
        })
      )
      // TODO: Fetch either the geometry or the
      // necessary arrays to build the geometry
      // const geometry = await Client.query({
      //   query: GET_VTK_DATA,
      //   variables: { url: url.url },
      // })
      const cacheKey = url && `cfd-${url}`
      const loader = new VTKLoader()
      loader.load(url, cacheKey, geometry => {
        dispatch(
          setVTKData({
            level,
            type,
            goal,
            url,
            geometry,
          })
        )

        loader.terminate()
      })
    }
  } catch (err) {
    dispatch(
      showAlert({
        text: get(err, 'message', 'Error loading VTK data.'),
        type: 'error',
      })
    )
  }
}

export const getVTKDataStarted = ({ level, type, goal }) => ({
  type: GET_VTK_DATA_STARTED,
  payload: {
    level,
    type,
    goal,
  },
})

export const setVTKData = ({ level, type, url, geometry, goal }) => ({
  type: SET_VTK_DATA,
  payload: {
    level,
    type,
    url,
    geometry,
    goal,
  },
})

export const setCFDSelectedLayer = ({ level, type, url, goal }) => ({
  type: SET_CFD_LAYER,
  payload: {
    level,
    goal,
    type,
    url,
  },
})

export const setCFDLayer = ({ level, type, url, goal }) => dispatch => {
  dispatch(getVTKData({ level, type, url, goal }))
  dispatch(
    setCFDSelectedLayer({
      level,
      type,
      url,
      goal,
    })
  )
}

export const updateCFD = payload => ({
  type: UPDATE_CFD,
  payload,
})

export const abortAnalyzeObj = () => dispatch => {
  dispatch({
    type: ABORT_UPLOAD_OBJ,
  })
  activeCanceller.cancel()
  dispatch(notifyUploadObjAborted())
}

