import React from 'react'
import { Helmet } from 'react-helmet'

import { getTitle } from 'config/titles'

import Dashboard from 'components/Dashboard'
import Templates from 'components/Templates'

/**
 * main page for templates, with searchable list of templates and edit facility modal
 * @returns Facilities Screen
 */
const TemplatesScreen = () => (
  <>
    <Helmet>
      <title>{getTitle('templates')}</title>
    </Helmet>
    <Dashboard>
      <Templates />
    </Dashboard>
  </>
)

// TODO: comment required in both places to trick intellisense
/**
 * main page for templates, with searchable list of templates and edit facility modal
 * @returns Facilities Screen
 */
export default TemplatesScreen
