import { PerformanceMetricsContext } from '../subcomponents/PerformanceMetricsControls'
import { useContext } from 'react'

/**
 * @typedef {object} PerformanceMetricsProps
 * @prop {import ("hooks/useFacilityDefaults").facilityDetailDefaults} facility
 * @prop {object} areaOfConcernOptions
 * @prop {object} areaOfConcernEdges
 * @prop {object} vtkFileOptions
 * @prop {object} selectedVtkFiles
 * @returns {PerformanceMetricsProps}
 */
export const usePerformanceMetricsProps = () => {
  const props = useContext(PerformanceMetricsContext)
  if (props === null) throw new Error('usePerformanceMetricsProps must be used within context')
  return props
}
