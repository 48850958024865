import type { ChangeEventHandler, ComponentProps } from 'react'
import { useNetwork } from 'networkProvider'

import InputContainer from './InputContainer'
import PaddedLabel from './styled/PaddedLabel'

export default function Checkbox({
  id,
  name,
  label = '',
  value = '',
  checked = false,
  onChange,
  disabled,
  externalLabel,
  notBold,
  ...props
}: {
  id?: string
  name?: string
  label?: string
  value?: string
  checked?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  externalLabel?: boolean
  notBold?: boolean
} & ComponentProps<"div">) {
  const network = useNetwork()
  const inputProps = {
    checked,
    disabled: disabled || !network.online,
    id,
    label,
    name,
    onChange,
    value,
    notBold,
  }

  if (externalLabel) {
    return (
      <div {...props}>
        <InputContainer {...inputProps as any} online={network.online} disabled={inputProps.disabled} />
      </div>
    )
  }

  return (
    <div {...props}>
      <PaddedLabel
        notBold={inputProps.notBold}
        htmlFor={id || name}
        disabled={inputProps.disabled}
      >
        <InputContainer {...inputProps as any} online={network.online} disabled={inputProps.disabled}/>
      </PaddedLabel>
    </div>
  )
}
