import { graphql } from '~/gql'

const CREATE_USER_MUTATION = graphql(`
  mutation CreateUser($idToken: String!, $name: String!, $email: String!) {
    createUser(
      authProvider: { auth0: { idToken: $idToken } }
      name: $name
      email: $email
    ) {
      ...UserFragment
    }
  }
`)

export default CREATE_USER_MUTATION
