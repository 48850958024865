export enum MeasurementSystem {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC',
  NATIVE = 'NATIVE',
}

export enum UnitTypes {
  DISTANCE = 'DISTANCE',
  VELOCITY = 'VELOCITY',
  TEMPERATURE = 'TEMPERATURE',
}

export { UnitTypes as TYPES, MeasurementSystem as SYSTEMS }
