import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Actions = styled.div`
  display: flex;
  justify-content: center;

  > *:first-child {
    margin-right: ${theme('spacing.base')};
  }
`

export default Actions
