import React from 'react'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import PropTypes from 'prop-types'

import { setAirflowLayer } from 'store/objects'
import { AIRFLOW_PANEL } from 'store/panel/types'
import { Distance } from 'store/units/types'
import { withUnits } from 'store/units/decorators'
import { setLoadingStatus } from 'store/status'
import { toggleLayerVisibility } from 'store/layers'
import { isTouchUI } from 'store/userInterface/selectors'

import { AIRFLOW_EVALUATION_POINTS } from 'lib/airflow/airflow'
import { updateAirflow } from 'lib/airflow/airflow'

import routes from 'config/routes'
import LAYER_KEYS from 'config/layerKeys'

import Panel from 'components/UIKit/Panel'
import Select from 'components/UIKit/Select'
import Switch from 'components/UIKit/Switch'

import Controls from './styled/Controls'
import Control from './styled/Control'
import Grid from './styled/Grid'
import Label from './styled/Label'

import FPMLegend from '../../FPMLegend'
import { SYSTEMS } from 'store/units/constants'
import {
  HIGH_VELOCITY_COLORS,
  LOW_VELOCITY_COLORS,
} from 'components/DrawingCanvas/lib/cfd'
import withFacility from 'client/decorators/withFacility'
import { primaryUseVelocityTypes } from 'config/facility'
import { get } from 'lodash-es'

const AirflowPanel = ({
  selectedLayer,
  onUpdateAirflowLayer,
  distanceUnits,
  layers,
  onUpdateAirflow,
  products,
  objects,
  segments,
  obstructions,
  airflowValid,
  onToggleVisibility,
  isTouchUI,
  facility,
}) => {
  let airflowVelocities = HIGH_VELOCITY_COLORS
  const primaryUse = get(facility, 'primaryUse')
  if (primaryUse === 'OTHER') {
    const primaryType = get(facility, 'primaryType')
    if (primaryType === 'RESIDENTIAL' || primaryType === 'COMMERCIAL') {
      airflowVelocities = LOW_VELOCITY_COLORS
    }
  } else if (primaryUseVelocityTypes.low.includes(primaryUse)) {
    airflowVelocities = LOW_VELOCITY_COLORS
  }

  return (
    <Panel
      title="Airflow"
      helpUrl={routes.help.airflow}
      landscape
      panelKey={AIRFLOW_PANEL}
    >
      <Grid>
        <Controls>
          {isTouchUI && (
            <Control>
              <Label>Airflow Toggle</Label>
              <Switch
                name="airflow"
                isChecked={layers.AIRFLOW.visible}
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  if (!layers.AIRFLOW.visible && !airflowValid) {
                    onUpdateAirflow({
                      products,
                      objects,
                      segments,
                      obstructions,
                    })
                  }
                  onToggleVisibility({ layerKey: LAYER_KEYS.AIRFLOW })
                }}
              />
            </Control>
          )}
          <Control>
            <Select
              disabled
              inline
              labelWidth="200px"
              name="select"
              label="View"
              value="Basic Airflow"
              options={[
                {
                  label: 'Basic Airflow',
                  value: 'Basic Airflow',
                },
              ]}
              onChange={event => {
                return
              }}
            />
          </Control>
          <Control>
            <Select
              disablePlaceholder
              inline
              labelWidth="200px"
              name="select"
              label="Evaluation Height"
              onChange={event => {
                onUpdateAirflowLayer(
                  AIRFLOW_EVALUATION_POINTS[event.target.value]
                )
              }}
              options={AIRFLOW_EVALUATION_POINTS.map((inchesValue, i) => {
                const value = new Distance({
                  value: inchesValue,
                  system: SYSTEMS.IMPERIAL,
                })
                return {
                  label: value.formattedValue(distanceUnits, {
                    both: true,
                    round: true,
                    roundCentimeters: true,
                  }),
                  value: i,
                }
              })}
              value={AIRFLOW_EVALUATION_POINTS.indexOf(parseInt(selectedLayer))}
            />
          </Control>
        </Controls>
        <FPMLegend
          options={{
            colors: airflowVelocities.map(c => c.color),
            xLabels: airflowVelocities.map(c => c.velocity),
            yLabels: ['Warm', 'Cool'],
          }}
        />
      </Grid>
    </Panel>
  )
}

AirflowPanel.propTypes = {
  airflowValid: PropTypes.bool,
  distanceUnits: PropTypes.string,
  isTouchUI: PropTypes.bool,
  layers: PropTypes.object,
  objects: PropTypes.object,
  obstructions: PropTypes.object,
  onToggleVisibility: PropTypes.func,
  onUpdateAirflow: PropTypes.func,
  onUpdateAirflowLayer: PropTypes.func,
  products: PropTypes.object,
  segments: PropTypes.object,
  selectedLayer: PropTypes.string,
  facility: PropTypes.object,
}

const mapStateToProps = ({ objects, layers, ...store }) => {
  let selectedLayer = '67'

  const airflow = objects.present.airflow
  if (airflow && airflow.selectedLayer) {
    selectedLayer = airflow.selectedLayer.evaluationHeight.toString()
  }

  return {
    airflowValid: objects.present.airflow.isValid,
    isTouchUI: isTouchUI(store),
    layers: layers.layers,
    objects: objects.present.objects,
    obstructions: objects.present.obstructions,
    products: objects.present.products,
    segments: objects.present.segments,
    selectedLayer,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateAirflowLayer(layerKey) {
    dispatch(setAirflowLayer({ layerKey }))
  },
  onUpdateAirflow({ products, objects, segments, obstructions }) {
    updateAirflow(dispatch, { products, objects, segments, obstructions })
  },
  onToggleVisibility({ layerKey }) {
    // Only setting the loading status for CFD since airflow is handled
    // directly within its async function.
    layerKey === LAYER_KEYS.CFD && dispatch(setLoadingStatus())
    dispatch(toggleLayerVisibility({ layerKey }))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withUnits,
  withFacility
)(AirflowPanel)
