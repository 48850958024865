import PropTypes from 'prop-types'

import Container from './styled/Container'

const ToolbarGroup = Container

ToolbarGroup.propTypes = {
  alignment: PropTypes.string,
  flexGrow: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
}

export default /** @type any */(ToolbarGroup)
