import React, { Component } from 'react'
import { object, func, string } from 'prop-types'
import { appConnect } from "~/store/hooks";
import * as THREE from 'three'

import store from 'store'
import { updateRoofStructure } from 'store/objects'
import { setActiveTool } from 'store/tools'
import { Distance } from 'store/units/types'
import { getDistanceUnits } from 'store/units/selectors'
import { SYSTEMS } from 'store/units/constants'
import { onCreateGableRoof } from 'config/analytics'
import { trackEvent } from 'lib/analytics'
import RoofToolUtils from 'components/DrawingCanvas/lib/roofToolUtil'
import Facility from 'components/DrawingCanvas/lib/facility'

import Button from 'components/UIKit/Button'
import DimensionInput from 'components/UIKit/DimensionInput'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Select from 'components/UIKit/Select'
import Space from 'components/UIKit/Space'

import RectangleIcon from '../../RectangleIcon'

class GableScreen extends Component {
  state = {
    height: null,
    offset: null,
    peak: 'horizontal',
    type: 'centered',
  }

  get areFieldsValid() {
    const { peak, height } = this.state

    return peak !== '' && height !== null
  }

  get typeOptions() {
    const { peak } = this.state
    const options = [{ label: 'Centered', value: 'centered' }]

    if (peak === 'horizontal') {
      options.push({ label: 'Offset from Top Wall', value: 'top' })
      options.push({ label: 'Offset from Bottom Wall', value: 'bottom' })
    } else {
      options.push({ label: 'Offset from Left Wall', value: 'left' })
      options.push({ label: 'Offset from Right Wall', value: 'right' })
    }
    return options
  }

  componentDidMount() {
    if (!this.state.height) {
      const peakHeight = 120 // 10'
      const defaultPeakOffset = 48 // 4'
      this.setState({
        height: new Distance({
          value: Facility.current.getWallHeight() + peakHeight,
          system: SYSTEMS.IMPERIAL,
        }),
        offset: new Distance({
          value: defaultPeakOffset,
          system: SYSTEMS.IMPERIAL,
        }),
      })
    }
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    const { height, offset, peak, type } = this.state
    const { history, onUpdateRoofStructure, parentRoute } = this.props
    const wallWithRoof = Facility.current.getWalls().find(wall => wall.roofId)
    const nativeHeight = height.native()
    const nativeOffset = offset.native()
    const isHorizontal = peak === 'horizontal'

    if (wallWithRoof) {
      const model = RoofToolUtils.getGableRoofModel(
        wallWithRoof.roofId,
        isHorizontal,
        nativeHeight,
        wallWithRoof.id,
        type,
        nativeOffset
      )
      onUpdateRoofStructure(model)
    }

    history.push(parentRoute)
  }

  render() {
    const { height, offset, peak, type } = this.state
    const distanceUnits = getDistanceUnits(store.getState())
    const box = new THREE.Box3().setFromObject(Facility.current.obj3d)
    const facilitySize = {
      x: Math.abs(box.min.x - box.max.x),
      y: Math.abs(box.min.y - box.max.y),
    }

    return (
      <Grid vCenter>
        <GridItem size="1of2">
          <form onSubmit={this.handleSubmit}>
            <Space bottom="base">
              <h2>Gable</h2>
            </Space>
            <Space bottom="l">
              <Space bottom="base">
                <Select
                  inline
                  labelWidth="80px"
                  size="125px"
                  name="peak"
                  label="Peak"
                  value={peak}
                  tabIndex={1}
                  onChange={event =>
                    this.setState({
                      type: 'centered',
                      peak: event.target.value,
                    })
                  }
                  options={[
                    { label: 'Horizontal', value: 'horizontal' },
                    { label: 'Vertical', value: 'vertical' },
                  ]}
                />
              </Space>
              <Space bottom="base">
                <DimensionInput
                  name="height"
                  label="Peak Height"
                  labelWidth="80px"
                  distance={height}
                  tabIndex={2}
                  units={distanceUnits}
                  onChange={({ distance }) => {
                    this.handleChange({
                      name: 'height',
                      value: distance,
                    })
                  }}
                />
              </Space>
              <Space bottom="base">
                <Select
                  inline
                  labelWidth="80px"
                  size="125px"
                  name="location"
                  label="Location"
                  value={type}
                  tabIndex={1}
                  onChange={event =>
                    this.handleChange({
                      name: 'type',
                      value: event.target.value,
                    })
                  }
                  options={this.typeOptions}
                />
              </Space>
              {type !== 'centered' && (
                <Space bottom="base">
                  <DimensionInput
                    name="offset"
                    label="Offset"
                    labelWidth="80px"
                    distance={offset}
                    tabIndex={3}
                    units={distanceUnits}
                    onChange={({ distance }) => {
                      this.handleChange({
                        name: 'offset',
                        value: distance,
                      })
                    }}
                  />
                </Space>
              )}
            </Space>
            <Button
              primary
              label="Create Gable Roof"
              type="submit"
              tabIndex={4}
              disabled={!this.areFieldsValid}
              onClick={() => {
                trackEvent(onCreateGableRoof())
                this.props.onSetActiveTool({ tool: 'SELECT_TOOL' })
              }}
            />
          </form>
        </GridItem>
        <GridItem size="1of2">
          <RectangleIcon
            facilitySize={facilitySize}
            peak={peak}
            side={type}
            offset={(offset && offset.native()) || 0}
          />
        </GridItem>
      </Grid>
    )
  }
}

GableScreen.propTypes = {
  history: object,
  onUpdateRoofStructure: func,
  onSetActiveTool: func,
  parentRoute: string,
}

const mapStateToProps = ({ objects }) => {
  return { objects }
}

const mapDispatchToProps = dispatch => ({
  onUpdateRoofStructure(model) {
    dispatch(updateRoofStructure(model))
  },
  onSetActiveTool({ tool }) {
    dispatch(setActiveTool({ tool }))
  },
})

export default appConnect(mapStateToProps, mapDispatchToProps)(GableScreen)
