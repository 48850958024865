import styled, { css } from 'styled-components'

import { theme } from 'lib/styleUtils'
import { getZIndexOf } from 'lib/utils'

const setDisplay = ({ isVisible }) => css`
  display: ${isVisible ? 'block' : 'none'};
`

const Container = styled.div`
  background-color: ${theme('colors.light.bg')};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  ${setDisplay}
  z-index: ${getZIndexOf('dropdown')};
`

export default Container
