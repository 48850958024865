import React from 'react'

import logo from 'images/logo.svg'

import H1 from 'components/UIKit/H1'
import Image from 'components/UIKit/Image'
import Loader from 'components/UIKit/Loader'
import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'

import Container from './styled/Container'

const MaintenanceScreen = () => (
  <Container>
    <Space bottom="xl">
      <Image src={logo} alt="Big Ass Fans" width="500" />
    </Space>
    <H1>
      <Loader label="" inline />
      We are doing some maintenance&hellip;
    </H1>
    <VariantText size="l">
      We apologize for the inconvenience, but we are currently working on a
      little bit of maintenance so the application runs nice and smooth. Please
      check back.
    </VariantText>
  </Container>
)

export default MaintenanceScreen
