import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Control = styled.div`
  display: flex;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: ${theme('spacing.base')};
  }
`

export default Control
