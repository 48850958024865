import * as THREE from 'three'
import sortBy from 'lodash-es/sortBy'
import has from 'lodash-es/has'
import get from 'lodash-es/get'
import Units from './units'
import Util from './util'
import Primitives from './primitives'
import store from '~/store'
import { clearStatus, setStatus } from 'store/status'
import { objectIsSelected } from 'store/selectedObjects/selectors'
import { activeTool } from 'store/tools/selectors'
import Facility from './facility'
import OBJECT_TYPES from 'config/objectTypes'
import CLASS_NAMES from 'config/objectClassNames'
import { getThreeHexFromTheme } from 'lib/utils'
import { orthogonalVectorOfWallSegment } from '../util/walls'

const RAYCAST_THRESHOLD = 120

class productDistance {
  constructor(options) {
    this.facility = Facility.current
    this.scene = Util.getSceneGraphRootFromNode(this.facility.obj3d)
    this.hasErrorState = false

    const spotHeatHeight = get(options, 'heaterData[0].spotHeatHeight')
    const nativeDistances = {
      minFloorClearance:
        Units.inchesToNative(spotHeatHeight) ||
        Units.inchesToNative(options.minFloorClearance),
      minObstructionClearance: Units.inchesToNative(
        options.minObstructionClearance
      ),
      minRoofClearance: Units.inchesToNative(options.minRoofClearance),
      minProductClearance: Units.inchesToNative(options.minProductClearance),
      minWallClearance: Units.inchesToNative(options.minWallClearance),
    }
    const minFloorDistance = Units.toDistanceString(
      nativeDistances.minFloorClearance
    )
    const minObstructionDistance = Units.toDistanceString(
      nativeDistances.minObstructionClearance
    )
    const minRoofDistance = Units.toDistanceString(
      nativeDistances.minRoofClearance
    )
    const minProductClearance = Units.toDistanceString(
      nativeDistances.minProductClearance
    )
    const minWallClearance = Units.toDistanceString(
      nativeDistances.minWallClearance
    )

    this.errors = {
      floor: `Too close to the floor! (must be greater than ${minFloorDistance})`,
      ceiling: `Too close to the ceiling! (must be greater than ${minRoofDistance})`,
      product: `Too close to a fan! (must be greater than ${minProductClearance})`,
      doorObstruction: `Too close to a door! (must be greater than ${minObstructionDistance})`,
      obstruction: `Too close to an obstruction! (must be greater than ${minObstructionDistance})`,
      utilityBoxObstruction: `Too close to a utility box! (must be greater than ${minObstructionDistance})`,
      roofObstruction: `Too close to the roof! (must be greater than ${minObstructionDistance})`,
      obstructionBelow: `Fan can not be placed on top of an obstruction`,
      outOfBounds: `Fan is out of bounds`,
      wall: `Too close to a wall! (must be greater than ${minWallClearance})`,
    }

    // Directions used for raycaster
    this.directions = [
      [0, 1], // up
      [0, -1], // down
      [-1, 0], // left
      [1, 0], // right

      [1, 1], // up-right
      [-1, 1], // up-left
      [-1, -1], // down-left
      [1, -1], // down-right

      [50, -100], // down down-right
      [-50, -100], // down down-left
      [100, -50], // right down-right
      [-100, -50], // left down-left

      [50, 100], // up up-right
      [-50, 100], // up up-left
      [100, 50], // right up-right
      [-100, 50], // left up-left
    ]

    // Reusable vectors and raycasters
    this.vector1 = new THREE.Vector3()
    this.vector2 = new THREE.Vector3()
    this.vector3 = new THREE.Vector3()
    this.vector4 = new THREE.Vector3()
    this.vector5 = new THREE.Vector3()
    this.vector6 = new THREE.Vector3()
    this.raycaster1 = new THREE.Raycaster()
    this.raycaster2 = new THREE.Raycaster()
  }

  productDistanceCheck(object, isReceiver) {
    if (!object) return false

    this.product = object
    this.product.isOutOfBounds = false

    const pos = object.obj3d.position.clone()
    const isInsideFacility = Util.isPositionsOverFacility([pos])
    if (!isInsideFacility) {
      this.showErrorAlert(this.errors.outOfBounds)
      this.addErrorState()
      this.product.isOutOfBounds = true
      this.hasErrorState = true
      return false
    }

    const invalidProduct = this.getNearestInvalidProduct()
    if (invalidProduct) {
      if (!this.hasErrorState) this.showErrorAlert(this.errors.product)
      this.addProductToProductMarker(invalidProduct)
      this.hasErrorState = true
      return false
    }

    if (!object.isDirectional) {
      const invalidWall = this.getNearestInvalidWalls()
      if (invalidWall) {
        if (!this.hasErrorState) this.showErrorAlert(this.errors.wall)
        this.addProductToPointMarker(invalidWall)
        this.hasErrorState = true
        return false
      }
    }

    if (object.isDirectional && !object.isDirectionalOverhead) {
      const invalidObstructionBelow = this.getNearestObstructionBelow(object)
      if (invalidObstructionBelow) {
        if (!this.hasErrorState) {
          this.showErrorAlert(this.errors.obstructionBelow)
        }
        this.addErrorState()
        this.hasErrorState = true
        return false
      }
    }

    const invalidObstruction = this.getNearestInvalidObstruction()
    if (invalidObstruction) {
      switch (invalidObstruction.className) {
      case 'Obstruction':
        if (!this.hasErrorState) this.showErrorAlert(this.errors.obstruction)
        break
      case 'UtilityBox':
        if (!this.hasErrorState) this.showErrorAlert(this.errors.utilityBoxObstruction)
        break
      case 'Roof':
      case 'RoofSection':
        if (!this.hasErrorState) this.showErrorAlert(this.errors.roofObstruction)
        break
      default:
        if (!('obj3d' in invalidObstruction)) {
          if (!this.hasErrorState) this.showErrorAlert(this.errors.doorObstruction)
          break
        }
        // TODO: when this is ported to typescript, make it exhaustive with a `never` check at comptime
        break
      }
      this.addProductToPointMarker(invalidObstruction)
      this.hasErrorState = true
      return false
    }

    const isMountedDirectionalWithClearance =
      object.isMounted && object.minFloorClearance
    const isOverheadDirectionalWithClearance =
      object.isDirectionalOverhead && object.minFloorClearance
    if (
      !object.isDirectional ||
      isOverheadDirectionalWithClearance ||
      isMountedDirectionalWithClearance
    ) {
      const floorDistance = Units.nativeToInches(this.product.height)
      const minDistanceToFloor = this.product.model.includes('IRH')
        ? get(this.product, 'heaterData[0].spotHeatHeight')
        : Math.max(this.product.size * 0.75, this.product.minFloorClearance)
      if (floorDistance < minDistanceToFloor) {
        if (!this.hasErrorState) this.showErrorAlert(this.errors.floor)
        this.addErrorState()
        this.hasErrorState = true
        return false
      }
    }

    const invalidCeiling = this.getNearestInvalidCeiling()
    if (invalidCeiling) {
      if (!this.hasErrorState) this.showErrorAlert(this.errors.ceiling)
      this.addErrorState()
      this.hasErrorState = true
      return false
    }

    if (this.hasErrorState) {
      store.dispatch(clearStatus())
    }

    this.hasErrorState = false
    this.hideErrorState()

    return true
  }

  getNearestInvalidProduct() {
    // To prevent bloat, use RAYCAST_THRESHOLD to test object distances
    const products = Facility.current
      .getProducts()
      .filter(
        product =>
          product.obj3d.position.distanceTo(this.product.obj3d.position) <
            RAYCAST_THRESHOLD && this.product.id !== product.id
      )

    // do not allow any products within the heater's clearance box
    if (this.product.model?.includes('IRH')) {
      for (let i = 0; i < products.length; i++) {
        const otherProduct = products[i]
        const otherPos = { ...otherProduct.position }

        // flag other IRH heaters when entering bounding box
        if (get(otherProduct, 'category', '') === 'HEAT') {
          const otherPoints = otherProduct.getBoundingBoxEdges()

          for (let j = 0; j < otherPoints.length; j++) {
            const p = otherPoints[j]
            if (this.product.pointIsInClearance(p)) {
              return otherProduct
            }
          }
        } else {
          const pointOnCircumference = ({ x, y, r, t }) => {
            return { x: r * Math.cos(t) + x, y: r * Math.sin(t) + y }
          }

          const radialPositions = []

          // 16 evenly spaced points on the fan radius to test
          const circleResolution = 16
          const circleRads = Math.PI * 2
          const circleDelta = circleRads / circleResolution

          for (let j = 0; j < circleRads; j += circleDelta) {
            const res = pointOnCircumference({
              ...otherPos,
              r: Units.inchesToNative(otherProduct.size / 2),
              t: j,
            })
            radialPositions.push({ ...otherPos, ...res })
          }

          for (let j = 0; j < radialPositions.length; j++) {
            const p = radialPositions[j]
            if (this.product.pointIsInClearance(p)) {
              return otherProduct
            }
          }
        }
      }

      return
    }

    const invalidProducts = []
    products.forEach(product => {
      this.vector1.copy(this.product.obj3d.position)
      if (!product.obj3d || !product.obj3d.position) return
      const objectPos = get(product, 'obj3d.position')
      const distance = this.vector1 && this.vector1.distanceTo(objectPos)
      const distanceInInches = Units.nativeToInches(distance)
      const minClearance = this.product.minProductClearance
      const isValidProduct = this.product.id !== product.id

      if (Math.round(distanceInInches) < minClearance && isValidProduct) {
        invalidProducts.push({
          ...product,
          distance,
        })
      }
    })

    // Filter out floor standing directional fans
    const filteredProducts = invalidProducts.filter(
      product =>
        (!product.isDirectional || product.isDirectionalOverhead) &&
        product.category !== 'HEAT'
    )

    if (filteredProducts.length) {
      const sortedProducts = sortBy(filteredProducts, 'distance')
      const product = products.find(
        product => product.id === sortedProducts[0].id
      )

      if (!product) return

      const productToProductDirection = new THREE.Vector3()
        .subVectors(product.obj3d.position, this.product.obj3d.position)
        .normalize()

      const options = {
        measureFrom: Facility.CENTER,
        measureTo: Facility.SURFACE,
        includedTypes: [OBJECT_TYPES.FAN_COLLISION_CUBE, OBJECT_TYPES.WALL],
        xyOnly: false,
      }

      const intersects = Facility.current.measureObjectsInDirectionFromObject(
        productToProductDirection,
        this.product.obj3d,
        options
      )

      const objectType = get(intersects, '[0].wrapper.className')
      const foundWallBetweenProducts = objectType === 'Wall'
      if (!foundWallBetweenProducts) return product
    }
  }

  getNearestInvalidObstruction() {
    const isMounted = this.product.isMounted
    // To prevent bloat, use RAYCAST_THRESHOLD to test object distances
    const obstructions = this.facility
      .getObstructions()
      .filter(
        o =>
          o.obj3d.position.distanceTo(this.product.obj3d.position) <
          RAYCAST_THRESHOLD
      )
    const doors = Object.values(store.getState().objects.present.doors)
    const wallSegments = Object.values(store.getState().objects.present.segments)
    const utilityBoxes = this.facility.getUtilityBoxes()
    const roofSections = !isMounted ? this.facility.getRoofSections() : []
    const roofs = !isMounted ? this.facility.getRoofs() : []

    const validObjects = obstructions.concat(
      doors,
      utilityBoxes,
      roofSections,
      roofs
    )

    const radius = Units.inchesToNative(this.product.size / 2)
    const invalidObstructions = []
    const position = this.product.obj3d.position
    const z =
      this.product.isDirectional &&
      !this.product.isDirectionalOverhead &&
      !isMounted
        ? 0
        : this.product.obj3d.position.z

    let intersections

    // do not allow any obstructions within the heater's clearance box
    if (this.product.model?.includes('IRH')) {
      for (let i = 0; i < validObjects.length; i++) {
        const obstruction = validObjects[i]
        if (!obstruction.positions) break
        for (let j = 0; j < obstruction.positions.length; j++) {
          const p = obstruction.positions[j]
          if (
            this.product.pointIsInClearance(p) ||
            this.product.pointIsInClearance({
              x: p.x,
              y: p.y,
              z: p.z + Units.inchesToNative(obstruction.height / 2),
            }) ||
            this.product.pointIsInClearance({
              x: p.x,
              y: p.y,
              z: p.z + Units.inchesToNative(obstruction.height),
            })
          ) {
            return obstruction
          }
        }
      }

      return
    }

    validObjects.forEach(obstruction => {
      this.directions.forEach(direction => {
        this.raycaster1.set(
          this.vector1.set(position.x, position.y, z),
          this.vector2.set(direction[0], direction[1], 0).normalize()
        )
        if ('obj3d' in obstruction) {
          intersections = this.raycaster1.intersectObject(obstruction.obj3d, true)
        } else {
          const wallSegment = wallSegments.find(it => it.id === obstruction.wallSegmentId)
          const doorX = Units.inchesToNative(obstruction.position.x)
          const doorY = Units.inchesToNative(obstruction.position.y)
          const doorZ = Units.inchesToNative(obstruction.height / 2)
          const doorWidth = Units.inchesToNative(obstruction.width)
          const doorHeight = Units.inchesToNative(obstruction.width)
          const doorThickness = Units.inchesToNative(wallSegment.thickness) + 0.2
          const geometry = new THREE.BoxGeometry(doorThickness, doorWidth, doorHeight)
          const mesh = new THREE.Mesh(geometry)
          mesh.position.set(doorX, doorY, doorZ)
          const rotation = orthogonalVectorOfWallSegment(wallSegment)
          mesh.rotation.copy(rotation)
          intersections = this.raycaster1.intersectObject(mesh, true)
          geometry.dispose()
        }

        if (!intersections.length) return

        const sortedIntersections = sortBy(intersections, 'distance')
        const edgeDistance = sortedIntersections[0].distance - radius
        const edgeDistanceInInches = Units.nativeToInches(edgeDistance)
        const recommendedClearance = this.product
          .recommendedObstructionClearance

        if (Math.round(edgeDistanceInInches) < recommendedClearance) {
          invalidObstructions.push({
            ...obstruction,
            position: sortedIntersections[0].point,
            edgeDistance,
            intersection: sortedIntersections[0],
          })
        }
      })
    })

    if (invalidObstructions.length) {
      const sortedObstructions = sortBy(invalidObstructions, 'edgeDistance')
      return sortedObstructions[0]
    }
  }

  getNearestInvalidWalls() {
    if (this.product.model.includes('IRH')) return

    const walls = this.facility.getWalls()
    const radius = Units.inchesToNative(this.product.size / 2)
    const invalidWalls = []
    let intersections

    walls.forEach(wall => {
      this.directions.forEach(direction => {
        this.raycaster1.set(
          this.product.obj3d.position,
          this.vector1.set(direction[0], direction[1], 0).normalize()
        )
        intersections = this.raycaster1.intersectObject(wall.obj3d)

        if (!intersections.length) return

        const sortedIntersections = sortBy(intersections, 'distance')
        const edgeDistance = sortedIntersections[0].distance - radius
        const edgeDistanceInInches = Units.nativeToInches(edgeDistance)
        const minClearance = this.product.minWallClearance

        if (Math.round(edgeDistanceInInches) < minClearance) {
          invalidWalls.push({
            ...wall,
            position: sortedIntersections[0].point,
            edgeDistance,
            intersection: sortedIntersections[0],
          })
        }
      })
    })

    if (invalidWalls.length) {
      const sortedWalls = sortBy(invalidWalls, 'edgeDistance')
      return sortedWalls[0]
    }
  }

  getNearestInvalidCeiling() {
    const intersections = this.facility.measureObjectsInDirectionFromPoint(
      this.vector1.set(0, 0, 1).normalize(),
      this.vector2.copy(this.product.obj3d.position),
      {
        includedTypes: [OBJECT_TYPES.CEILING, OBJECT_TYPES.ROOF],
      }
    )

    // filter out any disabled ceilings
    const filteredCeilings = intersections.filter(
      ceiling =>
        ceiling.wrapper.enabled ||
        ceiling.wrapper.className !== CLASS_NAMES.CEILING
    )

    if (!filteredCeilings.length) return

    const sortedIntersections = sortBy(filteredCeilings, 'distance')
    const closestIntersection = sortedIntersections[0]
    const distance = closestIntersection.distance
    const distanceInInches = Units.nativeToInches(distance)
    const minClearance = this.product.minRoofClearance

    if (Math.round(distanceInInches) < minClearance) {
      const roof = closestIntersection.object
      return {
        ...roof,
        distance,
      }
    }
  }

  getNearestObstructionBelow(object) {
    const positions = []
    const radius = Units.inchesToNative(object.size / 2)
    const offset = object.isMounted ? 0 : radius
    const isWallMountedDirectional =
      object.isDirectional && !object.isDirectionalOverhead && object.isMounted

    positions.push(
      this.vector1.set(
        this.product.obj3d.position.x,
        this.product.obj3d.position.y,
        this.product.obj3d.position.z + offset
      )
    )
    positions.push(
      this.vector2.set(
        this.product.obj3d.position.x + radius,
        this.product.obj3d.position.y,
        this.product.obj3d.position.z + offset
      )
    )
    positions.push(
      this.vector3.set(
        this.product.obj3d.position.x - radius,
        this.product.obj3d.position.y,
        this.product.obj3d.position.z + offset
      )
    )
    positions.push(
      this.vector4.set(
        this.product.obj3d.position.x,
        this.product.obj3d.position.y + radius,
        this.product.obj3d.position.z + offset
      )
    )
    positions.push(
      this.vector5.set(
        this.product.obj3d.position.x,
        this.product.obj3d.position.y - radius,
        this.product.obj3d.position.z + offset
      )
    )

    const intersections = []

    positions.forEach(pos => {
      const intersects = this.facility.measureObjectsInDirectionFromPoint(
        this.vector6.set(0, 0, -1).normalize(),
        pos,
        { includedTypes: [OBJECT_TYPES.OBSTRUCTION] }
      )
      if (intersects.length) {
        if (isWallMountedDirectional) {
          const distance = get(intersects, '[0].distance')
          if (distance < radius) intersections.push(intersects[0])
        } else {
          intersections.push(intersects[0])
        }
      }
    })

    if (!intersections.length) return

    const sortedIntersections = sortBy(intersections, 'distance')
    const closestIntersection = sortedIntersections[0]
    const obstruction = closestIntersection.object

    return {
      ...obstruction,
    }
  }

  addProductToProductMarker(product) {
    this.addErrorState()
    this.startPoint = this.product.obj3d.position.clone()
    this.endPoint = product.obj3d.position.clone()

    // Make sure the marker is not sloped by using the highest z axis
    if (this.startPoint.z > this.endPoint.z) {
      this.endPoint.z = this.startPoint.z + 1
    } else {
      this.startPoint.z = this.endPoint.z + 1
    }

    this.edgeDistance = this.startPoint.distanceTo(this.endPoint)

    const directionFromProduct = Util.getVectorToVectorDirection(
      this.startPoint,
      this.endPoint
    )
    const directionToProduct = Util.getVectorToVectorDirection(
      this.endPoint,
      this.startPoint
    )

    this.raycaster1.set(this.startPoint, directionToProduct)
    this.raycaster2.set(this.endPoint, directionFromProduct)

    this.addArrowHelper(this.raycaster1, this.raycaster2, 2, 2)
  }

  addProductToPointMarker(object) {
    this.addErrorState()

    const directionToProduct = Util.getVectorToVectorDirection(
      this.product.obj3d.position,
      object.position
    )

    const directionFromProduct = Util.getVectorToVectorDirection(
      object.position,
      this.product.obj3d.position
    )

    this.edgeDistance = object.edgeDistance
    if (this.edgeDistance < 0.2) return

    this.startPoint = object.intersection?.point || object.position
    this.raycaster1.set(this.startPoint, directionToProduct)

    this.endPoint = this.vector1.copy(this.startPoint)
    this.endPoint.addScaledVector(directionToProduct, this.edgeDistance)
    this.raycaster2.set(this.endPoint, directionFromProduct)

    this.addArrowHelper(this.raycaster1, this.raycaster2)
  }

  addArrowHelper(startRay, endRay, arrowLength = 0.5, arrowWidth = 0.7) {
    this.arrowHelper = this.getArrowHelper(
      startRay,
      this.edgeDistance,
      arrowLength,
      arrowWidth
    )
    this.arrowHelper.userData.objectType = OBJECT_TYPES.PRODUCT_DISTANCE
    this.arrowHelper.userData.productId = this.product.id

    this.arrowHelper2 = this.getArrowHelper(
      endRay,
      this.edgeDistance,
      arrowLength,
      arrowWidth,
      true
    )
    this.arrowHelper2.userData.objectType = OBJECT_TYPES.PRODUCT_DISTANCE
    this.arrowHelper2.userData.productId = this.product.id

    // Don't add arrow helpers if too small to prevent scaling by 0 warning
    const scale1 = this.arrowHelper.line.scale
    const scale2 = this.arrowHelper2.line.scale
    if (scale1.x > 0 && scale1.y > 0) this.scene.add(this.arrowHelper)
    if (scale2.x > 0 && scale2.y > 0) this.scene.add(this.arrowHelper2)
  }

  getArrowHelper(ray, distance, arrowLength, arrowWidth) {
    return new THREE.ArrowHelper(
      ray.ray.direction,
      ray.ray.origin,
      distance,
      0xff0000,
      arrowLength,
      arrowWidth
    )
  }

  addErrorState() {
    if (this.product) {
      this.hideErrorState()
      if (has(this.product, 'clearance.material')) {
        this.errorSign = Primitives.getErrorSign(this.product)
        this.product.obj3d.add(this.errorSign)
        this.product.clearance.material.emissive = new THREE.Color(1, 0, 0)
        this.product.clearance.material.opacity = 0.95
        this.product.clearance.material.color.setHex(
          getThreeHexFromTheme('three.invalidSelectedProduct')
        )
      }
    }
  }

  removeErrorState() {
    this.hideErrorState()
    store.dispatch(clearStatus())
  }

  hideErrorState() {
    if (this.arrowHelper) this.scene.remove(this.arrowHelper)
    if (this.arrowHelper2) this.scene.remove(this.arrowHelper2)
    this.arrowHelper = null
    this.arrowHelper2 = null

    function objectBelongsToThisProduct(mesh, id) {
      return (
        mesh.userData &&
        mesh.userData.objectType === OBJECT_TYPES.PRODUCT_DISTANCE &&
        mesh.userData.productId === id
      )
    }

    function objectBelongsToNoProduct(mesh, ids) {
      return (
        mesh.userData &&
        mesh.userData.objectType === OBJECT_TYPES.PRODUCT_DISTANCE &&
        !ids.includes(mesh.userData.productId)
      )
    }

    if (this.product) {
      const productIds = this.facility.getProducts().map(prod => prod.id)

      // Remove error sign
      if (this.errorSign) this.product.obj3d.remove(this.errorSign)

      // Remove arrow helpers from the scene that belong to this
      // product or do not belong to any product at all
      this.scene.children
        .filter(
          mesh =>
            objectBelongsToThisProduct(mesh, this.product.id) ||
            objectBelongsToNoProduct(mesh, productIds)
        )
        .forEach(mesh => this.scene.remove(mesh))

      if (has(this.product, 'clearance.material')) {
        this.product.clearance.material.emissive = new THREE.Color(0, 0, 0)
        const isSelectedObject = objectIsSelected(this.product.id)
        if (isSelectedObject) {
          this.product.clearance.material.opacity = 0.95
        } else {
          const isProductTool = activeTool() === 'PRODUCT_TOOL'
          if (!isProductTool) this.product.clearance.material.opacity = 0
        }
        this.product.clearance.material.color.setHex(
          getThreeHexFromTheme('three.objects.product.default')
        )
      }
    }
  }

  showErrorAlert(error) {
    store.dispatch(setStatus({ text: error, type: 'error' }))
  }
}

export default productDistance
