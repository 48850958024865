import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const Placeholder = styled.div<{ width?: string | number; height?: string | number; circle?: boolean }>`
  background-color: ${theme('colors.light.subdued')};
  height: ${props => props.height}px;
  width: ${props => props.width}px;

  ${props => props.circle && `border-radius: 50%;`};
`

export default Placeholder
