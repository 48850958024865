import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const DocTypeItem = styled.a`
  border: 1px solid ${theme('colors.light.subdued')};
  border-radius: ${theme('radius.base')};
  color: ${theme('colors.light.fg')};
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  padding: ${theme('spacing.base')};
  text-align: center;
  text-decoration: none;
  ${props =>
    props.active && `border-color: ${theme('colors.light.primary')(props)};`};
  ${props => props.disabled && `opacity: 0.2;`};

  &:active,
  &:focus {
    border-color: ${theme('colors.light.primary')};
  }
  &:hover {
    cursor: pointer;
  }
`

export default DocTypeItem
