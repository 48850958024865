import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import isString from 'lodash-es/isString'

import { URIFragmentGenerator } from 'components/UIKit/Offcanvas/utils/URIFragment'

import Collapsible from 'components/UIKit/Collapsible'
import Flex, { FlexItem } from 'components/UIKit/Flex'
import HR from 'components/UIKit/HR'
import Offcanvas, { MenuButton } from 'components/UIKit/Offcanvas'
import Space from 'components/UIKit/Space'

import { sections } from './data'
import Container from './styled/Container'
import SanitizeHTML from './SanitizeHTML'
import theme from 'config/theme'

const H1 = styled.span`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
`
const H2 = styled(H1)`
  font-size: 2rem;
  font-weight: 350;
  scroll-margin-top: 60px;
`

const H4 = styled(H1)`
  font-size: 1.5rem;
  font-weight: 350;
`

const TextContainer = styled.div`
  margin-bottom: ${theme.spacing.base};
`

const FAQ = props => {
  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [location])

  return (
    <Container>
      <Offcanvas
        data={sections}
        handleOpen={props.handleOpen}
        header="Table Of Contents"
        marginTop={60}
        open={props.open}
      />
      <Space bottom="base">
        <Flex justifyContent="center" numberOfItems={1} spacingRight="base">
          <FlexItem marginBottom="base" marginTop="base">
            {!props.open && <MenuButton handleOpen={props.handleOpen} />}
            <H1>{'Frequently Asked Questions'}</H1>
            {/* TODO: create search component to filter page
              <Icon name="search" />
            */}
          </FlexItem>
        </Flex>

        {sections.map((section, index) => (
          <Flex
            flexDirection="column"
            key={index}
            lastChildFormatOff
            numberOfItems={section.articles.length + 1}
            spacingRight="base"
            marginBottom="base"
          >
            <FlexItem marginBottom="m">
              <H2 id={URIFragmentGenerator(section.title)}>{section.title}</H2>
            </FlexItem>
            <HR marginBottom="m" />
            {section.articles.map((article, idx) => (
              <Collapsible
                flushBottom
                fullWidth
                iconSize={28}
                key={idx}
                marginTop={9}
                noBorder
                swapDirection
                trigger={<H4>{article.header}</H4>}
                withCaret
              >
                <TextContainer>
                  {article.paragraphs.map((p, i) => (
                    <SanitizeHTML
                      html={isString(p) ? p : p.html}
                      key={i}
                      tabOver={isString(p) ? 0 : p.tabOver}
                    />
                  ))}
                </TextContainer>
              </Collapsible>
            ))}
          </Flex>
        ))}
      </Space>
    </Container>
  )
}

FAQ.propTypes = {
  handleOpen: PropTypes.func,
  open: PropTypes.bool,
}

export default FAQ
