import React, { useState } from 'react'
import { format } from 'date-fns'
import { withRouter } from 'react-router-dom'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import { withProps } from 'recompact'
import PropTypes from 'prop-types'

import {
  queryIsCached,
  facilityIsDownloaded,
  facilityQueryOptions,
  downloadFacility,
} from 'client/decorators/withInitialData'
import {
  ARCHIVE_FACILITY_MUTATION,
  DELETE_FACILITY_MUTATION,
  CREATE_USER_FAVORITE_MUTATION,
  DELETE_USER_FAVORITE_MUTATION,
} from 'client/mutations'
import {
  handleFavoriteFacility,
  handleDeleteFavorite,
  handleArchiveFacility,
  handleDeleteFacility,
} from 'client/handlers'
import { showAlert } from 'store/alert'

import Button from 'components/UIKit/Button'
import Icon from 'components/UIKit/Icon'
import {
  SelectListActions,
  SelectListIconDownloading,
  SelectListIconDownload,
  SelectListItem,
  SelectListLink,
  SelectListMeta,
  SelectListTitle,
} from 'components/UIKit/SelectList'

import Flex from 'components/UIKit/Flex'
import Popover from 'components/UIKit/Popover'

import FacilitiesListItemLoader from './FacilitiesListItemLoader'
import { hasPermission } from 'components/RequiredPermission'
import { removeStorageItems } from 'components/Modals/ExportPDFLayoutModal/Wizard/constants'
import { get } from 'lodash-es'
import { currentUserCanEdit } from 'lib/currentUserIs'

const FacilitiesListItem = ({
  facility,
  match,
  history,
  onFavorite,
  onDeleteFavorite,
  onArchive,
  onDelete,
  onShowAlert,
  hideEditButton,
  hideFavoriteButton,
  hideDuplicateButton,
  hideDeleteButton,
  loading, // TODO: Show a loading indicator somewhere
  offline,
  user,
  title,
}) => {
  let subtext = `${format(new Date(facility.createdAt), 'MM/dd/yyyy h:mm a')}`
  let hideEdit = hideEditButton
  let hideDelete = hideDeleteButton

  if (title === 'My Favorites' || title === 'All Facilities') {
    subtext = `${format(
      new Date(facility.createdAt),
      'MM/dd/yyyy h:mm a'
    )} - ${get(facility, 'author.name')} - ${get(facility, 'author.email')}`
    // Only restrict access to facilities user didn't create
    if (!currentUserCanEdit(facility, user)) {
      hideEdit = true
      hideDelete = true
    }
  }
  const items = []
  const [downloaded, setDownloaded] = useState(
    queryIsCached(facilityQueryOptions({ facility })) &&
      facilityIsDownloaded(facility.id)
  )
  const [downloading, setDownloading] = useState(false)
  const disabled = offline && !downloaded

  if (!downloaded) {
    items.push({
      title: 'Download',
      icon: {
        name: 'disk',
      },
      onClick: async event => {
        setDownloading(true)
        event.preventDefault()
        event.stopPropagation()

        // TODO?: Download non-default areas/versions
        await downloadFacility({ facility })
        await downloadFacility({ facility: { id: facility.id } }) // Also cache short-url version
        setDownloaded(queryIsCached(facilityQueryOptions({ facility })))
        setDownloading(false)
      },
    })
  }

  if (!hideEdit) {
    items.push({
      title: 'Edit',
      icon: {
        name: 'edit',
      },
      onClick: event => {
        event.preventDefault()
        event.stopPropagation()
        history.push(`${match.url}/edit-facility/${facility.id}`)
      },
    })
  }

  if (!hideDuplicateButton) {
    items.push({
      title: 'Duplicate',
      icon: {
        name: 'duplicate',
      },
      onClick: event => {
        event.preventDefault()
        event.stopPropagation()
        history.push(`${match.url}/duplicate-facility/${facility.id}`)
      },
    })
  }

  if (!hideFavoriteButton) {
    items.push({
      title: facility.isFavorite ? 'Remove Favorite' : 'Favorite',
      icon: {
        name: 'star',
      },
      onClick: event => {
        event.preventDefault()
        event.stopPropagation()

        if (facility.isFavorite) {
          onDeleteFavorite({
            facilityId: facility.id,
          })
        } else {
          onFavorite({
            facilityId: facility.id,
          })
        }
      },
    })
  }

  if (!hideEdit) {
    items.push({
      title: facility.isArchived ? 'Unarchive' : 'Archive',
      icon: {
        name: 'inbox',
      },
      onClick: event => {
        event.preventDefault()
        event.stopPropagation()
        onArchive({
          id: facility.id,
          isArchived: !facility.isArchived,
        }).then(() => {
          onShowAlert({
            type: 'success',
            text: `Facility ${
              facility.isArchived ? 'unarchived' : 'archived'
            }!`,
          })
        })
      },
    })
  }

  if (!hideDelete) {
    items.push({
      title: 'Delete',
      icon: {
        name: 'trash',
      },
      onClick: event => {
        event.preventDefault()
        event.stopPropagation()
        // eslint-disable-next-line
        if (confirm('Are you sure you want to delete this facility?')) {
          onDelete({
            id: facility.id,
          }).then(res => {
            removeStorageItems({ facility: res.data.deleteFacility.id })

            onShowAlert({
              type: 'success',
              text: 'Facility deleted!',
            })
          })
        }
      },
    })
  }

  items.push({
    title: 'Reassign',
    icon: {
      name: 'building',
    },
    onClick: event => {
      event.preventDefault()
      event.stopPropagation()
      history.push(`${match.url}/${facility.id}/user-search`)
    },
    permissionNeeded: 'Reassign Facility',
  })

  let facilityLink = `/facility/${facility.id}`
  if (facility.floor && facility.floor.id) {
    facilityLink += `/floor/${facility.floor.id}`
    if (facility.floor.version && facility.floor.version.id) {
      facilityLink += `/version/${facility.floor.version.id}`
    }
  }

  return (
    <SelectListItem key={facility.id} disabled={disabled}>
      <SelectListActions>
        {disabled || offline ? (
          <Button noBorder icon="more" />
        ) : (
          <Popover
            items={items}
            renderTrigger={({ isDropdownVisible }) =>
              loading ? (
                <FacilitiesListItemLoader label="" iconSize="14" />
              ) : (
                <Button
                  noBorder
                  icon="more"
                  data-testid="facility-item-options"
                />
              )
            }
          />
        )}
      </SelectListActions>
      <SelectListLink actions to={disabled ? '' : facilityLink}>
        <Flex flexGrow="1" flexDirection="column">
          <SelectListTitle>
            {facility.name}
            {facility.isFavorite && (
              <Icon name="star" color="primary" size="14" marginLeft="xs" />
            )}
          </SelectListTitle>
          <SelectListMeta>{subtext}</SelectListMeta>
        </Flex>
        {(downloading && <SelectListIconDownloading />) ||
          (downloaded && <SelectListIconDownload />)}
        <Icon name="arrowRight" color="subdued" />
      </SelectListLink>
    </SelectListItem>
  )
}

FacilitiesListItem.propTypes = {
  facility: PropTypes.object,
  hideDeleteButton: PropTypes.bool,
  hideFavoriteButton: PropTypes.bool,
  hideDuplicateButton: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  history: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  onFavorite: PropTypes.func,
  onDeleteFavorite: PropTypes.func,
  onArchive: PropTypes.func,
  onDelete: PropTypes.func,
  onShowAlert: PropTypes.func,
  offline: PropTypes.bool,
  variables: PropTypes.object,
  user: PropTypes.object,
  title: PropTypes.string,
}

const mapStateToProps = ({ auth }) => ({
  userId: auth.userId,
})

const mapDispatchToProps = dispatch => ({
  onShowAlert({ text, type }) {
    dispatch(showAlert({ text, type }))
  },
})

export default compose(
  hasPermission({
    name: 'Edit Facility',
    withPermissions: true,
  }),
  withProps(props => ({
    hideEditButton: props.hideEditButton || !props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  hasPermission({
    name: 'Delete Facility',
    withPermissions: true,
  }),
  withProps(props => ({
    hideDeleteButton: props.hideDeleteButton || !props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  appConnect(mapStateToProps, mapDispatchToProps),
  graphql(CREATE_USER_FAVORITE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onFavorite: ({ facilityId }) =>
        handleFavoriteFacility({ facilityId, mutate, ownProps }),
    }),
  }),
  graphql(DELETE_USER_FAVORITE_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onDeleteFavorite: ({ facilityId }) =>
        handleDeleteFavorite({ facilityId, mutate, ownProps }),
    }),
  }),
  graphql(ARCHIVE_FACILITY_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onArchive: ({ id, isArchived }) =>
        handleArchiveFacility({ id, isArchived, mutate, ownProps }),
    }),
  }),
  graphql(DELETE_FACILITY_MUTATION, {
    props: ({ mutate, ownProps }) => ({
      onDelete: ({ id }) => handleDeleteFacility({ id, mutate, ownProps }),
    }),
  }),
  withRouter
)(FacilitiesListItem)
