import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { getTitle } from 'config/titles'

import { CREATE_FLOOR_MUTATION } from 'client/mutations'
import { ALL_FACILITIES_QUERY, GET_FACILITY_QUERY } from 'client/queries'

import FloorForm from 'components/FloorForm'

const NewFormModal = ({ ...props }) => (
  <div>
    <Helmet>
      <title>{getTitle('newFloor')}</title>
    </Helmet>
    <FloorForm title="New Area" buttonText="Create Area" {...props} />
  </div>
)

export default compose(
  graphql(CREATE_FLOOR_MUTATION, {
    props: ({ mutate }) => ({
      onSubmit: variables => mutate({ variables }),
    }),
    options: props => ({
      refetchQueries: [
        {
          query: GET_FACILITY_QUERY,
          variables: {
            id: props.facility.id,
            floorId: null,
            versionId: null,
          },
          name: 'facilityData',
        },
        {
          query: ALL_FACILITIES_QUERY,
          name: 'allFacilitiesData',
        },
      ],
    }),
  }),
  withRouter
)(NewFormModal)
