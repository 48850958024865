import styled from 'styled-components'

const InlineField = styled.div`
  align-items: center;
  display: flex;

  > *:first-child {
    width: 90px;
  }
`

export default InlineField
