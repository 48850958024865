import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

const numberSize = '20px'

const number = props => {
  if (props.number) {
    return `
      &::after {
        background-color: ${theme('colors.light.primary')(props)};
        color: ${theme('colors.light.fg')(props)};
        content: '${props.number.toString()}';
        display: block;
        font-size: ${theme('fonts.sizes.s')(props)};
        font-weight: bold;
        height: ${numberSize};
        line-height: ${numberSize};
        left: 0;
        position: absolute;
        top: 0;
        text-align: center;
        width: ${numberSize};
      }
    `
  }
}

const Item = styled.div`
  border: 2px solid transparent;
  display: block;
  margin-bottom: ${theme('spacing.base')};
  position: relative;
  transition: all 0.2s ease-in-out;

  ${props =>
    props.active && `border-color: ${theme('colors.light.primary')(props)};`};
  ${props => props.disabled && `opacity: 0.2;`};

  &:focus {
    outline: none;
  }

  &:active,
  &:focus,
  &:hover {
    border-color: ${theme('colors.light.primary')};
  }

  ${number};
`

export default Item
