import Tool from './tool'

class MoveTool extends Tool {
  constructor() {
    super()

    this.name = 'MOVE_TOOL'
  }

  getArrowDescriptions() {}
}

export default MoveTool
