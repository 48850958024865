import { isNil } from 'lodash-es'

import Unit from './unit'
import { SYSTEMS, TYPES } from '../constants'
import type { FormatOptions } from '../formatters'
import type { FormattedUnitData, UnformattedUnitData, SomeUnitData } from './unit'

class Velocity<Data extends FormattedUnitData | UnformattedUnitData> extends Unit<Data> {
  constructor({ value, system }: Omit<Data, 'type'>) {
    if (isNil(value)) {
      console.warn('No value provided to Velocity unit!')
    }
    if (isNil(system)) {
      console.warn('No system provided to Velocity unit!')
    }
    super({ value, system, type: TYPES.VELOCITY } as Data)
  }

  clone() {
    return new Velocity<Data>({
      value: this.value,
      system: this.system,
    } as Data)
  }

  static isValid(props: Partial<Omit<SomeUnitData, 'type'>> = {}): props is Omit<SomeUnitData, 'type'> {
    return (
      typeof props.value === 'number' &&
      [SYSTEMS.IMPERIAL, SYSTEMS.METRIC].includes(props.system as any)
    )
  }

  static unformat({ value, system }: Omit<FormattedUnitData, 'type'>, options?: FormatOptions) {
    return Unit.unformat({ value, system, type: TYPES.TEMPERATURE }, options)
  }
}

export class ImperialVelocity<Data extends FormattedUnitData | UnformattedUnitData> extends Velocity<Data> {
  constructor(value: string | number) {
    super({ value, system: SYSTEMS.IMPERIAL } as Data)
  }
}

export default Velocity
