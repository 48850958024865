import defaultTo from 'lodash-es/defaultTo'
import get from 'lodash-es/get'
import Util from './util'
import Units from './units'

import Primitives from './primitives'
import Facility from './facility'
import getRenderOrder from 'config/canvasRenderOrder'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'

import * as THREE from 'three'

const AIRFLOW_OPACITY = 0.4

class Airflow {
  constructor(airflow, units, colors) {
    this.wall = Facility.current.findObjectWithId(airflow.objectId)
    this.units = units
    this.colors = colors

    const model = this.createModel(airflow)
    this.className = CLASS_NAMES.AIRFLOW
    this.id = model.id
    this.layerKey = defaultTo(model.category, model.layerKey)
    this.height = Units.unitsToNative(units, model.evaluationHeight)
    this.velocities = model.airflowVelocities
    this.gridSize = airflow.gridSize

    this.obj3d = this.getAirflowMesh()

    this.obj3d.position.copy(this.wall.obj3d.position)
    this.obj3d.position.z = this.height
    this.obj3d.layerKey = this.layerKey

    // Used by Interactifier
    this.selectable = false
    this.draggable = false
    this.obj3d.wrapperId = this.id
    this.obj3d.renderOrder = getRenderOrder('airflow')
  }

  getColorData() {
    const rootArrayLength = this.velocities.length
    const nestedArrayLength = this.velocities[0].length
    const numVertices = rootArrayLength * nestedArrayLength

    const colors = new Float32Array(numVertices * 4) // r, g, b, a = 4;

    let n = 0

    const addColors = c => {
      colors[n++] = c.r
      colors[n++] = c.g
      colors[n++] = c.b
      colors[n++] = 1
    }

    for (let i = 0; i < rootArrayLength; ++i) {
      const velocitiesArray = this.velocities[i]
      for (let j = 0; j < nestedArrayLength; ++j) {
        const velocity = velocitiesArray[j]
        const color = Airflow.getVelocityColor(velocity, this.colors)

        addColors(color)
      }
    }

    return colors
  }

  static getVelocityColor(velocity, colors) {
    let j = 0
    // If the velocity is lower than the min, set its color to the minimum
    if (colors[j].velocity >= velocity) return new THREE.Color(colors[j].color)
    // Traverse  the colors array to find the two colors the point is between
    for (let i = 1; i < colors.length; j = i++) {
      if (velocity > colors[j].velocity && velocity < colors[i].velocity) {
        // Find the percentage the velocity is between the two colors
        let percent =
          (velocity - colors[j].velocity) /
          (colors[i].velocity - colors[j].velocity)
        // Return the gradient of the two colors
        return new THREE.Color(
          Util.getGradientColor(colors[j].color, colors[i].color, percent)
        )
      } else if (velocity === colors[i].velocity) {
        return new THREE.Color(colors[i].color)
      }
    }
    // If we did not return a color, make sure the velocity isn't higher than the maximum
    // If it is, return the color of the maximum
    if (velocity > colors[colors.length - 1].velocity) {
      return new THREE.Color(colors[colors.length - 1].color)
    }
  }

  getAirflowMesh() {
    const vertexShader = `
      varying vec2 vUv;

      void main() {
        vUv = uv;

        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
        gl_Position = projectionMatrix * mvPosition;
      }
    `

    const fragmentShader = `
      uniform float opacity;
      uniform sampler2D texture1;

      varying vec2 vUv;

      void main() {
        vec4 color = texture2D(texture1, vUv);
        gl_FragColor = vec4(color.rgb, opacity);
      }
    `

    const colors = this.getColorData()

    const texture = new THREE.DataTexture(
      colors,
      this.velocities[0].length,
      this.velocities.length,
      THREE.RGBAFormat,
      THREE.FloatType
    )
    texture.magFilter = THREE.LinearFilter
    texture.needsUpdate = true

    const uniforms = {
      opacity: { value: AIRFLOW_OPACITY },
      texture1: { value: texture },
    }

    const material = new THREE.ShaderMaterial({
      uniforms,
      vertexShader,
      fragmentShader,
      side: THREE.FrontSide,
      transparent: true,
    })

    const insetPointsArray = get(this.wall, 'insetPoints', [])
    const mesh = Primitives.getRegionMesh(insetPointsArray, null, material)
    const insetPoints = insetPointsArray.map(Util.arrayPointToObjectPoint)
    const boundingRect = Util.boundingRectForPoints(insetPoints)
    const positions = mesh.geometry.attributes.position
    const uvs = new Float32Array(positions.count * 2)
    let i = 0
    for (let j = 0; j < positions.count; ++j) {
      const x = positions.array[j * 3]
      const y = positions.array[j * 3 + 1]
      uvs[i++] = (x - boundingRect.x) / boundingRect.width
      uvs[i++] = (y - boundingRect.y) / boundingRect.height
    }

    mesh.geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2))

    return mesh
  }

  /*
    SceneBuilder event
  */
  visibilityChanged(visible) {
    if (this.obj3d) this.obj3d.visible = visible
  }

  createModel(airflow) {
    return {
      id: Util.guid(),
      layerKey: LAYER_KEYS.AIRFLOW,
      ...airflow,
    }
  }

  // ///
  // Interactable methods
  // ///

  drop() {}

  drag() {}

  snap() {}

  destroy() {}
}

export default Airflow
