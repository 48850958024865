import { ALL_FACILITIES_QUERY, ALL_USER_FAVORITES_QUERY } from 'client/queries'
import { FACILITY_PAGE_LIMIT } from 'client/decorators/withInitialData'

const handleDeleteFavorite = ({ facilityId, mutate, ownProps }) =>
  mutate({
    variables: { facilityId },
    optimisticResponse: {
      __typename: 'Mutation',
      deleteUserFavorite: {
        __typename: 'UserFavorite',
        id: facilityId,
      },
    },
    refetchQueries: [
      {
        query: ALL_FACILITIES_QUERY,
        name: 'allFacilities',
        variables: {
          ...ownProps.variables,
          filter: null,
          limit: FACILITY_PAGE_LIMIT,
        },
      },
      {
        query: ALL_FACILITIES_QUERY,
        name: 'allFacilities',
        variables: {
          ...ownProps.variables,
          filter: { isArchived: true },
          limit: FACILITY_PAGE_LIMIT,
        },
      },
      {
        query: ALL_USER_FAVORITES_QUERY,
        name: 'allFacilities',
      },
    ],
  })

export default handleDeleteFavorite
