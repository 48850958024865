import styled, { css } from 'styled-components'

import theme from 'config/theme'

const primaryActionButtonStyles = props => {
  if (props.primaryActionButton) {
    return css`
      &:after {
        border-radius: 50%;
        box-shadow: 0 0 0 4px ${theme.colors.light.primary},
          ${theme.shadows.menu};
        content: '';
        height: ${theme.touchUI.buttonSize};
        padding: ${theme.spacing.xs};
        position: absolute;
        width: ${theme.touchUI.buttonSize};
      }
    `
  }
}

const CaptureButton = styled.div`
  align-items: center;
  background-color: ${props =>
    props.color ? theme.colors.dark[props.color] : theme.colors.light.primary};
  border-radius: 50%;
  bottom: ${props => !props.top && theme.spacing.base};
  box-shadow: ${theme.shadows.menu};
  display: flex;
  height: ${theme.touchUI.buttonSize};
  justify-content: center;
  position: absolute;
  top: ${props => props.top && theme.spacing.base};
  width: ${theme.touchUI.buttonSize};
  ${primaryActionButtonStyles}
  ${props =>
    props.align
      ? css`
          ${props.align}: ${theme.spacing.base};
        `
      : css`
          right: ${theme.spacing.base};
        `}
`

export default CaptureButton
