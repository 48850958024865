import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const AccordionDetailsContainer = styled.div`
  width: 100%;
  display: grid;
  overflow: hidden;
  grid-template-rows: ${({ open }) => (open ? '1fr' : '0fr')};
  transition: ${({ transitionDuration }) =>
    typeof transitionDuration === 'number' ? `${transitionDuration}ms` : '250ms'};

  & > div:is(:first-child) {
    grid-template-rows: 1fr;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    min-height: 0;
    transition: visibility ${({ transitionDuration }) =>
      typeof transitionDuration === 'number' ? `${transitionDuration}ms` : '250ms'};
  }
`

const AccordionDetails = ({ children, open, transitionDuration }) => {
  return (
    <AccordionDetailsContainer open={open} transitionDuration={transitionDuration}>
      <div>{children}</div>
    </AccordionDetailsContainer>
  )
}

AccordionDetails.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  transitionDuration: PropTypes.number,
}

export { AccordionDetails }
