import React from 'react'
import PropTypes from 'prop-types'

import { DEFAULT_SELECTED_PANEL } from 'store/panel/types'

import Panel from 'components/UIKit/Panel'
import EmptyPanelSection from '../EmptyPanelSection'

const DefaultSelectedPanel = ({ alignment = 'right' }) => (
  <Panel
    title="Inspector"
    alignment={alignment}
    docked
    panelKey={DEFAULT_SELECTED_PANEL}
  >
    <EmptyPanelSection icon="select">
      Select an object to see its properties.
    </EmptyPanelSection>
  </Panel>
)

DefaultSelectedPanel.propTypes = {
  alignment: PropTypes.string,
}

export default DefaultSelectedPanel
