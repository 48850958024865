import styled from 'styled-components'

const size = '60px'

const Image = styled.img`
  height: ${size};
  object-fit: contain;
  width: ${size};
`

export default Image
