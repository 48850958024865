import PropTypes from 'prop-types'

import Container from './styled/Container'

const SelectListTitle = Container

SelectListTitle.propTypes = {
  marginLeft: PropTypes.string,
}

export default /** @type any */(SelectListTitle)
