import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { usePerformanceMetricsProps } from 'components/PerformanceMetrics/hooks/usePerformanceMetricsProps'
import { ImperialTemperature } from 'store/units/types/temperature'
import { ImperialVelocity } from 'store/units/types/velocity'
import { MetricsTable } from 'components/PerformanceMetrics/subcomponents/MetricsTable'
import { Stack } from 'components/UIKit/Stack'
import InfoTooltip from 'components/UIKit/InfoTooltip'
import Heading from 'components/PerformanceMetrics/styled/Heading'
import {
  HEIGHTS,
  HEIGHT_VALUES,
  SIMULATION_TYPES,
  STILL_AIR_VELOCITY,
  getUnitHeatThreshold,
} from 'config/cfd'
import { useHeatingCompositeWorker } from 'components/PerformanceMetrics/hooks/useVtkWorker'
import { SpinningIcon } from 'components/PerformanceMetrics/styled/SpinningIcon'
import { PieChart } from 'components/PerformanceMetrics/subcomponents/PieChart'
import { formatHeights, formatPercent } from '../util/format'
import { DissatisfiedOccupantsIcon, ThermometerIcon, PredictedMeanVoteIcon } from '../icons'
import Tabs from 'components/UIKit/Tabs/Tabs'
import { camelToTitleCase } from 'utils/string'
import { Tab } from 'components/UIKit/Tabs/index'
import { getSensationColor, getThermalComfort } from 'lib/thermalComfortTool/index'
import { IntensityInterpolator } from 'components/PerformanceMetrics/util/interpolateHeatIntensity'
import Icon from 'components/UIKit/Icon/Icon'
import { useFormatTemperature } from 'components/PerformanceMetrics/hooks/useFormatTemperature'
import { useFormatVelocity } from 'components/PerformanceMetrics/hooks/useFormatVelocity'

const useNoHeatersMetrics = () => {
  const { formatTemperature } = useFormatTemperature()
  const { facility } = usePerformanceMetricsProps()
  const { activityLevel, winterClothingType, indoorWinterHumidity, indoorWinterTemp } = facility
  const defaultAirTemp = new ImperialTemperature(indoorWinterTemp)
  const { pmv, ppd, sensation } = getThermalComfort({
    airVelocity: STILL_AIR_VELOCITY,
    humidity: indoorWinterHumidity,
    meanAirTemp: defaultAirTemp,
    clothingType: winterClothingType,
    metabolicRate: activityLevel,
  })
  const formattedTemperature = formatTemperature(defaultAirTemp)
  return {
    heatingCoverage: 0,
    averageIntensity: '---',
    meanAirTemperature: formattedTemperature,
    meanRadientTemperature: formattedTemperature,
    operativeTemperature: formattedTemperature,
    sensation,
    sensationColor: getSensationColor(sensation),
    pmv: pmv.toFixed(2),
    ppd: ppd.toFixed(2) + '%',
  }
}

const useHeatingCompositeMetrics = ({
  intensityUrl,
  temperatureAfterUrls,
  temperatureBeforeUrls,
  velocityAfterUrls,
}) => {
  const { formatTemperature } = useFormatTemperature()
  const { areaOfConcernEdges, facility } = usePerformanceMetricsProps()
  const { activityLevel, winterClothingType, indoorWinterHumidity, indoorWinterTemp } = facility
  const heatingComposite = useHeatingCompositeWorker({
    intensityUrl,
    temperatureAfterUrls,
    temperatureBeforeUrls,
    velocityAfterUrls,
    comfortZoneEdges: areaOfConcernEdges,
    heatingCoverageThreshold: getUnitHeatThreshold(facility),
  })

  if (heatingComposite.loading) {
    return { loading: true, error: undefined, data: undefined }
  } else if (heatingComposite.error || !heatingComposite.data) {
    return { loading: false, error: heatingComposite.error, data: undefined }
  } else {
    /**
     * @type {import("../workers/heatingComposite.worker.js").HeatingCompositeVTKResults}
     */
    const {
      averageAirTemperatureBefore,
      unitHeatingCoverageBefore,
      averageAirTemperatureAfter,
      unitHeatingCoverageAfter,
      averageAirVelocityAfter,
      averageIntensity,
    } = heatingComposite.data
    const defaultTemp = new ImperialTemperature(indoorWinterTemp)
    const { scaleMeanAirTemp, scaleMeanRadiantTemp } = new IntensityInterpolator(averageIntensity)
    const meanRadiantTemp = new ImperialTemperature(scaleMeanRadiantTemp(defaultTemp.value))
    const staticThermalComfortProps = {
      meanRadiantTemp,
      humidity: indoorWinterHumidity,
      clothingType: winterClothingType,
      metabolicRate: activityLevel,
    }
    const meanAirTempBefore = new ImperialTemperature(scaleMeanAirTemp(averageAirTemperatureBefore))
    const operativeTempBefore = new ImperialTemperature(
      (meanRadiantTemp.value + meanAirTempBefore.value) / 2
    )
    const withHeatersThermalComfort = getThermalComfort({
      airVelocity: STILL_AIR_VELOCITY,
      meanAirTemp: meanAirTempBefore,
      ...staticThermalComfortProps,
    })

    const meanAirTempAfter = new ImperialTemperature(scaleMeanAirTemp(averageAirTemperatureAfter))
    const operativeTempAfter = new ImperialTemperature(
      (meanRadiantTemp.value + meanAirTempAfter.value) / 2
    )
    const withHeatersAndFansThermalComfort = getThermalComfort({
      airVelocity: new ImperialVelocity(averageAirVelocityAfter),
      meanAirTemp: meanAirTempAfter,
      ...staticThermalComfortProps,
    })

    const data = {
      withHeaters: {
        heatingCoverage: unitHeatingCoverageBefore,
        averageIntensity: averageIntensity.toFixed(2),
        meanAirTemperature: formatTemperature(meanAirTempBefore),
        meanRadiantTemperature: formatTemperature(meanRadiantTemp),
        operativeTemperature: formatTemperature(operativeTempBefore),
        sensation: withHeatersThermalComfort.sensation,
        sensationColor: getSensationColor(withHeatersThermalComfort.sensation),
        pmv: withHeatersThermalComfort.pmv.toFixed(2),
        ppd: withHeatersThermalComfort.ppd.toFixed(2) + '%',
      },
      withHeatersAndFans: {
        heatingCoverage: unitHeatingCoverageAfter,
        averageIntensity: averageIntensity.toFixed(2),
        meanAirTemperature: formatTemperature(meanAirTempAfter),
        meanRadiantTemperature: formatTemperature(meanRadiantTemp),
        operativeTemperature: formatTemperature(operativeTempAfter),
        sensation: withHeatersAndFansThermalComfort.sensation,
        sensationColor: getSensationColor(withHeatersAndFansThermalComfort.sensation),
        pmv: withHeatersAndFansThermalComfort.pmv.toFixed(2),
        ppd: withHeatersAndFansThermalComfort.ppd.toFixed(2) + '%',
      },
    }
    return { loading: false, error: undefined, data }
  }
}

/**
 * @typedef {import('hooks/useFacilityVtkResults').vtkUrlGroups} vtkUrlGroups
 * @param {object} props
 * @param {object} props.vtkFiles
 * @param {object} props.vtkFiles.vtkUrlGroups
 * @param {vtkUrlGroups} props.vtkFiles.vtkUrlGroups.radiantHeat
 * @param {vtkUrlGroups} props.vtkFiles.vtkUrlGroups.unitHeat
 */
const HeatingCompositResults = ({ vtkFiles: { vtkUrlGroups } }) => {
  const [selectedHeight, setSelectedHeight] = useState(HEIGHTS.standing)
  const noHeatersMetrics = useNoHeatersMetrics()
  const { unitHeating } = SIMULATION_TYPES
  const { loading, error, data } = useHeatingCompositeMetrics({
    intensityUrl: vtkUrlGroups.radiantHeat.intensity,
    temperatureBeforeUrls: vtkUrlGroups[unitHeating].temperatureBefore[selectedHeight],
    temperatureAfterUrls: vtkUrlGroups[unitHeating].temperatureAfter[selectedHeight],
    velocityAfterUrls: vtkUrlGroups[unitHeating].velocityAfter[selectedHeight],
  })

  const formattedHeights = formatHeights(HEIGHT_VALUES[selectedHeight])
  const legend = {
    header: (
      <InfoTooltip
        title={`Calculated using evaluation heights: ${formattedHeights}`}
        iconSize="16px"
      />
    ),
    cells: [
      { content: 'Heating Coverage' },
      {
        content: (
          <Stack direction="row" spacing={1}>
            <InfoTooltip title="Heat intensity on a scale of 1-10" iconSize="16px" />
            <Heading>Average IRH Intensity</Heading>
          </Stack>
        ),
      },
      { icon: <ThermometerIcon />, content: 'Mean Radiant Temp' },
      { icon: <ThermometerIcon />, content: 'Mean Air Temp' },
      { content: 'Thermal Sensation' },
      { content: 'Operative Temperature' },
      {
        icon: <PredictedMeanVoteIcon />,
        content: 'PMV',
      },
      {
        icon: <DissatisfiedOccupantsIcon />,
        content: 'PPD',
      },
    ],
  }

  const noHeaters = {
    header: 'No Heaters',
    cells: [
      {
        icon: <PieChart percentComplete={noHeatersMetrics.heatingCoverage} />,
        content: formatPercent(noHeatersMetrics.heatingCoverage),
      },
      { content: noHeatersMetrics.averageIntensity },
      { content: noHeatersMetrics.meanRadientTemperature },
      { content: noHeatersMetrics.meanAirTemperature },
      {
        content: (
          <Heading color={noHeatersMetrics.sensationColor}>{noHeatersMetrics.sensation}</Heading>
        ),
      },
      { content: noHeatersMetrics.operativeTemperature },
      { content: noHeatersMetrics.pmv },
      { content: noHeatersMetrics.ppd },
    ],
  }

  const withHeaters = {
    header: 'With Heaters',
    cells: loading
      ? [{ content: <SpinningIcon name="refresh" size="20" /> }]
      : error || !data
      ? [
          {
            icon: <Icon name="warn" size="20" />,
            content: error?.message || 'Failed to calculate metrics',
          },
        ]
      : [
          {
            icon: <PieChart percentComplete={data.withHeaters.heatingCoverage} />,
            content: formatPercent(data.withHeaters.heatingCoverage),
          },
          { content: data.withHeaters.averageIntensity },
          { content: data.withHeaters.meanRadiantTemperature },
          { content: data.withHeaters.meanAirTemperature },
          {
            content: (
              <Heading color={data.withHeaters.sensationColor}>
                {data.withHeaters.sensation}
              </Heading>
            ),
          },
          { content: data.withHeaters.operativeTemperature },
          { content: data.withHeaters.pmv },
          { content: data.withHeaters.ppd },
        ],
  }

  const withFansAndHeaters = {
    header: (
      <Stack>
        Heaters + Fans<Heading>(1 Turnover)</Heading>
      </Stack>
    ),
    cells: loading
      ? [{ content: <SpinningIcon name="refresh" size="20" /> }]
      : error || !data
      ? [
          {
            icon: <Icon name="warn" size="20" />,
            content: error?.message || 'Failed to calculate metrics',
          },
        ]
      : [
          {
            icon: <PieChart percentComplete={data.withHeatersAndFans.heatingCoverage} />,
            content: formatPercent(data.withHeatersAndFans.heatingCoverage),
          },
          { content: data.withHeatersAndFans.averageIntensity },
          { content: data.withHeatersAndFans.meanRadiantTemperature },
          { content: data.withHeatersAndFans.meanAirTemperature },
          {
            content: (
              <Heading color={data.withHeatersAndFans.sensationColor}>
                {data.withHeatersAndFans.sensation}
              </Heading>
            ),
          },
          { content: data.withHeatersAndFans.operativeTemperature },
          { content: data.withHeatersAndFans.pmv },
          { content: data.withHeatersAndFans.ppd },
        ],
  }

  return (
    <Tabs size="s" activeIndex={Object.values(HEIGHTS).indexOf(selectedHeight)}>
      {Object.values(HEIGHTS).map((height, i) => (
        <Tab key={i} title={camelToTitleCase(height)} onClick={() => setSelectedHeight(height)}>
          <MetricsTable columns={[legend, noHeaters, withHeaters, withFansAndHeaters]} />
        </Tab>
      ))}
    </Tabs>
  )
}

HeatingCompositResults.propTypes = {
  vtkFiles: PropTypes.object,
}

export { HeatingCompositResults }
