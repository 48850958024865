import get from 'lodash-es/get'
import { graphql } from '~/gql'
import globalClient from '~/client'

const GET_PRODUCT_THUMBNAIL_QUERY = graphql(`
    query GetProductThumbnail($model: String!) {
        ProductThumbnail(model: $model)
    }
`)

export async function getAllThumbnails(models: string[], client = globalClient) {
    const thumbnails: Partial<Record<string, string | null>> = {}

    for (let i in models) {
        const response = await client.query({
            query: GET_PRODUCT_THUMBNAIL_QUERY,
            variables: {
                model: models[i]
            },
        })
        const url = get(response, 'data.ProductThumbnail')
        thumbnails[models[i]] = url
    }

    return thumbnails
}

export default GET_PRODUCT_THUMBNAIL_QUERY
