import styled, { ThemedStyledProps } from 'styled-components'
import { Spacing, Theme } from '~/config/theme'

const styles = (props: ThemedStyledProps<{ bottom?: Spacing; top?: Spacing; right?: Spacing; left?: Spacing }, Theme>) => {
  let styles = ''

  if (props.bottom) {
    styles += `margin-bottom: ${props.theme.spacing[props.bottom]};`
  }

  if (props.top) {
    styles += `margin-top: ${props.theme.spacing[props.top]};`
  }

  if (props.left) {
    styles += `margin-left: ${props.theme.spacing[props.left]};`
  }

  if (props.right) {
    styles += `margin-right: ${props.theme.spacing[props.right]};`
  }

  return styles
}

const Container = styled.div<{ bottom?: Spacing; top?: Spacing; right?: Spacing; left?: Spacing }>`
  ${styles};
`

export default Container
