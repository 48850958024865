import { compose } from 'redux'
import { appConnect } from "~/store/hooks";
import { branch, renderComponent } from 'recompact'
import findIndex from 'lodash-es/findIndex'
import get from 'lodash-es/get'
import map from 'lodash-es/map'
import flatten from 'lodash-es/flatten'
import isEqual from 'lodash-es/isEqual'
import difference from 'lodash-es/difference'
import startCase from 'lodash-es/startCase'

import DETAILED_OBSTRUCTIONS from 'config/detailedObstructions'

import Units from 'components/DrawingCanvas/lib/units'
import Wall from 'components/DrawingCanvas/lib/wall'
import Facility from 'components/DrawingCanvas/lib/facility'
import { productTag, productGroups, description } from './virtuals'

import { getSnapshotUrl, getCloudinaryImageUrl } from 'lib/utils'

import { getObjectImageIds } from 'store/objects/selectors'

import withEnrichedProducts from 'client/decorators/withEnrichedProducts'
import withFacility from 'client/decorators/withFacility'

import Loader from 'components/UIKit/Loader'

import * as THREE from 'three'

export const withObjects = compose(
  appConnect(state => {
    const stateObjects = get(state, 'objects.present')
    const objects = {
      products: stateObjects.products,
      others: {
        ...stateObjects.obstructions,
        ...stateObjects.utilityBoxes,
      },
    }

    return { objects }
  })
)

export const withFans = compose(
  withEnrichedProducts,
  appConnect((_, { enrichedProducts, loading }) => {
    if (loading) {
      return {}
    }
    const { groupedProducts, indexedProducts } = productGroups(enrichedProducts)
    const fans = map(groupedProducts, group => {
      const index =
        findIndex(indexedProducts, product => product.id === group[0].id) + 1
      return {
        tag: productTag(index),
        quantity: group.length,
        description: description(group[0]),
        id: group[0].id,
      }
    })

    return { fans }
  })
)

export const withImageSelector = compose(
  withFacility,
  appConnect(
    (_, ownProps) => {
      const snapshotURLs = (
        get(ownProps, 'facility.floor.version.snapshots') || []
      ).map(image =>
        getSnapshotUrl({
          cropData: image.cropData,
          cloudinaryId: image.cloudinaryId,
          defaultSizes: {
            // NOTE: These are set to a quarter of their actual size because
            // the `getSnapshotUrl()` function will double their size, which
            // we want to be halved in the selector.
            width: Math.floor(image.data.width * 0.25),
            height: Math.floor(image.data.height * 0.25),
          },
        })
      )
      const imageNoteUrls = getObjectImageIds().map(id =>
        getCloudinaryImageUrl(id)
      )
      const availableImages = []
      const { imageTypes = [] } = ownProps
      imageTypes.forEach(imageType => {
        switch (imageType) {
          case 'snapshot':
            availableImages.push(...snapshotURLs)
            break
          case 'object':
            availableImages.push(...imageNoteUrls)
            break
          default:
            break
        }
      })

      //  filter any bad items
      const remainingImages = difference(availableImages).filter(img => !!img)

      return {
        availableImages: remainingImages,
        snapshots: [...snapshotURLs],
      }
    },
    {},
    null,
    {
      areStatesEqual(next, prev) {
        const selectedImagesChanged = !isEqual(
          get(next, 'exportPDF'),
          get(prev, 'exportPDF')
        )
        return !selectedImagesChanged
      },
    }
  )
)

export const withPDFNames = compose(
  appConnect((_, ownProps) => {
    const salesRepName = startCase(get(ownProps, 'user.name'))
    const facilityRefNumber =
      get(ownProps, 'facility.id', '0') +
      '.' +
      get(ownProps, 'facility.floor.id', '0') +
      '.' +
      get(ownProps, 'facility.floor.version.id', '0')
    const salesRepEmail = get(ownProps, 'user.email')
    const facilityName = get(ownProps, 'facility.name')
    const versionName = get(ownProps, 'facility.floor.version.name')
    return {
      customerName: [facilityName, versionName]
        .filter(name => Boolean(name))
        .join('-'),
      salesRepName,
      salesRepEmail,
      facilityName,
      facilityRefNumber,
    }
  })
)

export const withWireframe = compose(
  withEnrichedProducts,
  appConnect((state, { enrichedProducts, loading }) => {
    if (loading) {
      return {}
    }
    const baf = get(state, 'objects.present')

    const segmentXs = []
    const segmentYs = []
    Object.values(baf.objects).forEach(w => {
      w.segments.forEach(s => {
        const segment = get(baf, `segments.${s}`)
        // NOTE: accounts for wall thickness (not exact due to angles)
        const wallThicknessOffset = segment.thickness / 2

        segmentXs.push(segment.startPoint.x + wallThicknessOffset)
        segmentXs.push(segment.endPoint.x + wallThicknessOffset)
        segmentYs.push(segment.startPoint.y + wallThicknessOffset)
        segmentYs.push(segment.endPoint.y + wallThicknessOffset)

        segmentXs.push(segment.startPoint.x - wallThicknessOffset)
        segmentXs.push(segment.endPoint.x - wallThicknessOffset)
        segmentYs.push(segment.startPoint.y - wallThicknessOffset)
        segmentYs.push(segment.endPoint.y - wallThicknessOffset)
      })
    })
    const boundaries = {
      left: Math.min.apply(null, segmentXs),
      right: Math.max.apply(null, segmentXs),
      bottom: Math.min.apply(null, segmentYs),
      top: Math.max.apply(null, segmentYs),
    }

    const dimensions = {
      h: boundaries.top - boundaries.bottom,
      w: boundaries.right - boundaries.left,
    }

    const facilityCenter = {
      x: boundaries.right - dimensions.w / 2,
      y: boundaries.top - dimensions.h / 2,
    }

    // We will flip the wireframe sideways if the height is greater than
    // the width * 1.01 (multiplied by 1.01 to offset for tiny differences in
    // threejs locations due to angles...plus, if its only a tiny bit taller
    // than wide, flipping the wireframe won't really be necessary)
    const adjustmentAngle = dimensions.h > dimensions.w * 1.01 ? 90 : 0
    return {
      wireframe: {
        walls: wireframe.walls(baf, adjustmentAngle, facilityCenter),
        backgroundImage: wireframe.backgroundImage(
          baf,
          adjustmentAngle,
          facilityCenter
        ),
        products: wireframe.products(
          enrichedProducts,
          adjustmentAngle,
          facilityCenter
        ),
        obstructions: Object.values(baf.obstructions).reduce((array, o) => {
          let positions = o.positions

          if (o.obstructionType === 'basic') {
            array.push({
              obstructionType: o.obstructionType,
              position: rotate(o.position, adjustmentAngle, facilityCenter),
              rotation: get(o, 'rotation.z', 0),
              adjustmentAngle,
              facilityCenter,
              positions,
            })
          }

          return array
        }, []),
        detailedObstructions: wireframe.detailedObstructions(
          baf.obstructions,
          adjustmentAngle,
          facilityCenter
        ),
        dimensions: wireframe.dimensions(
          baf.dimensions,
          adjustmentAngle,
          facilityCenter
        ),
        utilityBoxes: Object.values(baf.utilityBoxes).map(u => {
          const rotatedPos = rotate(
            { x: u.position.x, y: u.position.y },
            adjustmentAngle,
            facilityCenter
          )
          return {
            x: rotatedPos.x,
            y: rotatedPos.y,
            type: u.utilityBoxType,
          }
        }),
        doors: wireframe.doors(baf, adjustmentAngle, facilityCenter),
      },
    }
  })
)

const rotate = (pos, rotation, origin) => {
  const offsetX = pos.x - origin.x
  const offsetY = pos.y - origin.y
  const rads = (Math.PI / 180) * rotation
  return {
    x: offsetX * Math.cos(rads) - offsetY * Math.sin(rads) + origin.x,
    y: offsetY * Math.cos(rads) + offsetX * Math.sin(rads) + origin.y,
  }
}

const wireframe = {
  walls(baf, angle, center) {
    return Object.values(baf.objects).map(w => ({
      segments: w.segments.map(s => {
        const segment = get(baf, `segments.${s}`)
        const rotatedStartPoint = rotate(
          { x: segment.startPoint.x, y: segment.startPoint.y },
          angle,
          center
        )
        const rotatedEndPoint = rotate(
          { x: segment.endPoint.x, y: segment.endPoint.y },
          angle,
          center
        )
        return {
          startX: rotatedStartPoint.x,
          startY: rotatedStartPoint.y,
          endX: rotatedEndPoint.x,
          endY: rotatedEndPoint.y,
          thickness: get(
            segment,
            'thickness',
            Wall.thicknessForLayerKey(segment.layerKey)
          ),
        }
      }),
    }))
  },
  products(enrichedProducts, angle, center) {
    const { groupedProducts, indexedProducts } = productGroups(enrichedProducts)
    return flatten(
      map(groupedProducts, (group, key) => {
        const index =
          findIndex(indexedProducts, fan => fan.id === group[0].id) + 1
        return group.map(p => {
          const rotation = get(p, 'rotation.z', 0) + angle
          const rotatedPos = rotate(
            { x: p.position.x, y: p.position.y },
            angle,
            center
          )
          return {
            tag: productTag(index),
            x: rotatedPos.x,
            y: rotatedPos.y,
            modelName: p.product.model,
            size: p.size,
            type: p.product.type || 'OVERHEAD',
            rotation,
          }
        })
      })
    )
  },
  dimensions(dimensions, angle, center) {
    return Object.values(dimensions).map(d => {
      const anchorStartPos =
        d.anchorStartPos &&
        new THREE.Vector3(
          Units.nativeToInches(d.anchorStartPos.x),
          Units.nativeToInches(d.anchorStartPos.y),
          Units.nativeToInches(d.anchorStartPos.z)
        )

      const anchorEndPos =
        d.anchorStartPos &&
        new THREE.Vector3(
          Units.nativeToInches(d.anchorEndPos.x),
          Units.nativeToInches(d.anchorEndPos.y),
          Units.nativeToInches(d.anchorEndPos.z)
        )

      const rotatedStartPos = rotate(
        { x: d.startPos.x, y: d.startPos.y },
        angle,
        center
      )
      const rotatedEndPos = rotate(
        { x: d.endPos.x, y: d.endPos.y },
        angle,
        center
      )
      const rotatedAnchorStartPos =
        anchorStartPos &&
        rotate({ x: anchorStartPos.x, y: anchorStartPos.y }, angle, center)
      const rotatedAnchorEndPos =
        anchorEndPos &&
        rotate({ x: anchorEndPos.x, y: anchorEndPos.y }, angle, center)
      const rotatedLabelPos =
        d.labelPos &&
        rotate({ x: d.labelPos.x, y: d.labelPos.y }, angle, center)

      return {
        startX: rotatedStartPos.x,
        startY: rotatedStartPos.y,
        endX: rotatedEndPos.x,
        endY: rotatedEndPos.y,
        anchorStartX: anchorStartPos && rotatedAnchorStartPos.x,
        anchorStartY: anchorStartPos && rotatedAnchorStartPos.y,
        anchorEndX: anchorEndPos && rotatedAnchorEndPos.x,
        anchorEndY: anchorEndPos && rotatedAnchorEndPos.y,
        labelPosX: d.labelPos && rotatedLabelPos.x,
        labelPosY: d.labelPos && rotatedLabelPos.y,
        // Distance formula
        label: Units.toDistanceString(
          Units.inchesToNative(
            Math.sqrt(
              Math.pow(rotatedStartPos.x - rotatedEndPos.x, 2) +
                Math.pow(rotatedStartPos.y - rotatedEndPos.y, 2)
            )
          )
        ),
      }
    })
  },
  doors(baf, angle, center) {
    return Object.values(baf.doors).map(d => {
      const segment = get(baf, `segments[${d.wallSegmentId}]`)
      const rotatedPos = rotate(
        { x: d.position.x, y: d.position.y },
        angle,
        center
      )
      return {
        x: rotatedPos.x,
        y: rotatedPos.y,
        type: d.doorType,
        width: d.width,
        rotation: d.rotation._z, // Radians
        angle: (Math.PI / 180) * angle, // convert to Radians
        center,
        thickness: get(
          segment,
          'thickness',
          Wall.thicknessForLayerKey(get(segment, 'layerKey'))
        ),
      }
    })
  },
  detailedObstructions(obs, angle, center) {
    return Object.values(obs)
      .filter(o => o.obstructionType !== 'basic')
      .map(o => {
        const details = DETAILED_OBSTRUCTIONS.find(
          dobs => dobs.obstructionType === o.obstructionType
        )
        const facilityObstruction = Facility.current.findObjectWithId(o.id)
        const rotatedPosition = rotate(o.position, angle, center)
        return {
          position: rotatedPosition,
          angle,
          rotation: get(o, 'rotation.z', 0),
          positions: o.positions.map(p => {
            // If auto rotating, rotate each point around facility center,
            // then rotate back around obstruction center to preserve
            // obstruction's height and width
            const tempPos = rotate(p, angle, center)
            return rotate(tempPos, -angle, rotatedPosition)
          }),
          svg: details.obstructionType,
          width: details.width,
          height: details.length,
          scaleToLength: o.scaleToLength,
          scaleToWidth: o.scaleToWidth,
          obstructionType: o.obstructionType,
          // used to determine which svgs to render first
          top:
            Units.nativeToInches(facilityObstruction.startPosition) +
            facilityObstruction.height,
        }
      })
  },
  backgroundImage(baf, angle, center) {
    const backgroundImage = get(baf, 'backgroundImage.BACKGROUND_IMAGE')

    if (backgroundImage) {
      const position = backgroundImage.position || new THREE.Vector3()
      const rotatedPos = rotate(
        {
          x: position.x,
          y: position.y,
        },
        angle,
        center
      )
      return { ...backgroundImage, angle, position: rotatedPos }
    } else return backgroundImage
  },
}
