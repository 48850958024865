import { useAppSelector } from "~/store/hooks"
import get from 'lodash-es/get'

import { graphql } from '~/gql'
import { Product } from "~/store/objects/types"
import { EnrichedProductQueryQuery } from "~/gql/graphql"
import { useQuery } from "@apollo/client"

type QueriedData = NonNullable<EnrichedProductQueryQuery['ProductVariation']>
export type EnrichedProduct = Product & QueriedData

export function getVoltage(product: EnrichedProduct) {
  return product.voltages?.find(it => it.id === product.voltageId)
}

export function getMounting(product: EnrichedProduct) {
  return product.voltages?.find(it => it.id === product.voltageId)?.mountingOptions?.find(it => it.id === product.mountingOptionId)
}

export default (Component: React.ComponentType<any>) => (incomingProps: {}) => {
  const selectedObjects = useAppSelector(root => root.selectedObjects)
  const objects = useAppSelector(root => root.objects)

   let selectedProducts = selectedObjects
      .map(obj => get(objects, `present.products.${obj.id}`))
      .filter(obj => obj !== undefined)

    let productType: string | undefined | null
    let variationId: string | undefined | null
    let productId: string | undefined | null
    const productTypesDiffer = !selectedProducts.every(product => {
      if (variationId === undefined) {
        variationId = get(product, 'variationId')
        productId = get(product, 'id')
      } else if (variationId !== get(product, 'variationId')) {
        variationId = null
        productId = null
      }

      const model = get(product, 'product.model')
      if (productType === undefined) {
        productType = model
        return true
      } else {
        return productType === model
      }
    })

    const { loading, data } = useQuery(
      graphql(`
        query EnrichedProductQuery($variationID: ID!) {
          ProductVariation(id: $variationID) {
            id
            size
            label
            cfdId
            canMountOnColumn
            canMountOnWall
            canMountOverhead
            canStandAlone
            product {
              id
              model
              category
              type
              distinctFanSpeeds {
                speed
                power
                heightFromFloor
                overheadOnly
              }
            }
            degreesOfFreedom {
              id
              start
              end
              step
            }
            mountingOptionAdders {
              id
              mountingType
            }
            voltages {
              id
              inputPower
              mountingOptions {
                id
                forWall
                forColumn
                forPedestal
                forOverhead
                tubeLength
              }
            }
          }
        }
      `
      ),
      {
        variables: { variationID: variationId! },
        skip: !variationId,
      },
    )

    const newProps = {
      selectedObjects: selectedProducts.map(product => ({
        ...product,
        ...(data?.ProductVariation ? data.ProductVariation : {}),
        id: product.id,
      })),
      productTypesDiffer,
      variationId,
      productId,
    }

    return <Component {...incomingProps} {...newProps}/>
}
