import styled from 'styled-components'
import Loader from 'components/UIKit/Loader'

const CameraLoader = styled(Loader)`
  background: ${props => props.theme.colors.light.extraLight};
  position: absolute;
  top: 0;
`

export default CameraLoader
