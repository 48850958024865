import { graphql } from '~/gql'

const SAVE_LAYERS_MUTATION = graphql(`
  mutation saveLayers(
    $facilityId: ID!
    $floorId: ID
    $versionId: ID
    $layers: [LayerInput]
  ) {
    save(
      facilityId: $facilityId
      floorId: $floorId
      versionId: $versionId
      layers: $layers
    ) {
      ...VersionFragment
    }
  }
`)

export default SAVE_LAYERS_MUTATION
