import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import isEmpty from 'lodash-es/isEmpty'
import isEqual from 'lodash-es/isEqual'
import get from 'lodash-es/get'
import PropTypes from 'prop-types'

import { appendURL } from 'lib/utils'
import { objectStateKeyFromClassName } from 'store/objects/selectors'
import { withNetwork } from 'networkProvider'

import { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'

import Images from './Fields/Images'
import Notes from './Fields/Notes'
import Title from './Fields/Title'

import AddImageButton from './styled/AddImageButton'
import TakePhotoButton from './styled/TakePhotoButton'

export const LABEL_WIDTH = 50
export const THUMBNAIL_SIZE = 50
export const SQUARE_THUMBNAIL_SIZE = 80

// METADATA FIELDS
// ===============
// TITLE
// NOTES
// IMAGES
class MetadataSection extends Component {
  getUpdatedObject(object, event) {
    const { name, value } = event.target
    const { metadata = {} } = object
    return {
      ...object,
      metadata: {
        ...metadata,
        [name]: value,
      },
      ignoreForCFD: true,
    }
  }

  getUpdatedImages(object, images) {
    const { metadata = {} } = object
    return {
      ...object,
      metadata: {
        ...metadata,
        images,
      },
      ignoreForCFD: true,
    }
  }

  handleInputChange = event => {
    const { object, objects, onBlur } = this.props

    if (objects) {
      onBlur &&
        onBlur(objects.map(object => this.getUpdatedObject(object, event)))
    } else {
      onBlur && onBlur(this.getUpdatedObject(object, event))
    }
  }

  handleDeleteImage = (cloudinaryId, images) => {
    const { object, objects, onBlur } = this.props
    const newImagesArray = images.filter(
      img => img.cloudinaryId !== cloudinaryId
    )

    if (objects) {
      onBlur &&
        onBlur(
          objects.map(object => this.getUpdatedImages(object, newImagesArray))
        )
    } else {
      onBlur && onBlur(this.getUpdatedImages(object, images))
    }
  }

  getIcon = metadata =>
    !isEmpty(metadata) &&
    (!isEmpty(metadata.notes) ||
      !isEmpty(metadata.title) ||
      !isEmpty(metadata.images))
      ? 'edit'
      : 'plus'

  getObjectData() {
    const { object, objects } = this.props

    let title
    let notes
    let images
    let key

    if (objects) {
      const ids = []
      objects.forEach(obj => {
        ids.push(obj.id)
        const { metadata = {} } = obj
        if (title === undefined) {
          title = metadata.title || ''
        } else if (title !== metadata.title) {
          title = false
        }

        if (notes === undefined) {
          notes = metadata.notes || ''
        } else if (notes !== metadata.notes) {
          notes = false
        }

        if (images === undefined) {
          images = metadata.images || []
        } else if (!isEqual(images, metadata.images)) {
          images = []
        }
      })
      key = ids.join('-')
    } else {
      const { metadata = {} } = object
      key = object.id
      ;({ title, notes, images } = metadata)
    }

    images = images || []

    return { title, notes, images, key }
  }

  render(props = {}) {
    const { object, objects, match, history, online } = this.props

    if (!objects && !object) return null

    const { title, notes, images, key } = this.getObjectData()
    const selectedObject = object || get(objects, '[0]')
    const objectClassName = get(selectedObject, 'className')
    const objectStateKey = objectStateKeyFromClassName(objectClassName)
    const objectId = get(selectedObject, 'id')
    const url = appendURL(
      match.url,
      `view-image?objectId=${objectId}&objectType=${objectStateKey}&imageIndex=`
    )

    return (
      <PanelSection
        key={key}
        title="Metadata"
        collapsible
        icon={this.getIcon({ title, notes, images })}
      >
        <Space bottom="base">
          <Title
            onBlur={this.handleInputChange}
            value={title}
            labelWidth={`${LABEL_WIDTH}px`}
            disabled={this.props.disabled}
          />
        </Space>
        <Space bottom="base">
          <Notes
            onBlur={this.handleInputChange}
            value={notes}
            labelWidth={`${LABEL_WIDTH}px`}
            disabled={this.props.disabled}
          />
        </Space>
        {images.length >= 1 && (
          <Space bottom="base">
            <Images
              images={images}
              url={url}
              history={history}
              onDelete={cloudinaryId =>
                this.handleDeleteImage(cloudinaryId, images)
              }
            />
          </Space>
        )}
        <Space bottom="base">
          {online && (
            <AddImageButton
              onClick={() =>
                history.push(appendURL(match.url, 'upload-image/metadata'))
              }
              label="Upload Image"
              icon="cloudUpload"
              responsive
              disabled={this.props.disabled}
            />
          )}
          {online && (
            <TakePhotoButton
              label="Take Photo"
              to={appendURL(match.url, 'webcam-capture/metadata')}
              icon="camera"
              disabled={this.props.disabled}
            />
          )}
        </Space>
      </PanelSection>
    )
  }
}

MetadataSection.propTypes = {
  object: PropTypes.object,
  objects: PropTypes.arrayOf(PropTypes.object),
  onBlur: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  online: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default /** @type {any} */(compose(withRouter, withNetwork)(MetadataSection))
