import styled from 'styled-components'

import theme from 'config/theme'

const size = '16px'

const setPosition = ({ isTouchUI }) => {
  if (isTouchUI) {
    return `
      margin-right: ${size};
      position: static;
    `
  }
}

const Badge = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.light[props.type]};
  border-radius: 100%;
  display: flex;
  height: ${theme.spacing.base};
  justify-content: center;
  position: absolute;
  right: 8px; /* FIXME: Magic number! */
  top: 4px; /* FIXME: Magic number! */
  width: ${size};
  ${setPosition}
`

export default Badge
