import { graphql } from '~/gql'

const GET_PROCESS_JOB_QUERY = graphql(`
  query getProcessJob($processJobId: ID!) {
    ProcessJob(id: $processJobId) {
      ...ProcessFragment
    }
  }
`)

export default GET_PROCESS_JOB_QUERY
