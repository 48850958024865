import React from 'react'
import { string } from 'prop-types'

import Space from 'components/UIKit/Space'
import VariantText from 'components/UIKit/VariantText'

/**
 * Makes the item type singular and lowercase
 */
const getFormattedType = type => {
  const lastCharIndex = type.length - 1
  const secondToLastCharIndex = type.length - 2
  const lastTwoChars = type.substring(secondToLastCharIndex)
  if (lastTwoChars === 'es') {
    return `${type.substring(0, secondToLastCharIndex).toLowerCase()} `
  }
  return `${type.substring(0, lastCharIndex).toLowerCase()} `
}

const BlankSlateMessage = ({ type }) => {
  if (!type) return null

  const formattedType = getFormattedType(type)
  return (
    <Space top="l" bottom="l" left="l" right="l">
      <VariantText size="s" color="subdued">
        You do not have any {type !== 'All Items' && formattedType}
        configurations currently. Select an item to save a configuration.
      </VariantText>
    </Space>
  )
}

BlankSlateMessage.propTypes = {
  type: string.isRequired,
}

export default BlankSlateMessage
