import styled from 'styled-components'

import { theme } from 'lib/styleUtils'

import FormLabel from 'components/UIKit/FormLabel'

const PaddedLabel = styled(FormLabel)<{ notBold?: boolean; disabled?: boolean }>`
  display: inline-block;
  margin: 0 -${theme('spacing.s')};
  padding: ${theme('spacing.s')};
  ${props => props.notBold && `font-weight: normal`}
  ${props => props.disabled && `color: ${theme('colors.light.subdued')(props)}`}
`

export default PaddedLabel
