import styled from 'styled-components'
import theme from 'config/theme'

const Button = styled.button`
  border: 1px solid rgb(203, 203, 203);
  border-radius: ${theme.radius.base};
  cursor: pointer;
  display: inline-block;
  font-size: ${theme.fonts.sizes.s}};
  font-weight: ${theme.fonts.weights.bold};
  text-decoration: none;
  transition: 0.3s ease-in-out;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 3.5;
  background-color: ${props => props.bg};
  color: ${props => props.color};

  &:hover {
    color: black;
  }
`

const ButtonGroup = styled.div`
  > ${Button} {
    border-radius: 0;

    &:active,
    &:focus,
    &:hover {
      border-color: ${theme.colors.dark};
      /* FIXME: Find a better focus style. */
      outline: none;
    }
  }

  > ${Button}:not(:last-child) {
    border-right: 0;
  }

  > ${Button}:first-child {
    border-top-left-radius: ${theme.radius.base};
    border-bottom-left-radius: ${theme.radius.base};
  }

  > ${Button}:last-child {
    border-top-right-radius: ${theme.radius.base};
    border-bottom-right-radius: ${theme.radius.base};
  }
`

export { Button, ButtonGroup }
