import get from 'lodash-es/get'
import { graphql } from '~/gql'
import { HEATER_QUERY, PRODUCT_QUERY } from '.'

import globalClient from '~/client'
import { HeaterFragmentFragment, ProductFragmentFragment } from '~/gql/graphql'

const heaterProductIds = ['24', '25', '26']
const PRODUCTS_QUERY = graphql(`
  query AllProducts {
    Products {
      id
    }
  }
`)

// We're grabbing products one at a time so we don't
// Hit the API request timeout and throw a CORS error
export async function getAllProducts(setProgress: (progress: number) => void = () => { }, client = globalClient) {
  let prods = []
  const productIdsData = await client.query({ query: PRODUCTS_QUERY })
  const productIds = get(productIdsData, 'data.Products') || []

  let currentProgress = 0
  prods = await Promise.all(
    productIds.filter(it => it != null).map(async ({ id }) => {
      const isHeater = heaterProductIds.includes(id)
      const productData = await client.query({
        query: isHeater ? HEATER_QUERY : PRODUCT_QUERY,
        variables: { productId: id },
      })
      currentProgress++
      setProgress(Math.round((currentProgress / productIds.length) * 100))
      return productData.data.Product! as ProductFragmentFragment | HeaterFragmentFragment
    })
  )
  return prods
}

export async function getHeaterProducts(client = globalClient) {
  let prods = []
  for (let i = 0; i < heaterProductIds.length; ++i) {
    const id = heaterProductIds[i]
    const productData = await client.query({
      query: HEATER_QUERY,
      variables: { productId: id },
    })
    prods.push(get(productData, 'data.Product'))
  }

  return prods
}

export default PRODUCTS_QUERY
